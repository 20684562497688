(function () {
  'use strict';
  angular.module('tallyfy')
    .component('appExplanation', {
      templateUrl: 'app/components/app-explanation/app-explanation.component.html',
      bindings: {
        currentPage: '<',
        onAppExplanationClose: '&'
      },
      controller:
        /*@ngInject*/
        function (_, Helper, $uibModal) {
          var $ctrl = this, body;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onWatchIntroModal = onWatchIntroModal;

          function onInit() {
            body = angular.element(document.getElementsByTagName('body'));
            body.addClass('explanation-active');
            $ctrl.appExplanationItems = Helper.getAppExplanationItems();
          }
          
          function onChanges() { }

          function onDestroy() {
            body.removeClass('explanation-active');
          }

          /**
           * @ngdoc method
           * @name onWatchIntroModal
           * @description view intro video modal
           */
          function onWatchIntroModal() {
            $uibModal.open({
              component: 'watchIntroModal',
              windowClass: 'watch-intro-modal',
              resolve: {
                customTitleTranslationStr: function () {
                  return 'app_explanation.watchDemo';
                }
              }
            });
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.tags.Component.tags
 * @module tallyfy.tags
 *
 * @description
 * A component to manage tags
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tags')
    .component('tags', {
      templateUrl: 'app/modules/tags/tags.html',
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, TagsService, Growl, $filter, blockUI, $confirm, Helper, TAG, AuthPlan, TagsTableService, AccountService, preferencesStore) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('tag'),
            tagsData = [];

          /**
           * declare private/public properties
           */
          $ctrl.tags = [];
          $ctrl.addNewTagView = false;
          $ctrl.isTagColorCodeChanged = false;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getTagData = getTagData;
          $ctrl.addNewTag = addNewTag;
          $ctrl.showAddNewTagView = showAddNewTagView;
          $ctrl.tagPagination = { per_page: 10, page: 1 };
          $ctrl.onAutoGeneratedTags = onAutoGeneratedTags;

          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.perPageData = [5, 10, 20];
 
          /**
           * @function $onInit A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.autoGenerated = AccountService.getPreference($rootScope.identity.preferences, 'auto_generated_filter', 'no');
            $ctrl.specialTagTooltipText = $filter('translate')('tags.specialTagTooltipText');
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name addNewTag
           * @description Create new tag
           * @returns {void}
           */
          function addNewTag() {
            if (!Helper.checkAccessAuthority())
              return;
            blockUI.start();
            TagsService.createTag({
              title: $ctrl.tag.title
            }).then(function (response) {
              var newTag = angular.extend(response.data, {
                statistics: {
                  data: {
                    active_process: 0,
                    active_template: 0,
                    archived_process: 0,
                    archived_template: 0
                  }
                }
              });
              tagsData = _.concat([newTag], tagsData);
              $ctrl.tableOptions.gridConfig.dataSource.data(tagsData);
              $ctrl.addNewTagView = false;
              if ($ctrl.tagPagination) {
                $ctrl.tagPagination.total = parseInt($ctrl.tagPagination.total) + 1;
                $rootScope.$emit('COUNT_UPDATE', { type: 'tags', count: $ctrl.tagPagination.total });
              }
              resetForm();
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name deleteTag
           * @description Delete tag
           * @returns {void}
           */
          function deleteTag() {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: $ctrl.tableOptions.templateScope.variables.deletedUid });
            if (!Helper.checkAccessAuthority())
              return;
            var activeProcess = _.get(tag, 'statistics.data.active_process', 0),
              activeTemplate = _.get(tag, 'statistics.data.active_template', 0),
              archivedProcess = _.get(tag, 'statistics.data.archived_process', 0),
              archivedTemplate = _.get(tag, 'statistics.data.archived_template', 0),
              totalUse = activeProcess + activeTemplate + archivedProcess + archivedTemplate;
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', { actionName: 'Permanently delete', componentName: 'tag?' }),
              'body': $filter('translate')('tags.confirmAction.body', { totalUse: totalUse }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'DELETE TAG' }),
                'cancel': 'tags.confirmAction.buttons.cancel'
              },
              'modalType': 'modal-danger'
            }).then(function () {
              blockUI.start();
              TagsService.deleteTag(tag.id).then(function () {
                blockUI.stop();
                growl.success($filter('translate')('tags.messages.deleteTag'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                _.remove(tagsData, function (t) {
                  return t.id === tag.id;
                });
                $ctrl.tableOptions.gridConfig.dataSource.data(tagsData);
                if ($ctrl.tagPagination) {
                  $ctrl.tagPagination.total = parseInt($ctrl.tagPagination.total) - 1;
                  $rootScope.$emit('COUNT_UPDATE', { type: 'tags', count: $ctrl.tagPagination.total });
                }
              }, function () {
                blockUI.stop();
              });
            });
          }

          /**
           * @function
           * @description Reset form data
           * @returns {void}
           */
          function resetForm() {
            $ctrl.tag.title = null;
            $ctrl.tagForm.$setPristine();
            $ctrl.tagForm.$setUntouched();
            angular.element(document.querySelector("#addTag")).removeClass("show");
          }

          /**
           * @function
           * @description Update tag by tag id
           * @returns {void}
           */
          function updateTag() {
            var tagColorValid = $ctrl.tableOptions.templateScope.callbacks.validColor($ctrl.tableOptions.templateScope.variables.editedTagModel.color);
            if (!tagColorValid) {
              return;
            }
            blockUI.start();
            var id = $ctrl.tableOptions.templateScope.variables.editedTagModel.id;
            TagsService.updateTag({
              id: id,
              title: $ctrl.tableOptions.templateScope.variables.editedTagModel.title,
              color: $ctrl.tableOptions.templateScope.variables.editedTagModel.color
            }).then(function (res) {
              var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id });
              var tagSourceData = _.find(tagsData, { id: id });
              angular.extend(tag, res.data);
              angular.extend(tagSourceData, res.data);
              $ctrl.tableOptions.templateScope.variables.editedId = void 0;
              $ctrl.tableOptions.templateScope.variables.editedTagModel = void 0;
              $ctrl.oldEditedTagModel = void 0;
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @description Cancel edit mode
           * @returns {void}
           */
          function cancel() {
            var editedId = $ctrl.tableOptions.templateScope.variables.editedId;
            $ctrl.tableOptions.templateScope.variables.editedId = void 0;
            $ctrl.tableOptions.templateScope.variables.editedTagModel = void 0;
            if (editedId) {
              var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: editedId });
              angular.extend(tag, $ctrl.oldEditedTagModel);
              $ctrl.oldEditedTagModel = void 0;
            }
          }

          /**
           * @function
           * @name showAddNewTagView
           * @description Show add new tag section
           * @returns {void}
           */
          function showAddNewTagView() {
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.addNewTagView = !$ctrl.addNewTagView;
          }

          /**
           * @function
           * @name onColorChanged
           * @description color code change
           * @param {Object} tag
           * @returns {void}
           */
          function onColorChanged(id) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id });
            if (_.isEmpty(tag.color)) {
              tag.color = '#';
            }
          }

          /**
           * @function
           * @name validColor
           * @description Check Color Code valid
           * @param {String} tagColor
           * @returns {boolean}
           */
          function validColor(id) {
            var tagColor = (_.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {}).color || $ctrl.tableOptions.templateScope.variables.tagDefault;
            if (tagColor && tagColor !== '#') {
              tagColor = tagColor ? tagColor : $ctrl.tableOptions.templateScope.variables.tagDefault;
              var color = tinycolor(tagColor);
              return color.isValid();
            }
            return false;
          }

          function getTagData(args) {
            angular.extend($ctrl.tagPagination, args);
            var defer = $q.defer();
            var params = {
              action: 'tags',
              sort: $ctrl.tagPagination.sort,
              page: $ctrl.tagPagination.page,
              per_page: $ctrl.tagPagination.per_page,
              q: $ctrl.tagPagination.q,
              with: 'statistics',
              auto_generated: $ctrl.autoGenerated === 'no' ? false : null
            };
            $ctrl.isLoading = true;
            TagsService.get(params)
              .then(function (res) {
                angular.extend($ctrl.tagPagination, res.meta.pagination);
                tagsData = prepareUIGridOptions(res.data);
                $ctrl.isLoading = false;
                $rootScope.$emit('COUNT_UPDATE', { type: 'tags', count: $ctrl.tagPagination.total });
                defer.resolve(tagsData);
              }, function () {
                $ctrl.isLoading = false;
                defer.reject();
              });
            return defer.promise;
          }

          function prepareUIGridOptions(items) {
            var data = _.filter(items, function (tag) {
              tag.title = tag.title.replaceAll(/#/g, '');
              return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
            });
            return data;
          }

          function onEditTag(id) {
            if ($ctrl.tableOptions.templateScope.variables.editedId === id) {
              var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {};
              angular.extend(tag, $ctrl.oldEditedTagModel);
              $ctrl.tableOptions.templateScope.variables.editedId = void 0;
              $ctrl.tableOptions.templateScope.variables.editedTagModel = void 0;
              $ctrl.oldEditedTagModel = void 0;
            } else {
              cancel();
              var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {};
              angular.extend(tag, $ctrl.oldEditedTagModel);
              $ctrl.tableOptions.templateScope.variables.editedId = tag.id;
              $ctrl.tableOptions.templateScope.variables.editedTagModel = angular.copy(_.find(tagsData, { id: tag.id }));
              $ctrl.oldEditedTagModel = angular.copy($ctrl.tableOptions.templateScope.variables.editedTagModel);
              if (!$ctrl.tableOptions.templateScope.variables.editedTagModel.color) {
                $ctrl.tableOptions.templateScope.variables.editedTagModel.color = $ctrl.tableOptions.templateScope.variables.tagDefault;
              }
            }
          }

          function setTagTextColor(id) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {};
            return TagsService.isTagTextColor(tag.color);
          }

          function getRandomColorCode(id) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {},
              randomColor = TagsService.getRandomColorCode();
            if ($ctrl.tableOptions.templateScope.variables.editedId !== id) {
              onEditTag(id);
            }
            $ctrl.tableOptions.templateScope.variables.editedTagModel.color = randomColor;
            tag.color = randomColor;
          }

          function resetColor(uid) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            $ctrl.tableOptions.templateScope.variables.editedTagModel.color = $ctrl.tableOptions.templateScope.variables.tagDefault;
            angular.extend(tag, $ctrl.tableOptions.templateScope.variables.editedTagModel);
          }

          function getBackgroundColor(uid) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            return $ctrl.tableOptions.templateScope.callbacks.validColor(tag.id) ? tag.color : $ctrl.tableOptions.templateScope.variables.tagDefault;
          }

          function getEditedModelBackgroundColor(id) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: id }) || {};
            return $ctrl.tableOptions.templateScope.callbacks.getBackgroundColor(tag.uid);
          }

          function getTagTitle(uid) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            return tag.title;
          }

          function editedModelChanged(uid) {
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            angular.extend(tag, $ctrl.tableOptions.templateScope.variables.editedTagModel);
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function onTinyColorChanged(uid) {
            var color = tinycolor($ctrl.tableOptions.templateScope.variables.editedTagModel.color);
            if (_.isEmpty($ctrl.tableOptions.templateScope.variables.editedTagModel.color)) {
              return;
            }
            if (!color.isValid()) {
              return;
            }
            var tag = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            $ctrl.tableOptions.templateScope.variables.editedTagModel.color = color.toHexString().toUpperCase();
            angular.extend(tag, $ctrl.tableOptions.templateScope.variables.editedTagModel);
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: TagsTableService.getTagsTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'title',
                  title: $filter('translate')('global.tag.label.tagTitle'),
                  initState: true
                }, {
                  field: 'color',
                  title: $filter('translate')('global.tag.label.tagColor'),
                  initState: true
                }, {
                  field: 'activeProcesses',
                  title: $filter('translate')('global.tag.label.activeProcesses'),
                  initState: true
                }, {
                  field: 'activeBlueprints',
                  title: $filter('translate')('global.tag.label.activeTemplates'),
                  initState: true
                }, {
                  field: 'archivedProcesses',
                  title: $filter('translate')('global.tag.label.archiveProcesses'),
                  initState: true
                }, {
                  field: 'archivedBlueprints',
                  title: $filter('translate')('global.tag.label.archiveTemplates'),
                  initState: true
                }]
              },
              tableFilterModel: {
                title: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  onEditTag: onEditTag,
                  validColor: validColor,
                  getTagTitle: getTagTitle,
                  cancel: cancel,
                  updateTag: updateTag,
                  onColumnFilter: function (column) {
                    if (column !== 'title') {
                      return;
                    }
                    $ctrl.tagPagination = { per_page: 10, page: 1 };
                    $ctrl.tableOptions.tableState.sort = {};
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getTagData(
                      { q: $ctrl.tableOptions.templateScope.variables.searchParams.q }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  sortTable: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    if ($ctrl.tableOptions.tableState.sort.field !== column) {
                      $ctrl.tableOptions.tableState.sort.dir = 'asc';
                      $ctrl.tableOptions.tableState.sort.field = column;
                    } else {
                      if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                        $ctrl.tableOptions.tableState.sort = {};
                      } else {
                        $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                        $ctrl.tableOptions.tableState.sort.field = column;
                      }
                    }
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getTagData(
                      { sort: !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '') + column : void 0 }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  editedModelChanged: editedModelChanged,
                  setTagTextColor: setTagTextColor,
                  getRandomColorCode: getRandomColorCode,
                  onColorChanged: onColorChanged,
                  onTinyColorChanged: onTinyColorChanged,
                  resetColor: resetColor,
                  getBackgroundColor: getBackgroundColor,
                  getEditedModelBackgroundColor: getEditedModelBackgroundColor,
                  deleteTag: deleteTag
                },
                variables: {
                  editedId: void 0,
                  deletedUid: void 0,
                  editedTagModel: void 0,
                  tagDefault: TAG.TAGCOLOR,
                  searchParams: {}
                }
              }
            }
          }

          /**
           * @function
           * @name onAutoGeneratedTags
           * @description Show auto generated tags
           * @returns {void}
           */
          function onAutoGeneratedTags() {
            blockUI.start();
            getTagData({ page: 1 }).then(function (res) {
              AccountService.setUserPreference({
                slug: 'auto_generated_filter',
                value: $ctrl.autoGenerated
              }).then(function (response) {
                preferencesStore.updatePreferences(response.data);
                $rootScope.$emit('COUNT_UPDATE', { type: 'tags', count: $ctrl.tagPagination.total });
              }, function () { });
              blockUI.stop();
              $ctrl.tableOptions.gridConfig.dataSource.data(res);
            }, function () { blockUI.stop(); });
          }
        }
    });
})();
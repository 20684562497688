/**
 * @ngdoc Component
 * @name tallyfy.quickActions
 * @module tallyfy
 *
 * @description A component to trigger certain actions based on URL
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('quickActions', {
      template: '',
      controller:
        /*@ngInject*/
        function (QuickActionsService, $stateParams, $state) {
          var $ctrl = this, acceptableQuickActionTypes = QuickActionsService.getAcceptableQuickActionTypes();

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @function
           * @name initialization
           * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            var actionType = $stateParams.actionType;
            if (actionType) {
              var actionConfig = acceptableQuickActionTypes[actionType];
              if (actionConfig && actionConfig.isApplicableForCurrentAccount()) {
                // Redirect to dashboard instead of initiating the actions here via `actionConfig.actionHandler();` - this will be triggered at `dashboard` state
                $state.go('dashboard', { triggerQuickActionSlug: actionType }, { location: 'replace' });
              } else {
                $state.go('dashboard', null, { location: 'replace' });
              }
            } else {
              $state.go('dashboard', null, { location: 'replace' });
            }
          }

          /**
           * @function
           * @name onChanges
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
        }
    });
})();

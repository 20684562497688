
/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportView
 * @module tallyfy.support
 *
 * @description
 * A component to manage support view
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportViewRoles', {
      templateUrl: 'app/modules/support/components/view/support-view-role.html',
      controller:
        /*@ngInject*/
        function ($stateParams, blockUI, RolesService) {
          // private method
          var $ctrl = this,
            blockUI = blockUI.instances.get('supportView');

          // angular controller method lifecycle
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            $ctrl.user_org = $stateParams.user_org;
            $ctrl.accordionControls = {};
            getRoles();
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name getRoles
           * 
           * @description
           * set all roles
           */
          function getRoles() {
            blockUI.start();
            RolesService.all({
              org_id: $stateParams.org_id,
              with: 'users'
            }).then(function (response) {
              $ctrl.roles = response.data;
              blockUI.stop();
            })
          }
        }
    });
})();

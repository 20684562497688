/**
 * @ngdoc Config
 * @name tallyfy.notifications.config.NotificationsStateConfig
 * @module tallyfy.notifications
 *
 * @description
 * set route for notifications list Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.notifications')
    .config(NotificationsStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  NotificationsStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * NotificationsStateConfig construct 
   */
  function NotificationsStateConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/notifications', '/notifications/list')
    $urlRouterProvider.when('/notifications/', '/notifications/list')
    
    $stateProvider.state('notificationsList', {
      parent: 'app',
      absolute: true,
      url: '/:org_id/notifications',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'notifications',
          resolve : {
            layout : function(){
              return 'list';
            }
          }
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('notifications');
          return $translate.refresh();
        }]
      }
    });
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('koFormStep', {
      templateUrl: 'app/modules/steps/koFormStep/ko-form-step.component.html',
      bindings: {
        process: '=',
        oldProcess: '=',
        isKoStepExpanded: '=',
        onKoStepDelete: '&'
      },
      require: {
        stepsCtrl: '^steps'
      },
      controller:
        /*@ngInject */
        function (_, $rootScope, Helper, $filter, $q, DESCRIPTIONSIZE, $sce, koStepService, $confirm, StepService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onDescriptionSave = onDescriptionSave;
          $ctrl.deleteKOStep = deleteKOStep;
          $ctrl.onDiscardDescription = onDiscardDescription;
          $ctrl.onKOStepTitleClick = onKOStepTitleClick;
          $ctrl.onReadOnlyStepTitleClick = onReadOnlyStepTitleClick;
          $ctrl.onEditableTitleEventHandler = onEditableTitleEventHandler;

          /**
           * @ngdoc method
           * @name onInit
           * 
           * @description
           * initialization component handler
           */
          function onInit() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              initOnClick: !$ctrl.process.kickoff_description,
              placeholder: $filter('translate')('steps.koFormStep.content.editorPlaceholder'),
              currentUser: $rootScope.identity,
              accessibility: {
                haveAuthority: $ctrl.haveAuthority
              },
              enableFullscreen: true,
            });
            $ctrl.froalaOptions.allowEdit = $ctrl.haveAuthority;
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
            $ctrl.maxTitle = DESCRIPTIONSIZE.maxTitle;
            $ctrl.htmlPopoverKoStepHelpText = $sce.trustAsHtml($filter('translate')('steps.koFormStep.header.questionTooltipText'));
          }

          /**
           * @ngdoc method
           * @name onChanges
           * 
           * @description
           * onChanges component public variables handler
           */
          function onChanges(changes) {
            if (changes.isKoStepExpanded) {
              if (changes.isKoStepExpanded.currentValue !== changes.isKoStepExpanded.previousValue) {
                $ctrl.editableTitleMode = false;
              }
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * destroy component handler
           */
          function onDestroy() {
            processUpdatedHandler();
            onBPDescOpenEventHandler();
            onStepOpenEventHandler();
          }

          /**
           * @ngdoc method
           * @name onSaveChanges
           * @param {*} isDeleteAction
           * 
           * @description
           * save ko form step 
           */
          function onSaveChanges() {
            var deferred = $q.defer();
            koStepService.saveKOStep($ctrl.process).then(function (response) {
              $ctrl.froalaOptions.initOnClick = !$ctrl.process.kickoff_description;
              deferred.resolve(response);
            }, function (error) {
              deferred.reject(error);
            });
            return deferred.promise;
          }

          /**
           * @ngdoc method
           * @name deleteKOStep
           * 
           * @description
           * delete ko step handler
           */
          function deleteKOStep() {
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_description = '';
            $ctrl.process.prerun = [];
            onSaveChanges().then(function (process) {
              $ctrl.process = process;
              $ctrl.onKoStepDelete();
            });
          }

          /**
           * @ngdoc method
           * @name onEditableTitleEventHandler
           * @param {*} e 
           * 
           * @description
           * on title click editable
           */
          function onEditableTitleEventHandler(e) {
            (e)
              ? ((e.type === 'click')
                ? e.stopPropagation() : (e.type === 'blur')
                  ? onDescriptionSave() : angular.noop()) : onDescriptionSave();
          }

          /**
           * @ngdoc method
           * @name onDescriptionSave
           * 
           * @description
           * on description save
           */
          function onDescriptionSave() {
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
            $ctrl.onSaving = true;
            onSaveChanges().then(function () {
              $ctrl.oldProcess.kickoff_description = $ctrl.process.kickoff_description;
              if ($ctrl.descriptionForm) {
                $ctrl.descriptionForm.$setPristine();
              }
              $ctrl.onSaving = false;
              $ctrl.editableTitleMode = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name onDiscardDescription
           * 
           * @description
           * on discard description
           */
          function onDiscardDescription() {
            $ctrl.process.kickoff_description = $ctrl.oldProcess.kickoff_description;
            if ($ctrl.descriptionForm) {
              $ctrl.descriptionForm.$setPristine();
            }
          }

          /**
           * @ngdoc method
           * @name onKOStepTitleClick
           * @param {*} e 
           * 
           * @description
           * ok ko step title click
           */
          function onKOStepTitleClick(e) {
            e.stopPropagation();
            $ctrl.editableTitleMode = $ctrl.isKoStepExpanded = true;
          }

          /**
           * @ngdoc method
           * @name showConfirmModal
           * 
           * @description
           * show confirmation unsaved changes modal
           */
          function showConfirmModal(e, args) {
            $confirm({
              'body': 'global.unsaved.body',
              'header': 'global.unsaved.header',
              'buttons': {
                'accept': 'global.unsaved.buttons.accept',
                'cancel': 'global.unsaved.buttons.cancel'
              },
              'modalType': 'modal-blueprint'
            }).then(
              function () {
                onSaveChanges()
                  .then(
                    function (response) {
                      angular.extend($ctrl.process, response);
                      $ctrl.oldProcess = angular.copy($ctrl.process);
                      $ctrl.isKoStepExpanded = !$ctrl.isKoStepExpanded;
                      $ctrl.editableTitleMode = false;
                      $ctrl.stepsCtrl.checkTriggerFrom(e.name, args);
                    }
                  );
              }, function () {
                koStepService.discardKOFormStep($ctrl.process, $ctrl.oldProcess);
                $ctrl.isKoStepExpanded = $ctrl.editableTitleMode = false;
                $ctrl.stepsCtrl.checkTriggerFrom(e.name, args);
              });
          }

          /**
           * @ngdoc method
           * @name onReadOnlyStepTitleClick
           * @param {*} e 
           * 
           * @description
           * handler for step title click
           */
          function onReadOnlyStepTitleClick(e, data) {
            if (e.type === 'keypress') {
              return;
            }
            if (e.type) {
              e.stopPropagation();
            }
            if ($ctrl.isKoStepExpanded) {
              var isKOStepDirty = koStepService.isKOFormDirty($ctrl.process, $ctrl.oldProcess);
              if (isKOStepDirty) {
                showConfirmModal(e, data);
              } else {
                $ctrl.isKoStepExpanded = !$ctrl.isKoStepExpanded;
                $ctrl.editableTitleMode = false;
              }
            } else {
              var ableToExpand = StepService.isAnyDirtyStepExpanded($ctrl.stepsCtrl.selectedStep)
                && !_.get($ctrl.stepsCtrl, 'bpDescriptionForm.$dirty', false);
              if (!ableToExpand) {
                $rootScope.$emit('KO_STEP:OPEN');
              }
              else {
                $ctrl.isKoStepExpanded = !$ctrl.isKoStepExpanded;
                $ctrl.editableTitleMode = false;
              }
            }
          }

          /**handler for process if updated */
          var processUpdatedHandler = $rootScope.$on('PROCESS:UPDATED', function (e, data) {
            $ctrl.oldProcess.prerun = angular.copy(data.process.prerun);
          });

          var onBPDescOpenEventHandler = $rootScope.$on('BP_DESC:OPEN', function (e) {
            if ($ctrl.isKoStepExpanded) {
              onReadOnlyStepTitleClick(e);
            }
          });

          var onStepOpenEventHandler = $rootScope.$on('STEP:OPEN', function (e, data) {
            if ($ctrl.isKoStepExpanded) {
              onReadOnlyStepTitleClick(e, data);
            }
          });
        }
    })
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('folderMenuItem', {
      templateUrl: 'app/modules/process/components/library/folderMenuItem/folder-menu-item.component.html',
      bindings: {
        item: '<',
        isLoading: '=',
        parentBlueprint: '<',
        dropSelect: '<?',
        activeFolder: '<?'
      },
      controller:
        /*@ngInject*/
        function ($rootScope) {
          var $ctrl = this,
          folderTree = angular.element('.root-parent');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onMenuItemClick = onMenuItemClick;
          $ctrl.onScrollLeft = onScrollLeft;

          function onInit() { }

          function onChanges() { }

          function onDestroy() { }

          function onScrollLeft() {
            $ctrl.isExpanded = !$ctrl.isExpanded;
            if ($ctrl.isExpanded) {
              folderTree.animate({
                scrollLeft: "+=20px"
              }, "slow");
            } else {
              folderTree.animate({
                scrollLeft: "-=10px"
              }, "slow");  
            }
          }

          function onMenuItemClick(folder) {
            $ctrl.dropSelect ? $rootScope.$emit('FOLDER:SELECTED', { folder: folder })
              : $rootScope.$emit('FOLDER:CLICK', { blueprint: $ctrl.parentBlueprint, folder: folder });
          }
        }
    });
})();
/**
 * @ngdoc overview
 * @name tallyfy.analytics
 *
 * @module tallyfy.analytics
 *
 * @description
 * manage executable instances of analytics component
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy.analytics', []);
})();

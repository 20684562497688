/**
 * @ngdoc Component
 * @name tallyfy.organizations.access.Component.group
 * @module tallyfy.organizations.access
 *
 * @description
 * view group
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.access')
    .component('group', {
      bindings: {
        group: '<'
      },
      require: {
        groupsCtrl: '^groups'
      },
      templateUrl: 'app/modules/access/groups/group/group.html',
      controller: [
        '_',
        '$log',
        'GroupService',
        'blockUI',
        function (_, $log, GroupService, blockUI) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('groups');
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.setEditable = setEditable;

          /**
           * public properties
           */

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.hasOwnProperty('group') && bindings.group.currentValue) {
              $ctrl.users_name = _.map(bindings.group.currentValue.users, 'text');
            }
          }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc
           * @name setEditable
           * @description enable group edit mode
           * @param {any} $event 
           */
          function setEditable($event) {
            if ($event) {
              $event.preventDefault();
            }
            for (var index = 0; index < $ctrl.groupsCtrl.groups.length; index++) {
              if ($ctrl.groupsCtrl.groups[index]['editable']) {
                delete $ctrl.groupsCtrl.groups[index]['editable'];
                break;
              }
            }
            $ctrl.group.editable = true;
          }

          //end of controller
        }]
    });
})();

/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.orgGeneralInfo
 * @module tallyfy.organizations
 *
 * @description
 * A Component to manager genenal info of organization
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('orgGeneralInfo', {
      templateUrl: 'app/modules/organizations/generalInfo/general-info.html',
      bindings: {
      },
      require: {
      },
      controller: [
        '_',
        'blockUI',
        '$log',
        '$locale',
        'Growl',
        '$rootScope',
        'OrganizationsService',
        '$filter',
        function (_, blockUI, $log, $locale, Growl, $rootScope, OrganizationsService, $filter) {
          var $ctrl = this,
            growl = new Growl('manageOrg'),
            blockUI = blockUI.instances.get('manageOrg'),
            tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.organization = angular.copy($rootScope.identity.default_organization);

          /**
          * public methods
          */
          $ctrl.updateOrg = updateOrg;
          $ctrl.saveOrgChanges = saveOrgChanges;
          $ctrl.updateHolidays = updateHolidays;
          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            if (_.isEmpty($ctrl.organization.settings)) {
              $ctrl.organization.settings = {
                determine_deadline: {
                  workdays: 0,
                  holidays: 1
                },
                workdays: [
                ],
                holidays: [
                ]
              };
            }
            var workDays = $ctrl.organization.settings.workdays || [];
            $ctrl.workingDays = _.map($locale.DATETIME_FORMATS.DAY, function (day) {
              return {
                day: day,
                isChecked: _.indexOf(workDays, day) >= 0
              };
            });
          }
          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges(bindings) {
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function updateOrg() {
            var requestPayload, workingDays;
            requestPayload = angular.copy($ctrl.organization);
            workingDays = _.chain($ctrl.workingDays)
              .filter(function (day) {
                return day.isChecked;
              })
              .map(function (o) {
                return o.day;
              })
              .value();
            angular.extend(requestPayload.settings, {
              workdays: workingDays
            });
            saveOrgChanges(requestPayload);
          }

          function saveOrgChanges(requestPayload) {
            blockUI.start();
            OrganizationsService
              .update({
                id: requestPayload.id
              }, requestPayload)
              .then(function (successResponse) {
                $ctrl.generalInfoForm.$setDirty(false);
                $ctrl.generalInfoForm.$setPristine(true);
                growl.success($filter('translate')('org_settings.messages.success'));
                blockUI.stop();
              }, function (error) {
                blockUI.stop();
                $log.error('updateHolidays - error', error);
              });
          }

          function updateHolidays(holidays) {
            $ctrl.generalInfoForm.$setDirty(true);
            $log.info('updateHolidays - holidays are updated', holidays);
            angular.extend($ctrl.organization.settings, {
              holidays: holidays
            });
          }

        }]
    });

})();
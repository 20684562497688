/**
 * @ngdoc Component
 * @name tallyfy.settings.component.myAccount
 * @module tallyfy.settings
 *
 * @description
 * myAccount components updates current's accounts settings
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  var myAccount = {
    template: '<div ng-include="$ctrl.templateUrl"></div>',
    controller: 'MyAccountController'
  };

  angular
    .module('tallyfy.settings')
    .component('myAccount', myAccount);
})();
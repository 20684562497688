(function (window, angular, undefined) {
  'use strict';

  var $global = null;

  /**
   * @ngdoc overview
   * @name angulartics.mixpanel
   * Enables analytics support for Mixpanel (http://mixpanel.com)
   */
  angular.module('angulartics.mixpanel', ['angulartics'])
    .config(['$analyticsProvider', function ($analyticsProvider) {
      function extendProps(properties) {
        var organization = $global.$rootScope.userState === 'GUEST'
          ? $global._.get($global.$rootScope, 'guest.organization', {})
          : $global._.get($global.$rootScope, 'identity.default_organization', {});
        return angular.extend(properties || {}, { orgId: organization.id, orgName: organization.name });
      }
      angulartics.waitForVendorApi('mixpanel', 500, '__loaded', function (mixpanel) {
        $analyticsProvider.registerSetUsername(function (userId) {
          mixpanel.identify(userId);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.identify(userId);
          }
        });
        $analyticsProvider.registerSetAlias(function (userId) {
          mixpanel.alias(userId);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.alias(userId);
          }
        });
        $analyticsProvider.registerSetSuperPropertiesOnce(function (properties) {
          var props = extendProps(properties);
          mixpanel.register_once(props);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.register_once(props);
          }
        });
        $analyticsProvider.registerSetSuperProperties(function (properties) {
          var props = extendProps(properties);
          mixpanel.register(props);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.register(props);
          }
        });
        $analyticsProvider.registerSetUserPropertiesOnce(function (properties) {
          var props = extendProps(properties);
          mixpanel.people.set_once(props);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.people.set_once(props);
          }
        });
        $analyticsProvider.registerSetUserProperties(function (properties) {
          var props = extendProps(properties);
          mixpanel.people.set(props);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.people.set(props);
          }
        });
        $analyticsProvider.registerPageTrack(function (path) {
          var props = extendProps({ page: path });
          mixpanel.track("Page Viewed", props);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.track("Page Viewed", props);
          }
        });
        $analyticsProvider.registerEventTrack(function (action, properties, callback) {
          var props = extendProps(properties);
          mixpanel.track(((props && props.label) ? props.label : action), props, callback);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.track(((props && props.label) ? props.label : action), props, callback);
          }
        });
        $analyticsProvider.registerIncrementProperty(function (property, value) {
          if (typeof value === 'undefined') {
            mixpanel.people.increment(property);
            if (mixpanel.organization_mixpanel_project) {
              mixpanel.organization_mixpanel_project.people.increment(property);
            }
          } else {
            mixpanel.people.increment(property, value);
            if (mixpanel.organization_mixpanel_project) {
              mixpanel.organization_mixpanel_project.people.increment(property, value);
            }
          }
        });
        $analyticsProvider.registerUserTimings(function (action, properties) {
          mixpanel.time_event(action);
          if (mixpanel.organization_mixpanel_project) {
            mixpanel.organization_mixpanel_project.time_event(action);
          }
        });
      });
    }]
    ).run(
      /*@ngInject*/
      function (_, $rootScope) {
        $global = {
          _: _,
          $rootScope: $rootScope
        };
      }
    );
})(window, window.angular);

(function () {
  'use strict';
  angular
    .module('tallyfy.public')
    .factory('PublicLibraryRepository', publicLibraryRepository)

  /*@ngInject*/
  function publicLibraryRepository(BaseRepository, CONFIG) {
    var repository = BaseRepository('/public/library/:action/:action_id/:sub_action/:sub_action_id/:param1', {
      org: 'org_id',
      action: '@action',
      action_id: '@action_id',
      sub_action: '@sub_action',
      sub_action_id: '@sub_action_id',
      param1: '@param1'
    }, {
      $all: { method: 'GET' },
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' },
      getPublicLibraryList: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/checklists',
        params: {
          org: '@org',
          guest_code: '@guest_code'
        }
      },
      getPublicLibraryById: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/checklists/:checklist_id',
        params: {
          org: '@org',
          guest_code: '@guest_code',
          checklist_id: '@checklist_id'
        }
      }
    });
    return repository;
  }
})();
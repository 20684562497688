/**
 * @ngdoc component
 * @name tallyfy.compactStepList
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactStepList', {
      templateUrl: 'app/components/compact/components/stepList/compact-step-list.component.html',
      bindings: {
        process: '<',
        isFormTag: '<',
        selectedStep: '=',
        usersInOrg: '<',
        processDescriptionForm: '<?',
        isPublic: '<',
        publicOrganization: '<',
        orgGroups: '<?',
        menuClickHandler: '&',
        tocFocusElementId: '<',
        roles: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $window, $scope, $timeout, StepService, Helper, DOMService, ProcessService, BLUEPRINT_TYPE, $filter, CompactStepService, AuthPlan, $uibModal, $state, $stateParams, $analytics, MilestoneService) {
          var $ctrl = this,
            publicOrganizationWatcher,
            firstSort = true,
            firstStageSort = true,
            documentVariablesAttachedWatcherHandler,
            documentSnippetsAttachedWatcherHandler,
            documentBlueprintsAttachedWatcherHandler,
            unregisterOnStepDuplicate,
            stepStageLinkedWatcher,
            stepStageUnlinkedWatcher,
            stageCreatedWatcher,
            stepDeletedWatcher,
            stepStageUpdatedWatcherHandler,
            oldDocumentSummary,
            windowElem = angular.element($window),
            selectFieldWatcher,
            froalaWrapperHeightHandler;

          // angular life cycle hook
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public methods
          $ctrl.onStepCreated = onStepCreated;
          $ctrl.onProcessDescriptionAdd = onProcessDescriptionAdd;
          $ctrl.onKOFormAdd = onKOFormAdd;
          $ctrl.saveDocumentDescription = saveDocumentDescription;
          $ctrl.openVideoModal = openVideoModal;
          $ctrl.tocMenuItemClick = tocMenuItemClick;
          $ctrl.toggleTocView = toggleTocView;
          $ctrl.toggleDocumentEditor = toggleDocumentEditor;
          $ctrl.generateStepSuggestion = generateStepSuggestion;
          $ctrl.renameStage = renameStage;
          $ctrl.deleteStage = deleteStage;

          $ctrl.tableOfContentElements = ['h1', 'h2', 'h3', 'h4'];
          $ctrl.compactStepViewConfig = {
            mainSection: '.main-section',
            rightSection: {
              className: '.right-section'
            },
            viewContainer: '.app-body'
          };
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.documentPaneExpanded = false;
          $ctrl.editorSelectedField = void 0;
          $ctrl.documentVariables = [];
          $ctrl.documentSnippets = [];
          $ctrl.documentBlueprints = [];
          $ctrl.stageSteps = [];
          $ctrl.stageCollpaseOpen = {};
          $ctrl.stageMenu = {};
          $ctrl.showTOC = true;

          /**
           * @ngdoc methods
           * @name onInit
           *
           * @description
           * angular life cycle hook
           */
          function onInit() {
            $ctrl.tocActiveTab = 'formFields';
            $ctrl.showDescPanel = !ProcessService.isDescriptionEmptyForHuman($ctrl.process.summary);
            $ctrl.sortableOptions = getSortableOptions();
            $ctrl.stageSortableOptions = getStageSortableOptions();
            processDataUpdate($ctrl.process);
            oldDocumentSummary = $ctrl.process.summary;
            $ctrl.froalaOptions = angular.extend({
              skipBlueprintId: $ctrl.process.id
            }, Helper.getFroalaOptions({
              spellcheck: $ctrl.isProcessHavingDocumentType,
              allowEdit: Helper.checkAccessAuthority(false) && !$ctrl.isPublic,
              autofocus: false,
              toolbar: CompactStepService.getBlueprintFroalaToolbar($ctrl.isProcessHavingDocumentType),
              placeholder: $filter('translate')('steps.bpeDescStep.editorPlaceholder'),
              currentUser: $rootScope.identity,
              heightMin: 650,
              heightMax: 'auto',
              tableOfContentElements: $ctrl.tableOfContentElements,
              qAutoSave: {
                callback: $ctrl.saveDocumentDescription,
                debounce: 2000
              },
              confirmBeforeBrowserRefresh: true
            }, true));
            if ($ctrl.isPublic) {
              publicOrganizationWatcher = $rootScope.$watch('publicOrganization', function (value) {
                if (value) {
                  $ctrl.publicOrganization = value;
                }
              });
            }
            var stage = _.find($ctrl.process.stages.data, function (stage) {
              return stage.id == $ctrl.selectedStep.step.stage_id;
            });
            if (!!stage) {
              $timeout(function () {
                $rootScope.$emit('STAGE:EXPAND_ELEMENT', { stage: stage, hideSuccessUA: true });
              }, 350);
            }
          }

          /**
           * @ngdoc processDataUpdate
           * @name process
           * @param {Object} process
           * @description
           * on add new tag update process value
           */
          function processDataUpdate(process) {
            if ($ctrl.process.id !== process.id) return;
            $ctrl.isProcessHavingFormType = _.get(process, 'type') === BLUEPRINT_TYPE.FORM;
            $ctrl.isProcessHavingDocumentType = _.get(process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.isProcessHavingProcedureType = _.get(process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
            $ctrl.compactStepViewConfig.type = $ctrl.isProcessHavingDocumentType ? 'document' : ($ctrl.isProcessHavingFormType ? 'form' : 'procedure');
            angular.extend($ctrl.process, process);
            if ($stateParams.step === 'process-description') {
              onProcessDescriptionAdd();
            }
            if ($ctrl.isProcessHavingFormType || $stateParams.step === 'ko-step') {
              onKOFormAdd();
            }
            if ($ctrl.isProcessHavingDocumentType) {
              var isMobileView = !($window.innerWidth > 991);
              $ctrl.toggleDocumentEditor(isMobileView);
            }
          }

          /**
           * @ngdoc onChanges
           * @name changes
           * @param {Object} changes
           * @description
           * angular life cycle hook
           */
          function onChanges(changes) {
            if (changes.process && changes.process.currentValue) {
              $ctrl.steps = changes.process.currentValue.steps.data;
              $ctrl.isNewBP = !$ctrl.steps.length;
              mapStageSteps();
            }
            if (changes.tocFocusElementId && changes.tocFocusElementId.currentValue) {
              $ctrl.showTOC = true;
            }
          }

          /**
           * @ngdoc onDestroy
           *
           * @description
           * angular life cycle hook
           */
          function onDestroy() {
            unregisterOnStepDuplicate();
            rightPaneCloseHandler();
            rightPaneDocumentBuilderCloseHandler();
            addNewTagHandler();
            if (publicOrganizationWatcher) {
              publicOrganizationWatcher();
            }
            documentVariablesAttachedWatcherHandler();
            documentSnippetsAttachedWatcherHandler();
            documentBlueprintsAttachedWatcherHandler();
            stepStageLinkedWatcher();
            stepStageUnlinkedWatcher();
            stageCreatedWatcher();
            stepDeletedWatcher();
            selectFieldWatcher();
            stepStageUpdatedWatcherHandler();
            froalaWrapperHeightHandler ? froalaWrapperHeightHandler() : angular.noop();
          }

          function mapStageSteps() {
            if ($ctrl.isPublic) {
              $ctrl.unMappedSteps = $ctrl.steps;
            } else {
              $ctrl.stageSteps = [];
              $ctrl.unMappedSteps = [];
              var stagesData = _.orderBy(_.get($ctrl.process, 'stages.data', []), 'position', 'asc')
              for (var i = 0; i < stagesData.length; i++) {
                $ctrl.stageSteps.push({
                  stage: stagesData[i],
                  startIndex: $ctrl.stageSteps[i - 1] ? $ctrl.stageSteps[i - 1].startIndex + $ctrl.stageSteps[i - 1].steps.length : 0,
                  steps: _.filter($ctrl.steps, function (step) {
                    return !step.stage_id ? false : step.stage_id.toString() === stagesData[i].id.toString();
                  })
                });
              }
              $ctrl.stageSteps = _.orderBy($ctrl.stageSteps, 'stage.position', 'asc');
              $ctrl.unMappedSteps = _.orderBy(_.filter($ctrl.steps, { stage_id: null }), 'position', 'asc');
            }
          }

          /**
           * @ngdoc method
           * @name onStepCreated
           * @param {*} step
           *
           * @description
           * on step created method
           */
          function onStepCreated(step, duplicate) {
            if (!step) {
              return;
            }
            $ctrl.steps.push(step);
            if (duplicate) {
              $rootScope.$emit('STEP:LINK_STAGE', { step: step, stageId: step.stage_id, newStep: true });
              mapStageSteps();
            }
            $rootScope.$emit('STEPS:ADD', { process: $ctrl.process });
            $rootScope.$emit("STEP:UPDATE_DEADLINE", step);
            $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
            $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'checklist' });
            if ($ctrl.steps.length === 1) {
              StepService.openRightPaneOnFirstStepCreate($ctrl.process, step);
            }
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', {});
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                index: $ctrl.steps.length - 1,
                step: step,
                editStepTitle: true
              }
            });
          }

          /**
           * @ngdoc method
           * @name onProcessDescriptionAdd
           *
           * @description
           * on process description added/click
           */
          function onProcessDescriptionAdd() {
            if (!$ctrl.process.summary && !Helper.checkAccessAuthority()) {
              return;
            }
            if (ProcessService.isDescriptionEmptyForHuman($ctrl.process.summary)) {
              $ctrl.process.summary = "";
            }
            $ctrl.showDescPanel = true;
            // type of element is 'process-description'
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', {});
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                id: 'process-description',
                process: $ctrl.process
              }
            });
          }

          /**
           * @ngdoc method
           * @name onKOFormAdd
           *
           * @description
           * on ko form added / clicked
           */
          function onKOFormAdd() {
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
            // type of element is 'ko-step'
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                id: 'ko-step',
                process: $ctrl.process
              }
            });
          }

          /**
           * @ngdoc method
           * @name getSortableOptions
           *
           * @description
           * get sortable options
           */
          function getSortableOptions() {
            return {
              handle: '.step-number',
              connectWith: '.step-list',
              scroll: true,
              axis: 'y',
              start: startHandler,
              stop: sortHandler,
              sort: dragHandler,
              receive: receiveHandler,
              animation: 500,
              disabled: !Helper.checkAccessAuthority(false) || $ctrl.isPublic,
              scrollSpeed: 25,
              forceFallback: true,
              longTouch: true
            };
          }

          function getStageSortableOptions() {
            return {
              handle: '.move-icon',
              scroll: true,
              axis: 'y',
              start: startStageHandler,
              stop: sortStageHandler,
              sort: dragStageHandler,
              animation: 500,
              disabled: !Helper.checkAccessAuthority(false) || $ctrl.isPublic,
              scrollSpeed: 25,
              forceFallback: true,
              longTouch: true
            };
          }

          function sortStageHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.stageSteps.length) {
              for (var i = 0; i < $ctrl.stageSteps.length; i++) {
                $ctrl.stageSteps[i].stage.position = i + 1;
              }
            }
            if ($ctrl.stageSteps[ui.item.sortable.dropindex]) {
              MilestoneService.updateMilestone($ctrl.process.id, 'Checklist', $ctrl.stageSteps[ui.item.sortable.dropindex].stage)
                .then(function (res) {
                  Helper.showChangesSavedGrowl();
                })
            }
            mapStageSteps();
          }

          /**
           * @ngdoc method
           * @name dragHandler
           * @param {*} e
           *
           * @description
           * drag handler for sortable list options
           */
          function dragHandler(e) {
            $ctrl.pageY < e.pageY ? $(this).sortable("option", "scrollSensitivity", 10) : $(this).sortable("option", "scrollSensitivity", 208);
            $ctrl.pageY = e.pageY;
          }

          function dragStageHandler(e) {
            $ctrl.pageY < e.pageY ? $(this).sortable("option", "scrollSensitivity", 10) : $(this).sortable("option", "scrollSensitivity", 208);
            $ctrl.pageY = e.pageY;
          }

          /**
           * @ngdoc method
           * @name startHandler
           * @param {*} e
           * @param {*} ui
           *
           * @description
           * start handler for sortable list options
           */
          function startHandler(e, ui) {
            $ctrl.pageY = e.pageY;
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          function startStageHandler(e, ui) {
            $ctrl.pageY = e.pageY;
            if (firstStageSort) {
              $(this).sortable("refreshPositions");
              firstStageSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @ngdoc method
           * @name sortHandler
           * @param {*} e
           * @param {*} ui
           *
           * @description
           * sort handler for sortable list options
           */
          function sortHandler(e, ui) {
            var steps = [];
            if (e.target.dataset.stageId) {
              var stageStep = _.find($ctrl.stageSteps, function (stageStep) {
                return stageStep.stage.id == e.target.dataset.stageId;
              })
              steps = stageStep.steps;
            } else {
              steps = $ctrl.unMappedSteps;
            }
            if (ui.item.sortable.dropindex >= 0 &&
              ui.item.sortable.dropindex < steps.length) {
              if (steps[ui.item.sortable.dropindex - 1]) {
                steps[ui.item.sortable.dropindex].position = steps[ui.item.sortable.dropindex - 1].position + 1;
              } else {
                steps[ui.item.sortable.dropindex].position = 1;
              }
              reorderSteps(steps);
              changedPosition(steps[ui.item.sortable.dropindex]);
            }
          }

          function receiveHandler(e, ui) {
            var steps = [];
            if (ui.item[0].dataset.stageId == e.target.dataset.stageId) {
              if (e.target.dataset.stageId) {
                var stageStep = _.find($ctrl.stageSteps, function (stageStep) {
                  return stageStep.stage.id == e.target.dataset.stageId;
                })
                steps = _.get(stageStep, 'steps', []);
              } else {
                steps = $ctrl.unMappedSteps;
              }
              if (ui.item.sortable.dropindex >= 0 &&
                ui.item.sortable.dropindex < steps.length) {
                reorderSteps(steps);
                changedPosition(steps[ui.item.sortable.dropindex]);
              }
            } else {
              var stage, step, stageStep = null;
              if (e.target.dataset.stageId) {
                stageStep = _.find($ctrl.stageSteps, function (stageStep) {
                  return stageStep.stage.id == e.target.dataset.stageId;
                })
                stage = _.get(stageStep, 'stage', {});;
              }
              if (ui.item[0].dataset.stepId) {
                step = _.find($ctrl.steps, { id: ui.item[0].dataset.stepId });
              }
              linkMilestone(stageStep, step, ui.item.sortable.dropindex);
            }
          }

          function linkMilestone(stageStep, step, pos) {
            if (!stageStep) {
              step.stage_id = null;
              MilestoneService.unLinkStepToMilestone($ctrl.process.id, step.id)
                .then(function (res) {
                  if (pos >= 0 && pos < $ctrl.unMappedSteps.length) {
                    reorderSteps($ctrl.unMappedSteps);
                    changedPosition($ctrl.unMappedSteps[pos]);
                  }
                });
            } else {
              step.stage_id = _.get(stageStep.stage, 'id');
              MilestoneService.linkStepToMilestone($ctrl.process.id, step.id, _.get(stageStep.stage, 'id'))
                .then(function (res) {
                  if (pos >= 0 && pos < stageStep.steps.length) {
                    reorderSteps(stageStep.steps);
                    changedPosition(stageStep.steps[pos]);
                  }
                });
            }
          }

          /**
           * @ngdoc method
           * @name reorderSteps
           *
           * @description
           * reorder step method handler
           */
          function reorderSteps(steps) {
            var i = steps[0].position;
            _.forEach(steps, function (step) {
              step.position = i;
              i++;
            });
          }

          /**
           * @ngdoc method
           * @name changedPosition
           * @param {*} step
           *
           * @description
           * on position changed
           */
          function changedPosition(step) {
            StepService.isValidStepCondition(step);
            $ctrl.isLoading = true;
            var stepUpdateObj = _.omit(angular.copy(step), ['condition']);
            StepService.updateStep({
              id: step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, stepUpdateObj).then(function () {
              $ctrl.isLoading = false;
              $rootScope.$emit('STEP:RE-ORDER');
              $rootScope.$emit("STEP:UPDATE_STEP_POSITION", step);
              $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'checklist' });
              Helper.showChangesSavedGrowl();
            }, function () {
              $ctrl.isLoading = false;
            });
          }

          /**
           * @ngdoc method
           * @name saveDocumentDescription
           * @description
           * process save method
           */
          function saveDocumentDescription() {
            var defer = $q.defer(), payload = {
              title: $ctrl.process.title,
              summary: $ctrl.process.summary
            };
            $ctrl.documentForm ? $ctrl.documentForm.$setPristine() : angular.noop();
            $ctrl.process.isSaving = true;
            if ($ctrl.process.prerun && $ctrl.process.prerun.length && !$ctrl.process.kickoff_title) {
              payload.kickoff_title = $ctrl.process.title;
            }
            if (oldDocumentSummary === $ctrl.process.summary) {
              return;
            }
            ProcessService.update({
              id: $ctrl.process.id
            }, payload).then(function (response) {
              oldDocumentSummary = response.data.summary;
              $ctrl.process.last_updated = response.data.last_updated;
              $ctrl.process.isSaving = false;
              Helper.showChangesSavedGrowl();
              defer.resolve(response);
            }, function (err) {
              $ctrl.process.isSaving = false;
              defer.reject(err);
            });
            return defer.promise;
          }

          /**
           * @function
           * @name openVideoModal
           * @description create video modal
           */
          function openVideoModal() {
            $uibModal.open({
              component: $ctrl.isProcessHavingFormType ? 'createFormVideoModal' : 'createProcedureVideoModal',
              windowClass: 'steps-modal',
              keyboard: false,
              size: 'lg'
            }).result.then(function () { });
          }

          function tocMenuItemClick(menu) {
            $state.transitionTo($state.current, angular.extend($stateParams, { section: menu.nativeElement.id }), { notify: false });
            $timeout(function () {
              $ctrl.tocFocusElementId = menu.nativeElement.id;
            });
          }

          function toggleTocView() {
            $ctrl.showTOC = !$ctrl.showTOC;
            if (!$ctrl.showTOC) {
              $ctrl.editorSelectedField = void 0;
              $rootScope.$emit('DOCUMENT_EDITOR:CLOSE');
              if ($ctrl.tocFocusElementId) {
                $ctrl.tocFocusElementId = void 0;
                $state.transitionTo($state.current, angular.extend($stateParams, { section: void 0 }), { notify: false });
              }
            } else {
              $ctrl.tocActiveTab = 'formFields';
            }
          }

          function toggleDocumentEditor(temp) {
            if (!$ctrl.documentPaneExpanded) {
              if (!temp) {
                $ctrl.documentPaneExpanded = true;
              }
              $rootScope.$emit('DOCUMENT_EDITOR:OPEN', { field: void 0 });
            } else {
              $ctrl.documentPaneExpanded = false;
              $ctrl.editorSelectedField = void 0;
              $ctrl.tocActiveTab = 'formFields';
              $rootScope.$emit('DOCUMENT_EDITOR:CLOSE');
            }
          }

          function generateStepSuggestion(args) {
            if (!Helper.checkAccessAuthority())
              return;
            var defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
            $ctrl.onSaving = true;
            var step = angular.extend(StepService.getStep(), {
              checklist_id: $ctrl.process.id,
              title: args.stepTitle.substring(0, 200).trim(),
              deadline: {
                option: 'from',
                step: 'start_run',
                unit: defaultDeadline ? defaultDeadline.type : "days",
                value: defaultDeadline ? defaultDeadline.value : 5
              },
              step_type: 'task'
            });
            StepService.createStep({
              checklist_id: $ctrl.process.id
            }, step).then(function (response) {
              var step = response.data;
              trackEvent({ label: 'STEP CREATED IN BLUEPRINT', blueprintId: $ctrl.process.id, stepId: step.id, stepName: step.title });
              if (!step.hasOwnProperty('captures')) {
                angular.extend(step, {
                  captures: {
                    data: []
                  }
                });
              }
              $ctrl.onStepCreated(step);
              $rootScope.$emit('STEP:LINK_STAGE', { step: step, newStep: true });
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = { category: Helper.eventTrackingCategories['blueprint'] };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }

          function renameStage(e, stageStep) {
            e.stopImmediatePropagation();
            $ctrl.stageMenu[stageStep.stage.id] = !$ctrl.stageMenu[stageStep.stage.id];
            var modal = $uibModal.open({
              component: 'createMilestone',
              windowClass: 'create-milestone-modal',
              resolve: {
                process: function () {
                  return $ctrl.process;
                },
                stage: function () {
                  return stageStep.stage;
                }
              }
            });

            modal.result.then(function (res) {
              stageStep.stage.title = res.name;
              MilestoneService.updateMilestone($ctrl.process.id, 'Checklist', stageStep.stage)
                .then(function (res) {
                  Helper.showChangesSavedGrowl();
                })
            })
          }

          function deleteStage(e, stageStep) {
            e.stopImmediatePropagation();
            delete $ctrl.stageMenu[stageStep.stage.id];
            MilestoneService.deleteMilestone(stageStep.stage.id)
              .then(function () {
                var index = _.findIndex($ctrl.stageSteps, function (data) {
                  return data.stage.id === stageStep.stage.id;
                })
                var maxPosition = Math.max(_.map($ctrl.unMappedSteps, function (step) {
                  return step.position;
                }))
                _.map(_.orderBy($ctrl.stageSteps[index].steps, 'position'), function (step) {
                  step.stage_id = null;
                  step.position = maxPosition++;
                  return step;
                });
                $ctrl.unMappedSteps = _.concat($ctrl.unMappedSteps, $ctrl.stageSteps[index].steps);
                $ctrl.stageSteps.splice(index, 1);
                var stageIndex = _.findIndex($ctrl.process.stages.data, { id: stageStep.stage.id });
                $ctrl.process.stages.data.splice(stageIndex, 1);
                $rootScope.$emit('PROCESS:STEP_UPDATED');
                $rootScope.$emit('STAGE:DELETED', { stage: stageStep.stage });
                Helper.showChangesSavedGrowl();
              });
          }

          function onWindowResize() {
            $ctrl.showTOC = $ctrl.documentPaneExpanded = !!($window.innerWidth > 991);
          }


          function reCalculateStartIndex() {
            for (var i = 0; i < $ctrl.stageSteps.length; i++) {
              if ($ctrl.stageSteps[i - 1] && $ctrl.stageSteps[i - 1].steps.length) {
                $ctrl.stageSteps[i].startIndex = $ctrl.stageSteps[i - 1].startIndex + $ctrl.stageSteps[i - 1].steps.length;
              } else {
                $ctrl.stageSteps[i].startIndex = 0;
              }
            }
          }

          selectFieldWatcher = $scope.$watch('$ctrl.editorSelectedField.isExpanded', function (value) {
            if (value) {
              $ctrl.documentPaneExpanded = true;
            }
          }, true);

          // right pane closed event handler
          var rightPaneCloseHandler = $rootScope.$on('RIGHT_PANE:CLOSE', function () {
            if (!$ctrl.process.summary) {
              $ctrl.showDescPanel = false;
            }
            $ctrl.isNewBP = !$ctrl.steps.length;
            $ctrl.stepClicked = !$ctrl.isNewBP;
          });

          // Add new Tag in process event
          var addNewTagHandler = $rootScope.$on('NEW_TAG:ADDED', function (e, process) {
            $rootScope.$emit('RIGHT_PANE:CLOSE', {
              event: e
            });
            processDataUpdate(process);
          });

          var rightPaneDocumentBuilderCloseHandler = $rootScope.$on('DOCUMENT_EDITOR:OPEN', function (e, data) {
            $ctrl.editorSelectedField = _.get(data, 'field');
          });

          // Receives new duplicated step
          unregisterOnStepDuplicate = $rootScope.$on('STEP:DUPLICATED', function (event, data) {
            if (data.step) {
              for (var i = 0; i < $ctrl.steps.length; i++) {
                if ($ctrl.steps[i].position >= data.step.position) {
                  $ctrl.steps[i].position++;
                }
              }
              onStepCreated(data.step, true);
            }
          });

          documentVariablesAttachedWatcherHandler = $rootScope.$on('DOCUMENT_VARIABLE_ATTACHED', function (e, data) {
            if (!data) {
              return;
            }
            var isPresent = _.find($ctrl.documentVariables, { id: data.id });
            if (!isPresent) {
              $ctrl.documentVariables.push(data);
            }
          });

          documentSnippetsAttachedWatcherHandler = $rootScope.$on('DOCUMENT_SNIPPET_ATTACHED', function (e, data) {
            if (!data) {
              return;
            }
            var isPresent = _.find($ctrl.documentSnippets, { id: data.id });
            if (!isPresent) {
              $ctrl.documentSnippets.push(data);
            }
          });

          documentBlueprintsAttachedWatcherHandler = $rootScope.$on('DOCUMENT_BLUEPRINT_ATTACHED', function (e, data) {
            if (!data) {
              return;
            }
            var isPresent = _.find($ctrl.documentBlueprints, { id: data.id });
            if (!isPresent) {
              $ctrl.documentBlueprints.push(data);
            }
          })

          stepStageLinkedWatcher = $rootScope.$on('STEP:LINK_STAGE', function (e, data) {
            var editedStep = _.find($ctrl.steps, { id: data.step.id });
            editedStep.stage_id = data.step.stage_id;
            var currentStage = _.find($ctrl.stageSteps, function (stageStep) {
              return stageStep.stage.id == data.stageId;
            });
            currentStage ? currentStage.steps.push(editedStep) : $ctrl.unMappedSteps.push(editedStep || data.step);
            if (data.previousStageId) {
              var previousStage = _.find($ctrl.stageSteps, function (stageStep) {
                return stageStep.stage.id == data.previousStageId;
              })
              var index = _.findIndex(previousStage.steps, { id: data.step.id });
              previousStage.steps.splice(index, 1);
            } else {
              if (!data.newStep) {
                var index = _.findIndex($ctrl.unMappedSteps, { id: data.step.id });
                $ctrl.unMappedSteps.splice(index, 1);
              }
            }
            reCalculateStartIndex();
            if (currentStage) {
              $timeout(function () {
                $rootScope.$emit('STAGE:EXPAND_ELEMENT', { stage: currentStage.stage, step: data.step });
              }, 350);
            }
          });

          stepStageUnlinkedWatcher = $rootScope.$on('STEP:UNLINK_STAGE', function (e, data) {
            var editedStep = _.find($ctrl.steps, { id: data.step.id });
            editedStep.stage_id = null;
            $ctrl.unMappedSteps.push(editedStep);
            var previousStage = _.find($ctrl.stageSteps, function (stageStep) {
              return stageStep.stage.id == data.previousStageId;
            })
            if (previousStage) {
              var index = _.findIndex(previousStage.steps, { id: data.step.id });
              previousStage.steps.splice(index, 1);
            }
            reCalculateStartIndex();
            $timeout(function () {
              $rootScope.$emit('STAGE:EXPAND_ELEMENT', { step: data.step });
            }, 350);
          });

          stepDeletedWatcher = $rootScope.$on('STEP:DELETED', function (e, data) {
            if (data.step.stage_id) {
              var currentStage = _.find($ctrl.stageSteps, function (stageStep) {
                return stageStep.stage.id == data.step.stage_id;
              });
              var index = _.findIndex(currentStage.steps, { id: data.step.id });
              currentStage.steps.splice(index, 1);
            } else {
              var index = _.findIndex($ctrl.unMappedSteps, { id: data.step.id });
              $ctrl.unMappedSteps.splice(index, 1);
            }
            reCalculateStartIndex();
          });

          stageCreatedWatcher = $rootScope.$on('STAGE:CREATED', function (e, data) {
            var stageStepData = { stage: data.stage, steps: [] };
            $ctrl.stageSteps.push(stageStepData);
            data.step.stage_id = data.stage.id;
            $rootScope.$broadcast('STEP:LINK_STAGE', { step: data.step, previousStageId: data.previousStageId, stageId: data.stage.id });
          });

          stepStageUpdatedWatcherHandler = $rootScope.$on('STAGE:EXPAND_ELEMENT', function (e, data) {
            if (data.stage && !$ctrl.stageCollpaseOpen[data.stage.id]) {
              $('#stageCollapse-' + data.stage.id).trigger('click');
            }
            $timeout(function () {
              DOMService.centerObjectToView('.compact-item-selected', {
                behavior: "smooth",
                block: "center"
              });
            }, 1000);
            if (!_.get(data, 'hideSuccessUA', false)) {
              Helper.showChangesSavedGrowl();
            }
          });

          froalaWrapperHeightHandler = $rootScope.$on('FROALA:SET_WRAPPER_HEIGHT', function (e, data) {
            if (data.height) {
              $ctrl.froalaOptions.froalaEditor.$el.get(0).style.height = data.height;
            }
          });
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.support.config.SupportClientIntegrationsStateConfig
 * @module tallyfy.support
 *
 * @description
 * Route config for client integrations
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .config(SupportClientIntegrationsStateConfig);

  /**
   * Constructor
   * SupportClientIntegrationsStateConfig construct
   */
  /*@ngInject*/
  function SupportClientIntegrationsStateConfig($stateProvider) {
    
    $stateProvider.state('support.client-integrations', {
      parent: 'app',
      absolute: true,
      url: '/support/client-integrations/list',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'supportClientIntegrationsList'
        }
      },
      resolve: {
        authorize: ['Auth', '$state',
          function (Auth, $state) {
            if (Auth.authorize()) {
              Auth.getIdentity().then(function (identity) {
                if (!identity.is_support) {
                  $state.go('home');
                }
              });
            }
            return true;
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('support');
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });

    //@TODO Uncomment when the need for a single integration view arises
    /*$stateProvider.state('support.client-integration-single', {
      parent: 'app',
      absolute: true,
      url: '/support/client-integrations/:client_id',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'supportClientIntegrationsSingle'
        }
      },
      resolve: {
        authorize: ['Auth', '$state',
          function (Auth, $state) {
            if (Auth.authorize()) {
              Auth.getIdentity().then(function (identity) {
                if (!identity.is_support) {
                  $state.go('home');
                }
              });
            }
            return true;
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('support');
          return $translate.refresh();
        }]
      }
    })*/

  }
})();

/**
 * @ngdoc overview
 * @name tallyfy.taskFilters
 *
 * @module tallyfy.taskFilters
 *
 * @description
 * manage executable instances of task filters
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy.taskFilters', []);
})();
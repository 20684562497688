(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('relativeToParent', relativeToParent);
  /*@ngInject*/
  function relativeToParent($rootScope) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var parentRect, elementRect, positionIntervalCheck;
        scope.$watch(attrs.relativeToParent, function (value) {
          positionIntervalCheck = $rootScope.$on('RELATIVE_PARENT_ELEMENT::REPOSITION', function () {
            var parent = element.closest(value);
            if (parent.length && element.length) {
              parentRect = parent[0].getBoundingClientRect();
              elementRect = element[0].getBoundingClientRect();
              (parentRect.bottom + elementRect.height < window.innerHeight)
                ? element.css('top', parentRect.bottom).css('left', parentRect.left)
                : element.css('top', parentRect.top - (elementRect.height + parentRect.height)).css('left', parentRect.left);
            }
          });
        });

        scope.$on('$destroy', function () {
          positionIntervalCheck();
        });
      }
    };
  }
})();


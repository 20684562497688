/**
 * @ngdoc Config
 * @name tallyfy.organizations.config.routeConfig
 * @module tallyfy.organizations
 *
 * @description
 * set route for update organization
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    //Routes would be here
  }
})();
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('guestCopyLink', {
      templateUrl: 'app/modules/users/guestUsers/guestCopyLink/guest-copy-link.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, Helper, OrganizationsService, blockUI, Growl, $filter) {
          var $ctrl = this,
          growl = new Growl(),
          blockUI = blockUI.instances.get('guestLink');

          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.copyGuestLinkUrl = copyGuestLinkUrl;
          $ctrl.resetGuestLink = resetGuestLink;

          $ctrl.close = close;

          function onInit() {
            $ctrl.guest = $ctrl.resolve.guest;
            $ctrl.orgId = $ctrl.resolve.org_id;
          }

          function onChanges() { }

          function onDestroy() { }

          function copyGuestLinkUrl(e) {
            Helper.copyToClipboard($ctrl.guest.link, 'guestLink', e);
          }

          function resetGuestLink () {
            blockUI.start();
            OrganizationsService.resetGuestLink({ org_id: $ctrl.orgId, guest_email: $ctrl.guest.email }).then(function (res) {
              angular.extend($ctrl.guest, res.data);
              blockUI.stop();
              Helper.clearUniversalAlert('global');
              growl.success($filter('translate')('guestUsers.messages.resetLink'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            })
          }

          function close() {
            $ctrl.dismiss();
          }
        }
    });
})();
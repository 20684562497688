/**
 * @ngdoc overview
 * @name tallyfy
 *
 * @module tallyfy
 *
 * @description
 * Main module of tallyfy application
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy', [
    /*
     * Order is not important. Angular makes a
     * pass to register all of the modules listed
     */
    /*
     * Everybody has access to these.
     * We could place these under every feature area,
     * but this is easier to maintain.
     */
    'tallyfy.config',
    'tallyfy.account',
    'tallyfy.core',
    'tallyfy.organizations',
    'tallyfy.folders',
    'tallyfy.process',
    'tallyfy.run',
    'tallyfy.steps',
    'tallyfy.notifications',
    'tallyfy.integration',
    'tallyfy.support',
    'tallyfy.billing',
    'tallyfy.analytics',
    'tallyfy.settings',
    'tallyfy.tags',
    'tallyfy.tasks',
    'tallyfy.messages',
    'tallyfy.filters',
    'tallyfy.files',
    'tallyfy.pubnub',
    'tallyfy.editor',
    'tallyfy.taskFilters',
    'tallyfy.utils',
    'tallyfy.securityAndLog',
    'tallyfy.search',
    'tallyfy.public',
    'tallyfy.compliance',
    'tallyfy.list'
  ]).config(appConfigFn)
    .run(run);

  /**
   * @inject
   * inject dependencies
   */
  /*@ngInject*/
  function appConfigFn(tagsInputConfigProvider, dialCodeConfig, $provide) {
    tagsInputConfigProvider.setDefaults('tagsInput', { placeholder: '' });
    tagsInputConfigProvider.setDefaults('autoComplete', { minLength: 0 });
    tagsInputConfigProvider.setActiveInterpolation('tagsInput', { placeholder: true });
    dialCodeConfig.defaultCountry = 'us';
    dialCodeConfig.preferredCountries = ['us', 'gb'];

    $provide.decorator("$q", ["$delegate", function ($delegate) {
      var defer = $delegate.defer;
      $delegate.defer = function () {
        var deferred = defer();
        deferred.promise.state = deferred.state = 'pending';
        deferred.promise.then(function () {
          deferred.promise.state = deferred.state = 'fulfilled';
        }, function () {
          deferred.promise.state = deferred.state = 'rejected';
        });
        return deferred;
      };
      return $delegate;
    }]);
  }

  /**
   * @inject
   * inject dependencies
   */
  /*@ngInject*/
  function run($templateCache) {
    $templateCache.put('templates/growl/growl.html', '<div class="growl-container" ng-class="wrapperClasses()">' + '<div class="growl-item alert" ng-repeat="message in growlMessages.directives[referenceId].messages" ng-class="alertClasses(message)" ng-click="stopTimeoutClose(message)"><i class="fas"></i>' + '<button type="button" class="close" data-dismiss="alert" aria-hidden="true" ng-click="growlMessages.deleteMessage(message)" ng-show="!message.disableCloseButton">&times;</button>' + '<button type="button" class="close" aria-hidden="true" ng-show="showCountDown(message)">{{message.countdown}}</button>' + '<h4 class="growl-title" ng-show="message.title" ng-bind="message.title"></h4>' + '<div class="growl-message notify-content"><span class="notify-message" ng-bind-html="message.text"></span><button data-ng-if="message.variables && message.variables.method && message.variables.linkText" class="alert-action btn-link p-0 t-white _600 no-border" href="" ng-click="message.variables.method(message.variables.param)">{{message.variables.linkText}}</button></div>' + '</div>' + '</div>');
  }
})();
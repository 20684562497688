(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy
   * @module tallyfy
   *
   * @description
   * Create Rest resource for auth
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy')
    .factory('AuthRepository', AuthRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  AuthRepository.$inject = ['BaseRepository', 'CONFIG', 'ENV_CONFIG'];
  /**
   * Constructor
   * AuthRepository construct 
   */
  function AuthRepository(BaseRepository, CONFIG, ENV_CONFIG) {
    return BaseRepository('/auth/:action/:sub_action', {
      action: '@action',
      sub_action: '@sub_action'
    }, {
      login: {
        method: 'POST'
      },
      logout: {
        method: 'GET',
        url: ENV_CONFIG.AUTH_HOST + '/logout'
      },
      getPlans: {
        method: 'GET',
        url: CONFIG.API_HOST + '/get-plans',
        params: {
          currency_shortcode: ''
        }
      },
      getToken: {
        method: 'GET',
        url: CONFIG.API_HOST + '/support/token'
      },
      getRecurlyToken: {
        method: 'POST',
        url: CONFIG.API_HOST + '/recurly-token'
      }
    });
  }
})();

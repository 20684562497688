/**
 * @ngdoc Component
 * @name tallyfy.organizations.users.component.userAssignments
 * @module tallyfy.organizations.users
 *
 * @description
 * A component to get user assignments and reassign to other user.
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('userAssignments', {
      templateUrl: 'app/modules/users/userAssignments/userAssignments.html',
      controller:
        /*@ngInject*/
        function (_, $stateParams, UsersService, blockUI, Growl, $assigner, $filter, $state, $q, $log, TFY_EVENTS, $rootScope, AccountService, OrganizationsService,
                  $timeout, store) {
          var $ctrl = this, 
            blockUI = blockUI.instances.get('userAssignments'), 
            growl = new Growl();

          /**
           * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
          * Expose bindable methods
          * these methods are accessible in view
          */
          $ctrl.openOwnerAssigner = openOwnerAssigner;
          $ctrl.checkOrgAdmin = checkOrgAdmin;
          $ctrl.removeUser = removeUser;

          $ctrl.absoluteDateFormat = OrganizationsService.getDateFormat();

          /**
           * @function $onInit
           * A component's lifeCycle hook which is called after all 
           * the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.organizationId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.userId = $stateParams.id;
            loadData();  
          }

          /**
          * @ngdoc method
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

           /**
           * @ngdoc method
           * @name loadData
           * @description 
           * Get steps/processes/one-off task by id
           * Get all users from the organization
           */
          function loadData() {
            blockUI.start();
            $ctrl.isLoaded = false;
            $q.all([
              UsersService.get({
                id: $stateParams.id,
                action: 'reassignment-information'
              }),
              store.getUsersFullData()
            ]).then(function (response) {
              $ctrl.isLoaded = true;
              $ctrl.userAssignments = _.get(response[0], 'data');
              $ctrl.users = response[1];
              var selectedUser = getUser();
              $ctrl.account = _.pick(selectedUser, ['id', 'profile_pic', 'full_name']);
              blockUI.stop();  
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name getUser
           * @description Get user based on id
           * @returns object
           */
          function getUser() {
            return _.find($ctrl.users, function(user) {
              return user.id == $stateParams.id; // Comparing string with an integer here
            });
          }

          /**
           * @ngdoc method
           * @name openOwnerAssigner
           * @description opens a modal to choose owners
           * @see $assigner A reusable component for assignee
           * @returns void
           */
          function openOwnerAssigner() {
            var activeUsers = UsersService.getBilledUsers($ctrl.users);
            $assigner.ownerAssigner({
              users: activeUsers,
              owners: [$ctrl.account],
              params: {
                assignerType: 'OWNER_RE_ASSIGNER',
                isGuestAllowed: []
              }
            }).result.then(function (response) {
              var user = _.get(response, 'users[0]') || _.get(response, 'users');
              checkOrgAdmin(user);
            }, function () {
              $log.info('ownerAssigner modal-component dismissed.');
            });
          }
          
          /**
           * @ngdoc method
           * @name checkOrgAdmin
           * @public
           * @description check Org Admin then set new org admin
           * @param {*} user
           */
          function checkOrgAdmin(user) {
            $ctrl.oldUser = getUser();
            if(_.get($ctrl.oldUser, 'is_default_admin')) {
              AccountService.setNewAdmin($stateParams.id, {'new_admin_id': _.get(user, 'id')}).then(function () {
                reAssignmentOwner(user);
              });
            } else {
              reAssignmentOwner(user);
            }
          }
          
          /**
           * @ngdoc method
           * @name reAssignmentOwner
           * @public
           * @description assign the selected user as owner and delete the current user
           * @param {*} newOwner
           * @returns void
           */
          function reAssignmentOwner(newOwner) {
            removeUser({
              with_reassignment: true,
              to: newOwner.id
            });
          }
          
          /**
           * @ngdoc method
           * @name removeUser
           * @public
           * @description assign the selected user as owner and delete the current user
           * @param {*} param
           * @returns void
           */
          function removeUser(param) {
            if (_.get($ctrl.userAssignments, 'steps', []).length > 0 && !_.isEmpty(_.get($ctrl.userAssignments, 'templates')) && !_.get(param, 'with_reassignment', false)) {
              $timeout(function () {
                growl.warning($filter('translate')('organizations.owner.button.update.messages.warning'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 100);
            } else {
              var params = { id: $ctrl.account.id };
              if (param) {
                angular.extend(params, param);
              }
              blockUI.start();
              UsersService.delete(params).then(function (response) {
                growl.success($filter('translate')('organizations.owner.button.update.messages.success', { 'name': $ctrl.account.full_name}), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, response.data);
                $state.go('userProfile', { org_id: $ctrl.organizationId, id: $ctrl.account.id });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }
          }
        }
    });
})();
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .factory('authInterceptor', authInterceptor);
  /*@ngInject*/
  function authInterceptor($rootScope, _, $injector, $log, USER_STATE) {
    var service = {
      request: request
    };

    return service;

    function request(config) {
      /*jshint camelcase: false */
      config.headers = config.headers || {};
      var AuthServerProvider = $injector.get('AuthServerProvider'),
        skipAuthToken = _.get(config, 'params.skipAuthToken'),
        customTallyfyClientHeader = _.get(config, 'params.X-Tallyfy-Client'),
        token = AuthServerProvider.getToken(skipAuthToken);

      if (customTallyfyClientHeader) {
        config.headers['X-Tallyfy-Client'] = customTallyfyClientHeader;
        delete config.params['X-Tallyfy-Client'];
      } else {
        config.headers['X-Tallyfy-Client'] = 'ClientUI';
      }

      if (skipAuthToken) {
        delete config.params.skipAuthToken;
      }
      var org_id;
      if (skipAuthToken) {
        if ($rootScope.userState === USER_STATE.PUBLIC || $rootScope.userState === USER_STATE.PUBLIC_PROCESS) {
          org_id = $rootScope.publicOrganization ? $rootScope.publicOrganization.id : void 0;
        } else if ($rootScope.userState === USER_STATE.GUEST) {
          org_id = _.get($rootScope.identity, 'guest.organization.id');
        } else {
          org_id = _.get($rootScope, 'identity.default_organization.id');
        }
      } else {
        org_id = _.get($rootScope, 'identity.default_organization.id');
      }

      if (token && AuthServerProvider.hasValidToken() && !skipAuthToken) {
        config.headers.Authorization = 'Bearer ' + token;
        var helper = $injector.get('Helper');
        if (!helper.isMasquarade()) {
          config.headers['X-Tallyfy-UserID'] = skipAuthToken ? _.get($rootScope, 'identity.guest.email') : _.get($rootScope, 'identity.id');
          config.headers['X-Tallyfy-OrgID'] = org_id;
        } else {
          config.headers['X-Tallyfy-Support-UserID'] = helper.isMasquarade();
          config.headers['X-Tallyfy-UserID'] = skipAuthToken ? _.get($rootScope, 'identity.guest.email') : _.get($rootScope, 'identity.id');
          config.headers['X-Tallyfy-OrgID'] = org_id;
        }
      }

      if (token && skipAuthToken) {
        config.url = config.url.replace('guest_code', token);
      }

      if (config.url.indexOf('/functions/') < 0 && config.url.indexOf('org_id') >= 0) {
        if (!_.isEmpty(org_id)) {
          config.url = config.url.replace('org_id', org_id);
        } else {
          config.url = config.url.replace('org_id', 'undefined');
          $log.error('organization is not passed');
        }
      }

      return config;
    }
  }
})();

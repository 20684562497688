(function () {
  'use strict';
  angular.module('tallyfy')
    .factory('MilestoneRespository', MilestoneRespository);

  /*@ngInject*/
  function MilestoneRespository(BaseRepository) {
    var repository = BaseRepository('/organizations/:org/:action/:actionId/:subAction/:subActionId/:arg', {
      org: 'org_id',
      action: '@action',
      actionId: '@actionId',
      subAction: '@subAction',
      subActionId: '@subActionId',
      arg: '@arg'
    }, {
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' },
      $delete: { method: 'DELETE' }
    });

    return repository;
  }
})();
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(lightboxConfig);

  /*@ngInject */
  function lightboxConfig(LightboxProvider) {
    LightboxProvider.templateUrl = 'app/components/editor/modal.html';
  }
})();
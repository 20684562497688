/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.orgHoliday
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage organizations holidays
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('orgHoliday', {
      templateUrl: 'app/modules/organizations/holidays/holiday.html',
      bindings: {
        holiday : '<',
        onDelete : '&',
        onUpdate : '&'
      },
      require: {
        $parent : '^orgHolidays'
      },
      controller: [
        '_',
        '$log',
        'moment',
         function (_, $log, moment) {
          var $ctrl = this,
            today = new Date(),
            formName;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
          * public methods
          */
          $ctrl.openDatePicker = openDatePicker;
          $ctrl.deleteHoliday = deleteHoliday;
          $ctrl.updateHoliday = updateHoliday;

          /**
          * public properties
          */
         $ctrl.randString = _.now();
          $ctrl.datePicker = {
            format : 'MM-dd-yy',
            dateOptions: {
              dateDisabled: false,
              formatYear: 'yy',
              maxDate: new Date(2020, 5, 22),
              minDate: today,
              startingDay: 1,
              showButtonBar: false,
              altInputFormats: ['dd-MMMM-yyyy', 'yyyy-MM-dd', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
              showWeeks: false,
              ngModelOptions: {
                debounce: 100
              }
            },
            isOpened: false
          };

          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.hasHolidays = $ctrl.$parent.hasHolidays;
            $ctrl.holidays = $ctrl.$parent.holidays;
            formName = 'form_'+$ctrl.randString;
          }
          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges(bindings) {
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function deleteHoliday(holiday) {
            $ctrl.onDelete({holiday : holiday});
          }

          function openDatePicker(openDatePicker) {
            _.forEach($ctrl.holidays, function (o) {
              if (o.isChecked) {
                o.isChecked = false;
                return false;
              }
            });
            openDatePicker.isOpened = true;
          }

          function updateHoliday(){
            if($ctrl[formName].$invalid || !moment($ctrl.holiday.date).isValid()){
              return;
            }
            $ctrl.onUpdate({holiday : $ctrl.holiday});
          }
        }]
    });

})();
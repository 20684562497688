/**
 * @ngdoc Component
 * @name tallyfy.component.iconIssueReported
 * @module tallyfy
 *
 * @description
 * Icon for issue reported
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('iconIssueReported', {
      templateUrl: 'app/components/icon-issue-reported/icon-issue-reported.html',
      bindings: {
        isReject: '<'
      }
    });
})();
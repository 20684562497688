(function () {
  'use strict';
  angular
    .module('tallyfy.public')
    .factory('listRepository', listRepository)

  /*@ngInject*/
  function listRepository(BaseRepository) {
    var repository = BaseRepository('/organizations/:org_id/lists/:list_id/:action', {
      org_id: 'org_id',
      list_id: '@list_id',
      action: '@action'
    }, {
      $all: { method: 'GET' },
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' },
      $destroy: { method: 'DELETE' }
    });
    return repository;
  }
})();
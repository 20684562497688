/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.organizationList
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage organization list
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('organizationList', {
      templateUrl: [
        '$attrs',
        function ($attrs) {
          var layoutType = $attrs.layout || 'default';
          //data-layout="nav" will produce nav-organizations-list.html, and so on...
          return 'app/modules/organizations/organizationsList/' + layoutType.toLowerCase() + '-organizations-list.html';
        }
      ],
      controller:
        /*@ngInject*/
        function ($q, $attrs, $filter, _, OrganizationsService, $state, Principal, $rootScope, blockUI, store, preferencesStore, OrganizationListTableService, Helper) {
          var $ctrl = this,
            globalBlockUI = blockUI.instances.get('appView'),
            blockUI = blockUI.instances.get('organizationList'),
            supportParams,
            isSupportView = false,
            isFirstLoad = true;

          $ctrl.tableOptions = {};
          $ctrl.orgPagination = { per_page: 999, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          /**
           * @access public
           * public properties
           */
          $ctrl.listPager = {
            total: 0,
            per_page: 999,
            current_page: 1,
            onPageChanged: function (current_page) {
              this.current_page = current_page;
              $ctrl.search.searchText.length ? searchOrganizations() : getOrganizations(supportParams);
            }
          };
          $ctrl.guestListPager = {
            total: 0,
            per_page: 999,
            current_page: 1,
            onPageChanged: function (current_page) {
              this.current_page = current_page;
              getMyGuestOrganizations({ action: $rootScope.identity.email });
            }
          };
          $ctrl.organizations = [];
          $ctrl.defaultOrg = $rootScope.identity.default_organization;
          $ctrl.search = {
            form: {},
            searchText: '',
            reset: function () {
              this.searchText = '';
              getOrganizations(supportParams);
            }
          };

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.toggleOrganization = toggleOrganization;
          $ctrl.chooseOrganization = chooseOrganization;
          $ctrl.searchOrg = searchOrganizations;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getOrganizationData = getOrganizationData;
          $ctrl.getLogo = getLogo;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            isSupportView = ($attrs.userRole === 'support');
            if ($state.current.name === "support") {
              angular.extend($ctrl.orgPagination, {
                per_page: 20
              });
            }
            if (!isSupportView) {
              supportParams = isSupportView ? { admin: true } : {};
              getOrganizations(supportParams);
              getMyGuestOrganizations({ action: $rootScope.identity.email });
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getOrganizationData(params) {
            if (!params) {
              params = { sort: '-created_at' };
            }
            var defer = $q.defer();
            OrganizationsService.myOrganizations(
              angular.extend($ctrl.orgPagination, {
                with: 'subscription',
                admin: true
              }, params)
            ).then(function (res) {
              angular.extend($ctrl.orgPagination, res.meta.pagination);
              defer.resolve(res.data);
            });
            return defer.promise;
          }

          /**
           * @function
           * @name getOrganizations
           * @param {Object} params
           * @description get list of organizations
           */
          function getOrganizations(params) {
            blockUI.start();
            var requestParams = {
              per_page: 999,
              page: $ctrl.listPager.current_page,
              with: 'subscription',
              include_disabled: true
            };

            if (params && _.isObject(params)) {
              angular.extend(requestParams, params);
            }

            (!isSupportView && isFirstLoad && $ctrl.listPager.current_page === 1) ? $ctrl.loadingView = true : angular.noop();

            OrganizationsService.myOrganizations(requestParams).then(function (organizations) {
              $ctrl.organizations = organizations.data;

              if (!_.isEmpty(organizations.meta)) {
                if (organizations.meta.pagination.total <= 0 && !isSupportView) {
                  $state.go('organizations.create');
                  return;
                }
                $ctrl.listPager.total = $rootScope.identity.totalOrgOfCurrentUser = organizations.meta.pagination.total;
              }

              (!isSupportView && isFirstLoad && $ctrl.listPager.current_page === 1) ? $ctrl.loadingView = false : angular.noop();
              isFirstLoad = false;
              blockUI.stop();
            },
              function (error) {
                blockUI.stop();
                throw error;
              });
          }

          function getMyGuestOrganizations(args) {
            var requestParams = {
              per_page: 999,
              page: $ctrl.guestListPager.current_page
            };
            blockUI.start();
            OrganizationsService.myGuestOrganizations(args, requestParams)
              .then(function (res) {
                $ctrl.myGuestOrganizations = res.data;
                $ctrl.guestListPager.metadata = res.metadata;
                blockUI.stop();
              }, function (error) {
                blockUI.stop();
                throw error;
              });
          }

          /**
           * @function
           * @name searchOrganizations
           * @description search organizations
           */
          function searchOrganizations() {
            if ($ctrl.search.searchText.length) {
              var searchParams = {
                q: $ctrl.search.searchText
              };

              if (_.isObject(supportParams)) {
                angular.extend(searchParams, supportParams);
              }
              getOrganizations(searchParams);
            }
            else {
              initialization();
            }
          }

          /**
           * @function
           * @name chooseOrganization
           * @description set selected org as default.
           * @public
           * @returns void
           */
          function chooseOrganization(org) {
            blockUI.start();
            setOrganization(org).then(function () {
              $state.go('dashboard', {
                org_id: org.id
              });
            }, function () {
              blockUI.stop();
            });
          }
          /**
          * @function
          * @name toggleOrganization
          * @description switch to selected Organization, reload state to reinitialize component
          * @public
          * @returns void
          */
          function toggleOrganization(org) {
            globalBlockUI.start();
            setOrganization(org).then(function () {
              $state.go('home', {
                org_id: org.id
              }, {
                reload: true
              });
            }, function () {
              globalBlockUI.stop();
            });
          }

          /**
           * @function
           * @name setOrganization
           * @description set organization in rootScope and redirecting to org
           * @private
           * @returns promise
           */
          function setOrganization(org) {
            store.clearUserStore();
            preferencesStore.clearPreferences();
            return Principal.setOrganization(org);
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: OrganizationListTableService.getOrganizationListTableOptions(),
              tableState: {
                sort: {
                  field: 'created_at',
                  dir: 'desc'
                },
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'name',
                  title: $filter('translate')('organizations.title'),
                  initState: true
                }, {
                  field: 'plan_code',
                  title: $filter('translate')('organizations.planCode'),
                  initState: true
                }, {
                  field: 'users_count',
                  title: $filter('translate')('organizations.activeMembers'),
                  initState: true
                }, {
                  field: 'created_on',
                  title: $filter('translate')('organizations.createdOn'),
                  initState: true
                }]
              },
              tableFilterModel: {},
              templateScope: {
                callbacks: {
                  sortTable: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    if ($ctrl.tableOptions.tableState.sort.field !== column) {
                      $ctrl.tableOptions.tableState.sort.dir = 'asc';
                      $ctrl.tableOptions.tableState.sort.field = column;
                    } else {
                      if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                        $ctrl.tableOptions.tableState.sort = {};
                      } else {
                        $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                        $ctrl.tableOptions.tableState.sort.field = column;
                      }
                    }
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getOrganizationData(
                      { sort: !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '') + column : void 0 }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  onColumnFilter: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var params = { q: $ctrl.tableOptions.templateScope.variables.searchParams.q };
                    if (column === 'plan_code') {
                      params.plan_code = $ctrl.tableOptions.tableFilterModel.plan_code.value;
                    }
                    getOrganizationData(params)
                      .then(function (res) {
                        $ctrl.tableOptions.gridConfig.dataSource.data(res);
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      }, function () {
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      });
                  }
                },
                variables: {
                  searchParams: {},
                  dateFormat: OrganizationsService.getDateFormat()
                }
              }
            };
          }

          function getLogo (org) {
            if (org.org_logo) {
              return org.org_logo;
            } else {
              return OrganizationsService.getDefaultOrgLogoForPlan(org.plan_code);
            }
          }
        }
    });
})();
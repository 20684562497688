(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicLibraryPage', {
      templateUrl: 'app/components/public/pages/library.page.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function ($rootScope) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() {
            $ctrl.hideHeader = $rootScope.toState.name === 'public.library.organization.kickOff';
          }
          function onChanges() { }
          function onDestroy() { }
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.mouseDetector
 * @description mouse event detect
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('mouseDetector', mouseDetector);
    /*@ngInject*/
    function mouseDetector(_) {
      return {
        restrict: 'A',
        scope: {
          isDragging: '=',
          elementId: '<'
        }, 
        link: function (scope, element) {
          var previousChildEvent = '';
          element.bind('mousemove mouseup mousedown', function (e) {
            element.find("#" + scope.elementId).bind('mouseenter mouseleave mousedown mouseup', function (event) {
              if (event.type == 'mouseenter' || event.type == 'mousedown') {
                scope.isDragging = true;
                previousChildEvent = event.type;
              }
              if ((event.type == 'mouseleave' && previousChildEvent != 'mousedown') || event.type == 'mouseup') {
                scope.isDragging = false;
                previousChildEvent = event.type;
              }
            });
            if (e.type == 'mouseup' && _.includes(['mouseleave', 'mousedown', 'mouseup'], previousChildEvent)) {
              scope.isDragging = false;
            }
            scope.$apply();
          });
        }
      };
    }
})();


(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('AuthServerProvider', AuthServerProvider);

  /*@ngInject*/
  function AuthServerProvider($localStorage, $sessionStorage, $q, AuthRepository, _, $cookies, moment, ENV_CONFIG, $timeout, $tallyfyWebSocketFactory) {
    var service = {
      getToken: getToken,
      hasValidToken: hasValidToken,
      login: login,
      loginWithToken: loginWithToken,
      storeAuthenticationToken: storeAuthenticationToken,
      logout: logout,
      getAllTokenDetails: getAllTokenDetails,
      logoutViaAjax: logoutViaAjax
    };

    return service;

    function getToken(isGuest) {
      return isGuest ? $localStorage.guestCode || $sessionStorage.guestCode : $localStorage.authenticationToken || $sessionStorage.authenticationToken;
    }

    function hasValidToken() {
      var token = $localStorage.token || $sessionStorage.token;
      var expires_in = _.get(token, 'expires_in', '');
      /**
       * check if expires_in exists and greater than current timestamp.
       */
      return expires_in && moment().add(expires_in, 'seconds').valueOf() > moment().valueOf();
    }

    function login(credentials) {

      var data = {
        username: _.toLower(credentials.email),
        password: credentials.password,
        rememberMe: credentials.rememberMe
      };
      return AuthRepository.login({ action: 'login' }, data).$promise;

    }

    function loginWithToken(jwt, rememberMe, isGuest) {
      var deferred = $q.defer();

      if (angular.isDefined(jwt)) {
        this.storeAuthenticationToken(jwt, rememberMe, isGuest);
        deferred.resolve(jwt);
      } else {
        deferred.reject();
      }

      return deferred.promise;
    }

    function storeAuthenticationToken(token, rememberMe, isGuest) {
      if (rememberMe && !isGuest) {
        token.issued_at = moment.utc().format();
        var tallyfy_session = {
          expires_in: token.expires_in
        };
        $localStorage.authenticationToken = token.access_token;
        $localStorage.token = token;
        $cookies.putObject('tallyfy_session', tallyfy_session, { domain: 'tallyfy.com' });
      } else {
        if (isGuest) {
          $localStorage.guestCode = token;
        } else {
          $sessionStorage.authenticationToken = token.access_token;
          $sessionStorage.token = token;
        }
      }
    }

    function logoutViaAjax() {
      var deferred = $q.defer(), iframe = document.createElement('iframe');
      iframe.style.display = "none";
      iframe.id = 'logout_iframe';
      iframe.src = ENV_CONFIG.AUTH_HOST + '/logout';
      document.body.appendChild(iframe);
      
      $timeout(function () {
        var frame = document.getElementById('logout_iframe');
        frame.parentNode.removeChild(frame);
        deferred.resolve();
      }, 2000);
      return deferred.promise;
    }

    function logout() {
      var deferred = $q.defer();
      AuthRepository.logout();

      $localStorage.$reset();
      $sessionStorage.$reset();
      $cookies.remove('tallyfy_session', { domain: 'tallyfy.com' });
      $tallyfyWebSocketFactory.$setUnAuth();
      logoutViaAjax().then(function () {
        deferred.resolve({});
      });
      
      return deferred.promise;
    }

    function getAllTokenDetails() {
      return $localStorage.token || $sessionStorage.token;
    }
  }
})();

(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(quickActionsConfig);

  quickActionsConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  function quickActionsConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/action', '/action/');
    
    $stateProvider.state('quickActions', {
      parent: 'app',
      absolute: true,
      url: '/:org_id/action/:actionType',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'quickActions'
        }
      },
      params:  {
        actionType: {
          value: null,
          squash: true
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Service
 * @name authPlan.service.js
 *
 * @module AuthPlan
 *
 * @description
 * Service for showing up-sell modals
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('AuthPlan', AuthPlan);

  AuthPlan.$inject = ['$rootScope', '$state', 'Principal', '_', '$log', '$filter', 'CONFIG', 'PLANS', '$uibModal', 'USER_STATE'];

  function AuthPlan($rootScope, $state, Principal, _, $log, $filter, CONFIG, PLANS, $uibModal, USER_STATE) {
    var service = {
      planAuthorize: planAuthorize,
      getCurrentPlanCode: getCurrentPlanCode,
      isRestricted: isRestricted,
      hasAnyAuthority: hasAnyAuthority,
      setModalContent: setModalContent,
      isUserInFreeTrial: isUserInFreeTrial,
      isRestrictedWithDocsPlan: isRestrictedWithDocsPlan,
      getPlans: getPlans
    };
    return service;

    //prevent access from state using "data.restrictedPlans"
    function planAuthorize() {
      var authReturn = Principal.identity().then(authThen);
      return authReturn;

      function authThen() {
        var isAuthenticated = Principal.isAuthenticated();

        if (isAuthenticated) {
          // user is signed in but not authorized for desired state

          if ($rootScope.toState.data.restrictedPlans && service.isRestricted($rootScope.toState.data.restrictedPlans)) {
            $state.go('home');
          }
        }
      }
    }

    /**
     * @function getCurrentPlanCode
     * @description return current plan code or 'free' if not get any
     * @returns {String} plan
     */
    function getCurrentPlanCode() {
      var currentOrg = Principal.getCurrentOrganization(),
        currentPlanCode = $rootScope.userState === USER_STATE.GUEST ? _.get(currentOrg, 'plan_code') : _.get(currentOrg, 'subscription.data.plan_code');

      if (currentPlanCode && currentPlanCode === PLANS.FREE && currentOrg.in_trial) {
        return currentOrg.preferred_trial_plan ? currentOrg.preferred_trial_plan : PLANS.PRO;
      }
      if (currentPlanCode && !currentOrg.in_trial) {
        return currentPlanCode;
      }
      return currentPlanCode;
    }

    // check restrictions for current plan
    function isRestricted(restrictedPlans) {
      restrictedPlans = _.split(_.replace(restrictedPlans, " ", ""), ",");

      if (!_.isEmpty(restrictedPlans) && _.indexOf(restrictedPlans, service.getCurrentPlanCode()) >= 0) {
        return true;
      }
      return false;
    }

    // blocking access for ngClick
    function hasAnyAuthority(plans, feature) {
      if (service.isRestricted(plans)) {
        service.setModalContent(service.getCurrentPlanCode(), feature);
        return false;
      }
      return true;
    }

    /**
     * @ngdoc method
     * @name setModalContent
     * @description Set header and content in up-sell model plan wise
     * @param {String} orgPlanCode
     * @param {String} feature
     * @returns {Object}
    */
    function setModalContent(orgPlanCode, feature) {
      var isDocsPlan = service.isRestrictedWithDocsPlan(), header, content = (!isDocsPlan) ? $filter('translate')('global.upSellModal.' + feature + '.owner.' + orgPlanCode) : '';

      switch (feature) {
        
        case 'addFilter': // Process View Filter
          header = $filter('translate')('global.upSellModal.addFilter.header');
          break;

        case 'stepAdvancedTab': // Step advanced settings
          header = $filter('translate')('global.upSellModal.stepAdvancedTab.header');
          break;

        case 'myTask':
          header = $filter('translate')('global.upSellModal.myTask.header');
          break;

        case 'coworkerLimit': // Invite Coworks in Org Settings
          header = $filter('translate')('global.upSellModal.userLimit');
          break;

        case 'activeRunLimit': // exceeds allowed processes
          header = $filter('translate')('global.upSellModal.processLimit');
          content = $filter('translate')('global.upSellModal.' + feature + '.owner.' + orgPlanCode, { 'activeRunLimit': CONFIG.FREE_ACCOUNT_ACTIVE_RUN_LIMIT });
          break;
        
        case 'searchByCoworker': // Search By Co-worker
          header = $filter('translate')('global.upSellModal.searchByCoworkerHeading');
          break; 
          
        case 'searchByGuest': // Search By Guest
          header = $filter('translate')('global.upSellModal.searchByGuestHeading.header', { isDocsPlan: isDocsPlan}, 'messageformat');
          content = isDocsPlan ? $filter('translate')('global.upSellModal.searchByGuestHeading.content', { isDocsPlan: isDocsPlan}, 'messageformat') : '';
          break;
          
        case 'insertVariable': // exceeds allowed processes
          header = $filter('translate')('global.upSellModal.insertVariable.header');
          content = $filter('translate')('global.upSellModal.insertVariable.content');
          break;

        case 'relativeDeadline': // Not allowed to set step deadline
          header = $filter('translate')('global.upSellModal.relativeDeadline.header');
          break;

        case 'exportCSV':
          header = $filter('translate')('global.upSellModal.exportCSV.header');
          content = $filter('translate')('global.upSellModal.exportCSV.content');
          break;
          
        case 'launchBP': // Restrict to launch process
          header = $filter('translate')('global.upSellModal.launchBP.header');
          content = $filter('translate')('global.upSellModal.launchBP.content');
          break;

        case 'viewAccessToken': // Settings > Integrations > View Access Token
          header = $filter('translate')('global.upSellModal.viewAccessToken.header');
          content = '';
          break;
          
        case 'advanceFilter': // for Advance Process View Filter
          header = $filter('translate')('global.upSellModal.advanceFilter.header');
          content = '';
          break;

        case 'standaloneAdvancedSettings':
          header = $filter('translate')('global.upSellModal.ootAdvancedSettings.header');
          content = $filter('translate')('global.upSellModal.ootAdvancedSettings.content');
          break;
        default:
          header = $filter('translate')('global.upSellModal.header', { planCode: feature });
      }
      // Set content in modal
      openModal(header, content);
      return;
    }

    /**
     * @ngdoc method
     * @name upgradePlan
     * @param {String} header
     * @param {String} content
     * @description Open upsell modal
     */
    function openModal(header, content) {
      var options = {
        'header': header,
        'body': content,
        'buttons': {
          'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: $filter('translate')('global.upgradeNow') }),
          'cancel': 'global.confirmModal.buttons.cancel'
        },
        modalType: 'modal-success'
      };
      
      var modalInstance = $uibModal.open({
        component: 'upgradeModal',
        resolve: {
          updradeData: function () {
            return options;
          }
        }
      });
      modalInstance.opened.then(function() {
        $rootScope.$broadcast("UPGRADE_ORG_MODAL:OPENED");
      });
      modalInstance.result.then(function () {
        $rootScope.$broadcast("UPGRADE_ORG_MODAL:CLOSED");
        $state.go('settings.billing', {
          org_id: _.get($rootScope, 'identity.default_organization.id')
        });
      }, function () {
        $log.info("Modal Dismissed");
        $rootScope.$broadcast("UPGRADE_ORG_MODAL:CLOSED");
      });
    }
    
     /**
     * @function isUserInFreeTrial
     * @description check user is in free trial or not
     * @returns {Boolean}
     */
    function isUserInFreeTrial() {
      return _.get($rootScope.identity, 'default_organization.in_trial') && _.get($rootScope.identity, 'default_organization.subscription.data.plan_code') === PLANS.FREE;
    }
    
    /**
     * @ngdoc method
     * @name isRestrictedWithDocsPlan
     * @description Returns boolean value to restrict with docs plan
     * @returns {Boolean}
     */
    function isRestrictedWithDocsPlan() {
      return ([PLANS.DOCS, PLANS.DOCSANNUAL, PLANS.DOCSMONTHLY].indexOf(service.getCurrentPlanCode()) > -1);
    }
    
    /**
     * @ngdoc method
     * @name getPlans
     * @description Return all plans
     * @returns {Array}
     */
    function getPlans() {
      return [{ 
        name: PLANS.PRO,
        image:'assets/images/pro-logo.png',
        imageLarge:'assets/images/logo_pro@2x.png',
        description: $filter('translate')('signup.v2_sign_up_views.plan_selection.plans.proDescriptionV2'),
        textColor: 'text-green',
        backColor: 'upgrade-cover-green'
      }, { 
        name: PLANS.DOCS,
        image:'assets/images/docs-logo.png',
        imageLarge:'assets/images/logo_docs@2x.png',
        description: $filter('translate')('signup.v2_sign_up_views.plan_selection.plans.docsDescriptionV2'),
        textColor: 'text-slate',
        backColor: 'upgrade-cover-slate'
      }];
    }
  }
})();

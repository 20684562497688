(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(compileServiceConfig);
      /*@ngInject*/
      function compileServiceConfig($compileProvider, ENV_CONFIG, _) {
        // disable debug data on prod profile to improve performance
        
        if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
          $compileProvider.debugInfoEnabled(false);
        }
        /*
         If you wish to debug an application with this information
         then you should open up a debug console in the browser
         then call this method directly in this console: angular.reloadWithDebugInfo();
         */
      }
})();
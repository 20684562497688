/**
 * @ngdoc Service
 * @name tallyfy.compliance
 *
 * @module tallyfy.compliance
 *
 * @description
 * ComplianceService
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.compliance')
    .service('ComplianceService', ComplianceService);

  ComplianceService.$inject = ['_', 'AccountService', 'ComplianceRepository', 'PLANS'];

  function ComplianceService(_, AccountService, ComplianceRepository) {
    var self = this;

    //register default(get, all, delete, update, create) CRUD to service
    angular.extend(self, ComplianceRepository);

    self.getAPIModel = function (account) {
      var apiModel = _.pick(account, ['client_id', 'client_secret']);
      apiModel.orgId = account.default_organization.id;
      return apiModel;
    };

    self.resetKeys = function () {
      return AccountService.refreshKeys();
    };

    self.getSMTPStub = function () {
      return {
        "smtp_username": "",
        "smtp_password": "",
        "smtp_email_address": "",
        "smtp_email_name": "",
        "smtp_encryption": "",
        "smtp_port": "",
        "smtp_host": "",
        "smtp_mail_driver": "",
        "group": 'smtp'
      };
    };

    self.getSMTPFields = function () {
      return [
        "smtp_username",
        "smtp_password",
        "smtp_email_address",
        "smtp_email_name",
        "smtp_encryption",
        "smtp_port",
        "smtp_host",
        "smtp_mail_driver",
      ];
    }
  }
})();

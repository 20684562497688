/**
 * @ngdoc component
 * @name tallyfy.CompactStepService
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .service('CompactStepService', CompactStepService);

  /*@ngInject*/
  function CompactStepService(_, $q, $filter, MessagesService, FroalaService) {
    var self = this;

    // get rule terms and return copy of terms object
    self.getRuleTerms = function (process, step) {
      return _.filter(process.automated_actions, function (automatedAction) {
        return _.filter(automatedAction.then_actions, function (action) {
          return action.target_step_id === step.id;
        }).length;
      });
    };

    self.getAffectingAutomations = function (process, step) {
      return _.filter(process.automated_actions, function (automatedAction) {
        return _.filter(automatedAction.then_actions, function (action) {
          return action.target_step_id === step.id;
        }).length;
      });
    };

    self.getTriggerAutomations = function (process, step) {
      return _.filter(process.automated_actions, function (automatedAction) {
        return _.filter(automatedAction.conditions, function (condition) {
          return condition.conditionable_id === step.id;
        }).length;
      });
    };

    /**
     * @ngdoc method
     * @private
     * @name getRuleStatement
     * @param {*} steps 
     * @param {*} stepId 
     * 
     * @description
     * generate rule statement
     * used by getRuleTerms
     */
    function getRuleStatement(steps, stepId) {
      var index = _.findIndex(steps, ['id', stepId]);
      if (index >= 0) {
        var step = steps[index];
        return $filter('translate')('steps.logic.tooltip.if', {
          "stepNumber": step.position,
          "stepTitle": step.title
        });
      }
    }

    /**
     * @ngdoc method
     * @private
     * @name updateTerms
     * @param {*} steps 
     * @param {*} items 
     * @param {*} term 
     * 
     * @description
     * update step terms
     * used by getRuleTerms
     */
    function updateTerms(steps, items, term) {
      _.forEach(items, function (item) {
        if (term.subject_id == item.id) {
          if (item.field_type === 'table' && term.operation === 'contains') {
            term.operation = term.column_contains_name ? 'contains' : 'table_contains';
          }
          term.conditions = term.subject_type === 'Capture' ? getRuleStatement(steps, item.step_id) : $filter('translate')('steps.logic.tooltip.kickOffForm');
          term.operation = term.subject_type === 'Capture' ? $filter('translate')('steps.logic.tooltip.stepFormFields.' + term.operation, {
            "label": item.label,
            "statement": term.statement,
            "column": term.column_contains_name ? _.find(item.columns, { id: parseInt(term.column_contains_name) }).label : void 0
          }) : $filter('translate')('steps.logic.tooltip.kickOffFormFields.' + term.operation, {
            "label": item.label,
            "statement": term.statement,
            "column": term.column_contains_name ? _.find(item.columns, { id: parseInt(term.column_contains_name) }).label : void 0
          });
        }
      });
    }

    self.getBlueprintFroalaToolbar = function (isDoc) {
      var toolbarsButtons = [];
      var toolbars = {
        moreText: {
          buttons: _.concat(
            toolbarsButtons, [
            "bold",
            "italic",
            "underline",
            "align",
            "formatOL",
            "formatUL",
            "codeTag",
            "emoticons",
            "fullscreen",
            "insertImage",
            "insertFile",
            "insertVideo",
            "insertLink",
            "insertTable",
            "embedly",
            "pageBreak"
          ]),
          align: 'left',
          buttonsVisible: 9 + toolbarsButtons.length
        }
      };
      if (isDoc) {
        toolbars.moreText.buttons.splice(5, 0, "paragraphFormat");
        toolbars.moreText.buttons.splice(6, 0, "fontSize");
        toolbars.moreText.buttons.splice(8, 0, "textColor");
        toolbars.moreText.buttons.splice(9, 0, "quote");
        toolbars.moreText.buttonsVisible = 15;
        toolbars.colorsBackground = ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'];
        toolbars.colorsStep = 5;
        toolbars.colorsText = ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'];
      } else {
        toolbars.moreText.buttons.splice(7, 0, "paragraphFormat");
        toolbars.moreText.buttons.splice(8, 0, "fontSize");
        toolbars.moreText.buttons.splice(9, 0, "textColor");
        toolbars.moreText.buttons.splice(10, 0, "quote");
        toolbars.moreText.buttonsVisible = _.findIndex(toolbars.moreText.buttons, function (toolbar) { return toolbar === 'fullscreen' }) + 1;
        toolbars.colorsStep = 5;
        toolbars.colorsText = ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'];
      }
      return toolbars;
    };

    self.sendCommand = function (args) {
      var defer = $q.defer();
      var thread = {
        content: FroalaService.getRawContent(args.content)
      };
      thread.label = 'comment';
      MessagesService[args.type + 'Step'](args.step.id, thread, args.isGuest, args.state, args.skipNotFound)
        .then(function (res) {
          defer.resolve(res.data);
        }, function (err) {
          defer.reject(err);
        });
      return defer.promise;
    };

    self.setAssigneeMaxUser = function (newAssignee, oldAssignee, maxAssignee) {
      var total = newAssignee.users.length + newAssignee.guests.length;
      if (total <= maxAssignee) {
        return newAssignee;
      } else {
        var removeAssigneeCount = total - maxAssignee;
        if (removeAssigneeCount > 0) {
          var diffGuest = _.difference(newAssignee.guests, oldAssignee.guests),
            addGuestCount = diffGuest.length;
          if (addGuestCount > removeAssigneeCount) {
            newAssignee.guests = _.dropRight(newAssignee.guests, removeAssigneeCount);
          } else {
            var removeNewEditGuest = _.remove(newAssignee.guests, function (item) {
              return !_.includes(diffGuest, item);
            });
            newAssignee.guests = removeNewEditGuest;
            var totalCount = (newAssignee.users).length + (newAssignee.guests).length,
              removeUserCount = totalCount - maxAssignee;
            newAssignee.users = _.dropRight(newAssignee.users, removeUserCount);
          }
        }
        return newAssignee;
      }
    };
  }
})();

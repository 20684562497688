/**
 * @ngdoc Directive
 * @name tallyfy.tallyfySelect
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('tallyfySelect', tallyfySelectDirective);

  /*@ngInject*/
  function tallyfySelectDirective($timeout) {
    return {
      restrict: "A",
      link: function ($scope, $element, $attrs) {
        var selectedIndex, element, toggleButton, dropdownMenu, items;

        var elementTimeout = $timeout(function () {
          init();

          /**
           * @name init
           * @returns void
           * 
           * @description
           * initialize method
           */
          function init() {
            selectedIndex = 0;
            element = $element[0];
            toggleButton = element.querySelector('.ty-select-button');
            dropdownMenu = element.querySelector('.ty-select-menu');
            if (toggleButton && dropdownMenu) {
              toggleButton.setAttribute('aria-haspopup', 'true');
              toggleButton.addEventListener('click', onToggleButtonClickHandler);
              toggleButton.addEventListener('keydown', onToggleButtonKeyDownHandler);
              toggleButton.addEventListener('blur', onToggleButtonBlurHandler);
              dropdownMenu.tabIndex = -1;
              items = dropdownMenu.getElementsByClassName('ty-select-menu-items');

              for (var i = 0; i < items.length; i++) {
                items[i].addEventListener('mousedown', function (e) {
                  submitValue(e, i);
                });
              }

            }
          }

          /**
           * @name highLightMenuItem
           * @returns void
           * @param {*} additionIndex
           * 
           * @description
           * highLightMenuItem by index selected 
           */
          function highLightMenuItem(additionIndex) {
            if (additionIndex === 0) {
              items[selectedIndex].classList.add('ty-select-menu-items-highlight');
            } else {
              items[selectedIndex].classList.remove('ty-select-menu-items-highlight');
              selectedIndex += additionIndex;
              selectedIndex = (items.length + selectedIndex) % items.length;
              items[selectedIndex].classList.add('ty-select-menu-items-highlight');
            }
          }

          /**
           * @name openDropdownMenu
           * @return void
           * @param {*} e event
           * @description
           * handling open dropdown menu
           */
          function openDropdownMenu(e) {
            toggleButton.setAttribute('aria-expanded', 'true');
            items[selectedIndex].classList.add('ty-select-menu-items-highlight');
            dropdownMenu.style.display = 'block';
            toggleButton.scrollIntoView({ behavior: "smooth" });
          }

          /**
           * @name closeDropdownMenu
           * @returns void
           * 
           * @description
           * handling closeDropdownMenu
           */
          function closeDropdownMenu() {
            toggleButton.removeAttribute('aria-expanded');
            items[selectedIndex].classList.remove('ty-select-menu-items-highlight');
            dropdownMenu.style.display = 'none';
          }

          /**
           * @name isDropdownOpen
           * @returns boolean
           * 
           * @description
           * to check is dropdown is open
           */
          function isDropdownOpen() {
            return toggleButton.getAttribute('aria-expanded');
          }

          /**
           * @name submitValue
           * @returns void
           * @param {*} e 
           * @param {*} index
           * 
           * @description
           * handling submit value when mouse is mousedown at menu item 
           */
          function submitValue(e, index) {
            angular.element(items[index]).triggerHandler('mousedown');
            closeDropdownMenu();
          }

          /**
           * @name onToggleButtonClickHandler
           * @returns void
           * @param {*} e event
           * 
           * @description
           * handler for toggle button click's event
           */
          function onToggleButtonClickHandler(e) {
            isDropdownOpen() ? closeDropdownMenu() : openDropdownMenu(e);
          }

          /**
           * @name onToggleButtonKeyDownHandler
           * @returns void
           * @param {*} e event
           * 
           * @description
           * handler for toggle button keydown's event 
           */
          function onToggleButtonKeyDownHandler(e) {
            var flag = false;
            if (e.keyCode === 32 || e.keyCode === 13) {
              isDropdownOpen() ? submitValue(e, selectedIndex) : openDropdownMenu(e);
              flag = true;
            } else if (e.keyCode === 40 || e.keyCode === 38) {
              isDropdownOpen() ? highLightMenuItem(e.keyCode === 40 ? 1 : -1) : openDropdownMenu(e);
              flag = true;
            } else {
              angular.noop();
            }
            if (flag) {
              e.stopPropagation();
              e.preventDefault();
            }
          }

          /**
           * @name onToggleButtonBlurHandler
           * @returns void
           * @param {*} e event
           * 
           * @description
           *  handler for toggle button blur's event
           */
          function onToggleButtonBlurHandler(e) {
            closeDropdownMenu();
          }

          /**
           * clear/remove all event and timeout
           */
          $scope.$on('$destroy', function () {
            if (toggleButton) {
              toggleButton.removeEventListener('click', onToggleButtonClickHandler);
              toggleButton.removeEventListener('keydown', onToggleButtonKeyDownHandler);
              toggleButton.removeEventListener('blur', onToggleButtonBlurHandler);
            }
            if (items && items.length) {
              for (var i = 0; i < items.length; i++) {
                items[i].removeEventListener('mousedown', function (e) {
                  submitValue(e, i);
                });
              }
            }
            $timeout.cancel(elementTimeout);
          });
        }, 0);
      }
    }
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy.public')
    .service('PublicTagService', publicTagService);

  /*@ngInject*/
  function publicTagService(PublicTagRepository) {
    var self = this;

    self.getPublicTags = function (args) {
      return PublicTagRepository.get(args);
    };
  }
})();
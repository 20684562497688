/**
 * @ngdoc Service
 * @name tallyfy.account.AccountService
 *
 * @module tallyfy.account
 *
 * @description
 * AccountService
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .service('AccountService', AccountService);

  AccountService.$inject = ['AccountRepository', '_', '$q'];

  function AccountService(AccountRepository, _, $q) {
    var customFiltersData, self = this;

    self.me = function () {
      return AccountRepository.me().$promise;
    };
    self.getAccount = function (arg) {
      var param = arg || {};
      return AccountRepository.getAccount(param).$promise;
    };
    self.removeAvatar = function () {
      return AccountRepository.removeAvatar().$promise;
    };
    self.updateAccount = function (id, data) {
      return AccountRepository.updateAccount({ id: id }, data).$promise;
    };
    self.updateUserAccount = function (id, data) {
      return AccountRepository.updateUserAccount({ id: id }, data).$promise;
    };
    self.getGeneralInfo = function (account) {
      return _.pick(account, ['id', 'email', 'first_name', 'last_name', 'phone', 'job_title', 'job_description', 'team', 'timezone', 'country', 'role', 'date_format', 'cadence_days']);
    };
    self.refreshKeys = function () {
      return AccountRepository.refreshKey().$promise;
    };
    self.getUserPreference = function (id) {
      return id ? AccountRepository.getPreference({ id: id }).$promise : AccountRepository.getMyPreference().$promise;
    };
    self.setUserPreference = function (data) {
      var preference = data.preference || data;
      return _.get(data, 'id') ? AccountRepository.setPreference({ id: data.id }, preference).$promise : AccountRepository.saveMyPreference({}, preference).$promise;
    };
    self.getPreference = function (preferences, funKey, defaultVal) {
      var preferenceValue = _.find(preferences, { 'slug': funKey });
      if (!_.isEmpty(preferenceValue) && !_.isUndefined(preferenceValue)) {
        return preferenceValue.value;
      } else {
        return defaultVal;
      }
    };
    /**
     * get run views
     * @returns $promise
     */
    self.getRunView = function (params) {
      var deferred = $q.defer();
      if (_.isUndefined(customFiltersData)) {
        AccountRepository.getRunView(params).$promise.then(function (response) {
          customFiltersData = response;
          deferred.resolve(customFiltersData);
        });
      } else {
        deferred.resolve(customFiltersData);
      }
      return deferred.promise;
    };

    self.refreshCustomRunsFiltersStore = function (params) {
      customFiltersData = undefined;
      self.getRunView(params);
    };

    /**
     * create run view
     * @param {*} data
     * @returns $promise
     */
    self.setRunView = function (data) {
      return AccountRepository.setRunView({}, data).$promise;
    };
    /**
     * delete run view by id
     * @param {*} id 
     * @returns $promise
     */
    self.deleteRunView = function (id) {
      return AccountRepository.deleteRunView(id).$promise;
    };
    /**
     * update run view by id
     * @param {*} id 
     * @param {*} data
     * @returns $promise
     */
    self.updateRunView = function (id, data) {
      return AccountRepository.updateRunView(id, data).$promise;
    };

    /**
     * @ngdoc method
     * @returns array of string
     * 
     * @description
     * get available props account preference
     */
    self.getAccountPreferences = function () {
      return [
        'background',
        'daily_digest',
        'email_problems',
        'email_comments',
        'email_tasks_completed',
        'email_runs_updated',
        'send_emails',
        'email_other_completed_tasks',
        'google_2fa_switch',
        'email_comments',
        'email_mentioned',
        'email_deadline',
        'task_update',
        'assignee_changed',
        'email_process_completed'
      ];
    };

    self.updateCadenceDays = function (params, data) {
      return AccountRepository.updateCadenceDays(params, data).$promise;
    };

    /**
     * update New admin
     * @param {*} id
     * @param {*} data
     * @returns $promise
     */
    self.setNewAdmin = function (id, data) {
      return AccountRepository.updateNewAdmin({ id: id }, data).$promise;
    };

    self.convertMemberToGuest = function (id) {
      return AccountRepository.convertMemberToGuest({ id: id }).$promise;
    };
  }
})();
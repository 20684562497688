/**
 * @ngdoc Component
 * @name tallyfy.publicBlueprintActivityModal
 * @module tallyfy
 *
 * @description
 * modal for public blueprint activity tracking log
 *
 * @author Kiran Sompura (gmail::kiranv.sompura@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('publicBlueprintActivityModal', {
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      templateUrl: 'app/modules/support/components/public-blueprints/components/supportPublicBlueprints/modal/public-blueprint-activity-modal.component.html',
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onCancelClick = onCancelClick;
         
          function onInit() { }
          
          function onChanges() { }
          
          function onDestroy() { }

          function onCancelClick() {
            $ctrl.dismiss({ $value: 'cancel' });
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy.account')
    .component('welcomeModal', {
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      templateUrl: 'app/modules/account/welcomeModal/welcome-modal.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onContinueButtonClick = onContinueButtonClick;

          function onInit() {
            var welcomeMessageSnippetId = $rootScope.identity.default_organization.onboarding_snippet;
            $ctrl.template = $rootScope.organizationTemplates[welcomeMessageSnippetId] ? $rootScope.organizationTemplates[welcomeMessageSnippetId].template : null;
          }

          function onChanges() { }
          function onDestroy() { }

          function onContinueButtonClick() {            
            $ctrl.close();
          }

        }
    });
})();
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('TranslationService', translationService);

  /*@ngInject*/
  function translationService(_, $rootScope, USER_STATE, TranslationRepository) {
    var self = this, totalTranslateInProgress = 0;

    /**
     * @ngdoc method
     * @name getEmailLogs
     * 
     * @description
     * get all email logs
     */
    self.translateContent = function (params, body) {
      return TranslationRepository.translateContent(params, body);
    };

    self.test = function (params, body) {
      params = angular.extend(params, { action: 'test' });
      return TranslationRepository.test(params, body).$promise;
    };

    self.getMyAppLanguage = function (preferences) {
      var appLangPreference = _.find(preferences, { slug: 'app_language' });
      return _.get(appLangPreference, 'metadata.lang', $rootScope.currentLanguage);
    };

    self.getMyContentLanguage = function () {
      var preferences = ($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : ($rootScope.userState === USER_STATE.GUEST ? $rootScope.identity.guest.preferences : [])),
        contentLangPreference = _.find(preferences, { slug: 'content_language' });
      return _.get(contentLangPreference, 'metadata.lang', $rootScope.currentLanguage);
    };

    self.setTotalTranslationInProgress = function (val) {
      totalTranslateInProgress = totalTranslateInProgress + val;
    };

    self.geTotalContentTranslationInProgress = function () {
      return totalTranslateInProgress;
    };

    /**
     * @name getAppLanguages
     * @description 
     * All supported languages on Tallyfy app
     * 
     * @returns array of the languages
     */
    self.getAppLanguages = function () {
      return [{
        value: 'de',
        label: 'German'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'es',
        label: 'Spanish'
      }, {
        value: 'fr',
        label: 'French'
      }, {
        value: 'ja',
        label: 'Japanese'
      }, {
        value: 'nl',
        label: 'Dutch'
      }, {
        value: 'pt',
        label: 'Portuguese'
      }, {
        value: 'pt-br',
        label: 'Portuguese (Brazil)'
      }, {
        value: 'vi',
        label: 'Vietnamese'
      }, {
        value: 'zh',
        label: 'Chinese'
      }];
    };

    /**
     * @name getContentLanguages
     * @description 
     * All supported content languages
     * 
     * @returns array of languages
     */
    self.getContentLanguages = function () {
      return [{
        value: 'no_translation',
        label: 'No Translation'
      }, {
        value: 'af',
        label: 'Afrikaans'
      }, {
        value: 'sq',
        label: 'Albanian'
      }, {
        value: 'am',
        label: 'Amharic'
      }, {
        value: 'ar',
        label: 'Arabic'
      }, {
        value: 'hy',
        label: 'Armenian'
      }, {
        value: 'as',
        label: 'Assamese'
      }, {
        value: 'az',
        label: 'Azerbaijani (Latin)'
      }, {
        value: 'bn',
        label: 'Bangla'
      }, {
        value: 'ba',
        label: 'Bashkir'
      }, {
        value: 'eu',
        label: 'Basque'
      }, {
        value: 'bs',
        label: 'Bosnian (Latin)'
      }, {
        value: 'bg',
        label: 'Bulgarian'
      }, {
        value: 'yue',
        label: 'Cantonese (Traditional)'
      }, {
        value: 'ca',
        label: 'Catalan'
      }, {
        value: 'lzh',
        label: 'Chinese (Literary)'
      }, {
        value: 'zh-Hans',
        label: 'Chinese Simplified'
      }, {
        value: 'zh-Hant',
        label: 'Chinese Traditional'
      }, {
        value: 'hr',
        label: 'Croatian'
      }, {
        value: 'cs',
        label: 'Czech'
      }, {
        value: 'da',
        label: 'Danish'
      }, {
        value: 'prs',
        label: 'Dari'
      }, {
        value: 'dv',
        label: 'Divehi'
      }, {
        value: 'nl',
        label: 'Dutch'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'et',
        label: 'Estonian'
      }, {
        value: 'fo',
        label: 'Faroese'
      }, {
        value: 'fj',
        label: 'Fijian'
      }, {
        value: 'fil',
        label: 'Filipino'
      }, {
        value: 'fi',
        label: 'Finnish'
      }, {
        value: 'fr',
        label: 'French'
      }, {
        value: 'fr-ca',
        label: 'French (Canada)'
      }, {
        value: 'gl',
        label: 'Galician'
      }, {
        value: 'ka',
        label: 'Georgian'
      }, {
        value: 'de',
        label: 'German'
      }, {
        value: 'el',
        label: 'Greek'
      }, {
        value: 'gu',
        label: 'Gujarati'
      }, {
        value: 'ht',
        label: 'Haitian Creole'
      }, {
        value: 'he',
        label: 'Hebrew'
      }, {
        value: 'hi',
        label: 'Hindi'
      }, {
        value: 'mww',
        label: 'Hmong Daw (Latin)'
      }, {
        value: 'hu',
        label: 'Hungarian'
      }, {
        value: 'is',
        label: 'Icelandic'
      }, {
        value: 'id',
        label: 'Indonesian'
      }, {
        value: 'ikt',
        label: 'Inuinnaqtun'
      }, {
        value: 'iu',
        label: 'Inuktitut'
      }, {
        value: 'iu-Latn',
        label: 'Inuktitut (Latin)'
      }, {
        value: 'ga',
        label: 'Irish'
      }, {
        value: 'it',
        label: 'Italian'
      }, {
        value: 'ja',
        label: 'Japanese'
      }, {
        value: 'kn',
        label: 'Kannada'
      }, {
        value: 'kk',
        label: 'Kazakh'
      }, {
        value: 'km',
        label: 'Khmer'
      }, {
        value: 'tlh-Latn',
        label: 'Klingon'
      }, {
        value: 'tlh-Piqd',
        label: 'Klingon (plqaD)'
      }, {
        value: 'ko',
        label: 'Korean'
      }, {
        value: 'ku',
        label: 'Kurdish (Central)'
      }, {
        value: 'kmr',
        label: 'Kurdish (Central)'
      }, {
        value: 'ky',
        label: 'Kyrgyz (Cyrillic)'
      }, {
        value: 'lo',
        label: 'Lao'
      }, {
        value: 'lv',
        label: 'Latvian'
      }, {
        value: 'lt',
        label: 'Lithuanian'
      }, {
        value: 'mk',
        label: 'Macedonian'
      }, {
        value: 'mg',
        label: 'Malagasy'
      }, {
        value: 'ms',
        label: 'Malay (Latin)'
      }, {
        value: 'ml',
        label: 'Malayalam'
      }, {
        value: 'mt',
        label: 'Maltese'
      }, {
        value: 'mi',
        label: 'Maori'
      }, {
        value: 'mr',
        label: 'Marathi'
      }, {
        value: 'mn-Cyrl',
        label: 'Mongolian (Cyrillic)'
      }, {
        value: 'mn-Mong',
        label: 'Mongolian (Traditional)'
      }, {
        value: 'my',
        label: 'Myanmar'
      }, {
        value: 'ne',
        label: 'Nepali'
      }, {
        value: 'nb',
        label: 'Norwegian'
      }, {
        value: 'or',
        label: 'Odia'
      }, {
        value: 'ps',
        label: 'Pashto'
      }, {
        value: 'fa',
        label: 'Persian'
      }, {
        value: 'pl',
        label: 'Polish'
      }, {
        value: 'pt',
        label: 'Portuguese (Brazil)'
      }, {
        value: 'pt-pt',
        label: 'Portuguese (Portugal)'
      }, {
        value: 'pa',
        label: 'Punjabi'
      }, {
        value: 'otq',
        label: 'Queretaro Otomi'
      }, {
        value: 'ro',
        label: 'Romanian'
      }, {
        value: 'ru',
        label: 'Russian'
      }, {
        value: 'sm',
        label: 'Samoan (Latin)'
      }, {
        value: 'sr-Cyrl',
        label: 'Serbian (Cyrillic)'
      }, {
        value: 'sr-Latn',
        label: 'Serbian (Latin)'
      }, {
        value: 'sk',
        label: 'Slovak'
      }, {
        value: 'sl',
        label: 'Slovenian'
      }, {
        value: 'so',
        label: 'Somali (Arabic)'
      }, {
        value: 'es',
        label: 'Spanish'
      }, {
        value: 'sw',
        label: 'Swahili (Latin)'
      }, {
        value: 'sv',
        label: 'Swedish'
      }, {
        value: 'ty',
        label: 'Tahitian'
      }, {
        value: 'ta',
        label: 'Tamil'
      }, {
        value: 'tt',
        label: 'Tatar (Latin)'
      }, {
        value: 'te',
        label: 'Telugu'
      }, {
        value: 'th',
        label: 'Thai'
      }, {
        value: 'bo',
        label: 'Tibetan'
      }, {
        value: 'ti',
        label: 'Tigrinya'
      }, {
        value: 'to',
        label: 'Tongan'
      }, {
        value: 'tr',
        label: 'Turkish'
      }, {
        value: 'tk',
        label: 'Turkmen (Latin)'
      }, {
        value: 'uk',
        label: 'Ukrainian'
      }, {
        value: 'hsb',
        label: 'Upper Sorbian'
      }, {
        value: 'ur',
        label: 'Urdu'
      }, {
        value: 'ug',
        label: 'Uyghur (Arabic)'
      }, {
        value: 'uz',
        label: 'Uzbek (Latin)'
      }, {
        value: 'vi',
        label: 'Vietnamese'
      }, {
        value: 'cy',
        label: 'Welsh'
      }, {
        value: 'yua',
        label: 'Yucatec Maya'
      }, {
        value: 'zu',
        label: 'Zulu'
      }];
    };
  }
})();
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('uiNav', uiNav);
  /*@ngInject*/
  function uiNav($filter, BeaconService) {
    var directive = {
      restrict: 'AC',
      link: function (scope, el, attr) {
        el.find('a').bind('click', function (e) {
          /**
           * @function
           * @name giveFeedback
           * @description Open helpscout beacon feedback modal
           */
          scope.giveFeedback = function () {
            BeaconService.prefill($filter('translate')('beacon.feedback'));
            BeaconService.open();
          };

          /**
           * @function
           * @name helpTicket
           * @description Open helpscout beacon question modal
           */
          scope.helpTicket = function () {
            BeaconService.prefill($filter('translate')('beacon.help'));
            BeaconService.open();
          };
        });
      }
    };
    return directive;
  }
})();

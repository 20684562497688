/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.ldap
 * @module tallyfy.integration
 *
 * @description
 * A component to manage ldap integration
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .component('ldap', {
      bindings: {
        type: '<',
        index: "<"
      },
      templateUrl: 'app/modules/integration/ldap/ldap.html',
      controller:
       /*@ngInject*/
        function LDapController(_, blockUI) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('ldapIntegration');
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
          }

          //controller ends
        }
    });
})();
/**
 * @ngdoc factory
 * @name pollingService
 * @module tallyfy
 *
 * @description 
 * Service for polling user records
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .factory('pollingService', pollingService);
    /*@ngInject*/
    function pollingService(UsersService, store, $timeout, $rootScope, _, $q, preferencesStore, GuestTasksRepository, GroupsService) {
      var loadTime = 2100000,
        loadPromise,
        organizationID;

      return {
        startPolling: startPolling,
        cancelPolling: cancelPolling
      };

      /**
       * @function
       * @name startPolling
       * @description This method will start the polling 
       * to fetch record from the API
       * @param {*} userData
       * @param {*} prefData
       * @param {*} orgID
       */
      function startPolling(userData, prefData, orgID) {
        organizationID = orgID || _.get($rootScope, 'identity.default_organization.id');
        store.setUsers(userData);
        preferencesStore.setPreferences(_.get(prefData, 'data'));
        
        // Below is to avoid delay on first load at Principal Service
        store.clearGuestsStore();
        store.getGuests();
        store.clearGroupsStore();
        store.getGroups();
        
        nextLoad();
      }

      /**
       * @function
       * @name getPollingData
       * @description It will get users and preference from API
       * update the user arrays variables in store service,
       * update the preferences array in preferenceStore service
       */
      function getPollingData() {
        $q.all([
          UsersService.getLighterUsersList({
            org: organizationID,
            'X-Tallyfy-Client': 'BackgroundRefresh'
          }),
          UsersService.getPreferences({
            org: organizationID,
            'X-Tallyfy-Client': 'BackgroundRefresh'
          }),
          GuestTasksRepository.get({
            'X-Tallyfy-Client': 'BackgroundRefresh'
          }),
          GroupsService.getOrgGroups({
            'X-Tallyfy-Client': 'BackgroundRefresh'
          })
        ]).then(function (response) {
          store.setUsers(_.get(response, '[0]'));
          preferencesStore.setPreferences(_.get(response, '[1].data'));
          store.setGuests(_.get(response, '[2]'));
          store.setGroups(_.get(response, '[3]'));
          nextLoad();
        }).catch(function () {
          nextLoad();
        });
      }

     /**
       * @function
       * @name nextLoad
       * @description 
       * Cancel the polling before start a new one
       * poll the backend every loadTime ms
      */
      function nextLoad() {
        cancelPolling();
        loadPromise = $timeout(getPollingData, loadTime);
      }

      /**
       * @function
       * @name cancelPolling
       * @description Always clear the timeout when APP gets logout,
       * otherwise it will keep polling and leak memory
       */
      function cancelPolling() {
        $timeout.cancel(loadPromise);
      }
    }
})();

/**
 * @ngdoc Component
 * @name tallyfy.compliance.Component.compliance
 * @module tallyfy.compliance
 *
 * @description
 * A component to manage compliance
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.compliance')
    .component('compliance', {
      templateUrl: 'app/modules/compliance/compliance.html',
      controller:
      /*@ngInject*/
      function () {
        var $ctrl = this;

        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;
      
        /**
         * @function $onInit
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { }

        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }
      }
    });
})();
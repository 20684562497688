/**
 * @ngdoc Component
 * @name paymentFailure
 * @module tallyfy.billing
 *
 * @description
 * payment failure component
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com)
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.billing')
    .component('paymentFailure', {
      templateUrl: 'app/components/paymentFailure/payment-failure.html',
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          /**
           * angularjs component life cycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * 
           */
          function onInit() { }

          /**
           * 
           */
          function onDestroy() { }

          /**
           * 
           */
          function onChanges() { }

        }

    });
})();
/**
 * @ngdoc directive
 * @name tallyfy.numberOnly
 * @author Adi Winata ( gmail::adheegm@gmail.com)
 **/
(function () {
  "use strict";
  angular.module("tallyfy").directive('numberOnly', numberOnly);

  function numberOnly() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element) {

        function keydownHandler(event) {
          var $input = $(this);
          var value = $input.val();
          value = value.replace(/[^0-9]/g, '')
          $input.val(value);
          if (event.which == 64 || event.which == 16) {
            return false;
          } else if (event.which >= 48 && event.which <= 57) {
            return true;
          } else if (event.which >= 96 && event.which <= 105) {
            return true;
          } else if ([8, 13, 27, 37, 38, 39, 40].indexOf(event.which) > -1) {
            return true;
          } else {
            event.preventDefault();
            return false;
          }
        }

        scope.$on('$destroy', function () {
          element.off('keydown', keydownHandler)
        });

        element.on('keydown', keydownHandler);
      }
    };
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.scrollOnClick
 * @restrict 'A'
 * @element ANY
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 **/
(function () {
    'use strict';
    angular
        .module('tallyfy')
        .directive('scrollOnClick', scrollOnClick);


    function scrollOnClick() {
        return {
            restrict: 'A',
            link: function(scope, $elm) {
                $elm.on('click', function() {
                    angular.element("body").animate({scrollTop: $elm.offset().top - 90}, "slow");
                });
            }
        };
    }

})();

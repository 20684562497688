(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.assets.factory.AssetsRepository
   * @module tallyfy.assets
   *
   * @description
   * Create Rest resource for assets
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.assets')
    .factory('AssetsRepository', AssetsRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  AssetsRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * AssetsRepository construct 
   */
  function AssetsRepository(BaseRepository) {
    var repository;

    repository = BaseRepository('/organizations/:org/file/:action/:sub_action/:id', {
      org: 'org_id'
    }, {});

    return repository;
  }
})(); 
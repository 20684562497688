/**
 * @ngdoc component
 * @name tallyfy.taskStatus
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('taskStatus', {
      template: '<div ng-include="$ctrl.templateUrl">',
      bindings: {
        isHasIssue: '<',
        taskType: '@',
        taskStatus: '@',
        taskStatusLabel: '<?',
        statusClickHandler: '&?',
        isActive: '=',
        taskId: '<'
      },
      controller:
        /*@ngInject*/
        function ($timeout) {
          var $ctrl = this,
            rippleEffectTimeout,
            templatePreURL = 'app/components/compact/items/taskStatusIcon/templates/';

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.getTaskIconClass = getTaskIconClass;
          $ctrl.onStatusClick = onStatusClick;

          function onInit() {
            setTemplate();
          }

          function onChanges(changes) {
            if ((changes.isHasIssue && !changes.isHasIssue.isFirstChange()) || (changes.taskType && !changes.taskType.isFirstChange())) {
              setTemplate();
            }
          }

          function onDestroy() {
            rippleEffectTimeout ? $timeout.cancel(rippleEffectTimeout) : angular.noop();
          }

          function setTemplate() {
            $ctrl.templateUrl = templatePreURL + getTemplateByType() + '.html';
          }

          function getTemplateByType() {
            return ($ctrl.taskType === 'approval' && !$ctrl.isHasIssue) ? 'approval' : 'task';
          }

          function getTaskIconClass() {
            if ($ctrl.isHasIssue) {
              return 'fa-exclamation-triangle';
            } else if ($ctrl.taskType === 'prerun' || $ctrl.taskType === 'task') {
              return 'fa-check';
            } else if ($ctrl.taskType === 'email') {
              return 'fa-envelope v-m';
            } else if ($ctrl.taskType === 'expiring' || $ctrl.taskType === 'expiring_email') {
              if ($ctrl.taskStatusLabel === 'expired' || $ctrl.taskStatusLabel === 'expiring_email') {
                return 'fa-clock';
              }
              return $ctrl.taskType === 'expiring_email' ? 'fa-paper-plane' : 'fa-thumbs-up';
            }
          }

          function onStatusClick(e, action, id) {
            if(rippleEffectTimeout) {
               $timeout.cancel(rippleEffectTimeout);
            }
            $('input[id='+ id + $ctrl.taskId +']').addClass("add-task-ripple");
            rippleEffectTimeout = $timeout(function () {
              $('input[id='+ id + $ctrl.taskId +']').removeClass("add-task-ripple");
            }, 1500);
            var params = { e: e };
            if (action) {
              $ctrl.action = action;
              angular.extend(params, { action: action });
            }
            $ctrl.statusClickHandler(params);
          }
        }
    });
})();
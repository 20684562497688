(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.organizations.access.roles.factory.RolesRepository
   * @module tallyfy.organizations.access.roles
   *
   * @description
   * Create Rest resource for roles
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.organizations.access.roles')
    .factory('RolesRepository', RolesRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  RolesRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * RolesRepository construct 
   */
  function RolesRepository(BaseRepository) {
    return BaseRepository('/organizations/:org/roles/:id/:action', {
      org: 'org_id'
    }, {});
  }
})(); 
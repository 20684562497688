/**
 * @ngdoc overview
 * @name confirmModal
 *
 * @module confirmModal
 *
 * @description
 * this is an independent module for confirm modal box
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('confirmModal', ['tmh.dynamicLocale', 'ui.bootstrap']);
})();
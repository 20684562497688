(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('openAIRepository', openAIRepository);

  /*@ngInject*/
  function openAIRepository(BaseRepository) {
    var repository = BaseRepository('/functions/:action/:actionId', {
      action: '@action',
      actionId: '@actionId'
    });


    repository.generateTextCompletion = function (params, body) {
      params.action = 'text-ai';
      params.actionId = 'text-completion';
      return repository.$create(params, body);
    }

    repository.generateChatCompletion = function (params, body) {
      params.action = 'completion';
      params.actionId = 'chat';
      return repository.$create(params, body);
    }

    repository.generateDocChatCompletion = function (params, body) {
      params.action = 'completion';
      params.actionId = 'doc-chat';
      return repository.$create(params, body);
    }

    return repository;
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.settings.component.myPersonalization
 * @module tallyfy.settings
 *
 * @description
 * A component to update the personalized settings of logged in user
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .component('myPersonalization', {
      templateUrl: 'app/modules/manage/components/myAccount/myPersonalization/my-personalization.html',
      controller:
        /*@ngInject*/
        function ($rootScope, $scope, AccountService, Growl, blockUI, TranslationService, $timeout, $stateParams, $log, _, TimeZone, $filter, $state, $q, USER_STATE, ConfirmOnExitService, TFY_EVENTS, preferencesStore, Helper, OrganizationsService, moment) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('myPersonalization'),
            growl = new Growl(),
            stateChangeStart,
            selectedDateFormatWatcherHandler,
            date_format = OrganizationsService.getDateFormat();

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.resetForm = resetForm;
          $ctrl.saveUserPreference = saveUserPreference;
          $ctrl.getSaveText = getSaveText;
          $ctrl.save = save;
          $ctrl.setHardRelativeDatePreferences = setHardRelativeDatePreferences;
          $ctrl.saveAppLanguage = saveAppLanguage;
          $ctrl.saveContentLanguage = saveContentLanguage;
          $ctrl.getDateValue = getDateValue;

          /**
           * public properties
           */
          $ctrl.account = {};
          $ctrl.accountForm = {};
          $ctrl.timeZones = [];
          $ctrl.isAccountSaved = false;
          $ctrl.supportedAppLanguages = TranslationService.getAppLanguages();
          $ctrl.supportedContentLanguages = TranslationService.getContentLanguages();
          $ctrl.isTimezoneDropdownOpen = false;
          $ctrl.isDateFormatDropdownOpen = false;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            getCurrentAccount();
            setLanguagePreference();
            $ctrl.play_tasks_sound = AccountService.getPreference($rootScope.identity.preferences, 'play_tasks_sound', 'yes');
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            var hardRelativeDatePreferencesData = _.find($rootScope.identity.preferences, { 'slug': 'hard_relative_date' });
            $ctrl.isHardRelativeDate = !!(_.get(hardRelativeDatePreferencesData, 'value') === 'yes');
            $ctrl.isAzureCognitiveServiceEnabled = isAzureConfigEnabled($rootScope.identity.default_organization);
            $ctrl.listOrgDateFormat = OrganizationsService.getDeadlineFormat();
            $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
            var orgDateFormat = _.find($rootScope.identity.preferences, { 'slug': 'organization_date_format' });
            if (orgDateFormat) {
              $ctrl.organizationDateFormat = orgDateFormat.metadata;
            } else {
              $ctrl.organizationDateFormat = _.find($ctrl.listOrgDateFormat, { format: date_format });
              $ctrl.exampleDate = moment(new Date()).format(date_format);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            stateChangeStart();
            selectedDateFormatWatcherHandler();
          }

          /**
           * @ngdoc methods
           * @name saveUserPreference
           * @param {string} playTasksSound value
           * return void
           * 
           * @description 
           * Method handling user preference changed
           */
          function saveUserPreference(playTasksSound) {
            if (!Helper.checkAccessAuthority())
              return;
            AccountService.setUserPreference({
              slug: "play_tasks_sound",
              value: playTasksSound
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function () {
            });
          }

          /**
           * @ngdoc method
           * @name getCurrentAccount
           * @private
           * @description Get current user account records
           */
          function getCurrentAccount() {
            blockUI.start();
            $q.all([
              AccountService.getAccount({
                with: 'country,role'
              }),
              TimeZone.getAll()
            ]).then(function (response) {
              $ctrl.account = AccountService.getGeneralInfo(_.get(response, '[0].data'));
              $ctrl.defaultValue = angular.copy($ctrl.account);
              $ctrl.timeZones = _.get(response, '[1]');
              $ctrl.account.timezone = _.find($ctrl.timeZones, { value: $ctrl.account.timezone });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name save
           * @private
           * @description Save user account records
           */
          function save(isDateFormatSave) {
            var deferred = $q.defer();
            if ($ctrl.accountForm.$invalid) {
              return;
            }
            blockUI.start();
            var request = AccountService.getGeneralInfo($ctrl.account);
            request.timezone = _.get($ctrl.account, 'timezone.value') || $ctrl.account.timezone;
            AccountService.updateAccount($stateParams.id, request).then(function (response) {
              blockUI.stop();
              $ctrl.accountForm.$valid = false;
              setDateformat();
              getCurrentAccount();
              saveAppLanguage();
              saveUserPreference($ctrl.play_tasks_sound);
              setHardRelativeDatePreferences($ctrl.isHardRelativeDate);
              saveContentLanguage();
              $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, response.data);
              if (isDateFormatSave) {
                if (_.get($rootScope.identity, 'id') === _.get(response.data, 'id')) {
                  _.set($rootScope.identity, 'date_format', _.get(response.data, 'date_format'));
                }
                growl.success($filter('translate')('myAccount.messages.update.dateFormat'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              } else {
                growl.success($filter('translate')('myAccount.messages.update.account'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
              deferred.resolve({ 'status': 'Saved' });
              $ctrl.isAccountSaved = true;
              $ctrl.defaultValue = angular.copy(response.data);
              $ctrl.accountForm.$setPristine();
              $ctrl.accountForm.$setUntouched();
            }).catch(function (error) {
              var erorrMessages = _.get(error, 'data.errors');
              if (erorrMessages) {
                angular.forEach(erorrMessages, function (value, key) {
                  $ctrl.accountForm[key].$invalid = true;
                  $ctrl.accountForm.email.$touched = true;
                  $ctrl.accountForm[key].$error[key] = value;
                });
              }
              blockUI.stop();
              deferred.reject({ 'status': 'NotSaved' });
              $ctrl.isAccountSaved = false;
            });
            return deferred.promise;
          }
          
          function setDateformat() {
            AccountService.setUserPreference({
              id: $rootScope.identity.id,
              preference: {
                slug: "organization_date_format",
                value: "yes",
                metadata: $ctrl.organizationDateFormat
              }
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function (error) {
              $log.error(error);
            });
          }

          function getDateValue(value) {
            $ctrl.exampleDate = moment(new Date()).format(value.format);
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @private
           * @description Reset form
           * @returns {Void}
           */
          function resetForm() {
            $ctrl.account = angular.copy($ctrl.defaultValue);
            setLanguagePreference();
            setHardRelativeDatePreferences();
            getCurrentAccount();
            var orgDateFormat = _.find($rootScope.identity.preferences, { 'slug': 'organization_date_format' });
            $ctrl.organizationDateFormat = orgDateFormat.metadata;
            $ctrl.play_tasks_sound = AccountService.getPreference($rootScope.identity.preferences, 'play_tasks_sound', 'yes');
            $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
            $ctrl.accountForm.$setPristine();
            $ctrl.accountForm.$setUntouched();
          }

          /**
           * @function
           * @name getSaveText
           * @description get the text of save button
           * @public
           * @returns {string}
           */
          function getSaveText() {
            if ($ctrl.isAccountSaved && $ctrl.accountForm.$pristine) {
              return $filter('translate')('myAccount.form.buttons.update_profile.savedLabel');
            }
            return $filter('translate')('myAccount.form.buttons.update_profile.label');
          }

          /**
           * @function
           * @name setHardRelativeDatePreferences
           * @description set for hard date and relative date format
           * @public
           * @returns {string}
           */
          function setHardRelativeDatePreferences(boolean) {
            $ctrl.isHardRelativeDate = boolean;
            var value = boolean === true ? 'yes' : 'no';
            AccountService.setUserPreference({
              slug: 'hard_relative_date',
              value: value
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
              $ctrl.isHardRelativeDate = !!(_.get(response.data, 'value') === 'yes');
            }, function () { });
          }

          function saveAppLanguage(e) {
            if (!Helper.checkAccessAuthority())
              return;
            AccountService.setUserPreference({
              slug: "app_language",
              value: $ctrl.appLanguage ? 'yes' : 'no',
              metadata: $ctrl.appLanguage ? { lang: $ctrl.appLanguage } : void 0
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
              setLanguagePreference();
            });
          }

          function saveContentLanguage() {
            if (!Helper.checkAccessAuthority())
              return;
            AccountService.setUserPreference({
              slug: "content_language",
              value: $ctrl.contentLanguage ? 'yes' : 'no',
              metadata: $ctrl.contentLanguage ? { lang: $ctrl.contentLanguage } : void 0
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
              $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
            })
          }

          function isAzureConfigEnabled(orgInfo) {
            var result = false;
            if (orgInfo) {
              var config = OrganizationsService.getAzureCognitiveServiceConfig(orgInfo);
              if (!Helper.isObjectEmpty(config)) {
                result = config.key && config.resource_name && config.region;
              }
            }
            return result;
          }

          function setLanguagePreference() {
            $ctrl.appLanguage = TranslationService.getMyAppLanguage($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : $rootScope.identity.guest.preferences);
          }

          selectedDateFormatWatcherHandler = $scope.$watch('$ctrl.organizationDateFormat', function (value) {
            if (value) {
              getDateValue(value);
            }
          }, true);

          /**
            * Event handler when user navigates to anither tab/state
            * @type {*|(function())}
            */
          stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (angular.isDefined($ctrl.accountForm) && !$ctrl.accountForm.$pristine) {
              event.preventDefault();
              ConfirmOnExitService.checkStatus($ctrl.accountForm).then(function (data) {
                if (data.status === 'YES') {
                  $ctrl.accountForm.$submitted = true;
                  save().then(function (data) {
                    if (data.status === 'Saved') {
                      $state.go(toState);
                      resetForm();
                    }
                  });
                  return;
                }
                resetForm();
                $state.go(toState);
              });
            }
          });
        }
    });
})();

/**
 * @ngdoc Filter
 * @name supportDateFormat
 * @module tallyfy
 * @description
 * Convert the date to support format
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('supportDateFormat', ['OrganizationsService', '$filter', 'DateUtils', '$rootScope', function (OrganizationsService, $filter, DateUtils, $rootScope) {
      return function (date) {
        var tzStr = ($rootScope.identity.timezone) ? ' - ' + $rootScope.identity.timezone + ' TZ' : '',
          dateFormat = OrganizationsService.getDateFormat();

        return (date ? $filter('amDateFormat')(DateUtils.toTimezone(date).value(), dateFormat) +' (' + $filter('amTimeAgo')(DateUtils.toTimezone(date).value()) + ')' + tzStr : '-');
      };
    }]);
})();
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('RegularUserTableService', RegularUserTableService);

  function RegularUserTableService(_, $filter, Helper) {
    var self = this;

    self.getOptions = function (type) {
      return {
        columns: !!type && type === 'cadences' ? getCadencesColumns() : getColumns(),
        dataSource: new kendo.data.DataSource({
          pageSize: 10
        }),
        noRecords: {
          template: kendo.template($('#regularUserTableNoData').html())
        },
        resizable: true
      };
    };

    // guest table columns
    var getColumns = function () {
      return [{
        field: "status",
        exportKeyMap: {
          key: "status",
          label: $filter('translate')('regularUsers.table.header.status')
        },
        headerTemplate: kendo.template($('#regularUserTableStatusHederColumn').html()),
        template: kendo.template($('#regularUserTableStatusColumn').html()),
        width: 180
      }, {
        field: "lastAccessed",
        exportKeyMap: {
          key: "last_accessed_at",
          label: $filter('translate')('regularUsers.table.header.lastAccessed')
        },
        headerTemplate: kendo.template($('#regularUserTableLastAccessedAtHederColumn').html()),
        template: kendo.template($('#regularUserTableLastAccessedColumn').html()),
        width: 180
      }, {
        field: "firstCreated",
        exportKeyMap: {
          key: "created_at",
          label: $filter('translate')('regularUsers.table.header.firstCreated')
        },
        headerTemplate: kendo.template($('#regularUserTableFirstCreatedHederColumn').html()),
        template: kendo.template($('#regularUserTableFirstCreatedColumn').html()),
        width: 180
      }, {
        field: "member",
        exportKeyMap: {
          key: "full_name",
          label: $filter('translate')('regularUsers.table.header.member')
        },
        headerTemplate: kendo.template($('#regularUserTableMemberHederColumn').html()),
        template: kendo.template($('#regularUserTableMemberColumn').html()),
        width: 200
      }, {
        field: "role",
        exportKeyMap: {
          key: "role",
          label: $filter('translate')('regularUsers.table.header.role')
        },
        headerTemplate: kendo.template($('#regularUserTableRoleHederColumn').html()),
        template: kendo.template($('#regularUserTableRoleColumn').html()),
        width: 190
      }, {
        field: "email",
        exportKeyMap: {
          key: "email",
          label: $filter('translate')('regularUsers.table.header.email')
        },
        headerTemplate: kendo.template($('#regularUserTableEmailHederColumn').html()),
        template: kendo.template($('#regularUserTableEmailColumn').html()),
        width: 230
      }, {
        field: "convertMemberToGuest",
        exportKeyMap: {
          key: "convertMemberToGuest",
          label: $filter('translate')('regularUsers.table.header.convertMemberToGuest')
        },
        headerTemplate: kendo.template($('#regularUserTableConvertMemberToGuestHederColumn').html()),
        template: kendo.template($('#regularUserTableConvertMemberToGuestColumn').html()),
        width: 160
      }, {
        field: "lastEmailStatus",
        template: kendo.template($('#regularUserTableLastEmailStatusColumn').html()),
        title: $filter('translate')('regularUsers.table.header.lastEmailStatus'),
        width: 150
      }, {
        field: 'lastCity',
        exportKeyMap: {
          key: "last_city",
          label: $filter('translate')('regularUsers.table.header.lastCity')
        },
        template: kendo.template($('#regularUserTableLastCityColumn').html()),
        title: $filter('translate')('regularUsers.table.header.lastCity'),
        width: 120
      }, {
        field: 'lastCountry',
        exportKeyMap: {
          key: "last_country",
          label: $filter('translate')('regularUsers.table.header.lastCountry')
        },
        template: kendo.template($('#regularUserTableLastCountryColumn').html()),
        title: $filter('translate')('regularUsers.table.header.lastCountry'),
        width: 120
      }, {
        field: "tasks",
        template: kendo.template($('#regularUserTableViewTasksColumn').html()),
        title: $filter('translate')('regularUsers.table.header.tasks'),
        width: 120
      }, {
        field: "groups",
        exportKeyMap: {
          key: "groups",
          label: $filter('translate')('regularUsers.table.header.groups')
        },
        headerTemplate: kendo.template($('#regularUserTableGroupsHederColumn').html()),
        template: kendo.template($('#regularUserTableGroupsColumn').html()),
        width: 220
      }, {
        field: "action",
        template: kendo.template($('#regularUserTableActionColumn').html()),
        title: $filter('translate')('regularUsers.table.header.action'),
        width: 120
      }, {
        field: "emailLogs",
        template: kendo.template($('#regularUserTableEmailLogsColumn').html()),
        title: $filter('translate')('regularUsers.table.header.emailLogs'),
        width: 120
      }, {
        field: "deleteUser",
        template: kendo.template($('#regularUserTableDeleteColumn').html()),
        title: $filter('translate')('global.standAlone.label.delete'),
        width: 150
      }];
    };

    var getCadencesColumns = function () {
      return [{
        field: "status",
        exportKeyMap: {
          key: "status",
          label: $filter('translate')('regularUsers.table.header.status')
        },
        headerTemplate: kendo.template($('#regularUserTableStatusHederColumn').html()),
        template: kendo.template($('#regularUserTableStatusColumn').html()),
        width: 180
      }, {
        field: "member",
        exportKeyMap: {
          key: "full_name",
          label: $filter('translate')('regularUsers.table.header.member')
        },
        headerTemplate: kendo.template($('#regularUserTableMemberHederColumn').html()),
        template: kendo.template($('#regularUserTableMemberColumn').html()),
        width: 200
      }, {
        field: "cadenceDays",
        exportKeyMap: {
          key: "cadence_days",
          label: $filter('translate')('regularUsers.table.header.cadenceDays')
        },
        template: kendo.template($('#regularCadenceDaysColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.cadenceDays'),
        width: 480
      }];
    };

    /**
      * @function 
      * @name copySuccess
      * @param {String} text 
      * @param {Object} target 
      * @param {*} $event 
      * @description Copy success handler
      * @returns {Void} 
    */
    self.copySuccess = function (text, target, $event) {
      var label = $filter('translate')('userProfile.label.ssoLoginURL');
      Helper.copyToClipboard(text, target, $event, label);
    };
    return self;
  }

})();
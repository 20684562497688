(function () {
  'use strict';

  angular.module('tallyfy.public')
    .component('signupModal', {
      templateUrl: 'app/components/public/library/signup-modal/signup-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function ($window, ENV_CONFIG) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.closeModal = closeModal;
          $ctrl.act = act;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function closeModal() {
            $ctrl.close();
          }

          function act(act) {
            var urlPath = act;
            if (urlPath === 'register' && $ctrl.resolve.org_id && $ctrl.resolve.bp_id) {
              urlPath += '?source=tallyfy_public_bp&public_bp_org_id=' + $ctrl.resolve.org_id + '&public_bp_id=' + $ctrl.resolve.bp_id;
            }
            $window.location.replace(ENV_CONFIG.AUTH_HOST + '/' + urlPath);
          }
        }
    });
})();
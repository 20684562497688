/**
 * @ngdoc Component
 * @name tallyfy.country.component
 * @module tallyfy.country
 *
 * @description
 * A component to display country list.
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('country', {
      bindings: {
        country: '=',
        hideFlags: '<?',
        disabled: '<?',
        isOpen: '=?'
      },
      templateUrl: 'app/modules/country/country.html',
      controller:
        /*@ngInject*/
        function CountryController(UtilsService, _, $filter, $log) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            getCountries();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
          */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getCountries
           * @description Get countries in json file
           * @returns {Object} country
          */
          function getCountries() {
            UtilsService.getCountriesData().then(function (response) {
              $ctrl.countries = response;
              if (!$ctrl.country) {
                $ctrl.country = _.find($ctrl.countries, { code: "US" });
              }
            }, function (error) {
              $log.error(error);
            });
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.account.component.changePassword
 * @module tallyfy.account
 *
 * @description
 * A component to manage change list page
 *
 * @author Kodeplusdev ( gmail::kodeplusdev@gmail.com, skype :: dkhoa81 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .component('activate', {
      templateUrl: 'app/modules/account/activate/activate.html',
      controller:
      /*@ngInject*/
        function ($stateParams, $rootScope, Auth, Growl, $state, blockUI, $translate, _, moment) {
          var $ctrl = this,
            username,
            blockUI = blockUI.instances.get('activate');

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.activate = activate;
          $ctrl.checkFields = checkFields;
          $ctrl.checkPassword = checkPassword;

          /**
           * public properties
           */
          $ctrl.password = '';
          $ctrl.password_confirmation = '';
          $ctrl.error = {
            password: '',
            first_name: '',
            last_name: '',
            password_confirmation: '',
            agreeNotifications: ''
          };
          $ctrl.active = false;
          $ctrl.submitted = false;
          $ctrl.password_policy = {
            'contain_uppercase_letter': false,
            'contain_lowercase_letter': false,
            'contain_number': false,
            'at_least_6_letters': false
          };
          $ctrl.agreeNotifications = false;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            Auth.getAccountInfoByCode({
              'code': $stateParams.code
            }).then(function (response) {
              var data = response.data;
              $ctrl.is_active = !!(data.status === 'active');
              $ctrl.first_name = data.first_name;
              $ctrl.last_name = data.last_name;
              username = data.username;
              $ctrl.email = _.toLower(data.email);
              $ctrl.isShow = false;
            }).catch(function (response) {
              if (response.status === 404) {
                $translate('activate.messages.not_found').then(function (translation) {
                  $ctrl.error.not_found = translation;
                });
              }
            });
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {}
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {}

          /**
           * @ngdoc method
           * @name activate
           * @description
           * Activate the user
           */
          function activate() {
            $ctrl.submitted = true;

            var error = {};
            if ($ctrl.password === '') {
              error.password = 'activate.messages.empty_password';
            }

            if (!$ctrl.agreeNotifications) {
              return;
            }

            if (error.password) {
              $translate(error.password).then(function (translation) {
                $ctrl.error.password = translation;
              });
              return;
            }
            blockUI.start();
            updateUserInfo(function () {
              Auth.activateAccount({
                password: $ctrl.password,
                code: $stateParams.code,
                org_id: $stateParams.org_id
              }).then(function () {
                blockUI.stop();
                $ctrl.success = true;
              }).catch(function () {
                blockUI.stop();
                $ctrl.success = null;
                $ctrl.error = 'ERROR';
              });
            });
          }

          /**
           * @ngdoc method
           * @name checkFields
           * @description
           * Check all field are filled
           */
          function checkFields() {
            return $ctrl.password && $ctrl.first_name && $ctrl.last_name && $ctrl.password_confirmation && $ctrl.agreeNotifications;
          }
          
          /**
           * @ngdoc method
           * @name updateUserInfo
           * @description
           * Update the user information
           */
          function updateUserInfo(callback) {
            var user = {
              'password': $ctrl.password,
              'first_name': $ctrl.first_name,
              'last_name': $ctrl.last_name,
              'username': username,
              'password_confirmation': $ctrl.password,
              'org_id': $stateParams.org_id
            };
            if (moment.tz.guess()) {
              user.timezone = moment.tz.guess();
            }

            Auth.updateAccountInfoByCode({
              code: $stateParams.code
            }, user)
              .then(callback)
              .catch(function (response) {
                blockUI.stop();
                if (response.data.validation_errors) {
                  angular.forEach($ctrl.error, function (value, key) {
                    if (response.data.validation_errors[key]) {
                      $ctrl.error[key] = response.data.validation_errors[key][0];
                    } else {
                      $ctrl.error[key] = '';
                    }
                  });
                }
              });
          }

          /**
           * @ngdoc method
           * @name checkPassword
           * @description
           * Check password complexity
           */
          function checkPassword(str) {
            var hasNumber = (/[0-9]/.test(str));
            var hasLowercase = (/[a-z]/.test(str));
            var hasUppercase = (/[A-Z]/.test(str));
            var minLengthCheck = str.length >= 6;
            var passwordMatch = _.isEqualWith($ctrl.password, $ctrl.password_confirmation);
            $ctrl.password_policy.contain_number = hasNumber;
            $ctrl.password_policy.contain_lowercase_letter = hasLowercase;
            $ctrl.password_policy.contain_uppercase_letter = hasUppercase;
            $ctrl.password_policy.at_least_6_letters = minLengthCheck;
            if (!_.isEmpty($ctrl.password_confirmation)) {
              $ctrl.password_policy.passwords_match = passwordMatch;
            }
            return hasNumber && hasLowercase && hasUppercase && minLengthCheck && passwordMatch;
          }

        }
    });
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.billing.factory.BillingRepository
   * @module tallyfy.billing
   *
   * @description
   * Create Rest resource for billing
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.billing')
    .factory('BillingRepository', BillingRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  BillingRepository.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * BillingRepository construct 
   */
  function BillingRepository(BaseRepository, CONFIG) {
    return BaseRepository(CONFIG.API_HOST + '/billing/:id', {}, {});
  }
})(); 
/**
 * @ngdoc component
 * @name tallyfy.compactKoFormStep
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactKoFormStep', {
      templateUrl: 'app/components/compact/components/koFormStep/compact-ko-form-step.component.html',
      bindings: {
        process: '<',
        isFormTag: '<',
        isSelected: '<',
        isPublic: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $sce, DESCRIPTIONSIZE, koStepService, COMMON) {
          var $ctrl = this;

          // angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;

          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT_LEFT_PANE;

          // public methods
          $ctrl.onStepSelected = onStepSelected;
          $ctrl.openShareModal = openShareModal;

          /**
           * @ngdoc methods
           * @name onInit
           * 
           * @description
           * angular life cycle hook on component init
           */
          function onInit() {
            $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;
            $ctrl.htmlPopoverKoStepHelpText = $sce.trustAsHtml($filter('translate')('steps.koFormStep.header.questionTooltipText'));
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
          }

          function onChanges() { }

          /**
           * @ngdoc methods
           * @name onStepSelected
           * @param {*} e 
           * 
           * @description
           * on step selected handler
           */
          function onStepSelected(e) {
            if ($ctrl.isSelected) {
              return;
            }
            e.stopPropagation();
            // emit item with id 'ko-step' for statement checking on component
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                id: 'ko-step',
                process: $ctrl.process
              }
            });
          }
          
          /**
           * @ngdoc methods
           * @name openShareModal
           * 
           * @description
           * click to open popup modal to get share ko step link
           */
          function openShareModal() {
            koStepService.koStepPublicModalPopup($ctrl.process);
          }
        }
    });
})();
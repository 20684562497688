/**
 * @ngdoc Component
 * @name tallyfy.steps.component.bluePrintModal
 * @module tallyfy.steps
 *
 * @description
 * A component to open blue print modal
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('bluePrintModal', {
      templateUrl: 'app/modules/process/components/blueprint-create/blueprint-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (blockUI, ProcessService, DESCRIPTIONSIZE, $rootScope, _, $stateParams, BLUEPRINT_TYPE, $scope, MODAL_CLOSE_EVENTS, $cookies, $analytics, Helper, FolderService, TagsService) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('blueprint'),
            onModalClosePreventHandler,
            folderSelectedEventHandler;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancelModal = cancelModal;
          $ctrl.saveProcess = saveProcess;
          $ctrl.saveQuickProcess = saveQuickProcess;

          /**
           * public properties
           */
          $ctrl.process = {
            id: null,
            allow_guests_to_track: 'yes',
            steps: [],
            summary: '',
            title: ''
          };

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.blueprintType = $stateParams.blueprintType || $ctrl.resolve.blueprintType;
            if (!_.includes([BLUEPRINT_TYPE.DOCUMENT, BLUEPRINT_TYPE.QUICKPROCEDURE, BLUEPRINT_TYPE.FORM], $ctrl.blueprintType)) {
              $ctrl.blueprintType = BLUEPRINT_TYPE.PROCEDURE;
            }
            $ctrl.maxTitleLength = DESCRIPTIONSIZE.maxLength;
            var preference = _.find($rootScope.identity.preferences, { slug: "user_has_started_run" });
            $ctrl.isFirstBP = _.get(preference, 'value', '') !== 'yes';
            $ctrl.folderID = $stateParams.folder_id;
            if ($ctrl.folderID) {
              FolderService.getFolderById({ id: $ctrl.folderID }).then(function (response) {
                $ctrl.activeFolder = $ctrl.selectFolder = response.data;
              });
            }
            $ctrl.isSnippetBP = $ctrl.blueprintType === BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.isProcedureBP = $ctrl.blueprintType === BLUEPRINT_TYPE.PROCEDURE;
            $ctrl.isFormBP = $ctrl.blueprintType === BLUEPRINT_TYPE.FORM;
            $ctrl.isOnBoardingFlow = ProcessService.isForceOnboarding() && $ctrl.blueprintType === BLUEPRINT_TYPE.QUICKPROCEDURE;
            if ($ctrl.isOnBoardingFlow) {
              var URL = location.origin + '/' + $stateParams.org_id + '/blueprints/create?blueprintType=quickProcedure';
              history.pushState(null, document.title, URL);
            } else {
              $cookies.remove('forcedOnboardingFlow');
              $(".app").removeClass("user-onboarding");
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            onModalClosePreventHandler();
            folderSelectedEventHandler();
            blockUI.stop();
          }

          /**
           * @function
           * @name saveQuickProcess
           * @description create quick process
           * @param {$event} event
           */
          function saveQuickProcess(event) {
            blockUI.start();
            $ctrl.process.title = event.currentTarget.innerText;
            saveProcess(true);
          }

          /**
           * @function
           * @name saveProcess
           * @description create process
           * @param {*} isSaveQuickProcess
           */
          function saveProcess(isSaveQuickProcess) {
            if (($ctrl.processForm && $ctrl.processForm.$valid && !blockUI.state().blocking) || isSaveQuickProcess) {
              var process = angular.copy($ctrl.process);
              angular.extend(process, {
                allow_steps_editing: 'yes',
                folder_id: _.get($ctrl.selectFolder, 'id', null),
                type: isSaveQuickProcess || $ctrl.blueprintType === "quickProcedure" ? 'procedure' : $ctrl.blueprintType
              });
              $ctrl.onSaving = true;
              ProcessService.createProcess(process).then(function (response) {
                var newProcess = response.data;
                if ($ctrl.tags && $ctrl.tags.length > 0) {
                    var lastTag = _.last($ctrl.tags);
                    _.forEach($ctrl.tags, function (tag, key) {
                      TagsService.attachTag(tag, {
                        id: _.get(response.data, 'id'),
                        type: "Checklist"
                      }).then(function () {
                        if (lastTag.id === tag.id) {
                          trackEvent({ label: 'BLUEPRINT CREATED',  blueprintId: newProcess.id, processName: newProcess.title, type: $ctrl.blueprintType });
                          $ctrl.close({ $value: newProcess });
                        }
                      });
                    });
                } else {
                    $ctrl.close({ $value: newProcess });
                }
                /* TODO It can be unhide in future version
                $ctrl.processForm = {};
                TagsService.get({
                  action: 'tags',
                  q: isSaveQuickProcess ? 'procedure' : $ctrl.blueprintType,
                  strict: true
                }).then(function (res) {
                  if (res.data.length) {
                    attachTagToBP(newProcess, res.data[0]);
                  } else {
                    TagsService.createTag({
                      title: isSaveQuickProcess ? 'procedure' : $ctrl.blueprintType
                    }).then(function (tags) {
                      attachTagToBP(newProcess, tags.data);
                    }, function () {
                      $ctrl.onSaving = false;
                    });
                  }
                  if (isSaveQuickProcess) {
                    ProcessService.setForceOnboardingFlowData({ key: 'HasCreatedBP', value: 'yes' });
                  }
                }); */
              }, function () {
                blockUI.stop();
                $ctrl.onSaving = false;
              });
            }
          }

          /**
           * @function
           * @name attachTagToBP
           * @description Attach tag with BP
           * @param {Object} newProcess
           * @param {Object} tag
           * @returns {Void}
           */
           /*function attachTagToBP(newProcess, tag) {
            var checklistParam = {
              type: 'Checklist',
              id: newProcess.id
            };
            var attachTag = {
              id: tag.id,
              title: tag.title
            };
            TagsService.attachTag(attachTag, checklistParam).then(function () {
              $ctrl.close({ $value: newProcess });
            }, function () { });
          }*/

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }


          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = { category: Helper.eventTrackingCategories['blueprint'] };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.processForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
          
          folderSelectedEventHandler = $rootScope.$on('FOLDER:SELECTED', function (e, data) {
            if ($ctrl.isOpenPopOver) {
              $ctrl.selectFolder = data.folder;
            }
            $ctrl.isOpenPopOver = false;
          });
        }
    });
})();
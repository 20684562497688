/**
 * @ngdoc Directive
 * @name tallyfy.stickyHeader
 * @restrict 'A'
 * @element ANY
 * @author KIRAN Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 **/
(function () {
    'use strict';
    angular
        .module('tallyfy')
        .directive('stickyHeader', stickyHeader);


    function stickyHeader($window,  $rootScope) {
        return {
            restrict: 'A',
            scope: {
                offset: "@offset",
                isPageScrolled: '=',
                isProcessHeader: '='
            },
            link: function(scope, element) {
                var totalOffset = parseInt(scope.offset);
                angular.element(document.querySelector('.app-body')).bind("scroll", function(event) {
                  if (scope.isProcessHeader) {
                    totalOffset = element[0].offsetHeight + parseInt(scope.offset);
                  }
                  if (this.scrollTop >= totalOffset) {
                    scope.isPageScrolled = true;
                    element.css({
                        width: this.clientWidth
                    });
                    if (!scope.isProcessHeader) {
                      element.css({
                        'margin-top': 20
                      });
                    }
                    if (angular.element(document.querySelector('.process-info-next-task')).length && !scope.isProcessHeader) {
                      angular.element(document.querySelector('.process-info-next-task')).css({
                        display: 'none'
                      });
                    }
                  } else if ((this.scrollTop == 0 && !scope.isProcessHeader) || (scope.isProcessHeader && this.scrollTop < totalOffset)) {
                      scope.isPageScrolled = false;
                      element.css({
                          width: 'auto'
                      });
                      if (!scope.isProcessHeader) {
                        element.css({
                          'margin-top': 0
                        });
                      }
                      if (angular.element(document.querySelector('.process-info-next-task')).length && !scope.isProcessHeader) {
                        angular.element(document.querySelector('.process-info-next-task')).css({
                          display: 'block'
                        });
                      }
                  }
                  angular.element($window).bind('resize', function() {
                    var elm = document.querySelector('.app-body');
                    element.css({
                      width: elm.clientWidth
                    });
                  });
                  scope.$apply();
                });

                var unregisterDrawerListener = $rootScope.$on('DRAWER:CLOSED', function () {
                  var elm = document.querySelector('.app-body');
                  element.css({
                      width: elm.clientWidth
                  });
                });
                
                scope.$on('$destroy', function () {
                  unregisterDrawerListener();
                });
            }
        };
    }
})();

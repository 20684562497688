/**
 * @ngdoc directive
 * @name tallyfy.cutElement
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("cutElement", cutElement);

  /*@ngInject */
  function cutElement(_, $timeout, DOMService) {
    return {
      restrict: 'A',
      scope: {
        options: '=',
        elementClass: '@',
        hideElements: '='
      },
      link: function ($scope, $el) {
        var cutOffTimeoutHandler;
        function init() {
          $el.css('opacity', 0);
          cutOffTimeoutHandler = $timeout(function () {
            var cutElements = $el.find($scope.elementClass);
            $scope.hideElements = 0;
            var elementWidth = (parseInt(DOMService.getComputedStyle($el[0], 'width').replace('px', '')) - (($scope.options.widthOffset || 0) + 24));
            var length = 0;
            _.forEach(cutElements, function (cutElement) {
              length = length + parseInt(DOMService.getComputedStyle(cutElement, 'width').replace('px', ''));
              if (length > elementWidth) {
                cutElement.parentNode.removeChild(cutElement);
                $scope.hideElements++;
              }
            });
          }, 450).then(function () {
            $el.css('opacity', 1);
          });
        }

        init();

        $scope.$on('$destroy', function () {
          cutOffTimeoutHandler ? $timeout.cancel(cutOffTimeoutHandler) : angular.noop();
        });

        $scope.options.init = init;
      }
    };
  }
})();
/**
 * @ngdoc Service
 * @name tallyfy.integration
 *
 * @module tallyfy.integration
 *
 * @description
 * IntegrationService
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .service('IntegrationService', IntegrationService);

  IntegrationService.$inject = ['_', 'AccountService', 'IntegrationRepository', 'PLANS'];

  function IntegrationService(_, AccountService, IntegrationRepository, PLANS) {
    var self = this;
    var authority = PLANS.PRO + "," + PLANS.PROANNUAL + "," + PLANS.PROMONTHLY + "," + PLANS.BASIC + "," + PLANS.BASICANNUAL + "," + PLANS.BASICMONTHLY + "," + PLANS.FREE;
    
    //register default(get, all, delete, update, create) CRUD to service
    angular.extend(self, IntegrationRepository);

    self.getAPIModel = function (account) {
      var apiModel = _.pick(account, ['client_id', 'client_secret']);
      apiModel.orgId = account.default_organization.id;
      apiModel.userID = account.id;
      return apiModel;
    };

    self.resetKeys = function () {
      return AccountService.refreshKeys();
    };

    self.getSMTPStub = function () {
      return {
        "smtp_username": "",
        "smtp_password": "",
        "smtp_email_address": "",
        "smtp_email_name": "",
        "smtp_encryption": "",
        "smtp_port": "",
        "smtp_host": "",
        "smtp_mail_driver": "",
        "group": 'smtp'
      };
    };
    self.getIntegrationType = function () {
      return [
        { key: "zapier", class: 'success', authority: '' },
        { key: "storage", class: 'blue-800', authority: '' },
        { key: "ldap", class: 'orange-800', authority: authority}
      ];
    };

    self.getSMTPFields = function () {
      return [
        "smtp_username",
        "smtp_password",
        "smtp_email_address",
        "smtp_email_name",
        "smtp_encryption",
        "smtp_port",
        "smtp_host",
        "smtp_mail_driver",
      ];
    }
  }
})();

/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.orgEmailPreferences
 * @module tallyfy.organizations
 *
 * @description
 * A Component to manage org email preferences
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('orgEmailPreferences', {
      templateUrl: 'app/modules/organizations/email-preferences/email-preferences.html',
      controller:
      /*@ngInject*/
      function OrgEmailPreferencesController(blockUI, $log, Growl, $rootScope, OrganizationsService, $filter) {
        var $ctrl = this,
          growl = new Growl('manageOrg'),
          blockUI = blockUI.instances.get('org-email-preferences');
        /**
         * component's lifeCycle hooks 
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * public properties
         */
        $ctrl.organization = angular.copy($rootScope.identity.default_organization);

        /**
        * public methods
        */
        $ctrl.saveEmailPreferences = saveEmailPreferences;
        /**
        * @function
        * @name initialization
        * @description
        * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
        */
        function initialization() { }
        /**
        * @function
        * @name onChanges
        * @description
        * A component's lifeCycle hook which is called when bindings are updated.
        */
        function onChanges(bindings) {
        }
        /**
         * @function
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        /**
         * @function
         * @name saveEmailPreferences
         * @description Save org email preferences
         */
        function saveEmailPreferences() {
          var requestPayload;
          blockUI.start();
          requestPayload = angular.copy($ctrl.organization);
          OrganizationsService
            .update({
              id: requestPayload.id
            }, requestPayload)
            .then(function (successResponse) {
              blockUI.stop();
              growl.success($filter('translate')('email_preferences.messages.success'));
              $rootScope.identity.default_organization = successResponse.data;
              resetForm();
            }, function (error) {
              blockUI.stop();
              $log.error('Error on saveEmailPreferences  - error', error);
            });
        }
        
        function resetForm () {
          $ctrl.emailPreferenceForm.$setDirty(false);
          $ctrl.emailPreferenceForm.$setPristine(true);
        }
        //controller ends
      }
    });

})();
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(stateConfig)
    .run(runHandler);

  /*@ngInject*/
  function runHandler($urlRouter, Redirection, _, $state, AuthServerProvider, Helper) {
    /**
     * Start watching on route for ORG_ID or ORG_SLUG only if application has active session and 
     * - $state.current.name is empty 
     * - Page is hard reloaded or redirected from external sources
     * for public URLs keep routes active.
     */
    if (AuthServerProvider.hasValidToken() && $state.current.name === '') {
      Redirection
        .detectOrg()
        .then(function () {
          $urlRouter.listen();
          $urlRouter.sync();
        }, function (error) {
          if (error.status === 400 && (_.get(error, 'data.code') === 'ACCOUNT_DISABLED')) {
            $state.go('account-disabled', { args: error.data });
          }
        });
    } else {
      $urlRouter.listen();
    }
  }

  /*@ngInject*/
  function stateConfig($urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider, $provide, RedirectionProvider) {
    $urlRouterProvider.deferIntercept();
    /**
     * Enable hashless URL
     */
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
    /**
     * Redirect to auth/handler if no URL match
     */
    $urlRouterProvider.otherwise('auth/handler');
    /**
     * modify state param org_id before doing state transition
     * get org_id from local storage and passs the same in URL as org_id
     */
    $provide.decorator('$state', function ($delegate, $rootScope) {
      // Save off delegate to use 'state' locally
      var state = $delegate;

      // Save off reference to original state.go
      state.baseGo = state.go;

      // Decorate the original 'go' to always plug in the org_id
      var go = function (to, params, options) {
        params = params || {};
        if ($rootScope.identity && $rootScope.identity.default_organization) {
          params.org_id = $rootScope.identity.default_organization.id;
        }
        // Invoke the original go
        this.baseGo(getTargetState(to), params, options);
      };
      // assign new 'go', decorating the old 'go'
      state.go = go;
      return $delegate;
    });

    function getTargetState(to) {
      var stateName;
      switch (to) {
        case 'settings':
          stateName = 'settings.me.update';
          break;
        case 'process':
          stateName = 'process.templates';
          break;
        case 'organizations':
          stateName = 'organizations.list';
          break;
        default:
          stateName = to;
          break;
      }
      return stateName;
    }

    $urlMatcherFactoryProvider.type('boolean', {
      name: 'boolean',
      decode: function (val) {
        return val === true || val === 'true';
      },
      encode: function (val) {
        return val ? 1 : 0;
      },
      equals: function (a, b) {
        return this.is(a) && a === b;
      },
      is: function (val) {
        return [true, false, 0, 1].indexOf(val) >= 0;
      },
      pattern: /bool|true|0|1/
    });

    /**
     * Catch Invalid URLs
     * rewrite urls if found any match
     * @see redirection.provider.js
     * @todo This code will be removed once application is stable wil updated/refactored URLs
     */
    $urlRouterProvider.otherwise(function ($injector, $location) {
      console.log('Found invalid URL', $location.url(), $location);
      return RedirectionProvider.urlMatcher($location);
    });
  }
})();
/**
 * @ngdoc component
 * @name tallyfy.compactTaskCompleter
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactTaskCompleter', {
      templateUrl: 'app/components/compact/items/taskCompleter/compact-task-completer.component.html',
      bindings: {
        style: '@?',
        usersInOrg: '<',
        completerId: '<',
        completerGuest: '<',
        completedAt: '<',
        tooltipDirection: '<',
        deadline: '<?',
        completedStatusLabel: '<?',
        displayStyle: '<?',
        isKoSubmittedInfo: '<?',
        isPubliclyAssignees: '<?',
        isCompleter: '<?',
        isPublicProcess: '<?',
        isGuest: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, TasksService, DateUtils, OrganizationsService) {
          var $ctrl = this;

          // angularjs component lifecycle hook
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() {
            taskCompletedStatusLabel();
          }

          /**
           * @ngdoc methods
           * @name onChanges
           * @param {*} changes
           * 
           * @description
           * angular life cycle hook on component bindings changes
           */
          function onChanges(changes) {
            if (changes.completerId || changes.completerGuest || changes.completedAt || changes.deadline) {
              setCompleter();
            }
          }

          function onDestroy() { }

          /**
           * @ngdoc methods
           * @name setCompleter
           * 
           * @description
           * set text for task completer
           */
          function setCompleter() {
            if ($ctrl.completerGuest) {
              if (!$ctrl.isPubliclyAssignees && $ctrl.isPublicProcess) {
                $ctrl.completer = $ctrl.completerGuest;
              } else if (!$ctrl.isPublicProcess && !$ctrl.isGuest) {
                $ctrl.completer = $ctrl.completerGuest;
              }
            } else {
              if ($ctrl.completerId === _.get($rootScope.identity, 'id')) {
                $ctrl.completer = $rootScope.identity.full_name;
              } else {
                var idx = _.findIndex($ctrl.usersInOrg, { id: $ctrl.completerId });
                if (idx >= 0) {
                  $ctrl.completer = $ctrl.usersInOrg[idx].full_name;
                } else if (!$ctrl.isPubliclyAssignees) {
                  $ctrl.completer = $ctrl.isCompleter ? $ctrl.isCompleter : ($ctrl.completerId ? $ctrl.completerId : angular.noop());
                } else if ($ctrl.completerId) {
                  $ctrl.completer = $ctrl.completerId;
                }
              }
            }

            if ($ctrl.deadline && $ctrl.completedAt) {
              $ctrl.completedAt = angular.copy(DateUtils.toLocal($ctrl.completedAt).format());
              var getTaskCompletionTiming = TasksService.getCompletionTiming($ctrl.deadline, ($ctrl.completedStatusLabel === 'expired' ? $ctrl.deadline : $ctrl.completedAt));
              $ctrl.taskCompletedTiming = getTaskCompletionTiming.timing;
              $ctrl.isLate = getTaskCompletionTiming.isLate || $ctrl.completedStatusLabel === 'completed late';
            }
          }

          /**
           * @ngdoc methods
           * @name taskCompletedStatusLabel
           * 
           * @description
           * set text for task completed status label
           */
          function taskCompletedStatusLabel() {
            $ctrl.taskStatusLabel = $ctrl.completedStatusLabel ? $ctrl.completedStatusLabel : ($ctrl.isKoSubmittedInfo ? 'Submitted' : 'Completed');
            $ctrl.deadlineLabel = $ctrl.completedStatusLabel === 'expired' ? $ctrl.deadline : $ctrl.completedAt;
            $ctrl.completerLabel = $ctrl.completer && $ctrl.completedStatusLabel !== 'expired' && !$ctrl.isPubliclyAssignees ? ' by ' + $ctrl.completer : '';
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('EditorFormFieldService', EditorFormFieldService);


  function EditorFormFieldService(_, $rootScope, $compile, $timeout, FroalaService, ProcessService) {
    var self = this, isBusy = false, queues = [], elements = [];

    self.addToQueue = function (element, field, editorScope, oldFieldId) {
      if (!editorScope.froalaOptions) {
        return;
      }
      var lastField = _.maxBy(editorScope.froalaOptions.process.prerun, 'position'),
        position = (lastField ? lastField.position : 0) + 1,
        fieldModel = {
          field_type: field.field_type,
          label: field.field_label,
          guidance: null,
          required: false,
          position: position,
          oldFieldId: oldFieldId
        }
      queues.push(fieldModel);
      elements.push(element);
    }

    self.addFormField = function (editorScope, dragCallback) {
      if (!editorScope.froalaOptions) {
        return;
      }
      if (isBusy || !queues.length) {
        return;
      }
      var fieldModel = queues[0];
      editorScope.froalaOptions.process.prerun.push(fieldModel);
      if (!editorScope.froalaOptions.process.kickoff_title) {
        editorScope.froalaOptions.process.kickoff_title = editorScope.froalaOptions.title;
      }
      var index = 1;
      _.map(editorScope.froalaOptions.process.prerun, function (prerun) {
        prerun.position = index;
        index++;
      });
      isBusy = true;
      editorScope.froalaOptions.isUpdatingPrerun = true;
      editorScope.froalaOptions.isBusy = true;
      ProcessService.update({ id: editorScope.froalaOptions.process.id }, editorScope.froalaOptions.process)
        .then(function (res) {
          angular.extend(editorScope.froalaOptions.process, { prerun: res.data.prerun, last_updated: res.data.last_updated });
          var field = _.find(_.get(res.data, 'prerun', []), { position: queues[0].position });
          $timeout(function () {
            elements[0].setAttribute('draggable', 'true');
            elements[0].setAttribute('data-field-id', "'" + field.id + "'");
            elements[0].removeAttribute('data-new-field');
            elements[0].addEventListener('dragstart', dragCallback);
            if (fieldModel.oldFieldId) {
              var editorVariables = editorScope.froalaOptions.froalaEditor.$el[0].querySelectorAll(["editor-variable[data-field-id=\"'" + fieldModel.oldFieldId + "'\"]"]);
              for (var i = 0; i < editorVariables.length; i++) {
                editorVariables[i].setAttribute('data-field-id', "'" + field.id + "'");
              }
              FroalaService.compileEditorFieldElement(editorScope, 'editor-variable');
            }
            $compile(elements[0])(editorScope);
          }, 350).then(function () {
            queues.shift();
            elements.shift();
            isBusy = false;
            if (queues.length) {
              self.addFormField(editorScope, dragCallback);
            } else {
              editorScope.froalaOptions.isUpdatingPrerun = false;
              editorScope.froalaOptions.isBusy = false;
              FroalaService.compileEditorFieldElement(editorScope, 'editor-snippet');
              FroalaService.compileEditorFieldElement(editorScope, 'editor-blueprint');
              FroalaService.syncEntityFormField(editorScope);
              $rootScope.$emit('PROCESS:UPDATED', { process: res });
              $rootScope.$emit('EDITOR:CONTENT_CHANGED');
            }
          });
        }, function (err) {
          isBusy = false;
          editorScope.froalaOptions.isUpdatingPrerun = false;
          editorScope.froalaOptions.isBusy = false;
        });
    }
    return self;
  }
})();
/**
 * @ngdoc Config
 * @name tallyfy.billing.config.BillingStateConfig
 * @module tallyfy.billing
 *
 * @description
 * set route billing Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.billing')
    .config(BillingStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  BillingStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * BillingStateConfig construct 
   */
  function BillingStateConfig($stateProvider, $urlRouterProvider) {
    //Routes
  }
})();
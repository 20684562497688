/**
 * @ngdoc Component
 * @name tallyfy.filters.component.sortFilter
 * @module tallyfy.filters
 *
 * @description
 * A component to manage sorting view
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.filters')
    .component('sortFilter', {
      bindings: {
        sortOptions: '<',
        onSortFilterChange: '&',
        sortConfigSlug: '<',
        userPreferences: '<'
      },
      templateUrl: 'app/modules/filter/components/sortFilter/sortFilter.html',
      controller:
        /*@ngInject*/
        function (_, FiltersService, ProgressBar, $state) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.changeSortFilter = changeSortFilter;
          $ctrl.isProgressBar = isProgressBar;

          /**
           * public properties
           */
          $ctrl.selectedSortItem = {};

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($state.params.sortBy) {
              $ctrl.defaultSort = _.find($ctrl.sortOptions, function (f) {
                return _.toLower(f.value) === _.toLower($state.params.sortBy);
              });
              $ctrl.selectedSortItem = angular.copy($ctrl.defaultSort);
              $ctrl.onSortFilterChange({ sort: $ctrl.selectedSortItem });
            } else {
              $ctrl.defaultSort = _.head($ctrl.sortOptions);
              prepareSortFilter($ctrl.userPreferences);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name prepareSortFilter
           * @description prepare sort filter value
           * @param userPreferences
           */
          function prepareSortFilter(userPreferences) {
            var setActiveSort = FiltersService.getActiveFilterValue(userPreferences, $ctrl.sortConfigSlug, $ctrl.defaultSort) || {};
            $ctrl.selectedSortItem = _.find($ctrl.sortOptions, {
              value: setActiveSort.value
            });
          }

          /**
           * @ngdoc method
           * @name changeSortFilter
           * @description update sort filter value
           * @param selectedSortItemIndex
           */
          function changeSortFilter(selectedSortItemIndex) {
            if ($ctrl.selectedSortItem.name === $ctrl.sortOptions[selectedSortItemIndex].name)
              return;
            $ctrl.selectedSortItem = $ctrl.sortOptions[selectedSortItemIndex];
            $ctrl.onSortFilterChange({ sort: $ctrl.selectedSortItem });
          }

          /**
           * @ngdoc method
           * @name isProgressBar
           * @description Check progress bar is active or not
           * @returns {Boolean}
           */
          function isProgressBar() {
            return ProgressBar.isActiveProgress();
          }
        }
    });
})();
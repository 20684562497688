/**
 * @ngdoc component
 * @name tallyfy.compactStepDetail
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactStepDetail', {
      templateUrl: 'app/components/compact/components/stepDetail/compact-step-detail.component.html',
      bindings: {
        process: '<',
        index: '<',
        step: '<',
        usersInOrg: '<',
        activeTab: '=',
        stepDescribeForm: '=',
        captureForm: '=',
        assignForm: '=',
        deadlineForm: '=',
        logicForm: '=',
        settingsForm: '=',
        onTabChanged: '&',
        ownerList: '<',
        isPublic: '<',
        isGuest: '<',
        metadata: '=',
        orgGroups: '<?',
        qStepDirtyCheck: '&?',
        isChatBoxOpen: '<?',
        titleEditMode: '<?',
        roles: '<?',
        asModal: '<?',
        activeCategory: '<',
        stepCategories: '<',
        onCategoryChange: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, $analytics, StepService, Growl, blockUI, $filter, DESCRIPTIONSIZE, Helper, ChatlioService, COMMON) {
          var $ctrl = this,
            oldStep,
            blockUI = blockUI.instances.get('editProcess'),
            growl = new Growl(),
            processAtomationWatcherHandler,
            onStepIndexChanged,
            onStepReOrderEventHandler;

          /// angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;

          // public methods
          $ctrl.onEditTitleClick = onEditTitleClick;
          $ctrl.onTitleUpdate = onTitleUpdate;
          $ctrl.selectAssigneeTab = selectAssigneeTab;
          $ctrl.onEditTabChanged = onEditTabChanged;
          $ctrl.deleteStep = deleteStep;
          $ctrl.onDuplicateStep = onDuplicateStep;
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;
          $ctrl.onPaneClose = onPaneClose;
          $ctrl.setActiveCategory = setActiveCategory;

          /**
           * @ngdoc methods
           * @name onInit
           *
           * @description
           * angular life cycle hook
           */
          function onInit() {
            oldStep = angular.copy($ctrl.step);
            $ctrl.viewerId = $ctrl.isPublic ? 'PUBLIC' : $rootScope.identity.id;
            var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
            if (isInTrial) {
              ChatlioService.triggerMessage($rootScope.identity.id + '-' + _.get($rootScope.identity, 'default_organization.id') + '-step-detail', "Add details to your step - like how it's done, who does it, etc. Did you have questions?");
            }
            $ctrl.hiddenStep = isStepHasHideAction();
          }

          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           *
           * @description
           * angularjs life cycle hook component destroy
           */
          function onDestroy() {
            processAtomationWatcherHandler();
            onStepReOrderEventHandler();
            onStepIndexChanged();
            var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
            if (isInTrial) {
              ChatlioService.cancelTrigger();
            }
          }

          /**
           * @ngdoc methods
           * @name onEditTitleClick
           *
           * @description
           * on title click
           */
          function onEditTitleClick() {
            $ctrl.titleEditMode = true;
          }

          /**
           * @ngdoc methods
           * @name onTitleUpdate
           * @param {*} heading
           *
           * @description
           * update step title
           */
          function onTitleUpdate(heading) {
            if (!heading) {
              $ctrl.step.title = oldStep.title;
            } else {
              var stepUpdateObj = _.omit(angular.copy($ctrl.step), ['condition']);
              StepService.updateStep({
                id: $ctrl.step.id,
                checklist_id: $ctrl.process.id,
                skipNotFound: true
              }, stepUpdateObj);
            }
          }

          /**
           * @ngdoc methods
           * @name onEditTabChanged
           * @param {*} tab
           *
           * @description
           * on tab changed
           */
          function onEditTabChanged(tab) {
            $ctrl.onTabChanged({ tab: tab });
          }

          /**
           * @ngdoc methods
           * @param {*} e
           * @param {*} tab
           *
           * @description
           * on assignee tab selected
           * since have multiple value of assignee types
           */
          function selectAssigneeTab(e, tab) {
            var tabs = StepService.getStepOptions($ctrl.process);
            $ctrl.activeTab = _.find(tabs, { value: tab })
          }

          /**
           * @ngdoc methods
           * @name onDuplicateStep
           * @param {*} e
           *
           * @description
           * on step duplicate method handler
           */
          function onDuplicateStep(e) {
            e.stopImmediatePropagation();
            blockUI.start();
            StepService.clone($ctrl.process.id, $ctrl.step.id, $ctrl.step.title)
              .then(function (response) {
                trackEvent({ label: 'STEP COPIED / DUPLICATED IN BLUEPRINT', stepName: response.data.title });
                $rootScope.$emit('STEP:DUPLICATED', { step: response.data });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
          }

          /**
           * @ngdoc methods
           * @name deleteStep
           * @param {*} e
           *
           * @description
           * on step delete method handler
           */
          function deleteStep(e) {
            if (!Helper.checkAccessAuthority())
              return;
            e.stopImmediatePropagation();
            var stepRule = [], deadlineRule = [], steps = $ctrl.process.steps.data;

            stepRule = StepService.validateRule($ctrl.process, $ctrl.step);
            if (stepRule.length) {
              StepService.openRuleModal(stepRule, 'Step', $ctrl.process);
              return;
            }

            if (stepRule.length === 0) {
              deadlineRule = StepService.validateDeadlineRule(steps, $ctrl.step);
              if (deadlineRule.length) {
                StepService.openRuleModal(deadlineRule, 'Deadline', $ctrl.process);
                return;
              }
            }
            openDeleteModal(steps);
          }

          /**
           * @ngdoc methods
           * @name openDeleteModal
           * @param {*} steps
           *
           * @description
           * open confirm modal before step delete
           */
          function openDeleteModal(steps) {
            StepService
              .openDeleteStepConfirmation($ctrl.step)
              .then(
                function () {
                  blockUI.start();
                  StepService.deleteStep({
                    id: $ctrl.step.id,
                    checklist_id: $ctrl.process.id
                  }).then(
                    function () {
                      var index = _.findIndex(steps, { id: $ctrl.step.id });
                      steps.splice(index, 1);
                      growl.success($filter('translate')('steps.messages.stepDeleted'), {
                        referenceId: 'growlNotification',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                      $rootScope.$emit('STEP:DELETED', { step: $ctrl.step });
                      $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
                      blockUI.stop();
                    }, function () {
                      blockUI.stop();
                    }
                  );
                }
              );
          }

          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e
           *
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            if (StepService.isDescriptionSaveInProgress || StepService.isDescriptionSaveInQueue) {
              $timeout(function () {
                if (!blockUI.state().blocking) {
                  blockUI.start();
                }
                onPaneClose(e);
              }, 500);
            } else {
              emitRightPaneClose(e);
            }
          }

          function emitRightPaneClose(e) {
            $timeout(function () {
              blockUI.stop();
              $rootScope.$emit('RIGHT_PANE:CLOSE', {
                event: e
              });
            }, 150);
          }

          /**
           * @name trackEvent
           * @param {*} eventLabel
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventLabel) {
            $analytics.eventTrack('Click', {
              label: eventLabel,
              category: Helper.eventTrackingCategories['run'],
              processId: $ctrl.process.id,
              step: $ctrl.step.id
            });
          }

          function isStepHasHideAction() {
            var automatedActions = $ctrl.process.automated_actions;
            for (var i = 0; i < automatedActions.length; i++) {
              var visibilityActions = _.filter(automatedActions[i].then_actions, function (action) {
                return action.action_type === 'visibility' && action.action_verb === 'show' && action.target_step_id === $ctrl.step.id;
              })
              if (visibilityActions.length) {
                return true;
              }
            }
            return false;
          }

          //Set active category
          function setActiveCategory (category) {
            $ctrl.onCategoryChange({ category: category });
          }

          // watch for step terms value to update rule captions
          processAtomationWatcherHandler = $scope.$watchCollection('$ctrl.process.automations', function () {
            $ctrl.hiddenStep = isStepHasHideAction();
          });

          // on step re-order event handler
          onStepReOrderEventHandler = $rootScope.$on('STEP:RE-ORDER', function () {
            var index = _.findIndex($ctrl.process.steps.data, { id: $ctrl.step.id });
            $ctrl.index = index;
          });

          onStepIndexChanged = $rootScope.$on('INDEX:UPDATED', function (e, data) {
            if (data.step.id === $ctrl.step.id) {
              $ctrl.index = data.index;
            }
          });
        }
    });
})();

/**
 * @ngdoc Service
 * @name tallyfy.emailSettings.emailSettings
 *
 * @module tallyfy.organizations
 *
 * @description
 * EmailSettingsService
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com)
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .service('EmailSettingsService', EmailSettingsService);

  /*@ngInject*/
  function EmailSettingsService(EmailSettingsRepository) {
    var self = this;

    angular.extend(this, EmailSettingsRepository);

    self.updateSmtp = function (data) {
      return EmailSettingsRepository.createConnection(data);
    };

    self.enableSMTP = function (data) {
      return EmailSettingsRepository.enableSMTP(data);
    };
  }
})();

/**
 * @ngdoc Config
 * @name tallyfy.process.config.ProcessLibraryStateConfig
 * @module tallyfy.process
 *
 * @description
 * set route process Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .config(ProcessLibraryStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ProcessLibraryStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * ProcessLibraryStateConfig construct 
   */
  function ProcessLibraryStateConfig($stateProvider) {

    $stateProvider.state('guest.templates', {
      url: '/blueprints?status&page&sortBy&blueprint_id&public_org&folder_id&tags&sortByType&sortByIndustry&sortByTopic',
      parent: 'guest',
      params: {
        status: { squash: true, value: null },
        page: { squash: true, value: null },
        sortBy: { squash: true, value: null },
        blueprint_id: { squash: true, value: null },
        public_org: { squash: true, value: null },
        folder_id: { squash: true, value: null },
        tags: { squash: true, value: null },
        sortByType: { squash: true, value: null },
        sortByIndustry: { squash: true, value: null },
        sortByTopic: { squash: true, value: null }
      },
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'processLibrary'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    }).state('process.templates', {
      url: '/blueprints?status&page&sortBy&blueprint_id&public_org&folder_id&tags&sortByType&sortByIndustry&sortByTopic',
      params: {
        status: { squash: true, value: null },
        page: { squash: true, value: null },
        sortBy: { squash: true, value: null },
        blueprint_id: { squash: true, value: null },
        public_org: { squash: true, value: null },
        folder_id: { squash: true, value: null },
        tags: { squash: true, value: null },
        sortByType: { squash: true, value: null },
        sortByIndustry: { squash: true, value: null },
        sortByTopic: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'process_view': {
          component: 'processLibrary'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    }).state('process.folder', {
      url: '/blueprints/folder/:folder_id',
      params: {
        folder_id: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'process_view': {
          component: 'processLibrary'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();

(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicLibrary', {
      templateUrl: 'app/components/public/pages/library/public-library.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $filter, $state, $stateParams, $timeout, PublicLibraryService, PublicTagService, DOMService) {
          var $ctrl = this, publicLibraryQueryParams, $publicLibraryPromiseCall;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.goToBlueprintEditor = goToBlueprintEditor;
          $ctrl.goToNextPage = goToNextPage;
          $ctrl.onTypeClick = onTypeClick;
          $ctrl.onIndustryClick = onIndustryClick;
          $ctrl.onTopicClick = onTopicClick;
          $ctrl.onSearchTextChanged = onSearchTextChanged;

          $ctrl.processType = PublicLibraryService.getBlueprintTypes();
          $ctrl.publicIndustryTags = [];
          $ctrl.publicTopicTags = [];

          function onInit() {
            $ctrl.publicOrganization = $rootScope.publicOrganization;
            $ctrl.selectedType = _.find($ctrl.processType, function (tag) {
              var title = _.get(tag, 'title', '');
              var type = _.get($stateParams, 'type', '');
              return (title || '').toLowerCase() === (type || '').toLowerCase();
            });
            getIndustryTags();
            getTopicTags();
            getAllPublicLibrary();
            $ctrl.searchPlaceholder = $filter('translate')('public.input.placeholder.blueprint');
          }

          function onChanges() { }

          function onDestroy() { }

          function getIndustryTags() {
            $ctrl.isIndustryTagBusy = true;
            var params = {
              action: 'industry',
              page: 1,
              per_page: 8,
              pagination: true
            };
            if (_.get($ctrl.publicOrganization, 'name')) {
              params.org = $ctrl.publicOrganization.id;
            } else {
              params.is_featured = true;
            }
            params.all_tags = !params.org;
            PublicTagService.getPublicTags(params).then(function (res) {
              $ctrl.publicIndustryTags = res.data;
              $ctrl.selectedIndustry = _.find($ctrl.publicIndustryTags, function (tag) {
                var industryParam = _.get($stateParams, 'industry', '');
                return tag.title && tag.title.toLowerCase() === (_.isNull(industryParam) ? '' : industryParam).toLowerCase();
              });
              $ctrl.isIndustryTagBusy = false;
            }, function () {
              $ctrl.isIndustryTagBusy = false;
            });
          }

          function getTopicTags() {
            $ctrl.isTopicTagBusy = true;
            var params = {
              action: 'topic',
              page: 1,
              per_page: 8,
              pagination: true
            };
            if (_.get($ctrl.publicOrganization, 'name')) {
              params.org = $ctrl.publicOrganization.id;
            } else {
              params.is_featured = true;
            }
            params.all_tags = false;
            PublicTagService.getPublicTags(params).then(function (res) {
              $ctrl.publicTopicTags = res.data;
              $ctrl.selectedTopic = _.find($ctrl.publicTopicTags, function (tag) {
                var topicParam = _.get($stateParams, 'topic', '');
                return tag.title && tag.title.toLowerCase() === (_.isNull(topicParam) ? '' : topicParam).toLowerCase();
              });
              $ctrl.isTopicTagBusy = false;
            }, function () {
              $ctrl.isTopicTagBusy = false;
            });
          }

          function getPublicLibrary(params) {
            var defer = $q.defer();
            PublicLibraryService
              .getPublicLibrary(params)
              .then(function (res) {
                defer.resolve(res);
              }, function (err) {
                defer.reject(err);
              });
            return defer;
          }

          function getAllPublicLibrary(query, centerElement) {
            if ($publicLibraryPromiseCall && !$publicLibraryPromiseCall.promise.$$state.status) {
              $publicLibraryPromiseCall.resolve();
            }
            $ctrl.publicLibraries = [];
            var params = angular.extend({}, publicLibraryQueryParams, {
              type: $ctrl.selectedType ? $ctrl.selectedType.title.toLowerCase() : void 0,
              industryTag: _.get($stateParams, 'industry', '') || ($ctrl.selectedIndustry ? $ctrl.selectedIndustry.title.toLowerCase() : void 0),
              topicTag: _.get($stateParams, 'topic', '') || ($ctrl.selectedTopic ? $ctrl.selectedTopic.title.toLowerCase() : void 0),
              page: $stateParams.page || 1,
              per_page: 18,
              with: 'topic_tags,industry_tags',
              q: query
            });
            if (_.get($ctrl.publicOrganization, 'name')) {
              angular.extend(params, {
                action: 'organizations',
                action_id: $ctrl.publicOrganization.id
              });
            }
            $ctrl.isBusy = true;
            $publicLibraryPromiseCall = getPublicLibrary(params);
            $publicLibraryPromiseCall.promise.then(function (res) {
              $ctrl.publicLibraries = res.data;
              $ctrl.pagination = res.meta.pagination;
              $ctrl.isBusy = false;
            }, function () {
              $ctrl.isBusy = false;
            });
            if (centerElement) {
              $timeout(function () {
                DOMService.centerObjectToView('.library-main-top');
              }, 0);
            }
          }

          function goToBlueprintEditor(blueprint) {
            if (_.get($ctrl.publicOrganization, 'name')) {
              $state.go('public.library.organization.detail', {
                orgId: $ctrl.publicOrganization.id,
                blueprintId: blueprint.id
              });
            } else {
              $state.go('public.library.combo.detail', {
                bpOrgId: blueprint.organization_id,
                blueprintId: blueprint.id
              });
            }
          }

          function goToNextPage(page) {
            $state.transitionTo($state.current, angular.extend($stateParams, { page: page }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onTypeClick(tag) {
            $ctrl.selectedType = (_.get($ctrl.selectedType, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { type: $ctrl.selectedType ? $ctrl.selectedType.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onIndustryClick(tag) {
            $ctrl.selectedIndustry = (_.get($ctrl.selectedIndustry, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { industry: $ctrl.selectedIndustry ? $ctrl.selectedIndustry.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onTopicClick(tag) {
            $ctrl.selectedTopic = (_.get($ctrl.selectedTopic, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { topic: $ctrl.selectedTopic ? $ctrl.selectedTopic.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onSearchTextChanged(value) {
            getAllPublicLibrary(value, true);
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportDomains
 * @module tallyfy.support
 *
 * @description
 * A component to manage support domain list
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportDomains', {
      bindings: {
        orgId: '<'
      },
      templateUrl: 'app/modules/support/components/domains/support-domains.html',
      controller:
        /*@ngInject*/
        function (_, $uibModal, Growl, $attrs, $stateParams, SupportService, blockUI) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('domainOrg'),
            growl = new Growl();

          /**
           * public properties
           */
          $ctrl.listPager = {
            total: 0,
            per_page: $attrs.perPage || 20,
            current_page: 1,
            onPageChanged: function (current_page) {
              this.current_page = current_page;
            }
          };

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * public methods
           */
          $ctrl.openDomainModal = openDomainModal;
          $ctrl.editDomain = editDomain;
          $ctrl.deleteDomain = deleteDomain;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            getDomainData();
          }

          function onChanges() { }
          function onDestroy() { }

          /**
           * @function
           * @name openDomainModal
           * @description Open modal for create and update domain
           */
          function openDomainModal(domainId) {
            $uibModal.open({
              component: 'supportDomainModal',
              windowClass: 'support-domain-modal',
              backdrop: 'static',
              size: 'lg',
              resolve: {
                domains: function () {
                  return $ctrl.domains;
                },
                orgId: function () {
                  return $ctrl.orgId;
                },
                domainId: function () {
                  return domainId;
                }
              }
            });
          }

          /**
           * @function
           * @name getDomainData
           * @description get domain list
           */
          function getDomainData() {
            var params = {
              org: $stateParams.user_org
            };
            SupportService.getDomainSupport(params).then(function (response) {
              $ctrl.domains = _.get(response, 'data');
            });
          }

          /**
           * @function
           * @name editDomain
           * @description edit domain
           */
          function editDomain(domainId) {
            openDomainModal(domainId)
          }

          /**
           * @function
           * @name deleteDomain
           * @description delete domain
           */
          function deleteDomain(domainId) {
            blockUI.start();
            var orgDomainObj = _.find($ctrl.domains, { id: domainId });
            SupportService.deleteDomainSupport($ctrl.orgId, {
              id: domainId
            }).then(function () {
              blockUI.stop();
              var index = _.findIndex($ctrl.domains, { id: orgDomainObj.id });
              $ctrl.domains.splice(index, 1);
              growl.success($filter('translate')('support.domains.domain_deleted'), {
                referenceId: 'supportViewController',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function () { blockUI.stop(); });
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.updateLogo
 * @module tallyfy.organizations
 *
 * @description
 * A component to update org logo
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('updateLogo', {
      templateUrl: 'app/modules/organizations/updateLogo/update-logo.html',
      bindings: {
        showEditIcon: '<?',
        isGuest: '<',
        logoClass: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $log, $uibModal, OrganizationsRepository, Helper, $rootScope, OrganizationsService, Upload, ENV_CONFIG, $filter, AuthPlan, $confirm, PLANS, $state, USER_STATE) {
          var $ctrl = this,
            organization = _.get($rootScope.identity, 'default_organization', {});

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.onFileChange = onFileChange;
          $ctrl.uploadLogo = uploadLogo;
          $ctrl.removeLogo = removeLogo;

          /**
           * public method's
           */
          $ctrl.picFile = null;
          $ctrl.sourceImage = null;
          $ctrl.isDefaultLogo = true;
          $ctrl.isPublic = false;
          $ctrl.clickLogoToRedirect = clickLogoToRedirect;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            updatePlan();
            setAppLogo();
          }

          function setAppLogo() {
            $ctrl.appLogo = '';
            $ctrl.userState = $rootScope.userState;
            if ($ctrl.userState === USER_STATE.PUBLIC) {
              organization = $rootScope.publicOrganization || {};
              $ctrl.isPublic = true;
            } else if ($ctrl.userState === USER_STATE.GUEST) {
              organization = _.get($rootScope.identity, 'guest.organization', {});
            } else {
              organization = _.get($rootScope.identity, 'default_organization', {});
            }
            if (!_.isEmpty(organization) || !_.isEmpty(organization.org_logo || organization.orgLogo || organization.logo)) {
              $ctrl.fileId = OrganizationsService.getFileIdFromOrgLogoUrl(organization.org_logo || organization.orgLogo || organization.logo);
            }
            if (!_.isEmpty($ctrl.fileId)) {
              $ctrl.loading = true;
              updateOrgLogoUrl($ctrl.fileId);
              updateOrganizationsLogo($ctrl.fileId);
            } else {
              $ctrl.appLogo = $ctrl.isPublic
                ? void 0 : OrganizationsService.getDefaultOrgLogoForPlan(null);
            }
          }

          /**
           * @ngdoc method
           * @name updatePlan
           * @description
           * Update when change logo and Plan.
           */
          function updatePlan() {
            var activePlan = AuthPlan.getCurrentPlanCode();
            $ctrl.isProPlan = ([PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY].indexOf(activePlan) > -1);
            $ctrl.isDocsPlan = ([PLANS.DOCS, PLANS.DOCSANNUAL, PLANS.DOCSMONTHLY].indexOf(activePlan) > -1);
            $ctrl.isBasicPlan = ([PLANS.BASIC, PLANS.BASICANNUAL, PLANS.BASICMONTHLY].indexOf(activePlan) > -1);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            if (changes.logoClass && changes.logoClass.currentValue) {
              setAppLogo();
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function onFileChange($files) {
            if (_.isArray($files) && $files.length === 0) {
              return;
            }
            $ctrl.picFile = _.head($files);
            $uibModal.open({
              backdrop: 'static',
              component: 'cropImage',
              resolve: {
                file: function () {
                  return $ctrl.picFile;
                },
                sourceImage: function () {
                  return $ctrl.sourceImage;
                },
                cropShape: function () {
                  return 'rectangle'; // this can be rectangle/square/circle
                }
              }
            }).result.then(function (response) {
              $ctrl.uploadLogo(response);
            }, function () {
              $ctrl.sourceImage = null;
            });
          }

          function uploadLogo(data) {
            if ($rootScope.userState !== USER_STATE.PUBLIC) {
              $ctrl.loading = true;
              Upload.upload({
                url: ENV_CONFIG.API_HOST + '/organizations/' + _.get(organization, 'id') + '/logo',
                data: {
                  name: Upload.dataUrltoBlob(data.dataUrl, data.name)
                }
              }).then(function (response) {
                var fileId = _.get(response, 'data.data.id', '');
                updateOrganizationsLogo(fileId);
                updateOrgLogoUrl(fileId);
              }, function (response) {
                if (response.status > 0) {
                  $ctrl.errorMsg = response.status + ': ' + response.data;
                }
                $log.error('Error while uploading Org logo - ', response);
                $ctrl.loading = false;
              }, function (evt) {
                $ctrl.progress = parseInt(100.0 * evt.loaded / evt.total);
              });
            }
          }

          function updateOrganizationsLogo(fileId) {
            OrganizationsRepository.getOrganizationLogo({
              org: organization.id,
              fileId: fileId
            }).then(function (response) {
              $ctrl.appLogo = Helper.getFileObjectUrl(response.data, response.contentType);
              $ctrl.isDefaultLogo = false;
              $rootScope.$emit("ORGANIZATION:UPDATEORGLOGO", $ctrl.appLogo);
              $ctrl.loading = false;
            }, function () {
              $ctrl.loading = false;
              $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(AuthPlan.getCurrentPlanCode());
            });
          }

          function updateOrgLogoUrl(fileId) {
            var previousFileId = OrganizationsService.getFileIdFromOrgLogoUrl(organization.org_logo || organization.orgLogo);
            $ctrl.fileId = fileId;
            if (!$ctrl.isPublic) {
              $rootScope.userState === USER_STATE.GUEST
                ? $rootScope.identity.guest.organization.logo = _.replace(organization.logo, previousFileId, fileId)
                : $rootScope.identity.default_organization.org_logo = _.replace(organization.org_logo, previousFileId, fileId);
            }
          }

          /**
           * @ngdoc method
           * @name removeLogo
           * @description remove organization logo
           */
          function removeLogo() {
            $confirm({
              'header': $filter('translate')('updateLogo.confirmAction.header'),
              'body': '',
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Remove Image' }),
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              deleteLogoFile();
              updatePlan();
            }, function () {
              $ctrl.loading = false;
            });
          }

          /**
           * @function deleteLogoFile
           * @description Check if current plan can upload logo
           * @returns {boolean}
           */
          function deleteLogoFile() {
            $ctrl.loading = true;
            OrganizationsService.deleteOrganizationLogo({
              org: organization.id,
              fileId: $ctrl.fileId
            }).then(function () {
              delete $rootScope.identity.default_organization.org_logo;
              $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(null);
              $rootScope.$emit("ORGANIZATION:UPDATEORGLOGO", $ctrl.appLogo);
              $ctrl.isDefaultLogo = true;
              $ctrl.loading = false;
            }, function (error) {
              $log.error('Unable to delete file', error);
            });
          }

          /**
           * @function clickLogoToRedirect
           * @description Logo on click redirect dashboard & custom branding
           * @returns {void}
           */
          function clickLogoToRedirect() {
            if ($ctrl.showEditIcon && $rootScope.userState === USER_STATE.MEMBER) {
              if ($ctrl.isDefaultLogo) {
                $state.go('settings.org.branding', {
                  org_id: organization.id
                });
              } else if ($ctrl.isAdminMember) {
                $state.go('settings.org.updateOrgProfile', {
                  org_id: organization.id
                });
              } else {
                $state.go('dashboard', {
                  org_id: organization.id
                });
              }
            }
          }

          /**
           * @description Custom Branding Organization Logo Upload
           */
          $rootScope.$on('ORGANIZATION:UPDATEORGLOGO', function (event, appLogo) {
            if ($ctrl.isPublic) {
              return;
            }
            var planCode = AuthPlan.getCurrentPlanCode() || _.get(appLogo, 'organization.plan_code');
            if (appLogo) {
              $ctrl.fileId = OrganizationsService.getFileIdFromOrgLogoUrl(organization.org_logo);
              $ctrl.appLogo = appLogo.organization ? (appLogo.organization.logo ? ENV_CONFIG.API_HOST + '/organizations/' + appLogo.organization.id + '/logo/' + OrganizationsService.getFileIdFromOrgLogoUrl(appLogo.organization.logo) : null) : appLogo;
              if (!$ctrl.appLogo) {
                $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(planCode);
              }
              updatePlan();
            } else {
              initialization();
            }
            var DefaultOrgLogo = OrganizationsService.getDefaultOrgLogoForPlan(planCode);
            $ctrl.isDefaultLogo = ($ctrl.appLogo == DefaultOrgLogo);
          });
        }
    });
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .service('KendoUIService', KendoUIService);

  function KendoUIService() {
    var self = this;
    var getSaveString = function (value) {
      return value
        .replace(/[#/]+/g, '&num;')
        .replace('\\', '&bsol;');
    }
    self.attachWidgetEventHandler = function (widget, eventCallbacks) {
      var view = new kendo.View(null, eventCallbacks);
      view.render(widget);
    }
    self.getTooltippedHeaderLabel = function (column) {
      var saveString = getSaveString(column.label);
     return '<span auto-tooltip popover-enable="true" uib-popover-html="\''
        + saveString.replace('\'', '')
        + '\'" popover-placement="top" popover-class="popover popover-tallyfy" popover-append-to-body="true" popover-trigger="\'mouseenter\'">'
        + saveString + '</span>';
    }
    return self;
  }
})();
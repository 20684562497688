(function () {
  'use strict';
  angular.module('tallyfy')
    .component('blueprintMenu', {
      templateUrl: 'app/modules/dashboard/content-card-preview/blueprint-list-preview/blueprint-menu/blueprint-menu.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function ($scope) {
          var $ctrl = this, body = angular.element('body');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() {
            body.on('click', bodyClickHandler);
          }

          function onChanges() { }

          function onDestroy() {
            body.off('click', bodyClickHandler);
          }

          function bodyClickHandler(e) {
            var clickedElement = angular.element(e.target);
            var isInMenu = clickedElement.closest('.blueprint-menu').length;
            if (!isInMenu) {
              $ctrl.isOpen = false;
              $scope.$apply();
            }
          }
        }
    })
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepDeadline', {
      bindings: {
        process: '<',
        step: "<",
        form: '=',
        isPublic: '<'
      },
      templateUrl: 'app/modules/steps/deadline/deadline.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, PLANS, AuthPlan, StepService, Helper, $timeout, TimeFieldService) {
          var $ctrl = this,
            unregisterDeadlineEventHandler,
            discardDeadlineEventHandler,
            removeStepInDeadline = [],
            setStepInDeadline = [],
            oldStep;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.isLastStep = isLastStep;
          $ctrl.prepareOption = prepareOption;
          $ctrl.prepareStep = prepareStep;
          $ctrl.stepDisabled = stepDisabled;
          $ctrl.deadlineChanged = deadlineChanged;
          $ctrl.upgradeModal = upgradeModal;
          $ctrl.updateStep = updateStep;
          $ctrl.onDiscard = onDiscard;
          $ctrl.checkDateValid = checkDateValid;
          $ctrl.isSoftStartDate = isSoftStartDate;

          /**
           * public properties
           */
          $ctrl.selectedOption = {};

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.process = angular.copy($ctrl.process);
            prepareOptionsField();
            prepareStepsField();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            oldStep = angular.copy($ctrl.step);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterDeadlineEventHandler();
            discardDeadlineEventHandler();
          }

          /**
           * @ngdoc method
           * @name isLastStep
           * @description check step is last step in steps list
           * @param {Object} step
           * @returns {Boolean}
           */
          function isLastStep(step) {
            var lastStep = true;
            _.each($ctrl.process.steps.data, function (value) {
              if (step.position < value.position) {
                lastStep = false;
              }
            });
            return lastStep;
          }

          /**
           * @ngdoc method
           * @name prepareOption
           * @description update deadline option value
           * @returns {void}
           */
          function prepareOption(option) {
            if (option.key != $ctrl.selectedOption.key) {
              $ctrl.selectedOption = angular.copy(option);
              $ctrl.form.$setDirty();
              $ctrl.step.deadline.option = $ctrl.selectedOption.key;
              $ctrl.updateStep();
            }
          }

          /**
           * @ngdoc method
           * @name upgradeModal
           * @description display upgrade modal when organization is free
           * @param id
           * @returns {void}
           */
          function upgradeModal(id) {
            if (id !== "start_run") {
              AuthPlan.hasAnyAuthority(PLANS.FREE, 'relativeDeadline');
            }
          }

          /**
           * @ngdoc method
           * @name prepareStep
           * @description prepare step option
           * @param id
           * @returns {void}
           */
          function prepareStep(id) {
            if (id !== $ctrl.selectedStep.id) {
              $ctrl.form.$setDirty();
              $ctrl.step.deadline.step = id;
              setSelectedStep(id);
              $ctrl.updateStep();
            }
          }

          /**
           * @ngdoc method
           * @name disableDeadlineOption
           * @description disable "before" if "Start of Process' selected
           * @param id
           * @returns {void}
           */
          function disableDeadlineOption(id) {
            $ctrl.deadlineOptions[1].isDisable = (id === "start_run");
            $ctrl.deadlineOptionsAfterBefore = !!(id === "start_run");
          }

          /**
           * @ngdoc method
           * @name prepareOptionsField
           * @description prepare deadline option field
           * @returns {void}
           */
          function prepareOptionsField() {
            $ctrl.deadlineOptions = StepService.getDeadlineOptions();
            if (!$ctrl.step.deadline.option) {
              $ctrl.selectedOption = $ctrl.deadlineOptions[0];
              $ctrl.step.deadline.option = $ctrl.selectedOption.key;
            } else {
              $ctrl.selectedOption = _.find($ctrl.deadlineOptions, function (option) {
                return option.key == $ctrl.step.deadline.option;
              });
            }
          }

          /**
           * @ngdoc method
           * @name prepareStepsField
           * @description prepare steps Field
           * @private
           */
          function prepareStepsField() {
            setDeadlineOption($ctrl.step, $ctrl.process.steps.data);
            prepareStepTitles();
            $ctrl.stepOptions = setStepInDeadline;
            $ctrl.stepOptions.unshift({
              id: 'start_run',
              title: $filter('translate')('steps.deadlines.start_process')
            });
            $ctrl.stepOptions.splice(1, 0, {
              title: $filter('translate')('steps.deadlines.otherStep')
            });

            $ctrl.selectedStep = _.find($ctrl.stepOptions, function (step) {
              return step.id == $ctrl.step.deadline.step;
            });

            if (!$ctrl.step.deadline.step) {
              $ctrl.selectedStep = $ctrl.stepOptions[0];
              $ctrl.step.deadline.step = $ctrl.selectedStep;
            } else if ($ctrl.step.deadline.option == 'prior_to' && $ctrl.step.deadline.step == 'start_run') {
              $ctrl.selectedStep = $ctrl.stepOptions[3];
            }

            if (_.isUndefined($ctrl.selectedStep)) {
              if ($ctrl.step.deadline.option == 'prior_to') {
                $ctrl.selectedStep = $ctrl.stepOptions[3];
              } else {
                $ctrl.selectedStep = $ctrl.stepOptions[0];
              }
            }
            setSelectedStep($ctrl.selectedStep.id.toString());
          }

          /**
           * @ngdoc method
           * @name setDeadlineOption
           * @description Set deadline option
           * @public 
           * @param {Object} currentStep
           * @param {Array} steps
           */
          function setDeadlineOption(currentStep, steps) {
            _.forEach(steps, function (data) {
              if (data.deadline.step == currentStep.id) {
                removeStepInDeadline.push(data);
                setDeadlineOption(data, steps);
              }
            });
            setStepInDeadline = _.difference(steps, removeStepInDeadline);
          }

          /**
           * @ngdoc method
           * @name stepDisabled
           * @description disable step inside dropdown based on value
           * @param step
           * @returns {Boolean}
           */
          function stepDisabled(step) {
            if (($ctrl.isFreePlan && step.id != 'start_run') || step.id == $ctrl.step.id) {
              return true;
            }
            if (_.get($ctrl.step, 'deadline.option') == 'prior_to' && step.id == 'start_run') {
              return true;
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name deadlineChanged
           * @description make invalid perent form
           * @returns {void}
           */
          function deadlineChanged() {
            $ctrl.form.$setDirty();
            $timeout(function () {
              $ctrl.updateStep();
            }, 250);
          }

          /**
           * @ngdoc method
           * @name setSelectedStep
           * @description update selected step and its id on change
           * @param id
           * @returns {void}
           */
          function setSelectedStep(id) {
            $ctrl.selectedStepId = id;
            var index = _.findIndex($ctrl.stepOptions, { 'id': id });
            if (index >= 0) {
              $ctrl.selectedStep = $ctrl.stepOptions[index];
            }
            disableDeadlineOption(id);
          }

          /**
           * @ngdoc method
           * @name isSoftStartDate
           * @description update selected step soft start date value
           * @param {$event} event
           * @param {Boolean} value
           * @returns {void}
           */
          function isSoftStartDate(event, value) {
            if (value && value === $ctrl.step.is_soft_start_date) {
              return;
            }
            if (event.target.checked || value) {
              $ctrl.step.is_soft_start_date = true;
            } else {
              $ctrl.step.is_soft_start_date = false;
            }
            $ctrl.updateStep();
          }

          /**
           * event handler when changed step deadline
           * @type {*|(function())}
           */
          discardDeadlineEventHandler = $rootScope.$on('STEP:DEADLINE_CHANGED', function (event, step) {
            $ctrl.selectedOption = _.find($ctrl.deadlineOptions, function (option) {
              return option.key === step.option;
            });
            setSelectedStep(step.step);
          });

          /**
           * event handler when update the step
           * @type {*|(function())}
           */
          unregisterDeadlineEventHandler = $rootScope.$on('STEP:UPDATE_STEPS_IN_DEADLINE', function (event, step) {
            var index = _.findIndex($ctrl.stepOptions, { 'id': step.id });
            $ctrl.stepOptions.splice(index, 1);
            if (step.id == $ctrl.selectedStepId) {
              setSelectedStep($ctrl.stepOptions[0].id.toString());
            }
          });

          /**
           * @ngdoc method
           * @name prepareStepTitles
           * @description Prepare the step titles (add step numbers)
           * @returns {void}
           */
          function prepareStepTitles() {
            _.forEach(setStepInDeadline, function (step, index) {
              step.title = $filter('translate')('steps.logic.label.stepTitle', { position: index + 1, title: step.title });
            });
          }

          /**
           * @ngdoc method
           * @name checkDateValid
           * @description check start date unit and value is before of finsh date
           * @returns {void}
           */
          function checkDateValid() {
            $timeout(function () {
              if ($ctrl.step.is_soft_start_date || TimeFieldService.checkSelctedDateDiff($ctrl.step.start_date, $ctrl.step.deadline)) {
                deadlineChanged();
              }
            }, 250);
          }

          function updateStep() {
            if($ctrl.form.$invalid) {
              return;
            }
            $ctrl.onSaving = true;
            StepService.updateStep({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, $ctrl.step).then(function (response) {
              oldStep = angular.copy($ctrl.step);
              $rootScope.$emit('STEP:UPDATED', { step: response.data, updateDeadline: true });
              $ctrl.form.$setPristine();
              $ctrl.onSaving = false;
              Helper.showChangesSavedGrowl();
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function onDiscard() {
            _.extend($ctrl.step, oldStep);
            prepareOptionsField();
            prepareStepsField();
            $ctrl.form.$setPristine();
          }
        }
    });
})();

/**
 * @ngdoc Run
 * @name tallyfy.config
 *
 * @module tallyfy.config
 *
 * @description
 * Initialise application states
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .run(run);
  run.$inject = ['$rootScope', 'stateHandler', 'translationHandler', '$uibModalStack', '$state'];

  function run($rootScope, stateHandler, translationHandler) {
    stateHandler.initialize();
    translationHandler.initialize();
  }

})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.organizations.factory.OrganizationsRepository
   * @module tallyfy.organizations
   *
   * @description
   * Create Rest resource for organizations
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.organizations')
    .factory('OrganizationsRepository', OrganizationsRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  OrganizationsRepository.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * OrganizationsRepository construct
   */
  function OrganizationsRepository(BaseRepository, CONFIG) {
    var repository = BaseRepository('/organizations/:org/:action/:id/:sub_action/:arg1', {
      org: 'org_id',
      action: '@action',
      id: '@id',
      sub_action: '@sub_action'
    }, {
      $get: { method: 'GET' },
      $create: { method: 'POST' },
      $update: { method: 'PUT' },
      $delete: { method: 'DELETE' },
      myOrganizations: {
        url: CONFIG.API_HOST + '/me/organizations',
        method: 'GET'
      },
      myGuestOrganizations: {
        url: CONFIG.API_HOST + '/public/guests/:action/organizations',
        method: 'GET'
      },
      createOrganization: {
        url: CONFIG.API_HOST + '/organizations',
        method: 'POST',
        params: {
          skipNotFound: true
        }
      },
      setOrgCustomBranding: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/custom-branding'
      },
      getOrgCustomBranding: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/custom-branding'
      },
      getPublicOrgCustomBranding: {
        method: 'GET',
        url: CONFIG.API_HOST + '/utils/organizations/:org/custom-branding'
      },
      deleteOrgCustomBranding: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/custom-branding'
      },
      deleteOrgActiveTokens: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/me/delete-active-tokens'
      },
      fetchSearchResults: {
        method: 'GET',
        cancellable: true,
        url: CONFIG.API_HOST + '/organizations/:org/search'
      },
      removeGroupPicture: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/groups/:id/logo'
      }
    });

    /**
     * get organization
     */
    repository.getOrganization = function (params) {
      return repository.get(params);
    };

    repository.myGuestOrganizations = function (args, params) {
      return BaseRepository('/public/guests/:action/organizations', args).get(params);
    };

    repository.getGuestSMTP = function (args, params) {
      return BaseRepository('/organizations/:org/guests/:guest_code/organization', args).get(params);
    };

    /**
     * get organization logo
     */
    repository.getOrganizationLogo = function (requestParameters) {
      return BaseRepository('/organizations/:org/logo/:fileId', requestParameters, {}).file();
    };

    /**
     * delete organization logo
     */
    repository.deleteOrganizationLogo = function (requestParameters) {
      return BaseRepository('/organizations/:org/logo/:fileId', requestParameters, {}).delete();
    };

    repository.getByType = function (requestParameters) {
      var slug = requestParameters.slug;
      if (slug === 'users') {
        requestParameters.status = 'active';
      }
      delete requestParameters.slug;
      return BaseRepository('/organizations/:org/' + slug,
        requestParameters,
        {}).get();
    };

    repository.getTextTemplates = function (params) {
      return repository.get(params);
    };

    repository.getTextTemplate = function (params) {
      return repository.get(params);
    };

    repository.getGuestTextTemplates = function (params) {
      return BaseRepository('/organizations/:org/guests/guest_code/text-templates/:id', angular.extend(params, {
        org: 'org_id'
      }), {}).get({
        skipAuthToken: true
      });
    };

    repository.setGuestEnableLink = function (params) {
      return BaseRepository('/organizations/:org/guests/:guest_email/enable', angular.extend(params, {
        org: 'org_id'
      }), {}).update();
    };

    repository.setGuestDisableLink = function (params) {
      return BaseRepository('/organizations/:org/guests/:guest_email/disable', angular.extend(params, {
        org: 'org_id'
      }), {}).delete();
    };

    repository.resetGuestLink = function (params) {
      return BaseRepository('/organizations/:org/guests/:guest_email/reset-link', angular.extend(params, {
        org: 'org_id'
      }), {}).update();
    };

    repository.createTextTemplate = function (params, data) {
      return repository.create(params, data);
    };

    repository.updateTextTemplate = function (params, data) {
      return repository.update(params, data);
    };

    repository.deleteTextTemplate = function (params) {
      return repository.delete(params);
    };

    repository.getGuestLinks = function () {
      var params = {
        action: 'guests-link'
      };
      return repository.get(params);
    };

    repository.updateGuestCadenceDays = function (params, data) {
      return BaseRepository('/public/organizations/:org/:action/:id/:sub_action', {
        org: 'org_id',
        action: '@action',
        id: '@id',
        sub_action: '@sub_action'
      }).update(params, data);
    };

    repository.saveGuestData = function (params, data) {
      return repository.update(params, data);
    };

    repository.getDomainsResults = function (params) {
      return BaseRepository('/organizations/:org/domains', angular.extend(params, {
        org: 'org_id'
      })).get();
    };

    //Create new guest
    repository.createGuest = function (data) {
      var params = {
        action: 'guests'
      };
      return repository.create(params, data);
    };

    repository.getgetSignupQuestions = function () {
      return BaseRepository('/signup-questions').get();
    };

    repository.createSignupQuestions = function (params, data) {
      return BaseRepository('/organizations/:org/signup-answers').create(params, data);
    };
    return repository;
  }
})();

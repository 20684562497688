(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.organizations.users.factory.UsersRepository
   * @module tallyfy.organizations.users
   *
   * @description
   * Create Rest resource for account
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.organizations.users')
    .factory('UsersRepository', UsersRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  UsersRepository.$inject = ['BaseRepository', '$rootScope'];
  /**
   * Constructor
   * UsersRepository construct 
   */
  function UsersRepository(BaseRepository, $rootScope) {
    var BASE_URI_SEGMENT = '/organizations/:org/users', repository;

    repository = BaseRepository(BASE_URI_SEGMENT + '/:id/:action/:sub_action_id/:sub_action', {
      org: 'org_id',
      action: '@action',
      id: '@userId',
      sub_action_id: '@sub_action_id',
      sub_action: '@sub_action'
    }, {
      inviteMember: {
        method: 'POST',
        headers: { 'Accept': 'application/json' }
      }
    });

    /* TODO: It can be enabled in future given the alternate invite method is no more required */
    /*repository.invite = function (args, data) {
      args = args || {};
      var params = {
        action: 'invite'
      };
      angular.extend(params, args);
      return repository.create(params, data);
    };*/

    repository.invite = function (args, data) {
      args = args || {};
      var params = {
        action: 'invite'
      };
      angular.extend(params, args);
      return this.inviteMember(params, data).$promise;
    };

    repository.resendInvite = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'resend-invite'
      };
      angular.extend(params, args);
      return repository.create(params);
    };

    repository.withdrawInvite = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'withdraw-invite'
      };
      angular.extend(params, args);
      return repository.delete(params);
    };

    repository.disable = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'disable'
      };
      angular.extend(params, args);
      return repository.delete(params);
    };

    repository.enable = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'enable'
      };
      angular.extend(params, args);
      return repository.update(params);
    };

    repository.approve = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'approve'
      };
      angular.extend(params, args);
      return repository.update(params);
    };

    repository.reject = function (args) {
      args = args || {};
      var params = {
        action: 'reject'
      };
      angular.extend(params, args);
      return repository.delete(params);
    };

    repository.unsuspend = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'unsuspend'
      };
      angular.extend(params, args);
      return repository.update(params);
    };
    
    repository.getPreferences = function (args) {
      args = args || {};
      var params = {
        action: 'me',
        sub_action: 'preferences'
      };
      angular.extend(params, args);
      return BaseRepository('/organizations/:org/:action/:sub_action', params).get();
    };

    repository.savePreferences = function (args, data) {
      args = args || {};
      var params = {
        action: 'me',
        sub_action: 'preferences'
      };
      angular.extend(params, args);
      return repository.create(params, data);
    };

    repository.getOwnerList = function (args) {
      args = args || {};
      var params = {
        status: 'active'
      };
      angular.extend(params, args);
      return repository.get(params);
    };
    
    repository.updateRole = function (args) {
      args = args || {};
      var params = {
        id: args,
        action: 'role'
      };
      angular.extend(params, args);
      return repository.update(params);
    };

    repository.permanentlyDeleteMemberOrGuest = function (args) {
      args = args || {};
      var params = {
        org: $rootScope.identity.default_organization.id,
        sub_action: 'delete'
      };
      angular.extend(params, args);
      return BaseRepository('/organizations/:org/:action/:id/:sub_action', params).delete();
    };

    repository.getLighterUsersList = function (args) {
      args = args || {};
      var params = {
        action: 'users-list'
      };
      angular.extend(params, args);
      return BaseRepository('/organizations/:org/:action/:sub_action', params).get();
    };

    return repository;
  }
})();
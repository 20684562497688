(function () {
  'use strict';
  angular
    .module('tallyfy.list')
    .config(listStateConfig);
  /*@ngInject*/
  function listStateConfig($stateProvider) {
    $stateProvider.state('list', {
      parent: 'app',
      url: '/:org_id/lists',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'list'
        }
      }
    }).state('list.detail', {
      parent: 'app',
      url: '/:org_id/lists/:list_id',
      data: {
        authorities: []
      },
      views: {
        content: {
          component: 'listDetail'
        }
      }
    });
  }
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('runFilterDisplay', {
      bindings: {
        showFilterOptions: '&',
        filterConfig: '=',
        allTags: '<',
        foldersList: '<',
        isGuest: '<',
        filterRemoved: '&'
      },
      templateUrl: 'app/modules/runs/dashboard/run-filter/run-filter-display/run-filter-display.component.html',
      controller:
        /*@ngInject*/
        function (_, $scope, Helper, $rootScope) {
          var $ctrl = this,
            onFilterTagsHandler,
            runFilterConfigWatchers;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.pillClicked = pillClicked;
          $ctrl.filterItemDelete = filterItemDelete;

          function onInit() { }

          function onChanges() { }

          function onDestroy() {
            runFilterConfigWatchers();
            onFilterTagsHandler();
          }

          function pillClicked(e) {
            $ctrl.showFilterOptions({ e: e });
          }

          function filterItemDelete(dataKey, dataValue) {
            $ctrl.filterRemoved({ key: dataKey, value: dataValue });
          }

          runFilterConfigWatchers = $scope.$watchCollection(function () {
            return _.pick($ctrl.filterConfig, ['filterObj', 'tagsObj', 'folderObj', 'typeObj']);
          }, function (value) {
            for (var propName in value) {
              if (Helper.isObjectEmpty(value[propName])) {
                delete value[propName];
              }
            }
            $ctrl.filterParams = value;
          });

          onFilterTagsHandler = $rootScope.$on('FILTER_AP_TAGS:TAGS', function (e) {
            var filters = _.pick($ctrl.filterConfig, ['filterObj', 'tagsObj', 'folderObj', 'typeObj'])
            for (var propName in filters) {
              if (Helper.isObjectEmpty(filters[propName])) {
                delete filters[propName];
              }
            }
            $ctrl.filterParams = filters;
          });

        }
    });
})();
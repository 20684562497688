/**
 * @ngdoc Component
 * @name tallyfy.steps.component.steps
 * @module tallyfy.steps
 *
 * @description
 * A component to manage process edit view
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('steps', {
      templateUrl: 'app/modules/steps/steps.html',
      bindings: {
        processId: '<',
        steps: "<",
        selectedStep: "=",
        selectedStepLastActiveTab: "="
      },
      require: {
        processCtrl: '^processEdit'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, ProcessService, StepService, TFY_EVENTS, Helper, koStepService, BLUEPRINT_TYPE) {
          var $ctrl = this,
            stepUpdatedEventHandler,
            firstSort = true;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.groups = [];
          $ctrl.isLoading = false;
          $ctrl.selectedStep = {};
          $ctrl.newStepPosition = 1;
          $ctrl.koStepShown = false;
          $ctrl.sortableOptions = sortableOptions();

          /**
           * public methods
           */
          $ctrl.onCreated = onStepCreated;
          $ctrl.onDelete = onStepDeleted;
          $ctrl.onUpdate = onUpdate;
          $ctrl.stepsCollapsed = stepsCollapsed;

          $ctrl.onBpDescToggle = onBpDescToggle;
          $ctrl.onKOFormAddClick = onKOFormAddClick;
          $ctrl.sortHandler = sortHandler;
          $ctrl.openStepTipsModal = StepService.openStepTipsModal;
          $ctrl.onBpDescUpdate = onBpDescUpdate;
          $ctrl.onKoStepDelete = onKoStepDelete;
          $ctrl.openKOStep = openKOStep;
          $ctrl.openBPDesc = openBPDesc;
          $ctrl.openStep = openStep;
          $ctrl.checkTriggerFrom = checkTriggerFrom;

          /**
            * @function
            * @name initialization
            * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
            */
          function initialization() {
            $ctrl.process = $ctrl.processCtrl.process;
            $ctrl.oldProcess = angular.copy($ctrl.process);
            $ctrl.newStepPosition = $ctrl.steps.length + 1;
            $ctrl.originalSteps = angular.copy($ctrl.steps);
            $ctrl.isProcessHavingSnippetTag = _.get($ctrl.process, 'type') ===  BLUEPRINT_TYPE.DOCUMENT;
            $ctrl.isProcessHavingFormTag = _.get($ctrl.process, 'type') ===  BLUEPRINT_TYPE.FORM;
            if ($ctrl.isProcessHavingFormTag ) {
              onKOFormAddClick();
            }
            if ($ctrl.process.prerun.length) {
              $ctrl.koStepShown = true;
            }
          }

          /**
          * @function
          * @name onChanges
          * @description A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            stepUpdatedEventHandler();
            selectedStepWatcher();
            bpDescExpandedWatcher();
            koStepExpandedWatcher();
          }

          /**
           * @ngdoc method
           * @name sortableOptions
           * @description Update step position
           * @returns object
           */
          function sortableOptions() {
            return {
              handle: '.step-number',
              axis: 'y',
              start: startHandler,
              stop: sortHandler,
              animation: 300,
              disabled: !Helper.checkAccessAuthority(false)
            };
          }

          /**
           * @ngdoc method
           * @name changedPosition
           * @description Update step position
           * @param {object} step 
           * @returns void
           */
          function changedPosition(step) {
            StepService.isValidStepCondition(step);
            $ctrl.isLoading = true;
            StepService.updateStep({
              id: step.id,
              checklist_id: $ctrl.processId,
              skipNotFound: true
            }, step).then(function (response) {
              $ctrl.isLoading = false;
              $ctrl.isDragging = false;
              $rootScope.$emit("STEP:UPDATE_STEP_POSITION", step);
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { last_updated: response.data.last_updated });
            }, function () {
              $ctrl.isLoading = false;
              $ctrl.isDragging = false;
            });
          }

          /**
           * @ngdoc method
           * @name reorderSteps
           * @description rearrange the steps order
           * @returns void
           */
          function reorderSteps() {
            var i = 0;
            _.forEach($ctrl.steps, function (step) {
              step.position = ++i;
            });
          }

          /**
           * @name startHandler
           * @param {*} e 
           * @param {*} ui 
           * @description handling when step is start dragging
           */
          function startHandler(e, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @ngdoc method
           * @name sortHandler
           * @param {*} event
           * @param {*} ui
           * @description sortable handler
           * @returns void
           */
          function sortHandler(event, ui) {
            $ctrl.isDragging = true;
            if (ui.item.sortable.dropindex >= 0 &&
              ui.item.sortable.dropindex < $ctrl.steps.length) {
              reorderSteps();
              changedPosition($ctrl.steps[ui.item.sortable.dropindex]);
            }
          }

          /**
           * @ngdoc method
           * @name onStepCreated
           * @description Method will be triggered when a step is created
           * @param {*} step
           * @returns {any}
           */
          function onStepCreated(step) {
            if (step) {
              $ctrl.process.steps.data.push(step);
              $ctrl.originalSteps.push(step);
              $ctrl.selectedStep = step;
              stepsCollapsed();
              $ctrl.processCtrl.updateStepCount();
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { last_updated: step.last_updated });
              $rootScope.$emit("STEP:UPDATE_DEADLINE", step);
            }
          }

          /**
           * @ngdoc method
           * @name onStepDeleted
           * @param {*} step
           * @description Method will be triggered when a step is deleted
           * @returns void
           */
          function onStepDeleted(step) {
            var index = _.findIndex($ctrl.steps, { 'id': step.id });
            $ctrl.steps.splice(index, 1);
            if (step.id === $ctrl.selectedStep.id) {
              $ctrl.selectedStep = {};
            }
            $ctrl.processCtrl.updateStepCount();
            reorderSteps();
            $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { last_updated: step.last_updated });
            $rootScope.$emit("STEP:UPDATE_STEPS_IN_DEADLINE", step);
          }

          /**
           * @ngdoc method
           * @name onUpdate
           * @param {*} step
           * @description Method will be triggered when a step is updated
           * @returns void
           */
          function onUpdate(step) {
            var index = _.findIndex($ctrl.steps, { 'id': step.id });
            //@TODO will update step in process if required in future
            $ctrl.steps[index] = step;
            $ctrl.selectedStep = $ctrl.steps[index];
          }

          /**
           * @ngdoc method
           * @name stepsCollapsed
           * @description a method to collapse all step
           * @returns void
           */
          function stepsCollapsed() {
            _.forEach($ctrl.steps, function (stepItem) {
              var invalidStep = StepService.validateStepFields(stepItem);
              if (invalidStep || _.isEmpty(stepItem.title)) {
                $ctrl.selectedStep = stepItem;
              } else {
                stepItem.isExpanded = false;
              }
            });
          }

          /**
           * @ngdoc method
           * @public
           * @name onBpDescToggle
           * @description On BP Description Toggle click
           */
          function onBpDescToggle() {
            var ableToExpand = StepService.isAnyDirtyStepExpanded($ctrl.selectedStep) || koStepService.isKOFormDirty($ctrl.process, $ctrl.oldProcess);
            if (!ableToExpand) {
              $rootScope.$broadcast('BP_DESC:OPEN');
            }
            else {
              openBPDesc();
            }
          }

          function openBPDesc() {
            if (!$ctrl.process.summary && !Helper.checkAccessAuthority()) {
              return;
            }
            $ctrl.showDescPanel = true;
            $ctrl.isBpDescExpanded = true;
          }

          /**
           * @ngdoc method
           * @public
           * @name onKOFormAddClick
           * @param {*} event
           * @description
           * on KO Form navigation click handler
           */
          function onKOFormAddClick(event) {
            var ableToExpand = StepService.isAnyDirtyStepExpanded($ctrl.selectedStep) || StepService.isDescHasUnsavedChanges($ctrl.oldProcess.summary, $ctrl.process);
            if (!ableToExpand) {
              $rootScope.$broadcast('KO_STEP:OPEN');
            }
            else {
              openKOStep(event);
            }
          }

          function openKOStep(event) {
            if ($ctrl.process.kickoff_title || $ctrl.process.prerun.length) {
              $ctrl.isKoStepExpanded = true;
            } else {
              if (!Helper.checkAccessAuthority())
                return;
              if (event) {
                event.stopPropagation();
              }
              $ctrl.process.kickoff_title = $ctrl.process.title;
              updateChecklist().then(function () {
                $ctrl.isKoStepExpanded = true;
              }, function () {
                $ctrl.isKoStepExpanded = false;
              });
            }
          }

          function openStep(args) {
            $rootScope.$broadcast('STEP:OPEN_TRIGGER', args);
          }

          function updateChecklist() {
            return ProcessService.update({
              id: $ctrl.process.id
            }, $ctrl.process);
          }
          
          /**
           * @ngdoc method
           * @name onUpdate
           * @param {*} process
           * @param {*} isDelete
           * @description Method will be triggered when the BP desc is updated
           * @returns void
           */
          function onBpDescUpdate(process, isDelete) {
            $ctrl.process.summary = $ctrl.processCtrl.process.summary = process.summary;
            if (isDelete) {
              $ctrl.showDescPanel = $ctrl.isBpDescExpanded = false;
            }
          }

          /**
           * @ngdoc method
           * @name onUpdate
           * @description Method will be triggered when the KO desc / title is deleted
           * @returns void
           */
          function onKoStepDelete() {
            $ctrl.isKoStepExpanded = false;
          }

          function checkTriggerFrom(triggerName, args) {
            triggerName === 'KO_STEP:OPEN'
              ? $ctrl.openKOStep() : triggerName === 'BP_DESC:OPEN'
                ? $ctrl.openBPDesc() : triggerName === 'STEP:OPEN'
                  ? $ctrl.openStep(args) : angular.noop();
          }

          /**
           * event handler when step updated
           */
          stepUpdatedEventHandler = $rootScope.$on('STEP:UPDATED', function (e, data) {
            var stepData = data.step || data, editedStep = _.filter($ctrl.originalSteps, {
              'id': stepData.id
            })[0];
            angular.extend(editedStep, stepData);
          });

          // ko step expanded watcher
          var koStepExpandedWatcher = $scope.$watch('$ctrl.isKoStepExpanded', function (value) {
            if (!value) {
              return;
            }
            $ctrl.isBpDescExpanded = false;
            $ctrl.selectedStep.isExpanded = false;
          });

          // bp desc expanded watcher
          var bpDescExpandedWatcher = $scope.$watch('$ctrl.isBpDescExpanded', function (value) {
            if (!value) {
              return;
            }
            $ctrl.isKoStepExpanded = false;
            $ctrl.selectedStep.isExpanded = false;
          });

          // selected step expanded watcher
          var selectedStepWatcher = $scope.$watch('$ctrl.selectedStep.isExpanded', function (value) {
            if (!value) {
              return;
            }
            $ctrl.isBpDescExpanded = false;
            $ctrl.isKoStepExpanded = false;
          }, true);
        }
    });
})();

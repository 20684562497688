/**
 * @ngdoc Component
 * @name tallyfy.organizations.domainOrg
 * @module tallyfy.organizations
 *
 * @description
 * Template text
 *
 * @author samier Sompura (gmail::samier.sompura@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations')
    .component('domainOrg', {
      templateUrl: 'app/modules/organizations/domainOrg/domain-org.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, OrganizationsService, BeaconService, Helper, blockUI, SupportService) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('domainsOrg');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.openAskQuestion = openAskQuestion;
          $ctrl.updateAutoJoin = updateAutoJoin;
          $ctrl.upgradeOrg = upgradeOrg;

          function onInit() {
            blockUI.start();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.disabledAutoJoin = $ctrl.haveAuthority && _.get($rootScope, 'identity.is_support');
            $ctrl.org = _.get($rootScope.identity, 'default_organization');
            var params = {
              org: $ctrl.org.id
            };
            OrganizationsService.domainsData(params).then(function (response) {
              $ctrl.domains = _.get(response, 'data');
              getOrgData($ctrl.domains);
              blockUI.stop();
            });
          }

          function onChanges() { }
          function onDestroy() { }

          /**
           * @function
           * @name openAskQuestion
           * @returns {void}
           */
          function openAskQuestion() {
            BeaconService.open();
          }

          /**
           * @function
           * @name updateAutoJoin
           * @description update organization info - auto join domain.
           * @returns {void}
           */
          function updateAutoJoin(autoJoinSignupsValue) {
            blockUI.start();
            SupportService.updateOrgInfo($ctrl.org.id, {
              auto_join_signups: autoJoinSignupsValue
            }).then(function (res) {
              blockUI.stop();
              $rootScope.identity.organizations.auto_join_signups = _.get(res, 'data.auto_join_signups');
            }, function () { blockUI.stop(); });
          }

          /**
           * @ngdoc method
           * @name upgradeOrg
           * @description CA alert update Org Plan link text
          */
          function upgradeOrg() { 
            if (!Helper.checkAccessAuthority())
              return;
          }

          /**
           * @function
           * @name getOrgData
           * @description get attached organizations
           * @returns {void}
           */
          function getOrgData(data) {
            var userDomain = _.split(_.get($rootScope, 'identity.email'), '@');
            $ctrl.attached_organizations = _.filter(data, { 'name': userDomain[1] });
            $ctrl.orgDomains = _.get($ctrl.attached_organizations[0], 'attached_organizations');
          }
        }
    })
})();
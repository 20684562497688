/**
 * @ngdoc Directive
 * @name tallyfy.closeGrowlOnClick
 * @description 
 * Close growl message on click event
 * If the TTL value is -1 then the growl message will not disappear.
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('closeGrowlOnClick', closeGrowlOnClick);
    /*@ngInject*/
    function closeGrowlOnClick(_, growlMessages, Growl, $rootScope) {
      return {
        restrict: 'A',
        link: function (scope, element) {
          element.on('click', function (e) {
             var universalSearchEle = document.getElementById("universal-search"), 
             isUniversalSearchActive = angular.element(universalSearchEle).hasClass('universal-active'),
             appHeaderElement = document.getElementsByClassName('app-header')[0];
             if (universalSearchEle && isUniversalSearchActive) {
               angular.element(universalSearchEle).removeClass('universal-active');
               angular.element(appHeaderElement).removeClass('increase-z-index');
               $rootScope.$emit("UNIVERSAL:SEARCH_RESET");
             }
            // If click on link
            if (e.target.tagName == 'a' || e.target.tagName == 'A') return;
            var messages = [];
            _.forEach(growlMessages.directives, function (key, referenceId) {
              messages = Growl.getAllMessages(referenceId);
              if (messages.length > 0 && _.head(messages).ttl > 0) {
                Growl.clearAllMessages(referenceId);
              }
            });
          });
        }
      };
    }
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.account.factory.AccountRepository
   * @module tallyfy.account
   *
   * @description
   * Create Rest resource for account
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.account')
    .factory('AccountRepository', AccountRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  AccountRepository.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * AccountRepository construct 
   */
  function AccountRepository(BaseRepository, CONFIG) {
    var process_view = CONFIG.API_HOST + '/organizations/:org/me/process-views/:id';
    return BaseRepository('/organizations/:org/accounts/:action/:id', {
      org: 'org_id',
      action: '@action',
      id: '@id'
    }, {
        me: {
          method: 'GET',
          url: CONFIG.API_HOST + '/me',
          params: {
            org: ''
          }
        },
        getAccount: {
          method: 'GET',
          url: CONFIG.API_HOST + '/organizations/:org/me'
        },
        updateAccount: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/me'
        },
        updateUserAccount: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/users/:id'
        },
        removeAvatar: {
          method: 'DELETE',
          url: CONFIG.API_HOST + '/me/profile-pic'
        },
        getPreference: {
          method: 'GET',
          url: CONFIG.API_HOST + '/organizations/:org/users/:id/preferences',
          params: {
            per_page: 999
          }
        },
        setPreference: {
          method: 'POST',
          url: CONFIG.API_HOST + '/organizations/:org/users/:id/preferences'
        },
        getMyPreference: {
          method: 'GET',
          url: CONFIG.API_HOST + '/organizations/:org/me/preferences',
          params: {
            per_page: 999
          }
        },
        saveMyPreference: {
          method: 'POST',
          url: CONFIG.API_HOST + '/organizations/:org/me/preferences'
        },
        refreshKey: {
          method: 'GET',
          url: CONFIG.API_HOST + '/users/refresh-key'
        },
        /**
         * Run view
         */
        getRunView: {
          method: 'GET',
          url: process_view
        },
        setRunView: {
          method: 'POST',
          url: process_view
        },
        deleteRunView: {
          method: 'DELETE',
          url: process_view,
          params: {
            id: '@id'
          }
        },
        updateRunView: {
          method: 'PUT',
          url: process_view,
          params: {
            id: '@id'
          }
        },
        updateCadenceDays: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/users/:user_id/cadence_days'
        },
        updateNewAdmin: {
          method: 'PUT',
          params: {
            id: '@user_id'
          },
          url: CONFIG.API_HOST + '/organizations/:org/users/:id/set-new-admin'
        },
        convertMemberToGuest: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/users/:id/to/guest'
        }
      });
  }
})();
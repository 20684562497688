(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('shareKOModal', {
      templateUrl: 'app/components/compact/components/koFormStep/shareKOModal/share-ko-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, Helper, ENV_CONFIG, PLANS) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onCloseModal = onCloseModal;
          $ctrl.copyPublicKoLink = copyPublicKoLink;

          function onInit() {
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.publicKoLink = ENV_CONFIG.TYFY_MAIN_URL + '/public/library/'
              + $ctrl.orgId + '/blueprint/' + $ctrl.resolve.process.id + '/kick-off';
            $ctrl.isTrialPlan = _.get($rootScope.identity, 'default_organization.subscription.data.plan_code') === PLANS.FREE;
          }

          function onChanges() { }
          
          function onDestroy() { }

          function onCloseModal() {
            $ctrl.close();
          }

          function copyPublicKoLink(e) {
            Helper.copyToClipboard($ctrl.publicKoLink, 'guestUrl', e);
          }
        }
    });
})();
/**
 * @ngdoc directive
 * @name tallyfy.compactTaskContainer
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("compactTaskContainer", compactTaskContainer);

  /*@ngInject */
  function compactTaskContainer(_, $timeout, $rootScope, $window, $document, DOMService, $state) {
    return {
      restrict: 'A',
      scope: {
        compactTaskContainer: '<',
        stage: '<?',
        unmappedStage: '<?'
      },
      link: function ($scope) {
        var validationTimeoutHandler, lazyLoadTimeoutHandler, imageLoading = false,
          body = angular.element('body'),
          document = angular.element($document),
          window = angular.element($window),
          mainSection = angular.element($scope.compactTaskContainer.mainSection),
          lastScrollPosition = 0,
          containerWatcher, onFirstLoadFetchEnd, onFormValidationError, onCanLoadPreviousTaskLoadHandler, onFirstLoadFetchError;

        body.addClass('compact-task-active');

        /**
         * @ngdoc method
         * @name onWindowResize
         * 
         * @description
         * on window resize event handler callback method
         */
        function onWindowResize() {
          lazyLoadImage();
        }

        /**
         * @ngdoc method
         * @name scrollCheck
         * @param {*} data
         * @description
         * scroll check
         */
        function scrollCheck(data) {
          if ($scope.stage || $scope.unmappedStage) {
            return;
          }
          if (_.get($scope.compactTaskContainer, 'isSplashing')) {
            $rootScope.$emit('COMPACT_TASK:FETCH_NEXT', { direction: _.get(data, 'direction', 'next') });
          } else {
            if (!_.get(mainSection, 'length', 0)) {
              return;
            }
            $timeout(function () {
              // fetch next task if scroll don't appears yet but not all task/page rendered
              if (_.get(data, 'direction', '') === 'previous') {
                if (mainSection.scrollTop() < 40) {
                  $scope.compactTaskContainer.isBusy = true;
                  mainSection.scrollTop(60);
                  $rootScope.$emit('COMPACT_TASK:FETCH_PREVIOUS', { direction: data.direction });
                } else {
                  $scope.compactTaskContainer.isBusy = false;
                }
              } else {
                if (mainSection.get(0).scrollHeight <= mainSection.get(0).clientHeight) {
                  if ($scope.compactTaskContainer.isBusy) {
                    return;
                  }
                  $scope.compactTaskContainer.isBusy = true;
                  $rootScope.$emit('COMPACT_TASK:FETCH_NEXT', { direction: _.get(data, 'direction', 'next') });
                } else {
                  $scope.compactTaskContainer.isBusy = false;
                }
              }
            }, 250);
          }
        }

        /**
         * @ngdoc method
         * @name onContainerScrollUpHandler
         * @param {$event} e
         * @description
         * scroll up event handler
         */
        function onContainerScrollUpHandler(e) {
          if ($scope.stage || $scope.unmappedStage) {
            return;
          }
          var scrollPosition = mainSection.scrollTop();
          if (scrollPosition < lastScrollPosition) {
            if (scrollPosition < 40 && !$scope.compactTaskContainer.isBusy) {
              $scope.compactTaskContainer.isBusy = true;
              $rootScope.$emit('COMPACT_TASK:FETCH_PREVIOUS', { stage: $scope.stage, unmappedStage: $scope.unmappedStage });
            }
          }
          lastScrollPosition = scrollPosition;
        }

        /**
         * @ngdoc method
         * @name lazyLoadImage
         * 
         * @description
         * lazy load image method
         */
        function lazyLoadImage() {
          if (!imageLoading) {
            imageLoading = true;
            lazyLoadTimeoutHandler = $timeout(function () {
              var imgs = document.querySelectorAll('.lazy-image');
              if (imgs.length) {
                $scope.$applyAsync(function () {
                  _.forEach(imgs, function (img) {
                    var rect = img.getBoundingClientRect().top;
                    if (rect <= mainSection.height() + mainSection[0].getBoundingClientRect().top) {
                      var src = img.getAttribute('data-image-lazy-load');
                      img.setAttribute('src', src);
                      img.classList.add('lazy-image-loaded');
                      img.classList.remove('lazy-image');
                    }
                  });
                });
              }
              imageLoading = false;
            }, 350);
          }
        }

        /**
         * @ngdoc method
         * @name throttle
         * @param {*} fn 
         * @param {*} wait
         * 
         * @description
         * throttle method for scroll event handler to avoid event firing each time 
         */
        function throttle(fn, wait) {
          var time = Date.now();
          return function () {
            if ((time + wait - Date.now()) < 0) {
              fn();
              time = Date.now();
            }
          };
        }

        /**
         * @ngdoc method
         * @name onDestroy
         * 
         * @description
         * when scope destroyed
         */
        function onDestroy() {
          containerWatcher();
          onFirstLoadFetchEnd();
          onFormValidationError();
          onFirstLoadFetchError();
          onCanLoadPreviousTaskLoadHandler();
          validationTimeoutHandler ? $timeout.cancel(validationTimeoutHandler) : angular.noop();
          lazyLoadTimeoutHandler ? $timeout.cancel(lazyLoadTimeoutHandler) : angular.noop();
          window.unbind('resize', throttle(onWindowResize, 50));
          mainSection.unbind('scroll', throttle(lazyLoadImage, 50));
          if (!($scope.stage || ($scope.stage && $scope.unmappedStage))) {
            body.removeClass('compact-task-active');
          }
          mainSection.unbind('scroll', onContainerScrollUpHandler);
        }

        // watch for compactTaskContainer config value
        containerWatcher = $scope.$watch('compactTaskContainer', function (value) {
          var isFirstLoad = _.get(value, 'isFirstLoad');
          var isSplashing = _.get(value, 'isSplashing');
          mainSection.css('overflow-y', isFirstLoad || isSplashing ? 'hidden' : 'auto');
        }, true);

        onFirstLoadFetchEnd = $rootScope.$on('COMPACT_TASK:FETCH_END', function (e, data) {
          if ($scope.stage && data.stage) {
            return;
          }
          scrollCheck(data);
        });

        onFormValidationError = $rootScope.$on('COMPACT:FORM_VALIDATION_ERROR', function (e, data) {
          validationTimeoutHandler = $timeout(function () {
            DOMService.centerObjectToView('.has-invalid', { behavior: "smooth", block: "center" });
          }, 0);
        });

        onCanLoadPreviousTaskLoadHandler = $rootScope.$on('COMPACT:READY', function (e) {
          if ($scope.compactTaskContainer.canLoadPreviousPage) {
            mainSection.bind('scroll', onContainerScrollUpHandler);
          }
        });

        onFirstLoadFetchError = $rootScope.$on('COMPACT_TASK:FETCH_ERROR', function (e, data) {
          if ($scope.stage || $scope.unmappedStage) {
            return;
          }
          $scope.compactTaskContainer.isBusy = false;
        });

        window.bind('resize', throttle(onWindowResize, 50));
        mainSection.bind('scroll', throttle(lazyLoadImage, 50));
        $scope.$on('$destroy', onDestroy);
      }
    };
  }
})();
/**
 * @ngdoc constant
 * @name tallyfy.core.constant.[Moment, .....]
 * @module tallyfy.core
 *
 * @description
 * Create an Angular Constant for vendor libraries' global variables. Lets use everythings as angular dependecy 
 * avoid global variable/objects inside angular app
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

/* global moment:false */
(function () {
  'use strict';
  angular
    .module('tallyfy.core')
    .constant('CONST', (function () {
      var INVALID_CHARACTERS_REGEX = /[^~@#$%^*()_|+=;:`'",<>{}\\[\]]/;
      return {
        EMAIL_REGEX: /([A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)/,
        URL_REGEX: /(ftp|http|https):\/\/(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
        POSITIVE_INTEGER_REGEX: /^\d+$/,
        FULL_URL_REGEX: /(\b(ftp|http|https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
        TEMP_REGEX: /^[~!@#$%^&()_|+-=?;:'",.<>{}[\]/]/,
        NAME_REGEX: new RegExp("(^[^" + INVALID_CHARACTERS_REGEX.toString().slice(3, -2) + "]*$)|(^[ ]$)"),
        INVALID_CHARACTERS_MATCH_REGEX: new RegExp("[" + INVALID_CHARACTERS_REGEX.toString().slice(3, -2) + "]", 'gi'),
        INSERT_VARIABLE_REGEX: /\{?\{([^{]*?)(\}?})/g,
        ALIAS_REGEX: /[\])}[{(]/g,
        DOUBLE_CURLY_REGEX: /(\{{.*?\}})/g,
        SINGLE_CURLY_REGEX: /(\{.*?\})/g,
        SINGLE_CORNER_REGEX: /(\<.*?\>)/g,
        EXTRA_WHITESPACE_REGEX: /\s+/g,
        SINGLE_SQUARE_BRACES_REGEX: /(\[.*?\])/g,
        HREF_REGEX: /href="([^"]*)/g,
        TEXT_IN_B_TAG_REGEX: /(\<b.*?\<\/b>)/g,
        TEXT_IN_A_TAG_REGEX: /(\<a.*?\<\/a>)/g,
        REMOVE_HTML_TAG_REGEX: /(<([^>]+)>)/ig,
        B_TAG_REGEX: /\<b\>|<\/b\>/g,
        INSERT_VAR_REGEX: /(\<div class=.inline.*?\<\/div>)/g,
        URL_PREFIX_CHECKER: /^(?:https?:\/\/)?(?:www\.)?/i,
        PHONE_NUMBER_REGEX: /^([0-9][0-9]*)$/,
        COMA_REGEX: /,/g,
        IS_HTML_STRING: /<(?=.*? .*?\/ ?>|br|hr|input|img|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i,
        EMAIL_REGEX_START_WITH_PLUS: /[+]([A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)/,
        STEP_ALLOWED_FILE_TYPES: ['.xlsx', '.xls', '.pptx', '.docx', '.ppt', '.doc', '.rtf', '.pdf', '.txt', '.mpga', '.wav', '.mov', '.mp4', '.webm', '.jpg', '.gif', '.png', '.ai', '.psd', '.hdpkg', '.zip', '.anx', '.xml', '.jpeg', '.dwg', '.lic', '.LICENSE', '.csv', '.oft'],
        FROALA_ALLOWED_FILE_TYPES: ['xlsx', 'xls', 'pptx', 'docx', 'ppt', 'doc', 'rtf', 'pdf', 'txt', 'mpga', 'wav', 'mov', 'mp4', 'webm', 'jpg', 'gif', 'png', 'ai', 'psd', 'hdpkg', 'zip', 'anx', 'xml', 'jpeg', 'dwg', 'lic', 'LICENSE', 'csv', 'oft'],
        CLIENT_LOCAL_DEV_URL: 'https://localhost:8080/auth/handler',
        TRIM_WHITESPACE_REGEX: /\s/g,
        COMMENT_FILTER_LENGTH: 7,
        COMMENT_PER_PAGE: 3,
        TABLE_ALLOWED_IMPORT: ['.xlsx', '.xls', '.csv'],
        DOC_AI_SOP_ALLOWED_IMPORT: ['.doc', '.docx', '.pdf'],
        HTML_ATTRIBUTE_REGEX: /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g,
        CONTENT_IN_TAG_REGEX: />([^<]*)</g
      };
    })())
    .constant('PLANS', {
      ENTERPRISE_ANNUAL: "enterprise-annual",
      ENTERPRISE_MONTHLY: "enterprise-monthly",
      PRO: "pro",
      PROMONTHLY: "pro-monthly",
      PROANNUAL: "pro-annual",
      BASIC: "basic",
      BASICMONTHLY: "basic-monthly",
      BASICANNUAL: "basic-annual",
      FREE: "free",
      PRO_ANNUAL_PRICE: "25",
      BASIC_ANNUAL_PRICE: "12.50",
      DOCS_ANNUAL_PRICE: "2.50",
      ANNUAL_PLANS: ["basic-annual", "docs-annual", "pro-annual", "enterprise-annual"],
      RESTRICATED_PLANS: ["free", "basic", "basic-monthly", "basic-annual", "docs", "docs-monthly", "docs-annual"],
      DOCS: "docs",
      DOCSANNUAL: "docs-annual",
      DOCSMONTHLY: "docs-monthly"
    })
    .constant('TASKORDER', {
      POSITION: "position",
      DEADLINE: "deadline"
    })
    .constant('SOUNDPATH', {
      ONCOMPLETE: 'assets/sounds/completed-task.wav',
      ONREOPEN: 'assets/sounds/re-open-task.wav'
    })
    .constant('DOM', {
      MASTER_VIEW: {
        ELEMENT: '.app-body',
        STYLE: {
          padding_top: 80
        }
      },
      ERROR_VIEW: {
        ELEMENT: '.failed-error',
        ERROR_ELEMENT: '.has-error',
        STYLE: {
          padding_top: 100
        }
      },
      STEP_CARD: {
        ELEMENT: 'card'
      },
      AUTO_GROW: {
        max_height: 360
      }
    })
    .constant('DESCRIPTIONSIZE', {
      default: 250, //BP limit
      maxLength: 64,
      maxBPTitleLength: 150,
      maxProcessTitleLength: 300, //250 (BP limit)
      maxTitle: 125,
      compactProcessNote: 110,
      maxFormFieldTitleLength: 250,
      processNote: 180,
      globalSearchDescription: 85,
      taskStepTitle: 600 //Task & Step title length limit
    })
    .constant('moment', moment)
    .constant('_', window._)
    .constant('DATEFORMAT', {
      SHORT: 'MM/DD/YYYY',
      DEFAULT: 'YYYY-MM-DD HH:mm:ss',
      ABSOLUTE: 'MMM D, YYYY  h:mm a',
      UNIX: 'M/D/YYYY H:mm',
      TIMEUNIT: 'hh:mm A'
    })
    .constant('VIMEO', {
      ELEMENTS: {
        'PATH': 'https://player.vimeo.com/video/251707647?autoplay=1&title=0&byline=0&portrait=0',
        'KOFORM': 'https://player.vimeo.com/video/376863585?autoplay=1&color=1b933a&title=0&byline=0&portrait=0',
        'PROCEDURE': 'https://player.vimeo.com/video/376910589?autoplay=1&color=1b933a&title=0&byline=0&portrait=0',
        'FORM': 'https://player.vimeo.com/video/376910589?autoplay=1&color=1b933a&title=0&byline=0&portrait=0'
      }
    })
    .constant('COMMON', {
      LIMITS: {
        'TASKNAME': 40,
        'ASSIGNEE': 25,
        'TOTALTASKS': 5
      },
      MAX_TEXT_CHAR_LIMIT: 30000,
      MAX_SHORT_TEXT_FIELD_LIMIT: 200,
      MAX_AUTOMATION_NAME: 300,
      TITLE_TOOLTIP_LIMIT: 50,
      TITLE_TOOLTIP_LIMIT_LEFT_PANE: 25
    })
    .constant('COLOR', {
      'base_highlight': '#3fb65b'
    })
    .constant('NOTIFICATIONCOUNT', {
      'HISTORY_COUNT': 30
    })
    .constant('WEBPAGEURL', {
      public: [
        'authHandler',
        'register',
        'resetPassword',
        'activate',
        'guestTask'
      ]
    })
    .constant('MCAFEEHOSTURL', {
      production: 'go.tallyfy.com',
      staging: 'staging-go.tallyfy.com'
    })
    .constant('MOESIF_URL', {
      production: 'https://www.moesif.com/wrap/app/87:69-93:80/user',
      staging: 'https://www.moesif.com/wrap/app/87:69-493:76/user'
    })
    .constant('ENTITY_TYPE', {
      TASK: 'task'
    })
    .constant('WUFOO_URL', {
      URL: 'https://tallyfy.wufoo.com/forms/?formname=x1ppn8y00lni9lx&field7='
    })
    .constant('MESSAGE_TYPE', {
      REPORT: 'messageSend',
      RESOLVE: 'resolve',
      REOPEN_COMMENT: 'key:reopenComment, ',
      SEND_COMMENT: 'commentSend',
      REPORT_COMMENT: 'report',
      OPEN: 'open',
      COLLAPSED: 'collapsed',
      HIDE_FOR_GUESTS: 'hide-for-guests',
      IMPROVEMENT: 'improvement',
      ADVICE: 'advice',
      COMMENT: 'comment'
    })
    .constant('BLUEPRINT_TYPE', {
      SNIPPET: "snippet",
      PROCEDURE: "procedure",
      FORM: "form",
      QUICKPROCEDURE: "quickProcedure",
      DOCUMENT: "document"
    })
    .constant('MODAL_CLOSE_EVENTS', {
      OUTSIDECLICK: "backdrop click",
      ESCAPEPRESS: "escape key press"
    })
    .constant('TAG', {
      TAGCOLOR: "#d3dce2"
    })
    .constant('USER_STATE', {
      GUEST: 'GUEST',
      PUBLIC: 'PUBLIC',
      MEMBER: 'MEMBER',
      PUBLIC_PROCESS: 'PUBLIC_PROCESS'
    })
    .constant('MAILTO', {
      HEY_TALLYFY: "hey@tallyfy.com",
      SUPPORT_TALLYFY: "support@tallyfy.com",
      AMIT_TALLYFY: "amit@tallyfy.com"
    })
    .constant('WS', {
      EVENTS: {
        ON_OPEN: 'WS:EVENTS:ON_OPEN',
        ON_CLOSE: 'WS:EVENTS:ON_CLOSE',
        ON_ERROR: 'WS:EVENTS:ON_ERROR',
        ON_MESSAGE: 'WS:EVENTS:ON_MESSAGE',
      },
      STATES: {
        OPEN: 'WS:STATES:OPEN',
        CLOSING: 'WS:STATES:CLOSING',
        CLOSE: 'WS:STATES:CLOSED',
        CONNECTING: 'WS:STATES:CONNECTING'
      },
      READY: 'WS:READY'
    })
    .constant('LIST_TYPE', {
      PUBLIC: 'public',
      PRIVATE: 'private'
    })
    .constant('VALIDATION', {
      FN_LN_MAX_LENGTH: 32,
      PHONE_NUM_MAX_LENGTH: 20
    })
    .constant('SHORT_TEXT_VALUE_TYPE', {
      NUMERIC: 'numeric',
      INTEGER: 'integer',
      STRING: 'string',
      EMAIL: 'email',
      ALPHA_NUM: 'alpha_num',
      URL: 'url'
    })
    .constant('AUTOMATION_CONDITION_LOGIC', {
      AND: 'and',
      OR: 'or'
    })
    .constant('AUTOMATION_ACTION_CONDITION_TYPE', {
      STEP: 'Step',
      CAPTURE: 'Capture',
      PRERUN: 'Prerun'
    })
    .constant('AUTOMATION_CONDITION_OPERATION', {
      COMPLETED: 'completed',
      CONTAINS: 'contains',
      NOT_CONTAINS: 'not_contains',
      EQUALS: 'equals',
      NOT_EQUALS: 'not_equals',
      EQUALS_ANY: 'equals_any',
      GREATER_THAN: 'greater_than',
      IS_EMPTY: 'is_empty',
      IS_NOT_EMPTY: 'is_not_empty'
    })
    .constant('AUTOMATION_ACTION_TYPE', {
      VISIBILITY: 'visibility',
      DEADLINE: 'deadline',
      ASSIGMENT: 'assignment',
      STATUS: 'status'
    })
    .constant('AUTOMATION_ACTION_VERB', {
      SHOW: 'show',
      HIDE: 'hide',
      DEADLINE: 'deadline',
      ASSIGN: 'assign',
      ASSIGN_ONLY: 'assign_only',
      REOPEN: 'reopen'
    })
    .constant('CONDITION_STATEMENT', {
      ANY_TIME: 'any_time'
    })
    .constant('FORM_FIELD', {
      EMAIL_BODY_FIELD: 'Inbound Email Trigger - Email Body',
      EMAIL_ATTACHMENTS_FIELD: 'Inbound Email Trigger - Email Attachments'
    })
    .run(['moment', function (moment) {
      window._ = undefined;
      window.moment = undefined;
      //moment.tz.setDefault('Australia/Sydney');
      //console.log(moment().format('DD/MM/YYYY hh:mm:ss a'));
    }]);
})();
/**
 * @ngdoc Component
 * @name publishBlueprint
 * @module tallyfy.process
 *
 * @description
 * A component to manage blueprint publish detail
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('publishBlueprint', {
      templateUrl: 'app/modules/process/components/edit/publishBlueprint/publish-blueprint.component.html',
      bindings: {
        process: '=',
        type: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $uibModal, $timeout, $filter, blockUI, Growl, ProcessService, RunsService, ENV_CONFIG, Helper, PLANS) {
          var $ctrl = this, modalTimeoutHandler,
            blockUI = blockUI.instances.get('publicBlueprintContent'),
            growl = new Growl();

          $ctrl.isPublicProcess = false;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onPublishValueChanged = onPublishValueChanged;
          $ctrl.copyPublicLink = copyPublicLink;
          $ctrl.openPublicLink = openPublicLink;
          $ctrl.onConfigValueChanged = onConfigValueChanged;

          function onInit() {
            if (!$ctrl.type) {
              $ctrl.type = 'blueprint';
            }
            $ctrl.isPublicProcess = $ctrl.type === 'blueprint' ? $ctrl.process.is_public : !!parseInt($ctrl.process.is_public);
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.isTrialPlan = _.get($rootScope.identity, 'default_organization.subscription.data.plan_code') === PLANS.FREE;
            if ($ctrl.isPublicProcess) {
              $ctrl.publicLink = ENV_CONFIG.TYFY_MAIN_URL + '/public/'
                + ($ctrl.type === 'blueprint' ? 'library/' : 'organizations/') + $ctrl.orgId + '/'
                + ($ctrl.type === 'blueprint' ? 'blueprint' : 'process') + '/' + $ctrl.process.id;
            }
            if ($ctrl.type === 'run') {
              var hiddenFields = _.get($ctrl.process, 'publicly_hidden_fields');
              hiddenFields = (Helper.isObjectEmpty(hiddenFields)) ? [] : hiddenFields;
              $ctrl.showComments = hiddenFields.indexOf("comments") == -1;
              $ctrl.showFormFields = hiddenFields.indexOf("form_fields") == -1;
              $ctrl.showAssignees = hiddenFields.indexOf("assignees") == -1;
            }
          }

          function onChanges() { }

          function onDestroy() {
            if (typeof modalTimeoutHandler === 'function') {
              modalTimeoutHandler();
            }
          }

          function onPublishValueChanged(isEdit) {
            if ($ctrl.type === 'blueprint') {
              if ($ctrl.isPublicProcess) {
                var isValidPublish = _.get($ctrl.process, 'industry_tags', []).length && _.get($ctrl.process, 'topic_tags', []).length;
                if (isValidPublish && !isEdit) {
                  $ctrl.process.is_public = true;
                  $timeout(function () {
                    saveProcess(true);
                  }, 0);
                } else {
                  if (modalTimeoutHandler) {
                    $timeout.cancel(modalTimeoutHandler);
                  }
                  modalTimeoutHandler = $timeout(function () {
                    $uibModal.open({
                      backdrop: 'static',
                      component: 'bpPublishDetail',
                      windowClass: 'bp-publish-modal',
                      resolve: {
                        process: function () {
                          return $ctrl.process;
                        }
                      }
                    }).result.then(setPublicConfiguration, setPublicConfiguration);
                  }, 500);
                }
              } else {
                saveProcess(false);
              }
            } else {
              $ctrl.process.is_public = $ctrl.isPublicProcess;
              if ($ctrl.process.is_public) {
                $ctrl.process.publicly_hidden_fields = [];
                $ctrl.showComments = $ctrl.showFormFields = $ctrl.showAssignees = true;
              } else {
                $ctrl.process.publicly_hidden_fields = [
                  "comments",
                  "form_fields",
                  "assignees"
                ];
              }
              saveProcess();
            }
          }

          function copyPublicLink(e) {
            Helper.copyToClipboard($ctrl.publicLink, $ctrl.type === 'blueprint' ? 'linkCopied' : 'linkCopiedProcess', e);
          }

          function saveProcess(value) {
            if ($ctrl.type === 'blueprint') {
              blockUI.start();
              $ctrl.process.is_public = value;
              ProcessService.update({
                id: $ctrl.process.id
              }, $ctrl.process).then(function (res) {
                setPublicConfiguration({ process: res.data });
                growl.success($filter('translate')('template.publish.growl.' + (value ? 'published' : 'unPublished')), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            } else {
              if ($ctrl.isPublicProcess) {
                $ctrl.publicLink = ENV_CONFIG.TYFY_MAIN_URL + '/public/organizations/' + $ctrl.orgId + '/process/' + $ctrl.process.id;
              }
              RunsService.update({
                id: $ctrl.process.id
              }, {
                is_public: $ctrl.process.is_public,
                publicly_hidden_fields: $ctrl.process.publicly_hidden_fields
              }).then(function (res) {
                $rootScope.$emit('RUN:UPDATED', res.data);
              });
            }
          }

          function setPublicConfiguration(value) {
            $ctrl.publicLink = ENV_CONFIG.TYFY_MAIN_URL + '/public/library/' + _.get($rootScope.identity, 'default_organization.id') + '/blueprint/' + $ctrl.process.id;
            $ctrl.process.is_public = $ctrl.isPublicProcess = _.get(value, 'process.is_public');
            $rootScope.$emit('PROCESS:UPDATED', { process: value.process });
          }

          function onConfigValueChanged() {
            $ctrl.process.publicly_hidden_fields = [];
            if ($ctrl.showComments) {
              $ctrl.process.publicly_hidden_fields = _.filter($ctrl.process.publicly_hidden_fields, function (field) {
                return field !== 'comments';
              });
            } else {
              $ctrl.process.publicly_hidden_fields.push('comments');
            }
            if ($ctrl.showFormFields) {
              $ctrl.process.publicly_hidden_fields = _.filter($ctrl.process.publicly_hidden_fields, function (field) {
                return field !== 'form_fields';
              });
            } else {
              $ctrl.process.publicly_hidden_fields.push('form_fields');
            }
            if ($ctrl.showAssignees) {
              $ctrl.process.publicly_hidden_fields = _.filter($ctrl.process.publicly_hidden_fields, function (field) {
                return field !== 'assignees';
              });
            } else {
              $ctrl.process.publicly_hidden_fields.push('assignees');
            }
            saveProcess();
          }

          function openPublicLink() {
            window.open($ctrl.publicLink, '_blank');
          }
        }
    })
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('stepAutomationInfo', {
      templateUrl: 'app/modules/process/components/readBluePrint/step-automation-info/step-automation-info.component.html',
      bindings: {
        step: '<',
        process: '<',
        affectingAutomations:'=',
        asAutomationTriggers: '='
      },
      controller:
        /**@ngInject*/
        function (_, $filter, CompactStepService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.selectedAutomationTab = 'triggered';

          $ctrl.getConditionableLabel = getConditionableLabel;
          $ctrl.getActionLabel = getActionLabel;
          $ctrl.getStepPosition = getStepPosition;

          function onInit() {
            prepareAutomationsInfo();
          }

          function onChanges() { }

          function onDestroy() { }

          function prepareAutomationsInfo() {
            var affectingAutomations = CompactStepService.getAffectingAutomations($ctrl.process, $ctrl.step);
            $ctrl.affectingAutomations = _.map(affectingAutomations, function (affectingAutomation) {
              return {
                id: affectingAutomation.id,
                automated_alias: affectingAutomation.automated_alias,
                conditions: affectingAutomation.conditions,
                then_actions: _.filter(affectingAutomation.then_actions, function (action) {
                  return action.target_step_id === $ctrl.step.id;
                })
              }
            });
            var triggerAutomations = CompactStepService.getTriggerAutomations($ctrl.process, $ctrl.step);
            $ctrl.asAutomationTriggers = _.map(triggerAutomations, function (triggerAutomation) {
              return {
                id: triggerAutomation.id,
                automated_alias: triggerAutomation.automated_alias,
                conditions: _.filter(triggerAutomation.conditions, function (condition) {
                  return condition.conditionable_id === $ctrl.step.id;
                }),
                then_actions: triggerAutomation.then_actions
              }
            });
          }

          function getConditionableLabel(condition, asTrigger) {
            if (condition.conditionable_type === 'Step') {
              var step = _.find($ctrl.process.steps.data, { id: condition.conditionable_id });
              return (asTrigger ? 'this step' : _.get(step, 'title', '')) + ' ' + $filter('translate')('steps.logic.label.' + condition.operation);
            } else if (condition.conditionable_type === 'Prerun') {
              var prerun = _.find($ctrl.process.prerun, { id: condition.conditionable_id });
              return _.get(prerun, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            } else if (condition.conditionable_type === 'Capture') {
              var capture;
              for (var i = 0; i < $ctrl.process.steps.data.length; i++) {
                if ($ctrl.process.steps.data[i]) {
                  capture = _.find($ctrl.process.steps.data[i].captures, { id: condition.conditionable_id });
                  break;
                }
              }
              return _.get(capture, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            }
          }

          function getActionLabel(action) {
            var step = _.find($ctrl.process.steps.data, { id: action.target_step_id });
            return _.get(step, 'title', '');
          }

          function getStepPosition(action) {
            var step = _.find($ctrl.process.steps.data, { id: action.target_step_id });
            return _.get(step, 'position', '');
          }

        }
    })
})();
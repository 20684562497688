/**
 * @ngdoc Config
 * @name tallyfy.LayoutStateConfig
 * @module tallyfy
 *
 * @description
 * set route configuration for default layout of application
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(LayoutStateConfig)
    .run(['$browser', function ($browser) {
      $browser.baseHref = function () {
        return "/";
      };
    }]);

  /*@ngInject*/
  function LayoutStateConfig($stateProvider) {
    $stateProvider.state('app', {
      abstract: true,
      views: {
        '': {
          templateUrl: 'app/layouts/templates/layout-master.html'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader:
          /*@ngInject*/
          function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('organizations');
            $translatePartialLoader.addPart('billing');
            $translatePartialLoader.addPart('label');
            $translatePartialLoader.addPart('favoriteBlueprint');
            $translatePartialLoader.addPart('signup');
            $translatePartialLoader.addPart('tags');
            $translatePartialLoader.addPart('froala');
            $translatePartialLoader.addPart('messages');
            $translatePartialLoader.addPart('runs');
            $translatePartialLoader.addPart('process');
            $translatePartialLoader.addPart('public');
            $translatePartialLoader.addPart('contentPreview');
            $translatePartialLoader.addPart('editorFormField');
            $translatePartialLoader.addPart('table');
            $translatePartialLoader.addPart('dashboard');
            $translatePartialLoader.addPart('tasks');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('steps');
            $translatePartialLoader.addPart('filter');
            $translatePartialLoader.addPart('list');
            $translatePartialLoader.addPart('myAccount');
            return $translate.refresh();
          }
      }
    });

    $stateProvider.state('public', {
      parent: 'app',
      abstract: true,
      url: '/public',
      data: {
        authorities: []
      },
      views: {
        'content': {
          templateUrl: 'app/layouts/templates/layout-public-master.html'
        }
      }
    }).state('guest', {
      parent: 'public',
      url: '/organizations/:org_id/guest/:guest_code',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'guestComponent'
        }
      },
      onEnter:
        /*@ngInject*/
        function ($rootScope, $state, $stateParams, $localStorage, previousState, guestCredentials, guestPreferences, guestSmptInfo) {
          if ((previousState !== $state.current.name) || !previousState || ($state.params.org_id && $stateParams.org_id && ($stateParams.org_id !== $state.params.org_id))) {
            $rootScope.identity = {
              guest: {
                email: guestCredentials.meta.guest_email,
                initial: guestCredentials.meta.guest_email.substring(0, 2).toUpperCase(),
                organization: guestCredentials.data,
                guestOrganizations: guestCredentials.meta.guest_organizations,
                guest_code: $stateParams.guest_code,
                preferences: guestPreferences.data,
                smtp_connection: guestSmptInfo.data.smtp_connection.data,
                activeTask: $rootScope.toStateParams.activeTask
              }
            };
            $localStorage.guest = $rootScope.identity.guest;
          }
        },
      onExit:
        /*@ngInject*/
        function (_, $state, Auth, previousState) {
          if ((previousState !== $state.current.name) && previousState) {
            if (previousState !== 'guest.organizations') {
              Auth.logout(true);
            }
          }
        },
      resolve: {
        previousState:
          /*@ngInject*/
          function ($state) {
            return $state.current.name;
          },
        guestCredentials:
          /*@ngInject*/
          function ($rootScope, $q, $stateParams, Auth, CompactTaskService, blockUI) {
            var defer = $q.defer();
            $rootScope.identity = {
              guest: {
                guest_code: $stateParams.guest_code,
                organization: {
                  id: $stateParams.org_id
                }
              }
            };
            blockUI = blockUI.instances.get('global'),
            Auth.loginByToken($stateParams.guest_code, true);
            blockUI.start();
            CompactTaskService.getGuestOrganization()
              .then(function (res) {
                blockUI.stop();
                defer.resolve(res);
              }, function (err) {
                blockUI.stop();
                err.data = { id: $stateParams.org_id };
                err.meta = {
                  guest_email: '',
                  guest_organizations: []
                };
                defer.resolve(err);
              });
            return defer.promise;
          },
        guestPreferences:
          /*@ngInject*/
          function ($q, GuestPreferenceRepository, blockUI, $stateParams) {
            var defer = $q.defer();
            blockUI = blockUI.instances.get('global'),
            blockUI.start();
            GuestPreferenceRepository.getPreferences()
              .then(function (res) {
                blockUI.stop();
                defer.resolve(res);
              }, function (err) {
                blockUI.stop();
                err.data = { id: $stateParams.org_id };
                err.meta = {
                  guest_email: '',
                  guest_organizations: []
                };
                defer.resolve(err);
              });
            return defer.promise;
          },
        guestSmptInfo:
          /*@ngInject*/
          function ($q, blockUI, $stateParams, OrganizationsRepository) {
            var defer = $q.defer();
            blockUI = blockUI.instances.get('global'),
            blockUI.start();
            OrganizationsRepository.getGuestSMTP({
              org: $stateParams.org_id,
              guest_code: $stateParams.guest_code
            }, {
              with: 'smtp_connection'
            }).then(function (res) {
                blockUI.stop();
                defer.resolve(res);
              }, function (err) {
                blockUI.stop();
                err.data = { id: $stateParams.org_id };
                err.meta = {
                  guest_email: '',
                  guest_organizations: []
                };
                defer.resolve(err);
              });
            return defer.promise;
          }
      }
    }).state('library', {
      url: '/public/library',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'publicLibraryPage'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('public');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('tasks');
          return $translate.refresh();
        }],
        publicOrganizationDetail:
          /*@ngInject*/
          function (_, $q, $rootScope, OrganizationsService, PublicOrganizationService) {
            var defer = $q.defer();
            $q.all([
              OrganizationsService.getPublicOrgCustomBranding({
                org: $rootScope.publicOrganization.id
              }),
              PublicOrganizationService.getPublicOrganizationDetail({
                org_id: $rootScope.publicOrganization.id
              })
            ]).then(function (res) {
              angular.extend($rootScope.publicOrganization, {
                name: _.get(res, '[1].name', ''),
                orgLogo: _.get(res, '[1].org_logo', ''),
                branding: _.get(res, '[0].data', {})
              });
              defer.resolve($rootScope.publicOrganization);
            }, function (err) {
              defer.reject(err);
            });
            return defer.promise;
          }
      }
    });
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.component.iconComment
 * @module tallyfy
 *
 * @description
 * Set Icon with comment state message 
 *
 * @author Samier sompura ( gmail::samier.sompura@gmail.com)
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('iconComment', {
      templateUrl: 'app/components/icon-comment/icon-comment.html',
      bindings: {
        commentType: '=',
        item: '='
      },
      controller:
        /*@ngInject*/
        function (OrganizationsService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() { }
          
          function onChanges() { }

          function onDestroy() { }
       }
    });
})();
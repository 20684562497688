/**
 * @ngdoc Filter
 * @name defaultTitle
 * @module process
 * @description
 * Return default title based on props
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com, skype :: rehan.fl )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .filter('defaultTitle', ['DateUtils', '_', function (DateUtils, _) {
      return function (title, props) {
        var currentTime, name, limit;
        currentTime = DateUtils.toTimezone().value();
        name = _.get(props, 'prefix') ? _.get(props, 'prefix') + ' - ' + title : title;
        limit = _.get(props, 'limit');

        if (limit && (name.length > limit)) {
          return name.substring(0, limit - 3) + '...';
        }
        return name;
      };
    }]);
})();
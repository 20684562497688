/**
 * @ngdoc Component
 * @name tallyfy.steps.component.processStarterAssigneeView
 * @module tallyfy.steps
 *
 * @description
 * A component to view process starter assignee etc. (guest included or not as well)
 *
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('processStarterAssigneeView', {
      templateUrl: 'app/modules/steps/process-starter-assignee-view/process-starter-assignee-view.html',
      bindings: {
        step: '<',
        tooltipDirection: '<',
        ownerList: '<',
        roles: '<?',
        roleAsIcon: '<',
        isPublic: '<?',
        isReadMode: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope) {
          var $ctrl = this, roleWatcherHandler;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.roleInString = '';

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            generateRoleView();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            roleWatcherHandler();
          }

          function generateRoleView() {
            $ctrl.roleInString = '';
            if ($ctrl.step.owner === 'run_starter') {
              var roles = _.get($ctrl.step, 'roles.data', []);
              for (var i = 0; i < roles.length; i++) {
                var role = _.find($ctrl.roles, { id: roles[i].org_role_id });
                if (role) {
                  if (!$ctrl.roleInString) {
                    $ctrl.roleInString = role.title;
                  } else {
                    $ctrl.roleInString = $ctrl.roleInString + ', ' + role.title;
                  }
                }
              }
            }
          }

          roleWatcherHandler = $scope.$watchCollection('$ctrl.step.roles.data', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            generateRoleView();
          });
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.process.component.bpPublishDetail
 * @module tallyfy.process
 *
 * @description
 * Component to show publish detail of a blueprint
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('bpPublishDetail', {
      templateUrl: 'app/modules/process/components/edit/publishBlueprint/bpPublishDetails/bp-publish-detail.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $filter, ProcessService, blockUI, Growl) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('publicBlueprint'),
            growl = new Growl();

          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.onDismissButtonClick = onDismissButtonClick;
          $ctrl.onPublishButtonClick = onPublishButtonClick;

          function onInit() { }
          function onDestroy() { }

          function onChanges(changes) {
            if (changes.resolve) {
              $ctrl.process = changes.resolve.currentValue.process;
            }
          }

          function onPublishButtonClick(e) {
            blockUI.start();
            ProcessService
              .update({
                id: $ctrl.process.id
              }, {
                title: $ctrl.process.title,
                is_public: true
              })
              .then(
                function (res) {
                  $ctrl.close({
                    $value: {
                      process: res.data
                    }
                  });
                  growl.success($filter('translate')('template.publish.growl.published'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                  blockUI.stop();
                }, function () {
                  blockUI.stop();
                }
              );
          }

          function onDismissButtonClick() {
            blockUI.start();
            if (!$ctrl.process.industry_tags.length || !$ctrl.process.topic_tags.length) {
              $ctrl.process.is_public = false;
              ProcessService.update({
                id: $ctrl.process.id
              }, $ctrl.process).then(function (res) {
                $ctrl.dismiss({
                  $value: {
                    process: res.data
                  }
                });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            } else {
              $ctrl.dismiss({
                $value: {
                  process: $ctrl.process
                }
              });
            }
          }
        }
    });
})();

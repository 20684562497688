(function () {
  'use strict';
  angular.module('tallyfy')
    .service('OrganizationListTableService', OrganizationListTableService);

  function OrganizationListTableService($filter) {
    var self = this;
    self.getOrganizationListTableOptions = function () {
      return {
        columns: [{
          field: "name",
          exportKeyMap: {
            key: "name",
            label: $filter('translate')('organizations.title')
          },
          template: kendo.template($('#supportOrganizationListOrganizationNameColumn').html()),
          headerTemplate: kendo.template($('#supportOrganizationListOrganizationNameHeaderColumn').html())
        }, {
          field: "plan_code",
          exportKeyMap: {
            key: "plan_code",
            label: $filter('translate')('organizations.planCode')
          },
          template: kendo.template($('#supportOrganizationListOrganizationPlanCodeColumn').html()),
          headerTemplate: kendo.template($('#supportOrganizationListOrganizationPlanCodeHeaderColumn').html())
        }, {
          field: "users_count",
          exportKeyMap: {
            key: "users_count",
            label: $filter('translate')('organizations.activeMembers')
          },
          template: kendo.template($('#supportOrganizationListOrganizationActiveMembersColumn').html()),
          headerTemplate: kendo.template($('#supportOrganizationListOrganizationActiveMembersHeaderColumn').html())
        }, {
          field: "created_on",
          exportKeyMap: {
            key: "created_on",
            label: $filter('translate')('organizations.createdOn')
          },
          template: kendo.template($('#supportOrganizationListOrganizationCreatedOnColumn').html()),
          headerTemplate: kendo.template($('#supportOrganizationListOrganizationCreatedOnHeaderColumn').html())
        }, {
          field: "view",
          template: kendo.template($('#supportOrganizationListOrganizationCommandColumn').html()),
          headerTemplate: kendo.template($('#supportOrganizationListOrganizationCommandHeaderColumn').html()),
          width: 120
        }],
        dataSource: new kendo.data.DataSource(),
        noRecords: {
          template: kendo.template($('#supportOrganizationTableNoData').html())
        },
        resizable: true
      }
    }
    return self;
  }
})();
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("tyDragNDrop", tyDragNDrop);
  /*@ngInject*/
  function tyDragNDrop(_, $document, $timeout) {
    return {
      restrict: 'A',
      scope: {
        config: '=tyDragNDrop'
      },
      link: function ($scope, $element) {
        var draggableElements = void 0;
        $element.addClass('ty-drag-n-drop');
        $element.css('position', 'relative');

        var timeoutHandler = $timeout(function () {
          draggableElements = $element.find('.' + $scope.config.dragElementClassName);
          _.each(draggableElements, init);
        }, 0);

        function generateDropReplacer(idx) {
          return '<div class="ty-drop-replacer" data-index="' + idx + '"><div class="ty-drop-highlight"></div></div>';
        }

        function init(el) {
          var $dragElement = angular.element(el),
            $elementContainer = $dragElement.closest('.' + $scope.config.dragElementContainerClassName),
            $replacerElement = angular.element(
              generateDropReplacer(
                $dragElement
                  .closest('.' + $scope.config.dragElementContainerClassName)
                  .data('index')
              )
            );

          $replacerElement.insertBefore($dragElement);

          if ($dragElement
            .closest('.' + $scope.config.dragElementContainerClassName)
            .data('index') == draggableElements.length - 1) {
            var $lastElement = angular.element(
              generateDropReplacer(draggableElements.length)
            );
            $lastElement.insertAfter($dragElement);
          }

          function getIndex($childElement) {
            return $childElement.closest('.' + $scope.config.dragElementContainerClassName).data('index');
          }

          function setDragItemZIndex(value) {
            var replacers = $element.find('.ty-drop-replacer');
            _.each(replacers, function (replacer) {
              angular.element(replacer).css('z-index', value === 1 ? 9999 : value);
            });
            _.each(draggableElements, function (draggableElement) {
              angular.element(draggableElement).css('z-index', value);
            });
          }

          $dragElement.on('mousedown', function (e) {
            if (e.button) return;
            e.preventDefault();
            var $hoveredElement = void 0, action;
            setDragItemZIndex(1);
            var dragTimeout = $timeout(function () {
              var elementBoundingRect = $element[0].getBoundingClientRect(),
                dragElementBoundingDetails = $dragElement[0].getBoundingClientRect(),
                dragElementContainerBoundingDetails = $elementContainer[0].getBoundingClientRect(),
                dragElementsPerRow = parseInt(elementBoundingRect.width / dragElementContainerBoundingDetails.width),
                dragElementIndexInRow = getIndex($dragElement) % dragElementsPerRow;

              $element.on('mousemove', onMouseMove);
              $element.on('mouseup', onMouseUp);

              function clearDropContent(isDroppable, isClearHighlight, isDropContent, isDragOff) {
                if (isClearHighlight) {
                  var highlightContents = $element.find('.ty-drop-replacer.highlight');
                  _.each(highlightContents, function (highlightContent) {
                    (angular.element(highlightContent)).removeClass('highlight');
                  });
                }
                if (isDropContent) {
                  var dropContents = $element.find('.drop-content');
                  _.each(dropContents, function (dropContent) {
                    (angular.element(dropContent)).removeClass('drop-content');
                  });
                }
                if (!isDroppable) {
                  var dropContents = $element.find('.droppable');
                  _.each(dropContents, function (dropContent) {
                    (angular.element(dropContent)).removeClass('droppable');
                  });
                }
                if (isDragOff) {
                  var dropContents = $element.find('.element-on-drag');
                  _.each(dropContents, function (dropContent) {
                    (angular.element(dropContent)).removeClass('element-on-drag');
                  });
                }
              }

              function onMouseMove(e) {
                e.preventDefault();
                $dragElement.addClass('dragging').css('position', 'absolute').css('z-index', 99999);
                $scope.config.onDragging = true;
                $dragElement[0].style.setProperty('display', 'none', 'important');

                $hoveredElement = angular.element($document[0].elementFromPoint(e.clientX, e.clientY));
                var isReplacer = $hoveredElement.hasClass('ty-drop-replacer');
                if (isReplacer) {
                  action = 'positioning';
                  $hoveredElement.addClass('highlight');
                  clearDropContent(false, false, true);
                } else {
                  var isDroppableElement = $hoveredElement.hasClass($scope.config.droppableElementClassName);
                  if (isDroppableElement) {
                    $dragElement.addClass('droppable');
                    $hoveredElement.closest('.' + $scope.config.dragElementContainerClassName).addClass('drop-content');
                  } else {
                    $dragElement.removeClass('droppable');
                  }
                  action = isDroppableElement ? 'drop' : void 0;
                  clearDropContent(isDroppableElement, true, !isDroppableElement);
                }
                $dragElement[0].style.setProperty('display', 'inherit');

                var left = e.clientX - ((dragElementIndexInRow * dragElementContainerBoundingDetails.width) + elementBoundingRect.left
                  + (dragElementContainerBoundingDetails.width / 2) + (dragElementContainerBoundingDetails.width - dragElementBoundingDetails.width) / 2) + 4,
                  top = e.clientY - (dragElementContainerBoundingDetails.top) - (dragElementContainerBoundingDetails.height / 2) + 4,
                  minLeft = (elementBoundingRect.left - ((dragElementIndexInRow + 1) * dragElementContainerBoundingDetails.width) - (dragElementContainerBoundingDetails.width / 2)),
                  newLeftPosition = elementBoundingRect.left - ((dragElementIndexInRow + 1) * dragElementContainerBoundingDetails.width) - (dragElementContainerBoundingDetails.width / 2);

                $dragElement[0].style.left = ((minLeft <= left ? left : newLeftPosition) - 10) + 'px';
                $dragElement[0].style.top = top + 'px';
              }

              function onMouseUp(e) {
                e.preventDefault();

                function onDragElement() {
                  setDragItemZIndex('inherit');
                  $dragElement.removeClass('dragging').removeClass('droppable')
                    .css('position', 'inherit').css('transform', 'inherit')
                    .css('z-index', 'inherit').css('left', 'inherit').css('top', 'inherit');

                  if (action === 'positioning') {
                    $scope.config.onReposition(
                      $dragElement
                        .closest('.' + $scope.config.dragElementContainerClassName)
                        .data('index'),
                      $hoveredElement
                        .closest('.' + $scope.config.dragElementContainerClassName)
                        .data('index')
                    );
                  } else if (action === 'drop') {
                    $scope.config.onDrop(
                      $dragElement
                        .closest('.' + $scope.config.dragElementContainerClassName)
                        .data('index'),
                      $hoveredElement
                        .closest('.' + $scope.config.dragElementContainerClassName)
                        .data('index')
                    );
                  }
                  $scope.config.onDragging = false;
                  clearDropContent(false, true, true, true);
                  $element.removeClass('on-drag');
                  $scope.$apply();
                }

                function clearScope() {
                  $element.off('mousemove', onMouseMove);
                  $element.off('mouseup', onMouseUp);
                  $timeout.cancel(dragTimeout);
                  $timeout.cancel(dragOffTimeout);
                }

                var dragOffTimeout = $timeout(onDragElement, 0).then(clearScope);
              }
            }, 1000).then(function () {
              $dragElement.css('transform', 'rotateZ(-5deg)').addClass('element-on-drag');
              $element.addClass('on-drag');
            });
          });

          function onDragStart(e) {
            e.preventDefault();
            return false;
          }

          $dragElement.on('dragstart', onDragStart);
          $scope.$on('$destroy', function () {
            $dragElement.off('dragstart', onDragStart);
            $timeout.cancel(timeoutHandler);
          });
        }
      }
    }
  }
})();
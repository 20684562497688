(function () {
  'use strict';
  angular.module('tallyfy')
    .component('azureConfigModal', {
      templateUrl: 'app/modules/integration/developerApi/azureConfigModal/azure-config-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, Growl, OrganizationsService, TranslationService, blockUI) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('azureConfig'),
            growl = new Growl();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.saveAzureConfig = saveAzureConfig;
          $ctrl.orgAzureCognitiveService = {};

          function onInit() {
            $ctrl.organization = $ctrl.resolve.organization;
            $ctrl.orgAzureCognitiveService = JSON.parse($ctrl.organization.azure_cognitive_service);
          }

          function onChanges() { }
          function onDestroy() { }

          function saveAzureConfig() {
            if (!$ctrl.orgAzureCognitiveService.key || !$ctrl.orgAzureCognitiveService.resource_name || !$ctrl.orgAzureCognitiveService.region) {
              return;
            } else {
              Growl.clearAllMessages('global');
              blockUI.start();
              TranslationService
                .test({ org_id: $rootScope.identity.default_organization.id, email: $rootScope.identity.email }, $ctrl.orgAzureCognitiveService)
                .then(updateConfig, translationKeyInValid);
            }
          }

          function updateConfig(res) {
            $ctrl.organization.azure_cognitive_service = $ctrl.orgAzureCognitiveService;
            OrganizationsService
              .update(null, $ctrl.organization)
              .then(function (res) {
                $rootScope.identity.default_organization.azure_cognitive_service = $ctrl.organization.azure_cognitive_service = JSON.stringify(res.data.azure_cognitive_service);
                blockUI.stop();
                growl.success($filter('translate')('org_settings.azure_cognitive_service.configSave'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $ctrl.close({ $value: $rootScope.identity.default_organization.azure_cognitive_service });
              }, function (err) {
                blockUI.stop();
              });
          }

          function translationKeyInValid() {
            growl.error($filter('translate')('org_settings.azure_cognitive_service.configFailed'), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
            blockUI.stop();
          }
        }
    })
})();
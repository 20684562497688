/**
 * @ngdoc Component
 * @name tallyfy.organizations.component
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage organizations
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('organizations', {
      templateUrl: 'app/modules/organizations/organizations.html',
      controller: function () {
        var $ctrl = this;

        /**
         * component's lifeCycle hooks 
         */
        $ctrl.$onInit = initialization;

        /**
        * @function
        * @name initialization
        * @description
        * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
        */
        function initialization() { }
      }
    });
})();
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('GuestTableService', GuestTableService);

  function GuestTableService(_, $filter) {
    var self = this;

    self.getGuestTableOptions = function (isAdmin, type) {
      return {
        columns: !!type && type === 'cadences' ? getCadencesGuestTableColumns() : getGuestTableColumns(isAdmin),
        dataSource: new kendo.data.DataSource({
          pageSize: 10
        }),
        noRecords: {
          template: kendo.template($('#guestListTableNoData').html())
        },
        resizable: true
      };
    };

    // guest table columns
    var getCadencesGuestTableColumns = function () {
      var columns = [{
        field: "email",
        exportKeyMap: {
          key: "email",
          label: $filter('translate')('regularUsers.table.header.email')
        },
        headerTemplate: kendo.template($('#guestEmailColumnHeaderTemplate').html()),
        template: kendo.template($('#guestEmailColumnTemplate').html()),
        width: 280
      }, {
        field: "cadenceDays",
        exportKeyMap: {
          key: "details.cadence_days",
          label: $filter('translate')('regularUsers.table.header.cadenceDays')
        },
        template: kendo.template($('#guestCadenceDaysColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.cadenceDays'),
        width: 480
      }];
      return columns;
    };
    
    // guest table columns
    var getGuestTableColumns = function (isAdmin) {
      var columns = [{
        field: "email",
        exportKeyMap: {
          key: "email",
          label: $filter('translate')('regularUsers.table.header.email')
        },
        headerTemplate: kendo.template($('#guestEmailColumnHeaderTemplate').html()),
        template: kendo.template($('#guestEmailColumnTemplate').html()),
        width: 280
      }, {
        field: "enabled",
        template: kendo.template($('#guestEnabledColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.enabled'),
        width: 160
      }, {
        field: "associatedMembers",
        template: kendo.template($('#guestAssociatedMembersColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.associatedMembers'),
        width: 290
      }, {
        field: "tasks",
        template: kendo.template($('#guestTaskColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.tasks'),
        width: 140
      }, {
        field: "emailLogs",
        template: kendo.template($('#guestEmailLogsColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.emailLogs'),
        width: 140
      }, {
        field: "guestLink",
        exportKeyMap: {
          key: "link",
          label: $filter('translate')('regularUsers.label.guestLink')
        },
        template: kendo.template($('#guestGuestLinkColumnTemplate').html()),
        title: $filter('translate')('regularUsers.label.guestLink'),
        width: 140
      }, {
        field: "createdAt",
        exportKeyMap: {
          key: "created_at",
          label: $filter('translate')('regularUsers.table.header.firstCreated')
        },
        template: kendo.template($('#createdAtColumnTemplate').html()),
        headerTemplate: kendo.template($('#createdAtColumnHeaderTemplate').html()),
        width: 180
      }, {
        field: 'lastAccessed',
        exportKeyMap: {
          key: "details.last_accessed_at",
          label: $filter('translate')('regularUsers.table.header.lastAccessed')
        },
        template: kendo.template($('#guestLastAccessedColumnTemplate').html()),
        headerTemplate: kendo.template($('#guestLastAccessedColumnHeaderTemplate').html()),
        width: 140
      }, {
        field: 'lastCity',
        exportKeyMap: {
          key: "details.last_city",
          label: $filter('translate')('regularUsers.table.header.lastCity')
        },
        template: kendo.template($('#guestLastCityColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.lastCity'),
        width: 140
      }, {
        field: 'lastCountry',
        exportKeyMap: {
          key: "details.last_country",
          label: $filter('translate')('regularUsers.table.header.lastCountry')
        },
        template: kendo.template($('#guestLastCountryColumnTemplate').html()),
        title: $filter('translate')('regularUsers.table.header.lastCountry'),
        width: 140
      }, {
        field: "deleteGuest",
        template: kendo.template($('#guestUserTableDeleteColumn').html()),
        title: $filter('translate')('global.standAlone.label.delete'),
        width: 150
      }];
      if (isAdmin) {
        columns.splice(6, 0, {
          field: "convertToMember",
          template: kendo.template($('#guestConvertToMemberColumnTemplate').html()),
          title: $filter('translate')('regularUsers.label.convertToMemberQ'),
          width: 180
        });
      }
      return columns;
    };
    return self;
  }

})();

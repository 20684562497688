/**
 * @ngdoc Component
 * @name tallyfy.bpDescription
 * @module tallyfy
 *
 * @description
 * A component to manage BP Description
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('bpDescription', {
      templateUrl: 'app/modules/steps/bpDescription/bp-description.component.html',
      bindings: {
        process: '=',
        oldProcess: '=',
        isBpDescExpanded: '=',
        onBpDescUpdate: '&',
        descriptionForm: '='
      },
      require: {
        stepsCtrl: '^steps'
      },
      controller:
        /*@ngInject */
        function (_, $rootScope, ProcessService, Helper, $filter, $confirm, $q, StepService, koStepService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.toggleView = toggleView;
          $ctrl.onDescriptionSave = onDescriptionSave;
          $ctrl.deleteBpDescStep = deleteBpDescStep;
          $ctrl.onDiscardDescription = onDiscardDescription;

          function onInit() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              placeholder: $filter('translate')('steps.bpeDescStep.editorPlaceholder'),
              currentUser: $rootScope.identity,
              enableFullscreen: true
            });
          }

          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * angularjs life cycle hook
           */
          function onDestroy() {
            onKOStepOpenEventHandler();
            onStepOpenEventHandler();
          }

          function onSaveChanges(isDelete) {
            var deferred = $q.defer();
            onSaving(true);
            ProcessService.update({
              id: $ctrl.process.id
            }, $ctrl.process).then(function (response) {
              $ctrl.process = response.data;
              $ctrl.oldProcess.summary = $ctrl.process.summary;
              if ($ctrl.descriptionForm) {
                $ctrl.descriptionForm.$setPristine();
              }
              onSaving(false);
              deferred.resolve(response.data);
              if (isDelete) {
                $ctrl.isBpDescExpanded = false;
              }
              $ctrl.onBpDescUpdate({
                process: $ctrl.process,
                isDelete: !!isDelete
              });
            }, function (error) {
              onSaving(false);
              deferred.reject(error);
            });
            return deferred.promise;
          }

          function deleteBpDescStep() {
            $ctrl.process.summary = null;
            onSaveChanges(true);
          }

          function onDescriptionSave() {
            onSaveChanges();
          }

          function onDiscardDescription() {
            $ctrl.process.summary = $ctrl.oldProcess.summary;
            $ctrl.descriptionForm.$setPristine();
          }

          function toggleView(e, args) {
            var isDescHasUnsavedChanges = StepService.isDescHasUnsavedChanges($ctrl.oldProcess.summary, $ctrl.process);
            if ($ctrl.isBpDescExpanded && isDescHasUnsavedChanges) {
              $confirm({
                'body': 'global.unsaved.body',
                'header': 'global.unsaved.header',
                'buttons': {
                  'accept': 'global.unsaved.buttons.accept',
                  'cancel': 'global.unsaved.buttons.cancel'
                },
                'modalType': 'modal-blueprint'
              }).then(function () {
                onSaveChanges().then(function () {
                  $ctrl.isBpDescExpanded = false;
                  $ctrl.stepsCtrl.checkTriggerFrom(e.name, args);
                });
              }, function () {
                onDiscardDescription();
                $ctrl.isBpDescExpanded = false;
                $ctrl.stepsCtrl.checkTriggerFrom(e.name, args);
              });
            } else {
              var ableToExpand = StepService.isAnyDirtyStepExpanded($ctrl.stepsCtrl.selectedStep)
                && !koStepService.isKOFormDirty($ctrl.process, $ctrl.oldProcess);
              if (!ableToExpand) {
                $rootScope.$emit('BP_DESC:OPEN');
              }
              else {
                $ctrl.isBpDescExpanded = !$ctrl.isBpDescExpanded;
              }
            }
          }

          function onSaving(onSaving) {
            $ctrl.onSaving = onSaving;
            if (_.get($ctrl.froalaOptions, 'froalaEditor')) {
              onSaving ? $ctrl.froalaOptions.froalaEditor.edit.off() : $ctrl.froalaOptions.froalaEditor.edit.on();
            }
          }

          var onKOStepOpenEventHandler = $rootScope.$on('KO_STEP:OPEN', function (e) {
            if ($ctrl.isBpDescExpanded) {
              toggleView(e);
            }
          });

          var onStepOpenEventHandler = $rootScope.$on('STEP:OPEN', function (e, data) {
            if ($ctrl.isBpDescExpanded) {
              toggleView(e, data);
            }
          });
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.onSrcError
 * @restrict 'A'
 * @element ANY
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('onSrcError', onSrcError);


  function onSrcError() {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        element.on('error', function() {
          element.attr('src', attr.onSrcError);
        });
        scope.$on('$destroy', function () {
          element.unbind('error')
        })
      }
    };
  }

})();
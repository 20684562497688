/**
 * @ngdoc Service
 * @name SoundService
 *
 * @module tallyfy
 *
 * @description
 * Service for handling sound's action
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('SoundService', SoundService);

  /*@ngInject*/
  function SoundService(SOUNDPATH) {
    var completeSound = new Audio(SOUNDPATH.ONCOMPLETE);
    var reOpenSound = new Audio(SOUNDPATH.ONREOPEN);

    completeSound.load();
    reOpenSound.load();

    //service object
    var service = {
      onComplete: onComplete,
      onReOpen: onReOpen
    };
    return service;

    /**
     * @ngdoc method
     * @name onComplete
     * @return void
     * 
     * @description 
     * handling sound when status of task changed to 'completed'
     */
    function onComplete() {
      completeSound.currentTime = 0;
      completeSound.play();
    }

    /**
     * @ngdoc method
     * @name onReOpen
     * @return void
     * 
     * @description 
     * handling sound when status of task changed to 'in-progress'
     */
    function onReOpen() {
      reOpenSound.currentTime = 0;
      reOpenSound.play();
    }

  }


})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.payment
 * @module tallyfy.organizations
 *
 * @description
 * A component for recurly payment gateway
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('payment', {
      templateUrl: 'app/modules/organizations/payment/payment.html',
      bindings: {
        upgradePlanForm: '=',
        invalidNumber: '='
      },
      controller:
        /*@ngInject*/
        function(_, Helper, uiLoad, CONFIG, $filter, $timeout, $rootScope) {
          var $ctrl = this,
            paymentErrorEventHandler,
            recurlyPlaceholder = '#999 !important',
            recurlyFontColor = '#55595c';

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.cardTypes = Helper.getCardTypes();
            recurlyConfiguration();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            uiLoad.unloadScript('https://js.recurly.com/v4/recurly.js');
            paymentErrorEventHandler();
          }

          /**
           * @ngdoc method
           * @name recurlyConfiguration
           * @description Recurly configuration
          */
          function recurlyConfiguration() {
            uiLoad.unloadScript('https://js.recurly.com/v4/recurly.js');
            uiLoad
              .loadScript('https://js.recurly.com/v4/recurly.js')
              .then(function () {
                recurly.configure({
                  publicKey: CONFIG.RECURLY_PUBLICKEY,
                  required: ['cvv'],
                  style: {
                    all: {
                      fontFamily: '"Inter", sans-serif',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      fontColor: recurlyFontColor,
                      padding: '0px'
                    },
                    number: {
                      placeholder: {
                        content: $filter('translate')('updateOrgPlan.payment.placeholder.cardNumber'),
                        color: recurlyPlaceholder
                      }
                    },
                    month: {
                      placeholder: {
                        content: $filter('translate')('updateOrgPlan.payment.placeholder.mm'),
                        color: recurlyPlaceholder
                      }
                    },
                    year: {
                      placeholder: {
                        content: $filter('translate')('updateOrgPlan.payment.placeholder.yyyy'),
                        color: recurlyPlaceholder
                      }
                    },
                    cvv: {
                      placeholder: {
                        content: $filter('translate')('updateOrgPlan.payment.placeholder.cvv'),
                        color: recurlyPlaceholder
                      }
                    }
                  }
                });
                recurly.on('change', onChangeRecurly);
              });
          }

          /**
           * @ngdoc method
           * @name onChangeRecurly
           * @description Set defualt value and remove class
           * @param {Object} state
           * @returns {void}
          */
          function onChangeRecurly(state) {
            $timeout(function () {
              var fields = ['number', 'cvv', 'month', 'year'];
              angular.forEach(fields, function (field) {
                angular.element(document.querySelectorAll("[data-recurly=" + field + "]")).parent().removeClass('has-error');
              });
              $ctrl.cardType = state.fields.number.brand;
              $ctrl.invalidNumber = '';
              $ctrl.invalidMonth = '';
              $ctrl.invalidYear = '';
              $ctrl.invalidCvv = '';
              $ctrl.inValidField = '';
            }, 0);
          }

          /**
           * @ngdoc method
           * @name recurlyErrorHandler
           * @description Handle recurly validation
           * @param {Object} error
          */
          function recurlyErrorHandler(error) {
            $timeout(function () {
              _.filter(error.fields, function (field) {
                if (error.code === 'invalid-parameter') {
                  angular.element(document.querySelectorAll("[data-recurly='" + field + "']")).parent().addClass('has-error');
                  $ctrl.inValidField = error.message;
                } else {
                  if (field === 'number')
                    $ctrl.invalidNumber = $filter('translate')('updateOrgPlan.payment.messages.cardNumber');
                  if (field === 'month')
                    $ctrl.invalidMonth = $filter('translate')('updateOrgPlan.payment.messages.mm');
                  if (field === 'year')
                    $ctrl.invalidYear = $filter('translate')('updateOrgPlan.payment.messages.yyyy');
                  if (field === 'cvv')
                    $ctrl.invalidCvv = $filter('translate')('updateOrgPlan.payment.messages.cvv');
                }
              });
              if (!$ctrl.upgradePlanForm.$valid) {
                return;
              }
            }, 0);
          }

          /**
           * event handler when recurly form return an error
           * @type {*|(function())}
           */
          paymentErrorEventHandler = $rootScope.$on('PAYMENT:RECURLY_ERROR', function (event, data) {
            recurlyErrorHandler(data);
          });
        }
    });
})();
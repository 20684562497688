/**
 * @ngdoc Config
 * @name tallyfy.tasks.config.TaskStateConfig
 * @module tallyfy.tasks
 *
 * @description
 * set route for tasks Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .config(TaskStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  TaskStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * TaskStateConfig construct 
   */
  function TaskStateConfig($stateProvider) {
    $stateProvider.state('guest.tasks', {
      url: '/tasks?activeTask&status&sortBy',
      parent: 'guest',
      params: {
        activeTask: { squash: true, value: null },
        status: { squash: true, value: null },
        sortBy: { squash: true, value: 'newest' }
      },
      views: {
        'content': {
          component: 'guestTasks'
        }
      },
      data: {
        authorities: []
      },
      onEnter: 
        function ($rootScope, $localStorage) {
          if ($localStorage.guest) {
            $rootScope.identity = {
              guest: {
                email: $localStorage.guest.email,
                initial: $localStorage.guest.initial,
                organization: $localStorage.guest.organization,
                guestOrganizations: $localStorage.guest.guestOrganizations,
                smtp_connection: $localStorage.guest.smtp_connection,
                guest_code: $localStorage.guest.guest_code,
                preferences: $localStorage.guest.preferences
              }
            };
          }
        },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('compact');
          return $translate.refresh();
        }]
      },
    }).state('guest.task', {
      url: '/tasks/:task_code',
      parent: 'guest',
      views: {
        'content': {
          component: 'guestTask'
        }
      },
      data: {
        authorities: []
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('tasks');
          return $translate.refresh();
        }],
        newGuestUrl: function (_, $q, $stateParams, TasksService) {
          var defer = $q.defer();
          TasksService.getGuestTaskUrlFromOldCode({
            org: $stateParams.org_id,
            old_code: $stateParams.task_code
          }).then(function (res) {
            var urlPaths = _.get(res.data, 'guest_url', '').split('/'), newUrl = '';
            if (urlPaths && urlPaths.length) {
              newUrl = urlPaths.join('/');
            }
            defer.resolve(newUrl);
          }, function () {
            defer.resolve('');
          });
          return defer.promise;
        }
      }
    }).state('taskMagicLink', {
      url: '/:org_id/task?action&default_task_name&taskId&comment&formField&deadline',
      parent: 'app',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        action: { squash: true, value: null },
        taskId: { squash: true, value: null },
        comment: { squash: true, value: null },
        formField: { squash: true, value: null },
        deadline: { squash: true, value: null },
        default_task_name: { squash: true, value: null }
      },
      views: {
        'content': {
          component: 'taskMagicLink'
        }
      }
    })
  }
})();

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.groups.service.GroupService
   * @module tallyfy.process
   *
   * @description
   * GroupService manages process module's logic and CURD
   * This is repposible for business logic, common logic 
   * Module controller will intract with service only
   * 
   * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
   * 
   * */
  angular
    .module('tallyfy.process')
    .service('GroupService', GroupService);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  GroupService.$inject = ['GroupRepository', 'CONFIG', '_'];
  /**
   * Constructor
   * GroupService construct 
   */
  function GroupService(GroupRepository, CONFIG, _) {
    /**
     * store GroupService context in self
     * JavaScript has block level context So it can be changed in nested 
     * function
     */
    var self = this;
    angular.extend(this, GroupRepository);
    self.transformUsers = transformUsers;
    self.save = save;


    /**
    * @ngdoc method
    * @name save
    * @description create/update group
    * @param {any} payload A request payload object to be sent to API
    * @param {any} params An object of additional parameter
    * @returns promise
    */
    function save(payload, params) {
      var resource;
      if (payload.users && _.isArray(payload.users) && payload.users.length > 0) {
        payload.users = _.map(payload.users, 'id');
      }
      payload = _.pick(payload, ['users', 'name', 'id']);
      if (_.isObject(params)) {
        params = angular.extend({}, params, {
          with: 'users'
        });
      } else {
        params = {};
      }
      angular.extend(params, {
        with: 'users'
      });
      if (payload.hasOwnProperty('id') && payload.id) {
        resource = self.update(params, payload);
      } else {
        resource = self.create(params, payload);
      }
      return resource;
    }

    function transformUsers(users) {
      var u = [];
      if (users && _.isArray(users)) {
        _.forEach(users, function (o) {
          u.push({
            id: o.id,
            text: o.full_name,
            email: o.email,
            gravatar : o.profile_pic
          });
        });
      }
      return u;
    }

    //end of service
  }
})(); 

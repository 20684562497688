(function () {
  'use strict';
  angular.module('tallyfy')
    .component('fieldAssignee', {
      templateUrl: 'app/modules/steps/capture/form/field-assignee/field-assignee.component.html',
      bindings: {
        field: '<',
        assignee: '=',
        users: '<',
        groups: '<',
        requiredFieldNote: '=?',

        isEditMode: '=?',

        isGuest: '<',
        koTask: '=',
        isPublicProcess: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;


          function onInit() {
            if (!$ctrl.assignee) {
              $ctrl.assignee = {
                guest: [],
                users: [],
                groups: []
              };
            } else {
              if (!_.get($ctrl.assignee, 'guests', []).length) {
                $ctrl.assignee.guest = [];
              }
              if (!_.get($ctrl.assignee, 'users', []).length) {
                $ctrl.assignee.users = [];
              }
              if (!_.get($ctrl.assignee, 'groups', []).length) {
                $ctrl.assignee.groups = [];
              }
            }
            var viewer = $ctrl.isGuest ? _.get($rootScope.identity, 'guest.email', '') : $ctrl.isPublicProcess ? void 0 : $rootScope.identity.id;
            $ctrl.viewerId = (!viewer && !$ctrl.isGuest && !$ctrl.isPublicProcess) ? $rootScope.identity.id : viewer;
          }

          function onChanges() { }

          function onDestroy() { }

          $scope.$watch('$ctrl.koTask.status', function (newValue) {
            if (newValue === 'completed') {
              if (typeof $ctrl.koTask.completer_id === 'number') {
                $ctrl.assignee = {
                  guest: [],
                  users: [$ctrl.koTask.completer_id],
                  groups: []
                };
              } else {
                $ctrl.assignee = {
                  guest: [$ctrl.koTask.completer_id],
                  users: [],
                  groups: []
                };
              }
            }
          });
        }
    })
})();

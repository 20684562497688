/**
 * @ngdoc Directive
 * @name tallyfy.scrollToTopOnStateActive
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollToTopOnStateActive', scrollToTopOnStateActive);

  /*@ngInject*/
  function scrollToTopOnStateActive(_, DOM, DOMService) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attr) {
        var self = this;
        self.childStateChanged = $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          var parent = toState.name.split('.')[0];
          var parentList = $attr.scrollToTopOnStateActive.split(',');
          if (parent && _.includes(parentList, parent)) {
            DOMService.scrollViewToTop(DOM.MASTER_VIEW.ELEMENT, -1 * DOM.MASTER_VIEW.STYLE.padding_top);
          }
        });
        $scope.$on('$destroy', function (e) {
          self.childStateChanged();
        });
      }
    };
  }

})();
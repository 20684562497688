/**
 * @ngdoc Component
 * @name tallyfy.steps.component.createForm
 * @module tallyfy.steps
 *
 * @createForm
 * A component to manage capture tab
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('createForm', {
      bindings: {
        process: '<',
        onFieldChange: "&",
        step: '<',
        form: '=',
        isPublic: '<'
      },
      templateUrl: 'app/modules/steps/capture/form/create/create-form.html',
      controller:
        /*@ngInject*/
        function (StepService, _, $timeout, FieldService, $filter, Growl, blockUI, Helper, $rootScope) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('editProcess'),
            isDelete = false,
            firstSort = true;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.formFields = FieldService.getFields();
          $ctrl.fieldModel = null;
          $ctrl.sortableOptions = {
            handle: '.move-capture',
            axis: 'y',
            stop: sortHandler,
            start: startHandler,
            placeholder: 'ui-sortable-placeholder'
          };

          /**
           * public methods
           */
          $ctrl.newField = newField;
          $ctrl.deleteField = deleteField;
          $ctrl.deleteFieldOption = deleteFieldOption;
          $ctrl.deleteFieldColumn = deleteFieldColumn;
          $ctrl.getOptionLabel = getOptionLabel;
          $ctrl.fieldUpdate = fieldUpdate;
          $ctrl.collapseField = collapseField;

          /**
           * @function
           * @name initialization
           * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name newField
           * @description Add new field to capture
           * @returns void
           */
          function newField(type) {
            $ctrl.fieldModel = type;
            if (!Helper.checkAccessAuthority())
              return;
            collapseAll();
            if (!$ctrl.fieldModel) {
              return;
            }
            var newField = getNewField();
            newField.isExpanded = true;
            newField.isNew = true;
            $ctrl.step.captures.push(newField);
            $ctrl.fieldModel = null;
            $rootScope.$emit('STEP:FIELD_CHANGED', { step: $ctrl.step });
            onFieldChanges();
          }

          /**
           * @function
           * @name collapseAll
           * @description Helper funtion to collapse all field accordion
           * @returns void
           */
          function collapseAll() {
            _.forEach($ctrl.step.captures, function (field) {
              if (field.isExpanded && !FieldService.validateCaptures(field)) {
                delete field.isExpanded;
              }
            });
          }

          /**
           * @function
           * @name getNewField
           * @description Return a new field Object
           * @returns {Object} field
           * @returns {Object}
           */
          function getNewField() {
            var lastPosition, lastField, newPosition, field;
            field = angular.copy($ctrl.fieldModel);
            lastField = _.maxBy($ctrl.step.captures, 'position');
            lastPosition = lastField ? lastField.position : 0;
            newPosition = lastPosition + 1;
            field.position = newPosition;
            field.isNew = true;
            field.label = null;
            field.guidance = null;
            field.options = [];
            if (field.field_type === 'date') {
                field.collect_time = false;
            }
            return field;
          }


          /**
           * @function
           * @name deleteField
           * @param {Boolean} isOld
           * @param {Object} field
           * @description Delete field from the capture form
           * @returns void
           */
          function deleteField(field, isOld) {
            collapseAll();
            $timeout(function () {
              if (!isOld) {
                var index = _.findIndex($ctrl.step.captures, { position: field.position });
                if (index !== -1) {
                  $ctrl.step.captures.splice(index, 1);
                }
              }
              isDelete = true;
              $rootScope.$emit('STEP:FIELD_CHANGED', { step: $ctrl.step });
              onFieldChanges();
            }, 200);
          }

          /**
           * @function
           * @name deleteFieldOption
           * @param {Object} field
           * @param {Object} option
           * @description Delete option from multiple choice/select etc. field
           * @returns void
           */
          function deleteFieldOption(field, option) {
            if (field && option) {
              var fieldIndex, optionIndex;
              fieldIndex = _.findIndex($ctrl.step.captures, { id: field.id });
              optionIndex = _.findIndex($ctrl.step.captures[fieldIndex].options, { id: option.id });
              $ctrl.step.captures[fieldIndex].options.splice(optionIndex, 1);
              onFieldChanges();
            }
          }

          function deleteFieldColumn(field, column) {
            var fieldIndex = _.findIndex($ctrl.step.captures, { id: field.id }), columnIndex = _.findIndex($ctrl.step.captures[fieldIndex].columns, { id: column.id });
            if ((fieldIndex > -1) && (columnIndex > -1)) {
              $ctrl.step.captures[fieldIndex].columns.splice(columnIndex, 1);
              onFieldChanges();
            }
          }

          /***
           * @function
           * @name fieldUpdate
           * @description execute when every change made on field
           * @returns void
           */
          function fieldUpdate() {
            onFieldChanges();
          }

          /**
           * @function
           * @name onFieldChanges
           * @description Called bind method if there is any changes
           * @returns void
           */
          function onFieldChanges() {
            $ctrl.onFieldChange({ captureFields: $ctrl.step.captures });
            $ctrl.step.$dirty = true;
            if (isDelete) {
              $ctrl.step.$dirty = false;
              isDelete = false;
            }
          }

          /**
           * @name startHandler
           * @param {*} e
           * @param {*} ui
           * @description handling when step is start dragging
           * @returns void
           */
          function startHandler(e, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @function
           * @name sortHandler
           * @param {Object} ui
           * @description A handler function to sort the capture
           * @returns void
           */
          function sortHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.step.captures.length) {
              reorderCaptures();
              isSaveCaptures($ctrl.step.captures[ui.item.sortable.dropindex]);
            }
          }

          /**
           * @function
           * @name isSaveCaptures
           * @description Return if capture form field not save
           * @param {Object} field
           * @returns void
          */
          function isSaveCaptures(field) {
            var isSavedCaptures = true;
            _.forEach($ctrl.step.captures, function (capture) {
              if (!_.has(capture, 'step_id')) {
                isSavedCaptures = false;
                return false;
              }
            });
            if (isSavedCaptures) {
              changedFieldPosition(field);
            }
          }

          /**
           * @function
           * @name reorderCaptures
           * @description reorder captures
           * @returns void
           */
          function reorderCaptures() {
            var i = 0;
            _.forEach($ctrl.step.captures, function (value) {
              value.position = ++i;
            });
          }

          /**
           * @function
           * @name getOptionLabel
           * @description Change the select label based on there is captures or not
           * @param {Object} step
           * @returns {String} label
           */
          function getOptionLabel(step) {
            if (step.captures.length) {
              return $filter('translate')('steps.captures.anotherFormField');
            }
            return $filter('translate')('steps.captures.formField');
          }

          /**
           * @ngdoc method
           * @name changedFieldPosition
           * @param {Object} field
           * @description Update field position
           * @returns void
           */
          function changedFieldPosition(field) {
            blockUI.start();
            StepService.updateStepField({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, {
              capture_id: field.id,
              position: field.position
            }).then(function () {
              growl.success($filter('translate')('steps.captures.messages.positionChanged'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            }, function (error) {
              blockUI.stop();
              if (error.status !== 422) {
                growl.error($filter('translate')('steps.captures.messages.positionNotChanged'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            });
          }

          /**
           * @ngdoc method
           * @name collapseField
           * @param {Integer} index
           * @description Callback function onCollapse v-accordion
           * @returns {boolean}
           */
          function collapseField(index) {
            _.remove($ctrl.step.captures[index].options, function (option) {
              return (!option.required && (option.text === null || option.text === ""));
            });
          }
        }
    });
})();

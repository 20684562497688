/**
 * @ngdoc Service
 * @name hasPermission
 *
 * @module tallyfy
 *
 * @description
 * Service for user permission
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('Permission', Permission);
      
      /*@ngInject*/
      function Permission($rootScope, _) {
        var self = this;

        self.hasPermission = hasPermission;

        /**
         * @ngdoc method
         * @name userPermission
         * @description Returns boolean value on permission
         * @param {any} permission 
         * @returns {Boolean}
         */
        function hasPermission(permission) {
          /*@TODO: Uncomment this when roles & permissions are back*/
          /*if (_.has($rootScope.identity.permissions, permission) && $rootScope.identity.permissions[permission]) {
            return true;
          }
          return false;*/
          
          return true;
        }    
      }
})();
(function (jQuery) {
  'use strict';
  angular.module('tallyfy.editor')
    .value('froalaConfig', {})
    .directive('froala',
      /*@ngInject*/
      function (_, $rootScope, $q, $filter, $compile, $timeout, froalaConfig, Helper, CONST, FroalaService, uiLoad, UsersService, OrganizationsService, ProcessService, USER_STATE) {
        /**
         * GLOBAL VARIABLE
         * Required by froala directive
         */
        var MANUAL = "manual", AUTOMATIC = "automatic", SPECIAL_TAGS = ['img', 'button', 'input', 'a'],
          fileName, innerHtmlAttr = 'innerHTML', keyValue, codeViewTimeoutHandler,
          defaultConfig = {
            immediateAngularModelUpdate: true,
            angularIgnoreAttrs: null,
            htmlAllowedEmptyTags: [
              'span', 'a', 'p', 'iframe', 'video', 'editor-form-field', 'ko-field-assignee',
              'editor-snippet', 'editor-blueprint', 'blueprint-icon', 'editor-variable', 'i', 'editor-pasted-url'
            ],
            htmlAllowedTags: [
              'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo',
              'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd',
              'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'ko-field-assignee', 'editor-form-field', 'editor-snippet', 'editor-blueprint', 'editor-variable',
              'blueprint-icon', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5',
              'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link',
              'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p',
              'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source',
              'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title',
              'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr', 'editor-pasted-url'
            ]
          },
          imageInfo = { name: '', size: '' },
          atDefaultConfig = { at: "@" },
          listeningEvents = [
            'blur', 'commands.after', 'commands.before', 'contentChanged', 'drop', 'image.beforeUpload',
            'image.beforePasteUpload', 'image.uploaded', 'image.inserted', 'html.set', 'image.error',
            'link.beforeInsert', 'file.beforeUpload', 'file.uploaded', 'file.inserted', 'file.error', 'focus',
            'keyup', 'paste.after', 'paste.beforeCleanup', 'paste.afterCleanup', 'video.linkError', 'video.beforeUpload', 'video.uploaded', 'video.error'
          ],
          insertButtons = ['insertFile', 'insertVideo', 'insertLink', 'insertTable', 'embedly'],
          htmlShown, contentChangedWatcher, resetTemplate,
          body = angular.element('body');

        /**
         * @ngdoc method 
         * @name getTextTemplates
         * @param {*} params 
         * 
         * @description
         * get snippet data
         */
        function getTextTemplates(params) {
          var defer = $q.defer();
          OrganizationsService.getTextTemplates(params)
            .then(function (res) {
              defer.resolve(res);
            }, function (err) {
              defer.reject(err);
            });
          return defer;
        }

        function getBlueprints(params) {
          var defer = $q.defer();
          params.sort = 'title';
          ProcessService.getProcess(params)
            .then(function (res) {
              defer.resolve(res);
            }, function (err) {
              defer.reject(err);
            });
          return defer;
        }

        return {
          restrict: 'A',
          require: 'ngModel',
          scope: {
            froalaOptions: '=froala',
            initFunction: '&froalaInit',
            atOptions: '=mention',
            inviteMember: '<?',
            isAssignToGuest: '<?'
          },
          compile: function () {
            return {
              pre: function (scope, element, attrs, ngModel) {
                scope.$froalaController = {};

                var ctrl = scope.$froalaController;
                ctrl.moreTextOpen = false;
                ctrl.isCodeView = false;
                ctrl.listeningEvents = listeningEvents;
                ctrl.insertButtons = insertButtons;
                ctrl.editorState = {
                  isInFullscreenMode: false
                };

                element.on('blur', function () {
                  ngModel.$setViewValue(element[0].innerHTML);
                });

                ctrl.closeMoreButton = function () {
                  if (ctrl.moreTextOpen) {
                    var moreButton = ctrl.froalaEditor.$box.find('[data-cmd="moreText"].fr-open'),
                      moreToolbar = ctrl.froalaEditor.$box.find('.fr-more-toolbar');
                    if (!Helper.isObjectEmpty(moreButton) && !Helper.isObjectEmpty(moreToolbar)) {
                      moreButton.removeClass('fr-open');
                      moreToolbar.removeClass('fr-expanded');
                      var toolbarOpens = ctrl.froalaEditor.$box.find('.fr-toolbar-open');
                      _.forEach(toolbarOpens, function (toolbar) {
                        var tb = angular.element(toolbar);
                        tb.removeAttr('style');
                        tb.removeClass('fr-toolbar-open');
                      });
                    }
                    ctrl.moreTextOpen = false;
                  }
                };

                ctrl.disableTooltip = function (btn, act) {
                  if (!btn) {
                    return;
                  }
                  btn[act]('mouseenter', function (e) {
                    e.stopImmediatePropagation();
                    var el = angular.element('.fr-tooltip');
                    el.css('display', 'none');
                  });
                  btn[act]('mouseleave', function (e) {
                    e.stopImmediatePropagation();
                    var el = angular.element('.fr-tooltip');
                    el.css('display', 'inherit');
                  });
                };

                ctrl.tooltipConfig = function () {
                  var moreButton = ctrl.froalaEditor.$box.find('[data-cmd="moreText"]');
                  moreButton.find('.fr-sr-only').text('Insert');
                  ctrl.disableTooltip(moreButton[0], 'addEventListener');
                };

                ctrl.getCodeMirrorLeftPosition = function (wrapper) {
                  var windowsWidth = body[0].getBoundingClientRect().width;
                  var wrapperWidth = wrapper.find('.fr-view')[0].getBoundingClientRect().width;
                  return (windowsWidth - wrapperWidth) / 2;
                };

                ctrl.setBusy = function (isBusy) {
                  if (scope.froalaOptions) {
                    scope.froalaOptions.isBusy = isBusy;
                    scope.froalaOptions.editorScope && scope.froalaOptions.editorScope.froalaOptions
                      ? scope.froalaOptions.editorScope.froalaOptions.isBusy = isBusy
                      : angular.noop();
                  }
                  scope.$applyAsync();
                };
                ctrl.setBusy(true);
                ctrl.editorInitialized = false;
                ctrl.firstTimeBinding = true;

                if (jQuery) element = jQuery(element);

                /**
                 * ngdoc controller method
                 * method create froala editor
                 */
                ctrl.createEditor = function () {
                  var $getBlueprintsRequest, $getTextTemplatesRequest;
                  if (ctrl.editorInitialized) {
                    return;
                  }
                  ctrl.options = angular.extend({}, defaultConfig, froalaConfig, _.omit(scope.froalaOptions, ['froalaEditor', 'editorScope']));
                  ctrl.options.autofocus = _.get(ctrl.options, 'autofocus', true);
                  ctrl.atWhoOptions = scope.froalaOptions.atOptions ? angular.extend({}, atDefaultConfig, scope.froalaOptions.atOptions) : {};
                  if (!ctrl.options.isGuest && !ctrl.options.isPublic) {
                    ctrl.options.template = {
                      items: [],
                      itemsBlueprint: [],
                      apiParams: {
                        page: 1,
                        per_page: 10,
                        skipAuthToken: ctrl.options.isPublic
                      },
                      apiBlueprintParams: {
                        page: 1,
                        per_page: 10
                      },
                      createNewTemplateBtnText: $filter('translate')('textTemplate.label.createButton'),
                      paginationInfo: {},
                      paginationBlueprintInfo: {},
                      getNextTemplateData: function () {
                        var defer = $q.defer(), config = this;
                        if (!!resetTemplate) {
                          config.apiParams.page = 1;
                          resetTemplate = false;
                          config.paginationInfo = {};
                          config.allTemplateLoaded = false;
                        }
                        if (config.isLoading) {
                          return defer.promise;
                        }
                        if ($getTextTemplatesRequest && !$getTextTemplatesRequest.promise.$$state.status) {
                          $getTextTemplatesRequest.reject('cancelled');
                        }
                        config.isLoading = true;
                        $getTextTemplatesRequest = getTextTemplates(config.apiParams);
                        $getTextTemplatesRequest.promise.then(function (res) {
                          config.items = _.concat(config.items || [], res.data);
                          config.apiParams.page++;
                          config.paginationInfo = res.meta.pagination;
                          config.isLoading = false;
                          if (config.paginationInfo.current_page === config.paginationInfo.total_pages) {
                            config.allTemplateLoaded = true;
                          }
                          defer.resolve(res.data);
                        }, function (err) {
                          config.isLoading = false;
                          defer.reject(err);
                        });
                        return defer.promise;
                      },
                      getNextBlueprintData: function () {
                        var defer = $q.defer(), config = this;
                        if (config.isBlueprintLoading) {
                          return defer.promise;
                        }
                        if ($getBlueprintsRequest && !$getBlueprintsRequest.promise.$$state.status) {
                          $getBlueprintsRequest.reject('cancelled');
                        }
                        config.isBlueprintLoading = true;
                        $getBlueprintsRequest = getBlueprints(config.apiBlueprintParams);
                        $getBlueprintsRequest.promise.then(function (res) {
                          if (ctrl.options.skipBlueprintId && _.get(res, 'data')) {
                            res.data = _.filter(res.data, function (data) {
                              return data.id !== ctrl.options.skipBlueprintId;
                            });
                          }
                          config.itemsBlueprint = _.concat(config.itemsBlueprint || [], res.data);
                          config.apiBlueprintParams.page++;
                          config.paginationBlueprintInfo = res.meta.pagination;
                          config.isBlueprintLoading = false;
                          if (config.paginationBlueprintInfo.current_page === config.paginationBlueprintInfo.total_pages) {
                            config.allBlueprintsLoaded = true;
                          }
                          defer.resolve(res.data);
                        }, function (err) {
                          config.isBlueprintLoading = false;
                          defer.reject(err);
                        });
                        return defer.promise;
                      },
                      openCreateTextTemplateModal: function () {
                        var defer = $q.defer();
                        OrganizationsService.openCreateTextTemplateModal(ctrl.options.type).then(function (res) {
                          resetTemplate = true;
                          defer.resolve(res);
                        }, function (err) {
                          defer.reject(err);
                        });
                        return defer.promise;
                      },
                      isLoading: false,
                      allTemplateLoaded: false,
                      isBlueprintLoading: false,
                      allBlueprintsLoaded: false
                    };
                  }

                  /**
                   * froala editor object initialize
                   * v3 updated
                   * 
                   * ctrl.froalaEditor = Editor object
                   */
                  ctrl.froalaEditor = new FroalaEditor(element[0], ctrl.options, function () {
                    ctrl.initListeners();
                    ctrl.tooltipConfig();
                    // remove second toolbar (bottom toolbar)
                    this.$box[0].removeChild(this.$second_tb[0]);
                    ctrl.options.allowEdit ? this.edit.on() : this.edit.off();
                    this.placeholder.show();
                    if (!ctrl.options.isGuest) {
                      !angular.equals({}, ctrl.atWhoOptions) ? ctrl.initializeAtWho(ctrl.froalaEditor) : angular.noop();
                    }
                    if (scope.froalaOptions.transitionOnShow) {
                      ctrl.froalaEditor.$box.css('opacity', 0);
                      var transitionHandler = $timeout(function () {
                        ctrl.froalaEditor.$box.css('opacity', 1);
                        $timeout.cancel(transitionHandler);
                      }, 150);
                    }
                    if (_.indexOf(_.get(scope.froalaOptions, 'toolbarButtons.moreText.buttons', []), 'html') === -1) {
                      ctrl.froalaEditor.$wp.addClass('fr-non-edit-html');
                    }
                    ctrl.setBusy(false);
                    ctrl.editorInitialized = true;
                    if (typeof ctrl.options.initializedCallback === 'function') {
                      ctrl.options.initializedCallback(ngModel);
                    }
                    var isAllowEditHtml = (!_.get($rootScope.identity, 'default_organization.wyiwyg', false))
                      ? _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin")
                      : ($rootScope.userState !== USER_STATE.PUBLIC) && ($rootScope.userState !== USER_STATE.GUEST);
                    var footerElement = angular.element('<div class="fr-footer-element d-flex align-items-center">');
                    if (isAllowEditHtml && !ctrl.options.hideHtmlToggle) {
                      var html = angular.element('<span class="tooltipped tooltipped-top" data-title="Show Html">')
                        .html('Show HTML')
                        .addClass('html-show-button');
                      html.on('click', function () {
                        ctrl.froalaEditor.commands.exec('html');
                        htmlShown = !htmlShown;
                        html.attr('data-title', htmlShown ? 'Show Simple' : 'Show HTML');
                        html.html(htmlShown ? 'Show Simple' : 'Show HTML');
                      });
                      html.appendTo(footerElement);
                    }
                    if (scope.froalaOptions.spellcheck) {
                      footerElement.appendTo(ctrl.froalaEditor.$wp);
                    } else {
                      footerElement.appendTo(ctrl.froalaEditor.$box);
                    }
                    scope.froalaOptions ? scope.froalaOptions.editorInitialized = ctrl.editorInitialized : angular.noop();
                    ctrl.froalaEditor.$box[0].setAttribute('id', ctrl.froalaID);
                    scope.froalaOptions.ctrl = ctrl;
                    ngModel.$render();
                  });
                  element[0].innerHTML = ctrl.froalaEditor.$el[0];

                  /**
                   * Froala removed jQuery but we still need jQuery for atwho mention plugin
                   * Stored it on ctrl.froalaElement
                   */
                  ctrl.froalaElement = jQuery(ctrl.froalaEditor.$el);
                  if (ctrl.options.fullFeature) {
                    ctrl.froalaEditor.$box.addClass('fr-full-feature');
                  }
                  if (scope.froalaOptions) {
                    scope.froalaOptions.froalaEditor = ctrl.froalaEditor;
                    scope.froalaOptions.editorScope = scope;
                  }
                };

                // dropdown click listener event handler
                ctrl.initDropdownListeners = function (act) {
                  var dropdowns = angular.element('.fr-dropdown:not(.fr-options)');
                  _.forEach(dropdowns, function (dropdown) {
                    var dd = angular.element(dropdown);
                    dd[act]('click', ctrl.closeMoreButton);
                  });
                };

                ctrl.attachBodyClickEvent = function (act) {
                  body[act]('click', ctrl.closeMoreButton);
                };

                ctrl.showLinkInvalidURL = function (isReset, popupClass) {
                  var popup = angular.element(document.getElementsByClassName(popupClass));
                  var inputTags = popup.find('.fr-input-line');
                  var buttonNav = popup.find('.fr-action-buttons');
                  var errorText = popup.find('.error-validation');
                  if (!isReset) {
                    _.forEach(inputTags, function (el) {
                      el.style.display = 'none';
                    });
                    buttonNav[0].style.display = 'none';
                    if (errorText.length) {
                      errorText[0].style.display = 'block';
                    } else {
                      errorText = $('<div>').addClass('error-validation text-danger text-wrap-break-word').html($filter('translate')('froala.linkErrorText'));
                      popup.append(errorText);
                    }
                  } else {
                    _.forEach(inputTags, function (el) {
                      el.style.display = 'block';
                    });
                    if (buttonNav && buttonNav[0]) {
                      buttonNav[0].style.display = 'block';
                    }
                    if (errorText && errorText[0]) {
                      errorText[0].style.display = 'none';
                    }
                    $(inputTags[0]).find('input').get(0).focus();
                  }
                };

                /**
                 * ngdoc controller method
                 * all registered event listeners
                 */
                ctrl.initListeners = function () {
                  var fileTextChanged = false, imageTextChanged = false, embedlyCommand = false,
                    multipleImagesUpload = {}, pasteImage, totalUpload = 0, isEditImage = false;

                  if (ctrl.options.immediateAngularModelUpdate) {
                    // on keyup event listener
                    ctrl.froalaEditor.events.on('keyup', function (e) {
                      ngModel.$touched = true;
                      ctrl.wrapMediaContent();
                      var emoticons = scope.froalaOptions.emoticonsSet[0].emoticons;
                      FroalaService.parseEmoticon(ctrl.froalaEditor, emoticons);
                      scope.$evalAsync(ctrl.updateModelView);
                    });
                  }
                  ctrl.initDropdownListeners('bind');

                  // on keydown event listener
                  ctrl.froalaEditor.events.on("keydown", function (e) {
                    if (e.keyCode === 9) {
                      e.preventDefault();
                      ctrl.froalaEditor.html.insert("&emsp;");
                    }
                    keyValue = e.which;
                  });

                  if (ctrl.options.spellcheck) {
                    ctrl.froalaEditor.events.on("drop", function (e) {
                      var isInSideEntityElement = e.currentTarget.closest('editor-variable') || e.currentTarget.closest('editor-form-field') || e.currentTarget.closest('editor-snippet') || e.currentTarget.closest('editor-blueprint');
                      if (isInSideEntityElement) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        return false;
                      }
                      var args = e.originalEvent.dataTransfer.getData('args'), field;
                      if (args) {
                        field = JSON.parse(args);
                      }
                      if (!field) {
                        return;
                      }
                      e.stopImmediatePropagation();
                      ctrl.froalaEditor.markers.insertAtPoint(e.originalEvent);
                      var $marker = ctrl.froalaEditor.$el.find('.fr-marker');
                      $marker.replaceWith(FroalaEditor.MARKERS);
                      ctrl.froalaEditor.selection.restore();
                      if (!ctrl.froalaEditor.undo.canDo()) {
                        ctrl.froalaEditor.undo.saveStep();
                      }
                      if (e.originalEvent.dataTransfer.getData('element') === 'editor-variable') {
                        ctrl.froalaEditor.html.insert(
                          '\uFEFF<editor-variable class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                          + field.fieldId + '\'" data-field-type="\'' + field.fieldType + '\'" data-field-label="\'' + field.fieldLabel
                          + '\'"></editor-variable>\uFEFF'
                        );
                        FroalaService.compileEditorFieldElement(scope, 'editor-variable');
                      } else if (e.originalEvent.dataTransfer.getData('element') === 'editor-form-field') {
                        if (field.type) {
                          var fieldType = field.type;
                          if (field.type === 'smallText') {
                            fieldType = {
                              type: 'text',
                              label: 'Short text'
                            };
                          } else if (field.type === 'largeText') {
                            fieldType = {
                              type: 'textarea',
                              label: 'Long text'
                            };
                          } else if (fieldType === 'date') {
                            fieldType = {
                              type: 'date',
                              label: 'Date'
                            };
                          }
                          var lastField = _.maxBy(scope.froalaOptions.process.prerun, 'position'),
                            position = (lastField ? lastField.position : 0) + 1,
                            fieldModel = {
                              field_type: fieldType.type,
                              label: fieldType.label,
                              guidance: null,
                              required: false,
                              position: position
                            };

                          scope.froalaOptions.process.prerun.push(fieldModel);
                          ctrl.setBusy(true);
                          if (!scope.froalaOptions.process.kickoff_title) {
                            scope.froalaOptions.process.kickoff_title = scope.froalaOptions.title;
                          }
                          var index = 1;
                          _.map(scope.froalaOptions.process.prerun, function (prerun) {
                            prerun.position = index;
                            index++;
                          });
                          scope.froalaOptions.isUpdatingPrerun = true;
                          ProcessService.update({ id: scope.froalaOptions.process.id }, scope.froalaOptions.process)
                            .then(function (res) {
                              angular.extend(scope.froalaOptions.process, { prerun: res.data.prerun, last_updated: res.data.last_updated });
                              var field = _.find(_.get(res.data, 'prerun', []), { position: position });
                              ctrl.froalaEditor.selection.restore();
                              $timeout(function () {
                                ctrl.froalaEditor.html.insert(
                                  '\uFEFF<editor-form-field draggable="true" class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                                  + field.id + '\'"></editor-form-field>\uFEFF'
                                );
                                ctrl.froalaEditor.undo.saveStep();
                                scope.$evalAsync(ctrl.updateModelView);
                              }, 10).then(function () {
                                $timeout(function () {
                                  FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                                  FroalaService.compileEditorFieldElement(scope, 'editor-snippet');
                                  FroalaService.compileEditorFieldElement(scope, 'editor-blueprint');
                                  FroalaService.compileEditorFieldElement(scope, 'editor-variable');
                                }, 0);
                                ctrl.froalaEditor.html.insert(' ');
                                ctrl.froalaEditor.undo.saveStep();
                                e.preventDefault();
                                e.stopPropagation();
                                if (ctrl.froalaEditor.core.hasFocus() && ctrl.froalaEditor.browser.mozilla) {
                                  ctrl.froalaEditor.events.disableBlur();
                                  $timeout(function () {
                                    ctrl.froalaEditor.$el.blur().focus();
                                    ctrl.froalaEditor.events.enableBlur();
                                  }, 1000);
                                }
                                scope.froalaOptions.isUpdatingPrerun = false;
                                ctrl.setBusy(false);
                              });
                            }, function () {
                              ctrl.setBusy(false);
                            });
                        } else {
                          ctrl.froalaEditor.html.insert(
                            '\uFEFF<editor-form-field class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                            + field.fieldId + '\'" data-field-type="\'' + field.fieldType + '\'" data-field-label="\'' + field.fieldLabel
                            + '\'"></editor-form-field>\uFEFF'
                          );
                          FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                        }
                      } else if (e.originalEvent.dataTransfer.getData('element') === 'editor-snippet') {
                        ctrl.froalaEditor.html.insert(
                          '\uFEFF<editor-snippet class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                          + field.fieldId + '\'" data-snippet-id="\'' + field.snippetId + '\'" data-snippet-title="\'' + field.snippetTitle
                          + '\'"></editor-snippet>\uFEFF'
                        );
                        FroalaService.compileEditorFieldElement(scope, 'editor-snippet');
                      } else if (e.originalEvent.dataTransfer.getData('element') === 'editor-blueprint') {
                        ctrl.froalaEditor.html.insert(
                          '\uFEFF<editor-blueprint class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                          + field.fieldId + '\'" data-blueprint-id="\'' + field.blueprintId + '\'" data-blueprint-title="\'' + field.blueprintTitle
                          + '\'" data-blueprint-icon="\'' + field.blueprintIcon
                          + '\'"></editor-blueprint>\uFEFF'
                        );
                        FroalaService.compileEditorFieldElement(scope, 'editor-blueprint');
                      }
                      if (field.sourceId) {
                        var el = $('#' + field.sourceId);
                        el[0].parentNode.removeChild(el[0]);
                      }
                      ctrl.froalaEditor.undo.saveStep();
                      e.preventDefault();
                      e.stopPropagation();
                      if (ctrl.froalaEditor.core.hasFocus() && ctrl.froalaEditor.browser.mozilla) {
                        ctrl.froalaEditor.events.disableBlur();
                        $timeout(function () {
                          ctrl.froalaEditor.$el.blur().focus();
                          ctrl.froalaEditor.events.enableBlur();
                        }, 1000);
                      }
                      scope.$evalAsync(ctrl.updateModelView);
                      return false;
                    }, true);
                  }

                  ctrl.froalaEditor.events.on("focus", function () {
                    if (typeof ctrl.options.focusCallback === 'function') {
                      ctrl.options.focusCallback(ctrl.froalaEditor);
                    }
                  });

                  ctrl.froalaEditor.events.on("blur", function () {
                    if (typeof ctrl.options.contentChangedCallback === 'function') {
                      ctrl.options.contentChangedCallback(ctrl.froalaEditor);
                    }
                    ctrl.froalaEditor.selection.save();
                  });

                  // on content changed event listener
                  ctrl.froalaEditor.events.on('contentChanged', function () {
                    ctrl.froalaElement.compileAtwhoElements ? ctrl.froalaElement.compileAtwhoElements() : angular.noop();
                    if (!ctrl.firstTimeBinding && ngModel.$touched) {
                      ctrl.froalaEditor.undo.saveStep();
                    }
                    scope.inviteMember ? ctrl.plusInviteMember() : angular.noop();
                    FroalaService.removeDuplicateMedia(ctrl.froalaEditor.$el);
                    FroalaService.cleanHTML(ctrl.froalaEditor.$el.html());
                    FroalaService.parseOldPageBreakContent(ctrl.froalaEditor);
                    ctrl.wrapMediaContent();
                    if (ctrl.options.spellcheck) {
                      FroalaService.syncEntityFormField(scope);
                    }
                    if (!ctrl.firstTimeBinding) {
                      scope.$evalAsync(ctrl.updateModelView);
                    }
                    if (typeof ctrl.options.contentChangedCallback === 'function') {
                      ctrl.options.contentChangedCallback(ctrl.froalaEditor);
                    }
                  });

                  // bind destroy event to froala element
                  ctrl.froalaElement.bind('$destroy', function () {
                    ctrl.froalaElement.atwho('destroy');
                    element.off(ctrl.listeningEvents.join(" "));
                    ctrl.froalaEditor.destroy();
                    if (ctrl.editorInitialized) {
                      element = null;
                      ctrl.editorInitialized = scope.froalaOptions.editorInitialized = false;
                    }
                    if (ctrl.options.confirmBeforeBrowserRefresh) {
                      window.removeEventListener("beforeunload", ctrl.beforeUpload);
                    }
                    ctrl.initDropdownListeners('unbind');
                    ctrl.attachBodyClickEvent('unbind');
                    ctrl.disableTooltip(ctrl.froalaEditor.$box.find('[data-cmd="moreText"]')[0], 'removeEventListener');
                    ctrl.disableTooltip(ctrl.froalaEditor.$box.find('[data-cmd="html"]')[0], 'removeEventListener');
                    if (codeViewTimeoutHandler) {
                      $timeout.cancel(codeViewTimeoutHandler);
                    }
                  });

                  var uploadedImages = [];
                  // Image beforeUpload event
                  ctrl.froalaEditor.events.on('image.beforeUpload', function (images) {
                    ctrl.setBusy(true);
                    if (images.length) {
                      if (images[0].name) {
                        imageInfo = {
                          name: images[0].name,
                          size: Helper.bytesToSize(images[0].size)
                        };
                      } else {
                        $timeout(function () {
                          multipleImagesUpload[images[0].name] = {
                            name: images[0].name,
                            size: Helper.bytesToSize(images[0].size),
                            image: pasteImage
                          };
                          isEditImage = true;
                        }, 0);
                      }
                    } else {
                      $timeout(function () {
                        if (images.length && images[0].name) {
                          uploadedImages.push({
                            name: images[0].name,
                            size: Helper.bytesToSize(images[0].size)
                          });
                        } else {
                          var $popup = this.popups.get('image.insert');
                          if ($popup) {
                            $popup.hide();
                          }
                          scope.$evalAsync(ctrl.updateModelView);
                        }
                        isEditImage = true;
                      }, 0);
                    }
                  });

                  ctrl.froalaEditor.events.on('image.beforePasteUpload', function ($img) {
                    $timeout(function () {
                      pasteImage = $img;
                      totalUpload++;
                      isEditImage = false;
                    }, 0);
                  });

                  // Image uploaded event
                  ctrl.froalaEditor.events.on('image.uploaded', function (response) {
                    var url = JSON.parse(response).data.link;
                    var downloadUrl = _.get(JSON.parse(response).data, 'download-link');
                    var $img = void 0, checkImageKey;
                    if (totalUpload) {
                      var serverFileName = url.split('/').pop();
                      checkImageKey = serverFileName.split('_')[1];
                      imageInfo = multipleImagesUpload[checkImageKey];
                      $img = $(imageInfo.image);
                    } else {
                      $img = this.image.get();
                    }
                    $img.wrap('<div class="ty-fr-media fr-deletable">'
                      + '<span contenteditable="false" class="fr-img-caption fr-fic fr-draggable fr-dib fr-deletable">'
                      + '<span class="fr-img-wrap"><div class="froala-img-lightbox lightbox-image" data-view-url="'
                      + url
                      + '" data-caption-url="'
                      + downloadUrl
                      + '" data-caption-size="'
                      + imageInfo.size
                      + '" data-caption-name="'
                      + imageInfo.name
                      + '"></div></span></span></div>');
                    this.image.insert(url, false, null, $img, response);
                    multipleImagesUpload[checkImageKey] = void 0;
                    if (isEditImage) {
                      this.popups.hide('image.insert');
                      this.toolbar.enable();
                      isEditImage = false;
                    }
                    return false;
                  });

                  // on image inserted event listener
                  ctrl.froalaEditor.events.on('image.inserted', function ($img) {
                    if (!$img) {
                      return;
                    }
                    if (!totalUpload) {
                      isEditImage = false;
                      this.selection.setAfter($img.closest('.ty-fr-media'));
                      this.html.insert(" ");
                    } else {
                      totalUpload--;
                    }
                    ctrl.wrapMediaContent();
                    totalUpload ? angular.noop() : ctrl.setBusy(false);
                    this.popups.hide('image.insert');
                    this.toolbar.enable();
                  });

                  //set element html
                  ctrl.froalaEditor.events.on('html.set', function () {
                    FroalaService.removeFroalaBanner(element);
                    ctrl.froalaElement.compileAtwhoElements ? ctrl.froalaElement.compileAtwhoElements() : angular.noop();
                  });

                  // on image error event
                  ctrl.froalaEditor.events.on('image.error', function (error) {
                    var $popup = this.popups.get('image.insert');
                    var $layer = $popup.find('.fr-image-progress-bar-layer');
                    if (error.code === 5) {
                      $layer.find('h3').addClass('text-danger l-h text-wrap-break-word').html($filter('translate')('steps.describes.uploadFile.exceedSize'));
                    }
                    ctrl.setBusy(false);
                  });

                  // on file before upload event
                  ctrl.froalaEditor.events.on('file.beforeUpload', function (files) {
                    this.html.insert(" ");
                    ctrl.setBusy(true);
                    if (_.indexOf(CONST.FROALA_ALLOWED_FILE_TYPES, files[0].name.split('.').pop()) < 0) {
                      var $popup = this.popups.get('file.insert');
                      $popup.find('.fr-file-upload-layer').addClass('fr-pactive').removeClass('fr-active');
                      $popup.find('.fr-file-progress-bar-layer').addClass('fr-error text-danger text-wrap-break-word').addClass('fr-active');
                      this.events.trigger('file.error', [{
                        code: 6,
                        message: $filter('translate')('tasks.captures.invalid_files', {
                          fileNames: files[0].name,
                          allowedFileTypes: CONST.FROALA_ALLOWED_FILE_TYPES.join(', ')
                        })
                      }]);
                      return false;
                    } else {
                      fileName = files[0].name;
                    }
                  });

                  // File uploaded event
                  ctrl.froalaEditor.events.on('file.uploaded', function (response) {
                    var url = JSON.parse(response).data.link;
                    this.file.insert(url, fileName, response);
                    return false;
                  });

                  /**
                   * File inserted event
                   * Cursor always place before paper clip icon
                   * So insert extra space after paper clip icon when file has been uploaded into editors
                   */
                  ctrl.froalaEditor.events.on('file.inserted', function () {
                    this.html.insert(" ");
                    ctrl.setBusy(false);
                  });

                  // File error event
                  ctrl.froalaEditor.events.on('file.error', function (error) {
                    var $popup = this.popups.get('file.insert');
                    var $layer = $popup.find('.fr-file-progress-bar-layer');
                    var errorText = (error.code === 5) ? $filter('translate')('steps.describes.uploadFile.exceedSize') : error.message;
                    $layer.find('h3').addClass('text-danger l-h text-wrap-break-word').html(errorText);
                    ctrl.setBusy(false);
                  });

                  ctrl.froalaEditor.events.on('link.beforeInsert', function (link) {
                    var isValid = false;
                    var linkLength = link.split('.').length;
                    if (linkLength === 1) {
                      isValid = false;
                      ctrl.showLinkInvalidURL(false, 'fr-link-insert-layer');
                    } else {
                      for (var i = 0; i < linkLength; i++) {
                        if (!link.split('.')[i]) {
                          ctrl.showLinkInvalidURL(false, 'fr-link-insert-layer');
                          return false;
                        }
                      }
                      isValid = true;
                    }
                    return isValid;
                  });

                  ctrl.froalaEditor.events.on('paste.beforeCleanup', function (e) {
                    var tempEl = angular.element('<div>').html(e), childrens = tempEl[0].children;
                    for (var i = 0; i < childrens.length; i++) {
                      var childrenPTags = $(childrens[i]).find('p');
                      for (var j = 0; j < childrenPTags.length; j++) {
                        var brTag = document.createElement('br'), tempSpan = document.createElement('span');
                        childrenPTags[j].after(brTag);
                        tempSpan.innerHTML = ';brPasteMarker;';
                        childrenPTags[j].after(tempSpan);
                      }
                    }
                    return tempEl[0].innerHTML.replace('\'', '&#96');
                  })

                  ctrl.froalaEditor.events.on('paste.afterCleanup', function (e) {
                    var tempEl = angular.element('<div>').html(e.replace(/;brPasteMarker;/g, '<br/>'));
                    return tempEl[0].children.length ? tempEl[0].innerHTML : e.trim().replace(/\n/gi, '<br/>');
                  })

                  ctrl.froalaEditor.events.on('paste.after', function () {
                    ngModel.$$parentForm.$setDirty();
                    if (ctrl.options.spellcheck) {
                      $timeout(function () {
                        FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                      }, 0);
                    }
                    scope.$evalAsync(ctrl.updateModelView);
                  });

                  // on video link error event listener
                  ctrl.froalaEditor.events.on('video.linkError', function () {
                    var $popup = this.popups.get('video.insert');
                    $popup.find('.fr-video-by-url-layer').addClass('fr-pactive').removeClass('fr-active');
                    var progress = $popup.find('.fr-video-progress-bar-layer').addClass('fr-error').addClass('fr-active');
                    progress.find('.fr-message').text($filter('translate')('steps.describes.videoUrl.error'));
                    ctrl.setBusy(false);
                  });

                  // on video before upload event listener
                  ctrl.froalaEditor.events.on('video.beforeUpload', function (files) {
                    ctrl.setBusy(true);
                    if (_.indexOf(CONST.FROALA_ALLOWED_FILE_TYPES, files[0].name.split('.').pop()) < 0) {
                      var $popup = this.popups.get('video.insert');
                      $popup.find('.fr-video-upload-layer').addClass('fr-pactive').removeClass('fr-active');
                      $popup.find('.fr-video-progress-bar-layer').addClass('fr-error').addClass('fr-active');
                      this.events.trigger('video.error', [{
                        code: 6, message: $filter('translate')('tasks.captures.invalid_files', {
                          fileNames: files[0].name,
                          allowedFileTypes: CONST.FROALA_ALLOWED_FILE_TYPES.join(', ')
                        })
                      }]);
                      return false;
                    } else {
                      fileName = files[0].name;
                    }
                  });

                  // on video before uploaded event listener
                  ctrl.froalaEditor.events.on('video.uploaded', function (response) {
                    var videoElement = '<video src=' + JSON.parse(response).data.link + ' class="fr-deletable fr-draggable" controls>Your browser does not support HTML5 video.</video>';
                    this.video.insert(videoElement);
                    ctrl.wrapMediaContent();
                    ctrl.setBusy(false);
                    ctrl.froalaEditor.undo.saveStep();
                    scope.$evalAsync(ctrl.updateModelView);
                    return false;
                  });

                  // on video error event listener
                  ctrl.froalaEditor.events.on('video.error', function (error) {
                    var $popup = this.popups.get('video.insert');
                    var $layer = $popup.find('.fr-video-progress-bar-layer');
                    var errorText = (error.code === 5) ? $filter('translate')('steps.describes.uploadFile.exceedSize') : error.message;
                    $layer.find('h3').addClass('text-danger l-h text-wrap-break-word').html(errorText);
                    ctrl.setBusy(false);
                  });

                  // on command after event listener
                  ctrl.froalaEditor.events.on('commands.before', function (cmd, param1) {
                    if (cmd === 'lineHeight') {
                      ctrl.wrapSelectedElement(param1);
                    } else if (cmd === 'html') {
                      var docEntities = ctrl.froalaEditor.$el.find('editor-form-field, editor-blueprint, editor-snippet, editor-variable');
                      _.map(docEntities, function (entity) {
                        entity.innerHTML = '';
                      });
                    }
                  });

                  // on command after event listener
                  ctrl.froalaEditor.events.on('commands.after', function (cmd) {
                    if (ctrl.firstTimeBinding) {
                      ctrl.firstTimeBinding = false;
                    }
                    if (cmd === 'moreText') {
                      ctrl.moreTextOpen = !ctrl.moreTextOpen;
                    } else if (cmd === 'html') {
                      ctrl.isCodeView = !ctrl.isCodeView;
                      if (!ctrl.isCodeView) {
                        $timeout(function () {
                          FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                          FroalaService.compileEditorFieldElement(scope, 'editor-snippet');
                          FroalaService.compileEditorFieldElement(scope, 'editor-blueprint');
                          FroalaService.compileEditorFieldElement(scope, 'editor-variable');
                          FroalaService.compileEditorFieldElement(scope, 'editor-pasted-url');
                        }, 0);
                      } else {
                        $rootScope.$emit('FROALA:SHOW_HTML_TOGGLE');
                      }
                    } else {
                      if (cmd === 'videoDismissError') {
                        this.popups.refresh('video.insert');
                      } else if (cmd === 'fileDismissError') {
                        this.popups.refresh('file.insert');
                      } else if (cmd === 'insertFile') {
                        if (!fileTextChanged) {
                          var filePopup = this.popups.get('file.insert');
                          var fileLimit = filePopup.find('.file-size-limit');
                          if (!fileLimit.length) {
                            var form = filePopup.find('.fr-form');
                            form.before('<div class="file-size-limit">100 MB limit</div>');
                          }
                          fileTextChanged = true;
                        }
                      } else if (cmd === 'insertImage') {
                        if (!imageTextChanged) {
                          var imagePopup = this.popups.get('image.insert');
                          var fileLimit = imagePopup.find('.file-size-limit');
                          if (!fileLimit.length) {
                            var form = imagePopup.find('.fr-form');
                            form.before('<div class="file-size-limit">100 MB limit</div>');
                          }
                          imageTextChanged = true;
                        }
                      } else if (cmd === 'embedlyInsert') {
                        embedlyCommand = true;
                      } else if (cmd === 'insertLink' || cmd === 'linkEdit') {
                        ctrl.showLinkInvalidURL(true, 'fr-link-insert-layer');
                      } else if (cmd === 'fullscreen') {
                        ctrl.editorState.isInFullscreenMode = !ctrl.editorState.isInFullscreenMode;
                        $rootScope.$emit('FROALA:FULLSCREEN_TOGGLE', { isFullscreen: ctrl.editorState.isInFullscreenMode });
                      }
                      if (ctrl.insertButtons.indexOf(cmd) === -1) {
                        ctrl.closeMoreButton();
                      }
                    }
                  });

                  ctrl.beforeUpload = function (e) {
                    e.preventDefault();
                    e.returnValue = true;
                    return true;
                  };

                  if (ctrl.options.confirmBeforeBrowserRefresh) {
                    window.addEventListener("beforeunload", ctrl.beforeUpload);
                  }

                  ctrl.wrapSelectedElement = function (lineHeight) {
                    var selection = ctrl.froalaEditor.selection.get();
                    if (selection.anchorNode) {
                      var anchorNode = angular.element(selection.anchorNode);
                      if (!anchorNode.hasClass('fr-view')) {
                        if (selection.anchorNode.nodeType === 3) {
                          anchorNode.wrap('<div class="d-inline-block" style="line-height:' + lineHeight + ';">');
                        } else {
                          anchorNode.css('line-height', lineHeight);
                        }
                      }
                    }
                    if (selection.baseNode) {
                      var baseNode = angular.element(selection.baseNode);
                      if (baseNode.hasClass('fr-view')) {
                        var selectedElement = angular.element(baseNode.get(0).childNodes[selection.baseOffset]);
                        selectedElement.wrap('<div class="d-inline-block" style="line-height:' + lineHeight + ';">');
                      } else {
                        if (selection.baseNode.nodeType === 3) {
                          baseNode.wrap('<div class="d-inline-block" style="line-height:' + lineHeight + ';">');
                        } else {
                          baseNode.css('line-height', lineHeight);
                        }
                      }
                    }
                    if (selection.extentNode) {
                      var extentNode = angular.element(selection.extentNode);
                      if (!extentNode.hasClass('fr-view')) {
                        if (selection.extentNode.nodeType === 3) {
                          extentNode.wrap('<div class="d-inline-block" style="line-height:' + lineHeight + ';">');
                        } else {
                          extentNode.css('line-height', lineHeight);
                        }
                      }
                    }
                    if (selection.focusNode) {
                      var focusNode = angular.element(selection.focusNode);
                      if (!focusNode.hasClass('fr-view')) {
                        if (selection.focusNode.nodeType === 3) {
                          focusNode.wrap('<div class="d-inline-block" style="line-height:' + lineHeight + ';">');
                        } else {
                          focusNode.css('line-height', lineHeight);
                        }
                      }
                    }
                  };

                  // editor click event handler
                  ctrl.froalaEditor.events.on('click', function (e) {
                    var isTyMedia = e.currentTarget.closest('.ty-fr-media');
                    if (!isTyMedia && e.currentTarget.nodeName !== 'IMG') {
                      e.stopImmediatePropagation();
                      ctrl.closeMoreButton();
                    }
                    
                    if (ctrl.options.spellcheck) {
                      $rootScope.$emit('EDITOR:CLICKED', { event: e });
                    }
                  });

                  ctrl.attachBodyClickEvent('bind');

                  // on embedly card inserted event listener
                  if (ctrl.froalaEditor.win && typeof ctrl.froalaEditor.win.embedly === 'function') {
                    ctrl.froalaEditor.win.embedly('on', 'card.rendered', function () {
                      if (ctrl.editorInitialized) {
                        if (embedlyCommand === true) {
                          ctrl.froalaEditor.html.insert(FroalaService.setDOMBreakLine(scope.froalaOptions.enter));
                          embedlyCommand = false;
                        }
                      }
                    });
                  }
                };

                // +email/email element to emailToAtReply 
                ctrl.emailToAtReply = function (userInMember, lastLink, innerText) {
                  var atReply = '&nbsp;<span class="atwho-content fr-deletable" contenteditable="false">' + userInMember.insert_tpl + '</span>&nbsp;';
                  var atWho = innerText.replace(lastLink, atReply);
                  ctrl.froalaEditor.html.set(atWho);
                  var editor = ctrl.froalaEditor;
                  editor.selection.setAtEnd(editor.$el.get(0));
                  editor.selection.restore();
                };

                // +email element initialization
                ctrl.plusInviteMember = function () {
                  var innerText = ctrl.froalaEditor.html.get();
                  var userList = ctrl.atWhoOptions.data;
                  if (angular.isString(innerText)) {
                    var tagDataArray = innerText.match(CONST.TEXT_IN_A_TAG_REGEX) || [];
                    if (tagDataArray.length > 0) {
                      var lastLink = _.last(tagDataArray);
                      var n = innerText.indexOf(lastLink);
                      var bufferSpace = (keyValue === 32) ? 1 : ((keyValue === 13) ? 8 : 0);
                      var isLastEmail = _.size(innerText) === (_.size(lastLink) + n + bufferSpace);
                      if (isLastEmail) {
                        var removeHtmlTag = lastLink.replace(CONST.REMOVE_HTML_TAG_REGEX, "");
                        if (removeHtmlTag && keyValue !== 8 && (keyValue === 32 || keyValue === 13)) {
                          var validEmailWithPlus = CONST.EMAIL_REGEX_START_WITH_PLUS.test(removeHtmlTag);
                          if (validEmailWithPlus && removeHtmlTag.charAt(0) === '+') {
                            if (removeHtmlTag.charAt(0) === '+') {
                              var availableUser = removeHtmlTag.substr(1);
                            }
                            var userInMember = _.find(userList, _.matches({ 'email': availableUser }));
                            if (userInMember) {
                              ctrl.emailToAtReply(userInMember, lastLink, innerText);
                            } else {
                              if (scope.isAssignToGuest) {
                                ctrl.inviteUser(removeHtmlTag);
                              }
                            }
                          } else {
                            var validEmail = CONST.EMAIL_REGEX.test(removeHtmlTag);
                            if (validEmail) {
                              var userInMember = _.find(userList, _.matches({ 'email': removeHtmlTag }));
                              if (userInMember) {
                                ctrl.emailToAtReply(userInMember, lastLink, innerText);
                              } else {
                                if (scope.isAssignToGuest) {
                                  ctrl.inviteUser(removeHtmlTag);
                                  var lastLink = _.last(tagDataArray);
                                }
                              }
                            }
                          }
                        } else {
                          if (keyValue === 8) {
                            var lastLinkHtmlToText = innerText.replace(lastLink, removeHtmlTag);
                            ctrl.froalaEditor.html.set(lastLinkHtmlToText);
                            var newEditor = ctrl.froalaEditor;
                            newEditor.selection.setAtEnd(newEditor.$el.get(0));
                            newEditor.selection.restore();
                          }
                        }
                      }
                    }
                  }
                };

                // atwho element initialization
                ctrl.initializeAtWho = function () {
                  ctrl.atwhoEventListeners = [
                    'inserted.atwho',
                    'shown.atwho',
                    'hidden.atwho',
                    'keydown'
                  ];
                  angular.extend(ctrl.atWhoOptions, {
                    suffix: "",
                    limit: ctrl.atWhoOptions.limit ? ctrl.atWhoOptions.limit : 30,
                    startWithSpace: true,
                    callbacks: {
                      // plugin event callback beforeReposition
                      beforeReposition: function (offset) {
                        offset.left = this.rect().left;
                        offset.top = this.rect().top - this.$el.children().height();
                      },
                      // plugin method callback filter
                      filter: function (query, data, searchKey) {
                        var results = _.filter(data, function (item) {
                          return _.lowerCase(_.get(item, searchKey, '')).replace(/\s/g, '').indexOf(_.lowerCase(query)) != -1;
                        });
                        return results;
                      },
                      // plugin method callback sorter
                      sorter: function (query, items, searchKey) {
                        var results = _.filter(items, function (item) {
                          item.atwho_order = _.lowerCase(_.get(item, searchKey, '')).replace(/\s/g, '').indexOf(_.lowerCase(query));
                          return item.atwho_order !== -1;
                        });
                        return _.orderBy(results, 'atwho_order', 'asc');
                      }
                    }
                  });

                  // method to remove atwho element when x clicked
                  scope.onAtwhoRemoveElement = function (e) {
                    e.stopPropagation();
                    var container = e.target.closest('.atwho-content');
                    container.parentNode.removeChild(container);
                    scope.$evalAsync(ctrl.updateModelView);
                    ctrl.froalaElement.focus();
                  };

                  // new method on editor to get all atwho elements
                  ctrl.froalaElement.getAtwhoElements = function () {
                    return this.find('.atwho-inserted, .atwho-content');
                  };

                  // new method on editor to compile atwho element
                  ctrl.froalaElement.compileAtwhoElements = function () {
                    var atwhoEl = this.getAtwhoElements();
                    if (atwhoEl.length > 0) {
                      atwhoEl
                        .attr('contenteditable', false)
                        .addClass('atwho-content')
                        .addClass('fr-deletable')
                        .removeClass('atwho-inserted')
                        .removeAttr('data-atwho-at-query');
                      $compile(atwhoEl)(scope);
                    }
                  };

                  //event handler for scroll
                  var atWhoScrollHandler = function () {
                    ctrl.froalaElement.atwho('reposition');
                  };

                  // atwho view
                  ctrl.atwhoView = ctrl.froalaElement.atwho(ctrl.atWhoOptions);

                  // on atwho mention inserted
                  ctrl.atwhoView.on('inserted.atwho', function () {
                    scope.$evalAsync(ctrl.updateModelView);
                    ctrl.froalaElement.compileAtwhoElements();
                    ctrl.froalaEditor.html.insert("&nbsp;");
                    ctrl.froalaElement.focus();
                  });

                  // on atwho mention shown
                  ctrl.atwhoView.on('shown.atwho', function () {
                    angular.element(document.querySelector('.app-body')).bind("scroll", atWhoScrollHandler);
                  });

                  // on atwho mention hidden
                  ctrl.atwhoView.on('hidden.atwho', function () {
                    angular.element(document.querySelector('.app-body')).unbind("scroll", atWhoScrollHandler);
                  });

                  // on atwho mention keydown
                  ctrl.froalaEditor.events.on('keydown', function (e) {
                    // enter key handler
                    if (e.which === FroalaEditor.KEYCODE.ENTER && ctrl.froalaElement.atwho('isSelecting')) {
                      return false;
                    }
                    if (e.keyCode == 8) {
                      return false;
                    }
                  }, true);
                  angular.extend(ctrl.listeningEvents, ctrl.atwhoEventListeners);
                };

                var autoSaveTimeout;
                // update view model
                ctrl.updateModelView = function () {
                  FroalaService.decodeLinkUri(ctrl.froalaEditor.$el);
                  FroalaService.validateLinkProtocol(ctrl.froalaEditor.$el);
                  FroalaService.addLinkTargetFroalaFile(ctrl.froalaEditor.$el);
                  FroalaService.embedTallyfyEntityLink(scope, ctrl.froalaEditor.$el);
                  if (ctrl.firstTimeBinding) {
                    FroalaService.removeFroalaBanner(element);
                    FroalaService.removeDuplicateMedia(ctrl.froalaEditor.$el);
                    FroalaService.showImageLightbox(ctrl.froalaElement, true);
                    FroalaService.wrapFirstParagraphTag(ctrl.froalaEditor.$el);
                    ctrl.firstTimeBinding = false;
                    $timeout(function () {
                      if (ctrl.options.spellcheck) {
                        FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                        FroalaService.compileEditorFieldElement(scope, 'editor-snippet');
                        FroalaService.compileEditorFieldElement(scope, 'editor-blueprint');
                        FroalaService.compileEditorFieldElement(scope, 'editor-variable');
                      }
                      FroalaService.compileEditorFieldElement(scope, 'editor-pasted-url');
                      if (!ctrl.reInitialized) {
                        ngModel.$setPristine();
                        ngModel.$$parentForm[ngModel.$name] ? ngModel.$$parentForm[ngModel.$name].$setPristine() : void 0;
                        var isFormPristine = true;
                        _.forEach(_.get(ngModel, '$$parentForm.$$success.parse', []), function (control) {
                          if ((control.$name !== ngModel.$name) && !control.$pristine) {
                            isFormPristine = false;
                            return false;
                          }
                        });
                        if (isFormPristine) {
                          ngModel.$$parentForm.$setPristine();
                        }
                      }
                    }, 100);
                    if (ctrl.options.spellcheck) {
                      FroalaService.syncEntityFormField(scope);
                    }
                  } else {
                    var modelContent = null;
                    if (ctrl.specialTag) {
                      var attributeNodes = element[0].attributes, attrs = {};
                      for (var i = 0; i < attributeNodes.length; i++) {
                        var attrName = attributeNodes[i].name;
                        if (ctrl.options.angularIgnoreAttrs && ctrl.options.angularIgnoreAttrs.indexOf(attrName) !== -1) {
                          continue;
                        }
                        attrs[attrName] = attributeNodes[i].value;
                      }
                      if (element[0].innerHTML) {
                        attrs[innerHtmlAttr] = element[0].innerHTML;
                      }
                      modelContent = attrs;
                    } else {
                      var returnedHtml = ctrl.froalaEditor.html.get();
                      if (angular.isString(returnedHtml)) {
                        modelContent = _.isEmpty(returnedHtml) ? null : returnedHtml.replaceAll('&#39;', '\'');
                      } else {
                        modelContent = null;
                      }
                    }
                    ngModel.$setViewValue(modelContent);
                    if (!scope.$root.$$phase) {
                      scope.$apply();
                    }
                    if (typeof _.get(ctrl.options, 'qAutoSave.callback') === 'function') {
                      if (autoSaveTimeout) {
                        $timeout.cancel(autoSaveTimeout);
                      }
                      autoSaveTimeout = $timeout(function () {
                        ctrl.setBusy(true);
                        var result = ctrl.options.qAutoSave.callback();
                        if (result) {
                          result.then(function () {
                            ctrl.setBusy(false);
                          }, function () {
                            ctrl.setBusy(false);
                          });
                        } else {
                          ctrl.setBusy(false);
                        }
                      }, ctrl.options.qAutoSave.debounce || 1000);
                    }
                  }
                };

                // wrap all media content
                ctrl.wrapMediaContent = function () {
                  var childrens = ctrl.froalaElement.children();
                  _.forEach(childrens, function (children) {
                    var el = angular.element(children);
                    if (el.hasClass('fr-img-caption') || el.hasClass('fr-embedly') || el.hasClass('fr-video')) {
                      el.wrap('<div class="ty-fr-media fr-deletable">');
                      el.attr('contenteditable', 'false');
                    }
                  });

                  // remove empty file
                  var files = ctrl.froalaElement.find('.fr-file');
                  _.forEach(files, function (file) {
                    var parent = file.parentNode;
                    if (!file.innerText) {
                      parent ? parent.removeChild(file) : angular.noop();
                    }
                  });

                  // remove empty fr media wrapper
                  var tyFrMedia = ctrl.froalaElement.find('.ty-fr-media');
                  _.forEach(tyFrMedia, function (media) {
                    var el = angular.element(media);
                    el.addClass('fr-deletable');
                    var parent = el.get(0).parentNode;
                    if (!el.has('.fr-img-caption').length > 0 && !el.has('.fr-embedly').length > 0 && !el.has('.fr-video').length > 0) {
                      parent ? parent.removeChild(media) : angular.noop();
                    }
                  });
                  FroalaService.removeDuplicateMedia(ctrl.froalaEditor.$el);
                  FroalaService.attachMenuClickHandler(ctrl.froalaEditor);
                  FroalaService.parseInsertVariableTag(ctrl.froalaEditor.$el, _.get(ctrl.options, 'variableValue', []));
                  FroalaService.parseContentEditable(ctrl.froalaEditor.$el);
                  FroalaService.removeInlineBreakpoint(ctrl.froalaEditor.$el);
                  FroalaService.showImageLightbox(ctrl.froalaElement, true);
                  FroalaService.decodeLinkUri(ctrl.froalaEditor.$el);
                  FroalaService.validateLinkProtocol(ctrl.froalaEditor.$el);
                  FroalaService.addLinkTargetFroalaFile(ctrl.froalaEditor.$el);
                  FroalaService.wrapFirstParagraphTag(ctrl.froalaEditor.$el);
                  if (ctrl.options.spellcheck) {
                    FroalaService.removeEntityWhiteSpace(scope, 'editor-form-field');
                    FroalaService.removeEntityWhiteSpace(scope, 'editor-snippet');
                    FroalaService.removeEntityWhiteSpace(scope, 'editor-blueprint');
                    FroalaService.removeEntityWhiteSpace(scope, 'editor-variable');
                  }
                  if (scope.froalaOptions.tableOfContentElements) {
                    FroalaService.attachTableOfContentId(ctrl.froalaEditor.$el, scope);
                  }
                  if (ctrl.options.isPublic) {
                    FroalaService.parseBlueprintTag(ctrl.froalaEditor.$el, scope);
                    FroalaService.parseSnippetTag(ctrl.froalaEditor.$el, true);
                    scope.$digest();
                  } else {
                    scope.$evalAsync(ctrl.updateModelView);
                  }
                };

                // Invite user by email
                ctrl.inviteUser = function (email) {
                  if (email.charAt(0) === '+') {
                    email = email.substr(1);
                  }
                  UsersService.openInviteModal(email, scope.froalaOptions.elementID);
                };

                contentChangedWatcher = $rootScope.$on('EDITOR:CONTENT_CHANGED', function (e) {
                  ctrl.froalaEditor.events.trigger('contentChanged');
                });
              },
              post: function (scope, element, attrs, ngModel) {
                var ctrl = scope.$froalaController;
                froalaConfig = froalaConfig || {};
                ctrl.specialTag = SPECIAL_TAGS.indexOf(element.prop("tagName")) !== -1;
                scope.initMode = attrs.froalaInit ? MANUAL : AUTOMATIC;
                ctrl.init = function () {
                  if (!attrs.id) {
                    ctrl.froalaID = scope.froalaOptions.elementID = Helper.getId();
                    attrs.$set('name', 'froala-' + ctrl.froalaID);
                    attrs.$set('id', 'froala-' + ctrl.froalaID);
                  } else {
                    ctrl.froalaEditor ? ctrl.froalaID = scope.froalaOptions.elementID = attrs.id : angular.noop();
                  }
                  scope.initMode === AUTOMATIC ? scope.$froalaController.createEditor() : angular.noop();

                  //Instruct ngModel how to update the froala editor
                  ngModel.$render = function () {
                    ctrl.firstTimeBinding = true;
                    ngModel.$touched = false;
                    if (ctrl.specialTag) {
                      var tags = ngModel.$modelValue;
                      if (tags) {
                        for (var attr in tags) {
                          tags.hasOwnProperty(attr) && attr !== innerHtmlAttr ? element.attr(attr, tags[attr]) : angular.noop();
                        }
                        tags.hasOwnProperty(innerHtmlAttr) ? element[0].innerHTML = tags[innerHtmlAttr] : angular.noop();
                      }
                    } else {
                      if (ctrl.editorInitialized) {
                        ctrl.froalaEditor.html.set(ngModel.$viewValue || '', true);
                        if (ctrl.options.resizeable) {
                          ctrl.froalaEditor.$wp.addClass('resizeable-editor');
                        }
                        ctrl.froalaEditor.undo.reset();
                        ctrl.froalaEditor.undo.saveStep();
                      }
                    }
                  };

                  ngModel.$isEmpty = function (value) {
                    if (!value) {
                      return true;
                    }
                    if (ctrl.editorInitialized) {
                      var el = angular.element('<div>');
                      el.html(FroalaService.cleanHTML(value));
                      return ctrl.froalaEditor.node.isEmpty(el.get(0));
                    }
                    return true;
                  };
                };

                if (scope.initMode === MANUAL) {
                  var _ctrl = ctrl;
                  var controls = {
                    initialize: ctrl.createEditor,
                    destroy: function () {
                      if (_ctrl.froalaEditor) {
                        _ctrl.froalaEditor('destroy');
                        _ctrl.editorInitialized = false;
                        contentChangedWatcher();
                        if (_ctrl.options.confirmBeforeBrowserRefresh) {
                          window.removeEventListener("beforeunload", _ctrl.beforeUpload);
                        }
                      }
                    },
                    getEditor: function () {
                      return _ctrl.froalaEditor ? _ctrl.froalaEditor : null;
                    }
                  };
                  scope.initFunction({
                    initControls: controls
                  });
                }

                if (!scope.froalaOptions) {
                  return;
                }

                scope.froalaOptions.updateVariableValues = function (value) {
                  ctrl.options.variableValue = value;
                  FroalaService.parseInsertVariableTag(ctrl.froalaEditor.$el, _.get(ctrl.options, 'variableValue', []));
                };

                //re-initialize editor to the default state
                scope.froalaOptions.reInitializeEditor = function (value, showInit) {
                  ctrl.reInitialized = true;
                  if (showInit) {
                    scope.froalaOptions.initOnClick = ctrl.options.initOnClick = false;
                  }
                  if (ctrl.froalaEditor) {
                    ngModel.$setViewValue('');
                    element[0].value = '';
                    ctrl.froalaEditor.html.set('');
                    ctrl.editorInitialized = scope.froalaOptions.editorInitialized = false;
                    ctrl.froalaEditor.destroy();
                    ctrl.froalaElement.atwho('destroy');
                    if (ctrl.options.confirmBeforeBrowserRefresh) {
                      window.removeEventListener("beforeunload", ctrl.beforeUpload);
                    }
                    element.off(ctrl.listeningEvents.join(" "));
                    var frBox = ctrl.froalaElement.closest('.fr-box').get(0);
                    if (frBox) {
                      frBox.parentNode.removeChild(frBox);
                    }
                    scope.$applyAsync(function () {
                      if (value) {
                        ngModel.$viewValue = value;
                      }
                      ctrl.init();
                    });
                  }
                };
                scope.froalaOptions.updateModelView = ctrl.updateModelView;

                var isHasAccess = _.get(scope, 'froalaOptions.accessibility.haveAuthority', true);
                if (isHasAccess) {
                  ctrl.init();
                } else {
                  element.attr('placeholder', scope.froalaOptions.placeholderText);
                  if (!scope.froalaOptions.allowEdit) {
                    element.attr('disabled', 'true');
                  } else {
                    element.off('focus', function (e) {
                      e.preventDefault();
                      var accessibility = _.get(scope.froalaOptions, 'accessibility');
                      if (accessibility.callback) {
                        accessibility.callback(accessibility.callbackParams);
                      }
                      element.trigger('blur');
                    });
                  }
                }
              }
            };
          }
        };
      });
})(window.jQuery);

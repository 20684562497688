(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('automationCondition', {
      templateUrl: 'app/modules/process/components/edit/automation/wrapper/condition/condition.component.html',
      bindings: {
        form: '=',
        process: '=',
        isNew: '<',
        automation: '=',
        condition: '=',
        isFirst: '<',
        isLast: '<',
        showOperator: '<',
        usersInOrg: '<',
        orgsInOrg: '<',
        guestsInOrg: '<',
        conditionUpdatedCallback: '&',
        deleteConditionCallback: '&',
        validationActive: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, $filter, $timeout, ProcessService, CONDITION_STATEMENT, AUTOMATION_CONDITION_OPERATION, AUTOMATION_ACTION_CONDITION_TYPE, SHORT_TEXT_VALUE_TYPE,
          Helper, $window) {
          var $ctrl = this, localIdWatcher, windowElem = angular.element($window);

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.conditionLogics = ['and', 'or'];

          $ctrl.conditionSelected = conditionSelected;
          $ctrl.getSelectedConditionLabel = getSelectedConditionLabel;

          $ctrl.conditionUpdated = conditionUpdated;
          $ctrl.deleteCondition = deleteCondition;

          $ctrl.equalsAnyValueUpdated = equalsAnyValueUpdated;
          $ctrl.conditionOperationUpdated = conditionOperationUpdated;

          $ctrl.selectedCondition = null;

          windowElem.on('resize', onWindowResize);

          function onInit() {
            if (!$ctrl.condition.id) {
              localIdWatcher = $scope.$watch('$ctrl.condition.id', function (val) {
                if (val) {
                  delete $ctrl.condition.localId;
                }
              })
            }
          }

          function onChanges(changes) {
            prepareConditions();
          }

          function onDestroy() {
            windowElem.off('resize', onWindowResize);
            if (localIdWatcher) {
              localIdWatcher();
            }
          }

          function prepareConditions() {
            $ctrl.availableConditions = [];
            // Push prerun from process meta if and only if there is any prerun
            if (!_.isEmpty($ctrl.process.prerun)) {
              _.map($ctrl.process.prerun, function (item) {
                if (item.field_type !== 'assignees_form') {
                  $ctrl.availableConditions.push({
                    id: item.id,
                    name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                    nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                    title: $filter('translate')('steps.logic.label.kickOffTitle', { label: item.label }),
                    subjectType: $filter('translate')('steps.logic.label.pre_run'),
                    class: 'item-field',
                    type: 'Prerun',
                    required: item.required,
                    fieldtype: item.field_type,
                    options: item.options,
                    columns: item.columns,
                    fieldValidation: item.field_validation || []
                  });
                }
              });
            }

            // Push steps
            if ($ctrl.process.steps.data.length) {
              _.map(_.orderBy(getStepsOrdered(), ['stage_order', 'position']), function (step, index) {
                $ctrl.availableConditions.push({
                  id: step.id,
                  name: $filter('translate')('steps.logic.label.step_plain', { position: index + 1, title: step.title }),
                  nameText: $filter('translate')('steps.logic.label.step_plain_text', { position: index + 1, title: step.title }),
                  title: $filter('translate')('steps.logic.label.stepTitle', { position: index + 1, title: step.title }),
                  subjectType: '',
                  class: 'item-step',
                  type: 'Step',
                  stepType: step.step_type
                });
                _.map(_.filter(step.captures, function (item) {
                  return !_.isUndefined(item.step_id);
                }), function (item) {
                  if (item.field_type !== 'assignees_form') {
                    $ctrl.availableConditions.push({
                      id: item.id,
                      name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                      nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                      title: $filter('translate')('steps.logic.label.captureTitle', { position: step.position, title: step.title, fieldName: item.label }),
                      subjectType: '',
                      class: 'item-field ml-3',
                      type: 'Capture',
                      parent_step_id: step.id,
                      required: item.required,
                      fieldtype: item.field_type,
                      options: item.options,
                      columns: item.columns,
                      fieldValidation: item.field_validation || []
                    });
                  }
                });
              });
            }
            setSelectedValue();
          }

          function getStepsOrdered() {
            return _.map($ctrl.process.steps.data, function (task) {
              var stageData = _.find(_.get($ctrl.process, 'stages.data', []), function (stage) {
                return !task.stage_id ? false : (stage.id === task.stage_id.toString());
              });
              task.stage_order = stageData ? stageData.position : null;
              return task;
            })
          }

          function conditionSelected(e) {
            $ctrl.condition.conditionable_id = e.selected.id;
            $ctrl.condition.conditionable_type = e.selected.type;
            $ctrl.condition.operation = null;
            $ctrl.condition.statement = null;
            $ctrl.condition.column_contains_name = null;
            var isNumericType = e.selected.fieldValidation ? e.selected.fieldValidation.indexOf(SHORT_TEXT_VALUE_TYPE.NUMERIC) >= 0 : false;
            $ctrl.operations = ProcessService.getConditionableOperations(e.selected.type, e.selected.fieldtype ? e.selected.fieldtype + (isNumericType ? '_numeric' : '') : void 0, e.selected.stepType);
            conditionUpdated();
          }

          function getSelectedConditionLabel(e) {
            var condition = _.get(e, 'selected');
            if (!Helper.isObjectEmpty(condition)) {
              return condition.title;
            }
          }

          function setSelectedValue() {
            $ctrl.selectedCondition = _.find($ctrl.availableConditions, { id: $ctrl.condition.conditionable_id });
            if (!$ctrl.selectedCondition) {
              return;
            }
            var field;
            if ($ctrl.selectedCondition.type === AUTOMATION_ACTION_CONDITION_TYPE.CAPTURE) {
              var allCaptures = [];
              for (var i = 0; i < $ctrl.process.steps.data.length; i++) {
                allCaptures = _.concat(allCaptures, $ctrl.process.steps.data[i].captures);
              }
              field = _.find(allCaptures, { id: $ctrl.selectedCondition.id });
              var isNumericType = field.field_validation ? field.field_validation.indexOf(SHORT_TEXT_VALUE_TYPE.NUMERIC) >= 0 : false;
              $ctrl.operations = ProcessService.getConditionableOperations($ctrl.selectedCondition.type, field.field_type ? field.field_type + (isNumericType ? '_numeric' : '') : void 0);
            } else if ($ctrl.selectedCondition.type === AUTOMATION_ACTION_CONDITION_TYPE.PRERUN) {
              field = _.find($ctrl.process.prerun, { id: $ctrl.selectedCondition.id });
              var isNumericType = field.field_validation ? field.field_validation.indexOf(SHORT_TEXT_VALUE_TYPE.NUMERIC) >= 0 : false;
              $ctrl.operations = ProcessService.getConditionableOperations($ctrl.selectedCondition.type, field.field_type ? field.field_type + (isNumericType ? '_numeric' : '') : void 0);
            } else {
              $ctrl.operations = ProcessService.getConditionableOperations($ctrl.selectedCondition.type, void 0, $ctrl.selectedCondition.stepType);
            }
            if ($ctrl.condition.operation === AUTOMATION_CONDITION_OPERATION.EQUALS_ANY) {
              if (!$ctrl.selectOptions) {
                $ctrl.equalsAnyStatements = _.filter(_.get(field, 'options', []), function (opt) {
                  return _.find(_.get($ctrl.condition, 'statement', []), function (statement) {
                    return statement.toString() === opt.text;
                  });
                });
                _.map(field.options, function (field) {
                  field.selected = !!_.find($ctrl.equalsAnyStatements, { id: field.id });
                  return field;
                });
                $timeout(function () {
                  $ctrl.selectOptions = getSelectBoxOptions();
                }, 0);
              }
            }
          }

          function conditionUpdated() {
            if (_.get($ctrl.selectedCondition, 'stepType')) {
              $ctrl.condition.statement = CONDITION_STATEMENT.ANY_TIME;
            }
            $ctrl.conditionUpdatedCallback({ action: $ctrl.action });
          }

          function conditionOperationUpdated() {
            $ctrl.condition.statement = null;
            if ($ctrl.condition.operation === AUTOMATION_CONDITION_OPERATION.EQUALS_ANY) {
              $ctrl.maxItems = $window.innerWidth < 1764 ? $window.innerWidth < 1366 ? 1 : 2 : 3;
              if (!$ctrl.selectOptions) {
                $timeout(function () {
                  $ctrl.selectOptions = getSelectBoxOptions();
                }, 0);
              }
            }
            conditionUpdated();
          }

          function deleteCondition() {
            $ctrl.deleteConditionCallback({ condition: $ctrl.condition });
          }

          function setSelectedElement(selectboxWidget) {
            $timeout(function () {
              var list = selectboxWidget.ul[0];
              var options = list.getElementsByTagName('li');
              for (var i = 0; i < options.length; i++) {
                var input = options[i].getElementsByTagName('input');
                if (options[i].classList.contains('k-state-selected')) {
                  input[0].checked = true;
                } else {
                  input[0].checked = false;
                }
              }
            }, 0);
          }

          function equalsAnyValueUpdated() {
            $ctrl.condition.statement = _.map($ctrl.equalsAnyStatements || [], function (s) {
              return s.text;
            }) || [];
            conditionUpdated();
          }

          function getSelectBoxOptions() {
            return {
              selectBoxId: 'select-' + $ctrl.condition.id,
              widgetConfig: {
                autoClose: false,
                clearButton: false,
                dataSource: new kendo.data.DataSource(),
                dataTextField: "text",
                dataValueField: "id",
                downArrow: true,
                itemTemplate: kendo.template($('#optionTemplate').html()),
                placeholder: $filter('translate')('steps.logic.placeholder.automation.triggerValue'),
                tagTemplate: kendo.template($('#selectedOptionTemplate').html()),
                dataBound: function (e) {
                  setSelectedElement(e.sender);
                },
                deselect: function (e) {
                  e.dataItem.selected = !e.dataItem.selected;
                  setSelectedElement(e.sender);
                },
                select: function (e) {
                  e.dataItem.selected = !e.dataItem.selected;
                  setSelectedElement(e.sender);
                },
                change: function (e) {
                  e.preventDefault();
                  setSelectedElement($ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect'));
                  checkMultiSelectTagMode();
                },
                height: 160
              },
              templateScope: {
                variables: {},
                callbacks: {}
              }
            }
          }

          // Check the tag mode of multiselect and toggle it
          function checkMultiSelectTagMode () {
            var multiSelect = $($ctrl.selectOptions.selectBoxElement).data('kendoMultiSelect'),
            currentValue = multiSelect.value();
            if (multiSelect) {
              if (multiSelect.value().length > $ctrl.maxItems) {
                multiSelect.value([]);
                multiSelect.setOptions({
                  tagMode: 'single',
                  tagTemplate: null
                })
              } else {
                multiSelect.value([]);
                multiSelect.setOptions({
                  tagMode: 'multiple',
                  tagTemplate: kendo.template($('#selectedOptionTemplate').html())
                })
              }
              multiSelect.value(currentValue);
              multiSelect.refresh();
            }
          }

          //On window resize - set multiselect max items
          function onWindowResize () {
            $ctrl.maxItems = $window.innerWidth < 1764 ? $window.innerWidth < 1366 ? 1 : 2 : 3;
            checkMultiSelectTagMode();
          }
        }
    });
})();

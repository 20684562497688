(function () {
  'use strict';
  angular.module('tallyfy')
    .component('taskFilterOption', {
      bindings: {
        resolve: '=',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/components/task-filter/task-filter-options/task-filter-options.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $timeout, Helper, TagsService, RunsService, ProcessService, FolderService, TasksFilterService, RunRepository, OrganizationsService) {
          var $ctrl = this,
            blueprintParams,
            processParams,
            onFilterTagsHandler,
            onRemoveFilterTagsHandler,
            body = angular.element('body');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onCreateFolder = onCreateFolder;
          $ctrl.closeModal = closeModal;
          $ctrl.getActiveBlueprints = getActiveBlueprints;

          $ctrl.onStatusChange = onStatusChange;
          $ctrl.onAssigneeChange = onAssigneeChange;
          $ctrl.onBlueprintChange = onBlueprintChange;
          $ctrl.onTaskStatesChange = onTaskStatesChange;
          $ctrl.onFolderChange = onFolderChange;
          $ctrl.onTagsChange = onTagsChange;
          $ctrl.onProcessChange = onProcessChange;
          $ctrl.getActiveProcesses = getActiveProcesses;

          $ctrl.onEditFolder = onEditFolder;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;

          $ctrl.getTagTextColor = TagsService.isTagTextColor;
          $ctrl.taskStates = TasksFilterService.getTaskStates();
          $ctrl.isMemberAssigneeDropdownOpen = false;
          $ctrl.isGuestAssigneeDropdownOpen = false;
          $ctrl.selectedTags = [];

          function onInit() {
            $ctrl.memberPrefixTranslation = Helper.getMemberPrefixTranslation();
            body.addClass('task-filter-option-modal-active');
            if (!$ctrl.isGuest) {
              $ctrl.currentUser = $rootScope.identity;
              $ctrl.orgUsersWithoutCurrentUser = _.filter($ctrl.resolve.usersInOrg, function (user) {
                return user.id !== $ctrl.currentUser.id;
              });
              resetBlueprintParameters();
              getActiveBlueprints();
              resetProcessParameters();
              getActiveProcesses();
            }
            $timeout(function () {
              generateFilterObject(true);
            }, 100);
          }

          function onChanges() { }

          function onDestroy() {
            body.removeClass('task-filter-option-modal-active');
            onFilterTagsHandler();
            onRemoveFilterTagsHandler();
          }

          function generateFilterObject(init) {
            if ($ctrl.resolve.tasksFiltersConfig.status) {
              $('#taskStatusFilter').collapse('show');
            }
            if (_.get($ctrl.resolve.tasksFiltersConfig, 'group')) {
              $ctrl.assigneeObjgroup = _.find($ctrl.resolve.orgGroups, function (user) {
                return user.id === $ctrl.resolve.tasksFiltersConfig.group;
              });
            }
            if (_.get($ctrl.resolve.tasksFiltersConfig, 'role')) {
              $ctrl.assigneeObjrole = _.find($ctrl.resolve.orgRoles, function (user) {
                return user.id === $ctrl.resolve.tasksFiltersConfig.role;
              });
              $ctrl.assigneeObjrole.type = 'role';
            }
            if ($ctrl.resolve.tasksFiltersConfig.assignee) {
              $ctrl.assigneeObj = _.find(_.concat($ctrl.orgUsersWithoutCurrentUser, $ctrl.resolve.orgGuests), function (user) {
                return user.id === Number($ctrl.resolve.tasksFiltersConfig.assignee) || user.email === $ctrl.resolve.tasksFiltersConfig.assignee;
              });
              if (init && ($ctrl.assigneeObj || $ctrl.assigneeObjgroup || $ctrl.assigneeObjrole || $ctrl.resolve.tasksFiltersConfig.assignee === $ctrl.currentUser.id || $ctrl.resolve.tasksFiltersConfig.assignee === 'mine')) {
                $('#taskAssigneeFilter').collapse('show');
              }
            }
            if ($ctrl.resolve.tasksFiltersConfig.states) {
              var states = $ctrl.resolve.tasksFiltersConfig.states.split(',');
              _.map($ctrl.taskStates, function (state) {
                state.isSelected = _.includes(states, state.value);
                return state;
              });
              if (init && states.length) {
                $('#taskStateFilter').collapse('show');
              }
            }
            if ($ctrl.resolve.tasksFiltersConfig.tags) {
              var tags = $ctrl.resolve.tasksFiltersConfig.tags.split(',');
              $ctrl.selectedTags = _.filter($ctrl.resolve.allTags, function (state) {
                return _.includes(tags, state.id);
              });
              if (init && tags.length) {
                $('#taskTagFilter').collapse('show');
              }
            }
            $ctrl.folderObj = {};
            if ($ctrl.resolve.tasksFiltersConfig.folder) {
              $ctrl.folderObj = FolderService.validateAndReturnFolderObject($ctrl.resolve.tasksFiltersConfig.folder, $ctrl.resolve.foldersList);
              if (init && !Helper.isObjectEmpty($ctrl.folderObj)) {
                $('#taskFolderFilter').collapse('show');
              }
            }
            $ctrl.blueprintObj = {};
            if ($ctrl.resolve.tasksFiltersConfig.blueprint) {
              ProcessService.get({
                id: $ctrl.resolve.tasksFiltersConfig.blueprint,
                skipNotFound: true
              }).then(function (res) {
                $ctrl.blueprintObj = res.data;
                $ctrl.blueprintObj.displayTitle = $ctrl.blueprintObj.title;
                if (init && !Helper.isObjectEmpty($ctrl.blueprintObj)) {
                  $('#taskBlueprintFilter').collapse('show');
                }
              });
            }
            $ctrl.processObj = {};
            if ($ctrl.resolve.tasksFiltersConfig.process) {
              RunsService.get({ id: $ctrl.resolve.tasksFiltersConfig.process, skipNotFound: true })
                .then(function (res) {
                  $ctrl.processObj = res.data;
                  if (init && !Helper.isObjectEmpty($ctrl.processObj)) {
                    $('#taskProcessFilter').collapse('show');
                  }
                });
            }
          }

          function onStatusChange(status) {
            $ctrl.resolve.tasksFiltersConfig.status = status;
            if (status === 'to-do' || status === 'completed') {
              $ctrl.resolve.tasksFiltersConfig.sortBy = 'newest';
            } else if (status === 'all') {
              $ctrl.resolve.tasksFiltersConfig.status = 'all';
            }
          }

          function onAssigneeChange(type) {
            if (type === 'by-me') {
              resetFilter();
              $ctrl.resolve.tasksFiltersConfig.assignee = 'by-me';
            } else if (type === 'mine') {
              resetFilter();
              $ctrl.resolve.tasksFiltersConfig.assignee = $ctrl.currentUser.id;
            } else if (type === 'at-mentioned') {
              resetFilter();
              $ctrl.resolve.tasksFiltersConfig.assignee = 'at-mentioned';
            } else if (type === 'member') {
              resetFilter();
              $ctrl.resolve.tasksFiltersConfig.assignee = $ctrl.assigneeObj.id;
            } else if (type === 'anyone') {
              resetFilter();
              $ctrl.resolve.tasksFiltersConfig.assignee = 'anyone';
            } else {
              if (type === 'group') {
                resetFilter('group');
                $ctrl.resolve.tasksFiltersConfig.assignee = 'anyone';
                $ctrl.resolve.tasksFiltersConfig.group = $ctrl.assigneeObjgroup.id;
              } else if (type === 'role') {
                resetFilter('role');
                $ctrl.resolve.tasksFiltersConfig.assignee = 'anyone';
                $ctrl.assigneeObjrole.type = 'role';
                $ctrl.resolve.tasksFiltersConfig.role = $ctrl.assigneeObjrole.id;
              } else {
                resetFilter();
                $ctrl.resolve.tasksFiltersConfig.assignee = $ctrl.assigneeObj.email;
              }
            }
          }
          
          function resetFilter(temp) {
            switch (temp) {
              case 'group':
                $ctrl.assigneeObj = void 0;
                $ctrl.assigneeObjrole = void 0;
                $ctrl.resolve.tasksFiltersConfig.role = '';
                break;
              
              case 'role':
                $ctrl.assigneeObj = void 0;
                $ctrl.assigneeObjgroup = void 0;
                $ctrl.resolve.tasksFiltersConfig.group = '';
                break;
              
              default:
                $ctrl.assigneeObjgroup = void 0;
                $ctrl.assigneeObjrole = void 0;
                $ctrl.resolve.tasksFiltersConfig.group = '';
                $ctrl.resolve.tasksFiltersConfig.role = '';
            }
          }

          function onTaskStatesChange(taskStateItem) {
            if (taskStateItem.value === 'active_visible') {
              _.map($ctrl.taskStates, function (state) {
                if (state.value !== 'active_visible') {
                  state.isSelected = false;
                }
              });
            } else {
              var activeVisibleState = _.find($ctrl.taskStates, { value: 'active_visible' });
              activeVisibleState.isSelected = false;
            }
            var states, selectedStates = _.filter($ctrl.taskStates, { isSelected: true });
            if (selectedStates.length) {
              for (var i = 0; i < selectedStates.length; i++) {
                states += (!states) ? selectedStates[i].value : ',' + selectedStates[i].value;
              }
            } else {
              var activeVisibleState = _.find($ctrl.taskStates, { value: 'active_visible' });
              activeVisibleState.isSelected = true;
            }
            $ctrl.resolve.tasksFiltersConfig.states = _.map(
              selectedStates,
              function (state) {
                return state.value;
              }
            ).join(',') || 'active_visible';
          }

          function onBlueprintChange(blueprintObj) {
            $ctrl.resolve.tasksFiltersConfig.blueprint = blueprintObj.id;
          }

          function onProcessChange(processObj) {
            $ctrl.resolve.tasksFiltersConfig.process = processObj.id;
          }
          function onTagsChange() {
            $ctrl.resolve.tasksFiltersConfig.tags = _.map(
              $ctrl.selectedTags,
              function (tag) {
                return tag.id;
              }
            ).join(',');
          }

          function resetBlueprintParameters() {
            $ctrl.allActiveBlueprints = [];
            blueprintParams = {
              page: 1,
              per_page: 10,
              q: '',
              with: 'linked_tasks',
              allDataLoaded: false,
              skipNotFound: true
            };
          }

          function resetProcessParameters() {
            $ctrl.allActiveProcesses = [];
            processParams = {
              page: 1,
              per_page: 10,
              q: '',
              current_page: 1,
              allDataLoaded: false,
              skipNotFound: true
            };
          }

          function getActiveBlueprints(args) {
            var defer = $q.defer();
            if (_.get(args, 'query') !== blueprintParams.q) {
              resetBlueprintParameters();
            }
            blueprintParams.q = _.get(args, 'query');
            RunsService.getActiveBlueprints(blueprintParams)
              .then(function (response) {
                _.forEach(response.data, function (blueprint) {
                  blueprint.displayTitle = blueprint.title + ' (' + _.get(blueprint, 'linked_tasks.data').length + ')';
                });
                response.meta.pagination.current_page < response.meta.pagination.total_pages ? blueprintParams.page++ : blueprintParams.allDataLoaded = true;
                $ctrl.allActiveBlueprints = _.concat($ctrl.allActiveBlueprints, response.data);
                defer.resolve({ allItemLoaded: blueprintParams.allDataLoaded });
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          function getActiveProcesses(args) {
            var defer = $q.defer();
            if (_.get(args, 'query') !== processParams.q) {
              resetProcessParameters();
            }
            processParams.q = _.get(args, 'query');
            if (processParams.q) {
              var requestParams = {
                org: _.get($rootScope.identity, 'default_organization.id'),
                on: 'process',
                search: processParams.q,
                per_page: processParams.per_page,
                process: processParams.current_page,
                tags: true
              };
              processParams.processLoading = true;
              OrganizationsService.globalSearch(requestParams)
                .then(function (response) {
                  response.process.current_page < response.process.last_page ? processParams.current_page++ : processParams.allDataLoaded = true;
                  $ctrl.allActiveProcesses = _.uniqBy(_.concat($ctrl.allActiveProcesses, response.process.data), 'id');
                  processParams.total = response.process.total;
                  defer.resolve({ allItemLoaded: processParams.allDataLoaded });
                }, function () {
                  defer.reject(err);
                });
            } else {
              RunRepository.getActiveProcesses(processParams)
                .then(function (res) {
                  res.meta.pagination.current_page < res.meta.pagination.total_pages ? processParams.page++ : processParams.allDataLoaded = true;
                  $ctrl.allActiveProcesses = _.concat($ctrl.allActiveProcesses, res.data);
                  defer.resolve({ allItemLoaded: processParams.allDataLoaded });
                }, function () {
                  defer.reject(err);
                });
            }
            return defer.promise;
          }

          function onCreateFolder(folder, parentFolder) {
            var modifyTaskFolderModal = FolderService.openModifyTaskFolderModal(folder, parentFolder, $ctrl.resolve.foldersList);
            modifyTaskFolderModal.result.then(function (response) {
              response.data.folder_name = response.data.name;
              response.data.folder_id = response.data.id;
              if (parentFolder && parentFolder.id) {
                parentFolder.children.data.push(response.data);
                parentFolder.children.data = _.sortBy(parentFolder.children.data, 'name');
              } else {
                $ctrl.resolve.foldersList.push(response.data);
                $ctrl.resolve.foldersList = _.sortBy($ctrl.resolve.foldersList, 'name');
              }
            });
          }

          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          function onFolderChange(folderObj) {
            if (folderObj) {
              if (folderObj.id && ($ctrl.folderObj.id === folderObj.id)) {
                folderObj.isExpanded = !folderObj.isExpanded;
              }
              if ($ctrl.folderObj.id === folderObj.id) {
                return;
              }
              $ctrl.folderObj = folderObj;
              if (!$ctrl.folderObj.id) {
                FolderService.collapseAllChildFolders($ctrl.resolve.foldersList);
              }
              $ctrl.resolve.tasksFiltersConfig.folder = $ctrl.folderObj.id;
            }
          }

          function getTasksAllFolders() {
            var defer = $q.defer();
            FolderService.getTaskOrRunFolders({
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            }).then(function (res) {
              defer.resolve(res);
            });
            return defer.promise;
          }

          function onEditFolder(folder, parentFolder) {
            var modifyTaskFolderModal = FolderService.openModifyTaskFolderModal(folder, parentFolder, $ctrl.resolve.foldersList);
            modifyTaskFolderModal.result.then(function (response) {
              if (response.resultType === 'update_folder_name_changed') {
                angular.extend(folder, response.response.data);
              } else if (response.resultType === 'update_parent_folder_changed') {
                getTasksAllFolders()
                  .then(function (res) {
                    $ctrl.resolve.foldersList = res.data || [];
                    $ctrl.folderObj = FolderService.validateAndReturnFolderObject($ctrl.resolve.tasksFiltersConfig.folder, $ctrl.resolve.foldersList);
                  });
              } else if (response.resultType === 'folder_deleted') {
                var index;
                if (parentFolder && parentFolder.id) {
                  index = _.findIndex(_.get(parentFolder, 'children.data', []), { 'id': response.folder.id });
                  if (index > -1) {
                    parentFolder.children.data.splice(index, 1);
                  }
                } else {
                  index = _.findIndex($ctrl.resolve.foldersList, { 'id': response.folder.id });
                  if (index > -1) {
                    $ctrl.resolve.foldersList.splice(index, 1);
                  }
                }

                if ((folder.id === $ctrl.folderObj.id) || (folder.isExpanded)) {
                  getTasksAllFolders()
                    .then(function (res) {
                      $ctrl.resolve.foldersList = res.data || [];
                      $ctrl.folderObj = FolderService.validateAndReturnFolderObject($ctrl.resolve.tasksFiltersConfig.folder, $ctrl.resolve.foldersList);
                    });
                }
              }
            });
          }

          function closeModal() {
            $ctrl.close();
          }

          onFilterTagsHandler = $rootScope.$on('FILTER_TASK:TAGS', function (e, data) {
            $ctrl.selectedTags.push(data)
            onTagsChange();
          });

          onRemoveFilterTagsHandler = $rootScope.$on('REMOVE_FILTER_TASK:TAGS', function (e, data) {
            $ctrl.resolve.tasksFiltersConfig.tags = _.filter($ctrl.resolve.tasksFiltersConfig.tags.split(','), function (tag) {
              return data.id !== tag;
            }).join(',');
          });
        }
    });
})();

(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('activate', {
      parent: 'account',
      url: '/activate?code&org_id',
      data: {
        authorities: [],
        pageTitle: 'activate.title'
      },
      views: {
        '': {
          component: 'activate'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('activate');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.internationalDialCode
 * @element ANY
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 **/
(function () {
  "use strict";
  angular.module("tallyfy").constant('dialCodeConfig', {
    allowExtensions: false,
    autoFormat: true,
    autoHideDialCode: true,
    autoPlaceholder: true,
    initialCountry: "",
    geoIpLookup: null,
    nationalMode: true,
    numberType: "MOBILE",
    onlyCountries: void 0,
    preferredCountries: [],
    skipUtilScriptDownload: false
  }).directive('internationalDialCode', [
    '$timeout', 'dialCodeConfig', '_', '$filter', function ($timeout, dialCodeConfig, _, $filter) {
      return {
        restrict: 'A',
        scope: {
          phoneModel: '=?',
          ngModel: '=',
          onUpdateModel: '&'
        },
        link: function (scope, element) {
          scope.currentCode = "";
          var onCountryChanged = false;

          function formatDialCode(code) {
            return _.startsWith(code, '+') ? code : ('+' + code);
          }

          var options = angular.copy(dialCodeConfig);
          var countryData = $.fn.intlTelInput.getCountryData();
          element.intlTelInput(options);
          if (dialCodeConfig.defaultCountry) {
            var defaultCountry = _.find(countryData, function (obj) {
              return obj.iso2 === dialCodeConfig.defaultCountry;
            });
            if (defaultCountry) {
              $timeout(function () {
                element.intlTelInput("setCountry", defaultCountry.iso2);
                element.text(defaultCountry.name);
                scope.phoneModel = formatDialCode(defaultCountry.dialCode);
                scope.currentCode = formatDialCode(defaultCountry.dialCode);
                scope.onUpdateModel({
                  phone: scope.phoneModel,
                  diaCode: scope.currentCode
                });
                updateModel(scope.currentCode);
                onCountryChanged = true;
              });
            }
          }
          var deRegisterPhoneWatcher = scope.$watch('phoneModel', function (newValue) {
            if (!newValue) {
              return;
            }
            if (newValue.length < scope.currentCode.length) {
              scope.phoneModel = scope.currentCode;
            }
            if (!onCountryChanged) {
              var index = _.findIndex(countryData, function (item) {
                var trimValue = _.trimStart(newValue, '+');
                return _.startsWith(trimValue, item.dialCode);
              });
              if (index >= 0) {
                scope.currentCode = formatDialCode(countryData[index].dialCode);
                element.intlTelInput("setCountry", countryData[index].iso2);
                updateModel(scope.currentCode);
              }
            }
            onCountryChanged = false;
            scope.onUpdateModel({
              phone: scope.phoneModel,
              diaCode: scope.currentCode
            });
          });

          element.on('countrychange', function (e, countryData) {

            $timeout(function () {
              onCountryChanged = true;
              element.text(_.get(countryData, "name"));
              var code = countryData.dialCode ? countryData.dialCode : '';
              if (code) {
                if (scope.phoneModel) {
                  if (_.startsWith(scope.phoneModel, '+')) {
                    var replace = scope.currentCode, replacement = '+' + code;
                    scope.phoneModel = _.replace(scope.phoneModel, replace, replacement);
                  } else {
                    scope.phoneModel = '+' + scope.phoneModel;
                  }
                } else {
                  scope.phoneModel = formatDialCode(code);
                }
                scope.currentCode = formatDialCode(code);
                updateModel(scope.currentCode);
              } else {
                element.text($filter('translate')('signup.form.fields.countryCode.placeholder'));
              }
              scope.onUpdateModel({
                phone: scope.phoneModel,
                diaCode: scope.currentCode
              });
            }, 0);
          });

          function updateModel(modelValue) {
            scope.ngModel = modelValue;
          }

          element.on('click', function () {
            $timeout(function () {
              angular.element(".selected-flag").trigger("click");
            })
          });

          element.on('$destroy', function () {
            element.intlTelInput('destroy');
            deRegisterPhoneWatcher();
          });
        }
      };
    }
  ]);

})();
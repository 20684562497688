/**
 * @ngdoc Component
 * @name tallyfy.account.component.favorites
 * @module tallyfy.account
 *
 * @description
 * A component to manage user's favorites
 *
 * @author Kiran Sompura( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('favorites', {
      templateUrl: 'app/modules/account/favorites/favorites.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, FavoriteService, $q, $timeout, USER_STATE, $state, Growl, $filter) {
          var $ctrl = this,
            searchCallbackHandler,
            favoriteList = [],
            growl = new Growl();

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
         
          /**
           * public methods
           */
          $ctrl.setTargetOption = setTargetOption;
          $ctrl.setFrequencyOption = setFrequencyOption;
          $ctrl.changeWatching = changeWatching;
          $ctrl.searchToFavoriteList = searchToFavoriteList;
          $ctrl.removeFavorite = removeFavorite;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isGuest = $rootScope.userState === USER_STATE.GUEST;
            $ctrl.targetOptionList = FavoriteService.getTargetOption();
            $ctrl.frequencyOptionList = FavoriteService.getFrequencyOption();
            $ctrl.watchingOptionList = FavoriteService.getWatchingOption();
            getWatchList();
          }
          
          function getWatchList() {
            $ctrl.isLoading = true;
            if ($rootScope.userState === USER_STATE.GUEST) {
              FavoriteService.getGuest({
                org: $rootScope.identity.guest.organization.id,
                with: 'watching',
                guest_code: $rootScope.identity.guest.guest_code
              }).then(function(res) {
                  _.forEach(res.data, function (fav) {
                    var type = _.split(fav.object_type, '\\');
                    angular.extend(fav, {
                      watchType: _.toLower(_.last(type)),
                      selectedFrequency: _.find($ctrl.frequencyOptionList, { value: fav.frequency }),
                      selectedTarget: _.find($ctrl.targetOptionList, { value: fav.notification_type })
                    });
                  });
                  $ctrl.isLoading = false;
                  $ctrl.favoriteList = favoriteList = _.filter(res.data || [], function(e){ return _.get(e, 'watching'); });
              });
            } else {
              var watching = _.map($ctrl.watchingOptionList, 'value').join(',');
              FavoriteService.getFavorite({
                with: 'watching',
                watching: watching,
                without_pagination: true
              }).then( function(res) {
                _.forEach(res.data, function (fav) {
                  var type = _.split(fav.object_type, '\\');
                  angular.extend(fav, {
                    watchType: _.toLower(_.last(type)),
                    selectedFrequency: _.find($ctrl.frequencyOptionList, { value: fav.frequency }),
                    selectedTarget: _.find($ctrl.targetOptionList, { value: fav.notification_type })
                  });
                });
                $ctrl.isLoading = false;
                $ctrl.favoriteList = favoriteList = _.filter(res.data || [], function(e){ return _.get(e, 'watching'); });
              });
            }
          }

          function removeFavorite(watchId) {
            if (watchId) {
             if ($rootScope.userState === USER_STATE.GUEST) {
               FavoriteService.removeGuestFavorite( _.get($rootScope,'identity.guest.guest_code'), watchId);
             } else {                
               FavoriteService.removeFavorite(watchId);
             }
             _.remove($ctrl.favoriteList, { id: watchId });
             $timeout(function () {
               growl.success($filter('translate')('runs.dashboard.runAction.un-starred'), {
                 referenceId: 'global',
                 disableIcons: true,
                 disableCloseButton: true
               });
             }, 100);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function setTargetOption(favorite) {
            if (_.get(favorite, 'selectedTarget.value')) {
              _.set(favorite, 'notification_type', favorite.selectedTarget.value);
            }
            if ($rootScope.userState === USER_STATE.GUEST) {
              FavoriteService.updateGuestFavorite($rootScope.identity.guest.guest_code, favorite);
            } else {
              if (!_.get(favorite, 'user_id')) {
                _.set(favorite, 'user_id', $rootScope.identity.id);
              }
              FavoriteService.updateFavorite(favorite);
            }
          }

          function setFrequencyOption(favorite) {
            if (_.get(favorite, 'selectedFrequency.value')) {
              _.set(favorite, 'frequency', favorite.selectedFrequency.value);
            }
            if ($rootScope.userState === USER_STATE.GUEST) {
              FavoriteService.updateGuestFavorite($rootScope.identity.guest.guest_code, favorite);
            } else {
              if (!_.get(favorite, 'user_id')) {
                _.set(favorite, 'user_id', $rootScope.identity.id);
              }
              FavoriteService.updateFavorite(favorite);
            }
          }
          
          function searchToFavoriteList($event) {
            $event.stopPropagation();
            $ctrl.isLoading = true;
            searchCallbackHandler = $timeout(function () {
              if (!!$ctrl.searchFavorite) {
                $ctrl.favoriteList = _.filter(favoriteList, function(f) {
                  var text = _.get(f, 'watching.data.name') || _.get(f, 'watching.data.title');
                  if(text) {
                    return text.match(new RegExp("(" + $ctrl.searchFavorite + ")", "i"));
                  } else {
                    return false;
                  }
                });
              } else {
                $ctrl.favoriteList = favoriteList;
              }
              $ctrl.isLoading = false;
              $timeout.cancel(searchCallbackHandler);
            }, 2000);
          }

          function getWatcherData(args) {
            if ($rootScope.userState === USER_STATE.GUEST) {
              return FavoriteService.getGuest({
                org: $rootScope.identity.guest.organization.id,
                guest_code: $rootScope.identity.guest.guest_code,
                watching: _.map(args, 'value').join(','),
                with: 'watching'
              });
            } else {
              return FavoriteService.getFavorite({ 
                without_pagination: true,
                with: 'watching',
                watching: _.map(args, 'value').join(',')
              });
            }  
          }

          function changeWatching(watch) {
            var args = _.filter($ctrl.watchingOptionList, function(w) { return !!w.checked; });
            if (args.length > 0) {
              favoriteList = [];
              $ctrl.isLoading = true;
              getWatcherData(args).then(function(res) {
                if (res && res.data && (res.data.length > 0)) {
                  _.forEach(res.data, function (fav) {
                    var type = _.split(fav.object_type, '\\');
                    _.set(fav, 'watchType', _.toLower(_.last(type)));
                    _.set(fav, 'selectedFrequency', _.find($ctrl.frequencyOptionList, { value: fav.frequency }));
                    _.set(fav, 'selectedTarget', _.find($ctrl.targetOptionList, { value: fav.notification_type }));
                    favoriteList.push(fav);
                  });
                }
                $ctrl.isLoading = false;
                $ctrl.favoriteList = _.filter(favoriteList || [], function(e){ return _.get(e, 'watching'); });
              });
            } else {
              _.set(watch, 'checked', true);
            }
          }
        }
    });
})();
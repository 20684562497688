/**
 * @ngdoc Component
 * @name tallyfy.selectBox
 * @module tallyfy
 *
 * @description
 * Advance select box component
 *
 * @author Adi Winata (adheegm@gmail.com)
 */
(function () {
  'use strict';

  angular.module('tallyfy')
    .component('selectBox', {
      templateUrl: 'app/components/select-box/select-box.component.html',
      bindings: {
        idProperty: '<',
        displayProperty: '<',
        displayValueProperty: '<',
        canSearch: '<?',
        showClearButton: '<',
        data: '<',
        ngModel: '=',
        isOpen: '=?',
        isBusy: '=?',
        onItemSelected: '&?',
        onClearSelection: '&?',
        qScrollHandler: '&?',
        onDropdownOpenHandler: '&?',
        emptyPlaceholder: '<',
        alwaysOnTop: '<?',
        emptyValue: '<?',
        isDisabled: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, $element, $timeout) {
          var $ctrl = this,
            body = angular.element('body'),
            scrollContainer;

          $ctrl.allItemLoaded = false;
          $ctrl.isBusy = false;

          // angular life cycle hook method
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public method
          $ctrl.onFilterActive = onFilterActive;
          $ctrl.onDataFiltering = onDataFiltering;
          $ctrl.onSelectData = onSelectData;
          $ctrl.onClearItemSelection = onClearItemSelection;
          $ctrl.onDropdownOpen = onDropdownOpen;
          $ctrl.getWidth = getWidth;

          function onInit() {
            $ctrl.parentContainer = $ctrl.alwaysOnTop ? '.select-box-container' : void 0;
            dropdownCallback(false);
            $ctrl.displayedData = [];
            $ctrl.searchQuery = '';
            if (typeof $ctrl.qScrollHandler === 'function') {
              scrollContainer = $element.find('.data-list-container');
              scrollContainer.on('scroll', scrollCallback);
            }
            body.on('click', bodyClickHandler);
          }

          function onChanges(changes) {
            if (changes.data) {
              onDataFiltering();
            }
          }

          function onDestroy() {
            if (scrollContainer) {
              scrollContainer.off('scroll', scrollCallback);
            }
            body.off('click', bodyClickHandler);
          }

          /**
           * @ngdoc method
           * @name scrollCallback
           * @param {*} e 
           * 
           * @description
           * scroll callback handler
           * must be returning 'allItemLoaded' to prevent loading all time
           */
          function scrollCallback(e) {
            e.stopImmediatePropagation();
            if ((scrollContainer[0].scrollHeight - scrollContainer.scrollTop() < scrollContainer.height() + 80) && !$ctrl.isBusy && !$ctrl.allItemLoaded) {
              fetchData();
            }
          }

          function fetchData() {
            if (typeof $ctrl.qScrollHandler === 'function') {
              $ctrl.isBusy = true;
              $ctrl.qScrollHandler({
                args: {
                  query: $ctrl.searchQuery
                }
              }).then(function (res) {
                $ctrl.isBusy = false;
                $ctrl.allItemLoaded = res.allItemLoaded;
              }, function (err) {
                $ctrl.isBusy = false;
              });
            }
          }

          function onFilterActive(e) {
            e.preventDefault();
            if (!$ctrl.isDisabled) {
              $ctrl.isOpen = !$ctrl.isOpen;
              dropdownCallback($ctrl.isOpen); 
            }
          }

          /**
           * @ngdoc method
           * @name onDataFiltering
           * 
           * @description
           * filter displayed data on select box
           */
          function onDataFiltering(queryChanged) {
            if (queryChanged) {
              if (typeof $ctrl.qScrollHandler === 'function') {
                $ctrl.isBusy = true;
                $ctrl.qScrollHandler({
                  args: {
                    query: $ctrl.searchQuery,
                    init: true
                  }
                }).then(function (res) {
                  $ctrl.isBusy = false;
                  $ctrl.allItemLoaded = res.allItemLoaded;
                }, function (err) {
                  $ctrl.isBusy = false;
                });
              } else {
                $scope.$applyAsync(function () {
                  $ctrl.displayedData = _.filter($ctrl.data || [], function (value) {
                    return _.includes(value[$ctrl.displayProperty].toLowerCase(), $ctrl.searchQuery.toLowerCase()) > 0;
                  });
                });
              }
            } else {
              $scope.$applyAsync(function () {
                $ctrl.displayedData = _.filter($ctrl.data || [], function (value) {
                  return _.includes(value[$ctrl.displayProperty].toLowerCase(), $ctrl.searchQuery.toLowerCase()) > 0;
                });
              });
            }
          }

          /**
           * @ngdoc method
           * @name onSelectData
           * @param {*} data 
           * 
           * @description
           * on item selected method
           */
          function onSelectData(e, data) {
            if (data && data.disableEvent) {
              e.preventDefault();
            } else {
              $ctrl.ngModel = data;
              dropdownCallback(false);
              if (typeof $ctrl.onItemSelected === 'function') {
                $ctrl.onItemSelected({ item: data });
              }
            }
          }

          /**
           * @ngdoc method
           * @name onClearItemSelection
           * 
           * @description
           * on selection clear action method
           */
          function onClearItemSelection() {
            dropdownCallback(false);
            $ctrl.ngModel = void 0;
            if (typeof $ctrl.onClearSelection === 'function') {
              $ctrl.onClearSelection();
            }
          }

          /**
           * @ngdoc method
           * @name onDropdownOpen
           * @param {*} e 
           * 
           * @description
           * on select box open/close method / caret clicked
           */
          function onDropdownOpen(e) {
            e.stopImmediatePropagation();
            if (!$ctrl.isDisabled) {
              if (!(!$ctrl.isOpen && $ctrl.isBusy)) {
                dropdownCallback(!$ctrl.isOpen);
              }
            }
          }

          function dropdownCallback(isOpen) {
            $ctrl.isOpen = isOpen;
            $timeout(function () {
              if (typeof $ctrl.onDropdownOpenHandler === 'function') {
                if ($ctrl.isOpen) {
                  if (scrollContainer) {
                    if (scrollContainer.get(0).scrollHeight <= scrollContainer.get(0).clientHeight && !$ctrl.allItemLoaded) {
                      fetchData();
                    }
                  } else {
                    fetchData();
                  }
                }
                $ctrl.onDropdownOpenHandler();
              } else {
                if ($ctrl.isOpen) {
                  if (scrollContainer) {
                    if (scrollContainer.get(0).scrollHeight <= scrollContainer.get(0).clientHeight && !$ctrl.allItemLoaded) {
                      fetchData();
                    }
                  } else {
                    fetchData();
                  }
                }
              }
            }, 10).then(function () {
              if (!$ctrl.initialized) {
                $ctrl.initialized = true;
              }
            });
          }

          function bodyClickHandler(e) {
            var clickedElement = angular.element(e.target);
            var isInElement = clickedElement.closest('.select-box').length;
            if (!isInElement) {
              $ctrl.isOpen = false;
              $scope.$apply();
            }
          }

          function getWidth() {
            return $ctrl.alwaysOnTop ? $element[0].getBoundingClientRect().width + 'px' : 'auto';
          }
        }
    });
})();
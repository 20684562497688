/**
 * @ngdoc Directive
 * @name tallyfy.gridlyAnimation
 * @description 
 * Gridly is a jQuery plugin to enable dragging and dropping as well as resizing on a grid.
 * Demo link to check Gridly plugin https://www.jqueryscript.net/demo/Dynamic-Drag-Drop-Grid-Layout-Plugin-With-jQuery-Gridly/
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('gridlyAnimation', gridlyAnimation);
    /*@ngInject*/
    function gridlyAnimation($timeout, $rootScope, TFY_EVENTS, _, Growl, Helper) {
      return {
        restrict: 'A',
        scope: {
          runId: '<',
          isRunLoaded: '<',
          activeFilter: '<',
          action: '<'
        },
        link: function ($scope, $element) {
          var gbase,
            unregisterUpdatedRunsListener,
            reSizeBoxWidthListner,
            removeGridlyOnClickListener,
            unregisterUndoRunListener,
            $angularDoc = angular.element(document),
            $flexContainer = $angularDoc.find('.flex-container'),
            $gridly = $angularDoc.find('.gridly'),
            gridItemMinWidth = 250,
            spaceXBetweenEachItem = 16,
            sizeSyncTimeoutHandler;
    
            /**
             * clear/remove gridly class when dropdown/pagination item is clicked
             */
            $angularDoc.find(".process-filter filter .inline .uib-dropdown-menu div[id^='filter_'], sort-filter .sort-ascending ul li, #orgList, #paginationsElement").click(function () {
              Growl.clearAllMessages('global');
              removeGridlyClass();
              refreshGridly();
            });
            
            /**
             * Ressize box width after grigly document/HTML load
             */
            $angularDoc.ready(function () {
              resizeBoxWidth();
            });
            
            /**
             * Ressize box width after card box document/HTML load
             * @type {*|(function())}
             */
            reSizeBoxWidthListner = $rootScope.$on(TFY_EVENTS.GRIDLY.RE_SIZE_BOX_WIDTH, function(){  
              resizeBoxWidth();
            });
            
            /**
             * @ngdoc
             * @name resizeBoxWidth
             * @description It will resize box width
             */
            function resizeBoxWidth() {
              var container = $angularDoc.find('.runs-dash-grid-container'),
                containerWidth = container.width() - 32,
                numItemsPerRow = getNumItemsPerRow(containerWidth),
                boxSize = _.max([gridItemMinWidth, ((containerWidth - ((numItemsPerRow - 1)*8))/numItemsPerRow)]);
              gbase = Math.round(((containerWidth - (8 * 16)) / 16));
              if (boxSize) {
               $angularDoc.find('.flex-container .flex-div').css("width", boxSize);
              } 
            }

            function getNumItemsPerRow(containerWidth) {
             var numItems = 1;
              if (containerWidth && containerWidth < ((2 * gridItemMinWidth) + spaceXBetweenEachItem)) {
                numItems = 1;
              } else if (containerWidth >= ((2 * gridItemMinWidth) + spaceXBetweenEachItem) && containerWidth < ((3 * gridItemMinWidth) + (2 * spaceXBetweenEachItem))) {
                numItems = 2;
              } else if (containerWidth >= ((3 * gridItemMinWidth) + (2 * spaceXBetweenEachItem)) && containerWidth < ((4 * gridItemMinWidth) + (3 * spaceXBetweenEachItem))) {
                numItems = 3;
              } else if (containerWidth >= ((4 * gridItemMinWidth) + (3 * spaceXBetweenEachItem))) {
                numItems = 4;
              }
              return numItems;
            }
            
            /**
             * resize gridly box width whenever screen size is changed
             */
            angular.element(window).on('resize', function () {
              if (sizeSyncTimeoutHandler) {
                $timeout.cancel(sizeSyncTimeoutHandler);
              }
              
              sizeSyncTimeoutHandler = $timeout(function () {
                resizeBoxWidth();
              }, 500);
            });
            
            /**
             * @ngdoc
             * @name removeGridlyClass
             * @description remove gridly class from main container
             */
            function removeGridlyClass() {
              if ($flexContainer.hasClass('gridly')) {
                $flexContainer.removeClass('gridly');
              }
            }
            
            /**
             * @ngdoc
             * @name setGridlyConfig
             * @description set gridly config
             */
            function setGridlyConfig() {
              $gridly = $angularDoc.find('.gridly');
              if ($gridly.width() > 1024 ) {
                $gridly.gridly({
                  draggable: 'off',
                  columns: 16,
                  base: gbase,
                  gutter: 16
                });
              } else {
                 $gridly.gridly({
                  draggable: 'off',
                  columns: Math.floor($gridly.width() / ( 66 + 8 )),
                  base: 66,
                  gutter: 16
                });
              }
            }
            
            /**
             * Removed card when user click on archive
             */
            $element.bind('click', function (event) {
              event.preventDefault();
              event.stopPropagation();
              refreshGridly();
              if(!Helper.checkAccessAuthority(false)) {
                return;
              }
              $flexContainer.addClass('gridly');
              $gridly.css('position', 'relative');
              $('#gridlyParent').css({ "position": 'relative', "height": 'auto' });
              var boxId = '#box-' + $scope.runId;
              if (($scope.activeFilter === 'starred' && $scope.action === 'favorite') || $scope.action === 'archive') {
                angular.element(this).closest(boxId).remove();
                $gridly.gridly('layout');
              }
            });
            
            /**
             * @ngdoc
             * @name cleanGridly
             * @description It will clear gridly style
             */
            function cleanGridly() {
              var lastGridly = $gridly.clone();
              if (!_.isEmpty(lastGridly)) {
                lastGridly.removeAttr('style');
              }
            } 
            
            /**
             * @ngdoc
             * @name refreshGridly
             * @description It will refresh gridly
             */
            function refreshGridly() {
              cleanGridly();
              setGridlyConfig();
            }
          
            /**
             * event handler user undo the run
             * @type {*|(function())}
             */
            unregisterUndoRunListener = $rootScope.$on(TFY_EVENTS.GRIDLY.UNDO_ANIMATION, function(event) {
              event.preventDefault();
              event.stopPropagation();
              refreshGridly();
            });
            
            /**
             * event handler when runs are loaded on the view and initialize the gridly
             * @type {*|(function())}
             */
            unregisterUpdatedRunsListener = $rootScope.$on(TFY_EVENTS.GRIDLY.RE_INIT_ANIMATION, function ($event, data) {
              if (!_.get(data, 'isResetGridly', false)) {
                removeGridlyClass();
                if (_.get(data, 'isUpdateHeight', false)) {
                  $timeout(function () {
                    $('#gridlyParent').css({ "cursor": "default", "position": 'relative', "height": 'auto' });
                  }, 0);
                }
                return;
              }
              $angularDoc.ready(function () {
                removeGridlyClass();
                $timeout(function () {
                  $flexContainer.addClass('gridly');
                  $gridly.css('position', 'relative');
                  $('#gridlyParent').css({ "cursor": "default", "position": 'relative', "height": 'auto' });
                }, 50);
              });
            });
            
            /**
             * event handler when remove gridly class on click 
             * @type {*|(function())}
             */
            removeGridlyOnClickListener = $rootScope.$on(TFY_EVENTS.GRIDLY.REMOVE_GRIDLY_ON_CLICK, function(){ 
              Growl.clearAllMessages('global');
              removeGridlyClass();
              $timeout(function () {
                $flexContainer.addClass('gridly');
                $gridly.css('position', 'relative');
              }, 50);
            });
          
            /**
             * clear/remove all event and timeout
             */
            $scope.$on('$destroy', function () {
              unregisterUpdatedRunsListener();
              unregisterUndoRunListener();
              reSizeBoxWidthListner();
              removeGridlyOnClickListener();
              if (sizeSyncTimeoutHandler) {
                $timeout.cancel(sizeSyncTimeoutHandler);
              }
            });
            
            //end
        }
      };
    }
})();
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('DateUtils',

      /*@ngInject*/
      function (_, moment, $rootScope, DATEFORMAT) {

        var service = {
          toLocal: toLocal,
          toUTC: toUTC,
          toTimezone: toTimezone,
          toUnixTimestamp: toUnixTimestamp,
          isDifferent: isDifferent,
          getSplittedTimeForMoment: getSplittedTimeForMoment
        };

        return service;

        function toTimezone(date) {
          var timezone = !_.isUndefined(_.get($rootScope.identity, 'timezone')) ? $rootScope.identity.timezone : null;
          var localDate = null;

          if (!_.isUndefined(date) && timezone) {
            localDate = moment.tz(date, timezone);
          } else if (_.isUndefined(date) && timezone) {
            localDate = moment.tz(timezone);
          } else {
            localDate = date ? moment(date) : moment();
          }

          return {
            value: function () {
              return localDate;
            },
            getSplittedTime: function () {
              return getSplittedTimeForMoment(localDate);
            },
            getHours: function () {
              return localDate.format('HH');
            }
          };
        }

        function toLocal(date) {
          var timezone = !_.isUndefined($rootScope.identity) && !_.isUndefined($rootScope.identity.timezone) ? $rootScope.identity.timezone : null;
          if (!_.isUndefined(date) && timezone) {
            return moment.utc(date).tz(timezone);
          }
          if (!_.isUndefined(date)) {
            return moment.utc(date).local();
          }
          return moment();
        }

        function toUTC(date) {
          var timezone = !_.isUndefined($rootScope.identity.timezone) ? $rootScope.identity.timezone : null;

          if (!_.isUndefined(date) && timezone) {
            return moment.tz(date, timezone).utc();
          }

          if (date) {
            return moment(date).utc();
          }
          return moment();
        }

        function isDifferent(date1, date2) {
          return !moment(moment(date1).format()).isSame(moment(date2));
        }

        function getSplittedTimeForMoment(date) {
          return {
            day: date.format('DD'),
            month: date.format('MM'),
            year: date.format('YYYY'),
            hours: date.format('hh'),
            minutes: date.format('mm')
          };
        }

        /**
         * @name toUnixTimestamp
         * @param {*} date 
         * @returns number
         * 
         * @description
         * convert datetime to unix timestamp
         */
        function toUnixTimestamp(date) {
          var formatDate = moment(date).format(DATEFORMAT.UNIX);
          return moment(formatDate, DATEFORMAT.UNIX).valueOf();
        }
      }
    );

})();

/**
 * @ngdoc Directive
 * @name tallyfy.setDefaultRowHeight
 * @description 
 * Dynamically remove "height" for specific element
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('setDefaultRowHeight', setDefaultRowHeight);
      /*@ngInject*/
      function setDefaultRowHeight(_) {
        return {
          restrict: 'A',
          link: function ($scope, $element, $attr) {
            $element.on('click', function () {
              var rowElement = angular.element(document).find($attr.setDefaultRowHeight);
              if (rowElement[0]) {
                rowElement[0].style.height = null;
              }
            });
            
            $scope.$on('$destroy', function (e) {
              $element.unbind('click');
            });
          }
        };
      }
})();
(function() {
    'use strict';
    /**
     * @TODO
     * Refactoring
     */
    angular
        .module('tallyfy')

    /*
     set lang attribute to <html> tag
     */
    .directive('html', ['$language', function($language) {
        return {
            restrict: 'E',
            link: function(scope, element, attrs) {
              $language.getCurrent().then(function(lang){
                 attrs.$set("lang", lang); // Set the "lang" value dynamically here
              });
            }
        };
    }]);
})();

/**
 * @ngdoc Directive
 * @name tallyfy.highlightInputOnFocus
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('highlightInputOnFocus', highlightInputOnFocus);

  /*@ngInject*/
  function highlightInputOnFocus($parse, COLOR) {
    return {
      restrict: "A",
      link: function ($scope, $element, $attrs) {
        var element = $element[0];
        var labels = document.querySelectorAll("label[for='" + $attrs.id + "']");
        var config = {
          enableHighlight: true,
          highlightColor: {
            input: COLOR.base_highlight,
            label: COLOR.base_highlight
          },
          defaultElementColor: {
            input: '',
            label: ''
          }
        };

        function getComputedStyle(el, prop) {
          return (el.currentStyle) ? el.currentStyle[prop] : (window.getComputedStyle && window.getComputedStyle(el, null)) ? window.getComputedStyle(el, null).getPropertyValue(prop) : el.style[prop];
        }

        function setColor(e) {
          if (!config.enableHighlight) {
            element.style.borderColor = '';
            for (var i = 0; i < labels.length; i++) {
              labels[i].style.color = '';
            }
          } else {
            var color = e.type === 'focus' ? config.highlightColor : config.defaultElementColor;
            element.style.borderColor = color.input;
            for (var i = 0; i < labels.length; i++) {
              if (config.defaultElementColor.label) config.defaultElementColor.label = getComputedStyle(labels[i], 'color');
              labels[i].style.color = color.label;
            }
          }
        }

        function setClass() {
          $("label[for='" + $attrs.id + "']").closest('div').addClass("has-success");
        }

        function removeClass() {
          $("label[for='" + $attrs.id + "']").closest('div').removeClass("has-success");
        }

        var unbind = $scope.$watch(
          $parse($attrs.highlightInputOnFocus,
            function (value) {
              return value;
            }), function (newValue) {
              if (!newValue) {
                return;
              }
              config.enableHighlight = newValue.enableHighlight;
              config.highlightColor = (newValue.highlightColor) ? {
                input: newValue.highlightColor,
                label: newValue.highlightColor
              } : config.highlightColor;
            }, true);

        config.defaultElementColor.input = getComputedStyle(element, 'border-color');

        element.addEventListener("focus", function (e) {
          setColor(e);
          setClass();
        });
        element.addEventListener("blur", function (e) {
          setColor(e);
          removeClass();
        });

        $scope.$on('$destroy', function () {
          element.removeEventListener("focus", function (e) {
            setColor(e);
            setClass();
          });
          element.removeEventListener("blur", function (e) {
            setColor(e);
            removeClass();
          });
          unbind();
        });

      }
    }
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepAssign', {
      bindings: {
        process: '<',
        ownerList: '<',
        step: '=',
        form: '=',
        isPublic: '<',
        roles: '<?'
      },
      templateUrl: 'app/modules/steps/assign/assign.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, menuActionLinksService, Helper, TasksService, StepService, $uibModal, store, $q, $timeout, $window, MessagesService, blockUI, UsersService, $scope, Growl, $log) {
          var $ctrl = this, oldStep, invitedUsers = [], body = angular.element('body'),
            blockUI = blockUI.instances.get('assignRole'),
            roleSelectedTimeoutHandler, updateStepTimeoutHandler,
            growl = new Growl(),
            windowElem = angular.element($window);

          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.specificMembersSmallerLimit = 0;
          $ctrl.selectedStepOwners = [];
          $ctrl.orgGuests = [];

          /**
           * public methods
           */
          $ctrl.loadOwner = loadOwner;
          $ctrl.openInviteUserModel = openInviteUserModel;
          $ctrl.toggleStepOwner = toggleStepOwner;
          $ctrl.toggleGuestOwner = toggleGuestOwner;
          $ctrl.toggleSpecificSelection = toggleSpecificSelection;
          $ctrl.defaultAvatar = TasksService.setDefaultAvatar;
          $ctrl.defaultAvatarText = TasksService.setDefaultAvatarText;
          $ctrl.onAssigneeRemoved = onAssigneeRemoved;
          $ctrl.onAssigneeAdded = onAssigneeAdded;
          $ctrl.updateStep = updateStep;
          $ctrl.onDiscard = onDiscard;
          $ctrl.onAssigneeAdding = onAssigneeAdding;
          $ctrl.toggleIsAssigneeLimited = toggleIsAssigneeLimited;
          $ctrl.onMaxAssignableInputBlur = onMaxAssignableInputBlur;
          $ctrl.filters = MessagesService.getCommentFilters();
          $ctrl.openFilterMenu = openFilterMenu;
          $ctrl.selectRoles = selectRoles;
          $ctrl.setSelectedElement = setSelectedElement;
          $ctrl.setNoDataStylesForKendo = setNoDataStylesForKendo;
          $ctrl.toggleCreateGroupModal = toggleCreateGroupModal;

          //Attach resize handler
          windowElem.on('resize', onWindowResize);

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.memberPrefixTranslation = Helper.getMemberPrefixTranslation();
            _.forEach($ctrl.roles || [], function (role) {
              role.selected = !!_.find(_.get($ctrl.step, 'roles.data'), { org_role_id: role.id });
            });
            $ctrl.stepRoles = $ctrl.roles;
            if (!$ctrl.isPublic) {
              $q.all([
                store.getUsers(),
                store.getGroups(),
                store.getGuests()
              ]).then(function (response) {
                $ctrl.orgUsers = UsersService.getBilledUsers(response[0]);
                $ctrl.orgGroups = response[1];
                $ctrl.orgGuests = response[2];
                invitedUsers = _.filter(response[0], { status: 'invited' }) || [];
                _.forEach($ctrl.orgGuests, function(guestItem) {
                  guestItem.id = guestItem.email;
                  guestItem.text = guestItem.email;
                  guestItem.type = 'guest';
                });
                prepareStep();
                if ($ctrl.ownerList && ($ctrl.ownerList.length > 1 && $ctrl.ownerList.length <= $ctrl.specificMembersSmallerLimit)) {
                  setSpecificCheckboxBinding();
                }
                oldStep = angular.copy($ctrl.step);
                body.on('click', cleanUpData);
              }, function () {
              });
            }
            $ctrl.isAssigneeLimited = _.get($ctrl.step, 'max_assignable', 0) > 0;
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
            $ctrl.selectedRoles = _.filter($ctrl.stepRoles, function (role) {
              return !!_.find(_.get($ctrl.step, 'roles.data', []), { org_role_id: role.id });
            });
            $ctrl.allowManageGroups = _.get($rootScope, 'identity.default_organization.allow_manage_groups', false);
            onWindowResize();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            $timeout(function () {
              $ctrl.selectOptions = getSelectBoxOptions();
            }, 1000);
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            setOwnerType();
            body.off('click', cleanUpData);
            if (typeof roleSelectedTimeoutHandler === 'function') {
              roleSelectedTimeoutHandler();
            }
            if (typeof updateStepTimeoutHandler === 'function') {
              updateStepTimeoutHandler();
            }
            windowElem.off('resize', onWindowResize);
          }

          //Set no data styles for kendo multiselect
          function setNoDataStylesForKendo () {
            var selector = 'select-' + $ctrl.step.id + '-list', element = document.getElementById(selector).getElementsByClassName('k-nodata')[0];
            if (element) {
              var child = element.childNodes[0];
              child.style.display = 'flex';
            }
          }

          function selectRoles(role) {
            if ($ctrl.saving) {
              return;
            }
            var widget = $ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect');
            $ctrl.saving = true;
            widget.enable(false);
            if (!role.selected && _.get($ctrl.step, 'roles.data') && $ctrl.step.roles.data.length > 0) {
              if (roleSelectedTimeoutHandler) {
                $timeout.cancel(roleSelectedTimeoutHandler);
              }
              roleSelectedTimeoutHandler = $timeout(function () {
                var index = _.findIndex($ctrl.step.roles.data, { org_role_id: role.id });
                if (index > -1) {
                  StepService.unlinkRoleToStep({ id: _.get($ctrl.step, 'roles.data', [])[index]['id'] }).then(function () {
                    $ctrl.step.roles.data.splice(index, 1);
                    $ctrl.saving = false;
                    widget.enable(true);
                    Helper.showChangesSavedGrowl();
                  }, function () {
                    $ctrl.saving = false;
                    widget.enable(true);
                  });
                } else {
                  $ctrl.saving = false;
                  widget.enable(true);
                  Helper.showChangesSavedGrowl();
                }
              }, 100);
            } else {
              StepService.linkRoleToStep({ id: role.id, step_id: _.get($ctrl.step, 'id') }).then(function (res) {
                if (Helper.isObjectEmpty($ctrl.step.roles)) {
                  $ctrl.step.roles = {
                    data: []
                  };
                }
                $ctrl.step.roles.data.push(res.data);
                $ctrl.saving = false;
                widget.enable(true);
                Helper.showChangesSavedGrowl();
              }, function () {
                $ctrl.saving = false;
                widget.enable(true);
              });
            }
          }

          function openFilterMenu(e) {
            $ctrl.showMenuFilter = !$ctrl.showMenuFilter;
            if (!$ctrl.showMenuFilter) {
              return;
            }
            calculateMenuPosition(e);
          }

          function calculateMenuPosition(e) {
            var filterMenu = null;
            $timeout(function () {
              filterMenu = document.getElementsByClassName('comment-filter-items-wrapper');
              if (filterMenu.length) {
                var buttonEl = document.getElementsByClassName('comment-filter-nav');
                if (buttonEl.length) {
                  var buttonPosition = buttonEl[0].getBoundingClientRect(), filterMenuRect = filterMenu[0].getBoundingClientRect();
                  filterMenu[0].style.top = buttonPosition.top + filterMenuRect.height < window.innerHeight ? buttonPosition.top + 28 + 'px' : buttonPosition.top - filterMenuRect.height - 16 + 'px';
                }
              }
            }, 0).then(function () {
              if (filterMenu.length) {
                filterMenu[0].style.opacity = 1;
              }
            });
          }

          function prepareStep() {
            $ctrl.selectedStepOwners = $ctrl.selectedGroups = [];
            if ($ctrl.step.owner === 'everyone') {
              _.forEach($ctrl.ownerList, function (userVal) {
                $ctrl.selectedStepOwners.push(angular.copy(userVal));
              });
            } else if (_.get($ctrl.step, 'guests', []).length || _.get($ctrl.step, 'assignees', []).length || _.get($ctrl.step, 'groups', []).length) {
              $ctrl.selectedStepOwners = [];
              _.forEach($ctrl.step.assignees, function (member) {
                $ctrl.selectedStepOwners.push({
                  id: member,
                  text: (_.find($ctrl.ownerList, { id: member }) || {}).text
                });
              });
              _.forEach($ctrl.step.guests, function (guest) {
                $ctrl.selectedStepOwners.push({
                  id: guest,
                  text: guest,
                  status: _.find(invitedUsers, { email: guest }) ? 'invited' : void 0
                });
              });
              _.forEach($ctrl.step.groups, function (group) {
                var groupBaseObj = _.find($ctrl.orgGroups, { id: group });
                angular.extend(groupBaseObj, group);
                $ctrl.selectedStepOwners.push(groupBaseObj);
              });
              if (angular.isArray($ctrl.selectedStepOwners) && ($ctrl.selectedStepOwners.length === 0 && $ctrl.selectedGroups.length === 0)) {
                $ctrl.selectedStepOwners.push({ id: $window.user_id, text: $window.username });
              }
            }
          }

          function loadOwner($query) {
            return _.filter(_.concat($ctrl.ownerList, $ctrl.orgGroups, $ctrl.orgGuests), function (owner) {
              return owner.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          /**
           * @ngdoc method
           * @name toggleStepOwner
           * @param {Event} e
           * @param {Object} param
           * @public
           * @description Toggle step owner
           */
          function toggleStepOwner(e, param) {
            e.stopImmediatePropagation();
            if (!Helper.checkAccessAuthority() || $ctrl.isPublic) {
              return;
            }
            if (param === 'run_starter') {
              $ctrl.step.owner = param;
              $ctrl.step.allow_guest_owners = false;
              $ctrl.step.role_changes_every_time = false;
              if ($ctrl.step.roles.data.length) {
                $ctrl.step.roles.data = [];
              }
              $ctrl.step.assignees = $ctrl.step.guests = $ctrl.step.groups = $ctrl.selectedStepOwners = [];
              updateStep();
            }
            if (param === 'launcher') {
              $ctrl.step.allow_guest_owners = false;
              $ctrl.step.role_changes_every_time = true;
              $ctrl.step.assignees = $ctrl.step.guests = $ctrl.step.groups = $ctrl.selectedStepOwners = [];
              updateStep();
            }
            if (param === 'outside_guest') {
              $ctrl.step.allow_guest_owners = true;
              $ctrl.step.role_changes_every_time = false;
              if ($ctrl.step.roles.data.length) {
                $ctrl.step.roles.data = [];
              }
              $ctrl.step.assignees = $ctrl.step.guests = $ctrl.step.groups = $ctrl.selectedStepOwners = [];
              updateStep();
            }
          }

          /**
           * @ngdoc method
           * @name toggleGuestOwner
           * @public
           * @description Toggle guest owner
           */
          function toggleGuestOwner() {
            if (!Helper.checkAccessAuthority() || $ctrl.isPublic)
              return;
            updateStep({ owner: angular.copy($ctrl.step.owner) }); // Params added to avoid switching of process starter / specific tab on saving the step.allow_guest_owners
          }

          /**
           * @ngdoc method
           * @name openInviteUserModel
           * @public
           * @description To open invite user modal
           * @returns {object} data
           */
          function openInviteUserModel() {
            menuActionLinksService.openInviteUserModal();
          }

          /**
           * @ngdoc method
           * @name setSpecificCheckboxBinding
           * @public
           * @description set Checkbox Binding of owners
           */
          function setSpecificCheckboxBinding() {
            $ctrl.ownerListForCheckbox = angular.copy($ctrl.ownerList);
            _.each($ctrl.selectedStepOwners, function (select_owner) {
              var idx = _.findIndex($ctrl.ownerListForCheckbox, { id: select_owner.id });
              if (idx > -1) {
                angular.extend($ctrl.ownerListForCheckbox[idx], { selected: true });
              }
            });
          }

          /**
           * @ngdoc method
           * @name toggleSpecificSelection
           * @public
           * @description Toggle specific owner selection
           */
          function toggleSpecificSelection() {
            var selectedStepOwners = [], stepOwnerIds = [];
            _.each($ctrl.ownerListForCheckbox, function (select_owner) {
              if (select_owner.selected) {
                selectedStepOwners.push(select_owner);
                stepOwnerIds.push(select_owner.id);
              }
            });
            $ctrl.selectedStepOwners = selectedStepOwners;
            $ctrl.step.assignees = stepOwnerIds;
            updateStep();
          }

          function onAssigneeRemoved(value) {
            var isMember = _.find($ctrl.ownerList, { id: value.id });
            if (isMember) {
              $ctrl.step.assignees = _.filter($ctrl.step.assignees, function (assignee) {
                return assignee !== value.id;
              });
            } else {
              if (value.id && value.type === 'group') {
                $ctrl.step.groups = _.filter($ctrl.step.groups, function (group_id) {
                  return group_id !== value.id;
                });
              } else {
                $ctrl.step.guests = _.filter($ctrl.step.guests, function (guest) {
                  return guest !== value.id;
                });
              }
            }
            setOwnerType();
            updateStep();
          }

          function onAssigneeAdded(value) {
            if (!isUserExist($ctrl.ownerList, value) || value.type === "guest") {
              if (!value.id || value.type === "guest") {
                value.id = value.text;
                value.onConfirm = true;
                value.confirmAssignee = assigneeClicked;
                $ctrl.step.guests.push(value.id);
              } else {
                $ctrl.step.groups.push(value.id);
              }
            } else {
              if (!value.id) {
                angular.extend(value, _.find($ctrl.ownerList, function (user) {
                  return user.email === value.text;
                }));
              }
              $ctrl.step.assignees.push(value.id);
            }
            setOwnerType();
            updateStep();
          }

          function cleanUpData(e) {
            var target = angular.element(e.target);
            if (!target.closest('.assignee-item').length) {
              _.forEach($ctrl.selectedStepOwners, function (owner) {
                if (owner.onConfirm && !owner.invited) {
                  owner.onConfirm = void 0;
                }
              });
            }
          }

          function onAssigneeAdding(value) {
            if (!value.id) {
              if (!Helper.isValidEmail(value.text)) {
                return false;
              } else if ($ctrl.step.groups.length && value.type !== 'group' && isUserExist($ctrl.ownerList, value)) {
                angular.extend(value, _.find($ctrl.ownerList, function (user) {
                  return user.email === value.text;
                }));
                return !Helper.isMemberPresentInGroup(value, angular.copy($ctrl.step.groups), $ctrl.orgGroups, true, true);
              }
              return true;
            } else if ($ctrl.step.groups.length && value.type !== 'group' && Helper.isMemberPresentInGroup(value, angular.copy($ctrl.step.groups), $ctrl.orgGroups, false, true)) {
              return false;
            } else if ($ctrl.step.groups.length && value.type === 'guest' && Helper.isGuestPresentInGroup(value, angular.copy($ctrl.step.groups), $ctrl.orgGroups, false, true)) {
              return false;
            }
            return true;
          }

          function setOwnerType() {
            $ctrl.step.owner = (($ctrl.step.assignees && $ctrl.step.assignees.length) || ($ctrl.step.groups && $ctrl.step.groups.length)) ? 'specific' : 'run_starter';
          }

          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return owner.email === user.text || user.email;
            });
          }

          function updateStep(oldStepDataToExtend) {
            if (updateStepTimeoutHandler) {
              $timeout.cancel(updateStepTimeoutHandler);
            }
            updateStepTimeoutHandler = $timeout(function () {
              var updateStepObj = prepareUpdateStep(angular.copy($ctrl.step));
              $ctrl.onSaving = true;
              if ($ctrl.step.owner === 'run_starter' && !$ctrl.step.guests.length) {
                $ctrl.step.assignees = [];
                $ctrl.step.groups = [];
                $ctrl.selectedStepOwners = [];
                prepareUpdateStep($ctrl.step);
              }
              blockUI.start();
              StepService.updateStep({
                id: $ctrl.step.id,
                checklist_id: $ctrl.process.id,
                skipNotFound: true
              }, updateStepObj).then(function (response) {
                blockUI.stop();
                oldStep = angular.copy($ctrl.step);
                if (oldStepDataToExtend) {
                  angular.extend(response.data, oldStepDataToExtend);
                }
                $rootScope.$emit('STEP:UPDATED', response.data);
                $ctrl.form.$setPristine();
                $ctrl.onSaving = false;
                Helper.showChangesSavedGrowl();
                Helper.isNewGuestAddedProcessAndReturn(_.get(response, 'data.guests', [])).then(function (res) {
                  if (res.isNewGuestAdded) {
                    $ctrl.orgGuests = res.updatedGuests;
                  }
                });
              }, function () {
                blockUI.stop();
                $ctrl.onSaving = false;
              });
            }, 100);
          }

          /**
           * @ngdoc method
           * @name prepareUpdateStep
           * @param stepObj
           * @private
           * @description Return a payload worthy step object
           * @returns {object} stepObj
           */
          function prepareUpdateStep(stepObj) {
            if (stepObj.owner === 'run_starter' && !stepObj.guests.length) {
              stepObj.assignees = [];
              stepObj.groups = [];
              setProcessStarterAssignees();
            }
            return stepObj;
          }

          /**
           * @ngdoc method
           * @name setProcessStarterAssignees
           * @private
           * @description Set Process Starter Assignee data
           */
          function setProcessStarterAssignees() {
            $ctrl.selectedStepOwners = [];
            _.each($ctrl.ownerListForCheckbox, function (select_owner) {
              select_owner.selected = false;
            });
          }

          function onDiscard() {
            _.extend($ctrl.step, oldStep);
            prepareStep();
            setSpecificCheckboxBinding();
            $ctrl.form.$setPristine();
          }

          function assigneeClicked(invite, data) {
            if (invite) {
              var modal = $uibModal.open({
                component: 'inviteUserComponent',
                windowClass: 'member-invite-modal',
                resolve: {
                  email: function () {
                    return data.text;
                  },
                  oneTime: true
                }
              });
              modal.result.then(function (res) {
                data.status = res.invited ? 'invited' : angular.noop();
              }, function () {
                data.onConfirm = void 0;
              });
            } else {
              data.onConfirm = void 0;
            }
          }

          function toggleIsAssigneeLimited(isBlur) {
            if (!Helper.checkAccessAuthority() || $ctrl.isPublic) {
              return;
            }
            if (!isBlur) {
              $ctrl.step.max_assignable = $ctrl.isAssigneeLimited ? 1 : 0;
            }
            updateStep({ owner: angular.copy($ctrl.step.owner) }); // Params added to avoid switching of process starter / specific tab on saving the step.max_assignable
          }

          function onMaxAssignableInputBlur() {
            $timeout(function () {
              if ($ctrl.step.max_assignable !== oldStep.max_assignable) {
                toggleIsAssigneeLimited(true);
              }
            }, 350);
          }

          function setSelectedElement(selectboxWidget) {
            $timeout(function () {
              var list = selectboxWidget.ul[0];
              var options = list.getElementsByTagName('li');
              for (var i = 0; i < options.length; i++) {
                var input = options[i].getElementsByTagName('input');
                if (options[i].classList.contains('k-state-selected')) {
                  input[0].checked = true;
                } else {
                  input[0].checked = false;
                }
              }
            }, 0);
          }

          function getSelectBoxOptions() {
            return {
              selectBoxId: 'select-' + $ctrl.step.id,
              widgetConfig: {
                autoClose: false,
                clearButton: false,
                dataSource: new kendo.data.DataSource(),
                dataTextField: "escaped_title",
                dataValueField: "id",
                downArrow: true,
                filter: "contains",
                itemTemplate: kendo.template($('#itemTemplate').html()),
                noDataTemplate: kendo.template($('#noDataTemplate').html()),
                placeholder: $filter('translate')('filter.filtersModal.searchPlaceholder'),
                tagTemplate: kendo.template($('#selectedItemTemplate').html()),
                dataBound: function (e) {
                  setSelectedElement(e.sender);
                },
                filtering: function (e) {
                  setSelectedElement($ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect'));
                },
                deselect: function (e) {
                  if ($ctrl.saving) {
                    e.preventDefault();
                    return;
                  }
                  e.dataItem.selected = !e.dataItem.selected;
                  $ctrl.selectRoles(e.dataItem);
                },
                select: function (e) {
                  if ($ctrl.saving) {
                    e.preventDefault();
                    return;
                  }
                  e.dataItem.selected = !e.dataItem.selected;
                  $ctrl.selectRoles(e.dataItem);
                },
                change: function (e) {
                  setSelectedElement($ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect'));
                },
                height: 160
              },
              templateScope: {
                variables: {},
                callbacks: {
                  addNew: function () {
                    if ($ctrl.saving) {
                      return;
                    }
                    var widget = $ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect');
                    if (widget.input.val()) {
                      $ctrl.saving = true;
                      widget.enable(false);
                      StepService.createRoles({ title: widget.input.val() }).then(function (response) {
                        $ctrl.stepRoles.push(response.data);
                        $ctrl.selectOptions.widgetConfig.dataSource.add(response.data);
                        $ctrl.selectOptions.widgetConfig.dataSource.sync();
                        widget.refresh();
                        $ctrl.saving = false;
                        widget.enable(true);
                        Helper.showChangesSavedGrowl();
                      });
                    }
                  }
                }
              }
            }
          }

          //Window resize handler
          function onWindowResize () {
            $ctrl.isMobile = !!($window.innerWidth < 767);
            $timeout(function () {
              $scope.$apply();
            }, 0);
          }

          //Toggle create group modal
          function toggleCreateGroupModal () {
            $uibModal.open({
              component: 'orgGroupModal',
              windowClass: 'org-group-modal',
              resolve: {
                availableUsers: function () {
                  return $ctrl.orgUsers;
                }
              }
            }).result.then(function (newOrgGroup) {
              $ctrl.orgGroups.push(newOrgGroup);
              growl.success($filter('translate')('orgGroups.content.groupCreated', { groupName: newOrgGroup.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              store.setGroups({ data: angular.copy($ctrl.orgGroups) });
            }, function () {
              $log.info('Modal is cancelled');
            });
          }
        }
    });
})();

(function () {
  'use strict';

  angular.module('tallyfy')
    .component('completerList', {
      templateUrl: 'app/components/compact/items/completerList/completer-list.component.html',
      bindings: {
        usersInOrg: '<',
        usersCompleter: '<',
        guestsCompleter: '<'
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;

          function onInit() { }
          function onChanges() { }
        }
    })
})();
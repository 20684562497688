(function () {
  'use strict';
  angular.module('tallyfy')
    .component('guestComponent', {
      templateUrl: 'app/modules/guest/guest.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function ($rootScope) {
          var $ctrl = this, unRegisteredOrgIdWatcher;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() { }

          function onChanges() { }

          function onDestroy() {
            if (unRegisteredOrgIdWatcher) {
              unRegisteredOrgIdWatcher();
            }
          }

          // watcher for guest organization id
          unRegisteredOrgIdWatcher = $rootScope.$watch('identity.guest.organization.id', function (newValue, oldValue) {
            if (!newValue || (newValue !== oldValue)) {
              return;
            }
            $rootScope.$emit('ORGANIZATION:UPDATEORGLOGO', { organization: $rootScope.identity.guest.organization });
          }, true);
        }
    })
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.emailSetting
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage email sttings for the current organisation
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('emailSetting', {
      templateUrl: 'app/modules/organizations/emailSetting/emailSettings.html',
      controller:
        /*@ngInject*/
        function(_, blockUI, Growl, $scope, EmailSettingsService, Helper, $filter, preferencesStore, $rootScope, AccountService, $uibModal) {
          var $ctrl = this,
            modalInstance,
            growl = new Growl(),
            blockUI = blockUI.instances.get('smtpform'),
            selectedEmailSettingsWatcherHandler;
          
          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveSmtpConfig = saveSmtpConfig;
          $ctrl.toggleEmailSettings = toggleEmailSettings;
          $ctrl.enableSMTP = enableSMTP;
          
          $ctrl.smtpForm = {};

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on 
           * an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority();
            $ctrl.org = _.get($rootScope.identity, 'default_organization');
            $ctrl.email_settings = _.get($ctrl.org, 'enable_custom_smtp', false);
            if (_.get($rootScope, 'identity.preferences')) {
              var smtpConfig = _.find(_.get($rootScope, 'identity.preferences'), { slug: 'smtp_connection' });
              if (smtpConfig) {
                $ctrl.smtpConfig = smtpConfig.metadata;
                $ctrl.enableSMTPPopup = !!($ctrl.smtpConfig);
              }
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { 
            selectedEmailSettingsWatcherHandler();
          }
          
          function saveSmtpConfig() {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            if ($ctrl.smtpForm.$invalid) {
              return;
            }
            var params = angular.extend($ctrl.smtpConfig, {
              "from_address": _.get($ctrl.smtpConfig, 'from_address'),
              "from_name": _.get($ctrl.smtpConfig, 'from_name'),
              "encryption": "tls"
            });
            blockUI.start();
            EmailSettingsService.updateSmtp(params).then( function(res) {
              blockUI.stop();
              $ctrl.smtpConfig = _.omit($ctrl.smtpConfig, ['password']);
              savePreference('smtp_connection', $ctrl.smtpConfig);
              $ctrl.smtpForm.$setPristine();
              growl.success($filter('translate')("org_settings.email_settings.form.message.success.updateEmail"), { 
                referenceId: 'smtpmsg', disableIcons: true, disableCloseButton: true });
            }, function (err) {
              blockUI.stop();
              _.set($ctrl.org, 'enable_custom_smtp', false);
              savePreference('smtp_connection', $ctrl.smtpConfig);
              growl.error(err.message, { referenceId: 'smtpmsg', disableIcons: true, disableCloseButton: true });
            });
          }

          function savePreference(slug, value) {
            var prefData = !value ?  { slug: slug, value: "no", metadata: {} }
              : $ctrl.email_settings ? { slug: slug, value: "yes", metadata: value } 
                : { slug: slug, value: "no", metadata: value };
            AccountService.setUserPreference({
              id: _.get($rootScope, 'identity.id'),
              preference: prefData
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            });
          }

          //Toggle email settings
          function toggleEmailSettings () {
            if ($ctrl.email_settings && $ctrl.enableSMTPPopup) {
              modalInstance = $uibModal.open({
                component: 'enableSmtpModal',
                windowClass: 'smtp-modal',
                resolve: {
                  smtpConfig: function () {
                    return $ctrl.smtpConfig;
                  },
                  smtpForm: function () {
                    return $ctrl.smtpForm;
                  }
                }
              });

              modalInstance.result.then(function (value) {
                value.useExisting ? saveSmtpConfig() : resetForm();
              }, function () {
                $ctrl.email_settings = $ctrl.org.enable_custom_smtp = false;
                savePreference('smtp_connection', $ctrl.smtpConfig);
              });
            }

            if (!$ctrl.email_settings) {
              enableSMTP();
            }
          }

          //Enable SMTP
          function enableSMTP () {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            blockUI.start();
            EmailSettingsService.enableSMTP({
              enable_custom_smtp: $ctrl.email_settings
            }).then(function () {
              savePreference('smtp_connection', $ctrl.smtpConfig);
              $ctrl.org.enable_custom_smtp = $ctrl.email_settings;
              growl.success($filter('translate')("org_settings.email_settings.form.message.success.toggleOffSuccess"), { 
                referenceId: 'smtpmsg', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            }, function (err) {
              growl.error(err.message, { referenceId: 'smtpmsg', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            });
          }

          //Reset SMTP form
          function resetForm () {
            var smtpConfig = angular.copy($ctrl.smtpConfig);
            savePreference('smtp_connection', smtpConfig);
            $ctrl.smtpConfig.host = '';
            $ctrl.smtpConfig.port = '';
            $ctrl.smtpConfig.username = '';
            $ctrl.smtpConfig.password = '';
            $ctrl.smtpConfig.from_name = '';
            $ctrl.smtpConfig.from_address = '';
          }
          
          selectedEmailSettingsWatcherHandler = $scope.$watch('$ctrl.email_settings', function (value) {
             _.set($rootScope.identity, 'default_organization.enable_custom_smtp', value);
          });
        }
    });
})();
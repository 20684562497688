/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for myAccount component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    $stateProvider.state('settings.me', {
      parent: 'settings',
      url: '/me',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view': {
          component: 'myAccount'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('password');       
          $translatePartialLoader.addPart('tags');
          return $translate.refresh();
        }]
      }
    });
    $stateProvider.state('settings.me.update', {
      url: '/details',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_account_view@settings.me': {
          component: 'updateAccount'
        }
      }
    });
    $stateProvider.state('settings.me.changePassword', {
      url: '/changePassword',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_account_view@settings.me': {
          component: 'changePassword'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('password');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.me.preferences', {
      url: '/notifications',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_account_view@settings.me': {
          component: 'preferences'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.me.personalize', {
      url: '/personalize',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_account_view@settings.me': {
          component: 'myPersonalization'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    // Guest routes

    $stateProvider.state('guest.settings.me', {
      url: '/me',
      data: {
        authorities: []
      },
      views: {
        'settings_view': {
          component: 'myAccount'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
    $stateProvider.state('guest.favorites', {
      parent: 'guest',
      url: '/:org_id/favorites',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'favorites'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('settings.me.favorites', {
      url: '/favorites',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_account_view@settings.me': {
          component: 'favorites'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('favorites', {
      parent: 'app',
      absolute: true,
      url: '/:org_id/favorites',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'favorites'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('guest.settings.me.preferences', {
      url: '/notifications',
      data: {
        authorities: []
      },
      views: {
        'settings_account_view@guest.settings.me': {
          component: 'guestPreferences'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('guest.settings.me.language', {
      url: '/language',
      data: {
        authorities: []
      },
      views: {
        'settings_account_view@guest.settings.me': {
          component: 'guestLanguage'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.run.component.assignesDeadlineEditor
 * @module tallyfy.run
 *
 * @description
 * A component to assign owners and edit deadlines
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('editRunStep', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      require: {},
      templateUrl: 'app/modules/runs/editRunStep/edit_run_step.html',
      controller:
        /*@ngInject*/
        function (_, moment, Helper, DateUtils, $timeout, UsersService, $scope, MODAL_CLOSE_EVENTS, $rootScope, OrganizationsService) {
          var $ctrl = this,
            onModalClosePreventHandler;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancel = cancelModal;
          $ctrl.filterUsers = filterUsers;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.updateOwners = updateOwners;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.onUserRemoved = onUserRemoved;
          $ctrl.getTagClass = getTagClass;
          $ctrl.resetOwnerAssigner = resetOwnerAssigner;
          $ctrl.isTomorrow = isTomorrow;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.openInviteModal = UsersService.openInviteModal;
          $ctrl.updateStartAt = updateStartAt;

          /**
           * public properties
           */
          $ctrl.ownerAssigner = {
            isGuestAllowed: true,
            isFormValid: true
          };
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.params = $ctrl.resolve.params || {};
            $ctrl.users = $ctrl.resolve.users || [];
            $ctrl.orgGroups = $ctrl.resolve.orgGroups || [];
            $ctrl.owners = $ctrl.resolve.owners || [];
            $ctrl.groups = $ctrl.resolve.groups || [];
            $ctrl.guests = $ctrl.resolve.guests || [];
            $ctrl.editDeadlineOnly = _.get($ctrl.resolve, 'editDeadlineOnly');
            $ctrl.orgGuests = $ctrl.resolve.orgGuests || [];
            if ($ctrl.guests.length) {
              _.filter($ctrl.guests, function(guest) {
                return !_.get(guest.id) ? guest.id = guest.full_name : angular.noop();
              });
            }
            $ctrl.ownerLists = _.unionWith($ctrl.owners, $ctrl.guests);
            if ($ctrl.groups.length) {
              _.forEach($ctrl.groups, function(groupItem) {
                var groupBaseObj = _.find($ctrl.orgGroups, { id: groupItem });
                groupBaseObj.full_name = groupBaseObj.name;
                $ctrl.ownerLists.push(groupBaseObj);
              });
            }
            
            $ctrl.displayProperty = $ctrl.params.displayProperty || 'full_name';
            $ctrl.users = _.sortBy($ctrl.users, $ctrl.displayProperty);
            if ($ctrl.params.taskDeadline) {
              var taskDeadline = DateUtils.toTimezone($ctrl.params.taskDeadline);
              $ctrl.splittedTime = taskDeadline.getSplittedTime();
            }

            _.forEach($ctrl.orgGroups, function(groupItem) {
              groupItem.full_name = groupItem.name;
            });
            _.forEach($ctrl.orgGuests, function(guestItem) {
              guestItem.id = guestItem.email;
              guestItem.full_name = guestItem.email;
              guestItem.type = 'guest';
            });

            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            onModalClosePreventHandler();
          }

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }

          /**
           * @ngdoc method
           * @name updateStartAt
           * @description
           * update start date value
           * @param {*} value
           * @param {*} type
           */
          function updateStartAt(value, type) {
            var started_at = $ctrl.params.taskDeadline;
            $ctrl.params.taskDeadline = moment(started_at).add(value, type).format();
          }

          /**
           * @function
           * @name updateOwners
           * @description Update owners
           */
          function updateOwners() {
            $timeout(function () {
              if (_.get($ctrl.ownerAssignerForm,'owners.$invalid', false) || !$ctrl.params.taskDeadline) {
                return;
              }
              $ctrl.close({
                $value: {
                  users: angular.copy($ctrl.owners),
                  guests: angular.copy($ctrl.guests),
                  groups: angular.copy($ctrl.groups),
                  deadline: $ctrl.params.taskDeadline
                }
              });
            }, 500);
          }

          /**
           * @function
           * @name filterUsers
           * @param {*} $query 
           * @description Callback function in ngTagsInput autocomplete
           */
          function filterUsers($query) {
            return _.filter(_.concat($ctrl.users, $ctrl.orgGroups, $ctrl.orgGuests), function (owner) {
              return owner[$ctrl.displayProperty].toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          /**
           * @function
           * @name onUserSelect
           * @param {*} user 
           * @description Callback function onTagAdding in ngTagsInput
           */
          function onUserSelect(user) {
            resetOwnerAssigner();
            if (isUserEmailAleardyAdded(user)) {
              return false;
            }

            if (user.type === 'guest' && Helper.isGuestPresentInGroup(user, angular.copy($ctrl.groups), $ctrl.orgGroups, false, true)) {
              return false;
            }

            if (isUserExist($ctrl.users, user) || user.type === 'group') {
              var displayEmail = false;
              if (!user.id) {
                angular.extend(user, _.find($ctrl.users, function (userObj) {
                  return userObj.email === user.full_name;
                }));
                displayEmail = true;
              }
              return !($ctrl.groups.length && user.type !== 'group' && Helper.isMemberPresentInGroup(user, angular.copy($ctrl.groups), $ctrl.orgGroups, displayEmail, true));
            }

            if ($ctrl.params.isGuestAllowed === '1' && !isGuestExist($ctrl.guests, user) && Helper.isValidEmail(user.full_name)) {
              return true;
            }

            if ($ctrl.params.isGuestAllowed !== '1') {
              $ctrl.ownerAssigner.isGuestAllowed = false;
              return false;
            }

            $ctrl.ownerAssigner.isFormValid = false;

            return false;
          }

          /**
           * @function
           * @name isUserExist
           * @description Check user already exist in the object
           */
          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return owner.email === user.full_name || user.email;
            });
          }

          /**
           * @function
           * @name isGuestExist
           * @description Check guest already exist in the object
           */
          function isGuestExist(guests, user) {
            return _.some(guests, function (guest) {
              return guest.full_name === user.full_name || user.id;
            });
          }

          /**
           * @function
           * @name isUserEmailAleardyAdded
           * @description Check if user email is added
           * and it's already assigned
           */
          function isUserEmailAleardyAdded(user) {
            return _.some($ctrl.owners, function (owner) {
              return owner.email === user.full_name;
            });
          }

          /**
           * @function
           * @name onUserAdded
           * @description Callback function onTagAdded in ngTagsInput
           */
          function onUserAdded($owner) {
            if (!isUserExist($ctrl.users, $owner) || $owner.type === 'guest') {
              if ($owner.type === 'group') {
                $ctrl.groups.push($owner.id);
              } else {
                $owner.id = $owner.full_name;
                $ctrl.guests.push($owner);
              }
            } else {
              if (!$owner.id) {
                $owner = _.find($ctrl.users, function (user) {
                  return user.email === $owner.full_name;
                });
              }
              $ctrl.owners.push($owner);
            }
          }

          /**
           * @function
           * @name onUserRemoved
           * @description Callback function onTagRemoved in ngTagsInput
           */
          function onUserRemoved($owner) {
            if (!isUserExist($ctrl.users, $owner)) {
              if ($owner.type === 'group') {
                var index = _.indexOf($ctrl.groups, $owner.id);
                if (index > -1) {
                  $ctrl.groups.splice(index, 1);
                }
              } else {
                $ctrl.guests.splice($ctrl.guests.indexOf($owner), 1);
              }
            } else {
              $ctrl.owners.splice($ctrl.owners.indexOf($owner), 1);
            }
          }

          /**
           * @function
           * @name getTagClass
           * @description Callback function tagClass in ngTagsInput
           */
          function getTagClass(user) {
            if (Helper.isValidEmail(user.full_name)) {
              return 'tag-email';
            }
          }

          /**
           * @function
           * @name resetOwnerAssigner
           * @description Reset owner assigner form error
           */
          function resetOwnerAssigner() {
            $ctrl.ownerAssigner = {
              isGuestAllowed: true,
              isFormValid: true
            };
          }

          /**
           * @function
           * @name isTomorrow
           * @description Check the selected time is tomorrow or not
           */
          function isTomorrow() {
            var taskDeadline = _.get($ctrl.params, 'taskDeadline', {});
            if (taskDeadline && typeof taskDeadline.getFullYear === 'function' && typeof taskDeadline.getMonth === 'function') {
              if ((taskDeadline.getFullYear() !== new Date().getFullYear()) || (taskDeadline.getMonth() !== new Date().getMonth())) {
                return false;
              }
              var diff = taskDeadline.getDate() - new Date().getDate();
              return diff === 1;
            } else {
              return false;
            }
          }

          /**
           * @function
           * @name onRemovingUser
           * @description 
           * Check before removing user if only one coworker then it won't delete the user
           * @param $owner 
           */
          function onRemovingUser($owner) {
            if (!isUserExist($ctrl.users, $owner)) {
              return true;
            }
            return (($ctrl.owners.length + $ctrl.groups.length + $ctrl.guests.length) !== 1);
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function(event, reason) {
            if ($ctrl.ownerAssignerForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
        }
      //end of Controller
    });
})();

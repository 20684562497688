/**
 * @ngdoc Transformer
 * @name tallyfy.factory.RunTransformer
 * @module tallyfy
 *
 * @description
 * A transformer to update the run object
 * @param data {Object} run
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('RunTransformer',
      /*@ngInject*/

      function RunTransformer(_, DateUtils, TaskTransformer) {
        /**
         * Constructor, with class name
         */
        function RunTransformer(data) {
          var run = angular.copy(data);
          run.started_at_as_str = DateUtils.toLocal(data.started_at_as_str).format();
          run.started_at = DateUtils.toLocal(data.started_at).format();
          run.created_at = DateUtils.toLocal(data.created_at).format();
          run.last_updated_unformatted = run.last_updated;
          run.last_updated = DateUtils.toLocal(data.last_updated).format();

          if (!_.isUndefined(data.steps)) {
            run.steps.data = new TaskTransformer(data.steps.data);
          }

          if (!_.isUndefined(data.tasks)) {
            run.tasks.data = new TaskTransformer(data.tasks.data);
          }
          return run;
        }

        return RunTransformer;
      });

})();

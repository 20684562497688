/**
 * @ngdoc overview
 * @name tallyfy.compliance
 *
 * @module tallyfy.compliance
 *
 * @description
 * manage third parties compliance
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy.compliance', []);
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('documentPaneEditor', {
      templateUrl: 'app/components/documentPaneEditor/document-pane-editor.component.html',
      bindings: {
        process: '=',
        selectedField: '=',
        froalaOptions: '<',
        isPublic: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $element, $q, $rootScope, $scope, $timeout, $uibModal, ProcessService, FroalaService, Helper) {
          var $ctrl = this, fieldEditorSelectedWatcher, fieldEditorRemovedWatcher, fieldEditorBlurWatcher, selectedFieldWatcher, unregisterProcessHandler, unregisterEditorClickedHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.formFieldAdd = formFieldAdd;
          $ctrl.onSnippetSelected = onSnippetSelected;
          $ctrl.onBlueprintSelected = onBlueprintSelected;
          $ctrl.fieldUpdate = fieldUpdate;
          $ctrl.fieldControlClick = fieldControlClick;
          $ctrl.openLearnMoreModal = openLearnMoreModal;
          $ctrl.getProcessPreruns = getProcessPreruns;
          $ctrl.fieldTypes = [
            { type: 'smallText' },
            { type: 'largeText' },
            { type: 'date' }
          ];

          function onInit() {
            $timeout(function () {
              var dragContents = $element.find('.drag-content');
              for (var i = 0; i < dragContents.length; i++) {
                dragContents[i].setAttribute('draggable', true);
                dragContents[i].addEventListener('dragstart', dragContentCallback);
                dragContents[i].addEventListener('dragend', dragContentEndCallback);
              }
            }, 350);
          }

          function dragContentEndCallback() {
            var dummyElement = $('body').find('.dummy-form-field');
            if (dummyElement && dummyElement[0]) {
              dummyElement[0].parentNode.removeChild(dummyElement[0]);
            }
          }

          function dragContentCallback(e) {
            e.dataTransfer.setData('element', 'editor-form-field');
            e.dataTransfer.setData('args', JSON.stringify({ type: e.target.dataset.type }));
            $ctrl.editorOptions.froalaEditor.selection.restore();
            $timeout(function () {
              var position = Helper.getCurrentCaretPositionOnWindow();
              $ctrl.froalaOptions.froalaEditor.html.insert(' ');
              $timeout(
                function () {
                  $('body').append(
                    angular.element('<div>')
                      .addClass('dummy-form-field')
                      .css('left', position.left)
                      .css('top', position.top)
                      .html($(e.target).html())
                  );
                }, 10
              );
            }, 10);
          }

          function onChanges(changes) {
            if (changes.froalaOptions) {
              $ctrl.editorScope = $ctrl.froalaOptions.editorScope;
              $ctrl.editorOptions = $ctrl.editorScope.froalaOptions;
              $ctrl.editorOptions.process = $ctrl.process;
              $timeout(function () {
                compileAllFieldEditors();
              }, 350).then(function () {
                $timeout(function () {
                  FroalaService.syncEntityFormField($ctrl.editorScope);
                }, 350);
              });
            }
          }

          function onDestroy() {
            fieldEditorSelectedWatcher();
            fieldEditorBlurWatcher();
            fieldEditorRemovedWatcher();
            selectedFieldWatcher();
            unregisterProcessHandler();
            unregisterEditorClickedHandler();
          }

          function updateProcess() {
            var defer = $q.defer();
            var params = { id: $ctrl.process.id };
            if ($ctrl.isPublic) {
              params.skipAuthToken = true;
            }
            ProcessService.update(params, $ctrl.process)
              .then(function (res) {
                defer.resolve(res);
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          function setSelection() {
            var currentSelectionEl = $ctrl.froalaOptions.froalaEditor.selection.element(),
              closestFormField = currentSelectionEl.closest('editor-form-field'),
              closestSnippet = currentSelectionEl.closest('editor-snippet'),
              closestBlueprint = currentSelectionEl.closest('editor-blueprint');
            if (closestSnippet) {
              $ctrl.froalaOptions.froalaEditor.selection.setAfter(closestSnippet);
            } else if (closestFormField) {
              $ctrl.froalaOptions.froalaEditor.selection.setAfter(closestFormField);
            } else if (closestBlueprint) {
              $ctrl.froalaOptions.froalaEditor.selection.setAfter(closestBlueprint);
            }
            $ctrl.froalaOptions.froalaEditor.selection.save();
          }

          function formFieldAdd(e, type) {
            e.stopImmediatePropagation();
            var fieldType = type;
            if (fieldType === 'smallText') {
              fieldType = {
                type: 'text',
                label: 'Short text'
              };
            } else if (type === 'largeText') {
              fieldType = {
                type: 'textarea',
                label: 'Long text'
              };
            } else if (type === 'date') {
              fieldType = {
                type: 'date',
                label: 'Date'
              };
            }
            var lastField = _.maxBy($ctrl.process.prerun, 'position'),
              position = (lastField ? lastField.position : 0) + 1,
              fieldModel = {
                collect_time: fieldType.type === 'date' ? false : void 0,
                field_type: fieldType.type,
                label: fieldType.label,
                guidance: null,
                required: false,
                position: position
              };
            $ctrl.process.prerun.push(fieldModel);
            $ctrl.editorScope.froalaOptions.isBusy = true;
            if (!$ctrl.process.kickoff_title) {
              $ctrl.process.kickoff_title = $ctrl.process.title;
            }
            mapPrerunPosition();
            $ctrl.isBusy = $ctrl.froalaOptions.isUpdatingPrerun = true;
            updateProcess()
              .then(function (res) {
                angular.extend($ctrl.process, { prerun: res.data.prerun, last_updated: res.data.last_updated });
                var field = _.find(_.get(res.data, 'prerun', []), { position: position });
                $ctrl.selectedField = field;
                $('#fieldControl').collapse('show');
                $ctrl.fieldControlExpanded = true;
                $timeout(function () {
                  setSelection();
                }, 350).then(function() {
                  $ctrl.froalaOptions.froalaEditor.selection.restore();
                  $ctrl.froalaOptions.froalaEditor.html.insert(' ');
                  $timeout(function () {
                    $ctrl.froalaOptions.froalaEditor.html.insert(
                      '\uFEFF<editor-form-field draggable="true" class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-field-id="\''
                      + field.id + '\'"></editor-form-field>\uFEFF'
                    );
                    $ctrl.froalaOptions.froalaEditor.html.insert(' ');
                    $ctrl.froalaOptions.froalaEditor.undo.saveStep();
                    $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
                  }, 350).then(function () {
                    compileAllFieldEditors();
                    $('#variables').collapse($ctrl.process.prerun.length ? 'show' : 'hide');
                    $ctrl.variablesClicked = true;
                    $ctrl.isBusy = $ctrl.froalaOptions.isUpdatingPrerun = false;
                  });
                });
              }, function () {
                $ctrl.editorScope.froalaOptions.isBusy = false;
                $ctrl.isBusy = $ctrl.froalaOptions.isUpdatingPrerun = false;
              });
          }

          function compileAllFieldEditors() {
            $ctrl.editorScope.froalaOptions.isBusy = true;
            FroalaService.compileEditorFieldElement($ctrl.editorScope, 'editor-form-field');
            FroalaService.compileEditorFieldElement($ctrl.editorScope, 'editor-snippet');
            FroalaService.compileEditorFieldElement($ctrl.editorScope, 'editor-blueprint');
            FroalaService.compileEditorFieldElement($ctrl.editorScope, 'editor-variable');
          }

          function onSnippetSelected(snippet) {
            $timeout(function () {
              $ctrl.froalaOptions.froalaEditor.html.insert(
                '\uFEFF<editor-snippet draggable="true" class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-snippet-id="\''
                + snippet.id + '\'" data-snippet-title="\'' + snippet.title + '\'"></editor-snippet>\uFEFF'
              );
              $ctrl.froalaOptions.froalaEditor.undo.saveStep();
            }, 350).then(function () {
              compileAllFieldEditors();
            });
          }

          function onBlueprintSelected(blueprint) {
            $timeout(function () {
              $ctrl.froalaOptions.froalaEditor.html.insert(
                '\uFEFF<editor-blueprint draggable="true" class="fr-fil fr-dib fr-draggable" data-editor-scope="this" data-blueprint-id="\''
                + blueprint.id + '\'" data-blueprint-title="\'' + blueprint.title + '\'" data-blueprint-icon="\''
                + blueprint.icon + '\'"></editor-blueprint>\uFEFF'
              );
              $ctrl.froalaOptions.froalaEditor.undo.saveStep();
            }, 350).then(function () {
              compileAllFieldEditors();
            });
          }

          function fieldUpdate(field) {
            if (!$ctrl.selectedField) {
              return;
            }
            $ctrl.editorScope.froalaOptions.isBusy = true;
            angular.extend($ctrl.selectedField, field);
            if (!$ctrl.process.kickoff_title) {
              $ctrl.process.kickoff_title = $ctrl.process.title;
            }
            var prerunData = _.find($ctrl.process.prerun, { id: $ctrl.selectedField.id });
            angular.extend(prerunData, $ctrl.selectedField);
            mapPrerunPosition();
            updateProcess()
              .then(function (res) {
                $ctrl.editorScope.froalaOptions.isBusy = false;
                $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
              });
          }

          function fieldControlClick() {
            $ctrl.fieldControlExpanded = !$ctrl.fieldControlExpanded;
          }

          fieldEditorSelectedWatcher = $rootScope.$on('FIELD_EDITOR:SELECTED', function (e, data) {
            if ($ctrl.selectedField && (data.field.id === $ctrl.selectedField.id)) {
              $('#fieldControl').collapse('show');
              $ctrl.fieldControlExpanded = true;
              $timeout(function () {
                $ctrl.selectedField.isExpanded = true;
              }, 350);
            } else {
              $ctrl.selectedField = void 0;
              $timeout(function () {
                $ctrl.selectedField = _.find($ctrl.process.prerun, { id: data.field.id });
                $timeout(function () {
                  $ctrl.selectedField.isExpanded = true;
                  if ($ctrl.selectedField) {
                    $ctrl.previousSelectedFieldValue = angular.copy($ctrl.selectedField);
                    if (!$ctrl.fieldControlExpanded) {
                      $('#fieldControl').collapse('show');
                      $ctrl.fieldControlExpanded = true;
                    }
                    var el = $ctrl.froalaOptions.froalaEditor.$el[0].querySelectorAll('editor-form-field[data-field-id*=\'' + $ctrl.selectedField.id + '\']');
                    el[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                  } else {
                    $('#fieldControl').collapse('hide');
                    $ctrl.selectedField = void 0;
                  }
                }, 350);
              }, 350);
            }
          });

          fieldEditorBlurWatcher = $rootScope.$on('FIELD_EDITOR:BLUR', function (e, data) {
            var editedField = _.find($ctrl.process.prerun, { id: data.field.id });
            if (editedField && Helper.isObjectEmpty(editedField.label)) {
              editedField.label = $ctrl.previousSelectedFieldValue.label;
            }
            mapPrerunPosition();
            var params = { id: $ctrl.process.id };
            if ($ctrl.isPublic) {
              params.skipAuthToken = true;
            }
            ProcessService.update(params, $ctrl.process)
              .then(function () {
                $ctrl.editorScope.froalaOptions.isBusy = false;
                Helper.showChangesSavedGrowl();
              });
          });

          fieldEditorRemovedWatcher = $rootScope.$on('FIELD_EDITOR:REMOVED', function (e, data) {
            if (($ctrl.editorScope.froalaOptions.isBusy && !data.sync) || !data.fieldId) {
              return;
            }
            $ctrl.editorScope.froalaOptions.isBusy = true;
            ProcessService.deleteProcessPrerun({
              id: $ctrl.process.id,
              action: 'preruns',
              action_id: data.fieldId,
              skipNotFound: true
            }).then(function () {
              _.remove($ctrl.process.prerun, { id: data.fieldId });
              mapPrerunPosition();
              $('#variables').collapse($ctrl.process.prerun.length ? 'show' : 'hide');
              $timeout(function () {
                $ctrl.editorScope.froalaOptions.isBusy = false;
              }, 350);
            });
          });

          function mapPrerunPosition() {
            var index = 1;
            _.map($ctrl.process.prerun, function (prerun) {
              prerun.position = index;
              index++;
            });
          }

          function openLearnMoreModal(e) {
            e.stopImmediatePropagation();
            $uibModal.open({
              backdrop: 'static',
              component: 'learnMoreDocumentModal',
              windowClass: 'learn-more-modal'
            });
          }

          function getProcessPreruns() {
            return $ctrl.variableSearchQuery
              ? _.filter(_.get($ctrl.process, 'prerun', []), function (prerun) {
                return prerun.label.toLowerCase().includes($ctrl.variableSearchQuery.toLowerCase());
              }) : _.get($ctrl.process, 'prerun', []);
          }

          selectedFieldWatcher = $scope.$watch('$ctrl.selectedField', function (value) {
            if (!value) {
              $('#fieldControl').collapse('hide');
              $ctrl.fieldControlExpanded = false;
            }
          });

          unregisterProcessHandler = $rootScope.$on("PROCESS:UPDATED", function ($event, data) {
            angular.extend($ctrl.editorOptions.process, data.process);
          });

          unregisterEditorClickedHandler = $rootScope.$on('EDITOR:CLICKED', function (e, data) {
            var isInSideEntityElement = data.event.currentTarget.closest('editor-variable') || data.event.currentTarget.closest('editor-form-field') || data.event.currentTarget.closest('editor-snippet') || data.event.currentTarget.closest('editor-blueprint');
            if (!isInSideEntityElement) {
              $ctrl.selectedField = void 0;
            }
          });
        }
    });
})();
/**
 * @ngdoc overview
 * @name tallyfy.notifications
 *
 * @module tallyfy.notifications
 *
 * @description
 * notifications
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.notifications', []);
})();

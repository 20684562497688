(function () {
  'use strict';

  var $global = null;
  angular
    .module('tallyfy.config')
    .config(analyticsConfig)
    .run(run);

  /*@ngInject*/
  function analyticsConfig($analyticsProvider, CONFIG, _) {
    var isDeveloperMode = CONFIG.MIXPANEL_DEVELOPER_MODE === 'true';
    $analyticsProvider.withBase(true);
    $analyticsProvider.developerMode(isDeveloperMode);
    $analyticsProvider.trackExceptions(true);
    if (_.get($analyticsProvider, 'settings.ga')) {
      $analyticsProvider.settings.ga.additionalAccountNames = ['tracker2'];
    }

    function sendToAdditionalAccountName($window, event, dimension, additionalAccountNames) {
      if ($window.ga) {
        for (var i = 0; i < additionalAccountNames.length; i++) {
          $window.ga(additionalAccountNames[i] + '.set', 'dimension1', dimension.orgId);
          $window.ga(additionalAccountNames[i] + '.set', 'dimension2', dimension.taskId);
          $window.ga(additionalAccountNames[i] + '.set', 'dimension3', dimension.processId);
          $window.ga(additionalAccountNames[i] + '.set', 'dimension4', dimension.blueprintId);
          $window.ga(additionalAccountNames[i] + '.set', 'dimension5', dimension.blueprintStepId);
          $window.ga(additionalAccountNames[i] + '.send', event);
        }
      }
    }

    function sendToGA($window, event, dimension) {
      if ($window.ga) {
        $window.ga('set', 'dimension1', dimension.orgId);
        $window.ga('set', 'dimension2', dimension.taskId);
        $window.ga('set', 'dimension3', dimension.processId);
        $window.ga('set', 'dimension4', dimension.blueprintId);
        $window.ga('set', 'dimension5', dimension.blueprintStepId);
        $window.ga('send', event);
      }
      var additionalAccountNames = $analyticsProvider.settings.ga.additionalAccountNames || [];
      if (additionalAccountNames.length) {
        sendToAdditionalAccountName($window, event, dimension, additionalAccountNames);
      }
    }

    function sendFromGTMEvents($window, event, dimension) {
      if ($window.gtag) {
        $window.gtag('event', event, dimension);
      }
    }

    function sendToDataLayer($window, event, dimension) {
      angular.extend(dimension, { event: event });
      $window.dataLayer = $window.dataLayer || [];
      $window.dataLayer.push(dimension);
    }

    function sendCustomDimensionValue($window, event, dimension) {
      sendToDataLayer($window, event, dimension);
      sendFromGTMEvents($window, event, dimension);
      sendToGA($window, event, dimension);
    }

    function setGoogleCustomDimension($window, $state, event) {
      $global.$timeout(function () {
        var dimension = {
          orgId: $state.params.org_id
        };
        if ($state.current.name === 'run.view') {
          dimension.taskId = $state.params.activeTask;
          dimension.processId = $state.params.id;
        }
        else if ($state.current.name === 'home') {
          dimension.taskId = $state.params.activeTask;
        }
        else if ($state.current.name === 'process.edit') {
          dimension.blueprintId = $state.params.slug;
          dimension.blueprintStepId = $state.params.step;
        }
        sendCustomDimensionValue($window, event, dimension);
      }, 0);
    }

    $analyticsProvider.registerPageTrack(function () {
      setGoogleCustomDimension($global.$window, $global.$state, 'pageview');
    });

    $analyticsProvider.registerEventTrack(function (action) {
      setGoogleCustomDimension($global.$window, $global.$state, action);
    });
  }

  /*@ngInject*/
  function run($window, $state, $timeout) {
    $global = {
      $window: $window,
      $state: $state,
      $timeout: $timeout
    };
  }
})();
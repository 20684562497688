(function () {
  'use strict';
  angular.module('tallyfy')
    .component('compactKoFormTask', {
      templateUrl: 'app/components/compact/components/koFormTask/compact-ko-form-task.component.html',
      bindings: {
        process: '<',
        usersInOrg: '<',
        isSelected: '<',
        viewerId: '<',
        isPublicProcess: '<',
        isPublic: '<',
        simpleView: '<',
        onTaskClickHandler: '&?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $stateParams, Helper, CompactTaskService, RunsService, FieldService, DESCRIPTIONSIZE, COMMON, $analytics) {
          var $ctrl = this, descriptionClickHandler, taskCaptureUpdatedHandler;

          $ctrl.isOpenPopOver = false;
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onTaskSelected = onTaskSelected;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onStatusClick = onStatusClick;

          function onInit() {
            if ($stateParams.activeTask === "ko-task" && $ctrl.simpleView) {
              $ctrl.onTaskSelected();
            }
            $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT_LEFT_PANE;
          }

          function onChanges() { }

          function onDestroy() {
            descriptionClickHandler();
            taskCaptureUpdatedHandler();
          }

          function onTaskSelected(e) {
            if ($ctrl.onTaskClickHandler && typeof $ctrl.onTaskClickHandler === 'function') {
              $ctrl.onTaskClickHandler({ e: e, args: { focusField: $stateParams.focusField } });
            } else {
              if ($ctrl.isSelected) {
                return;
              }
              e.stopPropagation();
              // emit item with id 'ko-step' for statement checking on component
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  id: 'ko-task',
                  process: $ctrl.process
                }
              });
            }
          }

          function onStatusClick() {
            var preruns = _.get($ctrl.process, 'checklist.data.prerun', []);
            var isHasEmptyRequiredFields = CompactTaskService.validateCaptures(preruns), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(preruns);
            if (isHasEmptyRequiredFields || isHasExceededTextChars) {
              $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR');
            } else {
              $ctrl.process.prerun_status === 'complete' ? inCompleteTask() : completeTask();
            }
          }

          function inCompleteTask() {
            $ctrl.onSaving = true;
            CompactTaskService.koTaskReOpen($ctrl.process)
              .then(function (res) {
                angular.extend($ctrl.process, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackEvent('KO task re-opened', {});
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function completeTask() {
            $ctrl.onSaving = true;
            CompactTaskService.koTaskComplete($ctrl.process)
              .then(function (res) {
                angular.extend($ctrl.process, res.data);
                $rootScope.$emit('KICK_OFF_FORM:UPDATED_STATUS');
                trackEvent('KO task completed', {});
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function trackEvent(eventName, properties) {
            properties.type = 'process';
            properties.processId = $ctrl.process.id;
            $analytics.eventTrack(eventName, properties);
          }

          function updatePrerunField(field) {
            $ctrl.process.prerun[field.id] = FieldService.getFieldValue(field);
            RunsService.update({ id: $ctrl.process.id }, { prerun: mapProcessPrerunValue() })
              .then(function (res) {
                angular.extend($ctrl.process, { prerun: res.data.prerun });
                $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
              });
          }

          function mapProcessPrerunValue() {
            var prerun = {};
            for (var key in $ctrl.process.prerun) {
              var data = _.find($ctrl.process.ko_form_fields.data, { id: key }) || {};
              if (data.field_type === 'table') {
                var value = $ctrl.process.prerun[key];
                if (value && value.length) {
                  if (Helper.isObjectEmpty(prerun[key])) {
                    prerun[key] = [];
                  }
                  for (var i = 0; i < value.length; i++) {
                    prerun[key].push(
                      !Helper.isObjectEmpty(value) && !Helper.isObjectEmpty(value[i]) && value[i].id
                        ? value[i]
                        : {
                          id: data.id,
                          label: data.label,
                          value: value[i]
                        }
                    );
                  }
                }
              } else {
                prerun[key] = $ctrl.process.prerun[key];
              }
            }
            return prerun;
          }

          descriptionClickHandler = $rootScope.$on('KO_FIELD_ASSIGNEE:DESCRIPTION_CLICK', function (e, data) {
            var preruns = _.get($ctrl.process, 'checklist.data.prerun', []), selectedPrerun = _.find(preruns, { alias: data.alias });
            if (selectedPrerun) {
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  id: 'ko-task',
                  process: $ctrl.process,
                  focusField: selectedPrerun
                }
              });
            }
          });

          taskCaptureUpdatedHandler = $rootScope.$on('CAPTURE:UPDATE', function (e, data) {
            if (data.isKoTaskAssignee) {
              var tempEl = angular.element('<div>').html(data.updatedTask.summary),
                koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
              if (koAssigneeElements && koAssigneeElements.length) {
                var field = _.find(_.get($ctrl.process, 'checklist.data.prerun', []), { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') });
                angular.extend(field, { value: data.updatedCapture.value });
                if (field) {
                  updatePrerunField(field);
                }
              }
            }
          })
        }
    });
})();
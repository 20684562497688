/**
 * @ngdoc Component
 * @name tallyfy.run.component.newEditDeadlines
 * @module tallyfy.run
 *
 * @description
 * A component to edit the deadlines
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('newEditDeadlines', {
      bindings: {
        deadline: '=',
        disallowPastDates: '<?',
        dueDateUpdate: '=?'
      },
      require: {},
      templateUrl: 'app/modules/runs/newEditDeadlines/new-edit-deadlines.html',
      controller:
        /*@ngInject*/
        function (_, moment, $scope, OrganizationsService, DateUtils, $rootScope) {
          var $ctrl = this,
             updateDeadlineEvent,
             isFirst = true;
          
          $ctrl.isShow = false;
          $ctrl.hstep = 1;
          $ctrl.mstep = 1;
          $ctrl.datePicker = {
            dateOptions: {
              dateDisabled: false,
              formatYear: 'yy',
              formatMonth: 'MMM',
              monthColumns: '4',
              maxDate: new Date(2049, 12, 30),
              startingDay: 1,
              showButtonBar: false,
              altInputFormats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
              showWeeks: false,
              ngModelOptions: {
                debounce: 100
              }
            },
            isOpened: false
          };
          $ctrl.splittedTime = {
            year: "",
            day: "",
            month: ""
          };
          $ctrl.currentTZ = _.get($rootScope, 'identity.timezone', null) || moment.tz.guess();
          $ctrl.organizationId = _.get($rootScope, 'identity.default_organization.id');

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.isValidMonth = isValidMonth;
          $ctrl.isValidDay = isValidDay;
          $ctrl.isValidYear = isValidYear;
          $ctrl.splitTimeChanged = splitTimeChanged;
          $ctrl.openDatePicker = openDatePicker;
          $ctrl.datepickerChanged = datepickerChanged;
          $ctrl.timepickerChanged = timepickerChanged;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.selectedDateFormat = OrganizationsService.dateFormat().dateFormat;
            $ctrl.deadline = DateUtils.toTimezone($ctrl.deadline).value().format();
            $ctrl.tempDeadline = angular.copy(moment($ctrl.deadline, moment.ISO_8601));
            generateSplittedDate();
            updateOriginalDeadlineAttr();

            if ($ctrl.disallowPastDates) {
              $ctrl.datePicker.dateOptions.minDate = DateUtils.toTimezone().value();
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateDeadlineEvent();
          }

          /**
           * @function
           * @name openDatePicker
           * @description Datepicker open
           */
          function openDatePicker() {
            $ctrl.datePicker.isOpened = true;
          }

          /**
           * @function
           * @name generateSplittedDate
           * @description Generate date and time to show in the splitted input box in UI
           */
          function generateSplittedDate() {
            $ctrl.splittedTime = DateUtils.toTimezone($ctrl.tempDeadline).getSplittedTime();
          }

          /**
           * @function
           * @name isValidDate
           * @description Check if the date is valid or not
           */
          function isValidDate() {
            return isValidDay($ctrl.splittedTime.day) && isValidMonth($ctrl.splittedTime.month) && isValidYear($ctrl.splittedTime.year);
          }

          /**
           * @function
           * @name isValidMonth
           * @param month
           * @description Check if a month is valid
           * @returns {Number|boolean}
           */
          function isValidMonth(month) {
            return parseInt(month) && month <= 12;
          }

          /**
           * @function
           * @name isValidDay
           * @param day
           * @description Check if a day is valid
           * @returns {Number|boolean}
           */
          function isValidDay(day) {
            return parseInt(day) && day <= 31;
          }

          /**
           * @function
           * @name isValidYear
           * @param year
           * @description Check if a number is valid year
           * @returns {Number|boolean}
           */
          function isValidYear(year) {
            return parseInt(year) && year > 2017 && year < 2050;
          }

          /**
           * @function
           * @name splitTimeChanged
           * @description Track date change via split time inputs
           */
          function splitTimeChanged() {
            if (!isValidDate()) {
              return;
            }

            var deadline = moment($ctrl.tempDeadline).toDate();
            deadline.setDate($ctrl.splittedTime.day);
            deadline.setYear($ctrl.splittedTime.year);
            deadline.setMonth($ctrl.splittedTime.month - 1);
            $ctrl.tempDeadline = deadline;
            updateOriginalDeadlineAttr();
          }

          /**
           * @function
           * @name datepickerChanged
           * @description Track date change via datepicker
           */
          function datepickerChanged() {
            $ctrl.splittedTime = DateUtils.getSplittedTimeForMoment(moment($ctrl.tempDeadline));
            updateOriginalDeadlineAttr();
          }

          /**
           * @function
           * @name timepickerChanged
           * @description Track time change via timepicker
           */
          function timepickerChanged() {
            if ($ctrl.tempDeadline) {
              updateOriginalDeadlineAttr();
            }
          }

          /**
           * @function
           * @name updateOriginalDeadlineAttr
           * @description Update Original Deadline Attr - $ctrl.deadline
           */
          function updateOriginalDeadlineAttr() {
            $ctrl.deadline = $ctrl.currentTZ ? moment($ctrl.tempDeadline).tz($ctrl.currentTZ, true) : moment($ctrl.tempDeadline);
          }
          
          updateDeadlineEvent = $scope.$watch('$ctrl.deadline', function (newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
              if (!moment.isMoment(newValue)) {
                if (!isFirst) {
                  $ctrl.dueDateUpdate = true;
                }
                isFirst = false;
                initialization();
              } else if (moment.isMoment(oldValue) && moment.isMoment(newValue)) {
                $ctrl.dueDateUpdate = true;
              }
            }
          }, true);
        }
      //end of Controller
    });
})();
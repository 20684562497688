/**
 * @ngdoc Component
 * @name tallyfy.settings.component.myOrgCustomization
 * @module tallyfy.settings
 *
 * @description
 * A component to update the personalized settings of the current org
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.settings')
    .component('myOrgCustomization', {
      templateUrl: 'app/modules/manage/components/myOrg/myOrgCustomization/my-org-customization.html',
      controller: updateOrgCtrl
    });
  
   /*@ngInject*/
  function updateOrgCtrl(_, blockUI, Growl, OrganizationsService, $rootScope, $filter, $state, $q, ConfirmOnExitService, Helper, $window, UsersService, ProcessService, BLUEPRINT_TYPE) {
    var $ctrl = this,
      blockUI = blockUI.instances.get('myOrgCustomization'),
      growl = new Growl(),
      windowElem = angular.element($window),
      workHoursArr = OrganizationsService.getWorkHoursArray(),
      weekdaysWithHours = OrganizationsService.getWorkingDaysWithHours(),      
      pagination = {
        page: 1,
        per_page: 20
      };

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;


          /**
           * public properties
           */
          $ctrl.org = {};
          $ctrl.updateOrgForm = {};
          $ctrl.dropdownOptions = [];
          $ctrl.isAutoArchiveDropdownOpen = false;
          $ctrl.isAutoCompleteDropdownOpen = false;
          $ctrl.isNewOnBoardingNewMembersDropdownOpen = false;
          $ctrl.isSelectBoxChecklistOpen = false;
          $ctrl.userRoles = UsersService.getUserRoles();

          /**
           * public methods
          */
          $ctrl.updateOrganizations = updateOrganizations;
          $ctrl.resetForm = resetForm;
          $ctrl.saveDefaultDeadlinePreferences = saveDefaultDeadlinePreferences;
          $ctrl.cadenceDays = OrganizationsService.getCadenceDays();
          $ctrl.toggleAutoArchive = toggleAutoArchive;
          $ctrl.toggleAutoComplete = toggleAutoComplete;
          $ctrl.toggleOnBoardingNewMembers = toggleOnBoardingNewMembers;
          $ctrl.webhookDateFieldFormats = OrganizationsService.getWebhookDateFieldFormats();
          $ctrl.onCadenceDayChange = onCadenceDayChange;
          $ctrl.getNextChecklist = getNextChecklist;
          $ctrl.onChecklistSelected = onChecklistSelected;
          $ctrl.onChecklistDropdownOpenHandler = onChecklistDropdownOpenHandler;
          $ctrl.onBoardingTemplates = [{ id: null, label: $filter('translate')('orgProfile.placeholder.defaultEmptyOnBoardingLabel') }];
          windowElem.on('resize', onWindowResize);

          $ctrl.slider = {
            options: {
              stepsArray: workHoursArr,
              showTicks: true,
              noSwitching: true,
              ticksTooltip: function(v) {
                return workHoursArr[v];
              },
              translate: function(value, sliderId, label) {
                switch (label) {
                  case 'model':
                    return value + ' Hrs';
                  case 'high':
                    return value + ' Hrs';
                  default:
                    return value;
                }
              },
              onChange: function() {
                $ctrl.updateOrgForm.$pristine = false;
              }
            }
          };

          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization () {
            onWindowResize();
            $ctrl.organization = $rootScope.identity.default_organization;
            $ctrl.orgId = $ctrl.organization.id;
            loadData();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            var weekOptions = prepareDropdownValues(), monthOptions = prepareMonthsDropdown();
            $ctrl.dropdownOptions = weekOptions;
            $ctrl.monthDropdownOptions = monthOptions;
            getNextChecklist();
          }

          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges () { }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy () {
            unregisterStateListener();
            windowElem.off('resize', onWindowResize);
          }

          //Window resize listener
          function onWindowResize () {
            $ctrl.isMobile = !!($window.innerWidth < 992);
          }
          
          /**
           * @ngdoc method
           * @name loadData
           * @private
           * @description Get industries and organization data
           */
          function loadData () {
            blockUI.start();
            OrganizationsService.get().then(function (response) {
              $ctrl.defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
              var deadlineValue = _.get($ctrl.defaultDeadline, 'value', 5),
                deadlineType = _.get($ctrl.defaultDeadline, 'type', "days");
              angular.extend(response.data, { default_deadline: { value: deadlineValue, type: deadlineType } } );
              setOrganizationInfo(response.data);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }
          
          /**
           * @ngdoc method
           * @name setOrganizationInfo
           * @description set organization information
           * @param {Object} data
           * @returns {Object}
          */
          function setOrganizationInfo (data) {
            $ctrl.org = getOrgazationInfo(data);
            $ctrl.defualtOrganization = angular.copy($ctrl.org);
            setWorkWeekData();
            setAutoArchiveWeekNumber();
            setAutoCompleteMonths();
            if ($ctrl.org.onboarding_template_id) {
              getChecklistData();
            }
          }

          function getChecklistData() {
            ProcessService.get({
              id: $ctrl.org.onboarding_template_id,
              skipNotFound: true
            }).then(function(res) {
              $ctrl.selectedChecklist = res.data;
            })
          }

          /**
           * @ngdoc method
           * @name setWorkWeekData
           * @description Set Work Week Data
           */
          function setWorkWeekData() {
            $ctrl.workingDaysLength = _.size($ctrl.org.working_days) || 7;
            $ctrl.weekdaysWithHours = angular.copy(weekdaysWithHours);
            _.merge($ctrl.weekdaysWithHours, $ctrl.org.working_days);
          }

          /**
           * @ngdoc method
           * @name getOrgazationInfo
           * @description return some record from all object
           * @param {Object} data
           * @returns {Object}
          */
          function getOrgazationInfo (data) {
            return _.pick(data, ['name', 'address1', 'address2', 'industry', 'description', 'city', 'state', 'zipcode', 'country', 
            'allow_user_invite', 'wyiwyg', 'working_days', 'timezone', 'default_deadline', 'cadence_days', 'guest_cadence_days', 
            'allow_manage_groups', 'auto_archive', 'auto_archive_processes_after', 'auto_complete_tasks', 'auto_complete_task_months',
              'webhook_date_field_format', 'restrict_blueprint_permissions', 'sso_default_role', 'enable_onboarding_template', 'onboarding_template_id']);
          }

          /**
           * @ngdoc method
           * @name updateOrganizations
           * @description Update organization record
           * @returns {Object}
          */
          function updateOrganizations () {
            if(!Helper.checkAccessAuthority())
              return;
            var deferred = $q.defer();
            if ($ctrl.updateOrgForm.$invalid) {
              return;
            }
            blockUI.start();
            var selectedOption = _.get($ctrl.autoArchiveProcessesAfter, 'label', $ctrl.autoArchiveProcessesAfter),
             row = _.find($ctrl.dropdownOptions, { label: selectedOption });
            if (row) {
              $ctrl.org.auto_archive_processes_after = _.omit(row, ['name', 'label']);
            } else {
              $ctrl.org.auto_archive_processes_after = { value: 52, unit: 'weeks' };
            }
            $ctrl.org.working_days = OrganizationsService.getWorkdaysWithHoursForPayload($ctrl.weekdaysWithHours, $ctrl.workingDaysLength);
            setAutoArchiveWeekNumber();
            setAutoCompleteMonths();
            OrganizationsService.update(null, $ctrl.org).then( function (response) {
              setOrganizationInfo(response.data);
              $rootScope.identity.default_organization.name = _.get(response.data, 'name');
              $rootScope.identity.default_organization.auto_archive = _.get(response.data, 'auto_archive');
              $rootScope.identity.default_organization.auto_archive_processes_after = _.get(response.data, 'auto_archive_processes_after', { value: 52, unit: 'weeks', label: '52w' });
              $rootScope.identity.default_organization.auto_complete_tasks = _.get(response.data, 'auto_complete_tasks');
              $rootScope.identity.default_organization.auto_complete_task_months = _.get(response.data, 'auto_complete_task_months', 18);
              angular.extend( $rootScope.identity.default_organization, { default_deadline: { value: response.data.default_deadline.value, type: response.data.default_deadline.type } } );
              growl.success($filter('translate')('orgProfile.messages.organizationSaved'), { referenceId: 'myAccount', disableIcons: true, disableCloseButton: true });
              resetForm();
              blockUI.stop();
              deferred.resolve({'status':'Saved'});
            }, function () {
              blockUI.stop();
              deferred.reject({'status':'NotSaved'});
            });
            return deferred.promise;
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @private
           * @description Reset form
           * @returns {Void}
           */
          function resetForm () {
            $ctrl.org = angular.copy($ctrl.defualtOrganization);
            setWorkWeekData();
            $ctrl.updateOrgForm.$setPristine();
            $ctrl.updateOrgForm.$setUntouched();
          }

          /**
           * @ngdoc method
           * @name saveDefaultDeadlinePreferences
           * @description Save/update preference
           */
          function saveDefaultDeadlinePreferences() {
            $ctrl.updateOrgForm.$pristine = false;
          }

          function toggleAutoArchive() {
            setAutoArchiveWeekNumber();
          }

          function setAutoArchiveWeekNumber() {
            if ($ctrl.org.auto_archive) {
              if (_.get($ctrl.org.auto_archive_processes_after, 'value')) {
                var archive_label = $ctrl.org.auto_archive_processes_after.value + ($ctrl.org.auto_archive_processes_after.unit === 'weeks' ? 'w' : 'd');
                $ctrl.autoArchiveProcessesAfter = _.find($ctrl.dropdownOptions, { label: archive_label });
              } else {
                $ctrl.autoArchiveProcessesAfter = _.find($ctrl.dropdownOptions, { label: '52w' });
              }
            }
          }

          function prepareDropdownValues() {
            var options = [];
            for (var j = 1; j <= 7; j++) {
              options.push({
                name: $filter('translate')('orgProfile.label.after') + ' ' + j + ' ' + (j === 1 ? $filter('translate')('orgProfile.label.day') : $filter('translate')('orgProfile.label.days')),
                value: j,
                unit: 'days',
                label: j+'d'
              });
            }
            for (var i = 1; i <= 90; i++) {
              var obj = {
                name: $filter('translate')('orgProfile.label.after') + ' ' + i + ' ' + (i === 1 ? $filter('translate')('orgProfile.label.week') : $filter('translate')('orgProfile.label.weeks')),
                value: i,
                unit: 'weeks',
                label: i+'w'
              };
              options.push(obj);
            }
            return options;
          }

          //Toggle auto complete tasks
          function toggleAutoComplete () {
            setAutoCompleteMonths();
          }

          function toggleOnBoardingNewMembers() {
            $ctrl.org.onboarding_template_id = null;
          }

          //Set auto complete months
          function setAutoCompleteMonths () {
            $ctrl.org.auto_complete_task_months = $ctrl.org.auto_complete_tasks && $ctrl.org.auto_complete_task_months ? $ctrl.org.auto_complete_task_months : 18;
          }

          //Prepare month dropdown values
          function prepareMonthsDropdown () {
            var options = [];
            for (var i = 2; i <= 24; i += 2) {
              var obj = {
                name: i + ' ' + $filter('translate')('orgProfile.label.overDeadline'),
                value: i
              };
              options.push(obj);
            }
            return options;
          }

          //Handle guest cadence day change
          function onCadenceDayChange (day, type) {
            var mapping = {
              'member': 'cadence_days',
              'guest': 'guest_cadence_days'
            },
            isSelected = _.includes($ctrl.org[mapping[type]], day), position = $ctrl.org[mapping[type]].indexOf(day);

            isSelected ? $ctrl.org[mapping[type]].splice(position, 1) : $ctrl.org[mapping[type]].push(day);

            $ctrl.updateOrgForm.$pristine = false;
          }

          function onChecklistSelected(item) {
            $ctrl.org.onboarding_template_id = item.id;
            $ctrl.updateOrgForm.$setDirty();
          }

          function getNextChecklist(args) {
            var init = _.get(args, 'init', false);
            if (init) {
              $ctrl.displayedChecklists = [];
              $ctrl.allBlueprintsLoaded = false;
              pagination = {
                page: 1,
                per_page: 20
              };
            }
            var defer = $q.defer();
            if ($ctrl.allBlueprintsLoaded) {
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
              return defer.promise;
            }
            $ctrl.onBlueprintsLoading = true;
            var params = angular.extend({
              sort: 'title',
              with: 'tags',
              append: 'steps_count',
              q: _.get(args, 'query')
            }, pagination);
            ProcessService.filter(params).then(function (res) {
              var data = _.uniqBy(_.concat($ctrl.displayedChecklists || [], res.data), 'id'),
                tempDisplayedChecklists = _.filter(data || [], isBlueprintLaunchAble);
              $ctrl.displayedChecklists = _.concat([{ id: null, title: $filter('translate')('orgProfile.placeholder.defaultEmptyOnBoardingLabel') }], tempDisplayedChecklists);
              res.meta.pagination.current_page !== res.meta.pagination.total_pages ? pagination.page++ : $ctrl.allBlueprintsLoaded = true;
              $ctrl.onBlueprintsLoading = false;
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
            }, function () {
              $ctrl.onBlueprintsLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function isBlueprintLaunchAble(bp) {
            return ((bp.steps_count > 0 && bp.type === BLUEPRINT_TYPE.PROCEDURE)
              || (bp.steps_count === 0 && bp.prerun.length && bp.type === BLUEPRINT_TYPE.FORM))
              && !(bp.type === BLUEPRINT_TYPE.DOCUMENT)
              && !(_.filter(bp.prerun || [], { required: true }).length > 0);
          }

          function onChecklistDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxChecklistOpen = false;
            }
          }

          /**
           * Event handler when user navigates to another tab/state
           * @description It will send form name to 'confirmOnExit" factory to get confirmation from user 
           * wether would like to save changes or not. 
           * Based on user input, it will perform saving or discarding of chaged details in form.
           * @type {*|(function())}
          */
          var unregisterStateListener = $rootScope.$on('$stateChangeStart',
            function (event, toState, toParams, fromState, fromParams) {
              if (angular.isDefined($ctrl.updateOrgForm) && !$ctrl.updateOrgForm.$pristine) {
                event.preventDefault();
                var chekingstatus = ConfirmOnExitService.checkStatus($ctrl.updateOrgForm);
                chekingstatus.then(function (data) {
                  if (data.status == 'YES') {
                    $ctrl.updateOrgForm.$submitted = true;
                    var getstatus = $ctrl.updateOrganizations();
                    getstatus.then(function (data) {
                      if (data.status == 'Saved') {
                        $state.go(toState);
                        $ctrl.resetForm();
                      }
                    });
                  } else if (data.status == 'NO') {
                    $ctrl.resetForm();
                    $state.go(toState);
                  }
                });
              }
          });
  }
})();

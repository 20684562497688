/**
 * @ngdoc Component
 * @name tallyfy.account.component.authHandler
 * @module tallyfy.account
 *
 * @description
 * A component to login new account
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('authHandler', {
      template: '<div block-ui=\'authHandler\'></div>',
      controller:
        /*@ngInject*/
        function ($rootScope, $state, Auth, Growl, _, blockUI, $filter, OrganizationsService, $q, Principal, AccountService, Helper, $window, $uibModal,
          preferencesStore, DateUtils, moment) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('authHandler');

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            blockUI.start();

            var stateParams = $state.params || {};
            if (_.get(stateParams, 'access_token')) {
              var token = _.pick(stateParams, ['access_token', 'expires_in', 'refresh_token', 'token_type']);
              Auth.loginByToken(token).then(loginSuccessHandler, loginFailureHandler);
            } else if (_.get(stateParams, 'redirect_url')) {
              $window.location.replace(_.get(stateParams, 'redirect_url'));
            } else {
              var loginPageURL = ($rootScope.orgSamlInfo && $rootScope.orgSamlInfo.saml_enabled && $rootScope.orgSamlInfo.saml_login_url) ? $rootScope.orgSamlInfo.saml_login_url : Helper.loginURL;
              $window.location.replace(loginPageURL);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function loginFailureHandler
           * @private
           * an helper function that handles failure block of login promise
           * @param error
           */
          function loginFailureHandler(error) {
            blockUI.stop();
            var dataErrors = _.get(error, 'data.errors'),
              errorMessages = dataErrors ? _.head(dataErrors)[0] : $filter('translate')(error.data.message);
            showGrowlMessage(errorMessages);
          }

          /**
           * @function
           * @private
           * an helper function that handles success block of login promise
           */
          function loginSuccessHandler() {
            Helper.clearUniversalAlert('growlNotification');
            getUserData();
          }

          /**
           * @function
           * @name getUserData
           * @description get user datas such as user information and organization
           */
          function getUserData() {
            $q.all([
              AccountService.me(),
              OrganizationsService.myOrganizations({ with: 'subscription' })
            ]).then(function (response) {
              var user = _.get(response, '[0].data'),
                orgs = _.get(response, '[1].data');
              !_.isArray(orgs) ? orgs = [] : '';
              setSession(user, orgs);
            }).catch(function (error) {
              blockUI.stop();
              if (error.status === 400 && (_.get(error, 'data.code') === 'ACCOUNT_DISABLED')) {
                $state.go('account-disabled', { args: error.data });
              }
            });
          }

          /**
           * @function
           * @name setSession
           * @description set user data into user identity
           * @param {*} user
           * @param {*} orgs
           */
          function setSession(user, orgs) {
            var identity, orgsCount = orgs.length;

            if (!_.isEmpty(user)) {
              identity = user;
              identity.authorities = ['USER_ROLE'];

              /*@TODO: Uncomment this when roles & permissions are back*/
              /*identity.permissions = [];
              identity.preferences = [];*/

              //set user identity
              Principal.authenticate(identity);

              // Redirect to org creation if no org exists
              if (!orgsCount) {
                $state.go('organizations.create');
                return;
              }

              //check for redirection before unauthenticated access
              var previousState = Auth.getPreviousState(), isRedirect = false;
              if (_.get(previousState, 'name') && _.get(previousState, 'params.org_id')) {
                var index = _.findIndex(orgs, { 'id': previousState.params.org_id });
                if (index > -1) {
                  var redirectOrg = orgs[index];
                  orgs.splice(index, 1);
                  orgs.unshift(redirectOrg);
                  isRedirect = true;
                }
              }
              if (isRedirect) {
                setOrganization(orgs, identity, previousState);
              } else {
                identity.default_organization = orgsCount === 1 ? orgs[0] : null;
                orgsCount === 1 ? setOrganization(orgs, identity) : $state.go('organizations.list');
              }
            } else {
              blockUI.stop();
            }
          }

          /**
           * @function
           * @name setOrganization
           * @description ser user organizations
           * @param {*} orgs
           * @param {*} identity
           * @param {*} previousState
           */
          function setOrganization(orgs, identity, previousState) {
            Principal.setOrganization(orgs).then(function () {
              $rootScope.$broadcast('authenticationSuccess');
              if (previousState) {
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
              } else {
                handleOtherRedirections(identity);
              }
            }, function () {
              blockUI.stop();
            });
          }

          function handleOtherRedirections(identity) {
            if (identity.approved_at && (Helper.getDateDiffInMinutes(identity.approved_at) <= 60)) {
              checkForWelcomeSnippet(identity);
            } else {
              redirecToDash(identity);
            }
          }

          function checkForWelcomeSnippet(identity) {
            var welcomeMessageSnippetId = _.get($rootScope.identity, 'default_organization.onboarding_snippet');
            if (welcomeMessageSnippetId) {
              OrganizationsService
                .getTextTemplate(welcomeMessageSnippetId, true)
                .then(function (res) {
                  if (!$ctrl.organizationTemplates) {
                    $ctrl.organizationTemplates = {};
                  }
                  $rootScope.organizationTemplates[welcomeMessageSnippetId] = res.data;
                  goToWelcomeModal(identity);
                });
            } else {
              goToWelcomeModal(identity);
            }
          }

          function goToWelcomeModal(identity) {
            var modalInstance = $uibModal.open({
              component: 'welcomeModal',
              windowClass: 'welcome-modal',
              backdrop: 'static',
              size: 'lg'
            });
            modalInstance.result.then(function () {
              checkForOnboardingRun(identity);
            }, function () {
              checkForOnboardingRun(identity);
            });
          }

          function checkForOnboardingRun(identity) {
            preferencesStore.getPreferences(_.get(identity, 'id')).then(function (res) {
              var userPreferences = res,
                alreadyLoginAttempt = AccountService.getPreference(userPreferences, 'hasRedirectedToOnboardingRun', 'no');
              if (!alreadyLoginAttempt || alreadyLoginAttempt === 'no') {
                var currentDateTime = DateUtils.toUTC(moment()).format(),
                  activatedAt = DateUtils.toUTC(moment(identity.activated_at).add(5, 'minutes')).format(),
                  lastLoginAt = DateUtils.toUTC(_.get(identity, 'last_login_at')).format(),
                  isRedirectToOnboarding = ((!_.isDate(lastLoginAt) || lastLoginAt <= activatedAt) && currentDateTime <= activatedAt);
                if (isRedirectToOnboarding) {
                  var searchText = _.get(identity, 'first_name') + "'s Onboarding";
                  OrganizationsService.globalSearch({
                    org: _.get(identity, 'default_organization.id'),
                    on: 'process',
                    search: searchText,
                    per_page: 999
                  }).then(function (response) {
                    if (_.get(response, 'process.data').length !== 0) {
                      var processList = _.get(response, 'process.data', []),
                        process = _.find(processList, { name: searchText });
                      AccountService.setUserPreference({
                        slug: 'hasRedirectedToOnboardingRun',
                        value: 'yes'
                      }).then(function (response) {
                        if (process) {
                          preferencesStore.updatePreferences(response.data);
                          $state.go('run.view', { org_id: _.get(identity, 'default_organization.id'), id: process.id });
                        }
                      }, function () {
                        redirecToDash(identity);
                      });
                    } else {
                      redirecToDash(identity);
                    }
                  }, function () {
                    redirecToDash(identity);
                  });
                } else {
                  AccountService.setUserPreference({
                    slug: 'hasRedirectedToOnboardingRun',
                    value: 'yes'
                  }).then(function (response) {
                    preferencesStore.updatePreferences(response.data);
                    redirecToDash(identity);
                  }, function () {
                    redirecToDash(identity);
                  });
                }
              } else {
                redirecToDash(identity);
              }
            });
          }

          function redirecToDash(identity) {
            $state.go('dashboard', {
              org_id: _.get(identity, 'default_organization.id')
            });
          }

          /**
           * @name showGrowlMessage
           * @param {*} message
           * 
           * @description
           * To display growl message in login page
           */
          function showGrowlMessage(message) {
            growl.error(message, {
              referenceId: 'loginController',
              disableIcons: true,
              disableCloseButton: true
            });
          }
        }
    });
})();
/**
 * @ngdoc overview
 * @name tallyfy.search
 *
 * @module tallyfy.search
 *
 * @description
 * manage global search
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy.search', ['tallyfy.core']);
})();

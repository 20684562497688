(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.notifications.service.NotificationsService
   * @module tallyfy.notifications
   *
   * @description
   * NotificationsService manages new notifications and read notifications
   *
   * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
   *
   */
  angular
    .module('tallyfy.notifications')
    .service('NotificationsService',
      /*@ngInject*/
      function NotificationsService(_, Pubnub, moment, $q, NOTIFICATIONCOUNT, $state, $rootScope, DateUtils, DATEFORMAT, TasksService) {

        /**
         * store ProcessService context in self
         * JavaScript has block level context So it can be changed in nested
         * function
         */
        var self = this,
          readNotifications = [],
          unreadNotifications = 0,
          notifications = [];

        /**
         * @function
         * @name markRead
         * @description Mark a specific notification as read
         * @param notify_id
         * @param read_channel
         * @param timetoken
         */
        self.markRead = function (notify_id, read_channel, timetoken) {
          var defer = $q.defer();
          Pubnub.publish({
            message: notify_id,
            channel: read_channel,
            timetoken: timetoken
          }).then(function (res) {
            defer.resolve(res);
          }, function (error) {
            defer.reject(error);
          });
          return defer.promise;
        };

        /**
         *
         * @function
         * @name getReadNotifications
         * @description Get notifications from Pubnub history
         */
        self.getNotifications = function (channel) {
          var defer = $q.defer();
          notifications = [];
          Pubnub.history({
            channel: channel,
            count: NOTIFICATIONCOUNT.HISTORY_COUNT
          }).then(function (response) {
            if (response) {
              notifications = prepareResponse(response);
              defer.resolve(notifications);
            } else {
              defer.resolve([]);
            }
          }, function (error) {
            defer.reject(error);
          });
          return defer.promise;
        };
        /**
         * @function prepareResponse
         * @description An helper function
         * prepare notification 
         * parse time to UTC
         * added time_string, defaultAvatar, defaultAvatarText
         * @param {*} messages An array of raw notifications
         * @returns Array
         */
        function prepareResponse(messages) {
          if (!_.get(messages, 'length')) {
            return [];
          }
          return (_.filter(messages, function (item) {
            return item.message;
          })).map(function (item) {
            var name = _.split(_.get(item, 'message.author.full_name'), ' ');
            item.message.time = moment.utc(item.message.time);
            var message = _.merge({}, item.message, {
              id: item.id,
              timetoken: item.timetoken,
              time_string: DateUtils.toLocal(moment(item.message.time).format()).format(DATEFORMAT.ABSOLUTE),
              defaultAvatar: TasksService.setDefaultAvatar(item.message.author.profile_pic),
              defaultAvatarText: TasksService.setDefaultAvatarText(name[0], name[1])
            });
            return message;
          });
        }

        /**
         * @function
         * @name getReadNotifications
         * @description get read notifications
         */
        self.getReadNotifications = function (read_channel) {
          var defer = $q.defer();
          readNotifications = [];
          Pubnub.history({
            channel: read_channel
          }).then(function (response) {
            if (response && response.length) {
              _.forEach(response, function (item) {
                readNotifications.push(item.message);
              });
              defer.resolve(readNotifications);
            } else {
              defer.resolve([]);
            }
            readNotifications = [];
          }, function (error) {
            defer.reject(error);
          });
          return defer.promise;
        };

        /**
         * @function
         * @name redirectToView
         * @description Redirect to run/task view by id
         * @param {Object} subject
         * @param {Object} contextType
         */
        self.redirectToView = function (subject, contextType) {
          var type = _.toLower(_.get(subject, 'type', '')),
            org_id = _.get($rootScope, 'identity.default_organization.id');
          if (!type) {
            $state.go('notificationsList', { org_id: org_id });
          } else if (type === 'run') {
            $state.go('run.view', { id: subject.id });
          } else if (type === 'task') {
            subject.run_id
              ? $state.go('run.view', {
                id: subject.run_id,
                activeTask: subject.id,
                comment: subject.comment_id
              }) : $state.go('home', {
                comment: subject.comment_id,
                assignee: 'anyone',
                activeTask: subject.id,
                status: contextType === 'completed_task' ? 'completed' : (contextType === 'assigned_you_a_task' ? "to-do" : angular.noop()),
                sortBy: contextType === 'completed_task' ? 'completed_newest' : (contextType === 'assigned_you_a_task' ? "newest" : angular.noop())
              });
          } else if (type === 'step') {
            $state.go('process.edit', { slug: subject.template_id, view: 'edit', step: subject.alias, comment: subject.comment_id });
          } else if (_.toLower(type) === 'checklist') {
            $state.go('process.edit', { slug: subject.id, view: 'edit' }, { absolute: true });
          }
        };

        self.getUnreadNotifications = function () {
          return unreadNotifications;
        };

        self.setUnreadNotifications = function (value) {
          unreadNotifications = value;
        };
      });
})();

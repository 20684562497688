/**
 * @ngdoc factory
 * @name tallyfy.factory.Growl
 * @module tallyfy
 *
 * @description
 * factory to manage timeZones
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('Growl', Growl);

  Growl.$inject = ['growl', 'growlMessages'];
  /**
   * Growl
   * constructor
   */
  function Growl(growl, growlMessages) {
    function construct(reference) {
      var self = this;
      self.reference = reference;

      self.error = function (message, config, severity) {
        config = config || {};
        severity = severity || '';
        config.referenceId = config.referenceId || self.reference;
        growl.error(message || [], config, severity)
      };

      self.success = function (message, config, severity) {
        config = config || {};
        severity = severity || '';
        config.referenceId = config.referenceId || self.reference;
        growl.success(message || [], config, severity)
      };

      self.info = function (message, config, severity) {
        config = config || {};
        severity = severity || '';
        config.referenceId = config.referenceId || self.reference;
        growl.info(message || [], config, severity)
      };

      self.warning = function (message, config, severity) {
        config = config || {};
        severity = severity || '';
        config.referenceId = config.referenceId || self.reference;
        growl.warning(message || [], config, severity)
      };
    }
    construct.clearAllMessages = function (referenceId) {
      growlMessages.destroyAllMessages(referenceId);
    };
    construct.getAllMessages = function (referenceId) {
      return growlMessages.getAllMessages(referenceId);
    };

    return construct;
  }
})();

/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for myAccount component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    //$urlRouterProvider.when('/manage/access', '/manage/access/update');
    //$urlRouterProvider.when('/manage/access/', '/manage/access/update');

   /* TODO It can be unhide in future version */
   /* $stateProvider.state('settings.access.groups', {
      url: '/groups',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_access_view@settings.access': {
          component: 'groups'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('groups');
          return $translate.refresh();
        }],
        users: ['UsersService', function (UsersService) {
          return UsersService.getAvailableUsers();
        }]
      }
    }); */
  }
})();
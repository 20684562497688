(function () {
  'use strict';
  angular.module('tallyfy')
    .component('runTableList', {
      templateUrl: 'app/modules/runs/dashboard/runTableList/run-table-list.component.html',
      bindings: {
        filterParams: '<',
        usersInOrg: '<',
        metadata: '='
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $filter, $timeout, moment, RunRepository, RunsService, RunsTableService, DateUtils, Helper, $stateParams, OrganizationsService) {
          var $ctrl = this,
            pagination = {
              page: 1,
              per_page: 20
            }, identity = $rootScope.identity;

          $ctrl.displayedChecklists = [];
          $ctrl.isSelectBoxChecklistOpen = false;
          $ctrl.pagination = {};

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.getChecklists = getChecklists;
          $ctrl.onChecklistSelected = onChecklistSelected;
          $ctrl.getRunData = getRunData;
          $ctrl.initTableConfig = initTableConfig;
          $ctrl.calculateFilterEntities = calculateFilterEntities;
          $ctrl.loadMoreData = loadMoreData;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() { }

          function onChanges(changes) {
            if (changes.filterParams && changes.filterParams.currentValue) {
              $ctrl.displayedChecklists = [];
              $ctrl.selectedChecklist = {};
              $ctrl.allChecklistsLoaded = false;
              pagination.page = 1;
              getChecklists(null, true);
            }
          }

          function onDestroy() { }

          function getChecklists(args, isFirstLoad) {
            var init = _.get(args, 'init', false);
            if (init) {
              $ctrl.displayedChecklists = [];
              $ctrl.allChecklistsLoaded = false;
              pagination = {
                page: 1,
                per_page: 20
              };
            }
            if (isFirstLoad) {
              $ctrl.isFirstLoadCompleted = false;
            }
            var defer = $q.defer();
            if ($ctrl.allChecklistsLoaded) {
              defer.resolve({ allItemLoaded: $ctrl.allChecklistsLoaded });
            } else {
              $ctrl.onBlueprintsLoading = true;
              $ctrl.metadata.isBusy = true;
              RunsService.getRunsByChecklist(angular.extend({
                page: pagination.page,
                per_page: pagination.per_page,
                q: _.get(args, 'query')
              }, $ctrl.filterParams)).then(function (res) {
                var data = _.uniqBy(_.concat($ctrl.displayedChecklists || [], res.data), 'id');
                $ctrl.displayedChecklists = data || [];
                res.meta.pagination.current_page !== res.meta.pagination.total_pages ? pagination.page++ : $ctrl.allChecklistsLoaded = true;
                $ctrl.onBlueprintsLoading = false;
                if ($ctrl.displayedChecklists.length && isFirstLoad) {
                  if ($ctrl.displayedChecklists.length) {
                    var selectedChecklist = _.find($ctrl.displayedChecklists, { id: _.get($stateParams, 'checklist') });
                    getChecklistFormFields(isFirstLoad, selectedChecklist ? selectedChecklist : $ctrl.displayedChecklists[0]);
                  } else {
                    if (isFirstLoad) {
                      $ctrl.isFirstLoadCompleted = true;
                    }
                  }
                } else {
                  if (isFirstLoad) {
                    $ctrl.isFirstLoadCompleted = true;
                  }
                }
                $ctrl.metadata.isBusy = false;
                defer.resolve({ allItemLoaded: $ctrl.allChecklistsLoaded });
              }, function () {
                $ctrl.onBlueprintsLoading = false;
                $ctrl.metadata.isBusy = false;
                defer.reject();
              });
            }
            return defer.promise;
          }

          function getChecklistFormFields(isFirstLoad, checklist) {
            $ctrl.selectedChecklist = checklist;
            RunsService.getChecklistFormFields({ id: checklist.id })
              .then(function (res) {
                $ctrl.formFields = res.data.form_fields;
                $ctrl.koFormFields = res.data.ko_form_fields;
                if (isFirstLoad) {
                  $ctrl.isFirstLoadCompleted = true;
                }
                $ctrl.formFieldLoading = false;
              });
          }

          function onChecklistSelected(item) {
            $ctrl.formFieldLoading = true;
            $ctrl.selectedChecklist = void 0;
            $ctrl.tableOptions = {};
            $timeout(function () {
              getChecklistFormFields(false, !item ? {} : item);
            }, 100);
          }

          function getRunData(args) {
            var defer = $q.defer();
            $ctrl.metadata.isBusy = true;
            $ctrl.pagination = {
              page: !args ? 1 : args.current_page + 1,
              per_page: 20
            };
            var params = angular.extend($ctrl.pagination, {
              with: 'tasks_meta,folders',
              checklist_id: $ctrl.selectedChecklist.id,
              form_fields_values: true
            }, $ctrl.filterParams);
            RunRepository.getRunsData(params)
              .then(function (res) {
                $ctrl.pagination = res.meta.pagination;
                $ctrl.metadata.total = res.meta.pagination.total;
                var tempRunData = mapRunData(res.data);
                $ctrl.runData = _.concat($ctrl.runData, tempRunData);
                $ctrl.metadata.isBusy = false;
                defer.resolve(res.data);
              }, function (err) {
                $ctrl.metadata.isBusy = false;
                defer.reject(err);
              });
            return defer.promise;
          }

          function mapRunData(runData) {
            return _.map(runData, function (run) {
              if (run.users.length === 0) {
                run.users.push(run.owner_id);
              }
              var runStatusObj = getRunStatusObj(run);
              run.statusClassDisplay = runStatusObj.statusClass;
              run.name_escaped = run.name.replace(/'/g, "\\'");
              run.statusDisplay = runStatusObj.runStatus;
              run.startDateDisplay = moment(DateUtils.toLocal(run.started_at)).format($ctrl.dateFormat);
              run.lastUpdatedDisplay = moment(DateUtils.toLocal(run.last_updated)).format($ctrl.dateFormat);
              run.nextTaskDisplay = getRunMetaDataProperty(run, 'data.next_task.title');
              run.currentTaskTitleDisplay = getRunMetaDataProperty(run, 'data.current_task.title');
              run.lastCompletedTaskTitleDisplay = getRunMetaDataProperty(run, 'data.last_completed_task.title');
              run.nextTaskHasIssue = isNextTaskHaveIssue(run);
              return run;
            });
          }

          function loadMoreData() {
            kendo.ui.progress($ctrl.tableOptions.tableElement, true);
            getRunData($ctrl.pagination)
              .then(function (res) {
                $ctrl.tableOptions.gridConfig.dataSource.data($ctrl.runData);
                kendo.ui.progress($ctrl.tableOptions.tableElement, false);
              }, function () {
                kendo.ui.progress($ctrl.tableOptions.tableElement, false);
              });
          }

          function calculateFilterEntities(options) {
            var data = options.gridConfig.dataSource.data();
            options.templateScope.variables.statusList = _.uniqBy(
              _.map(data, function (process) {
                var runStatus = RunsService.getViewHelperRunStatus(process);
                return { value: runStatus.statusId, label: runStatus.runStatus };
              }),
              'value');
            options.templateScope.variables.startedByList = _.uniqBy(
              _.map(data, function (process) {
                return {
                  value: process.started_by,
                  label: (_.find(options.templateScope.variables.usersInOrg, { id: process.started_by }) || {}).text
                };
              }),
              'value');

            options.templateScope.variables.processOwnerList = _.uniqBy(
              _.map(data[0].users, function (userId) {
                return {
                  value: userId,
                  label: (_.find(options.templateScope.variables.usersInOrg, { id: userId }) || {}).text
                };
              }),
              'value');

            options.templateScope.variables.assigneeList = [];
            options.templateScope.variables.nextStepAssigneeList = [];
            _.forEach(data, function (process) {
              var guests = _.get(process, 'tasks_meta.data.all_assignees.guests', []),
                members = _.get(process, 'tasks_meta.data.all_assignees.users', []),
                nextStepGuests = _.get(process, 'tasks_meta.data.next_task.owners.guests', []),
                nextStepMembers = _.get(process, 'tasks_meta.data.next_task.owners.users', []);
              _.forEach(guests, function (guest) {
                var isExist = _.find(options.templateScope.variables.assigneeList, { value: guest });
                if (!isExist) {
                  options.templateScope.variables.assigneeList.push({
                    value: guest,
                    label: guest
                  });
                }
              });
              _.forEach(members, function (member) {
                var isExist = _.find(options.templateScope.variables.assigneeList, { value: member });
                if (!isExist) {
                  var data = _.find(options.templateScope.variables.usersInOrg, { id: member });
                  if (data) {
                    options.templateScope.variables.assigneeList.push({
                      value: data.id,
                      label: data.text
                    });
                  }
                }
              });
              _.forEach(nextStepGuests, function (guest) {
                var isExist = _.find(options.templateScope.variables.nextStepAssigneeList, { value: guest });
                if (!isExist) {
                  options.templateScope.variables.nextStepAssigneeList.push({
                    value: guest,
                    label: guest
                  });
                }
              });
              _.forEach(nextStepMembers, function (member) {
                var isExist = _.find(options.templateScope.variables.nextStepAssigneeList, { value: member });
                if (!isExist) {
                  var data = _.find(options.templateScope.variables.usersInOrg, { id: member });
                  if (data) {
                    options.templateScope.variables.nextStepAssigneeList.push({
                      value: data.id,
                      label: data.text
                    });
                  }
                }
              });
            });
            options.templateScope.variables.titleTooltipDirection = data.length === 1 ? 'right right-tooltip' : 'down';
          }

          function getRunStatusObj(run) {
            var runViewStatus = RunsService.getViewHelperRunStatus(run);
            run.status_filter = runViewStatus;
            return runViewStatus;
          }

          function getAssigned(uid) {
            var run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }),
              assignees = _.get(run.tasks_meta, 'data.all_assignees.users');
            run.assigned_filter = _.get(run.tasks_meta, 'data.all_assignees');
            return assignees;
          }

          function getUsers(uid) {
            var run, users;
            run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            users = _.get(run, 'users');
            run.users = _.get(run, 'users');
            return users;
          }

          function getNextTaskAssigned(uid) {
            var run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            run.next_step_assigned_filter = _.get(run.tasks_meta, 'data.next_task.owners');
            return _.get(run.tasks_meta, 'data.next_task.owners.users');
          }

          function getGuestAssigned(uid) {
            var run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return _.get(run.tasks_meta, 'data.all_assignees.guests');
          }

          function getNextTaskGuestAssigned(uid) {
            var run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return _.get(run.tasks_meta, 'data.next_task.owners.guests');
          }

          function isNextTaskHaveIssue(run) {
            var threads = _.get(run, 'tasks_meta.data.next_task.threads.data', []),
              isHasIssue = !!_.find(threads, { label: 'problem' });
            run.next_step_issue = isHasIssue;
            return isHasIssue;
          }

          function initTableConfig() {
            $ctrl.runData = [];
            $ctrl.pagination = {};
            $ctrl.tableOptions = {};
            getRunData()
              .then(function (res) {
                $ctrl.tableOptions = getTableConfig();
              });
          }

          function getRunMetaDataProperty(run, property) {
            run.next_step = run.tasks_meta.data.next_task.title;
            run.current_step = _.get(run, 'tasks_meta.data.current_task.title', '');
            run.last_completed_step = run.tasks_meta.data.last_completed_task.title;
            return _.get(run.tasks_meta, property, '').replace(/'/g, "\\'");
          }

          function getTableConfig() {
            var formFieldAsColumns = [];
            $ctrl.formFields = $ctrl.formFields || [];
            for (var i = 0; i < $ctrl.formFields.length; i++) {
              formFieldAsColumns.push({
                field: $ctrl.formFields[i].id,
                fieldType: $ctrl.formFields[i].field_type,
                title: $ctrl.formFields[i].label,
                source: 'form_field',
                width: 260,
                stepId: $ctrl.formFields[i].step_id,
                hidden: true
              });
            }
            var koFormFieldAsColumns = [];
            for (var i = 0; i < _.get($ctrl, 'koFormFields', []).length; i++) {
              koFormFieldAsColumns.push({
                field: $ctrl.koFormFields[i].id,
                fieldType: $ctrl.koFormFields[i].field_type,
                title: $ctrl.koFormFields[i].label,
                source: 'ko_form_field',
                width: 260,
                hidden: true
              });
            }
            var hideAndShowColumns = [{
              field: "name",
              title: $filter('translate')('runs.table.name'),
              initState: true
            }, {
              field: "progress",
              title: $filter('translate')('runs.table.progress'),
              initState: true
            }, {
              field: "status",
              title: $filter('translate')('runs.table.status'),
              initState: true
            }, {
              field: "due",
              title: $filter('translate')('runs.table.due'),
              initState: true
            }, {
              field: "started_by",
              title: $filter('translate')('runs.table.startedBy'),
              initState: true
            }, {
              field: "owner_id",
              title: $filter('translate')('runs.table.processOwner'),
              initState: true
            }, {
              field: "starred",
              title: $filter('translate')('runs.table.starred'),
              initState: true
            }, {
              field: "started_at",
              title: $filter('translate')('runs.table.startDate'),
              initState: true
            }, {
              field: "last_updated",
              title: $filter('translate')('runs.table.lastUpdated'),
              initState: true
            }, {
              field: "assigned",
              title: $filter('translate')('runs.table.assigned'),
              initState: true
            }, {
              field: "next_step",
              title: $filter('translate')('runs.table.nextStep'),
              initState: true
            }, {
              field: "next_step_assignee",
              title: $filter('translate')('runs.table.nextStepAssignee'),
              initState: true
            }, {
              field: "next_step_issue",
              title: $filter('translate')('runs.table.nextStepIssue'),
              initState: true
            }, {
              field: "current_step",
              title: $filter('translate')('runs.table.currentStep'),
              initState: true
            }, {
              field: "last_completed_step",
              title: $filter('translate')('runs.table.lastCompletedStep'),
              initState: true
            }];
            var groupedFormFieldMenus = _.uniqBy(_.map($ctrl.formFields, function (field) {
              return { id: field.step_id, title: 'Step: ' + field.step_title };
            }), 'id');
            for (var i = 0; i < groupedFormFieldMenus.length; i++) {
              groupedFormFieldMenus[i].menus = _.filter(formFieldAsColumns, { stepId: groupedFormFieldMenus[i].id });
            }
            return {
              beautyScrollStyle: true,
              gridConfig: RunsTableService.getRunsTableOptions(_.concat(formFieldAsColumns, koFormFieldAsColumns)),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                groupMenus: {
                  title: "Form Fields",
                  menus: [{
                    id: Helper.getId(),
                    title: "KO Form Fields",
                    menus: koFormFieldAsColumns
                  }, {
                    id: Helper.getId(),
                    menus: groupedFormFieldMenus
                  }]
                },
                export: true,
                hideAndShowColumns: _.concat(hideAndShowColumns)
              },
              tableFilterModel: {
                name: {
                  value: '',
                  operator: 'contains'
                },
                status_filter: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return value.statusId === args;
                  }
                },
                started_by: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return parseInt(value) === parseInt(args);
                  }
                },
                owner_id: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return parseInt(value) === parseInt(args);
                  }
                },
                starred: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (typeof value !== 'boolean') {
                      return false;
                    }
                    return args === 'yes' ? value : !value;
                  }
                },
                assigned_filter: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return _.find(value.users, function (u) { return parseInt(u) === parseInt(args); }) || _.find(value.guests, function (u) { return u === args; });
                  }
                },
                users: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return _.find(value, function (u) { return parseInt(u) === parseInt(args); });
                  }
                },
                next_step_assigned_filter: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (Helper.isObjectEmpty(value)) {
                      return false;
                    }
                    return _.find(value.users, function (u) { return parseInt(u) === parseInt(args); }) || _.find(value.guests, function (u) { return u === args; });
                  }
                },
                next_step: {
                  value: '',
                  operator: 'contains'
                },
                next_step_issue: {
                  value: '',
                  operator: function (value, args) {
                    if (Helper.isObjectEmpty(args)) {
                      return true;
                    }
                    if (typeof value !== 'boolean') {
                      return false;
                    }
                    return args === 'yes' ? value : !value;
                  }
                },
                current_step: {
                  value: '',
                  operator: 'contains'
                },
                last_completed_step: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  getAssigned: getAssigned,
                  getUsers: getUsers,
                  getGuestAssigned: getGuestAssigned,
                  getNextTaskAssigned: getNextTaskAssigned,
                  getNextTaskGuestAssigned: getNextTaskGuestAssigned,
                  onColumnFilter: function (column, item) {
                    var dataSource = $ctrl.tableOptions.gridConfig.dataSource;
                    $ctrl.metadata.isBusy = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    if (!dataSource.filter()) {
                      dataSource.filter({
                        field: column,
                        operator: $ctrl.tableOptions.tableFilterModel[column].operator,
                        value: item ? item.value || '' : $ctrl.tableOptions.tableFilterModel[column].value
                      });
                    } else {
                      var filter = _.find(dataSource.filter().filters, { field: column });
                      if (filter) {
                        if (!$ctrl.tableOptions.tableFilterModel[column]) {
                          $ctrl.tableOptions.tableFilterModel[column] = { value: '' };
                        }
                        filter.value = item ? item.value || '' : $ctrl.tableOptions.tableFilterModel[column].value;
                      } else {
                        dataSource.filter().filters.push({
                          field: column,
                          operator: $ctrl.tableOptions.tableFilterModel[column].operator,
                          value: item ? item.value || '' : $ctrl.tableOptions.tableFilterModel[column].value
                        });
                      }
                    }
                    $timeout(function () {
                      dataSource.fetch(function () {
                        $ctrl.metadata.isBusy = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      });
                    }, 0);
                  },
                  getFormFieldValues: function (uid, fieldData) {
                    var run = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
                    var result = run.prerun[fieldData.id] || run.form_fields_values[fieldData.id];
                    return result;
                  }
                },
                variables: {
                  usersInOrg: $ctrl.usersInOrg || [],
                  viewerId: $rootScope.identity.id,
                  statusList: [],
                  startedByList: [],
                  processOwnerList: [],
                  assigneeList: [],
                  yeNoValue: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }],
                  nextStepAssigneeList: [],
                  formFields: _.concat($ctrl.formFields, $ctrl.koFormFields),
                  dateFormat: OrganizationsService.getDateFormat(),
                  titleTooltipDirection: 'down'
                }
              }
            };
          }
        }
    });
})();
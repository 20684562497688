/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.guestTask
 * @module tallyfy.tasks
 *
 * @description
 * task component
 *
* @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('guestTask', {
      templateUrl: 'app/modules/tasks/guestTask/guestTask-container.html',
      bindings: {
        newGuestUrl: '<'
      },
      require: {
      },
      controller:
        /*@ngInject*/
        function TaskController(_, $rootScope, $scope, Growl, TasksService, $state, OrganizationsService, blockUI, $filter, $log, DateUtils, $timeout, FieldService, $stateParams, $window) {
          var $ctrl = this,
            STATUS = angular.copy(TasksService.STATUS),
            growl = new Growl(),
            blockUI = blockUI.instances.get('guestTasks'),
            unRegisterEventHandler,
            requestPayload = {},
            updateCaptureTimeout, unRegisterWatcherHandler;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties and methods 
           */
          $ctrl.isCompleted = false;
          $ctrl.completeTaskClicked = false;
          $ctrl.isStandAloneTask = false;
          $ctrl.onSaving = false;
          $ctrl.isTaskAvailable = true;
          $ctrl.absoluteDateFormat = OrganizationsService.getDateFormat();

          /**
          * public methods
          */
          $ctrl.taskComplete = taskComplete;
          $ctrl.updateTaskCapture = updateTaskCapture;
          $ctrl.viewMore = viewMore;
          $ctrl.getTaskDeadlineStatus = getTaskDeadlineStatus;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
            // initialization();
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unRegisterEventHandler();
            unRegisterWatcherHandler();
          }

          /**
           * @ngdoc method
           * @name taskComplete
           * @description guest task complete/incomplete
           * @param {any} status
           * @returns void
          */
          function taskComplete(status) {
            $ctrl.completeTaskClicked = Date.now();
            var stepCaptures = _.get($ctrl.task, 'step.data.captures', []);
            if (!$ctrl.isStandAloneTask && (status === 'COMPLETED') && TasksService.hasRequiredCaptures(stepCaptures)) {
              return;
            }

            if (status === 'COMPLETED' && stepCaptures.length > 0) {
              updateCapture(true);
              return;
            }
            updateTask(status);
          }

          /**
           * @ngdoc method
           * @name updateTaskCapture
           * @description update guest task capture
           * @param {any} capture 
           * 
           * @returns void
           */
          function updateTaskCapture(capture) {
            requestPayload.taskdata[capture.id] = FieldService.getFieldValue(capture);
            updateTaskData(requestPayload);

            if (updateCaptureTimeout) {
              $timeout.cancel(updateCaptureTimeout);
            }
            updateCaptureTimeout = $timeout(function () {
              updateCapture(false, capture);
            }, 800);
          }

          /**
           * @ngdoc method
           * @name updateCapture
           * @description Update guest task capture
           * @param {Boolean} isCompleted
           * @param {Object} capture
           * @returns void
          */
          function updateCapture(isCompleted, capture) {
            $ctrl.onSaving = isCompleted;
            TasksService.updateGuestTask({
              id: $state.params.task_code,
              org: $state.params.org_id
            }, requestPayload).then(function (response) {
              $ctrl.task.summary = response.data.summary;
              if (isCompleted) {
                updateTask('COMPLETED');
              }
              if (_.get(capture, 'field_type') === 'date' || isCompleted) {
                updateTaskData(response.data);
              }

              _.map(_.get($ctrl.task, 'step.data.captures', []), function (capture) {
                var fieldType = _.get(capture, 'field_type');
                if (fieldType === 'radio') {
                  var selectedOption = _.find(capture.options, function (option) {
                    return _.toLower(_.trim(option.text)) === _.toLower(_.trim(response.data.taskdata[capture.id]));
                  });
                  if (selectedOption) {
                    capture.value.id = selectedOption.id;
                    capture.value.text = selectedOption.text;
                  }
                }
              });
              $ctrl.onSaving = false;
            }, function (error) {
              if (error.status == 422) {
                $ctrl.errorFields = error.data.data.errors;
              }
              $log.error("There was error when updating form field");
              $ctrl.onSaving = false;
            });
          }

          /**
           * @function
           * @name submitComment          
           * @description
           * Guest comment form submit 
           * @TODO It can be unhide in future version
           */

          /* function submitComment(message) {
             var deffered = $q.defer();
             blockUI.start();
             TasksService.saveGuestComment({
               id: $state.params.task_code,
               org: $state.params.org_id
             }, message).then(function (response) {
               $ctrl.comments.push(response.data);
               deffered.resolve();
               blockUI.stop();
             }, function (error) {
               deffered.reject();
               blockUI.stop();
             });
 
             return deffered.promise;
           } */

          /**
           * @function
           * @name updateTask
           * @description
           * Update guest task 
           */
          function updateTask(status) {
            $ctrl.onSaving = true;
            TasksService.updateGuestTask({
              id: $state.params.task_code,
              org: $state.params.org_id,
            }, {
              status: STATUS[status]
            }).then(function (response) {
              $ctrl.isCompleted = (response.data.status === STATUS.COMPLETED.toLowerCase());
              $rootScope.$emit('TASK:UPDATE_TASK_STATUS', {
                isCompleted: $ctrl.isCompleted,
                taskId: $ctrl.task.id
              });
              if ($ctrl.isCompleted) {
                $ctrl.task.completed_at = DateUtils.toLocal(response.data.completed_at);
                $ctrl.task.completer = response.data.completer;
                $ctrl.task.completer_guest = response.data.completer_guest;
                updateCompletedBy();
              }
              $ctrl.onSaving = false;
            }, function (error) {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name updateTaskData
           * @description update task data
           * @param {Object} task 
           * 
           * @returns void
           */
          function updateTaskData(task) {
            $ctrl.task.taskdata = angular.copy(task.taskdata);
          }

          /**
           * @ngdoc method
           * @name updateGuestTaskCapture 
           * @private
           * @description update guest task data
           * @param {any} data 
           * @returns void
           */
          function updateGuestTaskCapture(data) {
            var file = _.get(data, 'file'),
              field = _.get(data, 'field'),
              fIndex;

            var taskData = _.get($ctrl.task, 'taskdata');

            if (taskData) {
              if (taskData[field.id] && !_.isEmpty(taskData[field.id])) {
                fIndex = _.findIndex(taskData[field.id], { id: file.id });
                taskData[field.id].splice(fIndex, 1);
              }
            }
            requestPayload.taskdata[field.id] = taskData[field.id];

            blockUI.start();
            TasksService.updateGuestTask({
              id: $state.params.task_code,
              org: $state.params.org_id,
            }, requestPayload).then(function (response) {
              $ctrl.task.taskdata = angular.copy(taskData);
              blockUI.stop();
            }, function (error) {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name viewMore
           * @description Check if need to show View More button
           * @returns {boolean}
           */
          function viewMore() {
            if ($ctrl.task.run_id) {
              return (_.has($ctrl.task.step.data, 'captures') && $ctrl.task.step.data.captures.length > 0 || $ctrl.task.step.data.summary);
            }
            return $ctrl.task.summary;
          }

          /**
           * @ngdoc method
           * @name updateCompletedBy
           * @description Update completed by
           */
          function updateCompletedBy() {
            if (_.get($ctrl.task, 'completer_guest')) {
              $ctrl.completedBy = $filter('translate')('tasks.general.completedGuest');
            } else {
              $ctrl.completedBy = $ctrl.task.completer;
            }
          }

          /**
           * @function getTaskDeadlineStatus
           * @description return task deadline status
           * @param {*} deadline
           * @returns deadline status
           */
          function getTaskDeadlineStatus(deadline) {
            return TasksService.getTaskDeadlineStatus(deadline);
          }

          /**
           * capture event GUEST_TASK:DELETE_FILE
           * and unregister when component is destroyed
           * @see onDestroy
           */
          unRegisterEventHandler = $rootScope.$on('GUEST_TASK:DELETE_FILE', function (event, data) {
            updateGuestTaskCapture(data);
          });

          unRegisterWatcherHandler = $scope.$watch('$ctrl.newGuestUrl', function (value) {
            if (value) {
              var urlPaths = value.split('/');
              if (urlPaths && urlPaths.length) {
                urlPaths.splice(5, 0, $stateParams.org_id);
                $window.location.replace(urlPaths.join('/'));
              }
            } else {
              growl.error($filter('translate')('tasks.general.guestTaskNotFound'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }
          }, true);
        }
    });
})();
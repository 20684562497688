/**
 * @ngdoc Controller
 * @name confirmModal.Controller.ConfirmationModalController
 * @module confirmModal
 *
 * @description
 * commonly used confrim modal
 * 
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function() {
    'use strict';
    angular
      .module('confirmModal')
      .controller('ConfirmationModalController', ConfirmationModalController);
      /*@ngInject*/
      function ConfirmationModalController($uibModalInstance, options) {
        var $ctrl = this;
        $ctrl.options = options;
        /**
         * Send accept modal event
         */
        $ctrl.accepts = function() {
          $uibModalInstance.close('close');
        };

        /**
         * Send close modal event
         */
        $ctrl.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
})();

/**
 * @ngdoc Component
 * @name tallyfy.component.createRun
 * @module tallyfy
 *
 * @description
 * A custom progress Bar
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('progressBar', {
      bindings: {
        value : '<',
        message : '@'
      },
      templateUrl: 'app/components/progress-bar/progress-bar.html',
      controller:
      /*@ngInject*/
      function () {
        var $ctrl = this;
        /**
        * Component's lifeCycle hooks
        */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;
        /**
         * Expose bindable methods
         * these methods are accessible in view
         */
        /**
         * public properties
         */
        /**
         * @ngdoc method
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          if(!$ctrl.message){
            $ctrl.message = 'In progress...';
          }
        }
        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges(bindings) { }
        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        //end of controller
      }
    });
})();

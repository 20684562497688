/**
 * @ngdoc Directive
 * @name $downloadFile
 *
 * @module tallyfy
 *
 * @description
 * its use to create file download link.
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 */
(function() {
  'use strict';
  angular
    .module('tallyfy')
    .directive('downloadFile', downloadFile);

    function downloadFile() {
      var directive = {
        restrict: 'E',
        scope: {
          url: '@?'
        },
        template: '<a data-ng-href="{{url}}" target="_blank" class="text-u-c t-slate-three _600 m-r-md"><i class="fa fa-download" aria-hidden="true"></i> <span translate="tasks.captures.download_link"></span></a>'
      };
  
      return directive;
    }
})();

/**
 * @ngdoc Component
 * @name tallyfy.process.component.bpIconControl
 * @module tallyfy.process
 *
 * @description
 * Blueprint icons list control's component
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('bpIconControl', {
      bindings: {
        ngModel: '='
      },
      templateUrl: 'app/modules/process/components/edit/publishBlueprint/bpIconControl/bp-icon-control.component.html',
      controller:
        /*@ngInject*/
        function ($scope) {
          var $ctrl = this,
            modelWatcherHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          modelWatcherHandler = $scope.$watch('$ctrl.ngModel', function (newValue) {
            if (!newValue) {
              $ctrl.ngModel = 'blog';
            }
          });
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.tallyfyRecommendAnother
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage branding recommend to another company
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('tallyfyRecommendAnother', {
      templateUrl: 'app/modules/organizations/tallyfyRecommendAnother/tallyfy-recommend-another.html',
      controller:
        /*@ngInject*/
        function ($rootScope, _, $window, MAILTO, VALIDATION) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.sendMail = sendMail;
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on 
           * an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name sendMail
           * @description send mail
           */
          function sendMail() {
            var body = "Hi "+ $ctrl.first_name +",\n\n"
                +"I wanted to recommend Tallyfy to you - to document and automate any repeatable process.\n\n"
                +$ctrl.note
                +"\n\n We use Tallyfy ourselves, and it's been great!\n\n"
                +"Thanks,\n"
                +_.get($rootScope.identity, 'first_name');
            var mailtoLink = "mailto:"+ $ctrl.email
                  +", "+ MAILTO.HEY_TALLYFY
                  +", "+ MAILTO.SUPPORT_TALLYFY
                  +"?subject=Tallyfy Recommendation"
                  +"&cc="+_.get($rootScope.identity, 'email')
                  +"&body="+encodeURIComponent(body);
            $window.open(mailtoLink);
          }
        }
    });
})();
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(dashboardConfig);

  dashboardConfig.$inject = ['$stateProvider'];
  function dashboardConfig($stateProvider) {
    $stateProvider.state('dashboard', {
      parent: 'app',
      url: '/:org_id/dashboard?triggerQuickActionSlug',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {},
      views: {
        'content': {
          component: 'dashboard'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('home');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('dashboard');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('contentPreview');
          $translatePartialLoader.addPart('backgroundChoice');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Service
 * @name DOMService
 *
 * @module tallyfy
 *
 * @description
 * DOM manipulation service
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('DOMService', DOMService);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  DOMService.$inject = ['$anchorScroll', '$location', '$rootScope'];

  /*@ngInject*/
  function DOMService($anchorScroll, $location, $rootScope) {
    /**
     * private configurations for element
     */
    var options = {
      topOffset: 0 //default 0
    }, deregisterHSWatcher, deregisterChatlioWatcher;

    /**
     * service properties
     */
    var service = {
      scrollViewToTop: scrollViewToTop,
      scrollViewToError: scrollViewToError,
      centerObjectToView: centerObjectToView,
      getComputedStyle: getComputedStyle,
      toggleHelpScoutVisibility: toggleHelpScoutVisibility,
      toggleChatlioVisibility: toggleChatlioVisibility
    };

    return service;

    /**
     * @ngdoc method
     * @name scrollViewToTop
     * @param {string} element element query class/id
     * @param {number} topOffset topOffset
     * @return void
     * 
     * @description scroll element to top
     */
    function scrollViewToTop(element, topOffset) {
      var domElement = document.querySelector(element);
      options.topOffset = topOffset || 0;
      if (domElement) {
        domElement.scrollTop = options.topOffset;
      }
    }
    function scrollViewToError(element, topOffset) {
      var domElement = document.querySelector(element);
      if (domElement) {
        $anchorScroll.yOffset = topOffset;
        $location.hash(domElement.closest('form').id);
        $anchorScroll();
      }
    }

    /**
     * @name scrollObjectIntoView
     * @param {*} elementClass 
     * @returns {void}
     * 
     * @description
     * scroll any element into center of view
     */
    function centerObjectToView(elementClass, config) {
      var domElement = document.querySelector(elementClass);
      if (domElement) {
        domElement.scrollIntoView(config || { behavior: "smooth", block: "center", inline: "center" });
      }
    }

    /**
     * 
     * @param {*} el 
     * @param {*} prop 
     */
    function getComputedStyle(el, prop) {
      return (el.currentStyle) ? el.currentStyle[prop] : (window.getComputedStyle && window.getComputedStyle(el, null)) ? window.getComputedStyle(el, null).getPropertyValue(prop) : el.style[prop];
    }

    /**
     * @name toggleHelpScoutVisibility
     * @param {*} isHidden
     * @returns {void}
     *
     * @description
     * Hide / Show HelpScout Beacon depending upon the param provided
     */
    function toggleHelpScoutVisibility(isHidden) {
      var domElement = document.getElementById('beacon-container');
      if (domElement) {
        isHidden ? domElement.style.opacity = 0 : domElement.style.opacity = 1;
        isHidden ? domElement.style.pointerEvents = 'none' : domElement.style.pointerEvents = 'all';
      } else {
        deregisterHSWatcher = $rootScope.$watch(function () {
          return document.getElementById('beacon-container');
        }, function (newValue, oldValue) {
          if (newValue && (newValue !== oldValue)) {
            isHidden ? newValue.style.opacity = 0 : newValue.style.opacity = 1;
            isHidden ? newValue.style.pointerEvents = 'none' : newValue.style.pointerEvents = 'all';
            deregisterHSWatcher();
          }
        });
      }
    }

    function toggleChatlioVisibility(isHidden) {
      var domElement = document.getElementById('chatlio-widget');
      if (domElement) {
        isHidden ? domElement.style.opacity = 0 : domElement.style.opacity = 1;
        isHidden ? domElement.style.pointerEvents = 'none' : domElement.style.pointerEvents = 'all';
      } else {
        deregisterChatlioWatcher = $rootScope.$watch(function () {
          return document.getElementById('chatlio-widget');
        }, function (newValue, oldValue) {
          if (newValue && (newValue !== oldValue)) {
            isHidden ? newValue.style.opacity = 0 : newValue.style.opacity = 1;
            isHidden ? newValue.style.pointerEvents = 'none' : newValue.style.pointerEvents = 'all';
            deregisterChatlioWatcher();
          }
        });
      }
    }
  }
})();
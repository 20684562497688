/**
 * @ngdoc Component
 * @name tallyfy.steps.component.otherMembersAssigneeView
 * @module tallyfy.steps
 *
 * @description
 * A component to view other members assignee etc. (guest included or not as well)
 *
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('otherMembersAssigneeView', {
      templateUrl: 'app/modules/steps/other-members-assignee-view/other-members-assignee-view.html',
      bindings: {
        step: '<',
        tooltipDirection: '<'
      },
      controller:
        /*@ngInject*/
        function (StepService, TasksService, _) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.stepOwnersAsStr = stepOwnersAsStr;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          
          /**
           * public properties
           */
          $ctrl.stepsOwners = [];

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name stepOwnersAsStr
           * @returns {String} owners
           */
          function stepOwnersAsStr() {
            $ctrl.stepsOwners = StepService.stepOwnersAsStr($ctrl.step);
            return _.join(_.map($ctrl.stepsOwners, 'text'), ', ');
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.renderForm
 * @module tallyfy.steps
 *
 * @renderForm
 * A component to render form with available capture field
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('guestRenderForm', {
      require: {
      },
      bindings: {
        fields: '<',
        onFieldUpdate: '&',
        fieldData: '<',
        fieldClass: '@',
        task: '<?',
        isDisabled: '<?',
        formSubmitted: '<',
        onSaving: '<'
      },
      templateUrl: 'app/modules/tasks/guestTask/form/render/render-form.html',
      controller: [
        'StepService',
        '_',
        '$log',
        'AuthRepository',
        '$rootScope',
        '$stateParams',
        '$filter',
        function (StepService, _, $log, AuthRepository, $rootScope, $stateParams, $filter) {
          var $ctrl = this;
          /**
          * component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.validationErrors = {};

          /**
           * public methods
           */
          $ctrl.updateFieldValue = updateFieldValue;
          /**
           * @function
           * @name initialization
           * @viewForm
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.orgId = $stateParams.org_id;
            $ctrl.code = $stateParams.task_code;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.formSubmitted && bindings.formSubmitted.currentValue) {
              validateFormFields()
            }
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function updateFieldValue(field) {
            $log.info('Field is updated with :', field);
            $ctrl.onFieldUpdate({ field: field });
          }

          /**
           * @function
           * @name validateFormFields
           * @description
           * This function is used to check empty form fields
           */
          function validateFormFields() {
            _.each($ctrl.fields, function (field) {
              if (field.required && ((_.isArray($ctrl.fieldData[field.id]) && $ctrl.fieldData[field.id].length < 1) || !$ctrl.fieldData[field.id])) {
                $ctrl.validationErrors[field.id] = field.field_type === 'multiselect' ? $filter('translate')('steps.messages.requiredMultipleField') : $filter('translate')('steps.messages.requiredField');
              }
            });
          }

        }]
    });
})();

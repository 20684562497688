/**
 * @ngdoc Config
 * @name tallyfy.integration.config.SMTPStateConfig
 * @module tallyfy.integration
 *
 * @description
 * set route integration Module
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .config(SMTPStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  SMTPStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * SMTPStateConfig construct 
   */
  function SMTPStateConfig($stateProvider) {
   
  }
})();
/**
 * @ngdoc provider
 * @name tallyfy.config.provider.Redirection
 * @module tallyfy.config
 *
 * @description
 * The purpose of the provide is to match URLs and redirects to new URL.
 * @example Below is how to define URL rewrite rule.
 * {
      url: /([\w-]+)\/settings\/me\/([\w-]+)(?:(?=\?).(.*))?$/,
      to: '/$1/settings/me/$2/preferences',
      query : {
        matcherIndex : "$3",
        params : {}
      }
    }
 *  query - is optional - extracts query string and maps params to reassign values to new variables  
 *  query.matcherIndex a index of machers - (?:(?=\?).(.*))?
 * (?:(?=\?).(.*))? - Match the query string
 * ([\w-]+) - A wildcard to match the value
 * $1 points to wildcard ([\w-]+) by sequence order.
 * @todo this is temporary provider class. Will be removed in future.
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';
  angular
    .module('tallyfy.config')
    .provider('Redirection', RedirectionProvider);
  /*@ngInject*/
  function RedirectionProvider(_) {
    var _redirectionRules = [{
      url: /([\w-]+)\/settings\/integration$/i,
      to: '/$1/settings/integrations'
    },
    {
      url: /([\w-]+)\/settings\/org\/users$/i,
      to: '/$1/settings/org/members'
    },
    {
      url: /([\w-]+)\/settings\/me\/preferences$/i,
      to: '/$1/settings/me/notifications'
    },
    {
      url: /([\w-]+)\/settings\/org\/update-organization$/i,
      to: '/$1/settings/org/details'
    },
    {
      url: /([\w-]+)\/settings\/me\/update$/i,
      to: '/$1/settings/me/details'
    },
    {
      url: /([\w-]+)\/UpdateOrgPlan$/i,
      to: '/$1/plan'
    },
    {
      url: /([\w-]+)\/process\/templates(?:(?=\?).(.*))?$/i,
      to: '/$1/blueprints?$2'
    },
    {
      url: /([\w-]+)\/process\/templates\/([\w-]+)(?:(?=\?).(.*))?$/i,
      to: '/$1/blueprints?status=$2',
      query: {
        matcherIndex: 3,
        params: {}
      }
    },
    {
      url: /([\w-]+)\/process\/([\w-]+)\/edit$/i,
      to: '/$1/blueprints/$2/edit'
    },
    {
      url: /([\w-]+)\/process\/([\w-]+)\/edit$/i,
      to: '/$1/blueprints/$2/edit'
    },
    {
      url: /([\w-]+)\/tasks\/me$/i,
      to: '/$1/tasks?assignee=me'
    },
    {
      url: /([\w-]+)\/tasks\/assignedByMe$/i,
      to: '/$1/tasks?assignee=by-me'
    },
    {
      url: /([\w-]+)\/tasks\/([\d-]+)$/i,
      to: '/$1/tasks?assignee=$2'
    },
    {
      url: /([\w-]+)\/task\/([\w-]+)$/i,
      to: '/$1/tasks?activeTask=$2'
    },
    /** 
     * runs-> processes  
     * */
    {
      url: /([\w-]+)\/runs$/i,
      to: '/$1/processes/all'
    },
    {
      url: /([\w-]+)\/runs\/([a-zA-Z0-9-_]+)$/i,
      to: '/$1/processes?filter=$2'
    },
    {
      url: /([\w-]+)\/run\/([a-z0-9]+)\/view\/([a-z0-9]+)?$/i,
      to: '/$1/processes/$2?activeTask=$3'
    },
    {
      url: /([\w-]+)\/processes\/([a-z0-9]+)\/view\/([a-z0-9]+)?$/i,
      to: '/$1/processes/$2?activeTask=$3'
    },
    {
      url: /([\w-]+)\/processes\/([a-z0-9]+)\/view$/i,
      to: '/$1/processes/$2'
    }
    ];

    /**
    * urlMatcher is a helper function that matches the pathname 
    * and extras path variable to create new URL from old URL
    */
    function urlMatcher($location) {
      var url = '/';
      for (var i = 0; i < _redirectionRules.length; i++) {
        var matcherRule = _redirectionRules[i];
        var matched = matcherRule.url.exec($location.url());
        if (matched) {
          url = matcherRule.to;
          var matchedPathVars = url.match(/\$([0-9\.]+)\b/g);
          if (matchedPathVars) {
            _.each(matchedPathVars, function (needle) {
              if (matched[needle.replace('$', '')])
                url = url.replace(needle, matched[needle.replace('$', '')]);
            });
          }
          var queryMatcherIndex = _.get(matcherRule, 'query.matcherIndex', '');
          if (queryMatcherIndex !== '' && matched[queryMatcherIndex]) {
            url = _.indexOf(url, '?') >= 0 ? url + '&' + matched[queryMatcherIndex] : url + '?' + matched[queryMatcherIndex];
            console.log('found query string', matched[queryMatcherIndex]);
          }
          break;
        }
      }
      //remove unecessary `/` and `${digit}
      url = url.replace(/(\$[1-9])/g, "").replace('//', '/');
      console.log('redirecting to new URL', url);
      return url;
    }

    return {
      urlMatcher: urlMatcher,
      $get: [
        '$location', '$localStorage', '_', '$log', 'OrganizationsService', '$q', 'Principal',
        function ($location, $localStorage, _, $log, OrganizationsService, $q, Principal) {
          function orgHandler() {
            var orgKey, deferred, path;
            deferred = $q.defer();
            path = _.trim($location.url(), '/');
            path = _.filter(_.split(path, '/'), function (segment) {
              return !!segment
            });
            orgKey = path[0];

            if ($localStorage.default_organization_Id === orgKey) {
              $log.debug('orgHandler: orgKey matched with current ord ID', $localStorage.default_organization_Id);
              redirectToDefault(path);
              // resolve promise and activate router
              deferred.resolve($localStorage.default_organization_Id);
            } else if ($localStorage.default_organization_slug === orgKey) {
              $log.debug('orgHandler:orgKey matched with current org slug', $localStorage.default_organization_slug);
              // return $localStorage.default_organization_Id
              // replace org slug in with ordID in $location
              $location.path().replace(orgKey, $localStorage.default_organization_Id);
              redirectToDefault(path);
              $log.debug('orgHandler:replace orgSlug with orgID', $localStorage.default_organization_slug, $localStorage.default_organization_Id);
              // activate router
              deferred.resolve($localStorage.default_organization_Id);
            } else {
              $log.debug('orgHandler:orgKey does not match');
              if (orgKey === 'public') {
                deferred.resolve();
                return deferred.promise;
              }
              // get user's all organizations.
              // find org by ID or slug
              // if found any = set ORG
              // else redirect to 404
              OrganizationsService
                .myOrganizations({
                  limit: '999'
                })
                .then(function (response) {
                  var orgBySlug = _.find(response.data, function (o) {
                    return o.slug === orgKey || o.id === orgKey;
                  });
                  if (orgBySlug) {
                    $log.debug('orgHandler found ORG by key', orgBySlug);
                    Principal.setDefaultORG({
                      id: orgBySlug.id,
                      slug: orgBySlug.slug
                    });
                    Principal.identity(true).then(function () {
                      deferred.resolve($localStorage.default_organization_Id);
                    }, function (error) {
                      deferred.reject(error);
                    });
                  } else {
                    $log.debug('orgHandler not found ORG by key', orgBySlug);
                    deferred.resolve();
                  }
                }).catch(function (error) {
                  $log.error('orgHandler Error', error);
                  deferred.reject(error);
                });
            }
            return deferred.promise;
          }

          function redirectToDefault(path) {
            if (path.length === 1) {
              $location.path('/' + $localStorage.default_organization_Id + '/tasks/');
            }
          }
          return {
            detectOrg: orgHandler
          }
        }]
    }
  }
})();

(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.utils.factory.UtilsRepository
   * @module tallyfy.utils
   *
   * @description
   * Create Rest resource for utils
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy')
    .factory('UtilsRepository', UtilsRepository);

  /*@ngInject*/
  function UtilsRepository(BaseRepository, _) {
    var BASE_URI_SEGMENT = '/utils/:action', repository;
    repository = BaseRepository(BASE_URI_SEGMENT, {
      action: '@action'
    }, {
        checkURL: {
          method: 'POST',
          params: {
            action: 'check-url'
          },
          isArray: true
        },
        getConfig: {
          method: 'GET',
          params: {
            action: 'config'
          },
          isArray: true
        },
        getCountries: {
          method: 'GET',
          params: {
            action: 'countries'
          },
          transformResponse: function (data) {
            data = angular.fromJson(data).data;
            _.each(data, function (country) {
              country.code = country.iso2.toUpperCase();
            });
            return data;
          },
          isArray: true
        }
      });
    return repository;
  }
})();
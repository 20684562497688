/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.task
 * @module tallyfy.tasks
 *
 * @description
 * task component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('task', {
      templateUrl: 'app/modules/tasks/task/task-container.html',
      bindings: {
        task: '<',
        taskLength: '=',
        completedTaskLength: '=',
        run: '<',
        entity: '<?',
        showHiddenTasks: '<?',
        newOneOffTask: '<?',
        isNewOneOffTask: '<?',
        isLast: '<?'
      },
      require: {
        tasksCtrl: '^tasksList'
      },
      controller:
        /*@ngInject*/
        function (_, $filter, $rootScope, $window, $uibModalStack, Growl, TasksService, moment, blockUI, $uibModal, realtime, $scope, $localStorage, $log, RunDetailService, DATEFORMAT, $analytics, DateUtils, TFY_EVENTS, $state, DOMService, $timeout, FieldService, Helper, AuthPlan, OrganizationsService) {
          var $ctrl = this,
            STATUS = angular.copy(TasksService.STATUS),
            growl = new Growl(),
            blockUI = blockUI.instances.get('tasks'),
            unregisterCommentDeletedHandler,
            unregisterTasksStatusListener,
            initialized = false;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.toggleTaskView = toggleTaskView;
          $ctrl.taskComplete = taskComplete;
          $ctrl.openModal = openModal;
          $ctrl.closeModal = closeModal;
          $ctrl.updateTaskCapture = updateTaskCapture;
          $ctrl.hasAnyIssue = hasAnyIssue;
          $ctrl.editTaskOpen = editTaskOpen;
          $ctrl.showViewMore = showViewMore;
          $ctrl.isTaskCompletable = isTaskCompletable;
          $ctrl.resendTask = resendTask;
          $ctrl.deleteStandaloneTask = deleteStandaloneTask;
          $ctrl.getDeadlineClass = getDeadlineClass;
          $ctrl.getTaskDeadlineStatus = getTaskDeadlineStatus;
          $ctrl.copyGuestLink = copyGuestLink;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;

          /**
           * public properties
           */
          $ctrl.isCompleted = false;
          $ctrl.isModalOpen = false;
          $ctrl.isStandAloneTask = false;
          $ctrl.threads = [];
          $ctrl.availableUsers = [];
          $ctrl.organizationId = $localStorage.default_organization_Id;
          $ctrl.isStandaloneEditOpen = false;
          $ctrl.completeTaskClicked = false;
          $ctrl.onSaving = false;
          $ctrl.completedBy = null;
          $ctrl.isAssignToGuest = false;
          $ctrl.isFilterExist = false;
          $ctrl.showLatestComment = true;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isFilterExist = $ctrl.tasksCtrl.primaryFilterConfig !== 'undefined';
            $ctrl.orgID = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.isStandAloneTask = _.isNull($ctrl.task.run_id);
            $ctrl.isCompleted = ($ctrl.task.status === STATUS.COMPLETED.toLowerCase());
            $ctrl.currentUser = $rootScope.identity;
            if (!$ctrl.isStandAloneTask && _.isObject($ctrl.task.run)) {
              $ctrl.run = _.isObject($ctrl.tasksCtrl.run) && !_.isEmpty($ctrl.tasksCtrl.run) ? $ctrl.tasksCtrl.run : angular.copy($ctrl.task.run.data);
            }
            $ctrl.isOverdued = moment($ctrl.task.deadline).isBefore(moment());
            $ctrl.availableUsers = $ctrl.tasksCtrl.availableUsers;
            $ctrl.allUsers = $ctrl.tasksCtrl.allUsers;
            $ctrl.threads = _.get($ctrl.task, 'threads.data', []);
            _.forEach($ctrl.threads, function (threads, key) {
              $ctrl.threads[key].author = _.find($ctrl.availableUsers, function (user) {
                return user.id === threads.author_id;
              });
            });
            hasAnyIssue();
            initialPopover();

            prepareOwner();
            $ctrl.assigneeType = $filter('translate')('tasks.assignee.coworker');
            if ($ctrl.isCompleted) {
              updateCompletedBy();
            }
            $ctrl.shortDateFormat = DATEFORMAT.SHORT;
            taskAssignToGuest($ctrl.task);
            if (!_.get($ctrl.task, 'deadline_unformatted')) {
              angular.extend($ctrl.task, {
                deadline_unformatted: DateUtils.toLocal($ctrl.task.deadline)
              });
            }

            /**
             * check if @param task is exists in query string
             * get task and find task by alias;
             * expand task
             * make task visible
             */
            var activeTask = _.get($rootScope, 'toStateParams.activeTask', '');
            if (activeTask && $ctrl.task.id === activeTask && ($ctrl.task.status !== 'auto-skipped')) {
              toggleTaskView();
              $timeout(function () {
                DOMService.centerObjectToView('#task_' + $ctrl.task.id);
              }, 150);
            }

            if (!initialized && !$ctrl.isStandAloneTask && _.isObject($ctrl.task.run)) {
              realtime.subscribe('run_' + $ctrl.run.id);
              $scope.$on('task_has_changed.' + $ctrl.task.id, function (event, message) {
                updateRealtime(message);
              });
            }
            initialized = true;
            $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
            var isMatchTask = _.find($ctrl.newOneOffTask, function (newTask) {
              return newTask.id === _.get($ctrl.task, 'id');
            });
            $ctrl.isHide = !!(isMatchTask && !$ctrl.isNewOneOffTask);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           * @param {*} bindings
           */
          function onChanges(bindings) {
            if (bindings.task && bindings.task.currentValue) {
              if (_.isNull(bindings.task.currentValue.run_id)) {
                $ctrl.templateUrl = 'app/modules/tasks/task/task-standalone.html';
              } else {
                $ctrl.templateUrl = 'app/modules/tasks/task/task.html';
              }
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterCommentDeletedHandler();
            unregisterTasksStatusListener();
            unregisterIssueReportHandler();
            unregisterIssueResolveHandler();
          }

          function toggleTaskView($event) {
            if ($ctrl.task.status === 'auto-skipped') {
              return;
            }

            var eventParentNode = _.get($event, 'target.parentNode.nodeName');
            if (eventParentNode === 'ANIMATED-BUTTON' || eventParentNode === 'BUTTON') {
              return;
            }
            if (!$ctrl.task.isExpanded) {
              trackEvent('Task opened', {});
            }
            _.forEach($ctrl.tasksCtrl.tasks, function (task) {
              if (task.isExpanded) {
                task.isExpanded = false;
                return false;
              }
            });
            _.forEach($ctrl.tasksCtrl.newAddedOneOffTaskList, function (task) {
              if (task.isExpanded) {
                task.isExpanded = false;
                return false;
              }
            });

            //check if task-list component has another list of completed task
            //if yes - then set isExpanded false to expanded task in the list
            if (_.isArray($ctrl.tasksCtrl.recentlyCompletedTasks) && $ctrl.tasksCtrl.recentlyCompletedTasks.length > 0) {
              _.forEach($ctrl.tasksCtrl.recentlyCompletedTasks, function (task) {
                if (task.isExpanded) {
                  task.isExpanded = false;
                  return false;
                }
              });
            }

            $ctrl.task.isExpanded = !(_.isUndefined($ctrl.task.isExpanded) ? false : $ctrl.task.isExpanded);

            //update taskID in route params
            updateRouteParams({
              activeTask: $ctrl.task.id,
              taskId: null
            });
          }

          /**
           * @ngdoc method
           * @name taskComplete
           * @description task complete/incomplete
           * @param {any} status
           *
           * @returns void
           */
          function taskComplete(status) {
            if (!Helper.checkAccessAuthority())
              return;
            var taskStepCaptures = _.get($ctrl.task, "form_fields.data", []),
              requestPayload = {
                taskdata: {}
              };

            if (status) {
              $ctrl.completeTaskClicked = Date.now();
              if (TasksService.hasRequiredCaptures(taskStepCaptures)) {
                return;
              }
              if (_.has($ctrl.task, 'step.data.captures') && taskStepCaptures.length > 0) {
                _.forEach($ctrl.task.step.data.captures, function (field) {
                  requestPayload.taskdata[field.id] = FieldService.getFieldValue(field);
                });
                updateTask(requestPayload, true);
                return;
              }
              completeTask();
            } else {
              incompleteTask();
            }
          }

          /**
           * @function
           * @name updateCompletableTask
           * @description set is_completable =1 if dependent task is completed
           * @param {any} completable_tasks
           *
           * @returns void
           */
          function updateCompletableTask(completable_tasks) {
            _.forEach(completable_tasks, function (tId) {
              var idx = _.findIndex($ctrl.tasksCtrl.tasks, function (t) {
                return t.id === tId;
              });
              if (idx >= 0) {
                $ctrl.tasksCtrl.tasks[idx]['is_completable'] = 1;
              }
            });
          }

          /**
           * @function
           * @name updateExistingTasksChangedByRules
           * @description set is_completable =1 if dependent task is completed
           * @param {any} tasksArr
           *
           * @returns void
           */
          function updateExistingTasksChangedByRules(tasksArr) {
            $ctrl.tasksCtrl.updateExistingTasksChangedByRules(tasksArr);
          }

          /**
           * @ngdoc method
           * @name updateDependentTaskDeadline
           * 
           * @description
           * check and update if deadline dependent to a task
           */
          function updateDependentTaskDeadline() {
            var tasks = _.get($ctrl.tasksCtrl, 'tasks', []);
            _.forEach(tasks, function (task) {
              var deadlineMetadata = _.get(task, 'step.data.deadline');
              if (_.get(deadlineMetadata, 'step') === _.get($ctrl.task, 'step_id')) {
                var deadline = DateUtils.toUTC(_.get($ctrl.task, 'completed_at')).add(deadlineMetadata.value, deadlineMetadata.unit);
                angular.extend(task, {
                  deadline: DateUtils.toLocal(deadline).format(),
                  deadline_unformatted: DateUtils.toLocal(deadline)
                });
              }
            });
          }

          /**
           * @ngdoc method
           * @name updateTaskCapture
           * @description update task capture
           * @param {any} capture
           *
           * @returns void
           */
          function updateTaskCapture(capture) {
            if ($ctrl.onSaving) {
              return;
            }
            var taskData;
            if (_.isArray($ctrl.task.taskdata)) {
              taskData = {};
            } else if (_.isObject($ctrl.task.taskdata)) {
              taskData = $ctrl.task.taskdata;
            } else {
              taskData = {};
            }
            var requestPayload = {
              taskdata: taskData
            };
            requestPayload.taskdata[capture.id] = FieldService.getFieldValue(capture);
            updateTask(requestPayload, false, capture);
          }

          /**
           * @ngdoc method
           * @name updateTask
           * @description Update task capture
           * @param {Object} task 
           * @param {Boolean} flag
           * @param {Object} capture
           * @returns void
           */
          function updateTask(task, flag, capture) {
            if (flag) $ctrl.onSaving = true;
            TasksService.updateTask({
              action_id: $ctrl.run.id,
              id: $ctrl.task.id
            }, task).then(function (response) {
              if (flag) {
                completeTask();
              }
              if (_.get(capture, 'field_type') === 'date' || flag) {
                updateTaskData(response.data);
              }
            }, function (e) {
              if (e.status === 422) {
                $ctrl.errorFields = e.data.data.errors;
              }
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name updateTaskData
           * @description update task data
           * @param {Object} task
           *
           * @returns void
           */
          function updateTaskData(task) {
            var idx = _.findIndex($ctrl.tasksCtrl.tasks, function (t) {
              return t.id === task.id;
            });
            angular.extend($ctrl.task.taskdata, angular.copy(task.taskdata));
            $ctrl.tasksCtrl.tasks[idx]['taskdata'] = angular.copy(task.taskdata);
          }

          /**
           * @ngdoc method
           * @name initialPopover
           * @description popover configuration status changed
           * @returns void
           */
          function initialPopover() {
            $ctrl.isPopoverEnable = $window.innerWidth > 767;
          }

          /**
           * @ngdoc method
           * @name openModal
           * @description modal open method
           *
           * @returns void
           */
          function openModal() {
            $uibModal.open({
              component: 'taskMeta',
              windowClass: '',
              backdrop: true,
              resolve: {
                task: function () {
                  return $ctrl.task;
                },
                run: function () {
                  return $ctrl.run;
                },
                availableUsers: function () {
                  return $ctrl.availableUsers;
                }
              }
            }).result.then(function () {
              closeModal();
            }, function () {
              closeModal();
            });
            $ctrl.isModalOpen = true;
          }

          /**
           * @ngdoc method
           * @name closeModal
           * @description modal close method
           *
           * @returns void
           */
          function closeModal() {
            $uibModalStack.dismissAll();
            $ctrl.isModalOpen = false;
          }

          /**
           * @ngdoc method
           * @name updateRealtime
           * @description update task status
           * @param {*} message
           * @returns void
           */
          function updateRealtime(message) {
            if (message.task.id === $ctrl.task.id) {
              $ctrl.isCompleted = (message.task.status === STATUS.COMPLETED.toLowerCase());
              angular.extend($ctrl.task, {
                status: _.get(message, 'task.status'),
                status_label: _.get(message, 'task.status_label')
              });
              initialization();
            }
          }

          /**
           * @ngdoc method
           * @name completeTask
           * @description complete task
           *
           * @returns void
           */
          function completeTask() {
            $ctrl.onSaving = true;
            var args = {};

            if (!$ctrl.isStandAloneTask) {
              angular.extend(args, {
                action: 'runs',
                run_id: $ctrl.run.id
              });
            }

            TasksService.completeTask(args, {
              task_id: $ctrl.task.id
            }).then(function (response) {
              $ctrl.onSaving = false;
              updateTaskInfo(response.data);
              var eventProperties = {};
              eventProperties['form-fields'] = $ctrl.task.step ? $ctrl.task.form_fields.data.length > 0 ? 'yes' : 'no' : 'no';
              trackEvent('Task completed', eventProperties);
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name incompleteTask
           * @description incomplete task
           *
           * @returns void
           */
          function incompleteTask() {
            var args = {
              task_id: $ctrl.task.id
            };

            if (!$ctrl.isStandAloneTask) {
              angular.extend(args, {
                action: 'runs',
                run_id: $ctrl.run.id
              });
            }

            $ctrl.onSaving = true;
            TasksService.incompleteTask(args).then(function (response) {
              updateTaskInfo(response.data);
              $ctrl.onSaving = false;
              var eventProperties = {};
              eventProperties['form-fields'] = $ctrl.task.step ? $ctrl.task.form_fields.data.length > 0 ? 'yes' : 'no' : 'no';
              trackEvent('Task re-opened', eventProperties);
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function getCurrentTaskIndex() {
            return _.findIndex($ctrl.tasksCtrl.tasks, function (t) {
              return t.id === $ctrl.task.id;
            });
          }

          /**
           * @ngdoc method
           * @name updateTaskInfo
           * @description update task object after taking any action
           * @param {Object} task
           *
           * @returns void
           */
          function updateTaskInfo(task) {
            if (RunDetailService.isDrawerOpen()) {
              $rootScope.$emit("RUN:UPDATE_RUN_DETAIL", task);
            }
            $ctrl.isCompleted = (task.status === STATUS.COMPLETED.toLowerCase());
            $rootScope.$emit('TASK:UPDATE_TASK_STATUS', {
              isCompleted: $ctrl.isCompleted,
              taskId: $ctrl.task.id
            });
            var idx = getCurrentTaskIndex();
            if ($ctrl.isCompleted) {
              var completed_at = DateUtils.toTimezone(_.get(task, 'completed_at')).value();
              $ctrl.task.completed_at = completed_at;
              $ctrl.task.completer_id = _.get(task, 'completer_id');
              $ctrl.task.completer_guest = _.get(task, 'completer_guest');
              updateCompletedBy();
            }
            if (idx >= 0) {
              angular.extend($ctrl.tasksCtrl.tasks[idx], {
                status: _.get(task, 'status'),
                isExpanded: !$ctrl.isCompleted,
                completed_at: completed_at
              });
              if (_.isFunction($ctrl.tasksCtrl.onUpdateStatus)) {
                $ctrl.tasksCtrl.onUpdateStatus(angular.copy($ctrl.tasksCtrl.tasks[idx]), _.get(task, 'status'));
              }
            }
            //get completable tasks if any
            var completable_tasks = _.get(task, 'completable_tasks');
            if (_.isArray(completable_tasks) && completable_tasks.length > 0) {
              //update dependent tasks
              updateCompletableTask(completable_tasks);
            }
            //get task status by rules
            var tasks_changed = _.get(task, 'tasks_changed_by_rules');
            if (!_.isEmpty(tasks_changed) && _.size(tasks_changed) > 0) {
              //update dependent tasks
              updateExistingTasksChangedByRules(tasks_changed);
            }

            if ($ctrl.isCompleted) {
              // check and update dependent task deadline
              updateDependentTaskDeadline();
            }

            if (!_.isUndefined($ctrl.taskLength) && _.isInteger($ctrl.taskLength)) {
              $ctrl.taskLength = $ctrl.isCompleted ? $ctrl.taskLength - 1 : $ctrl.taskLength + 1;
            } else {
              $ctrl.taskLength = 0;
            }
            angular.extend($ctrl.task, {
              status: angular.copy(task.status),
              status_label: angular.copy(task.status_label)
            });
            $ctrl.tasksCtrl.onStatusUpdated(task);
            $ctrl.tasksCtrl.setNumberOnTask();
          }

          function hasAnyIssue() {
            $ctrl.hasIssue = TasksService.getIssueWithUnresolvedProblem($ctrl.threads).length > 0 && !$ctrl.isCompleted;
          }

          function editTaskOpen(e, isAssignToGuest, action) {
            if (!Helper.checkAccessAuthority())
              return;
            Growl.clearAllMessages('global');
            e.stopPropagation();
            $ctrl.isOpenPopOver = false;
            $uibModal.open({
              component: 'editTask create-task-modal',
              windowClass: 'task-modal',
              resolve: {
                task: function () {
                  return $ctrl.task;
                },
                taskOwner: function () {
                  return $ctrl.taskOwners;
                },
                isGuest: function () {
                  return isAssignToGuest;
                },
                action: function () {
                  return action;
                }
              }
            }).result.then(function (updatedTask) {
              if (!_.isEqual(_.sortBy(_.get($ctrl.task, 'run.data.collaborators')), _.sortBy(updatedTask[1].collaborators))) {
                $rootScope.$emit(TFY_EVENTS.TASK.ASSIGNEE.UPDATED, updatedTask[1]);
              }
              var task = _.head(updatedTask);
              angular.extend($ctrl.task, task);
              taskAssignToGuest($ctrl.task);
              if (!$ctrl.task.task_type || $ctrl.task.run_id) {
                $ctrl.task = _.assign($ctrl.task, { deadline: task.deadline, deadline_unformatted: task.deadline_unformatted, owners: task.owners, isExpanded: false });
              } else {
                $ctrl.task = angular.copy(task);
              }
              if (!_.isUndefined(updatedTask[1]) && _.isObject(updatedTask[1])) {
                $ctrl.task.run.data = angular.copy(updatedTask[1]);
              }
              updateTaskRecord();
            }, function (task) {
              $log.info("Modal dissmissed", task);
            });
          }

          /**
           * @ngdoc method
           * @name updateTaskRecord
           * @private
           * @description Update task and owner
           */
          function updateTaskRecord() {
            // might be required in future 
            /*if (_.indexOf($ctrl.task.owners.users, $ctrl.tasksCtrl.entityId) < 0 && $ctrl.entity === 'users') {
              _.remove($ctrl.tasksCtrl.tasks, {
                id: $ctrl.task.id
              });
              return;
            }*/
            prepareOwner();
            $rootScope.$emit('TASK:UPDATE', { 'task': $ctrl.task });
          }


          /**
           * @ngdoc method
           * @name prepareOwner
           * @description Prepare owner data to show as icons and tooltip
           */
          function prepareOwner() {
            $ctrl.taskOwners = TasksService.getTaskOwners(_.get($ctrl.task, 'owners.users'), $ctrl.availableUsers, $ctrl.isStandAloneTask, _.get($ctrl.task, 'run.data.collaborators') || [], $ctrl.task.status);
            $ctrl.allOwner = _.concat($ctrl.taskOwners, $ctrl.task.owners.guests);
            $ctrl.ownersName = _.unionWith(_.map($ctrl.taskOwners, 'text'), $ctrl.task.owners.guests).join(", ");
          }

          /**
           * @ngdoc method
           * @name showViewMore
           * @description Check if need to show View More button
           * @returns {boolean}
           */
          function showViewMore() {
            if ($ctrl.task.run_id) {
              return !$ctrl.task.isExpanded && (_.has(_.get($ctrl.task, 'form_fields.data')) && _.get($ctrl.task, 'form_fields.data', []).length > 0 || _.get($ctrl.task, 'step.data.summary', ''));
            }
            return !$ctrl.task.isExpanded && $ctrl.task.summary;
          }

          /**
           * @ngdoc method
           * @name updateCompletedBy
           * @description Update completed by
           */
          function updateCompletedBy() {
            if (_.get($ctrl.task, 'completer_guest')) {
              $ctrl.completedBy = $filter('translate')('tasks.general.completedGuest');
            } else {
              var taskCompleter = _.find($ctrl.allUsers, ['id', $ctrl.task.completer_id]);
              $ctrl.completedBy = _.get(taskCompleter, "['full_name']", '');
              $ctrl.isDisabled = _.get(taskCompleter, 'status') === "disabled";
            }
          }

          /*
           * @ngdoc method
           * @name isTaskCompletable
           * @description check if task can completed by non-assigned user.
           * @param {Object} task
           * 
           * @returns {bollean}
           */
          function isTaskCompletable(task) {
            return TasksService.checkIsTaskCompletable(task);
          }

          /*
           * @ngdoc method
           * @name resendTask
           * @description Resend task to assigned co-workers and guest
           * @param {Object} event
           */
          function resendTask(event) {
            if (!Helper.checkAccessAuthority())
              return;
            Growl.clearAllMessages('global');
            event.stopPropagation();
            var args,
              params = {
                id: $ctrl.task.id,
                sub_action: 'email'
              };

            if ($ctrl.task.run_id) {
              args = {
                action: 'runs',
                action_id: $ctrl.run.id
              };
              angular.extend(params, args);
            }
            blockUI.start();
            TasksService.resendTask(params).then(function () {
              growl.success($filter('translate')('tasks.general.resendTask'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              $ctrl.isOpenPopOver = false;
              blockUI.stop();
              trackEvent('Task edited', { action: 're-send' });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name copyGuestLink
           * @param {*} owners
           * 
           * @description
           * copy guest link to clipboard 
           */
          function copyGuestLink(owners) {
            $uibModal.open({
              component: 'copyGuestUrl',
              windowClass: 'copy-guest-url-modal',
              backdrop: 'static',
              resolve: {
                owners: function () {
                  return owners;
                }
              }
            });
            $ctrl.isOpenPopOver = false;
          }

          /**
           * @ngdoc method
           * @name deleteStandaloneTask
           * @param {event} event 
           * @return void
           * 
           * @description
           * to delete standalone task
           */
          function deleteStandaloneTask(event) {
            Growl.clearAllMessages('global');
            event.stopPropagation();
            $ctrl.isOpenPopOver = false;
            blockUI.start();
            TasksService.deleteStandaloneTask({ id: $ctrl.task.id }).then(function () {
              growl.success($filter('translate')('tasks.general.deleteTask'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: restoreStandaloneTask,
                  param: {
                    "id": $ctrl.task.id
                  },
                  linkText: $filter('translate')('tasks.general.undo')
                }
              });
              $rootScope.$emit('STANDALONE_TASK:DELETE', { 'id': $ctrl.task.id });
              blockUI.stop();
              trackEvent('Task edited', { action: 'delete' });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name restoreStandaloneTask
           * @param {*} task 
           * @return void
           * 
           * @description
           * to restore standalone task
           */
          function restoreStandaloneTask(task) {
            Growl.clearAllMessages('global');
            blockUI.start();
            TasksService.restoreStandaloneTask(task).then(function (response) {
              growl.success($filter('translate')('tasks.general.restoreTask'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
              $rootScope.$emit('STANDALONE_TASK:RESTORE', response.data);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @name trackEvent
           * @param {*} eventName 
           * @param  {*} properties
           * 
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = $ctrl.task.run_id ? 'standalone' : 'process';
            properties.processId = $ctrl.task.run_id;
            properties.taskId = $ctrl.task.id;
            $analytics.eventTrack(eventName, properties);
          }

          /**
           * @ngdoc method
           * @name taskAssignToGuest
           * @description reminder to assign guest
           * @param {Object} task
           */
          function taskAssignToGuest(task) {
            $ctrl.isAssignToGuest = (task.step && task.step.data.allow_guest_owners && task.owners.guests.length <= 0);
          }

          /**
           * @function getDeadlineClass
           * @description Get css class based on task deadline
           * @param {Object} task
           * @returns Name of css class
           */
          function getDeadlineClass(task) {
            return TasksService.getDeadlineClass(task);
          }

          /**
           * @function getTaskDeadlineStatus
           * @description return task deadline status
           * @param {*} deadline
           * @returns deadline status
           */
          function getTaskDeadlineStatus(deadline) {
            return TasksService.getTaskDeadlineStatus(deadline);
          }

          /**
           * @ngdoc
           * @name updateRouteParams
           * @description An helper function to update query params
           * this function does not reload state.
           * @param params {Object} A key-value pair
           * @returns void
           */
          function updateRouteParams(params) {
            params = params && !_.isObject(params) ? {} : params;
            $state.go('.', params, { notify: false });
          }

          /**
           * event handler when any comment is deleted
           * @type {*|(function())}
           */
          unregisterCommentDeletedHandler = $rootScope.$on(TFY_EVENTS.TASK.COMMENT.DELETED, function () {
            hasAnyIssue();
          });

          /**
           * event handler when task status has been changed
           * @type {*|(function())}
           */
          unregisterTasksStatusListener = $scope.$watch('$ctrl.task.status', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            prepareOwner();
          });

          /**
           * Listen on issue report
           * @type {*|(function())}
           */
          var unregisterIssueReportHandler = $rootScope.$on('ISSUE:REPORTED', function () {
            hasAnyIssue();
          });

          /**
           * Listener on issue resolved
           * @type {*|(function())}
           */
          var unregisterIssueResolveHandler = $rootScope.$on('ISSUE:RESOLVED', function () {
            hasAnyIssue();
          });
        }
    });
})();

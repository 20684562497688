/**
 * @ngdoc Component
 * @name tallyfy.component.samlConfigModal
 * @module tallyfy
 *
 * @description
 * A component to add / edit SAML Configuration
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('samlConfigModal', {
      templateUrl: 'app/components/saml-config-modal/saml-config-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (blockUI, OrganizationsService, _, CONST, Helper) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('samlConfigModal'),
            samlConfigPickValues = OrganizationsService.samlConfigPickList();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveSAMLConfig = saveSAMLConfig;
          $ctrl.copyToClipboard = Helper.copyToClipboard;

          /**
           * public properties
           */
          $ctrl.samlConfig = {};
          $ctrl.URL_REGEX = CONST.URL_REGEX;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.samlProvidersList = OrganizationsService.getSAMLProviderList();
            if ($ctrl.resolve.samlConfig) {
              $ctrl.samlConfig = angular.copy($ctrl.resolve.samlConfig);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name saveSAMLConfig
           * @description create / update client integration
           */
          function saveSAMLConfig() {
            if ($ctrl.samlConfigForm.$valid && !blockUI.state().blocking) {
              saveSSOConfigHandler();
            }
          }

          /**
           * @private
           */
          function saveSSOConfigHandler() {
            $ctrl.onSaving = true;
            var samlConfig = angular.copy($ctrl.samlConfig);
            samlConfig.sso_auth_only = !!_.get(samlConfig, 'sso_auth_only');
            OrganizationsService.createSAMLConfig({ org: $ctrl.resolve.orgID }, _.pick(samlConfig, samlConfigPickValues))
              .then(function (response) {
                $ctrl.close({ $value: response.data });
              }, function () {
                $ctrl.onSaving = false;
              });
          }
        }
    });
})();
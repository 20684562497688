/**
 * @ngdoc Component
 * @name guestTasks
 * @module tallyfy.tasks
 *
 * @description
 * compact guest tasks views
 *
* @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('guestTasks', {
      templateUrl: 'app/modules/tasks/guestTasks/guest-tasks.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, $stateParams, Helper, CompactTaskService, $timeout, TasksFilterService, $scope, GuestTasksRepository, blockUI, $q, TasksService, GuestPreferenceRepository) {
          var $ctrl = this,
            unregisteredIssueReportedEventHandler,
            tasksLengthChangesHandler,
            tasksFiltersConfigWatchers,
            blockUI = blockUI.instances.get('guestTasks');

          $ctrl.filterMetadata = {};
          $ctrl.tasksFiltersConfig = {};

          $ctrl.rightPaneCustomClass = 'compact-container-task-detail ';
          $ctrl.viewGuestTask = false;

          $ctrl.$onInit = onInit;
          $ctrl.onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onItemSwitched = onItemSwitched;
          $ctrl.onRightPaneClosed = onRightPaneClosed;

          // Filter handlers
          $ctrl.saveSelectedTask = saveSelectedTask;
          $ctrl.discardSelectedTask = discardSelectedTask;

          function onInit() {
            blockUI.start();
            GuestPreferenceRepository.getPreferences().then(function (response) {
              $ctrl.preferencesStatus = _.filter(_.get(response, 'data'), function (preference) {
                return preference.slug === "primary-filter";
              });
              if($ctrl.preferencesStatus) {
                angular.extend($stateParams, { status: _.get($ctrl.preferencesStatus[0], 'metadata.status', 'to-do' ), sortBy: _.get($ctrl.preferencesStatus[0], 'metadata.sort_by', 'newest') });
              }
            }, function () { });
            $ctrl.usersInOrg = _.get($rootScope.identity, 'guest.organization.users', []);
            $ctrl.tasksFiltersConfig = { assignee: _.get($rootScope, 'identity.guest.email'), activeTask: _.get($rootScope, 'identity.guest.activeTask') };
            $timeout(function () {
              var params = {
                org_id: _.get($rootScope, 'identity.guest.organization.id'),
                guest_code: _.get($rootScope, 'identity.guest.guest_code'),
                id: _.get($stateParams, 'activeTask'),
                with: "run,threads_count,step,tags,folders",
                skipNotFound: true
              }, resource = TasksService.getGuestTaskById(params);
              resource.then(function (response) {
                $ctrl.currentTask = _.get(response, 'data');
                angular.extend($ctrl.tasksFiltersConfig, { status: $ctrl.currentTask.status });
                $ctrl.viewGuestTask = true;
                blockUI.stop();
              }, function () { $ctrl.viewGuestTask = true; });
            }, 100);
          }

          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * angularjs destroy lifecycle components hook
           */
          function onDestroy() {
            unregisteredIssueReportedEventHandler();
            tasksLengthChangesHandler();
            tasksFiltersConfigWatchers();
          }

          tasksFiltersConfigWatchers = $scope.$watchCollection(function () {
            return _.pick($ctrl.tasksFiltersConfig, ['status', 'sortBy']);
          }, function (value, oldValue) {
            if (value === oldValue) {
              return;
            }
            if (!Helper.isObjectEmpty(value) && $ctrl.tasksFiltersConfig.areTasksFiltersReady) {
              $state.go($state.current, angular.extend($stateParams, _.pick($ctrl.tasksFiltersConfig, TasksFilterService.getApplicableFilterKeysAndStateParams())), { notify: false });
              $rootScope.$broadcast("TASK:FILTER_CONFIG");
            }
          });

          /**
           * @ngdoc method
           * @name onItemSwitched
           * @param {*} item 
           * 
           * @description
           * on item switched
           */
          function onItemSwitched(item) {
            $state.transitionTo($state.current, angular.extend($stateParams, { activeTask: _.get(item.task, 'id', void 0) }), { notify: false });
          }

          /**
           * @ngdoc method
           * @name onRightPaneClosed
           * @param {*} data
           * 
           * @description
           * on right pane closed method callback
           */
          function onRightPaneClosed(data) {
            if (!Helper.isObjectEmpty(data)) {
              $state.transitionTo($state.current, angular.extend($stateParams, {
                activeTask: void 0
              }), {
                notify: false
              });
            }
          }

          /**
           * @ngdoc method
           * @name saveSelectedTask
           * @param {*} args
           * 
           * @description
           * save guest email on selected task
           */
          function saveSelectedTask(args) {
            var defer = $q.defer();
            blockUI.start();
            GuestTasksRepository.updateTaskByGuest(args.task).then(function (res) {
              blockUI.stop();
              defer.resolve({
                isValid: true,
                task: res.data
              });
            }, function () {
              defer.resolve({
                isValid: false
              });
              blockUI.stop();
            });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name discardSelectedTask
           * @param {*} args
           * 
           * @description
           * on closed popup model discard selected task change method callback
           */
          function discardSelectedTask(args) {
            var defer = $q.defer();
            angular.extend(args.task, {}, args.oldTask);
            defer.resolve({
              isValid: true
            });
            return defer.promise;
          }

          // watch for threads data edited
          unregisteredIssueReportedEventHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            _.extend($ctrl.selectedItem.task, { problem: _.get(data, 'issue.label') === 'problem' ? true : false });
            if (data.issue.task_id === $ctrl.selectedItem.task.id) {
              $timeout(function () {
                var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
                $ctrl.rightPaneCustomClass += isHasIssue ? ' has-issue' : '';
              }, 0);
            }
          });

          tasksLengthChangesHandler = $scope.$watch('$ctrl.taskLength', function (value) {
            $ctrl.filterMetadata.taskCount = value === 0 ? null : TasksFilterService.formatCount($ctrl.taskLength, true);
          }, true);
        }
    });
})();
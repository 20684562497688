/**
 * @ngdoc Component
 * @name tallyfy.component.universalSearch
 * @module tallyfy
 *
 * @description
 * A component to handle global search feature
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('universalSearch', {
      templateUrl: 'app/modules/universalSearch/universal-search.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, OrganizationsService, $state, ProcessService, $timeout, moment, $rootScope, ENV_CONFIG, clipboard, universalSearchService, Growl, $filter, PublicLibraryService, BLUEPRINT_TYPE) {
          var $ctrl = this,
            unregisterResetSearchBarListener,
            searchParams = {},
            orgID = _.get($rootScope.identity, 'default_organization.id'),
            growl = new Growl(),
            onFolderClickWatcher;

          /**
           * public properties
           */
          $ctrl.organizationId = _.get($rootScope.identity, 'default_organization.id');
          $ctrl.perPageGlobal = 20;
          $ctrl.bluePrintlistPager = {
            total: 0,
            per_page: $ctrl.perPageGlobal,
            last_page: 0,
            current_page: 1,
            onPageChanged: function () {
              if(this.current_page === this.last_page) {
                return;
              }
              this.current_page = this.current_page + 1;
              searchBluePrintOnly();
            },
            bluePrintLoading: false
          };
          $ctrl.processlistPager = {
            total: 0,
            per_page: $ctrl.perPageGlobal,
            last_page: 0,
            current_page: 1,
            onPageChanged: function () {
              if(this.current_page === this.last_page) {
                return;
              }
              this.current_page = this.current_page + 1;
              searchProcessOnly();
            },
            processLoading: false
          };
          $ctrl.taskslistPager = {
            total: 0,
            per_page: $ctrl.perPageGlobal,
            last_page: 0,
            current_page: 1,
            onPageChanged: function () {
               if(this.current_page === this.last_page) {
                return;
              }
              this.current_page = this.current_page + 1;
              searchTasksOnly();
            },
            tasksLoading: false
          };
          $ctrl.snippetslistPager = {
            total: 0,
            per_page: $ctrl.perPageGlobal,
            last_page: 0,
            current_page: 1,
            onPageChanged: function () {
              if(this.current_page === this.last_page) {
                return;
              }
              this.current_page = this.current_page + 1;
              searchSnippetsPrintOnly();
            },
            snippetsLoading: false
          };
          $ctrl.search = {
            searchText: '',
            reset: function () {
              this.searchText = '';
            }
          };
          $ctrl.froalaTextShortenConfig = {};
          $ctrl.searchKey = '';

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * public methods
           */
          $ctrl.searchGlobal = searchGlobal;
          $ctrl.cancel = cancel;
          $ctrl.changeTab = changeTab;
          $ctrl.isActiveContent = isActiveContent;
          $ctrl.resetSearchBox = resetSearchBox;
          $ctrl.setTagsString = setTagsString;
          $ctrl.openViewMoreModel = openViewMoreModel;
          $ctrl.getTotalResults = getTotalResults;
          $ctrl.openCloseSearchBox = openCloseSearchBox;
          $ctrl.getTaskStatus = getTaskStatus;
          $ctrl.copySearchLink = copySearchLink;
          $ctrl.clearGrowl = clearGrowl;
          $ctrl.hiddenElements = void 0;
          $ctrl.publicBlueprintData = [];
          $ctrl.onViewPublicBluePrint = onViewPublicBluePrint;
          $ctrl.goToFolder = goToFolder;
          $ctrl.isProcedureType = isProcedureType;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            $ctrl.typeTabOptions = universalSearchService.getTabs();
            $ctrl.drawerTab = _.first($ctrl.typeTabOptions);
            searchParams = universalSearchService.getSearchParams();
            if(!_.isEmpty(searchParams)) {
              $ctrl.search.searchText = searchParams.search;
              $ctrl.bluePrintlistPager.current_page = searchParams.blueprint;
              $ctrl.processlistPager.current_page = searchParams.process;
              $ctrl.taskslistPager.current_page = searchParams.task;
              $ctrl.snippetslistPager.current_page = searchParams.snippet;
              $timeout(function() {
                openCloseSearchBox('open');
                searchGlobal();
              }, 1500);
              universalSearchService.setSearchParams({});
              var tabIndex = _.findIndex($ctrl.typeTabOptions, { 'type': _.get(searchParams, 'on') });
              $ctrl.changeTab(tabIndex, $ctrl.drawerTab);
            }
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role'), "admin");
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { 
            unregisterResetSearchBarListener();
            onFolderClickWatcher();
          }

          /**
           * @function
           * @name searchGlobal
           * @description
           * search global with all types
           */
          function searchGlobal(reset) {
            if(!$ctrl.search.searchText) {
              return;
            }
            reset ? resetAll() : angular.noop();
            $ctrl.searchKey = $ctrl.search.searchText;
            var requestParams = {
              org: orgID,
              on: $ctrl.drawerTab.value,
              search:  $ctrl.search.searchText,
              blueprint: $ctrl.bluePrintlistPager.current_page,
              process: $ctrl.processlistPager.current_page,
              task: $ctrl.taskslistPager.current_page,
              snippet: $ctrl.snippetslistPager.current_page,
              per_page: $ctrl.perPageGlobal,
              tags: true
            };
            switch (_.get($ctrl.drawerTab, 'value')) {
              case 'blueprint':
                $ctrl.bluePrintlistPager.bluePrintLoading = true;
                break;
              case 'process':
                $ctrl.processlistPager.processLoading = true;
                break;
              case 'task':
                $ctrl.taskslistPager.tasksLoading = true;
                break;
              case 'snippet':
                $ctrl.snippetslistPager.snippetsLoading = true;
                break;
            }
            var searching = [$ctrl.drawerTab.value];
            fetchSearchResults(requestParams, searching);
          }

          /**
           * @function
           * @name searchBluePrintOnly
           * @description
           * search blueprint only type params
           */
          function searchBluePrintOnly() {
            var requestParams = {
              org: orgID,
              on: 'blueprint',
              search: $ctrl.searchKey,
              per_page: $ctrl.bluePrintlistPager.per_page,
              blueprint: $ctrl.bluePrintlistPager.current_page,
              tags: true
            };
            $ctrl.bluePrintlistPager.bluePrintLoading = true;
            fetchSearchResults(requestParams, ['blueprint']);
          }

          /**
           * @function
           * @name searchProcessOnly
           * @description
           * search process only type params
           */
          function searchProcessOnly() {
            var requestParams = {
              org: orgID,
              on: 'process',
              search: $ctrl.searchKey,
              per_page: $ctrl.processlistPager.per_page,
              process: $ctrl.processlistPager.current_page,
              tags: true
            };
            $ctrl.processlistPager.processLoading = true;
            fetchSearchResults(requestParams, ['process']);
          }

          /**
           * @function
           * @name searchTasksOnly
           * @description
           * search task only type params
           */
          function searchTasksOnly() {
            var requestParams = {
              org: orgID,
              on: 'task',
              search: $ctrl.searchKey,
              per_page: $ctrl.taskslistPager.per_page,
              task: $ctrl.taskslistPager.current_page
            };
            $ctrl.taskslistPager.tasksLoading = true;
            fetchSearchResults(requestParams, ['task']);
          }
          
          /**
           * @function
           * @name searchSnippetsPrintOnly
           * @description
           * search snippets only type params
           */
          function searchSnippetsPrintOnly() {
            var requestParams = {
              org: orgID,
              on: 'snippet',
              search: $ctrl.searchKey,
              per_page: $ctrl.snippetslistPager.per_page,
              snippet: $ctrl.snippetslistPager.current_page,
              tags: true
            };
            $ctrl.snippetslistPager.snippetsLoading = true;
            fetchSearchResults(requestParams, ['snippet']);
          }

          /**
           * @function
           * @name fetchSearchResults
           * @description
           * fetch search results
           */
          function fetchSearchResults(requestParams, types) {
            OrganizationsService.globalSearch(requestParams).then(function(response) {
              if(_.includes(types, 'blueprint')) {
                $ctrl.bluePrintlistPager.bluePrintLoading = false;
                $ctrl.blueprintData = _.concat($ctrl.blueprintData || [], _.get(response, 'blueprint.data'));
                prepareBreadcrumbs($ctrl.blueprintData);
                $ctrl.bluePrintlistPager.total = _.get(response,'blueprint.total', 0);
                $ctrl.bluePrintlistPager.last_page = _.get(response,'blueprint.last_page', 0);
                $ctrl.blueprintDataListEmpty = $ctrl.blueprintData.length < 1;
                if (!$ctrl.blueprintData.length > 0) {
                  $ctrl.bluePrintlistPager.bluePrintLoading = true;
                  getProcess().then(function (response) {
                    $ctrl.bluePrintlistPager.bluePrintLoading = false;
                    $ctrl.publicBlueprintData = _.get(response, 'data');
                  });
                }
              }

              if(_.includes(types, 'process')) {
                $ctrl.processlistPager.processLoading = false;
                $ctrl.processData = _.concat($ctrl.processData || [], _.get(response, 'process.data'));
                $ctrl.processlistPager.total = _.get(response,'process.total', 0);
                $ctrl.processlistPager.last_page = _.get(response,'process.last_page', 0);
                $ctrl.processDataListEmpty = $ctrl.processData.length < 1;
              }

              if(_.includes(types, 'task')) {
                $ctrl.taskslistPager.tasksLoading = false;
                $ctrl.taskData = _.concat($ctrl.taskData || [], _.get(response, 'task.data'));
                $ctrl.taskslistPager.total = _.get(response,'task.total', 0);
                $ctrl.taskslistPager.last_page = _.get(response,'task.last_page', 0);
                $ctrl.tasksDataListEmpty = $ctrl.taskData.length < 1;
              }

              if($ctrl.isAdminMember && _.includes(types, 'snippet')) {
                $ctrl.snippetslistPager.snippetsLoading = false;
                $ctrl.snippetsData = _.concat($ctrl.snippetsData || [], _.get(response, 'snippet.data'));
                $ctrl.snippetslistPager.total = _.get(response,'snippet.total', 0);
                $ctrl.snippetslistPager.last_page = _.get(response,'snippet.last_page', 0);
                $ctrl.snippetsDataListEmpty = $ctrl.snippetsData.length < 1;
              }
              prepareTabCount();
            }, function () {
              $ctrl.bluePrintlistPager.bluePrintLoading = false;
              $ctrl.processlistPager.processLoading = false;
              $ctrl.taskslistPager.tasksLoading = false;
              $ctrl.snippetslistPager.snippetsLoading = false;
            });
          }

          /**
           * @function
           * @name resetAll
           * @description
           * reset all variables
           */
          function resetAll() {
            $ctrl.blueprintData = [];
            $ctrl.processData = [];
            $ctrl.taskData = [];
            $ctrl.snippetsData = [];
            $ctrl.bluePrintlistPager.current_page = 1;
            $ctrl.bluePrintlistPager.last_page = 0;
            $ctrl.bluePrintlistPager.total = 0;
            $ctrl.processlistPager.current_page = 1;
            $ctrl.processlistPager.last_page = 0;
            $ctrl.processlistPager.total = 0;
            $ctrl.taskslistPager.current_page = 1;
            $ctrl.taskslistPager.last_page = 0;
            $ctrl.taskslistPager.total = 0;
            $ctrl.searchKey = '';
            $ctrl.bluePrintlistPager.bluePrintLoading = false;
            $ctrl.processlistPager.processLoading = false;
            $ctrl.taskslistPager.tasksLoading = false;
            $ctrl.snippetslistPager.current_page = 1;
            $ctrl.snippetslistPager.last_page = 0;
            $ctrl.snippetslistPager.total = 0;
            $ctrl.snippetslistPager.snippetsLoading = false;
            prepareTabCount();
            $ctrl.hiddenElements = void 0;
          }

          /**
           * @function
           * @name changeTab
           * @description Execute when change tab in Mobile view to check if it has something need to be saved.
           * @param {integer} seletedIndex 
           * @param {object} step 
           */
          function changeTab(seletedIndex, tab) {
            if(_.get($ctrl.drawerTab, 'type') === _.get($ctrl.typeTabOptions[seletedIndex], 'type')) {
              return;
            }
            tab.class = '';
            $ctrl.drawerTab = $ctrl.typeTabOptions[seletedIndex];
            $ctrl.drawerTab.class = 'active';
            if ((_.get($ctrl.drawerTab, 'value') === 'blueprint' && $ctrl.bluePrintlistPager.total <= 0)
              || (_.get($ctrl.drawerTab, 'value') === 'process' && $ctrl.processlistPager.total <= 0)
              || (_.get($ctrl.drawerTab, 'value') === 'task' && $ctrl.taskslistPager.total <= 0)
              || (_.get($ctrl.drawerTab, 'value') === 'snippet' && $ctrl.snippetslistPager.total <= 0)) {
              searchGlobal();
            }
          }

          /**
           * @function
           * @name isActiveContent
           * @description
           * check active tabss
           */
          function isActiveContent(tabValue) {
            if (tabValue === $ctrl.drawerTab.type) {
              return true;
            }
            return false;
          }

          /**
           * @function
           * @name resetSearchBox
           * @description
           * Reset universal search box value
           */
          function resetSearchBox() {
            resetAll();
            openCloseSearchBox('close');
            $ctrl.search.searchText = "";
          }

          /**
           * @function
           * @name cancel
           * @description
           * Dismiss modal
           */
          function cancel() {
            resetAll();
            if (!_.get($state,'current.previous.name')) {
              $state.go('dashboard');
            } else {
              var previousState = _.get($state,'current.previous.name')
              var previousStateParams = _.get($state,'current.previous.params')
              $state.go(previousState, previousStateParams);
            }
          }

          /**
           * @function
           * @name setTagsString
           * @description
           * add prefix # in tags title and merge it
           */
          function setTagsString(tags) {
            var allTags = "";
            _.forEach(tags, function(tag) {
                allTags += ' #' + tag.title;
            });
            return allTags;
          }

          /**
           * @ngdoc method
           * @name openViewMoreModel
           * @description open view more modal for viewmore details of summary
           */
          function openViewMoreModel(summary, event) {
            event.stopPropagation();
            ProcessService.openViewMoreModal({ summary: summary });
          }

          /**
           * @ngdoc method
           * @name getTotalResults
           * @description get total results
           */
          function getTotalResults() {
            return $ctrl.bluePrintlistPager.total + $ctrl.processlistPager.total + $ctrl.taskslistPager.total + $ctrl.snippetslistPager.total;
          }

          /**
           * @function
           * @name prepareStepTabCount
           * @description
           * update tab count
           */
          function prepareTabCount() {
            $ctrl.typeTabOptions[0].total = $ctrl.bluePrintlistPager.total;
            $ctrl.typeTabOptions[1].total = $ctrl.processlistPager.total;
            $ctrl.typeTabOptions[2].total = $ctrl.taskslistPager.total;
            $ctrl.typeTabOptions[3].total = $ctrl.snippetslistPager.total;
          }

          function openCloseSearchBox(action) {
            var tabIndex,
              universalSearchEle = document.getElementById("universal-search"),
              appHeaderElement = document.getElementsByClassName('app-header')[0],
              isUniversalSearchActive = angular.element(universalSearchEle).hasClass('universal-active');
      
            _.forEach($ctrl.typeTabOptions, function (ele) {
              ele.class = '';
            });
            if ($state.is("settings.org.textTemplate")) {
              tabIndex = _.findIndex($ctrl.typeTabOptions , { value: 'snippet' });
            } else if ($state.is("home")) {
              tabIndex = _.findIndex($ctrl.typeTabOptions , { value: 'task' });  
            } else if ($state.is("run.dashboard") || $state.is("run.view")) {
              tabIndex = _.findIndex($ctrl.typeTabOptions , { value: 'process' });  
            } else {
              tabIndex = _.findIndex($ctrl.typeTabOptions , { value: 'blueprint' });
            }
            $ctrl.drawerTab = $ctrl.typeTabOptions[tabIndex];
            $ctrl.drawerTab.class = 'active';
            $ctrl.changeTab(tabIndex, $ctrl.drawerTab);

            if(action == 'open' && universalSearchEle && !isUniversalSearchActive) {
              angular.element(universalSearchEle).addClass('universal-active');
              angular.element(appHeaderElement).addClass('increase-z-index');
            }
            if(action == 'close' && universalSearchEle && isUniversalSearchActive) {
              angular.element(universalSearchEle).removeClass('universal-active');
              angular.element(appHeaderElement).removeClass('increase-z-index');
            }
          }

          function getTaskStatus(deadline) {
            var taskDeadline = moment(deadline),
              isTaskDue = moment.duration(taskDeadline.diff(moment())).asHours(),
              isOverdue = taskDeadline.isBefore(moment());
            return isOverdue ? 'overdue' : (isTaskDue > 24) ? 'ontime' : 'duesoon';
          }

          function copySearchLink($event) {
            if($event) {
              $event.stopPropagation();
            }
            var link = ENV_CONFIG.TYFY_MAIN_URL + $state.href('search', {
              org_id: orgID,
              on: $ctrl.drawerTab.value,
              search: $ctrl.searchKey,
              blueprint: $ctrl.bluePrintlistPager.current_page,
              process: $ctrl.processlistPager.current_page,
              task: $ctrl.taskslistPager.current_page,
              snippet: $ctrl.snippetslistPager.current_page
            });
            clipboard.copyText(link);
            growl.success($filter('translate')('global.universalSearch.searchLinkCopied'), {
              referenceId: 'universalSearch',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          function clearGrowl() {
            Growl.clearAllMessages('universalSearch');
          }

          /**
           * @ngdoc method
           * @name getProcess
           * @param {*} params
           * 
           * @description
           * get public public blueprint
           */
          function getProcess() {
            var paramsPB = {
              with: 'steps',
              sort: 'updated',
              featured: true
            };
            return PublicLibraryService.getPublicLibrary(paramsPB);
          }

          /**
           * @function
           * @name onViewPublicBluePrint
           * @description It will redirect to public blueprint
           * @return void
           */
          function onViewPublicBluePrint(selected, index) {
            var page = Math.ceil((parseInt(index)+1)/20);
            $state.go('process.templates', {
              status: 'example',
              page: page,
              blueprint_id: _.get(selected, 'id'),
              public_org: _.get(selected, 'organization_id'),
              sortByType: 'all_blueprints'
            });
          }

          //Go to folder
          function goToFolder (event, folder) {
            event.stopPropagation();
            $ctrl.resetSearchBox();
            $state.go('process.templates', { status: 'folder', page: 1, folder_id: folder.id });
          }

          //Check if blueprint is procedure type
          function isProcedureType (blueprint) {
            if (!blueprint.steps_count && (blueprint.type === BLUEPRINT_TYPE.FORM || blueprint.type === BLUEPRINT_TYPE.DOCUMENT)) {
              return false;
            }
            return true;
          }

          //Prepare breadcrumbs
          function prepareBreadcrumbs (blueprints) {
            _.forEach(blueprints, function (blueprint) {
              var folders = angular.copy(blueprint.folders);
              if (folders.length) {
                var breadcrumb = folders.pop();
                breadcrumb = createFolderTree(folders, breadcrumb);
                if (breadcrumb && breadcrumb.parent.data) {
                  var totalLevels = prepareBreadcrumbData(breadcrumb, 1);
                  angular.extend(breadcrumb, { totalLevels: totalLevels });
                }
                angular.extend(blueprint, { breadcrumb: breadcrumb });
              }
            });
          }

          //Create folder tree
          function createFolderTree (folders, root) {
            var data = folders.pop();
            angular.extend(root, { parent: { data: data }});
            if (folders.length) {
              createFolderTree(folders, root.parent.data);
            }
            return root;
          }

          //Prepare breadcrumb levels
          function prepareBreadcrumbData (breadcrumb, level) {
            angular.extend(breadcrumb, { level: level });
            if (breadcrumb.parent) {
              level++;
              return prepareBreadcrumbData(breadcrumb.parent.data, level);
            }
            return level;
          }

          unregisterResetSearchBarListener = $rootScope.$on('UNIVERSAL:SEARCH_RESET', function () {
            $timeout(function() {
              $ctrl.search.searchText = "";
              resetAll();
            });
          });

          onFolderClickWatcher = $rootScope.$on('FOLDER_CLICK:VIEW_BLUEPRINT', function (e, data) {
            resetSearchBox();
          });
        }
     });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.phoneNumberInput
 * @element ANY
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 **/
(function () {
  "use strict";
  angular.module("tallyfy").directive('phoneNumberInput', [
    '$timeout', '_', function ($timeout, _) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
          ctrl.$parsers.push(function (inputValue) {
            if (inputValue === undefined) return '';
            var transformedInput = inputValue.replace(/[^0-9+]/g, '');
            if (transformedInput !== inputValue) {
              ctrl.$setViewValue(transformedInput);
              ctrl.$render();
            }
            return transformedInput;
          });
        }
      };
    }
  ]);

})();
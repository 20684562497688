/**
 * @ngdoc Component
 * @name tallyfy.process.blueprintCardPreview
 * @module tallyfy.process
 *
 * @description
 * Blueprint card component 
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('blueprintCardPreview', {
      templateUrl: 'app/modules/process/components/edit/publishBlueprint/blueprintCardPreview/blueprint-card-preview.component.html',
      bindings: {
        blueprint: '<',
        isPublicPreview: '<',
        onBlueprintSelected: '&?',
        tooltipDisabled: '<',
        showListView: '<?',
        folderId: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, ProcessRepository, $state, ProcessService, $timeout) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onContentSelected = onContentSelected;
          $ctrl.customizeThis = customizeThis;

          function onInit() {
            $ctrl.organization = $ctrl.isPublicPreview ? $rootScope.publicOrganization : $rootScope.identity.default_organization;
          }

          function onChanges(changes) {
            if (changes.blueprint) {
              $ctrl.blueprint.tags = {
                data: _.concat($ctrl.blueprint.industry_tags, $ctrl.blueprint.topic_tags)
              };
            }
          }

          function onDestroy() { }

          function onContentSelected() {
            if (typeof $ctrl.onBlueprintSelected === 'function') {
              $ctrl.onBlueprintSelected({ process: $ctrl.blueprint });
            }
          }

          function customizeThis(event, blueprint) {
            event.stopPropagation();
            $ctrl.onSaving = true;
            ProcessRepository
              .customizeTemplate({
                org: _.get($ctrl.organization, 'id'),
                title: "",
                id: blueprint.id,
                tenant: blueprint.organization_id
              }).then(function (res) {
                if($ctrl.folderId) {
                  $state.go('process.edit', {
                    slug: _.get(res.data, 'id')
                  });
                  onMoveToFolder(_.get(res, 'data'), $ctrl.folderId);
                } else {
                  if (res) {
                    $state.go('process.edit', {
                      slug: _.get(res.data, 'id')
                    });
                    onMoveToFolder(_.get(res, 'data'), null);
                    $ctrl.onSaving = false;
                  }
                }
              }, function () {
                $ctrl.onSaving = false;
              });
          }
          
          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process 
           * @param {*} folder 
           * 
           * @description
           * move blueprint to a folder
           */
          function onMoveToFolder(process, folder) {
            if (folder && _.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              process.folder_id = _.get(folder, 'id');
              process.folders = folder;
              ProcessService.update({
                id: process.id,
                skipNotFound: true
              }, process).then(function(res) {
                $ctrl.onSaving = false;
                $state.go('process.edit', {
                  slug: _.get(res.data, 'id')
                }, { inherit: false });
              }, function() { });
            } else {
              $timeout( function() {
                $state.go('process.edit', {
                  slug: _.get(process, 'id'), view: 'edit'
                }, { inherit: false });
              }, 1000);
            }
          }
        }
    });
})();
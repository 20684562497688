(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.process.factory.ProcessRepository
   * @module tallyfy.process
   *
   * @description
   * Create Rest resource for process
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.process')
    .factory('ProcessRepository', ProcessRepository);

  /**
   * Constructor
   * ProcessRepository construct 
   */
  /*@ngInject*/
  function ProcessRepository(_, BaseRepository, RunTransformer, CONFIG, Helper, $rootScope) {
    var transformResponse = function (response) {
      var transformedResponse = {};
      response = response && Helper.isJson(response) ? angular.fromJson(response) : { message: response };
      if (response.hasOwnProperty('data')) {
        transformedResponse.data = new RunTransformer(response.data);
      } else {
        transformedResponse.data = new RunTransformer(response);
      }
      if (response.hasOwnProperty('meta')) {
        transformedResponse.meta = response.meta;
      }

      return transformedResponse;
    };

    var repository = BaseRepository('/organizations/:org/checklists/:id/:action/:action_id', {
      org: 'org_id',
      id: '@id',
      action: '@action'
    }, {
      $get: {
        method: 'GET',
        transformResponse: transformResponse
      },
      getChecklistsPermissions: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/permissions',
        transformResponse: transformResponse
      },
      setChecklistsPermissions: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/permissions',
        transformResponse: transformResponse
      },
      getActivityFeeds: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/activity-feeds',
        transformResponse: transformResponse
      },
      customizeBlueprint: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/customize',
        transformResponse: transformResponse
      },
      setBlueprintFavorite: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/favorite',
        transformResponse: transformResponse
      },
      deleteBlueprintFavorite: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/favorite',
        transformResponse: transformResponse
      },
      onPinned: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/pin',
        transformResponse: transformResponse
      },
      blueprintBulkFavorite: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/favorite/bulk',
        transformResponse: transformResponse
      }
    });

    repository.getChecklist = function (args) {
      return repository.$get(args).$promise;
    };

    repository.duplicateTemplate = function (params) {
      return repository.$create(params).$promise;
    };

    repository.importTemplate = function (arg) {
      var params = {
        id: arg.id,
        action: 'clone'
      };
      return repository.$create(params, arg).$promise;
    };

    repository.customizeTemplate = function (arg) {
      var params = {
        id: arg.id,
        org: arg.org || _.get($rootScope.identity, 'default_organization.id')
      };
      return repository.customizeBlueprint(params, arg).$promise;
    };

    repository.getFavoriteBlueprint = function (params) {
      return repository.$get(params);
    };

    repository.setFavorite = function (id) {
      var params = {
        subject_id: id,
        subject_type: "Checklist"
      };
      return repository.setBlueprintFavorite(params);
    };
    
    repository.removeFavorite = function (id) {
      var params = {
        subject_id: id,
        subject_type: "Checklist"
      };
      return repository.deleteBlueprintFavorite(params);
    };

    repository.setPinned = function (arg) {
      var params = {
        id: arg.id,
        is_pinned: arg.is_pinned
      };
      return repository.onPinned(params);
    };

    repository.bulkFavorite = function (id, members) {
      var params = {
        subject_id: id,
        subject_type: "Checklist",
        users: members
      };
      return repository.blueprintBulkFavorite(params);
    };

    return repository;
  }
})();

(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .factory('translationHandler', translationHandler);

  translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate'];

  function translationHandler($rootScope, $window, $state, $translate) {
    return {
      initialize: initialize,
      updateTitle: updateTitle
    };

    function initialize() {
      $rootScope.$on('$destroy', function () {
        if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
          translateChangeSuccess();
        }
      });
    }

    // update the window title using params in the following
    // precendence
    // 1. titleKey parameter
    // 2. $state.$current.data.pageTitle (current state page title)
    // 3. 'global.title'
    function updateTitle(titleKey, values) {
      if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
        titleKey = $state.$current.data.pageTitle;
      }
      $translate(titleKey || 'global.title', values).then(function (title) {
        $window.document.title = title;
      });
    }
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.process.component.processOnboarding
 * @module tallyfy.process
 *
 * @description
 * A component to manage process onboarding flow
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('processOnboarding', {
      templateUrl: 'app/modules/process/components/edit/process-onboarding/process-onboarding.html',
      controller:
        /*@ngInject*/
        function (_, Helper, $stateParams, ProcessService, Growl, blockUI, $log, TagsService, $state, $filter, $q, StepService, translationHandler, $uibModal, DESCRIPTIONSIZE, FieldService, ProcessModalService, RunsService) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('onBoardingProcess'),
            modalInstance,
            processName,
            isTitleChanged = false,
            stepsDeadline;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.process = {};
          $ctrl.tags = [];
          $ctrl.forceOnboardGuidanceSteps = [
            { id: 2, title: $filter('translate')('global.processOnboarding.guideStepsTitle.secondStep')},
            { id: 3, title: $filter('translate')('global.processOnboarding.guideStepsTitle.thirdStep')}
          ];
          $ctrl.formFields = FieldService.getFields();

          /**
           * public methods
           */
          $ctrl.checkProcessTitleChanged = checkProcessTitleChanged;
          $ctrl.startRun = startRun;
          $ctrl.openHelpModal = Helper.showCalendlyModal;
          $ctrl.openEditRunNameModal = openEditRunNameModal;
          $ctrl.onStepCreated = onStepCreated;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.processId = $stateParams.slug;
            $ctrl.run = RunsService.getRunModel();
            $ctrl.title = $stateParams.title;
            getResources($ctrl.processId);
            ProcessService.setForceOnboardingFlowData({ key: 'HasCreatedBP', value: 'yes' });
            $ctrl.isForceOnboardingFlow = ProcessService.isForceOnboarding();
            history.pushState(null, document.title, location.href);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getResources
           * @param {*} slug
           * @description Get resources
           */
          function getResources(slug) {
            blockUI.start();
            $q.all([
              ProcessService.get({
                id: slug,
                with: 'steps,steps.captures,tags,folder'
              }),
            ]).then(function (response) {
              var process = response[0].data;
              process.steps.data = _.orderBy(process.steps.data, 'position');
              $ctrl.process = process;
              processName = $ctrl.process.title;
              translationHandler.updateTitle('global.pageTitle.singleTemplate', {
                title: $ctrl.process.title
              });
              $ctrl.run.tags = angular.copy(process.tags.data);
              blockUI.stop();
            }, function () {
                blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name startRun
           * @public
           * @description start a run
           */
          function startRun() {
            blockUI.start();
            StepService.getAllDeadlines({
                id: $ctrl.process.id
            }).then(function (response) {
              stepsDeadline = _.get(response, 'data');
              createRun();
            }, function () {
              blockUI.stop();
            });
          }
          
          /**
           * @ngdoc method
           * @name createRun
           * @public
           * @description Create start a run
           */
          function createRun() {
            var run = prepareRunData();
            RunsService.createRun(run).then(function (resultSet) {
              growl.success($filter('translate')('runs.messages.firstRunCreated'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              ProcessService.setForceOnboardingFlowData({ key: 'HasLaunchedBP', value: 'yes' });
              $state.go('run.view', { id: resultSet.data.id, onboarding: true });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name createRun
           * @public
           * @description Create or start a run
           */
          function prepareRunData() {
            angular.extend($ctrl.run, {
              checklist_id: $ctrl.process.id
            });
            var run = angular.copy($ctrl.run);
            run.name = "Process 1 - " + $ctrl.process.title;
            if (_.isArray(run.tags) && run.tags.length > 0) {
              run.tags = _.map(run.tags, function (o) {
                return (o.tag_id) ? o.tag_id : o.id;
              });
            }
            run.tasks = ProcessModalService.transformTasks(_.get($ctrl, 'process.steps.data', []), stepsDeadline);
            return run;
          }

          /**
           * @function
           * @name openEditRunNameModal
           * @private
           * @description open edit run name modal
           */
          function openEditRunNameModal() {
            if (!Helper.checkAccessAuthority())
              return;
            modalInstance = $uibModal.open({
              templateUrl: 'app/modules/process/components/edit/edit-run-name-modal.html',
              windowClass: 'build-name-modal',
              controller: function () {
                this.runName = $ctrl.process.title;
                this.maxBPTitleLength = DESCRIPTIONSIZE.maxBPTitleLength;
                this.closeVisitingModal = closeVisitingModal;
                this.editName = function () {
                  isTitleChanged = processName != this.runName;
                  if (!_.isEmpty(this.runName)) {
                    $ctrl.process.title = this.runName;
                  }
                };
                this.keypress = function (event) {
                  isTitleChanged = processName != this.runName;
                  if (event.which === 13) {
                    if (!_.isEmpty(this.runName)) {
                      $ctrl.process.title = this.runName;
                    }
                    this.closeVisitingModal();
                  }
                }
              },
              controllerAs: '$ctrl'
            });
            modalInstance.result.then(function () { }, function () {
              if (isTitleChanged) {
                saveProcess('major_update');
              }
              $log.info('modal is cancelled');
            });
          }

          /**
           * @function
           * @name closeVisitingModal
           * @description Close visiting modal
           */
          function closeVisitingModal() {
            if (isTitleChanged) {
              saveProcess('major_update');
            }
            modalInstance.close();
          }

          /**
           * @ngdoc method
           * @name checkProcessTitleChanged
           * @description Check process title has been changed or not
           * @param {String} title
           * @returns {Boolean}
           */
          function checkProcessTitleChanged(title) {
            if (title == $ctrl.process.title) {
              return false;
            }
          }

          /**
           * @ngdoc method
           * @name saveProcess
           * @description Update process
           * @param {String} status
           */
          function saveProcess(status) {
            var majorUpdate = (status === 'major_update'),
              requestPayload = angular.copy($ctrl.process);
            requestPayload.major_update = majorUpdate;
            ProcessService.update({
              id: requestPayload.id
            }, requestPayload).then(function (response) {
              processName = response.data.title;
              growl.success($filter('translate')('process.messages.saveMajorUpdate'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              isTitleChanged = false;
            }, function () {
                blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name onStepCreated
           * @param {*} step 
           *
           * @description on step created method  
           */
          function onStepCreated(step) {
            if (!step) {
              return;
            }
            $ctrl.process.steps.data.push(step);
            if ($ctrl.process.steps.data.length <= 1) {
              createFormField(step);
            }
            if ($ctrl.process.steps.data.length === 3) {
              ProcessService.setForceOnboardingFlowData({ key: 'HasCreatedThreeSteps', value: 'yes' });
            }
          }

          /**
           * @function
           * @name getNewField
           * @description Return a new field Object
           * @returns {Object} field
           */
          function getNewField() {
            var field;
            field = $ctrl.formFields[1];
            field.position = 1;
            field.isNew = true;
            field.label = 'Add notes';
            field.collect_time = true;
            field.options = [];
            field.guidance = null;
            return field;
          }

          /**
           * @function
           * @name createFormField
           * @description 
           * create default large form field for first step/task
           * @params {Object} step
           */
          function createFormField(step) {
            var newField = getNewField();
            StepService.createStepCapture({
              id: step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, _.omit(newField, ['isNew'])).then(function () {
            }, function () {
              $log.info('Large Field Created');
            });
          }
          //controller ends
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.highlightInputOnFocus
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';

  angular.module('tallyfy')
    .directive('highlightElement', function ($timeout) {
      return {
        restrict: 'A',
        scope: {
          highlightElement: '<',
          timeout: '<',
          color: '<'
        },
        link:
          function ($scope, $el) {
            var valueWatcher, backgroundTimeoutHandler, revertBackgroundTimeoutHandler;
            valueWatcher = $scope.$watch('highlightElement', function (value) {
              if (value) {
                if (backgroundTimeoutHandler) {
                  $timeout.cancel(backgroundTimeoutHandler);
                }
                backgroundTimeoutHandler = $timeout(function () {
                  $el.css('background-color', $scope.color);
                }, 0).then(revertBackground);
              }
            });

            function revertBackground() {
              if (backgroundTimeoutHandler) {
                $timeout.cancel(revertBackgroundTimeoutHandler);
              }
              revertBackgroundTimeoutHandler = $timeout(function () {
                $el.removeAttr('style');
                $timeout.cancel(revertBackgroundTimeoutHandler);
              }, $scope.timeout + 1000);
            }

            $scope.$on('$destroy', function () {
              valueWatcher();
            });
          }
      }
    });
})();
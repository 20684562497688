(function () {
  'use strict';
  angular.module('tallyfy')
    .component('taskListPreview', {
      templateUrl: 'app/modules/dashboard/content-card-preview/task-list-preview/task-list-preview.component.html',
      bindings: {
        taskcount: '=',
        myTasks: '<?',
        simpleView: '<?',
        runId: '<',
        process: '<',
        orgAllUsers: '<',
        usersInOrg: '<',
        orgGroups: '<',
        isGuest: '<',
        isPublicProcess: '<',
        saveSelectedTask: "&?",
        discardSelectedTask: '&?',
        metadata: '=?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $state, TasksService, CompactTaskService, $uibModal) {
          var $ctrl = this,
            actualTasks = [],
            koFieldDescriptionClickHandler,
            unregisteredTaskUpdateHandler,
            fieldEditorSelectedWatcher,
            linkTaskToProcessHandler,
            activeModal;

          $ctrl.activeTab = 'task';

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onTitleClick = onTitleClick;
          $ctrl.viewAllTasks = viewAllTasks;
          $ctrl.onKoTitleClick = onKoTitleClick;
          $ctrl.standaloneModal = TasksService.standaloneModal;

          function onInit() {
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id')
            if (!$ctrl.simpleView) {
              getMyTasks();
            } else if (_.get($ctrl.process, 'tasks.data')) {
              $ctrl.myTasks = $filter('activeTask')($ctrl.process.tasks.data, { showHiddenTasks: true, orderBy: 'position' });
            }
          }

          function onChanges() { }

          function onDestroy() {
            koFieldDescriptionClickHandler();
            unregisteredTaskUpdateHandler();
            fieldEditorSelectedWatcher();
            linkTaskToProcessHandler();
          }

          function onKoTitleClick(e, args) {
            if (!$ctrl.simpleView) {
              $state.go('home', { activeTask: task.id });
            } else {
              openKoFormModal(args);
            }
          }

          function openKoFormModal(args) {
            $uibModal.open({
              component: 'singleTaskModal',
              windowClass: 'single-task-modal',
              backdrop: 'static',
              resolve: {
                process: function () {
                  return $ctrl.process;
                },
                orgAllUsers: function () {
                  return $ctrl.orgAllUsers;
                },
                usersInOrg: function () {
                  return $ctrl.usersInOrg;
                },
                orgGroups: function () {
                  return $ctrl.orgGroups;
                },
                args: function () {
                  return args;
                },
                metadata: function () {
                  return $ctrl.metadata;
                }
              }
            });
          }

          function getMyTasks() {
            $ctrl.tasksLoading = true;
            CompactTaskService.getUserTasks({
              action: "users",
              action_id: $rootScope.identity.id,
              page: 1,
              per_page: 8,
              sort: 'newest',
              status: 'active_visible'
            }).then(function (res) {
              actualTasks = _.concat(actualTasks, res.data);
              $ctrl.myTasks = $filter('activeTask')(actualTasks, { showHiddenTasks: true, orderBy: 'position' });
              ($ctrl.myTasks && $ctrl.myTasks.length < 8 && res.meta.pagination.current_page < res.meta.pagination.total_pages) ? getMyTasks() : $ctrl.tasksLoading = false;
              $ctrl.taskcount = res.meta.visible_tasks_total;
              if ($ctrl.process) {
                getKoFieldAssigneeTasks();
              }
            }, function () {
              $ctrl.tasksLoading = false;
            });
          }

          linkTaskToProcessHandler = $rootScope.$on('STANDALONE_TASK:CREATED', function (e, data) {
            if (!$ctrl.simpleView) {
              return;
            }
            if (data.taskOpen) {
              $ctrl.myTasks = $ctrl.myTasks ? _.concat($ctrl.myTasks, data.task) : [data.task];
              $ctrl.taskcount++;
            }
          });

          function onTitleClick(e, task, args) {
            if (!$ctrl.simpleView) {
              $state.go('home', { activeTask: task.id });
            } else {
              if (task === 'ko-field') {
                onKoTitleClick(e, args);
              } else {
                activeModal = $uibModal.open({
                  component: 'singleTaskModal',
                  windowClass: 'single-task-modal',
                  backdrop: 'static',
                  resolve: {
                    saveSelectedTask: function () {
                      return $ctrl.saveSelectedTask;
                    },
                    discardSelectedTask: function () {
                      return $ctrl.discardSelectedTask;
                    },
                    selectedTask: function () {
                      return task;
                    },
                    process: function () {
                      return $ctrl.process;
                    },
                    orgAllUsers: function () {
                      return $ctrl.orgAllUsers;
                    },
                    usersInOrg: function () {
                      return $ctrl.usersInOrg;
                    },
                    selectedTaskIndex: function () {
                      var visibleTasks = _.filter($ctrl.myTasks, function (task) {
                        return task.status !== 'auto-skipped';
                      });
                      return _.findIndex(visibleTasks, { id: task.id });
                    },
                    orgGroups: function () {
                      return $ctrl.orgGroups;
                    },
                    allowGuestsAssigned: function () {
                      return true;
                    }
                  }
                });
              }
            }
          }

          function viewAllTasks() {
            $state.go('home');
          }

          function getKoFieldAssigneeTasks() {
            var preruns = _.get($ctrl.process, 'checklist.data.prerun', []);
            if (!preruns.length) {
              return;
            }
            for (var i = 0; i < actualTasks.length; i++) {
              var tempEl = angular.element('<div>');
              tempEl.html(actualTasks[i].summary);
              var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
              if (koAssigneeElements.length) {
                var koField = _.find(preruns, { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') });
                if (!$ctrl.metadata.koFieldAssignee) {
                  $ctrl.metadata.koFieldAssignee = {};
                }
                if (!$ctrl.metadata.koFieldTaskAssignee) {
                  $ctrl.metadata.koFieldTaskAssignee = {};
                }
                $ctrl.metadata.koFieldAssignee[koField.id] = _.pick(actualTasks[i].owners, ['users', 'guests', 'groups']);
                $ctrl.metadata.koFieldTaskAssignee[koField.id] = actualTasks[i];
              }
            }
          }

          unregisteredTaskUpdateHandler = $rootScope.$on('COMPACT_TASK:UPDATE', function (e, data) {
            var task = _.find(actualTasks, { id: data.task.id });
            angular.extend(task, data.task);
            getKoFieldAssigneeTasks();
          });

          koFieldDescriptionClickHandler = $rootScope.$on('KO_FIELD_ASSIGNEE:DESCRIPTION_CLICK', function (e, data) {
            if (!activeModal) {
              return;
            }
            activeModal.close();
            var selectedPrerun = _.find(_.get($ctrl.process, 'ko_form_fields.data', []), { alias: data.alias });
            onKoTitleClick(e, { focusField: selectedPrerun });
          });

          fieldEditorSelectedWatcher = $rootScope.$on('FIELD_EDITOR:SELECTED', function (e, data) {
            if (data.openKoFormTask) {
              openKoFormModal(null);
            }
          });
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.uiSelectOnBlur
 * @description detect blur event on ui-Select dropdown
 * @restrict 'A'
 * @element ANY
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('uiSelectOnBlur', uiSelectOnBlur);
    /*@ngInject*/
    function uiSelectOnBlur($timeout, $parse) {
      return {
        require: 'uiSelect',
        restrict:'A',
        link: function($scope, elm, attrs) {
          var saveForm = $parse(attrs.uiSelectOnBlur),
            savePromise = null,
            listener;
          elm.on('blur', 'input.ui-select-search', function() {
            listener = $scope.$watch(attrs.ngModel, function (newValue, oldValue) {
              if(newValue != oldValue) {
                if(savePromise) {
                  $timeout.cancel(savePromise);
                }
                savePromise = $timeout(function() {
                  savePromise = null;
                  $scope.$eval(saveForm);
                }, 500);
              }
            });
          });
          $scope.$on('$destroy', function() {
            if (listener) {
              listener();
            }
          });
        }
      };
    }
})();

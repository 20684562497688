(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('elementResize', elementResize);

  function elementResize($timeout) {
    return {
      restrict: 'A',
      scope: {
        url: '@?',
        methodHandler: '&',
        syncHeight: '<'
      },
      link: function (scope, $element) {
        $timeout(function () {
          var parent = $element[0].parentNode, style = 'position:absolute;left:0;top:-100%;height:100%;margin:1px 0 0;border:none;opacity:0;pointer-events:none;';
          if (!parent) {
            return;
          }
          function syncHeight() {
            var heigth = window.innerHeight
              || document.documentElement.clientHeight
              || document.body.clientHeight;
            $element.css('height', heigth - 56);
          }

          function onResize(element) {
            var frame = document.createElement('iframe');
            var supportsPE = document.documentMode < 11 && 'pointerEvents' in frame.style;

            frame.style.cssText = style + (supportsPE ? '' : 'visibility:hidden;');
            frame.classList.add('frame-resize-element');
            frame.scrolling = 'no';
            frame.onload = function () {
              frame.contentWindow.onresize = function () {
                if (scope.syncHeight) {
                  syncHeight();
                }
                if (scope.methodHandler) {
                  scope.methodHandler({ el: parent.id });
                }
              };
            };
            element.appendChild(frame);
            return frame;
          }

          parent.style.position = "relative";
          parent.id = "nav-parent-controller";
          $element[0].addEventListener('scroll', function () {
            if (scope.syncHeight) {
              syncHeight();
            }
            if (scope.methodHandler) {
              scope.methodHandler();
            }
          });
          onResize(parent);
          if (scope.syncHeight) {
            syncHeight();
          }
        }, 10);
      }
    }
  }
})();

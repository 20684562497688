/**
 * @ngdoc overview
 * @name tallyfy.tasks
 *
 * @module tallyfy.tasks
 *
 * @description
 * manage tasks
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.tasks', ['infinite-scroll','ui.bootstrap']);
})();

(function () {
  'use strict';
  var $global = null;

  angular.module('angulartics.quantify', ['angulartics', 'tallyfy'])
    .config(config)
    .run(run);

  function fetchEvent(orgId, userId, category, eventType, eventName) {
    $global.$tallyfyWebSocketFactory
      .$send({
        "type": "event",
        "org_id": orgId,
        "user_id": userId,
        "category": category,
        "event_type": eventType,
        "event_name": eventName
      });
  }

  function config($analyticsProvider) {
    $analyticsProvider.registerEventTrack(
      function (action, props) {
        var identityId, organizationId;
        if ($global.$rootScope.userState === $global.USER_STATE.GUEST) {
          identityId = $global._.get($global.$rootScope, 'identity.guest.email');
          organizationId = $global._.get($global.$rootScope, 'identity.guest.organization.id');
        } else if ($global.$rootScope.userState === $global.USER_STATE.MEMBER) {
          identityId = $global._.get($global.$rootScope, 'identity.id').toString();
          organizationId = $global._.get($global.$rootScope, 'identity.default_organization.id');
        } else if ($global.$rootScope.userState === $global.USER_STATE.PUBLIC_PROCESS) {
          identityId = $global.USER_STATE.PUBLIC_PROCESS;
          organizationId = $global._.get($global.$rootScope, 'publicOrganization.id');
        }
        if (identityId || ($global.$rootScope.userState === $global.USER_STATE.PUBLIC_PROCESS)) {
          if (!$global.$tallyfyWebSocketFactory.$isAuthed()) {
            $global.$tallyfyWebSocketFactory.$setAuth();
          }
          fetchEvent(organizationId, identityId, props.category || 'custom', props.eventType || 'custom', action);
        }
      }
    );
  }

  /*@ngInject*/
  function run(_, $rootScope, $tallyfyWebSocketFactory, USER_STATE) {
    $global = {
      _: _,
      $rootScope: $rootScope,
      $tallyfyWebSocketFactory: $tallyfyWebSocketFactory,
      USER_STATE: USER_STATE
    };
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.scrollDown
 * @description detect scroll down event
 * @restrict 'A'
 * @element ANY
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollDown', scrollDown);
    /*@ngInject*/
    function scrollDown($timeout) {
      return {
        restrict: 'A',
        scope : {
          downButton : '<'
        },
        link: function (scope, element, attrs) {
          element.bind('scroll', function () {
            $timeout(function() {
              if (element[0].scrollHeight - element.scrollTop() < element.height() + 80) {
                $('.'+scope.downButton).css({ "display": 'none' });
              } else {
                $('.'+scope.downButton).css({ "display": 'initial' });
              }
            }, 1500);
          });
        }
      };
    }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('guestReminderModal', {
      templateUrl: 'app/modules/runs/create/guestReminderModal/guest-reminder-modal.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // Public methods
          $ctrl.stayOnPage = stayOnPage;
          $ctrl.continueToNextPage = continueToNextPage;

          function onInit() {

          }

          function onChanges() {

          }

          function onDestroy() {

          }

          // Close modal - stay on page
          function stayOnPage () {
            $ctrl.close({ $value: 'cancel' });
          }

          // Close modal - continue to next page
          function continueToNextPage () {
            $ctrl.close({ $value: 'continue' });
          }
        }
    })
})();

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('copyGuestUrl', {
      templateUrl: 'app/modules/tasks/copyGuestUrl/copy-guest-url.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, Helper) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.guestUrls = [];
          $ctrl.copyLink = copyLink;

          function onInit() {
            $ctrl.showMemberLink = $ctrl.resolve.showMemberLink;
            if ($ctrl.showMemberLink) {
              $ctrl.taskLink = { url: window.location.href };
            }
          }

          function onChanges(changes) {
            if (changes.resolve) {
              _.forEach($ctrl.resolve.owners.guests, function (value, index) {
                $ctrl.guestUrls.push({
                  email: value,
                  url: $ctrl.resolve.owners.taskUrls[index]
                });
              });
            }
          }
          function onDestroy() { }

          function copyLink(e, guestUrl) {
            Helper.copyToClipboard(guestUrl.url, 'guestUrl', e);
          }
        }
    })
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tallyfyTagInput', {
      templateUrl: 'app/components/tallyfy-tag-input/tallyfy-tag-input.component.html',
      bindings: {
        subjectId: '<',
        subjectType: '<',
        tagType: '<',
        ngModel: '=',
        maxItemToDisplay: '<',
        notAttach: '<?',
        ngModelChange: '&?',
        readOnly: '<',
        customGrowl: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, $q, $element, TagsService, blockUI, $timeout, Helper, $rootScope, AccountService, preferencesStore) {
          var $ctrl = this,
            $tagsPromise,
            $suggestionList,
            // save all words that already checked
            tempQuery = [],
            // all queried tags
            tagSource = [],
            $container = $element.find('.tallyfy-tag-input'),
            $input = $element.find('input');

          $ctrl.selectedIndex = 0;
          $ctrl.tags = [];

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onTagSelected = onTagSelected;
          $ctrl.onTagRemoved = onTagRemoved;
          $ctrl.queryingTags = queryingTags;
          $ctrl.hightLightMatchChars = hightLightMatchChars;
          $ctrl.onFocus = onFocus;
          $ctrl.onBlur = onBlur;
          $ctrl.removeHash = removeHash;
          $ctrl.openCloseTags = openCloseTags;

          $ctrl.getTagTextColor = TagsService.isTagTextColor;
          $ctrl.filterQuery = '';

          function onInit() {
            initializeEventHandler();
          }

          function onChanges(changes) {
            if (changes.maxItemToDisplay && !changes.maxItemToDisplay.currentValue) {
              $ctrl.maxItemToDisplay = 32;
              getTags(void 0);
            }
          }

          function onDestroy() {
            destroyEventHandler();
          }

          function initializeEventHandler() {
            $container.on('click', onElementClickHandler);
            $container.on('focusout', onBlur);
            $input.on('keydown', onKeydownHandler);
          }

          function destroyEventHandler() {
            $container.off('click', onElementClickHandler);
            $container.off('focusout', onBlur);
            $input.off('keydown', onKeydownHandler);
          }

          function fetchTaskSource(query) {
            var defer = $q.defer();
            var params = {
              page: 1,
              per_page: $ctrl.maxItemToDisplay,
              q: query
            };
            params.action = $ctrl.tagType !== 'private' ? $ctrl.tagType : 'tags';
            if ($ctrl.tagType !== 'private') {
              params.all_tags = false;
            }
            var source = $ctrl.tagType !== 'private'
              ? TagsService.getPublicTags(params)
              : TagsService.get(params);

            source.then(function (res) {
              tagSource = _.uniqBy(
                _.concat(tagSource || [],
                  _.filter(res.data, function (tag) {
                    return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
                  })
                ), 'id');
              defer.resolve(tagSource);
            }, function (err) {
              defer.reject(err);
            });

            return defer.promise;
          }

          function getTags(query) {
            var defer = $q.defer();
            fetchTaskSource(query)
              .then(function (tags) {
                defer.resolve(tags);
              }, function (err) {
                defer.reject(err);
              });
            return defer;
          }

          function onFocus() {
            var container = getContainer();
            container.addClass('focus');
            $ctrl.showTagList = true;
          }

          function onBlur() {
            var container = getContainer();
            container.removeClass('focus');
            $timeout(function () {
              $ctrl.showTagList = false;
            }, 350);
          }

          function onElementClickHandler(e) {
            if (e.target.className.includes('tag-content-container') || e.target.className.includes('tag-model-value-container')) {
              $input.focus();
            }
            if (!e.target.className.includes('tag-remove') && !e.target.className.includes('rotate')) {
              queryingTags(e);
              onFocus();
            }
          }

          function getContainer() {
            return $element.find('.tag-content-container');
          }

          function onKeydownHandler(e) {
            // enter
            if (e.keyCode === 13) {
              e.preventDefault();
              $ctrl.onTagSelected(e, $ctrl.tags[$ctrl.selectedIndex]);
            }
            // space
            else if (e.keyCode === 32) {
              e.preventDefault();
              $ctrl.filterQuery ? attachNewTag() : angular.noop();
            }
            // arrow down
            else if (e.keyCode === 40) {
              e.preventDefault();
              $ctrl.selectedIndex++;
            }
            // arrow up
            else if (e.keyCode === 38) {
              e.preventDefault();
              $ctrl.selectedIndex--;
            }

            $ctrl.selectedIndex < 0
              ? $ctrl.selectedIndex = 0
              : ($ctrl.selectedIndex >= $ctrl.tags.length
                ? $ctrl.selectedIndex = $ctrl.tags.length - 1
                : angular.noop());

            $suggestionList = $element.find('.tag-autocomplete-source-container');
            $scope.$apply(function () {
              $suggestionList.scrollTop(($ctrl.selectedIndex * 32.8) - ($suggestionList.height() / 4));
            });
          }

          function queryingTags(e, query) {
            $ctrl.selectedIndex = 0;
            $ctrl.tags = filterTags(query) || [];
            if ($ctrl.tags.length <= $ctrl.maxItemToDisplay) {
              if ($tagsPromise && !$tagsPromise.promise.$$state.status) {
                $tagsPromise.resolve();
              }
              if (_.indexOf(tempQuery, query) === -1) {
                $tagsPromise = getTags(query);
                $tagsPromise.promise.then(function () {
                  $ctrl.tags = filterTags(query);
                  tempQuery.push(query);
                });
              }
            }
          }

          function filterTags(query) {
            var tags =_.orderBy(
              _.filter(
                _.filter(tagSource, function (tag) {
                  return _.findIndex($ctrl.ngModel, {
                    title: tag.title
                  }) === -1;
                }), function (tag) {
                  return tag.title.toLowerCase().indexOf((query || '').toLowerCase()) !== -1;
                }
              ), 'title', 'asc');
            _.forEach(tags, function(value) {
                value.title = removeHash(value.title);
            });
            return tags;
          }

          function removeHash(query) {
            return query.replaceAll(/#/g,'');
          }

          function onTagSelected(e, tag) {
            e.stopPropagation();
            if($ctrl.subjectType === 'filterAP') {
              $rootScope.$emit('FILTER_AP:TAGS', tag);
              $rootScope.$emit('FILTER_AP_TAGS:TAGS', tag);
            } else if($ctrl.subjectType === 'filterTask') {
              $rootScope.$emit('FILTER_TASK:TAGS', tag);
            } else if (tag) {
              blockUI.start();
              attachTag(tag);
            } else {
              attachNewTag();
            }
          }

          function attachNewTag() {
            if ($ctrl.tagType === 'private') {
              blockUI.start();
              TagsService.get({
                action: 'tags',
                q: $ctrl.filterQuery,
                strict: true
              }).then(function (res) {
                if (!res.data.length) {
                  TagsService.createTag({
                    title: $ctrl.filterQuery
                  }).then(function (resp) {
                    tagSource.push(resp.data);
                    attachTag(resp.data);
                  });
                } else {
                  attachTag(res.data[0]);
                }
              });
            } else {
              if ($ctrl.tagType !== 'industry') {
                TagsService.attachPublicTag({
                  title: $ctrl.filterQuery,
                  type: $ctrl.tagType,
                  blueprint_id: $ctrl.subjectId
                }).then(function (res) {
                  attachTag(res.data);
                  blockUI.stop();
                }, function () {
                  blockUI.stop();
                });
              }
            }
          }

          function attachTag(tag) {
            if ($ctrl.notAttach) {
              if (tag.id) {
                $ctrl.ngModel = _.uniqBy(_.compact(_.concat($ctrl.ngModel, tag)), 'id');
              } else {
                $ctrl.ngModel = _.compact(_.concat($ctrl.ngModel, tag));
              }
              $ctrl.filterQuery = '';
              $timeout(function () {
                $ctrl.showTagList = false;
              }, 0);
              blockUI.stop();
            } else {
              TagsService.attachTag(tag, {
                id: $ctrl.subjectId,
                type: $ctrl.subjectType
              }).then(function (res) {
                $ctrl.ngModel = _.uniqBy(_.compact(_.concat($ctrl.ngModel, res.data)), 'id');
                $ctrl.filterQuery = '';
                $ctrl.showTagList = false;
                if (typeof $ctrl.ngModelChange === 'function') {
                  $ctrl.ngModelChange({ ngModel: $ctrl.ngModel, current: tag, type: 'added' });
                }
                blockUI.stop();
                if (!$ctrl.customGrowl) {
                  Helper.showChangesSavedGrowl();
                }
              }, function () {
                blockUI.stop();
              });
            }
          }

          function onTagRemoved(e, tag) {
            e.stopPropagation();
            if($ctrl.subjectType === 'filterAP') {
              $rootScope.$emit('REMOVE_FILTER_AP:TAGS', tag);
            } else if($ctrl.subjectType === 'filterTask') {
              $ctrl.ngModel = _.filter($ctrl.ngModel, function (model) { return model.id !== tag.id; });
              $rootScope.$emit('REMOVE_FILTER_TASK:TAGS', tag);
            } else if ($ctrl.notAttach) {
              $ctrl.ngModel = _.filter($ctrl.ngModel, function (model) { return model.id !== tag.id; });
              filterTags($ctrl.filterQuery);
              $ctrl.showTagList = false;
            } else {
              blockUI.start();
              TagsService.detachTag(tag.id).then(function () {
                $ctrl.ngModel = _.filter($ctrl.ngModel, function (model) { return model.id !== tag.id; });
                filterTags($ctrl.filterQuery);
                if (typeof $ctrl.ngModelChange === 'function') {
                  $ctrl.ngModelChange({ ngModel: $ctrl.ngModel, current: tag, type: 'removed' });
                }
                blockUI.stop();
                if (!$ctrl.customGrowl) {
                  Helper.showChangesSavedGrowl();
                }
              }, function () {
                blockUI.stop();
              });
            }
          }

          function encodeHTML(value) {
            return (angular.isUndefined(value) || value === null ? '' : value.toString().trim()).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
          }

          function hightLightMatchChars(str, value) {
            str = encodeHTML(str);
            value = encodeHTML(value);

            if (!value) {
              return str;
            }

            var regex = new RegExp('&[^;]+;|' + value.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'gi');
            return str.replace(regex, function (match) {
              return match.toLowerCase() === value.toLowerCase() ? '<b>' + match + '</b>' : match;
            });
          }

          function openCloseTags(e, value) {
            if (value) {
              $ctrl.showTagList = false;
            } else if (!value && e.target.className.includes('rotate')) {
              $ctrl.onBlur();
            } else {
              queryingTags(e);
              onFocus();
            }
          }
        }
    });
})();

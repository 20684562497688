/**
 * @ngdoc Directive
 * 
 * @name tallyfy.validatePassword
 * 
 * @author Jonathan Emanuel ( gmail::jonathanemauel.rios@gmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .directive('validatePassword', validatePassword);
  validatePassword.$inject = ['_', '$filter', '$compile'];
  function validatePassword(_, $filter, $compile) {
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        scope.password_policy = {
          'contain_uppercase_letter': false,
          'contain_lowercase_letter': false,
          'contain_number': false,
          'at_least_6_letters': false
        };
        scope.valid = false;
        scope.is_empty = true;
        scope.is_focus = false;
        scope.password_val = "";
        scope.errorMessage = "";

        scope.showMessage = function () {
          if (scope.is_focus || scope.password_val.length === 0) {
            return false;
          }

          if (!scope.valid) {
            return true;
          }

          if (!_.isUndefined(scope.password_policy.passwords_match) && !scope.password_policy.passwords_match) {
            if (scope.errorMessage === "") {
              scope.errorMessage = _.capitalize($filter('translate')('global.form.fields.password.errors.must_match'));
            }
            return true;
          }

          return false;
        };
        var validation = 'validation' + elem.context.id;
        if (attrs.matchPassword) {
          scope.password_policy = _.assign(scope.password_policy, { 'passwords_match': false });
        }
        elem.add().on('focus', function () {
          scope.is_focus = true;
          if (!scope.valid || !scope.password_policy.passwords_match) {
            angular.element('#' + validation).removeAttr('hidden');
          }
        });
        elem.add().on('blur', function () {
          angular.element('#' + validation).attr('hidden', true);
          scope.is_focus = false;
        });
        elem.add().on('keyup', function () {
          scope.$apply(function () {
            var str = elem.val();
            var hasNumber = (/[0-9]/.test(str)), hasLowercase = (/[a-z]/.test(str)), hasUppercase = (/[A-Z]/.test(str)),
              minLengthCheck = str.length >= 6, isEmpty = str.length === 0;
            scope.password_val = str;
            scope.password_policy.contain_number = hasNumber;
            scope.password_policy.contain_lowercase_letter = hasLowercase;
            scope.password_policy.contain_uppercase_letter = hasUppercase;
            scope.password_policy.at_least_6_letters = minLengthCheck;
            scope.is_empty = isEmpty;
            scope.valid = hasNumber && hasLowercase && hasUppercase && minLengthCheck;
            scope.errorMessage = "";
            ctrl.$setValidity('validPassword', scope.valid);
            var arr = [];
            // Validation message if doesn't match the password
            if (!_.isUndefined(scope.password_policy.passwords_match) && !scope.password_policy.passwords_match) {
              scope.errorMessage = $filter('translate')('global.form.fields.password.errors.must_match');
            }

            // Password validation
            if (!scope.valid && elem.val()) {
              if (scope.errorMessage.length) {
                scope.errorMessage += ', ';
              }
              scope.errorMessage += $filter('translate')('global.form.fields.password.errors.must_contain');
              _.forEach(scope.password_policy, function (value, key) {
                if (!value && key !== 'passwords_match') {
                  arr.push($filter('translate')('global.form.fields.password.errors.' + key));
                }
              });
            }

            if (arr.length === 1) {
              scope.errorMessage += arr[0];
            } else if (arr.length > 1) {
              scope.errorMessage += _.initial(arr).join(', ') + ', & ' + arr[arr.length - 1];
            }
            scope.errorMessage = _.capitalize(scope.errorMessage);
          });
          scope.$on('$destroy', function () {
            elem.unbind('keyup');
          });
        });
        if (elem.next().length) {
          elem.next().insertBefore(elem);
        }

        var content = '<ul id="' + validation + '" hidden="true" class="password-policy mb-0">' +
          '<li ng-repeat="(key, value) in password_policy">' +
          '<i class="w-12 m-r-6px" ng-class="{\'icon-check-simple\': value, \'icon-close\': !value && is_empty, \'icon-close-error\': !value && !is_empty}"></i>' +
          '<span class="t-slate" translate="global.form.fields.password.password_policy.{{key}}"></span>' +
          '</li>' +
          '</ul>';
        content = content + '<span id="msg_' + validation + '" ng-if="showMessage()" class="form-control-feedback" translate="{{errorMessage}}"></span>';
        var addIcon = angular.element("<i class='fas fa-check-circle form-field-icon'></i>");
        addIcon.insertAfter(elem);
        var contentUl = angular.element(content);
        contentUl.insertAfter(elem.closest('div'));
        $compile(contentUl)(scope);
      }
    };
  }
})();
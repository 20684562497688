(function () {
  'use strict';
  angular.module('tallyfy')
    .service('RenderTableService', RenderTableService);

  function RenderTableService(_, Helper) {
    var self = this;
    
    self.getOptions = function (id, columns, readOnly, updateCallback, exportFileName, isDisabled) {
      var fields = { guid: { editable: false, nullable: true } };
      for (var i = 0; i < columns.length; i++) {
        fields[columns[i].field] = { editable: true, nullable: true };
      }
      if (!readOnly) {
        columns.push(
          {
            command: {
              name: 'removeRow',
              template: kendo.template($('#renderTableDeleteCommand_' + id).html())
            },
            width: 48
          }
        );
      }
      return {
        columns: columns,
        dataSource: new kendo.data.DataSource({
          data: [],
          transport: {
            read: function (o) {
              o.success([]);
            },
            create: function (o) {
              o.data.guid = Helper.guid();
              o.success(o.data);
            },
            update: function (o) {
              o.success();
            },
            destroy: function (o) {
              o.success();
            }
          },
          schema: {
            model: {
              id: 'guid',
              fields: fields
            }
          },
          requestEnd: function (e) {
            updateCallback(e.sender.data(), e.type === "destroy");
          },
        }),
        excelExport: function (e) {
          e.workbook.fileName = (exportFileName || 'export') + ".xlsx";
        },
        pdf: {
          fileName: (exportFileName || 'export') + ".pdf"
        },
        sortable: !isDisabled,
        resizable: true,
        noRecords: {
          template: kendo.template($('#renderTableNoData_' + id).html())
        },
        editable: readOnly ? false : {
          confirmation: false,
          createAt: "bottom"
        },
        cellClose: function () {
          this.dataSource.sync();
        }
      };
    }
    return self;
  }
})();
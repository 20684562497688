/**
 * @ngdoc component
 * @name tallyfy.CompactTaskService
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .service('CompactTaskService', CompactTaskService);

  /*@ngInject*/
  function CompactTaskService(_, $rootScope, $q, $filter, $uibModal, $timeout, Growl, blockUI, TasksService, MessagesService, MESSAGE_TYPE, FroalaService, CONST, Helper, RunsService, FieldService, moment, COMMON, DateUtils, DATEFORMAT, USER_STATE) {
    var self = this,
      growl = new Growl(),
      realValue,
      allFormFields,
      temRealValue,
      blockUI = blockUI.instances.get('tasks');

    self.getFileLocation = Helper.getFileLocation;
    self.isImage = Helper.isImage;
    self.isCaptureUpdateInProgress = false;
    self.isCaptureUpdateInQueue = false;

    /**
     * @ngdoc method
     * @name isHasIssue
     * @param {*} task 
     * @description
     * check task has issue
     */
    self.isHasIssue = function (task) {
      var threads = _.get(task, 'threads.data');
      var issue = (TasksService.getIssueWithUnresolvedProblem(threads).length > 0 || _.get(task, 'problem')) && task.status !== 'completed';
      return !issue ? _.get(task, 'hasIssues') : issue;
    };

    /**
     * @ngdoc method
     * @name getUserTasks
     * @param {*} params 
     * @description
     * get user tasks API call 
     */
    self.getUserTasks = function (params) {
      return TasksService.getUserTasks(params);
    };

    /**
     * @ngdoc method
     * @name getGuestTasks
     * @param {*} params 
     * @description
     * get guest tasks API call 
     */
    self.getGuestTasks = function (params) {
      return TasksService.getGuestTask(params);
    };

    self.getPublicProcessTasks = function (params) {
      return TasksService.getPublicProcessTasks(params);
    };

    self.getPublicProcessFormFields = function (params) {
      return TasksService.getPublicProcessFormFields(params);
    };

    /**
     * @ngdoc method
     * @name updateTask
     * @param {*} task 
     * @param {*} payloads 
     * @description
     * update task API call 
     */
    self.updateTask = function (task, payloads) {
      return task.run_id
        ? TasksService.updateTask({
          action_id: task.run_id,
          id: task.id
        }, payloads || task)
        : TasksService.updateStandaloneTask({
          id: task.id
        }, {
          name: task.name,
          owners: task.owners,
          deadline: DateUtils.toUTC(moment(task.deadline).format()).format(DATEFORMAT.DEFAULT),
          taskdata: task.taskdata
        });
    };

    self.updateStandaloneTask = function (task, payloads) {
      return TasksService.updateStandaloneTask({
        id: task.id
      }, payloads || task);
    };

    /**
     * @ngdoc method
     * @name updateGuestTask
     * @param {*} task 
     * @param {*} payloads 
     * @description
     * update guest task API call 
     */
    self.updateGuestTask = function (task, payloads) {
      return TasksService.updateGuestTask({
        action_id: task.id
      }, payloads || task);
    };

    /**
     * @ngdoc method
     * @name validateCaptures
     * @param {*} captures 
     * @description
     * validate captures form (required only)
     */
    self.validateCaptures = function (captures) {
      return TasksService.hasRequiredCaptures(captures);
    };

    self.getShortTextValidationInfo = function (field) {
      if (field.field_type !== 'text') {
        return null;
      }
      if (field.field_validation && field.field_validation.length) {
        var shortTextValidation = _.find(FieldService.getShortTextValidations(field), { value: field.field_validation[0] });
        return shortTextValidation ? shortTextValidation.getValidationInfo(field) : null;
      } else {
        return null;
      }
    };

    self.haveTextCharsExceeded = function (captures) {
      for (var i = 0; i < captures.length; i++) {
        if ((captures[i].field_type === 'textarea' || captures[i].field_type === 'text') && captures[i].value && captures[i].value.length > COMMON.MAX_TEXT_CHAR_LIMIT) {
          return true;
        }
      }
      return false;
    };

    /**
     * @ngdoc method
     * @name getTaskArgs
     * @param {*} task 
     * 
     * @description
     * get arguments for complete request
     */
    function getTaskArgs(task) {
      var args = {};
      if (task.run_id) {
        angular.extend(args, {
          action: 'runs',
          run_id: task.run_id
        });
      }
      return args;
    }

    /**
     * @ngdoc method
     * @name completeTask
     * @param {*} task 
     * @param {*} action
     * @param {*} override_user
     * @description
     * call complete task endpoint
     */
    self.completeTask = function (task, action, override_user) {
      var args = getTaskArgs(task);
      return TasksService.completeTask(args, {
        task_id: task.id,
        override_user: override_user
      }, {
        taskType: task.task_type,
        isApproved: typeof action !== 'undefined' ? action === 'approved' : void 0
      });
    };

    /**
     * @ngdoc method
     * @name completeGuestTask
     * @param {*} task 
     * @param {*} action
     * @description
     * call complete guest task endpoint
     */
    self.completeGuestTask = function (task, action) {
      return TasksService.completeGuestTask({}, {
        task_id: task.id
      }, {
        taskType: task.task_type,
        isApproved: typeof action !== 'undefined' ? action === 'approved' : void 0
      });
    };

    /**
     * @ngdoc method
     * @name inCompleteTask
     * @param {*} task 
     * @description
     * call in-complete endpoint
     */
    self.inCompleteTask = function (task) {
      var args = getTaskArgs(task);
      args.task_id = task.id;
      return TasksService.incompleteTask(args);
    };

    /**
     * @ngdoc method
     * @name sendCommand
     * @param {*} label
     * @param {*} args 
     * @description
     * send command
     */
    self.sendCommand = function (args, label) {
      var defer = $q.defer();
      var thread = {
        content: FroalaService.getRawContent(args.content)
      };
      if (args.type === MESSAGE_TYPE.RESOLVE) {
        thread.thread_id = _.get(args.unResolvedIssues[0], 'id');
        if (!thread.thread_id && args.isGuest) {
          thread.thread_id = _.get(args, 'task.issue_id');
        }
      } else {
        thread.label = label;
      }
      MessagesService[args.type](args.task.id, thread, args.isGuest, args.state)
        .then(function (res) {
          defer.resolve(res.data);
        }, function (err) {
          defer.reject(err);
        });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name getUnResolvedIssue
     * @param {*} threads 
     * @description
     * get unresolved issue
     */
    self.getUnResolvedIssue = function (threads) {
      return TasksService.getIssueWithUnresolvedProblem(threads);
    };

    /**
     * @ngdoc method
     * @name getGuestOrganization
     * @param {*} params 
     * @description
     * get guest organization and detail info
     */
    self.getGuestOrganization = function (params) {
      return TasksService.getGuestOrganization(params);
    };

    /**
     * @ngdoc method
     * @name isTaskCompletable
     * @param {*} task 
     * @param {*} orgGroups
     * @description
     * get guest organization and detail info
     */
    self.isTaskCompletable = function (task, orgGroups) {
      return TasksService.checkIsTaskCompletable(task, orgGroups);
    };

    /**
     * @ngdoc method
     * @name saveTask
     * @param {*} params 
     * @param {*} task 
     * @description
     * save Task
     */
    self.saveTask = function (params, task) {
      var defer = $q.defer();

      var started_at = DateUtils.toUTC(moment(task.started_at)).format(DATEFORMAT.DEFAULT);
      angular.extend(task, { started_at: started_at });
      var request = task.run_id
        ? TasksService.updateTask(params, task)
        : TasksService.updateStandaloneTask(params, task);

      request.then(function (response) {
        defer.resolve({
          task: response.data
        });
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name deleteStandaloneTask
     * @param {*} args 
     * @return void
     * @description
     * delete standalone task
     */
    self.deleteStandaloneTask = function (args) {
      var defer = $q.defer();
      Growl.clearAllMessages('global');
      blockUI.start();
      TasksService.deleteStandaloneTask({
        id: args.taskId
      }).then(function () {
        growl.success($filter('translate')('tasks.general.deleteTask'), {
          referenceId: 'global',
          disableIcons: true,
          disableCloseButton: true,
          variables: {
            method: restoreStandaloneTask,
            param: {
              id: args.taskId,
              index: args.index
            },
            linkText: $filter('translate')('tasks.general.undo')
          }
        });
        $rootScope.$emit('STANDALONE_TASK:DELETE', {
          id: args.taskId,
          index: args.index
        });
        defer.resolve();
        blockUI.stop();
      }, function () {
        defer.reject();
        blockUI.stop();
      });

      return defer.promise;
    };

    self.getSingleTask = function (args) {
      var defer = $q.defer(), orgid = _.get($rootScope, 'identity.default_organization.id'), params = { org: orgid };
      if (args.run_id) {
        params.action = 'runs';
        params.action_id = args.run_id;
        delete args.run_id;
      }
      params.id = args.task_id;
      angular.extend(params, args);
      TasksService.getSingleTask(params)
        .then(function (res) {
          defer.resolve(res);
        }, function (err) {
          defer.reject(err);
        });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name restoreStandaloneTask
     * @param {*} args 
     * @return void
     * @description
     * to restore standalone task
     */
    function restoreStandaloneTask(args) {
      if (!args) {
        return;
      }
      Growl.clearAllMessages('global');
      blockUI.start();
      TasksService.restoreStandaloneTask(args)
        .then(function (response) {
          growl.success($filter('translate')('tasks.general.restoreTask'), {
            referenceId: 'global',
            disableIcons: true,
            disableCloseButton: true
          });
          $rootScope.$emit('STANDALONE_TASK:RESTORE', {
            task: response.data,
            index: args.index
          });
          blockUI.stop();
        }, function () {
          blockUI.stop();
        });
    }

    /**
     * @ngdoc method
     * @name mergeAllFormField
     * @param {*} task 
     * @param {*} summaryValue 
     * @param { Boolean } isGuestTask 
     * @param {*} isTitleValue 
     * @description merge step form fields and prerun form fields
     */
    self.mergeAllFormField = function (task, summaryValue, isGuestTask, isTitleValue) {
      var defer = $q.defer();
      if (task && !isGuestTask && summaryValue) {
        RunsService.get({
          id: task.run_id,
          with: 'tasks,tasks.step,checklist'
        }).then(function (res) {
          var tasks = _.get(res.data, 'tasks.data', []);
          var taskTitle = self.mergeField(tasks, res.data, summaryValue, isGuestTask, isTitleValue);
          defer.resolve(taskTitle);
        }, function (err) {
          defer.reject(err);
        });
      }
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name mergeField
     * @param {*} tasks 
     * @param {*} run 
     * @param {*} summaryValue 
     * @param { Boolean } isGuestTask 
     * @param {*} isTitleValue 
     * @description
     * merge KO fields and capture fields
     */
    self.mergeField = function (tasks, run, summaryValue, isGuestTask, isTitleValue) {
      allFormFields = [];
      summaryValue = summaryValue || '';
      realValue = summaryValue;
      if (tasks.length) {
        _.forEach(tasks, function (task) {
          _.forEach(_.get(task, 'form_fields.data'), function (capture) {
            if (typeof capture.value === 'undefined') {
              capture.value = task.taskdata[capture.id];
            }
            allFormFields.push(capture);
          });
        });
      } else {
        _.forEach(_.get(tasks, 'form_fields.data'), function (capture) {
          if (typeof capture.value === 'undefined') {
            capture.value = tasks.taskdata[capture.id];
          }
          allFormFields.push(capture);
        });
      }
      var preruns = _.get(run, 'checklist.data.prerun', []);
      _.forOwn(preruns, function (prerun) {
        prerun.value = run.prerun[prerun.id];
        allFormFields.push(prerun);
      });
      return getRealValueOfFields(angular.copy(allFormFields), summaryValue, isGuestTask, isTitleValue);
    };

    /**
     * @ngdoc method
     * @name getRealValueOfFields
     * @description get original value of fields
     * @private 
     * @param {Array} tasks
     * @param {*} summaryValue 
     * @param {*} isTitleValue 
     * @param { Boolean } isGuestTask 
     */
    function getRealValueOfFields(tasks, summaryValue, isGuestTask, isTitleValue) {
      var doubleBracesAlias = [],
        aliasOfFormFields;
      aliasOfFormFields = summaryValue.match(CONST.INSERT_VARIABLE_REGEX);
      _.forEach(angular.copy(aliasOfFormFields), function (value, key) {
        if (value.match(CONST.DOUBLE_CURLY_REGEX)) {
          doubleBracesAlias.push(value);
          aliasOfFormFields.splice(key, 1);
        }
      });
      assignValueToAlias(tasks, doubleBracesAlias, isTitleValue, isGuestTask);
      var hrefAttr = realValue.match(CONST.HREF_REGEX);
      _.forEach(hrefAttr, function (value) {
        if (value.match(CONST.TEXT_IN_B_TAG_REGEX)) {
          realValue = _.replace(realValue, new RegExp(value, 'g'), value.replace(CONST.B_TAG_REGEX, ""));
        }
      });
      if (isGuestTask) {
        var guestSummary = '';
        temRealValue = realValue;
        temRealValue = _.replace(temRealValue, new RegExp('&nbsp;', 'g'), ' ');
        var variableTags = temRealValue.match(CONST.INSERT_VAR_REGEX) || [];
        if (variableTags.length) {
          _.forEach(variableTags, function (tagValue, key) {
            var totalLength = temRealValue.indexOf(tagValue) + tagValue.length;
            if (!tagValue.match(CONST.TEXT_IN_B_TAG_REGEX)) {
              if (temRealValue[totalLength] === ' ') {
                guestSummary += temRealValue.slice(0, totalLength);
                var remainRealValue = temRealValue.slice(totalLength + 1, temRealValue.length);
                temRealValue = remainRealValue.trimStart();
              } else {
                guestSummary += temRealValue;
                return false;
              }
            } else {
              guestSummary += temRealValue.slice(0, totalLength);
              temRealValue = temRealValue.slice(totalLength, temRealValue.length);
              if (key === variableTags.length - 1) {
                guestSummary += temRealValue;
                return false;
              }
            }
          });
        } else {
          guestSummary = realValue;
        }
        realValue = guestSummary;
      }
      return angular.copy(realValue);
    }

    /**
     * @ngdoc method
     * @name assingValueToAlias
     * @description assign real value to alias of description
     * @private 
     * @param {Array} stepCaptures
     * @param {Array} insertedVariable
     * @param {*} isTitleValue 
     * @param { Boolean } isGuestTask 
     */
    function assignValueToAlias(stepCaptures, insertedVariable, isTitleValue, isGuestTask) {
      self.formFieldWithValues = [];
      _.forEach(_.uniq(insertedVariable), function (alias) {
        var trimAlias = alias.replace(CONST.TRIM_WHITESPACE_REGEX, '');
        var capture = _.find(stepCaptures, { alias: trimAlias.replace(CONST.ALIAS_REGEX, '') });
        if (_.get(capture, 'value')) {
          if (capture.field_type === 'multiselect' && capture.value.length > 0) {
            realValue = multiSelectFields(alias, capture);
          }
          else if (capture.field_type === 'file' && capture.value.length > 0) {
            realValue = fileFields(alias, capture, isTitleValue);
          }
          else if (capture.field_type === "dropdown") {
            realValue = dropDownFields(alias, capture);
          }
          else if (capture.field_type === "date") {
            var value = moment(FieldService.getDateInDatepickerDisplayFormat(capture.value)).format('MM-DD-YYYY');
            self.formFieldWithValues.push({ alias: alias, value: value });
            realValue = _.replace(realValue, new RegExp(alias, 'g'), '<b>' + value + '</b>');
          }
          else if (capture.field_type === "radio") {
            var value = (capture.value.text || capture.value);
            self.formFieldWithValues.push({ alias: alias, value: value });
            realValue = _.replace(realValue, new RegExp(alias, 'g'), '<b>' + value + '</b>');
          }
          else if (capture.field_type === "text" || capture.field_type === "textarea") {
            var value = $filter('linkify')(capture.value, '_blank');
            self.formFieldWithValues.push({ alias: alias, value: value });
            realValue = _.replace(realValue, new RegExp(alias, 'g'), value);
          } else {
            setDefaultValue(alias, isGuestTask);
          }
        } else {
          setDefaultValue(alias, isGuestTask);
        }
      });
    }

    /**
     * @ngdoc method
     * @name multiSelectFields
     * @description get selected option of checklist and set it separated by a comma.
     * @private 
     * @param {Array} alias
     * @param {Array} capture
     * @returns {String} 
     */
    function multiSelectFields(alias, capture) {
      var multiSelectValues = [];
      _.forEach(capture.value, function (options) {
        multiSelectValues.push(options.text);
      });
      var value = multiSelectValues.toString();
      value = value.replace(CONST.COMA_REGEX, ", ");
      self.formFieldWithValues.push({ alias: alias, value: value });
      return _.replace(realValue, new RegExp(alias, 'g'), '<b>' + value + '</b>');
    }

    /**
     * @ngdoc method
     * @name dropDownFields
     * @description get selected option of dropdown.
     * @private 
     * @param {Array} alias
     * @param {Array} capture
     * @returns {String} 
     */
    function dropDownFields(alias, capture) {
      var value = capture.value.text;
      self.formFieldWithValues.push({ alias: alias, value: value });
      return _.replace(realValue, new RegExp(alias, 'g'), '<b>' + value + '</b>');
    }

    /**
     * @ngdoc method
     * @name fileFields
     * @description get uploaded file, if it is image then show filename with image 
     * otherwise only show filename and filename is downloadable
     * @private 
     * @param {Array} alias
     * @param {Array} capture
     * @param {*} isTitleValue 
     * @returns {String} 
     */
    function fileFields(alias, capture, isTitleValue) {
      var showFile = '';
      _.forEach(capture.value, function (file) {
        if (self.isImage(file.filename) && isTitleValue === 'summary') {
          showFile += '<img class="m-b-xs" src="' + self.getFileLocation(file, $rootScope.identity.default_organization) + '" alt="">';
        } else {
          showFile += '<b><a class="t-mossy-green"" href="' + self.getFileLocation(file, $rootScope.identity.default_organization) + '" target="_blank">' + file.filename + '</a></b><span class="m-l-sm"></span>';
        }
      });
      var value = showFile;
      self.formFieldWithValues.push({ alias: alias, value: value });
      return _.replace(realValue, new RegExp(alias, 'g'), value);
    }

    /**
     * @ngdoc method
     * @name setDefaultValue
     * @description set default value
     * @private 
     * @param {String} alias
     * @param { Boolean } isGuestTask 
     */
    function setDefaultValue(alias, isGuestTask) {
      if (isGuestTask) {
        realValue = _.replace(realValue, new RegExp(alias, 'g'), '');
      } else {
        realValue = _.replace(realValue, new RegExp(alias, 'g'), '<b>' + $filter('translate')('tasks.general.noValue') + '</b>');
      }
    }

    /**
     * @ngdoc method
     * @name isTaskToBeUpdatedOnCaptureUpdate
     * @description Check whether task is to be updated on capture update
     * @private
     * @param currentTask
     * @param updatedTask
     * @param updatedCapture
     * @param isGuestView
     */
    self.isTaskToBeUpdatedOnCaptureUpdate = function (currentTask, updatedTask, updatedCapture, isGuestView) {
      var title = _.get(currentTask, 'step.data.title', '') || '', summary = _.get(currentTask, 'step.data.summary', '') || '';
      if (isGuestView) {
        return ((currentTask.id !== updatedTask.id && _.get(currentTask, 'run.data.id')) && (_.get(currentTask, 'run.data.id') === updatedTask.run.data.id) && ((title.match(CONST.DOUBLE_CURLY_REGEX) && (title.indexOf('{{' + updatedCapture.alias + '}}') > -1)) || (summary.match(CONST.DOUBLE_CURLY_REGEX) && (summary.indexOf('{{' + updatedCapture.alias + '}}') > -1))));
      }
      return ((currentTask.id !== updatedTask.id && _.get(currentTask, 'run_id')) && (_.get(currentTask, 'run_id') === updatedTask.run_id) && ((title.match(CONST.DOUBLE_CURLY_REGEX) && (title.indexOf('{{' + updatedCapture.alias + '}}') > -1)) || (summary.match(CONST.DOUBLE_CURLY_REGEX) && (summary.indexOf('{{' + updatedCapture.alias + '}}') > -1))));
    };

    /**
     * @ngdoc method
     * @name isTaskToBeUpdatedOnDeadlineUpdate
     * @description Check whether task is to be updated on deadline update
     * @private
     * @param currentTask
     * @param eventData
     */
    self.isTaskToBeUpdatedOnDeadlineUpdate = function (currentTask, eventData) {
      return ((eventData.reFetchUpdatedTask && currentTask.id === eventData.updatedTask.id) || (_.get(currentTask, 'run_id') && (eventData.update_dependent_deadlines && _.includes(_.get(eventData, 'updatedTask.tasks_changed_by_deadlines', []), currentTask.id))));
    };

    self.linkOOTTask = function (taskId, processId, position) {
      return TasksService.linkOOTTask(taskId, processId, position);
    };

    self.unlinkOOTTask = function (taskId) {
      var defer = $q.defer();
      TasksService.unlinkOOTTask(taskId).then(function (response) {
        $timeout(function () {
          growl.success($filter('translate')('global.growlMessages.unlinkOOTTaskSuccess'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
        }, 200);
        defer.resolve({ task: response.data });
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };

    self.taskComplete = function (task, action, isGuest, reOpenNextTask, override_user) {
      var defer = $q.defer();
      $timeout(function () {
        if (self.isCaptureUpdateInProgress || self.isCaptureUpdateInQueue) {
          $timeout(function () {
            self.taskComplete(task, action, isGuest, reOpenNextTask);
          }, 500);
        } else {
          var resource = isGuest
            ? self.completeGuestTask(task, action)
            : self.completeTask(task, action, override_user);
          resource.then(function (response) {
            if (task.has_deadline_dependent_child_tasks) {
              $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { updatedTask: response.data, reFetchUpdatedTask: false, update_dependent_deadlines: true, fetchWithParams: false });
            }
            $rootScope.$emit('COMPACT_TASK:COMPLETED', {
              task: response.data,
              isRejectedTask: action === 'reject',
              reOpenNextTask: reOpenNextTask,
              autoLaunchProcess: _.get(task.step, 'data.bp_to_launch')
            });
            task.everyone_must_complete ? angular.extend(task.completion_progress, response.data.completion_progress) : angular.noop();
            defer.resolve({ task: response.data });
          }, function (err) {
            defer.reject(err);
          });
        }
      }, 100);
      return defer.promise;
    };

    self.taskReject = function (task) {
      var defer = $q.defer();
      $uibModal.open({
        backdrop: 'static',
        windowClass: "commentdialogbox",
        component: 'commentdialog',
        resolve: {
          headerTitle: function () {
            return 'tasks.messages.rejectedDialogbox.dialogBoxTitle';
          },
          titleDetails: function () {
            return 'tasks.messages.rejectedDialogbox.dialogBoxMessage';
          },
          btnAccepts: function () {
            return 'tasks.messages.rejectedDialogbox.btn.save';
          }
        }
      }).result.then(function (comment) {
        self.taskComplete(task, 'reject', $rootScope.userState === USER_STATE.GUEST).then(function (response) {
          if (comment) {
            $timeout(function () {
              growl.success($filter('translate')('compact.components.comment.taskRejectMessage'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 200);
            postRejectCommentSend(task, comment);
          } else {
            $timeout(function () {
              growl.success($filter('translate')('compact.components.emptyComment.taskRejectMessage'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 200);
          }
          task.everyone_must_complete ? angular.extend(task.completion_progress, response.data.completion_progress) : angular.noop();
          if (task.has_deadline_dependent_child_tasks) {
            $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { updatedTask: response.data, reFetchUpdatedTask: false, update_dependent_deadlines: true, fetchWithParams: false });
          }
          $rootScope.$emit('COMPACT_TASK:COMPLETED', { task: response.data });
          defer.resolve({ task: task });
        }, function (err) {
          defer.reject(err);
        });
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };

    self.taskReopen = function (task) {
      var defer = $q.defer();
      $uibModal.open({
        backdrop: 'static',
        windowClass: "reopendialogbox",
        component: 'reopendialog'
      }).result.then(function (comment) {
        self.inCompleteTask(task).then(function (response) {
          if (task.status === 'completed' && task.task_type === 'approval' && !task.is_approved && !comment) {
            comment = $filter('translate')('compact.components.comment.pendingApproval');
          }
          if (comment) {
            $timeout(function () {
              growl.success($filter('translate')('compact.components.comment.taskReopenSuccess'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 200);
            postCommentSend(task, comment);
          } else {
            $timeout(function () {
              growl.success($filter('translate')('compact.components.emptyComment.taskReopenSuccess'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 200);
          }
          task.everyone_must_complete ? angular.extend(task.completion_progress, response.data.completion_progress) : angular.noop();
          if (task.has_deadline_dependent_child_tasks) {
            $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { updatedTask: response.data, reFetchUpdatedTask: false, update_dependent_deadlines: true, fetchWithParams: false });
          }
          $rootScope.$emit('COMPACT_TASK:COMPLETED', { task: response.data });
          defer.resolve({ task: task });
        }, function (err) {
          defer.reject(err);
        });
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };
    
    self.taskComment = function (task) {
      var defer = $q.defer();
      $uibModal.open({
        backdrop: 'static',
        windowClass: "commentdialogbox",
        component: 'commentdialog',
        resolve: {
          headerTitle: function () {
            return 'tasks.messages.comment.dialogBoxTitle';
          },
          titleDetails: function () {
            return 'tasks.messages.comment.dialogBoxMessage';
          },
          btnAccepts: function () {
            return 'messages.button.submitFeedback';
          }
        }
      }).result.then(function (commentMessage) {
        self.sendCommand({
          content: commentMessage,
          task: task,
          type: MESSAGE_TYPE.SEND_COMMENT
        }).then(function (res) {
          if (_.get(task, 'threads.data')) {
            task.threads.data.push(res);
          }
          defer.resolve({ task: task });
        });
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };

    self.koTaskReOpen = function (process) {
      return TasksService.incompleteKoTask({
        action: 'runs',
        run_id: process.id
      });
    };

    self.koTaskComplete = function (process) {
      return TasksService.completeKoTask({
        action: 'runs',
        run_id: process.id
      });
    };

    self.reOrderTask = function (params, body) {
      return TasksService.reOrderTask(params, body);
    };

    function postCommentSend(task, comment) {
      var unResolvedIssues = self.getUnResolvedIssue(task.threads);
      var reopenComment = MESSAGE_TYPE.REOPEN_COMMENT + comment;
      self.sendCommand({
        content: reopenComment,
        task: task,
        type: MESSAGE_TYPE.SEND_COMMENT,
        unResolvedIssues: unResolvedIssues
      }).then(function (res) {
        $rootScope.$broadcast('REOPEN_COMMENT:UPDATE', res);
      });
    }
    
    function postRejectCommentSend(task, comment) {
      var unResolvedIssues = self.getUnResolvedIssue(task.threads),
       rejectComment = $filter('translate')('tasks.messages.approvalRejected') + comment,
       args = {
        content: rejectComment,
        task: task,
        type: MESSAGE_TYPE.SEND_COMMENT,
        label: "rejected-comment",
        unResolvedIssues: unResolvedIssues
      };
      if ($rootScope.userState === USER_STATE.GUEST) {
        angular.extend(args, { isGuest: true });
      }
      self.sendCommand(args).then(function (res) {
        $rootScope.$broadcast('REOPEN_COMMENT:UPDATE', res);
      });
    }

    /**
     * @ngdoc method
     * @name setEnquireCommentMessage
     * @description Set Enquire Message
     * @param {Object} task
     * @private
     */
    self.setEnquireCommentMessage = function (task) {
      var assignUsers = '';
      _.each(task.owners.users, function (userId) {
        assignUsers = assignUsers + '@[' + userId + '] ';
      });
      return assignUsers + $filter('translate')('global.task.messages.enquireMessage');
    };

    /**
     * @ngdoc method
     * @name postEnquireCommentSend
     * @description Send Comment About Task Enquire
     * @private
     * @param args
     */
    self.postEnquireCommentSend = function (args) {
      var defer = $q.defer();
      var commentMessage = self.setEnquireCommentMessage(args.task);
      var unResolvedIssues = self.getUnResolvedIssue(args.task.threads);
      self.sendCommand({
        content: commentMessage,
        task: args.task,
        type: MESSAGE_TYPE.SEND_COMMENT,
        unResolvedIssues: unResolvedIssues
      }).then(function (res) {
        args.task.threads.data.push(res);
        defer.resolve();
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name duplicateTask
     * @description Duplicate a task
     * @private
     * @param payload
     */
    self.duplicateTask = function (payload) {
      return TasksService.duplicateTask(payload);
    };

    self.getKoDateFieldInUtc = function (koDateFieldValue) {
      var timezone = $rootScope.identity && $rootScope.identity.timezone ? $rootScope.identity.timezone : null, timeoffset = timezone ? moment().tz(timezone).utcOffset() * -1 : 0;
      return DateUtils.toUTC(moment(koDateFieldValue).add(timeoffset, 'minutes')).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
  }
})();

(function () {
  'use strict';
  angular
    .module('tallyfy.public')
    .factory('PublicTagRepository', publicTagRepository)

  /*@ngInject*/
  function publicTagRepository(BaseRepository) {
    var repository = BaseRepository('/utils/public-tags/:action', {
      action: '@action'
    }, {
      $all: { method: 'GET' },
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' }
    });
    return repository;
  }
})();
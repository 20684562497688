(function () {
  'use strict';
  angular.module('tallyfy')
    .component('singleTaskModal', {
      templateUrl: 'app/modules/dashboard/content-card-preview/task-list-preview/single-task-modal/single-task-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $timeout, DOMService, USER_STATE) {
          var $ctrl = this, taskDetailsClosedEventHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onTaskSaved = onTaskSaved;
          $ctrl.onDiscardTask = onDiscardTask;

          $ctrl.metadata = {};

          function onInit() {
            $ctrl.isGuest = $rootScope.userState === USER_STATE.GUEST;
            $timeout(function () {
              $ctrl.orgAllUsers = $ctrl.resolve.orgAllUsers;
              $ctrl.process = $ctrl.resolve.process;
              $ctrl.selectedTask = $ctrl.resolve.selectedTask;
              $ctrl.usersInOrg = $ctrl.resolve.usersInOrg;
              $ctrl.selectedTaskIndex = $ctrl.resolve.selectedTaskIndex;
              $ctrl.orgGroups = $ctrl.resolve.orgGroups;
              $ctrl.saveSelectedTask = $ctrl.resolve.saveSelectedTask;
              $ctrl.discardSelectedTask = $ctrl.resolve.discardSelectedTask;
              $ctrl.allowGuestsAssigned = $ctrl.resolve.allowGuestsAssigned;
              $ctrl.args = $ctrl.resolve.args;
              $ctrl.metadata = $ctrl.resolve.metadata;
            }, 350).then(function () {
              if ($ctrl.resolve.args && $ctrl.resolve.args.focusField) {
                $timeout(function () {
                  var koFormField = _.find(_.get($ctrl.process, 'ko_form_fields.data', []), { alias: $ctrl.resolve.args.focusField.alias });
                  if (koFormField) {
                    DOMService.centerObjectToView('[data-field-alias=\'' + koFormField.alias + '\']');
                  }
                }, 1000);
              }
            });
          }

          function onChanges() { }

          function onDestroy() {
            taskDetailsClosedEventHandler();
          }

          function onTaskSaved(args) {
            return $ctrl.saveSelectedTask({
              args: args
            });
          }

          function onDiscardTask(args) {
            return $ctrl.discardSelectedTask({
              args: args
            });
          }

          taskDetailsClosedEventHandler = $rootScope.$on('RIGHT_PANE:CLOSE', function (e, data) {
            $ctrl.close({ $value: $ctrl.selectedTask });
          });

        }
    });
})();
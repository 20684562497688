/**
 * @ngdoc Component
 * @name tallyfy.process.component.bluePrintExampleModal
 * @module tallyfy.process
 *
 * @description
 * A component to open blue print example modal
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('bluePrintExampleModal', {
      templateUrl: 'app/modules/process/components/blueprint-create/blueprint-example-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (BLUEPRINT_TYPE) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancelModal = cancelModal;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.blueprintType = $ctrl.resolve.keyType;
            $ctrl.isSnippetBP = $ctrl.blueprintType === BLUEPRINT_TYPE.SNIPPET;
            $ctrl.isProcedureBP = $ctrl.blueprintType === BLUEPRINT_TYPE.PROCEDURE;
            $ctrl.isFormBP = $ctrl.blueprintType === BLUEPRINT_TYPE.FORM;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }
        }
    });
})();
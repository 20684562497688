/**
 * @ngdoc Component
 * @name tallyfy.account.component.updateAccount
 * @module tallyfy.account
 *
 * @description
 * A component to update the account of logged in user
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('updateAccount', {
      templateUrl: 'app/modules/account/updateAccount/update-account.html',
      controller:
        /*@ngInject*/
        function ($rootScope, AccountService, Growl, blockUI, UsersService, TranslationService, $timeout, $stateParams, $log, _, $uibModal, TimeZone, TasksService, $confirm, $filter, $state, $q, USER_STATE, ConfirmOnExitService, CONST, TFY_EVENTS, preferencesStore, Helper, OrganizationsService, VALIDATION) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('accountInfo'),
            updateUserProfileEventHandler,
            updateUserFullNameEventHandler,
            firstName,
            lastName,
            stateChangeStart;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.onFileChange = onFileChange;
          $ctrl.resetForm = resetForm;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.removeAvatar = removeAvatar;
          $ctrl.getSaveText = getSaveText;
          $ctrl.save = save;
          $ctrl.prepareRoleOption = prepareRoleOption;

          /**
           * public properties
           */
          $ctrl.account = {};
          $ctrl.sourceImage = null;
          $ctrl.accountForm = {};
          $ctrl.isAccountSaved = false;
          $ctrl.emailPattern = CONST.EMAIL_REGEX;
          $ctrl.phoneNumberPattern = CONST.PHONE_NUMBER_REGEX;
          $ctrl.supportedAppLanguages = TranslationService.getAppLanguages();
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;
          $ctrl.isCountryDropdownOpen = false;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            getCurrentAccount();
            $ctrl.play_tasks_sound = AccountService.getPreference($rootScope.identity.preferences, 'play_tasks_sound', 'yes');
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.authAllowsForChangePassword = checkAuthMethod();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateUserProfileEventHandler();
            updateUserFullNameEventHandler();
            stateChangeStart();
          }

          /**
           * @ngdoc method
           * @name getCurrentAccount
           * @private
           * @description Get current user account records
           */
          function getCurrentAccount() {
            blockUI.start();
            AccountService.getAccount({ with: 'country,role' }).then(function (response) {
              $ctrl.account = AccountService.getGeneralInfo(_.get(response, 'data'));
              firstName = $ctrl.account.first_name;
              lastName = $ctrl.account.last_name;
              $ctrl.userProfilePic = _.get(response, 'data.profile_pic');
              $ctrl.defaultValue = angular.copy($ctrl.account);
              $ctrl.country = getCountry();
              if ($ctrl.account.phone) {
                $ctrl.account.phone = $ctrl.account.phone.replace(/\D/g, '');
                var countryCode = _.get($ctrl.country, 'phone_code');
                if (countryCode) {
                  countryCode = countryCode.replace(/\D/g, '');
                }
                if (_.startsWith($ctrl.account.phone, countryCode)) {
                  $ctrl.account.phone = $ctrl.account.phone.replace(countryCode, '');
                }
              }
              prepareOrgRoles();
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name prepareOrgRoles
           * @description Get org roles and set current user role
           */
          function prepareOrgRoles() {
            $ctrl.userRoles = UsersService.getUserRoles();
            $ctrl.selectedOption = _.find($ctrl.userRoles, function (value) {
              return value.type === $ctrl.account.role;
            });
          }

          /**
           * @ngdoc method
           * @name prepareRoleOption
           * @description set/change role
           * @param {*} option
           */
          function prepareRoleOption(option) {
            if (option.id !== $ctrl.selectedOption.id) {
              $ctrl.selectedOption = angular.copy(option);
              $ctrl.accountForm.$setDirty();
              $ctrl.account.role = $ctrl.selectedOption.type;
            }
          }

          /**
           * @ngdoc method
           * @name removeAvatar
           * @private
           * @description Remove saved profile picture
           */
          function removeAvatar() {
            $confirm({
              'header': 'myAccount.removeAvatar.header',
              'body': '',
              'buttons': {
                'accept': 'myAccount.removeAvatar.buttons.accept',
                'cancel': 'myAccount.removeAvatar.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              $ctrl.userProfilePic = null;
              AccountService.removeAvatar().then(function () {
                AccountService.getAccount({ with: 'country,role' }).then(function (account) {
                  $ctrl.account = AccountService.getGeneralInfo(account.data);
                  $ctrl.userProfilePic = account.data.profile_pic;
                  $ctrl.defaultValue = angular.copy($ctrl.account);
                  $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, account.data);
                  $ctrl.country = getCountry();
                  $rootScope.$emit("USER:UPDATE_PROFILE", $ctrl.userProfilePic);
                }, function (error) {
                  $log.error(error);
                });
              }, function (error) {
                $log.error(error);
              });
              resetForm();
            });
          }

          /**
           * @ngdoc method
           * @private
           * @name getPhoneNumber
           * 
           * @description
           * get user phone number with dial code
           */
          function getPhoneNumber() {
            var userNumber = $ctrl.account.phone.replace(/\D/g, '');
            var dialCode = $ctrl.country.phone_code.replace(/\D/g, '');
            if (!_.startsWith(userNumber, dialCode)) {
              userNumber = dialCode + userNumber;
            }
            return userNumber;
          }

          /**
           * @ngdoc method
           * @name save
           * @private
           * @description Save user account records
           */
          function save() {
            var deferred = $q.defer();
            if ($ctrl.accountForm.$invalid) {
              return;
            }
            blockUI.start();
            var request = AccountService.getGeneralInfo($ctrl.account);
            if (request.phone) {
              request.phone = getPhoneNumber();
            }
            request = _.omit(request, ['country']);
            request.country_id = $ctrl.country.id;
            request.timezone = _.get($ctrl.account, 'timezone.value') || $ctrl.account.timezone;
            AccountService.updateAccount($stateParams.id, request).then(function (response) {
              blockUI.stop();
              getCurrentAccount();
              $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, response.data);
              $rootScope.identity.first_name = _.get(response.data, 'first_name');
              $rootScope.identity.last_name = _.get(response.data, 'last_name');
              $rootScope.identity.date_format = _.get(response.data, 'date_format');
              $rootScope.$emit("USER:UPDATEUSERFULLNAME", response.data.full_name);
              deferred.resolve({ 'status': 'Saved' });
              $ctrl.isAccountSaved = true;
              $ctrl.defaultValue = angular.copy(response.data);
              if ($ctrl.defaultValue.phone) {
                $ctrl.defaultValue.phone = $ctrl.defaultValue.phone.replace(/\D/g, '');
              }
              var countryCode = _.get($ctrl.country, 'phone_code');
              if (countryCode) {
                countryCode = countryCode.replace(/\D/g, '');
              }
              if (_.startsWith($ctrl.defaultValue.phone, countryCode)) {
                $ctrl.defaultValue.phone = $ctrl.defaultValue.phone.replace(countryCode, '');
              }
              resetForm();
            }).catch(function (error) {
              var erorrMessages = _.get(error, 'data.errors');
              if (erorrMessages) {
                angular.forEach(erorrMessages, function (value, key) {
                  $ctrl.accountForm[key].$invalid = true;
                  $ctrl.accountForm.email.$touched = true;
                  $ctrl.accountForm[key].$error[key] = value;
                });
              }
              blockUI.stop();
              deferred.reject({ 'status': 'NotSaved' });
              $ctrl.isAccountSaved = false;
            });
            return deferred.promise;
          }

          /**
           * @ngdoc method
           * @name onFileChange
           * @description Update user profile
           */
          function onFileChange($files) {
            if (!Helper.checkAccessAuthority())
              return;
            if (_.isArray($files) && $files.length === 0) {
              return;
            }

            $ctrl.picFile = _.head($files);
            $uibModal.open({
              backdrop: 'static',
              component: 'cropImage',
              resolve: {
                file: function () {
                  return $ctrl.picFile;
                },
                sourceImage: function () {
                  return $ctrl.sourceImage;
                },
                cropShape: function () {
                  return 'circle';
                }
              }
            }).result.then(function (cropedImage) {
              if (_.isObject(cropedImage)) {
                $ctrl.userProfilePic = null;
                UsersService.updateUserPicture(cropedImage).then(function (response) {
                  var data = _.get(response, 'data.data');
                  $ctrl.userProfilePic = data.profile_pic;
                  $rootScope.$emit("USER:UPDATE_PROFILE", $ctrl.userProfilePic);
                  $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, {
                    id: data.user_id,
                    profile_pic: $ctrl.userProfilePic
                  });
                }, function () {
                  $ctrl.sourceImage = null;
                });
              }
              resetForm();
            }, function () {
              $ctrl.sourceImage = null;
              resetForm();
            });
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @private
           * @description Reset form
           * @returns {Void}
           */
          function resetForm() {
            $ctrl.account = angular.copy($ctrl.defaultValue);
            $ctrl.selectedOption = _.find($ctrl.userRoles, function (value) {
              return value.type === $ctrl.account.role;
            });
            $ctrl.accountForm.$setPristine();
            $ctrl.accountForm.$setUntouched();
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @return {string} first character of name
           */
          function defaultAvatarText() {
            return TasksService.setDefaultAvatarText(firstName, lastName);
          }

          /**
           * @ngdoc method
           * @name getCountry
           * @description set country of the user
           */
          function getCountry() {
            return _.get($ctrl, 'account.country.data') || $ctrl.country;
          }

          /**
           * @ngdoc method
           * @name checkAuthMethod
           * @description check signup(Auth) method
           */
          function checkAuthMethod() {
            return !_.includes(['saml', 'google', 'live', 'microsoft'], _.get($rootScope.identity, 'initial_signup_method'));
          }

          /**
           * event handler when user profile has been changed
           * @type {*|(function())}
           */
          updateUserProfileEventHandler = $rootScope.$on('USER:UPDATE_PROFILE', function (event, profilePic) {
            $rootScope.identity.profile_pic = profilePic;
            $rootScope.identity.resize_profile_pic = profilePic;
          });

          /**
           * event handler when user full name has been changed
           * @type {*|(function())}
           */
          updateUserFullNameEventHandler = $rootScope.$on('USER:UPDATEUSERFULLNAME', function (event, fullName) {
            $rootScope.identity.full_name = fullName;
          });

          /**
           * @function
           * @name getSaveText
           * @description get the text of save button
           * @public
           * @returns {string}
           */
          function getSaveText() {
            if ($ctrl.isAccountSaved && $ctrl.accountForm.$pristine) {
              return $filter('translate')('myAccount.form.buttons.update_profile.savedLabel');
            }
            return $filter('translate')('myAccount.form.buttons.update_profile.label');
          }

          /**
            * Event handler when user navigates to anither tab/state
            * @type {*|(function())}
            */
          stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (angular.isDefined($ctrl.accountForm) && !$ctrl.accountForm.$pristine) {
              event.preventDefault();
              ConfirmOnExitService.checkStatus($ctrl.accountForm).then(function (data) {
                if (data.status === 'YES') {
                  $ctrl.accountForm.$submitted = true;
                  save().then(function (data) {
                    if (data.status === 'Saved') {
                      $state.go(toState);
                      resetForm();
                    }
                  });
                  return;
                }
                resetForm();
                $state.go(toState);
              });
            }
          });
        }
    });
})();

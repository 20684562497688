(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tyKendoGridTableCellView', {
      bindings: {
        fieldColumns: '<',
        fieldValues: '<'
      },
      templateUrl: 'app/components/kendo/tallyfy-grid-table/field-view/table/ty-kendo-grid-table-cell-view.component.html',
      controller:
        /*@ngInject*/
        function (_, $q, $timeout, Helper, KendoUIService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getData = getData;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getData() {
            var defer = $q.defer();
            $timeout(function () {
              if ($ctrl.fieldValues) {
                var data = $ctrl.fieldValues.length ? getTableData() : [];
                defer.resolve(data);
              } else {
                defer.resolve([]);
              }
            }, 0);
            return defer.promise;
          }

          function getTableData() {
            var result = [], columns = $ctrl.fieldColumns || [];
            for (var i = 0; i < columns.length; i++) {
              var columnValue = _.get($ctrl.fieldValues, '[' + i + ']', []);
              for (var j = 0; j < (columnValue || []).length; j++) {
                if (!result[j]) {
                  result[j] = {};
                }
                result[j][columns[i]._guid] = columnValue[j];
              }
            }
            return result;
          }

          function getTableConfig() {
            var columnDefs = [];
            for (var i = 0; i < $ctrl.fieldColumns.length; i++) {
              var guid = Helper.getRandomString(8);
              $ctrl.fieldColumns[i]._guid = guid;
              columnDefs.push({
                field: guid,
                exportKeyMap: {
                  key: guid,
                  label: $ctrl.fieldColumns[i].label
                },
                headerTemplate: kendo.template(KendoUIService.getTooltippedHeaderLabel($ctrl.fieldColumns[i])),
                title: $ctrl.fieldColumns[i].label
              });
            }
            return {
              beautyScrollStyle: true,
              readOnly: true,
              tableState: {
                sort: {},
                columns: {}
              },
              gridConfig: {
                columns: columnDefs,
                dataSource: new kendo.data.DataSource({
                  data: [],
                  transport: {
                    read: function (o) {
                      o.success([]);
                    },
                    create: function (o) {
                      o.data.guid = Helper.guid();
                      o.success(o.data);
                    },
                    update: function (o) {
                      o.success();
                    },
                    destroy: function (o) {
                      o.success();
                    }
                  },
                }),
              },
              templateScope: {
                callbacks: {},
                variables: {}
              }
            }
          }
        }
    });
})();
/**
 * @ngdoc Filter
 * @name tallyfyTimeAgo
 * @module tallyfy
 * @description
 * Convert the date to day. This is to fix the amAgo issues (which uses local moment to figure out moments ago etc.)
 * @author Kiran Sompura ( gmail:: kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('tallyfyTimeAgo', ['moment', '$filter', function (moment, $filter) {
      return function (value) {
        var currentDateTime = moment(), selectedDateTime = moment(value),
          isDueDate = currentDateTime.format() > selectedDateTime.format(),
          day = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asDays()));
        if (day > -1) {
          if (day === 0){
            var hours = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asHours()));
            if (hours > 1) {
              return isDueDate ? hours + " " + $filter('translate')('global.deadline.hoursAgo')
               : $filter('translate')('global.deadline.in') + " " + hours + " " + $filter('translate')('global.deadline.hours');
            } else {
              var minutes = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asMinutes()));
              if (minutes >= 60) {
                var hourTranslate = (hours === 1 ? 'global.deadline.hour' : 'global.deadline.hours');
                return isDueDate ? hours + " " + $filter('translate')('global.deadline.hoursAgo')
                 : $filter('translate')('global.deadline.in') + " " + hours + " " + $filter('translate')(hourTranslate)
                  ;
              } else if (minutes > 0) {
                var minuteTranslate = (minutes === 1 ? 'global.deadline.minute' : 'global.deadline.minutes');
                return isDueDate ? minutes + " " + $filter('translate')('global.deadline.minutesAgo')
                 : $filter('translate')('global.deadline.in') + " " + minutes + " " + $filter('translate')(minuteTranslate);
              } else {
                return $filter('amTimeAgo')(selectedDateTime);
              }
            }
          } else if (day > 0 && day < 30) {
            var hours = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asHours()));
            if (hours > 1 && hours < 24) {
              return isDueDate ? hours + " " + $filter('translate')('global.deadline.hoursAgo')
               : $filter('translate')('global.deadline.in') + " " + hours + " " + $filter('translate')('global.deadline.hours');
            } else {
              var dayTranslate = (day === 1 ? 'global.deadline.day': 'global.deadline.days');
              return isDueDate ? day + " " + $filter('translate')('global.deadline.daysAgo')
               : $filter('translate')('global.deadline.in') + " " + day + " " + $filter('translate')(dayTranslate);
            }
          } else {
            var weeks = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asWeeks())),
              months = Math.round(Math.abs(moment.duration(selectedDateTime.diff(currentDateTime)).asMonths()));
            if (weeks < 8) {
              return isDueDate ? weeks + " " + $filter('translate')('global.deadline.weeksAgo')
                : $filter('translate')('global.deadline.in') + " " + weeks + " " + $filter('translate')('global.deadline.weeks');
            } else if (weeks >= 8 && months < 12) {
              return isDueDate ? months + " " + $filter('translate')('global.deadline.monthsAgo')
                : $filter('translate')('global.deadline.in') + " " + months + " " + $filter('translate')('global.deadline.months');
            } else {
              return $filter('amTimeAgo')(selectedDateTime);
            }
          }
        } else {
          return $filter('amTimeAgo')(selectedDateTime);
        }
      };
    }]);
})();
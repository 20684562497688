/**
 * @ngdoc Component
 * @name tallyfy.account.component.forgotPassword
 * @module tallyfy.account
 * @description
 * A component to forgotPassword
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('forgotPassword', {
      templateUrl: 'app/modules/account/forgotPassword/forgot-password.html',
      controller:
        /*@ngInject*/
        function ($analytics) {
          var $ctrl = this;
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            trackEvent('Viewed forgotPassword');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @name trackEvent
           * @param {*} message 
           * 
           * @description
           * method to handling analytics event
           */
          function trackEvent(message) {
            $analytics.eventTrack(message);
          }

        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.taskMeta
 * @module tallyfy.tasks
 *
 * @description
 * taskMeta component
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('taskMeta', {
      templateUrl: 'app/modules/tasks/taskMeta/task-meta.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
        run: '<?',
        task: '<?',
        users: '<?',
        isStandalone: "<",
        entity: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, TasksService, OrganizationsService) {
          var $ctrl = this;
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * public methods
           */
          $ctrl.cancelModal = cancelModal;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;

          /**
           * public properties
           */
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.orgID = _.get($rootScope.identity, 'default_organization.id');
            if ($ctrl.resolve) {
              $ctrl.task = $ctrl.resolve.task;
              if ($ctrl.isStandalone) {
                $ctrl.run = $ctrl.resolve.run;
              }
              $ctrl.users = $ctrl.resolve.availableUsers;
            }
            if (_.get($ctrl.task, 'run.data') && _.toLower($ctrl.entity) === "users") {
              $ctrl.run = $ctrl.task.run.data;
            }
            prepareOwner();
            $ctrl.startedBy = $ctrl.isStandalone ? getStartedBy($ctrl.task.starter_id) : getStartedBy($ctrl.run.started_by);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getStartedBy
           * @param ownerId
           * @description return start by owner data.
           * @returns {Object} user
           */
          function getStartedBy(ownerId) {
            return _.find($ctrl.users, ['id', ownerId]);
          }

          /**
           * @ngdoc method
           * @name prepareOwner
           * @description Prepare owner data
           */
          function prepareOwner() {
            $ctrl.taskOwners = TasksService.getTaskOwners(_.get($ctrl.task, 'owners.users'), $ctrl.users, $ctrl.isStandalone, _.get($ctrl.run, 'collaborators') || [], $ctrl.task.status);
            $ctrl.ownersName = _.unionWith(_.map($ctrl.taskOwners, 'text'), $ctrl.task.owners.guests).join(", ");
            $ctrl.allOwner = _.concat($ctrl.taskOwners, $ctrl.task.owners.guests);
            $ctrl.ownersGuests = $ctrl.task.owners.guests;
          }

          /**
           * @ngdoc method
           * @name cancelModal
           * @description to close modal
           * 
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }

          /**
            * @ngdoc method
            * @name defaultAvatar 
            * @public
            * @description set default avatar
            * @param {string} avatar 
            * @return {boolean}
            */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }
          //end of controller
        }
    });
})();

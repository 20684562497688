/**
 * @ngdoc Directive
 * @name tallyfy.timeAgo
 * @restrict 'E'
 * @element ANY 
 * @author kiran kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 **/

(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("timeAgo", timeAgo);
    /*@ngInject*/
    function timeAgo(moment) {
      return {
        restrict: "E",
        scope: {
          time: '<'
        },
        template:'<span>{{ timeAgo }}</span>',
        link: function (scope, element, attributes) {
          var deRegisterTimeWatcher = scope.$watch('time', function(value) {
            if (value) {
              scope.timeAgo = moment(scope.time).fromNow().replace('in', '');
            }
          });

          scope.$on('$destroy', function () {
            deRegisterTimeWatcher();
          });
        }
      };
    }
})();

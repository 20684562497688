(function () {
  'use strict';
  angular.module('tallyfy')
    .component('dashboardHeader', {
      templateUrl: 'app/modules/dashboard/dashboard-header/dashboard-header.component.html',
      bindings: {
        taskCount: '=',
        orgUsersCount: '='
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, OrganizationsService, AccountService, NotificationsService, preferencesStore, $log, TasksService, UsersService, CONFIG, AuthPlan, PLANS, USER_STATE, Helper) {
          var $ctrl = this;

          $ctrl.unreadNotifications = NotificationsService.getUnreadNotifications;
          $ctrl.isDashboardSplashDismissed = true;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onDashboardSplashDismissed = onDashboardSplashDismissed;
          $ctrl.goToAllNotifications = goToAllNotifications;
          $ctrl.viewAllTasks = viewAllTasks;
          $ctrl.inviteUser = inviteUser;

          $ctrl.avatar = {
            profile_pic: '',
            profile_pic_url: '',
            text: '',
            job_title: '',
            team: '',
            last_accessed: ''
          };

          function onInit() {
            $ctrl.fullName = $rootScope.identity.full_name;
            preferencesStore.getPreferences($rootScope.identity.id).then(function (preference) {
              $ctrl.isDashboardSplashDismissed = _.get(_.find(preference, { slug: "dashboard_splash_viewed" }), 'value', 'no') === 'yes';
            });

            var profile_pic, text;
            profile_pic = TasksService.setDefaultAvatar($rootScope.identity.profile_pic);
            text = TasksService.setDefaultAvatarText($rootScope.identity.first_name, $rootScope.identity.last_name);
            angular.extend($ctrl.avatar, {
              profile_pic: profile_pic,
              text: text,
              job_title: $rootScope.identity.job_title,
              team: $rootScope.identity.team,
              profile_pic_url: $rootScope.identity.profile_pic,
              last_accessed: $rootScope.identity.default_organization.last_accessed
            });
            $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST ? _.get($rootScope.identity, 'guest.organization.id') : _.get($rootScope.identity, 'default_organization.id');
            if ($rootScope.organizationTemplates) {
              $ctrl.homepageSnippetTemplate = $rootScope.organizationTemplates[$rootScope.identity.default_organization.homepage_snippet];
            }
          }

          function onChanges() { }
          function onDestroy() { }

          function onDashboardSplashDismissed(e) {
            e.preventDefault();
            $ctrl.isDashboardSplashDismissed = true;
            AccountService.setUserPreference({
              slug: "dashboard_splash_viewed",
              value: "yes"
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function (error) {
              $log.error(error);
            });
          }

          function goToAllNotifications() {
            $state.go('notificationsList');
          }

          function viewAllTasks() {
            $state.go('home');
          }

          /**
           * @ngdoc method
           * @name inviteUser
           * @description Invite new user, Show upgrade modal when limit reached
           * @returns {Object}
          */
          function inviteUser() {
            if (!Helper.checkAccessAuthority()) {
              return;
            } else if ($ctrl.orgUsersCount >= CONFIG.FREE_ACCOUNT_ACTIVE_USERS_LIMIT && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'coworkerLimit'))) {
              return;
            }

            UsersService.openInviteModal();
          }

          $rootScope.$watch('identity.default_organization.homepage_snippet', function (value) {
            if (value) {
              if (!$rootScope.organizationTemplates) {
                $rootScope.organizationTemplates = {};
              }
              $ctrl.homepageSnippetTemplate = $rootScope.organizationTemplates[value];
              if (!$ctrl.homepageSnippetTemplate) {
                OrganizationsService.getTextTemplate(value, true, $rootScope.userState)
                  .then(function (res) {
                    $ctrl.homepageSnippetTemplate = res.data;
                    $rootScope.organizationTemplates[value] = $ctrl.homepageSnippetTemplate;
                  });
              }
            }
          });

        }
    });
})();
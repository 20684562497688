/**
 * @ngdoc Component
 * @name tallyfy.process.component.processView
 * @module tallyfy.process
 *
 * @description
 * A component to manage blueprint view section
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('processView', {
      bindings: {
        selectedBlueprint: '=',
        launchProcess: '&',
        status: '<',
        onArchiveProcess: '&?'
      },
      templateUrl: 'app/modules/process/components/library/processView/process-view.html',
      controller:
        /*@ngInject*/
        function (_, $scope, ProcessService, blockUI, $filter, Growl, UsersService, $q, store, BLUEPRINT_TYPE, USER_STATE, $stateParams, $rootScope,
                  $timeout, PublicLibraryService, UtilsService) {
          var $ctrl = this,
          growl = new Growl();

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.isLoading = false;
          $ctrl.isScrollableView = isScrollableView;
          $ctrl.scrollBottom = scrollBottom;
          $ctrl.showBtn = showBtn;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function isScrollableView() {
            $ctrl.isScrolling = false;
            $timeout(function () {
              if (angular.element('#processContent').length && angular.element('#processView').length) {
                var clientHeight = document.getElementById('processContent').clientHeight,
                  contentHeight = document.getElementById('processView').scrollHeight;
                $ctrl.isScrolling = (clientHeight>=contentHeight);
              }
            }, 500);
          }

          function scrollBottom(elementById) {
            document.getElementById(elementById).scrollBy(0, 700);
          }

          function showBtn() {
            if ($ctrl.status === 'example') {
              $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST
                ? _.get($rootScope.identity, 'guest.organization.id')
                : _.get($rootScope.identity, 'default_organization.id');
              return _.get($ctrl.process, 'organization_id') === $ctrl.organizationId ? 'launch' : 'share';
            }
            return 'launch';
          }

          $scope.$watch('$ctrl.selectedBlueprint', function (value) {
            $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST
                ? _.get($rootScope.identity, 'guest.organization.id')
                : _.get($stateParams, 'public_org');
            if (value) {
              $ctrl.isLoading = true;
              if ($ctrl.status === 'example') {
                PublicLibraryService
                  .getPublicBlueprintDetail({
                    id: _.get(value, 'id'),
                    action: 'organizations',
                    action_id: $ctrl.organizationId,
                    sub_action: 'checklists',
                    sub_action_id: $stateParams.blueprint_id,
                    with: 'steps,steps.captures,tags',
                    skipAuthToken: true,
                    featured: false
                  }).then(function (res) {
                    $ctrl.publicExampleBP = res.data;
                    $ctrl.process = angular.copy(res.data);
                    $ctrl.isLoading = false;
                    isScrollableView();
                  });
              } else if ($rootScope.userState === USER_STATE.GUEST && $ctrl.status === 'uncategorized') {
                PublicLibraryService
                  .getPublicLibraryById({
                    org: _.get($rootScope.identity, 'guest.organization.id'),
                    guest_code: $stateParams.guest_code,
                    checklist_id: $stateParams.blueprint_id,
                    with: 'steps'
                  }).then(function(res) {
                    $ctrl.publicExampleBP = res.data;
                    $ctrl.process = angular.copy(res.data);
                    $ctrl.isLoading = false;
                    isScrollableView();
                  });
              } else {
                $q.all(
                  [ProcessService.get({
                    id: value.id || value,
                    with: 'steps,steps.captures,steps.threads,tags,folder,permissions',
                    skipNotFound: true
                  }),
                  store.getUsers(),
                  store.getGroups()
                  ]).then(function (response) {
                    var process = response[0].data;
                    $ctrl.orgGroups = response[2];
                    process.steps.data = _.orderBy(process.steps.data, 'position');
                    $ctrl.process = process;
                    var currentUserId = _.get($rootScope.identity, 'id'),
                      isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin"),
                      isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
                    $ctrl.process.havePermissionToLaunch = UtilsService.hasSpecificPermissions(isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.process_launch', []), _.get(process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups);
                    $ctrl.process.havePermissionToEdit = !isLightMember && UtilsService.hasSpecificPermissions(isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_edit', []), _.get(process, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups);
                    $ctrl.process.can_edit_perms = _.get(process, 'permissions.data', []);
                    $ctrl.process.guidance = ($ctrl.process.guidance) ? $ctrl.process.guidance : null;
                    $ctrl.orgAllUsers = UsersService.getAllOrgUsers(response[1]); // for all org users
                    $ctrl.availableUsers = UsersService.getBilledUsers(response[1]); // for active billed users
                    $ctrl.standardUsers = UsersService.getStandardUsers(response[1]); // for active standard users
                    $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
                    $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
                    isScrollableView();
                    blockUI.stop();
                    $ctrl.isLoading = false;
                  }, function (error) {
                    blockUI.stop();
                    $ctrl.isLoading = false;
                    if (error.status === 404) {
                      growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                    }
                });
              }
            }
          }, true);
          //controller ends
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.process.config.ProcessEditStateConfig
 * @module tallyfy.process
 *
 * @description
 * set route process Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .config(ProcessEditStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ProcessEditStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * ProcessEditStateConfig construct 
   */
  function ProcessEditStateConfig($stateProvider) {
    $stateProvider.state('process.edit', {
      url: '/blueprints/:slug/:view/:automationId?action&step&importPublicBlueprint&comment&section&print',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        title: 'Edit Template',
        slug: { squash: true, value: null },
        step: { squash: true, value: null },
        view: { squash: true, value: null },
        print: { squash: true, value: null },
        comment: { squash: true, value: null },
        importPublicBlueprint: { squash: true, value: null }
      },
      views: {
        'process_view': {
          component: 'processEdit'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('validation');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('process.onboarding', {
      url: '/onboarding/:slug',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        title: 'Onboarding Template',
      },
      views: {
        'process_view': {
          component: 'processOnboarding'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('validation');
          return $translate.refresh();
        }]
      }
    });
  }
})();
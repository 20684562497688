(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(GrowlConfig);

  GrowlConfig.$inject = ['growlProvider', '$httpProvider'];

  function GrowlConfig(growlProvider, $httpProvider) {
    growlProvider.onlyUniqueMessages(true);
    growlProvider.globalReversedOrder(true);
    growlProvider.globalPosition('top-center');
    growlProvider.globalTimeToLive(5000);
    growlProvider.globalDisableCountDown(true);
  }
})();

/**
 * @ngdoc Service
 * @name Progress
 *
 * @module tallyfy
 *
 * @description
 * Show progressbar on the top of the screen.
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('ProgressBar', ProgressBar);
    /*@ngInject*/
    function ProgressBar(_, ngProgressFactory) {
      /** 
       * store ProgressBar context in self
       */
      var self = this,
        progressBar = ngProgressFactory.createInstance();

      /**
       * public method's
       */
      self.start = start;
      self.stop = stop;
      self.status = status;
      self.reset = reset;
      self.isActiveProgress = isActiveProgress;

      /**
       * @ngdoc method
       * @name start
       * @description start progress bar at the top of screen
       */
      function start() {
        progressBar.start();
      }

      /**
       * @ngdoc method
       * @name stop
       * @description stop progress bar
       */
      function stop() {
        progressBar.complete();
      }

      /**
       * @ngdoc method
       * @name status
       * @description return progress bar current status
       */
      function status() {
        return progressBar.status();
      }

      /**
       * @ngdoc method
       * @name reset
       * @description reset progress bar
       */
      function reset() {
        progressBar.reset();
      }

      /**
       * @ngdoc method
       * @name isActiveProgress
       * @description check progress bar is loading/active
       * @returns {Boolean} 
       */
      function isActiveProgress() {
        var status = self.status();
        return status != 100 && status != 0;
      }
    }
})();
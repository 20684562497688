(function () {
  'use strict';
  angular
    .module('tallyfy.messages')
    .factory('ReactionRepository', ReactionRepository);

  /*@ngInject*/
  function ReactionRepository(_, BaseRepository) {
    var BASE_URI_SEGMENT = '/organizations/:org', BASE_GUEST_URI_SEGMENT = '/public/organizations/:org', repository;

    repository = BaseRepository(BASE_URI_SEGMENT + '/:action/:actionId/:subAction/:subActionId', {
      org: 'org_id',
      action: '@action',
      actionId: '@actionId',
      subAction: '@subAction',
      subActionId: '@subActionId'
    }, {});

    repository.addReactionToComment = function (args, body) {
      var params = {
        action: 'comments',
        actionId: args.commentId,
        subAction: 'reactions'
      };
      return repository.create(params, body);
    };

    repository.addGuestReactionToComment = function (args, body) {
      var guestRepo = getGuestRepository();
      var params = {
        action: 'tasks',
        actionId: args.taskId,
        subAction: 'comment',
        subActionId: args.commentId,
        section: 'reactions',
        skipAuthToken: true
      };
      return guestRepo.create(params, body);
    };

    repository.removeReactionFromComment = function (args) {
      var params = {
        action: 'comments',
        actionId: args.commentId,
        subAction: 'reactions',
        subActionId: args.reactionId
      };
      return repository.delete(params);
    };

    repository.removeGuestReactionFromComment = function (args) {
      var guestRepo = getGuestRepository();
      var params = {
        action: 'tasks',
        actionId: args.taskId,
        subAction: 'comment',
        subActionId: args.commentId,
        section: 'reactions',
        sectionId: args.reactionId,
        skipAuthToken: true
      };
      return guestRepo.delete(params);
    };

    function getGuestRepository() {
      return BaseRepository(BASE_GUEST_URI_SEGMENT + '/guests/guest_code/:action/:actionId/:subAction/:subActionId/:section/:sectionId', {
        org: 'org_id'
      }, {});
    }

    return repository;
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('RunsTableService', RunsTableService);

  function RunsTableService(_) {
    var self = this;
    self.getRunsTableOptions = function (additionalColumns) {
      return {
        columns: _.concat([{
          field: "name",
          headerTemplate: kendo.template($('#runTableNameHeaderColumnTemplate').html()),
          width: 260
        }, {
          field: "progress",
          headerTemplate: kendo.template($('#runTableProgressHeaderColumnTemplate').html()),
          width: 100
        }, {
          field: "status",
          headerTemplate: kendo.template($('#runTableStatusHeaderColumnTemplate').html()),
          width: 210
        }, {
          field: "due",
          headerTemplate: kendo.template($('#runTableDueHeaderColumnTemplate').html()),
          width: 140
        }, {
          field: "started_by",
          headerTemplate: kendo.template($('#runTableStartedByHeaderColumnTemplate').html()),
          width: 180
        }, {
          field: "owner_id",
          headerTemplate: kendo.template($('#runTableProcessOwnerHeaderColumnTemplate').html()),
          width: 180
        }, {
          field: "starred",
          headerTemplate: kendo.template($('#runTableStarredHeaderColumnTemplate').html()),
          width: 140
        }, {
          field: "started_at",
          headerTemplate: kendo.template($('#runTableStartDateHeaderColumnTemplate').html()),
          width: 140
        }, {
          field: "last_updated",
          headerTemplate: kendo.template($('#runTableLastUpdatedHeaderColumnTemplate').html()),
          width: 140
        }, {
          field: "assigned",
          headerTemplate: kendo.template($('#runTableAssignedHeaderColumnTemplate').html()),
          width: 220
        }, {
          field: "next_step",
          headerTemplate: kendo.template($('#runTableNextStepHeaderColumnTemplate').html()),
          width: 260
        }, {
          field: "next_step_assignee",
          headerTemplate: kendo.template($('#runTableNextStepAssigneeHeaderColumnTemplate').html()),
          width: 220
        }, {
          field: "next_step_issue",
          headerTemplate: kendo.template($('#runTableNextStepIssueHeaderColumnTemplate').html()),
          width: 260
        }, {
          field: "current_step",
          headerTemplate: kendo.template($('#runTableCurrentStepHeaderColumnTemplate').html()),
          width: 260
        }, {
          field: "last_completed_step",
          headerTemplate: kendo.template($('#runTableLastCompletedStepHeaderColumnTemplate').html()),
          width: 260
        }], additionalColumns),
        dataSource: new kendo.data.DataSource(),
        rowTemplate: kendo.template($('#runTableTableRow').html()),
        noRecords: {
          template: kendo.template($('#runTableNoData').html())
        },
        resizable: false
      }
    }
    return self;
  }
})();
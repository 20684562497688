/**
 * @ngdoc Service
 * @name tallyfy.files.FilesService
 *
 * @module tallyfy.files
 *
 * @description
 * FilesService
 *
* @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.files')
    .service('FilesService', FilesService);

  FilesService.$inject = ['$q', 'FilesRepository'];

  function FilesService($q, FilesRepository) {
    var self = this;
    angular.extend(self, FilesRepository);
  }
})();

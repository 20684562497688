/**
 * @ngdoc Component
 * @name tallyfy.filters.component.filter
 * @module tallyfy.filters
 *
 * @description
 * A component to manage filter view
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.filters')
    .component('filter', {
      bindings: {
        filterConfig: '<',
        changeView: '&',
        userPreferences: '<',
        runView: '<'
      },
      templateUrl: 'app/modules/filter/filter.html',
      controller:
        /*@ngInject*/
        function (_, FiltersService, AccountService, $state, Growl, FilterModalService, 
          $translate, $confirm, blockUI, $filter, AuthPlan, PLANS, RunsDashboardService, ProgressBar, preferencesStore, BLUEPRINT_TYPE) {
          var $ctrl = this,
            growl = new Growl(),
            sortOptions = [],
            sortConfigSlug,
            defaultSort,
            setActiveSort,
            isInit = false,
            isReplaceState;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.updateFiltersView = updateFiltersView;
          $ctrl.selectFilter = selectFilter;
          $ctrl.openFilterModal = openFilterModal;
          $ctrl.deleteCustomFilter = deleteCustomFilter;
          $ctrl.setRequestParameters = setRequestParameters;
          $ctrl.filterModalSuccessCallback = filterModalSuccessCallback;
          $ctrl.concatDefaultandCustomFilters = concatDefaultandCustomFilters;
          $ctrl.isProgressBar = isProgressBar;

          /**
           * public properties
           */
          $ctrl.emptyFilter = {
            name: '',
            criteria: [],
            type: { id: 'all', label: 'All' },
            customizable: true
          };

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            // Initialise filters config
            $ctrl.fc = angular.copy($ctrl.filterConfig);
            $ctrl.defaultFilter = _.head($ctrl.fc.defaultFilters);
            $ctrl.showUpgrade = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            
            // Initialise sort filters config
            var sortFilterConfig = RunsDashboardService.getSortFilterConfig();
            sortOptions = sortFilterConfig.sortOptions;
            sortConfigSlug = sortFilterConfig.sortConfigSlug;
            defaultSort = _.head(sortOptions);
            isInit = true;
            prepareFilter(true);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name prepareFilter
           * @description prepare filter value
           * @param onLoad
           */
          function prepareFilter(onLoad) {
            isReplaceState = !_.get($state, 'params.filter.length', 0);
            $ctrl.prefrenceValue = $state.params.filter ? $state.params.filter : FiltersService.getActiveFilterValue($ctrl.userPreferences, $ctrl.fc.filterConfigSlug, $ctrl.defaultFilter.id);
            setActiveSort =  FiltersService.getActiveFilterValue($ctrl.userPreferences, sortConfigSlug, defaultSort);
            onLoad ? getFiltersSuccessCallback($ctrl.runView) : getFilters();
          }

          /**
           * @ngdoc method
           * @name getFilters
           * @description Get run view records
           * @returns {Object} Run view
           */
          function getFilters() {
            blockUI.start();
            AccountService.getRunView({
              page: 1,
              per_page: 999
            }).then(getFiltersSuccessCallback, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name getFiltersSuccessCallback
           * @description Get custome filter
           * @param {Object} response Run view response
           * @returns {Object} Custom filters
           */
          function getFiltersSuccessCallback(response) {
            $ctrl.customFilters = FiltersService.setFilterRecord(response.data);
            $ctrl.concatDefaultandCustomFilters();
            $ctrl.updateFiltersView($ctrl.prefrenceValue);
            blockUI.stop();
          }

          /**
           * @ngdoc method
           * @name updateFiltersView
           * @description Get selected filter, Set id on URL
           * @param {id} Selected filter id
           * @param {eventSource} an optional property
           * @returns {Object} Filter
           */
          function updateFiltersView(id, eventSource) {
            var selectedFilter = getRunViewFilter(id);
            if (_.isUndefined(selectedFilter) || AuthPlan.getCurrentPlanCode() === PLANS.FREE && selectedFilter.id === $ctrl.fc.defaultFilters[4].id) {
              selectedFilter = $ctrl.defaultFilter;
            }


            // Set active filter activeView
            $ctrl.filter = selectedFilter;
            // Set selected filter as current filter for filter dropdown.
            $ctrl.setRequestParameters($ctrl.filter, eventSource);
        
            // Set id of active view on URL
            $state.go($state.current, {filter: $ctrl.filter.id}, {notify: false, location: isReplaceState ? 'replace' : true});
            isReplaceState = false;
          }

          /**
           * @ngdoc method
           * @name setRequestParameters
           * @description Set criteria for request parameters
           * @param {Object} Seleted filter
           * @returns {Object} filter
           */
          function setRequestParameters(filter, eventSource) {
            $ctrl.params = {};

            _.forEach(filter.criteria, function (elem, index) {
              $ctrl.params[elem.field] = $ctrl.params[elem.field] ? ($ctrl.params[elem.field] + ',' + elem.value) : elem.value;
            });

            filter.criteria.length === 1 ? $ctrl.params.filter_type = 'all' : $ctrl.params.filter_type = filter.type.id;

            // Set active filter procedure view
            if (filter.id === BLUEPRINT_TYPE.PROCEDURE) {
              $ctrl.params.type = BLUEPRINT_TYPE.PROCEDURE;
            }

            // Set active filter forms view
            if (filter.id === BLUEPRINT_TYPE.FORM) {
              $ctrl.params.type = BLUEPRINT_TYPE.FORM;
            }

            if (($ctrl.params.status) && ($ctrl.params.status === 'archived')) {
              if ($ctrl.params.filter_type === 'any') {
                $ctrl.params.archived = true;
              } else {
                $ctrl.params.archived = 'only';
              }
            }
            isInit ? $ctrl.params.sort = setActiveSort.value : isInit = false;
            $ctrl.changeView({ params: $ctrl.params, filter: $ctrl.filter, page : null , eventSource : eventSource});
          }

          /**
           * @ngdoc method
           * @name selectFilter
           * @description 
           *  Change a current active view, 
           *  Upsell modal for Overdue & Problems and Archived
           * @param {id} Seleted filter id
           * @returns {Object} filter
           */
          function selectFilter(id) {
            var selectedFilter = getRunViewFilter(id),
            /**
             * @property eventSource
             * @description property is used to identify if function is trigger on init or click
             * @private
             */
            eventSource = 'click';

            if (_.isUndefined(selectedFilter)) {
              selectedFilter = $ctrl.defaultFilter;
            }

            if ($ctrl.filter.id === selectedFilter.id)
              return;

            //Upsell modal for Overdue & Problems
            if (selectedFilter.id === $ctrl.fc.defaultFilters[4].id && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'reporting')))
              return;

            // TODO It can be uncomment in future
            //Upsell modal for Archived Processes
            // if (selectedFilter.id === $ctrl.fc.defaultFilters[2].id && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'archivedProcesses')))
            //   return;

            savePreferences(id);
            $ctrl.updateFiltersView(selectedFilter.id, eventSource);
          }
          
          /**
           * @ngdoc method
           * @name savePreference
           * @description Save/update preference.
           * @param {*} id
           */
          function savePreferences(id) {
            AccountService.setUserPreference({
              slug: $ctrl.fc.filterConfigSlug,
              value: "yes",
              metadata: { active_view: id }
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function () {
            });
          }

          /**
           * @ngdoc method
           * @name openFilterModal
           * @description Open a filter modal and update filter data
           * @param {boolean} isEdit
           * @returns {Object} Filter
           */
          function openFilterModal(isEdit) {
            if (!AuthPlan.hasAnyAuthority(PLANS.FREE, 'addFilter'))
              return;

            FilterModalService.openFilterModal(
              angular.copy(isEdit ? $ctrl.filter : $ctrl.emptyFilter),
              $ctrl.fc.filterFor,
              $ctrl.fc.filterTypes
            ).result.then(function successCallback(response) {
              if (response.isdelete) {
                deleteCustomFilter(response);
                return;
              }
              $ctrl.filterModalSuccessCallback(response, isEdit);
            });
          }

          /**
           * @ngdoc method
           * @name filterModalSuccessCallback
           * @description Save and update custom filter
           * @param {Object, boolean} filter, isEdit
           * @returns {Object} Filter
           */
          function filterModalSuccessCallback(response, isEdit) {
            // save and update custom filter
            response.filter.filter_type = response.filter.type.id;
            blockUI.start();
            if (isEdit) {
              AccountService.updateRunView({id: response.filter.id}, response.filter).then(function (data) {
                blockUI.stop();
                showMessage($ctrl.fc.successMessage);
                $ctrl.prefrenceValue = data.data.id;
                getFilters();
              }, function (error) {
                blockUI.stop();
              });
            } else {
              AccountService.setRunView(response.filter).then(function (data) {
                data.data.customizable = true;
                data.data.type = {id: data.data.filter_type, label: $filter('capitalize')(data.data.filter_type)};
                showMessage($ctrl.fc.successMessage);
                $ctrl.allFilters.push(data.data);
                $ctrl.selectFilter(data.data.id);
                blockUI.stop();
              }, function (error) {
                blockUI.stop();
              });
            }
          }

          /**
           * @ngdoc method
           * @name deleteCustomFilter
           * @description Delete custome filter
           * @param {Object} Filter
           * @returns {Object} Filter
           */
          function deleteCustomFilter(filter) {
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', {actionName: 'Delete', componentName: 'Custom View'}),
              'body': $filter('translate')('global.confirmAction.body', {actionName: 'delete', componentName: $ctrl.filter.name}),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', {actionName: 'Delete'}),
                'cancel': 'global.confirmAction.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              blockUI.start();
              AccountService.deleteRunView({id: filter.id}).then(function (data) {
                showMessage($ctrl.fc.deleteMessage);
                removeFilter(filter.id);
                $ctrl.selectFilter($ctrl.defaultFilter.id);
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }, function () {
              if (filter.isdelete)
                openFilterModal(true);
            });
          }

          /**
           * @ngdoc method
           * @name showMessage
           * @description Show growl message
           * @param {string} message
           * @returns {String} message
           */
          function showMessage(message) {
            $translate(message).then(function (translation) {
              growl.success(translation, {referenceId: 'filterController', disableIcons: true, disableCloseButton: true});
            });
          }

          /**
           * @ngdoc method
           * @name concatDefaultandCustomFilters
           * @description Concat default and custom filters
           * @returns {Object} All filter
           */
          function concatDefaultandCustomFilters() {
            $ctrl.allFilters = _.concat($ctrl.fc.defaultFilters, $ctrl.customFilters);
          }

          /**
           * @ngdoc method
           * @name getRunViewFilter
           * @description Return object in $ctrl.allFilters based in id
           * @param {id} Filter id
           * @returns {Object} Filter
           */
          function getRunViewFilter(id) {
            return _.find($ctrl.allFilters, function(o){
              return o.id === id;
            });
          }


          /**
           * @ngdoc method
           * @name removeFilter
           * @description Remove selected filter in all filters
           * @param {id} filterId
           * @returns {Object} Filters
           */
          function removeFilter(filterId) {
            _.filter(angular.copy($ctrl.allFilters), function (data, index) {
              if (data.id === filterId) {
                $ctrl.allFilters.splice(index, 1);
                return;
              }
            });
          }

          /**
           * @ngdoc method
           * @name isProgressBar
           * @description Check progress bar is active or not
           * @returns {Boolean}
           */
          function isProgressBar() {
            return ProgressBar.isActiveProgress();
          }
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.froalaTextShorten
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular.module('tallyfy')
    .directive('froalaTextShorten', froalaTextShorten);

  /*@ngInject*/
  function froalaTextShorten($parse, $filter, _, Lightbox, FroalaService) {
    return {
      restrict: 'A',
      scope: {
        froalaTextShorten: '=',
        shortenConfig: '=',
        openModal: '=',
        viewMoreModal: '&',
        isSearchable: '<?',
        searchKeyword: '<?'
      },
      link: function ($scope, $element) {
        var config = {
          threshold: 128,
          lessLabel: $filter('translate')('global.shortenLabel.viewLess'),
          moreLabel: $filter('translate')('global.shortenLabel.viewMore'),
          isExpand: false
        };

        /**
         * internal scope method
         */
        $scope.onToggle = function ($event) {
          config.isExpand = !$scope.openModal ? !config.isExpand : false;
          !$scope.openModal ? FroalaService.parseElement($scope, $element, config) : $scope.viewMoreModal({ event: $event });
        };

        /**
         * internal scope method
         */
        $scope.showImageLightbox = function (e) {
          Lightbox.openModal([{
            'url': e.target.dataset.link,
            'caption': e.target.dataset.text + '<span class="fa fa-circle">&nbsp</span>' + '<span>' + e.target.dataset.size + '</span>'
              + '<span class="fa fa-circle">&nbsp</span>' + '<a class="froala-img-download" href="' + e.target.dataset.link + '" target="_blank">' + $filter('translate')('steps.downloadText') + '</a>'
          }], 0);
        };

        /**
         * watch froala text shorten value
         */
        var unbindValue = $scope.$watch('froalaTextShorten', function () {
          FroalaService.parseElement($scope, $element, config);
        });

        /**
         *watch config value
         */
        var unbindConfig = $scope.$watch(
          $parse('shortenConfig', function (value) {
            return value;
          }), function (value) {
            if (value) {
              angular.extend(config, value);
              FroalaService.parseElement($scope, $element, config);
            }
          });

        /**
         * @name onDestroy
         * @description
         * handler on directive destroy
         */
        function onDestroy() {
          unbindValue();
          unbindConfig();
        }

        $scope.$on('$destroy', onDestroy);
      }
    };
  }
})();

(function () {
  'use strict';
  angular.module('tallyfy.public')
    .service('PublicOrganizationService', publicLibraryService);

  /*@ngInject*/
  function publicLibraryService(PublicOrganizationRepository) {
    var self = this;

    self.getPublicOrganizationDetail = function (args) {
      return PublicOrganizationRepository.get(args);
    };

    self.getPublicKoFormDetail = function (args) {
      return PublicOrganizationRepository.get(args);
    };

    self.createGuest = function (params, args) {
      return PublicOrganizationRepository.$create(params, args).$promise;
    };

    self.getGuestRunFormFields = function (runId) {
      var params = {
        org_id: 'org_id',
        action: 'guests',
        actionId: 'guest_code',
        subAction: 'runs',
        subActionId: runId,
        metadata: 'form-fields',
        skipAuthToken: true
      };
      return PublicOrganizationRepository.get(params);
    };
  }
})();
/**
 * @ngdoc Service
 * @name tallyfy.organizations.users.UsersService
 *
 * @module tallyfy.organizations.users
 *
 * @description
 * UsersService
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.organizations.users')
    .service('UsersService', UsersService);

  /*@ngInject*/
  function UsersService($q, UsersRepository, _, $log, $uibModal, Upload, CONFIG, $rootScope, $filter) {
    var self = this,
      user = {};

    //register default(get, all, delete, update, create) CRUD to service
    angular.extend(self, UsersRepository);
    /**
     * @function
     * @name getAvailableUsers
     * @description return users which are not disabled[disabled_on = true]
     * @return promise
     */
    /** TODO It can be unhide in future version
    self.getAvailableUsers = function (args) {
      var defer = $q.defer();
      args = args || {};

      self.getOwnerList(args).then(function (response) {
        var owners = _.filter(response.data, function (user) {
          return _.isNull(user.organization.data.disabled_on);
        }).map(function (owner) {
          return {
            id: owner.id,
            text: owner.full_name,
            email: owner.email,
            profile_pic: owner.profile_pic,
            first_name: owner.first_name
          };
        });
        defer.resolve(owners);
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    }
    */

    /**
     * @ngdoc method
     * @name getUsers
     * @description Returns only active users
     * @returns {Object}
     */
    self.getUsers = function () {
      var defer = $q.defer();
      $log.info('Loading Org Users');
      var args = {
        with: 'organization',
        role: '-guest'
      };
      self.getOwnerList(args).then(function (response) {
        defer.resolve(response.data);
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name openInviteModal
     * @param {string} email
     * @param {Integer} froalaID
     * @description open modal to invite the user
     */
    self.openInviteModal = function (email, froalaID) {
      var isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
      var allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
      if(isAdminMember || (!isAdminMember && allow_user_invite)) {
        return $uibModal.open({
          component: 'inviteUserComponent',
          windowClass: 'member-invite-modal',
          resolve : { email : function () { return email; }}
        }).closed.then(function() {
          if (froalaID) {
            var froalaIDfocus = '#froala-'+froalaID;
            var editor = new FroalaEditor(froalaIDfocus);
            editor.selection.setAtEnd(editor.$el.get(0));
            editor.selection.restore();
          }
        });
      }
    };

    /**
     * @ngdoc method
     * @name getAvailableUsers
     * @description Returns only active and invited users
     * @param {Object} users
     * @returns {Object}
     */
    self.getAvailableUsers = function (users) {
      return _.filter(users, function (user) {
        return user.status !== 'disabled';
      }).map(function (owner) {
        return {
          id: owner.id,
          text: owner.full_name,
          email: owner.email,
          profile_pic: owner.profile_pic,
          resize_profile_pic: owner.resize_profile_pic,
          first_name: owner.first_name,
          last_name: owner.last_name,
          full_name: owner.full_name
        };
      });
    };

    /**
     * @ngdoc method
     * @name updateUserPicture
     * @description update the user picture
     * @param {data} data
     * @returns {Object}
     */
    self.updateUserPicture = function (data) {
      return Upload.upload({
        url: CONFIG.API_HOST + '/me/profile-pic',
        data: {
          profile_pic: Upload.dataUrltoBlob(data.dataUrl, data.name)
        }
      });
    };

    /*
     * @ngdoc method
     * @name moveCurrentUserIndex
     * @description Get current user on the first index
     * @param {object} users
     * @returns {Object}
     */
    self.moveCurrentUserIndex = function (users) {
      var userIndex, user;
      userIndex = _.findIndex(users, { 'id': $rootScope.identity.id });
      if (userIndex >= 0) {
        user = _.find(users, { 'id': $rootScope.identity.id });
        users.splice(userIndex, 1);
        users.unshift(user);
      }
      return users;
    };

    /**
     * @ngdoc method
     * @name getActiveUsers
     * @description Returns only active users
     * @param {Object} users
     * @param {Object} isAll
     * @returns {Object}
     */
    self.getActiveUsers = function (users, isAll) {
      return _.filter(users, function (user) {
        return (!isAll && (user.status !== 'disabled') && (user.status !== 'invited')) || (isAll && (user.status !== 'invited')) ;
      }).map(function (owner) {
        return {
          id: owner.id,
          text: owner.full_name,
          email: owner.email,
          profile_pic: owner.profile_pic,
          resize_profile_pic: owner.resize_profile_pic,
          first_name: owner.first_name,
          last_name: owner.last_name,
          full_name: owner.full_name,
          role: owner.role,
          status: owner.status,
          type: owner.type
        };
      });
    };

    /**
     * @ngdoc method
     * @name getBilledUsers
     * @description Returns only billed users
     * @param {Object} users
     * @returns {Object}
     */
    self.getBilledUsers = function (users) {
      return _.filter(self.getActiveUsers(users, false), function (user) {
        return (user.type !== 'bot');
      });
    };

    /**
     * @ngdoc method
     * @name usersWithoutBot
     * @description Returns users omitting the bot user
     * @param {Object} users
     * @returns {Object}
     */
    self.usersWithoutBot = function (users) {
      return _.filter(users, function (user) {
        return (user.type !== 'bot');
      });
    };

    /**
     * @ngdoc method
     * @name getAllOrgUsers
     * @description Returns only non invited users
     * @param {Object} users
     * @returns {Object}
     */
    self.getAllOrgUsers = function (users) {
       return self.getActiveUsers(users, true);
    };

    /**
     * @ngdoc method
     * @name getStandardUsers
     * @description Returns only standard users
     * @param {Object} users
     * @returns {Object}
     */
    self.getStandardUsers = function (users) {
      return _.filter(users, function (user) {
        return user.status !== 'disabled' && user.role !== "admin" && (user.status === 'active');
      }).map(function (owner) {
        return {
          id: owner.id,
          text: owner.full_name,
          email: owner.email,
          profile_pic: owner.profile_pic,
          resize_profile_pic: owner.resize_profile_pic,
          first_name: owner.first_name,
          last_name: owner.last_name,
          full_name: owner.full_name,
          role: owner.role
        };
      });
    };

    /**
     * @ngdoc method
     * @name setUser
     * @param {Object} u 
     * @description Set user data
     */
    self.setUser = function (u) {
      user = u;
    };

    /**
     * @ngdoc method
     * @name getUser
     * @description Get user data
     * @returns {Object}
     */
    self.getUser = function () {
      return user;
    };

    /**
     * @ngdoc method
     * @name getUserRoles
     * @description Get user roles
     * @returns {Object}
     */
    self.getUserRoles = function () {
      return [
        { 
          'id'  : 1,
          'type': 'admin',
          'icon': 'fa-user-cog',
          'name': $filter('translate')('global.roles.admin'),
          'explanationTranslate': 'global.roles.explanation.admin'
        },
        { 
          'id'  : 2,
          'type': 'standard',
          'icon': 'fa-user-shield',
          'name': $filter('translate')('global.roles.standard'),
          'explanationTranslate': 'global.roles.explanation.standard'
        },
        {
          'id'  : 3,
          'type': 'light',
          'icon': 'fa-user-tag',
          'name': $filter('translate')('global.roles.light'),
          'explanationTranslate': 'global.roles.explanation.light'
        }];
    };
  }
})();

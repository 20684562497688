/**
 * @ngdoc Config
 * @name tallyfy.organizations.config.routeConfig
 * @module tallyfy.organizations
 *
 * @description
 * set route Organizations Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    $stateProvider.state('organizations.list', {
      parent: 'organizations',
      url: '/list',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        '@': {
          component: 'organizationList'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();

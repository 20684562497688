(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('Activate', Activate);

  Activate.$inject = ['$resource', 'CONFIG'];

  function Activate($resource, CONFIG) {
    var service = $resource(CONFIG.API_HOST + '/auth/activate', {}, {
      'get': { method: 'GET', params: {}, isArray: false }
    });

    return service;
  }
})();
/**
 * @ngdoc component
 * @name tallyfy.compactItemDeadline
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactItemDeadline', {
      templateUrl: 'app/components/compact/items/compactDeadline/compact-item-deadline.component.html',
      bindings: {
        deadline: '=',
        asHumanize: '<',
        tooltippedTitle: '@',
        tooltipDirection: '<',
        editMode: '<?',
        focusOnEdit: '<',
        tooltipDisabled: '<',
        disallowPastDates: '<?',
        dueDateUpdate: '=?',
        showPill: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, TasksService, $rootScope, USER_STATE, OrganizationsService) {
          var $ctrl = this,
            unRegisterDeadlineWatcher;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() {
            var hardRelativeDatePreferencesData = _.find(_.get($rootScope.identity, 'preferences', {}), { 'slug': 'hard_relative_date' }),
              isGuest = $rootScope.userState === USER_STATE.GUEST;

            $ctrl.isHardRelativeDate = (isGuest || (_.get(hardRelativeDatePreferencesData, 'value') === 'yes'));
          }

          function onChanges() { }

          function onDestroy() {
            unRegisterDeadlineWatcher();
          }

          unRegisterDeadlineWatcher = $scope.$watch('$ctrl.deadline', function (value) {
            $ctrl.deadline = angular.copy(value);
            if (!$ctrl.asHumanize) {
                $ctrl.deadlineClass = '';
                if ($ctrl.showPill) {
                    var type = TasksService.getStatusUsingDate($ctrl.deadline);
                    $ctrl.pillStatus = _.get(type, 'title');
                    $ctrl.pillStatusClass = _.get(type, 'class');
                } else {
                    $ctrl.deadlineClass = TasksService.getClassUsingDate($ctrl.deadline);
                }
            }
          }, true);
        }
    });
})();
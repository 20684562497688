/**
 * @ngdoc component
 * @name tallyfy.compactItemStartedAt
 * @restrict 'A'
 * 
 * @author kiran Sompura ( gmail::kiranv.sompura@gmail.com)
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactItemStartedAt', {
      templateUrl: 'app/components/compact/items/compactStartedAt/compact-item-started-at.component.html',
      bindings: {
        startedAt: '=',
        asHumanize: '<?',
        tooltippedTitle: '@?',
        tooltipDirection: '<?',
        editMode: '<?',
        focusOnEdit: '<?',
        tooltipDisabled: '<?',
        disallowPastDates: '<?',
        dueDateUpdate: '=?',
        deadline: '=?',
        showPill: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, TasksService, $rootScope, USER_STATE, TranslationService, OrganizationsService) {
          var $ctrl = this,
            unRegisterStartWatcher;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() {
            var hardRelativeDatePreferencesData = _.find(_.get($rootScope.identity, 'preferences', {}), { 'slug': 'hard_relative_date' }),
              isGuest = $rootScope.userState === USER_STATE.GUEST,
              appLanguage = TranslationService.getMyAppLanguage($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : _.get($rootScope.identity, 'guest.preferences', {}));
            $ctrl.isHardRelativeDate = (isGuest || (_.get(hardRelativeDatePreferencesData, 'value') === 'yes'));
          }

          function onChanges() { }

          function onDestroy() {
            unRegisterStartWatcher();
          }

          unRegisterStartWatcher = $scope.$watch('$ctrl.startedAt', function (value) {
            $ctrl.startedAt = angular.copy(value);
            $ctrl.startedAtClass = '';
            if ($ctrl.showPill) {
                var type = TasksService.getStatusUsingDate($ctrl.startedAt);
                $ctrl.pillStatus = _.get(type, 'title');
                $ctrl.pillStatusClass = _.get(type, 'class');
            } else {
                $ctrl.startedAtClass = TasksService.getClassUsingDate($ctrl.startedAt);
            }
          }, true);
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.access.Component.editGroup
 * @module tallyfy.organizations.access
 *
 * @description
 * edit Group
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.access')
    .component('editGroup', {
      bindings: {
        group: '<',
        users: '<'
      }, require: {
        groupsCtrl: '^groups'
      },
      templateUrl: 'app/modules/access/groups/edit/edit-group.html',
      controller: [
        '_',
        'blockUI',
        '$log',
        'GroupService',
        'Growl',
        '$filter',
        function (_, blockUI, $log, GroupService, Growl, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('editGroup'),
            growl = new Growl('groups');
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.loadUsers = loadUsers;
          $ctrl.save = save;
          $ctrl.cancelEdit = cancelEdit;
          $ctrl.delete = deleteGroup;

          /**
           * public properties
           */

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.editGroup = angular.copy($ctrl.group);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @description 
           * @param {any} $query 
           * @param {any} itemList 
           * @returns 
           */
          function loadUsers($query, itemList) {
            return _.filter(itemList, function (owner) {
              return owner.text.toLowerCase().indexOf($query.toLowerCase()) != -1;
            });
          }
          /**
           * @name cancelEdit
           * @description An helper function to set editable=false and 
           * set form valid
           */
          function cancelEdit() {
            $ctrl.group.editable = false;
            $ctrl.editGroup = angular.copy($ctrl.group);
            $ctrl.editGroupForm.$setPristine();
            $ctrl.editGroupForm.$setUntouched();
          }
          /**
          * @ngdoc method
          * @name save
          * @description update group
          * @returns promise
          */
          function save() {
            if ($ctrl.editGroupForm.$invalid) {
              return;
            }
            blockUI.start();
            var requestPayload = angular.copy($ctrl.editGroup);
            GroupService.save(requestPayload, {
              id: requestPayload.id
            }).then(function (response) {
              var g = response.data, users = _.get(g, 'users.data') || [];
              g.users = GroupService.transformUsers(users);
              angular.extend($ctrl.group, g);
              $ctrl.group.editable = false;
              blockUI.stop();
              growl.success($filter('translate')('groups.messages.updated'));
            }).catch(function (error) {
              blockUI.stop();
              $log.error('Error while adding new Group', error);
            });
          }

          /**
         * @ngdoc method
         * @name deleteGroup
         * @description delete group
         * @param {any} id
         */
          function deleteGroup(id, $event) {
            if ($event) {
              $event.preventDefault();
            }
            blockUI.start();
            GroupService
              .delete({
                id: id
              })
              .then(function (response) {
                var idx = _.findIndex($ctrl.groupsCtrl.groups, { id: id });
                $ctrl.groupsCtrl.groups.splice(idx, 1);
                $log.info('Delete group with id - ', id);
                blockUI.stop();
                growl.success($filter('translate')('groups.messages.deleted'));
              }).catch(function (e) {
                $log.info('Unable to delete group with id-', id);
                blockUI.stop();
              });
          }

          //end of controller
        }]
    });
})();

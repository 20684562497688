(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tableOfContent', {
      templateUrl: 'app/components/tableOfContent/table-of-content.component.html',
      bindings: {
        elementQuerySelector: '<',
        tagsInOrder: '<',
        froalaOptions: '<',
        type: '<',
        menuClickHandler: '&',
        focusId: '<',
        isFormFieldVisible: '<',
        selectedField: '=',
        activeTab: '=',
        fields: '<',
        asTaskContent: '<',
        documentVariables: '<',
        documentSnippets: '<',
        documentBlueprints: '<',
        hideContent: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, Helper) {
          var $ctrl = this, sourceElement, variableRemovedWatcher, snippetRemovedWatcher, blueprintRemovedWatcher;;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.menuClick = menuClick;
          $ctrl.onFieldSelected = onFieldSelected;

          $ctrl.treeMenus = [];

          function onInit() {
            if ($ctrl.froalaOptions) {
              $ctrl.editorScope = $ctrl.froalaOptions.editorScope;
              $ctrl.process = $ctrl.editorScope.froalaOptions.process;
            }
          }

          function onChanges(changes) {
            if (changes.elementQuerySelector && changes.elementQuerySelector && changes.elementQuerySelector && changes.elementQuerySelector.currentValue) {
              generateTableOfContentTreeModel(true);
            }
          }

          function onDestroy() {
            variableRemovedWatcher();
            snippetRemovedWatcher();
            blueprintRemovedWatcher();
          }

          function getParent(source, menus, tagIndex, i) {
            var previousTagIndex = _.findIndex($ctrl.tagsInOrder,
              function (tag) {
                return tag === source[i - 1].localName;
              }
            );
            return ((tagIndex - previousTagIndex >= 1) || (i - 1 === 0))
              ? menus[i - 1].id
              : getParent(source, menus, tagIndex, i - 1);
          }

          function setParentExpanded(parent) {
            var parentMenu = _.find($ctrl.treeMenus, { id: parent });
            if (parentMenu) {
              parentMenu.expanded = true;
              parentMenu.parent !== 'root' ? setParentExpanded(parentMenu.parent) : angular.noop();
            }
          }

          function generateTableOfContentTreeModel(focusElement) {
            $timeout(function () {
              sourceElement = angular.element($ctrl.elementQuerySelector);
              if (sourceElement && sourceElement.length && $ctrl.tagsInOrder && !Helper.isObjectEmpty($ctrl.tagsInOrder)) {
                var titles = _.filter(sourceElement.querySelectorAll($ctrl.tagsInOrder), function (title) {
                  return !!angular.element(title).text().trim();
                });
                $ctrl.treeMenus = [];
                if (!Helper.isObjectEmpty(titles)) {
                  titles = _.filter(titles, function (title) {
                    return !Helper.isObjectEmpty(title.innerText);
                  });
                  for (var i = 0; i < titles.length; i++) {
                    var tagIndex = _.findIndex($ctrl.tagsInOrder,
                      function (tag) {
                        return tag.startsWith('.')
                          ? titles[i].classList.contains(tag.substring(1, tag.length))
                          : tag === titles[i].localName;
                      }
                    );
                    var menuElement = {
                      id: Helper.guid(),
                      expanded: titles[i].getAttribute('id') === $ctrl.focusId,
                      caption: titles[i].innerText,
                      classList: titles[i].classList.value,
                      parent: tagIndex === 0 ? 'root' : !titles[i - 1] ? 'root' : getParent(titles, $ctrl.treeMenus, tagIndex, i),
                      icon: $ctrl.type === 'procedure' ? titles[i].dataset.stepType : void 0,
                      nativeElement: titles[i]
                    };
                    if (menuElement.parent !== 'root' && menuElement.expanded && $ctrl.treeMenus.length) {
                      setParentExpanded(menuElement.parent);
                    }
                    $ctrl.treeMenus.push(menuElement);
                  }
                  _.map($ctrl.treeMenus, function (menu) {
                    menu.isHasChild = _.filter($ctrl.treeMenus, { parent: menu.id }).length;
                  });
                }
              }
            }, 1500).then(function () {
              if ($ctrl.focusId && focusElement) {
                var menuElement = _.find($ctrl.treeMenus, function (el) {
                  return el.nativeElement.getAttribute('id') === $ctrl.focusId;
                });
                $timeout(function () {
                  var menuDOMElement = angular.element('#toc-menu-item-' + menuElement.id);
                  menuDOMElement[0].scrollIntoView({
                    behavior: 'auto',
                    block: 'center'
                  });
                }, 350).then(function () {
                  menuElement.nativeElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                });
              }
            });
          }

          function menuClick(menu) {
            if ($ctrl.menuClickHandler && typeof $ctrl.menuClickHandler === 'function') {
              $ctrl.menuClickHandler({ menu: menu });
            }
          }

          function onFieldSelected(field) {
            if (field.id === _.get($ctrl.selectedField, 'id')) {
              $ctrl.selectedField = void 0;
              $rootScope.$emit('DOCUMENT_EDITOR:OPEN', { field: void 0 });
            } else {
              $rootScope.$emit('DOCUMENT_EDITOR:OPEN', { field: field });
              $timeout(function () {
                $rootScope.$emit('FIELD_EDITOR:SELECTED', { field: field, openKoFormTask: !$ctrl.hideContent });
              }, 0);
            }
          }

          $scope.$watch('$ctrl.froalaOptions.editorInitialized', function () {
            if ($ctrl.froalaOptions && $ctrl.froalaOptions.editorInitialized) {
              $ctrl.froalaOptions.froalaEditor.events.on('contentChanged', function () {
                generateTableOfContentTreeModel();
              });
            }
          });

          variableRemovedWatcher = $rootScope.$on('VARIABLE_FIELD_EDITOR:REMOVED', function (e, data) {
            $ctrl.documentVariables = _.filter($ctrl.documentVariables, function (variable) {
              return variable.id !== data.fieldId;
            });
          });

          blueprintRemovedWatcher = $rootScope.$on('BLUEPRINT_FIELD_EDITOR:REMOVED', function (e, data) {
            $ctrl.documentBlueprints = _.filter($ctrl.documentBlueprints, function (blueprint) {
              return blueprint.id !== data.blueprintId;
            });
          });

          snippetRemovedWatcher = $rootScope.$on('SNIPPET_FIELD_EDITOR:REMOVED', function (e, data) {
            $ctrl.documentSnippets = _.filter($ctrl.documentSnippets, function (snippet) {
              return snippet.id !== data.snippetId;
            });
          });
        }
    });
})();
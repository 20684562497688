/**
 * @ngdoc component
 * @name tallyfy.compactStepList
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactTask', {
      templateUrl: 'app/components/compact/components/task/compact-task.component.html',
      bindings: {
        index: '<',
        isGuest: '<',
        isPublicProcess: '<',
        viewerId: '<',
        task: '<',
        usersInOrg: '<',
        isSelected: '<',
        readOnlyMode: '<',
        editMode: '=?',
        process: '<?',
        forceOnboarding: '<?',
        onTooltipClose: '&',
        isLoading: '<',
        isPublic: '<',
        orgGroups: '<?',
        foldersList: '<?',
        simpleView: '<',
        onTaskClickHandler: '&?',
        koTaskAssigneeClickHandler: '&?',
        autoLoadProfileImage: '<?',
        tooltipLimit: '=?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, $analytics, $state, TasksService, CompactTaskService, Helper, Growl, blockUI, ENV_CONFIG, $uibModal,
          AuthPlan, PLANS, RunsService, OOTAdvancedSettingsService, FolderService, FieldService, $localStorage, StepService, $timeout, DESCRIPTIONSIZE) {
          var $ctrl = this,
            taskThreadsWatcher,
            unregisteredIssueReportedEventHandler,
            unregisteredIssueResolvedEventHandler,
            statusWatcher,
            unregisterUpdateCaptureListener,
            unregisterUpdateDeadlineListener,
            newUserInvitedWatcher,
            taskEditStatusWatcher,
            blockUI = blockUI.instances.get('tasks'),
            koFieldAssigneeClickEventHandler,
            koFieldUpdatedHandler,
            growl = new Growl(),
            unregisteredTaskUpdateHandler;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /* 
           * public properties
           */
          $ctrl.isOpenPopOver = false;
          $ctrl.isOpenMobilePopOver = false;
          $ctrl.isDragging = false;
          $ctrl.isAdminMember = false;
          $ctrl.isCompactTaskView = true;
          $ctrl.displayType = 'icon-only';
          $ctrl.iconSize = 'medium';
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;

          /**
           * Public method
           */
          $ctrl.onTaskSelected = onTaskSelected;
          $ctrl.copyLink = copyLink;
          $ctrl.resendTask = resendTask;
          $ctrl.viewProcess = viewProcess;
          $ctrl.editTask = editTask;
          $ctrl.deleteStandaloneTask = deleteStandaloneTask;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onSubHeadingClick = onSubHeadingClick;
          $ctrl.onStatusClick = onStatusClick;
          $ctrl.closePilotTooltip = closePilotTooltip;
          $ctrl.onChatIconClick = onChatIconClick;
          $ctrl.onLinkOOTToProcess = onLinkOOTToProcess;
          $ctrl.onUnlinkOOTFromProcess = onUnlinkOOTFromProcess;
          $ctrl.enquireAboutStatus = enquireAboutStatus;
          $ctrl.setShowHideTask = setShowHideTask;
          $ctrl.addTaskToFolders = addTaskToFolders;
          $ctrl.duplicateTask = duplicateTask;
          $ctrl.goToKoField = goToKoField;

          function onInit() {
            $ctrl.enquireStatus = !_.includes(_.get($ctrl.task, 'owners.users'), _.get($rootScope.identity, 'id'));
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.organizationId = $ctrl.isGuest ? _.get($rootScope.identity, 'guest.organization.id')
              : _.get($rootScope.identity, 'default_organization.id');
            var guestEmail = _.get($localStorage.guest, 'email', '');
            if (guestEmail) {
              $ctrl.isGuestAssigned = _.indexOf($ctrl.task.owners.guests, guestEmail) >= 0;
            }
            $ctrl.stepTypes = StepService.getStepTypes();
            $ctrl.selectedTaskType = _.find($ctrl.stepTypes, { value: $ctrl.task.task_type });
            if ($ctrl.task.is_oneoff_task && $ctrl.task.run_id) {
              $ctrl.linkedText = $filter('translate')('compact.task.detail.form.label.linked', { processName: _.get($ctrl.task, 'run.data.name', '') });
            }
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @param {*} changes 
           */
          function onChanges(changes) {
            if (changes.task) {
              if (changes.task.currentValue && changes.task.currentValue !== changes.task.previousValue) {
                $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
                setCompleteProgress();
                if ($ctrl.isPublicProcess) {
                  $ctrl.task.threads_count = $ctrl.task.threads.data.length;
                }
                if (!$ctrl.simpleView) {
                  koTaskAssigneeCheck();
                }
              }
              else {
                $ctrl.isHasIssue = false;
              }
              setThreads();
            }
            if (changes.index) {
              $timeout(function() {
                $rootScope.$emit('COMPACT_TASK:INDEX_UPDATED', { task: $ctrl.task, index: $ctrl.index });
              }, 1500);
            }
          }

          function onDestroy() {
            taskThreadsWatcher();
            unregisteredIssueReportedEventHandler();
            unregisteredIssueResolvedEventHandler();
            unregisterUpdateCaptureListener();
            unregisterUpdateDeadlineListener();
            statusWatcher();
            taskEditStatusWatcher();
            newUserInvitedWatcher();
            if (koFieldAssigneeClickEventHandler) {
              koFieldAssigneeClickEventHandler();
            }
            if (koFieldUpdatedHandler) {
              koFieldUpdatedHandler();
            }
            unregisteredTaskUpdateHandler();
          }

          function setShowHideTask(event) {
            event.stopPropagation();
            if (!$ctrl.task.is_oneoff_task) {
              CompactTaskService.taskComment($ctrl.task).then(function () {
                $ctrl.isOpenPopOver = false;
                var updateTaskStatus = $ctrl.task.status === "auto-skipped" ? 'show' : 'hide';
                TasksService.setShowHideTask({
                  org_id: $ctrl.organizationId,
                  task_id: _.get($ctrl.task, 'id'),
                  run_id: _.get($ctrl.task, 'run_id', void 0),
                  action: updateTaskStatus
                }).then(function (res) {
                  angular.extend($ctrl.task, {
                    status: _.get(res.data, 'status'),
                    status_label: _.get(res.data, 'status_label')
                  });
                  $ctrl.isOpenPopOver = false;
                  growl.success($filter('translate')('tasks.general.message.' + updateTaskStatus), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                  $rootScope.$emit('RUN:TASK_UPDATED');
                });
              });
            }
          }

          /**
           * @ngdoc method
           * @name onTaskSelected
           * @param {*} e 
           * @param {*} editMode 
           * @param {*} focusElement
           * 
           * @description
           * on task selected handler method
           */
          function onTaskSelected(e, editMode, focusElement) {
            if ($ctrl.onTaskClickHandler && typeof $ctrl.onTaskClickHandler === 'function') {
              $ctrl.onTaskClickHandler({ e: e, task: $ctrl.task });
            } else {
              var isChatBoxOpen = (focusElement === 'comment');
              if (angular.copy($ctrl.isSelected)) {
                return;
              }
              var isPopOverMenu = e.target.parentNode.className === 'edit-icon';
              if (isPopOverMenu) {
                return;
              }
              if (!$ctrl.isSelected) {
                $rootScope.$emit('RIGHT_PANE:OPEN', {
                  item: {
                    task: $ctrl.task,
                    index: $ctrl.index,
                    editMode: editMode,
                    forceOnboarding: $ctrl.forceOnboarding,
                    isChatBoxOpen: isChatBoxOpen,
                    focusElement: focusElement || 'title'
                  },
                  statusContent: {
                    showElement: $ctrl.isHasIssue && $ctrl.isGuest && ($ctrl.task.status !== 'completed'),
                    contentClass: ' has-issue-content ',
                    content: $filter('translate')('compact.task.detail.info.hasIssue', {
                      orgName: _.get($rootScope.identity, 'guest.organization.name')
                    })
                  },
                  customClass: ($ctrl.task.status === 'completed' && $ctrl.task.task_type === 'approval' && !$ctrl.task.is_approved) ? " rejected-task " : ""
                });
              } else if (typeof isChatBoxOpen !== 'undefined') {
                $rootScope.$emit('RIGHT_PANE:OPEN', {
                  isChatBoxOpen: isChatBoxOpen,
                  customClass: (($ctrl.task.status === 'completed' && $ctrl.task.task_type === 'approval' && !$ctrl.task.is_approved) ? " rejected-task " : "")
                });
              }
            }
          }

          function getStatusClickable() {
            var isHaveDescription = $ctrl.task.summary || _.get($ctrl.task, 'step.data.summary'),
              isHaveFormFields = _.get($ctrl.task, 'form_fields.data', []);
            return !Helper.isObjectEmpty(isHaveDescription) || !Helper.isObjectEmpty(isHaveFormFields);
          }

          /**
           * @ngdoc method
           * @param {*} $event
           * 
           * @description
           * copy guest link to clipboard 
           */
          function copyLink($event) {
            var owners = _.get($ctrl.task, 'owners');
            if (owners.guests.length) {
              if (owners.guests.length > 1) {
                $uibModal.open({
                  component: 'copyGuestUrl',
                  windowClass: 'copy-guest-url-modal',
                  backdrop: 'static',
                  resolve: {
                    owners: function () {
                      return owners;
                    }
                  }
                });
              } else if (owners.guests.length === 1) {
                var text = _.get($ctrl.task, 'owners.taskUrls[0]');
                Helper.copyToClipboard(text, 'guestUrl', $event);
              }
            } else {
              var text = $ctrl.task.is_oneoff_task ?
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('home', { org_id: $ctrl.organizationId, activeTask: $ctrl.task.id }) :
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('run.view', { org_id: $ctrl.organizationId, id: _.get($ctrl.task, 'run_id'), activeTask: $ctrl.task.id });
              Helper.copyToClipboard(text, 'taskLink', $event);
            }
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
          }

          function resendTask(event) {
            if (!Helper.checkAccessAuthority())
              return;
            Growl.clearAllMessages('global');
            event.stopPropagation();
            var params = {
              id: $ctrl.task.id,
              sub_action: 'email'
            };
            if (_.get($ctrl.task, 'run_id')) {
              angular.extend(params, {
                action: 'runs',
                action_id: _.get($ctrl.task, 'run.data.id')
              });
            }
            blockUI.start();
            TasksService.resendTask(params)
              .then(function () {
                blockUI.stop();
                trackEvent('Task edited', { action: 're-send' });
                growl.success($filter('translate')('tasks.general.resendTask'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
              }, function () {
                blockUI.stop();
              });
          }

          function viewProcess(event) {
            event.stopImmediatePropagation();
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            $state.go('run.view', {
              id: _.get($ctrl.task, 'run_id'),
              activeTask: $ctrl.task.id
            });
          }

          function editTask(event, editMode, focusElement) {
            if ($ctrl.isGuest) { return; }
            event.stopPropagation();
            event.preventDefault();
            $timeout(function () {
              $rootScope.$emit('COMPACT_TASK:ACTIVATE_ACCORDION', { accordionId: 'itemAssignee' });
            }, ($ctrl.isSelected ? 600 : 2000));

            var edit = !TasksService.isTaskEditable({
              task: $ctrl.task,
              owners: $ctrl.task.owners
            }, $ctrl.orgGroups);
            $ctrl.editMode = editMode = !edit;
            if (angular.copy($ctrl.editMode) && angular.copy($ctrl.isSelected)) {
              return;
            }
            if (!angular.copy($ctrl.editMode) && angular.copy($ctrl.isSelected)) {
              $rootScope.$emit('RIGHT_PANE:EDIT_MODE', { focusElement: focusElement });
              return;
            }
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            onTaskSelected(event, editMode, focusElement);
          }

          /**
           * @ngdoc method
           * @name deleteStandaloneTask
           * @param {event} event 
           * @return void
           * 
           * @description
           * to delete standalone task
           */
          function deleteStandaloneTask(event) {
            event.stopPropagation();
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            CompactTaskService.deleteStandaloneTask({
              taskId: $ctrl.task.id,
              index: $ctrl.index
            }).then(function () {
              trackEvent('Task edited', {
                action: 'delete'
              });
            });
          }

          /**
           * @ngdoc method
           * @name onHeadingClick
           * @param {*} e 
           * 
           * @description
           * heading title click handler
           */
          function onSubHeadingClick(e) {
            if ($ctrl.process || $ctrl.isPublicProcess) {
              return;
            }
            e.preventDefault();
            e.stopImmediatePropagation();
            $state.go($ctrl.isGuest ? 'guest.runs.view' : 'run.view', {
              org_id: $ctrl.organizationId,
              id: _.get($ctrl.task, 'run_id'),
              activeTask: $ctrl.task.id
            });
          }

          /**
           * @ngdoc method
           * @private
           * @name completeTask
           * @param {object} action
           * @description
           * complete a task
           */
          function completeTask(action) {
            $ctrl.onSaving = true;
            var stepCaptures = _.get($ctrl.task, 'form_fields.data', []), override_user;
            if ($ctrl.task.everyone_must_complete && $ctrl.isAdminMember && $ctrl.enquireStatus) {
              override_user = _.first(_.get($ctrl.task, 'completion_progress.waiting.users', []));
            }
            CompactTaskService.taskComplete($ctrl.task, action, $ctrl.isGuest, $ctrl.isSelected, override_user).then(function (res) {
              angular.extend($ctrl.task, res.task);
              setThreads();
              setCompleteProgress();
              trackEvent('Task - Left Pane > Task - Left Pane - Complete', {
                'category': 'Task - Left Pane',
                'form-fields': stepCaptures.length ? 'yes' : 'no'
              });
              if ($state.current.name === "run.view") {
                $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'run' });
              }
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @private
           * @name inCompleteTask
           * @description
           * re-open a task
           */
          function inCompleteTask() {
            if ($ctrl.isGuest) {
              return; // Guests cannot re-open a task
            }
            $ctrl.onSaving = true;
            var stepCaptures = _.get($ctrl.task, 'form_fields.data', []);
            CompactTaskService.taskReopen($ctrl.task).then(function (res) {
              angular.extend($ctrl.task, res.task);
              setThreads();
              setCompleteProgress();
              trackEvent('Task re-opened', {
                'form-fields': stepCaptures.length ? 'yes' : 'no'
              });
              $ctrl.onSaving = false;
              if ($state.current.name === "run.view") {
                $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.process.id, auditableType: 'run' });
              }
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name onStatusClick
           * @param {*} e 
           * @param {*} action
           * @description
           * on status click method handler
           */
          function onStatusClick(e, action) {
            if ($ctrl.isPublicProcess) {
              return;
            }
            Growl.clearAllMessages('global');
            e.preventDefault();
            e.stopPropagation();
            var isCanCompleteTask = CompactTaskService.isTaskCompletable($ctrl.task, $ctrl.orgGroups || []);
            var taskCaptures = _.get($ctrl.task, 'form_fields.data', []);
            if (taskCaptures.length) {
              _.forEach(taskCaptures, function (capture) {
                capture.value = $ctrl.task.taskdata[capture.id];
              });
            }
            var isHasEmptyRequiredFields = CompactTaskService.validateCaptures(taskCaptures), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(taskCaptures);
            if (getStatusClickable() && (isHasExceededTextChars || isHasEmptyRequiredFields)) {
              growl.warning($filter('translate')('tasks.messages.taskFormFields'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              onTaskSelected(e, $ctrl.editMode, 'comment');
              return;
            }
            if (!isCanCompleteTask) {
              growl.warning($filter('translate')('tasks.messages.taskNotAssignees'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              onTaskSelected(e, $ctrl.editMode, 'comment');
              return;
            }
            if ($ctrl.isHasIssue) {
              growl.warning($filter('translate')('tasks.messages.taskIssueExist'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              onTaskSelected(e, $ctrl.editMode, 'comment');
              return;
            }
            if ($ctrl.task.status === ('completed' || $ctrl.iHaveCompleted)) {
              inCompleteTask();
            } else {
              if (action === "reject") {
                onRejectTaskClick();
              } else {
                completeTask(action);
              }
            }
          }

          function onRejectTaskClick() {
            $ctrl.onRejectSaving = true;
            CompactTaskService.taskReject($ctrl.task).then(function (res) {
              angular.extend($ctrl.task, res.task);
              setCompleteProgress();
              trackEvent('Task reject', {
                'form-fields': _.get(res.task, 'form_fields', []).length ? 'yes' : 'no'
              });
              getAllCompletedBy();
              $ctrl.onRejectSaving = false;
            }, function () {
              $ctrl.onRejectSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name getAllCompletedBy
           * 
           * @description
           * on collect list of all completed by user and guest
           */
          function getAllCompletedBy() {
            var orgUser, completedBy = [], completed_by = _.get($ctrl.task, 'completion_progress.completed_by');
            orgUser = $ctrl.isGuest ? _.get($ctrl.task, 'owners.users') : $ctrl.orgAllUsers;
            if (_.get(completed_by, 'users', []).length > 0) {
              _.forEach(completed_by.users, function (userId) {
                completedBy.push(_.find(orgUser, { id: userId }));
              });
            }
            completedBy = _.concat(_.map(completedBy, 'full_name'), _.get(completed_by, 'guests', []));
            $ctrl.completedBy = completedBy.join(', ');
          }

          /**
           * @name trackEvent
           * @param {*} eventName 
           * @param  {*} properties
           * 
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = !_.get($ctrl.task, 'run_id') ? 'standalone' : 'process';
            properties.processId = $ctrl.process ? $ctrl.process.id : void 0;
            properties.taskId = $ctrl.task.id;
            $analytics.eventTrack(eventName, properties);
          }

          /**
           * @name closePilotTooltip
           * @description
           * close tooltips
           */
          function closePilotTooltip() {
            $ctrl.onTooltipClose({
              task: $ctrl.task
            });
          }

          function onChatIconClick(e) {
            e.preventDefault();
            e.stopPropagation();
            onTaskSelected(e, false, 'comment');
          }

          /**
           * @name getLatestTaskData
           * @description Get the latest task data
           * @param {*} updatedTask
           * @param {*} fetchWithParams
           */
          function getLatestTaskData(updatedTask, fetchWithParams) {
            var params = {};
            if (fetchWithParams) {
              angular.extend(params, { with: 'threads,threads.reactions' });
            }
            var resource = $ctrl.isGuest ? TasksService.getGuestTask(angular.extend({ action_id: $ctrl.task.id }, params)) : TasksService.getTask(angular.extend({ action_id: $ctrl.task.is_oneoff_task ? void 0 : _.get($ctrl.task, 'run_id'), id: $ctrl.task.id }, params));
            resource.then(function (response) {
              angular.extend($ctrl.task, response.data);
            }, function () { });
          }

          /**
           * @name shouldFetchLatestTaskOnCaptureUpdate
           * @param {*} updatedTask
           * @param {*} updatedCapture
           * @description Should fetch the latest task data on capture update?
           * @returns {boolean}
           */
          function shouldFetchLatestTaskOnCaptureUpdate(updatedTask, updatedCapture) {
            return CompactTaskService.isTaskToBeUpdatedOnCaptureUpdate($ctrl.task, updatedTask, updatedCapture, $ctrl.isGuest);
          }

          /**
           * @name shouldFetchLatestTaskOnDeadlineUpdate
           * @param {*} eventData
           * @description Should fetch the latest task data on deadline update?
           * @returns {boolean}
           */
          function shouldFetchLatestTaskOnDeadlineUpdate(eventData) {
            return CompactTaskService.isTaskToBeUpdatedOnDeadlineUpdate($ctrl.task, eventData);
          }

          function setThreads() {
            var threads = _.get($ctrl.task, 'threads.data', []);
            $ctrl.threads = $ctrl.task.is_approved ? _.filter(threads, function (thread) {
              return thread.label !== 'rejected-comment';
            }) : threads;
            $ctrl.getTaskCompletionTiming = TasksService.getCompletionTiming($ctrl.task.deadline_unformatted, $ctrl.task.completed_at);
            if (($ctrl.task.status_label === 'completed' && $ctrl.task.task_type === 'email') || ($ctrl.task.status_label === 'acknowledged' && $ctrl.task.task_type === 'expiring_email')) {
              $ctrl.taskStatusLabel = $ctrl.getTaskCompletionTiming.isLate ? 'sent late' : 'sent';
            } else if ($ctrl.task.everyone_must_complete && _.get($ctrl.task, 'completion_progress.rejected_count') >= 1) {
              $ctrl.taskStatusLabel = 'rejected';
            } else if ($ctrl.task.task_type === 'task' && $ctrl.getTaskCompletionTiming.isLate) {
              $ctrl.taskStatusLabel = 'completed late';
            } else if ($ctrl.task.task_type === 'approval' && $ctrl.getTaskCompletionTiming.isLate) {
              $ctrl.taskStatusLabel = $ctrl.task.status_label === 'approved' ? 'approved late' : 'rejected late';
            } else if ($ctrl.task.task_type === 'expiring' && $ctrl.task.status_label === 'acknowledged' && $ctrl.getTaskCompletionTiming.isLate) {
              $ctrl.taskStatusLabel = 'acknowledged late';
            } else {
              $ctrl.taskStatusLabel = $ctrl.task.status_label;
            }
          }

          function onLinkOOTToProcess() {
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              var modalInstance = OOTAdvancedSettingsService.openModalOOTAdvancedSetting($ctrl.task);
              modalInstance.then(function (task) {
                CompactTaskService.linkOOTTask(task.id, _.get(task, 'run_id'), task.position)
                  .then(function (res) {
                    angular.extend($ctrl.task, res.data);
                    _.get($ctrl.task, 'run_id') ? updateTaskRun() : $ctrl.task = _.omit($ctrl.task, ['run']);
                    $rootScope.$emit('COMPACT_TASK:TASK_LINKED', { task: $ctrl.task });
                  });
              }, function (task) {
                $ctrl.task = _.omit($ctrl.task, ['run_id', 'position', 'run']);
                angular.extend($ctrl.task, task);
                if (_.get($ctrl.task, 'run_id')) {
                  updateTaskRun();
                } else {
                  $ctrl.task = _.omit($ctrl.task, ['omit']);
                }
              });
            }
          }

          function onUnlinkOOTFromProcess() {
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              CompactTaskService.unlinkOOTTask($ctrl.task.id, _.get($ctrl.task, 'run_id'), $ctrl.task.position)
                .then(function (res) {
                  angular.extend($ctrl.task, res.data);
                  $ctrl.task.run_id = null;
                  $ctrl.task = _.omit($ctrl.task, ['run', 'run_status']);
                  $rootScope.$emit('COMPACT_TASK:UNLINK_FROM_PROCESS', {
                    stageId: $ctrl.task.stage_id,
                    taskId: $ctrl.task.id,
                    index: $ctrl.index
                  });
                });
            }
          }

          function updateTaskRun() {
            RunsService.get({
              id: _.get($ctrl.task, 'run_id')
            }).then(function (res) {
              angular.extend($ctrl.task, {
                run: { data: res.data }
              });
            });
          }

          /**
           * @ngdoc method
           * @name setCompleteProgress
           * 
           * @description
           * set configuration for everyone_must_complete
           */
          function setCompleteProgress() {
            if ($ctrl.task.everyone_must_complete) {
              var owners = _.get($ctrl.task, 'owners', {});
              var waitingCompleter = _.get($ctrl.task, 'completion_progress.waiting', {});
              $ctrl.completePercent = (100 * $ctrl.task.completion_progress.completed) / $ctrl.task.completion_progress.total;
              if ($rootScope.identity.guest) {
                $ctrl.iHaveCompleted = !_.includes(waitingCompleter.guests, $rootScope.identity.guest.email);
              } else {
                $ctrl.iHaveCompleted = false;
                if (_.includes(owners.users, $rootScope.identity.id)) {
                  $ctrl.iHaveCompleted = !_.includes(waitingCompleter.users, $rootScope.identity.id);
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name enquireAboutStatus
           * @param {*} event
           * @description
           * click on dots list Enquire about status
           */
          function enquireAboutStatus(event) {
            blockUI.start();
            CompactTaskService.postEnquireCommentSend({
              task: $ctrl.task
            }).then(function () {
              blockUI.stop();
              enquireCommentPostedMessage(event);
              $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            }, function () {
              blockUI.stop();
            });
          }

          function enquireCommentPostedMessage(event) {
            var alertObj = { referenceId: 'global', disableIcons: true, disableCloseButton: true };
            angular.extend(alertObj, {
              variables: {
                method: function (param) {
                  onTaskSelected(param.e, param.editMode, '');
                },
                param: { "e": event, "editMode": false },
                linkText: $filter('translate')('global.task.messages.viewTask')
              }
            });
            growl.success($filter('translate')('global.task.messages.enquireCommentPosted'), alertObj);
          }

          statusWatcher = $scope.$watch('$ctrl.task.status', function (oldValue, newValue) {
            if (newValue === 'completed' || oldValue === 'completed') {
              setThreads();
            }
          });

          taskThreadsWatcher = $scope.$watchCollection('$ctrl.task.threads.data', function (value) {
            if (!!value) {
              $ctrl.task.threads_count = value.length;
              _.extend($ctrl.task, { problem: _.get($ctrl.task, 'hasIssues') });
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
              setThreads();
              setRightPaneStatus();
            }
          });

          // watch for threads data edited
          unregisteredIssueReportedEventHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            if (data.issue.task_id === $ctrl.task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
              setRightPaneStatus();
            }
          });

          // watch for threads data edited
          unregisteredIssueResolvedEventHandler = $rootScope.$on('ISSUE:RESOLVED', function (e, data) {
            _.extend($ctrl.task, { problem: _.get(data, 'issue.label') === 'resolve' ? false : true });
            if (data.issue.task_id === $ctrl.task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue(_.get(data, 'issue'));
              setRightPaneStatus();
            }
            $ctrl.isGuest ? _.set($ctrl.task, 'hasIssues', false) : angular.noop();
          });

          function setRightPaneStatus() {
            if ($ctrl.isGuest) {
              $rootScope.$emit('RIGHT_PANE:UPDATE_STATUS', $ctrl.isHasIssue ? {
                showElement: true,
                contentClass: ' has-issue-content ',
                content: $filter('translate')('compact.task.detail.info.hasIssue', {
                  orgName: _.get($rootScope.identity, 'guest.organization.name')
                })
              } : void 0);
            }
          }

          function addTaskToFolders(event) {
            event.stopImmediatePropagation();
            $ctrl.isOpenPopOver = false;
            var openTaskFoldersModal = FolderService.openObjectFoldersModal($ctrl.foldersList, $ctrl.task.id, 'Task', $ctrl.task.folders.data, $ctrl.task.title);
            openTaskFoldersModal.result.then(function (data) {
              $ctrl.task.folders.data = data;
            }, function (data) {
              $ctrl.task.folders.data = data;
            });
          }

          /**
           * event handler Update task title / summary variables when a field is updated. Handles only visible tasks (where this component is active), invisible / hidden tasks updating handled at compactTaskList component.
           * @type {*|(function())}
           */
          unregisterUpdateCaptureListener = $rootScope.$on('CAPTURE:UPDATE', function (event, data) {
            var updatedTask = data.updatedTask, updatedCapture = data.updatedCapture;
            if (shouldFetchLatestTaskOnCaptureUpdate(updatedTask, updatedCapture)) {
              getLatestTaskData(updatedTask, false);
            }
          });

          /**
           * event handler Update dependent tasks' deadlines when a deadline is updated. Handles only visible tasks (where this component is active), invisible / hidden tasks updating handled at compactTaskList component.
           * @type {*|(function())}
           */
          unregisterUpdateDeadlineListener = $rootScope.$on('COMPACT_TASK:UPDATE_DATA', function (event, eventData) {
            if (shouldFetchLatestTaskOnDeadlineUpdate(eventData)) {
              getLatestTaskData(eventData.updatedTask, eventData.fetchWithParams);
            }
          });

          taskEditStatusWatcher = $scope.$watchCollection('$ctrl.task.owners.users', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              $ctrl.enquireStatus = !_.includes(_.get($ctrl.task, 'owners.users'), _.get($rootScope, 'identity.id'));
            }
          });

          newUserInvitedWatcher = $rootScope.$on('COMPACT_TASK_COMMENT:NEW_USER_INVITED', function (e, data) {
            getLatestTaskData(data.task, true);
          });

          /**
           * Duplicate a task
           * @type {*|(function())}
           */
          function duplicateTask() {
            var payload = {
              task_id: $ctrl.task.id,
              org: $ctrl.organizationId
            };
            if ($ctrl.task.run_id) {
              payload.run_id = $ctrl.task.run_id;
            }
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            blockUI.start();
            CompactTaskService.duplicateTask(payload).then(function (response) {
              if (response.data.run_id) {
                updateTaskRun();
                $rootScope.$emit('COMPACT_TASK:CREATED', { task: response.data });
              } else {
                $rootScope.$emit('STANDALONE_TASK:CREATED', { task: response.data, taskOpen: true });
              }
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  task: response.data,
                  index: response.data.position
                }
              });
              blockUI.stop();
            });
          }

          function koFieldUpdated(field) {
            $ctrl.task.taskdata[field.id] = FieldService.getFieldValue(field);
            var taskPayload = { taskdata: $ctrl.task.taskdata },
              resource = $ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned)
                ? CompactTaskService.updateGuestTask($ctrl.task, taskPayload)
                : CompactTaskService.updateTask($ctrl.task, taskPayload);
            resource.then(function () {
              completeTask();
            });
          }

          function koTaskAssigneeCheck() {
            var tempEl = angular.element('<div>');
            tempEl.html($ctrl.task.summary);
            var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
            if (koAssigneeElements.length) {
              $ctrl.isKoTaskAssignee = true;
              koFieldAssigneeClickEventHandler = $rootScope.$on(
                'KO_FIELD_ASSIGNEE:CLICK' + koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, ''),
                function (e) {
                  $ctrl.koTaskAssigneeClickHandler({
                    e: e,
                    task: $ctrl.task
                  });
                }
              );
              koFieldUpdatedHandler = $rootScope.$on('CAPTURE:UPDATE' + koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, ''), function (e, data) {
                angular.extend($ctrl.task.form_fields.data[0], { value: data.updatedCapture.value });
                koFieldUpdated($ctrl.task.form_fields.data[0]);
              });
            }
          }

          function goToKoField(e) {
            var tempEl = angular.element('<div>');
            tempEl.html($ctrl.task.summary);
            var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
            if (koAssigneeElements.length) {
              $ctrl.onTaskClickHandler({
                e: e,
                task: 'ko-field',
                args: {
                  focusField: {
                    alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '')
                  }
                }
              });
            }
          }

          unregisteredTaskUpdateHandler = $rootScope.$on('COMPACT_TASK:UPDATE', function (e, data) {
            if (data.task.id === $ctrl.task.id) {
              $ctrl.selectedTaskType = _.find($ctrl.stepTypes, { value: $ctrl.task.task_type });
            }
          });
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.run.component.editDeadlines
 * @module tallyfy.run
 *
 * @description
 * A component to change the deadline
 * @param deadline {Object} current dealine
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('editDeadlines', {
      bindings: {
        deadline: '=?',
        invalidDeadline: '=?',
        focus: '<?',
        deadlineUnformatted: '=?'
      },
      require: {},
      templateUrl: 'app/modules/runs/editDeadlines/edit-deadlines.html',
      controller:
        /*@ngInject*/
        function (_, moment, $scope, RunsService, DateUtils, OrganizationsService) {
          var $ctrl = this;
          $ctrl.isShow = false;
          $ctrl.hstep = 1;
          $ctrl.mstep = 10;
          $ctrl.datePicker = {
            dateOptions: {
              dateDisabled: false,
              formatYear: 'yy',
              formatMonth: 'MMM',
              monthColumns: '4',
              maxDate: new Date(2049, 12, 30),
              startingDay: 1,
              showButtonBar: false,
              altInputFormats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
              showWeeks: false,
              ngModelOptions: {
                debounce: 100
              }
            },
            isOpened: false
          };
          $ctrl.splittedTime = {
            year: "",
            day: "",
            month: "",
            hours: "",
            minutes: "",
            period: ""
          };
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.isValidMonth = isValidMonth;
          $ctrl.isValidDay = isValidDay;
          $ctrl.isValidYear = isValidYear;
          $ctrl.isValidHour = isValidHour;
          $ctrl.isValidMinute = isValidMinute;
          $ctrl.onMeridianChange = onMeridianChange;
          $ctrl.openDatePicker = openDatePicker;
          $ctrl.toggleMode = toggleMode;
          $ctrl.isValidMeridian = isValidMeridian;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.deadline = moment(DateUtils.toTimezone($ctrl.deadline).value()).toDate();
            generateSplittedDateTime();
            $ctrl.selectedDateFormat = OrganizationsService.dateFormat().dateFormat;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          $scope.$watch('$ctrl.deadline', function (newValue, oldValue) {
            if (_.isNull(newValue) && isValidSplittedTime($ctrl.splittedTime, false).length <= 0) {
              $ctrl.deadline = oldValue;
            }
            if (!moment(newValue).isValid()) {
              return;
            }
            generateSplittedDateTime();
          });

          $scope.$watchCollection('$ctrl.splittedTime', function (newValue, oldValue) {
            if (_.isEmpty(oldValue.day)) {
              $ctrl.deadline = RunsService.getNewDate($ctrl.splittedTime);
            }
            if (isValidSplittedTime(newValue, false).length > 0) {
              $ctrl.deadline = null;
            }

            if (newValue === oldValue) {
              return;
            }
            $ctrl.invalidDeadline = isValidDate();
            if (!$ctrl.invalidDeadline) {
              return;
            }
            updateDeadLine();
          });

          /**
           * @function
           * @name onMeridianChange
           * @description update date when meridian value changed
           */
          function onMeridianChange() {
            if ($ctrl.meridian && moment($ctrl.deadline).isValid()) {
              updateDeadLine();
            }
          }

          /**
           * @function
           * @name isValidMeridian
           * @param meridian
           * @description Check if the meridian is valid or not
           */
          function isValidMeridian(meridian) {
            return _.toLower(meridian) === 'pm' || _.toLower(meridian) === 'am';
          }

          /**
           * @function
           * @name updateDeadLine
           * @description update dead line when date/time get changed
           */
          function updateDeadLine() {
            if (isValidSplittedTime($ctrl.splittedTime, true).length > 0) {
              return;
            }
            if (_.isNull(angular.copy($ctrl.deadline))) {
              $ctrl.deadline = RunsService.getNewDate($ctrl.splittedTime);
            }
            $ctrl.localDeadline.setDate($ctrl.splittedTime.day);
            $ctrl.localDeadline.setYear($ctrl.splittedTime.year);
            $ctrl.localDeadline.setMonth($ctrl.splittedTime.month - 1);
            var meridian = _.toUpper($ctrl.meridian);
            var hours = angular.copy($ctrl.splittedTime.hours);
            if (meridian == 'PM' && $ctrl.splittedTime.hours != '12') {
              hours = parseInt($ctrl.splittedTime.hours) + 12;
            } else if (meridian == 'AM' && $ctrl.splittedTime.hours == '12') {
              hours = '00';
            }
            $ctrl.localDeadline.setHours(hours);
            $ctrl.localDeadline.setMinutes($ctrl.splittedTime.minutes);
            $ctrl.deadlineUnformatted = DateUtils.toTimezone(DateUtils.toUTC(moment($ctrl.localDeadline).format()).format()).value();
            $ctrl.deadline = formatDeadline($ctrl.localDeadline, 'todate');
          }

          /**
           * @function
           * @name isValidSplittedTime
           * @description check spiltted time is empty or not
           * @param splittedTime
           * @param checkFullDate
           * @returns {Array}
           */
          function isValidSplittedTime(splittedTime, checkFullDate) {
            return _.filter(splittedTime, function (key, value) {
              if (!checkFullDate && _.includes(['day', 'month', 'year'], value)) {
                return _.isEmpty(key);
              }
              if (checkFullDate) {
                return _.isEmpty(key);
              }
            });
          }

          /**
           * @function
           * @name formatDeadline
           * @description Format the deadline according the request
           */
          function formatDeadline(deadline, formatType) {
            return (formatType === 'todate') ? DateUtils.toTimezone(deadline).value().toDate() : DateUtils.toTimezone(deadline).value().format('YYYY-MM-DD HH:mm:ss');
          }

          /**
           * @function
           * @name openDatePicker
           * @description Datepicker open
           */
          function openDatePicker() {
            $ctrl.datePicker.isOpened = true;
          }

          /**
           * @function
           * @name toggleMode
           * @description
           * Timepicker toggle to show
           */
          function toggleMode() {
            $ctrl.isShow = !$ctrl.isShow;
          }

          /**
           * @function
           * @name generateSplittedDateTime
           * @description Generate date and time to show in the splitted input box in UI
           */
          function generateSplittedDateTime() {
            var deadline = DateUtils.toTimezone($ctrl.deadline);
            $ctrl.localDeadline = moment(deadline.value()).toDate();
            $ctrl.meridian = deadline.getHours() >= 12 ? 'PM' : 'AM';
            $ctrl.splittedTime = deadline.getSplittedTime();
            $ctrl.invalidDeadline = !isValidDate();
          }

          /**
           * @function
           * @name isValidDate
           * @param date
           * @description Check if the date is valid or not
           */
          function isValidDate() {
            return isValidDay($ctrl.splittedTime.day) && isValidMonth($ctrl.splittedTime.month) && isValidYear($ctrl.splittedTime.year) && isValidHour($ctrl.splittedTime.hours) && isValidMinute($ctrl.splittedTime.minutes);
          }

          /**
           * @function
           * @name isValidMonth
           * @param month
           * @description Check if a month is valid
           * @returns {Number|boolean}
           */
          function isValidMonth(month) {
            return parseInt(month) && month <= 12;
          }

          /**
           * @function
           * @name isValidDay
           * @param day
           * @description Check if a day is valid
           * @returns {Number|boolean}
           */
          function isValidDay(day) {
            return parseInt(day) && day <= 31;
          }

          /**
           * @function
           * @name isValidYear
           * @param year
           * @description Check if a number is valid year
           * @returns {Number|boolean}
           */
          function isValidYear(year) {
            return parseInt(year) && year > 2017 && year < 9999;
          }

          /**
           * @function
           * @name isValidHour
           * @param h
           * @description Check if a number is valid hour
           * @returns {Number|boolean}
           */
          function isValidHour(h) {
            return parseInt(h) && h <= 23;
          }

          /**
           * @function
           * @name isValidMinute
           * @param m
           * @description Check if a number is valid minute
           * @returns {Number|boolean}
           */
          function isValidMinute(m) {
            return parseInt(m) && m <= 59 || m == '00';
          }
        }
      //end of Controller
    });
})();

(function () {
  'use strict';

  angular.module('tallyfy')
    .component('koFormCreator', {
      templateUrl: 'app/modules/steps/koFormCreator/ko-form-creator.component.html',
      bindings: {
        process: '<',
        isPublic: '<',
        isDropDownOpen: '=?'
      },
      controller:
        /*@ngInject */
        function (_, $rootScope, StepService, ProcessService, FieldService, blockUI, Growl, $filter, Helper) {
          var $ctrl = this,
            firstSort = true,
            growl = new Growl(),
            tempProcess,
            blockUI = blockUI.instances.get('koFormFieldBlockUI');

          $ctrl.$onInit = onInit;
          $ctrl.onDestroy = onDestroy;

          $ctrl.newField = newField;
          $ctrl.deleteField = deleteField;
          $ctrl.deleteFieldOption = deleteFieldOption;
          $ctrl.deleteFieldColumn = deleteFieldColumn;
          $ctrl.fieldUpdate = fieldUpdate;
          $ctrl.formFields = FieldService.getFields();

          function onInit() {
            tempProcess = angular.copy($ctrl.process);
            $ctrl.sortableOptions = {
              handle: '.move-capture',
              axis: 'y',
              stop: sortHandler,
              start: startHandler,
              placeholder: 'ui-sortable-placeholder'
            };
            showFormFieldInvalidMessage();
          }

          function onDestroy() { }

          /**
           * @name startHandler
           * @param {*} event 
           * @param {*} ui 
           * @description
           * handling when step is start dragging
           */
          function startHandler(event, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @function
           * @name sortHandler
           * @param {*} event
           * @param {*} ui
           * @description 
           * A handler function to sort the template kick-off form field
           */
          function sortHandler(event, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.process.prerun.length) {
              reOrderKickOffField();
              validKickOffField();
            }
          }

          /**
           * @function
           * @name reOrderKickOffField
           * @description reorder the template kick-off form field
           */
          function reOrderKickOffField() {
            _.forEach($ctrl.process.prerun, function (prerun, key) {
              prerun.position = key + 1;
            });
          }

          /**
           * @ngdoc method
           * @name newField
           * @description add new field 
           */
          function newField(type) {
            $ctrl.fieldModel = type;
            collapseAll();
            if (!$ctrl.fieldModel) {
              return;
            }
            var newField = getNewField();
            newField.isExpanded = true;
            $ctrl.process.prerun.push(newField);
            $ctrl.fieldModel = null;
          }

          /**
           * @function
           * @name collapseAll
           * @description Helper function to collapse all field accordion
           */
          function collapseAll() {
            _.forEach($ctrl.process.prerun, function (field) {
              if (field.isExpanded && !FieldService.validateCaptures(field)) {
                delete field.isExpanded;
              }
            });
          }

          /**
           * @ngdoc method
           * @name showFormFieldInvalidMessage
           * @description 
           * Showing static grow alert message for template invalid form fields
           */
          function showFormFieldInvalidMessage() {
            growl.error($filter('translate')('template.title.formFieldMessage'), {
              referenceId: 'inValidKickOff',
              disableIcons: true,
              disableCloseButton: true,
              ttl: -1
            });
          }

          /**
           * @ngdoc method
           * @name getNewField
           * @description Get new field object
           * @returns {Object} field
           */
          function getNewField() {
            if (!Helper.checkAccessAuthority())
              return;
            var field, lastField, newId,
              defaultProperties = {
                isNew: true,
                label: null,
                guidance: null
              };
            field = angular.copy($ctrl.fieldModel);
            newId = Helper.getId();
            lastField = _.maxBy($ctrl.process.prerun, 'position');
            field.position = (lastField ? lastField.position : 0) + 1;
            field.id = newId;
            field.slug = newId;
            angular.extend(field, defaultProperties);
            if (!field.title) {
              field.title = field.name + " - " + field.slug;
            }
            if (field.field_type === 'date') {
              field.collect_time = false;
            }
            return field;
          }

          /**
           * @ngdoc method
           * @name deleteField
           * @param {Object} field
           * @description 
           * Open the modal if prerun exits in the step rule
           */
          function deleteField(field, form) {
            var rule = [],
              index;
            rule = StepService.validateRule($ctrl.process, field);

            if (rule.length > 0) {
              StepService.openRuleModal(rule, 'capture');
            } else {
              index = _.findIndex($ctrl.process.prerun, {
                'id': field.id
              });
              $ctrl.process.prerun.splice(index, 1);
              if (!field.isNew) {
                deleteTemplatePrerun(field);
              }
              form.$setPristine();
              $ctrl.checkKOFRequired = _.filter($ctrl.process.prerun, function (step) {
                return step.required === true;
              });
              $rootScope.$emit('INBOUND_EMAIL_UNAVAILABLE:UPDATE', {
                status: $ctrl.checkKOFRequired.length >= 1
              });
              $rootScope.$emit('KO_FIELD:UPDATED', { process: $ctrl.process });
            }
          }

          /**
           * @ngdoc method
           * @private
           * @param {*} source
           * @param {*} destination 
           * @param {*} item 
           * 
           * @description
           * placing and mapping new prerun item to save
           */
          function placingPrerun(source, destination, item) {
            var filteredSource = _.filter(source, function (value) {
              return !value.isNew || (value.id === item.id);
            });
            var itemIndex = _.findIndex(filteredSource, { id: item.id });
            var appendPosition = filteredSource.length - (filteredSource.length - itemIndex);
            item = _.omit(item, ['id', 'isNew', 'isExpanded']);
            destination.splice(appendPosition, 0, item);
            for (var i = 0; i < destination.length; i++) {
              destination[i].position = i;
            }
            return appendPosition;
          }

          /**
           * @ngdoc method
           * @name fieldUpdate
           * @param {Object} field
           * @param {Object} form
           * @description 
           * Update prerun field 
           */
          function fieldUpdate(field, form) {
            if (!field.isFFSave) {
              return;
            }
            var index = _.findIndex($ctrl.process.prerun, {
              'id': field.id
            });
            var process = angular.copy($ctrl.process);
            process.prerun = tempProcess.prerun;
            var appendPosition;
            if (field.isNew) {
              appendPosition = placingPrerun($ctrl.process.prerun, process.prerun, field);
            } else {
              process.prerun[index] = _.omit(field, ['isFFSave']);
            }
            beforeStepPrerunUpdate(process.prerun);            
            if (!process.auto_naming) process = _.omit(process, ['allow_launcher_change_name']);
            $ctrl.onSaving = true;
            ProcessService.update({
              id: $ctrl.process.id
            }, process).then(function (response) {
              field.isFFSave = false;
              $ctrl.process.prerun[index] = _.omit($ctrl.process.prerun[index], ['isNew']);
              process.prerun = _.get(response, 'data.prerun');
              var editedPrerun = $ctrl.process.prerun[index],
                newPrerunValue = typeof appendPosition !== 'undefined' ? process.prerun[appendPosition] : _.find(process.prerun, { id: editedPrerun.id });
              angular.extend(editedPrerun, _.omit(newPrerunValue, ['position', 'required']));
              angular.extend(field, _.omit(editedPrerun, ['options']));
              tempProcess.prerun = angular.copy(process.prerun);
              if (form) {
                form.$setPristine();
              }
              $ctrl.process.last_updated = _.get(response, 'data.last_updated');
              $rootScope.$emit("PROCESS:UPDATED", {
                process: $ctrl.process,
                editedPrerun: editedPrerun
              });
              $rootScope.$emit('STEP:UPDATE_STEP_AND_PRERUN');
              Helper.showChangesSavedGrowl();
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name beforeStepPrerunUpdate
           * @description Delete some properties when template being save
           * @returns void
           */
          function beforeStepPrerunUpdate(preruns) {
            _.forEach(preruns || [], function (prerun) {
              if (prerun.isNew) {
                delete prerun.isNew;
                delete prerun.id;
              }
              prerun.name = prerun.label;
              delete prerun.slug;
            });
          }

          /**
           * @ngdoc method
           * @name deleteTemplatePrerun
           * @param {Object} field
           * @description 
           * Delete prerun field 
           */
          function deleteTemplatePrerun(field) {
            blockUI.start();
            ProcessService.deleteProcessPrerun({
              id: $ctrl.process.id,
              action: 'preruns',
              action_id: field.id
            }).then(function () {
              _.remove(tempProcess.prerun, { id: field.id });
              $rootScope.$emit("PROCESS:UPDATED", {
                process: $ctrl.process
              });
              Helper.showChangesSavedGrowl();
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name isSaveCaptures
           * @description Return if kick-off form field not save
           */
          function validKickOffField() {
            var isFieldSaved = true;
            _.forEach($ctrl.process.prerun, function (prerun) {
              if (!_.has(prerun, 'checklist_id')) {
                isFieldSaved = false;
                return false;
              }
            });
            if (isFieldSaved) {
              changedFieldPosition();
            }
          }

          /**
           * @ngdoc method
           * @name changedFieldPosition
           * @description Update field position
           * @returns void
           */
          function changedFieldPosition() {
            blockUI.start();
            ProcessService.update({
              id: $ctrl.process.id
            }, $ctrl.process).then(function (response) {
              showSuccessContextAlert($filter('translate')('template.title.kickOffPosition'));
              $rootScope.$emit("PROCESS:UPDATED", {
                process: response.data
              });
              tempProcess.prerun = angular.copy(response.data.prerun);
              blockUI.stop();
            }, function () {
              showErrorContextAlert($filter('translate')('template.title.notChangePosition'));
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name showSuccessContextAlert
           * @param {String} message
           * @description Showing success global growl message
           * @returns void
           */
          function showSuccessContextAlert(message) {
            growl.success(message, {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdoc method
           * @name showErrorContextAlert
           * @param {String} message
           * @description Showing error global growl message
           * @returns void
           */
          function showErrorContextAlert(message) {
            growl.error(message, {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @function
           * @name deleteFieldOption
           * @param {Object} field
           * @param {Object} option 
           * @description Delete option from multiple choice/select etc. field
           * @returns void
           */
          function deleteFieldOption(field, option) {
            var fieldIndex, optionIndex;
            fieldIndex = _.findIndex($ctrl.process.prerun, { id: field.id });
            optionIndex = _.findIndex($ctrl.process.prerun[fieldIndex].options, { id: option.id });
            $ctrl.process.prerun[fieldIndex].options.splice(optionIndex, 1);
          }

          function deleteFieldColumn(field, column) {
            var fieldIndex, columnIndex;
            fieldIndex = _.findIndex($ctrl.process.prerun, { id: field.id });
            columnIndex = _.findIndex($ctrl.process.prerun[fieldIndex].columns, { id: column.id });
            $ctrl.process.prerun[fieldIndex].columns.splice(columnIndex, 1);
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.taskThread
 * @module tallyfy.tasks
 *
 * @description
 * taskThread component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.messages')
    .component('taskThread', {
      bindings: {
        task: '<',
        step: '<',
        taskStatus: '=',
        item: '=',
        threads: '=',
        editCommentId: '=',
        onSave: '&',
        onEdit: '&',
        onDelete: '&',
        onDiscard: '&',
        users: '=',
        groups: '=',
        isHighlighted: '<',
        isGuest: '<',
        hideReaction: '<',
        asPreview: '<?',
        editDisabled: '<?',
        iAmMentioned: '<?',
        onCreateTask: '&'
      },
      templateUrl: ['$attrs', function ($attrs) {
        var layoutType = $attrs.layout || 'issue';
        return 'app/modules/messages/thread/' + layoutType.toLowerCase() + '-messages-thread.html';
      }],
      controller:
        /*@ngInject*/
        function ($rootScope, $scope, $filter, TasksService, _, Helper, MessagesService, FroalaService, MESSAGE_TYPE, UsersService, CompactTaskService, OrganizationsService) {
          var $ctrl = this,
            oldComment = '',
            unregisterMessageThreadListener,
            unregisterIdListener;

          /**
           * angularjs lifecycle hook methods
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.discardChanges = discardChanges;
          $ctrl.saveComments = saveComments;
          $ctrl.editComments = editComments;
          $ctrl.editCommentState = editCommentState;
          $ctrl.selectTag = selectTag;
          $ctrl.editCommentType = editCommentType;
          $ctrl.isActiveUserAssign = isActiveUserAssign;
          $ctrl.commentTypesList = commentTypesList;
          $ctrl.resolveIssue = resolveIssue;
          $ctrl.createNewTask = createNewTask;

          /**
           * public properties
           */
          $ctrl.allowUserToEdit = false;
          $ctrl.messageForm = {};
          $ctrl.message = {};
          $ctrl.isDisableButton = true;
          $ctrl.resolverThread = {};
          $ctrl.hiddenFromGuestsState = MESSAGE_TYPE.HIDE_FOR_GUESTS;
          $ctrl.collapsedState = MESSAGE_TYPE.COLLAPSED;
          $ctrl.openAllMemberState = MESSAGE_TYPE.OPEN;
          $ctrl.issueReport = false;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            var author = _.get($ctrl.item, 'author', {});
            $ctrl.allowUserToEdit = (($ctrl.isGuest && $ctrl.item.guest) || _.get($rootScope, 'identity.id', '') === author.id);
            $ctrl.collaborators = _.get($ctrl.task, 'run.data.collaborators', []);
            $ctrl.resolver = getResolver();
            $ctrl.currentUser = $rootScope.identity;
            reInitFroala();
            $ctrl.item.content = prepareEditorData();
            oldComment = angular.copy($ctrl.item.content);
            prepareResolver();
            if($ctrl.task) {
              resetCommentForm();
            }
          }
          
          function reInitFroala() {
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              isGuest: $ctrl.isGuest,
              placeholder: $filter('translate')($ctrl.task ? 'messages.placeholder' : 'messages.bpPlaceholder'),
              currentUser: $ctrl.currentUser,
              mentionData: prepareMentionData(),
              limit: 30
            }, true);
            $ctrl.froalaOptions.enter = FroalaEditor.ENTER_BR;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterMessageThreadListener();
            unregisterIdListener();
            unRegisteredThreadsChangesHandler();
          }

          /**
           * @ngmethod
           * private method
           * @name getResolver
           * @return resolver object
           * 
           * @description
           * get thread's resolver
           */
          function getResolver() {
            if (!$ctrl.item.resolve_id) return {};
            return _.find($ctrl.threads, {
              id: $ctrl.item.resolve_id
            }) || {};
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           */
          function defaultAvatarText() {
            var author = $ctrl.item.author || $ctrl.item.guest;
            var firstName = $ctrl.isGuest ? (author.full_name || author.email).split(' ')[0] : (author.first_name || author.email),
              lastName = $ctrl.isGuest ? (author.full_name || '').split(' ')[1] : author.last_name;
            return TasksService.setDefaultAvatarText(firstName, lastName);
          }

          /**
           * @function
           * @name discardChanges
           * @description Discard the comments changes
           */
          function discardChanges(force) {
            $ctrl.item.content = angular.copy(oldComment);
            if (!force) {
              $ctrl.editCommentId = void 0;
              $ctrl.message = {};
              $ctrl.isDisableButton = true;
            }
          }

          /**
           * @function
           * @name saveComments
           * @description save comments
           * @param {*} objComment
           */
          function saveComments(objComment) {
            var comment = angular.copy(objComment);
            if($ctrl.message.type) {
              comment.label = $ctrl.messageType;
            } else {
              comment.label = _.get($ctrl.selectedTagType, 'label');
            }
            $ctrl.messageForm.$submitted = true;
            comment.content = FroalaService.getRawContent(comment.content);
            if (!comment.content) {
              $ctrl.messageForm.message.$invalid = true;
              $ctrl.messageForm.message.$valid = false;
              return;
            }
            if (comment.label) {
              switch (comment.label) {
                case MESSAGE_TYPE.COMMENT.toLowerCase():
                  editCommentType(objComment, comment.label);
                  break;
                case MESSAGE_TYPE.ADVICE.toLowerCase():
                  editCommentType(objComment, comment.label);
                  break;
                case MESSAGE_TYPE.IMPROVEMENT.toLowerCase():
                  editCommentType(objComment, comment.label);
                  break;
                case MESSAGE_TYPE.RESOLVE.toLowerCase():
                  resolveIssue(objComment);
                  break;
                case MESSAGE_TYPE.REPORT_COMMENT.toLowerCase():
                  if ($ctrl.taskStatus !== 'completed') {
                    sendReport(objComment, $ctrl.isGuest);
                  }
                  else {
                    sendComment(objComment, $ctrl.isGuest);
                  }
                  break;
              }
            } else {
              sendComment(objComment, $ctrl.isGuest);
            }
          }

          function sendReport(objComment, isGuest) {
            var comment = angular.copy(objComment);
            comment.label = 'problem';
            comment.content = FroalaService.getRawContent(comment.content);
            $ctrl.onSaving = true;
            MessagesService.commentUpdate(comment, isGuest).then(function (response) {
              $ctrl.editCommentId = void 0;
              $ctrl.isDisableButton = true;
              oldComment = prepareEditorData(_.get(response, 'data.content'));
              var updatedThread = _.find($ctrl.threads, { id: $ctrl.item.id });
              _.extend(updatedThread, response.data);
              if (_.get(response.data,'label') === "problem") {
                angular.extend(response.data, {
                  hasIssues: true
                });  
              }
              $rootScope.$emit('ISSUE:REPORTED', {
                'issue': updatedThread
              });
              $rootScope.$emit('VIEW:ISSUE_STATUS_UPDATE', { 'issue': response.data  });
              $ctrl.messageForm.$submitted = false;
              $ctrl.message = {};
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function resolveIssue(objComment) {
            var comment = angular.copy(objComment);
            var issues = TasksService.getIssueWithUnresolvedProblem($ctrl.threads);
            if(issues.length > 0) {
              comment.thread_id = issues.length === 0 && $ctrl.isGuest ?
                _.get($ctrl.task, 'issue_id')
                : issues[0].id;
              comment.label = 'resolve';
            } else {
              comment.label = 'comment';
            }
            comment.content = FroalaService.getRawContent(comment.content);
            $ctrl.onSaving = true;
            MessagesService.commentUpdate(comment, $ctrl.isGuest).then(function (response) {
              $ctrl.editCommentId = void 0;
              $ctrl.isDisableButton = true;
              oldComment = prepareEditorData(_.get(response, 'data.content'));
              _.extend(response.data, { problem: comment.label === 'resolve' ? false : true, hasIssues: comment.label === 'resolve' ? false : true });
              var updatedThread = _.find($ctrl.threads, { id: $ctrl.item.id });
              _.extend(updatedThread, response.data);
              if (issues.length > 0) {
                var resolvedThread = _.find($ctrl.threads, { 'id': issues[0].id });
                resolvedThread.resolve_id = updatedThread.id;
              }
              $rootScope.$emit('ISSUE:RESOLVED', {
                'issue': updatedThread
              });
              $rootScope.$emit('VIEW:ISSUE_STATUS_UPDATE', { 'issue': response.data });
              $ctrl.messageForm.$submitted = false;
              $ctrl.message = {};
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function sendComment(objComment, isGuest) {
            var comment = angular.copy(objComment);
            comment.content = FroalaService.getRawContent(comment.content);
            $ctrl.onSaving = true;
            MessagesService.commentUpdate(comment, isGuest, $ctrl.step).then(function (response) {
              $ctrl.editCommentId = void 0;
              $ctrl.isDisableButton = true;
              oldComment = prepareEditorData(_.get(response, 'data.content'));
              _.extend(_.find($ctrl.threads, { id: response.data.id }), response.data);
              $ctrl.messageForm.$submitted = false;
              $ctrl.message = {};
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @function
           * @name editComments
           * @description Edit comments
           * @param {*} comment
           */
          function editComments(comment) {
            reInitFroala();
            $ctrl.isHasIssue = $ctrl.task ? CompactTaskService.isHasIssue($ctrl.task) : angular.noop();
            $ctrl.label = comment.label;
            if (comment.label === 'comment') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, !$ctrl.isNone, !$ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (comment.label === 'improvement' || comment.label === 'advice') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (comment.label === 'report' || comment.label === 'resolve') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isNone, !$ctrl.isImproveAdvice, !$ctrl.isReportResolve);
            } else if (comment.label === 'problem') {
              $ctrl.label = 'report';
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isNone, !$ctrl.isImproveAdvice, !$ctrl.isReportResolve);
            }
            $ctrl.selectedTagType = _.find($ctrl.commentTypes, { label: $ctrl.label });
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.editCommentId = comment.id;
            $ctrl.actionEdited = false;
            $scope.$emit("TASK:EDIT_MESSAGE", { editComment: $ctrl.editCommentId });
          }

          function prepareEditorData(itemContent) {
            var content = itemContent ? itemContent : $ctrl.item.content;
            return FroalaService.getViewContent(content, _.concat($ctrl.users, $ctrl.groups) || $ctrl.item.mentioned_users);
          }

          //watchinng for $ctrl.threads if value was changed
          var unRegisteredThreadsChangesHandler = $scope.$watch('$ctrl.threads', function (value) {
            var newItem = _.find(value, { id: $ctrl.item.id });
            var string = newItem.content;
            var reopen = MESSAGE_TYPE.REOPEN_COMMENT;
            if (_.includes(string, String(reopen))) {
              newItem.sublabel = 'reopened';
              newItem.content = _.replace(string, String(reopen), '');
            }
            newItem.content = prepareEditorData(newItem.content);
            angular.extend($ctrl.item, newItem);
            $ctrl.resolver = getResolver();
            prepareResolver();
          }, true);

          /**
           * Un-register listener on taks message
           * @type {*|(function())}
           */
          unregisterMessageThreadListener = $rootScope.$on('TASK:MESSAGES_THREAD', function () {
            $ctrl.editCommentId = undefined;
          });

          /**
           * unregister id listener
           * @type {*|(function())}
           */
          unregisterIdListener = $scope.$watch('$ctrl.editCommentId', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              discardChanges(true);
            }
          });

          /**
           * @ngdoc method
           * @name prepareResolver
           * @description
           * Set global model based on the issues
           * @returns void
           */
          function prepareResolver() {
            $ctrl.unResolvedIssues = TasksService.getIssueWithUnresolvedProblem($ctrl.threads);
            $ctrl.messageType = MESSAGE_TYPE.REPORT_COMMENT.toLowerCase();
            $ctrl.isResolver = ($ctrl.unResolvedIssues.length === 0 && $ctrl.isGuest) ?
              !_.isEmpty(_.get($ctrl.task, 'issue_id'))
              : $ctrl.unResolvedIssues.length > 0;
            if ($ctrl.isResolver) {
              $ctrl.messageType = MESSAGE_TYPE.RESOLVE.toLowerCase();
            }
            $ctrl.resolverThread = _.find($ctrl.threads, { resolve_id: $ctrl.item.id });
          }

          /**
           * @ngdoc method
           * @name prepareMentionData
           * @description
           * prepare at mention user data
           * @returns Object
           */
          function prepareMentionData() {
            return MessagesService.getMentionData($ctrl.threads, $ctrl.currentUser, UsersService.usersWithoutBot($ctrl.users), $ctrl.task);
          }
          /**
           * @ngdoc method
           * @name editCommentState
           * @description Edit comment state
           * @param {Object} comment
           * @param {string} state
           */
          function editCommentState(comment, state) {
            comment.state = angular.copy(state);
            var editComment = angular.copy(comment);
            if (_.get(editComment, 'sublabel')) {
              var reopenComment = MESSAGE_TYPE.REOPEN_COMMENT + editComment.content;
              _.set(editComment, 'content', reopenComment);
            }
            MessagesService.commentUpdate(editComment, $ctrl.isGuest, $ctrl.step).then(function (response) {
              oldComment = prepareEditorData(_.get(response, 'data.content'));
              _.extend(_.find($ctrl.threads, { id: response.data.id }), response.data);
            });
          }

          /**
           * @ngdoc method
           * @name editCommentType
           * @description Edit comment Type
           * @param {Object} comment
           * @param {string} type
           */
          function editCommentType(comment, type) {
            comment.label = type;
            comment.content = FroalaService.getRawContent(comment.content);
            var editComment = angular.copy(comment);
            MessagesService.commentUpdate(editComment, $ctrl.isGuest, $ctrl.step).then(function (response) {
              oldComment = prepareEditorData(_.get(response, 'data.content'));
              var updatedThread = _.find($ctrl.threads, { id: $ctrl.item.id });
              _.extend(updatedThread, response.data);
              $rootScope.$emit('ISSUE:RESOLVED', {
                'issue': updatedThread
              });
              discardChanges();
            });
          }

          function resetCommentForm() {
            $ctrl.typeChecked = false;
            $ctrl.selectedTagType = _.find($ctrl.commentTypes, { label: 'comment' });
            $ctrl.selectedVisibilityType = _.find($ctrl.visibilityTypes, { label: 'hideFromGuest' });
          }

          function selectTag(type) {
            $ctrl.selectedTagType = type;
          }

          /**
           * @ngdoc method
           * @name isActiveUserAssign
           * @description get user list
           */
          function isActiveUserAssign() {
            if (_.get($ctrl.currentUser, 'id')) {
              var owners = _.get($ctrl.task, 'owners');
              if (_.get(owners, 'groups',[]).length > 0) {
                var list = _.filter(_.get(owners, 'groups', []), function (group) {
                  if (_.get(group, 'users', []).length > 0) {
                    return _.includes(_.get(group, 'users'), _.get($ctrl.currentUser, 'id'));
                  }
                });
                if(list.length > 0) {
                   return true;
                }
              }
              if (_.get(owners, 'users', []).length > 0) {
                return _.includes(_.get(owners, 'users'), _.get($ctrl.currentUser, 'id')) || false;
              }
            }
            return _.get($ctrl.currentUser, 'guest.email');
          }

          /**
           * @ngdoc method
           * @name commentTypesList
           * @description
           * Listing for comment types
           * @param  {*} comment type
           * @returns {*}
          */
          function commentTypesList(type) {
            $ctrl.actionEdited = true;
            if (type.label === 'comment') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, !$ctrl.isNone, !$ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (type.label === 'improvement' || type.label === 'advice') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (type.label === 'resolve') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isNone, !$ctrl.isImproveAdvice, !$ctrl.isReportResolve);
            } else if (type.label === 'report') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES(!$ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isNone, !$ctrl.isImproveAdvice, !$ctrl.isReportResolve);
            }
          }

          /**
           * @ngdoc method
           * @name createNewTask
           * @description Create new task for comment
           */
          function createNewTask(data){
            TasksService.standaloneModal(_.get($ctrl.task, 'run_id'), '', data);
          }
        }
    });
})();

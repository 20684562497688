/**
 * @ngdoc provider
 * @name $language
 * @module tallyfy.config
 * @description
 * provider type service serves language utils.
 * Inject $languageProvider in config
 * Inject $language in run, controller, directive etc.
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.config')
    .provider('$language', /*@ngInject*/function lProvider(TFY_LOCALE, _) {
      var LANGUAGES = TFY_LOCALE.locales;
      this.hasLang = function (l) {
        var flag = false;
        for (var i = 0; i < LANGUAGES.length; i++) {
          var e = LANGUAGES[i];
          if (_.startsWith(l, e)) {
            flag = true;
            break;
          }
        }
        return flag;
      };
      this.getClientLang = function () {
        var lang =  window.navigator.languages && window.navigator.languages[0] ||
          window.navigator.language || window.navigator.userLanguage;
          return _.toLower(lang);
      };
      /**
       * $get is a factory method which is available to controller, directive and services.
       */
      this.$get = /*@ngInject*/function (TFY_LOCALE, _, $translate, $q) {
        var LANGUAGES = TFY_LOCALE.locales;
        lProvider.prototype = {
          getAll: function () {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
          },
          getCurrent: function () {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
            deferred.resolve(language);
            return deferred.promise;
          }
        };
        return new lProvider(TFY_LOCALE, _);
      };
    })

})();

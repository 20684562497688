/**
 * @ngdoc component
 * @name tallyfy.compactProcessDescriptionSnippetDetail
 * @restrict 'A'
 * @description Used to view snippet assigned to Guest onboarding
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactProcessDescriptionSnippetDetail', {
      templateUrl: 'app/components/compact/components/processDescriptionStepSnippetDetail/compact-process-description-step-snippet-detail.component.html',
      bindings: {
        process: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $q, ProcessService, Helper, BLUEPRINT_TYPE, $timeout, DESCRIPTIONSIZE, COMMON) {
          // private properties
          var $ctrl = this;

          // angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.maxLength = DESCRIPTIONSIZE.maxTitle;
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;
          
          $ctrl.onPaneClose = onPaneClose;

          /**
           * @ngdoc methods
           * @name onInit
           * 
           * @description
           * angular life cycle hook on component init
           */
          function onInit() {
          }

          function onChanges() { }

          function onDestroy() { }
          
          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e 
           * 
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            $rootScope.$emit('RIGHT_PANE:CLOSE', {
              event: e
            });
          }
        }
    });
})();
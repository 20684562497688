/**
 * @ngdoc overview
 * @name tallyfy.files
 *
 * @module tallyfy.files
 *
 * @description
 * manage files
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.files', []);
})();

/**
 * @ngdoc component
 * @name tallyfy.emptyTask
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('emptyTask', {
      templateUrl: 'app/components/compact/items/emptyTask/empty-task.component.html',
      bindings: {
        isVisible: '<',
        isGuest: '<?',
        runId: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, TasksService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;

          $ctrl.openStandaloneModal = openStandaloneModal;

          function onInit() { }

          /**
           * @ngdoc method
           * @name openStandaloneModal
           * 
           * @description
           * open standalone modal
           */
          function openStandaloneModal() {
            TasksService.standaloneModal($ctrl.runId);
          }

          if ($ctrl.isGuest) {
            $rootScope.$watch('identity.guest.organization.name', function (value) {
              $ctrl.orgName = value;
            }, true);
          }
        }
    });
})();
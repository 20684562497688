/**
 * @ngdoc component
 * @name tallyfy.compactSplashMessage
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactSplashMessage', {
      templateUrl: 'app/components/compact/items/splashMessage/splash-message.component.html',
      bindings: {
        splashMessage: '@'
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;
        }
    });
})();
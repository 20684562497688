/**
 * @ngdoc Component
 * @name tallyfy.process.component.blueprint
 * @module tallyfy.process
 *
 * @description
 * A component to manage blueprint folder breadcrumb
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('folderBreadcrumb', {
      templateUrl: 'app/modules/process/components/library/folderBreadcrumb/folder-breadcrumb.component.html',
      bindings: {
        breadcrumb: '<',
        isEditable: '<?',
        isLink: '<?',
        maxLevel: '<?',
        textColor: '<?',
        onClick: '&',
        onMoreClick: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $timeout, $rootScope, FolderService, $log, Helper, $scope) {
          var $ctrl = this, body = angular.element('body');
          $ctrl.showBreadcrumbDropdown = false;
          

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.goToFolder = goToFolder;
          $ctrl.editFolder = editFolder;
          $ctrl.onToggled = onToggled;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;

          function onInit() {
            body.on('click', handleBodyClick);
            $timeout(function() {
              $ctrl.child = _.get($ctrl.breadcrumb, 'parent.data', {});
            }, 0);
            if (!$ctrl.maxLevel) {
              $ctrl.maxLevel = 2;
            }
            $ctrl.dropdownOptions = [];
            $ctrl.isDropdownOpen = false;
            prepareBreadcrumbsDropdown($ctrl.breadcrumb);
          }

          function onChanges() { }

          function onDestroy() {
            body.off('click', handleBodyClick)
          }

          function goToFolder(folder, $event) {
            if ($ctrl.maxLevel <= 2) {
              $ctrl.onClick({ e: $event, folder: folder });
            }
          }

          /**
           * @ngdoc method
           * @name editFolder
           * @param {*} folder
           * @description
           * edit folder
           */
          function editFolder(folder) {
            if (!$ctrl.haveAuthority())
              return;

            var editModal = FolderService.openFolderModal(folder, folder.id, 'checklist');
            editModal.result.then(function (response) {
              $rootScope.$emit('FOLDER:EDIT_NAME', { folder: response.data });
              $log.debug('folder saved', response);
            });
          }

          function prepareBreadcrumbsDropdown (breadcrumb) {
            if (breadcrumb && (breadcrumb.level > $ctrl.maxLevel)) {
              $ctrl.dropdownOptions.push(breadcrumb);
            }
            if (breadcrumb && breadcrumb.parent) {
              prepareBreadcrumbsDropdown(breadcrumb.parent.data); 
            } else {
              $ctrl.dropdownOptions = _.orderBy($ctrl.dropdownOptions, ['level'], ['desc']);
            }
          }

          // Toggle dropdown
          function onToggled($event) {
            $event.stopPropagation();
            $ctrl.isDropdownOpen = !$ctrl.isDropdownOpen;
            $ctrl.onMoreClick({ isOpen: $ctrl.isDropdownOpen });
          }

          // Handle body click
          function handleBodyClick(e) {
            if ($ctrl.isDropdownOpen) {
              var clickedElement = angular.element(e.target), isInMenu = clickedElement.closest('.folder-breadcrumb-component').length;
              if (!isInMenu) {
                onToggled(e);
                $scope.$apply();
              }
            }
          }
        }
    });
})();
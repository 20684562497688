/**
 * @ngdoc Component
 * @name tallyfy.organizations.engageCustomer
 * @module tallyfy.organizations
 *
 * @description
 * A component to engage a customer (videoAsk etc.)
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('engageCustomer', {
      templateUrl: 'app/modules/interact/engageCustomer/engageCustomer.html',
      controller:
      /*@ngInject*/
        function ($state, $window, $timeout, _, $scope) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.disableBack = $state.params.dback;
            if ($ctrl.disableBack){
              setPopStateListener();
            }
            setVideoaskListener();
          }

          /**
           * @function
           * @name onDestroy
           * @description destroy event handler
           */
          function onDestroy() {
            $timeout(function () {
              $window.removeEventListener('popstate', videoaskPopStateListener);
            }, 1000);
            $window.removeEventListener('message', videoaskEventListener);
          }

          /**
           * @function
           * @name videoaskPopStateListener
           * @description fire when pop state
           */
          function videoaskPopStateListener() {
            $window.history.forward(1);
          }

          /**
           * @function
           * @name setPopStateListener
           * @description Set popstate event
           */
          function setPopStateListener() {
            $window.addEventListener('popstate', videoaskPopStateListener);
          }

          /**
           * @function
           * @name setVideoaskListener
           * @description Set Videoask listener
           */
          function setVideoaskListener() {
            $window.addEventListener('message', videoaskEventListener);
          }

          /**
           * @function
           * @name isVideoaskEvent
           * @description Check whether the emitted event is from Videoask
           */
          function isVideoaskEvent(message) {
            return (message.origin === 'https://ask.tallyfy.com' &&
              message.data &&
              message.data.type &&
              message.data.type.startsWith('videoask_'));
          }

          /**
           * @function
           * @name videoaskEventListener
           * @description Videoask message event listener
           */
          function videoaskEventListener(message) {
            if (isVideoaskEvent(message) && (_.get(message, 'data.type') === 'videoask_submitted')) {
              $timeout(function () {
                $state.go('authHandler');
                $scope.$apply();
              }, 3000);
            }
          }
        }
    });
})();
(function () {
	'use strict';
	angular.module('tallyfy')
		.directive('shadowElement', shadowElement);

	/**@ngInject*/
	function shadowElement($timeout) {
		return {
			restrict: 'A',
			scope: {
				viewPortElement: '<',
				parentElement: '<',
				shadowElements: '<',
				topOffset: '<'
			},
			link: function ($scope, $element) {
				var viewPortElement;

				function isElementInViewPort(el) {
					var viewportRect = viewPortElement[0].getBoundingClientRect(), elRect = el.getBoundingClientRect();
					return elRect.top >  ($scope.topOffset || 56) && viewportRect.height >= elRect.top - ($scope.topOffset || 56);
				}

				function scrollCallback() {
					var shadowElements = document.querySelectorAll($scope.shadowElements),
						parentElement = angular.element($scope.parentElement),
						isInViewPort = false;
					for (var i = 0; i < shadowElements.length; i++) {
						isInViewPort = isElementInViewPort(shadowElements[i]);
						if (isInViewPort) {
							break;
						}
					}
					$element.css('display', isInViewPort ? 'none' : 'block');
					if (isInViewPort) {
						$element.removeClass('active');
					} else {
						$element.addClass('active');
					}
					if (parentElement && parentElement.length) {
						$element.css('width', parentElement[0].clientWidth + 'px');
					}
				}

				function throttle(fn, wait) {
					var time = Date.now();
					return function () {
						if ((time + wait - Date.now()) < 0) {
							fn();
							time = Date.now();
						}
					};
				}

				function init() {
					$element.css('display', 'none');
					$timeout(function () {
						viewPortElement = angular.element($scope.viewPortElement);
						if (viewPortElement.length) {
							viewPortElement[0].addEventListener('scroll', throttle(scrollCallback, 10));
						}
					}, 1500).then(function () {
						scrollCallback();
					}, 100);
				}

				$scope.$watch('viewPortElement', function (value) {
					if (value) {
						init();
					}
				});

				$scope.$on('$destroy', function () {
					if (viewPortElement && viewPortElement.length) {
						viewPortElement[0].removeEventListener('scroll', throttle(scrollCallback, 10));
					}
				});
			}
		}
	}
})();
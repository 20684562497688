/**
 * @ngdoc Config
 * @name tallyfy.integration.config.LDapStateConfig
 * @module tallyfy.integration
 *
 * @description
 * set route integration Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .config(LDapStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  LDapStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * LDapStateConfig construct 
   */
  function LDapStateConfig($stateProvider) {
   
  }
})();
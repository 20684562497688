(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('cutOffElement', cutOffElement);

  function cutOffElement($timeout) {
    return {
      restrict: 'A',
      scope: {
        hiddenElements: '=',
        offsetSize: '<'
      },
      link: function ($scope, $element, $attributes) {
        var asyncTimeoutHandler;
        var parent = angular.element($attributes.cutOffElement);
        if (!parent.length) {
          return;
        }
        $element.css('width', 'fit-content');
        var parentClientRect = parent.get(0).getBoundingClientRect();
        asyncTimeoutHandler = $timeout(function () {
          function cutElement() {
            var elementClientRect = $element.get(0).getBoundingClientRect();
            if (parentClientRect.width > elementClientRect.width + $scope.offsetSize) {
              return;
            } else {
              var elementChild = $element.children();
              elementChild.get(0).parentNode.removeChild(elementChild.get(0));
              if (!$scope.hiddenElements) {
                $scope.hiddenElements = 0;
              }
              $scope.hiddenElements++;
              cutElement();
            }
          }
          cutElement();
        }, 0);
      }
    }
  }
})();

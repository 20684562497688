(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('ListTableService', ListTableService);

  function ListTableService(_, $filter) {
    var self = this;

    self.getOptions = function () {
      return {
        columns: getColumns(),
        dataSource: new kendo.data.DataSource({
          pageSize: 10
        }),
        noRecords: {
          template: kendo.template($('#listTableNoData').html())
        },
        resizable: true
      };
    };

    // guest table columns
    var getColumns = function () {
      return [{
        field: "title",
        exportKeyMap: {
          key: "title",
          label: $filter('translate')('list.label.title')
        },
        headerTemplate: kendo.template($('#listTableTitleColumnHeader').html()),
        template: kendo.template($('#listTableTitleColumn').html())
      }, {
        field: "type",
        exportKeyMap: {
          key: "type",
          label: $filter('translate')('list.label.type')
        },
        headerTemplate: kendo.template($('#listTableTypeColumnHeader').html()),
        template: kendo.template($('#listTableTypeColumn').html()),
        width: 200
      }, {
        field: "created_at",
        exportKeyMap: {
          key: "created_at",
          label: $filter('translate')('list.label.createdAt')
        },
        headerTemplate: kendo.template($('#listTableCreateAtColumnHeader').html()),
        template: kendo.template($('#listTableCreatedAtColumn').html()),
        width: 200
      }, {
        template: kendo.template($('#listTableActionColumn').html()),
        width: 240
      }];
    };

    return self;
  }

})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepTimeField', {
      bindings: {
        'time': '=',
        'unit': '=',
        'onSelect': '&',
        'isPublic': '<'
      },
      templateUrl: 'app/modules/steps/deadline/timeField/timeField.html',
      controller:
        /*@ngInject*/
        function (_, TimeFieldService, $rootScope, Helper, CONST) {
          var $ctrl = this,
            discardDeadlineEventHandler;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Public properties and method
           */
          $ctrl.updateTime = updateTime;
          $ctrl.onTimeUnitChange = onTimeUnitChange;
          $ctrl.positiveIntegerRegex = CONST.POSITIVE_INTEGER_REGEX;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.time = !$ctrl.time && $ctrl.time != 0 ? 5 : _.toNumber($ctrl.time);
            prepareTimeUnit();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { 
            discardDeadlineEventHandler();
          }

          /**
           * @ngdoc method
           * @name prepareTimeUnit
           * @description prepare deadline time unit
           * @returns {void}
           */
          function prepareTimeUnit() {
            $ctrl.timeUnits = TimeFieldService.getUnitOptions();
            $ctrl.timeUnit = ($ctrl.unit) ? getTimeUnit($ctrl.unit) : $ctrl.timeUnits[1];
          }

          /**
           * @ngdoc method
           * @name onTimeUnitChange
           * @description update deadline unit value
           * @returns {void}
           */
          function onTimeUnitChange(deadlineUnit) {
            if (deadlineUnit.title !== $ctrl.timeUnit.title) {
              $ctrl.timeUnit = angular.copy(deadlineUnit);
              $ctrl.unit = $ctrl.timeUnit.title;
              $ctrl.onSelect();
            }
          }

          function updateTime() {
            $ctrl.onSelect();
          }
          
          /**
           * event handler when changed step deadline
           * @type {*|(function())}
           */
          discardDeadlineEventHandler = $rootScope.$on('STEP:DEADLINE_CHANGED', function (event, step) {
            if (step.unit) {
              $ctrl.timeUnit = getTimeUnit(step.unit);
            }
            $ctrl.time = step.value;
          });
          
          /**
           * @ngdoc method
           * @name getTimeUnit
           * @description To get time unit
           * @param {*} stepUnit
           * @returns {*} timeUnit
           */
          function getTimeUnit(stepUnit) {
            return _.find($ctrl.timeUnits, function (item) {
              return item.title === stepUnit;
            });
          }
        }
    });
})();
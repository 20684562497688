/**
 * @ngdoc Directive
 * @name tallyfy.customColorPicker
 * @description custom color picker
 * @restrict 'E'
 * @element Element
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('customColorPicker', customColorPicker);

  /*@ngInject*/
  function customColorPicker($parse, customBrandingService) {
    var swatches = customBrandingService.getSwatches();
    return {
      restrict: "E",
      require: 'ngModel',
      link: function ($scope, element, attrs, ngModel) {
        angular.element(element).addClass('materialpicker');

        var selection = customBrandingService.getMultiDimensionalArray(14, 3),
          state = {
            mousedown: false,
            ignore: false,
            selected: { color: null, x: 0, y: 0 }
          },
          container = angular.element('<div>');

        container.addClass('materialpicker-colors');

        container.on('mousedown', function () {
          state.mousedown = true;
        });

        container.on('mouseup', function () {
          state.mousedown = false;
        });

        container.on('mouseleave', function () {
          angular.element(window.document).on('mouseup', mouseup);
        });

        container.on('mouseenter', function () {
          angular.element(window.document).off('mouseup', mouseup);
        });
        
        function mouseup() {
          state.mousedown = false;
          angular.element(window.document).off('mouseup', mouseup);
        }

        for (var i = 0; i < 14; i++) {
          var column = angular.element('<div>');
          column.css({
            display: 'inline-block',
            verticalAlign: 'top'
          });
          for (var j = 0; j < swatches[i].colors.length; j++) {
            var row = angular.element('<div>');
            selection[i][j] = {ele: row};
            swatches[i].colors[j].x = i;
            swatches[i].colors[j].y = j;
            selection[i][j].ele.css({
              width: attrs.width + 'px',
              height: attrs.height + 'px'
            });

            row.css({
              background: swatches[i].colors[j].hex,
              height: attrs.height + 'px'
            });

            row.on('click', (function (color) {
              return function () {
                action(color);
              };
            })(swatches[i].colors[j]));

            row.on('mouseover', (function (color) {
              return function () {
                if (!state.mousedown || (color.x === state.selected.x && color.y === state.selected.y)) {
                  return;
                }
                action(color);
              };
            })(swatches[i].colors[j]));
            column.append(row);
          }
          container.append(column);
        }
        element.append(container);

        function action(color) {
          selection[state.selected.x][state.selected.y].ele.removeClass('selected');
          state.selected.color = color.hex;
          state.selected.x = color.x;
          state.selected.y = color.y;
          
          var format = $parse(attrs.format);
          if (format() === 'hex') {
            ngModel.$setViewValue((color.hex).toUpperCase());
          } else {
            ngModel.$setViewValue({
              hex: color.hex,
              rgb: tinycolor(color.hex).toRgb()
            });
          }
          state.ignore = true;
          selection[color.x][color.y].ele.addClass('selected');
        };

        function selectColor(hex) {
          if (tinycolor(hex).getFormat() == 'hex') {
            selection[state.selected.x][state.selected.y].ele.removeClass('selected');
            for (var i = 0; i < swatches.length; i++) {
              for (var j = 0; j < swatches[i].colors.length; j++) {
                if (swatches[i].colors[j].hex == (hex).toLowerCase()) {
                  selection[i][j].ele.addClass('selected');
                  state.selected.x = i;
                  state.selected.y = j;
                  return false;
                }
              }
            }
          }
        }

        /**
         * watch ngModel value
         */
        var deRegisterNgModelWatcher = $scope.$watch(attrs.ngModel, function (value, oldValue) {
          if (typeof value == 'undefined') {
            return;
          }
          selectColor(value);
          $scope.$eval(attrs.onSelect);
        });
        
        $scope.$on('$destroy', function () {
          deRegisterNgModelWatcher();
        });
      }
    };
  }
})();

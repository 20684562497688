(function () {
  'use strict';
  angular.module('tallyfy')
    .directive('bodyClick', bodyClick);

  /*@ngInject*/
  function bodyClick($timeout) {
    return {
      restrict: "A",
      scope: {
        ignoredClasses: '<',
        bodyClick: '&'
      },
      link: function (scope) {
        var __this = this, body = document.getElementsByTagName('body');

        function clickCallback(e) {
          var isIgnoredElement = false;
          for (var i = 0; i < scope.ignoredClasses.length; i++) {
            isIgnoredElement = e.target.classList.contains(scope.ignoredClasses[i]);
            if (isIgnoredElement) {
              break;
            }
          }
          if (!isIgnoredElement) {
            scope.bodyClick.call(__this, { e: e });
          } else {
            e.stopImmediatePropagation();
          }
        }

        body[0].addEventListener('click', clickCallback);

        scope.$on('$destroy', function () {
          body[0].removeEventListener('click', clickCallback);
        })
      }
    }
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy.list')
    .service('listService', listService);

  /*@ngInject*/
  function listService(_, $rootScope, listRepository) {
    var self = this;

    self.createList = function (data) {
      return listRepository.$create(data).$promise;
    }

    self.getLists = function (args) {
      return listRepository.$get(args).$promise;
    }

    self.getListById = function (listId) {
      return listRepository.$get({ list_id: listId }).$promise;
    }

    self.editList = function (listId, body) {
      return listRepository.$update({ list_id: listId }, body).$promise;
    }

    self.updateListColumns = function (listId, body) {
      return listRepository.$update({ list_id: listId, action: 'columns' }, body).$promise;
    }

    self.updateListRows = function (listId, body) {
      return listRepository.$update({ list_id: listId, action: 'rows' }, body).$promise;
    }

    self.deleteList = function (listId) {
      return listRepository.$destroy({ list_id: listId }).$promise;
    }

    self.mapListColumnsFromArray = function (rawCols) {
      return _.map(rawCols, function (val) {
        var value = { id: $rootScope.identity.id };
        value[val] = null;
        return value;
      });
    }
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.onKeyEnter
 * @restrict 'A'
 * @element ANY 
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('onKeyEnter', onKeyEnter);
    /*@ngInject*/
    function onKeyEnter() {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('keydown keypress', function (event) {
            if (event.which === 13) {
              scope.$apply(function() {
                scope.$eval(attrs.onKeyEnter);
              });
              event.preventDefault();
            }
          });
          scope.$on('$destroy', function () {
            element.unbind('keydown keypress');
          });
        }
      };
    }
})();

/**
 * @ngdoc Component
 * @name tallyfy.process.component.process
 * @module tallyfy.process
 *
 * @description
 * A component to manage process
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('runsComponent', {
      templateUrl: 'app/modules/runs/runs.html',
      controller: function () {
        var $ctrl = this;

        /**
         * component's lifeCycle hooks 
         */
        $ctrl.$onInit = initialization;

        /**
         * @function
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { }
      }
    });
})();

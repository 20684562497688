/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for analytics component
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
    
  /**
   * Constructor
   * routeConfig construct 
   */
   /*@ngInject*/
  function routeConfig($stateProvider) {
    $stateProvider.state('settings.analytics', {
      parent: 'settings',
      url: '/analytics',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view': {
          component: 'analytics'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.focusMe
 * @description 
 * focus current element
 * @restrict 'A'
 * @element ANY 
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('focusMe', focusMe);

  focusMe.$inject = ['$timeout'];

  function focusMe($timeout) {
    return {
      restrict: 'AC',
      priority: 1,
      link: function (scope, element, attrs) {
        var input;
        attrs.$observe('focusMe', function (isFocus) {
          input = element;
          if (isFocus.toLowerCase() === 'child') {
            $timeout(function () {
              input = element.find('input');
              doFocus();
            }, parseInt(attrs.focusTimeout) || 50);
          } else if (isFocus.toLowerCase() !== 'false') {
            doFocus();
          } else if (isFocus.toLowerCase() == 'false') {
            input[0].blur();
          }
        });

        function doFocus() {
          if(parseInt(attrs.focusTimeout) == 0) {
            input[0].focus();
          } else {
            $timeout(function () {
              input[0].focus();
            }, parseInt(attrs.focusTimeout) || 100);
          }
        }
      }
    };
  }

})();

/**
 * @ngdoc component
 * @name tallyfy.compactStep
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactStep', {
      templateUrl: 'app/components/compact/components/step/compact-step.component.html',
      bindings: {
        process: '<',
        index: '<',
        step: '<',
        isSelected: '<',
        usersInOrg: '<',
        isLoading: '<',
        showClickStepImage: '<',
        isPublic: '<',
        orgGroups: '<?',
        roles: '<?',
        maxTitleLength: '<',
        hideMeta: '<',
        noIndex: '<',
        previewStep: '<',
        showInfoIcon: '<?',
        showAutomationPopoverId: '=?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, $timeout, StepService, CompactStepService, COMMON) {
          var $ctrl = this,
            processAtomationWatcherHandler,
            processWatcherHandler,
            stepSaveChangesEventHandler,
            stepUpdatedEventHandler;

          // angular life cycle hook
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public methods
          $ctrl.onStepSelected = onStepSelected;

          $ctrl.getConditionableLabel = getConditionableLabel;
          $ctrl.getActionLabel = getActionLabel;

          // public properties
          $ctrl.isDragging = false;
          $ctrl.affectingAutomations = [];
          $ctrl.asAutomationTriggers = [];
          $ctrl.isAdvancedMode = false; //This will be toggled based on an upcoming member-level preference at org-level
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;

          /**
           * @ngdoc methods
           * @name onInit
           * 
           * @description
           * angular life cycle hook methods on component init
           */
          function onInit() {
            $ctrl.toolTipPosition = ($ctrl.index === 0) ? "down" : "top";
            $ctrl.viewerId = $ctrl.isPublic ? 'PUBLIC' : $rootScope.identity.id;
            $ctrl.stepTypes = StepService.getStepTypes();
            $ctrl.selectedStepType = _.find($ctrl.stepTypes, { value: $ctrl.step.step_type });
            $ctrl.hiddenStep = isStepHasHideAction();
          }

          /**
           * @ngdoc methods
           * @name onChanges
           * @param {*} changes
           * 
           * @description
           * angular life cycle hook on component changes 
           */
          function onChanges(changes) {
            if (changes.step) {
              var affectingAutomations = CompactStepService.getAffectingAutomations($ctrl.process, changes.step.currentValue);
              $ctrl.affectingAutomations = _.map(affectingAutomations, function (affectingAutomation) {
                return {
                  id: affectingAutomation.id,
                  automated_alias: affectingAutomation.automated_alias,
                  conditions: affectingAutomation.conditions,
                  then_actions: _.filter(affectingAutomation.then_actions, function (action) {
                    return action.target_step_id === $ctrl.step.id;
                  })
                }
              });
              var triggerAutomations = CompactStepService.getTriggerAutomations($ctrl.process, $ctrl.step);
              $ctrl.asAutomationTriggers = _.map(triggerAutomations, function (triggerAutomation) {
                return {
                  id: triggerAutomation.id,
                  automated_alias: triggerAutomation.automated_alias,
                  conditions: _.filter(triggerAutomation.conditions, function (condition) {
                    return condition.conditionable_id === $ctrl.step.id;
                  }),
                  then_actions: triggerAutomation.then_actions
                }
              });
            }
            if (changes.index) {
              emitIndexUpdated();
            }
          }

          /**
           * @ngdoc methods
           * @name onDestroy
           * 
           * @description
           * angular life cycle hook methods on component init
           */
          function onDestroy() {
            processAtomationWatcherHandler();
            processWatcherHandler();
            stepSaveChangesEventHandler();
            stepUpdatedEventHandler();
          }

          /**
           * @ngdoc method
           * @name onStepSelected
           * @param {*} e 
           * @param {*} selectedTab
           * 
           * @description
           * on step selected method handler 
           */
          function onStepSelected(e, selectedTab) {
            if ($ctrl.previewStep) {
              return;
            }
            var stepTabs = StepService.getStepOptions($ctrl.process);
            var activeTab = _.find(stepTabs, { value: selectedTab || 'describe_capture' });
            activeTab = activeTab || {};
            e.preventDefault();
            e.stopPropagation();
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', { event: e, onStepClicked: true });
            $ctrl.isSelected
              ? $rootScope.$emit('RIGHT_PANE:OPEN', {
                isChatBoxOpen: (selectedTab === 'view_comment'),
                activeTab: activeTab
              }) : $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  index: $ctrl.index,
                  step: $ctrl.step,
                  isChatBoxOpen: (selectedTab === 'view_comment'),
                  activeTab: activeTab
                }
              });
            $ctrl.showAutomationPopoverId = selectedTab === 'automations' ? $ctrl.step.id : void 0;
          }

          /**
           * @ngdoc method
           * @name updateRuleTerms
           * @description
           * update step rule terms info
           */
          function updateRuleTerms() {
            if (_.get($ctrl.step, 'condition.flag')) {
              $ctrl.affectingAutomations = CompactStepService.getAffectingAutomations($ctrl.process, angular.copy($ctrl.step));
              $ctrl.asAutomationTriggers = CompactStepService.getTriggerAutomations($ctrl.process, angular.copy($ctrl.step));
            }
          }

          function isStepHasHideAction() {
            var automatedActions = $ctrl.process.automated_actions;
            for (var i = 0; i < automatedActions.length; i++) {
              var visibilityActions = _.filter(automatedActions[i].then_actions, function (action) {
                return action.action_type === 'visibility' && action.action_verb === 'show' && action.target_step_id === $ctrl.step.id;
              })
              if (visibilityActions.length) {
                return true;
              }
            }
            return false;
          }

          function getConditionableLabel(condition, asTrigger) {
            if (condition.conditionable_type === 'Step') {
              var step = _.find($ctrl.process.steps.data, { id: condition.conditionable_id });
              return (asTrigger ? 'this step' : _.get(step, 'title', '')) + ' ' + $filter('translate')('steps.logic.label.' + condition.operation);
            } else if (condition.conditionable_type === 'Prerun') {
              var prerun = _.find($ctrl.process.prerun, { id: condition.conditionable_id });
              return _.get(prerun, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            } else if (condition.conditionable_type === 'Capture') {
              var capture;
              for (var i = 0; i < $ctrl.process.steps.data.length; i++) {
                if ($ctrl.process.steps.data[i]) {
                  capture = _.find($ctrl.process.steps.data[i].captures, { id: condition.conditionable_id });
                  break;
                }
              }
              return _.get(capture, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            }
          }

          function getActionLabel(action) {
            var step = _.find($ctrl.process.steps.data, { id: action.target_step_id });
            return _.get(step, 'title', '');
          }

          function emitIndexUpdated() {
            $timeout(function () {
              $rootScope.$emit('INDEX:UPDATED', { step: $ctrl.step, index: $ctrl.index });
            }, 750);
          }

          // watch for step terms value to update rule captions
          processAtomationWatcherHandler = $scope.$watchCollection('$ctrl.process.automations', function () {
            $ctrl.hiddenStep = isStepHasHideAction();
          });

          processWatcherHandler = $scope.$watchCollection('$ctrl.process.steps.data', function () {
            updateRuleTerms();
          });

          // event handler on form rule step changes
          stepSaveChangesEventHandler = $rootScope.$on('SAVE_STEP_CHANGES', function (event, stepId) {
            stepId === $ctrl.step.id ? updateRuleTerms() : angular.noop();
          });

          stepUpdatedEventHandler = $rootScope.$on('STEP:UPDATED', function (e, data) {
            var stepData = data.step || data;
            if ($ctrl.step.id === stepData.id) {
              $ctrl.selectedStepType = _.find($ctrl.stepTypes, { value: $ctrl.step.step_type });
            }
          });
        }
    });
})();
/**
 * @ngdoc Config
 * @name tallyfy.run.config.RunViewStateConfig
 * @module tallyfy.run
 *
 * @description
 * set route run Module
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .config(RunViewStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  RunViewStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * RunViewStateConfig construct 
   */
  function RunViewStateConfig($stateProvider) {
    $stateProvider.state('run.dashboard', {
      url: '/tracker?filter&page&sortBy&folder&tags',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        sortBy: { squash: true, value: null },
        page: { squash: true, value: '1' },
        filter: { squash: true, value: null },
        folder: { squash: true, value: null },
        tags: { squash: true, value: null },
        checklist: { squash: true, value: null }
      },
      views: {
        'run_view': {
          component: 'runDashboard'
        }
      }
    });

    $stateProvider.state('guest.runs', {
      url: '/processes?filter&page&sortBy',
      parent: 'guest',
      params: {
        sortBy: { squash: true, value: null },
        page: { squash: true, value: "1" },
        filter: { squash: true, value: null },
      },
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'runDashboard'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('filter');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('process');
          return $translate.refresh();
        }]
      }
    });
  }
})();

(function () {
	'use strict';

	angular.module('tallyfy')
		.component('taskFilterDisplay', {
			bindings: {
				tasksFiltersConfig: '=',
				isGuest: '<',
				usersInOrg: '<',
				rolesInOrg: '<?',
				orgGuests: '<',
				groupsInOrg: '<?',
				allTags: '<',
				allActiveProcesses: '<',
				foldersList: '<',
				filterItemClicked: '&',
				filterRemoved: '&',
				showFilterOptions: '&'
			},
			templateUrl: 'app/components/task-filter/task-filter-display/task-filter-display.component.html',
			controller:
				/*@ngInject*/
				function (_, $scope, Helper, TasksFilterService) {
					var $ctrl = this, tasksFiltersConfigWatchers;

					$ctrl.$onInit = onInit;
					$ctrl.$onChanges = onChanges;
					$ctrl.$onDestroy = onDestroy;

					$ctrl.filterItemDelete = filterItemDelete;
					$ctrl.pillClicked = pillClicked;

					function onInit() { }

					function onChanges() { }

					function onDestroy() {
						tasksFiltersConfigWatchers();
					}

					function filterItemDelete(dataKey, dataValue) {
						$ctrl.filterRemoved({ key: dataKey, value: dataValue });
					}

					function pillClicked(e) {
						if ($ctrl.isGuest) {
							return;
						}
						$ctrl.filterItemClicked({ e: e });
					}

					tasksFiltersConfigWatchers = $scope.$watchCollection(function () {
						return _.pick($ctrl.tasksFiltersConfig, $ctrl.isGuest ? ['status'] : _.filter(TasksFilterService.getApplicableFilterKeysAndStateParams(), function (key) {
							return key !== 'sortBy';
						}));
					}, function (value) {
						for (var propName in value) {
							if (Helper.isObjectEmpty(value[propName])) {
								delete value[propName];
							}
						}
						$ctrl.hideAssignees = !!(_.get(value, 'group') || _.get(value, 'role'));
						$ctrl.filterParams = value;
					});
				}
		});
})();
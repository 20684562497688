/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportGuests
 * @module tallyfy.support
 *
 * @description
 * A component to manage support Guest List
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportGuests', {
      bindings: {
        orgId: '<'
      },
      templateUrl: 'app/modules/support/components/users/org-support-guests.html',
      controller:
        /*@ngInject*/
        function (GuestTasksRepository) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }
        
          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */  
          function onChanges() { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
        }
    });
})();

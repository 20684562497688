/**
 * @ngdoc Service
 * @name tallyfy.QuickActionsService
 * @module tallyfy
 * @description QuickActionsService
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('QuickActionsService', QuickActionsService);

  /*@ngInject*/
  function QuickActionsService(Helper, $uibModal, $state, AuthPlan, $analytics) {
    var self = this,
      actionsTranslationsMap = {
        'get_feedback': {
          title: 'global.standAlone.taskQuickActionSubType.get_feedback.title',
          default_name: 'global.standAlone.taskQuickActionSubType.get_feedback.default_name'
        },
        'request_approval': {
          title: 'global.standAlone.taskQuickActionSubType.request_approval.title',
          default_name: 'global.standAlone.taskQuickActionSubType.request_approval.default_name',
          assignees_label: 'global.standAlone.taskQuickActionSubType.request_approval.assignees_label'
        },
        'share_update': {
          title: 'global.standAlone.taskQuickActionSubType.share_update.title',
          default_name: 'global.standAlone.taskQuickActionSubType.share_update.default_name',
          assignees_label: 'global.standAlone.taskQuickActionSubType.share_update.assignees_label',
          deadline_label: 'global.standAlone.taskQuickActionSubType.share_update.deadline_label',
          separate_task_for_each_assignee: {
            label: 'global.standAlone.taskQuickActionSubType.share_update.separate_task_for_each_assignee.label',
            help_text: 'global.standAlone.taskQuickActionSubType.share_update.separate_task_for_each_assignee.help_text'
          }
        }
      };

    /**
     * @ngdoc method
     * @name getLinksTranslations
     * @description Get Links' Translations
     * @param action
     * @returns {Object}
     */
    function getLinksTranslations(action) {
      var initialStr = 'dashboard.quickActionLinks.';
      return {
        title: initialStr + action + '.title',
        description: initialStr + action + '.description',
        example: initialStr + action + '.example',
        buttonText: initialStr + 'common.buttonText'
      };
    }

    /**
     * @ngdoc method
     * @name openOOTModal
     * @description Open OOT Modal with custom action params
     * @param quickActionTaskType
     * @param showSeparateTasksPerAssigneeOption
     * @param customTaskTranslations
     * @returns {Object}
     */
    function openOOTModal(quickActionTaskType, showSeparateTasksPerAssigneeOption, customTaskTranslations) {
      if (!Helper.checkAccessAuthority()) {
        return;
      }
      var modalInstance = $uibModal.open({
        component: 'editTask',
        windowClass: 'task-modal create-task-modal',
        resolve: {
          isQuickAction: function () {
            return true;
          },
          quickActionTaskType: function () {
            return quickActionTaskType;
          },
          showSeparateTasksPerAssigneeOption: function () {
            return showSeparateTasksPerAssigneeOption;
          },
          customTaskTranslations: function () {
            return customTaskTranslations;
          }
        }
      });
      modalInstance.result.then(function () {}, function () {
        $state.go('.', null, { inherit: false, notify: false });
      });
    }

    /**
     * @ngdoc method
     * @name openCreateBPModal
     * @description Open create BP Modal with custom type params
     * @param blueprintType
     * @returns {Object}
     */
    function openCreateBPModal(blueprintType) {
      if (!Helper.checkAccessAuthority())
        return;

      $uibModal.open({
        component: 'bluePrintModal',
        windowClass: 'blueprint-modal',
        backdrop: 'static',
        resolve: {
          blueprintType: function () {
            return blueprintType;
          }
        }
      }).result.then(function (newProcess) {
        $state.go('process.edit', {
          slug: newProcess.id
        });
      }, function () {
        $state.go('.', null, { inherit: false, notify: false });
      });
    }
    
    /**
     * @name trackEvent
     * @param {*} triggerSource
     * @param {*} type
     * @description
     * method to handling analytics event
     */
    function trackEvent(triggerSource, type) {
      var source = triggerSource ? triggerSource : "Direct URL";
      $analytics.eventTrack('Click', { label: 'Quick Links - ' + type + ' - ' + source, category: Helper.eventTrackingCategories['quicklinks'] });
    }

    /**
     * @ngdoc method
     * @name getAcceptableQuickActionTypes
     * @description Get the list of acceptable action types
     * @returns {Object}
     */
    self.getAcceptableQuickActionTypes = function () {
      return {
        'get-feedback': {
          alternateVariableName: "get_feedback",
          iconClasses: "fas fa-comments",
          linkContentTranslations: getLinksTranslations('get_feedback'),
          actionHandler: function (source) {
            trackEvent(source, 'Get Feedback');
            openOOTModal('task', true, actionsTranslationsMap['get_feedback']);
          },
          isApplicableForCurrentAccount: function () {
            return true;
          }
        },
        'request-approval': {
          alternateVariableName: "request_approval",
          iconClasses: "fas fa-map-signs",
          linkContentTranslations: getLinksTranslations('request_approval'),
          actionHandler: function (source) {
            trackEvent(source, 'Request Approval');
            openOOTModal('approval', false, actionsTranslationsMap['request_approval']);
          },
          isApplicableForCurrentAccount: function () {
            return true;
          }
        },
        'share-update': {
          alternateVariableName: "share_update",
          iconClasses: "fas fa-bullhorn",
          linkContentTranslations: getLinksTranslations('share_update'),
          actionHandler: function (source) {
            trackEvent(source, 'Share Update');
            openOOTModal('expiring', true, actionsTranslationsMap['share_update']);
          },
          isApplicableForCurrentAccount: function () {
            return true;
          }
        },
        'create-procedure': {
          alternateVariableName: "create_procedure",
          iconClasses: "fas fa-list-ol",
          linkContentTranslations: getLinksTranslations('create_procedure'),
          actionHandler: function (source) {
            trackEvent(source, 'Create Procedure');
            openCreateBPModal('procedure');
          },
          isApplicableForCurrentAccount: function () {
            return true;
          }
        },
        'launch-process': {
          alternateVariableName: "launch_process",
          iconClasses: "fas fa-rocket",
          linkContentTranslations: getLinksTranslations('launch_process'),
          actionHandler: function (source) {
            trackEvent(source, 'Launch Process');
            $state.go('process.templates');
          },
          isApplicableForCurrentAccount: function () {
            return !AuthPlan.isRestrictedWithDocsPlan();
          }
        },
        'create-document': {
          alternateVariableName: "create_document",
          iconClasses: "fas fa-align-left",
          linkContentTranslations: getLinksTranslations('create_document'),
          actionHandler: function (source) {
            trackEvent(source, 'Create Document');
            openCreateBPModal('document');
          },
          isApplicableForCurrentAccount: function () {
            return true;
          }
        },
        'create-form': {
          alternateVariableName: "create_form",
          iconClasses: "fas fa-list",
          linkContentTranslations: getLinksTranslations('create_form'),
          actionHandler: function (source) {
            trackEvent(source, 'Create Form');
            openCreateBPModal('form');
          },
          isApplicableForCurrentAccount: function () {
            return false;
          }
        }
      };
    };
  }
})();
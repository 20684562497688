/**
 * @TODO Refactoring
 * @return {[type]} [description]
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .controller('LanguageController', LanguageController);

  LanguageController.$inject = ['$translate', '$language', 'tmhDynamicLocale'];

  function LanguageController($translate, $language, tmhDynamicLocale) {
    var vm = this;

    vm.changeLanguage = changeLanguage;
    vm.languages = null;

    $language.getAll().then(function (languages) {
      vm.languages = languages;
    });

    function changeLanguage(languageKey) {
      $translate.use(languageKey);
      tmhDynamicLocale.set(languageKey);
    }
  }
})();

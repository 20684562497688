(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('shareLink', {
      templateUrl: 'app/components/share-link/share-link.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, Helper) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.copyLink = copyLink;

          function onInit() {
            $ctrl.titleTranslation = $ctrl.resolve.titleTranslation || 'process.label.share_link_title';
          }

          function onChanges() { }
          
          function onDestroy() { }

          function copyLink(e, link) {
            Helper.copyToClipboard(link, 'shareLink', e);
          }
        }
    });
})();
/**
 * @ngdoc Config
 * @name tallyfy.organizations.config.routeConfig
 * @module tallyfy.organizations
 *
 * @description
 * set route Organizations Module
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(routeConfig);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct
   */
  function routeConfig($stateProvider) {
    $stateProvider.state('organizations.create', {
      parent: 'organizations',
      url: '/create/:view_name?allow_new_creation',
      params: {
        view_name: { squash: true, value: 'org_name' }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        '@': {
          component: 'createOrganizationV2'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('signup');
          return $translate.refresh();
        }]
      }
    }).state('organizations.schedule_call', {
      url: '/schedule_call?dback&dskip&rac', //dback -> Disable back button; dskip -> Disable Skip (Schedule Call); rac -> Redirect to home after completion;
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        '@': {
          component: 'scheduleCall'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('signup');
          return $translate.refresh();
        }]
      }
    });
  }
})();
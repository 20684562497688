(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicTagList', {
      templateUrl: 'app/components/public/shareds/tag-list/tag-list.component.html',
      bindings: {
        title: '<',
        tagItems: '<',
        style: '@',
        itemOnClick: '&?',
        selectedTag: '<',
        filterCallback: '&?',
        isBusy: '<',
        hideGroupLetter: '<?'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onTagClick = onTagClick;

          function onInit() { }

          function onChanges(changes) {
            if (changes.tagItems && changes.tagItems.currentValue) {
              if (typeof $ctrl.filterCallback === 'function') {
                $ctrl.tagItems = $ctrl.filterCallback({
                  args: {
                    title: $ctrl.title,
                    tagItems: $ctrl.tagItems
                  }
                });
              }
            }
          }
          
          function onDestroy() { }

          function onTagClick(tag) {
            if (typeof $ctrl.itemOnClick === 'function') {
              $ctrl.itemOnClick({
                tag: tag
              });
            }
          }
        }
    });
})();
/**
 * @ngdoc Directive
 * 
 * @name tallyfy.showTab
 * 
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .directive('showtab', function () {

      return {
        scope: {
          tabToInject: '='
        },
        link: function (scope, element, attrs) {
          var deRegisterTabToInjectWatcher = scope.$watch('tabToInject', function (data) {
            if (data) {
              data.switchTab = function (target, id) {
                angular.element("[data-target='"+ target + id +"']").tab('show');
              };
            }
          });
          
          scope.$on('$destroy', function () {
            deRegisterTabToInjectWatcher();
          });
        }
      };
    });
})();
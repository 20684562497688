/**
 * @ngdoc Component
 * @name tallyfy.users.component.inviteUserComponent
 * @module tallyfy.organizations.users
 *
 * @description
 * invite user
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('inviteUserComponent', {
      templateUrl: 'app/modules/users/inviteUser/inviteUser.html',
      bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<?'
      },
      controller:
        /*@ngInject*/
        function (blockUI, UsersService, VALIDATION, _, CONST, $rootScope, TFY_EVENTS, $filter, growl, $state, $scope, MODAL_CLOSE_EVENTS, store, RegularUserTableService, GroupsService) {

          var $ctrl = this,
            blockUI = blockUI.instances.get('inviteUserForm'),
            onModalClosePreventHandler,
            groupObjPickValues = ['name', 'description', 'members', 'guests', 'logo'];

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          $ctrl.inviteUser = inviteUser;
          $ctrl.resetValidity = resetServerValidity;
          $ctrl.prepareRoleOption = prepareRoleOption;
          $ctrl.copySSOLink = copySSOLink;
          $ctrl.selectGroups = selectGroups;
          $ctrl.removeGroup = removeGroup;

          /**
           * public properties
           */
          $ctrl.user = {};
          $ctrl.emailPattern = CONST.EMAIL_REGEX;
          $ctrl.isInviteAnother = false;
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;
          $ctrl.selectedGroups = [];
          $ctrl.isOpenGroup = false;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            if ($ctrl.resolve && _.get($ctrl.resolve, 'email')) {
              $ctrl.user.email = _.get($ctrl.resolve, 'email');
            }
            $ctrl.focusOnFName = true;
            $ctrl.user.message = $filter('translate')('userProfile.defaultFormValues.message');
            prepareOrgRoles();
            getGroups();
            $ctrl.orgDetail =  _.get($rootScope, 'orgSamlInfo');
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */

          function onDestroy() {
            onModalClosePreventHandler();
          }

          /*
           * @ngdoc method
           * @name prepareOrgRoles
           * @description to get the total role of the organization
           * @private
           */
          function prepareOrgRoles() {
            $ctrl.userRoles = UsersService.getUserRoles();
            if (!$ctrl.isAdminMember) {
              $ctrl.userRoles[0].disable = true;
              $ctrl.selectedOption = $ctrl.userRoles[1];
            } else {
              $ctrl.userRoles[0].disable = false;
              $ctrl.selectedOption = _.first($ctrl.userRoles);
            }
          }

          /*
           * @ngdoc method
           * @name prepareRoleOption
           * @description set/change role 
           * @public
           * @param {*} option
           */
          function prepareRoleOption(option) {
            $ctrl.selectedOption = option;
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
           * @public
           * @returns {undefined}
           */
          function cancel() {
            $ctrl.dismiss();
          }

          /*
           * @ngdoc method
           * @name inviteAnotherMemberInit
           * @description Initialize the adding of another member
           * @public
           */
          function initInviteAnotherMember() {
            $ctrl.isInviteAnother = true;
            $ctrl.user = {
              message: $filter('translate')('userProfile.defaultFormValues.message')
            };
            $ctrl.focusOnFName = true;
            $ctrl.userForm.$setPristine();
            $ctrl.userForm.$setUntouched();
          }

          /*
           * @ngdoc method
           * @name inviteUser
           * @description to invite the user
           * @public
           * @returns {Object} data
           */
          function inviteUser() {
            blockUI.start();
            $ctrl.user.email = _.toLower($ctrl.user.email);
            $ctrl.user.role = $ctrl.selectedOption.type;
            UsersService.invite({}, $ctrl.user).then(function (response) {
              blockUI.stop();
              $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, response.data);
              if ($ctrl.selectedGroups.length) {
                _.forEach($ctrl.selectedGroups, function (group) {
                  group.members.push(_.get(response, 'data.id'));
                  saveOrgGroupHandler(group);
                });
              }
              if ($state.current.name === "settings.org.usersRegular") {
                $rootScope.$emit('USER:UPDATE_USERS', { member: response.data });
              }
              store.updateUser(angular.extend(response.data, { role: $ctrl.user.role }));
              growl.success($filter('translate')('userProfile.messages.invitedSuccessfully', {
                fullname: response.data.full_name
              }), { referenceId: 'MemberAdditionSuccessfulController', disableCloseButton: true });
              $ctrl.resolve.oneTime ? $ctrl.close({
                $value: {
                  invited: true
                }
              }) : initInviteAnotherMember();
            }, function (error) {
              if (error.data.validation_errors) {
                _.forEach(error.data.validation_errors, function (msg, index) {
                  $ctrl.userForm[index].$setValidity('server', false);
                  $ctrl.userForm[index].errorMessage = _.first(msg);
                });
              } else {
                growl.error(error.data.message, { referenceId: 'MemberAdditionSuccessfulController', disableCloseButton: true });
              }
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name resetServerValidity
           * @description reset server side validation
           * @param key
           */
          function resetServerValidity(key) {
            if ($ctrl.userForm[key].$invalid) {
              $ctrl.userForm[key].$setValidity('server', true);
              delete $ctrl.userForm[key].errorMessage;
            }
          }

          /**
           * @function 
           * @name copySSOLink
           * @param {String} text 
           * @param {Object} target 
           * @param {*} $event 
           * @description Copy success handler
           * @returns {Void} 
           */
          function copySSOLink(text, target, $event) {
            RegularUserTableService.copySuccess(text, target, $event);
          }

          /**
           * @ngdoc method
           * @name getGroups
           * @private
           * @description Get groups data
           */
          function getGroups() {
            store.getGroups().then(function (response) {
              $ctrl.groups = response;
              _.forEach($ctrl.groups, function (group) {
                group.selected = false;
              });
            });
          }

          /**
           * @private
           */
          function saveOrgGroupHandler(orgGroup) {
            GroupsService.updateOrgGroup({ id: orgGroup.id }, _.pick(orgGroup, groupObjPickValues)).then(function () {
              $ctrl.selectedGroups = [];
              orgGroup.selected = false;
            });
          }

          /**
           * @ngdoc method
           * @name selectGroups
           * @private
           * @description select groups data
           */
          function selectGroups (group) {
            $ctrl.isOpenGroup = false;
            var groupIndex = _.findIndex($ctrl.selectedGroups, { id: group.id });
            groupIndex < 0 ? $ctrl.selectedGroups.push(group) : $ctrl.selectedGroups.splice(groupIndex, 1);
          }

          /**
           * @ngdoc method
           * @name groupRemove
           * @private
           * @description select groups data
           */
          function removeGroup (group) {
            group.selected = false;
            selectGroups(group);
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.userForm.$dirty && (reason == MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason == MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
          //end of controller
        }
    });
})();

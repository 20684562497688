/**
 * @ngdoc component
 * @name tallyfy.compactProcessDescriptionStep
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactProcessDescriptionStep', {
      templateUrl: 'app/components/compact/components/processDescriptionStep/compact-process-description-step.component.html',
      bindings: {
        process: '<',
        isSelected: '<',
        isPublic: '<',
        isSnippet: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, DESCRIPTIONSIZE) {
          var $ctrl = this, oldProcess;

          // angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;

          // public methods
          $ctrl.onStepSelected = onStepSelected;

          /**
           * @ngdoc methods
           * @name onInit
           * 
           * @description
           * angular life cycle hook on component init
           */
          function onInit() {
            $ctrl.maxLength = DESCRIPTIONSIZE.maxLength;
          }

          /**
           * @ngdoc methods
           * @name onChanges
           * @param {*} changes
           * 
           * @description
           * angular life cycle hook on binding changes 
           */
          function onChanges(changes) {
            if (changes.process) {
              oldProcess = angular.copy($ctrl.process);
            }
          }

          /**
           * @ngdoc methods
           * @name onStepSelected
           * @param {*} e 
           * 
           * @description
           * on step selected handler
           */
          function onStepSelected(e) {
            if ($ctrl.isSelected) {
              return;
            }
            e.stopPropagation();
            // emit item with id 'ko-step' for statement checking on component
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', { });
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                id: 'process-description',
                process: $ctrl.process,
                isSnippet: $ctrl.isSnippet
              }
            });
          }
        }
    });
})();
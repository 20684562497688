/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerBuilder
 * @module tallyfy.steps
 *
 * @description
 * A component to manage bunch of step
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('powerBuilder', {
      templateUrl: 'app/modules/steps/powerBuilder/powerBuilder.html',
      bindings: {
        resolve: '<',
        cancel: '&',
        activeTab: '<',
        builderForm: '='
      },
      controller:
         /*@ngInject*/
        function (_, StepService, $filter, $q, Growl, $timeout, DESCRIPTIONSIZE, Helper, $analytics, $rootScope) {
          var $ctrl = this,
            growl = new Growl(),
            isError = false,
            defaultStep,
            availableSteps,
            savedSteps = [],
            stepsLength;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.buildStep = buildStep;
          $ctrl.getSaveText = getSaveText;
          $ctrl.checkStepTitleLength = checkStepTitleLength;
          $ctrl.addStepNumber = addStepNumber;

          /**
           * public properties
           */
          $ctrl.steps = [];
          $ctrl.isSaved = true;
          $ctrl.onSaving = false;
          $ctrl.isStepPaste = false;
          $ctrl.stepsTitles = [];
          $ctrl.isTimeOut = false;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            defaultStep = StepService.getStep();
            stepsLength = $ctrl.resolve.process.stepsLength;
            $ctrl.processTitle = $ctrl.resolve.process.title;
            $ctrl.stepsForExample = StepService.getStepsTips();
            $timeout(function(){
              document.getElementById('power-builder-el').focus();
              $ctrl.stepsTitles = setNextStepNumber();
            },0);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @ngdoc method
           * @name checkStepTitleLength
           * @description check step title length
           * @public
           */
          function checkStepTitleLength() {
            $ctrl.isSaved = false;
            var titleWithoutNumber;
            if ($ctrl.isStepPaste) {
              addStepNumber($ctrl.isStepPaste); 
              $ctrl.isStepPaste = false;
            }

            $ctrl.steps.length = 0;
            $ctrl.showError = false;
            Growl.clearAllMessages('powerBuilder');

            _.forEach($ctrl.stepsTitles, function (title, index) {
              titleWithoutNumber = removeStepNumber(title);
              if (_.size(titleWithoutNumber) >  DESCRIPTIONSIZE.maxTitle) {
                $ctrl.showError = true;
                displayErrorGrowl(titleWithoutNumber, index);
                return;
              }
              updateAllSteps(titleWithoutNumber);
            });
            setNextStepNumber();
          }

          /**
           * @ngdoc method
           * @name displayErrorGrowl
           * @description It will display error growl message
           * @param {*} titleWithoutNumber
           * @param {*} index
           */
          function displayErrorGrowl(titleWithoutNumber, index) {
            growl.error($filter('translate')('steps.messages.titleInvalid', {
              stepNumber: _.sum([stepsLength, index, 1]),
              stepTitle: titleWithoutNumber.slice(0, 25),
              maxTitle: DESCRIPTIONSIZE.maxTitle
            }), {
              referenceId: 'powerBuilder',
              disableIcons: true,
              disableCloseButton: true,
              ttl: -1
            });
          }

          /**
           * @ngdoc method
           * @name updateAllSteps
           * @description update step
           * @public 
           * @param {String} titleWithoutNumber
           */
          function updateAllSteps(titleWithoutNumber) {
            if (!_.isEmpty(_.trim(titleWithoutNumber))) {
              var step = angular.copy(defaultStep);
              angular.extend(step, {
                title: titleWithoutNumber,
                checklist_id: $ctrl.resolve.process.id
              });
              $ctrl.steps.push(step);
            }
          }

          /**
           * @ngdoc method
           * @name addStepNumber
           * @description add step number
           * @public 
           * @param {boolean} isPaste
           * @param {Object} event
           */
          function addStepNumber(isPaste, event) {
            if ((event && event.which === 13 && !event.shiftKey) || isPaste) {
              $ctrl.stepsTitles = setStepNumber();
              if(isPaste) {
                $ctrl.steps.length =  $ctrl.steps.length + 1;
              }
              $ctrl.stepsTitles = setNextStepNumber();
            }
          }

          /**
           * @ngdoc method
           * @name setStepNumber
           * @description set step number
           * @public 
           * @returns {Array} title
           */
          function setStepNumber() {
            var stepCount = 0,
                stepWithoutNumber,
                validSteps = removeEmptyStep();

            return _.map(validSteps, function (step) {
              stepWithoutNumber = removeStepNumber(step);
              if (!_.isEmpty(stepWithoutNumber)) {
                stepCount = stepCount + 1;
                var stepTitle = getStepTitle(stepCount);
                return (stepTitle + _.trim(stepWithoutNumber));
              }
            });
          }

          /**
           * @ngdoc method
           * @name getStepTitle
           * @description get step title with number
           * @param {*} stepCount
           * @returns {String} It will return step title with number
           */
          function getStepTitle(stepCount) {
            return $filter('translate')('steps.set_builder_title', {
              stepNumber: _.sum([stepsLength, stepCount])
            });
          }

          /**
           * @ngdoc method
           * @name removeEmptyStep
           * @description Remove empty step 
           * @public 
           * @returns {Array}
           */
          function removeEmptyStep() {
            return _.remove($ctrl.stepsTitles, function (value) {
              return !_.isEmpty(_.trim(value));
            });
          }

          /**
           * @ngdoc method
           * @name removeStepNumber
           * @description remove step number
           * @public 
           * @param {string} title
           * @returns {Array} title
           */          
          function removeStepNumber(title) {
            if (_.startsWith(title, $filter('translate')('steps.builder_title')) && _.includes(title, ":")) {
              return title.substr(title.indexOf(":") + 2);
            }
            return title;
          }

          /**
           * @ngdoc method
           * @name buildStep
           * @description It will create bunch steps
           * @returns promise
           */
          function buildStep() {
            if(!Helper.checkAccessAuthority())
              return;
            if ($ctrl.builderForm.$invalid || $ctrl.onSaving) {
              return;
            }
            isError = false;
            $timeout(function () {
              $ctrl.isTimeOut = true;
            }, 5000);
            saveSteps($ctrl.steps);
          }

          /**
           * @ngdoc method
           * @name saveSteps
           * @description save steps
           * @private 
           * @param {*} allSteps
           */
          function saveSteps(allSteps) {
            var firstStep = _.head(allSteps);
            availableSteps = allSteps;
            
            $ctrl.onSaving = true;
            createStep(firstStep).then(successStepResponse, failureStepResponse);
          }

          /**
           * @ngdoc method
           * @name createStep
           * @description APIs is calls to create a step
           * @param {*} step
           * @returns {*} promise 
           */
          function createStep(step) {
            $ctrl.defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
            step.deadline['value'] = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.value : 5;
            step.deadline['unit'] = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.type : "days";
            return StepService.createStep({ checklist_id: $ctrl.resolve.process.id }, step);
          }

          /**
           * @ngdoc method
           * @name successStepResponse
           * @description 
           * Calls after getting successful response from API
           * Used recursion to call step API to create bunch of steps
           * @param {*} response
           */
          function successStepResponse(response) {
            var step = response.data,
              remainSteps = availableSteps.slice(1);

            trackEvent({ label: 'STEP CREATED IN BLUEPRINT', stepName: step.title });

            setCaptures(step);
            savedSteps.push(step);

            if (remainSteps.length) {
              saveSteps(remainSteps);
            }
            if (!remainSteps.length && !isError) {
              $ctrl.isSaved = true;
              $ctrl.onSaving = false;
              $ctrl.cancel({ value: savedSteps, type: 'power-builder' });
            }
            if (isError) {
              resetVariables();
            }
          }

          /**
           * @ngdoc method
           * @name failureStepResponse
           * @description It will call when step API gets failed due to some reason
           */
          function failureStepResponse() {
            isError = true;
            resetVariables();
            $ctrl.isSaved = false;
            var resetSteps = availableSteps.slice(1);
            if (resetSteps.length) {
              saveSteps(resetSteps);
            }
            $ctrl.steps.length = 0;
          }

          /**
           * @ngdoc method
           * @name setCaptures
           * @description set saved step capture  
           * @private 
           * @param {Array} step
           */
          function setCaptures(step) {
            if (!step.hasOwnProperty('captures')) {
              angular.extend(step, {
                captures: {
                  data: []
                }
              });
            }
          }

          /**
           * @function
           * @name getSaveText
           * @description get the text of save button
           * @public
           * @returns {string}
           */
          function getSaveText() {
            if ($ctrl.isSaved) {
              return $filter('translate')('global.task.button.build');
            }
            return $filter('translate')('global.task.button.build');
          }

          /**
           * @function
           * @name resetVariables
           * @description Helper method to set falsy value in some variable's
           * @returns {void}
           */
          function resetVariables() {
            $ctrl.onSaving = false;
            $ctrl.isTimeOut = false;
          }
          
           /**
           * @function
           * @name setNextStepNumber
           * @description set next step number 
           * @returns {void}
           */
          function setNextStepNumber() {
            var newNumber = $filter('translate')('steps.set_builder_title', {
              stepNumber: _.sum([stepsLength, $ctrl.steps.length,1])
            });
            $ctrl.stepsTitles = _.compact($ctrl.stepsTitles);
            return  _.concat($ctrl.stepsTitles, newNumber);
          }

          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = { category: Helper.eventTrackingCategories['blueprint'] };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }
          //controller ends
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerTools.powerDeadline
 * @module tallyfy.steps
 *
 * @description
 * A component to steps set Deadline
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('powerDeadline', {
      templateUrl: 'app/modules/steps/powerTools/powerDeadline/powerDeadline.html',
      bindings: {
        process: '<',
        cancel: '&',
        activeTab: '<',
        type: '<'
      },
      controller:
         /*@ngInject*/
        function (_, TasksService, StepService, TimeFieldService, CompactTaskService, moment, DATEFORMAT, DateUtils, $rootScope, OrganizationsService) {
          var $ctrl = this,
            isError = false,
            availableSteps;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.onTimeUnitChange = onTimeUnitChange;
          $ctrl.timeUnits = TimeFieldService.getUnitOptions();
          $ctrl.onTimeIncrease = onTimeIncrease;
          $ctrl.onTimeDecrease = onTimeDecrease;
          $ctrl.updateStepsDeadline = updateStepsDeadline;
          $ctrl.deadlineTypeList = StepService.getDeadlineTypes();
          $ctrl.resetDeadline = resetDeadline;
          $ctrl.setDeadlineClass = setDeadlineClass;
          $ctrl.deadlineUnitInit = deadlineUnitInit;
          $ctrl.onClickDate = onClickDate;
          $ctrl.onSpecificDateDeadlineUpdate = onSpecificDateDeadlineUpdate;
          $ctrl.stepReset = stepReset;

          /**
           * public properties
           */
          $ctrl.onSaving = false;
          $ctrl.responseData = [];
          $ctrl.defaultTime = 0;
          $ctrl.hstep = 1;
          $ctrl.mstep = 1;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();
          $ctrl.datePicker = {
            dateOptions: {
              dateDisabled: false,
              formatYear: 'yy',
              formatMonth: 'MMM',
              monthColumns: '4',
              maxDate: new Date(2049, 12, 30),
              startingDay: 1,
              showButtonBar: false,
              altInputFormats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
              showWeeks: false,
              ngModelOptions: {
                debounce: 100
              }
            },
            isOpened: false
          };
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
            if($ctrl.activeTab.value === 'deadline') {
              $ctrl.selectedDeadlineType = _.head($ctrl.deadlineTypeList);
              $ctrl.specificDate = angular.copy($ctrl.process.startedAt);
              $ctrl.today = moment(new Date()).add( 1 , 'h').toDate();
              $ctrl.defaultUnit =  _.find($ctrl.timeUnits, { title: 'days' });
              $ctrl.stepsUpdate = _.orderBy(angular.copy($ctrl.process.steps), ['stage_id', 'position']);
              $ctrl.isDeadlineUpdated = false;
              $ctrl.resetDeadline(false);
            }
          }
          
          /**
           * @function
           * @name onClickDate
           * @param {*} $event
           * @description
           * on click open date picker
           */
          function onClickDate($event) {
            if($event) {
              $event.stopPropagation();
            }
            $ctrl.datePicker.isOpened = true;
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @ngdoc method
           * @name updateStepsDeadline
           * @description Update Step Assigner
           */
          function updateStepsDeadline() {
            $ctrl.isDeadlineUpdated = true;
            var updatedSteps = [];
            if (!$ctrl.forceUpdateAll) {
              updatedSteps = _.filter($ctrl.stepsUpdate, function(step) {
                return step.deadlineUpdate;
              });
            } else {
              updatedSteps = [];
              _.forEach($ctrl.stepsUpdate, function (step) {
                if (!step.deadlineUpdate) {
                  increaseTime(step, 0);
                }
                updatedSteps.push(step);
              });
            }
            if(updatedSteps.length > 0) {
              if ($ctrl.type === 'launchBlueprint') {
                $ctrl.selectedDeadlineDate = selectedDeadlineType();
                $ctrl.cancel({ value: updatedSteps, type: 'power-deadline', startDate: $ctrl.selectedDeadlineDate });
              } else {
                if($ctrl.process.type === 'task') {
                  saveBulkDeadline(updatedSteps);
                } else {
                  saveSteps(updatedSteps);
                }
              }
            }
          }
          
          /**
           * @ngdoc method
           * @name saveSteps
           * @description save steps
           * @private 
           * @param {*} allUpdatedSteps
           */
          function saveSteps(allUpdatedSteps) {
            var firstStep = _.omit(_.head(allUpdatedSteps), ['summary','original_summary']);
            availableSteps = allUpdatedSteps;
            $ctrl.isDeadlineUpdated = false;
            $ctrl.onSaving = true;
            updateStep(firstStep).then(successStepResponse, failureStepResponse);
          }
          
          /**
           * @ngdoc method
           * @name successStepResponse
           * @description 
           * Calls after getting successful response from API
           * Used recursion to call step API to create bunch of steps
           * @param {*} response
           */
          function successStepResponse(response) {
            var step = $ctrl.process.type === 'task' ? _.get(response, 'task') : _.get(response, 'data'),
            remainSteps = availableSteps.slice(1);
            $ctrl.responseData.push(step);
            if (remainSteps.length) {
              saveSteps(remainSteps);
            }
            if (!remainSteps.length && !isError) {
              $ctrl.isSaved = true;
              $ctrl.onSaving = false;
              $ctrl.cancel({ value: $ctrl.responseData, type: 'power-deadline' });
            }
            if (isError) {
              resetVariables();
            }
          }
          
          /**
           * @function
           * @name resetVariables
           * @description Helper method to set falsy value in some variable's
           * @returns {void}
           */
          function resetVariables() {
            $ctrl.isSaved = true;
            $ctrl.onSaving = false;
          }

          /**
           * @ngdoc method
           * @name failureStepResponse
           * @description It will call when step API gets failed due to some reason
           */
          function failureStepResponse() {
            isError = true;
            resetVariables();
            $ctrl.isSaved = false;
            var resetSteps = availableSteps.slice(1);
            if (resetSteps.length) {
              saveSteps(resetSteps);
            }
            $ctrl.stepsUpdate.length = 0;
          }
          
          /**
           * @ngdoc method
           * @name updateStep
           * @description Update Step Assigner
           * @param {Object} value
           */
          function updateStep(value) {
            if($ctrl.process.type === 'task') {
              value.deadline = angular.copy(value.deadlineEdit.deadline);
              var step = _.omit(value, ['deadlineEdit','deadlineUpdate']),
                params = { id: step.id, action_id: step.run_id, update_dependent_deadlines: true, with: 'threads' },
                deadline = DateUtils.toUTC(moment(step.deadline)).format(DATEFORMAT.DEFAULT),
                savedTask = angular.extend({}, step, { deadline: deadline });
              return CompactTaskService.saveTask(params, savedTask);
            } else {
              var step = _.omit(value, ['deadlineUpdate']);
              return StepService.updateStep({ id: step.id, checklist_id: $ctrl.process.id, skipNotFound: true }, step);
            }
          }

          /**
           * @ngdoc method
           * @name onTimeUnitChange
           * @description update deadline unit value
           * @param {Object} deadlineUnit
           * @param {Object} step
           * @returns {void}
           */
          function onTimeUnitChange(deadlineUnit, step) {
            if($ctrl.process.type === 'task') {
              if (deadlineUnit.title !== step.deadlineEdit.unit) {
                step.deadlineEdit.unit = angular.copy(deadlineUnit.title);
                step.deadlineEdit.shortHand = angular.copy(deadlineUnit.shortHand);
                $ctrl.selectedDeadlineDate = selectedDeadlineType();
                _.set(step, 'deadlineEdit.deadline', moment($ctrl.selectedDeadlineDate).add(step.deadlineEdit.value, step.deadlineEdit.shortHand).toDate());
              }
            } else {
              if (deadlineUnit.title !== step.deadline.unit) {
                step.deadline.unit = angular.copy(deadlineUnit.title);
                if($ctrl.process.type === 'blueprint') {
                  step.deadlineEdit.unit = '';
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name onTimeIncrease
           * @description Deadline Time Value Increase
           * @param {Object} step
           * @returns {void}
           */
          function onTimeIncrease(step) {
            increaseTime(step, 1);
          }

          /**
           * @ngdoc method
           * @name onTimeDecrease
           * @description Deadline Time Value Decrease
           * @param {Object} step
           * @returns {void}
           */
          function onTimeDecrease(step) {
            if ($ctrl.process.type === 'task') {
                step.deadlineEdit.value = parseInt(step.deadlineEdit.value) - 1;
                $ctrl.selectedDeadlineDate = selectedDeadlineType();
                _.set(step, 'deadlineEdit.deadline', moment($ctrl.selectedDeadlineDate).add(step.deadlineEdit.value, step.deadlineEdit.shortHand).toDate());
                step.deadlineUpdate = true;
                $ctrl.isDeadlineUpdated = true;
            } else {
              if(step.deadline.value > 0) {
                step.deadline.value = parseInt(step.deadline.value) - 1;
                step.deadlineUpdate = step.deadline.value !== 0;
                $ctrl.isDeadlineUpdated = true;
              }
            }
          }

          /**
           * @ngdoc method
           * @name increaseTime
           * @description Deadline Time Value Increase
           * @param {Object} step
           * @param {*} timeStep
           * @returns {void}
           */
          function increaseTime(step, timeStep) {
            if($ctrl.process.type === 'task') {
              if(step.deadlineEdit.value < 7000) {
                step.deadlineEdit.value = parseInt(step.deadlineEdit.value) + timeStep;
                step.deadlineUpdate = true;
                $ctrl.selectedDeadlineDate = selectedDeadlineType();
                _.set(step, 'deadlineEdit.deadline', moment($ctrl.selectedDeadlineDate).add(step.deadlineEdit.value, step.deadlineEdit.shortHand).toDate());
                $ctrl.isDeadlineUpdated = true;
              }
            } else {
              if(step.deadline.value < 7000) {
                step.deadline.value = parseInt(step.deadline.value) + timeStep;
                step.deadlineUpdate = true;
                $ctrl.isDeadlineUpdated = true;
              }
            }
          }

          /**
           * @function
           * @name resetDeadline
           * @param {boolean} isManualReset
           * @description set on step Deadline Type value
           */       
          function resetDeadline(isManualReset) {
            _.forEach($ctrl.stepsUpdate, function (step) {
               stepReset(step);
            });
            $ctrl.isDeadlineUpdated = false;
            
            if ($ctrl.type === 'launchBlueprint' && isManualReset) {
              _.forEach($ctrl.stepsUpdate, function (step) {
                increaseTime(step, 0);
              });
            }
          }

          /**
           * @function
           * @name stepReset
           * @param {Object} step
           * @description Reset step value
           */
          function stepReset(step) {
            _.set(step, $ctrl.type === 'launchBlueprint' ? 'deadline' : 'deadlineEdit', {
              value: $ctrl.defaultTime,
              unit: _.get($ctrl.defaultUnit, 'title'),
              shortHand: _.get($ctrl.defaultUnit, 'shortHand')
            });
            step.deadlineUpdate = false;
            var updateListCount = _.filter($ctrl.stepsUpdate , 'deadlineUpdate').length;
            $ctrl.isDeadlineUpdated = updateListCount > 0;
          }

          /**
           * @function
           * @name deadlineUnitInit
           * @param {Object} step
           * @description on init deadline unit value
           */
          function deadlineUnitInit(step) {
            if($ctrl.process.type === 'task') {
              step.deadlineEdit.unit = _.get($ctrl.defaultUnit, 'title');
              step.deadlineEdit.shortHand = _.get($ctrl.defaultUnit, 'shortHand');
            } else {
              step.deadline.unit = _.get($ctrl.defaultUnit, 'title');
            } 
          }

          /**
           * @function
           * @name setDeadlineClass
           * @param {Object} deadline
           * @description set class in deadline value
           */
          function setDeadlineClass(deadline) {
            return TasksService.getClassUsingDate(deadline);
          }
          
          /**
           * @function
           * @name selectedDeadlineType
           * @description selected deadline type
           */
          function selectedDeadlineType() {
            return _.get($ctrl.selectedDeadlineType, 'value') === 'specificDate' ? $ctrl.specificDate : (_.get($ctrl.selectedDeadlineType, 'value') === 'today' ? $ctrl.today : $ctrl.process.startedAt);
          }

          /**
           * @function
           * @name onSpecificDateDeadlineUpdate
           * @description when Specific Date and time change Update Deadline
           */
          function onSpecificDateDeadlineUpdate() {
            _.forEach($ctrl.stepsUpdate, function (step) {
              if(step.deadlineUpdate === true) {
                $ctrl.selectedDeadlineDate = selectedDeadlineType();
                _.set(step, 'deadlineEdit.deadline', moment($ctrl.selectedDeadlineDate).add(step.deadlineEdit.value, step.deadlineEdit.shortHand).toDate());
              }
            });
          }

          /**
           * @ngdoc method
           * @name saveBulkDeadline
           * @description Update Step Bulk Deadline
           */
          function saveBulkDeadline(updatedSteps){
            $ctrl.updateSteps = [];
            $ctrl.isDeadlineUpdated = false;
            var run_id = _.get($ctrl.process, 'id');
            _.forEach(updatedSteps, function (step) {
              $ctrl.getDeadline = angular.copy(step.deadlineEdit.deadline);
              $ctrl.deadline = DateUtils.toUTC(moment($ctrl.getDeadline)).format(DATEFORMAT.DEFAULT);
              var stepsData = {
                "id": step.id,
                "deadline": $ctrl.deadline
              };
              $ctrl.updateSteps.push(stepsData);
            });
            var savedTask = { tasks: $ctrl.updateSteps },
            params = { org: _.get($rootScope, 'identity.default_organization.id'), run_id: run_id};
            TasksService.updateBulkTasks(params, savedTask).then(function (response) {
              $ctrl.isDeadlineUpdated = false;
              var responseData = _.get(response, 'data');
              $ctrl.isDeadlineUpdated = true;
              $ctrl.cancel({ value: responseData, type: 'power-deadline' });
            }, function () { });
          }
          //controller ends
        }
    });
})();

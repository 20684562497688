/**
 * @ngdoc Service
 * @name preferencesStore
 *
 * @module tallyfy
 *
 * @description
 * Service for preference storage
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('preferencesStore', function (_, $rootScope, AccountService, $q) {
      var self = this,
        preferences = [];

      /**
       * public method's
       */
      self.getPreferences = getPreferences;
      self.setPreferences = setPreferences;
      self.updatePreferences = updatePreferences;
      self.clearPreferences = clearPreferences;

      /**
       * @ngdoc method
       * @name setPreferences
       * @description set preferences
       * @param {*} res
       */
      function setPreferences(res) {
        clearPreferences();
        angular.extend(preferences, res);
        $rootScope.identity.preferences = angular.copy(preferences);
      }

      /**
       * @ngdoc method
       * @name getPreferences
       * @description get preferences
       * @param {*} userID
       */
      function getPreferences(userID) {
        var deferred = $q.defer();
        if ((!_.isEmpty(userID) && userID !== _.get($rootScope, 'identity.id')) || !preferences.length) {
          AccountService.getUserPreference(userID).then(function (pref) {
            deferred.resolve(pref.data);
          }, function (error) {
            deferred.reject(error);
          });
        } else {
          deferred.resolve(preferences);
        }
        return deferred.promise;
      }

      /**
       * @ngdoc method
       * @name updatePreferences
       * @description update preferences array
       * @param {*} pref
       */
      function updatePreferences(pref) {
        var index = _.findIndex(preferences, { 'slug': pref.slug });
        if (index >= 0) {
          preferences[index] = pref;
        } else {
          preferences.push(pref);
        }
        $rootScope.identity.preferences = angular.copy(preferences);
      }

      /**
       * @ngdoc method
       * @name clearPreferences
       * @description clear preferences array
       */
      function clearPreferences() {
        preferences.length = 0;
      }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.contentChangedAndIdle
 * @description 
 * Check for content changes and idle state reached. Advanced form of debounce
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('contentChangedAndIdle', contentChangedAndIdle);

  /*@ngInject*/
  function contentChangedAndIdle(_, $parse, $timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attr) {
        var flagTimeout,
          deregisterNgModelWatcher,
          isTriggerContentChangeOnce = !_.isUndefined($attr.triggerContentChangeOnce);
        
        setFlag(false);
        deregisterNgModelWatcher = $scope.$watch($attr.ngModel, trigger);

        function trigger(newValue, oldValue) {
          if (newValue !== oldValue) {
            if (isTriggerContentChangeOnce && $scope.$eval($attr.contentChangedAndIdle)) {
              deregisterNgModelWatcher();
            } else {
              setFlag(false);
              if(flagTimeout){
                $timeout.cancel(flagTimeout);
              }
              flagTimeout = $timeout(function(){
                setFlag(true);
              }, 1000);
            }
          }
        }

        function setFlag(value) {
          $parse($attr.contentChangedAndIdle).assign($scope.$parent, value);
        }

        $scope.$on('$destroy', function () {
          deregisterNgModelWatcher();
        });
      }
    };
  }
})();
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('Principal', Principal);
  /*@ngInject*/
  function Principal($q, AccountService, OrganizationsRepository, $localStorage, $rootScope, AuthServerProvider, UsersService, _, PLANS, $timeout, pollingService, $log, $analytics, TFY_EVENTS, $state, BeaconService, USER_STATE) {
    var _identity,
      _authenticated = false,
      identityDeferred,
      service = {
        authenticate: authenticate,
        hasAnyAuthority: hasAnyAuthority,
        hasAuthority: hasAuthority,
        identity: identity,
        isAuthenticated: isAuthenticated,
        isIdentityResolved: isIdentityResolved,
        setOrganization: setOrganization,
        setIdentity: setIdentity,
        setAuthoritiesAndAuthenticated: setAuthoritiesAndAuthenticated,
        setUserData: setUserData,
        getCurrentOrganization: getCurrentOrganization,
        isCurrentPlan: isCurrentPlan,
        getOrganizationToken: getOrganizationToken,
        setDefaultORG: setDefaultORG,
        hasAccessDeniedToState: hasAccessDeniedToState
      };
    return service;

    function setIdentity(identity) {
      _identity = identity;
      $rootScope.identity = _identity;
    }

    function setAuthoritiesAndAuthenticated() {
      _identity.authorities = ['USER_ROLE'];
      _authenticated = true;
    }

    function authenticate(identity) {
      setIdentity(identity);
      _authenticated = identity !== null;
    }

    function setOrganization(orgs) {
      var defer = $q.defer();
      var org = _.isArray(orgs) ? orgs[0] : orgs;
      setUserData(orgs).then(function () {
        if (_identity) {
          _identity.default_organization = org;
          setDefaultORG(_.pick(org, ['id', 'slug']));
        }
        defer.resolve(_identity);
      }, function (error) {
        defer.reject(error);
      });

      return defer.promise;
    }

    function hasAnyAuthority(authorities) {
      if (!_authenticated || !_identity || !_identity.authorities) {
        return false;
      }

      for (var i = 0; i < authorities.length; i++) {
        if (_identity.authorities.indexOf(authorities[i]) !== -1) {
          return true;
        }
      }

      return false;
    }

    function hasAccessDeniedToState(state) {
      return _.indexOf(['settings.billing', 'settings.org.updateOrgProfile', 'settings.compliance'], state) !== -1 && ((_identity.role !== "admin" && _.get($rootScope, 'identity.default_organization.plan_code') !== 'free'));
    }

    function hasAuthority(authority) {
      if (!_authenticated) {
        return $q.when(false);
      }

      return this.identity().then(function (_id) {
        return _id.authorities && _id.authorities.indexOf(authority) !== -1;
      }, function () {
        return false;
      });
    }

    function identity(force) {
      // Return the deferred object if the promise is already in progress / pending - this is to avoid duplicate calls to the same set of APIs
      if (_.get(identityDeferred, 'state') === 'pending') {
        return identityDeferred.promise;
      }

      identityDeferred = $q.defer();
      if ($rootScope.userState === USER_STATE.PUBLIC) {
        getAccountThen();
      } else if ((!AuthServerProvider.getToken() || !AuthServerProvider.hasValidToken()) || $rootScope.userState === USER_STATE.GUEST) {
        getAccountCatch();
      } else {
        if (force === true) {
          setIdentity(undefined);
        }
        // check and see if we have retrieved the identity data from the server.
        // if we have, reuse it by immediately resolving
        if (angular.isDefined(_identity)) {
          identityDeferred.resolve(_identity);
        } else {
          // retrieve the identity data from the server, update the identity object, and then resolve.
          AccountService.me()
            .then(getAccountThen, getAccountCatch);
        }
      }
      return identityDeferred.promise;

      function getAccountThen(account) {
        var identity = {};
        if (account) {
          identity = account.data;
          identity.authorities = ['USER_ROLE'];
          _authenticated = true;
        }
        if (!$localStorage.default_organization_Id) {
          identity.default_organization = {};
          setIdentity(identity);
          identityDeferred.resolve(identity);
        } else {
          OrganizationsRepository
            .get({
              with: 'subscription',
              org: $localStorage.default_organization_Id
            }).then(function (response) {
              identity.default_organization = response.data;
              identity.organizations = response.data;
              setIdentity(identity);
              setUserData().then(function () {
                identityDeferred.resolve(identity);
              });
            }, function (error) {
              if (error.status === 404) {
                forceClearSession();
              }
            });
        }
      }

      function getAccountCatch() {
        setIdentity(undefined);
        _authenticated = false;
        delete $localStorage.default_organization_Id;
        identityDeferred.resolve(_identity);
      }
    }

    function setUserData(orgs) {
      var org_id, deferred, sources, isDefaultOrg;
      /**
       * @property isDefaultOrg
       * boolean property that checks if orgs hold a single org or all list of user
       */
      isDefaultOrg = !_.isArray(orgs);
      deferred = $q.defer();
      org_id = _.get($rootScope, 'identity.default_organization.id');
      $rootScope.orgSamlInfo = _.get($rootScope, 'identity.default_organization');
      if (orgs) {
        var org = !isDefaultOrg ? orgs[0] : orgs;
        org_id = _.get(org, 'id');
        $rootScope.orgSamlInfo = org;
      }

      sources = [
        UsersService.getLighterUsersList({
          org: org_id
        }),
        UsersService.getPreferences({
          org: org_id
        }),
        AccountService.getAccount({
          org: org_id,
          with: 'country,role'
        })
      ];

      if (isDefaultOrg) {
        sources.push(
          OrganizationsRepository.get({
            with: 'subscription',
            org: org_id
          }));
      }

      $q.all(sources).then(function (response) {
        var roleName, user;
        user = _.get(response, '[2].data');

        // Start the poll when user gets logged in and switch the organization
        pollingService.startPolling(response[0], response[1], org_id);
        
        if (!_.isEmpty(_identity)) {
          _identity['role'] = roleName = _.get(user, 'role');
          _identity['timezone'] = _.get(user, 'timezone');
          _identity['date_format'] = _.get(user, 'date_format');
          _identity['UTC_offset'] = _.get(user, 'UTC_offset');
          _identity['organizations'] = isDefaultOrg ? _.get(response, '[3].data') : orgs;
          _identity['approved_at'] = _.get(user, 'approved_at');
          _identity['job_description'] = _.get(user, 'job_description');
          _identity['job_title'] = _.get(user, 'job_title');
          _identity['team'] = _.get(user, 'team');
          _identity['phone'] = _.get(user, 'phone');
        }
        /*@TODO: Uncomment this when roles & permissions are back*/
        /*if (permissions && roleName) {
          deferred.resolve(_identity);
        } else {
          $growl.error('You do not have permission to access this organization');
          deferred.reject();
        }*/
        deferred.resolve(_identity);
      }, function (error) {
        deferred.reject();
        throw new Error(error);
      });
      return deferred.promise;
    }

    function isAuthenticated() {
      return _authenticated;
    }

    function isIdentityResolved() {
      return angular.isDefined(_identity);
    }

    function getCurrentOrganization() {
      return $rootScope.userState === USER_STATE.GUEST ? _.get($rootScope.identity, 'guest.organization') : _.get(_identity, 'default_organization');
    }

    function isCurrentPlan(planCode) {
      if (!_.isUndefined(_identity.default_organization.subscription) && _.isObject(_identity.default_organization.subscription.data.plan_code) && !_.isEmpty(_identity.default_organization.subscription.data.plan_code)) {
        if (_identity.default_organization.subscription.data.plan_code === planCode) {
          return true;
        }
      } else if (planCode === PLANS.FREE) {
        return true;
      }
      return false;
    }

    /**
     * @ngdoc method
     * @description Return organization token
     * @returns {String} token
     */
    function getOrganizationToken() {
      return $localStorage.authenticationToken;
    }

    /**
     * @ngdoc method
     * @name setDefaultORG
     * @description A helper method that store org's primary info in $localStorage for future use.
     * @todo orgSlug will be removed once urls will become slug free.
     * @public
     * @param {*} orgProps 
     * 
     * @returns void
     */
    function setDefaultORG(orgProps) {
      var orgID, orgSlug;
      orgID = _.get(orgProps, 'id', '');
      orgSlug = _.get(orgProps, 'slug', '');
      $localStorage.default_organization_Id = orgID;
      $localStorage.default_organization_slug = orgSlug;
      $log.debug('Current org', orgProps);
    }

    /**
     * @ngdoc method
     * @name forceClearSession
     * @description Force clear session
     */
    function forceClearSession() {
      AuthServerProvider.logout().then(function () {
        authenticate(null);
        BeaconService.logout('logout');
        $analytics.setUsername(null);
        $analytics.setUserProperties(null);
        pollingService.cancelPolling();
        $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, { reset: 'no', setTheme: '' });
        $state.go('authHandler');
      }, function () {
        $state.go('authHandler');
      });

    }
  }
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorSnippet', {
      templateUrl: 'app/components/documentPaneEditor/editorSnippet/editor-snippet.component.html',
      bindings: {
        snippetId: '<',
        snippetTitle: '<',
        editorScope: '<?',
        hideDeleteButton: '<?',
        isStaticElement: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $timeout, $element, Helper) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.removeField = removeField;

          function onInit() {
            if ($ctrl.editorScope) {
              if (!$ctrl.editorScope.fieldMode) {
                $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
                if (!$ctrl.isStaticElement) {
                  $timeout(function () {
                    $element.attr('draggable', 'true');
                    $element[0].addEventListener('dragstart', dragCallback);
                  }, 350);
                }
              }
            }
            if (!$ctrl.isStandalone) {
              $rootScope.$emit('DOCUMENT_SNIPPET_ATTACHED', { id: $ctrl.snippetId, title: $ctrl.snippetTitle });
            }
          }

          function onChanges() { }

          function onDestroy() {
            $element[0].removeEventListener('dragstart', dragCallback);
          }

          function dragCallback(e) {
            var isInEditor = $(e.target).closest('.fr-element').length;
            if (isInEditor) {
              $element.attr('id', Helper.guid());
            }
            e.dataTransfer.setData('element', 'editor-snippet');
            e.dataTransfer.setData('args', JSON.stringify({
              snippetId: $ctrl.snippetId,
              snippetTitle: $ctrl.snippetTitle,
              removeElement: isInEditor,
              sourceId: isInEditor ? $element.attr('id') : void 0
            }));
          }

          function removeField(e) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            var fieldElement = angular.element(e.target).closest('editor-snippet');
            if ($ctrl.editorScope && $ctrl.editorScope.froalaOptions) {
              var editor = $ctrl.froalaOptions.froalaEditor;
              editor.selection.setBefore(fieldElement[0]);
              fieldElement[0].parentNode.removeChild(fieldElement[0]);
              editor.selection.restore();
            }
            $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
            $rootScope.$emit('SNIPPET_FIELD_EDITOR:REMOVED', { snippetId: $ctrl.snippetId });
          }
        }
    })
})();
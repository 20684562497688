/**
 * @ngdoc Config
 * @name tallyfy.processTriggerDetail.component.processTriggerDetail
 * @module tallyfy.processTriggerDetail
 *
 * @description
 * A component to recurring schedule
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('processTriggerDetail', {
      templateUrl: 'app/components/compact/components/processTriggerDetail/compact-process-trigger.component.html',
      bindings: {
        process: '<',
        selectedId: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $state, $scope, $rootScope, Helper, AuthPlan, ProcessService, MagicLinkService, ENV_CONFIG, FORM_FIELD, FieldService, $filter, $timeout) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.tabs = [
            { id: 'middleware', translationVal: 'middleware', isExpanded: false },
            { id: 'recurringSchedule', translationVal: 'recurringSchedule', isExpanded: false },
            { id: 'inboundEmail', translationVal: 'inboundEmail', isExpanded: false },
            { id: 'inboundWebhook', translationVal: 'inboundWebhook', isExpanded: false },
            { id: 'magicLink', translationVal: 'magicLink', isExpanded: false },
            { id: 'viaAPI', translationVal: 'viaAPI', isExpanded: false }
          ];

          $ctrl.onPaneClose = onPaneClose;
          $ctrl.copyLink = copyLink;
          $ctrl.getMagicLinkContent = getMagicLinkContent;
          $ctrl.openKOFField = openKOFField;
          $ctrl.formFields = FieldService.getFields();
          $ctrl.newField = newField;
          $ctrl.onAccordionChanges = onAccordionChanges;

          function onInit() {
            $ctrl.appEnv = _.toLower(ENV_CONFIG.APP_ENV);
            $ctrl.steps = _.get($ctrl.process, 'steps.data');
            $ctrl.createLink = getMagicLinkContent('launchProcess');
            $ctrl.currentUserEmail = _.get($rootScope, 'identity.email');
            $ctrl.checkKOFRequired = _.filter($ctrl.process.prerun, function (step) {
              return step.required === true;
            });
            $ctrl.preCreatedTextareaField = _.filter($ctrl.process.prerun, function (step) {
              return step.label === FORM_FIELD.EMAIL_BODY_FIELD && step.field_type === 'textarea';
            });
            $ctrl.preCreatedFileField = _.filter($ctrl.process.prerun, function (step) {
              return step.label === FORM_FIELD.EMAIL_ATTACHMENTS_FIELD && step.field_type === 'file';
            });
          }

          function onChanges() { }

          function onDestroy() { }

          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e 
           * 
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            $rootScope.$emit('RIGHT_PANE:CLOSE', { event: e });
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', { event: e });
          }

          function copyLink(link, title, $event) {
            Helper.copyToClipboard(link, title, $event);
          }

          /**
           * @ngdoc method
           * @name getMagicLinkContent
           * @param {*} type 
           * 
           * @description
           * get magic link content
           */
          function getMagicLinkContent(type) {
            var text, htmlText, action = MagicLinkService.getActionMagicLinkMethods()[type]({
              selectedProcess: $ctrl.process
            });
            text = action.getLink();
            htmlText = action.getText();
            return {
              text: text,
              htmlText: htmlText
            };
          }

          function openKOFField(id) {
            if(id === 1) {
              $ctrl.preCreatedTextareaField.length = 1;
              preCreatedInboundEmailField(id);
            }
            if(id === 6) {
              $ctrl.preCreatedFileField.length = 1;
              preCreatedInboundEmailField(id);
            }
          }

          function preCreatedInboundEmailField(id) {
            newField($ctrl.formFields[id]);
            $ctrl.checkPreCreatedField = false;
          }

          /**
           * @ngdoc method
           * @name newField
           * @description add new field 
           */
          function newField(type) {
            $ctrl.fieldModel = type;
            if (!$ctrl.fieldModel) {
              return;
            }
            var newField = getNewField();
            newField.isExpanded = true;
            if(type.field_type === 'textarea') {
              newField.label = FORM_FIELD.EMAIL_BODY_FIELD;
              newField.use_wysiwyg_editor = true;
              newField.position = 1;
            }
            if(type.field_type === 'file') {
              newField.label = FORM_FIELD.EMAIL_ATTACHMENTS_FIELD;
              newField.position = 2;
            }
            newField.guidance = $filter('translate')('steps.captures.form.label.fields.preCreatedFieldGuidance');
            newField.isFFSave = true;
            fieldUpdate(newField);
            $ctrl.fieldModel = null;
          }

          /**
           * @ngdoc method
           * @name fieldUpdate
           * @param {Object} field
           * @description 
           * Update prerun field 
           */
          function fieldUpdate(field) {
            if (!field.isFFSave) {
              return;
            }

            var process = angular.copy($ctrl.process);
            delete field.id;
            $ctrl.process.prerun.push(field);
            process.prerun.push(field);
            ProcessService.update({
              id: $ctrl.process.id
            }, process).then(function (response) {
              field.isFFSave = false;
              $ctrl.process = _.get(response, 'data');
              $rootScope.$emit('KO_FIELD:UPDATED', { process: $ctrl.process });
              $ctrl.process.last_updated = _.get(response, 'data.last_updated');
              $rootScope.$emit("PROCESS:UPDATED", {
                process: $ctrl.process
              });
              Helper.showChangesSavedGrowl();
            }, function () { });
          }

          /**
           * @ngdoc method
           * @name getNewField
           * @description Get new field object
           * @returns {Object} field
           */
          function getNewField() {
            if (!Helper.checkAccessAuthority())
              return;
            var field, lastField, newId,
              defaultProperties = {
                label: null,
                guidance: null
              };
            field = angular.copy($ctrl.fieldModel);
            newId = Helper.getId();
            lastField = _.maxBy($ctrl.process.prerun, 'position');
            field.position = (lastField ? lastField.position : 0) + 1;
            field.id = newId;
            field.slug = newId;
            angular.extend(field, defaultProperties);
            if (!field.title) {
              field.title = field.name + " - " + field.slug;
            }
            return field;
          }

          //Handle accordion change
          function onAccordionChanges (e, tab) {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            if ($scope.otherOptionsAccordion) {
              $scope.otherOptionsAccordion.collapseAll();
            }
            _.forEach($ctrl.tabs, function (opt) {
              if (opt.id === tab.id) {
                opt.isExpanded = !opt.isExpanded;
              }
            });
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorVariable', {
      templateUrl: 'app/components/documentPaneEditor/editorVariable/editor-variable.component.html',
      bindings: {
        fieldId: '<',
        editorScope: '<?',
        hideDeleteButton: '<?',
        isStandalone: '<',
        isStaticElement: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $element, $timeout, Helper, DateUtils, OrganizationsService, moment) {
          var $ctrl = this,
            fieldEditorRemovedWatcher;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.removeField = removeField;
          $ctrl.getDisplayDate = getDisplayDate;

          $ctrl.dateFormat = OrganizationsService.dateFormat();

          function onInit() {
            if ($ctrl.editorScope) {
              if (!$ctrl.editorScope.fieldMode) {
                $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
                if (!$ctrl.isStaticElement) {
                  $timeout(function () {
                    $element.attr('draggable', 'true');
                    $element[0].addEventListener('dragstart', dragCallback);
                  }, 350);
                }
              }
            }
            if (!$ctrl.isStandalone) {
              $rootScope.$emit('DOCUMENT_VARIABLE_ATTACHED', $ctrl.field);
            }
          }

          function onChanges(changes) {
            if (changes.editorScope && changes.editorScope.currentValue) {
              if (!changes.editorScope.currentValue.fieldMode) {
                $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
                $ctrl.field = _.find($ctrl.froalaOptions.process.prerun, { id: $ctrl.fieldId });
              } else {
                $ctrl.field = _.find($ctrl.editorScope.process.prerun, { id: $ctrl.fieldId });
              }
              if (!$ctrl.field) {
                removeField();
              }
            }
          }

          function onDestroy() {
            fieldEditorRemovedWatcher();
            $rootScope.$emit('FIELD_EDITOR:DESTROYED', { fieldId: $ctrl.fieldId });
            $element[0].removeEventListener('dragstart', dragCallback);
          }

          function dragCallback(e) {
            var isInEditor = $(e.target).closest('.fr-element').length;
            if (isInEditor) {
              $element.attr('id', Helper.guid());
            }
            e.dataTransfer.setData('element', 'editor-variable');
            e.dataTransfer.setData('args', JSON.stringify({
              fieldId: $ctrl.fieldId,
              fieldType: $ctrl.field.field_type,
              fieldLabel: $ctrl.field.label,
              sourceId: isInEditor ? $element.attr('id') : void 0
            }));
          }

          function removeField(e) {
            if (e) {
              e.preventDefault();
            }
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            if ($ctrl.editorScope && $ctrl.editorScope.froalaOptions) {
              var editor = $ctrl.froalaOptions.froalaEditor;
              editor.selection.setBefore($element[0]);
              $element[0].parentNode.removeChild($element[0]);
              editor.selection.restore();
            }
            $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
            $rootScope.$emit('VARIABLE_FIELD_EDITOR:REMOVED', { fieldId: $ctrl.fieldId });
          }

          function getDisplayDate(dateStr, fieldId) {
            if (Helper.isObjectEmpty(dateStr)) {
              return '';
            }
            var prerun = _.get($ctrl.editorScope, 'process.prerun');
            if (typeof dateStr === 'object') {
              return dateStr.format($ctrl.dateFormat.format);
            } else if (prerun.length > 0 && fieldId) {
              var field = _.find(prerun, { id: fieldId });
              if (field.collect_time) {
                return DateUtils.toLocal(dateStr).format($ctrl.dateFormat.format);
              } else {
                return DateUtils.toLocal(dateStr).format($ctrl.dateFormat.dateFormat.toUpperCase());
              }
            }
          }

          fieldEditorRemovedWatcher = $rootScope.$on('FIELD_EDITOR:REMOVED', function (e, data) {
            if ($ctrl.fieldId !== data.fieldId) {
              return;
            }
            $element[0].parentNode.removeChild($element[0]);
            $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
            $rootScope.$emit('VARIABLE_FIELD_EDITOR:REMOVED', { fieldId: $ctrl.fieldId });
          });

        }
    });
})();
/**
 * @ngdoc Provider
 * @name $confirm
 *
 * @module $confirm
 *
 * @description
 * this is an independent module for confirm modal box
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('confirmModal')
    .provider('$confirm', $confirm);

  function $confirm() {
    this.options = {
      'body': 'global.confirmModal.body',
      'header': 'global.confirmModal.header',
      'buttons': {
        'accept': 'global.confirmModal.buttons.accept',
        'cancel': 'global.confirmModal.buttons.cancel'
      },
      animate : false,
      backdrop : true
    };

    this.setOptions = function (options) {
      angular.extend(this.options, options);
    };

    /*jshint validthis: true */
    this.$get = function ($uibModal) {
      var $this = this;
      return function (options) {
        var templateOptions = $this.options;
        if (options) {
          angular.extend(templateOptions, options);
        }
        return $uibModal.open({
          animation: templateOptions.animate,
          backdrop : templateOptions.backdrop,
          windowClass : "black-overlay modal-confirm-container",
          templateUrl: 'app/components/confirm/confirm.html',
          controller: 'ConfirmationModalController as $ctrl',
          resolve: {
            options: function () {
              return templateOptions;
            }
          }
        }).result;
      };
    };
  }
})();
/**
 * @ngdoc Service
 * @name tallyfy.universalSearchService
 *
 * @module tallyfy
 *
 * @description
 * universalSearchService
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('universalSearchService', universalSearchService);

  /*@ngInject*/
  function universalSearchService($uibModal) {
    var self = this;

    self.searchParams = {};

    self.getTabs = function () {
      return [
        { type: "blueprint", value: "blueprint", name: "global.universalSearch.blueprints", class: "active", total: 0, iconClass: 'fa-file' },
        { type: "process", value: "process", name: "global.universalSearch.processes", class: "", total: 0, iconClass: 'fa-rocket pos-rlt' },
        { type: "task", value: "task", name: "global.universalSearch.tasks", class: "", total: 0, iconClass: 'fa-check' },
        { type: "snippets", value: "snippet", name: "global.universalSearch.snippets", class: "", total: 0, iconClass: 'fa-file' }
      ];
    };

    self.getSearchParams = function () {
      return self.searchParams;
    };

    self.setSearchParams = function (params) {
      self.searchParams = params;
    };

    self.openGlobalSearchModal = function (event) {
      event.stopPropagation();
      $uibModal.open({
        component: 'universalSearch',
        windowClass: 'universal-search-modal',
        resolve: {
          openModal: function () {
            return true;
          }
        }
      });
    };
  }
})();

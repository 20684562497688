/**
 * @ngdoc Directive
 * 
 * @name tallyfy.altHotkeys
 * 
 * @author Austin Willis ( gmail::austinwillis8@gmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .directive('altHotkeys', altHotkeys);

  function altHotkeys() {
    var directive = {
      restrict: 'E',
      link: linkFunc
    };
    
    return directive;

    function linkFunc(scope, element, attrs) {
      document.onkeyup = function(e) {
        var e = e || window.event;
        if (e.altKey && e.ctrlKey) {
          var info = scope.$eval(attrs.hotkeyCombos);
          info.forEach(function(element) {
            if (e.which === element.key) {
              element.callback();
            }
          });
        }
      };
    }      
  }
})();
(function() {
  'use strict';
  
    var innerGrowlNotification = {
      templateUrl:  'app/modules/growl/growl.html'
    };
    
    angular
        .module('tallyfy')
        .component('innerGrowlNotification', innerGrowlNotification);
})();
/**
 * @ngdoc Directive
 * 
 * @name tallyfy.matchPassword
 * 
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .directive('matchPassword', [function () {
        
      return {
        require: 'ngModel',

        link: function (scope, elem, attrs, ctrl) {
            var newPassword = '#' + attrs.matchPassword;
            var validation = 'validation' + elem.context.id;
            elem.add(newPassword).on('keyup', function () {
            scope.$apply(function () {
              var match = elem.val() && (elem.val() === angular.element(newPassword).val());
              ctrl.$setValidity('passwordMatch', match);
              scope.password_policy.passwords_match = match;
              if(match && scope.valid) {
                angular.element('#' + validation).attr('hidden', true);
              } else if(elem.val() && scope.is_focus){
                angular.element('#' + validation).removeAttr('hidden');
              }
            });
            scope.$on('$destroy', function() {
                elem.unbind('keyup');
            });
          });
          
        }
      };
    }]);
})();
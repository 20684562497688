(function () {
  'use strict';
  angular.module('tallyfy.list')
    .component('list', {
      bindings: {},
      templateUrl: 'app/components/list/list.component.html',
      controller: listController
    });

  /*@ngInject*/
  function listController(_, $q, $state, $uibModal, $filter, OrganizationsService, listService, ListTableService) {
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onDestroy = onDestroy;

    $ctrl.getListData = getListData;
    $ctrl.openAddNewListModal = openAddNewListModal;
    $ctrl.initTableConfig = initTableConfig;
    $ctrl.deleteList = deleteList;

    $ctrl.listPagination = { per_page: 20, page: 1 };
    $ctrl.perPageData = [5, 10, 20, 35, 50];

    function onInit() { }

    function onChanges() { }

    function onDestroy() { }

    function getListData(args) {
      var defer = $q.defer();
      var params = angular.extend($ctrl.listPagination, args);
      listService.getLists(_.pick(params, ['page', 'per_page']))
        .then(function (res) {
          angular.extend($ctrl.listPagination, res.meta.pagination);
          defer.resolve(res.data);
        }, function (err) {
          defer.reject(err);
        });
      return defer.promise;
    }

    function openAddNewListModal(uid) {
      var list = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
      $uibModal.open({
        component: 'addNewListModal',
        windowClass: 'add-new-list-modal',
        backdrop: 'static',
        resolve: {
          list: function () {
            return list;
          },
          action: function () {
            return uid ? 'edit' : 'add';
          }
        }
      }).result.then(function (args) {
        if (args.action === 'add') {
          $state.go('list.detail', { list_id: args.list.id });
        } else if (args.action === 'edit') {
          var list = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: _.get(args, 'list.uid') });
          angular.extend(list, args.list);
          $ctrl.tableOptions.gridConfig.dataSource.sync();
          $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
        }
      });
    }

    function deleteList(uid) {
      var list = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
      listService.deleteList(list.id)
        .then(function (res) {
          var tableData = $ctrl.tableOptions.gridConfig.dataSource.data();
          $ctrl.tableOptions.gridConfig.dataSource.data(
            _.filter(tableData, function (data) {
              return data.uid !== list.uid;
            })
          );
          $ctrl.tableOptions.gridConfig.dataSource.sync();
          $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
        });
    }

    function initTableConfig() {
      $ctrl.tableOptions = getTableConfig();
    }

    function editList(uid) {
      openAddNewListModal(uid);
    }

    function viewList(uid) {
      var list = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
      $state.go('list.detail', { list_id: list.id });
    }

    function getTableConfig() {
      return {
        beautyScrollStyle: true,
        gridConfig: ListTableService.getOptions(),
        tableState: {
          sort: {},
          columns: {}
        },
        tableMenus: {
          export: true,
          hideAndShowColumns: [{
            field: 'title',
            title: $filter('translate')('list.label.title'),
            initState: true
          }, {
            field: 'type',
            title:  $filter('translate')('list.label.type'),
            initState: true
          }, {
            field: 'created_at',
            title:  $filter('translate')('list.label.createdAt'),
            initState: true
          }]
        },
        tableFilterModel: {},
        templateScope: {
          callbacks: {
            viewList: viewList,
            editList: editList,
            deleteList: deleteList
          },
          variables: {
            dateFormat: OrganizationsService.getDateFormat()
          }
        }
      };
    }
  }
})();
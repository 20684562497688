(function () {
  'use strict';
  angular
    .module('tallyfy.config')
    .config(TrackJsConfig);
  /*@ngInject*/
  function TrackJsConfig($provide) {
    $provide.decorator("$exceptionHandler", ["$delegate", "$window", function ($delegate, $window) {
      return function (exception, cause) {
        if ($window.trackJs) {
          $window.trackJs.track(exception);
        }
      };
    }]);
  }
})();
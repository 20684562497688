/**
 * @ngdoc Component
 * @name tallyfy.components.tagList
 * @module tallyfy
 *
 * @description
 * Tag list logo component
 *
 * @author Adi Winata (gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com)
 */
(function () {
  'use strict';

  angular.module('tallyfy')
    .component('tagList', {
      templateUrl: 'app/components/tagList/tag-list.component.html',
      bindings: {
        isEdit: '<',
        tags: '<',
        tooltipDisabled: '<',
        containerWidth: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $timeout, $element) {
          var $ctrl = this;

          /**
           * angularJs component life-cycle
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public component's properties
           */
          $ctrl.tooltipTitle = '';
          $ctrl.moreItem = 0;

          /**
           * private component's properties
           */
          var tagsListener = undefined;

          /**
           * @ngdoc method
           * @name onInit
           * 
           * @description
           * on init component's handler
           */
          function onInit() { }

          /**
           * @ngdoc method
           * @name onChanges
           * 
           * @description
           * on component changes handler
           */
          function onChanges() {
            $ctrl.tooltipTitle = getTooltipCaption();
            tagsListener = $timeout(calculateDisplay, 0);
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * on component destroy handler
           */
          function onDestroy() {
            if (tagsListener) {
              $timeout.cancel(tagsListener);
            }
          }

          /**
           * @ngdoc method
           * @name getTooltipCaption
           * 
           * @description
           * get tooltip text title
           */
          function getTooltipCaption() {
            return _.reduce($ctrl.tags, function (tooltipCaption, tag) {
              tag.title = removeHash(tag.title);
              return tooltipCaption + (!tooltipCaption ? '#' : ', #') + tag.title;
            }, '');
          }

          function removeHash(query) {
            return query.replaceAll(/#/g,'');
          }

          /**
           * @ngdoc method
           * @name calculateDisplay
           * 
           * @description
           * calculate the display of tags
           */
          function calculateDisplay() {
            var tagsContent = $element.find('.tag-content');
            $ctrl.moreItem = 0;
            _.forEach(tagsContent, function (tag) {
              if (tag.offsetLeft + tag.offsetWidth > ($ctrl.containerWidth || 500)) {
                tag.parentNode.removeChild(tag);
                $ctrl.moreItem++;
              }
            });
          }
        }
    });
})();
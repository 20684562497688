/**
 * @ngdoc Service
 * @name tallyfy.organizations.customBrandingService
 *
 * @module tallyfy.organizations
 *
 * @description
 * service for manage custom branding
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .service('customBrandingService', customBrandingService);

  /*@ngInject*/
  function customBrandingService(_) {
    var self = this,
      brandingColors = [
        ['Cioccolato', [
          '#c74b16',
          '#913710',
          '#5c230a']
        ],
        ['Red', [
          '#c71f16',
          '#911710',
          '#5c0e0a']
        ],
        ['Pink', [
          '#c7166f',
          '#911051',
          '#5c0a33']
        ],
        ['Purple', [
          '#6c16c7',
          '#4f1091',
          '#320a5c']
        ],
        ['Indigo', [
          '#2516c7',
          '#1b1091',
          '#110a5c']
        ],
        ['Blue', [
          '#1663c7',
          '#104891',
          '#0a2e5c']
        ],
        ['Light Blue', [
          '#16a9c7',
          '#107c91',
          '#0a4e5c']
        ],
        ['Cyan', [
          '#16c79e',
          '#109173',
          '#0a5c49']
        ],
        ['Green', [
          '#16c72e',
          '#109121',
          '#0a5c15']
        ],
        ['Light Green', [
          '#86c716',
          '#629110',
          '#3e5c0a']
        ],
        ['Lime', [
          '#c7c116',
          '#918d10',
          '#5c590a']
        ],
        ['Yellow', [
          '#c79816',
          '#916f10',
          '#5c460a']
        ],
        ['Amber', [
          '#c76f16',
          '#915110',
          '#5c330a']
        ],
        ['Brown', [
          '#a66037',
          '#794628',
          '#4d2c19']
        ]
      ];

    self.defaultColor = {
      'BASE_COLOR': '#3fb65b',
      'WHITE_COLOR': '#FFFFFF',
      'GRAY_COLOR': '#505B6A'
    };

    /**
     * @ngdoc method
     * @name getSwatches
     * @description Get swatches
     */
    self.getSwatches = function () {
      return _.map(brandingColors, function (color) {
        return {
          name: _.head(color),
          colors: _.map(color[1], function (hexacode) {
            return {
              hex: hexacode
            };
          })
        };
      });
    };

    /**
     * @ngdoc method
     * @name getBtnRules
     * @description get rules for buttons
     */
    self.getBtnRules = function (colors) {
      return [
        ['.btn.nasty-green:not(:disabled), :not(.upgrade-btn).btn.btn-primary:not([disabled]), .btn.primary, .btn.btn-mode, .modal-confirm-container .modal-dialog .modal-content .modal-confirm.modal-success .btn-mode, .btn.btn-primary.saving.btn-animated, .btn.nasty-green.saving.btn-animated, .nasty-green',
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color]
        ],
        ['.btn.nasty-green:hover, .btn.nasty-green:focus, :not(.upgrade-btn).btn.btn-primary:hover, :not(.upgrade-btn).btn.btn-primary:focus, .modal-confirm-container .modal-dialog .modal-content .modal-confirm.modal-success .btn-mode:hover, .nasty-green:hover, .btn.btn-modal-primary:not([disabled]):hover, .progress-button:not(.i-have-completed) .progress-button-background:not(:disabled) .progress-button-percent, .progress-button:not(.i-have-completed):hover .progress-button-background',
          ['background-color', colors.dark_shade, true], // 'true' for !important rules
          ['color', colors.text_color]
        ],
        ['.btn:not([disabled]).btn-modal-primary, .btn[disabled].btn-animated.progress-button, .progress-button:not(.i-have-completed) .progress-button-background:not(:disabled)',
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getNavRules
     * @description get rules for nav-icon and nav-tabs
     */
    self.getNavRules = function (colors) {
      return [
        ['.app-header',
          ['border-top-color', colors.bg_color]
        ],
        ['.folded.show-text .navside .nav li.active::after',
          ['border-left-color', colors.bg_color]
        ],
        ['.folded.show-text .navside, .folded.show-text .navside .nav-sub, .folded.show-text .navside .nav-sub:before',
          ['background', '#202326']
        ],
        ['.folded.show-text .navside .nav li.active .sidebar-notch',
          ['display', 'block']
        ],
        ['.folded.show-text .navside .nav li.active a .nav-icon',
          ['color', this.defaultColor.WHITE_COLOR]
        ],
        ['.folded.show-text .navside .nav li.active a .nav-icon .far',
          ['color', this.defaultColor.WHITE_COLOR]
        ],
        ['.folded.show-text .navside .nav li.active a .nav-icon .fas',
          ['color', this.defaultColor.WHITE_COLOR]
        ],
        /*jshint multistr: true */
        ['.navbar .new-button, .navbar .new-button:focus, .navbar .new-button:hover, .folded.show-text .navside .nav \
          li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-submenus ul li span \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li \
          .show-examples-details ul li span, .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch span.btn-watch \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch span.btn-coworker \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch a.btn-coworker a \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch span.btn-coworker a \
          , .universal-search-top.universal-active .fa-search, .sidebar-brand-settings .dropdown .dropdown-nav i.far.fa-angle-down \
          , .member-invite-modal .modal-dialog .modal-body .form-group.invite-group-lists .dropdown .uib-dropdown-menu.dropdown-menu .dropdown-menu-lists ul li label.active',
          ['color', colors.alternate_color]
        ],
        ['.tallyfy-nav-tab:not(.with-secondary-border) .nav-tabs .nav-link.active:after, .universal-search-top.universal-active .search-content ul.nav li a.process.active \
          , .universal-search-top.universal-active .search-content ul.nav li a.task.active, .universal-search-top.universal-active .search-content ul.nav li a.snippets.active \
          , .horizontal-strip, .member-invite-modal .modal-dialog .modal-body .form-group.invite-group-lists .dropdown span.invite-lists-label \
          , .member-invite-modal .modal-dialog .modal-body .form-group.invite-group-lists .dropdown .uib-dropdown-menu.dropdown-menu .dropdown-menu-lists ul li label:hover',
          ['background-color', colors.alternate_color, true]
        ],
        ['.folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav-blueprints ul li a',
          ['color', colors.alternate_color],
          ['border-color', colors.alternate_color]
        ],
        ['.folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-submenus.open:before \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-submenus.open \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-examples-details.open \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-examples-details.open.first:before \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-examples-details.open.second:before \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-examples-details.open.third:before \
          , .universal-search-top.universal-active input.form-control, .sidebar-brand-settings .dropdown:hover .dropdown-nav:not(.dropdown-nav-mobile) .w-36 \
          , .sidebar-brand-settings .dropdown.open .dropdown-nav:not(.dropdown-nav-mobile) .w-36 \
          , .member-invite-modal .modal-dialog .modal-body .form-group.invite-group-lists .dropdown:hover',
          ['border-color', colors.alternate_color]
        ],
        ['.folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-submenus ul li \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav ul.task-process-blueprint-lists li .show-examples-details ul li \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch span.btn-watch \
          , .folded.show-text .navside .nav li.dropdown.dropdown-new-options.open .create-nav .tallyfy-watch span.btn-coworker',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.1).toRgbString(), true]
        ],
        ['.universal-search-top.universal-active input.form-control',
          ['caret-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getTaskBarRules
     * @description get rules for task/primary-filter bar
     */
    self.getTaskBarRules = function (colors) {
      return [
        ['.primary-task-bar .task-count-container',
          ['background-color', colors.dark_shade]
        ],
        ['.primary-task-bar .task-count-container .task-count, .t-white',
          ['color', colors.text_color]
        ],
        ['.primary-task-bar .all-tasks-label #simple-dropdown, .primary-task-bar .task-status-dropdown #simple-dropdown, .primary-task-bar .assignee-dropdown #simple-dropdown, .primary-task-bar .sort-dropdown #simple-dropdown, .run-dashboard-page .runs-dash-view .display-mode .selected, .run-dashboard-page .runs-dash-view .display-mode > div:hover, .task-detail-content-form-field .file-input-type-selector .input-type-label.active, .task-detail-content-form-field .file-input-type-selector .input-type-label:hover',
          ['border-bottom-color', colors.dark_shade]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getProcessBarRules
     * @description get rules for process-filter bar
     */
    self.getProcessBarRules = function (colors) {
      return [
        ['.primary-task-bar.primary-process-bar .process-filter .task-count',
          ['background-color', colors.dark_shade],
          ['color', colors.text_color]
        ],
        ['.primary-task-bar.primary-process-bar .process-filter .filter-dropdown-btn',
          ['box-shadow', 'inset 0 -3px 0 0' + colors.dark_shade],
          ['color', colors.text_color]
        ],
        ['.primary-task-bar.primary-process-bar .sort-ascending .dropdown-primary, .primary-task-bar .guest-filters',
          ['box-shadow', 'inset 0 -3px 0 0' + colors.dark_shade]
        ],
        ['a#simple-dropdown.btn.dropdown-primary.text-white',
          ['color', colors.text_color, true]
        ],
        ['.dropdown.open .uib-dropdown-menu.filter-dropdown .filter-item .dropdown-item:hover, .primary-task-bar.primary-process-bar .sort-ascending .dropdown-menu .dropdown-item:hover, .filter-dropdown .filter-item i',
          ['color', colors.alternate_color]
        ],
        ['.filter-dropdown .create-custom-view .fa-plus-circle, .filter-dropdown .filter-item:hover i, .filter-dropdown .create-custom-view:hover > span',
          ['color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getSelectDDRules
     * @description get rules for all select-dropdown
     */
    self.getSelectDDRules = function (colors) {
      return [
        ['.dropdown.open .dropdown-menu .dropdown-item:hover, .atwho-view .cur',
          ['background-color', colors.bg_color],
          ['color', colors.text_color]
        ],
        ['#orgList.dropdown-item:hover',
          ['background-color', colors.bg_color],
          ['color', colors.text_color, true]
        ],
        ['.ty-select-menu-items-highlight a',
          ['background-color', colors.bg_color, true]
        ],
        ['.ty-select-menu-items a:hover',
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color]
        ],
        ['.primary-task-bar .dropdown-menu',
          ['border-color', colors.alternate_color]
        ],
        [':not(#step-option-dropdown):not(#deadline-option):not(#time-unit).btn.dropdown-primary:hover, .btn.dropdown-primary:not([disabled]):focus',
          ['border-color', colors.bg_color]
        ],
        ['.select2-container.select2-dropdown-open, .c-select:focus, .atwho-view .cur',
          ['border-color', colors.bg_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getInputFieldsRules
     * @description get rules for input, textarea, tag-input fields
     */
    self.getInputFieldsRules = function (colors) {
      return [
        ['tags-input .tags.focused, .compact-task-detail.edit-mode .compact-task-detail-head .compact-item-title .sub-heading .form-control:focus, .kickoff-email-form .form-control:focus',
          ['border-color', colors.alternate_color]
        ],
        [':not(.assign-tab) tags-input .autocomplete .suggestion-list .suggestion-item.selected',
          ['background-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getLinksRules
     * @description get rules for links
     */
    self.getLinksRules = function (colors) {
      return [
        [':not(.tooltipped) > :not(.compare-plan).t-mossy-green, :not(.tooltipped) > :not(.compare-plan).t-mossy-green:hover, .fr-view a, .fr-view a.fr-file, .bp-ctx .bp-link, .process-summary .ngTruncateToggleText, .quick-action-links-container .vAccordion-quick-action-links v-pane .btn-quick-action-link, .content-card-body .preview-tab .tab-content .entity-content-view-all-button', //links
          ['color', colors.alternate_color]
        ],
        ['.bp-ctx .tpl-nav, .k-icon-brand-color', //froalalinks
          ['color', colors.alternate_color, true]
        ],
        ['.notification-dropdown .notifications-lists .list-group-item .block .btn-link, .notification-dropdown .notifications-lists .list-group-item .block .btn-link:hover, .filter-dropdown .filter-item.active a, .dropdown-menu .selected.highlighted a, .notifications-container .notifications-text-content .btn-link, .notifications-container .notifications-text-content .btn-link:focus, .notifications-container .notifications-text-content .btn-link:hover', //notification links
          ['color', colors.alternate_color]
        ],
        ['.pagination > .active > a', //pagination links
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color, true],
          ['border-color', colors.bg_color, true]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getCalendarRules
     * @description get rules for calendar and time picker
     */
    self.getCalendarRules = function (colors) {
      return [
        ['.has-calender .uib-datepicker-popup.dropdown-menu .uib-monthpicker table tr td .btn.active, .has-calender .uib-datepicker-popup.dropdown-menu .uib-monthpicker table tr td .btn:hover',
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color, true]
        ],
        ['.has-calender .uib-datepicker-popup.dropdown-menu .uib-daypicker table tr td .btn.active, .has-calender .uib-datepicker-popup.dropdown-menu .uib-daypicker table tr td .btn:hover',
          ['background-color', colors.bg_color, true],
          ['color', colors.text_color, true]
        ],
        ['.has-calender .uib-datepicker-popup.dropdown-menu .uib-daypicker table tr td .btn .text-info',
          ['color', colors.alternate_color, true]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getCheckBoxRadioBtnRules
     * @description get rules for check-box ans radio button / animation
     */
    self.getCheckBoxRadioBtnRules = function (colors) {
      var color = tinycolor(colors.alternate_color);
      return [
        // ['.md-check input[type=radio]:checked + i:not(.blueprint-check):not(.upgrade-check) > span, :not(.blueprint-check).primary', //checkbox and radio button animation
        //   ['background-color', colors.alternate_color]
        // ],
        ['@keyframes pulse ',
          [' 0% {box-shadow', '0 0 0 6px ' + color.setAlpha(0).toRgbString() + '}', false, true],
          [' 30% {box-shadow', '0 0 0 10px ' + color.setAlpha(0.3).toRgbString() + '}', false, true],
          [' 100% {box-shadow', '0 0 0 6px ' + color.setAlpha(0).toRgbString() + ',}', false, true]
        ],
        [':not(.tallyfy-grid-table-cell-contents):not(.card-text):not(.pull-right):not(.run-status).text-primary-tallfy', //notifivation radio buttons
          ['color', colors.bg_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getDashboardRules
     * @description get rules for dashboard page
     */
    self.getDashboardRules = function (colors) {
      return [
        ['.dashboard-header-user-info, .content-card-header .task-header, .content-card-header .process-header',
          ['background-color', colors.bg_color, true]
        ],
        ['.dashboard-header .user-detail .notification-info',
          ['background-color', tinycolor(colors.bg_color).darken(10).toRgbString(), true]
        ],
        ['.content-card-body .preview-tab .tab-header .tab-title.active, .content-card-body .preview-tab .tab-header .tab-title:active, .content-card-body .preview-tab .tab-header .tab-title:hover, .content-card-body .preview-tab .tab-header .tab-title.btn.btn-dropdown:not([disabled]):focus, .content-card-body .preview-tab .tab-header .tab-title.btn.dropdown-primary:not([disabled]):focus',
          ['border-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getTasksRules
     * @description get rules for tasks page
     */
    self.getTasksRules = function (colors) {
      return [
        ['.compact-task.compact-item-selected .compact-item-context:not(.issue-task), .compact-task:not(.compact-item-completed):not(.compact-item-selected):not(.dragging):hover .compact-item-context:not(.issue-task) \
          , .compact-task.btn.btn-dropdown:not(.compact-item-completed):not(.compact-item-selected):not(.dragging):not([disabled]):focus .compact-item-context:not(.issue-task) \
          , .compact-task.btn.dropdown-primary:not(.compact-item-completed):not(.compact-item-selected):not(.dragging):not([disabled]):focus .compact-item-context:not(.issue-task), .task-status-icon \
          , .compact-item.compact-item-selected .compact-item-context::before, .compact-item:not(.compact-item-completed):not(.dragging):hover .compact-item-context::before \
          , .compact-item.btn.btn-dropdown:not(.compact-item-completed):not(.dragging):not([disabled]):focus .compact-item-context::before \
          , .compact-item.btn.dropdown-primary:not(.compact-item-completed):not(.dragging):not([disabled]):focus .compact-item-context::before \
          , .tasks-dash-main .tasks-dash-view .compact-container .compact-container-task-detail.compact-section.right-pane.pane-active \
          , tags-input.theme-grey .tags.focused, .form-control.control-blueprint:focus, textarea.form-control:focus \
          , input.form-control:focus',
          ['border-color', colors.alternate_color]
        ],
        ['.compact-task.compact-item-selected .compact-item-context .compact-item-content .compact-task-status .task-status-icon:not(.task-reject-icon):not(.has-issue):not(.task-rejected):not(.task-completed) .fas \
          , .compact-task.compact-item-selected .compact-item-context .compact-item-content .content-task-heading-index .circle \
          , .compact-task.compact-item-selected .compact-item-context .compact-item-content .compact-task-title-container .compact-item-title .heading \
          , .compact-task.compact-item-selected .compact-item-context .compact-item-content .compact-task-title-container .compact-item-title .sub-heading \
          , .compact-task.compact-item-selected .compact-item-context .compact-item-content .detail-meta .task-meta-item-deadline .t-slate \
          , .compact-task.compact-item-selected .compact-item-context .compact-item-content .compact-task-assignee, .task-status-icon i \
          , .compact-item-active.compact-task-active:not(.hide-menu) .compact-container .compact-section.right-pane .compact-task-detail-head .compact-item-title .heading .progress-bar-box .progress-icon .t-primary-base \
          , .fr-box.fr-fullscreen.fr-basic .fr-toolbar .fr-btn-grp .fr-command:hover, .fr-box.fr-fullscreen.fr-basic .fr-toolbar .fr-btn-grp .fr-command:focus \
          , .fr-box.fr-fullscreen.fr-basic .fr-toolbar .fr-btn-grp .fr-command.fr-active, .fr-box.fr-basic .fr-toolbar .fr-btn-grp .fr-command:hover \
          , .fr-box.fr-basic .fr-toolbar .fr-btn-grp .fr-command:focus, .fr-box.fr-basic .fr-toolbar .fr-btn-grp .fr-command.fr-active \
          , .fr-footer-element .html-show-button:hover, .fr-footer-element .html-show-button.btn.btn-dropdown:not([disabled]):focus \
          , .fr-footer-element .html-show-button.btn.dropdown-primary:not([disabled]):focus \
          , .popover.auto-name-process.edit-any-task.deadline-popup .deadline-shortcuts button.btn-shortcuts \
          , .fr-box.fr-fullscreen.fr-basic .fr-toolbar .fr-more-toolbar .fr-btn:hover, .fr-box.fr-basic .fr-toolbar .fr-more-toolbar .fr-btn:hover \
          , .fr-box.fr-basic .fr-toolbar .fr-more-toolbar .fr-btn.btn.btn-dropdown:not([disabled]):focus \
          , .fr-box.fr-basic .fr-toolbar .fr-more-toolbar .fr-btn.btn.dropdown-primary:not([disabled]):focus \
          , .task-detail-content .fr-command .fr-sr-only:hover, .form-group:not(.has-error).control-blueprint .control-label \
          , .vAccordion-capture v-pane v-pane-header .move i.t-dark-sky-blue-two, .compact-task-active .vAccordion-capture v-pane v-pane-content .tallyfy-btn-link-blueprint \
          , .compact-task-content-details .detail-meta .task-meta-type .task-meta-type-icon-cont:not(.type-task-status-completed) .fas.fa-envelope.t-steel-three \
          , .compact-task-content-details .detail-meta .task-meta-type .task-meta-type-icon-cont:not(.type-task-status-completed) .fas.fa-paper-plane.t-steel-three',
          ['color', colors.alternate_color]
        ],
        ['.tallyfy-nav-tab.with-border .nav-tabs .nav-link.active, .tallyfy-nav-tab.with-border .nav-tabs .nav-link.active:focus, .tallyfy-nav-tab.with-border .nav-tabs .nav-link.active:hover',
          ['border-bottom-color', colors.alternate_color]
        ],
        ['.popover.auto-name-process.edit-any-task.assignee-popup .popover-content .btn-member, .popover.auto-name-process.edit-any-task.assignee-popup .popover-content .btn-guests \
          , .popover.auto-name-process.edit-any-task.deadline-popup .popover-content .btn-member, .popover.auto-name-process.edit-any-task.deadline-popup .popover-content .btn-guests \
          , .popover.auto-name-process.edit-any-task.summary-popup .popover-content .btn-member, .popover.auto-name-process.edit-any-task.summary-popup .popover-content .btn-guests \
          , .popover.auto-name-process.edit-any-task.tags-popup .popover-content .btn-member, .popover.auto-name-process.edit-any-task.tags-popup .popover-content .btn-guests \
          , .field-group-label:focus-within > .option-container, .compact-task-detail.edit-mode .task-detail-tab .formfield-dropdown \
          , .compact-container-task-detail .task-detail-tab v-pane.task-edit-v-pane.is-expanded v-pane-content.task-edit-v-pane-content .formfield-dropdown.dropdown.open .dropdown-menu .dropdown-item:hover \
          , .task-modal .modal-dialog .modal-body .create-task-form oot-form-creator .formfield-dropdown, .task-modal .modal-dialog .modal-body .create-task-form oot-form-creator .formfield-dropdown.dropdown.open .dropdown-menu .dropdown-item:hover \
          , .tlfy-apr-rej-icon::before',
          ['background-color', colors.bg_color]
        ],
        ['.popover.auto-name-process.edit-any-task.deadline-popup .deadline-shortcuts button.btn-shortcuts',
          ['background-color', tinycolor(colors.alternate_color).setAlpha(0.2).toRgbString()]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getTrackerRules
     * @description get rules for tracker and internal pages
     */
    self.getTrackerRules = function (colors) {
      return [
        ['.compact-task.compact-item-selected .compact-item-context .compact-item-content .compact-task-status .task-status-icon:not(.task-reject-icon):not(.has-issue):not(.task-rejected):not(.task-completed) \
          , .compact-container .compact-section.right-pane, .compact-task-active .tallyfy-tag-input .tag-content-container.focus, .published-run .input-group-prepend button \
          , .published-run input:focus, .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.active \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.active \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter:not(.active):hover \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.btn.btn-dropdown:not(.active):not([disabled]):focus \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.btn.dropdown-primary:not(.active):not([disabled]):focus \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member:not(.active):hover \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.btn.btn-dropdown:not(.active):not([disabled]):focus \
          , .task-detail-content-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.btn.dropdown-primary:not(.active):not([disabled]):focus \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter:not(.active):hover \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.btn.btn-dropdown:not(.active):not([disabled]):focus \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.btn.dropdown-primary:not(.active):not([disabled]):focus \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member:not(.active):hover \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.btn.btn-dropdown:not(.active):not([disabled]):focus \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.btn.dropdown-primary:not(.active):not([disabled]):focus \
          , .task-detail-content-modal .modal-dialog .activeprocess tags-input .tags.focused, .more-detail-drawer-modal .modal-dialog .activeprocess tags-input .tags.focused \
          , .task-detail-content-modal .modal-dialog tags-input .tags.focused, .compact-task-active .more-detail-drawer-modal .modal-dialog tags-input .tags.focused \
          , .more-detail-drawer-modal .modal-dialog .modal-content .more-detail-drawer .b-success, .add-to-folders-modal .modal-dialog .modal-content .b-success \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .btn.btn-blueprint',
          ['border-color', colors.bg_color, true]
        ],
        ['.compact-task-detail .content-task-heading-index .circle, .compact-task-active .tallyfy-tag-input .tag-autocomplete-source-container .tag-source-element.selected \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.process-starter.active \
          , .more-detail-drawer-modal .modal-dialog .activeprocess .assign-members-container .assign-members-item.specific-member.active \
          , .task-detail-content-modal .modal-dialog .activeprocess tags-input .autocomplete .suggestion-list .suggestion-item.selected \
          , .more-detail-drawer-modal .modal-dialog .activeprocess tags-input .autocomplete .suggestion-list .suggestion-item.selected \
          , .task-detail-content-modal .modal-dialog .activeprocess tags-input .autocomplete .suggestion-list .suggestion-item.selected em \
          , .more-detail-drawer-modal .modal-dialog .activeprocess tags-input .autocomplete .suggestion-list .suggestion-item.selected em \
          , .task-detail-content-modal .modal-dialog tags-input .autocomplete .suggestion-list .suggestion-item.selected \
          , .compact-task-active .more-detail-drawer-modal .modal-dialog tags-input .autocomplete .suggestion-list .suggestion-item.selected \
          , .compact-task-active tags-input .autocomplete .suggestion-item.selected em, .add-to-folder-actions .btn \
          , .box.run-header .toggle-switch .onoffswitch-inner::before, .box.run-header .toggle-switch .onoffswitch-inner::after',
          ['background-color', colors.bg_color]
        ],
        ['.form-group:focus-within label.control-label, .published-run .bp-info-icon .fas.fa-globe, .more-detail-drawer .fr-command .fr-sr-only:hover \
          , .tasks-folders-tree.add-to-folders-modal .tasks-folders-select-toggle i.cb-icon:after \
          , .box.run-header .status-launch-part.box-header-right .bpe-quick-actions.active i \
          , .box.run-header .status-launch-part.box-header-right .bpe-quick-actions.active span \
          , .more-detail-drawer-modal .modal-dialog .permission-cont template-edit-permission .permission-edit \
          , .published-run .input-group-prepend button',
          ['color', colors.alternate_color]
        ],
        ['.tasks-folders-tree.add-to-folders-modal .tasks-folders-select-toggle i.cb-icon:before',
          ['border-color', colors.alternate_color]
        ],
        ['.tasks-folders-tree.add-to-folders-modal .tasks-folder-item:hover:not(.tasks-folder-item-disable-hover) \
          , .tasks-folders-tree.add-to-folders-modal .tasks-folder-item.btn.btn-dropdown:not(.tasks-folder-item-disable-hover):not([disabled]):focus \
          , .tasks-folders-tree.add-to-folders-modal .tasks-folder-item.btn.dropdown-primary:not(.tasks-folder-item-disable-hover):not([disabled]):focus \
          , .tasks-folders-tree.add-to-folders-modal .tasks-folder-item.active',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.2).toRgbString()]
        ],
        ['.run-sub-header .sub-nav-item.active, .run-sub-header .sub-nav-item:hover, .run-sub-header .sub-nav-item.btn.btn-dropdown:not([disabled]):focus, .run-sub-header .sub-nav-item.btn.dropdown-primary:not([disabled]):focus',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.1).toRgbString()],
          ['color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getSettingsRules
     * @description get rules for settings and internal pages
     */
    self.getSettingsRules = function (colors) {
      return [
        ['.onoffswitch-inner:before, .settings-page .setting-page-main-view.right .settings-page-section .account-info .form-group.hard-relative-dates .toggle-switch .onoffswitch-inner::before \
          , .settings-page .setting-page-main-view.right .settings-page-section .account-info .form-group.hard-relative-dates .toggle-switch .onoffswitch-inner::after \
          , .work-hours-slider.rzslider .rz-selection, .work-hours-slider.rzslider .rz-tick.rz-selected, .ty-custom-pagination-container.classic .page-control.active \
          , .k-list-container .k-item.k-state-selected, .k-animation-container .k-list > .k-state-selected:hover, .badge.badge-success \
          , .tab-content > .active.tab-pane .company-domains-box button.update-domains-btn \
          , .account-plan.pro .tallyfy-box .box .box-body.pro-form form .coupon-code-submit .btn.btn-primary \
          , .integration-lists ul li.active::before, .integration-box .btn-success \
          , .settings-page .setting-page-main-view.right .days-container .day-selected',
          ['background-color', colors.bg_color]
        ],
        ['.settings-page .setting-page-main-view.right .settings-page-section .account-info .form-group.hard-relative-dates .bpe-quick-actions.active-link span \
          , .work-hours-slider.rzslider .rz-limit, div.pos-abs.pointer.p-1.t-green-dark, .select-box-data-list .data-list-container .data-row.item-selected \
          , .select-box-data-list .data-list-container .data-row.item-selected i \
          , .account-plan.pro .tallyfy-box .box .box-body.pro-form form .yearly-monthly-plan.upgrade-cover-green .green-base-25 span.save-free \
          , .account-plan.pro .tallyfy-box .box .box-body .save-free, .integration-lists ul li.active a',
          ['color', colors.alternate_color]
        ],
        ['.ui-select-container:not(.select2) .ui-select-match-item',
          ['background', colors.bg_color, true]
        ],
        ['.ui-select-container:not(.select2) .ui-select-bootstrap .ui-select-choices-row > span:hover \
          , .ui-select-container:not(.select2) .ui-select-bootstrap .ui-select-choices-row > span.btn.btn-dropdown:not([disabled]):focus, .ui-select-container:not(.select2) .ui-select-bootstrap .ui-select-choices-row > span.btn.dropdown-primary:not([disabled]):focus \
          , .ui-select-container:not(.select2) .ui-select-choices-row:hover, .ui-select-container:not(.select2) .ui-select-choices-row.btn.btn-dropdown:not([disabled]):focus \
          , .ui-select-container:not(.select2) .ui-select-choices-row.btn.dropdown-primary:not([disabled]):focus \
          , .ui-select-container:not(.select2) .ui-select-match-item:hover, .ui-select-container:not(.select2) .ui-select-match-item.btn.btn-dropdown:not([disabled]):focus \
          , .ui-select-container:not(.select2) .ui-select-match-item.btn.dropdown-primary:not([disabled]):focus',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.9).toRgbString(), true]
        ],
        ['.dropdown.open .dropdown-primary, .select-box-content-display:focus, .select-box-content-display:hover \
          , .select-box-content-display.btn.btn-dropdown:not([disabled]):focus, .select-box-content-display.btn.dropdown-primary:not([disabled]):focus \
          , .settings-page .setting-page-main-view.right .days-container .day-selected',
          ['border-color', colors.alternate_color]
        ],
        ['.work-hours-slider.rzslider .rz-bar, .work-hours-slider.rzslider .rz-tick',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.3).toRgbString()]
        ],
        ['.tallyfy-grid-table-component .k-grid tr:hover, .tallyfy-grid-table-component .k-grid tr.btn.btn-dropdown:not([disabled]):focus \
          , .tallyfy-grid-table-component .k-grid tr.btn.dropdown-primary:not([disabled]):focus \
          , .select-box-data-list .data-list-container .data-row.item-selected',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.1).toRgbString(), true]
        ],
        ['.account-plan.pro .tallyfy-box .box .box-body.pro-form form .yearly-monthly-plan.upgrade-cover-green .green-base-25 \
          , .account-plan.pro .tallyfy-box .box .box-body.pro-form form .compare-plan-details.upgrade-cover-green .dropdown-item:hover \
          , .account-plan.pro .tallyfy-box .box .box-body.pro-form form .compare-plan-details.upgrade-cover-green .dropdown-item:focus',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.2).toRgbString()]
        ],
        ['.account-plan.pro .tallyfy-box .box .box-body.pro-form form .coupon-code-submit button',
          ['box-shadow', '0 0 16px 0 ' + colors.bg_color]
        ],
        ['.settings-page .left:not(.popover) .settings-menu a.nav-link:not(.has-children):hover \
          , .settings-page .left:not(.popover) .settings-menu a.nav-link:not(.has-children):focus \
          , .settings-page .left:not(.popover) .settings-menu a.nav-link:not(.has-children).active',
          ['background-color', colors.bg_color],
          ['color', colors.text_color]
        ],
        ['.tallyfy-check input[type=radio]:checked + i > span',
          ['background', colors.bg_color],
          ['border-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getFilterRules
     * @description get rules for filters(tasks & tracker)
     */
    self.getFilterRules = function (colors) {
      return [
        ['.filter-pill-value',
          ['background-color', colors.bg_color]
        ],
        ['.run-filter-component .add-nav, .task-filter-component .add-nav',
          ['color', colors.alternate_color]
        ],
        ['.run-filter-option-component .filter-item .active, .task-filter-option-component .filter-item .active \
          , .tasks-folders-tree .tasks-folder-item:hover:not(.tasks-folder-item-disable-hover) \
          , .tasks-folders-tree .tasks-folder-item.btn.btn-dropdown:not(.tasks-folder-item-disable-hover):not([disabled]):focus \
          , .tasks-folders-tree .tasks-folder-item.btn.dropdown-primary:not(.tasks-folder-item-disable-hover):not([disabled]):focus \
          , .run-filter-option-component .default-filter-item:hover, .run-filter-option-component .default-filter-item.btn.btn-dropdown:not([disabled]):focus \
          , .run-filter-option-component .default-filter-item.btn.dropdown-primary:not([disabled]):focus',
          ['background', tinycolor(colors.bg_color).setAlpha(0.2).toRgbString()]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getBlueprintRules
     * @description get rules for blueprints & inner pages
     */
    self.getBlueprintRules = function (colors) {
      return [
        ['.process-header .box-header-nav.box-header-action .lp-button',
          ['background-color', colors.bg_color]
        ],
        ['.process-header .box-header-nav.box-header-action .lp-button .nav-info, .process-header .box-header-nav.box-header-action .lp-button .nav-process',
          ['border-color', tinycolor(colors.alternate_color).darken(10).toRgbString()]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getLaunchRules
     * @description get rules for launch process
     */
    self.getLaunchRules = function (colors) {
      return [
        ['.pre-launch-page-head .pre-launch-button.launch-button, .pre-launch-page-footer .pre-launch-button.launch-button \
          , .pre-launch-content-box .tallyfy-tag-input .tag-autocomplete-source-container .tag-source-element.selected \
          , .pre-launch .steps-info .step-list-column-checkbox .checkbox-wrapper .md-check i.primary \
          ,  .pre-launch-content-container.assign-someone-else-button .launch-process-kickoff-form .kickoff-fields .kickoff-fields-form .form-fields field-assignee compact-item-assignee .assignee .assignee-member button.btn-member',
          ['background-color', colors.alternate_color]
        ],
        ['.pre-launch-content-box animated-button .btn.btn-add-guest, .pre-launch-content-box animated-button .btn.btn-add-guest:hover \
          , .pre-launch-content-box animated-button .btn.btn-add-guest.btn-dropdown:not([disabled]):focus \
          , .pre-launch-content-box animated-button .btn.btn-add-guest.dropdown-primary:not([disabled]):focus',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.2).toRgbString()]
        ],
        ['.pre-launch-content-box animated-button .btn.btn-add-guest, .pre-launch .steps-info .step-list-column-assignee .t-link \
          , .pre-launch .steps-info .step-list-column-deadline .t-link, .task-modal .step-type-label .dropdown-menu .dropdown-item .selected-step-type.fa-check',
          ['color', colors.alternate_color]
        ],
        ['.pre-launch-content-box .tallyfy-tag-input .tag-content-container.focus',
          ['border-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getModalRules
     * @description get rules for modals app-wide
     */
    self.getModalRules = function (colors) {
      return [
        ['.task-modal .tallyfy-tag-input .tag-content-container.focus, .edit-standalone-modal .edit-task-form .task-title-wrapper \
        , .edit-standalone-modal .edit-task-form .task-assignee, .edit-standalone-modal .edit-task-form .task-deadline \
        , .edit-standalone-modal .edit-task-form .task-linkto, .edit-standalone-modal .edit-task-form .create-task-form \
        , .edit-standalone-modal .edit-task-form .task-tags, .edit-standalone-modal .edit-task-form .form-group-main',
          ['border-color', colors.alternate_color]
        ],
        ['.task-modal .tallyfy-tag-input .tag-autocomplete-source-container .tag-source-element.selected \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .select2-results ul li.select2-highlighted \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .select2-results ul li:hover \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .select2-results ul li.btn.btn-dropdown:not([disabled]):focus \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .select2-results ul li.btn.dropdown-primary:not([disabled]):focus \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .dropdown a.dropdown-item.active \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .dropdown a.dropdown-item:hover \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .dropdown a.dropdown-item.btn.btn-dropdown:not([disabled]):focus \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .dropdown a.dropdown-item.btn.dropdown-primary:not([disabled]):focus \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content > .active.tab-pane .assign-user-steps ~ .steps-buttons.buttons-group animated-button button',
          ['background-color', colors.bg_color]
        ],
        ['.member-invite-modal .dropdown.open .uib-dropdown-menu .dropdown-item.t-primary-base \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-content .tab-content .tab-pane.power-pane .assign-user-steps .assign-user-steps-title \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content .tab-pane.power-pane .assign-user-steps .steps-box ul li .assign-button span \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content .tab-pane.power-pane .assign-user-steps .steps-box ul li .status-assigned.steps-green \
        , .privilege-group .role-dropdown.dropdown .dropdown-menu li .dropdown-item.t-primary-base .invite-options-drops span \
        , .privilege-group .role-dropdown.dropdown .dropdown-menu li .dropdown-item.t-primary-base i.far',
          ['color', colors.alternate_color, true]
        ],
        ['.member-invite-modal .dropdown.open .uib-dropdown-menu .dropdown-item.t-primary-base:hover \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-content .tab-content .tab-pane.deadlines power-deadline .assign-user-steps .steps-box ul li .countdays .countdays-daysbox ul.dropdown-menu li a.dropdown-item:hover \
        , .modal-open.tracker-powertools > .modal-super > .modal-dialog .modal-body .tab-content .tab-pane.deadlines power-deadline .assign-user-steps .steps-box ul li .countdays .countdays-daysbox ul.dropdown-menu li a.dropdown-item:hover \
        , .tab-content > .active.tab-pane .assign-user-steps .steps-box ul li .countdays-daysbox ul.dropdown-menu li a.dropdown-item.active \
        , .privilege-group .role-dropdown.dropdown .dropdown-menu li .dropdown-item.t-primary-base:hover .invite-options-drops span \
        , .privilege-group .role-dropdown.dropdown .dropdown-menu li .dropdown-item.t-primary-base:hover i.far',
          ['color', colors.text_color, true]
        ],
        ['.tab-content > .active.tab-pane .assign-user-steps .steps-box-assigner li .assign-column.tracker-view .assign-button:hover \
        , .tab-content > .active.tab-pane .assign-user-steps .steps-box-assigner li .assign-column.tracker-view .assign-button.btn.btn-dropdown:not([disabled]):focus \
        , .tab-content > .active.tab-pane .assign-user-steps .steps-box-assigner li .assign-column.tracker-view .assign-button.btn.dropdown-primary:not([disabled]):focus',
          ['background-color', tinycolor(colors.bg_color).setAlpha(0.1).toRgbString()]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getNotificationRules
     * @description get rules for growl notifications
     */
    self.getNotificationRules = function (colors) {
      return [
        ['.tallyfy-notify .notify-container .alert-success',
          ['border-color', colors.bg_color],
          ['background-color', colors.bg_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getOthersRules
     * @description get rules for progress bar..
     */
    self.getOthersRules = function (colors) {
      return [
        ['#ngProgress, .client-content .days-container .day-selected, #chatlio-widget .chatlio-widget.chatlio-open .chatlio-title-bar.chatlio-bottom-right',
          ['background-color', colors.bg_color, true]
        ],
        ['.client-content .days-container .day-selected',
          ['border-color', colors.alternate_color]
        ]
      ];
    };

    /**
     * @ngdoc method
     * @name getMultiDimensionalArray
     * @description create/get multi dimensional aray
     */
    self.getMultiDimensionalArray = function (outer, inner) {
      return _.map(_.fill(Array(outer), []), function () {
        return _.fill(Array(inner), []);
      });
    };
  }
})();

/**
 * @ngdoc run
 * @name tallyfy.core.run.[Moment, .....]
 * @module tallyfy.core
 *
 * @description
 * Create an Angular Constant for vendor libraries' global variables. Lets use everythings as angular dependecy 
 * avoid global variable/objects inside angular app
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

/* global moment:false */
(function() {
    'use strict';
    angular
        .module('tallyfy.core')
        .run(
            /*@ngInject*/
            function(moment, angularMomentConfig, amMoment, $language, DATEFORMAT) {
                $language
                    .getCurrent()
                    .then(function(l) {
                        amMoment.changeLocale(l);
                    });
                moment.defaultFormat = DATEFORMAT.DEFAULT;
                angularMomentConfig.preprocess = function(value) {
                    return moment(value).locale(moment.locale());
                }
                // moment.tz.setDefault('UTC'); 
            });
    angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);
})();

/**
 * @ngdoc overview
 * @name tallyfy.messages
 *
 * @module tallyfy.messages
 *
 * @description
 * manage messages
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.messages', []);
})();

/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportBar
 * @module tallyfy.support
 *
 * @description
 * A component to show notice that user is in masquerading mode
 *
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('supportBar', {
      bindings: {
        message: '<'
      },
      templateUrl: 'app/modules/support/components/supportBar/support-bar.html',
      controller: [
        '_',
        '$log',
        '$state',
        'Auth',
        'blockUI',
        '$rootScope',
        function SupportBarController(_, $log, $state, Auth, blockUI, $rootScope) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('appView');
          $ctrl.identity = $rootScope.identity;
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.switchToAdmin = switchToAdmin;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
          }

          /**
          * @ngdoc method
          * @name switchToAdmin
          * @description switch Masquerade to admin
          * @todo adminOrganizationSlug will be removed once urls will become slug free.
          * @returns  void
          */
          function switchToAdmin() {
            blockUI.start();
            Auth.logoutMasquerade().then(function (organization) {
              $state.go('home', { org_id: organization.id }, { reload: true });
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }


        }]
    });
})();
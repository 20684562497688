/**
 * @ngdoc Component
 * @name tallyfy.organizations.access.Component.newGroup
 * @module tallyfy.organizations.access
 *
 * @description
 * create/update new group
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.access')
    .component('newGroup', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/access/groups/new/new-group.html',
      controller: [
        '_',
        'blockUI',
        '$log',
        'GroupService',
        function (_, blockUI, $log, GroupService) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('newGroup'),
            group = {
              name: '',
              users: []
            };
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.loadUsers = loadUsers;
          $ctrl.cancel = cancelModal;
          $ctrl.save = save;

          /**
           * public properties
           */
          $ctrl.group = angular.copy(group);

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.users = $ctrl.resolve.users;
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel' });
          }

          /**
           * @description 
           * @param {any} $query 
           * @param {any} itemList 
           * @returns 
           */
          function loadUsers($query, itemList) {
            return _.filter(itemList, function (owner) {
              return owner.text.toLowerCase().indexOf($query.toLowerCase()) != -1;
            });
          }
          /**
          * @ngdoc method
          * @name save
          * @description create new group
          * @returns promise
          */
          function save() {
            if ($ctrl.newGroupForm.$invalid) {
              return;
            }
            blockUI.start();
            var requestPayload = angular.copy($ctrl.group);
            GroupService.save(requestPayload).then(function (response) {
              blockUI.stop();
              $ctrl.close({ $value: response.data });
            }).catch(function (error) {
              blockUI.stop();
              $log.error('Error while adding new Group', error);
            });
          }

          //end of controller
        }]
    });
})();

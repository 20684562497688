/**
 * @ngdoc Component
 * @name tallyfy.process.component.duplicateTemplate
 * @module tallyfy.process
 *
 * @description
 * Modal to show duplicate a template's process
 *
 * @author Adi Winata (gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com)
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('duplicateTemplate', {
      templateUrl: 'app/modules/process/components/duplicateTemplate/duplicate-template.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, ProcessRepository, $rootScope, $filter, DESCRIPTIONSIZE, Growl, $timeout, UtilsService) {
          var $ctrl = this,
          growl = new Growl();

          /**
           * public properties
           */
          $ctrl.onSaving = false;
          $ctrl.maxBPTitleLength = DESCRIPTIONSIZE.maxBPTitleLength;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.duplicate = duplicate;
          $ctrl.cancel = cancel;

          /**
           * @ngdoc method
           * 
           * @name onInit
           * @returns void
           * 
           * @description
           * initalization component
           */
          function onInit() {
            $ctrl.process = $ctrl.resolve.process;
            $ctrl.processNewTitle = $filter('translate')('template.duplicate.copyOfSubTitle', { 'copyOfSubTitle': $ctrl.process.title });
          }

          /**
           * @ngdoc method
           * 
           * @name onDestroy
           * @returns void
           * 
           * @description
           * on destroy component
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * 
           * @name onChanges
           * @returns void
           * 
           * @description
           * on component get changes
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * 
           * @name duplicate
           * @returns void
           * 
           * @description
           * duplicate process
           */
          function duplicate() {
            var isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin"),
              isDuplicatePermission = UtilsService.hasSpecificPermissions(isAdminMember, $ctrl.process.created_by, _.get($rootScope.identity, 'id'), _.get($ctrl.process, 'can_edit_perms.checklist_duplicate', []), _.get($ctrl.process, 'can_edit_perms.checklist_duplicate_group', []), $ctrl.resolve.orgGroups);
            if (isDuplicatePermission) {
              $ctrl.onSaving = true;
              ProcessRepository
                .duplicateTemplate({
                  id: $ctrl.process.id,
                  action: 'clone',
                  title: $ctrl.processNewTitle,
                  skipNotFound: true
              }).then(function (res) {
                $ctrl.onSaving = false;
                $ctrl.close({ $value: res.data });
              }, function (error) {
                $ctrl.onSaving = false;
                if (error.status === 422) {
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  $ctrl.dismiss({ $value: 'dismissed' });
                }
              });
            } else {
              $timeout(function () {
                growl.error($filter('translate')('process.messages.duplicatedRestricted'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                $ctrl.dismiss({ $value: 'dismissed' });
              }, 1000);
            }
          }

          /**
           * @ngdoc method
           * 
           * @name cancel
           * @returns void
           * 
           * @description
           * on modal get dismiss
           */
          function cancel() {
            $ctrl.dismiss({ $value: 'dismissed' });
          }
        }
    });
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('runFilterPill', {
      bindings: {
        key: '<',
        value: '=',
        foldersList: '<',
        allTags: '<',
        removeFilter: '&',
        pillClicked: '&'
      },
      templateUrl: 'app/modules/runs/dashboard/run-filter/run-filter-display/run-filter-pill/run-filter-pill.component.html',
      controller:
        /*@ngInject*/
        function (_, TagsService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.valueRemoved = valueRemoved;

          $ctrl.elementClicked = elementClicked;
          $ctrl.pillDelete = pillDelete;
          $ctrl.getTagTextColor = TagsService.isTagTextColor;

          function onInit() { }

          function onChanges() { }

          function onDestroy() { }

          function valueRemoved(e, value) {
            e.stopImmediatePropagation();
            $ctrl.value = _.filter($ctrl.value, function (val) {
              return value !== val.id;
            });
            $ctrl.removeFilter({ dataKey: $ctrl.key, dataValue: $ctrl.value });
          }

          function pillDelete(e) {
            e.stopImmediatePropagation();
            $ctrl.removeFilter({ dataKey: $ctrl.key });
          }

          function elementClicked(e) {
            $ctrl.pillClicked({ e: e });
          }

        }
    });
})();
(function () {
  'use strict';
  angular
    .module('tallyfy.public')
    .config(PublicLayoutStateConfig);

  /*@ngInject*/
  function PublicLayoutStateConfig($stateProvider) {
    $stateProvider.state('public.process', {
      parent: 'public',
      abstract: true,
      url: '/organizations',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'publicProcessPage'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('filter');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('process');
          return $translate.refresh();
        }]
      }
    }).state('public.process.detail', {
      parent: 'public.process',
      url: '/:orgId/process/:id?:activeTask',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'viewRun'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('filter');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('process');
          return $translate.refresh();
        }]
      }
    });
    $stateProvider.state('public.library', {
      parent: 'public',
      abstract: true,
      url: '/library',
      data: {
        authorities: [],
      },
      views: {
        'content': {
          component: 'publicLibraryPage'
        }
      },
      resolve: {
        translatePartialLoader:
          /*@ngInject*/
          function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('steps');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('public');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('process');
            return $translate.refresh();
          },
        publicOrganizationDetail:
          /*@ngInject*/
          function (_, $q, $rootScope, OrganizationsService, PublicOrganizationService) {
            var defer = $q.defer();
            if (!$rootScope.publicOrganization) {
              defer.resolve();
            } else {
              $q.all([
                OrganizationsService.getPublicOrgCustomBranding({
                  org: $rootScope.publicOrganization.id
                }).$promise,
                PublicOrganizationService.getPublicOrganizationDetail({
                  org_id: $rootScope.publicOrganization.id
                })
              ]).then(function (res) {
                angular.extend($rootScope.publicOrganization, {
                  name: _.get(res, '[1].name', ''),
                  orgLogo: _.get(res, '[1].org_logo', ''),
                  branding: _.get(res, '[0].data', {})
                });
                defer.resolve($rootScope.publicOrganization);
              }, function (err) {
                defer.reject(err);
              });
            }
            return defer.promise;
          }
      }
    }).state('public.library.combo', {
      parent: 'public.library',
      url: '?type&industry&topic&page',
      data: {
        authorities: [],
      },
      params: {
        type: { squash: true, value: null },
        industry: { squash: true, value: null },
        topic: { squash: true, value: null },
        page: { squash: true, value: null }
      },
      views: {
        'content': {
          component: 'publicLibrary'
        }
      },
      resolve: {
        translatePartialLoader:
          /*@ngInject*/
          function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('steps');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('public');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('process');
            $translatePartialLoader.addPart('organizations');
            return $translate.refresh();
          }
      }
    }).state('public.library.organization', {
      parent: 'public.library',
      url: '/:orgId?type&industry&topic&page',
      data: {
        authorities: [],
      },
      params: {
        type: { squash: true, value: null },
        industry: { squash: true, value: null },
        topic: { squash: true, value: null },
        page: { squash: true, value: null }
      },
      views: {
        'content': {
          component: 'publicLibrary'
        }
      },
      onEnter:
        /*@ngInject*/
        function ($state, $stateParams) {
          if (!$stateParams.orgId) {
            $state.go('public.library.combo');
          }
        }
    }).state('public.library.combo.detail', {
      parent: 'public.library',
      url: '/organization/:bpOrgId/blueprint/:blueprintId?step&section',
      data: {
        authorities: [],
      },
      views: {
        'content': {
          component: 'publicLibraryDetail'
        }
      },
      onEnter:
        /*@ngInject*/
        function ($state, $stateParams) {
          if (!$stateParams.bpOrgId || !$stateParams.blueprintId) {
            $state.go('public.library.combo');
          }
        }
    }).state('public.library.organization.detail', {
      parent: 'public.library',
      url: '/:orgId/blueprint/:blueprintId?step&section',
      data: {
        authorities: [],
      },
      views: {
        'content': {
          component: 'publicLibraryDetail'
        }
      },
      onEnter:
        /*@ngInject*/
        function ($state, $stateParams) {
          if (!$stateParams.blueprintId) {
            $state.go('public.library.combo');
          }
        }
    }).state('public.library.combo.tags', {
      parent: 'public.library',
      url: '/tags/:category',
      data: {
        authorities: [],
      },
      params: {
        category: { squash: true, value: 'topic' },
      },
      views: {
        'content': {
          component: 'publicTags'
        }
      }
    }).state('public.library.organization.tags', {
      parent: 'public.library',
      url: '/:orgId/tags/:category',
      data: {
        authorities: [],
      },
      params: {
        category: { squash: true, value: 'topic' },
      },
      views: {
        'content': {
          component: 'publicTags'
        }
      }
    }).state('public.library.organization.kickOff', {
      parent: 'public.library',
      url: '/:orgId/blueprint/:blueprintId/kick-off',
      data: {
        authorities: [],
      },
      views: {
        'content': {
          component: 'kickOffFormEmailSubmission'
        }
      }
    });
  }
})();

/**
 * @ngdoc Directive
 * @name tallyfy.expandableInput
 * @restrict 'A'
 * @element ANY
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('expandableInput', ['_', '$log', function (_, $log) {
      return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element) {
          var $tempElement = $('<span>');
          $tempElement.appendTo('body');
          $tempElement.css('display', 'none');
          $tempElement.css('font', element.css('font'));
          element.addClass('expandable-input');

          function textWidth() {
            $tempElement.text(element.val());
            return $tempElement.width() + 30; //accounts for padding and indents
          }

          element.on('input', function () {
            $(this).css('width', textWidth());
          });
          element.on('$destroy', function () {
            $tempElement.remove();
          });
        }
      }
    }]);

})();

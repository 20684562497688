/**
 * @ngdoc Service
 * @name ChatlioService
 *
 * @module tallyfy
 *
 * @description
 * Chatlio service
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */
(function () {
  'use strict';

  angular.module('tallyfy')
    .service('ChatlioService', chatlioService);

  /*@ngInject*/
  function chatlioService(_, $rootScope, DOMService, $interval) {
    var self = this, isExpanded = false, triggerInterval;

    function _init() {
      document.addEventListener('chatlio.ready', function (e) {
        _initializeEvents();
        _initializeBehavior();
      }, false);
    }

    self.getIsExpanded = function () {
      return isExpanded;
    }

    function setExpanded(value) {
      isExpanded = value;
    }

    function _initializeEvents() {
      _chatlio.configure({
        collapsedMode: 'chip',
        hideWhenOffline: false
      });
      setExpanded(false);
      DOMService.toggleChatlioVisibility(true);

      document.addEventListener("chatlio.offline", function () {
        $rootScope.$emit('CHATLIO:STATUS', { isOnline: false });
      });

      document.addEventListener("chatlio.online", function () {
        $rootScope.$emit('CHATLIO:STATUS', { isOnline: true });
      });

      document.addEventListener("chatlio.expanded", function () {
        setExpanded(true);
        DOMService.toggleChatlioVisibility(false);
        $rootScope.$emit('CHATLIO:EXPANDED');
      });

      document.addEventListener("chatlio.collapsed", function () {
        setExpanded(false);
        DOMService.toggleChatlioVisibility(true);
        $rootScope.$emit('CHATLIO:COLLAPSED');
      });

      document.addEventListener("chatlio.trigger", function (event) {
        setExpanded(true);
        DOMService.toggleChatlioVisibility(false);
        $rootScope.$emit('CHATLIO:EXPANDED');
      });
    }

    function _initializeBehavior() {
      if (!window._chatlio) {
        return;
      }
      var isOnline = _chatlio.isOnline();
      _chatlio.show({ expanded: false });
      $rootScope.$emit('CHATLIO:STATUS', { isOnline: isOnline });
    }

    self.setIdentity = function (username, identity) {
      if (!window._chatlio) {
        return;
      }
      _chatlio.identify(username, identity);
    }

    self.reset = function () {
      if (!window._chatlio) {
        return;
      }
      _chatlio.reset();
    }

    self.showOrHideWidget = function () {
      if (!window._chatlio) {
        return;
      }
      isExpanded = !isExpanded;
      setExpanded(isExpanded);
      _chatlio.show({ expanded: isExpanded });
      if (isExpanded) {
        DOMService.toggleChatlioVisibility(false);
        $rootScope.$emit('CHATLIO:EXPANDED');
      } else {
        DOMService.toggleChatlioVisibility(true);
        $rootScope.$emit('CHATLIO:COLLAPSED');
      }
    }

    self.triggerMessage = function (uid, message, style, after) {
      if (!window._chatlio) {
        return;
      }
      if (!uid || !message) {
        return;
      }
      var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
      if (isInTrial) {
        var data = {
          message: message,
          after: 0,
          displayStyle: style || "peek",
          uid: uid
        };
        if (triggerInterval) {
          $interval.cancel(triggerInterval);
        }
        triggerInterval = $interval(function () {
          _chatlio.trigger(data);
          $interval.cancel(triggerInterval);
        }, (after || 30) * 1000);
      }
    }

    self.cancelTrigger = function () {
      if (triggerInterval) {
        $interval.cancel(triggerInterval);
      }
    }

    if (window._chatlio) {
      _init();
    }

    $rootScope.$on('BEACON:CLOSE', function () {
      setExpanded(isExpanded);
      _chatlio.show({ expanded: isExpanded });
      DOMService.toggleChatlioVisibility(!isExpanded);
    });

    $rootScope.$on('BEACON:OPEN', function () {
      setExpanded(false);
      _chatlio.show({ expanded: false });
      DOMService.toggleChatlioVisibility(true);
    });

    return self;
  }

})();
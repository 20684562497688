(function () {
  'use strict';
  angular
    .module('tallyfy.folders')
    .factory('FolderRepository', FolderRepository);

  /*@ngInject*/
  function FolderRepository(BaseRepository, CONFIG) {
    var repository = BaseRepository('/organizations/:org/folders/:id/:sub_action', {
      org: 'org_id',
      id: '@id'
    }, {
      $all: { method: 'GET' },
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' },
      $delete: { method: 'DELETE' },
      deleteFoldersObjects: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/folders-objects/:id'
      }
    });

    repository.create = function (data) {
      return repository.$create({ org: 'org_id' }, data);
    };

    repository.get = function (params) {
      return repository.$all(params);
    };

    repository.getByID = function (params) {
      return repository.$get(params);
    };

    repository.edit = function (params) {
      return repository.$update(params);
    };

    repository.delete = function (params) {
      return repository.$delete(params);
    };
    return repository;
  }
})(); 
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tyCustomPagination', {
      templateUrl: 'app/components/ty-custom-pagination/ty-custom-pagination.component.html',
      bindings: {
        callback: '&',
        totalItems: '<',
        perPage: '<',
        currentPage: '<',
        classicStyle: '<',
        perPageData: '<',
        alwaysShow: '<',
        dropdownId: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, Helper, $timeout) {
          var $ctrl = this,
            perPageDropdown = void 0;

          $ctrl.pages = [];

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.getPrev = getPrev;
          $ctrl.getCurrent = getCurrent;
          $ctrl.getNext = getNext;
          $ctrl.goToFirstPage = goToFirstPage;
          $ctrl.goToLastPage = goToLastPage;

          function onInit() {
            if (!$ctrl.dropdownId) {
              $ctrl.dropdownId = Helper.guid();
            }
          }

          function onChanges(changes) {
            if (changes.totalItems || changes.perPage || $ctrl.currentPage) {
              setTotalPage();
            }
            if (changes.perPageData) {
              $timeout(function () {
                perPageDropdown = angular.element('.dropdown-template-' + $ctrl.dropdownId);
                if (perPageDropdown.length) {
                  $(perPageDropdown).kendoDropDownList({
                    value: $ctrl.perPage,
                    dataSource: $ctrl.perPageData,
                    change: function (e) {
                      var totalPages = Math.ceil(($ctrl.totalItems || 0) / (e.sender.value()));
                      if (totalPages < $ctrl.currentPage) {
                        $ctrl.currentPage = totalPages;
                      }
                      $ctrl.callback({ page: $ctrl.currentPage, perPage: e.sender.value() });
                      setTotalPage();
                    }
                  });
                }
              }, 1500);
            }
          }
          function onDestroy() {
            if (!perPageDropdown) {
              return;
            }
            perPageDropdown.data("kendoDropDownList").destroy();
            perPageDropdown.remove();
            perPageDropdown.empty();
          }

          function setTotalPage() {
            var totalItems = $ctrl.totalItems || 1;
            var totalPages = Math.ceil(totalItems / ($ctrl.perPage));
            $ctrl.lastPage = totalPages;
            var totalDisplayedPageNumber = totalPages > 5 ? 5 : totalPages;

            $ctrl.pages = [];
            if (totalPages > 5) {
              var start = getStartRanges(totalPages);
              var end = getEndRanges(totalPages);
              $ctrl.pages.push(1);
              $ctrl.pages.push($ctrl.currentPage > 3 ? '...' : 2);
              for (var i = start; i <= end; i++) {
                $ctrl.pages.push(i);
              }
              $ctrl.pages.push($ctrl.currentPage + 1 < totalPages ? '...' : totalPages - 1);
              $ctrl.pages.push(totalPages);
            } else {
              for (var i = 0; i < totalDisplayedPageNumber; i++) {
                $ctrl.pages.push(i + 1);
              }
            }
          }

          function getStartRanges(totalPages) {
            return ($ctrl.currentPage > 3)
              ? (($ctrl.currentPage >= totalPages - 3)
                ? totalPages - 2
                : $ctrl.currentPage - 1) : 3;
          }

          function getEndRanges(totalPages) {
            return ($ctrl.currentPage > 3)
              ? (($ctrl.currentPage >= totalPages - 3)
                ? totalPages - 2
                : $ctrl.currentPage + 1) : 3;
          }

          function getPrev() {
            $ctrl.currentPage - 1 >= 1 ? $ctrl.callback({ page: $ctrl.currentPage - 1 }) : angular.noop();
          }

          function getCurrent(page) {
            $ctrl.callback({ page: page });
          }

          function getNext() {
            var totalItems = $ctrl.totalItems || 0;
            var totalPages = Math.ceil(totalItems / ($ctrl.perPage));
            $ctrl.currentPage + 1 <= totalPages ? $ctrl.callback({ page: $ctrl.currentPage + 1 }) : angular.noop();
          }

          function goToFirstPage() {
            $ctrl.currentPage = 0;
            $ctrl.callback({ page: $ctrl.currentPage + 1 });
          }

          function goToLastPage() {
            var totalItems = $ctrl.totalItems || 0;
            var totalPages = Math.ceil(totalItems / ($ctrl.perPage));
            $ctrl.callback({ page: totalPages });
          }
        }
    });
})();
/**
 * @ngdoc overview
 * @name tallyfy.config
 *
 * @module tallyfy.config
 *
 * @description
 * tallyfy.config applications configurations
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function() {
    'use strict';

    angular.module('tallyfy.config', ['tallyfy.core']);
})();
/**
 * @ngdoc Directive
 * @name tallyfy.tfyTextSelector
 * @description selects all text on focus
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('tfyTextSelector', tfyTextSelector);
    /*@ngInject*/
    function tfyTextSelector() {
      return {
        restrict: 'A',
        link: function ($scope, element) {
          element.bind('focus', function () {
            element.select();
            $scope.$apply();
          });

          $scope.$on('$destroy', function () {
            element.unbind('focus');
          });
        }
      };
    }
})();
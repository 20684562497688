(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('openAIService', openAIService);

  /*@ngInject*/
  function openAIService(_, openAIRepository) {
    var self = this;

    self.generateTextCompletion = function (params, body) {
      return openAIRepository.generateTextCompletion(angular.extend(params, { skipNotFound: true }), body).$promise;
    }

    self.generateChatCompletion = function (params, body) {
      return openAIRepository.generateChatCompletion(angular.extend(params, { skipNotFound: true }), body).$promise;
    }

    self.generateDocChatCompletion = function (params, body) {
      return openAIRepository.generateDocChatCompletion(angular.extend(params, { skipNotFound: true }), body).$promise;
    }
  }
})();
/**
 * @ngdoc service
 * @name tallyfy.OOTAdvancedSettingsService
 * @module tallyfy
 *
 * @description
 * advanced settings service
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('OOTAdvancedSettingsService', OOTAdvancedSettingsService);

  /*@ngInject*/
  function OOTAdvancedSettingsService(_, $uibModal, $filter) {
    var self = this;

    self.openModalOOTAdvancedSetting = function (task) {
      var modalInstance = $uibModal.open({
        component: 'advancedSettingsOotModal',
        windowClass: 'advanced-settings-oot-modal',
        backdrop: 'static',
        resolve: {
          task: function () {
            return task;
          }
        }
      });
      return modalInstance.result;
    };

    self.defaultTaskPositions = function () {
      return [{
        value: 'beginning',
        title: $filter('translate')('global.task.menuPosition.start'),
        class: 'item-value',
        iconLabel: 'fas fa-arrow-left'
      }, {
        value: 'last',
        title: $filter('translate')('global.task.menuPosition.end'),
        class: 'item-value',
        iconLabel: 'fas fa-arrow-left'
      }];
    };

    self.generateTaskValueOptions = function (tasks) {
      var tasksData = [];
      for (var i = 0; i < tasks.length; i++) {
        tasksData.push({
          id: tasks[i].id,
          value: tasks[i].id + '_' + (i + 1),
          title: tasks[i].title,
          class: 'item-value',
          status: tasks[i].status
        });
      }
      return tasksData;
    };

    self.openModalOOTBlueprintAdvancedSetting = function (task) {
      var modalInstance = $uibModal.open({
        component: 'advancedBlueprintSettingsOotModal',
        windowClass: 'advanced-settings-oot-modal',
        backdrop: 'static',
        resolve: {
          task: function () {
            return task;
          }
        }
      });
      return modalInstance.result;
    };

    return self;
  }
})();
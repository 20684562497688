/**
 * @ngdoc Component
 * @name tallyfy.run.component.completedState
 * @module tallyfy.run
 *
 * @description
 * A component to redirect user to create new process
 *
 * @author Sireesha Purushotham ( gmail::sireeshapurushotham@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('completedState', {
      bindings: {
        run: '=',
        isDisable: '<?',
        isGuest: '<'
      },
      templateUrl: 'app/modules/runs/completedState/completedState.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, AuthPlan, menuActionLinksService, Helper, blockUI, RunRepository, Growl, $filter) {
          var $ctrl = this,  growl = new Growl(),
            blockUI = blockUI.instances.get('viewRun');
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.launchNewProcess = launchNewProcess;
          $ctrl.openInviteUserModel = openInviteUserModel;
          $ctrl.archiveRun = archiveRun;

          /**
           * public properties
           */

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isUserInFreeTrial = AuthPlan.isUserInFreeTrial();
            $ctrl.orgID =  _.get($rootScope.identity, 'default_organization.id');
            $ctrl.org = _.get($rootScope.identity, 'default_organization');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
          }
          
          /**
           * @function
           * @name launchNewProcess
           * @description
           * A component function which can redirect user to  new process creation page
           */
          function launchNewProcess() {
            $state.go('process.templates', {
              org_id: _.get($rootScope.identity, 'default_organization.id')
            });
          }
          
          /**
           * @ngdoc method
           * @name openInviteUserModel
           * @public
           * @description To open invite user modal
           * @returns {object} data
           */
          function openInviteUserModel() {
            menuActionLinksService.openInviteUserModal();
          }
          
          /**
           * @ngdoc method
           * @name archiveRun
           * @public
           * @description archive Run
           * @returns void
           */
          function archiveRun() {
            if(!Helper.checkAccessAuthority()) {
              return;
            }
            blockUI.start();
            RunRepository.deleteRunsData({
              id: $ctrl.run.id
            }).then(function (response) {
              blockUI.stop();
              angular.extend($ctrl.run, response.data);
              $rootScope.$emit('RUN_DETAILS:UPDATED', angular.copy($ctrl.run));
              growl.success($filter('translate')('runs.dashboard.runAction.archived'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function () {
              blockUI.stop();
            });
          }
          //end of controller
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for myAccount component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/settings/org', '/settings/org/logo');
    $urlRouterProvider.when('/settings/org/', '/settings/org/logo');
    /**
     * parent route
     * @name settings.org
     */
    $stateProvider.state('settings.org', {
      parent: 'settings',
      url: '/org',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view': {
          component: 'myOrg'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    });
    /**
     * child route
     * @name settings.org.generalInfo
     */
    /* TODO It can be unhide in future version */
    /*$stateProvider.state('settings.org.generalInfo', {
      url: '/general-info',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgGeneralInfo'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    }); */
    /**
    * child route
    * @name settings.org.generalInfo
    */
    /* TODO It can be unhide in future version */
    /* $stateProvider.state('settings.org.orgEmailPreferences', {
      url: '/email-preferences',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgEmailPreferences'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    }); */

    /**
     * parent route
     * @name settings.org
     */
    $stateProvider.state('settings.org.updateOrgProfile', {
      url: '/details',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'updateOrg'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.customize', {
      url: '/customize',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'myOrgCustomization'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.usersRegular', {
      url: '/members?tab',
      params: {
        tab: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgRegularUsers'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.tags', {
      url: '/tags',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'tags'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('tags');
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('settings.org.email', {
      url: '/email',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'emailSetting'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });
    
    $stateProvider.state('settings.org.branding', {
      url: '/branding',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'customBranding'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.groups', {
      url: '/groups?groupId&tab',
      params: {
        groupId: { squash: true, value: null },
        tab: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgGroups'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('support');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.roles', {
      url: '/roles?roleId&tab',
      params: {
        groupId: { squash: true, value: null },
        tab: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgRoles'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('support');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.orgGuestUsers', {
      url: '/guests',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'orgGuestUsers'
        }
      },
      params: {
        success: '',
        error: ''
      },
      resolve: {
        translatePartialLoader: ['$translate', function ($translate) {
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.textTemplate', {
      url: '/template?snippetId',
      params: {
        snippetId: { squash: true, value: null }
      },
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'textTemplate'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.domainOrg', {
      url: '/domain',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'domainOrg'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.org.personalization', {
      url: '/personalization',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'manage_org_view@settings.org': {
          component: 'personalizationSnippet'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });
  }
})();

/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.orgHolidays
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage organizations holidays
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('orgHolidays', {
      templateUrl: 'app/modules/organizations/holidays/holidays.html',
      bindings: {
        org: '<'
      },
      require: {
        orgSettings: '^orgGeneralInfo'
      },
      controller: [
        '_',
        '$log',
        'OrganizationsService',
        'uibDateParser',
        'moment',
        function (_, $log, OrganizationsService, uibDateParser, moment) {
          var $ctrl = this,
            today = new Date();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
          * public methods
          */
          $ctrl.addNewHoliday = addNewHoliday;
          $ctrl.onDelete = deleteHoliday;
          $ctrl.onUpdate = onUpdate;

          /**
          * public properties
          */
          $ctrl.newHoliday = {
            name: '',
            date: today
          };
          $ctrl.hasHolidays = false;
          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.holidays = parseHolidays($ctrl.org.settings.holidays);
            if ($ctrl.holidays.length === 0) {
              addNewHoliday();
            }
            $ctrl.hasHolidays = $ctrl.holidays.length > 0 ? true : false;
          }
          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges(bindings) {
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function parseHolidays(holidays) {
            var hs = angular.copy(holidays);
            if (!_.isArray(hs)) {
              hs = [];
              return hs;
            }
            hs = _.map(hs, function (h) {
              h.date = uibDateParser.parse(h.date, 'yyyy-MM-dd');
              return h;
            });
            return hs;
          }

          function addNewHoliday() {
            var newHoliday = angular.copy($ctrl.newHoliday);
            $ctrl.holidays.push(newHoliday);
          }

          function deleteHoliday(holiday) {
            var index = _.indexOf($ctrl.holidays, holiday);
            $ctrl.holidays.splice(index, 1);
            onUpdate();
          }

          function onUpdate() {
            var holidays;
            holidays = angular.copy($ctrl.holidays);

            holidays = _.map(holidays, function (h) {
              delete h.isOpened;
              h.date = moment(h.date).format('YYYY-MM-DD');
              return h;
            });
            $ctrl.orgSettings.updateHolidays(holidays);
          }

        }]
    });

})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.fieldPreview
 * @module tallyfy.steps
 *
 * @description
 * A component to preview capture fields
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('fieldPreview', {
      bindings: {
        field: '<',
        displayView: '<?',
        onCancel: '&'
      },
      templateUrl: 'app/modules/steps/capture/fieldPreview/fieldPreview.html',
      controller:
        /*@ngInject*/
        function (_, OrganizationsService, CreateFieldPreviewTableService) {
          var $ctrl = this;

          /**
           * public properties
           */
          $ctrl.options = [];
          $ctrl.columns = [];

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.initTableConfig = initTableConfig;

          /**
           * @function
           * @name onInit
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            generateSplittedDateTime();
            isTitleHide();
            $ctrl.selectedDateFormat = OrganizationsService.dateFormat().dateFormat;
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name onChanges
           * @param {*} changes
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            if (changes.field) {
              var field = changes.field.currentValue;
              if (field && field.field_type === 'multiselect') {
                $ctrl.options = _.differenceBy(field.options, field.value, 'value');
              } else if (field && field.field_type === 'table') {
                $ctrl.columns = _.differenceBy(field.columns, field.value, 'value');
              }
            }
          }

          /**
           * @function
           * @name generateSplittedDateTime
           * @description Generate date and time to show in the splitted input box in UI
           */
          function generateSplittedDateTime() {
            var todayDate = new Date();
            $ctrl.day = todayDate.getDate();
            $ctrl.month = todayDate.getMonth() + 1;
            $ctrl.year = todayDate.getFullYear();
          }

          /**
           * @function
           * @name isTitleHide
           * @description Hide show field title
           */
          function isTitleHide() {
            $ctrl.isHideTitle = (!!$ctrl.displayView && $ctrl.displayView === 'fieldHeader');
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: CreateFieldPreviewTableService.getFieldPreviewTableOptions($ctrl.field.id, $ctrl.columns),
              templateScope: {
                callbacks: {},
                variables: {}
              }
            }
          }
        }
    });
})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('koFieldAssignee', {
      template: '<div translate="compact.task.koAssigneeDescription" translate-compile="true"></div>',
      bindings: {
        koFieldAlias: '<'
      },
      controller:
        /*@ngInject*/
        function ($rootScope) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.tagClick = tagClick;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function tagClick() {
            $rootScope.$emit('KO_FIELD_ASSIGNEE:DESCRIPTION_CLICK', { alias: $ctrl.koFieldAlias });
          }
        }
    })
})();
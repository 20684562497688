/**
 * @ngdoc Directive
 * @name tallyfy.alpha
 * @restrict 'A'
 * @element ANY
 * @author Kodeplusdev ( gmail::kodeplusdev@gmail.com, skype :: dkhoa81 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('validateAlpha', validateAlpha);

    /*@ngInject*/
    function validateAlpha(CONST) {
      return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, elem, attr, ngModel) {
          var validator = function(value) {    
            if (CONST.NAME_REGEX.test(value)) {
              ngModel.$setValidity('alpha', true);
            } else {
              ngModel.$setValidity('alpha', false);
              var unique_arr = value.match(CONST.INVALID_CHARACTERS_MATCH_REGEX).filter(
                function(item, idx, array) {
                  return array.indexOf(item) === idx;
                })
              ;
              ngModel.charactersNotAllowed = unique_arr.join(' ');
            }
            return value;
          };
          ngModel.$parsers.unshift(validator);
          ngModel.$formatters.unshift(validator);
        }
      }
    }

})();

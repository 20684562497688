/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.ootFormCreator
 * @module tallyfy.tasks
 *
 * @description
 * one-off task form creator component
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('ootFormCreator', {
      templateUrl: 'app/modules/tasks/editTask/ootFormCreator/oot-form-creator.html',
      bindings: {
        task: '=',
        form: '=',
        isEditMode: '<',
        showAddForms: '<',
        deletedFormFields: '=?',
        processTasks: '<?'

      },
      require: {},
      controller:
        /*@ngInject*/
        function (_, FieldService, Helper) {
          var $ctrl = this,
            firstSort = true;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.formFields = FieldService.getFields();
          $ctrl.sortableOptions = {
            handle: '.move-capture',
            axis: 'y',
            stop: sortHandler,
            start: startHandler,
            placeholder: 'ui-sortable-placeholder'
          };

          /**
           * public methods
           */
          $ctrl.newField = newField;
          $ctrl.fieldUpdate = fieldUpdate;
          $ctrl.deleteField = deleteField;
          $ctrl.deleteFieldOption = deleteFieldOption;
          $ctrl.deleteFieldColumn = deleteFieldColumn;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
          }

          function newField(type) {
            $ctrl.fieldModel = type;
            if (!_.get($ctrl.task, 'form_fields.data')) {
              _.set($ctrl.task, 'form_fields.data', []);
            }
            collapseAll();
            if (!$ctrl.fieldModel) {
              return;
            }
            var newField = getNewField();
            newField.isExpanded = true;

            $ctrl.task.form_fields.data.push(newField);
            $ctrl.fieldModel = null;
          }

          function getNewField() {
            if (!Helper.checkAccessAuthority())
              return;
            var field, lastField, newId,
              defaultProperties = {
                isNew: true,
                label: null,
                guidance: null
              };
            field = angular.copy($ctrl.fieldModel);
            newId = Helper.getId();
            lastField = _.maxBy($ctrl.task.form_fields.data, 'position');
            field.position = (lastField ? lastField.position : 0) + 1;
            field.id = newId;
            field.slug = newId;
            angular.extend(field, defaultProperties);
            if (!field.title) {
              field.title = field.name + " - " + field.slug;
            }
            return field;
          }

          function collapseAll() {
            _.forEach($ctrl.task.form_fields.data, function (field) {
              if (field.isExpanded && !FieldService.validateCaptures(field)) {
                delete field.isExpanded;
              }
            });
          }

          function fieldUpdate(field) {
            if ($ctrl.isEditMode) {
              if (field.isNew) {
                field.isFormCreate = true;
              } else {
                field.isFormUpdate = true;
              }
            }
            
            if (field && field.isNew && field.field_type === 'date' && !field.hasOwnProperty('collect_time')) {
              field.collect_time = false;
            }
            $ctrl.form.$setDirty();
          }

          function deleteField(field) {
            $ctrl.form.$setDirty();
            var index = _.findIndex($ctrl.task.form_fields.data, {
              'id': field.id
            });
            if (index > -1) {
              if ($ctrl.isEditMode && !field.isNew) {
                $ctrl.deletedFormFields.push($ctrl.task.form_fields.data[index]);
              }
              $ctrl.task.form_fields.data.splice(index, 1);
            }
          }

          function deleteFieldOption(field, option) {
            var fieldIndex, optionIndex;
            fieldIndex = _.findIndex($ctrl.task.form_fields.data, { id: field.id });
            optionIndex = _.findIndex($ctrl.task.form_fields.data[fieldIndex].options, { id: option.id });
            $ctrl.task.form_fields.data[fieldIndex].options.splice(optionIndex, 1);
          }

          function deleteFieldColumn(field, column) {
            var fieldIndex, columnIndex;
            fieldIndex = _.findIndex($ctrl.task.form_fields.data, { id: field.id });
            columnIndex = _.findIndex($ctrl.task.form_fields.data[fieldIndex].columns, { id: column.id });
            $ctrl.task.form_fields.data[fieldIndex].columns.splice(columnIndex, 1);
          }

          /**
           * @name startHandler
           * @param {*} e 
           * @param {*} ui 
           * @description handling when step is start dragging
           * @returns void
           */
          function startHandler(e, ui) {
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          /**
           * @function
           * @name sortHandler
           * @param {Object} ui 
           * @description A handler function to sort the capture
           * @returns void
           */
          function sortHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.task.form_fields.data.length) {
              $ctrl.form.$setDirty();
              reorderCaptures();
            }
          }

          /**
           * @function
           * @name reorderCaptures
           * @description reorder captures
           * @returns void
           */
          function reorderCaptures() {
            var i = 0;
            _.forEach($ctrl.task.form_fields.data, function (value) {
              if ($ctrl.isEditMode) {
                value.isFormUpdate = true;
              }
              value.position = ++i;
            });
          }
        }
    });
})();

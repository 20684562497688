/**
 * @ngdoc Component
 * @name tallyfy.process.component.editComment
 * @module tallyfy.process
 *
 * @description
 * edit comments component
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('editComment', {
      templateUrl: 'app/modules/process/components/readBluePrint/editComment/editComment.html',
      bindings: {
        resolve: '<',
        close: '&'
      },
      require: {},
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          /**
           * public properties
           */

          /**
           * public methods
           */

        }
    });
})();

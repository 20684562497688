(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tocMenuItem', {
      templateUrl: 'app/components/tableOfContent/toc-menu-item/toc-menu-item.component.html',
      bindings: {
        parentId: '<',
        treeMenus: '<',
        type: '<',
        menuClickHandler: '&'
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.goToElement = goToElement;
          $ctrl.menuClick = menuClick;

          function onInit() { }

          function onChanges(changes) {
            if (changes.treeMenus && changes.treeMenus.currentValue) {
              $ctrl.inOrderMenus = _.filter($ctrl.treeMenus, { parent: $ctrl.parentId });
            }
          }

          function onDestroy() { }

          function goToElement(e, menu) {
            e.preventDefault();
            menu.nativeElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
            menuClick(menu);
          }

          function menuClick(menu) {
            if ($ctrl.menuClickHandler && typeof $ctrl.menuClickHandler === 'function') {
              $ctrl.menuClickHandler({ menu: menu });
            }
          }
        }
    });
})();
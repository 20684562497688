/**
 * @ngdoc Directive
 * @name tallyfy.stopImmediatePropagation
 * @description 
 * disabled keybord events
 * @restrict 'E'
 * @element ANY 
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('stopImmediatePropagation', stopImmediatePropagation);

  /*@ngInject*/
  function stopImmediatePropagation($timeout) {
    return {
      restrict: 'A',
      link: function (scope, iElement) {
        $timeout(function () {
          var $a = angular.element(iElement).find("a");
          $a.bind('click', function ($event) {
            $event.stopImmediatePropagation();
          });
          scope.$on('$destroy', function () {
            $a.unbind('click');
          });
        }, 200);

      }
    };
  }

})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.template
 * @module tallyfy.organizations
 *
 * @description
 * Froala single snippet component
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations')
    .component('formTextTemplate', {
      bindings: {
        action: '<',
        template: '=?',
        resolve: '<',
        templateSaveCallback: '&',
        templateDeleteCallback: '&',
        qOnTemplateSelectedChangeCallback: '&',
        isSelected: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/organizations/textTemplate/formTextTemplate/form-text-template.component.html',
      controller:
        function (_, $rootScope, $scope, $filter, $timeout, OrganizationsService, blockUI, Helper, Growl) {
          var $ctrl = this,
            templateWatcherHandler,
            blockUI = blockUI.instances.get('tpl-form'),
            oldTemplate, oldTemplates = [],
            growl = new Growl();

          $ctrl.onSaving = false;
          $ctrl.textTemplateForm = {};
          $ctrl.froalaOptions = {};
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onSaveClick = onSaveClick;
          $ctrl.onCancelClick = onCancelClick;
          $ctrl.onDeleteTemplate = onDeleteTemplate;
          $ctrl.onTemplateSelected = onTemplateSelected;
          $ctrl.closeModal = closeModal;
          $ctrl.hideOnBoardingInput = false;
          $ctrl.hideDiscard = false;

          function onInit() {
            $ctrl.snippetsEditMode = _.get($rootScope.identity, 'role') === 'admin';
            $ctrl.btnClass = ($ctrl.resolve && $ctrl.resolve.type === 'blueprint') ? 'btn-blueprint' : 'nasty-green';
            if (!$ctrl.resolve) {
              generateFroalaConfig();
            }
            oldTemplate = angular.copy($ctrl.template);
          }

          function onChanges(changes) {
            if (changes.resolve) {
              if ($ctrl.resolve.template) {
                $ctrl.template = $ctrl.resolve.template;
              }
              if ($ctrl.resolve.action) {
                $ctrl.action = $ctrl.resolve.action;
              }
              if ($ctrl.resolve.hideOnBoardingInput) {
                $ctrl.hideOnBoardingInput = $ctrl.resolve.hideOnBoardingInput;
              }
              if ($ctrl.resolve.hideDiscard) {
                $ctrl.hideDiscard = $ctrl.resolve.hideDiscard;
              }
              $ctrl.froalaOptions.enableFullscreen = $ctrl.action !== 'edit' && $ctrl.action !== 'view';
              generateFroalaConfig();
            } else {
              if (changes.action && changes.action.currentValue) {
                changes.action.currentValue !== 'edit' && changes.action.currentValue !== 'view' ? $ctrl.template = {} : angular.noop();
              }
            }
          }

          function onDestroy() {
            templateWatcherHandler();
          }

          function generateFroalaConfig() {
            var toolbarsButtons = [];
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              placeholder: $filter('translate')('messages.typeSomething'),
              currentUser: $rootScope.identity,
              enter: FroalaEditor.ENTER_BR,
              hideTemplatePlugin: true,
              heightMin: 320,
              toolbar: {
                colorsStep: 5,
                colorsText: ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'],
                moreText: {
                  buttons: _.concat(
                    toolbarsButtons, [
                    "bold",
                    "italic",
                    "underline",
                    "align",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "fontSize",
                    "textColor",
                    "quote",
                    "codeTag",
                    "emoticons",
                    "fullscreen",
                    "insertImage",
                    "insertFile",
                    "insertVideo",
                    "insertLink",
                    "insertTable",
                    "embedly",
                    "pageBreak"
                  ]),
                  align: 'left',
                  buttonsVisible: 13 + toolbarsButtons.length
                }
              },
              enableFullscreen: $ctrl.action !== 'edit'
            }, true);
          }

          function onSaveClick(template) {
            angular.extend(template, {
              snippet_visible_public: _.get(template, 'snippet_visible_public', false)
            });
            $ctrl.onSaving = true;
            saveTemplate(template)
              .then(function (res) {
                if ($ctrl.action === 'edit') {
                  if ($ctrl.templateSaveCallback && typeof $ctrl.templateSaveCallback === 'function') {
                    $ctrl.templateSaveCallback({
                      template: angular.extend(template, res.data)
                    });
                  } else {
                    if ($ctrl.resolve.qOnTemplateSaveCallback && typeof $ctrl.resolve.qOnTemplateSaveCallback === 'function') {
                      $ctrl.resolve.qOnTemplateSaveCallback({
                        action: $ctrl.action,
                        template: angular.extend(template, res.data)
                      });
                    }
                  }
                } else {
                  if ($ctrl.resolve.qOnTemplateSaveCallback && typeof $ctrl.resolve.qOnTemplateSaveCallback === 'function') {
                    $ctrl.resolve.qOnTemplateSaveCallback({
                      action: $ctrl.action,
                      template: angular.extend(template, res.data),
                      isSelected: $ctrl.isSelected
                    });
                  }
                }
                _.set($rootScope, 'organizationTemplates['+res.data.id+']', res.data);
                oldTemplate = angular.copy(res.data);
                oldTemplates.push(res.data);
                setFormPristine($ctrl.action !== 'edit');
                growl.success($filter('translate')('textTemplate.modal.messages.snippetCreatedSuccessfully'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $ctrl.dismiss();
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function saveTemplate(template) {
            return $ctrl.action === 'edit'
              ? OrganizationsService.updateTextTemplate(template)
              : OrganizationsService.createTextTemplate(template);
          }

          function onCancelClick() {
            if ($ctrl.action === 'edit') {
              angular.extend($ctrl.template, oldTemplate);
              setFormPristine();
            } else {
              $ctrl.dismiss();
            }
          }

          function onDeleteTemplate() {
            blockUI.start();
            $ctrl.onSaving = true;
            OrganizationsService.deleteTextTemplate($ctrl.template.id)
              .then(function (res) {
                $ctrl.templateDeleteCallback(res.data);
                $ctrl.onSaving = false;
                $rootScope.organizationTemplates[$ctrl.template.id] = void 0;
                blockUI.stop();
              }, function () {
                $ctrl.onSaving = false;
                blockUI.stop();
              });
          }

          function setFormPristine(clearModel) {
            $timeout(function () {
              $ctrl.textTemplateForm.$setPristine();
              if (clearModel) {
                $ctrl.template = {};
                $ctrl.isSelected = false;
              }
            }, 0);
          }

          function onTemplateSelected() {
            if ($ctrl.action === 'edit') {
              $ctrl.qOnTemplateSelectedChangeCallback({ value: $ctrl.isSelected ? $ctrl.template.id : '' })
                .then(function (res) {
                  if (res === 'discardChanges') {
                    $ctrl.isSelected = !$ctrl.isSelected;
                  }
                });
            }
          }

          function closeModal() {
            $ctrl.close(
              $ctrl.action === 'edit'
                ? {
                  $value: {
                    action: $ctrl.action,
                    template: $ctrl.template
                  }
                } : {
                    $value: {
                      action: $ctrl.action || 'add',
                      template: oldTemplates
                    }
                  }
            );
          }

          templateWatcherHandler = $scope.$watch('$ctrl.template', function (oldValue, newValue) {
            if (oldValue !== newValue) {
              $ctrl.textTemplateForm.$setDirty();
            }
          }, true);
        }
    });
})();
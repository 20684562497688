/**
 * @ngdoc Config
 * @name tallyfy.support.config.SupportViewStateConfig
 * @module tallyfy.support
 *
 * @description
 * set route Support View
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .config(SupportViewStateConfig);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  SupportViewStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

  /**
   * Constructor
   * SupportViewStateConfig construct
   */
  function SupportViewStateConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/support/', '/support');
    $stateProvider.state('support.view', {
      parent: 'app',
      absolute: true,
      url: '/support/:user_org',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'supportView'
        }
      },
      resolve: {
        authorize: ['Auth', '$state',
          function (Auth, $state) {
            if (Auth.authorize()) {
              Auth.getIdentity().then(function (identity) {
                if (!identity.is_support) {
                  $state.go('home');
                }
              });
            }
            return true;
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('support');
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('support.view.roles', {
      parent: 'app',
      absolute: true,
      url: '/support/:user_org/roles',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'supportView'
        }
      },
      resolve: {
        authorize: ['Auth', '$state',
          function (Auth, $state) {
            if (Auth.authorize()) {
              Auth.getIdentity().then(function (identity) {
                if (!identity.is_support) {
                  $state.go('home');
                }
              });
            }
            return true;
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('support');
          return $translate.refresh();
        }]
      }
    });
  }
})();

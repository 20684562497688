(function () {
  'use strict';
  angular.module('tallyfy')
    .component('contentCardPreview', {
      templateUrl: 'app/modules/dashboard/content-card-preview/content-card-preview.component.html',
      bindings: {

      },
      transclude: {
        'header': 'headerContent',
        'body': 'bodyContent'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

        }
    });
})();
/**
 * @ngdoc constant
 * @name tallyfy.core.events
 * @module tallyfy.core
 *
 * @description
 * Create an Angular Constant for vendor libraries' global variables. Lets use everythings as angular dependecy 
 * avoid global variable/objects inside angular app
 *
 * @author Kiranv Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.core')
    .constant('TFY_EVENTS', {
      USER: {
        UPDATE_DETAILS : 'USER:UPDATE_DETAILS'
      },
      TASK: {
        COMMENT: {
          DELETED: 'COMMENT:DELETED'
        },
        ASSIGNEE: {
          UPDATED: 'TASK:COLLABORATORS_UPDATED'
        }
      },
      BRANDING: {
        'UPDATE_COLOR': 'BRANDING:COLOR_UPDATE',
        'REMOVE_ORG_LOGO': 'ORG:REMOVE_LOGO'
      },
      STEP: {
        'UPDATE_DRAWER_STEP': 'DRAWER:STEP_UPDATE'
      },
      GRIDLY: {
        'UNDO_ANIMATION' : 'GRIDLY:UNDO_ANIMATION',
        'RE_INIT_ANIMATION' : 'GRIDLY:RE_INIT_ANIMATION',
        'REMOVE_GRIDLY_ON_CLICK': 'GRIDLY:REMOVE_GRIDLY_ON_CLICK',
        'RE_SIZE_BOX_WIDTH': 'GRIDLY:RE_SIZE_BOX_WIDTH'
      }
    });
})();

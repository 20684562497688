(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.run.factory.RunRepository
   * @module tallyfy.run
   *
   * @description
   * Create Rest resource for run
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.run')
    .factory('RunRepository',
      /*@ngInject*/
      function RunRepository(BaseRepository, _, moment, DateUtils, CONFIG, Helper) {
        var BASE_URI_SEGMENT = '/organizations/:org', BASE_GUEST_URI_SEGMENT = '/organizations/:org/guests/guest_code', repository;

        /**
         * @name transformedResponse
         * @param {*} response 
         * @returns transformed response
         * 
         * @description
         * transform run response from API
         */
        function transformResponse(response, headers) {
          var transformedResponse = {};
          var isContentDisposition = headers()['content-disposition'];
          if (isContentDisposition) {
            transformedResponse.data = response;
            transformedResponse.headers = headers();
          } else {
            response = response && Helper.isJson(response) ? angular.fromJson(response) : { message: response };
            if (response.hasOwnProperty('data')) {
              transformedResponse.data = mappingRunData(response.data);
            } else {
              transformedResponse.data = mappingRunData(response);
            }
            if (response.hasOwnProperty('meta')) {
              transformedResponse.meta = response.meta;
            }

            if (_.get(response, 'data.started_at')) {
              response.data.started_at = DateUtils.toLocal(response.data.started_at).format();
            }
            if (_.get(response, 'data.started_at_as_str')) {
              response.data.started_at_as_str = DateUtils.toLocal(response.data.started_at_as_str).format();
            }
          }
          return transformedResponse;
        }

        /**
         * @name mappingRunData
         * @param {*} obj = run object
         * @returns mapped run object
         * 
         * @description
         * mapping run data
         */
        function mappingRunData(obj) {
          return _.isArray(obj) ? _.map(obj, function (run) { return mappingRunDeadline(run); }) : mappingRunDeadline(obj);
        }

        /**
         * @name mappingRunDeadline
         * @param {*} run object
         * @returns mapped run object
         * 
         * @description
         * mapping due_date_passed and due_soon value
         */
        function mappingRunDeadline(run) {
          if (run.tasks) {
            var deadlines = _.compact(_.map(run.tasks.data, function (task) {
              if (task.status !== 'completed' && task.status !== 'auto-skipped') {
                return moment(task.deadline);
              }
            }));
            if (deadlines.length <= 0) {
              run.due_date_passed = false;
              run.due_soon = false;
            } else {
              var closestTaskDeadline = moment(DateUtils.toLocal(moment.min(deadlines)).format()),
                duration = moment.duration(closestTaskDeadline.diff(moment())).asHours();
              run.due_date_passed = closestTaskDeadline.isBefore(moment());
              run.due_soon = (duration >= 0 && duration < 24);
            }
          }
          return run;
        }

        repository = BaseRepository('/organizations/:org/runs/:id/:action', {
          org: 'org_id',
          id: '@id',
          action: '@action'
        }, {
          $all: { method: 'GET', cancellable: true, transformResponse: transformResponse },
          $get: { method: 'GET', transformResponse: transformResponse },
          $create: { method: 'POST', transformResponse: transformResponse },
          $update: { method: 'PUT', transformResponse: transformResponse },
          getRunsPermissions: {
            method: 'GET',
            url: CONFIG.API_HOST + '/organizations/:org/runs/:id/permissions',
            transformResponse: transformResponse
          },
          setRunsPermissions: {
            method: 'PUT',
            url: CONFIG.API_HOST + '/organizations/:org/runs/:id/permissions',
            transformResponse: transformResponse
          },
          getRunsByChecklist: {
            method: 'GET',
            url: CONFIG.API_HOST + '/organizations/:org/checklists-list'
          },
          getRunFormFields: {
            method: 'GET',
            url: CONFIG.API_HOST + '/organizations/:org/runs/:id/form-fields'
          },
          getChecklistFormFields: {
            method: 'GET',
            url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/form-fields'
          },
          getRunStageTask: {
            method: 'GET',
            url: CONFIG.API_HOST + '/organizations/:org/runs/:run_id/stages/tasks'
          }
        });

        repository.getRunsData = function (requestParameters) {
          return repository.get(requestParameters);
        };

        repository.updateRunsData = function (requestParameters, data) {
          return repository.update(requestParameters, data);
        };

        repository.deleteRunsData = function (requestParameters) {
          return repository.delete(requestParameters);
        };

        repository.activeRunsData = function (requestParameters) {
          requestParameters = requestParameters || {};
          var params = {
            id: requestParameters,
            action: 'activate'
          };
          angular.extend(params, requestParameters);
          return repository.update(params);
        };

        repository.exportCSV = function (requestParameters) {
          requestParameters = requestParameters || {};
          var params = {
            id: requestParameters,
            action: 'export'
          };
          angular.extend(params, requestParameters);
          return repository.create(params);
        };

        repository.permanentlyDeleteRun = function (requestParameters) {
          requestParameters = requestParameters || {};
          var params = {
            id: requestParameters,
            action: 'delete'
          };
          angular.extend(params, requestParameters);
          return repository.delete(params);
        };

        repository.getActiveProcesses = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/runs-list', angular.extend({ org: 'org_id' }, args), {}).get();
        };

        repository.getActiveBlueprints = function (args) {
          var params = angular.extend(args || {}, {
            org: 'org_id'
          });
          return BaseRepository(BASE_URI_SEGMENT + '/checklists', params, {}).get();
        };

        repository.getGuestRunData = function (args) {
          var params = angular.extend(args, { skipAuthToken: true });
          var guestRepository = getGuestRunRepository(args);
          return guestRepository.get(params);
        };

        repository.getGuestRunDetails = function (args) {
          var params = angular.extend(args, {
            skipAuthToken: true,
            sub_action: 'tasks'
          });
          var guestRepository = getGuestRunRepository(args);
          return guestRepository.get(params);
        };

        function getGuestRunRepository(params) {
          return BaseRepository(BASE_GUEST_URI_SEGMENT + '/:action/:action_id/:sub_action', {
            org: 'org_id',
            action: params.action || 'runs',
            action_id: params.action_id || params.runId
          }, {
            $get: { method: 'GET', transformResponse: transformResponse },
            $create: { method: 'POST', transformResponse: transformResponse },
            $update: { method: 'PUT', transformResponse: transformResponse }
          });
        }

        repository.updateGuestPrerun = function (params, body) {
          params.action = 'form-field';
          params.action_id = 'value';
          var guestRepository = getGuestRunRepository(params);
          return guestRepository.create(params, body);
        };

        repository.getPublicSingleRunData = function (params) {
          var publicRunRepository = getPublicRunRepository(params);
          return publicRunRepository.get(params);
        };

        function getPublicRunRepository(params) {
          params = angular.extend(params, {
            org: 'org_id',
            action: 'runs',
            action_id: params.runId,
            skipAuthToken: true
          });
          return BaseRepository(BASE_URI_SEGMENT + '/public/:action/:action_id/:sub_action', params, {
            $get: { method: 'GET', transformResponse: transformResponse }
          });
        }
        return repository;
      }
    );
})();
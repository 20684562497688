/**
 * @ngdoc Filter
 * @name toText
 * @module tallyfy
 * @description
 * covert HTML to plain text
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('toText', ['_', function (_, Helper) {
      var tag = document.createElement('div');
      return function (html, props) {
        tag.innerHTML = Helper.removeScriptTagOnString(html);

        return _.trim(tag.innerText.replace(/\n/g, ''));
      };
    }]);
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.account.factory.FavoriteRepository
   * @module tallyfy.account
   *
   * @description
   * Create Rest resource for account
   *
   * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
   * 
   * */
  angular
    .module('tallyfy.account')
    .factory('FavoriteRepository', FavoriteRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  FavoriteRepository.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * FavoriteRepository construct 
   */
  function FavoriteRepository(BaseRepository, CONFIG) {
    return BaseRepository('/organizations/:org/watchers', {
      org: 'org_id'
    }, {
        getFavorite: {
          method: 'GET',
          url: CONFIG.API_HOST + '/organizations/:org/watchers',
          params: {
            org: 'org_id'
          }
        },
        addFavorite: {
          method: 'POST',
          url: CONFIG.API_HOST + '/organizations/:org/users/:user/add-watcher',
          params: {
            watcherId: '@user'
          }
        },
        updateFavorite: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/users/:user/update-watcher/:watcher_id',
          params: {
            user: '@user',
            watcher_id: '@watcherId'
          }
        },
        deleteFavorite: {
          method: 'DELETE',
          url: CONFIG.API_HOST + '/organizations/:org/remove-watcher/:watcher_id',
          params: {
            watcher_id: '@watcherId'
          }
        },
        getGuest: {
          method: 'GET',
          url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/watches',
          params: {
            org: '@org',
            guest_code: '@guest_code'
          }
        },
        addGuestFavorite: {
          method: 'POST',
          url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/guest-watcher',
          params: {
            org: '@org',
            guest_code: '@guest_code'
          }
        },
        updateGuestFavorite: {
          method: 'PUT',
          url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/guest-watch/:watch_id',
          params: {
            org: '@org',
            guest_code: '@guest_code',
            watch_id: '@watch_id'
          }
        },
        deleteGuestFavorite: {
          method: 'DELETE',
          url: CONFIG.API_HOST + '/organizations/:org/guests/:guest_code/guest-watch/:watch_id',
          params: {
            org: '@org',
            guest_code: '@guest_code',
            watch_id: '@watch_id'
          }
        }
      });
  }
})();
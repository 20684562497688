/**
 * @ngdoc Service
 * @name $assigner
 *
 * @module tallyfy
 *
 * @description
 * Common service for reusable components
 * ownerAssigner component
 * tagAssigner component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('$assigner', [
      '$uibModal',
      '_',
      function ($uibModal, _) {
        /**
         * @name ownerAssigner
         * @description A reusable function to assigne owners.
         * @params options {Object} options can have 
         * users : A list of all users in org.
         * owners: A subset of users
         * params: Additional params 
         */
        this.ownerAssigner = function (options) {
          var owners = [], users = [], orgGroups = [], groups = [], guests = [], params = {};
          options = options || {};
          if (_.isArray(options.owners)) {
            owners = options.owners;
          }
          if (_.isArray(options.users)) {
            users = options.users;
          }
          if (_.isArray(options.groups)) {
            groups = options.groups;
          }
          if (_.isArray(options.orgGroups)) {
            orgGroups = options.orgGroups;
          }
          if (_.isArray(options.guests)) {
            guests = options.guests;
          }

          if (!_.isEmpty(options.params) && _.isObject(options.params)) {
            angular.extend(params, options.params);
          }
          return $uibModal.open({
            component: 'ownerAssigner',
            resolve: {
              users: function () {
                return users;
              },
              orgGroups: function () {
                return orgGroups;
              },
              owners: function () {
                return owners;
              },
              groups: function () {
                return groups;
              },
              guests: function () {
                return guests;
              },
              params: function () {
                return params;
              }
            }
          });
        };

        /**
         * @name tagAssigner
         * @description A reusable function to assigne selectedTags.
         * @params options {Object} options can have 
         * tags : A list of all tags in org.
         * selectedTags: A subset of tags
         * params: Additional params 
         */
        this.tagAssigner = function (options) {
          var selectedTags = [], tags = [], params = {};
          options = options || {};
          if (_.isArray(options.selectedTags)) {
            selectedTags = options.selectedTags;
          }
          if (_.isArray(options.tags)) {
            tags = options.tags;
          }
          if (!_.isEmpty(options.params) && _.isObject(options.params)) {
            angular.extend(params, options.params);
          }
          return $uibModal.open({
            component: 'tagAssigner',
            backdrop: 'static',
            resolve: {
              tags: function () {
                return tags;
              },
              selectedTags: function () {
                return selectedTags;
              },
              params: function () {
                return params;
              }
            }
          });
        };

        //end of service
      }]);
})();

(function () {
  'use strict';
/**
 * @TODO
 * Refactoring
 */
  angular
    .module('tallyfy.config')

    /*
     Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
     They are written in English to avoid character encoding issues (not a perfect solution)
     */
    .constant('TFY_LOCALE', {
      locales: ['en', 'es', 'nl', 'pt', 'vi', 'zh', 'pt-br', 'ja', 'fr', 'de'],
      mapping: {
        'en-*': 'en',
        'es-*': 'es',
        'nl-*': 'nl',
        'pt-br*': 'pt-br',
        'pt-*': 'pt',
        'zh-*': 'zh',
        'ja-*': 'ja',
        'fr-*': 'fr',
        'de-*': 'de'
      }
    });
})();

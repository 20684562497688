/**
 * @ngdoc Service
 * @name tallyfy.organizations.OrganizationsService
 *
 * @module tallyfy.organizations
 *
 * @description
 * OrganizationsService
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 * @modified by Mohan Singh 
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .service('OrganizationsService', OrganizationsService);
  /*@ngInject*/
  function OrganizationsService($rootScope, $q, OrganizationsRepository, _, $http, PLANS, $filter, $uibModal, USER_STATE) {
    var self = this, newOrgDetails = {
      invited_members: [{ role: 'standard' }],
      scheduleCall: false,
      phoneDialCode: '',
      recurly_token: '',
      plan_code: PLANS.FREE,
      team_size_above: 0, //Default - can be changed in settings
      signup_survey: {
        scheduleCall: '',
        improveAndWhy: ''
      },
      preferred_trial_plan: PLANS.PRO //Default - PRO
    },
      globalSearchPromise,
      samlProvidersList = [
        {
          name: 'Okta',
          value: 'okta'
        },
        {
          name: 'G-Suite',
          value: 'g-suite'
        },
        {
          name: 'OneLogin',
          value: 'onelogin'
        },
        {
          name: 'Azure AD',
          value: 'azure-ad'
        }
      ],
      newSignupDetails = [];

    angular.extend(self, OrganizationsRepository);

    /**
    * @ngdoc method
    * @name myOrganizations
    * @description get current user orgs
    * @param {*} args
    * @returns promise
   */
    self.myOrganizations = function (args) {
      args = args || {};
      return OrganizationsRepository.myOrganizations(args).$promise;
    };

    self.onDeleteOrgActiveTokens = function () {
      return OrganizationsRepository.deleteOrgActiveTokens().$promise;
    };
    
    self.myGuestOrganizations = function (args, params) {
      return OrganizationsRepository.myGuestOrganizations(angular.extend(args, params, { skipNotFound: true }));
    };

    /**
     * @ngdoc method
     * @name getFileIdFromOrgLogoUrl
     * @description Return fileId from organization logo url
     * @param {String} fileUrl
     * @returns {String} fileId
    */
    self.getFileIdFromOrgLogoUrl = function (fileUrl) {
      return _.last(_.split(fileUrl, '/'));
    };

    /**
     * @ngdoc method
     * @name getIndustries
     * @description Get industries data from json file
     * @returns {Promise}
    */
    self.getIndustries = function () {
      var defer = $q.defer();
      $http.get('/resources/industry.json')
        .then(
          function (res) {
            defer.resolve(res.data);
          }, function () {
            return defer.reject;
          });
      return defer.promise;
    };

    /**
     * @ngdoc method
     * @name getDefaultOrgLogoForPlan
     * @description Get logo url based on plan type
     * @returns {string}
    */
    self.getDefaultOrgLogoForPlan = function (plan) {
      if ([PLANS.PRO, PLANS.PROMONTHLY, PLANS.PROANNUAL].indexOf(plan) > -1) {
        return 'assets/images/logo_pro@2x.png';
      }
      else if ([PLANS.BASIC, PLANS.BASICMONTHLY, PLANS.BASICANNUAL].indexOf(plan) > -1) {
        return 'assets/images/logo_basic@2x.png';
      }
      else if (PLANS.FREE === plan) {
        return 'assets/images/logo_starter@2x.png';
      }
      else if ([PLANS.DOCS, PLANS.DOCSMONTHLY, PLANS.DOCSANNUAL].indexOf(plan) > -1) {
        return 'assets/images/logo_docs@2x.png';
      }
      else {
        return 'assets/images/logo@2x.png';
      }
    };

    /**
     * @ngdoc method
     * @name setOrgCustomBranding
     * @description Set custom branding for an organization
     * @returns {Promise}
     */
    self.setOrgCustomBranding = function (data) {
      return OrganizationsRepository.setOrgCustomBranding(data).$promise;
    };

    /**
     * @ngdoc method
     * @name deleteOrgCustomBranding
     * @description Delete custom branding for an organization
     * @returns {Promise}
     */
    self.deleteOrgCustomBranding = function () {
      return OrganizationsRepository.deleteOrgCustomBranding().$promise;
    };

    /**
     * @ngdoc method
     * @name getOrgCustomBranding
     * @description get custom branding for an organization
     * @returns {Promise}
     */

    self.getOrgCustomBranding = function (args) {
      return OrganizationsRepository.getOrgCustomBranding(args).$promise;
    };

    self.getPlanFeature = function (planName) {
      var translation = [];
      if (planName === 'basic' || planName === 'pro') {
        for (var i = 0; i <= 3; i++) {
          translation.push($filter('translate')('updateOrgPlan.plans.' + planName + '.features._' + i))
        }
      } else if (planName === 'docs') {
        for (var i = 0; i <= 2; i++) {
          translation.push($filter('translate')('updateOrgPlan.plans.' + planName + '.features._' + i))
        }
      }

      return translation;
    };

    self.createOrganization = function (params, data) {
      return OrganizationsRepository.createOrganization(params, data).$promise;
    };

    self.getTeamSize = function () {
      return [
        { label: $filter('translate')('signup.teamSize.lessThanFive'), value: 0 },
        { label: $filter('translate')('signup.teamSize.sixToTwentyFive'), value: 5 },
        { label: $filter('translate')('signup.teamSize.TwentySixToFifty'), value: 25 },
        { label: $filter('translate')('signup.teamSize.fiftyOneToHundred'), value: 50 },
        { label: $filter('translate')('signup.teamSize.twoHundred'), value: 100 },
        { label: $filter('translate')('signup.teamSize.twoHundredPlus'), value: 200 }
      ];
    };

    self.getDeadlineFormat = function () {
      return [
        { label: '15/03/2023 5:06 AM', format: 'DD/MM/YYYY h:mm a', dateFormat: 'dd/mm/yyyy' },
        { label: '3/15/23 5:06 AM', format: 'M/D/YY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: '03/15/23 5:06 AM', format: 'MM/DD/YY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 5:06 AM', format: 'MM-DD-YY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 5:06 AM', format: 'M-D-YY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: 'Mar 15, 2023 5:06:07 AM', format: 'MMM D, YYYY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: 'Monday, March 15, 2023 5:06:07 AM CET', format: 'dddd, MMMM D, YYYY h:mm:ss a z', dateFormat: 'mm/dd/yyyy' },
        { label: 'Mon Mar 15 05:06:07 CET 2023', format: 'ddd MMM DD HH:mm:ss z YYYY', dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-2023 5:06:07 AM', format: 'MM-DD-YYYY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 5:06:07 AM', format: 'M-D-YYYY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '2023-03-15 5:06:07 AM', format: 'YYYY-MM-DD h:mm:ss a', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-3-15 5:06:07 AM', format: 'YYYY-M-D h:mm:ss a', dateFormat: 'yyyy/mm/dd' },
        { label: 'Monday, 15 March 2023', format: 'dddd, D MMMM YYYY', dateFormat: 'dd/mm/yyyy' },
        { label: '2023-03-15 05:06:07.0', format: 'YYYY-MM-DD HH:mm:ss.S', dateFormat: 'yyyy/mm/dd' },
        { label: '15/03/2023 5:06:07 AM', format: 'DD/MM/YYYY h:mm:ss a', dateFormat: 'dd/mm/yyyy' },
        { label: '15/3/2023 5:06:07 AM', format: 'D/M/YYYY h:mm:ss a', dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/2023 5:06:07 AM', format: 'MM/DD/YYYY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/2023 5:06:07 AM', format: 'M/D/YYYY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '03/15/23 5:06:07 AM', format: 'MM/DD/YY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '03/15/23 5:06:07', format: 'MM/DD/YY H:mm:ss', dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/23 5:06:07', format: 'M/D/YY H:mm:ss', dateFormat: 'mm/dd/yyyy' },
        { label: '03/15/2023 5:06 AM', format: 'MM/DD/YYYY h:mm a', dateFormat: 'mm/dd/yyyy' }
      ];
    };

    // To be concatenated with the above if required. Part of GH: https://github.com/tallyfy/client/issues/13231, PR: https://github.com/tallyfy/client/pull/14772
    self.getAdditionalDeadlineFormat = function () {
      return [
        { label: '15/03/2023 5:06 AM', format: 'DD/MM/YYYY h:mm a', dateFormat: 'dd/mm/yyyy' },
        { label: '15/3/2023 5:06 AM', format: 'D/M/YYYY h:mm a', dateFormat: 'dd/mm/yyyy' },
        { label: '3/15/2023 5:06 AM', format: 'M/D/YYYY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 5:06:07 AM', format: 'MM-DD-YY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 5:06:07 AM', format: 'M-D-YY h:mm:ss a', dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-2023 5:06 AM', format: 'MM-DD-YYYY h:mm', dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 5:06 AM', format: 'M-D-YYYY h:mm a', dateFormat: 'mm/dd/yyyy' },
        { label: '2023-03-15 5:06 AM', format: 'YYYY-MM-DD h:mm a', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-3-15 5:06 AM', format: 'YYYY-M-D h:mm a', dateFormat: 'yyyy/mm/dd' },
        { label: 'Mar.15.2023', format: 'MMM.DD.YYYY', dateFormat: 'mm/dd/yyyy' },
        { label: '15/Mar/2023 5:06:07 +0100', format: 'D/MMM/YYYY H:mm:ss Z', dateFormat: 'dd/mm/yyyy' },
        { label: '15/Mar/23 5:06 AM', format: 'DD/MMM/YY h:mm a', dateFormat: 'dd/mm/yyyy' },
        { label: '23/03/15 5:06', format: "YY/MM/DD H:mm", dateFormat: 'yyyy/mm/dd' },
        { label: '03/15/23 5:06', format: "MM/DD/YY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/23 5:06', format: "M/D/YY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 5:06', format: "MM-DD-YY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 5:06 AM', format: "M-D-YY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-2023 5:06:07', format: "MM-DD-YYYY H:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 5:06:07', format: "M-D-YYYY H:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '2023-03-15 5:06:07', format: "YYYY-MM-DD H:mm:ss", dateFormat: 'yyyy/mm/dd' },
        { label: '23/03/15 5:06:07', format: "YY/MM/DD H:mm:ss", dateFormat: 'yyyy/mm/dd' },
        { label: '3/15/23 5:06:07 AM', format: "M/D/YY h:mm:ss a", dateFormat: 'mm/dd/yyyy' },
        { label: '2023/03/15 5:06', format: "YYYY/MM/DD H:mm", dateFormat: 'yyyy/mm/dd' },
        { label: '15/03/2023 5:06', format: "DD/MM/YYYY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '15/3/2023 5:06', format: "D/M/YYYY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/2023 5:06', format: "MM/DD/YYYY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/2023 5:06', format: "M/D/YYYY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 5:06:07', format: "MM-DD-YY H:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 5:06:07', format: "M-D-YY H:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-2023 5:06', format: "MM-DD-YYYY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 5:06', format: "M-D-YYYY H:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '2023-03-15 5:06', format: "YYYY-MM-DD H:mm", dateFormat: 'yyyy/mm/dd' },
        { label: '2023-3-15 5:06', format: "YYYY-M-D H:mm", dateFormat: 'yyyy/mm/dd' },
        { label: '15/03/2023 5:06', format: "D/MM/YY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '15.3.23 5:06', format: "D.M.YY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: 'Mon, 15 Mar 2023 05:06:07 +0100', format: 'ddd, D MMM YYYY HH:mm:ss Z', dateFormat: 'dd/mm/yyyy' },
        { label: '15 Mar 2023 05:06:07 +0100', format: 'D MMM YYYY HH:mm:ss Z', dateFormat: 'dd/mm/yyyy' },
        { label: '15 mars 2023 05:06:07 CET', format: "D MMMM YYYY HH:mm:ss z", dateFormat: 'dd/mm/yyyy' },
        { label: 'March 15, 2023 5:06:07 CET AM', format: "MMMM D, YYYY h:mm:ss z a", dateFormat: 'mm/dd/yyyy' },
        { label: '2023-03-15 05:06:07', format: "YYYY-MM-DD HH:mm:ss", dateFormat: 'yyyy/mm/dd' },
        { label: '15/03/23 05:06', format: "DD/MM/YY HH:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/23 05:06', format: "MM/DD/YY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/23 05:06', format: "M/D/YY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 05:06', format: "MM-DD-YY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 05:06', format: "M-D-YY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '15 mars 2023 05:06:07', format: "D MMM YYYY HH:mm:ss", dateFormat: 'dd/mm/yyyy' },
        { label: '15 mars 2023 05:06:07 CET', format: "D MMMM YYYY HH:mm:ss z", dateFormat: 'dd/mm/yyyy' },
        { label: '03-15-2023 05:06:07', format: "MM-DD-YYYY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 05:06:07', format: "M-D-YYYY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '2023-3-15 05:06:07', format: "YYYY-M-D HH:mm:ss", dateFormat: 'yyyy/mm/dd' },
        { label: '15/03/2023 05:06:07', format: "DD/MM/YYYY HH:mm:ss", dateFormat: 'dd/mm/yyyy' },
        { label: '15/3/2023 05:06:07', format: "D/M/YYYY HH:mm:ss", dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/2023 05:06:07', format: "MM/DD/YYYY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/2023 05:06:07', format: "M/D/YYYY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '15/03/23 05:06:07', format: "DD/MM/YY HH:mm:ss", dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/23 05:06:07', format: "MM/DD/YY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/23 05:06:07', format: "M/D/YY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '15/03/2023 05:06', format: "DD/MM/YYYY HH:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '15/3/2023 05:06', format: "D/M/YYYY HH:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '03/15/2023 05:06', format: "MM/DD/YYYY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3/15/2023 05:06', format: "M/D/YYYY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-23 05:06:07', format: "MM-DD-YY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-23 05:06:07', format: "M-D-YY HH:mm:ss", dateFormat: 'mm/dd/yyyy' },
        { label: '03-15-2023 05:06', format: "MM-DD-YYYY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '3-15-2023 05:06', format: "M-D-YYYY HH:mm", dateFormat: 'mm/dd/yyyy' },
        { label: '2023-3-15 05:06', format: "YYYY-M-D HH:mm", dateFormat: 'yyyy/mm/dd' },
        { label: '15-3-23 5:06', format: "D-M-YY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '15-03-2023 5:06', format: "DD-MM-YYYY H:mm", dateFormat: 'dd/mm/yyyy' },
        { label: '05:06:07 15-03-2023', format: "HH:mm:ss DD-MM-YYYY", dateFormat: 'dd/mm/yyyy' },
        { label: '05:06 15/03/2023', format: "HH:mm DD/MM/YYYY", dateFormat: 'dd/mm/yyyy' },
        { label: '23-3-15 上午5:06', format: 'YY-M-D ah:mm', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-3-15 5:06:07', format: 'YYYY-M-D H:mm:ss', dateFormat: 'yyyy/mm/dd' },
        { label: '2023年3月15日 上午05时06分07秒', format: "YYYY'年'M'月'D'日' ahh'时'mm'分'ss'秒'", dateFormat: 'yyyy/mm/dd' },
        { label: '2023年3月15日 5時06分07秒 CET', format: "YYYY'年'M'月'D'日' H'時'mm'分'ss'秒' z", dateFormat: 'yyyy/mm/dd' },
        { label: '2023年3月15日 星期一 上午05时06分07秒 CET', format: "YYYY'年'M'月'D'日' dddd ahh'时'mm'分'ss'秒' z", dateFormat: 'yyyy/mm/dd' },
        { label: '15 March 2023 05:06:07 CET', format: 'DD MMMM YYYY HH:mm:ss z', dateFormat: 'dd/mm/yyyy' },
        { label: 'Monday, 15 March 2023 05:06:07 o\'clock CET', format: 'dddd, D MMMM YYYY HH:mm:ss \'o\'\'clock\' z', dateFormat: 'dd/mm/yyyy' },
        { label: '15-Mar-2023 05:06:07', format: 'DD-MMM-YYYY HH:mm:ss', dateFormat: 'dd/mm/yyyy' },
        { label: '15-Mar-23 05.06.07.000000888 AM', format: 'DD-MMM-YY hh.mm.ss.SSSSSSSSS a', dateFormat: 'dd/mm/yyyy' },
        { label: '2023-03-15T05:06:07.000+01:00', format: 'YYYY-MM-DD\'T\'HH:mm:ss.SSSXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07,000+01:00', format: 'YYYY-MM-DD\'T\'HH:mm:ss,SSSXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07.000+01:00', format: 'YYYY-MM-DD HH:mm:ss.SSSXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07,000+01:00', format: 'YYYY-MM-DD HH:mm:ss,SSSXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07+01', format: 'YYYY-MM-DD\'T\'HH:mm:ssX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07+01', format: 'YYYY-MM-DD HH:mm:ssX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07+01:00', format: 'YYYY-MM-DD\'T\'HH:mm:ssXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07+01:00', format: 'YYYY-MM-DD HH:mm:ssXXX', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07Z', format: 'YYYY-MM-DD\'T\'HH:mm:ssZ', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07Z', format: 'YYYY-MM-DD HH:mm:ss\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07Z', format: 'YYYY-MM-DD HH:mm:ssZ', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07.000Z', format: 'YYYY-MM-DD\'T\'HH:mm:ss.SSS\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07,000Z', format: 'YYYY-MM-DD\'T\'HH:mm:ss,SSS\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2032-03-15 05:06:07.000Z', format: 'YYYY-MM-DD HH:mm:ss.SSS\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07,000Z', format: 'YYYY-MM-DD HH:mm:ss,SSS\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07Z', format: 'YYYY-MM-DD\'T\'HH:mm:ss\'Z\'', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07', format: 'YYYY-MM-DD\'T\'HH:mm:ss', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07,000', format: 'YYYY-MM-DD HH:mm:ss,SSS', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15 05:06:07.000', format: 'YYYY-MM-DD HH:mm:ss.SSS', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07,000', format: 'YYYY-MM-DD\'T\'HH:mm:ss,SSS', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15T05:06:07.000', format: 'YYYY-MM-DD\'T\'HH:mm:ss.SSS', dateFormat: 'yyyy/mm/dd' },
        { label: '2023-03-15+01:00', format: 'YYYY-MM-DDXXX', dateFormat: 'yyyy/mm/dd' }
      ];
    };
    
    self.dateFormat = function () {
      var isGuest = $rootScope.userState === USER_STATE.GUEST,
        preferences = (isGuest ? _.get($rootScope.identity.guest, 'preferences') : _.get($rootScope.identity, 'preferences')),
        dateformatPreferences = _.find(preferences, { slug: 'organization_date_format' }),
        dateformatPreferencesSlugVal = _.get(dateformatPreferences, 'metadata.format');
      
      if (!dateformatPreferencesSlugVal) {
        return _.find(self.getDeadlineFormat(), { format: 'MM/DD/YYYY h:mm a' });
      } else {
        var dateformatObj = _.find(self.getDeadlineFormat(), { format: dateformatPreferencesSlugVal });
        return dateformatObj ? dateformatObj : _.find(self.getDeadlineFormat(), { format: 'MM/DD/YYYY h:mm a' });
      }
    };
    
    self.getDateFormat = function () {
      return self.dateFormat().format;
    };

    self.getOrgCarouselViews = function () {
      return {
        'org_name': { position: 1, name: 'org_name', templateName: '01_org_name', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.org_name.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.org_name.left_pane.hero_text_sm') } },
        'plan_selection': { position: 2, name: 'plan_selection', templateName: '02_plan_selection', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.plan_selection.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.plan_selection.left_pane.hero_text_sm'), view_name_on_back: 'dashboard_complete' } },
        'invite_members_and_complete': { position: 3, name: 'invite_members_and_complete', templateName: '03_invite_members_and_complete', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.invite_members_and_complete.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.invite_members_and_complete.left_pane.hero_text_sm'), view_name_on_back: 'plan_selection' } },
        'problem_solve': { position: 4, name: 'problem_solve', templateName: '04_problem_solve', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.problem_solve.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.problem_solve.left_pane.hero_text_sm'), view_name_on_back: 'org_name' } },
        'process_improve': { position: 4, name: 'process_improve', templateName: '05_process_improve', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.process_improve.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.process_improve.left_pane.hero_text_sm'), view_name_on_back: 'problem_solve' } },
        'dashboard_complete': { position: 4, name: 'dashboard_complete', templateName: '06_dashboard_complete', leftPane: { heroTextLG: $filter('translate')('signup.v2_sign_up_views.process_improve.left_pane.hero_text_lg'), heroTextSM: $filter('translate')('signup.v2_sign_up_views.process_improve.left_pane.hero_text_sm'), view_name_on_back: 'process_improve' } }
      };
    };

    self.getTextTemplates = function (params) {
      if ($rootScope.userState === USER_STATE.GUEST) {
        return OrganizationsRepository.getGuestTextTemplates(params);
      } else {
        angular.extend(params, {
          org: 'org_id',
          action: 'text-templates'
        });
        return OrganizationsRepository.getTextTemplates(params);
      }
    };

    self.getTextTemplate = function (id, skipNotFound, userState) {
      var params = {};
      angular.extend(params, { skipNotFound: skipNotFound });
      if (userState === USER_STATE.GUEST) {
        angular.extend(params, {
          id: id
        });
        return OrganizationsRepository.getGuestTextTemplates(params);
      } else if (userState === USER_STATE.PUBLIC) {
        angular.extend(params, {
          id: id,
          org: $rootScope.publicOrganization.id
        });
        return OrganizationsRepository.getGuestTextTemplates(params);

      } else {
        angular.extend(params, { skipNotFound: skipNotFound });
        angular.extend(params, {
          org: 'org_id',
          action: 'text-templates',
          id: id
        });
        return OrganizationsRepository.getTextTemplate(params);
      }
    };

    self.createTextTemplate = function (data) {
      var params = {
        org: 'org_id',
        action: 'text-templates'
      };
      return OrganizationsRepository.createTextTemplate(params, data);
    };

    self.updateTextTemplate = function (data) {
      var params = {
        org: 'org_id',
        action: 'text-templates'
      };
      return OrganizationsRepository.updateTextTemplate(params, data);
    };

    self.updateGuestVisibleSnippet = function (data) {
      var params = {
        org: 'org_id'
      };
      return OrganizationsRepository.updateTextTemplate(params, data);
    };

    self.deleteTextTemplate = function (id) {
      var params = {
        org: 'org_id',
        action: 'text-templates'
      };
      angular.extend(params, {
        id: id
      });
      return OrganizationsRepository.deleteTextTemplate(params);
    };

    self.openCreateTextTemplateModal = function (type) {
      var defer = $q.defer();
      $uibModal.open({
        backdrop: 'static',
        component: 'formTextTemplate',
        windowClass: 'text-template-modal',
        resolve: {
          type: function () {
            return type;
          }
        }
      }).result.then(function (temp) {
        var templates = [];
        if (_.get(temp, 'template', []).length > 0) {
          _.forEach(_.get(temp, 'template', []), function (value, key) {
            templates.push(value);
          });
        }
        defer.resolve({ template: templates });
      }, function (err) {
        defer.reject(err);
      });
      return defer.promise;
    };

    self.setNewOrgData = function (data) {
      newOrgDetails = data;
    };

    self.getNewOrgData = function () {
      return newOrgDetails;
    };

    self.globalSearch = function (requestParams) {
      if (globalSearchPromise) {
        globalSearchPromise.$cancelRequest();
      }
      globalSearchPromise = OrganizationsRepository.fetchSearchResults(requestParams);
      return globalSearchPromise.$promise;
    };

    self.getAmountList = function () {
      return [
        { value: '$5000' },
        { value: '$8000' },
        { value: '$10000' },
        { value: '$15000' },
        { value: '$20000' },
        { value: '$25000' },
        { value: '$30000' },
        { value: $filter('translate')('billing.label.custom') }
      ];
    };

    self.samlConfigPickList = function () {
      return [
        'provider',
        'sp_nameID_format',
        'sp_x509cert',
        'sp_private_key',
        'idp_entity_id',
        'idp_sso_url',
        'idp_sls_url',
        'idp_x509cert',
        'sso_auth_only'
      ];
    };

    self.getSAMLProviderList = function () {
      return samlProvidersList;
    };

    self.getSAMLConfig = function (params) {
      var defaultParams = {
        action: 'saml',
        sub_action: 'config',
        skipNotFound: true
      };
      angular.extend(defaultParams, params);
      return OrganizationsRepository.get(defaultParams);
    };

    self.createSAMLConfig = function (params, data) {
      var defaultParams = {
        action: 'saml',
        sub_action: 'config'
      };
      angular.extend(defaultParams, params);
      return OrganizationsRepository.create(defaultParams, data);
    };
    
    self.updateSAMLConfig = function (params, data) {
      var defaultParams = {
        action: 'saml',
        sub_action: 'config'
      };
      angular.extend(defaultParams, params);
      return OrganizationsRepository.update(defaultParams, data);
    };

    self.deleteSAMLConfig = function (params) {
      var defaultParams = {
        action: 'saml',
        sub_action: 'config',
        skipNotFound: true
      };
      angular.extend(defaultParams, params);
      return OrganizationsRepository.delete(defaultParams);
    };

    self.getGuestLinks = function () {
      return OrganizationsRepository.getGuestLinks();
    };

    self.getWorkWeek = function (value) {
      return _.slice(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'], 0, value);
    };

    self.getCadenceDays = function () {
      return [
        { value: "Monday", text: $filter('translate')('cadenceDays.label.monday') },
        { value: "Tuesday", text: $filter('translate')('cadenceDays.label.tuesday') },
        { value: "Wednesday", text: $filter('translate')('cadenceDays.label.wednesday') },
        { value: "Thursday", text: $filter('translate')('cadenceDays.label.thursday') },
        { value: "Friday", text: $filter('translate')('cadenceDays.label.friday') },
        { value: "Saturday", text: $filter('translate')('cadenceDays.label.saturday') },
        { value: "Sunday", text: $filter('translate')('cadenceDays.label.sunday') }
      ];
    };

    self.getWorkingDaysWithHours = function () {
      return {
        "Monday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Tuesday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Wednesday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Thursday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Friday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Saturday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        },
        "Sunday": {
          "from": '09:00',
          "to": '17:00',
          "anytime": true
        }
      };
    };

    self.getWebhookDateFieldFormats = function () {
      return [
        {
          id: 'iso',
          translationProp: 'isoFormat',
          value: 'yyyy-MM-ddTHH:mm:ssZ'
        },
        {
          id: 'timezone',
          translationProp: 'tzFormat',
          value: 'd/m/Y h:i A T'
        }
      ];
    };

    self.getWorkHoursArray = function () {
      return [
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00'
      ];
    };

    self.getWorkdaysWithHoursForPayload = function (weekdaysWithHours, workingDaysLength) {
      var splicedObj = $filter('objLimitTo')(weekdaysWithHours, workingDaysLength);
      _.forEach(splicedObj, function (value, key) {
        if (value.anytime) {
          delete value.from;
          delete value.to;
        }
      });
      return splicedObj;
    };

    self.updateGuestCadenceDays = function (params, data) {
      return OrganizationsRepository.updateGuestCadenceDays(params, data);
    };

    self.saveGuestData = function (params, data) {
      if (!params) {
        params = {};
      }
      params.action = 'guests';
      return OrganizationsRepository.saveGuestData(params, data);
    };

    self.domainsData = function (params) {
      return OrganizationsRepository.getDomainsResults(params);
    };

    //Create new guest
    self.createGuest = function (data) {
      return OrganizationsRepository.createGuest(data);
    };

    self.getSignupDetails = function () {
      return newSignupDetails;
    };

    self.setSignupDetails = function (data) {
      newSignupDetails = data;
    };

    //Get signup questions
    self.getSignupQuestions = function () {
      return OrganizationsRepository.getgetSignupQuestions();
    };

    //Create new signup questions
    self.createSignupQuestions = function (params, data) {
      return OrganizationsRepository.createSignupQuestions(params, data);
    };

    self.isOrgHasValidAzureCognitiveService = function (organization) {
      if (organization && organization.azure_cognitive_service) {
        try {
          var orgAzureCognitiveService = JSON.parse(organization.azure_cognitive_service);
          return (orgAzureCognitiveService.key && orgAzureCognitiveService.resource_name && orgAzureCognitiveService.region);
        } catch (e) {
          return false;
        }
      }
      return false;
    };

    self.getAzureCognitiveServiceConfig = function (organization) {
      try {
        var orgAzureCognitiveService = JSON.parse(organization.azure_cognitive_service);
        return orgAzureCognitiveService;
      } catch (e) {
        return null;
      }
    };

    self.checkSnippetExistance = function (params, data) {
      params.action = 'check-text-templates-existence';
      return OrganizationsRepository.$create(params, data).$promise;
    };

    self.checkBlueprintExistance = function (params, data) {
      params.action = 'check-checklists-existence';
      return OrganizationsRepository.$create(params, data).$promise;
    };

    self.convertGuestToMember = function (params, data) {
      params.action = 'guests';
      params.sub_action = 'to';
      params.arg1 = 'member';
      return OrganizationsRepository.$create(params, data).$promise;
    };
    
    self.billingSettingOptions = function () {
      return [{
          text: $filter('translate')('support.orgSettings.addOns.enforceMinimum'),
          value: false
        }, {
          text: $filter('translate')('support.orgSettings.addOns.noMinimum'),
          value: true
        }
      ];  
    };

    self.getEmailPreferences = function () {
      var isGuest = $rootScope.userState === USER_STATE.GUEST,
        preferences = isGuest ? _.get($rootScope.identity.guest, 'preferences') : _.get($rootScope.identity, 'preferences'),
        emailPreferences = _.find(preferences, function(pref) { return ((pref.slug === 'smtp_connection') && (pref.value === 'yes')); });

      return emailPreferences ? _.get(emailPreferences, 'metadata.from_address')
        : (isGuest && _.get($rootScope.identity, 'guest.organization.enable_custom_smtp', false) ? _.get($rootScope.identity, 'guest.smtp_connection.from_address', 'no-reply@tallyfy.com') : 'no-reply@tallyfy.com');
    };
  }
})();
/**
 * @ngdoc Directive
 * @name tallyfy.taskMenu
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('taskMenu', taskMenu);

  /*@ngInject*/
  function taskMenu() {
    return {
      restrict: 'A',
      scope: false,
      link: function ($scope, $element, $attrs) {
        var $trigger = $element.find($attrs.taskMenuTrigger);
        var $subMenu = $element.find($attrs.taskMenu);

        /**
         * display changes
         */
        function changeMenuDisplay() {
          var display = $subMenu.css('display') === 'none' ? 'block' : 'none';
          $subMenu.css('display', display);
        }

        $trigger.bind('click', changeMenuDisplay);

        $scope.$on('$destroy', function () {
          $trigger.unbind('click', changeMenuDisplay);
        });
      }
    }
  }

})();
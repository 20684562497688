/**
 * @ngdoc Component
 * @name tallyfy.advancedOotSettings
 * @module tallyfy
 *
 * @description
 * advanced settings for OOT
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('advancedOotSettings', {
      bindings: {
        task: '=',
        activeIndex: '=?',
        appendIndex: '=?',
        onEmitValue: '&?',
        isModalView: '<?'
      },
      templateUrl: 'app/components/advanced-oot-settings/advanced-oot-settings.component.html',
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $filter, TasksService, TASKORDER, OOTAdvancedSettingsService, RunRepository, OrganizationsService) {
          var $ctrl = this,
            processTasks,
            processParams = {},
            unregisteredOOTCreatedHandler;

          // public properties
          $ctrl.pagination = { page: 1, per_page: 10 };
          $ctrl.displayedProcessTasks = [];
          $ctrl.processTaskMeta = {};

          $ctrl.onProcessesLoading = false;
          $ctrl.onProcessTasksLoading = false;

          // angularjs life cycle hook
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public methods
          $ctrl.getProcessValue = getProcessValue;
          $ctrl.onClearSelection = onClearSelection;
          $ctrl.onItemSelected = onItemSelected;
          $ctrl.onItemTaskSelected = onItemTaskSelected;
          $ctrl.onClearSelectedTask = onClearSelectedTask;
          $ctrl.getNextTask = getNextTask;
          $ctrl.onProcessesDropdownOpenHandler = onProcessesDropdownOpenHandler;
          $ctrl.onProcessTasksDropdownOpenHandler = onProcessTasksDropdownOpenHandler;
          $ctrl.getActiveProcesses = getActiveProcesses;
          $ctrl.onProcessLinkToTypeChange = onProcessLinkToTypeChange;

          function onInit() {
            $ctrl.activeIndex = 0;
            getActiveProcesses();
          }

          function onChanges() { }
          function onDestroy() {
            unregisteredOOTCreatedHandler();
          }

          function getProcessValue() {
            return _.get($ctrl.selectedProcess, 'name', $filter('translate')('tasks.label.searchActiveProcesses'));
          }

          function onClearSelection() {
            $ctrl.pagination = { page: 1, per_page: 10 };
            processTasks = $ctrl.processTaskMeta = void 0;
            $ctrl.allProcessTasksLoaded = false;
          }

          function resetProcessParameters() {
            $ctrl.allActiveProcesses = [];
            processParams = {
              page: 1,
              per_page: 10,
              q: '',
              current_page: 1,
              allDataLoaded: false,
              skipNotFound: true
            };
          }

          /**
           * @function
           * @name getActiveProcesses
           * @description Get all active processes
           * @returns {Void}
           */
          function getActiveProcesses(args) {
            var defer = $q.defer();
            if (_.get(args, 'query') !== processParams.q) {
              resetProcessParameters();
            }
            processParams.q = _.get(args, 'query');
            if (processParams.q) {
              var requestParams = {
                org: _.get($rootScope.identity, 'default_organization.id'),
                on: 'process',
                search: processParams.q,
                per_page: processParams.per_page,
                process: processParams.current_page,
                tags: true
              };
              $ctrl.onProcessesLoading = true;
              OrganizationsService.globalSearch(requestParams)
                .then(function (response) {
                  response.process.current_page < response.process.last_page ? processParams.current_page++ : processParams.allDataLoaded = true;
                  $ctrl.allActiveProcesses = _.uniqBy(_.concat($ctrl.allActiveProcesses, response.process.data), 'id');
                  $ctrl.selectedProcess = _.find($ctrl.allActiveProcesses, { id: $ctrl.task.run_id });
                  processParams.total = response.process.total;
                  $ctrl.onProcessesLoading = false;
                  if ($ctrl.task.run_id) {
                    onItemSelected($ctrl.selectedProcess);
                  }
                  defer.resolve({ allItemLoaded: processParams.allDataLoaded });
                }, function () {
                  defer.reject(err);
                  $ctrl.onProcessesLoading = false;
                });
            } else {
              RunRepository.getActiveProcesses(processParams)
                .then(function (res) {
                  res.meta.pagination.current_page < res.meta.pagination.total_pages ? processParams.page++ : processParams.allDataLoaded = true;
                  $ctrl.allActiveProcesses = _.concat($ctrl.allActiveProcesses, res.data);
                  defer.resolve({ allItemLoaded: processParams.allDataLoaded });
                }, function () {
                  defer.reject(err);
                });
            }
            return defer.promise;
          }

          function onItemSelected(item) {
            $ctrl.pagination = { page: 1, per_page: 10 };
            $ctrl.displayedProcessTasks = [];
            $ctrl.allProcessTasksLoaded = false;
            if ($ctrl.selectedProcess) {
              if (item.id === $ctrl.selectedProcess.id) {
                $ctrl.selectedProcess = void 0;
                $ctrl.task.run_id = void 0;
                generateProcessLinkToOptions();
                return;
              }
            }
            $ctrl.selectedProcess = item;
            if (!$ctrl.isModalView) {
              $ctrl.onEmitValue({ value: $ctrl.selectedProcess, type: 'process' });
            }
            $ctrl.task.run_id = _.get($ctrl.selectedProcess, 'id', void 0);
            processTasks = [];
            getProcessTask(item);
            generateProcessLinkToOptions();
          }

          function onItemTaskSelected(item) {
            $ctrl.selectedTask = item;
            $ctrl.activeIndex = getActiveIndex($ctrl.selectedTask);
            $ctrl.task.position = $ctrl.activeIndex;
            if (!$ctrl.isModalView) {
              $ctrl.onEmitValue({ value: $ctrl.activeIndex, type: 'position' });
            }
          }

          function getActiveIndex(task) {
            var activeTask = $filter('activeTask')(processTasks, {
              orderBy: 'position'
            });
            if (task.value === 'beginning') {
              return 0;
            } else if (task.value === 'last') {
              return $ctrl.processTaskMeta.pagination.total + 1;
            } else {
              return _.find(activeTask, { id: task.value.split('_')[0] }).position + 1;
            }
          }

          function onClearSelectedTask() {
            $ctrl.selectedTask = void 0;
            $ctrl.isSelectBoxTasksOpen = false;
          }

          /**
           * @ngdoc method
           * @name getProcessTask
           * @param {*} process 
           * 
           * @description
           * get process tasks
           */
          function getProcessTask(process) {
            var defer = $q.defer();
            if ($ctrl.allProcessTasksLoaded) {
              defer.resolve({ allItemLoaded: true });
              return defer.promise;
            }
            $ctrl.onProcessTasksLoading = true;
            TasksService.getUserTasks({
              action: process ? "runs" : void 0,
              action_id: process ? process.id : void 0,
              page: $ctrl.pagination.page,
              per_page: $ctrl.pagination.per_page,
              sort: TASKORDER.POSITION
            }).then(function (res) {
              processTasks = _.concat(processTasks || [], res.data);
              processTasks = $filter('activeTask')(processTasks, {
                orderBy: 'position'
              });
              generateDisplayedProcessTask();
              $ctrl.processTaskMeta = res.meta;
              res.meta.pagination.current_page != res.meta.pagination.total_pages ? $ctrl.pagination.page++ : $ctrl.allProcessTasksLoaded = true;
              $ctrl.onProcessTasksLoading = false;
              defer.resolve({ allItemLoaded: $ctrl.allProcessTasksLoaded });
            }, function () {
              $ctrl.onProcessTasksLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function getNextTask() {
            return getProcessTask($ctrl.selectedProcess);
          }

          function generateDisplayedProcessTask() {
            $ctrl.displayedProcessTasks = OOTAdvancedSettingsService.generateTaskValueOptions(processTasks);
            $ctrl.selectedTask = _.head($ctrl.displayedProcessTasks);
          }

          function onProcessesDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxTasksOpen = false;
            }
          }

          function onProcessTasksDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxOpen = false;
            }
          }

          function generateProcessLinkToOptions () {
            $ctrl.processLinkToOptions = OOTAdvancedSettingsService.defaultTaskPositions();
            $ctrl.processLinkToOptions.push({
              value: 'after',
              title: $filter('translate')('global.task.menuPosition.after')
            });
            $ctrl.processLinkToType = _.head($ctrl.processLinkToOptions).value;
            $ctrl.onProcessLinkToTypeChange($ctrl.processLinkToType);
          }

          function onProcessLinkToTypeChange (option) {
            if (option !== 'after') {
              $ctrl.activeIndex = getActiveIndex({ value: option });
              $ctrl.task.position = $ctrl.activeIndex;
              if (!$ctrl.isModalView) {
                $ctrl.onEmitValue({ value: $ctrl.activeIndex, type: 'position' });
              }
            }
          }

          unregisteredOOTCreatedHandler = $rootScope.$on('STANDALONE_TASK:CREATED', function () {
            if (!$ctrl.task.run_id) {
              $ctrl.selectedProcess = void 0;
            }
            $ctrl.selectedTask = OOTAdvancedSettingsService.defaultTaskPositions()[0];
            $ctrl.task.position = 1;
            $ctrl.allProcessTasksLoaded = false;
            processTasks = [];
            $ctrl.pagination = { page: 1, per_page: 10 };
            getProcessTask($ctrl.selectedProcess);
          });
        }
    });
})();
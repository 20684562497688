(function () {
  'use strict';
  angular.module('tallyfy')
    .component('processListPreview', {
      templateUrl: 'app/modules/dashboard/content-card-preview/process-list-preview/process-list-preview.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, RunsService, DateUtils) {
          var $ctrl = this;

          $ctrl.activeTab = 'process';

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.getProgressBarClass = getProgressBarClass;
          $ctrl.viewAllProcesses = viewAllProcesses;
          $ctrl.getStatus = getStatus;

          function onInit() {
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id');
            getMyProcess();
          }
          function onChanges() { }
          function onDestroy() { }

          function getMyProcess() {
            $ctrl.processesLoading = true;
            RunsService.get({
              filter_type: 'any',
              owners: $rootScope.identity.id,
              page: 1,
              per_page: 8,
              sort: '-created_at',
              status: 'active,problem',
              with: 'tasks_meta'
            }).then(function (res) {
              _.forEach(res.data, function (process) {
                process.due_date = DateUtils.toLocal(process.due_date).format();
              });
              $ctrl.myProcesses = res.data;
              $ctrl.processesLoading = false;
            }, function () {
              $ctrl.processesLoading = false;
            });
          }

          function getProgressBarClass(run) {
            return RunsService.getBarClass(run);
          }

          function viewAllProcesses() {
            $state.go('run.dashboard');
          }

          /**
           * @ngdoc method
           * @name getStatus 
           * @public
           * @description get status
           * @param {Object} process 
           */
          function getStatus(process) {
            return RunsService.getViewHelperRunStatus(process);
          }
        }
    });
})();

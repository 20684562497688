/**
 * @ngdoc Component
 * @name tallyfy.component.animatedButton
 * @module tallyfy
 *
 * @description
 * Animated button
 *
 * @author Kodeplusdev ( gmail::dkhoa81@gmail.com, skype :: dkhoa81 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('animatedButton', {
      bindings: {
        'text': '<',
        'onSaving': '<',
        'class': '<',
        'isDisabled': '<',
        'savedText': '<',
        'isSaved': '<',
        'onClickHandler': '&',
        'onActive': '<',
        'isLocked': '<?',
        'buttonId': '<?',
        'buttonType': '<?',
        'isIconEnabled': '<?',
        'isRulerEnabled': '<?',
        'triggerWiggle': '=',
        'customIconClass': '<',
        'progressButton': '<',
        'percent': '<',
        'isHover': '=?',
        'hoverActive': '<?',
        'args': '<'
      },
      templateUrl: 'app/components/animated-button/animated-button.html',
      controller:
        /*@ngInject*/
        function (_, $scope, Helper) {
          var $ctrl = this, current = false;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.setCurrent = setCurrent;
          $ctrl.isCurrent = isCurrent;
          $ctrl.isButtonDisabled = isButtonDisabled;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            current = $ctrl.onSaving;
            if (typeof $ctrl.isSaved === 'undefined') {
              $ctrl.isSaved = true;
            }
            if (!$ctrl.buttonId) {
              $ctrl.buttonId = Helper.getId();
            }
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            var percent = _.get(changes.percent, 'currentValue');
            if (typeof percent !== 'undefined') {
              changeProgressBackground();
              $ctrl.isHover = false;
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
          }

          /**
           * @ngdoc method
           * @name setCurrent
           * @description
           * Set current button for animation
           */
          function setCurrent() {
            current = true;
          }

          /**
           * @ngdoc function
           * @name isCurrent
           * @description
           * Check if this button is current button
           * @returns {boolean}
           */
          function isCurrent() {
            if (!current) {
              $ctrl.onSaving = false;
            }
            return current;
          }

          /**
           * @ngdoc function
           * @name isButtonDisabled
           * @description
           * Check if this button is disabled
           * @returns {boolean}
           */
          function isButtonDisabled() {
            return $ctrl.onActive ? $ctrl.isDisabled : ($ctrl.isDisabled || $ctrl.onSaving);
          }

          /**
           * @ngdoc method
           * @name changeProgressBackground
           * 
           * @description
           * set percent background width
           */
          function changeProgressBackground() {
            $scope.$applyAsync(function () {
              var button = angular.element(document.getElementById($ctrl.buttonId));
              var percentBackground = button.find('.progress-button-percent');
              percentBackground.css('width', $ctrl.percent + '%');
            })
          }
          //end of controller
        }
    });
})();

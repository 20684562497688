/**
 * @ngdoc Component
 * @name upgradeModal
 * @module tallyfy
 * @description
 * Component for upgrade modal
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('upgradeModal', {
      templateUrl: 'app/components/upgradeModal/upgradeModal.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
      /*@ngInject*/
        function upgradeModalComponentController(_) {
          var $ctrl = this;

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          $ctrl.accepts = accepts;
          $ctrl.contactSalesClicked = false;
          $ctrl.showInlineWidget = showInlineWidget;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.updradeModal = angular.copy($ctrl.resolve.updradeData);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
          */
          function cancel() {
            $ctrl.dismiss();
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Close modal
          */
          function accepts() {
            $ctrl.close();
          }

          /**
           * @ngdoc method
           * @name showInlineWidget
           * @description Show Calendly widget
           */
          function showInlineWidget() {
            $ctrl.contactSalesClicked = true;
          }
        }
    });
})();

/**
 * @ngdoc Directive
 * @name tallyfy.saveOnClick
 * @description save form field on click
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('saveOnClick', saveOnClick);
    /*@ngInject*/
    function saveOnClick(_, $rootScope, $timeout) {
      return {
        restrict: 'A',
        scope : {
          fieldData : '=',
          formData : '=',
          isPreviewModalOpen: "<",
          triggerButton: '&'
        },
        link: function ($scope, element, attrs) {
          var unregisterUpdateFormListener;
          var triggerButton = $scope.triggerButton;
          $scope.saveForm = function () {
            if (!$scope.fieldData.isExpanded) {
              return;
            }
            $timeout(function () {
              if(attrs.id == $scope.fieldData.id + "-formField") {
                $scope.$eval(triggerButton);
              }
            }, 10);
            element.find("#fieldInput_" + $scope.fieldData.id).blur();
            if (_.get($scope.fieldData, 'options.length', 0) > 0) {
              _.forEach($scope.fieldData.options, function (val) {
                element.find("#option_" + val.id).blur();
              });
            }
          }

          element.bind('click keypress', function (event) {
            if ((event.which == 13 || event.which == 1) && $scope.formData.$dirty && !$scope.isPreviewModalOpen && event.target.tagName != 'TEXTAREA') {
              $scope.saveForm();
            }
          });

          unregisterUpdateFormListener = $rootScope.$on('SAVE:FORM_FIELD', function (event) {
              $scope.saveForm();
          });
          
          $scope.$on('$destroy', function () {
            element.unbind('click keypress');
            unregisterUpdateFormListener();
          });
        }
      };
    }
})();


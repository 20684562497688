/**
 * @ngdoc Config
 * @name tallyfy.integration.config.ZapierStateConfig
 * @module tallyfy.integration
 *
 * @description
 * set route integration Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .config(ZapierStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ZapierStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * ZapierStateConfig construct 
   */
  function ZapierStateConfig($stateProvider) {
   
  }
})();
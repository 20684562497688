/**
 * @ngdoc Component
 * @name templateIcon
 * @module tallyfy
 * @description
 * Component for template icon
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 **/
(function () {
    'use strict';
    angular
      .module('tallyfy')
      .component('templateIcon', {
        templateUrl: 'app/components/template-icon/template-icon.component.html',
        bindings: {
          width: '<?',
          type: '<',
          status: '<',
          showStatus: '<?'
        },
        controller:
        /*@ngInject*/
          function (_, BLUEPRINT_TYPE, StepService) {
            var $ctrl = this,
            templateIcon = 'fa-ballot-check',
            documentIcon = 'fa-file-alt',
            formIcon = 'fa-file-edit';

            /*
             * Component's lifeCycle hooks
             */
            $ctrl.$onInit = initialization;
            $ctrl.$onDestroy = onDestroy;
            $ctrl.$onChanges = onChanges;

            /**
             * Expose bindable methods
             * these methods are accessible in view
             */

            /**
             * @ngdoc method
             * @name initialization
             * @description
             * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
             */
            function initialization() {
                $ctrl.statusTypes = StepService.getProgressStatus();
                initIcon();
            }

            /**
             * @ngdoc method
             * @name onChanges
             * @description
             * A component's lifeCycle hook which is called when bindings are updated.
             */
            function onChanges(changes) {
              if (changes.status.currentValue !== changes.status.previousValue) {
                initStatus(changes.status.currentValue);
              }
            }

            /**
             * @ngdoc method
             * @name onDestroy
             * @description
             * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
             * Usefull to release external resources, watches and event handlers.
             */
            function onDestroy() { }

            /**
             * @ngdoc method
             * @name initIcon
             * @description init the icon component
             * @param {*} params
             */
            function initIcon() {
              if ($ctrl.type) {
                $ctrl.iconClass = $ctrl.type === BLUEPRINT_TYPE.PROCEDURE ? templateIcon : $ctrl.type === BLUEPRINT_TYPE.DOCUMENT ? documentIcon : formIcon;
              }
              if ($ctrl.status) {
                initStatus($ctrl.status);
              }
            }

            /**
             * @ngdoc method
             * @name initStatus
             * @description init the icon's status
             */
            function initStatus(status) {
              var statusObj = _.find($ctrl.statusTypes, { value: status });
              if (statusObj) {
                $ctrl.badgeColor = 'bg-' + statusObj.statusClass;
              }
            }
          }
      });
  })();

/**
 * @ngdoc overview
 * @name tallyfy.appInitializer
 *
 * @module tallyfy
 *
 * @description
 * This is very first module which is mounted at very first
 * This module get the configuration from server before application get bootstrap
 * Store configurations in constant service to be used in application
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.envConfig', []);
  angular
    .module('tallyfy.appInitializer', ['tallyfy.envConfig'])
    .run(run);
  /**
   * @inject
   * inject dependencies
   */
  run.$inject = ['$http', 'ENV_CONFIG'];
  function run($http, ENV_CONFIG) {

    ENV_CONFIG = ENV_CONFIG || {};
    if (!ENV_CONFIG.hasOwnProperty('API_HOST') || !ENV_CONFIG.API_HOST) {
      throw new Error('API_HOST is not defined in .env');
    }
    $http.get(ENV_CONFIG.API_HOST + '/utils/config').then(function (data) {
      var constants = data.data;
      constants.API_HOST = ENV_CONFIG.API_HOST;
      angular.module('tallyfy.config').constant('CONFIG', data.data);
      /**
       * Bootstrap application
       * @name tallyfy
       * @namespace tallyfy
       */
      angular.bootstrap(document, ['tallyfy']);
      
    });
  }
  /**
   * Bootstrap appInitializer
   * @name tallyfy
   * @namespace tallyfy
   */
  angular.element(document).ready(function () {
    //angular.bootstrap(document, ['tallyfy']);
    angular.bootstrap(document.getElementById('appConfig'), ['tallyfy.appInitializer'], {
      strictDi: true
    });
  });
})();
(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.steps.service.TimeFieldService
   * @module tallyfy.steps
   *
   * @description
   * A service for steps
   * 
  * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
   * 
   * */
  angular
    .module('tallyfy.steps')
    .service('TimeFieldService', TimeFieldService);
    /*@ngInject*/
    function TimeFieldService(_, moment, $filter) {
      /**
       * store TimeFieldService context in self
       * JavaScript has block level context So it can be changed in nested 
       * function
       */
      var self = this;

      /**
       * @ngdoc
       * @name getUnitOptions
       * @description It will return time unit options
       * @returns {*} Array
       */
      self.getUnitOptions = function () {
        return [{
          title: 'minutes',
          text: $filter('translate')('steps.deadlines.optionTimeUnits.minutes'),
          multiplier: 1,
          shortHand: 'm'
        }, {
          title: 'hours',
          text: $filter('translate')('steps.deadlines.optionTimeUnits.hours'),
          multiplier: 60,
          shortHand: 'h'
        }, {
          title: 'days',
          text: $filter('translate')('steps.deadlines.optionTimeUnits.days'),
          multiplier: 60 * 24,
          shortHand: 'd'
        }, {
          title: 'weeks',
          text: $filter('translate')('steps.deadlines.optionTimeUnits.weeks'),
          multiplier: 60 * 24 * 7,
          shortHand: 'w'
        }, {
          title: 'months',
          text: $filter('translate')('steps.deadlines.optionTimeUnits.months'),
          multiplier: 60 * 24 * 30,
          shortHand: 'M'
        }];
      };

      /**
       * @ngdoc
       * @name getUnitOptionsValue
       * @description It will return value
       * @returns {*} Array
       */
      self.getUnitOptionsValue = function (unit) {
        return _.find(self.getUnitOptions() , { title: unit });
      };

      /**
       * @ngdoc
       * @name checkSelctedDateDiff
       * @description Check start and end value is valid return true or not return false
       * @returns {*} Array
       */
      self.checkSelctedDateDiff = function (start, end) {
        var startValue = parseInt(_.get(self.getUnitOptionsValue(start.unit), 'multiplier'))*start.value,
            finishValue = parseInt(_.get(self.getUnitOptionsValue(end.unit), 'multiplier'))*end.value;

        if (startValue < finishValue || _.get(start, 'unit') === _.get(end, 'unit') && _.get(start, 'value', 1) < _.get(end, 'value', 1)) {
          return true;
        }
        return false;
      };
      
      self.getSelctedDateDiff = function (deadline) {
        var data = {}, deadlineHours = Math.round(Math.abs(moment.duration(moment(deadline).diff(moment())).asHours()));
        if (deadlineHours < 1) {
          angular.extend(data, {'value': deadlineHours, 'unit': 'minutes'});
        } else if (deadlineHours < 24) {
          angular.extend(data, {'value': deadlineHours, 'unit': 'hours'});
        } else {
          var weekCount = Math.round(Math.abs(moment.duration(moment(deadline).diff(moment())).asWeeks()));
          if (weekCount < 4) {
            angular.extend(data, {'value': Math.round(deadlineHours / 24), 'unit': 'days'});
          } else {
            angular.extend(data, {'value': weekCount, 'unit': 'months'});
          }
        }
        return data;
      };
    }
})(); 

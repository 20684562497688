/**
 * @ngdoc Directive
 * @name tallyfy.scrollIntoView
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollIntoView', scrollIntoView);

  /*@ngInject*/
  function scrollIntoView($timeout) {
    return {
      restrict: 'AE',
      scope: {
        allowToScroll: '=',
        collapseContainer: '='
      },
      link: function ($scope, $element, $attr) {
        var self = this;

        var container = document.querySelector($attr.scrollIntoView);
        var parentElement = document.querySelector($attr.parentElement);    
        var element = $element[0];

        if (!container || !element) return;

        function getComputedStyle(el, prop) {
          return (el.currentStyle) ? el.currentStyle[prop] : (window.getComputedStyle && window.getComputedStyle(el, null)) ? window.getComputedStyle(el, null).getPropertyValue(prop) : el.style[prop];
        }

        function getMinHeight(element) {
          function getNumericValue(strValue) {
            return parseInt(strValue.substr(0, strValue.length - 2));
          }
          var elHeight = getNumericValue(getComputedStyle(element, 'height'));
          var bodyPadding = getNumericValue(getComputedStyle(container, 'padding-bottom'));

          return elHeight + bodyPadding;
        }
        
        $element.on('click', function (e) {
          var topOffset = $attr.topOffset || 0;

          self.timer = $timeout(function () {
            if ($scope.allowToScroll !== undefined && !$scope.allowToScroll) {
              if(!$scope.collapseContainer) parentElement.style.minHeight = '';
              return;
            }

            if(parentElement.style.minHeight) parentElement.style.minHeight = '';
            $scope.allowToScroll = false;
            element.scrollIntoView(true);

            container.scrollTop = container.scrollTop - topOffset;
            parentElement.style.minHeight = getMinHeight(parentElement) + 'px';   
          }, 100);
        });

        $scope.$on('$destroy', function (e) {
          if (!self.timer) return;
          $timeout.cancel(self.timer);          
          parentElement.style.minHeight = '';
        });

      }
    };
  }

})();
(function () {
  'use strict';
  angular
    .module('tallyfy.public')
    .factory('PublicOrganizationRepository', publicOrganizationRepository);

  /*@ngInject*/
  function publicOrganizationRepository(BaseRepository) {
    var repository = BaseRepository('/public/organizations/:org_id/:action/:actionId/:subAction/:subActionId/:metadata', {
      action: '@action',
      actionId: '@actionId',
      subAction: '@subAction'
    }, {
      $all: { method: 'GET' },
      $get: { method: 'GET' },
      $update: { method: 'PUT' },
      $create: { method: 'POST' }
    });
    return repository;
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('TextTemplateTableService', TextTemplateTableService);

  function TextTemplateTableService($filter) {
    var self = this;
    self.getTextTemplateTableOptions = function () {
      return {
        columns: [{
          field: "title",
          exportKeyMap: {
            key: "title",
            label: $filter('translate')('textTemplate.table.header.title')
          },
          template: kendo.template($('#textTemplateTitleColumn').html()),
          headerTemplate: kendo.template($('#textTemplateTitleHeaderColumn').html()),
          width: 420
        }, {
          field: "created_at",
          exportKeyMap: {
            key: "created_at",
            label: $filter('translate')('list.label.createdAt')
          },
          template: kendo.template($('#textTemplateCreatedAtColumn').html()),
          headerTemplate: kendo.template($('#textTemplateCreatedAtHeaderColumn').html()),
          width: 220
        }, {
          field: "updated_at",
          exportKeyMap: {
            key: "updated_at",
            label: $filter('translate')('textTemplate.table.header.updatedAt')
          },
          template: kendo.template($('#textTemplateUpdatedAtColumn').html()),
          headerTemplate: kendo.template($('#textTemplateUpdatedAtHeaderColumn').html()),
          width: 220
        }, {
          field: "action",
          template: kendo.template($('#textTemplateActionColumn').html()),
          headerTemplate: kendo.template($('#textTemplateActionHeaderColumn').html()),
          width: 280
        }],
        dataSource: new kendo.data.DataSource(),
        noRecords: {
          template: kendo.template($('#textTemplateTableNoData').html())
        },
        resizable: true
      };
    };
    return self;
  }
})();
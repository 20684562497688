/**
 * @ngdoc Component
 * @name tallyfy.account.component.resetFinish
 * @module tallyfy.account
 *
 * @description
 * A component to reset password
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .component('resetFinish', {
      templateUrl: 'app/modules/account/reset/finish/reset-finish.html',
      controller:
      /*@ngInject*/
      function ($stateParams, Auth, Growl, $filter, _, blockUI, CONFIG, $state) {
        var $ctrl = this,
          growl = new Growl(),
          _blockUI = blockUI.instances.get('resetfinish');

        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = onInit;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * public properties
         */
        $ctrl.finishReset = finishReset;

        /**
         * public properties
         */
        $ctrl.resetAccount = {};
        $ctrl.resetAccountForm = {};

        /**
        * @function $onInit
        * A Callback function which called when component has mounted
        * this is best place to initialze things
        */
        function onInit() {
          $ctrl.tallyfyURL = CONFIG.MARKETING_URL;
          if (_.isEmpty($stateParams.key)) {
            showGrowlMessage($filter('translate')('reset.finish.messages.keymissing'));
          } else {
            checkResetCode();
          }
        }

        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() {}

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        /**
         * @ngdoc method
         * @name checkResetCode
         * @description Check reset password code
         */
        function checkResetCode() {
          _blockUI.start();
          Auth.checkResetCode({
            sub_action: $stateParams.key
          }).then(function () {
            _blockUI.stop();
          }, function () {
            _blockUI.stop();
            growl.error($filter('translate')('reset.finish.messages.linkExpired'), {
              referenceId: 'resetFinishController',
              disableIcons: true,
              disableCloseButton: true,
              variables: {
                route: "authHandler",
                routeName: $filter('translate')('reset.finish.resetPassword')
              }
            });
          });
        }

        /**
         * @ngdoc method
         * @name finishReset
         * @description To reset password
         * @returns {void}
         */
        function finishReset() {
          if ($ctrl.resetAccountForm.$invalid) {
            return;
          }

          if (_.isEmpty($stateParams.key)) {
            showGrowlMessage($filter('translate')('reset.finish.messages.keymissing'));
            return;
          }

          _blockUI.start();
          Auth.resetPasswordFinish({
            code: $stateParams.key,
            password: $ctrl.resetAccount.password,
            password_confirmation: $ctrl.resetAccount.confirmPassword
          }).then(function (response) {
            _blockUI.stop();
            if (!response.error) {
              $state.go('authHandler');
              return;
            }
            showGrowlMessage($filter('translate')('reset.finish.messages.' + response.code));
          }, function (error) {
            _blockUI.stop();
            if (!_.isUndefined(error.data) && error.data.code === 'VALIDATION_FAILED')
              return;

            if (!_.isUndefined(error.data)) {
              showGrowlMessage($filter('translate')('reset.finish.messages.' + error.data.code));
              return;
            }
            showGrowlMessage($filter('translate')('reset.finish.messages.' + error.code));
          });
        }
        
        /*
         * @function
         * @name showGrowlMessage
         * @param {*} message
         * @description To show growl error messages
         */
        function showGrowlMessage(message) {
          growl.error(message, {
            referenceId: 'resetFinishController',
            disableIcons: true,
            disableCloseButton: true
          });
        }
      }
    });
})();
/**
 * @ngdoc Transformer
 * @name tallyfy.factory.TaskTransformer
 * @module tallyfy
 *
 * @description
 * A transformer to update the tasks
 * @param data {Object} Task
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('TaskTransformer',
      /*@ngInject*/

      function TaskTransformer(_, DateUtils) {
        var tasks = [];
        /**
         * Constructor, with class name
         */
        function TaskTransformer(data) {
          tasks = [];
          if (!_.isArray(data)) {
            if (_.isEqual(data.taskdata, [])) {
              data.taskdata = {};
            }
            return convertDate(data);
          }
          angular.forEach(data, convertDateString);
          return tasks;
        }

        function convertDateString(task) {
          if (_.isEqual(task.taskdata, [])) {
            task.taskdata = {};
          }
          tasks.push(convertDate(task));
        }

        function convertDate(task) {
          var newTask = angular.copy(task);
          if (!_.isUndefined(task.deadline) && typeof task.deadline === 'string') {
            newTask.deadline = DateUtils.toLocal(task.deadline).format();
            newTask.deadline_unformatted = DateUtils.toLocal(task.deadline);
          }
          if (!_.isUndefined(task.started_at) && typeof task.started_at === 'string') {
            newTask.started_at = DateUtils.toLocal(task.started_at).format();
          }
          if (task.completed_at) {
            newTask.completed_at = DateUtils.toLocal(task.completed_at);
          }
          newTask.created_at = DateUtils.toLocal(task.created_at).format();
          newTask.last_updated = DateUtils.toLocal(task.last_updated).format();

          if (_.get(task, 'run')) {
            newTask.run.data.started_at = DateUtils.toLocal(task.run.data.started_at).format();
            newTask.run.data.started_at_as_str = DateUtils.toLocal(task.run.data.started_at_as_str).format();
          }

          // Update the conditions time
          if (!_.isUndefined(task.condition) && !_.isNull(task.condition)) {
            newTask.condition.created_at = DateUtils.toLocal(task.condition.created_at).format();
            newTask.condition.updated_at = DateUtils.toLocal(task.condition.updated_at).format();
          }
          return newTask;
        }

        return TaskTransformer;
      });

})();

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorBlueprint', {
      templateUrl: 'app/components/documentPaneEditor/editorBlueprint/editor-blueprint.component.html',
      bindings: {
        blueprintId: '<',
        blueprintTitle: '<',
        blueprintIcon: '<',
        editorScope: '<?',
        hideDeleteButton: '<?',
        isStaticElement: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $timeout, $element, Helper, $state, $window, ProcessService, UtilsService, store, $q) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.removeField = removeField;
          $ctrl.goToBlueprint = goToBlueprint;

          function onInit() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            if ($ctrl.editorScope) {
              if (!$ctrl.editorScope.fieldMode) {
                $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
                if (!$ctrl.isStaticElement) {
                  $timeout(function () {
                    $element.attr('draggable', 'true');
                    $element[0].addEventListener('dragstart', dragCallback);
                  }, 350);
                }
              }
            }
            $q.all([
              store.getGroups(),
              ProcessService.getPermission({
                id: $ctrl.blueprintId,
                skipNotFound: true
              })
            ]).then(function (res) {
              $ctrl.blueprint = {
                id: $ctrl.blueprintId,
                title: $ctrl.blueprintTitle,
                icon: $ctrl.blueprintIcon,
                permission: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, null, $rootScope.identity.id, _.get(res[1], 'data.checklist_read', []), _.get(res[1], 'data.checklist_read_group', []), res[0])
              }
              if (!$ctrl.isStandalone) {
                $rootScope.$emit('DOCUMENT_BLUEPRINT_ATTACHED', { id: $ctrl.blueprintId, title: $ctrl.blueprintTitle, icon: $ctrl.blueprintIcon, permission: $ctrl.blueprint.permission });
              }
            })
          }

          function onChanges(changes) {
            if (changes.editorScope && changes.editorScope.currentValue) {
              $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
            }
          }

          function onDestroy() {
            $element[0].removeEventListener('dragstart', dragCallback);
          }

          function dragCallback(e) {
            var isInEditor = $(e.target).closest('.fr-element').length;
            if (isInEditor) {
              $element.attr('id', Helper.guid());
            }
            e.dataTransfer.setData('element', 'editor-blueprint');
            e.dataTransfer.setData('args', JSON.stringify({
              blueprintId: $ctrl.blueprintId,
              blueprintTitle: $ctrl.blueprintTitle,
              blueprintIcon: $ctrl.blueprintIcon,
              permission: $ctrl.permission || $ctrl.blueprint.permission,
              sourceId: isInEditor ? $element.attr('id') : void 0
            }));
          }

          function removeField(e) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            var fieldElement = angular.element(e.target).closest('editor-blueprint');
            if ($ctrl.editorScope && $ctrl.editorScope.froalaOptions) {
              var editor = $ctrl.editorScope.froalaOptions.froalaEditor;
              editor.selection.setBefore(fieldElement[0]);
              fieldElement[0].parentNode.removeChild(fieldElement[0]);
              editor.selection.restore();
            }
            $rootScope.$emit('BLUEPRINT_FIELD_EDITOR:REMOVED', { blueprintId: $ctrl.blueprintId });
            $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
          }

          //Open blueprint in new tab
          function goToBlueprint () {
            if (!$ctrl.blueprintId) {
              return;
            }

            var url = $state.href('process.edit', {
              slug: $ctrl.blueprintId,
              view: 'read'
            });

            $window.open(url, '_blank');
          }
        }
    })
})();

/**
 * @ngdoc Service
 * @name tallyfy.organizations.SupportService
 *
 * @module tallyfy.support
 *
 * @description
 * SupportService
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 *
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .service('SupportService', SupportService);

  SupportService.$inject = ['OrganizationsRepository', 'SupportRepository', 'RecurlyRepository', '_'];

  function SupportService(OrganizationsRepository, SupportRepository, RecurlyRepository, _) {
    /**
     * store SupportService context in self
     * JavaScript has block level context So it can be changed in nested
     * function
     */
    var self = this;

    self.getOrganization = function (params) {
      params = params || {};
      return params.org ? OrganizationsRepository.getOrganization(params) : OrganizationsRepository.myOrganizations(params).$promise;
    };

    self.allUsers = function (params) {
      var inputs = getRequestParams(params);
      var requestParams = {
        per_page: 10,
        sort: params.sort || 'first_name'
      };
      angular.extend(requestParams, inputs);
      return SupportRepository.get(requestParams);
    };

    self.switchUser = function (params) {
      var requestParams = {
        type: 'organizations',
        sub_action: 'switch_users'
      };
      angular.extend(requestParams, params);
      return SupportRepository.get(requestParams);
    };

    self.archiveOrg = function (params) {
      var requestParams = {
        type: 'organizations'
      };
      angular.extend(requestParams, params);
      return SupportRepository.delete(requestParams);
    };

    self.liveSupport = function (org, params) {
      var requestParams = {
        org: org
      };
      return SupportRepository.liveSupport(requestParams, params).$promise;
    };
    
    self.syncSubscription = function (org, params) {
      var requestParams = {
        org: org
      };
      return SupportRepository.syncSubscription(requestParams, params).$promise;
    };

    function getRequestParams(params) {
      var _params;
      if (!params) return {};
      _params = angular.copy(params);

      if (_.isObject(_params)) {
        for (var key in _params) {
          if (_params.hasOwnProperty(key) && typeof _params[key] === 'function') {
            delete _params[key];

          }
          if (key === 'current_page') {
            _params.page = _params.current_page;
            delete _params[key];
          }
        }
        return _params;
      } else {
        throw new Error('Support Service: Params are not valid');
      }
    }

    self.getUser = function (params) {
      var defaultParams = {
        "type": "users"
      };
      angular.extend(defaultParams, params);
      return SupportRepository.get(defaultParams);
    };

    self.resetPassword = function (params) {
      var defaultParams = {
        "type": "users",
        "flag": "password-reset"
      };
      angular.extend(defaultParams, params);
      return SupportRepository.get(defaultParams);
    };

    self.changePlan = function (params, data) {
      var requestParams = {
        action: 'subscriptions',
        org: params.org
      };
      return RecurlyRepository.subscriptions(requestParams, data).$promise;
    };

    self.addBillingInfo = function (org_id, token) {
      var params = {
        'org': org_id,
        'action': 'billing-info'
      };
      var data = { token_id: token };
      return RecurlyRepository.billCreate(params, data).$promise;
    };

    self.extendSubscription = function (org_id, data) {
      var params = {
        org: org_id,
        action: 'postpone-subscription',
        action_id: data.uuid
      };
      return RecurlyRepository.update(params, data);
    };

    self.addUserToOrganization = function (params, data) {
      var defaultParams = {
        type: 'organizations',
        action: params.org_id,
        sub_action: 'users'
      };
      angular.extend(defaultParams, params);
      return SupportRepository.create(defaultParams, data);
    };

    self.removeUserFromOrganization = function (params) {
      var defaultParams = {
        type: 'organizations',
        action: params.org_id,
        sub_action: 'users',
        sub_action_id: params.user_id
      };
      angular.extend(defaultParams, params);
      return SupportRepository.delete(defaultParams);
    };

    self.cancelSubscription = function (org_id, uuid) {
      var params = {
        org: org_id,
        action: 'subscriptions',
        action_id: uuid
      };
      return RecurlyRepository.delete(params);
    };

    self.unsuspend = function (user) {
      var params = {
        type: 'suspended-users',
        sub_action_id: user.id
      };
      return SupportRepository.delete(params);
    };

    self.updateBillingInfo = function (org_id, data) {
      var params = {
        org: org_id,
        action: 'billing-account'
      };
      return RecurlyRepository.update(params, data);
    };

    self.updateOrgInfo = function (org_id, data) {
      var params = {
        type: 'organizations',
        action: org_id
      };
      return SupportRepository.update(params, data);
    };

    self.getClientIntegrations = function () {
      var params = {
        type: 'oauth',
        action: 'clients'
      };
      return SupportRepository.get(params);
    };

    self.createClientIntegrations = function (data) {
      var params = {
        type: 'oauth',
        action: 'clients'
      };
      return SupportRepository.create(params, data);
    };

    self.updateClientIntegrations = function (params, data) {
      var paramsObj = {
        type: 'oauth',
        action: 'clients',
        sub_action: params.id
      };
      return SupportRepository.update(paramsObj, data);
    };

    self.deleteClientIntegration = function (params) {
      var paramsObj = {
        type: 'oauth',
        action: 'clients',
        skipNotFound: true
      };
      angular.extend(paramsObj, params);
      return SupportRepository.delete(paramsObj);
    };

    self.getOrgUserTypes = function () {
      return [
        { name: "support.tabs.users", value: "user", class: "active" },
        { name: "support.tabs.guests", value: "guest", class: "" },
        { name: "support.tabs.domains", value: "domains", class: "" }
      ];
    };

    self.toggleSAMLSetting = function (params, data) {
      var defaultParams = {
        action: 'saml',
        sub_action: data.samlEnabled ? 'enable' : 'disable',
        skipNotFound: true
      };
      angular.extend(defaultParams, params);
      return data.samlEnabled ? OrganizationsRepository.update(defaultParams) : OrganizationsRepository.delete(defaultParams);
    };

    self.getChecklists = function (params) {
      if (!params) {
        params = {};
      }
      params.type = 'checklists';
      return SupportRepository.get(params);
    };

    self.setSupportEmailCadence = function (org_id, cadenceDays) {
      return SupportRepository.$update({
        type: 'organizations',
        action: org_id,
        sub_action: 'cadence_days'
      }, { "cadence_days": cadenceDays }).$promise;
    };

    self.getDomainSupport = function (data) {
      var requestParams = {
        org: data.org
      };
      return SupportRepository.getDomainSupport(requestParams).$promise;
    };

    self.createDomainSupport = function (org, data) {
      var requestParams = {
        org: org
      };
      return SupportRepository.createDomainSupport(requestParams, data).$promise;
    };

    self.updateDomainSupport = function (org, data) {
      var requestParams = {
        org: org,
        id: data.id
      };
      return SupportRepository.updateDomainSupport(requestParams, data).$promise;
    };

    self.deleteDomainSupport = function (org, data) {
      var requestParams = {
        org: org,
        id: data.id
      };
      return SupportRepository.deleteDomainSupport(requestParams).$promise;
    };

    self.deleteUser = function (data) {
      var requestParams = {
        id: data
      };
      return SupportRepository.deleteUser(requestParams).$promise;
    };

    self.getCadenceDays = function () {
      return [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ];
    };
  }
})();
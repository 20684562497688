/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportMenu
 * @module tallyfy.support
 *
 * @description
 * A component to manage support menu
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('supportMenu', {
      bindings: {},
      templateUrl: 'app/modules/support/components/support_menu/support-menu.html',
      controller:
        /*@ngInject*/
        function (_, $log, $state, Auth, blockUI, $rootScope) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('appView');

          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          
          /**
          * Expose bindable methods
          * these methods are accessible in view
          */
          $ctrl.switchTo = switchTo;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.adminOrganizationName = _.get($rootScope.identity, 'default_organization.name');
          }

          /**
           * @ngdoc method
           * @name switchTo
           * @description switch Masquerade or or org
           * @param {*} temp
           * @returns  void
           */
          function switchTo(temp) {
            blockUI.start();
            Auth.logoutMasquerade().then(function (organization) {
              blockUI.stop();
              if (!temp) {
                $state.go('home', { org_id: organization.id, assignee: _.get($rootScope.identity, 'id', 'anyone') }, { reload: true });
              } else {
                $state.go('support.view', { user_org: $state.params.org_id }, { reload: true });
              }
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }
        }
    });
})();
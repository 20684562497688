(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(interactStateConfig);

  interactStateConfig.$inject = ['$stateProvider'];
  function interactStateConfig($stateProvider) {
    $stateProvider.state('interact', {
      absolute: true,
      url: '/interact?dback', //dback -> Disable back button;
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        '': {
          component: 'engageCustomer'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ]
      }
    });
  }
})();
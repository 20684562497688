(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .factory('errorHandlerInterceptor', errorHandlerInterceptor);

  /*@ngInject*/

  function errorHandlerInterceptor($q, $rootScope, growl, _, $injector, TFY_EVENTS, $timeout, $filter, USER_STATE) {
    var service = {
      responseError: responseError
    };

    return service;

    function responseError(response) {
      if (!(response.status === 401 && (response.data === '' || response.data.path)) && !(_.endsWith(_.get(response, 'config.url'), ".html"))) {
        parseServerErrors(response);
      }
      return $q.reject(response);
    }

    function parseServerErrors(httpResponse) {
      var state = $injector.get('$state');
      switch (httpResponse.status) {
        // connection refused, server not reachable
        case 0:
          growl.error('global.error.server_not_reachable', { referenceId: 'growlNotification', disableIcons: true, disableCloseButton: true });
          break;
        case 400:
          if (httpResponse.code === "ACCOUNT_DISABLED") {
            parseInvalidResponse(httpResponse);
          }
          break;
        case 401:
          parse401Error(httpResponse);
          break;
        case 403:
          if (_.get(httpResponse, 'data.data.code') === "YOU_ARE_NOT_AN_ACTIVE_GUE")
            state.go('account-disabled');
          else if (_.get(httpResponse, 'data.data.code') === "SORRY_YOUR_ACCOUNT_HAS_BE")
            showDisabledAccountServerError();
          else if (_.get(httpResponse, 'data.message', '').includes("Since your free trial has run out")) {
            $rootScope.$emit('SHOW_SUPPORT_BAR_MESSAGE', { message: httpResponse.data.message });
          } else if (!_.get(httpResponse, 'config.params.skipNotFound')) {
            showServerError(httpResponse);
          }
          break;
        case 404:
          if (_.get($rootScope, 'identity.id') && !_.get(httpResponse, 'config.params.skipNotFound') && !_.get(httpResponse, 'data.data.mailgunStatus')) {
            state.go('notfound');
          } else if ($rootScope.toState.name === 'guest.tasks' && !_.get(httpResponse, 'config.params.skipNotFound')) {
            state.go('guest.task');
          } else if (_.get(httpResponse, 'config.params.skipNotFound') && checkForStepNotFoundErrors(httpResponse)) {
            // handling deleted step of existing blue print
            growl.error($filter('translate')('steps.messages.stepNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            state.reload();
          } else if (!_.get($rootScope, 'identity.id') && !_.get(httpResponse, 'config.params.skipNotFound')) {
            state.go('public-notfound');
          }
          break;
        case 405:
          showServerError(httpResponse);
          break;
        case 422:
          parseInvalidResponse(httpResponse);
          break;
        case 429:
          growl.error($filter('translate')('global.account.limitMaxedOut.message'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
          break;
        case 503:
        case 500:
          parse500Error(httpResponse);
          break;
        default:
          showServerError(httpResponse);
      }
    }
    function parse500Error(response) {
      var urlSegments,
        state = $injector.get('$state');

      urlSegments = response.config.url.split('?');
      if (urlSegments.length && _.endsWith(urlSegments[0], "/me")) {
        state.go('maintenance');
        return;
      }
      if (response && _.has(response.config.data, 'url')) {
        return;
      }
      showServerError(response);
    }

    function checkForStepNotFoundErrors(httpResponse) {
      return ((httpResponse && httpResponse.status === 404) && (httpResponse.config && (httpResponse.config.method === 'PUT' || httpResponse.config.method === 'POST') && httpResponse.config.url && (httpResponse.config.url.indexOf('checklists') > -1) && (httpResponse.config.url.indexOf('steps') > -1 || httpResponse.config.url.indexOf('checklists/tags') > -1)));
    }

    // Check for checkklist not found error insode step udpate error
    function checkForStepInvalidErrors(httpResponse) {
      return (httpResponse && httpResponse.status === 422) && (httpResponse.config && (httpResponse.config.method === 'PUT' || httpResponse.config.method === 'POST') && httpResponse.config.url && (httpResponse.config.url.indexOf('checklists') > -1) &&
        (httpResponse.config.url.indexOf('steps') > -1 && (_.has(httpResponse, "data.errors['checklist_id'][0]") && _.get(httpResponse, "data.errors['checklist_id'][0]") === "The selected checklist id is invalid.") ||
          (httpResponse.config.url.indexOf('checklists/tags') > -1 && (_.has(httpResponse, "data.errors['subject_type'][0]") && _.get(httpResponse, "data.errors['subject_type'][0]") === "Subject not found"))));
    }

    function parseInvalidResponse(httpResponse) {
      var state = $injector.get('$state'),
        errors = httpResponse.data && (_.get(httpResponse, 'data.errors') || _.get(httpResponse, 'data.data.errors'));
      if (httpResponse.data && (!_.isEmpty(httpResponse.data.validation_errors) || !_.isEmpty(errors))) {
        if (errors) {
          httpResponse.data.validation_errors = errors;
        }
        if (_.has(httpResponse.data.validation_errors, 'url')) {
          return;
        }
        if (_.has(httpResponse.data.validation_errors, 'owner_id')) {
          return;
        }
        if (checkForStepInvalidErrors(httpResponse)) {
          growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
          state.go('process.templates');
        } else if (!_.has(httpResponse.data.validation_errors, 'old_password') &&
          !_.has(httpResponse.data.validation_errors, 'email') &&
          !_.has(httpResponse.data.validation_errors, 'first_name') &&
          !_.has(httpResponse.data.validation_errors, 'last_name')) {
          var errors = parseError(httpResponse.data.validation_errors);
          growl.error(errors.join('<div></div>'), {
            referenceId: 'growlNotification',
            disableIcons: true,
            disableCloseButton: true
          });
        }
      } else {
        showServerError(httpResponse);
      }
    }

    function parseError(errorItems) {
      var errors = [];
      _.forEach(errorItems, function (value, key) {
        if (_.isArray(value) || _.isObject(value)) {
          errors = _.union(errors, parseError(value));
        } else {
          errors.push(value);
        }
      });
      return errors;
    }

    function showServerError(httpResponse) {
      var errorMessage = '',
        state = $injector.get('$state');
      if (_.has(httpResponse, "data.data.errors['tasks'][0]")) {
        var errors = _.values(_.get(httpResponse, "data.data.errors['tasks'][0]"));
        errorMessage = _.get(errors, '[0][0]');
      } else if (_.has(httpResponse, "data.data.errors['max_assignable'][0]")) {
        errorMessage = _.get(httpResponse, "data.data.errors['max_assignable'][0]");
      } else if (_.has(httpResponse, "data.data.errors['same.organizations']")) {
        errorMessage = _.get(httpResponse, "data.data.errors['same.organizations'][0]");
      } else if (_.has(httpResponse, "data.data.errors['owners.guests.0']")) {
        errorMessage = _.get(httpResponse, "data.data.errors['owners.guests.0'][0]");
      } else if (_.has(httpResponse, "data.data.errors['owners']")) {
        errorMessage = _.get(httpResponse, "data.data.errors['owners'][0]");
      } else if (_.has(httpResponse, "data.data.errors['checklist_id'][0]")) {
        errorMessage = _.get(httpResponse, "data.data.errors['checklist_id'][0]");
      } else if (_.has(httpResponse, "data.data.errors['task'][0]")) {
        errorMessage = _.get(httpResponse, "data.data.errors['task'][0]");
      } else if (_.has(httpResponse, 'data.data.message')) {
        errorMessage = _.get(httpResponse, 'data.data.message');
      } else if (_.has(httpResponse, 'data.message')) {
        errorMessage = _.get(httpResponse, 'data.message');
      } else {
        errorMessage = httpResponse.statusText;
      }
      if (errorMessage) {
        growl.error(errorMessage, { referenceId: 'growlNotification', disableIcons: true, disableCloseButton: true });
      }
      if (!httpResponse.status && !errorMessage) {
        state.go('notfound');
      }
    }

    function showDisabledAccountServerError() {
      var auth = $injector.get('Auth');

      auth.logout().then(function () {
        $timeout(function () {
          growl.error($filter('translate')('global.account.accountDisabled.message'), { referenceId: 'loginController', disableIcons: true, disableCloseButton: true });
        });
        postLogoutHandler();
      }, function (error) {
        growl.error(_.get(error, 'data.message', ''), { referenceId: 'logoutController', disableIcons: true, disableCloseButton: true });
      });
    }

    function parse401Error(response) {
      if ((_.get(response, 'data.message') === 'Unauthenticated.') || (_.get(response, 'data.message') === 'Permission denied. Error Code: apsh213.')) {
        if ($rootScope.userState === USER_STATE.PUBLIC) {
          $rootScope.$emit('PUBLIC:ACCESS_RESTRICTED');
        } else {
          var auth = $injector.get('Auth');
          auth.logout().then(function () {
            postLogoutHandler();
          }, function (error) {
            growl.error(_.get(error, 'data.message', ''), { referenceId: 'logoutController', disableIcons: true, disableCloseButton: true });
          });
        }
      }
    }

    function postLogoutHandler() {
      var state = $injector.get('$state'),
        pollyservice = $injector.get('pollingService');
      pollyservice.cancelPolling();
      $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, { reset: 'no', setTheme: '' });
      state.go('authHandler', null, { inherit: false });
    }
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportClientIntegrationsList
 * @module tallyfy.support
 *
 * @description
 * A component to manage client integrations list
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportClientIntegrationsList', {
      templateUrl: 'app/modules/support/components/client-integrations/list/client-integrations-list.html',
      controller:
        /*@ngInject*/
        function (_, SupportService, blockUI, Growl, $uibModal, $log, $confirm, $filter, OrganizationsService, Helper) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('supportClientIntegrationsList');

          /**
           * public properties
           */
          $ctrl.clientIntegrations = [];
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.openClientIntegrationModal = openClientIntegrationModal;
          $ctrl.confirmSwitchStatus = confirmSwitchStatus;
          $ctrl.copyToClipboard = Helper.copyToClipboard;

          /**
           * public methods
           */

          function initialization() {
            getClientIntegrations();
          }

          function getClientIntegrations() {
            blockUI.start();
            SupportService.getClientIntegrations().then(function (response) {
              $ctrl.clientIntegrations = _.sortBy(response.data || [], 'id');
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name openClientIntegrationModal
           * @param {Object} clientIntegrationObj
           * @description To open new client integration modal
           * @returns void
           */
          function openClientIntegrationModal(clientIntegrationObj) {
            $uibModal.open({
              component: 'clientIntegrationModal',
              windowClass: 'client-integration-modal',
              backdrop: 'static',
              resolve: {
                clientIntegrationObj: function () {
                  return clientIntegrationObj;
                }
              }
            }).result.then(function (newClientIntegration) {
              if (clientIntegrationObj) {
                var index = _.findIndex($ctrl.clientIntegrations, { id: clientIntegrationObj.id });
                angular.extend($ctrl.clientIntegrations[index], newClientIntegration);
                growl.success($filter('translate')('support.client_integrations.messages.client_updated', { clientName: newClientIntegration.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              } else {
                $ctrl.clientIntegrations.push(newClientIntegration);
                growl.success($filter('translate')('support.client_integrations.messages.client_added', { clientName: newClientIntegration.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            }, function () {
              $log.info('Modal is cancelled');
            });
          }

          function confirmSwitchStatus(clientIntegrationObj) {
            $confirm({
              'header': clientIntegrationObj.revoked ? $filter('translate')('support.client_integrations.confirm_activate', { clientName: clientIntegrationObj.name }) : $filter('translate')('support.client_integrations.confirm_revoke', { clientName: clientIntegrationObj.name }),
              'body': '',
              'buttons': {
                'accept': $filter('translate')('support.client_integrations.action_yes'),
                'cancel': $filter('translate')('support.client_integrations.action_cancel')
              },
              'modalType': 'modal-danger'
            }).then(function () {
              switchStatus(clientIntegrationObj);
            }, function () {
              $log.info('Client status change is cancelled.');
            });
          }

          function switchStatus(clientIntegrationObj) {
            if (clientIntegrationObj.revoked) {
              //@TODO Unhide / add functionality to activate a client here
              //growl.success($filter('translate')('support.client_integrations.messages.client_activated', { clientName: clientIntegrationObj.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            } else {
              SupportService.deleteClientIntegration({ sub_action: clientIntegrationObj.id }).then(function () {
                var index = _.findIndex($ctrl.clientIntegrations, { id: clientIntegrationObj.id });
                $ctrl.clientIntegrations[index].revoked = true;
                growl.success($filter('translate')('support.client_integrations.messages.client_revoked', { clientName: $ctrl.clientIntegrations[index].name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }, function () {
                growl.error($filter('translate')('global.error.url.not.found'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                $log.info('Error while revoking access to the client');
              });
            }
          }
        }
    });
})();

/**
 * @ngdoc component
 * @name tallyfy.guestBookmark
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('guestBookmark', {
      templateUrl: 'app/components/compact/items/guestBookmark/guest-bookmark.component.html',
      bindings: {
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;

          $ctrl.bookmarkPage = onBookmarkPageClick;

          function onBookmarkPageClick(e) {
            /**
             * TODO bookmark action
             */
          }
        }
    });
})();
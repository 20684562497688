/**
 * @ngdoc Directive
 * @name tallyfy.onEnterKeyTriggerAnimatedButton
 * @description save on enter key and trigger animated button
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('onEnterKeyTriggerAnimatedButton', onEnterKeyTriggerAnimatedButton);
    /*@ngInject*/
    function onEnterKeyTriggerAnimatedButton() {
      return {
        restrict: 'A',
        scope : {
          isAnimatedButton: '<'
        },
        link: function ($scope, element, attrs) {
          element.bind('keypress', function (event) {
            if (event.which === 13 && $scope.isAnimatedButton == true) {
              element.find('#saveBluePrint').trigger('click');
            }
          });

          $scope.$on('$destroy', function () {
            element.unbind('keypress');
          });
        }
      };
    }
})();

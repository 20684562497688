/**
 * @ngdoc Filter
 * @name objectLength
 * @module tallyfy
 * @description
 * return length on the object
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('objectLength', function () {
      return function(input){
        if (!angular.isObject(input)){
            return 0;
        }
        return Object.keys(input).length;
      }
    });
})();
/**
 * @ngdoc component
 * @name tallyfy.processDetail
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('processDetail', {
      templateUrl: 'app/components/compact/items/processDetails/process-detail.component.html',
      bindings: {
        task: '<',
        process: '<',
        isGuest: '<',
        isPublicProcess: '<'
      },
      controller:
        /*@ngInject*/
        function (_, ProcessService, RunsService, RunRepository, DESCRIPTIONSIZE, $timeout) {
          var $ctrl = this;

          // angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;

          // public methods
          $ctrl.openViewMoreModel = openViewMoreModel;
          $ctrl.allFormFieldHeigth = allFormFieldHeigth;

          /**
           * @ngdoc method
           * @name onInit
           * 
           * @description
           * angular life cycle hook on init method
           */
          function onInit() {
            $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(DESCRIPTIONSIZE.compactProcessNote);
            if ($ctrl.process) {
              var data = {};
              if (_.get($ctrl.process, 'form_fields.data.length', 0) > 0) {
                var list = _.filter($ctrl.process.form_fields.data, function (row) {
                  return !!(row.field_value);
                });
                _.forEach(list, function (field) {
                  if (!data[field.task_id]) {
                    data[field.task_id] = [];
                  }
                  data[field.task_id].push(field);
                });
              }
              _.set($ctrl.process, 'form_fields_group.data', data);
            }
          }

          function onChanges(changes) {
            if (changes.task && changes.task.currentValue) {
              $ctrl.taskSummary = _.get(changes.task, 'currentValue.summary');
              $ctrl.taskType = _.get(changes.task, 'currentValue.task_type');
              if (changes.task.currentValue !== changes.task.previousValue) {
                if (_.get(changes.task, 'currentValue.run_id') && !$ctrl.isGuest) {
                  $ctrl.notes = _.get(changes.task, 'currentValue.run.data.summary');
                  var withStr = ($ctrl.isGuest ? 'checklist,guest_watchers.watcher' : 'checklist,member_watchers.watcher');
                  if ($ctrl.isPublicProcess) {
                    RunRepository.getPublicSingleRunData({
                      runId: _.get(changes.task, 'currentValue.run_id'),
                      with: withStr
                    }).then(function (res) {
                      updateFieldValue(res.data);
                    });
                  } else {
                    if (!$ctrl.process) {
                      RunsService.get({
                        id: _.get(changes.task, 'currentValue.run_id'),
                        with: withStr + ',stages'
                      }).then(function (res) {
                        if (!$ctrl.process) {
                          $ctrl.process = res.data;
                        }
                        updateFieldValue(res.data);
                      });
                    } else {
                      updateFieldValue($ctrl.process);
                    }
                  }
                }
              }
            }
            if (changes.process && changes.process.currentValue) {
              if (changes.process.currentValue !== changes.process.previousValue) {
                if (!$ctrl.isGuest) {
                  $ctrl.notes = _.get(changes.process, 'currentValue.summary');
                  $ctrl.checklist_id = _.get(changes.process, 'currentValue.checklist.data.id');
                }
              }
            }
          }
          
          function updateFieldValue(data) {
            $ctrl.checklist_id = _.get(data, 'checklist.data.id');
            $ctrl.preruns = _.get(data, 'checklist.data.prerun', []);
            $ctrl.prerunValues = _.get(data, 'prerun');
          }

          /**
           * @ngdoc method
           * @name openViewMoreModel
           * 
           * @description open view more modal for view more details of summary
           */
          function openViewMoreModel() {
            ProcessService.openViewMoreModal({ summary: $ctrl.notes });
          }

          /**
           * @ngdoc method
           * @name allFormFieldHeigth
           * 
           * @description open view details of form field and check heigth
           */
          function allFormFieldHeigth(id) {
            $timeout(function () {
              $ctrl.fieldHeigth = angular.element(document.getElementById("allFormField_" + id).clientHeight);
            }, 300);
          }
        }
    });
})();

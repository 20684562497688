(function () {
  'use strict';
  angular.module('tallyfy')
    .service('CreateFieldPreviewTableService', CreateFieldPreviewTableService);

  function CreateFieldPreviewTableService(Helper, KendoUIService) {
    var self = this;
    self.getFieldPreviewTableOptions = function (id, columns) {
      var columnDefs = [];
      for (var i = 0; i < columns.length; i++) {
        var guid = Helper.getRandomString(8);
        columnDefs.push({
          field: guid,
          exportKeyMap: {
            key: guid,
            label: columns[i].label
          },
          headerTemplate: kendo.template(KendoUIService.getTooltippedHeaderLabel(columns[i])),
          title: columns[i].label
        });
      }
      return {
        columns: columnDefs,
        sortable: false,
        resizable: true,
        noRecords: {
          template: kendo.template($('#previewTableNoData_' + id).html())
        }
      };
    }
    return self;
  }
})();
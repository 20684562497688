/**
 * @ngdoc Component
 * @name tallyfy.filters.component.filter
 * @module tallyfy.filters
 *
 * @description
 * A component to manage filter Modal view
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.filters')
    .component('filtersModal', {
      templateUrl: 'app/modules/filter/components/filterModal/filterModal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, Growl, $rootScope, FolderService, FilterModalService, $translate, $filter, SpinnerService, $timeout, $scope, MODAL_CLOSE_EVENTS, store, TasksService, $window) {
          var $ctrl = this,
            growl = new Growl(),
            appendFilter = [],
            body = angular.element('body'),
            onModalClosePreventHandler;

          /**
           * public properties
           * */
          $ctrl.processTypes = [
            { id: 'procedure', label: 'Procedure' },
            { id: 'document', label: 'Document' }
          ];
          $ctrl.filterTypeValuesCache = {};
          $ctrl.criteriaData = [];
          $ctrl.filterValue = [];
          $ctrl.action = "";
          $ctrl.existFilterData = [];
          $ctrl.filterTypeValue = {};
          $ctrl.fileredRecords = [];
          $ctrl.filterLimit = 10;
          $ctrl.perPage = 10;
          $ctrl.viewForm = {};
          $ctrl.isComponentDestroyed = false;
          var disallowed_statuses = ['complete', 'archived', 'active', 'problem'];
          var allowed_statuses = ['starred', 'delayed'];
          $ctrl.isFilterConditionTypeDropdownOpen = false;
          $ctrl.selectedAssignees = [];
          $ctrl.selectedAssigneesGroup = [];

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          /**
           * public methods
           * */
          $ctrl.selectCriteria = selectCriteria;
          $ctrl.setCriteria = setCriteria;
          $ctrl.setCriteriaField = setCriteriaField;
          $ctrl.selectCriteriaValue = selectCriteriaValue;
          $ctrl.updateCriteriaValue = updateCriteriaValue;
          $ctrl.setFilterCondition = setFilterCondition;
          $ctrl.addNewCriteria = addNewCriteria;
          $ctrl.deleteFilterCriteria = deleteFilterCriteria;
          $ctrl.getfilterTypeValuesCache = getfilterTypeValuesCache;
          $ctrl.getFilterTypeValuesBySlug = getFilterTypeValuesBySlug;
          $ctrl.setfilterTypeValuesCache = setfilterTypeValuesCache;
          $ctrl.getfilterTypeValue = getfilterTypeValue;
          $ctrl.setCriteriaValue = setCriteriaValue;
          $ctrl.setCriteriaTaskValue = setCriteriaTaskValue;
          $ctrl.getCriteriaValue = getCriteriaValue;
          $ctrl.updateAllCriterialValueByFilterType = updateAllCriterialValueByFilterType;
          $ctrl.validFilter = validFilter;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;
          $ctrl.setActionAndCriteriaData = setActionAndCriteriaData;
          $ctrl.searchRecords = searchRecords;
          $ctrl.deleteCustomFilter = deleteCustomFilter;
          $ctrl.isSpinnerActive = isSpinnerActive;
          $ctrl.accepts = accepts;
          $ctrl.cancel = cancel;
          $ctrl.loadMore = loadMore;
          $ctrl.getErrorMessageModel = getErrorMessageModel;
          $ctrl.getDDPlaceholder = getDDPlaceholder;
          $ctrl.getGroups = getGroups;
          $ctrl.onAssignTab = onAssignTab;
          $ctrl.onUsers = onUsers;
          $ctrl.searchUsers = searchUsers;
          $ctrl.toggleOwnerSelected = toggleOwnerSelected;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.onFolderSeletionToggle = onFolderSeletionToggle;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.onRunMenuViewClick = onRunMenuViewClick;

          function initialization() {
            getTasksAllFolders();
            body.addClass('filter-modal-component-active');
            $ctrl.filterData = angular.copy($ctrl.resolve.filterData);
            $ctrl.filterTypes = $ctrl.filterData.filterTypes;
            $ctrl.filterAssignedTypes = [
              { label: $filter('translate')('filter.options.labels.all'), value: 'all' },
              { label: $filter('translate')('filter.options.labels.completed'), value: 'complete' },
              { label: $filter('translate')('filter.options.labels.active'), value: 'active' }
            ];
            $ctrl.leftText = $ctrl.filterData.filterFor + 'DropdownTextLeft';
            $ctrl.rightText = $ctrl.filterData.filterFor + 'DropdownTextRight';
            $ctrl.filterCondition = [
              { id: 'all', label: $filter('translate')('filter.options.labels.all') },
              { id: 'any', label: $filter('translate')('filter.options.labels.any') }
            ];
            var row = _.find($ctrl.filterData.filter.criteria, { label: "groups" });
            if (row) {
              if (row.task === "completed") {
                row.task = "complete";
              }
            }
            setActionAndCriteriaData(_.get($ctrl.filterData, 'filter.criteria', []).length);
            $ctrl.assigneeLimit = $window.innerWidth <= 576 ? 8 : 5;
          }

          function onFolderTreeToggle(e, folder, index) {
            if (e) {
              e.stopImmediatePropagation();
            }
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          function getTasksAllFolders() {
            $ctrl.isLoading = true;
            FolderService.getTaskOrRunFolders({
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            }).then(function (res) {
              $ctrl.isLoading = false;
              $ctrl.foldersList = res.data;
            });
          }

          /**
           * @ngdoc method
           * @name onRunMenuViewClick
           * @public
           * @description check authority for archive process
           * @param {*} $event
           */
          function onRunMenuViewClick(key) {
            angular.element(document.querySelector('body')).click();
            $ctrl.isOpenPopOver[key] = true;
          }

          /* @function
           * @name onFolderSeletionToggle
           * @param {*} folder
           * @description
           * Toggle folder selection
           */
          function onFolderSeletionToggle(folder, index) {
            angular.extend($ctrl.filterData.filter.criteria[index], { value: _.get(folder, 'id'), label: _.get(folder, 'name')});
            $ctrl.isOpenPopOver[index] = false;
          }

          /**
           * @ngdoc method
           * @name loadMore
           * @public
           * @description triggers when scrolled down
           */
          function loadMore(fieldId, key, search) {
            $ctrl.filterLimit += 10;
            if ((fieldId === 'checklist_id' || fieldId === 'tag') && !$ctrl.filterData.filter.criteria[key].isLoadedData) {
              $ctrl.filterData.filter.criteria[key].currentPage = $ctrl.filterData.filter.criteria[key].currentPage + 1;
              getFilterTypeValuesBySlug($ctrl.filterType, key, search, true);
            }
          }

          function setActionAndCriteriaData(criteriaLength) {
            onUsers();
            getGroups();
            if (!criteriaLength) {
              // add criteria of filter
              addNewCriteria();
              $ctrl.action = "Add";
              return;
            }
            // edit criteria of filter
            $ctrl.action = "Edit";
            _.forEach($ctrl.filterData.filter.criteria, function (v) {
              v.currentPage = 1;
              v.isLoadedData = false;
              if (v.field === 'checklist_id') {
                v.bpLabelQueryMade = false;
              }
              var filterType = _.find($ctrl.filterTypes, { id: v.field }),
                filterTaskType = _.get(v, 'task') ? _.find($ctrl.filterAssignedTypes, { value: v.task })
                  : _.first($ctrl.filterAssignedTypes);
              
              if (v.field === 'owners') {
                $timeout(function () {
                  $ctrl.criteriaData.push({ filterType: filterType, filterTaskType: filterTaskType, filterTypeValue: '' });
                }, 0);
              } else {
                $ctrl.criteriaData.push({ filterType: filterType, filterTypeValue: '' });
              }
              if (v.field == 'type') {
                var selectedType = $ctrl.processTypes.find(function (t) {
                  return t.id == v.value;
                });
                $ctrl.filterValue.push(selectedType);
              }
            });
          }

          /**
           * @ngdoc method
           * @name addNewCriteria
           * @description Add new criteria to the filter
           * @public 
           */
          function addNewCriteria() {
            var criteriaData = {
              filterType: _.first($ctrl.filterTypes),
              filterTypeValue: '',
              filterTaskType: _.first($ctrl.filterAssignedTypes)
            }, criteria = {
              field: _.first($ctrl.filterTypes).id,
              value: '',
              label: "member",
              task: _.first($ctrl.filterAssignedTypes),
              currentPage: 1
            };
            
            $ctrl.totalData = false;
            if ($ctrl.filterData.filter.criteria && $ctrl.filterData.filter.criteria.length > 0) {
              $ctrl.filterData.filter.criteria.push(criteria);
            } else {
              _.set($ctrl.filterData, 'filter.criteria', [criteria]);
            }
            if ($ctrl.criteriaData && $ctrl.criteriaData.length > 0) {
              $ctrl.criteriaData.push(criteriaData);
            } else {
              $ctrl.criteriaData = [criteriaData];
            }
          }

          /**
           * Set filter condition value
           */
          function setFilterCondition(type) {
            type.label !== $ctrl.filterData.filter.type.label ?
              $ctrl.filterData.filter.type = type :
              angular.noop();

            setValidStatusFilters()
          }

          function selectCriteria(key, filterType) {
            filterType.label !== $ctrl.criteriaData[key].filterType.label ?
              setCriteria(key, filterType) :
              angular.noop();
          }

          /**
           * Set filter Type according to selected criteria
           */
          function setCriteria(key, filterType) {
            if (!filterType) {
              return;
            }
            $ctrl.setCriteriaField(key, filterType.id);
            $ctrl.criteriaData[key].filterType = filterType;

            // Check cache exists then get datafrom cache
            if (!_.isEmpty($ctrl.getfilterTypeValuesCache(filterType.id))) {
              $ctrl.updateCriteriaValue(key, $ctrl.getfilterTypeValue(key, filterType.id));
              return;
            }

            // check filter type is static data      
            if (!_.isEmpty(filterType.data)) {
              // Add data to cache
              $ctrl.setfilterTypeValuesCache(filterType.id, filterType.data);
              $ctrl.updateCriteriaValue(key, $ctrl.getfilterTypeValue(key, filterType.id));
              return;
            }

            //Check filter has slug then fetch data from api
            if (!_.isEmpty(filterType.slug)) {
              appendFilter = [];
              $ctrl.getFilterTypeValuesBySlug(filterType, key);
            }
          }

          /**
           * Set filter type-value 
           */
          function getfilterTypeValue(key, filterTypeid) {
            var filterTypeValue;
            if (!$ctrl.getCriteriaValue(key)) {
              filterTypeValue = _.first(getStatusFilters($ctrl.getfilterTypeValuesCache(filterTypeid), key));
            } else {
              filterTypeValue = _.find($ctrl.getfilterTypeValuesCache(filterTypeid), {
                id: $ctrl.getCriteriaValue(key)
              });
              if (_.isUndefined(filterTypeValue) && filterTypeid === 'checklist_id' && !$ctrl.filterData.filter.criteria[key].bpLabelQueryMade) {
                $ctrl.filterData.filter.criteria[key].bpLabelQueryMade = true;
                getFilterTypeValuesBySlug($ctrl.filterType, key, angular.copy($ctrl.filterData.filter.criteria[key].label), false);
              }
            }

            // If not found then use first object
            if (_.isEmpty(filterTypeValue)) {
              if (filterTypeid === 'status') {
                filterTypeValue = _.first(getStatusFilters($ctrl.getfilterTypeValuesCache(filterTypeid), key));
              }
              if (filterTypeid !== 'checklist_id') {
                filterTypeValue = _.first($ctrl.getfilterTypeValuesCache(filterTypeid));
              }
            }

            if (!_.isUndefined(filterTypeValue)) {
              return filterTypeValue;
            }
          }

          function updateAllCriterialValueByFilterType(filterTypeId, isLoad) {
            _.forEach(angular.copy($ctrl.filterData.filter.criteria), function (v, k) {
              if (v.field === filterTypeId) {
                var filterValues = $ctrl.getfilterTypeValue(k, filterTypeId);
                if (filterValues) {
                  $ctrl.updateCriteriaValue(k, filterValues);
                  if (!isLoad) {
                    v.currentPage = 1;
                  }
                } else {
                  v.currentPage += 1;
                }
              }
            });
          }

          function selectCriteriaValue(key, filterTypeValue) {
            filterTypeValue.id !== $ctrl.criteriaData[key].filterTypeValue.id ?
              $ctrl.updateCriteriaValue(key, filterTypeValue) :
              angular.noop();
          }

          function updateCriteriaValue(key, filterTypeValue) {
            $ctrl.filterTypeValue = _.isEmpty(filterTypeValue) ? { id: '', label: '' } : filterTypeValue;

            $ctrl.setCriteriaValue(key, $ctrl.filterTypeValue);
            $ctrl.criteriaData[key].filterTypeValue = $ctrl.filterTypeValue;

            $ctrl.filterValue[key] = $ctrl.filterTypeValue;
          }

          /**
           * @ngdoc method
           * @name getFilterTypeValuesBySlug
           * @description Get filter type values by selected filter
           * @private 
           */
          function getFilterTypeValuesBySlug(filterType, key, search, isLoad) {
            $ctrl.filterData.filter.criteria[key].isLoadedData = false;
            if (!_.includes($ctrl.existFilterData, filterType.id) || (filterType.id === 'checklist_id' || filterType.id === 'tag')) {
              $ctrl.existFilterData.push(filterType.id);
              if (filterType.id === 'checklist_id' || filterType.id === 'tag') {
                $ctrl.filterType = angular.copy(filterType);
                var requestParam = {
                  per_page: $ctrl.perPage,
                  page: $ctrl.filterData.filter.criteria[key].currentPage,
                  search: search
                };
                angular.extend(filterType, requestParam);
              }
              if (filterType.id !== 'type') {
                $timeout(function () {
                  SpinnerService.show(key);
                  FilterModalService.getFilterTypeValuesBySlug($rootScope.identity.default_organization.id, filterType, $rootScope.identity.id, function (response) {
                    if ($ctrl.isComponentDestroyed) {
                      return;
                    }
                    if ((filterType.id === 'checklist_id' || filterType.id === 'tag') && _.get(response, 'data')) {
                      _.forEach(response.data, function (value, k) {
                        appendFilter.push(value);
                      });
                      if (_.get(response.meta, 'pagination').current_page === _.get(response.meta, 'pagination').total_pages) {
                        $ctrl.filterData.filter.criteria[key].isLoadedData = true;
                      }
                      $ctrl.setfilterTypeValuesCache(filterType.id, _.uniqBy(appendFilter, 'id'));
                    } else {
                      $ctrl.setfilterTypeValuesCache(filterType.id, response.data);
                    }
                    if (_.get(response, 'data')) {
                      $ctrl.updateAllCriterialValueByFilterType(filterType.id, isLoad);
                    }
                    SpinnerService.hide(key);
                    // $ctrl.updateCriteriaValue(key, $ctrl.getfilterTypeValue(key, filterType.id));
                  }, function (error) {
                    SpinnerService.hide(key);
                  });
                });
              } else {
                $ctrl.setfilterTypeValuesCache(filterType.id, $ctrl.processTypes);
                $ctrl.filterData.filter.criteria[key].isLoadedData = true;
              }
            }
          }

          /**
           * @ngdoc method
           * @name setfilterTypeValuesCache
           * @description Getter Setter for filtertypevaluescache
           * @private 
           */
          function setfilterTypeValuesCache(filterTypeId, data) {
            $ctrl.filterTypeValuesCache[filterTypeId] = data;
            $ctrl.fileredRecords = $ctrl.filterTypeValuesCache[filterTypeId];
          }

          function getfilterTypeValuesCache(filterTypeId) {
            return $ctrl.filterTypeValuesCache[filterTypeId] || [];
          }

          function setCriteriaField(key, value) {
            if ($ctrl.filterData.filter.criteria[key].field === value) {
              $ctrl.filterData.filter.criteria[key].field = value;
            } else {
              $ctrl.filterData.filter.criteria[key].field = value;
              $ctrl.filterData.filter.criteria[key].value = '';
            }
          }

          function getCriteriaValue(key) {
            return $ctrl.filterData.filter.criteria[key].value || '';
          }

          function setCriteriaValue(key, value) {
            $ctrl.filterData.filter.criteria[key].value = value.id;
            if (value.label !== 'groups') {
              $ctrl.filterData.filter.criteria[key].label = value.label;
            }
          }
          
          function setCriteriaTaskValue(key, value) {
            $ctrl.filterData.filter.criteria[key].task = value;
          }

          /**
           * Delete criteria from filter
           */
          function deleteFilterCriteria(key) {
            $ctrl.filterData.filter.criteria.splice(key, 1);
            $ctrl.criteriaData.splice(key, 1);
            $ctrl.filterValue.splice(key, 1);
            var assignedMember, assignedGroup;
            assignedMember = _.filter($ctrl.filterData.filter.criteria, function (m) { return m.field === 'owners' && (m.label === 'members' || m.label === "Me"); });
            assignedGroup = _.filter($ctrl.filterData.filter.criteria, function (g) { return g.field === 'owners' && g.label === 'groups'; });
            if (assignedMember.length === 0) {
              _.forEach($ctrl.users, function (value) {
                _.set(value, 'selected', false);
              });
            }
            if (assignedGroup.length === 0) {
              _.forEach($ctrl.groups, function (value) {
                _.set(value, 'selected', false);
              });
            }
          }

          /**
           * Valid filter's name and criterias
           */
          function validFilter(customFilter) {
            if (!customFilter.criteria.length || !_.isUndefined(_.find(customFilter.criteria, { value: "" }))) {
              $translate('filter.error.NotNull', { 'fieldName': "Filter conditions" }).then(function (translation) { growl.error(translation, { referenceId: 'filterModelController', disableIcons: true, disableCloseButton: true }) });
              return false;
            }

            if (_.isEmpty(customFilter.name)) {
              $translate('filter.error.NotNull', { 'fieldName': "Filter name" }).then(function (translation) { growl.error(translation, { referenceId: 'filterModelController', disableIcons: true, disableCloseButton: true }) });
              return false;
            }
            return true;
          }

          /**
           * Send accept modal event
           */
          function accepts() {
            var val = _.first($ctrl.filterAssignedTypes).value, memberTask, groupTask;
            memberTask = groupTask = val;
            if ($ctrl.viewForm.$valid && $ctrl.validFilter($ctrl.filterData.filter)) {
              var assignedMember, assignedGroup, selectMembers, selectedGroups, members = '', groups = '';
              assignedMember = _.filter($ctrl.filterData.filter.criteria, function (m) { return m.field === 'owners' && (m.label === 'members' || m.label === "Me"); });
              assignedGroup = _.filter($ctrl.filterData.filter.criteria, function (g) { return g.field === 'owners' && g.label === 'groups'; });
              
              if (assignedMember.length > 0 || assignedGroup.length > 0) {
                if (assignedMember.length > 0) {
                  selectMembers = _.filter($ctrl.users, function (value) {
                    return value.selected === true;
                  });
                  members = _.reduce(selectMembers, function (current, value, index) {
                    if (index > 0)
                      current += ',';
                    return current + value.id;
                  }, '');
                  memberTask = _.find($ctrl.filterData.filter.criteria, function (m) { return m.label === 'members' || m.label === 'Me'; }).task;
                }
                
                if (assignedGroup.length > 0) {
                  selectedGroups = _.filter($ctrl.groups, function (value) {
                    return value.selected === true;
                  });
                  groups = _.reduce(selectedGroups, function (current, value, index) {
                    if (index > 0)
                      current += ',';
                    return current + value.id;
                  }, '');
                  groupTask = _.find($ctrl.filterData.filter.criteria, { label: 'groups' }).task;
                }
                
                _.remove($ctrl.filterData.filter.criteria, function (m) { return m.label === 'members' || m.label === 'Me'; });
                if (members) {
                  $ctrl.filterData.filter.criteria.push({
                    field: 'owners',
                    label: 'members',
                    value: members,
                    task: _.get(memberTask, 'value') || memberTask
                  });
                }

                _.remove($ctrl.filterData.filter.criteria, { label: 'groups' });
                if (groups) {
                  var taskType = (_.get(groupTask, 'value') || groupTask); 
                  $ctrl.filterData.filter.criteria.push({
                    field: 'groups',
                    label: 'group',
                    value: groups,
                    task: taskType
                  });
                }

                if (!members && !groups) {
                  $ctrl.ownersValidate = true;
                  return;
                }
              } else {
                _.remove($ctrl.filterData.filter.criteria, function (m) { return m.field === 'owners' && (m.label === 'members' || m.label === "Me"); });
                _.remove($ctrl.filterData.filter.criteria, function (g) { return g.field === 'owners' && g.label === 'groups'; });
              }
              $ctrl.filterData.filter.criteria = _.map($ctrl.filterData.filter.criteria, function (o) {
                var temp = ['isLoadedData', 'currentPage', 'bpLabelQueryMade'];
                if (o.field !== 'owners' && o.field !== 'groups') {
                  temp.push('task');
                }
                return _.omit(o, temp);
              });
              $ctrl.close({ $value: $ctrl.filterData });
            }
            $ctrl.close({ $value: $ctrl.filterData });
          }

          /**
           * Send close modal event
           */
          function cancel() {
            $ctrl.dismiss();
          }

          function setSearchPlaceholder($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')('filter.filtersModal.searchPlaceholder');
            }
          }

          /**
            * @ngdoc method
            * @name searchRecords
            * @private
            * 
            * @description search record locally
            * wher user search for record. if no search it return first 20 record by default
            * 
            * @param {string} field type of search (assignee,template, tags)
            * @param {search} field search text
            */
          function searchRecords($select, key, fieldId, search) {
            if (!$select.open) {
              return;
            }

            if (_.isEmpty(search)) {
              $ctrl.filterData.filter.criteria[key].isLoadedData = false;
            }
            if ((fieldId === 'checklist_id' || fieldId === 'tag') && !_.isEmpty(search)) {
              $ctrl.filterData.filter.criteria[key].currentPage = 1;
              getFilterTypeValuesBySlug($ctrl.filterType, key, search);
              return;
            }

            $ctrl.filterLimit = 10;
            // clear filtered records everytime select will be open
            $ctrl.fileredRecords = [];

            // if spinner is active then select should not be open
            if ($ctrl.isSpinnerActive(key) && fieldId != 'checklist_id') {
              $select.close();
            } else {
              if ($ctrl.filterTypeValuesCache[fieldId]) {
                if (!search) {
                  var fileredRecords = $ctrl.filterTypeValuesCache[fieldId];
                  if (fieldId === 'status') {
                    $ctrl.fileredRecords = getStatusFilters(fileredRecords, key);
                  } else {
                    $ctrl.fileredRecords = fileredRecords;
                  }
                } else {
                  var fileredRecords = _.filter($ctrl.filterTypeValuesCache[fieldId], function (value) {
                    return _.toLower(value.label).indexOf(_.toLower(search)) !== -1;
                  });
                  if (fieldId === 'status') {
                    $ctrl.fileredRecords = getStatusFilters(fileredRecords);
                  } else {
                    $ctrl.fileredRecords = fileredRecords;
                  }
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name deleteCustomFilter
           * @description Delete custom filter
           * @public 
           * @param {object} filter
           * @returns {object} filter
           */
          function deleteCustomFilter(filter) {
            filter.isdelete = true;
            $ctrl.close({ $value: filter });
          }

          /**
           * @ngdoc method
           * @name isSpinnerActive
           * @description check if spinner active
           * @public 
           * @param {string} spinner name
           */
          function isSpinnerActive(name) {
            return SpinnerService.isSpinnerActive(name);
          }

          /**
           * @ngdoc method
           * @name getStatusFilters
           * @description get filtered status filters data
           * @public
           * @param {array} filters_data
           * @param {string} key
           */
          function getStatusFilters(filters_data, key) {
            var statusFiltersDataOne = _.filter($ctrl.filterData.filter.criteria, function (value, item_key) {
              return (_.includes(disallowed_statuses, value.value) && key !== item_key && $ctrl.filterData.filter.type.id === 'all');
            });

            var statusFiltersDataTwo = _.filter($ctrl.filterValue, function (value, item_key) {
              return (value && _.includes(disallowed_statuses, value.id) && key !== item_key && $ctrl.filterData.filter.type.id === 'all');
            });

            if (statusFiltersDataOne.length || statusFiltersDataTwo.length) {
              var filters_data_custom = _.filter(filters_data, function (value) {
                return (_.includes(allowed_statuses, value.id));
              });
              return filters_data_custom;
            }

            return filters_data;
          }

          /**
           * @ngdoc method
           * @name setValidStatusFilters
           * @description check if the status filters added are valid
           * @public
           */
          function setValidStatusFilters() {
            var statusFiltersData = _.filter($ctrl.filterValue, function (value, item_key) {
              value.key = item_key;
              return (_.includes(disallowed_statuses, value.id) && $ctrl.filterData.filter.type.id === 'all');
            });

            if (statusFiltersData.length > 1) {
              _.forEach(statusFiltersData, function (filter_item, idx) {
                if (idx > 0) {
                  $ctrl.filterData.filter.criteria[filter_item.key].value = undefined;
                  $ctrl.criteriaData[filter_item.key].filterTypeValue = undefined;
                  $ctrl.filterValue[filter_item.key] = undefined;
                }
              });
            }
          }

          /**
           * @ngdoc method
           * @name getErrorMessageModel
           * @description get Error message model
           * @public
           * @param {string} key
           */
          function getErrorMessageModel(key) {
            return $ctrl.viewForm['filter_value_' + key].$error;
          }

          /**
           * @ngdoc method
           * @name getDDPlaceholder
           * @description get dropdown placeholder
           * @public
           * @param {string} key
           */
          function getDDPlaceholder(key) {
            return !$ctrl.isSpinnerActive(key) ? $filter('translate')('filter.filtersModal.selectOption') : '';
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            body.removeClass('filter-modal-component-active');
            $ctrl.isComponentDestroyed = true;
            onModalClosePreventHandler();
          }

          function getGroups() {
            store.getGroups().then(function (response) {
              $ctrl.groupsValuesCache = response;
              $ctrl.groups = response;
              _.forEach($ctrl.groups, function (group) {
                angular.extend(group, {
                  selected: false
                });
              });
              var groupIds = [];
              _.forEach($ctrl.filterData.filter.criteria, function (v) {
                if (v.label == 'groups') {
                  groupIds = _.split(v.value, ',');
                  if (groupIds) {
                    _.forEach(groupIds, function (id) {
                      _.forEach($ctrl.groups, function (group) {
                        if (group.id == id) {
                          angular.extend(group, {
                            selected: true
                          });
                          $ctrl.selectedAssigneesGroup.push(group);
                        }
                      });
                    });
                    $ctrl.assigneesTooltipGroup = _.reduce($ctrl.selectedAssigneesGroup, function (current, value, index) {
                      if (index > 0)
                        current += ',';
                      return current + value.name;
                    }, '');
                  }
                }
              });
            });
          }

          function onAssignTab(e, customeCriteria) {
            e.stopImmediatePropagation();
            if (e.target.innerHTML === 'Groups') {
              _.set(customeCriteria, 'label', "groups");
            } else {
              _.set(customeCriteria, 'label', "members");
            }
          }

          function onUsers() {
            store.getUsers().then(function (response) {
              $ctrl.usersValuesCache = _.orderBy(response, 'full_name', 'asc');
              $ctrl.users = _.orderBy(response, 'full_name', 'asc');
              _.forEach($ctrl.users, function (user) {
                angular.extend(user, {
                  selected: false
                });
              });
              var userIds = [];
              _.forEach($ctrl.filterData.filter.criteria, function (v) {
                if (v.field == 'owners') {
                  userIds = _.split(v.value, ',');
                  if (userIds) {
                    _.forEach(userIds, function (id) {
                      _.forEach($ctrl.users, function (user) {
                        if (user.id == id) {
                          angular.extend(user, {
                            selected: true
                          });
                          $ctrl.selectedAssignees.push(user);
                        }
                      });
                    });
                    $ctrl.assigneesTooltip = _.reduce($ctrl.selectedAssignees, function (current, value, index) {
                      if (index > 0)
                        current += ',';
                      return current + value.full_name;
                    }, '');
                  }
                }
              });
            });
          }

          function searchUsers(fieldType, event) {
            if (fieldType === 'owners') {
              $ctrl.users = _.filter($ctrl.usersValuesCache, function (value) {
                return _.toLower(value.full_name).indexOf(_.toLower(event.currentTarget.value)) !== -1;
              });
            }
            if (fieldType === 'groups') {
              $ctrl.groups = _.filter($ctrl.groupsValuesCache, function (value) {
                return _.toLower(value.full_name).indexOf(_.toLower(event.currentTarget.value)) !== -1;
              });
            }
          }

          function toggleOwnerSelected (assignee) {
            $ctrl.ownersValidate = false;
            if (assignee.type === "group") {
              var assigneeIndex = _.findIndex($ctrl.selectedAssigneesGroup, { id: assignee.id });
              assigneeIndex < 0 ? $ctrl.selectedAssigneesGroup.push(assignee) : $ctrl.selectedAssigneesGroup.splice(assigneeIndex, 1);
              $ctrl.assigneesTooltipGroup = _.reduce($ctrl.selectedAssigneesGroup, function (current, value, index) {
                if (index > 0)
                  current += ',';
                return current + value.name;
              }, '');               
            } else {
              var assigneeIndex = _.findIndex($ctrl.selectedAssignees, { id: assignee.id });
              assigneeIndex < 0 ? $ctrl.selectedAssignees.push(assignee) : $ctrl.selectedAssignees.splice(assigneeIndex, 1);
              $ctrl.assigneesTooltip = _.reduce($ctrl.selectedAssignees, function (current, value, index) {
                if (index > 0)
                  current += ',';
                return current + value.full_name;
              }, '');
            }
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar (avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText (firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.viewForm.$dirty && (reason == MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason == MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.cropImage
 * @module tallyfy.organizations
 *
 * @description
 * A component to crop image
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('cropImage', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/crop-image/crop-image.html',
      controller:
      /*@ngInject*/
      function () {
        var $ctrl = this;
        
        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * Expose bindable methods
         * these methods are accessible in view
         */
        $ctrl.saveImage = saveImage;
        $ctrl.cancel = cancel;

        /**
         * public properties
         */
        $ctrl.cropper = {
          sourceImage: null,
          croppedImage: null,
          bounds: {
            left: 0,
            right: 100,
            top: 0,
            bottom: 43
          },
          croppedImageSize: 400
        };

        /**
         * @ngdoc method
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { 
          $ctrl.file = $ctrl.resolve.file;
          $ctrl.sourceImage = $ctrl.resolve.sourceImage;
          var image = new Image();
          image.src = $ctrl.sourceImage;
          image.onload = function() {
            if(this.height < $ctrl.cropper.croppedImageSize){
              $ctrl.cropper.croppedImageSize = 'max';
            }
          };
          $ctrl.shape = $ctrl.resolve.cropShape;
        }

        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }
        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }
        /**
         * @description 
         * @param {any} dataUrl 
         * @param {any} name 
         */
        function saveImage(dataUrl, name) {
          $ctrl.cropper = {
            dataUrl: dataUrl,
            name: name
          };
          $ctrl.close({ $value: $ctrl.cropper });
        }

        function cancel() {
          $ctrl.file = null;
          $ctrl.sourceImage = null;
          $ctrl.dismiss({ $value: 'cancel' });
        }
        //controller ends
      }
    });
})();
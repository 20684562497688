/**
 * @ngdoc Module plugin
 * @name facebook.pixel
 * @module angulartics.facebook.pixel
 *
 * @description
 * facebook pixel analytics plugin for angulartics
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com )
 */
(function (window) {
  'use strict';

  /**
   * @ngdoc module
   * @name angulartics.facebook.pixel
   */
  angular.module('angulartics.facebook.pixel', ['angulartics', 'tallyfy'])
    .config(config);

  /*@ngInject*/
  function config($analyticsProvider) {
    $analyticsProvider.settings.pageTracking.trackRelativePath = true;

    // register page track
    $analyticsProvider.registerPageTrack(function () {
      window.fbq ? fbq('track', 'PageView') : angular.noop();
    });
    
    // register event track
    $analyticsProvider.registerEventTrack(function (action, props) {
      if (props) {
        props.content_type = action;
        
        if (props.category) {
          props.content_category = props.category;
        }
      }
      
      window.fbq ? fbq('track', ((props && props.label) ? props.label : action), props) : angular.noop();
    });
  }
})(window);
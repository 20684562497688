/**
 * @ngdoc Directive
 * @name tallyfy.scrollToItem
 * @restrict 'A'
 * @element ANY
 * @author Kiran sompura ( gmail::kiranv.sompura@gmail.com)
 **/
(function () {
    'use strict';
    angular
        .module('tallyfy')
        .directive('scrollToItem', scrollToItem);

    function scrollToItem() {
        return {
            restrict: 'A',
            scope: {
                scrollTo: "@"
            },
            link: function(scope, $elm,attr) {
                $elm.on('click', function() {
                    var element = angular.element(scope.scrollTo);
                    angular.element(".app-body").animate({ scrollTop: element.offset().top }, "slow");
                });                                                                              
            }
        };
    }
})();

/**
 * @ngdoc Component
 * @name tallyfy.noTaskView.component
 * @module tallyfy.noTaskView
 *
 * @description
 * A component to show when task length is 0.
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  
  angular
    .module('tallyfy')
    .component('noTaskView', {
      bindings: {
        onClick: '&',
        message: '<',
        taskLength: '<',
        completedTaskLength: '<',
        userId: '<'
      },
      templateUrl: 'app/modules/noTaskView/noTaskView.html',
      controller: 
      /*@ngInject*/
      function noTaskViewComponent(TasksService, $uibModal) {
        var $ctrl = this;
        
        /**
         * component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;
        
        /**
         * Expose bindable methods
         * these methods are accessible in view
         */
        $ctrl.standaloneModal = standaloneModal;
        
        /**
         * @function
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { }
        
        /**
         * @function
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @function
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        /**
         * @ngdoc method
         * @name standaloneModal
         * @description  To open standalonetask modal
         * @returns void
         */
        function standaloneModal() {
          $uibModal.open({
            component: 'editTask',
            windowClass: 'task-modal create-task-modal',
            resolve: {
              isOwner: function () {
                return $ctrl.userId;
              }
            }
          });
        }
      }
    });
})();
/**
 * @ngdoc Component
 * @name publicOrganizations
 * @module tallyfy.organizations
 *
 * @description
 * Guest Org Switcher - List of guest organizations
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 */
(function () {
    'use strict';
    angular
      .module('tallyfy.organizations')
      .component('publicOrganizations', {
        templateUrl: 'app/modules/organizations/publicOrganizations/publicOrganizations.html',
        controller:
          /*@ngInject*/
          function (_, $rootScope, Helper, blockUI, OrganizationsService, $window, Auth, $state, $timeout) {
            var $ctrl = this,
              blockUI = blockUI.instances.get('guest-organizations');
            
            //Public properties
            $ctrl.orgsWithoutCurrent = [];
            
            $ctrl.$onInit = onInit;
            $ctrl.onChanges = onChanges;
            $ctrl.$onDestroy = onDestroy;

            //Public methods
            $ctrl.getLogo = getLogo;
            $ctrl.switchToMemberView = switchToMemberView;
            $ctrl.chooseOrganization = chooseOrganization;
  
            function onInit() {
              $ctrl.guest = _.get($rootScope.identity, 'guest');
              $ctrl.org = _.get($ctrl.guest, 'organization');
              $ctrl.orgsWithoutCurrent = prepareOrgList();
            }
  
            function onChanges() { }
  
            /**
             * @ngdoc method
             * @name onDestroy
             * 
             * @description
             * angularjs destroy lifecycle components hook
             */
            function onDestroy() {
            }

            //Prepare org list
            function prepareOrgList () {
              if (!$ctrl.guest || !$ctrl.org)
                  return;
              
              return _.filter($ctrl.guest.guestOrganizations, function (org) { return org.id !== $ctrl.org.id });
            }

            //Get org logo
            function getLogo (org) {
              if (org.org_logo) {
                return org.org_logo;
              } else {
                return OrganizationsService.getDefaultOrgLogoForPlan(org.plan_code);
              }
            }

            //Logout and switch to member view
            function switchToMemberView () {
              blockUI.start();
              Auth.logoutAndSwitchToMember().then(function (response) {
                blockUI.stop();
                $window.open(Helper.loginURL, '_blank');
              }, function () {
                blockUI.stop();
              });
            }

            //Switch guest org
            function chooseOrganization (org) {
              $timeout(function () {
                $window.location.reload();
              }, 1000);
              $state.go('guest.tasks', { org_id: org.id, guest_code: $ctrl.guest.guest_code });
            }
          }
      });
  })();
/**
 * @ngdoc Component
 * @name tallyfy.notifications.component.notificationsDropdown
 * @module tallyfy.notifications
 *
 * @description
 * Manage notification with Two Layout 
 * - LIST - Renders notification in grid view
 * - DROPDOWN - Renders notification in Dropdown
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
  */
(function () {
  'use strict';

  angular
    .module('tallyfy.notifications')
    .component('notifications', {
      template: '<div ng-include="$ctrl.templateUrl"></div>',
      bindings: {
        layout: '@?'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, Pubnub, ENV_CONFIG, $rootScope, $localStorage, $filter, moment, $timeout, NotificationsService, realtime, FroalaService, blockUI, Helper, CommentReactionService, UsersService, store) {
          var $ctrl = this,
            readNotifications = [],
            org_id = $localStorage.default_organization_Id,
            channel,
            rt_channel,
            read_channel,
            unregisterNewNotificationListener,
            unregisterNotificationReadListener,
            unregisterUnsubscribeChannelListener,
            unregisterSubscribeChannelListener,
            blockUI = blockUI.instances.get('notificationView');

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.checkRead = checkRead;
          $ctrl.unreadMessages = unreadMessagesCount;
          $ctrl.markReadAll = markReadAll;
          $ctrl.markRead = markRead;
          $ctrl.redirectToView = redirectToView;
          $ctrl.unreadNotifications = NotificationsService.getUnreadNotifications;

          /**
           * public properties
           */
          $ctrl.notifications = [];
          $ctrl.notificationsLimit = 5;
          $ctrl.snoozeNotification = true;
          $ctrl.notificationsLoaded = false;
          $ctrl.unreadMessagesLength = 0;
          $ctrl.isDropDownOpen = false;
          $ctrl.getFroalaViewContent = FroalaService.getViewContent;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            channel = 'tallyfy_org_' + org_id + '.user_' + $rootScope.identity.id + '_notifications';
            read_channel = channel + '_read';
            rt_channel = 'user_' + $rootScope.identity.id + '_notifications';
            getNotifications();
            getReadNotifications();
            realtime.subscribe(rt_channel);
            $ctrl.reactionLists = {};
            _.reduce(CommentReactionService.getReactionIconList(), function (acc, reaction) {
              acc[reaction.value] = reaction.context;
              return acc;
            }, $ctrl.reactionLists);
            store.getUsers().then(function (response) {
              $ctrl.allUsers = response || [];
              $ctrl.availableUsers = UsersService.getBilledUsers($ctrl.allUsers);
            });
          }

          /**
           * @function
           * @name onChanges
           * @param {*} bindings
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.layout) {
              if (bindings.layout.isFirstChange()) {
                var layout = _.get(bindings, 'layout.currentValue', 'LIST').toLowerCase();
                if (layout === 'list')
                  $ctrl.templateUrl = 'app/modules/notifications/partials/notificationsList.html';
                else if (layout === 'dropdown')
                  $ctrl.templateUrl = 'app/modules/notifications/partials/notifications-dropdown.html';
                else
                  $ctrl.templateUrl = 'app/modules/notifications/partials/notificationsList.html';
              }
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterNewNotificationListener();
            unregisterNotificationReadListener();
            unregisterUnsubscribeChannelListener();
            unregisterSubscribeChannelListener();
          }

          /**
           * @function
           * @name proccessNotification
           * @description Process a new notification and push to list
           * @param message
           */
          function proccessNotification(message) {
            $scope.$apply(function () {
              message.content = _.startsWith(message.content, 'key:reopenComment, ') ? message.content.replace('key:reopenComment, ', '') : message.content;
              message.context_type = message.updated_fields_string ? message.context_type + '.' + message.updated_fields_string.replace(/ /g, '_') : message.context_type;
              message.time = moment.utc(message.time);
              $ctrl.notifications.unshift(message);
              $ctrl.notificationCount = $ctrl.notifications.length;
            });
            $timeout(function () {
              $ctrl.unreadMessagesLength = $ctrl.unreadMessages();
              NotificationsService.setUnreadNotifications($ctrl.unreadMessagesLength);
            });
          }

          /**
           * @function
           * @name markRead
           * @description Mark a specific notification as read
           * @param notification
           */
          function markRead(notification) {
            if (!checkRead(notification.id)) {
              if (!Helper.checkAccessAuthority())
                return;
            }
            if (!_.find(readNotifications, { id: notification.id })) {
              NotificationsService.markRead(notification.id, read_channel, notification.timetoken)
                .then(function (res) {
                  readNotifications.push(notification.id);
                  $ctrl.unreadMessagesLength = $ctrl.unreadMessages();
                  NotificationsService.setUnreadNotifications($ctrl.unreadMessagesLength);
                });
            }
          }

          /**
           * @function
           * @name markReadAll
           * @description Mark all notifications as read
           */
          function markReadAll() {
            if (!Helper.checkAccessAuthority())
              return;
            angular.forEach($ctrl.notifications, function (item) {
              if (readNotifications.indexOf(item.id) < 0) {
                markRead(item);
              }
            });
          }

          /**
           * @function
           * @name checkRead
           * @param notify_id
           * @description Check if a specific notification is read
           * @returns {boolean}
           */
          function checkRead(notify_id) {
            return (readNotifications.indexOf(notify_id) > -1);
          }

          /**
           * @function
           * @name unreadMessagesCount
           * @description Count unread messages
           * @returns {int}
           */
          function unreadMessagesCount() {
            var unreadMsg = 0;
            _.forEach($ctrl.notifications, function (item) {
              if (readNotifications.indexOf(item.id) < 0) {
                unreadMsg += 1;
              }
            });
            return unreadMsg;
          }

          /**
           *
           * @function
           * @name getReadNotifications
           * @description Get notifications from Pubnub history
           */
          function getNotifications() {
            showLoader(true);
            NotificationsService.getNotifications(channel).then(function (response) {
              response = _.map(response, function (value) {
                if (value.content) {
                  value.content = _.startsWith(value.content, 'key:reopenComment, ') ? value.content.replace('key:reopenComment, ', '') : value.content;
                  value.content = FroalaService.getRawContent(value.content);
                }
                value.context_type = value.updated_fields_string ? value.context_type + '.' + value.updated_fields_string.replace(/ /g, '_') : value.context_type;
                if (!Helper.isObjectEmpty(value.author.profile_pic)) {
                  value.author.profile_pic = value.author.profile_pic.replace('https://localhost:8080', ENV_CONFIG.TYFY_MAIN_URL);
                }
                return value;
              });
              angular.extend($ctrl.notifications, response);
              $ctrl.notifications = $filter('orderBy')($ctrl.notifications, '-time');
              $ctrl.notificationsLoaded = true;
              $ctrl.unreadMessagesLength = $ctrl.unreadMessages();
              NotificationsService.setUnreadNotifications($ctrl.unreadMessagesLength);
              showLoader(false);
            }, function () {
              showLoader(false);
            });
          }

          /**
           * @function
           * @name getReadNotifications
           * @description get read notifications
           */
          function getReadNotifications() {
            NotificationsService.getReadNotifications(read_channel).then(function (response) {
              readNotifications = response;
              $timeout(function () {
                $ctrl.unreadMessagesLength = $ctrl.unreadMessages();
                NotificationsService.setUnreadNotifications($ctrl.unreadMessagesLength);
              }, 100);
            });
          }

          /**
           * @function
           * @name redirectToView
           * @description Redirect to run/task view by id or notification view
           * @param {Object} event
           * @param {Object} subject
           * @param {Object} contextType
           */
          function redirectToView(event, subject, contextType) {
            if (event) {
              event.preventDefault();
            }
            $ctrl.isDropDownOpen = false;
            NotificationsService.redirectToView(subject, contextType);
          }
          /**
           * @function
           * @name showLoader
           * @description An helper function
           * @param {*} isShow
           */
          function showLoader(isShow) {
            isShow ? blockUI.start() : blockUI.stop();
          }

          /**
           * Un-register listener on notification read
           * @type {*|(function())}
           */
          unregisterNotificationReadListener = $rootScope.$on('NOTIFICATION:READ_MESSAGES', function (ngEvent, readData) {
            readNotifications = readData;
            $ctrl.unreadMessagesLength = $ctrl.unreadMessages();
            NotificationsService.setUnreadNotifications($ctrl.unreadMessagesLength);
          });

          /**
           * Un-register listener on new notification
           * @type {*|(function())}
           */
          unregisterNewNotificationListener = $rootScope.$on('PN_LISTENING:user_notifications', function (ngEvent, message) {
            // add message to the messages list
            proccessNotification(message);
          });

          //controller ends
        }
    });
})();

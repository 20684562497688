(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('GroupTableService', GroupTableService);

  function GroupTableService($filter) {
    var self = this;

    self.getGroupTableOptions = function () {
      return {
        columns: getGroupTableColumns(),
        dataSource: new kendo.data.DataSource({
          pageSize: 10
        }),
        noRecords: {
          template: kendo.template($('#groupListTableNoData').html())
        },
        resizable: true
      }
    }

    // guest table columns
    var getGroupTableColumns = function () {
      return [{
        field: "name",
        exportKeyMap: {
          key: "name",
          label: $filter('translate')('orgProfile.label.orgTitle')
        },
        headerTemplate: kendo.template($('#groupNameHeaderColumnTemplate').html()),
        template: kendo.template($('#groupNameColumnTemplate').html()),
        width: 320
      }, {
        field: "description",
        exportKeyMap: {
          key: "description",
          label: $filter('translate')('orgProfile.label.description')
        },
        headerTemplate: kendo.template($('#groupDescriptionHeaderColumnTemplate').html()),
        template: kendo.template($('#groupDescriptionColumnTemplate').html()),
        width: 460
      }, {
        field: "members",
        exportKeyMap: {
          key: "members",
          label: $filter('translate')('orgProfile.label.members')
        },
        template: kendo.template($('#groupMembersColumnTemplate').html()),
        title: "Members",
        width: 320
      }, {
        field: "action",
        template: kendo.template($('#groupActionColumnTemplate').html()),
        title: "Action",
        width: 180
      }];
    }
    return self;
  }

})();
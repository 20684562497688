(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('EmailLogTableService', EmailLogTableService);

  function EmailLogTableService($filter) {
    var self = this;

    self.getGuestTableOptions = function () {
      return {
        columns: getSecurityAndLogsTableColumns(),
        dataSource: new kendo.data.DataSource(),
        height: 480,
        noRecords: {
          template: kendo.template($('#emailAndLogsTableNoData').html())
        },
        resizable: true
      }
    }

    var getSecurityAndLogsTableColumns = function () {
      return [{
        field: "date",
        exportKeyMap: {
          key: "date",
          label: $filter('translate')('emailLogs.securityTable.date')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnDate').html()),
        template: kendo.template($('#emailAndLogsDateColumnTemplate').html()),
        width: 210
      }, {
        field: "to",
        exportKeyMap: {
          key: "to",
          label: $filter('translate')('emailLogs.securityTable.to')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnTo').html()),
        template: kendo.template($('#emailAndLogsToColumnTemplate').html()),
        width: 180
      }, {
        field: "subject",
        exportKeyMap: {
          key: "subject",
          label: $filter('translate')('emailLogs.securityTable.subject')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnSubject').html()),
        template: kendo.template($('#emailAndLogsSubjectColumnTemplate').html()),
        width: 440
      }, {
        field: "status",
        exportKeyMap: {
          key: "status",
          label: $filter('translate')('emailLogs.securityTable.status')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnStatus').html()),
        template: kendo.template($('#emailAndLogsStatusColumnTemplate').html()),
        width: 100
      }, {
        field: "deliveryStatusMessage",
        exportKeyMap: {
          key: "deliveryStatusMessage",
          label: $filter('translate')('emailLogs.securityTable.deliveryStatus')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnDeliveryMessageStatus').html()),
        template: kendo.template($('#emailAndLogsDeliveryMessageStatusColumnTemplate').html()),
        width: 240
      }, {
        field: "logLevel",
        exportKeyMap: {
          key: "logLevel",
          label: $filter('translate')('emailLogs.securityTable.logLevel')
        },
        headerTemplate: kendo.template($('#emailAndLogsTableHeaderColumnLogLevel').html()),
        template: kendo.template($('#emailAndLogsLogLevelColumnTemplate').html()),
        width: 100
      }, {
        name: 'detail',
        template: kendo.template($('#emailAndLogsDetailColumnTemplate').html()),
        title: 'Details',
        width: 140
      }];
    }
    return self;
  }

})();
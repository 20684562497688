(function () {
  'use strict';
  angular.module('tallyfy')
    .component('magicLink', {
      templateUrl: 'app/modules/integration/developerApi/magicLinkModal/magic-link.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, ProcessService, OrganizationsService, CompactTaskService, blockUI, TasksService, Helper, MagicLinkService) {
          var $ctrl = this, blockUI = blockUI.instances.get('entitySelectionMagicLink'),
            taskPagination = {
              page: 1,
              per_page: 20
            },
            pagination = {
              page: 1,
              per_page: 20
            };

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onChecklistSelected = onChecklistSelected;
          $ctrl.onTaskSelected = onTaskSelected;

          $ctrl.onChecklistDropdownOpenHandler = onChecklistDropdownOpenHandler;
          $ctrl.onTaskDropdownOpenHandler = onTaskDropdownOpenHandler;

          $ctrl.getNextChecklist = getNextChecklist;
          $ctrl.getNextTask = getNextTask;

          $ctrl.onClearSelectedChecklist = onClearSelectedChecklist;
          $ctrl.onClearSelectedTask = onClearSelectedTask;

          $ctrl.displayedChecklists = [];
          $ctrl.displayedTasks = [];

          $ctrl.goToNextState = goToNextState;
          $ctrl.copyLink = copyLink;

          $ctrl.stateIndex = 0;
          $ctrl.actionIndex = 0;

          function onInit() {
            $ctrl.states = MagicLinkService.getStates();
            $ctrl.organizationId = $rootScope.identity.default_organization.id;
          }

          function onChanges() { }
          function onDestroy() { }

          function goToNextState(type) {
            if (!$ctrl.stateIndex && type !== 'launchProcess') {
              getNextTask({ init: true, isCompleteTask: type === 'reOpenTask' })
                .then(function () {
                  $ctrl.selectedType = type;
                  $ctrl.stateIndex++;
                  $ctrl.createLink = getMagicLinkContent(type);
                });
            } else {
              $ctrl.selectedType = type;
              $ctrl.stateIndex++;
              $ctrl.createLink = getMagicLinkContent(type);
            }
          }

          function getMagicLinkContent(type) {
            var text, htmlText, action = MagicLinkService.getActionMagicLinkMethods()[type]({
              selectedProcess: $ctrl.selectedProcess,
              selectedTask: $ctrl.selectedTask
            });
            text = action.getLink();
            htmlText = action.getText();
            return {
              text: text,
              htmlText: htmlText
            };
          }

          function copyLink($event) {
            Helper.copyToClipboard($ctrl.createLink.text, 'magicLink', $event);
          }

          function onTaskSelected(item) {
            if (item.id) {
              blockUI.start();
              TasksService.getSingleTask({
                id: item.id,
                with: 'form_fields'
              }).then(function (res) {
                $ctrl.selectedTask = res.data;
                $ctrl.actionIndex++;
                $ctrl.createLink = getMagicLinkContent($ctrl.selectedType);
                blockUI.stop();
              }, function (err) {
                $ctrl.loading = false;
                blockUI.stop();
              });
            }
          }

          function onChecklistSelected(item) {
            $ctrl.selectedProcess = item;
            $ctrl.actionIndex++;
            $ctrl.createLink = getMagicLinkContent($ctrl.selectedType);
          }

          function onTaskDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxTaskOpen = false;
            }
          }

          function onChecklistDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxChecklistOpen = false;
            }
          }

          function onClearSelectedTask() {
            $ctrl.selectedTask = void 0;
          }

          function onClearSelectedChecklist() {
            $ctrl.selectedProcess = void 0;
          }

          function getNextTask(args) {
            var init = _.get(args, 'init', false);
            if (init) {
              $ctrl.displayedTasks = [];
              $ctrl.allTasksLoaded = false;
              taskPagination = {
                page: 1,
                per_page: 20
              };
            }
            var q = _.get(args, 'query');
            var defer = $q.defer();
            if (q) {
              var params = {
                org: $ctrl.organizationId,
                on: 'task',
                search: q,
                per_page: taskPagination.per_page,
                task: taskPagination.page
              };
              params.status = ($ctrl.selectedType === 'completeTask' || $ctrl.selectedType === 'editDeadline' || $ctrl.selectedType === 'updateFormFieldValue')
                ? 'active' : ($ctrl.selectedType === 'reOpenTask')
                  ? 'completed' : void 0;
              if ($ctrl.allTasksLoaded) {
                defer.resolve({ allItemLoaded: $ctrl.allTasksLoaded });
                return defer.promise;
              }
              OrganizationsService.globalSearch(params)
                .then(function (res) {
                  $ctrl.displayedTasks = _.uniqBy(_.concat($ctrl.displayedTasks || [], _.get(res, 'task.data', [])), 'id');
                  res.task.current_page != res.task.last_page ? taskPagination.page++ : $ctrl.allTasksLoaded = true;
                  $ctrl.onTasksLoading = false;
                  defer.resolve({ allItemLoaded: $ctrl.allTasksLoaded });
                }, function () {
                  $ctrl.onTasksLoading = false;
                  defer.reject();
                });
            } else {
              var params = {};
              if (args.isCompleteTask) {
                params = {
                  action: 'users',
                  action_id: $rootScope.identity.id,
                  id: 'completed',
                  page: taskPagination.page,
                  per_page: taskPagination.per_page
                };
              } else {
                params = {
                  page: taskPagination.page,
                  per_page: taskPagination.per_page,
                  status: 'active_visible'
                };
              }
              if ($ctrl.allTasksLoaded) {
                defer.resolve({ allItemLoaded: $ctrl.allTasksLoaded });
                return defer.promise;
              }
              blockUI.start();
              CompactTaskService.getUserTasks(params)
                .then(function (res) {
                  $ctrl.displayedTasks = _.uniqBy(_.concat($ctrl.displayedTasks || [], _.get(res, 'data', [])), 'id');
                  res.meta.pagination.total_pages != res.meta.pagination.current_page ? taskPagination.page++ : $ctrl.allTasksLoaded = true;
                  $ctrl.onTasksLoading = false;
                  blockUI.stop();
                  defer.resolve({ allItemLoaded: $ctrl.allTasksLoaded });
                }, function () {
                  blockUI.stop();
                  $ctrl.onTasksLoading = false;
                  defer.reject();
                });
            }
            return defer.promise;
          }

          function getNextChecklist(args) {
            var init = _.get(args, 'init', false);
            if (init) {
              $ctrl.displayedChecklists = [];
              $ctrl.allBlueprintsLoaded = false;
              pagination = {
                page: 1,
                per_page: 20
              };
            }
            var defer = $q.defer();
            if ($ctrl.allBlueprintsLoaded) {
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
              return defer.promise;
            }
            $ctrl.onBlueprintsLoading = true;
            var params = angular.extend({
              sort: 'title',
              with: 'tags',
              append: 'steps_count',
              q: _.get(args, 'query')
            }, pagination);
            ProcessService.filter(params).then(function (res) {
              $ctrl.displayedChecklists = _.uniqBy(_.concat($ctrl.displayedChecklists || [], res.data), 'id');
              res.meta.pagination.current_page != res.meta.pagination.total_pages ? pagination.page++ : $ctrl.allBlueprintsLoaded = true;
              $ctrl.onBlueprintsLoading = false;
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
            }, function () {
              $ctrl.onBlueprintsLoading = false;
              defer.reject();
            });
            return defer.promise;
          }
        }
    });
})();
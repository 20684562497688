/**
 * @ngdoc directive
 * @name tallyfy.activeTask
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular.module('tallyfy')
    .filter('activeTask',
      /*@ngInject*/
      function (_, Helper) {
        var activeTasks = function (value, args) {
          var isEmpty = Helper.isObjectEmpty(value);
          if (isEmpty) {
            return;
          }
          value = _.uniqBy(value, 'id');
          var activeTask = _.filter(value, function (task) {
            return _.get(args, 'showHiddenTasks', false) ? true : task.status !== 'auto-skipped';
          });
          if (args.orderBy) {
            activeTask = _.orderBy(activeTask, args.orderBy, 'asc');
          }
          return activeTask;
        };
        return activeTasks || [];
      });
})();
/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.compAccess
 * @module tallyfy.integration
 *
 * @description
 * A component to manage integration
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .component('integration', {
      templateUrl: 'app/modules/integration/integration.html',
      controller: 
      /*@ngInject */
      function (_, IntegrationService, CONFIG, ENV_CONFIG) {
        var $ctrl = this;

        /**
         * component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;

        function initialization() {
          $ctrl.integrationTypes = _.map(IntegrationService.getIntegrationType(), function (type) {
            return {
              icon: 'integration.' + type.key + '.icon',
              title: 'integration.' + type.key + '.title',
              desc: 'integration.' + type.key + '.description',
              help: 'integration.' + type.key + '.help',
              type : type.key.toLowerCase(),
              sref: 'settings.integration.' + type.key.toLowerCase(),
              class: type.class,
              authority: type.authority
            };
          });
          $ctrl.apiHost = CONFIG.API_HOST;
          $ctrl.slackAuthURL = "https://slack.com/oauth/authorize?client_id=" + ENV_CONFIG.SLACK_CLIENT_ID + "&scope=commands,bot,chat:write:user";
        }
      }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.vPaneHeader
 * @description 
 * disabled keybord events
 * @restrict 'E'
 * @element ANY 
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('vPaneHeader', vPaneHeader);

  function vPaneHeader() {
    return {
      restrict: 'E',
      priority: 1,
      link: function (scope, iElement) {
        iElement.off('keydown');
        iElement.find('input[type="text"]').bind('click', function ($event) {
          $event.stopImmediatePropagation();
        });

        scope.$on('$destroy', function () {
          iElement.find('input[type="text"]').unbind('click');
        });
      }
    };
  }

})();
/**
 * @ngdoc Service
 * @name tallyfy.filters.FiltersService
 *
 * @module tallyfy.filters
 *
 * @description
 * FiltersService
 *
 * @author kiran sompura ( gmail::kiranv.sompura@gmail.com )  
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.filters')
    .service('FiltersService', FiltersService);

  FiltersService.$inject = ['_', '$filter'];

  function FiltersService(_, $filter) {
    var self = this;
    
    /**
     * @function setFilterRecord
     * @description Set new property in filter object
     * @param {Object} Filter
     * @return {Object} Filter
     */
    self.setFilterRecord = function (filter) {
      var record = [];
      _.filter(filter, function (data) {
        data.customizable = true;
        data.type = { id: data.filter_type, label: $filter('capitalize')(data.filter_type) };
        record.push(data);
      });
      return record;
    };
    
    /**
     * @function getActiveFilterValue
     * @description Return active view in user preferences
     * @param {Object, String, Any} preferences, funKey, defaultVal
     * @return {string} Filter value OR defaultVal
     */
    self.getActiveFilterValue = function (preferences, funKey, defaultVal) {
      var getActiveFilter = _.find(preferences, {'slug': funKey});
      if (!_.isEmpty(getActiveFilter) && !_.isUndefined(getActiveFilter) && !_.isNull(getActiveFilter.metadata) && !_.isUndefined(getActiveFilter.metadata.active_view)) {
        return getActiveFilter.metadata.active_view;
      }
      return defaultVal;
    };
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.stepCreate
 * @module tallyfy.steps
 *
 * @description
 * A component to manage stepCreate
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepCreate', {
      bindings: {
        processId: '<?',
        onCreated: '&?',
        onFocused: '&?',
        stepsLength: '<?',
        isForm: '<?',
        isForceOnboardingFlow: '<?',
        steps: '<?',
        isOneOfTaskCreate: '@?',
        type: '<?',
        position: '<?',
        stage: '<?',
        isDisabled: '<?'
      },
      templateUrl: 'app/modules/steps/create/create.html',
      controller:
        /* @nginject*/
        function (_, DESCRIPTIONSIZE, Helper, $rootScope, preferencesStore, AccountService, $analytics, $timeout, $filter, DateUtils, TasksService, StepService, moment, DATEFORMAT, MilestoneService) {
          var $ctrl = this,
            blueprintStepFrameContainer;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.isValid = isValid;
          $ctrl.closeToolTip = closeToolTip;
          $ctrl.typeSelected = typeSelected;

          /**
           * public properties
           */
          $ctrl.step = {};
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;

          /**
           * public methods
           */
          $ctrl.createStep = createStep;
          $ctrl.openStepTipsModal = StepService.openStepTipsModal;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.stepTypes = StepService.getStepTypes();
            $ctrl.selectedType = _.first($ctrl.stepTypes);
            $ctrl.isValidForm = true;
            $ctrl.maxTitle = DESCRIPTIONSIZE.maxTitle;
            $ctrl.step = StepService.getStep();
            if (!$ctrl.isOneOfTaskCreate && $ctrl.isForceOnboardingFlow) {
              $ctrl.step.deadline['value'] = 7;
            }
            $ctrl.stepExample = [1, 2, 3];
            $ctrl.sendMailLists = [1, 2, 3, 4];
            $ctrl.isOpenToolTip = getRealTimeToolTipPreferences();
            $ctrl.toolTipTemplate = "app/modules/steps/create/realTimeToolTip.html";
            $timeout(function () {
              blueprintStepFrameContainer = $('.blueprint-step-type-selector');
            }, 0);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(changes) {
            if (changes.stepsLength && (changes.stepsLength.previousValue !== changes.stepsLength.currentValue)) {
              $ctrl.stepsLength = changes.stepsLength.currentValue;
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name createStep
           * @description create new step
           */
          function createStep() {
            if (!$ctrl.createStepForm.$valid) {
              return;
            }
            if ($ctrl.isOneOfTaskCreate) {
              $ctrl.onSaving = true;
              $ctrl.defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
              $ctrl.deadlineValue = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.value : 5;
              $ctrl.deadlineUnit = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.type : "days";
              TasksService.createStandaloneTask({
                title: $ctrl.taskName,
                deadline: DateUtils.toUTC(moment().format()).add($ctrl.deadlineValue, $ctrl.deadlineUnit).format(DATEFORMAT.DEFAULT),
                owners: {
                  users: [_.get($rootScope.identity, 'id')]
                },
                position: $ctrl.position,
                run_id: $ctrl.processId,
                task_type: _.get($ctrl.selectedType, 'value', 'task')
              }).then(function (response) {
                $rootScope.$emit('STANDALONE_TASK:CREATED', { task: response.data, taskOpen: true, stage: $ctrl.stage, unmapped: !($ctrl.stage && $ctrl.stage.id) });

                $ctrl.taskName = '';
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.onSaving = false;
              });
            } else {
              $ctrl.isValidForm = false;
              if ($ctrl.createStepForm.$valid) {
                $ctrl.isValidForm = true;
                $ctrl.defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
                $ctrl.step.deadline['value'] = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.value : 5;
                $ctrl.step.deadline['unit'] = $ctrl.defaultDeadline ? $ctrl.defaultDeadline.type : "days";
                if (!Helper.checkAccessAuthority())
                  return;
                var stepParams = angular.copy($ctrl.step);
                stepParams.title = $ctrl.taskName;
                stepParams.step_type = _.get($ctrl.selectedType, 'value', 'task');
                stepParams.stage_id = _.get($ctrl.stage, 'id');
                angular.extend(stepParams, {
                  checklist_id: $ctrl.processId
                });
                $ctrl.onSaving = true;
                StepService.createStep({
                  checklist_id: $ctrl.processId
                }, stepParams).then(function (response) {
                  var step = response.data;
                  if ($ctrl.stage) {
                    $ctrl.step.stage_id = $ctrl.stage.id;
                    MilestoneService.linkStepToMilestone($ctrl.processId, step.id, $ctrl.stage.id)
                      .then(function (res) {
                        trackEvent({ label: 'STEP CREATED IN BLUEPRINT', blueprintId: $ctrl.processId, stepId: step.id, stepName: step.title });
                        if (!step.hasOwnProperty('captures')) {
                          angular.extend(step, {
                            captures: {
                              data: []
                            }
                          });
                        }
                        $ctrl.onCreated({ step: step });
                        $ctrl.taskName = '';
                        $ctrl.onSaving = false;
                        Helper.showChangesSavedGrowl();
                        $ctrl.step.stage_id = res.data.id;
                        $rootScope.$emit('STEP:LINK_STAGE', { step: step, stageId: $ctrl.stage.id, newStep: true });
                      });
                  } else {
                    trackEvent({ label: 'STEP CREATED IN BLUEPRINT', blueprintId: $ctrl.processId, stepId: step.id, stepName: step.title });
                    if (!step.hasOwnProperty('captures')) {
                      angular.extend(step, {
                        captures: {
                          data: []
                        }
                      });
                    }
                    $ctrl.onCreated({ step: step });
                    $ctrl.taskName = '';
                    $ctrl.onSaving = false;
                    Helper.showChangesSavedGrowl();
                    $rootScope.$emit('STEP:LINK_STAGE', { step: step, newStep: true });
                  }
                }, function () {
                  $ctrl.onSaving = false;
                });
              } else {
                $ctrl.isValidForm = false;
              }
            }
            $ctrl.createStepForm.$setPristine();
          }

          /**
           * @function
           * @name isValid
           * @description check form validation 
           */
          function isValid() {
            $ctrl.popoverIsOpen = true;
            $ctrl.onFocused();
            if ($ctrl.createStepForm.$valid || $ctrl.onFocused) {
              $ctrl.isValidForm = true;
            } else {
              $ctrl.isValidForm = false;
            }
          }

          function closeToolTip() {
            setUserPreferences('yes');
            $ctrl.popoverIsOpen = false;
            $ctrl.isOpenToolTip = getRealTimeToolTipPreferences();
          }

          /**
           * @function
           * @name getRealTimeToolTipPreferences
           * @description Get value "create_step_real_time_tool_tip_viewed" slug from $rootScope
           * @returns {Boolean}
           */
          function getRealTimeToolTipPreferences() {
            var koFormIntroViewed = _.find($rootScope.identity.preferences, { 'slug': 'create_step_real_time_tool_tip_viewed' });
            if (_.isUndefined(koFormIntroViewed) || koFormIntroViewed.value === 'no') {
              return true;
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name setUserPreferences
           * @description Save/update preference.
           * @param { string } setValue 
           * @returns {void}
           */
          function setUserPreferences(setValue) {
            AccountService.setUserPreference({
              preference: {
                slug: 'create_step_real_time_tool_tip_viewed',
                value: setValue
              }
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
              $ctrl.isOpenToolTip = getRealTimeToolTipPreferences();
            }, function () {
            });
          }

          /**
           * @name trackEvent
           * @param {*} eventProps
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventProps) {
            var defaultProps = { category: Helper.eventTrackingCategories['blueprint'] };
            angular.extend(defaultProps, eventProps);
            $analytics.eventTrack('Click', defaultProps);
          }

          /**
           * @name typeSelected
           * @param {*} e
           * @param {Object} item
           *
           * @description
           * Task type select event
           */
          function typeSelected(e, item) {
            e.stopImmediatePropagation();
            $ctrl.selectedType = item;
            $ctrl.isOpenMobilePopOver = false;
          }
        }
    });
})();

(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.steps.factory.StepRepository
   * @module tallyfy.steps
   *
   * @description
   * Create Rest resource for steps
   *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.steps')
    .factory('StepRepository', StepRepository);

  /**
   * Constructor
   * StepRepository construct
   */
  /*@ngInject*/
  function StepRepository(BaseRepository, CONFIG, DeadlineTransformer, _, Helper) {
    var repository;
    var transformResponse = function (response, headersGetter, status) {
      var transformedResponse = {};
      if (status === 404) {
        return transformedResponse;
      }

      response = response && Helper.isJson(response) ? angular.fromJson(response) : { message: response };
      if (response.hasOwnProperty('data')) {
        transformedResponse.data = new DeadlineTransformer(response.data);
      } else {
        transformedResponse.data = new DeadlineTransformer(response);
      }
      if (response.hasOwnProperty('meta')) {
        transformedResponse.meta = response.meta;
      }

      return transformedResponse;
    };

    repository = BaseRepository('/organizations/:org/checklists/:checklist_id/steps/:id/:action/:action_id', {
      org: 'org_id'
    }, {
      getStepDeadlines: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:id/steps-deadlines',
        transformResponse: transformResponse
      },
      setBlueprintStatus: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/organizations/:org/checklists/:checklist_id/status'
      },
      getAllRoles: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/organization-roles'
      },
      createRoles: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/organization-roles'
      },
      linkRoleToStep: {
        method: 'POST',
        url: CONFIG.API_HOST + '/organizations/:org/organization-roles/add-object'
      },
      unlinkRoleToStep: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/roles-object/:id'
      },
      $updateStep: { method: 'PUT', cancellable: true },
      $updateRole: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/organizations/:org/organization-roles/:roleId'
      },
      $deleteRole: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/organizations/:org/organization-roles/:roleId'
      }
    });

    repository.updateStep = function (params, data) {
      return this.$updateStep(omitEmpty(params), data);
    };

    repository.editRole = function (params, data) {
      return repository.$updateRole(params, data);
    };

    repository.deleteRole = function (params) {
      return repository.$deleteRole(params);
    };

    function omitEmpty(obj) {
      return _.omit(obj, function (value) {
        return _.isEmpty(value);
      });
    };
    return repository;
  }
})(); 

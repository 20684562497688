/**
 * @ngdoc Config
 * @name tallyfy.integration.config.IntegrationStateConfig
 * @module tallyfy.integration
 *
 * @description
 * set route integration Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .config(IntegrationStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  IntegrationStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * IntegrationStateConfig construct 
   */
  function IntegrationStateConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/integrations', '/integrations/list')
    $urlRouterProvider.when('/integrations/', '/integrations/list')
    
    $stateProvider.state('integration', {
      parent: 'app',
      absolute: true,
      url: '/:org_id/integrations',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          templateUrl: 'app/modules/integration/integration.html',
          controller: 'IntegrationController',
          controllerAs: '$integrationCtrl'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('integration');
          $translatePartialLoader.addPart('magicLink');
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });
  }
})();
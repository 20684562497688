/**
 * @ngdoc Config
 * @name tallyfy.tags.config.tagsStateConfig
 * @module tallyfy.tags
 *
 * @description
 * set route tags Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tags')
    .config(tagsStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  tagsStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * tagsStateConfig construct 
   */
  function tagsStateConfig($stateProvider, $urlRouterProvider) {
    //Routes
  }
})();
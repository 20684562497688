(function () {
  'use strict';
  angular.module('tallyfy.editor')
    .directive('froalaView',
      /*@ngInject*/
      function ($q, FroalaService, _, $parse, $filter, $timeout, OrganizationsService, $rootScope, $compile, uiLoad, Helper, USER_STATE, CONST) {
        return {
          restrict: 'A',
          scope: false,
          link: function (scope, element, attrs) {
            var parseCode = {}, renderingTotal = 0, id = Helper.getId();
            if (scope.$parent && scope.$parent.$ctrl) {
              scope.$parent.$ctrl.froalaViewBusy = renderingTotal > 0;
            }
            element.addClass('fr-view fr-view-read-only rounded-8');
            element[0].setAttribute('id', id);
            var parseElement = function () {
              if (_.get(parseCode, 'isElementParsed')) {
                FroalaService.normalizeCodeTag(element, parseCode.rawValue, parseCode.formFieldValues);
              }
              FroalaService.removeDuplicateMedia(element);
              FroalaService.attachViewFullSize(element);
              FroalaService.showImageLightbox(element);
              FroalaService.removeFroalaBanner(element);
              FroalaService.removeInlineBreakpoint(element);
              FroalaService.wrapOldMediaElement(element);
              FroalaService.validateLinkProtocol(element);
              FroalaService.addLinkTargetFroalaFile(element);
            };

            function checkSnippets(snippetTagElements) {
              var defer = $q.defer();
              var snippetIds = _.map(snippetTagElements, function (el) {
                return el.dataset.snippetId.replace(/'/g, '');
              });
              OrganizationsService.checkSnippetExistance({}, { ids: snippetIds })
                .then(function (res) {
                  defer.resolve(res.data);
                });
              return defer.promise;
            }

            function checkBlueprints(blueprintTagElements) {
              var defer = $q.defer();
              var blueprintIds = _.map(blueprintTagElements, function (el) {
                return el.dataset.blueprintId.replace(/'/g, '');
              });
              OrganizationsService.checkBlueprintExistance({}, { ids: blueprintIds })
                .then(function (res) {
                  defer.resolve(res.data);
                });
              return defer.promise;
            }

            function parseDOMEntity() {
              parseElement();
              parseBlueprintsTemplate(scope.fieldMode);
              parseEmbedEntityLinks();
              parseKOFieldAssigneeElement();
            }

            var parseSnippetsTemplate = function (readMode) {
              if (Helper.isObjectEmpty($rootScope.organizationTemplates)) {
                $rootScope.organizationTemplates = {};
              }
              var snippetTags = element.querySelectorAll(['editor-snippet', '.insert-snippet-tag']) || [];
              if (snippetTags.length && $rootScope.userState === USER_STATE.MEMBER) {
                checkSnippets(snippetTags)
                  .then(function (res) {
                    var requests = [], requestContents = [];
                    for (var i = 0; i < snippetTags.length; i++) {
                      var snippetId = snippetTags[i].dataset.snippetId.replace(/'/g, '');
                      if (res[snippetId]) {
                        var template = $rootScope.organizationTemplates[snippetId];
                        if (template) {
                          angular.element(snippetTags[i]).addClass('rendered').html(template.template);
                        } else {
                          requests.push(
                            OrganizationsService.getTextTemplate(
                              snippetTags[i].dataset.snippetId.replace(/'/g, ''),
                              true,
                              $rootScope.userState
                            )
                          );
                          requestContents.push(snippetTags[i]);
                        }
                      } else {
                        snippetTags[i].outerHTML = '';
                      }
                    }
                    if (requests.length) {
                      $q.all(
                        _.map(requests, function (request) {
                          return request.catch(angular.noop);
                        })
                      ).then(function (res) {
                        for (var i = 0; i < requests.length; i++) {
                          if (res[i]) {
                            $rootScope.organizationTemplates[res[i].data.id] = res[i].data;
                            var renderSnippetTags = element.querySelectorAll(['editor-snippet', '.insert-snippet-tag']) || [];
                            for (var j = 0; j < renderSnippetTags.length; j++) {
                              if (res[i].data.id == renderSnippetTags[j].dataset.snippetId.replace(/'/g, '')) {
                                angular.element(renderSnippetTags[j]).addClass('rendered').html(res[i].data.template);
                                if (readMode) {
                                  angular.element(renderSnippetTags[j]).addClass('preview-mode');
                                }
                              }
                            }
                          }
                        }
                        parseDOMEntity();
                      });
                    } else {
                      parseDOMEntity();
                    }
                  });
              } else {
                parseDOMEntity();
              }
            };

            var parseBlueprintsTemplate = function (readMode) {
              var blueprintTags = element.querySelectorAll(['editor-blueprint:not(.rendered), .insert-blueprint-tag:not(.rendered)']);
              if (blueprintTags.length) {
                if ($rootScope.userState === USER_STATE.MEMBER) {
                  checkBlueprints(blueprintTags)
                    .then(function (res) {
                      for (var i = 0; i < blueprintTags.length; i++) {
                        var blueprintId = blueprintTags[i].dataset.blueprintId.replace(/'/g, '');
                        if (res[blueprintId]) {
                          var el = readMode ?
                            $compile('<froala-bp-view data-expand-all="true" data-blueprint-id="\''
                              + blueprintTags[i].dataset.blueprintId.replace(/'/g, '').replace(/'/g, '')
                              + '\'" data-preview-mode="true" data-active-process="' + scope.activeProcess + '"></froala-bp-view>')(scope) :
                            $compile('<froala-bp-view data-expand-all="true" data-blueprint-id="\''
                              + blueprintTags[i].dataset.blueprintId.replace(/'/g, '')
                              + '\'" data-blueprint-alias="\''
                              + blueprintTags[i].innerText.replace(/'/g, '\\\'').replace(/"/g, '&quot;')
                              + '\'"></froala-bp-view>')(scope);
                          angular.element(blueprintTags[i]).addClass('rendered').html(el);
                        } else {
                          blueprintTags[i].outerHTML = '';
                        }
                      }
                    });
                } else {
                  for (var i = 0; i < blueprintTags.length; i++) {
                    var el = readMode ?
                      $compile('<froala-bp-view data-expand-all="true" data-blueprint-id="\''
                        + blueprintTags[i].dataset.blueprintId.replace(/'/g, '').replace(/'/g, '')
                        + '\'" data-preview-mode="true" data-active-process="' + scope.activeProcess + '"></froala-bp-view>')(scope) :
                      $compile('<froala-bp-view data-expand-all="true" data-blueprint-id="\''
                        + blueprintTags[i].dataset.blueprintId.replace(/'/g, '')
                        + '\'" data-blueprint-alias="\''
                        + blueprintTags[i].innerText.replace(/'/g, '\\\'').replace(/"/g, '&quot;')
                        + '\'"></froala-bp-view>')(scope);
                    angular.element(blueprintTags[i]).addClass('rendered').html(el);
                  }
                }
              } else if (scope.$parent && scope.$parent.$ctrl) {
                scope.$parent.$ctrl.froalaViewBusy = renderingTotal > 0;
              }
            };

            var parseEmbedEntityLinks = function () {
              var embedEntityLinks = element.querySelectorAll(['editor-pasted-url']);
              if (embedEntityLinks.length) {
                for (var i = 0; i < embedEntityLinks.length; i++) {
                  $compile(embedEntityLinks[i])(scope);
                }
              }
            };

            var renderEditorPasteUrlVariable = function (tagElement) {
              $timeout(function () {
                var tempEl = angular.element('<div>').html(tagElement.innerHTML), editorPasteUrl = tempEl.find('editor-pasted-url');
                for (var i = 0; i < editorPasteUrl.length; i++) {
                  editorPasteUrl[i].setAttribute('contenteditable', 'false');
                  editorPasteUrl[i].classList.add('embed-tallyfy-entityLink-previewed');
                  editorPasteUrl[i].classList.add('fr-deletable');
                  var html = $compile(editorPasteUrl[i])(scope);
                  angular.element(editorPasteUrl[i]).html(html);
                }
                $timeout(function () {
                  angular.element(tagElement).html(tempEl.html());
                }, 350);
              }, 350);
            };

            var parseNotRenderedVariable = function (value) {
              if (value && value.formFields && value.originalSummary) {
                var variableTags = element.find('.insert-variable-tag:not(.rendered)'),
                  preruns = _.reduce(
                    _.filter(value.formFields || [], { type: 'Prerun' }),
                    function (acc, prerun) {
                      return _.concat(acc, prerun.capture);
                    },
                    []
                  ),
                  formFields = _.reduce(
                    _.filter(value.formFields || [], { type: 'Step' }),
                    function (acc, field) {
                      return _.concat(acc, field.capture);
                    },
                    []
                  );

                for (var i = 0; i < variableTags.length; i++) {
                  var linkedElements = angular.element(variableTags[i]).find('img, a');
                  var field = _.find(_.concat(preruns, formFields), function (field) {
                    return '{{' + field.alias + '}}' === variableTags[i].dataset.variableId;
                  });
                  if (!field) {
                    continue;
                  }
                  if (linkedElements.length && field.field_type != 'assignees_form') {
                    if (field.field_type !== 'file' && field.field_type !== 'multiselect' && field.field_type !== 'table') {
                      variableTags[i].innerHTML = $filter('linkify')(variableTags[i].innerText, '_blank');
                    } else if (field.field_type === 'file') {
                      variableTags[i].classList.add('text-u-l');
                    }
                  } else {
                    if (field.field_type === 'table') {
                      var cells = variableTags[i].getElementsByTagName('td');
                      for (var j = 0; j < cells.length; j++) {
                        cells[j].innerHTML = $filter('linkify')(cells[j].innerText, '_blank');
                      }
                    } else if (field.field_type === 'textarea') {
                      field.use_wysiwyg_editor ? renderEditorPasteUrlVariable(variableTags[i]) : variableTags[i].innerHTML = $filter('linkify')(variableTags[i].innerText, '_blank');
                    } else if (field.field_type === 'multiselect') {
                      var options = variableTags[i].getElementsByTagName('div');
                      for (var j = 0; j < options.length; j++) {
                        options[j].innerHTML = $filter('linkify')(options[j].innerText, '_blank');
                      }
                    } else if (field.field_type !== 'multiselect') {
                      if (field.field_type !== 'assignees_form') {
                        variableTags[i].innerHTML = $filter('linkify')(variableTags[i].innerText, '_blank');
                      } else {
                        var regex_guest = /[a-z0-9+-.]+[@][a-z0-9+-.]+[.][a-z0-9+-.]+/mgi, matches_guest;
                        matches_guest = $(variableTags[i]).text().match(regex_guest);
                        if (matches_guest && matches_guest.length) {
                          _.forEach(matches_guest, function (id) {
                            var guestEmail = id.substr(0).slice(0, -1),
                              str = '<span class="atwho-content guets-content fr-deletable ng-scope d-inline-block pos-rlt mr-1" contenteditable="false"><span class="atwho-dom-element"><span class="user-id" data-user-id="' + id + '"></span><span class="flex-justify atwho-avatar w-24 circle text-u-c guest-avatar-bg text-white">' + guestEmail[0] + guestEmail[1] + '</span><span class="atwho-display-text _600">' + id + '</span></span></span>';

                            variableTags[i].innerHTML = variableTags[i].innerHTML.replaceAll(id, str);
                          });
                        }
                      }
                    }
                  }
                  if (variableTags[i]) {
                    variableTags[i].classList.add('tooltipped-right');
                    if (variableTags[i].classList.contains('tooltipped-top')) {
                      variableTags[i].classList.remove('tooltipped-top');
                    }
                  }
                }
              }
            };

            var parseKOFieldAssigneeElement = function () {
              var koFieldAssignee = element.querySelectorAll(['ko-field-assignee']);
              if (koFieldAssignee.length) {
                for (var i = 0; i < koFieldAssignee.length; i++) {
                  $compile(koFieldAssignee[i])(scope);
                }
              }
            };

            var parseFields = function (value) {
              if (value && value.formFields && value.originalSummary) {
                var tempElement = $(document.createElement('div'));
                tempElement.html(value.originalSummary);
                var variableTags = tempElement.find('.insert-variable-tag');
                var insertVariableValues = element.find('.insert-variable-tag');
                for (var i = 0; i < variableTags.length; i++) {
                  insertVariableValues[i].dataset.variableId = variableTags[i].innerText;
                  var hasVariableBeenSwapped = !_.get(insertVariableValues[i], 'innerText', '').match(CONST.DOUBLE_CURLY_REGEX);
                  if (insertVariableValues[i].innerText === variableTags[i].innerText && !hasVariableBeenSwapped) {
                    insertVariableValues[i].style.display = 'none';
                  }
                }
                parseNotRenderedVariable(value);
                FroalaService.parseInsertVariableTag(element, value.formFields);
                FroalaService.parseTextareaFroalaPreviewContent(element, value.formFields);
              }
            };

            scope.$watch(attrs.editorFieldMode, function (val) {
              scope.fieldMode = val;
            });

            scope.$watch(attrs.activeProcess, function (val) {
              scope.activeProcess = val;
            });

            scope.$watch(attrs.froalaView, function (nv) {
              if (nv) {
                nv = nv.replace(/&nbsp;/g, ' ');
              }
              element.html(nv);
              $timeout(function () {
                if (scope.fieldMode) {
                  scope.element = element;
                  FroalaService.compileEditorFieldElement(scope, 'editor-form-field');
                  FroalaService.compileEditorFieldElement(scope, 'editor-variable');
                }
                if (($rootScope.userState === USER_STATE.MEMBER) || ($rootScope.userState === USER_STATE.GUEST)) {
                  parseSnippetsTemplate(scope.fieldMode);
                } else {
                  parseDOMEntity();
                }
              }, 1000);
            }, true);

            scope.$watch(attrs.process, function (process) {
              scope.process = process;
            });

            scope.$watch(attrs.prerunValues, function (prerunValues) {
              scope.prerunValues = prerunValues;
            });

            scope.$watch($parse(attrs.froalaParseCode), function (value) {
              if (_.get(value, 'isElementParsed')) {
                _.extend(parseCode, value);
                FroalaService.normalizeCodeTag(element, parseCode.rawValue, parseCode.formFieldValues);
              }
            }, true);

            scope.$watch($parse(attrs.args), function (value) {
              scope.args = value;
              if (scope.args) {
                parseFields(value);
              }
            }, true);

            $rootScope.$on('BLUEPRINT_READ_MODE:RENDERING', function () {
              renderingTotal++;
              if (scope.$parent && scope.$parent.$ctrl) {
                scope.$parent.$ctrl.froalaViewBusy = renderingTotal > 0;
              }
            });

            $rootScope.$on('BLUEPRINT_READ_MODE:RENDERED', function () {
              renderingTotal--;
              if (scope.$parent && scope.$parent.$ctrl) {
                scope.$parent.$ctrl.froalaViewBusy = renderingTotal > 0;
              }
            });
          }
        };
      });
})();

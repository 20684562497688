(function () {
  'use strict';
  angular.module('tallyfy.messages')
    .service('CommentReactionService',
      /*@ngInject*/
      function (_, $rootScope, ReactionRepository, USER_STATE) {
        var self = this;

        self.getReactionIconList = function () {
          return [
            { value: 'Like', context: "👍" },
            { value: 'Dislike', context: "👎" },
            { value: 'Party', context: "🎉" },
            { value: 'Love', context: "❤️" },
            { value: 'Smile', context: "😀" },
            { value: 'Rocket', context: "🚀" }
          ];
        };

        self.addReactionToComment = function (args, body) {
          return $rootScope.userState === USER_STATE.GUEST
            ? ReactionRepository.addGuestReactionToComment(args, body)
            : ReactionRepository.addReactionToComment(args, body);
        };

        self.removeReactionFromComment = function (args) {
          return $rootScope.userState === USER_STATE.GUEST
            ? ReactionRepository.removeGuestReactionFromComment(args)
            : ReactionRepository.removeReactionFromComment(args)
        };
      }
    );
})();
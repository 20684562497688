/**
 * @ngdoc Directive
 * 
 * @name tallyfy.hasAnyAuthority
 * 
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .directive('hasAnyAuthority', hasAnyAuthority);

  hasAnyAuthority.$inject = ['_', 'AuthPlan'];

  function hasAnyAuthority(_, AuthPlan) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };
    
    return directive;

    function linkFunc(scope, element, attrs) {
      var setHidden = function () {
        element.addClass('hide');
      };
      
      if (AuthPlan.isRestricted(attrs.hasAnyAuthority)) {
        if (!_.isEmpty(attrs.hide) && attrs.hide === "true") {
          setHidden();
        } else {
          element.bind("click", function (event) {
            event.preventDefault();
            AuthPlan.hasAnyAuthority(attrs.hasAnyAuthority, attrs.hasAnyAuthorityFeature);
          });
          element.bind("touchstart", function (event) {
            event.preventDefault();
            AuthPlan.hasAnyAuthority(attrs.hasAnyAuthority, attrs.hasAnyAuthorityFeature);
          });
          scope.$on('$destroy', function () {
            element.unbind('click touchstart');
          });
        }
      }
    }
    
  }
})();
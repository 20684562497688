/**
 * @ngdoc Filter
 * @name convertToAbsoluteLocalTZ
 * @module tallyfy
 * @description
 * Convert the date to absolute local / machine TZ. This is to fix the amAgo issues (which uses local moment to figure out moments ago etc.)
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('convertToAbsoluteLocalTZ', ['$rootScope', 'moment', '_', function ($rootScope, moment, _) {
      return function (value) {
        var timezone = _.get($rootScope, 'identity.timezone', null);
        return timezone ? moment(value).tz(timezone, true).local() : moment(value).local();
      };
    }]);
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.support.factory.SupportRepository
   * @module tallyfy.support
   *
   * @description
   * Create Rest resource for support
   *
   * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
   *
   * */
  angular
    .module('tallyfy.support')
    .factory('SupportRepository', SupportRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  SupportRepository.$inject = ['BaseRepository', 'CONFIG'];

  /**
   * Constructor
   * SupportRepository construct
   */

  function SupportRepository(BaseRepository, CONFIG) {
    return BaseRepository('/support/:type/:action/:sub_action/:sub_action_id/:flag', {}, {
      liveSupport: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/organizations/:org'
      },
      syncSubscription: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/support/organizations/:org/sync-subscription'
      },
      getDomainSupport: {
        method: 'GET',
        url: CONFIG.API_HOST + '/organizations/:org/domains'
      },
      createDomainSupport: {
        method: 'POST',
        url: CONFIG.API_HOST + '/support/organizations/:org/domains'
      },
      updateDomainSupport: {
        method: 'PUT',
        url: CONFIG.API_HOST + '/support/organizations/:org/domains/:id'
      },
      deleteDomainSupport: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/support/organizations/:org/domains/:id'
      },
      deleteUser: {
        method: 'DELETE',
        url: CONFIG.API_HOST + '/support/users/:id/delete'
      }
    });
  }
})(); 

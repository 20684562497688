(function () {
  'use strict';
  angular.module('tallyfy')
    .service('MagicLinkService',
      /*@ngInject*/
      function (_, $rootScope, $filter, moment, RunsService, ENV_CONFIG, DATEFORMAT, DESCRIPTIONSIZE) {
        var self = this;

        self.getStates = function () {
          return [
            'question',
            {
              createOotTask: [
                'magicLink'
              ],
              completeTask: [
                'taskSelection',
                'magicLink'
              ],
              reOpenTask: [
                'taskSelection',
                'magicLink'
              ],
              writeAComment: [
                'taskSelection',
                'magicLink'
              ],
              editDeadline: [
                'taskSelection',
                'magicLink'
              ],
              updateFormFieldValue: [
                'taskSelection',
                'magicLink'
              ],
              launchProcess: [
                'processSelection',
                'magicLink'
              ]
            }
          ];
        }

        self.getActionMagicLinkMethods = function () {
          return {
            createOotTask: function (args) {
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=createOotTask&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('default_task_name=Type in your task title') + '</span>';
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=createOotTask&default_task_name=Type in your task title';
                }
              }
            },
            completeTask: function (args) {
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=completeTask&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('task_id=' + args.selectedTask.id) + '</span>' : void 0;
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=completeTask&task_id=' + args.selectedTask.id : void 0;
                }
              }
            },
            reOpenTask: function (args) {
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=reOpenTask&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('task_id=' + args.selectedTask.id) + '</span>' : void 0;
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=reOpenTask&task_id=' + args.selectedTask.id : void 0;
                }
              }
            },
            writeAComment: function (args) {
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=writeAComment&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('task_id=' + args.selectedTask.id) + '</span>'
                    + '&<span class="magic-link-meta-html meta-default-comment pt-1 pb-1">' + encodeURI('comment=Default Comment') + '</span>' : void 0;
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=writeAComment&task_id=' + args.selectedTask.id + '&comment=Default comment' : void 0;
                }
              }
            },
            editDeadline: function (args) {
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=editDeadline&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('task_id=' + args.selectedTask.id) + '</span>'
                    + '&<span class="magic-link-meta-html meta-default-deadline pt-1 pb-1">' + encodeURI('deadline=' + moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')) + '</span>' : void 0;
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return args.selectedTask ? ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=editDeadline&task_id=' + args.selectedTask.id + '&deadline=' + moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : void 0;
                }
              }
            },
            updateFormFieldValue: function (args) {
              var taskdata = {};
              if (args.selectedTask) {
                var formFields = args.selectedTask.form_fields.data;
                for (var i = 0; i < formFields.length; i++) {
                  taskdata[formFields[i].id] = null;
                }
              }
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/magic?action=updateFormFieldValue&<span class="magic-link-meta-html meta-default-task-name pt-1 pb-1">' + encodeURI('task_id=' + args.selectedTask.id) + '</span>'
                    + '&<span class="magic-link-meta-html meta-form-fields pt-1 pb-1">' + encodeURI('formFields=' + encodeURI(JSON.stringify(taskdata))) + '</span>';
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  return ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/magic?action=updateFormFieldValue&task_id=' + args.selectedTask.id + '&form_fields=' + encodeURI(JSON.stringify(taskdata));
                }
              }
            },
            launchProcess: function (args) {
              if(!args.selectedProcess) {
                args.selectedProcess = {};
              }
              return {
                getText: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  var URIParams = '<span class="magic-link-meta-html meta-default-run-name pt-1 pb-1">default_run_name='
                    + encodeURI($filter('defaultTitle')(args.selectedProcess.title, {
                      limit: DESCRIPTIONSIZE.maxProcessTitleLength
                    })) + '</span>&<span class="magic-link-meta-html meta-ko-fields pt-1 pb-1">ko_fields='
                    + encodeURI(JSON.stringify(RunsService.getPrerunValues(args.selectedProcess.prerun))) + '</span>&launchprocess=true';
                  return ENV_CONFIG.TYFY_MAIN_URL + '/<span class="magic-link-meta-html meta-organization pt-1 pb-1">' + orgId + '</span>'
                    + '/process/<span class="magic-link-meta-html meta-process-id pt-1 pb-1">' + args.selectedProcess.id
                    + '</span>/create?' + URIParams + '</span>';
                },
                getLink: function () {
                  var orgId = $rootScope.identity.default_organization.id;
                  var params = 'default_run_name='
                    + $filter('defaultTitle')(args.selectedProcess.title, {
                      limit: DESCRIPTIONSIZE.maxProcessTitleLength
                    }) + '&ko_fields=' + encodeURI(JSON.stringify(RunsService.getPrerunValues(args.selectedProcess.prerun))) + '&launchprocess=true';
                  return ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/process/' + args.selectedProcess.id + '/create?' + params;
                }
              }
            }
          }
        }

        return self;
      })
})();
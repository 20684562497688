/**
 * @ngdoc Component
 * @name fieldGuidanceContent
 *
 * @module tallyfy
 *
 * @description
 * Field Guidance Content (Shared)
 *
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('fieldGuidanceContent', {
      templateUrl: 'app/modules/steps/capture/fields/shared/components/field-guidance-content.component.html',
      bindings: {
        text: '<',
        translateEnable: '<?'
      },
      controller:
      /*@ngInject*/
        function (TranslationService) {
          var $ctrl = this;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @ngdoc method
           *
           * @name onInit
           * @returns void
           *
           * @description
           * initalization component
           */
          function onInit() {            
            if($ctrl.translateEnable) {
              $ctrl.translateTo = TranslationService.getMyContentLanguage();
            }
          }

          /**
           * @ngdoc method
           *
           * @name onDestroy
           * @returns void
           *
           * @description
           * on destroy component
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           *
           * @name onChanges
           * @returns void
           *
           * @description
           * on component get changes
           */
          function onChanges() { }
        }
    });
})();

(function () {
	'use strict';
	angular.module('tallyfy')
		.component('accountDisabled', {
			bindings: {},
			templateUrl: 'app/modules/error/account-disabled/account-disabled.component.html',
			controller:
				/*@ngInject*/
				function ($stateParams, Helper, OrganizationsService) {
					var $ctrl = this;

					$ctrl.$onInit = onInit;
					$ctrl.$onChanges = onChanges;
					$ctrl.$onDestroy = onDestroy;

					$ctrl.createNewOrg = Helper.createNewOrg;
                                        $ctrl.dateFormat = OrganizationsService.getDateFormat();

					function onInit() {
						$ctrl.stateParams = $stateParams;
					}

					function onChanges() { }

					function onDestroy() { }
				}
		});
})();
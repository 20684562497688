(function () {
  'use strict';
  angular.module('tallyfy')
    .service('TagsTableService', TagsTableService);

  function TagsTableService(_, $filter) {
    var self = this;
    self.getTagsTableOptions = function () {
      return {
        columns: [{
          field: "title",
          exportKeyMap: {
            key: "title",
            label: $filter('translate')('global.tag.label.tagTitle')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnTitle').html()),
          width: 360
        }, {
          field: "color",
          exportKeyMap: {
            key: "color",
            label: $filter('translate')('global.tag.label.tagColor')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnColor').html()),
          width: 160
        }, {
          field: "activeProcesses",
          exportKeyMap: {
            key: "statistics.data.active_process",
            label: $filter('translate')('global.tag.label.activeProcesses')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnActiveProcess').html()),
          width: 180
        }, {
          field: "activeBlueprints",
          exportKeyMap: {
            key: "statistics.data.active_template",
            label: $filter('translate')('global.tag.label.activeTemplates')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnActiveBlueprint').html()),
          width: 180
        }, {
          field: "archivedProcesses",
          exportKeyMap: {
            key: "statistics.data.archived_process",
            label: $filter('translate')('global.tag.label.archiveProcesses')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnArchiveProcess').html()),
          title: $filter('translate')('global.tag.label.archiveProcesses'),
          width: 180
        }, {
          field: "archivedBlueprints",
          exportKeyMap: {
            key: "statistics.data.archived_template",
            label: $filter('translate')('global.tag.label.archiveTemplates')
          },
          headerTemplate: kendo.template($('#tagsTableHeaderColumnArchiveBlueprint').html()),
          width: 180
        }, {
          field: "action",
          headerTemplate: kendo.template($('#tagsTableHeaderColumnAction').html()),
          width: 20
        }],
        dataSource: new kendo.data.DataSource(),
        noRecords: {
          template: kendo.template($('#tagsTableNoData').html())
        },
        rowTemplate: kendo.template($('#tagsTableRow').html()),
        altRowTemplate: kendo.template($('#tagsTableAltRow').html()),
        resizable: true
      }
    }
    return self;
  }
})();
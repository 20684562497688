(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorPastedUrl', {
      templateUrl: 'app/components/documentPaneEditor/editorPastedUrl/editor-pasted-url.component.html',
      bindings: {
        blueprintId: '<',
        pastedUrl: '<',
        taskId: '<',
        processId: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $timeout, $element, Helper, ProcessService, RunsService, TasksService, $document) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.getBlueprint = getBlueprint;
          $ctrl.getTask = getTask;
          $ctrl.getProcess = getProcess;
          $ctrl.gotoLink = gotoLink;
          $ctrl.blueprintNotFound = false;
          $ctrl.taskNotFound = false;
          $ctrl.processNotFound = false;

          function onInit() {
            if ($ctrl.blueprintId) {
              $ctrl.getBlueprint();  
            } else if ($ctrl.taskId) {
              $ctrl.getTask();
            } else if ($ctrl.processId) {
              $ctrl.getProcess();
            }
          }

          function getBlueprint() {
            ProcessService.get({
              id: this.blueprintId,
              skipNotFound: true
            })
            .then(function (res) {
              $ctrl.data = res.data;
            }, function () {
              $ctrl.blueprintNotFound = true;
            });
          }

          function getTask() {
            TasksService.getTaskById({ 
              task_id: $ctrl.taskId,
              skipNotFound: true
            }).then(function (res) {
              $ctrl.data = res.data;
              $ctrl.deadlineClass = TasksService.getClassUsingDate(res.data.deadline);
            }, function() {
              $ctrl.taskNotFound = true;
            });
          }

          function getProcess() {
            RunsService.get({
              id: $ctrl.processId,
              skipNotFound: true
            }).then(function (res) {
              $ctrl.data = res.data;
            }, function() {
              $ctrl.processNotFound = true;
            });
          }

          function gotoLink() {
            var tempLink = $document[0].createElement('a');
            tempLink.href = $ctrl.pastedUrl;
            tempLink.target = "_blank";
            $document[0].body.appendChild(tempLink);
            tempLink.click();
            $document[0].body.removeChild(tempLink);
          }
        }
    });
})();
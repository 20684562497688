/**
 * @ngdoc Config
 * @name tallyfy.support.config.SupportStateConfig
 * @module tallyfy.support
 *
 * @description
 * set route Support Module
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(SupportStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  SupportStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * SupportStateConfig construct
   */
  function SupportStateConfig($stateProvider, $urlRouterProvider) {
    $stateProvider.state('support', {
      parent: 'app',
      absolute: true,
      url: '/support',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          templateUrl: 'app/modules/support/support.html'
        }
      },
      resolve: {
        authorize: ['Auth', '$state',
          function (Auth, $state) {
            if (Auth.authorize()) {
              Auth.getIdentity().then(function (identity) {
                if (!identity.is_support) {
                  $state.go('home');
                }
              });
            }
            return true;
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('organizations');
          $translatePartialLoader.addPart('support');
          return $translate.refresh();
        }]
      }
    });
  }
})();

(function () {
  'use strict';

  angular
    .module('tallyfy.securityAndLog')
    .factory('SecurityAndLogRepository', securityAndLogsRepository);

  /*@ngInject*/
  function securityAndLogsRepository(BaseRepository) {
    var repository = BaseRepository('/functions/email-logs/:action');

    repository.getEmailContent = function (args) {
      var params = {
        action: 'storage'
      };
      if (args) {
        angular.extend(params, args);
      }
      return repository.get(params);
    }
    
    repository.getLastEmailStatus = function (args) {
      var params = {
        action: 'last-email-status'
      };
      if (args) {
        angular.extend(params, args);
      }
      return repository.get(params);
    }

    return repository;
  }
})();
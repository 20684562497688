(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.organizations.access.roles.service.RolesService
   * @module tallyfy.organizations.access.roles
   *
   * @description
   * RolesService manages Roles module's logic and CURD
   * This is repposible for business logic, common logic 
   * Module controller will intract with service only
   * 
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.organizations.access.roles')
    .service('RolesService', RolesService);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  RolesService.$inject = ['RolesRepository', 'CONFIG', '_'];
  /**
   * Constructor
   * RolesService construct 
   */
  function RolesService(RolesRepository, CONFIG, _) {
    /**
     * store RolesService context in self
     * JavaScript has block level context So it can be changed in nested 
     * function
     */
    var self = this,
      PAGER = {
        per_page: 20,
        current_page: 1
      };
    angular.extend(this, RolesRepository);
    /**
     * lets keen everything in the context of RolesService 
     * by exposing properties, methods to 'this'
     */
    self.getPager = function () {
      return {
        total: 0,
        per_page: 20,
        current_page: 1,
        onPageChanged: angular.noop
      };
    };
    self.getSearchable = function () {
      return {
        form: {},
        searchText: '',
        reset: angular.noop
      };
    };

    self.all = function (params) {
      var inputs = getRequestParams(params);
      var requestParams = {
        per_page: PAGER.per_page,
        page: PAGER.current_page
      };
      angular.extend(requestParams, inputs);
      return RolesRepository.all(requestParams);
    };

    function getRequestParams(params) {
      var _params;
      if (!params) return {};
      _params = angular.copy(params);

      if (_.isObject(_params)) {
        for (var key in _params) {
          if (_params.hasOwnProperty(key) && typeof _params[key] === 'function') {
            delete _params[key];

          }
          if (key === 'current_page') {
            _params.page = _params.current_page;
            delete _params[key];
          }
        }

        return _params;
      } else {
        throw new Error('Roles Service: Params are not valid');
      }
    }
  }
})(); 

(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .factory('translationStorageProvider', translationStorageProvider);
  /*@ngInject*/
  function translationStorageProvider($cookies, $log, TFY_LOCALE) {
    return {
      get: get,
      put: put
    };

    function get(name) {
      var LANGUAGES = TFY_LOCALE.locales;
      if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
        $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "en"');
        $cookies.putObject(name, 'en');
      }
      return $cookies.getObject(name);
    }

    function put(name, value) {
      $cookies.putObject(name, value);
    }
  }
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.factory.BaseRepository
   * @module tallyfy
   *
   * @description
   * BaseRepository
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy')
    .factory('BaseRepository', BaseRepository);

  /*@ngInject*/

  /**
   * Constructor
   * BaseRepository construct 
   */
  function BaseRepository($resource, _, CONFIG) {

    /**
     * Check whether an object is a number.
     *
     * @param  {Object} object - Object to check numericallity on.
     * @return {Boolean} True if number, false otherwise.
     */
    var isNumeric = function (object) {
      return !isNaN(parseFloat(object)) && isFinite(object);
    };

    /**
     * Generate options based on arguments passed.
     * If the object passed is :
     *    - An object : Use it directly.
     *    - Something else : Throw an error.
     *
     * @param  {Object} args - Javascript object or string as the name of the resource (singular).
     * @return {Object} Options to pass to $resource.
     */
    var sanitizeOptions = function (resourceUri) {
      var options = {
        url: ''
      };
      if (typeof resourceUri === 'string' && (resourceUri.indexOf("/") >= 0)) {
        options.url = resourceUri;
        return options;
      } else if (resourceUri.indexOf("/") < 0 && typeof resourceUri === 'string') {
        options.url = '/:org/' + resourceUri + '/:id/:action/:sub_action';
        return options;
      } else {
        throw new Error('resourceUri is not a valid options');
      }
    };

    /**
     * BaseResource core definition.
     */
    function Resource(apiUrl, params, methods) {
      var options = sanitizeOptions(apiUrl);
      options.params = params || { id: '@id', org: '@org' };
      options.methods = methods || {};



      /**
       * Transform data after querying the server.
       * If the response contains an object (instead of a query) with the resource namespace in plural :
       * 
       * new BaseResource() => Check for the key users
       * 
       * then attach to each object the Resource object. This is a particular case
       * mostly used in pagination scenario.
       *
       * @param {Object} data - Data to send.
       * @return {String} Stringify data.
       */
      var transformResponse = function (response) {
        var transformedResponse = {};
        response = response ? angular.fromJson(response) : {};

        if (response.hasOwnProperty('data')) {
          transformedResponse.data = response.data;
        } else {
          transformedResponse.data = response;
        }
        if (response.hasOwnProperty('meta')) {
          transformedResponse.meta = response.meta;
        }

        return transformedResponse;
      };

      var defaults = {
        $browse: { method: 'GET', transformResponse: transformResponse },
        $all: { method: 'GET', cancellable: true, transformResponse: transformResponse },
        $get: { method: 'GET', transformResponse: transformResponse },
        $file: { method: 'GET', responseType: 'arraybuffer', transformResponse: function (response, header) { return { data: response, contentType: header('Content-Type') }; } },
        $create: { method: 'POST', cancellable: true },
        $update: { method: 'PUT' },
        $destroy: { method: 'DELETE' }
      };

      angular.extend(defaults, options.methods);
      var resource = $resource(CONFIG.API_HOST + options.url, options.params, defaults);

      /**
       * Get an entire collection of objects.
       *
       * @param  {Object} args - $resource.query arguments.
       * @return {Promise} Promise
       */
      resource.all = function (args) {
        var options = args || {};
        return this.$all(omitEmpty(options)).$promise;
      };

      resource.filter = function (args) {
        var options = args || {};
        return this.$all(omitEmpty(options));
      };
      /**
       * Get an entire collection of objects.
       * Since a search is often returning pagination type of data,
       * the collection of object will be wrapped under a key within that response.
       * See transformResponse for more information about that case.
       *
       * @param  {Object} args - $resource.query arguments.
       * @return {Promise} Promise
       */
      resource.search = function (args) {
        var options = args || {};
        return this.$browse(omitEmpty(options)).$promise;
      };

      /**
       * get a specific object.
       *
       * @param  {Object|Integer} args - $resource.get arguments, or { id: args } if numeric.
       * @param  {Function} callback - $resource.get callback function if any.
       * @return {Promise} Promise
       */
      resource.get = function (args, callback) {
        var options = isNumeric(args) ? { id: args } : omitEmpty(args);
        return this.$get(options, callback).$promise;
      };

      /**
       * $resource's $create method override.
       * Allow to use $create in order to create  a resource based on it's id.
       *
       * @return {Promise} Promise
       */
      resource.create = function (params, data) {
        return this.$create(omitEmpty(params), data).$promise;
      };

      /**
       * $resource's $update method override.
       * Allow to use $update in order to update a resource based on it's id.
       *
       * @return {Promise} Promise
       */
      resource.update = function (params, data) {
        return this.$update(omitEmpty(params), data).$promise;
      };

      /**
       * Delete instance object.
       *
       * @return {Promise} Promise
       */
      resource.delete = function (args) {
        var options = isNumeric(args) ? { id: args } : args;
        return this.$destroy(options).$promise;
      };

      /**
       * Get file objects.
       *
       * @param  {Object} args - $resource.query arguments.
       * @return {Promise} Promise
       */
      resource.file = function (args, callback) {
        var options = isNumeric(args) ? { id: args } : omitEmpty(args);
        return this.$file(options, callback).$promise;
      };

      function omitEmpty(obj) {
        return _.omit(obj, function (value, key, object) {
          return _.isEmpty(value);
        });
      }

      return resource;
    }
    return Resource;
  }
})();


/**
 * @ngdoc Component
 * @name tallyfy.advancedSettingsOotModal
 * @module tallyfy
 *
 * @description
 * modal for advanced settings for OOT
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('advancedSettingsOotModal', {
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      templateUrl: 'app/components/advanced-oot-settings/modal/advanced-settings-oot-modal.component.html',
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this, oldTask;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onCancelClick = onCancelClick;
          $ctrl.onSaveClick = onSaveClick;

          function onInit() {
            oldTask = angular.copy($ctrl.resolve.task);
          }
          function onChanges() { }
          function onDestroy() { }

          function onCancelClick() {
            angular.extend($ctrl.resolve.task, oldTask);
            $ctrl.dismiss({ $value: 'cancel' });
          }
          function onSaveClick() {
            $ctrl.close({ $value: $ctrl.resolve.task });
          }
        }
    })
})();
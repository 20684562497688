(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicLibraryHeader', {
      templateUrl: 'app/components/public/library/header/library-header.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $state, $localStorage, $sessionStorage, $uibModal, ProcessRepository, Helper) {
          var $ctrl = this, stateNameWatcher, publicBlueprintSelected, publicRestricted;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onShareBlueprintClick = onShareBlueprintClick;
          $ctrl.customizeBlueprint = customizeBlueprint;
          $ctrl.getPublicPageTitle = getPublicPageTitle;

          function onInit() { }

          function onChanges() { }

          function onDestroy() {
            stateNameWatcher();
            publicBlueprintSelected();
            publicRestricted();
          }

          function onShareBlueprintClick($event) {
            Helper.copyToClipboard(window.location.href, 'publicLibraryLinkCopied', $event);
          }

          function customizeBlueprint() {
            var token = $localStorage.token || $sessionStorage.token;
            if (token && $rootScope.identity) {
              if ($rootScope.identity.totalOrgOfCurrentUser === 1) {
                customizeThis($rootScope.identity.organizations);
              } else {
                $uibModal.open({
                  component: 'organizationListModal',
                  windowClass: 'public-sign-up-modal',
                  backdrop: 'static'
                }).result.then(function (organization) {
                  customizeThis(organization);
                });
              }
            } else {
              $uibModal.open({
                component: 'signupModal',
                windowClass: 'public-sign-up-modal',
                backdrop: 'static',
                resolve: {
                  org_id: function () {
                    return $ctrl.publicBlueprint.organization_id;
                  },
                  bp_id: function () {
                    return $ctrl.publicBlueprint.id;
                  }
                }
              });
            }
          }

          function customizeThis(organization) {
            $ctrl.onSaving = true;
            ProcessRepository
              .customizeTemplate({
                org: _.get(organization, 'id'),
                title: "",
                id: $ctrl.publicBlueprint.id,
                tenant: $ctrl.publicBlueprint.organization_id
              }).then(function (res) {
                if (res) {
                  window.open($state.href('process.edit', {
                    org_id: _.get(organization, 'id'),
                    slug: res.data.id,
                    view: 'edit',
                    importPublicBlueprint: true
                  }, { absolute: true }), '_self');
                  $ctrl.onSaving = false;
                }
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function getPublicPageTitle() {
            return ($state.current.name === 'public.library.organization')
              ? _.get($ctrl.publicOrganization, 'name', '') + " - Public Library"
              : ($state.current.name === 'public.library.combo')
                ? $filter('translate')('public.header.library')
                : $filter('translate')('public.header.' + $state.params.category);
          }

          publicBlueprintSelected = $rootScope.$on('PUBLIC_LIBRARY_DETAIL:BLUEPRINT_SELECTED', function (e, args) {
            if (args.publicLibrary) {
              $ctrl.publicBlueprint = args.publicLibrary;
              $ctrl.publicTags = _.concat($ctrl.publicBlueprint.topic_tags, $ctrl.publicBlueprint.industry_tags);
              $ctrl.logoClass = $ctrl.publicBlueprint.type;
            }
          });

          stateNameWatcher = $rootScope.$watch('toState', function (value) {
            if (value) {
              $ctrl.publicUserRouteState = value.name;
              $ctrl.publicOrganization = $rootScope.publicOrganization;
              if ($ctrl.publicUserRouteState !== 'public.library.organization.detail' && $ctrl.publicUserRouteState !== 'public.library.combo.detail'
                && $ctrl.publicUserRouteState !== 'public.library.combo.kickOff' && $ctrl.publicUserRouteState !== 'public.library.organization.kickOff') {
                $ctrl.logoClass = void 0;
              }
              if ($ctrl.publicUserRouteState === 'public.library.combo.kickOff' || $ctrl.publicUserRouteState === 'public.library.organization.kickOff') {
                $ctrl.headerTemplate = 'public-kick-off-header.template.html';
              } else {
                $ctrl.headerTemplate = 'public-library-header.template.html';
              }
            }
          });

          publicRestricted = $rootScope.$on('PUBLIC:ACCESS_RESTRICTED', function () {
            if ($ctrl.publicUserState === 'public.library.organization.detail' || $ctrl.publicUserState === 'public.library.combo.detail'
              || $ctrl.publicUserState === 'public.library.organization.kickOff' || $ctrl.publicUserState === 'public.library.combo.kickOff') {
              customizeBlueprint();
            }
          });
        }
    });
})();

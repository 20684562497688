(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicTags', {
      templateUrl: 'app/components/public/pages/tags/public-tags.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $state, $stateParams, PublicTagService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.tagFilter = tagFilter;
          $ctrl.onTagSelected = onTagSelected;
          $ctrl.getAllTags = getAllTags;

          $ctrl.alphabets = [
            "a", "b", "c", "d", "e", "f", "g", "h", "i",
            "j", "k", "l", "m", "n", "o", "p", "q", "r",
            "s", "t", "u", "v", "w", "x", "y", "z", "1",
            "2", "3", "4", "5", "6", "7", "8", "9", "0"
          ]

          $ctrl.tagsData = [];

          function onInit() {
            $ctrl.publicOrganization = $rootScope.publicOrganization;
            $ctrl.searchPlaceholder = $filter('translate')('public.input.placeholder.' + $stateParams.category);
            getAllTags();
          }

          function onChanges() { }
          function onDestroy() { }

          function tagFilter(args) {
            var result = _.filter(args.tagItems, function (tag) {
              return tag.title && args.title && tag.title.toLowerCase().startsWith(args.title.toLowerCase());
            });
            return result;
          }

          function getAllTags(q) {
            $ctrl.isBusy = true;
            var params = {
              action: $stateParams.category,
              q: q
            };
            if (_.get($ctrl.publicOrganization, 'id')) {
              params.org = $ctrl.publicOrganization.id;
              params.all_tags = false;
            } else {
              params.is_featured = true;
              if ($stateParams.category === 'topic') {
                params.all_tags = false;
              }
            }
            PublicTagService.getPublicTags(params)
              .then(function (res) {
                $ctrl.tagsData = res.data;
                $ctrl.isBusy = false;
              }).catch(function (err) {
                $ctrl.isBusy = false;
              });
          }

          function onTagSelected(tag) {
            var params = {};
            if (_.get($ctrl.publicOrganization, 'id')) {
              params.orgId = $ctrl.publicOrganization.id;
            }
            params[tag.type] = tag.title;
            $ctrl.publicOrganization ? $state.go('public.library.organization', params) : $state.go('public.library.combo', params);
          }
        }
    });
})();
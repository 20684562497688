/**
 * @ngdoc overview
 * @name tallyfy.filter
 *
 * @module tallyfy.filter
 *
 * @description
 * manage executable instances of filter
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy.filters', []);
})();
/**
 * @ngdoc Directive
 * @name tallyfy.defaultValue
 * @restrict 'A'
 * @element ANY 
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('noDirty', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelCtrl) {
          // override the $setDirty method on ngModelController
          ngModelCtrl.$setDirty = angular.noop;
        }
      }
    });

})();

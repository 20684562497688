/**
 * @ngdoc Directive
 * @name tallyfy.pulseMultiselect
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('pulseMultiselect', pulseMultiselect);

  /*@ngInject*/
  function pulseMultiselect($timeout) {
    return {
      restrict: "A",
      link: function ($scope, $element) {
        var element, inputs;

        var elementTimeout = $timeout(function () {
          init();

          /**
           * @name init
           * @returns void
           * 
           * @description
           * initialize method
           */
          function init() {
            element = $element[0];
            inputs = element.getElementsByTagName('input');
          }

          /**
           * @name onInputClick
           * @param {*} e 
           * 
           * @description
           * on element get click event handler
           */
          var onInputClick = function (e) {
            e.stopPropagation();
            element.classList.add('pulse');
            element.classList.remove('i-pulse');
          }

          /**
           * @name onInputFocus
           * @param {*} e 
           * 
           * @description
           * on element get focused event handler
           */
          var onInputFocus = function () {
            element.classList.add('i-pulse');
            element.classList.remove('pulse');
          }

          /**
           * @name onInputBlur
           * @param {*} e 
           * 
           * @description
           * on element get blur event handler
           */
          var onInputBlur = function () {
            element.classList.remove('i-pulse');
            element.classList.remove('pulse');
          }

          /**
           * @name onInputKeyUp
           * @param {*} e 
           * 
           * @description
           * on element get keyup event handler
           */
          var onInputKeyUp = function (e) {
            if (e.keyCode === 32) {
              element.classList.add('pulse');
              element.classList.remove('i-pulse');
            }
          }

          var onInputKeyDown = function (e) {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }

          /**
           * attach event to all input elements
           */
          for (var i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', onInputClick);
            inputs[i].addEventListener('focus', onInputFocus);
            inputs[i].addEventListener('blur', onInputBlur);
            inputs[i].addEventListener('keyup', onInputKeyUp);
            inputs[i].addEventListener('keydown', onInputKeyDown);
          }

          /**
           * clear/remove all event and timeout
           */
          $scope.$on('$destroy', function () {
            for (var i = 0; i < inputs.length; i++) {
              inputs[i].removeEventListener('click', onInputClick);
              inputs[i].removeEventListener('focus', onInputFocus);
              inputs[i].removeEventListener('blur', onInputBlur);
              inputs[i].removeEventListener('keyup', onInputKeyUp);
              inputs[i].removeEventListener('keydown', onInputKeyDown);
            }
            $timeout.cancel(elementTimeout);
          });
        });
      }
    }
  }
})();
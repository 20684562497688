/**
 * @ngdoc Config
 * @name tallyfy.accountLoginStateConfig
 * @module tallyfy
 *
 * @description
 * set route configuration for default layout of application
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .config(AuthHandlerStateConfig);

  /**
   * inject dependecies 
   */
  AuthHandlerStateConfig.$inject = ['$stateProvider'];
  /**
   * AuthHandlerStateConfig
   * AuthHandlerStateConfig construct 
   */
  function AuthHandlerStateConfig($stateProvider) {
    $stateProvider.state('authHandler', {
      url: '/auth/handler?access_token&expires_in&token_type&redirect_url',
      parent: 'account',
      data: {
        authorities: []
      },
      views: {
        '': {
          component: 'authHandler'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('signup');
          return $translate.refresh();
        }]
      }
    });
  }

})();
/**
 * @ngdoc Config
 * @name tallyfy.processTrigger.Component.processTrigger
 * @module tallyfy.processTrigger
 *
 * @description
 * A component to manage process triggers
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('processTrigger', {
      templateUrl: 'app/components/compact/components/stepList/processTrigger/process-trigger.component.html',
      bindings: {
        process: '<',
        isEmptyBlueprint: '<',
        selectedId: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $sce, $filter, StepService) {
          var $ctrl = this,
          inboundEmailUnavailableHandler,
          unregisterProcessTriggerHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public methods
          $ctrl.onProcessTrigger = onProcessTrigger;

          $ctrl.mainTabsArr = [
            {
              id: 'ko-step',
              translationVal: 'kickOffForm'
            },
            {
              id: 'others',
              translationVal: 'others'
            }
          ]

          $ctrl.tabsArr = [
            {
              id: 'middleware',
              translationVal: 'middleware'
            },
            {
              id: 'recurringSchedule',
              translationVal: 'recurringSchedule'
            },
            {
              id: 'inboundEmail',
              translationVal: 'inboundEmail'
            },
            {
              id: 'inboundWebhook',
              translationVal: 'inboundWebhook'
            },
            {
              id: 'magicLink',
              translationVal: 'magicLink'
            },
            {
              id: 'viaAPI',
              translationVal: 'viaAPI'
            }
          ];
          
          $ctrl.onSelectedTab = onSelectedTab;

          function onInit() {
            if ($ctrl.selectedId) {
              $ctrl.onSelectedTab($ctrl.selectedId);
            }
            $ctrl.checkKOFRequired = _.filter($ctrl.process.prerun, function (step) {
              return step.required === true;
            });
            $rootScope.$emit('INBOUND_EMAIL_UNAVAILABLE:UPDATE', {
              status: $ctrl.checkKOFRequired.length >= 1
            });
            $ctrl.triggersTooltip = $sce.trustAsHtml($filter('translate')('compact.components.processTrigger.processTriggerTooltip'));
          }

          function onChanges() { }

          function onDestroy() {
            unregisterProcessTriggerHandler();
            inboundEmailUnavailableHandler();
          }

          /**
           * @ngdoc method
           * @name onSelectedTab
           * 
           * @description
           * view selected tab
           */
          function onSelectedTab(id) {
            if ($ctrl.activeTab === id) {
              return;
            }
            if ($ctrl.activeTab === 'ko-step' && StepService.validatePrerunFields($ctrl.process)) {
              return;
            }
            $ctrl.activeTab = id;
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
            $rootScope.$emit('RIGHT_PANE:OPEN', {
              item: {
                id: id,
                process: $ctrl.process
              }
            });
          }

          /**
           * @ngdoc method
           * @name onProcessTrigger
           * 
           * @description
           * view process trigger tab
           */
          function onProcessTrigger(){
            $ctrl.processTrigger = !$ctrl.processTrigger;
            if($ctrl.processTrigger) {
              onSelectedTab('ko-step');
            } else {
              $rootScope.$emit('RIGHT_PANE:CLOSE', { });
              $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', { });
            }
          }

          /**
           * Listen on process update
           * @type {*|(function())}
           */
          unregisterProcessTriggerHandler = $rootScope.$on("PROCESS_TRIGGER_RIGHT_PANE:CLOSE", function (e, data) {
            $ctrl.activeTab = '';
            if (data.onStepClicked && $ctrl.processTrigger) {
              $ctrl.processTrigger = !$ctrl.processTrigger;
            }
          });

          /**
           * Listen on process update
           * @type {*|(function())}
           */
          inboundEmailUnavailableHandler = $rootScope.$on("INBOUND_EMAIL_UNAVAILABLE:UPDATE", function (e, data) {
            $ctrl.inboundEmailUnavailable = data.status;
          });
        }
    });
})();
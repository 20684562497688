(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('createMilestone', {
      templateUrl: 'app/modules/steps/edit/createMilestoneModal/create-milestone-modal.component.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.saveMilestone = saveMilestone;

          $ctrl.cancel = cancel;

          function onInit() {
            if ($ctrl.resolve.stage) {
              $ctrl.milestoneName = $ctrl.resolve.stage.title;
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function saveMilestone() {
            $ctrl.submitted = true;
            if (!$ctrl.milestoneName) {
              return;
            }
            $ctrl.close({
              $value: {
                name: $ctrl.milestoneName
              }
            });
          }

          function cancel() {
            $ctrl.dismiss();
          }
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.compliance.config.ComplianceStateConfig
 * @module tallyfy.compliance
 *
 * @description
 * set route compliance Module
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.compliance')
    .config(ComplianceStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ComplianceStateConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * ComplianceStateConfig construct 
   */
  function ComplianceStateConfig($stateProvider) {
    $stateProvider.state('compliance', {
      parent: 'app',
      url: '/compliance',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          templateUrl: 'app/modules/compliance/compliance.html'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('integration');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
  * @ngdoc factory
  * @name tallyfy.messages.factory.MessagesRepository
  * @module tallyfy.messages
  *
  * @description
  * Create Rest resource for messages
  *
  * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
  * 
  * */
(function () {
  'use strict';
  angular
    .module('tallyfy.messages')
    .factory('MessagesRepository', MessagesRepository);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  MessagesRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * MessagesRepository construct
   */
  function MessagesRepository(BaseRepository) {
    var BASE_URI_SEGMENT = '/organizations/:org', BASE_GUEST_URI_SEGMENT = '/public/organizations/:org', repository;

    repository = BaseRepository(BASE_URI_SEGMENT + '/:action/:sub_action/:type/:comment_id', {
      org: 'org_id',
      action: '@action',
      sub_action: '@sub_action'
    }, {});

    /*    REOPEN_COMMENT: 'key:reopenComment, ',
          REPORT_COMMENT: 'report', */

    function messageSendGuest(taskId, params, data) {
      var guestRepo = getGuestRepository(taskId);
      params.skipAuthToken = true;
      return guestRepo.create(params, data);
    }

    repository.messageSend = function (taskId, data, isGuest, state) {
      var params = {
        action: 'tasks',
        sub_action: taskId,
        type: 'problem',
        state: state
      };
      return isGuest
        ? messageSendGuest(taskId, params, data)
        : repository.create(params, data);
    };

    function guestResolve(taskId, params, data) {
      var guestRepo = getGuestRepository(taskId);
      params.skipAuthToken = true;
      return guestRepo.create(params, data);
    }

    repository.resolve = function (taskId, data, isGuest, state) {
      var params = {
        action: 'tasks',
        sub_action: taskId,
        type: 'resolved-threads',
        state: state
      };
      return isGuest
        ? guestResolve(taskId, params, data)
        : repository.create(params, data);
    };

    function guestCommentSend(taskId, params, data) {
      var guestRepo = getGuestRepository(taskId);
      params.skipAuthToken = true;
      return guestRepo.create(params, data);
    }

    repository.commentSend = function (taskId, data, isGuest, state, type) {
      var params = {
        action: 'tasks',
        sub_action: taskId,
        type: type || 'comment',
        state: state
      };
      return isGuest
        ? guestCommentSend(taskId, params, data)
        : repository.create(params, data);
    };

    repository.commentSendStep = function (stepId, data, isGuest, state, skipNotFound) {
      var params = {
        action: 'steps',
        sub_action: stepId,
        type: 'comment',
        state: state,
        skipNotFound: skipNotFound
      };
      return repository.create(params, data);
    };

    function guestCommentUpdate(taskId, params, data) {
      var guestRepo = getGuestRepository(taskId);
      params.skipAuthToken = true;
      return guestRepo.update(params, data);
    }

    repository.commentUpdate = function (comment, isGuest, step) {
      var params = {
        action: step ? 'steps' : 'tasks',
        sub_action: step ? step.id : comment.task_id,
        type: 'comment',
        comment_id: comment.id,
        state: comment.state
      };
      return isGuest
        ? guestCommentUpdate(comment.task_id, params, {
          content: comment.content,
          label: comment.label,
          thread_id: comment.thread_id
        })
        : repository.update(params, {
          content: comment.content,
          label: comment.label,
          thread_id: comment.thread_id
        });
    };

    function guestDeleteComment(taskId, params) {
      var guestRepo = getGuestRepository(taskId);
      params.skipAuthToken = true;
      return guestRepo.delete(params);
    }

    repository.commentDelete = function (comment, isGuest, step) {
      var params = {
        action: step ? 'steps' : 'tasks',
        sub_action: step ? step.id : comment.task_id,
        type: 'comment',
        comment_id: comment.id
      };
      return isGuest
        ? guestDeleteComment(comment.task_id, params)
        : repository.delete(params);
    };

    function getGuestRepository(taskId) {
      return BaseRepository(BASE_GUEST_URI_SEGMENT + '/guests/guest_code/:action/:sub_action/:type/:comment_id', {
        org: 'org_id',
        action_id: taskId
      }, {});
    }

    return repository;
  }
})();

(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('resizer', resizer);
  /*@ngInject*/
  function resizer($document) {
    return function($scope, $element, $attrs) {
      $element.on('mousedown', function(event) {
        event.preventDefault();
        $document.on('mousemove', mousemove);
        $document.on('mouseup', mouseup);
      });
      function mousemove(event) {
        $($attrs.resizerRight).addClass("library-contents-resizer");
        $($attrs.resizerLeft).addClass("library-sidebar-resizer");
        $('#'+$attrs.id).addClass("active");
        if ($attrs.resizer == 'vertical') {
          // Handle vertical resizer
          var x = parseInt(event.pageX);
          if ($attrs.subtraction) {
            x = x - parseInt($attrs.subtraction);
          }
          if ($attrs.resizerMax && x > $attrs.resizerMax) {
            x = parseInt($attrs.resizerMax);
          } else if($attrs.resizerMin && x < $attrs.resizerMin) {
            x = parseInt($attrs.resizerMin);  
          }
          $($attrs.resizerLeft).css({ width: x + 'px' });
          $element.css({ left: x + 'px' });
          $($attrs.resizerRight).css({ left: (x + parseInt($attrs.resizerWidth)) + 'px' });
        } else {
          // Handle horizontal resizer
          var y = window.innerHeight - event.pageY;
          $element.css({ bottom: y + 'px' });
          $($attrs.resizerTop).css({ bottom: (y + parseInt($attrs.resizerHeight)) + 'px' });
          $($attrs.resizerBottom).css({ height: y + 'px' });
        }
      }
      function mouseup() {
         $('#'+$attrs.id).removeClass("active");
         $document.unbind('mousemove', mousemove);
         $document.unbind('mouseup', mouseup);
      }
    };
  }
})();

/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportLink
 * @module tallyfy.support
 *
 * @description
 * A component to manage support links
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('supportLink', {
      bindings: {
        subscription: '<'
      },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          var layoutType = $attrs.type || 'recurly';
          return 'app/modules/support/components/links/support-link-' + layoutType.toLowerCase() + '.html';
        }
      ],
      controller:
        /*@ngInject*/
        function SupportLinksController(CONFIG, $attrs, ENV_CONFIG) {
          var $ctrl = this;

          /**
           * @access public
           * public properties
           */
          $ctrl.config = CONFIG;
          $ctrl.clientConfig = ENV_CONFIG;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {}

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           * @param {Object} bindings
           */
          function onChanges(bindings) {}

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {}
        }
    });
})();

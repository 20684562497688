/**
 * @ngdoc Component
 * @name tallyfy.process.component.processActions
 * @module tallyfy.process
 *
 * @description
 * A component to manage process edit view
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('processFilters', {
      templateUrl: 'app/modules/process/components/process-filters/process-filters.html',
      bindings: {
        filterConfig: '<',
        changeView: '&'
      },
      controller:
        /*@ngInject*/
        function ($filter, _, $state, $stateParams, $rootScope, preferencesStore, RunsDashboardService, Helper) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.selectFilter = selectFilter;

          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.allFilters =  $ctrl.filterConfig.defaultFilters;
            var filterId =   $stateParams.status ? $stateParams.status : $ctrl.allFilters[0].id; 
            $ctrl.filter = _.find($ctrl.allFilters, { 'id': filterId });
             preferencesStore.getPreferences($stateParams.id || $rootScope.identity.id).then(function (preference) {
              var activePreference = _.get(_.find(preference, { slug: 'bp_filter_config' }), 'metadata');
              var sortFilterConfig = RunsDashboardService.getBluePrintSortFilterConfig();
              var sort = Helper.isObjectEmpty(activePreference) ? _.first(sortFilterConfig.sortOptions).value : _.get(activePreference.active_view, 'value');
              angular.extend($ctrl.filter, { sort: sort });
              setRequestParameters($ctrl.filter);
            });
          }

          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {}

          /**
          * @ngdoc method
          * @name selectFilter
          * @description select filter
          */
          function selectFilter(id) {
            if (id == $ctrl.filter.id)
                return;
            $ctrl.filter = _.find($ctrl.allFilters, {
                'id': id
            });
            $state.go($state.current, {
              status: $ctrl.filter.state,
              page : null
            }, {
                notify: true
            });
          }

          /**
          * @ngdoc method
          * @name setRequestParameters
          * @description Set criteria for request parameters
          * @param {Object} Seleted filter
          * @returns {Object} filter
          */
          function setRequestParameters(filter) {
            var params = {};
            if (filter.criteria.length) {
                _.forEach(filter.criteria, function(elem, index) {
                    params[elem.field] = elem.value;
                });
            }
            params.sort = filter.sort;
            $ctrl.changeView({
                params: params
            });
          }
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for compliance component
 *
 * integration@author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    $stateProvider.state('settings.compliance', {
      parent: 'settings',
      url: '/compliance',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view': {
          component: 'compliance'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('integration');
          return $translate.refresh();
        }]
      }
    });
  }
})();
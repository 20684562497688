/**
 * @ngdoc Component
 * @name explanationVideoModal
 * @module tallyfy
 * @description
 * Component for Explanation Video Modal
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('explanationVideoModal', {
      templateUrl: 'app/modules/process/components/library/explanationVideoModal/explanation-video-modal.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
      /*@ngInject*/
        function explanationVideoModalComponentController(_, $sce) {
          var $ctrl = this;

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          $ctrl.accepts = accepts;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.blueprint = angular.copy($ctrl.resolve.data);
            $ctrl.videoSplit = _.split($ctrl.blueprint.explanation_video, '/');
            if($ctrl.videoSplit[2] === 'youtu.be') {
              $ctrl.videoSource = $sce.trustAsResourceUrl("//www.youtube.com/embed/"+$ctrl.videoSplit[3]);     
            } else if($ctrl.videoSplit[2] === 'www.youtube.com') {
              var vId = _.split($ctrl.videoSplit[3], '=');
              $ctrl.videoSource = $sce.trustAsResourceUrl("//www.youtube.com/embed/"+vId[1]);
            } else if (_.indexOf($ctrl.videoSplit, 'share') > 0 ) {
              $ctrl.videoSource = $sce.trustAsResourceUrl(_.replace($ctrl.blueprint.explanation_video, 'share', 'embed'));
            } else {
              $ctrl.videoSource = $sce.trustAsResourceUrl($ctrl.blueprint.explanation_video);
            }
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
          */
          function cancel() {
            $ctrl.dismiss();
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Close modal
          */
          function accepts() {
            $ctrl.close();
          }
        }
    });
})();

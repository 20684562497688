/**
 * @ngdoc Config
 * @name tallyfy.account.component.config.routeConfig
 * @module tallyfy.account
 *
 * @description
 * set route for change password component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .config(routeConfig);
  /**
 * @inject 
 * @description
 * inject dependecies 
 */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */

  function routeConfig($stateProvider) {
    
  }
})();
/**
 * @ngdoc overview
 * @name tallyfy.process
 *
 * @module tallyfy.process
 *
 * @description
 * manage process
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.process', [
    'infinite-scroll', 
    'ui.sortable',
    'tallyfy.organizations.users',
    'angular-clipboard']);
})();

/**
 * @ngdoc Component
 * @name tallyfy.component.iconIssueResolved
 * @module tallyfy
 *
 * @description
 * Icon for issue resolved
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('iconIssueResolved', {
      bindings: {
        resolver: '=',
        item: '='
      },
      templateUrl: 'app/components/icon-issue-resolved/icon-issue-resolved.html',
      controller:
        /*@ngInject*/
        function (OrganizationsService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          function onInit() { }
          
          function onChanges() { }

          function onDestroy() { }
       }
    });
})();
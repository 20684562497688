(function () {
  'use strict';
  angular.module('tallyfy')
    .component('libraryFaq', {
      templateUrl: 'app/components/library-faq/library-faq.component.html',
      controller:
        /*@ngInject*/
        function ($state) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.openVideoModal = openVideoModal;
          $ctrl.onBlueprintCreate = onBlueprintCreate;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function openVideoModal() {
            window.open('https://tallyfy.com/products/pro/documenting/', '_blank');

          }

          function onBlueprintCreate(type) {
            $state.go('process.create', { blueprintType: type });
          }
        }
    });
})();
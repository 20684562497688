/**
 * @ngdoc Component
 * @name tallyfy.assignee.component
 * @module tallyfy.assignee
 *
 * @description
 * A component to show task assignee.
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('assigneeList', {
      bindings: {
        taskOwners: '<?',
        ownersGuests: '<',
        assigneeType: '<',
        allOwner:'<?',
        ownersName:'<?',
        assignGuests: '<',
        isTaskCompleted: '<?'
      },
      templateUrl: 'app/modules/assigneeView/assigneeList.html',
      controller: 
        /*@ngInject*/
        function ($filter, TasksService) {
          var $ctrl = this;

          /**
          * component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          
          /**
           * public methods
           */
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          
          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.coworker = $filter('translate')('tasks.assignee.coworker');
            $ctrl.guest = $filter('translate')('tasks.assignee.guest');
          }
          
          /**
          * @ngdoc method
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() {}
          
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }
          
          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }
        }
    });
})();

/**
 * @ngdoc Component
 * @name tallyfy.support.component.tallyfyGuestTable
 * @module tallyfy.support
 *
 * @description
 * A component to manage Guest List
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('tallyfyGuestTable', {
      bindings: {
        orgId: '<?'
      },
      templateUrl: 'app/modules/support/components/users/tallyfyGuestTable/tallyfy-guest-table.html',
      controller:
        /*@ngInject*/
        function (_, $q, $filter, GuestTasksRepository, OrganizationsService) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getGuests = getGuests;

          $ctrl.guestPagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }
        
          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */  
          function onChanges() { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getTableConfig() {
            var hideAndShowColumns = [{
              field: 'email',
              title: $filter('translate')('regularUsers.table.header.email'),
              initState: true
            }, {
              field: 'lastAccessed',
              title: $filter('translate')('regularUsers.table.header.lastAccessed'),
              initState: true
            }];
            
            return {
              beautyScrollStyle: true,
              gridConfig: getGuestTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: hideAndShowColumns
              },
              tableFilterModel: {
                email: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                },
                variables: {
                  dateFormat: OrganizationsService.getDateFormat(),
                  editedGuest: void 0,
                  isOnEdit: false
                }
              }
            };
          }

          function getGuestTableOptions() {
            return {
              columns: getGuestTableColumns(),
              dataSource: new kendo.data.DataSource({
                pageSize: 10
              }),
              noRecords: {
                template: kendo.template($('#guestListTableNoData').html())
              },
              resizable: true
            };
          }

          // guest table columns
          function getGuestTableColumns() {
            var columns = [{
              field: "email",
              exportKeyMap: {
                key: "email",
                label: $filter('translate')('regularUsers.table.header.email')
              },
              headerTemplate: kendo.template($('#guestEmailColumnHeaderTemplate').html()),
              template: kendo.template($('#guestEmailColumnTemplate').html()),
              width: 280
            }, {
              field: "lastAccessed",
              exportKeyMap: {
                key: "details.last_accessed_at",
                label: $filter('translate')('regularUsers.table.header.lastAccessed')
              },
              template: kendo.template($('#guestLastAccessedColumnTemplate').html()),
              title: $filter('translate')('regularUsers.table.header.lastAccessed'),
              width: 720
            }];
            return columns;
          }

          function getGuests() {
            var defer = $q.defer();
            $ctrl.isLoading = true;
            GuestTasksRepository.get({ org: $ctrl.orgId }).then(function (response) {
              $ctrl.isLoading = false;
              defer.resolve(response.data);
            }, function () {
              $ctrl.isLoading = false;
              defer.reject();
            });
            return defer.promise;
          }
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.toggleCheckbox
 * @description 
 * Perform animation when toggle checkbox
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('toggleCheckbox', toggleCheckbox);

  /*@ngInject*/
  function toggleCheckbox(_) {
    return {
      restrict: 'AE',
      scope: {
        allowToAnimate: '=',
        tasks: '@'
      },
      link: function ($scope, $element, $attr) {
        var parentElement;

        /*
         * @ngdoc method
         * @name applyAnimation
         * @param element
         * @param height
         * @description 
         * Apply animation as dynamic height
         */
        function applyAnimation(element, height) {
          element.animate({
            height: height + 'px'
          }, 200);
        }

        /*
         * @ngdoc method
         * @name calculateHeight
         * @param allowToAnimate
         * @description 
         * calculate hight 
         */
        function calculateHeight(allowToAnimate) {
          if (!parentElement || !parentElement.length) {
            return;
          }
          var newHeight,
            previousHeight = parentElement[0].getAttribute('previousHeight');

          if (allowToAnimate) {
            newHeight = (previousHeight >= parentElement[0].clientHeight) ? previousHeight : parentElement[0].clientHeight;
          } else {
            parentElement[0].style.height = null;
            var setHeight = (parentElement[0].clientHeight > 42) ? parentElement[0].clientHeight : 42;
            parentElement[0].setAttribute("previousHeight", setHeight);
            newHeight = 42;
          }
          applyAnimation(parentElement, newHeight);
        }

        $element.on('click', function() {
          if ($scope.tasks) {
            _.forEach(angular.fromJson($scope.tasks), function (task) {
              parentElement = angular.element(document).find('#max-height_' + task.id);
              calculateHeight(task.isChecked);
            });
            return;
          }
          parentElement = angular.element(document).find($attr.toggleCheckbox);
          calculateHeight($scope.allowToAnimate);
        });

        $scope.$on('$destroy', function (e) {
          $element.unbind('click');
          if (parentElement) {
            parentElement[0].style.height = null;
          }
        });
      }
    };
  }
})();
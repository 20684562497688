(function () {
  'use strict';
  angular.module('tallyfy')
    .component('processNameHelpModal', {
      templateUrl: 'app/modules/runs/create/processNameHelpModal/process-name-help-modal.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
        /*@ngInject*/
        function (_) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() {

          }

          function onChanges() {

          }

          function onDestroy() {

          }
        }
    })
})();
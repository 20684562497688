(function () {
  'use strict';
  angular.module('tallyfy')
    .directive('froalaTranslateContent', froalaTranslateContent);

  /**@ngInject*/
  function froalaTranslateContent(_, $q, $rootScope, $timeout, USER_STATE, OrganizationsService, FroalaService, TranslationService) {
    return {
      restrict: 'A',
      scope: {
        scrollContainer: '<',
        translateTo: '<',
        froalaTranslateContent: '<'
      },
      link: function ($scope, $element) {
        var translating = false, scrollElement, translateRequest = $q.defer();

        function translate() {
          if (translating || (!$scope.translateTo || $scope.translateTo === 'no_translation')) {
            return;
          }
          var textNodes = [], timeoutHandler;
          if (textNodes) {
            $timeout.cancel(timeoutHandler);
          }
          translating = true;
          timeoutHandler = $timeout(function () {
            if ($element[0]) {
              var nodes = FroalaService.getAllTextNodes($element[0]);
              textNodes = _.filter(nodes, function (text) {
                var range = document.createRange();
                range.selectNodeContents(text);
                var rects = range.getClientRects();
                if (!rects.length) {
                  return false;
                }
                var parent = text.parentNode;
                if (parent && parent.classList.contains('ty-content-translated')) {
                  return false;
                } else {
                  var temp = angular.element('<div>').html(text.nodeValue);
                  return temp.text().trim().length != 0;
                }
              });
            }
          }, 2000);
          timeoutHandler.then(function () {
            var arrayOfText = _.map(textNodes || [], function (text) {
              return { text: text.nodeValue };
            });
            if (arrayOfText.length) {
              $element.addClass('content-translating');
              TranslationService.setTotalTranslationInProgress(1);
              translateRequest = TranslationService.translateContent({
                org_id: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.default_organization.id : $rootScope.identity.guest.organization.id,
                email: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.email : $rootScope.identity.guest.email,
                token: $rootScope.userState === USER_STATE.MEMBER ? void 0 : $rootScope.identity.guest.guest_code
              }, {
                to: $scope.translateTo,
                translations: arrayOfText
              });

              translateRequest.$promise.then(function (res) {
                for (var i = 0; i < textNodes.length; i++) {
                  $(textNodes[i]).wrap('<span class="translated">');
                  textNodes[i].nodeValue = res.data[i].translations[0].text;
                }
                translating = false;
                $element.removeClass('content-translating');
                TranslationService.setTotalTranslationInProgress(-1);
              }, function () {
                translating = false;
                $element.removeClass('content-translating');
                TranslationService.setTotalTranslationInProgress(-1);
              });
            }
          });
        }

        function isAzureServiceEnabled() {
          var preferences = $rootScope.userState === USER_STATE.MEMBER
            ? _.get($rootScope, 'identity.preferences')
            : _.get($rootScope, 'identity.guest.preferences'), isAzureCognitiveServiceEnabled = false,
            config = _.find(preferences, { slug: 'content_language' });
          if (_.get(config, 'metadata.lang')) {
            var organization = $rootScope.userState === USER_STATE.MEMBER
              ? $rootScope.identity.default_organization
              : _.find($rootScope.identity.guest.guestOrganizations, { id: $rootScope.identity.guest.organization.id }),
              config = OrganizationsService.getAzureCognitiveServiceConfig(organization);
            if (config) {
              isAzureCognitiveServiceEnabled = config.key && config.resource_name && config.region;
            }
          } else {
            isAzureCognitiveServiceEnabled = false;
          }
          return isAzureCognitiveServiceEnabled;
        }

        function _throttle(fn, wait) {
          var time = Date.now();
          return function () {
            if ((time + wait - Date.now()) < 0) {
              fn();
              time = Date.now();
            }
          };
        }

        function fetchScrollData() {
          return _throttle(function () {
            if (translating) {
              translating = false;
              $element.removeClass('content-translating');
              if (translateRequest.$cancelRequest) {
                translateRequest.$cancelRequest();
              }
            }
            var isAzureCognitiveServiceEnabled = isAzureServiceEnabled();
            if (!isAzureCognitiveServiceEnabled) {
              return;
            }
            translate();
          }, 750);
        }

        $scope.$watch('froalaTranslateContent', function (value) {
          if (!value || !$scope.translateTo) {
            return;
          }
          var isAzureCognitiveServiceEnabled = isAzureServiceEnabled();
          if (!isAzureCognitiveServiceEnabled) {
            return;
          }
          translate();
        });

        $scope.$watch('translateTo', function (value) {
          if (!value || !$scope.froalaTranslateContent) {
            return;
          }
          var isAzureCognitiveServiceEnabled = isAzureServiceEnabled();
          if (!isAzureCognitiveServiceEnabled) {
            return;
          }
          if (value) {
            $timeout(function () {
              if ($element[0]) {
                var nodes = $element[0].getElementsByClassName('ty-content-translated');
                for (var i = 0; i < nodes.length; i++) {
                  nodes[i].outerHTML = nodes[i].innerHTML;
                }
              }
            }, 2000).then(function () {
              translate();
            });
          }
        });

        $scope.$watch('scrollContainer', function (value) {
          if (!value) {
            return;
          }
          $timeout(function () {
            var _scrollElement = document.querySelectorAll(value);
            if (_scrollElement && _scrollElement.length) {
              scrollElement = angular.element(_scrollElement[0]).bind("scroll", fetchScrollData());
            }
          }, 350);
        });

        $scope.$on('$destroy', function () {
          if (scrollElement) {
            scrollElement.unbind("scroll", fetchScrollData());
          }
        });
      }
    }
  }
})();
/**
 * @ngdoc component
 * @name tallyfy.randomGif
 * @restrict 'A'
 * 
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('randomGif', {
      templateUrl: 'app/modules/randomGif/randomGif.html',
      bindings: { },
      controller:
        /*@ngInject*/
        function (store) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;

          function onInit() {
            $ctrl.randomGifInteger = store.getRandomGifInteger();
          }
        }
    });
})();
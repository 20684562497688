(function () {
  'use strict';
  angular.module('tallyfy')
    .directive('memberPrefixTranslation', memberPrefixTranslation);

  /*@ngInject*/
  function memberPrefixTranslation($rootScope, $filter) {
    return {
      restrict: 'A',
      scope: {
        memberPrefixTranslation: '<'
      },
      link: function ($scope, $element) {
          var memberValueWatcher, prefix;
          memberValueWatcher = $scope.$watch(function () {
            prefix = $rootScope.identity && ($rootScope.identity.last_known_country == 'US' || $rootScope.identity.last_known_country == 'CA') ? 'Co' : '';
            $element.html($filter('translate')($scope.memberPrefixTranslation + prefix));
          }, function () { });

          $scope.$on('$destroy', function () {
            memberValueWatcher();
          });
        }
    }
  }
})();
/**
 * @ngdoc Service
 * @name tallyfy.utils.Service.UtilsRepository
 *
 * @module tallyfy.utils
 *
 * @description
 * UtilsService
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('UtilsService', UtilsService);
  /*@ngInject*/
  function UtilsService(UtilsRepository, $q, _, $rootScope) {
    var self = this, countriesList = [];
    angular.extend(self, UtilsRepository);
    /*
    * @name checkURL
    * @param url
    * @description Check URL status
    * @return {Promise} Promise
    */
    self.checkURL = function (url) {
      var params = {
        url: url
      };
      return UtilsRepository.checkURL(params).$promise;
    };

    /*
    * @name isElementPresentInViewport
    * @param parent
    * @param child
    * @param offset
    * @description Check if child element is present in parent viewport
    * @return {Promise} Promise
    */
    self.isElementPresentInViewport = function (element, offset) {
      var elementRect = element.getBoundingClientRect();
      return (
        (elementRect.bottom - offset > 0) && (elementRect.top - offset < (window.innerHeight - offset || document.documentElement.clientHeight - offset))
      );
    };

    /*
    * @name getCountries
    * @description Get countries list
    * @return {Promise} Promise
    */
    self.getCountriesData = function () {
      var deferred = $q.defer();
      if (!countriesList.length) {
        UtilsRepository.getCountries().$promise.then(function(response) {
          countriesList = response;
          deferred.resolve(countriesList);
        });
      } else {
        deferred.resolve(countriesList);
      }
      return deferred.promise;
    };

    self.hasSpecificPermissions = function (isAdmin, creator, currentUserID, membersPermissionsList, groupsPermissionsList, orgGroups) {
      if (isAdmin || (creator === currentUserID) || (!membersPermissionsList.length && !groupsPermissionsList.length) || (membersPermissionsList.length && _.includes(membersPermissionsList, currentUserID))) {
        return true;
      } else if (groupsPermissionsList.length) {
        var hasGroupLevelPermission = false;
        for (var i = 0; i < groupsPermissionsList.length; i++) {
          var groupItem = _.find(orgGroups, { 'id': groupsPermissionsList[i] });
          if (groupItem && _.includes(groupItem.members, currentUserID)) {
            hasGroupLevelPermission = true;
            break;
          }
        }
        return hasGroupLevelPermission;
      } else {
        return false;
      }
    };

    /*
    * @name canCreateOrEditTemplates
    * @description Create or edit templates permission
    * @return {Promise} Promise
    */
    self.canCreateOrEditTemplates = function () {
      return !(_.isEqual(_.get($rootScope, 'identity.role'), "light"));
    };
  }
})();

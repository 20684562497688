/**
 * @ngdoc Config
 * @name tallyfy.run.config.RunsStateConfig
 * @module tallyfy.run
 *
 * @description
 * set route run Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .config(RunStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  RunStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * RunsStateConfig construct
   */
  function RunStateConfig($stateProvider, $urlRouterProvider) {

    $stateProvider.state('run', {
      parent: 'app',
      absolute: true,
      url: '/:org_id',
      data: {
        authorities: ['USER_ROLE'],
        pageTitle: 'global.pageTitle.processTracker'
      },
      views: {
        'content': {
          component: 'runsComponent'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('filter');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('process');
          return $translate.refresh();
        }]
      }
    });
  }
})();

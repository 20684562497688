/**
 * @ngdoc Component
 * @name tallyfy.organizations.customBranding
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage theme color and custom branding
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('customBranding', {
      templateUrl: 'app/modules/organizations/customBranding/custom-branding.html',
      controller:
        /*@ngInject*/
        function (OrganizationsService, $rootScope, _, $confirm, $log, customBrandingService, TFY_EVENTS, $aside, blockUI) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('customBranding'),
            savedThemeColor;

          $ctrl.defaultThemeColor = {
            bg_color: customBrandingService.defaultColor.BASE_COLOR,
            text_color: customBrandingService.defaultColor.WHITE_COLOR,
            alternate_color: customBrandingService.defaultColor.WHITE_COLOR
          };

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.onColorChanged = onColorChanged;
          $ctrl.resetDefaultSetting = resetDefaultSetting;
          $ctrl.onToggled = onToggled;
          $ctrl.deleteOrgCustomBranding = deleteOrgCustomBranding;
          $ctrl.openStylesheetEditor = openStylesheetEditor;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on 
           * an element have been constructed and had their bindings initialized
           */
          function initialization() {
            getOrgCustomBranding();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getOrgCustomBranding
           * @description To get org  custom color 
           */
          function getOrgCustomBranding() {
            blockUI.start();
            OrganizationsService.getOrgCustomBranding().then(function (response) {
              var getCustomThemeColor = _.get(response, 'data');
              blockUI.stop();
              if (!_.isEmpty(getCustomThemeColor)) {
                $ctrl.isDefaultColor = false;
                setDefaultTheme(getCustomThemeColor);
              } else {
                $ctrl.isDefaultColor = true;
                setDefaultTheme($ctrl.defaultThemeColor);
              }
            }, function () {
              blockUI.stop();
              $log.error('Error while set branding color');
            });
          }

          /**
           * @ngdoc method
           * @name setDefaultTheme
           * @description set original/default theme colors
           * @param {Object} colors
           */
          function setDefaultTheme(colors) {
            savedThemeColor = colors;
            $ctrl.color = colors.bg_color;
            $ctrl.bgColor = colors.bg_color;
            $ctrl.alternateColor = colors.alternate_color;
            $ctrl.textColor = colors.text_color;
          }

          /**
           * @function
           * @name onColorChanged
           * @description set theme colors 
           * @param {Boolean} isSave
           */
          function onColorChanged(isSave) {
            var color = tinycolor($ctrl.color);

            if (_.isEmpty($ctrl.color)) {
              setDefaultTheme(savedThemeColor);
            }
            if (!color.isValid() || _.get(savedThemeColor, 'bg_color') === $ctrl.color) {
              $ctrl.validColor = false;
              return;
            }
            $ctrl.validColor = true;
            if (_.includes(['name', 'rgb'], color.getFormat())) {
              $ctrl.color = color.toHexString().toUpperCase();
            }
            $ctrl.bgColor = $ctrl.color;
            var theme = setColorsShades(color);
            if (isSave) {
              setOrgCustomBranding(theme, isSave);
            }
          }

          /**
           * @ngdoc method
           * @name setColorsShades
           * @description set other shades of seleted color
           * @param {string} color
           */
          function setColorsShades(color) {
            var darkColor = color.darken().toString(),
              brightenColor = color.brighten(10).toString();

            if (color.getBrightness() > 200) {
              $ctrl.textColor = customBrandingService.defaultColor.GRAY_COLOR;
              $ctrl.alternateColor = tinycolor(darkColor).darken().toString();
            } else {
              $ctrl.textColor = customBrandingService.defaultColor.WHITE_COLOR;
              $ctrl.alternateColor = $ctrl.color;
            }

            return getColorsShades(darkColor, brightenColor);
          }

          /**
           * @ngdoc method
           * @name getColorsShades
           * @description get other shades of seleted color
           * @param {*} darkColor
           * @param {*} brightenColor
           */
          function getColorsShades(darkColor, brightenColor) {
            return {
              bg_color: $ctrl.color,
              text_color: $ctrl.textColor,
              alternate_color: $ctrl.alternateColor,
              bright_shade: brightenColor,
              dark_shade: darkColor
            };
          }

          /**
           * @ngdoc method
           * @name resetDefaultSetting
           * @description reset branding color setting and org logo
           */
          function resetDefaultSetting() {
            $confirm({
              'header': "customBranding.confirmModal.header",
              'body': "customBranding.confirmModal.body",
              'buttons': {
                'accept': "customBranding.confirmModal.button.accept",
                'cancel': "customBranding.confirmModal.button.cancel"
              },
              modalType: 'modal-danger'
            }).then(function () {
              deleteOrgCustomBranding();
            }, function () {
              $log.info('Modal closed');
            });
          }

          /**
           * @ngdoc method
           * @name setOrgCustomBranding
           * @description set branding color setting
           * @param {Object} setTheme 
           * @param {string} value 
           */
          function setOrgCustomBranding(setTheme, value) {
            var setVal = value ? 'yes' : 'no';
            $ctrl.onSaving = true;
            OrganizationsService.setOrgCustomBranding(setTheme).then(function (response) {
              $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, {
                reset: setVal,
                setTheme: setTheme
              });
              if (_.isEmpty(_.get(response, 'data'))) {
                $ctrl.isDefaultColor = true;
                setDefaultTheme($ctrl.defaultThemeColor);
              } else {
                $ctrl.isDefaultColor = false;
                setDefaultTheme(setTheme);
                $ctrl.validColor = false;
                $ctrl.onSaving = false;
              }
            }, function () {
              $ctrl.onSaving = false;
              $log.error('Error while set branding color');
            });
          }

          /**
           * @ngdoc method
           * @name deleteOrgCustomBranding
           * @description delete branding color settin
           */
          function deleteOrgCustomBranding() {
            $ctrl.onSaving = true;
            OrganizationsService.deleteOrgCustomBranding().then(function (response) {
              $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, { reset: 'no', setTheme: '' });
              $ctrl.isDefaultColor = true;
              setDefaultTheme($ctrl.defaultThemeColor);
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
              $log.error('Error while deleteing branding color');
            });
          }

          /**
           * @ngdoc method
           * @name onToggled
           * @description reset when color picker toggle closed
           * @param {Boolean} open
           */
          function onToggled(open) {
            if (!open) {
              setDefaultTheme(savedThemeColor);
            }
          }

          function openStylesheetEditor(e) {
            var drawerInstance = $aside.open({
              component: 'stylesheetEditor',
              placement: 'right',
              windowClass: 'stylesheet-editor',
              backdrop: 'static',
              resolve: {
                process: function () {
                  return $ctrl.process;
                }
              }
            });
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('magic', {
      template: '<div block-ui=\'taskMagicLink\' class="magic-link-page-container"></div>',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, $stateParams, $analytics, CompactTaskService, TasksService, DateUtils, blockUI, DATEFORMAT, DESCRIPTIONSIZE, moment, MESSAGE_TYPE) {
          var $ctrl = this, blockUI = blockUI.instances.get('taskMagicLink');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() {
            if ($stateParams.action === 'createOotTask') {
              createOot();
            } else if ($stateParams.action === 'completeTask') {
              completeTask();
            } else if ($stateParams.action === 'reOpenTask') {
              reOpenTask();
            } else if ($stateParams.action === 'writeAComment') {
              writeAComment();
            } else if ($stateParams.action === 'editDeadline') {
              editDeadline();
            } else if ($stateParams.action === 'updateFormFieldValue') {
              updateFormFieldValue();
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function createOot() {
            blockUI.start();
            TasksService.createStandaloneTask({
              deadline: DateUtils.toUTC(moment().format()).format(DATEFORMAT.DEFAULT),
              owners: {
                users: [$rootScope.identity.id]
              },
              title: $stateParams.default_task_name.substring(0, DESCRIPTIONSIZE.maxTitle),
              task_type: 'task'
            }).then(function (response) {
              $state.go('home', { activeTask: response.data.id });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          function completeTask() {
            blockUI.start();
            CompactTaskService.getSingleTask({
              task_id: $stateParams.task_id,
              skipNotFound: true
            }).then(function (res) {
              var task = res.data,
                override_user,
                enquireStatus = !_.includes(_.get(task, 'owners.users'), _.get($rootScope, 'identity.id')),
                isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
              if (task.everyone_must_complete && isAdminMember && enquireStatus) {
                override_user = _.first(_.get(task, 'completion_progress.waiting.users', []));
              }
              CompactTaskService.taskComplete(task, null, false, true, override_user)
                .then(function (res) {
                  blockUI.stop();
                  $state.go('home', {
                    activeTask: res.task.id,
                    status: 'completed'
                  });
                }, function () {
                  blockUI.stop();
                });
            }, function () {
              blockUI.stop();
            });
          }

          function reOpenTask() {
            blockUI.start();
            CompactTaskService.getSingleTask({
              task_id: $stateParams.task_id,
              skipNotFound: true
            }).then(function (res) {
              var task = res.data;
              CompactTaskService.taskReopen(task).then(function (res) {
                trackEvent('Task re-opened', {
                  task: res.task,
                  'form-fields': _.get(res.task, 'form_fields', []).length ? 'yes' : 'no'
                });
                $state.go('home', {
                  status: 'to-do',
                  activeTask: task.id
                });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }, function () {
              blockUI.stop();
            });
          }

          function writeAComment() {
            blockUI.start();
            CompactTaskService.getSingleTask({
              task_id: $stateParams.task_id,
              skipNotFound: true
            }).then(function (res) {
              var task = res.data;
              CompactTaskService.sendCommand({
                content: $stateParams.comment,
                task: task,
                type: MESSAGE_TYPE.SEND_COMMENT
              }, MESSAGE_TYPE.COMMENT)
                .then(function (res) {
                  blockUI.stop();
                  $state.go('home', {
                    activeTask: task.id
                  });
                }, function () {
                  blockUI.stop();
                });
            }, function () {
              blockUI.stop();
            });
          }

          function editDeadline() {
            blockUI.start();
            CompactTaskService.getSingleTask({
              task_id: $stateParams.task_id,
              skipNotFound: true
            }).then(function (res) {
              var task = res.data;
              task.deadline = moment($stateParams.deadline).format(DATEFORMAT.DEFAULT);
              CompactTaskService.updateTask(task)
                .then(function (res) {
                  blockUI.stop();
                  $state.go('home', {
                    activeTask: task.id
                  });
                }, function () {
                  blockUI.stop();
                });
            }, function () {
              blockUI.stop();
            });
          }

          function updateFormFieldValue() {
            blockUI.start();
            CompactTaskService.getSingleTask({
              task_id: $stateParams.task_id,
              skipNotFound: true
            }).then(function (res) {
              var task = res.data;
              var formFields = JSON.parse($stateParams.form_fields);
              task.taskdata = formFields;
              CompactTaskService.updateTask(task)
                .then(function (res) {
                  blockUI.stop();
                  $state.go('home', {
                    activeTask: task.id
                  });
                }, function () {
                  blockUI.stop();
                });
            }, function () {
              blockUI.stop();
            });
          }

          function trackEvent(eventName, properties) {
            properties.type = !_.get(properties.task, 'run_id') ? 'standalone' : 'process';
            properties.processId = properties.task.run_id;
            properties.taskId = properties.task.id;
            $analytics.eventTrack(eventName, properties);
          }

        }
    })
})();
/**
 * @ngdoc Component
 * @name tallyfy.run.component.run
 * @module tallyfy.run
 *
 * @description
 * A component to manage run view
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('run', {
      bindings: {
        id: '<',
        run: '<',
        onArchive: '&',
        onUnArchive: '&',
        users: '<',
        orgGroups: '<',
        onFavoriteUpdated: '&',
        onPermanentlyDeleteRun: '&',
        activeFilter: '<',
        isGuest: '<',
        foldersList: '<?'
      },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          var layoutType = $attrs.layout || 'default';
          //data-layout="popover" will produce popover-run.html, and so on...
          return 'app/modules/runs/run/' + layoutType.toLowerCase() + '-run.html';
        }
      ],
      controller:
        /*@ngInject*/
        function (_, RunsService, RunRepository, Growl, blockUI, $rootScope, $filter, TasksService, UsersService, $timeout, Helper, moment, DateUtils, FolderService,
          TagsService, TAG, $state, ProcessService, UtilsService, FavoriteService, store, OrganizationsService, USER_STATE) {
          var $ctrl = this, growl = new Growl();
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * public methods
           */
          $ctrl.toggleStar = toggleStar;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.getDeadlineWithStatus = getDeadlineWithStatus;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onRunMenuViewClick = onRunMenuViewClick;
          $ctrl.onMoreTagsViewClick = onMoreTagsViewClick;
          $ctrl.onArchiveClick = onArchiveClick;
          $ctrl.onRunMenuArchiveClick = onRunMenuArchiveClick;
          $ctrl.addProcessToFolders = addProcessToFolders;
          $ctrl.getTagBackgroundColor = getTagBackgroundColor;
          $ctrl.getTagTextColor = getTagTextColor;
          $ctrl.getTagOverflowText = getTagOverflowText;
          $ctrl.getTagTooltipText = getTagTooltipText;
          $ctrl.goToBlueprint = goToBlueprint;
          $ctrl.goToUpcomingTask = goToUpcomingTask;
          $ctrl.getAssignee = getAssignee;
          $ctrl.setSelectedTask = setSelectedTask;
          $ctrl.onUnarchiveLocal = onUnarchiveLocal;
          $ctrl.deleteRun = deleteRun;

          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.infoName = $filter('translate')('runs.process.processStatus.archived');
            $ctrl.action = $filter('translate')('runs.dashboard.unarchiveRun');
            $ctrl.started = $filter('translate')('runs.dashboard.started');
            $ctrl.processText = $filter('translate')('process.process');
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST
              ? _.get($rootScope.identity, 'guest.organization.id')
              : _.get($rootScope.identity, 'default_organization.id');
            if (!_.isUndefined($ctrl.id)) {
              blockUI.start();
              RunsService.get({
                id: $ctrl.id,
                with: 'collaborators,checklist,tags'
              }).then(function (response) {
                $ctrl.run = response.data;
                $ctrl.checklist = angular.copy($ctrl.run.checklist.data);
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            } else {
              if ($ctrl.run.started_at && $ctrl.run.completed_at)
                $ctrl.run.tookTimeToComplete = RunsService.getTakenDurationToCompleteProcess(moment($ctrl.run.started_at), moment($ctrl.run.completed_at));
              $ctrl.getBarClass = RunsService.getBarClass($ctrl.run);
              $ctrl.getStatus = getStatus($ctrl.run);
            }
            // Prepare timeline for procedure type runs
            if ($ctrl.run.type === 'procedure' && !$ctrl.run.completed_at && !$ctrl.run.progress !== 100 && !$ctrl.run.archived_at) {
              getTimelineAssignees();
            }
            // Get process owner
            if (!$ctrl.isGuest) {
              getProcessOwner();
            }
            if ($ctrl.isGuest) {
              $ctrl.run.starred = !!($ctrl.run.guest_watchers.data.length > 0);
            }
            $ctrl.enableArchivePopover = $ctrl.haveAuthority(false);
            $ctrl.tagsLimit = $ctrl.run.archived_at ? 1 : 2;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           * @param {Object} bindings
           */
          function onChanges(bindings) {
            if (bindings.run && !bindings.run.isFirstChange()) {
              $ctrl.getBarClass = RunsService.getBarClass(bindings.run.currentValue);
              $ctrl.getStatus = getStatus(bindings.run.currentValue);
              getTimelineAssignees();
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name toggleStar
           * @description starred/un-starred run
           * @param {Object} run
           */
          function toggleStar(run) {
            if (!$ctrl.haveAuthority()) {
              return;
            }
            blockUI.start();
            var growlMessages = Growl.getAllMessages('global'),
              showAlertDelay = 0, oldRun = angular.copy(run),
              action = !($ctrl.run.starred) ? 'starred' : 'un-starred';
            if (angular.copy(growlMessages.length) > 0) {
              showAlertDelay = 500;
              Growl.clearAllMessages('global');
            }
            $ctrl.run.starred = !_.get(run, 'starred', false);
            $timeout(function () {
              growl.success($filter('translate')('runs.dashboard.runAction.' + action), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, showAlertDelay);
            $ctrl.onFavoriteUpdated({ run: run });
            var requestParams = { id: oldRun.id };
            if ($ctrl.isGuest) {
              requestParams.with = 'tasks_meta,tags,guest_watchers.watcher';
              if (_.get(run, 'guest_watchers.data[0].id')) {
                FavoriteService.removeGuestFavorite(
                  _.get($rootScope,'identity.guest.guest_code'),
                  _.get(run, 'guest_watchers.data[0].id')
                ).then(function () {
                  blockUI.stop();
                  $ctrl.run.guest_watchers.data = [];
                  $ctrl.run.starred = false;
                });
              } else {
                FavoriteService.addGuestFavorite(_.get($rootScope,'identity.guest.guest_code'), {
                 objId: _.get($ctrl.run, 'id'),
                 objectType: 'run'
                }).then(function (res) {
                 blockUI.stop();
                 $ctrl.run.guest_watchers.data.push(res.data);
                 $ctrl.run.starred = true;
                });
              }
            } else {
              requestParams.with = 'tasks_meta,folders,tags,member_watchers.watcher';
              RunRepository.updateRunsData({ id: _.get($ctrl.run, 'id'), with: requestParams.with, starred: oldRun.starred ? '0' : '1' }).then(function (data) {
                $ctrl.run = data.data;
                if ($ctrl.run.starred) {
                  FavoriteService.addFavorite({
                    userId: _.get($rootScope, 'identity.id'),
                    objId: _.get($ctrl.run, 'id'),
                    objectType: 'run'
                  }).then(function (res) {
                    blockUI.stop();
                    $ctrl.run.member_watchers.data.push(res.data);
                  });
                } else if(_.get(run, 'member_watchers.data[0].id')) {
                  FavoriteService.removeFavorite(_.get(run, 'member_watchers.data[0].id'));
                  blockUI.stop();
                  $ctrl.run.member_watchers.data = [];
                } else {
                  blockUI.stop();
                }
              }, function () {
                $ctrl.run.starred = oldRun.starred;
                blockUI.stop();
              });
            }
          }

          /**
           * @ngdoc method
           * @name getStatus
           * @public
           * @description get status
           * @param {Object} run
           */
          function getStatus(run) {
            return RunsService.getViewHelperRunStatus(run);
          }

          /**
           * @ngdoc method
           * @name getTimelineAssignees
           * @public
           * @description Get assignees for current and next task
           */
          function getTimelineAssignees() {
            $ctrl.relativeDeadline = $ctrl.run.max_task_deadline;
            $ctrl.currentTask = _.get(
              $ctrl.run,
              "tasks_meta.data.current_task",
              {}
            );
            $ctrl.nextTask = _.get($ctrl.run, "tasks_meta.data.next_task", {});
            $ctrl.lastCompletedTask = _.get(
              $ctrl.run,
              "tasks_meta.data.last_completed_task",
              {}
            );
            if (
              Helper.isObjectEmpty($ctrl.nextTask) &&
              !Helper.isObjectEmpty($ctrl.lastCompletedTask)
            ) {
              $ctrl.nextTask = $ctrl.currentTask;
              $ctrl.currentTask = angular.extend($ctrl.lastCompletedTask, {
                isCompleted: true,
              });
              $ctrl.mostCurrentTaskUnformattedDeadline = DateUtils.toLocal(
                _.get($ctrl.nextTask, "deadline")
              );
              setSelectedTask("next");
            } else {
              if (
                Helper.isObjectEmpty($ctrl.nextTask) &&
                Helper.isObjectEmpty($ctrl.lastCompletedTask)
              ) {
                $ctrl.nextTask = $ctrl.currentTask;
                $ctrl.currentTask = angular.extend({}, {
                  title: 'No previous tasks',
                  isCompleted: true,
                  isPlaceholder: true
                });
              }
              $ctrl.mostCurrentTaskUnformattedDeadline = DateUtils.toLocal(
                _.get($ctrl.currentTask, "deadline")
              );
              setSelectedTask($ctrl.currentTask.isPlaceholder ? "next" : "current");
            }
            getAssignee($ctrl.currentTask);
            getAssignee($ctrl.nextTask);
          }

          /**
           * @ngdoc method
           * @name setSelectedTask
           * @public
           * @description set the selected task
           * @param {*} task
           */
          function setSelectedTask(type) {
            $ctrl.isCurrentTaskSelected = type === "current";
          }

          /**
           * @ngdoc method
           * @name getAssignee
           * @public
           * @description get assignee of most current task
           * @param {*} task
           */
          function getAssignee(task) {
            if (!task || task.isPlaceholder) {
              return;
            }
            var assignee = _.get(task, "owners"),
              owners = _.filter($ctrl.users, function (item) {
                return _.indexOf(_.get(assignee, "users", []), item.id) >= 0;
              });
            var groups = _.filter($ctrl.orgGroups, function (item) {
              return _.indexOf(_.get(assignee, "groups", []), item.id) >= 0;
            });
            angular.extend(task, {
              timeline: {
                owners: UsersService.moveCurrentUserIndex(angular.copy(owners)),
                guests: _.get(assignee, "guests", []),
                groups: groups,
                ownersName: _.unionWith(
                  _.map(groups, "name"),
                  _.map(owners, "text"),
                  _.get(assignee, "guests", [])
                ).join(", "),
                allOwner: _.concat(groups, owners, _.get(assignee, "guests", [])),
                status: $ctrl.getBarClass === 'progress-danger' ? 'overdue' : $ctrl.getBarClass === 'progress-warning' ? 'due-soon' : 'ontime',
              },
            });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name getDeadlineWithStatus
           * @public
           * @description get deadline with status
           * @param {String} status
           * @param {String} deadline
           */
          function getDeadlineWithStatus(status, deadline) {
            return _.get(status, 'statusId') !== 'archived'
              ? _.get(status, 'runStatus') + ', ' + $filter('translate')('runs.process.processStatus.due') + deadline
              : $filter('translate')('runs.process.processStatus.archived');
          }

          /**
           * @ngdoc method
           * @name onRunMenuViewClick
           * @public
           * @description check authority for archive process
           * @param {*} $event
           */
          function onRunMenuViewClick() {
            angular.element(document.querySelector('body')).click();
            $ctrl.isOpenPopOver = true;
          }

          /**
           * @ngdoc method
           * @name onRunMenuViewClick
           * @public
           * @description check authority for archive process
           * @param {*} $event
           */
          function onMoreTagsViewClick($event) {
            angular.element(document.querySelector('body')).click();
            $ctrl.isTagsPopOverOpen = true;
          }

          /**
           * @ngdoc method
           * @name onRunMenuArchiveClick
           * @public
           * @description On Run Menu > Archive click
           * @param {*} run
           * @param {event} $event
           */
          function onRunMenuArchiveClick(run, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            if(!Helper.checkAccessAuthority()) {
              $ctrl.isOpenPopOver = false;
              return;
            }
            $ctrl.onArchive({ run: run });
          }

          /**
           * @ngdoc method
           * @name onArchiveClick
           * @public
           * @description set Process in Archive
           * @param {Object} run
           * @param {event} $event
           */
          function onArchiveClick(run) {
            $ctrl.onArchive({ run: run });
          }

          function addProcessToFolders($event) {
            $event.preventDefault();
            $event.stopPropagation();
            var openProcessFoldersModal = FolderService.openObjectFoldersModal($ctrl.foldersList, $ctrl.run.id, 'Run', $ctrl.run.folders.data, $ctrl.run.name);
            openProcessFoldersModal.result.then(function (data) {
              $ctrl.run.folders.data = data;
            }, function (data) {
              $ctrl.run.folders.data = data;
            });
          }

          //Method to get tag background color
          function getTagBackgroundColor (tag) {
            if (!tag.color) {
              return TAG.TAGCOLOR;
            }
            return tag.color;
          }

          //Method to get tag text color
          function getTagTextColor(color) {
            return TagsService.isTagTextColor(color);
          }

          //Method to get tag overflow text
          function getTagOverflowText () {
            if (!$ctrl.run.tags.data.length) {
              return '';
            }
            var tagText = '+' + ($ctrl.run.tags.data.length - $ctrl.tagsLimit);
            return tagText;
          }

          //Method to get remaining tags list for tooltip
          function getTagTooltipText () {
            if (!$ctrl.run.tags.data.length) {
              return;
            }
            return _.slice($ctrl.run.tags.data, $ctrl.tagsLimit);
          }

          //Method to navigate to blueprint view
          function goToBlueprint () {
            if ($ctrl.isGuest && !$ctrl.run.can_view_template && !$ctrl.run.template_is_public) {
              return;
            }
            var _blockUI = blockUI.instances.get('runDashboard'), hasReadPermission;
            _blockUI.start();
            if ($ctrl.isGuest) {
              _blockUI.stop();
              if ($ctrl.run.template_is_public || $ctrl.run.can_view_template) {
                $state.go('guest.templates', { org_id: $ctrl.organizationId, blueprint_id: $ctrl.run.checklist_id, status: $ctrl.run.template_is_public ? 'example' : 'uncategorized' });
              }
            } else {
              $state.go('process.edit', { slug: $ctrl.run.checklist_id });
            }
          }

          //Method to navigate to process view with upcoming task selected
          function goToUpcomingTask (e) {
            e.stopPropagation();
            var nextTask = _.get($ctrl.run, 'tasks_meta.data.current_task', {});
            if (!Helper.isObjectEmpty(nextTask)) {
              $ctrl.isGuest ? $state.go('guest.runs.view', { id: $ctrl.run.id, activeTask: nextTask.id }) : $state.go('run.view', { id: $ctrl.run.id, activeTask: nextTask.id });
            }
          }

          //Wrapper method for un-archiving
          function onUnarchiveLocal (run) {
            $ctrl.onUnArchive(run);
          }

          //Wrapper method to delete run
          function deleteRun () {
            $ctrl.onPermanentlyDeleteRun();
          }

          //Get process owner
          function getProcessOwner () {
            store.getUsers().then(function (res) {
              $ctrl.runOwner = _.find(res, { id: $ctrl.run.owner_id });
            })
          }
        }
    });
})();

(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicLibraryView', {
      templateUrl: 'app/components/public/pages/library/public-library-view.component.html',
      bindings: {
        isNotPublicView: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $filter, $state, $stateParams, $timeout, PublicLibraryService, PublicTagService, DOMService, FolderService) {
          var $ctrl = this, publicLibraryQueryParams, $publicLibraryPromiseCall, scrollIntoViewTimeoutHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.goToBlueprintEditor = goToBlueprintEditor;
          $ctrl.goToNextPage = goToNextPage;
          $ctrl.onTypeClick = onTypeClick;
          $ctrl.onIndustryClick = onIndustryClick;
          $ctrl.onTopicClick = onTopicClick;
          $ctrl.onSearchTextChanged = onSearchTextChanged;
          $ctrl.resetFilter = resetFilter;

          $ctrl.processType = PublicLibraryService.getBlueprintTypes();
          $ctrl.publicIndustryTags = [];
          $ctrl.publicTopicTags = [];
          $ctrl.delayFromExample = false;

          function onInit() {
            $timeout(function() {
              $ctrl.delayFromExample = true;
              $ctrl.publicOrganization = $rootScope.publicOrganization;
              $ctrl.selectedType = _.find($ctrl.processType, function (tag) {
                var title = _.get(tag, 'title', ''),
                  type = _.get($stateParams, 'type', '');
                return (title || '').toLowerCase() === (type || '').toLowerCase();
              });
              getIndustryTags();
              getTopicTags();
              getAllPublicLibrary();
            }, 1000);
            if (_.get($rootScope.toStateParams, 'folder_id')) {
              FolderService.getFolderById({ id: _.get($rootScope.toStateParams, 'folder_id'), skipNotFound: true })
                .then(function (response) {
                  $ctrl.selectFolder = _.get(response, 'data');
                });
            }
          }

          function onChanges() { }

          function onDestroy() {
            if (scrollIntoViewTimeoutHandler) {
              $timeout.cancel(scrollIntoViewTimeoutHandler);
            }
          }

          function getIndustryTags() {
            $ctrl.isIndustryTagBusy = true;
            var params = {
              action: 'industry',
              page: 1,
              per_page: 999,
              pagination: true
            };
            if (_.get($ctrl.publicOrganization, 'name')) {
              params.org = $ctrl.publicOrganization.id;
            } else {
              params.is_featured = true;
            }
            params.all_tags = !params.org;
            PublicTagService.getPublicTags(params).then(function (res) {
              $ctrl.publicIndustryTags = res.data;
              $ctrl.selectedIndustry = _.find($ctrl.publicIndustryTags, function (tag) {
                var industryParam = _.get($stateParams, 'industry', '');
                return tag.title && tag.title.toLowerCase() === (_.isNull(industryParam) ? '' : industryParam).toLowerCase();
              });
              $ctrl.isIndustryTagBusy = false;
            }, function () {
              $ctrl.isIndustryTagBusy = false;
            });
          }

          function getTopicTags() {
            $ctrl.isTopicTagBusy = true;
            var params = {
              action: 'topic',
              page: 1,
              per_page: 999,
              pagination: true
            };
            if (_.get($ctrl.publicOrganization, 'name')) {
              params.org = $ctrl.publicOrganization.id;
            } else {
              params.is_featured = true;
            }
            params.all_tags = false;
            PublicTagService.getPublicTags(params).then(function (res) {
              $ctrl.publicTopicTags = res.data;
              $ctrl.selectedTopic = _.find($ctrl.publicTopicTags, function (tag) {
                var topicParam = _.get($stateParams, 'topic', '');
                return tag.title && tag.title.toLowerCase() === (_.isNull(topicParam) ? '' : topicParam).toLowerCase();
              });
              $ctrl.isTopicTagBusy = false;
            }, function () {
              $ctrl.isTopicTagBusy = false;
            });
          }

          function getPublicLibrary(params) {
            var defer = $q.defer();
            PublicLibraryService
              .getPublicLibrary(params)
              .then(function (res) {
                defer.resolve(res);
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          function getAllPublicLibrary(query, centerElement) {
            if (!!$publicLibraryPromiseCall && !$publicLibraryPromiseCall.$$state.status) {
              $publicLibraryPromiseCall.resolve();
            }
            $ctrl.publicLibraries = [];
            var params = angular.extend({}, publicLibraryQueryParams, {
              type: _.toLower(_.get($ctrl.selectedType, 'title', void 0)),
              industryTag: _.get($stateParams, 'industry', '') || _.toLower(_.get($ctrl.selectedIndustry, 'title', void 0)),
              topicTag: _.get($stateParams, 'topic', '') || _.toLower(_.get($ctrl.selectedTopic, 'title', void 0)),
              page: $stateParams.page || $ctrl.selectedPage || 1,
              per_page: 18,
              with: 'topic_tags,industry_tags',
              q: query
            });
            if (_.get($ctrl.publicOrganization, 'name')) {
              angular.extend(params, {
                action: 'organizations',
                action_id: $ctrl.publicOrganization.id
              });
            }
            $ctrl.isBusy = true;
            $publicLibraryPromiseCall = getPublicLibrary(params);
            $publicLibraryPromiseCall.then(function (res) {
              $ctrl.publicLibraries = res.data;
              $ctrl.pagination = res.meta.pagination;
              $ctrl.isBusy = false;
            }, function () {
              $ctrl.isBusy = false;
            });
            if (centerElement) {
              scrollIntoViewTimeoutHandler = $timeout(function () {
                DOMService.centerObjectToView('.library-main-top');
              }, 0);
            }
          }

          function goToBlueprintEditor(blueprint) {
            if ($ctrl.isNotPublicView) {
              return;
            }
            if (_.get($ctrl.publicOrganization, 'name')) {
              $state.go('public.library.organization.detail', {
                orgId: $ctrl.publicOrganization.id,
                blueprintId: blueprint.id
              });
            } else {
              $state.go('public.library.combo.detail', {
                bpOrgId: blueprint.organization_id,
                blueprintId: blueprint.id
              });
            }
          }

          function goToNextPage(page) {
            $ctrl.selectedPage = page;
            $state.transitionTo($state.current, angular.extend($stateParams, { page: page }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onTypeClick(tag) {
            $ctrl.selectedPage = 1;
            $ctrl.selectedType = (_.get($ctrl.selectedType, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { type: $ctrl.selectedType ? $ctrl.selectedType.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onIndustryClick(tag) {
            $ctrl.selectedPage = 1;
            $ctrl.selectedIndustry = (_.get($ctrl.selectedIndustry, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { industry: $ctrl.selectedIndustry ? $ctrl.selectedIndustry.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onTopicClick(tag) {
            $ctrl.selectedPage = 1;
            $ctrl.selectedTopic = (_.get($ctrl.selectedTopic, 'id') === tag.id) ? void 0 : tag;
            $state.transitionTo($state.current, angular.extend($stateParams, { topic: $ctrl.selectedTopic ? $ctrl.selectedTopic.title.toLowerCase() : void 0, page: 1 }), { notify: false });
            getAllPublicLibrary('', true);
          }

          function onSearchTextChanged(value) {
            $ctrl.selectedPage = 1;
            getAllPublicLibrary(value, true);
          }

          function resetFilter(value) {
            switch (value) {
              case 'type':
                $ctrl.selectedType = [];
                break;
              case 'industry':
                $ctrl.selectedIndustry = [];
                break;
              case 'topic':
                $ctrl.selectedTopic = [];
                break;
            }
            $ctrl.selectedPage = 1;
            getAllPublicLibrary('', true);
          }
        }
    });
})();
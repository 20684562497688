
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(translationConfig)
    .run(
      /*@ngInject*/
      function ($rootScope, $translate, ENV_CONFIG, $log, $language) {
        var language = $language.getClientLang();
        if ($language.hasLang(language)) {
          $translate.use(language);
        } else {
          $translate.use(ENV_CONFIG.LANG_KEY);
        }
        $rootScope.currentLanguage = language;
        $log.info('Application Language- ' + language);
      })
    .factory("i18nPartialLoaderErrorHandler", ["$q", function ($q) {
      return function (partName, languageKey) {
        console.error('Unable to load ' + languageKey + '/' + partName + '.json locale file.')
        return $q.when({});
      };
    }]);
  /*@ngInject*/
  function translationConfig($translateProvider, tmhDynamicLocaleProvider, ENV_CONFIG, $languageProvider, TFY_LOCALE) {
    /**
     * Configure to translate provider to lazy load locale file.
     * Append a buildVersion to make a unique request after each deployment
     */
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: 'resources/i18n/{lang}/{part}.json' + '?cacheBuster=' + ENV_CONFIG.buildVersion,
      loadFailureHandler: "i18nPartialLoaderErrorHandler"
    });
    $translateProvider.registerAvailableLanguageKeys(TFY_LOCALE.locales, TFY_LOCALE.mapping);
    var language = $languageProvider.getClientLang();
    $translateProvider.determinePreferredLanguage(function () {
      return $languageProvider.hasLang(language) ? language : ENV_CONFIG.LANG_KEY;
    });
    $translateProvider.useStorage('translationStorageProvider');
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

    tmhDynamicLocaleProvider.localeLocationPattern('resources/i18n/angular-locale_{{locale}}.js');
    tmhDynamicLocaleProvider.useCookieStorage();
    $translateProvider.fallbackLanguage(ENV_CONFIG.LANG_KEY);
  }

})();
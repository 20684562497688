/**
  * @ngdoc factory
  * @name tallyfy.messages.factory.EmailSettingsRepository
  * @module tallyfy.organizations
  *
  * @description
  * Create Rest resource for org email config
  *
  * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com)
  * 
  * */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .factory('EmailSettingsRepository', EmailSettingsRepository);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  EmailSettingsRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * EmailSettingsRepository construct
   */
  function EmailSettingsRepository(BaseRepository) {
    var BASE_URI_SEGMENT = '/organizations/:org', repository;

    repository = BaseRepository(BASE_URI_SEGMENT + '/:action', {
      org: 'org_id',
      action: '@action'
    }, {
      $get: { method: 'GET' },
      $create: { method: 'POST' },
      $update: { method: 'PUT' },
      $delete: { method: 'DELETE' }
    });

    repository.createConnection = function (data) {
      return repository.create(angular.extend(data, { action: 'smtp' }));
    };

    repository.enableSMTP = function (data) {
      var params = {
        action: 'enable-smtp'
      };
      return repository.update(params, data);
    };

    return repository;
  }
})();
/**
 * @ngdoc component
 * @name tallyfy.compactComment
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactComment', {
      templateUrl: 'app/components/compact/items/compactComment/compact-comment.component.html',
      bindings: {
        isGuest: '<?',
        isPublicProcess: '<',
        isGuestAssigned: '<',
        task: '<',
        step: '<',
        viewerId: '<',
        usersInOrg: '<',
        isHasIssue: '<',
        isOpen: '<',
        displayStyle: '<',
        highlightedId: '<',
        readOnlyCommentContainer: '<',
        orgGroups: '<',
        enableFilter: '<',
        process: '<?',
        asPreview: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, $state, CompactTaskService, USER_STATE, CompactStepService, MessagesService, MESSAGE_TYPE, Helper, $analytics, DOMService, $timeout, TFY_EVENTS, growl, UsersService, FroalaService, CONST, store) {
          var $ctrl = this,
            unResolvedIssues,
            statusWatcher,
            threadDeletedHandler,
            taskThreadsWatcher,
            stepSelectedItemThreadsWatcher,
            onNudgeCommentHandler, onCommentBoxHandler, resetCommentTagUpdateHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.submitComment = submitComment;
          $ctrl.onShowNextComment = onShowNextComment;
          $ctrl.resetCommentDisplay = resetCommentDisplay;
          $ctrl.selectVisibility = selectVisibility;
          $ctrl.selectTag = selectTag;
          $ctrl.isActiveUserAssign = isActiveUserAssign;
          $ctrl.commentTypesList = commentTypesList;
          $ctrl.onFilterValueChanged = onFilterValueChanged;

          $ctrl.isAssignToGuest = false;
          $ctrl.filteredThreads = [];
          $ctrl.showLatestComment = true;
          $ctrl.commentPage = 0;
          $ctrl.enquireComment = '';
          $ctrl.nextCommentCount = 0;
          $ctrl.visibilityTypes = MessagesService.COMMENT_VISIBILITY();
          $ctrl.filterLength = CONST.COMMENT_FILTER_LENGTH;
          $ctrl.commentFilter = [];
          $ctrl.publiclyComments = false;

          function onInit() {
            $ctrl.isPublicProcess = $rootScope.userState === USER_STATE.PUBLIC_PROCESS;
            $ctrl.currentUser = $rootScope.identity;
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              isGuest: $ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned),
              initOnClick: true,
              accessibility: {
                haveAuthority: $ctrl.haveAuthority,
                callback: Helper.checkAccessAuthority
              },
              placeholder: $filter('translate')($ctrl.task ? 'messages.taskCommentPlaceholder' : 'messages.bpCommentPlaceholder'),
              allowEdit: true,
              currentUser: $ctrl.currentUser,
              mentionData: prepareMentionData(),
              limit: 30
            }, true);
            if ($ctrl.task) {
              $ctrl.froalaOptions.initializedCallback = function (model) {
                if (!model.$modelValue) {
                  trackEvent('Task - Right Pane > Task - Right Pane - Add Comment Box', { category: 'Task - Right Pane' });
                }
              };
            }
            $ctrl.displayStyle === 'always-open' ? $ctrl.isOpen = true : angular.noop();
            $ctrl.commentFilter = $ctrl.enableFilter ? [] : MessagesService.getCommentFilters(true);
            $ctrl.onFilterValueChanged($ctrl.commentFilter);
            getPubliclyHiddenComments(_.get($ctrl.task, 'run.data.publicly_hidden_fields'));
            $ctrl.commentTypes = MessagesService.COMMENT_TYPES(!_.isUndefined($ctrl.isHasIssue) ? $ctrl.isHasIssue : false, $ctrl.isGuest, $ctrl.isNone, $ctrl.isImproveAdvice, $ctrl.isReportResolve);
            if ($ctrl.task) {
              resetCommentForm();
            }
          }

          function onChanges(changes) {
            if (changes.task) {
              if (changes.task.currentValue && changes.task.currentValue !== changes.task.previousValue) {
                var step = _.get(changes.task, 'currentValue.step.data', []);
                setThreads();
                if (!$ctrl.isPublicProcess) {
                  mapThreads();
                }
                $ctrl.isAssignToGuest = (step && _.get(step, 'metadata.is_assign_guest', 'no') === 'yes' && changes.task.currentValue.owners.guests.length <= 0);
                $ctrl.commentPage = 0;
                $ctrl.onShowNextComment();
              }
            }
            if (changes.step) {
              if (changes.step.currentValue && changes.step.currentValue !== changes.step.previousValue) {
                setThreads();
                mapThreads();
                $ctrl.commentPage = 0;
                $ctrl.onShowNextComment();
              }
            }
            if (changes.isOpen && changes.isOpen.currentValue) {
              if(_.get($state, 'params.activeTab') !== 'view') {
                $timeout(function () {
                  DOMService.centerObjectToView('.task-comment-form-container', {
                    behavior: "smooth",
                    block: "center",
                    inline: "end"
                  });
                }, 3000);
              }
            }
            if (changes.highlightedId && changes.highlightedId.currentValue) {
              var commentHighlightTimeoutHandler = $timeout(function () {
                $ctrl.isOpen = true;
                $timeout.cancel(commentHighlightTimeoutHandler);
              }, 500)
              commentHighlightTimeoutHandler.then(function () {
                $timeout(function () {
                  DOMService.centerObjectToView('.comment-highlighted', {
                    behavior: "smooth",
                    block: "center"
                  });
                }, 1500);
              });
            }
          }

          function onDestroy() {
            threadDeletedHandler();
            statusWatcher();
            taskThreadsWatcher();
            postEnquireCommentHandler();
            onNudgeCommentHandler();
            stepSelectedItemThreadsWatcher();
            onCommentBoxHandler();
            resetCommentTagUpdateHandler();
          }

          function selectTag(type) {
            $ctrl.selectedTagType = type;
          }

          function selectVisibility(type) {
            $ctrl.selectedVisibilityType = type;
          }

          function isActiveUserAssign() {
            if (_.get($ctrl.currentUser, 'id')) {
              var owners = _.get($ctrl.task, 'owners');
              if (_.get(owners, 'groups', []).length > 0) {
                var list = _.filter(_.get(owners, 'groups'), function (group) {
                  if (_.get(group, 'users', []).length > 0) {
                    return _.includes(_.get(group, 'users'), _.get($ctrl.currentUser, 'id'));
                  }
                });
                if (list.length > 0) {
                  return true;
                }
              }
              if (_.get(owners, 'users').length > 0) {
                return _.includes(_.get(owners, 'users'), _.get($ctrl.currentUser, 'id')) || false;
              }
            }
            return _.get($ctrl.currentUser, 'guest.email');
          }

          function submitTaskComment(isShowMessage) {
            $ctrl.onSaving = true;
            $ctrl.messageType = ($ctrl.isGuest && $ctrl.typeChecked) ? ($ctrl.isHasIssue ? MESSAGE_TYPE.RESOLVE : MESSAGE_TYPE.REPORT) :
              $ctrl.isGuest ? MESSAGE_TYPE.SEND_COMMENT : _.get($ctrl.selectedTagType, 'value', MESSAGE_TYPE.SEND_COMMENT);
            if ($ctrl.comment === null && $ctrl.commentForm.$invalid) {
              $ctrl.commentForm.comment.$setValidity('required', false);
              $ctrl.onSaving = false;
              return;
            }
            var state = _.get($ctrl.selectedVisibilityType, 'value') === 'hide-for-guests' && !($ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned)) ? MESSAGE_TYPE.HIDE_FOR_GUESTS : MESSAGE_TYPE.OPEN;
            unResolvedIssues = CompactTaskService.getUnResolvedIssue($ctrl.threads);
            CompactTaskService.sendCommand({
              content: $ctrl.comment,
              task: $ctrl.task,
              type: $ctrl.messageType,
              unResolvedIssues: unResolvedIssues,
              isGuest: $ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned),
              state: state
            }, _.get($ctrl.selectedTagType, 'label')).then(function (res) {
              if (_.includes(['resolve', 'report'], _.get($ctrl.selectedTagType, 'label'))) {
                _.filter($ctrl.commentTypes, function (step) {
                  step.label === 'resolve' ? step.isDisable = !step.isDisable
                    : step.label === 'report' ? step.isDisable = !step.isDisable
                      : angular.noop();
                });
              }
              $ctrl.comment = '';
              resetCommentForm();
              postCommentSend(res, isShowMessage);
            }, errorCommentHandler);
          }

          function submitStepComment(isShowMessage) {
            $ctrl.onSaving = true;
            $ctrl.messageType = MESSAGE_TYPE.SEND_COMMENT;
            if ($ctrl.commentForm.$invalid) {
              $ctrl.commentForm.comment.$setValidity('required', false);
              $ctrl.onSaving = false;
              return;
            }
            var state = MESSAGE_TYPE.OPEN;
            CompactStepService.sendCommand({
              content: $ctrl.comment,
              step: $ctrl.step,
              type: $ctrl.messageType,
              state: state,
              skipNotFound: true
            }).then(function (res) {
              postStepCommentSend(res, isShowMessage);
            }, errorStepCommentHandler);
            $ctrl.comment = '';
          }

          function postStepCommentSend(res) {
            var eventAction = 'none';
            $ctrl.threads.push(res);
            _.set($ctrl.step, 'threads.data', $ctrl.threads)
            $ctrl.isOpen = true;
            if (!$ctrl.isPublicProcess) {
              mapThreads();
            }
            resetCommentForm();
            trackEvent('Step comment added', {
              action: eventAction
            });
            $ctrl.commentForm.$submitted = false;
            $ctrl.onSaving = false;
            $ctrl.commentPage = 0;
            $ctrl.onShowNextComment();
          }

          function errorStepCommentHandler(error) {
            if (error.status === 404) {
              growl.error($filter('translate')('steps.messages.stepNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              $state.reload();
            }
            $ctrl.onSaving = false;
          }

          function submitComment(isShowMessage) {
            $ctrl.task ? submitTaskComment(isShowMessage) : submitStepComment();
          }

          function errorCommentHandler() {
            $ctrl.onSaving = false;
          }

          function postCommentSend(res, isShowMessage) {
            unResolvedIssues = CompactTaskService.getUnResolvedIssue($ctrl.threads);
            var eventAction;
            if ($ctrl.messageType === MESSAGE_TYPE.RESOLVE) {
              angular.extend($ctrl.task, {
                hasIssues: false
              });
              var unResolvedIssuesId = _.get(unResolvedIssues[0], 'id');
              if (unResolvedIssuesId) {
                var index = _.findIndex($ctrl.threads, { 'id': unResolvedIssuesId });
                $ctrl.threads[index].resolve_id = res.id;
                eventAction = 'resolve-issue';
                $ctrl.task.problem = unResolvedIssues.length > 1;
                $rootScope.$emit('ISSUE:RESOLVED', {
                  'issue': res
                });
              }
            } else if ($ctrl.messageType === MESSAGE_TYPE.REPORT) {
              eventAction = 'report-issue';
              angular.extend($ctrl.task, {
                hasIssues: true
              });
              $rootScope.$emit('ISSUE:REPORTED', {
                'issue': res
              });
            } else {
              eventAction = 'none';
            }
            $ctrl.task.threads.data.push(res);
            if ($ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned)) {
              $rootScope.$emit('VIEW:ISSUE_STATUS_UPDATE', { 'issue': $ctrl.task });
            }
            $ctrl.isOpen = true;
            if (!$ctrl.isPublicProcess) {
              mapThreads();
            }
            resetCommentForm();
            trackEvent('Comment added', {
              action: eventAction
            });
            $ctrl.commentForm.$submitted = false;
            $ctrl.onSaving = false;
            $ctrl.commentPage = 0;
            $ctrl.onShowNextComment();
            $rootScope.$emit('COMPACT_TASK:UPDATE', { task: $ctrl.task });
            isShowMessage ? growl.success($filter('translate')('global.task.messages.enquireCommentPosted'), { referenceId: 'global', disableIcons: true, disableCloseButton: true }) : '';
            var isAnyUserInvited = CONST.EMAIL_REGEX_START_WITH_PLUS.test(res.content);
            if (isAnyUserInvited) {
              $rootScope.$emit('COMPACT_TASK_COMMENT:NEW_USER_INVITED', { task: $ctrl.task });
            }
          }

          function resetCommentForm() {
            $ctrl.typeChecked = false;
            $ctrl.selectedTagType = _.find($ctrl.commentTypes, { label: 'comment' });
            $ctrl.selectedVisibilityType = _.find($ctrl.visibilityTypes, { label: 'hideFromGuest' });
          }

          /**
           * @ngdoc method
           * @name getAuthorDetails
           * @description get author details
           */
          function mapThreads() {
            _.map($ctrl.threads, function (thread) {
              thread.author = _.find($ctrl.usersInOrg, { id: thread.author_id });
              if ($ctrl.task) {
                if (Helper.isObjectEmpty(_.get(thread, 'reactions.data'))) {
                  thread.reactions = {
                    data: []
                  };
                }
              }
            });
          }

          /**
           * @name trackEvent
           * @param {*} eventName
           * @param  {*} properties
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = !_.get($ctrl.task, 'run_id') ? 'standalone' : 'process';
            properties.processId = $ctrl.process ? $ctrl.process.id : void 0;
            properties.taskId = _.get($ctrl.task, 'id');
            $analytics.eventTrack(eventName, properties);
          }

          /**
           * @ngdoc method
           * @name prepareMentionData
           * @description
           * prepare at mention user data
           * @returns {*}
           */
          function prepareMentionData() {
            return MessagesService.getMentionData($ctrl.threads, $ctrl.currentUser, _.concat(UsersService.usersWithoutBot($ctrl.usersInOrg), $ctrl.orgGroups), $ctrl.task);
          }

          function onShowNextComment(isMultipleHighligh) {
            if (isMultipleHighligh) {
              $ctrl.highlightedId = void 0;
            }
            $ctrl.commentPage = $ctrl.commentFilter && $ctrl.commentFilter.length < CONST.COMMENT_FILTER_LENGTH ? Math.ceil($ctrl.filteredThreads.length / CONST.COMMENT_PER_PAGE) : $ctrl.commentPage + 1;
            var activeComment = !$ctrl.commentFilter || ($ctrl.commentFilter.length === CONST.COMMENT_FILTER_LENGTH) ? $ctrl.threads.length : $ctrl.filteredThreads.length;
            $ctrl.nextCommentCount = activeComment - ($ctrl.commentPage * CONST.COMMENT_PER_PAGE);
          }

          function resetCommentDisplay() {
            $ctrl.commentPage = 0;
            $ctrl.onShowNextComment();
          }

          function setThreads() {
            if ($ctrl.task) {
              var threads = _.uniqBy(_.get($ctrl.task, 'threads.data', []), 'id');
              $ctrl.threads = _.get($ctrl.task, 'is_approved') ? _.filter(threads, function (thread) {
                return thread.label !== 'rejected-comment';
              }) : threads;
            } else if ($ctrl.step) {
              $ctrl.threads = _.uniqBy(_.get($ctrl.step, 'threads.data', []), 'id');
            }
          }

          /**
           * @ngdoc method
           * @name commentTypesList
           * @description
           * Listing for comment types
           * @param  {*} comment type
           * @returns {*}
          */
          function commentTypesList(type) {
            if (type.label === 'comment') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, !$ctrl.isNone, !$ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (type.label === 'improvement' || type.label === 'advice') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isImproveAdvice, $ctrl.isReportResolve);
            } else if (type.label === 'report' || type.label === 'resolve') {
              $ctrl.commentTypes = MessagesService.COMMENT_TYPES($ctrl.isHasIssue, $ctrl.isGuest, $ctrl.isNone, !$ctrl.isImproveAdvice, !$ctrl.isReportResolve);
            }
          }

          function onFilterValueChanged(value) {
            $ctrl.commentFilter = value;
            $ctrl.resetCommentDisplay();
          }

          function getPubliclyHiddenComments(value) {
            value = value || [];
            for (var i = 0; i < value.length; i++) {
              if (value[i]=== 'comments') {
                break;
              }
            }
          }

          statusWatcher = $scope.$watch('$ctrl.task.status', function (oldValue, newValue) {
            if (newValue === 'completed' || oldValue === 'completed') {
              setThreads();
            }
          });

          threadDeletedHandler = $rootScope.$on(TFY_EVENTS.TASK.COMMENT.DELETED, function () {
            $ctrl.resetCommentDisplay();
          });

          var postEnquireCommentHandler = $rootScope.$on('ENQUIRY_STATUS:TASK_ADD_COMMENT', function (event, res) {
            $ctrl.task = res.task;
            $ctrl.comment = angular.copy(res.comment);
            $ctrl.commentForm.$setPristine();
            $ctrl.commentForm.$setValidity();
            submitComment(true);
          });

          stepSelectedItemThreadsWatcher = $scope.$watchCollection('$ctrl.step.threads.data', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            if (newValue.length !== oldValue) {
              $ctrl.highlightedId = void 0;
            }
          });

          taskThreadsWatcher = $scope.$watchCollection('$ctrl.task.threads.data', function (value) {
            if (!!value) {
              var step = _.get($ctrl.task, 'step.data', []);
              setThreads();
              if (!$ctrl.isPublicProcess) {
                mapThreads();
              }
              $ctrl.isAssignToGuest = (step && _.get(step, 'metadata.is_assign_guest', 'no') === 'yes' && changes.task.currentValue.owners.guests.length <= 0);
              $ctrl.commentPage = 0;
              $ctrl.onShowNextComment();
            }
          });

          onNudgeCommentHandler = $rootScope.$on('FROALA_BOX_OPEN:ADD_COMMENT', function (event, res) {
            DOMService.centerObjectToView('.task-comment-form-container', {
              behavior: "smooth",
              block: "center",
              inline: "end"
            });
            var groups = [], orgGroups = [];
            if(!$ctrl.isGuest) {
              store.getGroups().then(function (response) {
                orgGroups = response;
                groups = _.get(res, 'assignUser.groups', []);
              }, function () { });
            }
            $timeout(function () {
              if (!$ctrl.froalaOptions.editorInitialized) {
                $ctrl.froalaOptions.initOnClick = false;
                $ctrl.froalaOptions.reInitializeEditor();
              }
              if (_.get(res, 'comment', null)) {
                var data = {
                  comment: _.get(res, 'comment', null),
                  users: ($ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned)) ? _.map(
                    _.get(res, 'assignUser.users', []),
                    function (user) {
                      return user.id;
                    }
                  ) : _.get(res, 'assignUser.users', []),
                  guests: _.get(res, 'assignUser.guests', []),
                  orgUsers: _.get(res, 'users', []),
                  groups: groups,
                  orgGroups: orgGroups
                };
                $ctrl.comment = FroalaService.setCommentWithAssignUsers(data);
              }
            }, 1000);
          });

          onCommentBoxHandler = $rootScope.$on('BLUEPRINT:COMMENTS_OPEN', function (e, data) {
            $ctrl.isOpen = data.isOpen;
            $timeout(function () {
              DOMService.centerObjectToView('.task-comment-form-container', {
                behavior: "smooth",
                block: "center",
                inline: "end"
              });
            }, 0);
          });

          resetCommentTagUpdateHandler = $rootScope.$on('COMMENT_TAG:RESET_TAG', function () {
            resetCommentForm();
          });
        }
    });
})();
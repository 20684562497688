/**
 * @ngdoc Component
 * @name tallyfy.info.component
 * @module tallyfy.info
 *
 * @description
 * A component to show information.
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  var infoComponent = {
    bindings: {
      onInfoAction: '&',
      infoName: '<',
      action: '<',
      isDisabled: '<'
    },
    templateUrl: 'app/modules/info/info.html',
    controller: ['$rootScope', 'CONFIG', function ($rootScope, CONFIG) {
      }]
  };

  angular
    .module('tallyfy')
    .component('infoTask', infoComponent);
})();
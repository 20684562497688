(function () {
  'use strict';

  angular.module('tallyfy')
    .component('emailContentViewer', {
      templateUrl: 'app/modules/securityAndLog/emailContentViewer/email-content-viewer.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:

        /*@ngInject*/
        function (_, $rootScope, SecurityAndLogService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;

          function onInit() {
            getEmailContent({
              url: $ctrl.resolve.mailgunUrl.$$unwrapTrustedValue(),
              key: $ctrl.resolve.key.$$unwrapTrustedValue()
            }).then(function (res) {
              $ctrl.emailContent = res.data.bodyHtml;
              $ctrl.from = res.data.from;
              $ctrl.recipients = res.data.recipients;
              document.getElementById('emailFrame').contentWindow.document.write($ctrl.emailContent);
              $ctrl.subject = res.data.subject;
            }, function (err) {
              $ctrl.close({ $value: err });
            });
          }

          function onChanges() { }

          function getEmailContent(args) {
            var params = {
              org_id: _.get($rootScope.identity, 'default_organization.id'),
              user_email: $ctrl.resolve.user.email,
              key: $ctrl.resolve.key
            };
            if (args) {
              angular.extend(params, args);
            }
            return SecurityAndLogService.getEmailContent(params);
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.taskThreads
 * @module tallyfy.tasks
 *
 * @description
 * taskThreads component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.messages')
    .component('taskThreads', {
      bindings: {
        task: '<',
        step: '<?',
        isExpanded: '=',
        taskStatus: '=',
        threads: "<",
        users: '<',
        groups: '<',
        page: '=?',
        isGuest: '<',
        highlightedId: '<',
        hideReaction: '<',
        asPreview: '<?',
        filter: '<?',
        filteredThreads: '=?',
        editDisabled: '<?',
        process: '<?'
      },
      templateUrl: 'app/modules/messages/threads/messages-threads.html',
      controller:
        /*@ngInject*/
        function ($rootScope, $scope, _, MessagesService, $confirm, blockUI, TFY_EVENTS, Helper, CONST, FroalaService, DateUtils, moment, DATEFORMAT, $filter, TasksService, $state, Growl, $q, RunsService) {
          var $ctrl = this,
            threadsChangesHandler,
            pageWatcherHandler,
            processBlockUI = blockUI.instances.get('editProcess'),
            blockUI = blockUI.instances.get('task-list'),
            growl = new Growl();

          /**
           * component public properties
           */
          $ctrl.editCommentId = undefined;

          /**
           *  angularjs component lifecycle hook
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * component public methods
           */
          $ctrl.onDelete = onDelete;
          $ctrl.onCreateTask = onCreateTask;
          $ctrl.iAmMentioned = iAmMentioned;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.page = 1;
            setDisplayThreads();
          }


          function onChanges(changes) {
            if (changes.filter) {
              setDisplayThreads();
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            isExpandedDetector();
            threadsChangesHandler();
            pageWatcherHandler();
          }

          /**
           * 
           * @param {*} comment 
           */
          function onDelete(comment) {
            if (!Helper.checkAccessAuthority())
              return;
            $confirm({
              'header': 'tasks.messages.modal.discard.header',
              'body': 'tasks.messages.modal.discard.body',
              'buttons': {
                'accept': 'tasks.messages.modal.discard.buttons.accept',
                'cancel': 'tasks.messages.modal.discard.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function (e) {
              blockUI.start();
              MessagesService.commentDelete(comment, $ctrl.isGuest, $ctrl.step).then(function () {
                var resolvedIssue = _.find($ctrl.threads, { resolve_id: comment.id });
                if (resolvedIssue) {
                  resolvedIssue.resolve_id = null;
                }
                if (!resolvedIssue && $ctrl.isGuest) {
                  _.set($ctrl.task, 'hasIssues', true);
                }
                if ($ctrl.isGuest && (comment.label === 'resolve')) {
                  $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { updatedTask: $ctrl.task, reFetchUpdatedTask: true, update_dependent_deadlines: false, fetchWithParams: true });
                }
                if ($ctrl.isGuest) {
                  $ctrl.task.hasIssues = comment.label === "problem" ? false
                    : comment.label === "resolve";
                  $rootScope.$emit('VIEW:ISSUE_STATUS_UPDATE', { 'issue': $ctrl.task });
                }
                $ctrl.editCommentId = undefined;
                _.remove($ctrl.threads, { id: comment.id });
                $ctrl.task ? _.remove($ctrl.task.threads.data, { id: comment.id }) : angular.noop();
                $rootScope.$emit(TFY_EVENTS.TASK.COMMENT.DELETED, { thread: comment });
                blockUI.stop();
              }, function (error) {
                blockUI.stop();
              });
            }, function (e) {
              angular.noop();
            });
          }

          /**
           * @ngdoc method
           * @private
           * 
           * @name filterThreads
           * 
           * @description
           * set the display threads on template
           */
          function setDisplayThreads() {
            var threads = _.orderBy(angular.copy($ctrl.threads), ['created_at']);
            if ($ctrl.filter && $ctrl.filter.length && $ctrl.filter.length < CONST.COMMENT_FILTER_LENGTH) {
              threads = _.filter(threads, function (thread) {
                var isDisplayed = _.filter($ctrl.filter, function (filter) {
                  if (filter.value === 'improvement') {
                    return thread.label === 'improvement' && !thread.auto_generated;
                  } else if (filter.value === 'issue_and_resolution') {
                    return (thread.label === 'problem' || thread.label === 'resolve') && !thread.auto_generated;
                  } else if (filter.value === 'auto_generated') {
                    return thread.auto_generated;
                  } else if (filter.value === 'guest_facing') {
                    return thread.state !== 'hide-for-guests' && !thread.auto_generated;
                  } else if (filter.value === 'collapsed') {
                    return thread.state === 'collapsed' && !thread.auto_generated;
                  } else if (filter.value === 'i_am_mentioned') {
                    return iAmMentioned(thread) && !thread.auto_generated;
                  } else if (filter.value === 'general_comment') {
                    return thread.label === 'comment' && !thread.auto_generated;
                  }
                  return true;
                });
                return isDisplayed.length;
              });
            }
            if (threads.length == 4) {
              $ctrl.page = 2;
            }
            $ctrl.filteredThreads = threads;
            $ctrl.displayThreads = ($ctrl.filter || []).length === CONST.COMMENT_FILTER_LENGTH ? _.slice(threads, (CONST.COMMENT_PER_PAGE * -1) * $ctrl.page) : threads;
            $ctrl.totalNewThreadShown = $ctrl.displayThreads.length - (CONST.COMMENT_PER_PAGE * ($ctrl.page - 1)) > CONST.COMMENT_PER_PAGE ? CONST.COMMENT_PER_PAGE : ($ctrl.displayThreads.length - (CONST.COMMENT_PER_PAGE * ($ctrl.page - 1)));
          }

          function iAmMentioned(thread) {
            var tempEl = angular.element('<div>').html(FroalaService.getViewContent(thread.content, $ctrl.users, []));
            return tempEl.find('.user-id[data-user-id=\'' + _.get($rootScope.identity, 'id') + '\']').length;
          }

          //Create task from comment
          function onCreateTask (comment) {
            if (!$ctrl.iAmMentioned(comment)) {
              return;
            }
            var task = prepareTaskValues(comment);
            $ctrl.step ? processBlockUI.start() : blockUI.start();
            TasksService.createStandaloneTask(task, false).then(function(response) {
              var resources = [];
              if (_.get(task, 'run_id')) {
                resources.push(RunsService.get({
                  id: task.run_id,
                  with: 'checklist'
                }));
              }
              if (_.get(task, 'checklist_id')) {
                resources.push(TasksService.createStandaloneTaskLinkByBlueprint({
                  id: _.get(response.data, 'id'),
                  checklist_id: task.checklist_id
                }));
              }

              $q.all(resources).then(function (res) {
                if (_.get(task, 'run_id')) {
                  angular.extend(response.data, { run: { data : res[0].data } });
                }
                $ctrl.step ? processBlockUI.stop() : blockUI.stop();
                showTaskCreationAlert(response.data);
                $rootScope.$emit('STANDALONE_TASK:CREATED', { task: response.data, taskOpen: true });
              }, function () {
                $ctrl.step ? processBlockUI.stop() : blockUI.stop();
              });
            }, function () {
              $ctrl.step ? processBlockUI.stop() : blockUI.stop();
            });
          }

          //Prepare task values for create
          function prepareTaskValues (comment) {
            var startedAt = DateUtils.toTimezone().value().add(2, 'h').format(), 
            taskObj = {
              started_at: DateUtils.toUTC(moment(startedAt).format()).format(DATEFORMAT.DEFAULT),
              deadline: DateUtils.toUTC(moment(startedAt).add(1, 'd').format()).format(DATEFORMAT.DEFAULT),
              owners: {
                users: !$ctrl.isGuest ? [ _.get($rootScope.identity, 'id') ] : [],
                guests: $ctrl.isGuest ? [ _.get($rootScope.identity, 'guest.email') ] : [],
                groups: []
              },
              task_type: 'task',
              title: $ctrl.step || $ctrl.task.run_id ? ($filter('translate')('tasks.taskFromProcedure', { taskName: $ctrl.step ? $ctrl.step.title : $ctrl.task.title, processName: $ctrl.step ? $ctrl.process.title : $ctrl.process.name })) 
                    : $filter('translate')('tasks.taskFromOOT', { date: moment().format('MMM D') }),
              summary: comment.content
            };

            if ($ctrl.task && $ctrl.task.run_id) {
              taskObj.run_id = $ctrl.task.run_id;
              if ($ctrl.process) {
                taskObj.position = _.size($ctrl.process.prerun) ? $ctrl.process.whole_progress.total : $ctrl.process.whole_progress.total + 1;
              }
            }

            if ($ctrl.step && $ctrl.step.checklist_id) {
              taskObj.checklist_id = $ctrl.step.checklist_id;
            }
            return taskObj;
          }

          var isExpandedDetector = $scope.$watch('$ctrl.isExpanded', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            if ($ctrl.isExpanded === false) {
              $ctrl.editCommentId = undefined;
            }
          });

          /**
           * @ngdoc method
           * @name showTaskCreationAlert
           * @description Show Growl for task creation
           * @param {*} task
           */
          function showTaskCreationAlert(task) {
            var alertObj = {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            };
            if (task.owners.guests.length) {
              angular.extend(alertObj, {
                variables: {
                  method: copyGuestLink,
                  param: task,
                  linkText: $filter('translate')('tasks.messages.copyGuestLink')
                }
              });
            } else {
              angular.extend(alertObj, {
                variables: {
                  method: viewNewlyCreatedTask,
                  param: {
                    "task": task
                  },
                  linkText: $filter('translate')('global.task.messages.viewTask')
                }
              });
            }
            growl.success($filter('translate')('global.task.messages.taskCreated'), alertObj);
          }

          /**
           * @ngdoc method
           * @name viewNewlyCreatedTask
           * @description Link to newly created tasks' list view
           * @param {*} params
           */
          function viewNewlyCreatedTask(params) {
            if (_.keys(params).length > 0) {
              var userId = _.head(_.concat(_.get(params, 'task.owners.users', []), _.get(params, 'task.owners.guests', [])));
              navigateToHome({
                assignee: userId, activeTask: _.get(params, 'task.id', ''), newOneOffTaskList: $ctrl.oneOffTaskList
              });
            }
          }

          /**
           * @ngdoc method
           * @name  navigateToHome
           * @description An helper method that redirects to home page with default params
           * A newly created task is visible on {status: 'to-do', sortBy: 'newest', assignee: OWNER_ID} 
           * @param {*} params An object that containes  {assignee, activeTask}
           * @returns void
           */
          function navigateToHome(params) {
            params = _.keys(params).length > 0 ? _.merge({}, params, { status: 'to-do', sortBy: 'newest', isRequireToOpenOneOffTaskModal: false }) : { org_id: currentUser.default_organization.id, status: 'to-do', sortBy: 'newest', isRequireToOpenOneOffTaskModal: false };
            $state.go('home', params);
          }

          // threads value watcher
          threadsChangesHandler = $scope.$watchCollection('$ctrl.threads', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            $ctrl.page = 1;
            setDisplayThreads();
          });

          pageWatcherHandler = $scope.$watch('$ctrl.page', function (newValue, oldValue) {
            if (newValue != oldValue) {
              setDisplayThreads();
            }
          });
        }
    });
})();

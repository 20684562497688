(function () {
  'use strict';

  angular.module('tallyfy')
    .factory('$tallyfyWebSocketFactory',
      /*@ngInject*/
      function ($rootScope, $interval, $tallyfyWebSocket, WS, ENV_CONFIG) {
        var self = this,
          _isAuthed,
          _socket,
          _wsConfig,
          _reconnectWatcher,
          _attemptReconnectCounter = 0,
          _started;

        self.$init = function () {
          if (_started) {
            return;
          }
          _started = true;
          $tallyfyWebSocket.$new();
          self.startSocket();
        }

        self.startSocket = function (reconnect) {
          if (reconnect) {
            $tallyfyWebSocket.$new();
          }

          _wsConfig = $tallyfyWebSocket.$getConfig();
          _socket = $tallyfyWebSocket.$getInstance();

          if (!_socket || !_wsConfig) {
            return;
          }

          function clearAttemptReconnectInterval() {
            _attemptReconnectCounter = 0;
            if (_reconnectWatcher) {
              $interval.cancel(_reconnectWatcher);
            }
          }

          _socket.onopen = function ($event) {
            clearAttemptReconnectInterval();
            if (reconnect) {
              self.$setAuth();
            }
            $rootScope.$emit(WS.EVENTS.ON_OPEN, { event: $event });
          }

          _socket.onclose = function ($event) {
            self.$setIsAuth(false);
            if (_wsConfig && _wsConfig.autoReconnect && !_attemptReconnectCounter) {
              _reconnectWatcher = $interval(function () {
                _attemptReconnectCounter++;
                if (_attemptReconnectCounter === 5) {
                  clearAttemptReconnectInterval();
                } else {
                  if (_socket.readyState === 3) {
                    self.startSocket(true);
                  }
                }
              }, _wsConfig.autoConnectInterval);
            }
          }

          _socket.onerror = function ($event) {
            $rootScope.$emit(WS.EVENTS.ON_ERROR, { event: $event });
          }

          _socket.onmessage = function ($event) {
            var data = null;
            if ($event.data === 'Manufactory Collector ✅') {
              $rootScope.$emit(WS.READY);
            } else {
              data = JSON.parse($event.data);
              $rootScope.$emit(WS.EVENTS.ON_MESSAGE, { data: data });
            }
          }
        }

        self.$setAuth = function () {
          if (_socket && _socket.readyState === 1 && !_isAuthed) {
            _socket.send(JSON.stringify({
              type: "auth",
              token: "Bearer " + ENV_CONFIG.COLLECTOR_WS_TOKEN
            }));
          }
        }

        self.$setUnAuth = function () {
          if (_socket && _socket.readyState === 1 && _isAuthed) {
            _socket.send(JSON.stringify({
              type: "auth",
              token: null
            }));
          }
        }

        self.$setIsAuth = function (value) {
          _isAuthed = value;
        }

        self.$send = function (data) {
          if (_socket && _socket.readyState === 1 && _isAuthed) {
            _socket.send(JSON.stringify(data));
          }
        }

        self.$open = function () {
          if (_socket) {
            _socket.open();
          }
        }

        self.$close = function () {
          if (_socket) {
            _socket.close();
          }
        }

        self.$isOpen = function () {
          return _socket && _socket.readyState === 1;
        }

        self.$isAuthed = function () {
          return _isAuthed;
        }

        self.$getWsConfig = function () {
          return _wsConfig;
        }

        return self;
      }
    );
})();
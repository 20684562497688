(function () {
  'use strict';
  angular.module('tallyfy')
    .component('quickActionLinks', {
      templateUrl: 'app/modules/dashboard/quick-action-links/quick-action-links.component.html',
      controller:
        /*@ngInject*/
        function (_, QuickActionsService, $rootScope) {
          var $ctrl = this, acceptableQuickActionTypes = QuickActionsService.getAcceptableQuickActionTypes();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onQuickActionClick = onQuickActionClick;
          $ctrl.onAccordionToggle = onAccordionToggle;

          function onInit() {
            $ctrl.orgID =  _.get($rootScope.identity, 'default_organization.id');
            $ctrl.quickActionItems = _.pickBy(acceptableQuickActionTypes, function(value) {
              return value.isApplicableForCurrentAccount();
            });
          }

          function onChanges() { }
          function onDestroy() { }

          function onQuickActionClick(actionConfig) {
            actionConfig.actionHandler('Homepage');
          }

          function onAccordionToggle(index, id, isExpanded) {
            $ctrl.quickActionItems[id].isExpanded = isExpanded;
          }
        }
    });
})();
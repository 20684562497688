/**
  * @ngdoc factory
  * @name tallyfy.files.factory.FilesRepository
  * @module tallyfy.files
  *
  * @description
  * Create Rest resource for files
  *
  * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
  * 
  * */
(function () {
  'use strict';
  angular
    .module('tallyfy.files')
    .factory('FilesRepository', FilesRepository);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  FilesRepository.$inject = ['BaseRepository', 'ENV_CONFIG'];
  /**
   * Constructor
   * FilesRepository construct
   */
  function FilesRepository(BaseRepository, ENV_CONFIG) {
    var repository = BaseRepository('/organizations/:org/file/:id/:action/:action_id', {
      org: 'org_id'
    }, {
      downloadFileMember: {
        method: 'GET',
        responseType: 'arraybuffer',
        url: ENV_CONFIG.FILES_HOST + '/organizations/:org/file/:fileId/dl',
        transformResponse: function (response, header) { return { data: response, contentType: header('Content-Type') }; }
      },
      downloadFileGuest: {
        method: 'GET',
        responseType: 'arraybuffer',
        url: ENV_CONFIG.FILES_HOST + '/organizations/:org/guests/guest_code/file/:fileId/dl',
        transformResponse: function (response, header) { return { data: response, contentType: header('Content-Type') }; }
      }
    });

    repository.downloadFile = function (args, isGuest) {
      if (isGuest) {
        args.skipAuthToken = true;
      }

      return !isGuest ? repository.downloadFileMember(args).$promise : repository.downloadFileGuest(args).$promise ;
    };

    repository.deleteGuestFile = function (args) {
      args = args || {};
      return BaseRepository('/organizations/:org/guests/guest_code/tasks/:task_id/file/:file_id', {
        org: 'org_id',
        task_id: args.task_id,
        file_id: args.id
      }).delete({
        skipAuthToken: true
      });
    };

    return repository;
  }
})();

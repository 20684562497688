/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.updateOrg
 * @module tallyfy.organizations
 *
 * @description
 * A component to update org of current user
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('updateOrg', {
      templateUrl: 'app/modules/organizations/updateOrg/update-org.html',
      controller: updateOrgCtrl
    });
  
   /*@ngInject*/
  function updateOrgCtrl(_, blockUI, Growl, OrganizationsService, $rootScope, $filter, $state, $q, ConfirmOnExitService, Helper, TimeZone, $scope, AccountService, preferencesStore, $log) {
    var $ctrl = this,
      blockUI = blockUI.instances.get('updateOrgProfile'),
      growl = new Growl(),
      unregisterStateListener;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;


          /**
           * public properties
           */
          $ctrl.org = {};
          $ctrl.updateOrgForm = {};
          $ctrl.industries = [];
          $ctrl.timeZones = [];
          $ctrl.isCountryDropdownOpen = false;
          $ctrl.isTimezoneDropdownOpen = false;
          $ctrl.isDateFormatDropdownOpen = false;
          $ctrl.isIndustryDropdownOpen = false;

          /**
           * public methods
          */
          $ctrl.updateOrganizations = updateOrganizations;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;
          $ctrl.changeIndustryName = changeIndustryName;
          $ctrl.resetForm = resetForm;

          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization () {
            $ctrl.organization = $rootScope.identity.default_organization;
            $ctrl.orgId = $ctrl.organization.id;
            loadData();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.listOrgDateFormat = OrganizationsService.getDeadlineFormat();
            var orgDateFormat = _.find($rootScope.identity.preferences, { 'slug': 'organization_date_format' });
            if (orgDateFormat) {
              $ctrl.organizationDateFormat = orgDateFormat.metadata;
            }
          }
          
          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges () { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy () {
            unregisterStateListener();
          }
          
          /**
           * @ngdoc method
           * @name loadData
           * @private
           * @description Get industries and organization data
           */
          function loadData () {
            blockUI.start();
            $q.all([
              OrganizationsService.getIndustries(),
              OrganizationsService.get()
            ]).then(function (response) {
              if (!_.isUndefined(_.head(response))) {
                $ctrl.industries = _.head(response);
              }
              if (!_.isUndefined(response[1].data)) {
                setOrganizationInfo(response[1].data);
              }
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }
          
          /**
           * @ngdoc method
           * @name setOrganizationInfo
           * @description set organization information
           * @param {Object} data
           * @returns {Object}
          */
          function setOrganizationInfo (data) {
            $ctrl.org = getOrgazationInfo(data);
            $ctrl.organizationTimezone = _.get($ctrl.org, 'timezone');
            getCountryTimezone();
            if (!_.isNull($ctrl.org.industry))
              $ctrl.industry = { "name": $ctrl.org.industry };
            
            if (!_.isNull($ctrl.org.country))
              $ctrl.country = { "name": $ctrl.org.country };
            
            $ctrl.defualtOrganization = angular.copy($ctrl.org);
          }

          /**
           * @ngdoc method
           * @name getOrgazationInfo
           * @description return some record from all object
           * @param {Object} data
           * @returns {Object}
          */
          function getOrgazationInfo (data) {
            return _.pick(data, ['name', 'address1', 'address2', 'industry', 'description', 'city', 'state', 'zipcode', 'country', 'allow_user_invite', 'wyiwyg', 'working_days', 'timezone', 'default_deadline', 'cadence_days', 'guest_cadence_days', 'allow_manage_groups', 'auto_archive', 'auto_archive_weeks_number']);
          }

          /**
           * @ngdoc method
           * @name updateOrganizations
           * @description Update organization record
           * @returns {Object}
          */
          function updateOrganizations () {
            if(!Helper.checkAccessAuthority())
              return;
            var deferred = $q.defer();

            if ($ctrl.updateOrgForm.$invalid) {
              return;
            }

            if (!_.isUndefined($ctrl.country)) {
              $ctrl.org.country = $ctrl.country.name;
            }
            blockUI.start();
            $ctrl.org.timezone = _.get($ctrl.organizationTimezone, 'value') || $ctrl.organizationTimezone;
            OrganizationsService.update(null, $ctrl.org).then( function (response) {
              AccountService.setUserPreference({
                slug: "organization_date_format",
                value: "yes",
                metadata: $ctrl.organizationDateFormat
              }).then(function (response) {
                preferencesStore.updatePreferences(response.data);
              }, function (error) {
                $log.error(error);
              });
              setOrganizationInfo(response.data);
              $rootScope.identity.default_organization.name = _.get(response.data, 'name');
              growl.success($filter('translate')('orgProfile.messages.organizationSaved'), { referenceId: 'myAccount', disableIcons: true, disableCloseButton: true });
              resetForm();
              blockUI.stop();
              deferred.resolve({'status':'Saved'});
            }, function () {
              blockUI.stop();
              deferred.reject({'status':'NotSaved'});
            });
            return deferred.promise;
          }
          
          /**
           * @ngdoc method
           * @name setSearchPlaceholder
           * @description Set placeholder message from json file
           * @param {Object} $select
           * @returns {string}
           */
          function setSearchPlaceholder ($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')('orgProfile.placeholder.industry');
            }
          }

          /**
           * @ngdoc method
           * @name changeIndustryName
           * @description Change industry name
           * @param {Object} industry
           * @returns {string} industry name
           */
          function changeIndustryName (industry) {
            $ctrl.org.industry = industry.name;
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @private
           * @description Reset form
           * @returns {Void}
           */
          function resetForm () {
            $ctrl.org = angular.copy($ctrl.defualtOrganization);
            $ctrl.industry = { "name": $ctrl.org.industry };
            $ctrl.country = { "name": $ctrl.org.country };
            if (_.isNull($ctrl.org.industry))
              $ctrl.industry = '';

            if (_.isNull($ctrl.org.country))
              $ctrl.country = '';
            $ctrl.updateOrgForm.$setPristine();
            $ctrl.updateOrgForm.$setUntouched();
          }

          /**
           * @ngdoc method
           * @name getCountryTimezone
           * @private
           * @description Get country timezone
           */
          function getCountryTimezone() {
            blockUI.start();
            TimeZone.getAll().then(function (response) {
              blockUI.stop();
              $ctrl.timeZones = response;
              $ctrl.organizationTimezone = $ctrl.organizationTimezone !== 'UTC' ? _.find($ctrl.timeZones, { value: $ctrl.organizationTimezone }) : 'UTC';
            }, function () {
               blockUI.stop();
            });
          }

          /**
           * Event handler when user navigates to another tab/state
           * @description It will send form name to 'confirmOnExit" factory to get confirmation from user 
           * wether would like to save changes or not. 
           * Based on user input, it will perform saving or discarding of chaged details in form.
           * @type {*|(function())}
          */
          unregisterStateListener = $rootScope.$on('$stateChangeStart',
            function (event, toState, toParams, fromState, fromParams) {
              if (angular.isDefined($ctrl.updateOrgForm) && !$ctrl.updateOrgForm.$pristine) {
                event.preventDefault();
                var chekingstatus = ConfirmOnExitService.checkStatus($ctrl.updateOrgForm);
                chekingstatus.then(function (data) {
                  if (data.status === 'YES') {
                    $ctrl.updateOrgForm.$submitted = true;
                    var getstatus = $ctrl.updateOrganizations();
                    getstatus.then(function (data) {
                      if (data.status === 'Saved') {
                        $state.go(toState);
                        $ctrl.resetForm();
                      }
                    });
                  } else if (data.status === 'NO') {
                    $ctrl.resetForm();
                    $state.go(toState);
                  }
                });
              }
          });
  }
})();
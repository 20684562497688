/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.renderGuestDate
 * @module tallyfy.tasks
 *
 * @description
 * renderGuestDate
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21å )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('renderGuestDate', {
      templateUrl: 'app/modules/tasks/guestTask/form/fields/date/render.html',
      bindings: {
        field: '=',
        fieldValue: "<",
        validationErrors: '<',
        hideNoInfoData: '<'
      },
      require: {
        taskCtrl: '^guestTask'
      },
      controller: ['$rootScope', '$scope', 'moment', 'FieldService', function ($rootScope, $scope, moment, FieldService) {
        var $ctrl = this,
          updateTaskStatusListener;
        /**
         * component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * public properties
         */
        $ctrl.datePicker = {
          dateOptions: {
            dateDisabled: false,
            formatYear: 'yy',
            formatMonth: 'MMM',
            monthColumns: '4',
            maxDate: new Date(9999, 5, 22),
            startingDay: 1,
            showButtonBar: false,
            altInputFormats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
            showWeeks: false,
            ngModelOptions: {
              debounce: 100
            }
          },
          isOpened: false
        };
        $ctrl.splittedTime = {};

        /**
         * public methods
         */
        $ctrl.openDatePicker = openDatePicker;
        $ctrl.updateChange = updateChange;
        $ctrl.isValidMonth = isValidMonth;
        $ctrl.isValidDay = isValidDay;
        $ctrl.isValidYear = isValidYear;
        $ctrl.isValidDate = isValidDate;
        
        /**
         * @function
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          var fieldValue = $ctrl.taskCtrl.task.taskdata[$ctrl.field.id];
          if (fieldValue) {
            $ctrl.field.value = FieldService.getDateInDatepickerDisplayFormat(fieldValue);
            $ctrl.splittedTime = {
              day: moment($ctrl.field.value).format('DD'),
              month: moment($ctrl.field.value).format('MM'),
              year: moment($ctrl.field.value).format('YYYY')
            };
          } else {
            $ctrl.field.value = "";
          }
          $ctrl.isDisabled = $ctrl.taskCtrl.isCompleted;
        }

        /**
         * @function
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges(bindings) {
          if (bindings.fieldValue && !bindings.fieldValue.isFirstChange()) {
            initialization();
          }
        }
        /**
         * @function
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() {
          $scope.$on('$destroy', updateTaskStatusListener);
        }

        /**
         * @function
         * @name openDatePicker
         * @description Open datePicker
        */
        function openDatePicker() {
          $ctrl.datePicker.isOpened = true;
        }
        
        /**
         * @function
         * @name updateChange
         * @description Update task capture field value
        */
        function updateChange() {
          if (!$ctrl.field.value) {
            $ctrl.splittedTime = {};
          }
          if ($ctrl.field.value && $ctrl.validationErrors[$ctrl.field.id]) {
            delete $ctrl.validationErrors[$ctrl.field.id];
          }
          $ctrl.field.value = $ctrl.field.value ? FieldService.getDateInPayloadFormat($ctrl.field.value) : $ctrl.field.value;
          $ctrl.taskCtrl.updateTaskCapture(angular.copy($ctrl.field));
        }
        
        /**
         * @function
         * @name isValidMonth
         * @param month
         * @description Check if a month is valid
         * @returns {Number|boolean}
        */
        function isValidMonth(month) {
          return parseInt(month) && month <= 12;
        }

        /**
         * @function
         * @name isValidDay
         * @param day
         * @description Check if a day is valid
         * @returns {Number|boolean}
        */
        function isValidDay(day) {
          return parseInt(day) && day <= 31;
        }

        /**
         * @function
         * @name isValidYear
         * @param year
         * @description Check if a number is valid year
         * @returns {Number|boolean}
        */
        function isValidYear(year) {
          return parseInt(year) && year > 1900 && year < 9999;
        }
        
        /**
         * @function
         * @name isValidDate
         * @description Check if a date is valid date
        */
        function isValidDate() {
          if (isValidMonth($ctrl.splittedTime.month) && isValidDay($ctrl.splittedTime.day) && isValidYear($ctrl.splittedTime.year)) {
            var newDate = new Date();
            newDate.setDate($ctrl.splittedTime.day);
            newDate.setYear($ctrl.splittedTime.year);
            newDate.setMonth($ctrl.splittedTime.month - 1);
            $ctrl.field.value = newDate;
            updateChange();
            return;
          }
          $ctrl.field.value = '';
        }

        updateTaskStatusListener = $rootScope.$on('TASK:UPDATE_TASK_STATUS', function (event, data) {
          if (data.taskId === $ctrl.taskCtrl.task.id) {
            $ctrl.isDisabled = data.isCompleted;
            if(!$ctrl.field.value) {
              $ctrl.splittedTime = {};
            }
          }
        });

      }]
    });
})();

(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.integration.factory.IntegrationRepository
   * @module tallyfy.integration
   *
   * @description
   * Create Rest resource for integration
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.integration')
    .factory('IntegrationRepository', IntegrationRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  IntegrationRepository.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * IntegrationRepository construct 
   */
  function IntegrationRepository(BaseRepository, CONFIG) {
    var repository;
    repository = BaseRepository('/organizations/:org/integrations/:id/:action/:sub_action_id/:sub_action', {
      org: 'org_id',
      action: '@action',
      id: '@id',
      sub_action_id: '@sub_action_id',
      sub_action: '@sub_action'
    }, {});
    return repository;
  }
})(); 
/**
 * @ngdoc Filter
 * @name convertToWeeks
 * @module tallyfy
 * @description
 * Convert the date to week in humanized form
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('convertToWeeks', ['moment', '$filter' ,function (moment, $filter) {
      return function (value) {
        var duration_in_weeks = moment.duration(moment(value).diff(moment())).asWeeks();
        var humanize_diff_in_weeks = (duration_in_weeks > 0) ? $filter('translate')('global.deadline.in') + " " + Math.round(Math.abs(duration_in_weeks)) + " " + $filter('translate')('global.deadline.weeks') : Math.round(Math.abs(duration_in_weeks)) + " " + $filter('translate')('global.deadline.weeksAgo');
        return humanize_diff_in_weeks;
      };
    }]);
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.scheduleCall
 * @module tallyfy.organizations
 *
 * @description
 * A component to schedule a call
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('scheduleCall', {
      templateUrl: 'app/components/scheduleCall/scheduleCall.html',
      controller:
      /*@ngInject*/
        function ($rootScope, $state, $window, $timeout, _, $scope, blockUI) {
          var $ctrl = this, blockUI = blockUI.instances.get('scheduleCallLoader'), currentUser;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.disableBack = $state.params.dback;
            $ctrl.disableSkip = $state.params.dskip;
            $ctrl.redirectAfterComplete = $state.params.rac;
            currentUser = $rootScope.identity;
            if ($ctrl.disableBack){
              setPopStateListener();
            }
            if ($ctrl.redirectAfterComplete) {
              setCalendlyListener();
            }
            blockUI.start();
            $timeout(function () {
              blockUI.stop();
              Calendly.initInlineWidget({
                url: 'https://calendly.com/tallyfy/chat-with-tallyfy-app?hide_event_type_details=1&text_color=414042&primary_color=3fb65b',
                parentElement: document.getElementById('calendly_schedule_call'),
                prefill: {
                  name: currentUser.full_name,
                  email: currentUser.email
                }
              });
            }, 1000);
          }

          /**
           * @function
           * @name onDestroy
           * @description destroy event handler
           */
          function onDestroy() {
            $timeout(function () {
              $window.removeEventListener('popstate', scheduleCallPopStateListener);
            }, 1000);
            $window.removeEventListener('message', calendlyEventListener);
          }

          /**
           * @function
           * @name scheduleCallPopStateListener
           * @description fire when pop state
           */
          function scheduleCallPopStateListener() {
            $window.history.forward(1);
          }

          /**
           * @function
           * @name setPopStateListener
           * @description Set popstate event
           */
          function setPopStateListener() {
            $window.addEventListener('popstate', scheduleCallPopStateListener);
          }

          /**
           * @function
           * @name setCalendlyListener
           * @description Set calendly listener
           */
          function setCalendlyListener() {
            $window.addEventListener('message', calendlyEventListener);
          }

          /**
           * @function
           * @name isCalendlyEvent
           * @description Check whether the emitted event is from calendly
           */
          function isCalendlyEvent(e) {
            return e.data.event &&
              e.data.event.indexOf('calendly') === 0;
          }

          /**
           * @function
           * @name calendlyEventListener
           * @description Calendly message event listener
           */
          function calendlyEventListener(e) {
            if (isCalendlyEvent(e) && (_.get(e, 'data.event') === 'calendly.event_scheduled')) {
              $timeout(function () {
                blockUI.start();
              }, 1000);
              
              $timeout(function () {
                $state.go('authHandler');
                blockUI.stop();
                $scope.$apply();
              }, 3000);
            }
          }
        }
    });
})();
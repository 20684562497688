/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.modifyTaskOrRunFolderModal
 * @module tallyfy.tasks
 *
 * @description
 * A component to add / modify / remove  a folder
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('modifyTaskOrRunFolderModal', {
      templateUrl: 'app/modules/home/components/modify-task-or-run-folder-modal/modify-task-or-run-folder-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (Growl, blockUI, FolderService, _, $rootScope, $filter, $confirm) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('addToFoldersModal'),
            growl = new Growl();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.onParentFolderSelect = onParentFolderSelect;
          $ctrl.saveFolder = saveFolder;
          $ctrl.deleteFolder = deleteFolder;
          $ctrl.cancel = cancel;

          /**
           * public properties
           */
          $ctrl.newObjectFolders = []; 

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if (_.get($ctrl.resolve, 'folder.id')) {
              $ctrl.folderObj = angular.copy($ctrl.resolve.folder);
              $ctrl.foldersList = angular.copy($ctrl.resolve.foldersList);
              $ctrl.parentFolderObj = angular.copy($ctrl.resolve.parentFolder) || {};
            } else {
              // Defaults
              $ctrl.folderObj = {};
              $ctrl.parentFolderObj = angular.copy($ctrl.resolve.parentFolder) || {};
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name onFolderTreeToggle
           * @param {*} folder
           * @description
           * Toggle folder tree
           */
          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          /**
           * @function
           * @name onParentFolderSelect
           * @param {*} folder
           * @description
           * Select parent folder
           */
          function onParentFolderSelect(folder) {
            $ctrl.parentFolderObj = folder;
            $ctrl.popoverIsOpen = false;
          }
          
          /**
           * @ngdoc method
           *
           * @name save
           * @returns void
           *
           * @description
           * save folder
           */
          function saveFolder() {
            $ctrl.onSaving = true;
            if (!$ctrl.folderObj.id) {
              var params = {
                name: $ctrl.folderObj.name,
                folder_type: 'run',
                active: 1,
                parent_id: $ctrl.parentFolderObj.id ? $ctrl.parentFolderObj.id : null
              };
              FolderService.createFolder(params).then(function (response) {
                $ctrl.onSaving = false;
                response.data.children = { data: [] };
                $ctrl.close({ $value: response });
              }, function () {
                $ctrl.onSaving = false;
              });
            } else {
              var folderObj = angular.copy($ctrl.folderObj);
              folderObj.parent_id = $ctrl.parentFolderObj.id ? $ctrl.parentFolderObj.id : null;
              FolderService.editFolder(folderObj).then(function (response) {
                $ctrl.onSaving = false;
                growl.success($filter('translate')('global.growlMessages.editAndMoveSuccess'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                var resultType = _.get(response, 'data.parent_id') !== _.get($ctrl.resolve, 'folder.parent_id') ? 'update_parent_folder_changed' : 'update_folder_name_changed';
                $ctrl.close({ $value: { resultType: resultType, response: response } });
              }, function () {
                $ctrl.onSaving = false;
              });
            }
          }

          function deleteFolder() {
            $confirm({
              'header': 'template.folder.deleteModal.taskOrRunHeader',
              'body': '',
              'buttons': {
                'accept': 'template.folder.deleteModal.buttons.accept',
                'cancel': 'template.folder.deleteModal.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              $ctrl.onSaving = true;
              FolderService.delete({
                id: $ctrl.folderObj.id
              }).then(function () {
                $ctrl.onSaving = false;
                $ctrl.close({ $value: { resultType: 'folder_deleted', folder: $ctrl.folderObj } });
              }, function () {
                $ctrl.onSaving = false;
              });
            });
          }

          function cancel() {
            $ctrl.dismiss({ $value: 'dismissed' });
          }
        }
    });
})();
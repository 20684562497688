(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('processRenameModal', {
      templateUrl: 'app/modules/process/components/edit/modals/process-rename-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, DESCRIPTIONSIZE) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.editName = editName;

          function onInit() {
            $ctrl.maxLength = _.get($ctrl.resolve, 'activeProcess') ? DESCRIPTIONSIZE.maxProcessTitleLength : DESCRIPTIONSIZE.default;
            $ctrl.titleName = _.get($ctrl.resolve, 'titleName');
          }

          function onChanges() { }

          function onDestroy() { }

          function editName() {
            $ctrl.close({ $value: $ctrl.titleName });
          }
        }
    });
})();
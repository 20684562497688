/**
 * @ngdoc Config
 * @name tallyfy.settings.config.ManageStateConfig
 * @module tallyfy.settings
 *
 * @description
 * set route manage Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(ManageStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ManageStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * ManageStateConfig construct 
   */
  function ManageStateConfig($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.when('/settings', '/settings/me/details');

    $stateProvider.state('settings', {
      absolute: true,
      parent: 'app',
      url: '/:org_id/settings',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'manage'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('billing');
          $translatePartialLoader.addPart('payment');
          return $translate.refresh();
        }]
      }
    }).state('guest.settings', {
      parent: 'guest',
      url: '/settings',
      data: {
        authorities: []
      },
      views: {
        'content': {
          component: 'manage'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('billing');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.component.viewMore
 * @module tallyfy
 *
 * @description
 * A component to manage summay details
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('viewMore', {
      templateUrl: 'app/modules/viewMore/view-more.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
         /*@ngInject*/
        function ($filter, $timeout) {
          var $ctrl = this;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancelModal = cancelModal;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isLoading = true;
            $timeout(function () {
              $ctrl.summary = $ctrl.resolve.summary;
              $ctrl.title = $ctrl.resolve.title || $filter('translate')('global.description.modal.title');
              $ctrl.isLoading = false;
            }, 0);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({ $value: 'cancel'});
          }
          //controller ends
        }
    });
})();
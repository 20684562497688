/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.tasksList
 * @module tallyfy.tasks
 *
 * @description
 * tasksList component
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('tasksList', {
      templateUrl: 'app/modules/tasks/list/tasks-list.html',
      bindings: {
        entityId: '<',
        entity: '@',
        activeTask: '<?',
        run: '<?',
        onComplete: '&',
        users: '<',
        taskLength: '=?',
        completedTaskLength: '=?',
        primaryFilterConfig: '<?',
        isTaskView: '<?',
        allUsers: '<?',
        showHiddenTasks: '<?',
        isDisable: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, TasksService, blockUI, $rootScope, $log, $q, $filter, TASKORDER, SoundService, AccountService, COMMON, AuthPlan, PLANS, TasksFilterService, $state) {
          var $ctrl = this,
            unRegisterEventHandler,
            unRegisterAddFileEventHandler,
            unRegisterDeleteTaskEventHandler,
            unRegisterEditTaskEventHandler,
            unRegisterDeadlineHandler,
            unRegisterRestoreTaskEventHandler,
            unregisterTasksFilterHandler,
            blockUI = blockUI.instances.get('tasks'),
            deletedOneOffTaskIndex,
            viewPortOffset = 188,
            scrollOffset = 40,
            loadMoreTasks,
            enableVisibleTasksAutoLoad = true,
            autoLoadTasksMaxLimit = 10; // Should be less than the pagination > items per page value

          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.getCompletedTasks = getCompletedTasks;
          $ctrl.getTasks = getTasks;
          $ctrl.loadMore = loadMore;
          $ctrl.onUpdateStatus = onUpdateStatus;
          $ctrl.onStatusUpdated = onStatusUpdated;
          $ctrl.setNumberOnTask = setNumberOnTask;
          $ctrl.standaloneModal = standaloneModal;
          $ctrl.toggleTasksView = toggleTasksView;
          $ctrl.disableInfiniteScroll = disableInfiniteScroll;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.isPrimaryFilterConfig = isPrimaryFilterConfig;
          $ctrl.isSpinnerActive = isSpinnerActive;
          $ctrl.updateExistingTasksChangedByRules = updateExistingTasksChangedByRules;
          $ctrl.blockUI = blockUI;
          $ctrl.isVisibleCreateTask = isVisibleCreateTask;
          /**
           * public properties
           */
          $ctrl.noOfTasks = COMMON.LIMITS.TOTALTASKS;
          $ctrl.perPage = 20;
          $ctrl.groupedTasksLimit = 5;

          resetToDefault();

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            helpText($rootScope.identity);
            getNewAddedOneOffTaskList();
            $ctrl.run = $ctrl.run || {};
            $ctrl.availableUsers = $ctrl.users;
            $ctrl.currentUser = $rootScope.identity;
            $ctrl.params = {
              action: $ctrl.entity,
              action_id: $ctrl.entityId
            };
            if (!$ctrl.isTaskView) {
              getTasks(true);
            }
            $ctrl.play_tasks_sound = AccountService.getPreference(_.get($ctrl.currentUser, 'preferences'), 'play_tasks_sound', 'yes');
            onPageScroll();
            $ctrl.loadMoreText = $filter('translate')('global.loadMore');
            $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
            $ctrl.isProPlan = ([PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
            $ctrl.isBasicPlan = ([PLANS.BASIC, PLANS.BASICANNUAL, PLANS.BASICMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
          }

          /**
           * @ngdoc method
           * @name getNewAddedOneOffTaskList
           * @description get new added one off task list.
           */
          function getNewAddedOneOffTaskList() {
            $ctrl.newAddedOneOffTaskList = _.orderBy(_.get($state.params, 'newOneOffTaskList', null), 'created_at', 'desc');
          }

          function isVisibleCreateTask(primaryFilterConfig) {
            var defaultFilter = TasksFilterService.getDefaultFilters();
            return primaryFilterConfig.sortBy.value !== defaultFilter.sortBy.value ||
              primaryFilterConfig.process.id !== defaultFilter.process.id ||
              primaryFilterConfig.statesBy.value !== defaultFilter.statesBy.value;
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description A component's lifeCycle hook which is called when bindings are updated.
           * @param {*} changes
           */
          function onChanges(changes) {
            if ($ctrl.availableUsers && changes.entityId) {
              var selectedUser = _.find($ctrl.availableUsers, { 'id': changes.entityId.currentValue });
              helpText(selectedUser);
            }
            if (changes.entityId && changes.entityId.currentValue !== changes.entityId.previousValue && !changes.entityId.isFirstChange()) {
              $log.info('TasksListComponent - onChanges ', changes);
              $ctrl.tasks.length = 0;
              $ctrl.params = {
                action: $ctrl.entity,
                action_id: changes.entityId.currentValue
              };
              getTasks();
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unRegisterEventHandler();
            unRegisterAddFileEventHandler();
            unRegisterDeleteTaskEventHandler();
            unRegisterEditTaskEventHandler();
            unregisterTasksFilterHandler();
            unRegisterDeadlineHandler();
            unRegisterRestoreTaskEventHandler();
          }

          /**
           * Playing sound when task updated
           * @param {Object} task Object task
           */
          function onStatusUpdated(task) {
            if ($ctrl.play_tasks_sound === 'yes') {
              task.status === 'completed' ? SoundService.onComplete() : SoundService.onReOpen();
            }
          }

          /**
          * @ngdoc method
          * @name getTasks
          * @private
          * @description fetch tasks
          * @param {Boolean} isFirstLoad
          * @returns void
          */
          function getTasks(isFirstLoad) {
            var requestParam = getRequestParams(false);
            angular.extend(requestParam, $ctrl.params);
            delete requestParam.sortBy;
            delete requestParam.process;
            delete requestParam.statesBy;
            showSpinner(isFirstLoad);
            $ctrl.tasksLoaded = false;
            TasksService.getUserTasks(requestParam).then(function (response) {
              onTasksLoaded(response, isFirstLoad, getTasks);
            }, function () {
              hideSpinner();
            });
          }

          /**
           * @ngdoc method
           * @name appendTasks
           * @private
           * @description An helper function to prepare task list and set active task
           * @param {any} tl 
           * @returns void
           */
          function appendTasks(tl) {
            if ($ctrl.newAddedOneOffTaskList.length > 0) {
              _.forEach($ctrl.newAddedOneOffTaskList, function (task) {
                if (task.id === $ctrl.activeTask) {
                  task.isExpanded = true;
                  return false;
                }
              });
            }
            for (var index = 0; index < tl.length; index++) {
              $ctrl.tasks.push(tl[index]);
              if (index === tl.length - 1 && loadMoreTasks.length) {
                $ctrl.backgroundLoadedCount++;
                loadMoreTasks = _.slice(loadMoreTasks, 20);
              }
            }
            if ($ctrl.activeTask) {
              _.forEach($ctrl.tasks, function (task) {
                if (task.id == $ctrl.activeTask) {
                  task.isExpanded = true;
                  return false;
                }
              });
            }
            setNumberOnTask();
            $log.info('TasksListComponent - count of loaded tasks ', $ctrl.tasks.length);
          }

          /**
           * @ngdoc method
           * @name groupTasksByUser
           * @private
           * @description An helper function to prepare group of task by user
           * @param {Object} newTasks 
           * @returns void
           */
          function groupTasksByUser(newTasks) {
            $ctrl.tasks = newTasks;
            var allAvailableUsersTask = [];
            if ($ctrl.users.length) {
              $ctrl.availableUsers = _.sortBy($ctrl.users, 'text');
              _.forEach($ctrl.availableUsers, function (user) {
                var tasks = _.filter(newTasks, function (task) {
                  return _.includes(task.owners.users, user.id);
                });
                Array.prototype.push.apply(allAvailableUsersTask, tasks);
                var userTask = _.find($ctrl.userTasks, function (task) {
                  return task.user.id === user.id;
                });
                if (userTask) {
                  Array.prototype.push.apply(userTask.tasks, tasks);
                } else {
                  $ctrl.userTasks.push({
                    user: user,
                    tasks: tasks
                  });
                }
              });
              $ctrl.userWithNoTasks = _.filter($ctrl.userTasks, function (userTask) {
                return !userTask.tasks.length;
              });
              $ctrl.userTasks = _.filter($ctrl.userTasks, function (userTask) {
                return userTask.tasks.length;
              });
              var newGuestTasks = _.filter(newTasks, function (task) {
                return task.owners.guests.length;
              });
              $ctrl.taskLength += newGuestTasks.length;
              _.forEach(newGuestTasks, function (newGuestTask) {
                _.forEach(newGuestTask.owners.guests, function (guest) {
                  var guestTasks = _.find($ctrl.guestTasks, function (task) {
                    return task.guest === guest;
                  });
                  if (guestTasks) {
                    guestTasks.tasks.push(newGuestTask);
                  } else {
                    $ctrl.guestTasks.push({
                      guest: guest,
                      tasks: [newGuestTask]
                    });
                  }
                });
              });
              $ctrl.guestTasks = _.sortBy($ctrl.guestTasks, 'guest');

              _.forEach($ctrl.userTasks, function (user_tasks_item) {
                user_tasks_item.tasksLimit = $ctrl.groupedTasksLimit;
              });
              _.forEach($ctrl.guestTasks, function (guest_tasks_item) {
                guest_tasks_item.tasksLimit = $ctrl.groupedTasksLimit;
              });
              scrollUp();
            }
            getUnassignedTasks(allAvailableUsersTask, false, newTasks);
            $ctrl.backgroundLoadedCount++;
            loadMoreTasks = _.slice(loadMoreTasks, 20);
          }

          /**
           * @ngdoc method
           * @name scrollUp
           * @description Move scroll to top
           * @returns {void}
           */
          function scrollUp() {
            angular.element(document.querySelector('.app-body'))[0].scrollTop = 0;
          }

          /**
           * @ngdoc method
           * @name onPageScroll
           * @description Call the function on page scroll
           * @returns {void}
           */
          function onPageScroll() {
            angular.element(document.querySelector('.app-body')).bind("scroll", function () {
              if ($ctrl.primaryFilterConfig && $ctrl.primaryFilterConfig.assignee.key === 'everyone') {
                var elements = angular.element(document.querySelectorAll('.tasks-by-user-header'));
                if (elements.length && (this.scrollTop >= scrollOffset)) {
                  TasksService.attachUserStickyHeader(elements, viewPortOffset);
                } else if (elements.length && (this.scrollTop < scrollOffset)) {
                  TasksService.removeUserStickyHeader(elements);
                }
              }
            });
          }

          /**
           * @ngdoc method
           * @name getUnassignedTasks
           * @public
           * @description get unassigned tasks of removed users
           * and update unassigned task when task is assign to other user.
           * @param {Boolean} isUpdated
           * @param {Array} tasks
           * @param {Object} allTasks
           */
          function getUnassignedTasks(tasks, isUpdated, allTasks) {
            if (!isUpdated) {
              var removedUserTasks = _.difference(allTasks, tasks);
              if (removedUserTasks.length) {
                Array.prototype.push.apply($ctrl.removedUserTasks, removedUserTasks);
              }
            } else {
              var unassignedTasks = _.filter($ctrl.removedUserTasks, function (task) {
                return task.id !== tasks.id;
              });
              if (unassignedTasks.length !== $ctrl.removedUserTasks.length) {
                $ctrl.taskLength = $ctrl.taskLength - 1;
                $ctrl.removedUserTasks = unassignedTasks;
              }
            }
          }

          /**
           * @ngdoc method
           * @name disableInfiniteScroll
           * @public
           * @description Handles infinite scroll disabling on the template
           * @returns boolean
           */
          function disableInfiniteScroll() {
            return ($ctrl.blockUI.isBlocking() || $ctrl.isLastPage || ($ctrl.primaryFilterConfig && $ctrl.primaryFilterConfig.assignee.label === 'everyone'));
          }

          /**
           * @ngdoc method
           * @name loadMore
           * @public
           * @description triggers when page changed or page is scrolled down
           * @returns void
           */
          function loadMore() {
            if ($ctrl.isSpinnerActive() || !$ctrl.tasks.length || $ctrl.backgroundLoadedCount === $ctrl.totalPages) {
              return;
            }

            if (!$ctrl.isLastPage && $ctrl.tasksLoaded) {
              $ctrl.currentPage++;
              if ($ctrl.primaryFilterConfig) {
                filterTasks(false);
              } else {
                getTasks(false);
              }
            }
            if (loadMoreTasks.length) {
              if ($ctrl.primaryFilterConfig && $ctrl.primaryFilterConfig.assignee.key === 'everyone') {
                groupTasksByUser(_.take(loadMoreTasks, 20));
              } else {
                appendTasks(_.take(loadMoreTasks, 20));
              }
            } else {
              showSpinner(true);
            }
          }

          /**
           * @ngdoc method
           * @name onUpdateStatus
           * @public
           * @description A callback method which is called when a task is marked as complete/uncomplete
           * @param {any} task 
           * @param {any} status 
           * @returns void
           */
          function onUpdateStatus(task, status) {
            $rootScope.$emit('TASK:UPDATED', task);
            if (_.isFunction($ctrl.onComplete)) {
              $ctrl.onComplete({
                firstLoad: false,
                task: task,
                status: status
              });
            }
          }

          /**
           * @ngdoc method
           * @name getCompletedTasks 
           * @private
           * @description get completed tasks
           * @param {Boolean} isFirstLoad
           * @returns promise
           */
          function getCompletedTasks(isFirstLoad) {
            var params = angular.extend({}, $ctrl.params, getRequestParams(false)),
              deffered = $q.defer();
            delete params.sortBy;
            delete params.process;
            delete params.statesBy;
            showSpinner(isFirstLoad);
            $ctrl.tasksLoaded = false;
            TasksService.getUserCompletedTasks(params).then(function (response) {
              onTasksLoaded(response, isFirstLoad, getCompletedTasks);
            }, function (error) {
              deffered.reject(error);
              hideSpinner();
            });
            return deffered.promise;
          }

          /**
          * @ngdoc method
          * @name updateTask 
          * @private
          * @description update task data
          * @param {any} data 
          * @returns void
          */
          function updateTask(data) {
            var task = _.get(data, 'task'),
              file = _.get(data, 'file'),
              field = _.get(data, 'field'),
              fIndex;

            var taskIndex = _.findIndex($ctrl.tasks, { id: task.id }),
              taskData = _.get($ctrl.tasks[taskIndex], 'taskdata');

            if (taskData) {
              if (taskData[field.id] && !_.isEmpty(taskData[field.id])) {
                fIndex = _.findIndex(taskData[field.id], { id: file.id });
                taskData[field.id].splice(fIndex, 1);
              }
            }
            var requestPayload = {
              taskdata: taskData
            };
            requestPayload.taskdata[field.id] = taskData[field.id];
            blockUI.start();
            TasksService.updateTask({
              action_id: task.run_id,
              id: task.id
            }, requestPayload).then(function () {
              var t = $ctrl.tasks[taskIndex];
              t['taskdata'] = taskData;
              $ctrl.tasks[taskIndex] = angular.copy(t);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * capture event TASK:DELETE_FILE
           * and unregister when component is destroyed
           * @see onDestroy
           */
          unRegisterEventHandler = $rootScope.$on('TASK:DELETE_FILE', function (event, data) {
            $log.info('Component - tasksList - TASK:DELETE_FILE', data);
            updateTask(data);
          });

          /**
          * capture event TASK:ADD_FILE
          * and unregister when component is destroyed
          * @see onDestroy
          */
          unRegisterAddFileEventHandler = $rootScope.$on('TASK:ADD_FILE', function (event, data) {
            $log.info('Component - tasksList - TASK:ADD_FILE', data);
            updateTaskFiles(data);
          });

          /**
          * capture event TASK:EDIT
          * and unregister when component is destroyed
          * @see onDestroy
          */
          unRegisterEditTaskEventHandler = $rootScope.$on('TASK:UPDATE', function (event, data) {
            if (data.task) {
              var taskIndex = _.findIndex($ctrl.tasks, { id: data.task.id });
              if (taskIndex >= 0) {
                $ctrl.tasks[taskIndex] = angular.copy(data.task);
              }
              updateTasksByUsers(data);
              updateTasksByGuest(data);
              updateUserWithNoTasks(data);
              getUnassignedTasks(data.task, true);
            }
          });

          /**
           * capture event STANDALONE_TASK:DELETE
           * and unregister when component is destroyed
           * @see onDestroy
           */
          unRegisterDeleteTaskEventHandler = $rootScope.$on('STANDALONE_TASK:DELETE', function (event, data) {
            var taskIndex = _.findIndex($ctrl.tasks, { id: data.id });
            deletedOneOffTaskIndex = taskIndex;
            if (taskIndex >= 0) {
              $ctrl.tasks.splice(taskIndex, 1);
              $ctrl.taskLength = $ctrl.taskLength - 1;
            }
          });

          /**
           * capture event STANDALONE_TASK:RESTORE
           * and unregister when component is restore
           * @see onDestroy
           */
          unRegisterRestoreTaskEventHandler = $rootScope.$on('STANDALONE_TASK:RESTORE', function (event, data) {
            if (deletedOneOffTaskIndex >= 0) {
              $ctrl.taskLength = $ctrl.taskLength + 1;
              $ctrl.tasks.splice(deletedOneOffTaskIndex, 0, data);
            }
          });

          /**
            * @ngdoc method
            * @name updateTasksByUsers
            * @private
            * @description update task in user task list by users
            * @param {Object} updatedTask 
            */
          function updateTasksByUsers(updatedTask) {
            _.forEach($ctrl.userTasks, function (val, key) {
              if (_.includes(updatedTask.task.owners.users, val.user.id)) {
                var i = _.findIndex(val.tasks, { id: updatedTask.task.id });
                if (i < 0) {
                  $ctrl.userTasks[key].tasks.push(updatedTask.task);
                  $ctrl.taskLength = $ctrl.taskLength + 1;
                } else {
                  $ctrl.userTasks[key].tasks[i] = angular.copy(updatedTask.task);
                }
              }
              if (!_.includes(updatedTask.task.owners.users, val.user.id)) {
                var index = _.findIndex(val.tasks, { id: updatedTask.task.id });
                if (index >= 0) {
                  $ctrl.userTasks[key].tasks.splice(index, 1);
                  $ctrl.taskLength = $ctrl.taskLength - 1;
                }
              }
            });
          }

          /**
            * @ngdoc method
            * @name updateUserWithNoTasks
            * @private
            * @description update task for user which have no tasks
            * @param {Object} updatedTask 
            */
          function updateUserWithNoTasks(updatedTask) {
            _.forEach($ctrl.userWithNoTasks, function (val, key) {
              if (_.includes(updatedTask.task.owners.users, val.user.id)) {
                $ctrl.userWithNoTasks.splice(key, 1);
                $ctrl.userTasks.push({
                  user: val.user,
                  tasks: [updatedTask.task]
                });
                $ctrl.taskLength = $ctrl.taskLength + 1;
              }
            });
            $ctrl.userWithNoTasks = _.filter($ctrl.userTasks, function (userTask) {
              return !userTask.tasks.length;
            });
          }

          /**
            * @ngdoc method
            * @name updateTasksByGuest
            * @private
            * @description update task in guest task list by guest
            * @param {Object} updatedTask 
            */
          function updateTasksByGuest(updatedTask) {
            if ($ctrl.guestTasks.length <= 0 && updatedTask.task.owners.guests.length > 0) {
              $ctrl.guestTasks.push({
                guest: updatedTask.task.owners.guests[0],
                tasks: [updatedTask.task]
              });
              $ctrl.taskLength = $ctrl.taskLength + 1;
            }

            if ($ctrl.guestTasks.length > 0) {
              _.forEach($ctrl.guestTasks, function (val, key) {
                if (_.includes(updatedTask.task.owners.guests, val.guest)) {
                  var index = _.findIndex(val.tasks, { id: updatedTask.task.id });
                  if (index < 0) {
                    $ctrl.guestTasks[key].tasks.push(updatedTask.task);
                    $ctrl.taskLength = $ctrl.taskLength + 1;
                  } else {
                    $ctrl.guestTasks[key].tasks[index] = angular.copy(updatedTask.task);
                  }
                }
                if (!_.includes(updatedTask.task.owners.guests, val.guest)) {
                  var index = _.findIndex(val.tasks, { id: updatedTask.task.id });
                  if (index >= 0) {
                    $ctrl.guestTasks[key].tasks.splice(index, 1);
                    $ctrl.taskLength = $ctrl.taskLength - 1;
                  } else if (index < 0 && updatedTask.task.owners.guests.length > 0) {
                    var guestIndex = _.findIndex($ctrl.guestTasks, { guest: updatedTask.task.owners.guests[0] })
                    if (guestIndex < 0) {
                      $ctrl.guestTasks.push({
                        guest: updatedTask.task.owners.guests[0],
                        tasks: [updatedTask.task]
                      });
                      $ctrl.taskLength = $ctrl.taskLength + 1;
                    }
                  }
                }
              });
            }
          }

          /**
            * @ngdoc method
            * @name helpText
            * @private
            * @description Set help text
            * @param {Object} selectedUser 
            * @returns String
            */
          function helpText(selectedUser) {
            $ctrl.noTaskTitle = (_.get(selectedUser, 'id') === _.get($rootScope, 'identity.id')) ? $filter('translate')('tasks.noTaskTitle') : $filter('translate')('tasks.noTaskUserTitle', { 'first_name': selectedUser.first_name });
          }

          /**
            * @ngdoc method
            * @name showSpinner
            * @private
            * @param {Boolean} isFirstLoad
            * @description Show spinner
            */
          function showSpinner(isFirstLoad) {
            if (isFirstLoad) {
              $ctrl.currentPage === 1 ? blockUI.start() : ($ctrl.showLoadingMore = true);
            }
          }

          /**
            * @ngdoc method
            * @name hideSpinner
            * @private
            * @description Hide spinner
            */
          function hideSpinner() {
            $ctrl.currentPage === 1 ? blockUI.stop() : ($ctrl.showLoadingMore = false);
          }

          /**
           * @ngdoc method
           * @name filterTasks
           * @param {Boolean} isFirstLoad
           * @description For filter tasks
           */
          function filterTasks(isFirstLoad) {
            if ($ctrl.primaryFilterConfig.taskStatus.value === 'completed' && ($ctrl.primaryFilterConfig.assignee.key === 'me' || $ctrl.primaryFilterConfig.assignee.key === 'by_coworker_name')) {
              getCompletedTasks(isFirstLoad);
            } else if ($ctrl.primaryFilterConfig.assignee.key === 'by_guest_email') {
              getGuestTask(isFirstLoad);
            } else {
              getTasks(isFirstLoad);
            }
          }

          unregisterTasksFilterHandler = $rootScope.$on('TASK:FILTER_CONFIG', function ($event, data) {
            if (!isSpinnerActive()) {
              resetToDefault();
              $ctrl.primaryFilterConfig = data.primaryFilterConfig;
              filterTasks(true);
            }
          });

          /**
           * @ngdoc method
           * @name getGuestTask
           * @private
           * @param {Boolean} isFirstLoad
           * @description Get guest task
           */
          function getGuestTask(isFirstLoad) {
            var params = getRequestParams(true);
            angular.extend(params, {
              status: $ctrl.primaryFilterConfig.taskStatus.value === 'completed' ? 'complete' : 'active'
            });
            showSpinner(isFirstLoad);
            $ctrl.tasksLoaded = false;
            TasksService.get(params).then(function (response) {
              onTasksLoaded(response, isFirstLoad, getGuestTask);
            }, function () {
              hideSpinner();
            });
          }

          /**
           * @ngdoc method
           * @name onTasksLoaded
           * @private
           * @param {Object} resData
           * @param {Boolean} isFirstLoad
           * @param {*} callFunction
           * @description update the data when tasks loaded
           */
          function onTasksLoaded(resData, isFirstLoad, callFunction) {
            $ctrl.tasksLoaded = true;
            $ctrl.completedTaskLength = ($ctrl.completedTaskLength === 0 && resData.data.length !== 0) ? -1 : $ctrl.completedTaskLength;
            $ctrl.tasksMeta = resData.meta;
            $ctrl.totalPages = _.get($ctrl.tasksMeta, 'pagination.total_pages');
            $ctrl.isLastPage = $ctrl.currentPage === $ctrl.totalPages;
            var defaultTaskLength = _.get(resData, 'meta.pagination.total', 0);
            $ctrl.taskLength = _.get(resData, 'meta.visible_tasks_total', defaultTaskLength);
            if (isFirstLoad) {
              hideSpinner();
              $ctrl.currentPage++;
              callFunction(false);
              if (_.isArray(resData.data) && resData.data.length > 0) {
                if ($ctrl.primaryFilterConfig && $ctrl.primaryFilterConfig.assignee.key === 'everyone') {
                  groupTasksByUser(resData.data);
                } else {
                  appendTasks(resData.data);
                }
              }
            } else {
              loadMoreTasks = loadMoreTasks.concat(resData.data);
              if (isSpinnerActive()) {
                hideSpinner();
              }

              if ($ctrl.tasks.length && enableVisibleTasksAutoLoad) {
                var visibleTasksLength = _.filter($ctrl.tasks, function (t) {
                  return t.status !== 'auto-skipped';
                }).length;

                if (visibleTasksLength < Math.min($ctrl.taskLength, autoLoadTasksMaxLimit)) {
                  loadMore();
                } else {
                  enableVisibleTasksAutoLoad = false;
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name getRequestParams
           * @private
           * @param {Boolean} isGuestTask
           * @description Get requested parameters
           */
          function getRequestParams(isGuestTask) {
            var orderBy = !_.isEmpty($ctrl.run) ? TASKORDER.POSITION : TASKORDER.DEADLINE,
              sortBy = (($ctrl.params || {}).sortBy || {}).value,
              process = (($ctrl.params || {}).process || {}).id,
              params = {
                per_page: $ctrl.perPage,
                page: $ctrl.currentPage,
                with: 'run,comments,threads,step',
                sort: sortBy ? sortBy : orderBy,
                processes: process
              };
            if (process === 'all') {
              delete params.processes;
            }
            if (isGuestTask) {
              angular.extend(params, {
                guests: $ctrl.primaryFilterConfig.assignee.id
              });
            }
            return params;
          }

          /**
           * @ngdoc method
           * @name resetToDefault
           * @private
           * @description Reset the data when filter get changed
           */
          function resetToDefault() {
            $ctrl.tasksMeta = {};
            $ctrl.recentlyCompletedTasks = [];
            $ctrl.tasks = [];
            $ctrl.isLastPage = false;
            $ctrl.currentPage = 1;
            $ctrl.userTasks = [];
            $ctrl.userWithNoTasks = [];
            $ctrl.guestTasks = [];
            $ctrl.params = {};
            $ctrl.tasksLoaded = false;
            $ctrl.removedUserTasks = [];
            $ctrl.showLoadingMore = false;
            $ctrl.backgroundLoadedCount = 1;
            $ctrl.totalPages = undefined;
            loadMoreTasks = [];
            enableVisibleTasksAutoLoad = true;
          }

          /**
           * @ngdoc method
           * @name updateTasksDeadline
           * @private
           * @param {Object} tasks
           * @description Update deadline in the task
           */
          function updateTasksDeadline(tasks) {
            _.forEach($ctrl.tasks, function (t) {
              var task = _.find(tasks, function (task) {
                return task.id === t.id;
              });
              if (!_.isUndefined(task)) {
                t.deadline = task.deadline;
              }
            });
          }

          /**
           * Listen on tasks updated deadline
           * @type {*|(function())}
           */
          unRegisterDeadlineHandler = $rootScope.$on('TASKS:UPDATED', function ($event, data) {
            updateTasksDeadline(data.tasks.data);
          });

          /**
           * @ngdoc method
           * @name setNumberOnTask
           * @description Add number properties in task
           * Shows number on active process task only
           */
          function setNumberOnTask() {
            if ($ctrl.entity === 'runs') {
              _.filter($ctrl.tasks, function (t) {
                return t.status != "auto-skipped";
              }).map(function (task, index) {
                task.number = index + 1;
                return task;
              });
            }
          }

          /**
           * @ngdoc method
           * @name standaloneModal
           * @description open the stand alone modal to create a tasks
           * Open the stand alone modal to create a tasks
           */

          function standaloneModal() {
            TasksService.standaloneModal();
          }

          /**
           * @ngdoc method
           * @name toggleTasksView
           * @public
           * @description Handler to toggle the tasks view when the everyone filter is applied
           * @param {any} groupedTasksObj
           * @param {any} limit
           * @returns void
           */
          function toggleTasksView(groupedTasksObj, limit) {
            groupedTasksObj.tasksLimit = limit;
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name isPrimaryFilterConfig
           * @public
           * @description check primary filter config
           */
          function isPrimaryFilterConfig() {
            return !_.isEmpty($ctrl.primaryFilterConfig) && $ctrl.primaryFilterConfig.assignee.label === 'everyone';
          }

          /**
           * @ngdoc method
           * @name updateExistingTasksChangedByRules
           * @public
           * @param tasksArr
           * @description Update task statuses changed by rules
           */
          function updateExistingTasksChangedByRules(tasksArr) {
            _.forEach(tasksArr, function (taskStatus, taskId) {
              var task = _.find($ctrl.tasks.concat(loadMoreTasks), function (t) {
                return t.id === taskId;
              });
              if (!_.isUndefined(task)) {
                task['is_completable'] = 1;
                task['status'] = taskStatus;
                task.run.data.collaborators = _.uniq(_.concat(task.run.data.collaborators, task.owners.users));
              }
            });
          }

          /**
           * @ngdoc method
           * @name isSpinnerActive
           * @public
           * @description Return true if spinner is active
           */
          function isSpinnerActive() {
            return $ctrl.showLoadingMore;
          }
        }
    });
})();

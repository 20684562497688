(function () {
  'use strict';
  angular.module('tallyfy')
    .component('orgRoles', {
      bindings: {},
      templateUrl: 'app/modules/organizations/orgRoles/orgRoles.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $filter, $uibModal, blockUI, RoleTableService, StepService, Growl) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('orgRoles'),
            growl = new Growl();

          $ctrl.rolePagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getRoleData = getRoleData;
          $ctrl.openAddNewRoleModal = openAddNewRoleModal;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getRoleData() {
            var defer = $q.defer();
            StepService.getAllRoles()
              .then(function (response) {
                defer.resolve(response.data);
              }, function () {
                defer.reject();
              });
            return defer.promise;
          }

          function editRole(uid) {
            var role = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            $ctrl.tableOptions.templateScope.variables.editedRole = role;
          }

          function deleteRole(e, uid) {
            blockUI.start();
            var role = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            StepService.deleteRole(role.id)
              .then(function () {
                var grid = $ctrl.tableOptions.tableElement.data('kendoGrid');
                grid.removeRow($(e.target).closest('tr'));
                grid.dataSource.sync();
                $rootScope.$emit('COUNT_UPDATE', { type: 'roles', count: $ctrl.tableOptions.gridConfig.dataSource.data().length });
                growl.success($filter('translate')('orgRoles.content.roleDeleted', { roleName: role.title }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
          }

          function saveEditedRole() {
            blockUI.start();
            StepService.editRole($ctrl.tableOptions.templateScope.variables.editedRole.id, {
              title: $ctrl.tableOptions.templateScope.variables.editedRole.title
            }).then(function (res) {
              var role = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { id: $ctrl.tableOptions.templateScope.variables.editedRole.id });
              angular.extend(role, { title: $ctrl.tableOptions.templateScope.variables.editedRole.title });
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              $ctrl.tableOptions.templateScope.variables.editedRole = void 0;
              growl.success($filter('translate')('orgRoles.content.roleUpdated', { roleName: role.title }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          function cancelEditRole() {
            $ctrl.tableOptions.templateScope.variables.editedRole = void 0;
          }

          function openAddNewRoleModal() {
            $uibModal.open({
              component: 'newRoleModal',
              windowClass: 'org-role-modal',
              backdrop: 'static',
            }).result.then(function (newRole) {
              if (newRole) {
                $ctrl.tableOptions.gridConfig.dataSource.add(newRole);
                $ctrl.tableOptions.gridConfig.dataSource.sync();
                $rootScope.$emit('COUNT_UPDATE', { type: 'roles', count: $ctrl.tableOptions.gridConfig.dataSource.data().length });
                $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
                growl.success($filter('translate')('orgRoles.content.roleCreated', { roleName: newRole.title }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            });
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: RoleTableService.getRoleTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'title',
                  title: $filter('translate')('orgProfile.label.orgTitle'),
                  initState: true
                }]
              },
              tableFilterModel: {
                title: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  edit: editRole,
                  delete: deleteRole,
                  cancel: cancelEditRole,
                  saveEditedRole: saveEditedRole
                },
                variables: {
                  editedRole: void 0
                }
              }
            }
          }
        }
    })
})();
/**
 * @ngdoc Config
 * @name tallyfy.organizations.UserAssignmentsConfig
 * @module tallyfy.organizations.users
 *
 * @description
 * Set route for user assignment
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .config(UserAssignmentsConfig);
    
  /**
   * Constructor
   * ManageStateConfig construct 
   */
   /*@ngInject*/
  function UserAssignmentsConfig($stateProvider) {
    
    $stateProvider.state('userAssignments', {
      parent: 'app',
      url: '/:org_slug/userAssignments/:id',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'userAssignments'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    });
  }
})();
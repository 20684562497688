(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tyKendoSelectBox', {
      bindings: {
        qWidgetRenderedCallback: '&',
        options: '<',
        itemSource: '<',
        valueChange: '&',
        ngModel: '='
      },
      templateUrl: 'app/components/kendo/selectbox/ty-kendo-selectbox.component.html',
      controller:
        /*@ngInject*/
        function (_, $timeout, KendoUIService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() { }

          function onChanges(changes) {
            if (changes.itemSource && changes.itemSource.currentValue) {
              $timeout(function () {
                initializeKendoSelectBox();
              }, 1000);
            }
          }
          function onDestroy() {
            var selectBox = $ctrl.options.selectBoxElement;
            if (selectBox) {
              if (selectBox.data('kendoMultiSelect')) {
                selectBox.data('kendoMultiSelect').destroy();
              }
              selectBox.empty();
              selectBox.remove();
            }
          }

          function initializeKendoSelectBox() {
            if (!$ctrl.options) {
              $ctrl.options = {};
            }
            KendoUIService.attachWidgetEventHandler($ctrl.options.selectBoxElement, {
              init: function () {
                $ctrl.options.widgetConfig.dataSource.data($ctrl.itemSource);
                $ctrl.options.selectBoxElement = angular.element('#' + $ctrl.options.selectBoxId);
                var multiSelect = $ctrl.options.selectBoxElement.data('kendoMultiSelect');
                multiSelect.value($ctrl.ngModel);
                if ($ctrl.valueChange) {
                  multiSelect.bind("change", function (e) {
                    $ctrl.valueChange({ $event: e });
                  });
                }
                if ($ctrl.qWidgetRenderedCallback) {
                  $ctrl.qWidgetRenderedCallback({ widget: $ctrl.options.selectBoxElement.data('kendoMultiSelect') });
                }
              }
            });
          }
        }
    })
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.account.factory.GuestPreferenceRepository
   * @module tallyfy.account
   *
   * @description
   * Create Rest resource for guest's preferences
   *
   * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
   * 
   * */
  angular
    .module('tallyfy.account')
    .factory('GuestPreferenceRepository', GuestPreferenceRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  GuestPreferenceRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * GuestPreferenceRepository construct 
   */
  function GuestPreferenceRepository(BaseRepository) {
    var BASE_URI_SEGMENT = '/public/organizations/org_id', repository;

    repository = BaseRepository(BASE_URI_SEGMENT+'/guests/guest_code/:action/:sub_action', {
      action: '@action',
      sub_action: '@sub_action'
    }, {});

    repository.getPreferences = function () {
      var params = {
        action: 'preferences',
        skipAuthToken: true
      };
      return repository.get(params);
    };

    repository.getCadenceDays = function () {
      var params = {
        action: 'get_cadence_days',
        skipAuthToken: true
      };
      return repository.get(params);
    };

    repository.updateCadenceDays = function(data, token) {
      var params = {
        action: 'cadence_days',
        skipAuthToken: true,
        guest_code: token
      };
      return repository.update(params, data);
    }

    repository.updatePreferences = function (data) {
      var params = {
        action: 'preferences',
        skipAuthToken: true
      };
      return repository.create(params, data);
    };

    return repository;
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.process.component.createFolder
 * @module tallyfy.process
 *
 * @description
 * Modal to show create a folder
 *
 * @author Adi Winata (gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com)
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.folders')
    .component('folderModal', {
      templateUrl: 'app/modules/folder/components/folderModal/folder-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, FolderService, $confirm, $state, $scope, MODAL_CLOSE_EVENTS, $rootScope, $filter, Growl) {
          var $ctrl = this,
            onModalClosePreventHandler,
            folderSelectedEventHandler,
            growl = new Growl();

          /**
           * public properties
           */
          $ctrl.onSaving = false;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveFolder = saveFolder;
          $ctrl.cancel = cancel;
          $ctrl.deleteFolder = deleteFolder;

          /**
           * @ngdoc method
           * 
           * @name onInit
           * @returns void
           * 
           * @description
           * initalization component
           */
          function onInit() {
            $ctrl.activeFolder = _.get($ctrl.resolve, 'folder', { name: $filter('translate')('global.navbar.left.none')});
            $ctrl.selectParentFolder = _.get($ctrl.resolve, 'folder.parent.data', { name: $filter('translate')('global.navbar.left.none')});
            if ($ctrl.resolve.folder) {
              $ctrl.newFolderName = $ctrl.resolve.folder.name;
            }
          }

          /**
           * @ngdoc method
           * 
           * @name onDestroy
           * @returns void
           * 
           * @description
           * on destroy component
           */
          function onDestroy() {
            onModalClosePreventHandler();
            folderSelectedEventHandler();
          }

          /**
           * @ngdoc method
           * 
           * @name onChanges
           * @returns void
           * 
           * @description
           * on component get changes
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * 
           * @name save
           * @returns void
           * 
           * @description
           * save folder
           */
          function saveFolder() {
            $ctrl.onSaving = true;
            if (!$ctrl.resolve.folder) {
              var params = {
                name: $ctrl.newFolderName,
                folder_type: $ctrl.resolve.folderType,
                active: 1,
                with: 'children'
              };
              if (_.get($ctrl.resolve, 'parentId') && _.get($ctrl.resolve, 'parentId') !== 'root') {
                params.parent_id = _.get($ctrl.resolve, 'parentId');
              }
              FolderService.createFolder(params).then(function (response) {
                $ctrl.onSaving = false;
                $ctrl.close({ $value: response });
              }, function () {
                $ctrl.onSaving = false;
              });
            } else {
              $ctrl.resolve.folder.name = $ctrl.newFolderName;
              var selectedFolderId = _.get($ctrl.selectParentFolder, 'id');
              if (selectedFolderId && (selectedFolderId !==  $ctrl.resolve.folder.parent_id)) {
                $ctrl.resolve.folder.parent_id = angular.copy($ctrl.selectParentFolder.id);
              } else if(!selectedFolderId) {
                $ctrl.resolve.folder.parent_id = null;
              }
              FolderService.editFolder($ctrl.resolve.folder).then(function (response) {
                $ctrl.onSaving = false;
                growl.success($filter('translate')('global.growlMessages.editAndMoveSuccess'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                });
                if ($ctrl.resolve.folderType === 'checklist') {
                  $rootScope.$emit('FOLDER:UPDATE_BREADCRUMB');
                }
                $ctrl.close({ $value: response });
              }, function () {
                $ctrl.onSaving = false;
              });
            }
          }

          function deleteFolder() {
            $confirm({
              'header': 'template.folder.deleteModal.header',
              'body': '',
              'buttons': {
                'accept': 'template.folder.deleteModal.buttons.accept',
                'cancel': 'template.folder.deleteModal.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              var parent_id = $ctrl.resolve.parent_id;
              $ctrl.onSaving = true;
              FolderService.delete({
                id: $ctrl.resolve.folder.id
              }).then(function () {
                $ctrl.onSaving = false;
                if ($ctrl.resolve.folderType === 'checklist') {
                  $state.go('process.templates', {
                    status: 'folder',
                    page: 1,
                    folder_id: parent_id
                  });
                }
              }, function () {
                $ctrl.onSaving = false;
              });
            });
          }

          /**
           * @ngdoc method
           * 
           * @name cancel
           * @returns void
           * 
           * @description
           * on modal get dismiss
           */
          function cancel() {
            $ctrl.dismiss({ $value: 'dismissed' });
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.folderForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
          
          folderSelectedEventHandler = $rootScope.$on('FOLDER:SELECTED', function (e, data) {
            if ($ctrl.isOpenPopOver) {
              $ctrl.selectParentFolder = data.folder;
            }
            $ctrl.isOpenPopOver = false;
          });
        }
    });
})();

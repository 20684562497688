/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.
 * @module tallyfy.organizations
 *
 * @description
 * A component to add comment
 *
 * @author Kiran sompura ( gmail::kiranv.sompura@gmail.com)
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('commentdialog', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/commentdialog/commentdialog.html',
      controller:
      /*@ngInject*/
      function (Helper, $rootScope, USER_STATE) {
        var $ctrl = this;
        
        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * Expose bindable methods
         * these methods are accessible in view
         */
        $ctrl.cancel = cancel;
        $ctrl.accepts = accepts;

        /**
         * @ngdoc method
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { 
          $ctrl.froalaOptions = Helper.getFroalaOptions({
            isGuest: $rootScope.userState === USER_STATE.GUEST,
            accessibility: {
              haveAuthority: Helper.checkAccessAuthority(false),
              callback: Helper.checkAccessAuthority
            },
            heightMin: 140,
            heightMax: 150,
            allowEdit: true,
            currentUser: $rootScope.identity,
            enableFullscreen: true,
            limit: 30
          });
        }

        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        /**
         * Send accept modal event
         */
        function accepts() {
          if (!$ctrl.commentMessage) {
            $ctrl.messageForm.comment.$invalid = true;
            $ctrl.messageForm.comment.$valid = false;
          } else {
            $ctrl.close({ $value: $ctrl.commentMessage });
          }
        }

        /**
         * Send close modal event
         */
        function cancel() {
          $ctrl.dismiss({ $value: '' });
        }
      }
    });
})();
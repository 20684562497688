/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportView
 * @module tallyfy.support
 *
 * @description
 * A component to manage support view
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportUsers', {
      require: {
        parent: '?^supportView'
      },
      bindings: {
        support: '<?'
      },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          var layoutType = $attrs.layout || 'default';
          return 'app/modules/support/components/users/' + layoutType.toLowerCase() + '-support-users.html';
        }
      ],
      controller:
        /*@ngInject*/
        function (_, $attrs, $stateParams, SupportService, Auth, $state, blockUI, Growl, ENV_CONFIG, MOESIF_URL, $rootScope, TFY_EVENTS, $log, OrganizationsService) {
          var $ctrl = this,
            $growl = new Growl(),
            blockUI = blockUI.instances.get('supportUserList'),
            usersParam;

          /**
           * public properties
           */
          $ctrl.users = [];
          $ctrl.switchedUser = null;
          $ctrl.orgId = $stateParams.user_org;
          $ctrl.listPager = {
            total: 0,
            per_page: $attrs.perPage || 20,
            current_page: 1,
            onPageChanged: function (current_page) {
              this.current_page = current_page;
              $ctrl.search.searchText.length ? searchUsers() : getSupportUsers(usersParam);
            }
          };
          $ctrl.search = {
            form: {},
            searchText: '',
            reset: function () {
              this.searchText = '';
              getSupportUsers(usersParam);
            }
          };

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;

          /**
           * public methods
           */
          $ctrl.searchUsers = searchUsers;
          $ctrl.switchUser = switchUser;
          $ctrl.masqueradeUser = masqueradeUser;
          $ctrl.resetPassword = resetPassword;

          function initialization() {
            $ctrl.dateFormat = OrganizationsService.getDateFormat();
            $ctrl.entityType = $state.current.name === 'support.orgGuests' ? 'guests' : 'users';
            if ($stateParams.user_org) {
              usersParam = {
                type: $stateParams.user_org,
                action: 'users'
              };
            } else {
              usersParam = {
                type: $ctrl.entityType || 'users',
                with: 'organizations'
              };
            }
            $ctrl.capsuleCRMUrl = ENV_CONFIG.TALLYFY_CAPSULECRM_URL;
            $ctrl.moesifURL = _.toLower(ENV_CONFIG.APP_ENV) === 'prod' ? MOESIF_URL.production : MOESIF_URL.staging;
            getSupportUsers(usersParam);
          }

          function getSupportUsers(params) {
            blockUI.start();
            var requestParams = {
              per_page: $ctrl.listPager.per_page,
              page: $ctrl.listPager.current_page
            };

            if (params && _.isObject(params)) {
              angular.extend(requestParams, params);
            }

            SupportService.allUsers(requestParams).then(function (response) {
              var users = response.data || [];
              users = _.map(response.data, function(user) {
                if ($ctrl.switchedUser === user.id) {
                  $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, user);
                }
                user.activities = [];
                var invitedByUser = _.find(response.data, { 'id': user.invited_by }), disableByUser = _.find(response.data, { 'id': user.disabled_by }), reactivatedByUser = _.find(response.data, { 'id': user.reactivated_by });
                user.invited_by && invitedByUser ? user.activities.push({ userEmail: invitedByUser.email, actStatus: 1, activityDate: user.approved_at }) : angular.noop();
                user.disabled_by && disableByUser ? user.activities.push({ userEmail: disableByUser.email, actStatus: 2, activityDate: user.disabled_at }) : angular.noop();
                user.reactivated_by && reactivatedByUser ? user.activities.push({ userEmail: reactivatedByUser.email, actStatus: 3, activityDate: user.reactivated_at }) : angular.noop();
                return user;
              });
              $ctrl.users = users;
              if (!_.isEmpty(response.meta)) {
                $ctrl.listPager.total = response.meta.pagination.total;
              } else {
                $ctrl.listPager.total = $ctrl.users.length;
              }
              if ($ctrl.parent) {
                $ctrl.parent.totalUsers = $ctrl.listPager.total;
              }
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          function searchUsers() {
            if ($ctrl.search.searchText.length) {
              var searchParams = {
                q: $ctrl.search.searchText
              };

              if (_.isObject(usersParam)) {
                angular.extend(searchParams, usersParam);
              }
              getSupportUsers(searchParams);
            }
            else{
              initialization();
            }
          }

          function switchUser(user) {
            $ctrl.switchedUser = null;
            var requestParams = {
              action: $stateParams.user_org,
              sub_action_id: user.id,
              flag: user.disabled_at ? 1 : 0
            };
            SupportService.switchUser(requestParams).then(function (response) {
              if (response.data.result) {
                $ctrl.search.searchText.length ? searchUsers() : getSupportUsers(usersParam);
                $ctrl.support.users_count = user.disabled_at ? $ctrl.support.users_count + 1 : $ctrl.support.users_count - 1;
              }
              $ctrl.switchedUser = user.id;
            }, function (error) {
              console.log(error);
            });
          }

          function masqueradeUser(user) {
            blockUI.start();
            Auth.masqueradeUser(user, { admin: true }).then(function (organization) {
              $state.go('home', { org_id: organization.id }, { reload: true });
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }

          /**
           * @ngdoc ngdoc
           * @name resetPassword
           * @private
           * @description Reset a user's password
           * @param user
           */
          function resetPassword(user) {
            blockUI.start();
            SupportService.resetPassword({
              sub_action_id: user.id
            }).then(function (resp) {
              if (resp.data) {
                var info = 'New user password: <b>' + resp.data.new_password + '</b>';
                $growl.success(info);
              }
              blockUI.stop();
            });
          }
        }
    });
})();

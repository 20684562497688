/**
 * @ngdoc Component
 * @name tallyfy.run.component.ownerAssigner
 * @module tallyfy.run
 *
 * @description
 * A component to assign owners
 * @param resolve {Object} An object of resolved promises
 * @param close {function} An function callback which is triggered when modal is closed.
 * @param dismiss {function} An function callback which is triggered when modal is cancelled.
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('ownerAssigner', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      require: {},
      template: '<div ng-include="$ctrl.templateUrl">',
      controller:
        /*@ngInject*/
        function (_, blockUI, Helper, PLANS, AuthPlan, $timeout, UsersService, $scope, MODAL_CLOSE_EVENTS, $rootScope, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('run'),
            onModalClosePreventHandler;
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancel = cancelModal;
          $ctrl.updateOwners = updateOwners;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.getTagClass = getTagClass;
          $ctrl.upgradePlan = upgradePlan;
          $ctrl.openInviteModal = UsersService.openInviteModal;
          $ctrl.searchRecords = searchRecords;
          $ctrl.setAssignmentUser = setAssignmentUser;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;

          /**
           * public properties
           */
          $ctrl.guests = [];
          $ctrl.owners = [];
          $ctrl.groups = [];
          $ctrl.ownerAssigner = {
            isGuestAllowed: true,
            isFormValid: true
          };
          $ctrl.searchPreviousValue = '';
          $ctrl.ownersDisabled = false;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.params = $ctrl.resolve.params || {};
            $ctrl.displayProperty = $ctrl.params.displayProperty || 'full_name';
            $ctrl.users = _.sortBy($ctrl.resolve.users, $ctrl.displayProperty);
            $ctrl.orgGroups = $ctrl.resolve.orgGroups || [];
            $ctrl.guests = $ctrl.resolve.guests || [];
            $ctrl.isRestricted = AuthPlan.isRestricted(PLANS.FREE);
            $ctrl.activeUsersCache = angular.copy($ctrl.users);

            if ($ctrl.params.assignerType === 'OWNER_RE_ASSIGNER') {
              prepareOwner();
              return;
            }
            $ctrl.owners = $ctrl.resolve.owners || [];
            $ctrl.groups = $ctrl.resolve.groups || [];
            $ctrl.ownerLists =  _.union($ctrl.owners || [], $ctrl.groups || [], $ctrl.guests || []);
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.resolve && bindings.resolve.isFirstChange()) {
              var assignerType = bindings.resolve.currentValue.params.assignerType || 'POWER_ASSINER';
              assignerType = assignerType.toLowerCase();
              $ctrl.templateUrl = 'app/modules/runs/ownerAssigner/' + assignerType + '.html';
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            onModalClosePreventHandler();
          }

          /**
           * @function
           * @name prepareOwner
           * @description 
           */
          function prepareOwner() {
            var newOwner = _.get($ctrl.resolve, 'owners[0]');
            $ctrl.ownerName = newOwner.full_name;
            _.remove($ctrl.users, {
              id: newOwner.id
            });
          }

          /**
           * @function
           * @name cancelModal
           * @description Callback function for cancel the modal
           */
          function cancelModal() {
            $ctrl.dismiss({$value: 'cancel'});
          }

          /**
           * @function
           * @name updateOwners
           * @description Update owners
           */
          function updateOwners() {
            $timeout(function () {
              if ($ctrl.ownerAssignerForm.owners.$invalid) {
                return;
              }
              $ctrl.close({
                $value: {
                  users: angular.copy($ctrl.owners),
                  guests: angular.copy($ctrl.guests),
                  groups: angular.copy($ctrl.groups),
                  type: $ctrl.params.assignerType
                }
              });
            }, 500);
          }

          /**
           * @function
           * @name isUserExist
           * @description Check user already exist in the object
           */
          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return owner.email === user.full_name || user.email;
            });
          }

          /**
           * @function
           * @name isGuestExist
           * @description Check guest already exist in the object
           */
          function isGuestExist(guests, user) {
            return _.some(guests, function (guest) {
              return guest.full_name === user.full_name || user.id;
            });
          }
          
          /**
           * @function
           * @name isUserEmailAlreadyAdded
           * @description Check if user email is added
           * and it's already assigned
           */
          function isUserEmailAlreadyAdded(user) {
            return _.some($ctrl.owners, function (owner) {
              return owner.email === user.full_name;
            });
          }

          /**
           * @function
           * @name onUserAdded
           * @description Callback function onTagAdded in ngTagsInput
           */
          function onUserAdded($owner) {
            if (!isUserExist($ctrl.users, $owner)) {
              if ($owner.type === 'group') {
                $ctrl.groups.push($owner);
              } else {
                $owner.id = $owner.full_name;
                $ctrl.guests.push($owner);
              }
            } else {
              if (!$owner.id) {
                $owner = _.find($ctrl.users, function (user) {
                  return user.email === $owner.full_name;
                });
              }
              $ctrl.owners.push($owner);
            }
          }

          /**
           * @function
           * @name getTagClass
           * @description Callback function tagClass in ngTagsInput
           */
          function getTagClass(user) {
            if (Helper.isValidEmail(user.full_name)) {
              return 'tag-email';
            }
          }

          /**
           * @function
           * @name upgradePlan
           * Dismiss modal and go to upgrade page
           */
          function upgradePlan() {
            $ctrl.close({
              $value: {
                upgrade: true
              }
            });
          }

          /**
            * @ngdoc method
            * @name searchRecords
            * @private
            * @description search record locally
            * wher user search for record. 
            * @param {object} $select
            * @param {object} search
            */
          function searchRecords($select, search) {
            if (!$select.open && (!search && !$ctrl.searchPreviousValue)) {
              return;
            }
            if (search) {
              $ctrl.searchPreviousValue = search;
              var searchedRecords = _.filter($ctrl.activeUsersCache, function (value) {
                return _.toLower(value.full_name).indexOf(_.toLower(search)) !== -1 || _.toLower(value.text).indexOf(_.toLower(search)) !== -1;
              });
              $ctrl.users = searchedRecords;
            }
            if(!search && $ctrl.searchPreviousValue) {
              $ctrl.users = $ctrl.activeUsersCache;
            }
          }

          /**
            * @ngdoc method
            * @name setAssignmentUser
            * @param {Object} selected
            * @description Reset steps assignment 
          */
          function setAssignmentUser() {
            $ctrl.ownersDisabled = true;
          }

          /**
           * @ngdoc method
           * @name setSearchPlaceholder
           * @description Set placeholder message from json file
           * @param {Object} $select
           */
          function setSearchPlaceholder($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')("organizations.owner.placeholder");
            }
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function(event, reason) {
            if ($ctrl.ownerAssignerForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
        }
      //end of Controller
    });
})();

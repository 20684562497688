/**
 * @ngdoc Config
 * @name tallyfy.run.config
 * @module tallyfy.run
 *
 * @description
 * set route run Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .config(['$stateProvider', function ($stateProvider) {
      $stateProvider.state('run.view', {
        url: '/processes/:id?:activeTask&activeTab&onboarding&comment&focusField',
        data: {
          authorities: ['USER_ROLE']
        },
        views: {
          'run_view': {
            component: 'viewRun'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('tasks');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('runs');
            return $translate.refresh();
          }]
        }
      });

      $stateProvider.state('guest.runs.view', {
        url: '/processes/:id?:activeTask&activeTab&onboarding',
        parent: 'guest',
        data: {
          authorities: []
        },
        views: {
          'content': {
            component: 'viewRun'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('tasks');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('runs');
            $translatePartialLoader.addPart('compact');
            $translatePartialLoader.addPart('steps');
            return $translate.refresh();
          }]
        }
      });
    }]);
})();

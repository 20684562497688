/**
 * @ngdoc Filter
 * @name objLimitTo
 * @module tallyfy
 * @description
 * return Limit to object's length in ng-repeat
 * @author Rehan Mahmoood ( go4mahmood@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('objLimitTo', function () {
      return function(obj, limit){
        var keys = Object.keys(obj);
        if (keys.length < 1) {
          return [];
        }

        var ret = new Object(), count = 0;
        angular.forEach(keys, function(key){
          if(count >= limit) return false;
          ret[key] = obj[key];
          count++;
        });
        return ret;
      };
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorReference', {
      templateUrl: 'app/components/documentPaneEditor/editorReference/editor-reference.component.html',
      bindings: {
        editorScope: '<?',
        isOpen: '=?',
        onSnippetSelected: '&',
        onBlueprintSelected: '&',
        isPublic: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $q, $timeout, ProcessService, OrganizationsService) {
          var $ctrl = this,
            snippetMenuContainer,
            blueprintMenuContainer;

          $ctrl.snippetList = [];
          $ctrl.blueprintList = [];
          $ctrl.snippetRequestConfig = {
            pagination: {
              page: 1,
              per_page: 10
            },
            isLoading: false,
            isLoaded: false
          }
          $ctrl.blueprintRequestConfig = {
            pagination: {
              page: 1,
              per_page: 10
            },
            isLoading: false,
            isLoaded: false
          }

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.dropdownOpen = dropdownOpen;
          $ctrl.dropdownSnippetOpen = dropdownSnippetOpen;
          $ctrl.dropdownBlueprintOpen = dropdownBlueprintOpen;
          $ctrl.snippetItemSelected = snippetItemSelected;
          $ctrl.blueprintItemSelected = blueprintItemSelected;
          $ctrl.blueprintSearchQueryParams = blueprintSearchQueryParams;

          function onInit() {
            if ($ctrl.isPublic) {
              $ctrl.snippetRequestConfig.pagination.skipAuthToken = true;
              $ctrl.blueprintRequestConfig.pagination.skipAuthToken = true;
            }
            getSnippets($ctrl.snippetRequestConfig.pagination)
              .then(function (res) {
                $ctrl.snippetList = _.uniqBy(_.concat($ctrl.snippetList, res.data), 'id');
                if (res.meta.pagination.current_page === res.meta.pagination.total_pages) {
                  $ctrl.snippetRequestConfig.isLoaded = true;
                  if (snippetMenuContainer) {
                    snippetMenuContainer.off('scroll', onSnippetMenuContainerThrottle());
                  }
                } else {
                  $ctrl.snippetRequestConfig.pagination.page++;
                  $timeout(function () {
                    snippetMenuContainer = $('.snippet-list-container');
                    snippetMenuContainer.on('scroll', onSnippetMenuContainerThrottle());
                  }, 0);
                }
              });
            getBlueprints($ctrl.blueprintRequestConfig.pagination)
              .then(function (res) {
                $ctrl.blueprintList = _.uniqBy(_.concat($ctrl.blueprintList, res.data), 'id');
                if (res.meta.pagination.current_page === res.meta.pagination.total_pages) {
                  $ctrl.blueprintRequestConfig.isLoaded = true;
                  if (blueprintMenuContainer) {
                    blueprintMenuContainer.off('scroll', onBlueprintMenuContainerThrottle());
                  }
                } else {
                  $ctrl.blueprintRequestConfig.pagination.page++;
                  $timeout(function () {
                    blueprintMenuContainer = $('.blueprint-list-container')
                    blueprintMenuContainer.on('scroll', onBlueprintMenuContainerThrottle());
                  }, 0);
                }
              });
          }

          function onChanges(changes) {
            if (changes.editorScope) {
              $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
            }
          }

          function onDestroy() {
            if (snippetMenuContainer) {
              snippetMenuContainer.off('scroll', onSnippetMenuContainerThrottle());
            }
            if (blueprintMenuContainer) {
              blueprintMenuContainer.off('scroll', onBlueprintMenuContainerThrottle());
            }
          }

          function getSnippets(params) {
            var defer = $q.defer();
            $ctrl.snippetRequestConfig.isLoading = true;
            OrganizationsService
              .getTextTemplates(params)
              .then(function (res) {
                $ctrl.snippetRequestConfig.isLoading = false;
                defer.resolve(res);
              }, function (err) {
                $ctrl.snippetRequestConfig.isLoading = false;
                defer.reject(err);
              });
            return defer.promise;
          }

          function getBlueprints(params) {
            var defer = $q.defer();
            $ctrl.blueprintRequestConfig.isLoading = true;
            ProcessService
              .getProcess(params)
              .then(function (res) {
                res.data = _.filter(res.data, function (data) {
                  return data.id !== $ctrl.froalaOptions.process.id
                });
                $ctrl.blueprintRequestConfig.isLoading = false;
                defer.resolve(res);
              }, function (err) {
                $ctrl.blueprintRequestConfig.isLoading = false;
                defer.reject(err);
              });
            return defer.promise;
          }

          function onSnippetMenuContainerThrottle() {
            return _throttle(function () {
              if (!$ctrl.snippetRequestConfig.isLoaded) {
                if (
                  (snippetMenuContainer[0].scrollHeight - snippetMenuContainer.scrollTop() < snippetMenuContainer.height() + 120)
                  && !$ctrl.snippetRequestConfig.isLoading
                ) {
                  getSnippets($ctrl.snippetRequestConfig.pagination)
                    .then(function (res) {
                      $ctrl.snippetList = _.uniqBy(_.concat($ctrl.snippetList, res.data), 'id');
                      if (res.meta.pagination.current_page === res.meta.pagination.total_pages) {
                        $ctrl.snippetRequestConfig.isLoaded = true;
                        snippetMenuContainer.off('scroll', onSnippetMenuContainerThrottle());
                      } else {
                        $ctrl.snippetRequestConfig.pagination.page++;
                      }
                    });
                }
              }
            }, 10);
          }

          function onBlueprintMenuContainerThrottle() {
            return _throttle(function () {
              if (!$ctrl.blueprintRequestConfig.isLoaded) {
                if (
                  (blueprintMenuContainer[0].scrollHeight - blueprintMenuContainer.scrollTop() < blueprintMenuContainer.height() + 120)
                  && !$ctrl.blueprintRequestConfig.isLoading
                ) {
                  getBlueprints($ctrl.blueprintRequestConfig.pagination)
                    .then(function (res) {
                      $ctrl.blueprintList = _.uniqBy(_.concat($ctrl.blueprintList, res.data), 'id');
                      if (res.meta.pagination.current_page === res.meta.pagination.total_pages) {
                        $ctrl.blueprintRequestConfig.isLoaded = true;
                        blueprintMenuContainer.off('scroll', onBlueprintMenuContainerThrottle());
                      } else {
                        $ctrl.blueprintRequestConfig.pagination.page++;
                      }
                    });
                }
              }
            }, 10);
          }

          function _throttle(fn, wait) {
            var time = Date.now();
            return function () {
              if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
              }
            };
          }

          function dropdownOpen(e) {
            e.preventDefault();
            $ctrl.isOpen = !$ctrl.isOpen;
            if (!$ctrl.isOpen) {
              $('#editor-blueprint').collapse('hide');
              $ctrl.isBlueprintOpen = false;
              $('#editor-snippet').collapse('hide');
              $ctrl.isSnippetOpen = false;
            }
          }

          function dropdownSnippetOpen(e) {
            e.preventDefault();
            $ctrl.isSnippetOpen = !$ctrl.isSnippetOpen;
            if ($ctrl.isSnippetOpen) {
              $('#editor-blueprint').collapse('hide');
              $ctrl.isBlueprintOpen = false;
            }
          }

          function dropdownBlueprintOpen(e) {
            e.preventDefault();
            $ctrl.isBlueprintOpen = !$ctrl.isBlueprintOpen;
            if ($ctrl.isBlueprintOpen) {
              $('#editor-snippet').collapse('hide');
              $ctrl.isSnippetOpen = false;
            }
          }

          function snippetItemSelected(e, snippet) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            $ctrl.onSnippetSelected({ snippet: snippet });
          }

          function blueprintItemSelected(e, blueprint) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            $ctrl.onBlueprintSelected({ blueprint: blueprint });
          }

          function blueprintSearchQueryParams() {
            $ctrl.blueprintList = [];
            $ctrl.blueprintRequestConfig.pagination.page = 1;
            $ctrl.blueprintRequestConfig.pagination.q = $ctrl.blueprintQuerySearch;
            getBlueprints($ctrl.blueprintRequestConfig.pagination)
              .then(function (res) {
                $ctrl.blueprintList = _.uniqBy(_.concat($ctrl.blueprintList, res.data), 'id');
                if (res.meta.pagination.current_page === res.meta.pagination.total_pages) {
                  $ctrl.blueprintRequestConfig.isLoaded = true;
                  blueprintMenuContainer.off('scroll', onBlueprintMenuContainerThrottle());
                } else {
                  $ctrl.blueprintRequestConfig.pagination.page++;
                }
              });
          }
        }
    })
})();
(function () {
  'use strict';

  angular.module('tallyfy')
    .service('koStepService', koStepService);

  /*@ngInject */
  function koStepService(_, ProcessService, $q, $uibModal) {
    var self = this;

    /**ko step save method */
    self.saveKOStep = function (processToSave, forceSave) {
      var deferred = $q.defer();
      var process = angular.copy(processToSave);
      if (forceSave) {
        process.prerun = _.map(process.prerun, function (prerun) {
          prerun = _.omit(prerun, ['id', 'slug', 'isNew', 'isExpanded', 'isFFSave']);
          return prerun;
        });
      } else {
        process.prerun = _.filter(process.prerun, function (prerun) {
          return !prerun.isNew;
        });
      }
      ProcessService.updateKOStep({
        id: processToSave.id
      }, process).then(function (response) {
        deferred.resolve(response.data);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    /** check dirty ko form step */
    self.isKOFormDirty = function (processToDiscard, oldValue) {
      var isDescriptionEdited = processToDiscard.kickoff_description !== oldValue.kickoff_description;
      var isEqual = true;
      var checkedProps = ['label', 'required', 'guidance', 'name', 'position', 'field_value', 'field_type', 'alias'];
      for (var i = 0; i < processToDiscard.prerun.length; i++) {
        var oldPrerunValue = _.find(oldValue.prerun, {
          id: processToDiscard.prerun[i]['id']
        });
        for (var j = 0; j < checkedProps.length; j++) {
          if (_.get(processToDiscard.prerun, '[' + i + '][' + checkedProps[j] + ']') !== _.get(oldPrerunValue, '[' + checkedProps[j] + ']')) {
            isEqual = false;
            break;
          }
        }
        if (!isEqual) {
          break;
        }
      }
      return isDescriptionEdited || !isEqual || (processToDiscard.prerun.length != oldValue.prerun.length);
    };

    /** discard method ko form step */
    self.discardKOFormStep = function (processToDiscard, oldValue) {
      processToDiscard.kickoff_description = oldValue.kickoff_description;
      // discard all values
      for (var i = 0; i < oldValue.prerun.length; i++) {
        angular.extend(processToDiscard.prerun[i], oldValue.prerun[i]);
        processToDiscard.prerun[i] = _.omit(processToDiscard.prerun[i], ['isExpanded', 'isFFSave']);
      }
      // remove unsaved KO fields
      processToDiscard.prerun = _.filter(processToDiscard.prerun, function (value) {
        return !value.isNew;
      });
    };

    self.koStepPublicModalPopup = function (process) {
      $uibModal.open({
        backdrop: 'static',
        component: 'shareKOModal',
        windowClass: 'steps-modal',
        resolve: {
          process: function () {
            return process;
          }
        }
      });
    };
  }
})();
(function () {
  'use strict';

  angular
    .module('tallyfy.core', [
      /**
       * @module LocalStrorage
       *
       * @description
       * This module contails all feature of local storage which are easy to use and short in syntax
       */
      'ngStorage',
      /**
       * @module dynamicLocale
       *
       * @description
       * Module to be able to change the locale at an angularjs application
       */
      'tmh.dynamicLocale',
      /**
       * @module ngResource
       *
       * @description
       * The ngResource module provides interaction support with RESTful services via the $resource service.
       */
      'ngResource',
      /**
       * @module ngMessages
       *
       * @description
       * The ngMessages module provides enhanced support for displaying messages
       */
      'ngMessages',
      /**
       * @module ui.router
       *
       * @description
       * AngularUI Router is a routing framework, which implements flexible routing with nested views in AngularJS.
       */
      'ui.router',
      /**
       * @module ui.router.components
       *
       * @description
       * polyfil to support routes in angular new componenets
       */
      'ui.router.components',
      /**
       * @module ngCookies
       *
       * @description
       * JavaScript plain cookies
       */
      'ngCookies',
      /**
       * @module angular-translate
       *
       * @description
       * angular-translate is an AngularJS module that makes your life much easier when
       * it comes to i18n and l10n including lazy loading and pluralization.
       */
      'pascalprecht.translate',
      /**
       * @module ngAria
       *
       * @description
       * The ngAria module provides support for common ARIA attributes that convey state or
       * semantic information about the application for users of assistive technologies, such as screen readers.
       */
      'ngAria',
      /**
       * @module ui.bootstrap
       *
       * @description
       * Twitter bootstrap ui componenets
       */
      'ui.bootstrap',
      /**
       * @module ngFileUpload
       *
       * @description
       * upload files
       */
      'ngFileUpload',
      /**
       * @module ngCacheBuster
       *
       * @description
       * For http request caching
       */
      'ngCacheBuster',
      /**
       * @module ngSanitize
       *
       * @description
       * The ngSanitize module provides functionality to sanitize HTML.
       */
      'ngSanitize',
      /**
       * @module infinite-scroll
       * @description
       * page/content Infinite Scroll
       */
      'infinite-scroll',
      /**
       * @module angulartics
       * @description
       * A module for angulartics
       */
      'angulartics',
      /**
       * @module angulartics.mixpanel
       * @description
       * Mixpanel plugin for Angulartics.
       */
      'angulartics.mixpanel',
      /**
       * @module angulartics.facebook.pixel
       * @description
       * facebook pixel plugin for Angulartics.
       */
      'angulartics.facebook.pixel',
      /**
       * @module angulartics.quantify
       * @description
       * Tallyfy quantify plugin for Angulartics.
       */
      'angulartics.quantify',
      /**
       * @module angulartics.google.analytics
       * @description
       * Google Analytics plugin for Angulartics
       */
      'angulartics.google.analytics',
      'angular-growl',
      /**
       * @module blockUI
       * @description
       * block ui while processing any request
       */
      'blockUI',
      'confirmModal',
      //'angularUtils.directives.uiBreadcrumbs',
      'angularUtils.directives.dirPagination',
      'ui.select',
      'tallyfy.envConfig',
      'credit-cards',
      /**
       * @module angular-moment
       * @description
       * moment directives for date formatting
       */
      'angularMoment',
      'ngAnimate',
      'vAccordion',
      'xeditable',
      /**
     * @module angular-img-cropper
     * @description
     * An image cropping tool for AngularJS.
     */
      'angular-img-cropper',
      /**
   * @module uiCropper
   * @description
   * An image cropping tool for AngularJS.
   */
      'uiCropper',
      /**
     * @module ngTextTruncate
     * @description
     * Truncate the text after the specified limit.
     */
      'ngTextTruncate',

      /**
      * @module ngAside
      * @description
      * angular aside.
      */
      'ngAside',
      'bootstrapLightbox',
      'ngProgress',
      /**
      * @module detectAdblock
      * @description
      * detect Adblock.
      */
      'detectAdblock',
      /**
       * kendo ui directive
       */
      'kendo.directives',
      /**
       * Angular Slider
       */
      'rzSlider'
    ]).run(function ($rootScope, adblock) {
      $rootScope.$on('$stateChangeSuccess', function (event, toState) {
        if (toState.name != 'authHandler') {
          adblock.detect();
        }
      }
      );
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.settings.config.ManageStateConfig
 * @module tallyfy.organizations.users
 *
 * @description
 * Set route update user profile
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .config(UserProfileConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  UserProfileConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * ManageStateConfig construct 
   */
  function UserProfileConfig($stateProvider) {
    
    $stateProvider.state('userProfile', {
      parent: 'app',
      url: '/:org_id/userProfile/:id',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'userProfile'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    });
  }
})();
/**
 * @ngdoc Service
 * @name ConfirmOnExitService
 * @function ConfirmOnExitService
 * @description It will take form name and  ask user to save or not.
 * @module tallyfy.organizations
 * @author Sireesha Purushotham( gmail::sireeshapurushotham@gmail.com )
 */

'use strict';
angular.module('tallyfy.organizations')
       .factory('ConfirmOnExitService', ConfirmOnExitService);
/*@ngInject*/
function ConfirmOnExitService($confirm, $q) {
    return {
        checkStatus: function (formname) {
            var deferred = $q.defer();
            $confirm({
                'body': 'global.unsaved.body',
                'header': 'global.unsaved.header',
                'buttons': {
                    'accept': 'global.unsaved.buttons.accept',
                    'cancel': 'global.unsaved.buttons.cancel'
                },
                modalType: 'modal-success'
            }).then(function () {
                deferred.resolve({ 'status': 'YES' });
            }, function () {
                deferred.resolve({ 'status': 'NO' });
            });
            return deferred.promise;
        }
    };
}

(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.support.factory.SupportRepository
   * @module tallyfy.support
   *
   * @description
   * Create Rest resource for support
   *
   * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
   * 
   * */
  angular
    .module('tallyfy.support')
    .factory('RecurlyRepository', RecurlyRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  RecurlyRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * SupportRepository construct 
   */
  function RecurlyRepository(BaseRepository) {
    var repository = BaseRepository('/organizations/:org/:action/:action_id/:flag', {}, {
      billCreate: {
        method: 'POST',
        headers: { 'Accept': 'application/json' }
      },
      subscriptions: {
        method: 'PUT',
        headers: { 'Accept': 'application/json' }
      }
    });
    
    return repository;
  }
})(); 

(function () {
  'use strict';
  angular.module('tallyfy')
    .service('SupportPublicBlueprintTableService', SupportPublicBlueprintTableService);

  function SupportPublicBlueprintTableService($filter) {
    var self = this;
    self.getSupportPublicBlueprintTableOptions = function () {
      return {
        columns: [{
          field: "organization_name",
          exportKeyMap: {
            key: "organization_name",
            label: $filter('translate')('orgProfile.label.organizationName')
          },
          template: kendo.template($('#supportPublicBlueprintTableOrganizationNameColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableOrganizationNameHeaderColumn').html()),
          width: 290
        }, {
          field: "title",
          exportKeyMap: {
            key: "title",
            label: $filter('translate')('orgProfile.label.blueprintName')
          },
          template: kendo.template($('#supportPublicBlueprintTableBlueprintColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableBlueprintNameHeaderColumn').html())
        }, {
          field: "is_public",
          exportKeyMap: {
            key: "is_public",
            label: $filter('translate')('orgProfile.label.isPublic')
          },
          template: kendo.template($('#supportPublicBlueprintTableIsPublicColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableIsPublicHeaderColumn').html()),
          width: 150
        }, {
          field: "is_featured",
          exportKeyMap: {
            key: "is_featured",
            label: $filter('translate')('orgProfile.label.isFeatured')
          },
          template: kendo.template($('#supportPublicBlueprintTableIsFeaturedColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableIsFeaturedHeaderColumn').html()),
          width: 150
        }, {
          field: "tracks",
          exportKeyMap: {
            key: "tracks.data.number_of_imports",
            label: $filter('translate')('orgProfile.label.tracks')
          },
          template: kendo.template($('#supportPublicBlueprintTableTracksColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableTracksHeaderColumn').html()),
          width: 150
        }, {
          field: "tracks_activities",
          template: kendo.template($('#supportPublicBlueprintTableTracksActivitiesColumn').html()),
          headerTemplate: kendo.template($('#supportPublicBlueprintTableTracksActivitiesHeaderColumn').html()),
          width: 150
        }],
        dataSource: new kendo.data.DataSource(),
        noRecords: {
          template: kendo.template($('#supportPublicBlueprintTableNoData').html())
        },
        resizable: true
      };
    };
    return self;
  }
})();
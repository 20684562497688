/**
 * @ngdoc Directive
 * @name tallyfy.isolateForm
 * @restrict 'A'
 * @element ANY 
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('isolateForm', ['_', function (_) {
      return {
        restrict: 'A',
        require: '?form',
        link: function (scope, elm, attrs, ctrl) {
          if (!ctrl) {
            return;
          }

          // Do a copy of the controller
          var ctrlCopy = ctrl ? _.cloneDeep(ctrl) : {};

          // Get the parent of the form
          var parent = elm.parent().controller('form') || {};
          // Remove parent link to the controller

          if (typeof parent.$removeControl === 'function') {
            parent.$removeControl(ctrl);

            // Replace form controller with a "isolated form"
            var isolatedFormCtrl = {
              $setValidity: function (validationToken, isValid, control) {
                if (ctrlCopy.$setValidity) {
                  ctrlCopy.$setValidity(validationToken, isValid, control);
                }
                if (parent.$setValidity) {
                  parent.$setValidity(validationToken, true, ctrl);
                }
              },
              $setDirty: function () {
                elm.removeClass('ng-pristine').addClass('ng-dirty');
                ctrl.$dirty = true;
                ctrl.$pristine = false;
              },
            };
            angular.extend(ctrl, isolatedFormCtrl);
          }
        }
      };
    }]);

})();
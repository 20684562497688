/**
 * @ngdoc Component
 * @name tallyfy.steps.component.kickFormInValid
 * @module tallyfy.steps
 * 
 * @description
 * A component to display validation messages in the modal.
 * 
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('kickFormInValid', {
      templateUrl: 'app/modules/steps/kickFormInValidModal/kickFormInValid.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
      /*@ngInject*/
        function(StepService) {
          var $ctrl = this;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          
          /**
           * public properties
           */
          $ctrl.content = {};

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          
          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.content = StepService.getModalContent($ctrl.resolve.subjectType);
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
           */
          function cancel() {
            $ctrl.dismiss();
          }
        }
    });
})();

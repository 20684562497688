(function () {
  'use strict';
  angular.module('tallyfy')
    .component('favoriteBlueprint', {
      templateUrl: 'app/modules/process/components/library/favoriteBlueprint/favorite-blueprint.component.html',
      bindings: {
        favoriteData: '=?',
        onRemovedFromFavorite: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $scope, $filter, $window, $timeout, $state, ProcessService, Growl) {
          var $ctrl = this,
            growl = new Growl(),
            favoriteBPContainer = angular.element('.favorite-blueprint-body'),
            allDataLoaded = false,
            window = angular.element($window),
            dataWatcher,
            params = {
              page: 1,
              per_page: 12,
              append: 'steps_count',
              with: 'tags,folder,permissions',
              starred: 1
            }, sizeSyncTimeoutHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.editBp = editBp;
          $ctrl.onBlueprintRemovedFromFavorite = onBlueprintRemovedFromFavorite;

          function onInit() {
            if (!($ctrl.favoriteData || []).length) {
              getFavoriteBlueprints();
            }
            favoriteBPContainer.on('scroll', getFavoriteBlueprints);
            window.on('resize', syncWidthHandler);
          }

          function onChanges() { }

          function onDestroy() {
            dataWatcher();
            favoriteBPContainer.off('scroll', getFavoriteBlueprints);
            if (sizeSyncTimeoutHandler) {
              $timeout.cancel(sizeSyncTimeoutHandler);
            }
            window.off('resize', syncWidthHandler);
          }

          function syncWidthHandler() {
            syncWidth(10);
          }

          function getFavoriteBlueprints(e) {
            var right = favoriteBPContainer[0].scrollWidth - (favoriteBPContainer[0].scrollLeft + favoriteBPContainer[0].clientWidth + 1);
            if (!allDataLoaded && !$ctrl.onLoading && right < 80) {
              $ctrl.onLoading = true;
              ProcessService.getFavoriteBlueprint(params)
                .then(function (res) {
                  $ctrl.favoriteData = _.concat($ctrl.favoriteData || [], res.data);
                  res.meta.pagination.current_page < res.meta.pagination.total_pages ? params.page++ : allDataLoaded = true;
                  $ctrl.onLoading = false;
                  $scope.$applyAsync(function () {
                    syncWidth(10);
                  });
                });
            } else {
              if (allDataLoaded) {
                favoriteBPContainer.off('scroll', getFavoriteBlueprints);
              }
            }
          }

          function syncWidth(timeout) {
            if (sizeSyncTimeoutHandler) {
              $timeout.cancel(sizeSyncTimeoutHandler);
            }
            sizeSyncTimeoutHandler = $timeout(function () {
              var itemPerRow = $window.innerWidth < 1199.98 ? ($window.innerWidth > 677 ? 4 : 3) : 6;
              var elWidth = (favoriteBPContainer.width() / itemPerRow) - ($window.innerWidth < 1199.98 ? 2 : 0);
              var blueprints = angular.element('.blueprint-item');
              _.forEach(blueprints, function (blueprint) {
                angular.element(blueprint).css('min-width', Math.ceil(elWidth) - ($window.innerWidth < 1199.98 ? 12 : 8));
                angular.element(blueprint).css('max-width', Math.ceil(elWidth) - ($window.innerWidth < 1199.98 ? 12 : 8));
              });
            }, timeout || 2000);
          }

          function onBlueprintRemovedFromFavorite(e, blueprint) {
            e.stopImmediatePropagation();
            Growl.clearAllMessages('global');
            blueprint.starred = !blueprint.starred;
            $timeout(function () {
              growl.success(
                $filter('translate')('runs.messages.unFavorited'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, 250);

            var resource = blueprint.starred ? ProcessService.setFavorite(_.toString(_.get(blueprint, 'id')))
              : ProcessService.removeFavorite(_.toString(_.get(blueprint, 'id')));
            resource.then(function () {
              var removedIndex = _.findIndex($ctrl.favoriteData, { id: blueprint.id });
              $ctrl.favoriteData.splice(removedIndex, 1);
              $ctrl.onRemovedFromFavorite({ blueprint: blueprint });
            });
          }

          function editBp(bp, mode) {
            $state.go('process.edit', {
              slug: bp.id,
              view: mode
            });
          }

          dataWatcher = $scope.$watchCollection('$ctrl.favoriteData', function (newValue, oldValue) {
            $scope.$applyAsync(function () {
              syncWidth(!oldValue.length ? 2000 : 10);
            });
          });
        }
    })
})();
/**
 * @ngdoc Directive
 * @name tallyfy.scrollspyMenu
 * @description Get scroll to clicked menu and set active menu as per scrolling scrollbar
 * @restrict 'A'
 * @element ANY
 * @author Kiranv Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollspyMenu', scrollspyMenu);
    /*@ngInject*/
    function scrollspyMenu($state) {
      return {
        restrict : "AC",
         scope: {
            setScrollToMenuIntegration: '&'
        },
        link: function(scope){
          var isScrollLocked = false;
          var mobileElement = document.querySelector('.integration-details.w-100.settings-page-section'),
              desktopElement = document.querySelector('.setting-page-main-view.right');
          scope.scrollToMenu = function(integration, index) {
            isScrollLocked = true;
            $('.setting-page-main-view.right').animate({
              scrollTop: $(integration.id).position().top
            }, 500, function () {
              isScrollLocked = false;
            });
            $('.navigation li').removeClass('active');
            document.getElementById(integration.id + index).classList.add("active");
          };
          scope.setScrollToMenuIntegration({ scrollToMenu: scope.scrollToMenu });
          var mobileScrollFn = function() {
            if ($state.current.name === 'settings.integration' && !isScrollLocked) {
              $('.integration-section').each(function(i, value) {
                var scrollDistance = angular.element(mobileElement).scrollTop(),
                    elementDistance = $(value).position().top + 110;
                  if (elementDistance <= scrollDistance) {
                    $('.navigation li').removeClass('active');
                    $('.navigation li').eq(i).addClass('active');
                  }
                  var highlightedTab = document.querySelector('.ng-scope.active');
                  highlightedTab.scrollIntoView();
              });
            }
          };
          var desktopScrollFn = function() {
            if ($state.current.name === 'settings.integration' && !isScrollLocked) {
              $('.integration-section').each(function(i, value) {
                var scrollDistance = angular.element(desktopElement).scrollTop(),
                    elementDistance = $(value).position().top;
                  if (elementDistance <= scrollDistance) {
                    $('.navigation li').removeClass('active');
                    $('.navigation li').eq(i).addClass('active');
                  }
              });
            }
          }
          angular.element(window).on('resize', onResize);
          onResize();

          //Resize function
          function onResize () {
            if (window.innerWidth < 1200) {
              angular.element(desktopElement).unbind('scroll', desktopScrollFn);
              angular.element(mobileElement).bind('scroll', mobileScrollFn);
            } else {
              angular.element(mobileElement).unbind('scroll', mobileScrollFn);
              angular.element(desktopElement).bind('scroll', desktopScrollFn);
            }
            scope.$apply();
          }

          //Destroy resize handler
          scope.$on('$destroy', function () {
            angular.element(window).off('resize', onResize);
            angular.element(mobileElement).unbind('scroll', mobileScrollFn);
            angular.element(desktopElement).unbind('scroll', desktopScrollFn);
          });
        }
      };
    }
})();


/**
 * @ngdoc Provider
 * @name menuActionLinksService
 *
 * @module tallyfy
 *
 * @description
 * this is an independent module for confirm modal box
 *
 * @author Austin Willis ( gmail::austinwillis@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('menuActionLinksService', menuActionLinksService);
    /*@ngInject*/
    function menuActionLinksService(_, $state, TasksService, CONFIG, AuthPlan, PLANS, $filter, $rootScope, UsersService, Helper, ProcessService, store, $log, QuickActionsService, $uibModal) {
      var $this = this,
      organizationId = _.get($rootScope.identity, 'default_organization.id');
      /**
       * public variable's
       */
      $this.state = $state;
      $this.currentUser = $rootScope.identity;

      /**
       * public method's
       */
      $this.standaloneModal = standaloneModal;
      $this.goToNewProcess = goToNewProcess;
      $this.getDropdownOptions = getDropdownOptions;
      $this.openInviteUserModal = openInviteUserModal;
      $this.watchIntroModal = watchIntroModal;

      /**
       * @function
       * @name getDropdownOptions
       * @description It will returns dropdown default values
       * @return {Array}
       */
      function getDropdownOptions() {
        var orgId = _.get($rootScope.identity, 'default_organization.id');
        return [{
          taskTitle: "global.newDropdown.taskTitle",
          tasks: [{
            id: 0,
            menu: 'global.newDropdown.newTask',
            iconClass: 'far fa-check-square',
            action: standaloneModal,
            shortcutModifierOne: 'CTRL',
            shortcutModifierTwo: Helper.isMacOS() ? 'OPT' : 'ALT',
            shortcutKey: 'T',
            isVisible: true
          }, {
            id: 1,
            menu: 'dashboard.quickActionLinks.share_update.title',
            iconClass: 'far fa-bullhorn',
            action: shareUpdateModal,
            isVisible: true
          }, {
            id: 2,
            menu: 'dashboard.quickActionLinks.request_approval.title',
            iconClass: 'far fa-map-signs',
            action: requestApprovalModal,
            isVisible: true
          }],
          processTitle: "global.newDropdown.processTitle",
          process: [{
            id: 0,
            menu: 'global.newDropdown.menuProcess',
            iconClass: 'far fa-rocket',
            action: goToNewProcess,
            shortcutModifierOne: 'CTRL',
            shortcutModifierTwo: Helper.isMacOS() ? 'OPT' : 'ALT',
            shortcutKey: 'P',
            isVisible: !AuthPlan.isRestrictedWithDocsPlan()
          }],
          blueprintTitle: "global.newDropdown.blueprintTitle",
          useTemplate: "global.newDropdown.useTemplate",
          blueprintTypes: ['document', 'procedure'],
          createBlueprint: "global.newDropdown.menuNewBlueprint",
          blueprints: [{
            id: 0,
            menu: 'global.newDropdown.menuBlueprintDocument',
            subMenu: 'global.newDropdown.showExamples',
            iconClass: 'fas fa-file-invoice',
            type: 'document',
            isVisible: true,
            documentShowExamples: [{
                id: 0,
                name: 'global.newDropdown.invoice',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/13d856a19516300d99f0cbf51ae15b28',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&blueprint_id=13d856a19516300d99f0cbf51ae15b28&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 1,
                name: 'global.newDropdown.salesOrder',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/8a33daa45cb1e84fd1e647eb124992ef',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=8a33daa45cb1e84fd1e647eb124992ef&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 2,
                name: 'global.newDropdown.salesProposal',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/33d3e3211c0a583375e7b5d8aeb849cf',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=33d3e3211c0a583375e7b5d8aeb849cf&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }]
          }, {
            id: 1,
            menu: 'global.newDropdown.menuBlueprintForm',
            subMenu: 'global.newDropdown.showExamples',
            iconClass: 'far fa-file-alt',
            type: 'form',
            isVisible: true,
            formShowExamples: [{
                id: 0,
                name: 'global.newDropdown.vacationRequest',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/2565d1a6a00fb65ab2e147cac4a7f3e6/kick-off',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=2565d1a6a00fb65ab2e147cac4a7f3e6&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 1,
                name: 'global.newDropdown.requestQuotationForm',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/82debecc1257234e44b78942914d657b/kick-off',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=82debecc1257234e44b78942914d657b&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 2,
                name: 'global.newDropdown.fundingRequestForm',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/80677431276b557f58aff0150d60f5c8/kick-off',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=80677431276b557f58aff0150d60f5c8&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }]
          }, {
            id: 2,
            menu: 'global.newDropdown.menuBlueprintProcedure',
            subMenu: 'global.newDropdown.showExamples',
            iconClass: 'far fa-shoe-prints',
            type: 'procedure',
            isVisible: true,
            procedureShowExamples: [{
                id: 0,
                name: 'global.newDropdown.clientOnboarding',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/a125686e3b39407f94820217bd40c4fb',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=a125686e3b39407f94820217bd40c4fb&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 1,
                name: 'global.newDropdown.employeeOnboarding',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/30958479e8c34603a3315897eafc2c7c',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=30958479e8c34603a3315897eafc2c7c&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }, {
                id: 2,
                name: 'global.newDropdown.clientContentApproval',
                actionTrialOrg: 'https://go.tallyfy.com/public/library/753dd0c021b922879bb5387414627676/blueprint/2930214caff04194a4e9799c49ca75ab',
                actionPaidOrg: 'https://go.tallyfy.com/'+orgId+'/blueprints?status=example&page=1&blueprint_id=2930214caff04194a4e9799c49ca75ab&public_org=753dd0c021b922879bb5387414627676',
                isVisible: true
              }]
          }
        ]
        }];
      }

      /**
       * @function
       * @name goToNewProcess
       * @description It will redirect to `/{org_id}/blueprints`
       * @return void
       */
      function goToNewProcess() {
        $state.go('process.templates', {
          org_id: organizationId
        });
      }

      /**
       * @function
       * @name standaloneModal
       * @description Open standalon modal
       * @return {void}
       */
       function standaloneModal() {
        if (!!document.querySelector('.task-modal')) {
          return;
        }
        var params = _.get($this.state, 'params');
        if (_.get($this.state, 'current.name') === 'run.view') {
          TasksService.standaloneModal(params.id);
        } else {
          TasksService.standaloneModal();
        }
      }

      /**
       * @function
       * @name openInviteUserModal
       * @description Open invitation modal to invite new user
       * @return {void}
       */
      function openInviteUserModal() {
        if (!Helper.checkAccessAuthority())
          return;
        store.getUsers().then(function (data) {
          var activeuser = UsersService.getAvailableUsers(data);
          if (activeuser.length >= CONFIG.FREE_ACCOUNT_ACTIVE_USERS_LIMIT && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'coworkerLimit')))
            return;
          UsersService.openInviteModal();
        }, function (error) {
          $log.error(error);
        });
      }

      /**
       * @function
       * @name shareUpdateModal
       * @description Open share update modal
       * @return {void}
       */
      function shareUpdateModal() {
        var quickActionItems = _.pickBy(QuickActionsService.getAcceptableQuickActionTypes(), function(value) {
            return value.isApplicableForCurrentAccount();
          });
        _.get(quickActionItems, 'share-update').actionHandler('Homepage');
      }

      /**
       * @function
       * @name requestApprovalModal
       * @description Open request approval modal
       * @return {void}
       */
      function requestApprovalModal() {
        var quickActionItems = _.pickBy(QuickActionsService.getAcceptableQuickActionTypes(), function(value) {
            return value.isApplicableForCurrentAccount();
          });
        _.get(quickActionItems, 'request-approval').actionHandler('Homepage');
      }

      /**
       * @function
       * @name watchIntroModal
       * @description Open intro video modal
       * @return {void}
       */
       function watchIntroModal() {
        $uibModal.open({
          component: 'watchIntroModal',
          windowClass: 'watch-intro-modal'
        });
      }
    }
  })();

(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('RoleTableService', GroupTableService);

  /*@ngInject*/
  function GroupTableService($filter) {
    var self = this;

    self.getRoleTableOptions = function () {
      return {
        columns: getRoleTableColumns(),
        dataSource: new kendo.data.DataSource({
          pageSize: 10
        }),
        noRecords: {
          template: kendo.template($('#roleListTableNoData').html())
        },
        resizable: true
      }
    }

    // guest table columns
    var getRoleTableColumns = function () {
      return [{
        field: "title",
        exportKeyMap: {
          key: "title",
          label: $filter('translate')('textTemplate.table.title')
        },
        headerTemplate: kendo.template($('#roleTitleHeaderColumnTemplate').html()),
        template: kendo.template($('#roleTitleColumnTemplate').html()),
        title: $filter('translate')('textTemplate.table.title'),
      }, {
        field: "action",
        template: kendo.template($('#roleActionColumnTemplate').html()),
        title: $filter('translate')('textTemplate.table.header.actions'),
        width: 220
      }];
    }
    return self;
  }

})();
/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.taskComment
 * @module tallyfy.tasks
 *
 * @description
 * taskComment component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.messages')
    .component('taskComment', {
      require: {
        taskCtrl: '^task'
      },
      bindings: {
        'threads': "=",
        'onIssue': "&",
        'users': '<',
        'isActionShow': "=",
        'isAssignToGuest': "=",
        'guestsLength': '<'
      },
      templateUrl: 'app/modules/messages/comment/messages-comment.html',
      controller:
        /*@ngInject*/
        function (_, MessagesService, $rootScope, $scope, TasksService, $filter, Helper, PLANS, AuthPlan, $analytics, FroalaService, UsersService) {
          var $ctrl = this,
            MESSAGE_TYPE = angular.copy(MessagesService.MESSAGE_TYPE),
            taskupdate,
            unregisterTaskMessageListener;

          /**
           * public properties and methods
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.submitComment = submitComment;
          $ctrl.isSenderOrRecipient = isSenderOrRecipient;
          $ctrl.showFormActions = showFormActions;
          $ctrl.upgradeModal = upgradeModal;

          /**
           * public properties
           */
          $ctrl.isResolver = false;
          $ctrl.message = {};
          $ctrl.messageType = MESSAGE_TYPE.REPORT.toLowerCase();
          $ctrl.isIssueSender = false;
          $ctrl.isActionShow = false;
          $ctrl.unResolvedIssues = [];
          $ctrl.onSaving = false;
          $ctrl.froalaOptions = {};

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.task = angular.copy($ctrl.taskCtrl.task);
            if (!isStandaloneTask() && _.isObject($ctrl.task.run)) {
              $ctrl.run = $ctrl.task.run.data;
            }
            $ctrl.collaborators = _.get($ctrl.taskCtrl, 'run.collaborators') || [];
            $ctrl.currentUser = $rootScope.identity;
            $ctrl.showUpgrade = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              initOnClick: true,
              accessibility: {
                haveAuthority: $ctrl.haveAuthority,
                callback: Helper.checkAccessAuthority
              },
              placeholder: $filter('translate')($ctrl.task ? 'messages.placeholder' : 'messages.bpPlaceholder'),
              currentUser: $ctrl.currentUser,
              mentionData: prepareMentionData(),
              limit: 30
            }, true);
            $ctrl.froalaOptions.enter = FroalaEditor.ENTER_BR;
            prepareResolver();
            taskupdate = $rootScope.$on('TASK:UPDATED', function (event, data) {
              $ctrl.task = data;
            });
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            taskupdate();
            watchThreadChanges();
            unregisterTaskMessageListener();
          }

          /**
           * @ngdoc method
           * @name submitComment
           * @description
           * @param {Form} form
           */
          function submitComment(form) {
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.commentForm.$submitted = true;
            if (!$ctrl.commentForm.$valid) {
              $ctrl.commentForm.message.$setValidity('required', false);
              return;
            }

            if ($ctrl.message.type) {
              switch ($ctrl.messageType) {
                case MESSAGE_TYPE.RESOLVE.toLowerCase():
                  resolveIssue();
                  break;
                case MESSAGE_TYPE.REPORT.toLowerCase():
                  if ($ctrl.taskCtrl.task.status != 'completed') {
                    sendReport();
                  }
                  else {
                    sendComment();
                  }
                  break;
              }
            } else {
              sendComment();
            }
          }

          /**
           * @ngdoc method
           * @name sendReport
           * @description
           * Function will send the report
           * @returns void
           */
          function sendReport() {
            var newMessageThread = getIssueOrComment('problem');
            $ctrl.onSaving = true;
            MessagesService.messageSend($ctrl.taskCtrl.task.id, newMessageThread).then(function (response) {
              var thread = _.get(response, 'data');
              thread.author = getAuthorDetails(thread.author_id);
              $ctrl.taskCtrl.threads.push(thread);
              $ctrl.taskCtrl.hasAnyIssue();
              resetMessageModel();
              prepareResolver();
              $rootScope.$emit('ISSUE:REPORTED', {
                'issue': thread
              });
              $ctrl.onSaving = false;
              $ctrl.commentForm.$submitted = false;
              trackEvent('Comment added', {
                action: 'report-issue'
              });
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name resolveIssue
           * @description
           * Existing issue can be resolved
           * @returns void
           */
          function resolveIssue() {
            var issues = TasksService.getIssueWithUnresolvedProblem($ctrl.threads),
              threadId = issues.length === 0 && $ctrl.isGuest ?
                _.get($ctrl.taskCtrl, 'issue_id')
                : issues[0].id;    
            var newMessageThread = {
              "thread_id": threadId,
              "content": $ctrl.message.content
            };
            $ctrl.onSaving = true;
            MessagesService.resolve($ctrl.taskCtrl.task.id, newMessageThread).then(function (response) {
              var thread = _.get(response, 'data');
              thread.author = getAuthorDetails(thread.author_id);
              if (issues.length === 0 && $ctrl.isGuest) {
                var index = _.findIndex($ctrl.threads, { 'id': threadId });
                $ctrl.taskCtrl.threads[index].resolve_id = thread.id;
              }
              $ctrl.taskCtrl.threads.push(thread);
              $ctrl.taskCtrl.hasAnyIssue();
              resetMessageModel();
              prepareResolver();
              $rootScope.$emit('ISSUE:RESOLVED', {
                'issue': thread
              });
              $ctrl.onSaving = false;
              $ctrl.commentForm.$submitted = false;
              trackEvent('Comment added', {
                action: 'resolve-issue'
              });
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name sendComment
           * @description
           * Functoion will add the comment
           * @returns void
           */
          function sendComment() {
            var comment = getIssueOrComment('comment');
            $ctrl.onSaving = true;
            MessagesService.commentSend($ctrl.taskCtrl.task.id, comment).then(function (response) {
              var thread = _.get(response, 'data');
              thread.author = getAuthorDetails(thread.author_id);
              $ctrl.taskCtrl.threads.push(thread);
              resetMessageModel();
              $ctrl.commentForm.$submitted = false;
              $ctrl.onSaving = false;
              trackEvent('Comment added', {
                action: 'none'
              });
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name getAuthorDetails
           * @description get author details
           * @param {Integer} author_id
           */
          function getAuthorDetails(author_id) {
            return _.find($ctrl.users, function (user) {
              return user.id === author_id;
            });
          }

          /**
           * @ngdoc method
           * @name isSenderOrRecipient
           * @param {object} sentBy
           * @param {object} sentTo
           * @return {boolean}
           */
          function isSenderOrRecipient(sentBy, sentTo) {
            if (sentBy.id == $ctrl.currentUser.id) {
              return true;
            }
            _.some(sentTo, function (elem) {
              return elem.id == $ctrl.currentUser.id;
            });
            return false;
          }

          /**
           * @ngdoc method
           * @name prepareResolver
           * @description
           * Set global model based on the issues
           * @returns void
           */
          function prepareResolver() {
            $ctrl.unResolvedIssues = TasksService.getIssueWithUnresolvedProblem($ctrl.threads);
            $ctrl.messageType = MESSAGE_TYPE.REPORT.toLowerCase();
            $ctrl.isResolver = $ctrl.unResolvedIssues.length > 0;

            if ($ctrl.isResolver) {
              $ctrl.messageType = MESSAGE_TYPE.RESOLVE.toLowerCase();
              // TODO: It can be come back in future
              // $ctrl.isIssueSender = MessagesService.isSender($ctrl.unResolvedIssues[0].sent_by, $ctrl.currentUser.id);
            }
          }

          /**
           * @ngdoc method
           * @name resetMessageModel
           * @description
           * Empty message model
           * @returns void
           */
          function resetMessageModel() {
            $ctrl.message = {};
          }

          /**
           * @ngdoc method
           * @name isStandaloneTask
           * @description Check this task is standalone or not
           * @returns boolean
           */
          function isStandaloneTask() {
            return $ctrl.taskCtrl.isStandAloneTask;
          }

          /**
           * @ngdoc method
           * @name getIssueOrComment
           * @description Format problem/resolve object
           * @param {string} label
           * @return {Object}
           */
          function getIssueOrComment(label) {
            return {
              "label": label,
              "content": FroalaService.getRawContent($ctrl.message.content)
            };
          }

          /**
           * @function
           * @name showFormActions
           * @description Update the isActionShow
           */
          function showFormActions() {
            if (!Helper.checkAccessAuthority())
              return;
          }

          $scope.$watch('$ctrl.froalaOptions.editorInitialized', function (value) {
            if (value) {
              $scope.$emit("TASK:MESSAGES_THREAD");
            }
          });

          /**
           * @ngdoc method
           * @name upgradeModal
           * @description Show upgrade modal
           * @returns {void}
           */
          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.FREE, 'reporting');
          }
          
          /**
           * @function
           * @name prepareMentionData
           * @description Prepare data for suggestion list when user typed "@" in task comment box
           * @returns {Array} mentionData
           */
          function prepareMentionData() {
            var mentionData = MessagesService.getMentionData($ctrl.threads, $ctrl.currentUser, UsersService.usersWithoutBot($ctrl.users), $ctrl.task, $ctrl.collaborators);
            return mentionData;
          }

          /**
           * @name trackEvent
           * @param {*} eventName
           * @param  {*} properties
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = !$ctrl.taskCtrl.run_id ? 'standalone' : 'process';
            properties.processId = $ctrl.taskCtrl.run_id;
            properties.taskId = $ctrl.task.id;
            $analytics.eventTrack(eventName, properties);
          }

          var watchThreadChanges = $scope.$watch('$ctrl.threads', function () {
            prepareResolver();
          }, true);

          /**
           * Un-register listener on task message
           * @type {*|(function())}
           */
          unregisterTaskMessageListener = $rootScope.$on('TASK:EDIT_MESSAGE', function () {
            if ($ctrl.froalaOptions.editorInitialized) {
              $ctrl.froalaOptions.reInitializeEditor();
            }
            $ctrl.isActionShow = false;
          });
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.organizations.config.routeConfig
 * @module tallyfy.organizations
 *
 * @description
 * Set route for update organization plan
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider) {
    //Routes would be here
    $stateProvider.state('updateOrgPlan', {
      parent: 'app',
      url: '/:org_id/plan',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'updateOrgPlan'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('organizations');
          $translatePartialLoader.addPart('payment');
          return $translate.refresh();
        }]
      }
    });
  }
})();
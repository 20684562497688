/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.smtp
 * @module tallyfy.integration
 *
 * @description
 * A component to manage smtp integration
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .component('smtp', {
      bindings: {
        type: '<',
        index: "<"
      },
      templateUrl: 'app/modules/integration/smtp/smtp.html',
      controller: [
        '_',
        'IntegrationService',
        'Growl',
        'blockUI',
        '$filter',
        function (_, IntegrationService, Growl, blockUI, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('smtpIntegration'),
            growl = new Growl(),
            group = 'smtp';

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;

          /**
           * public properties
           */
          $ctrl.smtp = {};
          $ctrl.isAccountAdded = false;
          $ctrl.isDisConnected = false;

          /**
           * public methods 
           */
          $ctrl.save = save;

          function initialization() {
            $ctrl.smtp = IntegrationService.getSMTPStub();
            getSmtp();
          }

          function getSmtp() {
            blockUI.start();
            IntegrationService.get({
              action: group
            }).then(function (response) {
              response.data ? _.extend($ctrl.smtp, response.data) : angular.noop();
              prepareStatus();
              blockUI.stop();
            }, function (error) {
              blockUI.stop();
            });
          }

          function save() {
            blockUI.start();
            var payload = angular.copy($ctrl.smtp);
            IntegrationService.create({ action: group }, payload).then(function (response) {
              growl.success($filter('translate')('integration.smtp.messages.success'), { referenceId: 'smtpController', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            },
              function (error) {
                growl.error(error.data.message, { referenceId: 'smtpController', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              });
          }

          function prepareStatus() {
            $ctrl.isAccountAdded = ($ctrl.smtp.smtp_username !== "" && $ctrl.smtp.smtp_password &&
              $ctrl.smtp.smtp_email_address !== "" && $ctrl.smtp.smtp_email_name !== '' &&
              $ctrl.smtp.smtp_encryption !== "" && $ctrl.smtp.smtp_host !== '' &&
              $ctrl.smtp.smtp_mail_driver !== "");
            $ctrl.isDisConnected = $ctrl.smtp.status == 'disconnected';
          }
        }]
    });
})();
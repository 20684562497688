/**
 * @ngdoc overview
 * @name tallyfy.utils
 *
 * @module tallyfy.utils
 *
 * @description
 * responsible to manage global responsibilities
 * commonly used API end points
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.utils', []);
})();

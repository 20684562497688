(function () {
  'use strict';
  angular.module('tallyfy.list')
    .component('addNewListModal', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/components/list/add-list-modal/add-list-modal.component.html',
      controller: addNewListModal
    });

  /*@ngInject*/
  function addNewListModal(_, $scope, Growl, blockUI, LIST_TYPE, listService) {
    var $ctrl = this, growl = new Growl();

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onDestroy = onDestroy;

    $ctrl.createList = createList;
    $ctrl.cancelCreateList = cancelCreateList;
    $ctrl.addColumn = addColumn;

    function onInit() {
      var list = $ctrl.resolve.list;
      $scope.$applyAsync(function () {
        $ctrl.formList = {
          title: { value: list ? list.title : null },
          type: { value: list ? list.type : LIST_TYPE.PRIVATE }
        }
      }, 0);
    }

    function onChanges() { }

    function onDestroy() { }

    function createList() {
      if ($ctrl.resolve.action === 'add') {
        var newList = {
          title: $ctrl.formList.title.value,
          type: 'private',
          source: 'tallyfy'
        };
        blockUI.start();
        listService.createList(newList)
          .then(function (res) {
            listService.updateListColumns(res.data.id, angular.extend(res.data, { columns: ['title'] }))
              .then(function (res) {
                growl.success('List created', { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
                $ctrl.close({ $value: { list: res.data, action: $ctrl.resolve.action } });
              });
          }, function () {
            blockUI.stop();
          });
      } else {
        angular.extend($ctrl.resolve.list, { title: $ctrl.formList.title.value });
        listService.editList($ctrl.resolve.list.id, $ctrl.resolve.list)
          .then(function (res) {
            $ctrl.close({ $value: { list: $ctrl.resolve.list, action: $ctrl.resolve.action } });
          });
      }
    }

    function cancelCreateList() {
      $ctrl.dismiss();
    }

    function addColumn() {
      $ctrl.formList.columns.value.push(null);
    }

  }
})();
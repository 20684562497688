(function () {
  'use strict';
  angular.module('tallyfy')
    .component('editorFormField', {
      templateUrl: 'app/components/documentPaneEditor/editorFormField/editor-form-field.component.html',
      bindings: {
        fieldId: '<',
        editorScope: '<?',
        hideDeleteButton: '<?',
        newField: '<?',
        fieldType: '<?',
        fieldLabel: '<?',
        compiling: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, $element, Helper, DateUtils, EditorFormFieldService, DOMService, OrganizationsService) {
          var $ctrl = this, oldFieldLabelValue, timeoutHandler, fieldFocusWatcher, fieldLabelUpdateWatcher, fieldBlurWatcher, timeoutFocusHandler, timeoutBlurHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.fieldFocus = false;
          $ctrl.dateFormat = OrganizationsService.dateFormat();

          $ctrl.titleClick = titleClick;
          $ctrl.removeField = removeField;
          $ctrl.onTitleBlur = onTitleBlur;
          $ctrl.titleKeyDown = titleKeyDown;
          $ctrl.onTitleKeypress = onTitleKeypress;
          $ctrl.getDisplayDate = getDisplayDate;

          function onInit() {
            if ($ctrl.editorScope) {
              $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
              if (!$ctrl.editorScope.fieldMode) {
                if ($ctrl.newField) {
                  var field = {
                    field_type: $ctrl.fieldType,
                    field_label: $ctrl.fieldLabel
                  };
                  EditorFormFieldService.addToQueue($element[0], field, $ctrl.editorScope, $ctrl.fieldId);
                  EditorFormFieldService.addFormField($ctrl.editorScope, dragCallback);
                } else {
                  $timeout(function () {
                    $element.attr('draggable', 'true');
                    $element[0].addEventListener('dragstart', dragCallback);
                  }, 350);
                }
              } else {
                $timeout(function () {
                  $element.attr('draggable', 'true');
                  $element[0].addEventListener('dragstart', dragCallback);
                }, 350);
              }
            }

            fieldFocusWatcher = $rootScope.$on('FIELD:FOCUS-' + $ctrl.fieldId, function (e, data) {
              if (timeoutBlurHandler) {
                $timeout.cancel(timeoutBlurHandler);
              }
              if (timeoutFocusHandler) {
                $timeout.cancel(timeoutFocusHandler);
              }
              timeoutFocusHandler = $timeout(function () {
                $ctrl.fieldFocus = true;
                $timeout(function () {
                  DOMService.centerObjectToView('.editor-entity.field-active');
                }, 350);
              }, 0);
            });

            fieldBlurWatcher = $rootScope.$on('FIELD:BLUR-' + $ctrl.fieldId, function (e, data) {
              if (timeoutFocusHandler) {
                $timeout.cancel(timeoutFocusHandler);
              }
              if (timeoutBlurHandler) {
                $timeout.cancel(timeoutBlurHandler);
              }
              timeoutBlurHandler = $timeout(function () {
                $ctrl.fieldFocus = false;
              }, 350);
            });
          }

          function onChanges(changes) {
            if (changes.editorScope) {
              if (!changes.editorScope.currentValue.fieldMode) {
                $ctrl.froalaOptions = $ctrl.editorScope.froalaOptions;
                var field = _.find($ctrl.froalaOptions.process.prerun, { id: $ctrl.fieldId });
                if (field) {
                  $ctrl.field = field;
                  oldFieldLabelValue = $ctrl.field.label;
                  $element.attr('data-field-type', "'" + $ctrl.field.field_type + "'");
                  $element.attr('data-field-label', "'" + $ctrl.field.label + "'");
                } else {
                  createField();
                }
              } else {
                var field = _.find($ctrl.editorScope.process.prerun, { id: $ctrl.fieldId });
                if (field) {
                  $ctrl.field = field;
                  oldFieldLabelValue = $ctrl.field.label;
                  $element.attr('data-field-type', "'" + $ctrl.field.field_type + "'");
                  $element.attr('data-field-label', "'" + $ctrl.field.label + "'");
                } else {
                  createField();
                }
              }
            }
          }

          function onDestroy() {
            $rootScope.$emit('FIELD_EDITOR:DESTROYED', { field: $ctrl.field });
            $element[0].removeEventListener('dragstart', dragCallback);
            fieldFocusWatcher();
            fieldBlurWatcher();
            fieldLabelUpdateWatcher();
          }

          function createField() {
            var type = $element.attr('data-field-type');
            var label = $element.attr('data-field-label');
            var field = {
              field_type: type.slice(1, -1),
              field_label: label.slice(1, -1)
            }
            EditorFormFieldService.addToQueue($element[0], field, $ctrl.editorScope, $ctrl.fieldId);
            EditorFormFieldService.addFormField($ctrl.editorScope, dragCallback);
          }

          function dragCallback(e) {
            var isInEditor = $(e.target).closest('.fr-element').length;
            if (isInEditor) {
              $element.attr('id', Helper.guid());
            }
            e.dataTransfer.setData('element', 'editor-form-field');
            e.dataTransfer.setData('args', JSON.stringify({
              fieldId: $ctrl.fieldId,
              fieldType: $ctrl.field.field_type,
              fieldLabel: $ctrl.field.label,
              removeElement: isInEditor,
              sourceId: isInEditor ? $element.attr('id') : void 0
            }));
          }

          function titleClick(e) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            $rootScope.$emit('DOCUMENT_EDITOR:OPEN', { field: $ctrl.field });
            $timeout(function () {
              e.target.focus();
              $ctrl.froalaOptions.froalaEditor.undo.saveStep();
              $rootScope.$emit('FIELD_EDITOR:SELECTED', { field: $ctrl.field });
            }, 350);
          }

          function removeField(e) {
            e.preventDefault();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            var fieldElement = angular.element(e.target).closest('editor-form-field');
            var editor = $ctrl.editorScope.froalaOptions.froalaEditor;
            editor.selection.setBefore(fieldElement[0]);
            fieldElement[0].parentNode.removeChild(fieldElement[0]);
            editor.selection.restore();
            emitEventDeleteFormField();
            $ctrl.editorScope.$evalAsync($ctrl.editorScope.froalaOptions.ctrl.updateModelView);
          }

          function emitEventDeleteFormField() {
            var otherSameFieldElement = $ctrl.froalaOptions.froalaEditor.$el.find('editor-form-field');
            var isElementExists = false;
            for (var i = 0; i < otherSameFieldElement.length; i++) {
              if (_.get(otherSameFieldElement[i], 'data.fieldId') === "'" + $ctrl.fieldId + "'") {
                isElementExists = true;
                break;
              }
            }
            if (!isElementExists) {
              $rootScope.$emit('FIELD_EDITOR:REMOVED', { fieldId: $ctrl.fieldId });
            }
          }

          function onTitleBlur(e) {
            if (e) {
              e.preventDefault();
            }
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            }
            $timeout(function () {
              if (oldFieldLabelValue === $ctrl.field.label) {
                return;
              }
              oldFieldLabelValue = $ctrl.field.label;
              $ctrl.froalaOptions.froalaEditor.undo.saveStep();
              if (e) {
                $rootScope.$emit('FIELD_EDITOR:BLUR', { field: $ctrl.field });
              }
            }, 0);
          }

          function titleKeyDown(e) {
            e.stopImmediatePropagation();
            if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
              return;
            } else {
              if (e.keyCode === 8) {
                e.stopImmediatePropagation();
              } else if (e.keyCode === 13) {
                e.preventDefault();
                e.stopImmediatePropagation();
              }
              $ctrl.froalaOptions.froalaEditor.undo.saveStep();
              return true;
            }
          }

          function onTitleKeypress(e) {
            if (e.keyCode == 13) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
            return true;
          }

          function getDisplayDate(dateStr, fieldId) {
            if (Helper.isObjectEmpty(dateStr)) {
              return '';
            }
            var prerun = _.get($ctrl.editorScope, 'process.prerun');
            if (typeof dateStr === 'object') {
              return dateStr.format($ctrl.dateFormat.format);
            } else if (prerun.length > 0 && fieldId) {
              var field = _.find(prerun, { id: fieldId });
              if (field.collect_time) {
                return DateUtils.toLocal(dateStr).format($ctrl.dateFormat.format);
              } else {
                return DateUtils.toLocal(dateStr).format($ctrl.dateFormat.dateFormat.toUpperCase());
              }
            }
          }

          fieldLabelUpdateWatcher = $scope.$watch('$ctrl.field.label', function (newValue, oldValue) {
            if (newValue && _.trim(newValue) == _.trim(oldValue)) {
              return;
            }
            if (timeoutHandler) {
              $timeout.cancel(timeoutHandler);
            }
            timeoutHandler = $timeout(function () {
              if (!$ctrl.froalaOptions || _.get($ctrl.froalaOptions, 'isBusy')) {
                return;
              }
              oldFieldLabelValue = $ctrl.field.label;
              $rootScope.$emit('FIELD_EDITOR:BLUR', { field: $ctrl.field });
            }, 1000);
          });
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.support.component.recurly
 * @module tallyfy.support
 *
 * @description
 * A component to manage support view
 *
 * @author Kodeplusdev ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .controller('RecurlyController', RecurlyController);

  RecurlyController.$inject = ['_','$scope','$uibModalInstance', '$stateParams', 'SupportService', '$timeout'];

  function RecurlyController(_, $scope, $uibModalInstance, $stateParams, SupportService, $timeout)
  {
    var $ctrl = this;
    /**
     * component callback
     * invoked when component is mounted
     */
    $ctrl.$onInit = initialization;
    $ctrl.cancel = cancel;
    $ctrl.errorMessage = '';
    $ctrl.saveBillingInfo = saveBillingInfo;


    function initialization()
    {
    }

    /**
     * Save Billing Info
     */
    function saveBillingInfo() {
      var form = angular.element(document).find('form');
      recurly.token(form, function(error, token) {
        if(error) {
          var errorMessage = error.message;
          if(error.fields) {
            errorMessage = errorMessage + ' Please check these fields: '+ error.fields.join(', ');
          }
          $timeout(function() {
            $ctrl.errorMessage = errorMessage;
          }, 0);
        } else {
          SupportService.addBillingInfo($stateParams.org_id, token.id).then(function(){
            $uibModalInstance.close({'success' : true});
          })
        }
      });
    }

    /**
     * Cancel billing info modal
     */
    function cancel() {
      $uibModalInstance.close();
    }

  }
})();

(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];
  function stateConfig($stateProvider) {
    $stateProvider.state('home', {
      parent: 'app',
      url: '/:org_id/tasks?assignee&status&sortBy&activeTask&states&process&blueprint&comment&folder&tags',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        success: '',
        error: '',
        referenceId: '',
        assignee: { squash: true, value: null },
        activeTask: { squash: true, value: null },
        status: { squash: true, value: null },
        sortBy: { squash: true, value: null },
        states: { squash: true, value: null },
        folder: { squash: true, value: null },
        process: { squash: true, value: null },
        tags: { squash: true, value: null },
        blueprint: { squash: true, value: null },
        isRequireToOpenOneOffTaskModal: null,
        newOneOffTaskList: null
      },
      views: {
        'content': {
          component: 'home'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('home');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('process');
          return $translate.refresh();
        }]
      }
    });
  }
})();

/**
 * @ngdoc Config
 * @name tallyfy.account.forgotPasswordStateConfig
 * @module tallyfy
 * @description
 * set route configuration for forgot password
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .config(forgotPasswordStateConfig);
  /*@ngInject*/
  function forgotPasswordStateConfig($stateProvider) {
    $stateProvider.state('forgotPassword', {
      url: '/forgot-password',
      parent: 'account',
      data: {
        authorities: [],
        pageTitle: 'reset.request.title'
      },
      views: {
        '': {
          component: 'forgotPassword'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('reset');
          return $translate.refresh();
        }]
      }
    })
  }

})();
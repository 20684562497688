(function () {
  'use strict';
  angular
    .module('tallyfy')
    .config(MagicStateConfig);

  MagicStateConfig.$inject = ['$stateProvider'];

  function MagicStateConfig($stateProvider) {
    $stateProvider.state('magic', {
      parent: 'app',
      url: '/:org_id/magic?action&default_task_name&task_id&comment&form_fields&deadline',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        action: { squash: true, value: null },
        task_id: { squash: true, value: null },
        comment: { squash: true, value: null },
        form_fields: { squash: true, value: null },
        deadline: { squash: true, value: null },
        default_task_name: { squash: true, value: null }
      },
      views: {
        content: {
          component: 'magic'
        }
      }
    });
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.textTemplate
 * @module tallyfy.organizations
 *
 * @description
 * Template text
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations')
    .component('textTemplate', {
      templateUrl: 'app/modules/organizations/textTemplate/text-template.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $uibModal, $filter, $state, $stateParams, OrganizationsService, TextTemplateTableService, Helper) {
          var $ctrl = this;

          $ctrl.isLoading = false;
          $ctrl.templateList = [];

          $ctrl.tableOptions = {};
          $ctrl.textTemplatePagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          $ctrl.emptySelectedGuestView = { id: '', title: 'None' };
          $ctrl.emptySelectedMemberView = { id: '', title: 'None' };
          $ctrl.emptySelectedMemberHomepageView = { id: '', title: 'None' };

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getTextTemplateData = getTextTemplateData;

          $ctrl.openTextTemplateInModal = openTextTemplateInModal;

          $ctrl.viewTemplate = viewTemplate;
          $ctrl.editTemplate = editTemplate;
          $ctrl.deleteTemplate = deleteTemplate;

          function onInit() {
            $ctrl.userRole = _.get($rootScope.identity, 'role');
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
          }

          function onChanges() { }
          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getTextTemplateData(args) {
            var defer = $q.defer();
            var params = angular.extend($ctrl.textTemplatePagination, args);
            OrganizationsService.getTextTemplates(_.pick(params, ['page', 'per_page', 'q', 'sort']))
              .then(function (res) {
                angular.extend($ctrl.textTemplatePagination, res.meta.pagination);
                if (_.get($stateParams, 'snippetId')) {
                  var template = _.find(res.data, { id: $stateParams.snippetId });
                  if (template) {
                    $ctrl.openTextTemplateInModal('view', template);
                  } else {
                    OrganizationsService.getTextTemplate($stateParams.snippetId, true, $rootScope.userState)
                      .then(function (r) {
                        $ctrl.openTextTemplateInModal('view', r.data);
                      }); 
                  }
                }
                defer.resolve(res.data);
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          function openTextTemplateInModal(action, textTemplate) {
            $uibModal.open({
              backdrop: 'static',
              component: 'formTextTemplate',
              windowClass: 'text-template-modal',
              resolve: {
                hideOnBoardingInput: function () {
                  return true;
                },
                hideDiscard: function () {
                  return true;
                },
                action: function () {
                  return action;
                },
                template: function () {
                  return textTemplate;
                },
                qOnTemplateSaveCallback: function () {
                  return function (res) {
                    $ctrl.templateList = _.concat([res.template], $ctrl.templateList);
                    getTextTemplateData().then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                    });
                    var defer = $q.defer();
                    defer.resolve('saveChanges');
                    return defer.promise;
                  };
                }
              }
            }).result.then(function (args) {
              if ($stateParams.snippetId) {
                $state.transitionTo($state.current, angular.extend($stateParams, { snippetId: void 0 }), { notify: false });
              }
              if (args && (Helper.isObjectEmpty(args.template) || args.action !== 'edit')) {
                return;
              }
              var template = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: _.get(args, 'template.uid') });
              angular.extend(template, args.template);
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
            });
          }

          function viewTemplate(uid) {
            var template = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            if (template) {
              $state.transitionTo($state.current, angular.extend($stateParams, { snippetId: template.id }), { notify: false });
            }
            $ctrl.openTextTemplateInModal('view', template);
          }

          function editTemplate(uid) {
            var template = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            $ctrl.openTextTemplateInModal('edit', template);
          }

          function deleteTemplate(uid) {
            var template = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            OrganizationsService.deleteTextTemplate(template.id)
              .then(function () {
                var tableData = $ctrl.tableOptions.gridConfig.dataSource.data();
                $ctrl.tableOptions.gridConfig.dataSource.data(
                  _.filter(tableData, function (data) {
                    return data.uid !== template.uid;
                  })
                );
                $ctrl.tableOptions.gridConfig.dataSource.sync();
                $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              });
          }

          function getSnippetTitle(uid) {
            var template = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return template.title;
          }

          function getTableConfig() {
            var config = {
              beautyScrollStyle: true,
              gridConfig: TextTemplateTableService.getTextTemplateTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'title',
                  title: $filter('translate')('textTemplate.table.header.title'),
                  initState: true
                }, {
                  field: 'created_at',
                  title: $filter('translate')('textTemplate.table.header.createdAt'),
                  initState: true
                }, {
                  field: 'updated_at',
                  title: $filter('translate')('textTemplate.table.header.updatedAt'),
                  initState: true
                }]
              },
              tableFilterModel: {},
              templateScope: {
                callbacks: {
                  addGridRow: $ctrl.openTextTemplateInModal,
                  viewTemplate: $ctrl.viewTemplate,
                  editTemplate: $ctrl.editTemplate,
                  deleteTemplate: $ctrl.deleteTemplate,
                  sortTable: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    if ($ctrl.tableOptions.tableState.sort.field !== column) {
                      $ctrl.tableOptions.tableState.sort.dir = 'asc';
                      $ctrl.tableOptions.tableState.sort.field = column;
                    } else {
                      if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                        $ctrl.tableOptions.tableState.sort = {};
                      } else {
                        $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                        $ctrl.tableOptions.tableState.sort.field = column;
                      }
                    }
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getTextTemplateData(
                      { sort: !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '') + column : void 0 }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  onColumnFilter: function (column) {
                    if (column !== 'title') {
                      return;
                    }
                    $ctrl.textTemplatePagination = { per_page: 10, page: 1 };
                    $ctrl.tableOptions.tableState.sort = {};
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getTextTemplateData(
                      { q: $ctrl.tableOptions.templateScope.variables.searchParams.q }
                    ).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  getSnippetTitle: getSnippetTitle
                },
                variables: {
                  searchParams: {},
                  isAdminMember: $ctrl.isAdminMember,
                  dateFormat: OrganizationsService.getDateFormat()
                }
              }
            };
            if ($ctrl.userRole === 'admin') {
              config.addRow = {
                capitalize: true,
                label: $filter('translate')('textTemplate.label.createButton')
              };
            }
            return config;
          }
        }
    });
})();
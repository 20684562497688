/**
 * @ngdoc Config
 * @name tallyfy.organizations.config.OrganizationStateConfig
 * @module tallyfy.organizations
 *
 * @description
 * set route Organizations Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(OrganizationStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  OrganizationStateConfig.$inject = ['$stateProvider','$urlRouterProvider'];
  /**
   * Constructor
   * OrganizationStateConfig construct 
   */
  function OrganizationStateConfig($stateProvider,  $urlRouterProvider) {
    $urlRouterProvider.when('/organizations', '/organizations/list');
    $urlRouterProvider.when('/organizations/', '/organizations/list');
    $stateProvider.state('organizations', {
      absolute: true,
      url: '/organizations',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        '': {
          component: 'organizations'
        }
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    }).state('guest.organizations', {
      url: '/guest-list',
      parent: 'organizations',
      data: {
        authorities: []
      },
      views: {
        'organization-view': {
          component: 'publicOrganizations'
        }
      },
      onEnter: 
        function ($rootScope, $localStorage) {
          if ($localStorage.guest) {
            $rootScope.identity = {
              guest: {
                email: $localStorage.guest.email,
                initial: $localStorage.guest.initial,
                organization: $localStorage.guest.organization,
                smtp_connection: $localStorage.guest.smtp_connection,
                guestOrganizations: $localStorage.guest.guestOrganizations,
                guest_code: $localStorage.guest.guest_code,
                preferences: $localStorage.guest.preferences
              }
            };
          }
        },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('organizations');
          return $translate.refresh();
        }]
      }
    });
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('backgroundChoice', {
      templateUrl: 'app/modules/dashboard/background-choice/background-choice.component.html',
      bindings: {
        onBackgroundSelected: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $state, OrganizationsService, Helper, Growl, $filter) {
          var $ctrl = this, body = angular.element('body'), growl = new Growl();

          $ctrl.isOpen = false;
          $ctrl.isAdmin = false;
          $ctrl.isBrandingSet = true;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onImageChoiceClick = onImageChoiceClick;
          $ctrl.onResetToDefaultClick = onResetToDefaultClick;
          $ctrl.onSelectBackground = onSelectBackground;
          $ctrl.onColorBrandingConfigClick = onColorBrandingConfigClick;

          function onInit() {
            $ctrl.isAdmin = _.get($rootScope, 'identity.role', 'standard') === 'admin';
            $ctrl.selectedBackground = _.get($rootScope, 'identity.default_organization.home_bg');
            $ctrl.onBackgroundSelected({ name: $ctrl.selectedBackground });
            body.bind('click', bodyClickHandler);
            OrganizationsService
              .getOrgCustomBranding()
              .then(function (res) {
                $ctrl.isBrandingSet = !Helper.isObjectEmpty(res.data);
              });
          }

          function onChanges() { }
          function onDestroy() {
            body.unbind('click', bodyClickHandler)
          }

          function onImageChoiceClick() {
            $ctrl.isOpen = !$ctrl.isOpen;
          }

          function onResetToDefaultClick() {
            onSelectBackground(void 0, null);
          }

          function onSelectBackground(e, name) {
            Growl.clearAllMessages('global');
            $rootScope.identity.default_organization.home_bg = name;
            $ctrl.selectedBackground = name;
            $ctrl.onBackgroundSelected({ name: $ctrl.selectedBackground });
            OrganizationsService
              .update(null, {
                name: $rootScope.identity.default_organization.name,
                home_bg: name || 'NO_PHOTO'
              })
              .then(function (res) {
                growl.success($filter('translate')('contentPreview.message.backgroundChanged'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $rootScope.identity.default_organization.home_bg = res.data.home_bg;
              });
          }

          function bodyClickHandler(e) {
            var clickedElement = angular.element(e.target);
            var isInBackgroundChoice = clickedElement.closest('.background-choice-container').length;
            if (!isInBackgroundChoice) {
              $ctrl.isOpen = false;
              $scope.$apply();
            }
          }

          function onColorBrandingConfigClick() {
            $state.go('settings.org.branding');
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('presetSaveModal', {
      templateUrl: 'app/modules/runs/dashboard/run-filter/preset-save-modal/preset-save-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.savePreset = savePreset;

          function onInit() { }

          function onChanges() { }

          function onDestroy() { }

          function savePreset() {
            $ctrl.formSubmitted = true;
            if (!$ctrl.presetName) {
              return;
            }
            $ctrl.close({
              $value: {
                name: $ctrl.presetName
              }
            })
          }

        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('MilestoneService', MilestoneService)

  /*@ngInject*/
  function MilestoneService(MilestoneRespository) {
    var self = this;

    self.createTemplateMilestone = function (templateId, milestoneTitle, subjectType, position) {
      var params = {
        action: 'stages'
      }, data = {
        title: milestoneTitle,
        subject_id: templateId,
        subject_type: subjectType,
        position: position
      }
      return MilestoneRespository.create(params, data);
    }

    self.updateMilestone = function (subJectId, subJectType, data) {
      var params = {
        action: 'stages',
        actionId: data.id
      }, data = {
        title: data.title,
        subject_id: subJectId,
        subject_type: subJectType,
        position: data.position
      }
      return MilestoneRespository.update(params, data);
    }

    self.linkStepToMilestone = function (processId, stepId, milestoneId) {
      var params = {
        action: 'checklists',
        actionId: processId,
        subAction: 'steps',
        subActionId: stepId,
        arg: 'link-stage'
      }
      return MilestoneRespository.update(params, { stage_id: milestoneId });
    }

    self.unLinkStepToMilestone = function (processId, stepId) {
      var params = {
        action: 'checklists',
        actionId: processId,
        subAction: 'steps',
        subActionId: stepId,
        arg: 'unlink-stage'
      }
      return MilestoneRespository.update(params, {});
    }

    self.linkTaskToMilestone = function (processId, taskId, milestoneId) {
      var params = {
        action: 'runs',
        actionId: processId,
        subAction: 'tasks',
        subActionId: taskId,
        arg: 'link-stage'
      }
      return MilestoneRespository.update(params, { stage_id: milestoneId });
    }

    self.unLinkTaskToMilestone = function (runId, taskId) {
      var params = {
        action: 'runs',
        actionId: runId,
        subAction: 'tasks',
        subActionId: taskId,
        arg: 'unlink-stage'
      }
      return MilestoneRespository.update(params, {});
    }

    self.deleteMilestone = function (stageId) {
      var params = {
        action: 'stages',
        actionId: stageId
      }
      return MilestoneRespository.delete(params, {});
    }
  }
})();
/**
 * @ngdoc overview
 * @name tallyfy.steps
 *
 * @module tallyfy.steps
 *
 * @description
 * manage steps of process
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.steps', ['ui.bootstrap', 'ngTagsInput']);
})();

(function () {
  'use strict';

  angular.module('tallyfy')
    .provider('$tallyfyWebSocket',
      function () {
        var self = this, _socket, _config;

        self.$setConfig = function (config) {
          if (!config) {
            return;
          }
          _config = config;
        }

        self.$get =
          /*@ngInject*/
          function () {
            return {
              $getInstance: function () {
                return _socket;
              },
              $getConfig: function () {
                return _config;
              },
              $new: function () {
                if (!_config || !(_config && _config.url)) {
                  return;
                }
                _socket = new WebSocket(_config.url, []);
              }
            }
          }
      }
    );
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.textTemplate
 * @module tallyfy.organizations
 *
 * @description
 * Template text
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations')
    .component('personalizationSnippet', {
      templateUrl: 'app/modules/organizations/personalizationSnippet/personalization-snippet.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $filter, Growl, OrganizationsService, Helper) {
          var $ctrl = this,
            growl = new Growl();

          $ctrl.textTemplatePagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];
          $ctrl.universalSnippetFormModel = {
            guest: {
              pagination: { per_page: 10, page: 1, sort: 'title' },
              data: []
            },
            member: {
              pagination: { per_page: 10, page: 1, sort: 'title' },
              data: []
            },
            homepage: {
              pagination: { per_page: 10, page: 1, sort: 'title' },
              data: []
            }
          };

          $ctrl.emptySelectedGuestView = { id: '', title: 'None' };
          $ctrl.emptySelectedMemberView = { id: '', title: 'None' };
          $ctrl.emptySelectedMemberHomepageView = { id: '', title: 'None' };
          $ctrl.emptySelectedLightRolepageView = { id: '', title: 'None' };

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.getSelectBoxTextTemplates = getSelectBoxTextTemplates;
          $ctrl.guestViewSelect = guestViewSelect;
          $ctrl.updateHomepageTemplate = updateHomepageTemplate;
          $ctrl.updateOnBoardingTemplate = updateOnBoardingTemplate;
          $ctrl.saveCustomError404Template = saveCustomError404Template;
          $ctrl.updateOnLightRoleTemplate = updateOnLightRoleTemplate;

          function onInit() {
            $ctrl.selectedGuestView = $ctrl.emptySelectedGuestView;
            $ctrl.selectedMemberView = $ctrl.emptySelectedMemberView;
            $ctrl.selectedHomepageView = $ctrl.emptySelectedMemberHomepageView;
            $ctrl.selectedLightRolepageView = $ctrl.emptySelectedLightRolepageView;
            $ctrl.onBoardingTemplateId = $rootScope.identity.default_organization.onboarding_snippet;
            $ctrl.guest_onboarding_snippet = $rootScope.identity.default_organization.guest_onboarding_snippet;
            $ctrl.homepage_snippet = $rootScope.identity.default_organization.homepage_snippet;
            $ctrl.enableCustom404error = $rootScope.identity.default_organization.enable_custom_404_error;
            $ctrl.error404Template = $rootScope.identity.default_organization.error_404_template;
            $ctrl.light_role_error_snippet = $rootScope.identity.default_organization.light_role_error_snippet;
            if ($ctrl.onBoardingTemplateId) {
              getOnBoardingTextTemplate($ctrl.onBoardingTemplateId);
            }
            if ($ctrl.guest_onboarding_snippet) {
              getGuestOnBoardingSnippet($ctrl.guest_onboarding_snippet);
            }
            if ($ctrl.homepage_snippet) {
              getHomepageSnippet($ctrl.homepage_snippet);
            }
            if ($ctrl.light_role_error_snippet) {
              getLightRolepageSnippet($ctrl.light_role_error_snippet);
            }
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            generateFroalaConfig();
          }

          function onChanges() { }
          function onDestroy() { }

          function getOnBoardingTextTemplate(id) {
            OrganizationsService.getTextTemplate(id, true, $rootScope.userState)
              .then(function (res) {
                $ctrl.selectedMemberView = res.data;
              });
          }

          function getGuestOnBoardingSnippet(id) {
            OrganizationsService.getTextTemplate(id, true, $rootScope.userState)
              .then(function (res) {
                $ctrl.selectedGuestView = res.data;
              });
          }

          function getHomepageSnippet(id) {
            OrganizationsService.getTextTemplate(id, true, $rootScope.userState)
              .then(function (res) {
                $ctrl.selectedHomepageView = res.data;
              });
          }

          function guestViewSelect(template) {
            var snippetId = $ctrl.guest_onboarding_snippet === template.id ? null : template.id;
            OrganizationsService.update(null, {
              name: $rootScope.identity.default_organization.name,
              guest_onboarding_snippet: Helper.isObjectEmpty(snippetId) ? null : parseInt(snippetId),
              working_days: $rootScope.identity.default_organization.working_days
            }).then(function (res) {
              $rootScope.identity.default_organization.guest_onboarding_snippet = res.data.guest_onboarding_snippet;
              $ctrl.guest_onboarding_snippet = res.data.guest_onboarding_snippet;
              growl.success($filter('translate')('textTemplate.guestOnboardingSnippetSaved'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          function updateHomepageTemplate(value) {
            OrganizationsService
              .update(null, {
                name: $rootScope.identity.default_organization.name,
                homepage_snippet: parseInt(value.id),
                working_days: $rootScope.identity.default_organization.working_days
              }).then(function (res) {
                $rootScope.identity.default_organization.homepage_snippet = res.data.homepage_snippet;
                $ctrl.homepageTemplateId = $rootScope.identity.default_organization.homepage_snippet;
                if (!$rootScope.organizationTemplates[$ctrl.homepageTemplateId]) {
                  getHomepageSnippetData();
                } else {
                  OrganizationsService
                    .getTextTemplate($ctrl.homepageTemplateId, true)
                    .then(function (res) {
                      $rootScope.organizationTemplates[$ctrl.homepageTemplateId] = res.data;
                      growl.success($filter('translate')('textTemplate.homepageSnippetSaved'), {
                        referenceId: 'global',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                    });
                }
              });
          }

          function updateOnBoardingTemplate(value) {
            OrganizationsService
              .update(null, {
                name: $rootScope.identity.default_organization.name,
                onboarding_snippet: parseInt(value.id),
                working_days: $rootScope.identity.default_organization.working_days
              }).then(function (res) {
                $rootScope.identity.default_organization.onboarding_snippet = res.data.onboarding_snippet;
                $ctrl.onBoardingTemplateId = $rootScope.identity.default_organization.onboarding_snippet;
                if (!$rootScope.organizationTemplates[$ctrl.onBoardingTemplateId]) {
                  getWelcomeMessageSnippetData();
                }
                growl.success($filter('translate')('textTemplate.userOnboardingSnippetSaved'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
          }

          function getSelectBoxTextTemplates(args, model) {
            var defer = $q.defer();
            if ($ctrl.universalSnippetFormModel[model].allDataLoaded && $ctrl.allBlueprintsLoaded) {
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
              return defer.promise;
            }
            var params = {};
            if (args.init || ($ctrl.universalSnippetFormModel[model].pagination.q) && (args.query !== $ctrl.universalSnippetFormModel[model].pagination.q)) {
              $ctrl.universalSnippetFormModel[model].pagination = { per_page: 10, page: 1, sort: 'title' };
              $ctrl.universalSnippetFormModel[model].data = [];
              $ctrl.universalSnippetFormModel[model].pagination.q = args.query;
              params = _.pick($ctrl.universalSnippetFormModel[model].pagination, ['page', 'per_page', 'sort', 'q']);
            }
            if (args.query) {
              $ctrl.universalSnippetFormModel[model].pagination.q = args.query;
            }
            params = _.pick($ctrl.universalSnippetFormModel[model].pagination, ['page', 'per_page', 'sort', 'q']);
            OrganizationsService.getTextTemplates(params)
              .then(function (res) {
                res.meta.pagination.current_page !== res.meta.pagination.total_pages
                  ? $ctrl.universalSnippetFormModel[model].pagination.page++ : $ctrl.universalSnippetFormModel[model].allDataLoaded = true;
                $ctrl.universalSnippetFormModel[model].data = _.concat($ctrl.universalSnippetFormModel[model].data, res.data);
                defer.resolve({ allItemLoaded: $ctrl.universalSnippetFormModel[model].allDataLoaded });
              });
            return defer.promise;
          }

          function getWelcomeMessageSnippetData() {
            OrganizationsService
              .getTextTemplate($ctrl.onBoardingTemplateId, true)
              .then(function (res) {
                if (!$ctrl.organizationTemplates) {
                  $ctrl.organizationTemplates = {};
                }
                $rootScope.organizationTemplates[$ctrl.onBoardingTemplateId] = res.data;
              });
          }

          function getHomepageSnippetData() {
            OrganizationsService
              .getTextTemplate($ctrl.homepageTemplateId, true)
              .then(function (res) {
                if (!$ctrl.organizationTemplates) {
                  $ctrl.organizationTemplates = {};
                }
                $rootScope.organizationTemplates[$ctrl.homepageTemplateId] = res.data;
                growl.success($filter('translate')('textTemplate.homepageSnippetSaved'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
          }

          function generateFroalaConfig() {
            var toolbarsButtons = [];
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              placeholder: $filter('translate')('messages.typeSomething'),
              currentUser: $rootScope.identity,
              enter: FroalaEditor.ENTER_BR,
              hideTemplatePlugin: true,
              heightMin: 320,
              toolbar: {
                colorsStep: 5,
                colorsText: ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'],
                moreText: {
                  buttons: _.concat(
                    toolbarsButtons, [
                    "bold",
                    "italic",
                    "underline",
                    "align",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "fontSize",
                    "textColor",
                    "quote",
                    "codeTag",
                    "emoticons",
                    "fullscreen",
                    "insertImage",
                    "insertFile",
                    "insertVideo",
                    "insertLink",
                    "insertTable",
                    "embedly",
                    "pageBreak"
                  ]),
                  align: 'left',
                  buttonsVisible: 13 + toolbarsButtons.length
                }
              },
              enableFullscreen: $ctrl.action !== 'edit'
            }, true);
          }

          function saveCustomError404Template() {
            $ctrl.onSaving = !!$ctrl.error404Template;
            generateFroalaConfig();
            OrganizationsService.update(null, {
              name: $rootScope.identity.default_organization.name,
              working_days: $rootScope.identity.default_organization.working_days,
              enable_custom_404_error: $ctrl.enableCustom404error,
              error_404_template: $ctrl.error404Template
            }).then(function (res) {
              $rootScope.identity.default_organization.enable_custom_404_error = res.data.enable_custom_404_error;
              $rootScope.identity.default_organization.error_404_template = res.data.error_404_template;
              $ctrl.onSaving = false;
              $ctrl.form.$setPristine();
              if ($rootScope.identity.default_organization.error_404_template) {
                growl.success($filter('translate')('textTemplate.errorTemplateSaved'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }
            }, function () {
              $ctrl.onSaving = false;
            })
          }

          function getLightRolepageSnippet(id) {
            OrganizationsService.getTextTemplate(id, true, $rootScope.userState)
              .then(function (res) {
                $ctrl.selectedLightRolepageView = res.data;
              });
          }

          function updateOnLightRoleTemplate(value) {
            OrganizationsService
              .update(null, {
                name: $rootScope.identity.default_organization.name,
                light_role_error_snippet: parseInt(value.id),
                working_days: $rootScope.identity.default_organization.working_days
              }).then(function (res) {
                $rootScope.identity.default_organization.light_role_error_snippet = res.data.light_role_error_snippet;
                $ctrl.light_role_error_snippet = $rootScope.identity.default_organization.light_role_error_snippet;
                growl.success($filter('translate')('textTemplate.userLightRoleSnippetSaved'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
          }
        }
    });
})();
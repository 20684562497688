(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(AdBlockConfig);

  AdBlockConfig.$inject = ['adblockProvider'];

  function AdBlockConfig(adblockProvider) {
    adblockProvider.imagePath = 'assets/images/Ad_block_2x.png';
    adblockProvider.title = '<h2 class="_600 t-slate-three">Unfortunately all ad blockers slow down Tallyfy</h2><p class="t-slate-three text _600 mb-0">We don’t run ads so there’s no need for ad blockers anyway.</p>';
    adblockProvider.description = '<p class="t-slate-three text _600 text-center text-sm-left mb-3">If you’re using AdBlock, please select, “Don’t run on pages on this site.” to improve your Tallyfy experience.</p><img src="assets/images/arrow-right@2x.png" height="179" class="float-right" alt="Tallyfy"/>';
    adblockProvider.cancel = true;
    adblockProvider.refresh = false;
  }
})();
(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.tags.service.TagsService
   * @module tallyfy.tags
   *
   * @description
   * A service for tags
   * 
  * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: Feroj )
   * 
   * */
  angular
    .module('tallyfy.tags')
    .service('TagsService', TagsService);
  /*@ngInject*/
  function TagsService(TagsRepository, BLUEPRINT_TYPE, customBrandingService) {
    /**
     * store StepService context in self
     * JavaScript has block level context So it can be changed in nested 
     * function
     */
    var self = this;
    angular.extend(this, TagsRepository);

    /**
     * @ngdoc method
     * @name isSpecialTag
     * @description Check the tag is special tag or not
     * @param {String} tagTitle
     * @returns {Boolean}
     */
    self.isSpecialTag = function (tagTitle) {
      return ([BLUEPRINT_TYPE.DOCUMENT, BLUEPRINT_TYPE.PROCEDURE, BLUEPRINT_TYPE.FORM].indexOf(_.toLower(tagTitle)) > -1);
    };

    /**
     * @ngdoc method
     * @name isTagTextColor
     * @description check tag background color and set text color
     * @param {String} tagColor
     * @returns {String}
     */
    self.isTagTextColor = function (tagColor) {
      var textColor = '';
      if(tagColor && tagColor.length === 7) {
        if (tinycolor(tagColor).getBrightness() > 200) {
          textColor = customBrandingService.defaultColor.GRAY_COLOR;
        } else {
          textColor = customBrandingService.defaultColor.WHITE_COLOR;
        }
      }
      return textColor;
    };

    /**
      * @function
      * @name getRandomColorCode
      * @description Get the random color code
      * @param {type} tag
      * @returns {void}
      */
    self.getRandomColorCode = function () {
      return '#' + (function co(lor) {
        return (lor += [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f'][Math.floor(Math.random() * 16)]) && (lor.length === 6) ? lor : co(lor);
      })('');
    };
  }
})(); 

(function () {
  'use strict';
  angular.module('tallyfy')
    .component('commentFilter', {
      templateUrl: 'app/components/compact/items/compactComment/comment-filter/comment-filter.component.html',
      bindings: {
        valueChanged: '&?'
      },
      controller:
        /*@ngInject*/
        function (_, MessagesService, $timeout) {
          var $ctrl = this,
            body = angular.element('body');

          $ctrl.filters = MessagesService.getCommentFilters();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.selectAllFilter = selectAllFilter;
          $ctrl.filterSelect = filterSelect;
          $ctrl.openFilterMenu = openFilterMenu;

          $ctrl.selectAll = false;

          function onInit() {
            body.on('click', bodyClickHandler);
            $ctrl.selectedFilters = _.filter($ctrl.filters, { selected: true });
            $ctrl.valueChanged({ value: $ctrl.selectedFilters });
            document.addEventListener('scroll', onPageScrollThrottle(), true);
          }
          function onChanges() { }

          function onDestroy() {
            document.removeEventListener('scroll', onPageScrollThrottle(), true);
          }

          function bodyClickHandler(e) {
            var clickedElement = angular.element(e.target),
              isInElement = clickedElement.closest('.comment-filter-wrapper').length;
            if (!isInElement) {
              $ctrl.showMenuFilter = false;
            }
          }

          function selectAllFilter() {
            _.map($ctrl.filters, function (filter) {
              filter.selected = !$ctrl.selectAll;
              return filter;
            });
            $ctrl.filters[0].selected = true;
            $ctrl.selectedFilters = _.filter($ctrl.filters, { selected: true });
            if ($ctrl.selectedFilters.length) {
              $ctrl.selectAll = $ctrl.selectedFilters.length === $ctrl.filters.length;
              $ctrl.valueChanged({ value: $ctrl.selectedFilters });
            } else {
              $ctrl.selectAll = true;
              selectAllFilter();
            }
          }

          function filterSelect() {
            $ctrl.selectedFilters = _.filter($ctrl.filters, { selected: true });
            if ($ctrl.selectedFilters.length) {
              $ctrl.selectAll = $ctrl.selectedFilters.length === $ctrl.filters.length;
              $ctrl.valueChanged({ value: $ctrl.selectedFilters });
            } else {
              $ctrl.selectAll = true;
              selectAllFilter();
            }
          }

          function openFilterMenu(e) {
            $ctrl.showMenuFilter = !$ctrl.showMenuFilter;
            if (!$ctrl.showMenuFilter) {
              return;
            }
            calculateMenuPosition(e);
          }

          function calculateMenuPosition(e) {
            var filterMenu = null;
            var timeoutHandler = $timeout(function () {
              filterMenu = document.getElementsByClassName('comment-filter-items-wrapper');
              if (filterMenu.length) {
                var buttonEl = document.getElementsByClassName('comment-filter-nav');
                if (buttonEl.length) {
                  var buttonPosition = buttonEl[0].getBoundingClientRect(), filterMenuRect = filterMenu[0].getBoundingClientRect();
                  filterMenu[0].style.top = buttonPosition.top + filterMenuRect.height < window.innerHeight ? buttonPosition.top + 28 + 'px' : buttonPosition.top - filterMenuRect.height - 16 + 'px';
                }
              }
            }, 10);
            timeoutHandler.then(function () {
              if (filterMenu.length) {
                filterMenu[0].style.opacity = 1;
              }
            })
          }

          function _throttle(fn, wait) {
            var time = Date.now();
            return function () {
              if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
              }
            };
          }

          function onPageScrollThrottle() {
            return _throttle(function () {
              calculateMenuPosition();
            }, 10);
          }
        }
    });
})();
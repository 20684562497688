/**
 * @ngdoc Config
 * @name tallyfy.settings.config.routeConfig
 * @module tallyfy.settings
 *
 * @description
 * set route for integration component
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'PLANS'];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig($stateProvider, $urlRouterProvider, PLANS) {
    $stateProvider.state('settings.integration', {
      parent: 'settings',
      absolute: true,
      url: '/integrations',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view': {
          component: 'integration'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('integration');
          $translatePartialLoader.addPart('magicLink');
          $translatePartialLoader.addPart('steps');
          return $translate.refresh();
        }]
      }
    });

    $stateProvider.state('settings.integration.api', {
      url: '/api',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view@settings': {
          component: 'api'
        }
      }
    });

    $stateProvider.state('settings.integration.storage', {
      url: '/storage',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view@settings': {
          component: 'storage'
        }
      }
    });

    $stateProvider.state('settings.integration.ldap', {
      url: '/ldap',
      data: {
        authorities: ['USER_ROLE'],
        restrictedPlans: [PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY, PLANS.BASIC, PLANS.BASICANNUAL, PLANS.BASICMONTHLY, PLANS.FREE]
      },
      views: {
        'settings_view@settings': {
          component: 'ldap'
        }
      },
      resolve: {
        authorizePlan: ['AuthPlan',
          function(AuthPlan) {
              return AuthPlan.planAuthorize();
          }
        ]
      }
    });

    $stateProvider.state('settings.integration.smtp', {
      url: '/smtp',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view@settings': {
          component: 'smtp'
        }
      }
    });

    $stateProvider.state('settings.integration.zapier', {
      url: '/zapier',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'settings_view@settings': {
          component: 'zapier'
        }
      }
    });

  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.account.component.preferences
 * @module tallyfy.account
 *
 * @description
 * A component to manage guest user's preferences
 *
 * @author Rehan Mahmood ( gmail:: go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .component('guestPreferences', {
      templateUrl: 'app/modules/manage/guest-components/guest-preferences/guest-preferences.html',
      controller:
        /*@ngInject*/
        function (_, $q, Growl, blockUI, $filter, GuestPreferenceRepository, OrganizationsService) {
          var $ctrl = this,
            preferences = [],
            growl = new Growl(),
            blockUI = blockUI.instances.get('preferences');

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.onDaySelectedChanged = onDaySelectedChanged;

          /**
           * public methods
           */
          $ctrl.savePreference = savePreference;
          $ctrl.cadenceDays = OrganizationsService.getCadenceDays();
          $ctrl.onDayClicked = onDayClicked;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            preferences = ['daily_digest'];
            $ctrl.preferences = _.chain(preferences).map(function (p) {
              return {
                slug: p,
                metadata: undefined,
                value: undefined
              };
            }).keyBy(function (k) {
              return k.slug;
            }).value();
            getPreferences();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getPreferences
           * @description Get updated preference values
           * @returns {Object} preference
           */
          function getPreferences() {
            blockUI.start();
            $q.all([
              GuestPreferenceRepository.getPreferences(),
              GuestPreferenceRepository.getCadenceDays()
            ]).then(function (response) {
              extractPreferences(response[0].data);
              $ctrl.guestCadenceDays = response[1].data.cadence_days;
              $ctrl.isShowPreference = true;
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name extractPreferences
           * @description Make object for user preferences
           * @param {Object} pfs
           * @returns {Object}
           */
          function extractPreferences(pfs) {
            var pf = _.chain(pfs).filter(function (p) {
              return _.includes(preferences, p.slug);
            }).keyBy(function (k) {
              return k.slug;
            }).value();
            angular.extend($ctrl.preferences, pf);
            setPreferencesDefaultValue();
          }

          /**
           * @ngdoc method
           * @name setPreferencesDefaultValue
           * @description 
           * set default value for preferences for empty value 
           */
          function setPreferencesDefaultValue() {
            $ctrl.preferences['daily_digest'].value = _.get($ctrl.preferences, "daily_digest.value", 1);
          }

          /**
           * @ngdoc method
           * @name savePreference
           * @description Save/update preference.
           * @param {Object} preference
           * @returns {void}
           */
          function savePreference(preference) {
            GuestPreferenceRepository.updatePreferences(preference).then(function () {
              growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          function onDaySelectedChanged() {
            if (!$ctrl.guestCadenceDays.length) {
              $ctrl.guestCadenceDays.push('Monday');
            }
            GuestPreferenceRepository.updateCadenceDays(
              { cadence_days: $ctrl.guestCadenceDays }
            ).then(function (res) {
              growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          //On day clicked
          function onDayClicked (day) {
            var isSelected = _.includes($ctrl.guestCadenceDays, day), position = $ctrl.guestCadenceDays.indexOf(day);

            isSelected ? $ctrl.guestCadenceDays.splice(position, 1) : $ctrl.guestCadenceDays.push(day);

            onDaySelectedChanged();
          }
        }
    });
})();

/**
 * @ngdoc Config
 * @name tallyfy.search.config.SearchStateConfig
 * @module tallyfy.search
 *
 * @description
 * set route search Module
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.search')
    .config(SearchStateConfig);
    
  SearchStateConfig.$inject = ['$stateProvider'];
  function SearchStateConfig($stateProvider) {
    $stateProvider.state('search', {
      parent: 'app',
      url: '/:org_id/search?on&search&blueprint&process&task',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        on: { squash: true, value: null },
        search: { squash: true, value: null },
        blueprint: { squash: true, value: null },
        process: { squash: true, value: null },
        task: { squash: true, value: null },
        tags: true
      },
      onEnter: ["$state", "$stateParams", "universalSearchService", function ($state, $stateParams, universalSearchService) {
        universalSearchService.setSearchParams($stateParams);
        $state.go('dashboard');
      }],
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();

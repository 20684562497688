/**
 * @ngdoc Component
 * @name tallyfy.analytics.Component.analytics
 * @module tallyfy.securityAndLog
 *
 * @description
 * A component to manage security and logs
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.securityAndLog')
    .component('securityAndLog', {
      templateUrl: 'app/modules/securityAndLog/securityAndLog.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $filter, $uibModal, $sce, EmailLogTableService, Growl, moment, SecurityAndLogService) {
          var $ctrl = this,
            growl = new Growl(),
            emailLogs = [];

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getEmailLogs = getEmailLogs;

          /**
           * @function $onInit
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Useful to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getEmailLogs(args) {
            var defer = $q.defer();
            var params = {
              org_id: _.get($rootScope.identity, 'default_organization.id'),
              user_email: $ctrl.resolve.user.email
            }
            if (args) {
              angular.extend(params, args || {});
            }
            $ctrl.isLoading = true;
            $ctrl.emailLogsSubLine = _.indexOf($ctrl.resolve.user.email, '+') !== -1;
            SecurityAndLogService.getEmailLogs(params).then(function (res) {
              var data = getMappedEmailAndLogsData(res.data.items);
              emailLogs = _.concat(emailLogs, data);
              $ctrl.tableOptions.infiniteScrollDataLoad.pagination = res.data.paging;
              if (!res.data.items.length) {
                $ctrl.tableOptions.infiniteScrollDataLoad.pagination.next = void 0;
              }
              $ctrl.isLoading = false;
              defer.resolve(emailLogs);
            }, function () {
              $ctrl.isLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function getMappedEmailAndLogsData(data) {
            return _.map(data, function (item) {
              var message = _.get(item, 'delivery-status.message') || _.get(item, 'delivery-status.description'),
                dateSplit = new Date().toString().split(" "),
                timeZoneFormatted = dateSplit[dateSplit.length - 3];
              return {
                date: moment.unix(item["timestamp"]).format('ddd, DD MMM YYYY hh:mm A') + " "
                  + timeZoneFormatted.substring(0, timeZoneFormatted.length - 2) + ':'
                  + timeZoneFormatted.substring(timeZoneFormatted.length - 2, timeZoneFormatted.length),
                to: _.get(item, 'recipient'),
                subject: _.get(item, 'message.headers.subject'),
                status: _.get(item, 'event'),
                deliveryStatusMessage: message || '',
                logLevel: _.get(item, 'log-level'),
                url: _.get(item, 'storage.url'),
                key: _.get(item, 'storage.key')
              };
            });
          }

          function showDetails(url, key) {
            $uibModal.open({
              component: 'emailContentViewer',
              windowClass: 'email-content-viewer',
              size: 'lg',
              resolve: {
                mailgunUrl: $sce.trustAsResourceUrl(url),
                user: $ctrl.resolve.user,
                key: $sce.trustAsResourceUrl(key)
              }
            }).result.then(function (res) {
              if (res && res.status == 404) {
                growl.success($filter('translate')('regularUsers.status.emailContentNotFound'), {
                  referenceId: 'emailContentNotFound',
                  disableIcons: true,
                  disableCloseButton: false
                });
              }
            });
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: EmailLogTableService.getGuestTableOptions(),
              infiniteScrollDataLoad: {},
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'date',
                  title: $filter('translate')('emailLogs.securityTable.date'),
                  initState: true
                }, {
                  field: 'to',
                  title: $filter('translate')('emailLogs.securityTable.to'),
                  initState: true
                }, {
                  field: 'subject',
                  title: $filter('translate')('emailLogs.securityTable.subject'),
                  initState: true
                }, {
                  field: 'status',
                  title: $filter('translate')('emailLogs.securityTable.status'),
                  initState: true
                }, {
                  field: 'deliveryStatusMessage',
                  title: $filter('translate')('emailLogs.securityTable.deliveryStatus'),
                  initState: true
                }, {
                  field: 'logLevel',
                  title: $filter('translate')('emailLogs.securityTable.logLevel'),
                  initState: true
                }]
              },
              tableFilterModel: {
                title: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  showDetails: showDetails
                },
                variables: {}
              }
            }
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.stepLogicForm
 * @module tallyfy.steps
 *
 * @description
 * A component to manage rules tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepLogicForm', {
      bindings: {
        process: '<',
        step: '=',
        field: "=",
        flag: "=",
        isLast: "<",
        isFirst: "<",
        onDelete: "&",
        index: "<",
        onSaving: '<',
        isPublic: '<',
        enableRulesOrder: '<'
      },
      templateUrl: 'app/modules/steps/logic/form/logic-form.html',
      controller: function (_, $scope, StepService, $filter, $rootScope, $timeout, Helper, PLANS, AuthPlan, FieldService, SHORT_TEXT_VALUE_TYPE) {
        var $ctrl = this,
          unregisterStepPositionEventListener,
          unregisterTabChangedEventHandler,
          prerunData = [],
          captureData = [],
          updateInvalidRuleEventHandler,
          searchPreviousValue = '';

        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * Public properties
         */
        $ctrl.isStep = false;
        $ctrl.isFieldSelected = false;
        $ctrl.isSaved = true;
        $ctrl.conditions = [];
        $ctrl.logicForm = {};
        $ctrl.logicFormValid = true;
        $ctrl.conditionUiSelectLimit = 10;

        /**
         * Public method
         */
        $ctrl.isStepSelected = isStepSelected;
        $ctrl.isStepSelectedCheck = isStepSelectedCheck;
        $ctrl.saveCondition = saveCondition;
        $ctrl.deleteLogic = deleteLogic;
        $ctrl.isSelected = isSelected;
        $ctrl.setSearchPlaceholder = setSearchPlaceholder;
        $ctrl.prepareConditions = prepareConditions;
        $ctrl.setSelectedValue = setSelectedValue;
        $ctrl.setFieldData = setFieldData;
        $ctrl.getSaveText = getSaveText;
        $ctrl.triggerSaveStep = triggerSaveStep;
        $ctrl.getConditionLabel = getConditionLabel;
        $ctrl.isDeleting = false;
        $ctrl.searchRecords = searchRecords;
        $ctrl.loadMore = loadMore;
        $ctrl.uiSelectOpenClose = uiSelectOpenClose;
        $ctrl.optionChange = optionChange;

        /**
         * @function
         * @name initialization
         * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          $ctrl.guid = Helper.guid();
          $ctrl.isProPlan = [PLANS.PRO, PLANS.PROMONTHLY, PLANS.PROANNUAL, PLANS.ENTERPRISE_ANNUAL, PLANS.ENTERPRISE_MONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
          var getStepValues = StepService.getStepValues();
          prerunData = $ctrl.process.prerun;
          _.forEach(_.get($ctrl.process, 'steps.data') || [], function (step) {
            captureData.push(step.captures);
          });
          captureData = _.flattenDeep(captureData);
          var stepInRule = _.find($ctrl.process.steps.data, { id: $ctrl.field.subject_id }) || {};
          $ctrl.stepCases = stepTransformer(StepService.getStepCase(stepInRule.step_type));
          $ctrl.stepValues = stepTransformer(getStepValues);
          $ctrl.prepareConditions();
          setFieldDataValue($ctrl.field.subject_id, $ctrl.field.subject_type);
          var isFirstValue = _.get($ctrl.step, 'condition'), terms = _.first(isFirstValue.terms);
          $ctrl.step.condition.isFirst = !!($ctrl.isFirst || _.isEmpty(terms.subject_id));
          $ctrl.hasAuthority = Helper.checkAccessAuthority(false);
          $ctrl.stepsListExample = [
            { name: $filter('translate')('steps.logic.logicExample.dropDown1') }
          ];
          $ctrl.selectedStepExample = _.first($ctrl.stepsListExample);
          $ctrl.hasAuthority = Helper.checkAccessAuthority(false);
          if (_.get($ctrl.field, 'dataValue.field_type') === 'table') {
            if ($ctrl.field.column_contains_name) {
              $ctrl.field.operation = 'contains';
              $ctrl.field.column_contains_name = parseInt($ctrl.field.column_contains_name);
            } else {
              $ctrl.field.operation = 'table_contains';
            }
          } else if ($ctrl.field.operation === "equals_any") {
            $ctrl.fieldStatements = _.filter(_.get($ctrl.field, 'dataValue.options', []), function (opt) {
              return _.find(_.get($ctrl.field, 'statement', []), function (statement) {
                return statement === opt.text;
              });
            });
            _.map($ctrl.field.dataValue.options, function (field) {
              field.selected = !!_.find($ctrl.fieldStatements, { id: field.id });
              return field;
            });
            $timeout(function () {
              $ctrl.selectOptions = getSelectBoxOptions();
            }, 0);
          }
          $ctrl.stepsListExample = [
            { name: $filter('translate')('steps.logic.logicExample.dropDown1') }
          ];
          $ctrl.selectedStepExample = _.first($ctrl.stepsListExample);
        }

        /**
         * @function
         * @name onChanges
         * @description A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @function
         * @name onDestroy
         * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() {
          unregisterTabChangedEventHandler();
          unregisterStepPositionEventListener();
          updateInvalidRuleEventHandler();
        }

        /**
         * @function
         * @name stepTransformer
         * @description Transform step cases records
         * @param {*} stepCases
         * @returns {*} steps
         */
        function stepTransformer(stepCases) {
          return _.map(stepCases, function (step) {
            return {
              name: step,
              key: 'steps.logic.label.' + step
            };
          });
        }

        /**
         * @function
         * @name setFieldDataValue
         * @description Set field value
         * @param {*} id
         * @param {*} type
         * @returns {void}
         */
        function setFieldDataValue(id, type) {
          if (type === 'Capture') {
            $ctrl.field.dataValue = _.find(captureData, { id: id }) || [];
          } else if (type === 'Prerun') {
            $ctrl.field.dataValue = _.find(prerunData, { id: id }) || [];
          } else {
            angular.noop();
          }
        }

        /**
         * @function
         * @name isStepSelected
         * @param {Object} value
         * @description Check the step is selected in dropdown
         * @returns void
         */
        function isStepSelected(value) {
          $ctrl.step.condition.isFirst = false;
          $ctrl.flag = "show";
          $ctrl.isStep = value.type === 'Step';
          $ctrl.isFieldSelected = (value.type === 'Capture') || (value.type === 'Prerun');
          $ctrl.setFieldData(value);
          $ctrl.isSaved = false;
          $ctrl.stepCases = stepTransformer(StepService.getStepCase(value.stepType));
        }

        /**
         * @function
         * @name saveCondition
         * @description Save the condition
         */
        function saveCondition() {
          if (!Helper.checkAccessAuthority() || !$ctrl.logicForm.$dirty)
            return;
          if (!$ctrl.isFieldSelected && !$ctrl.isStep) {
            $ctrl.logicForm.inlineFormCustomSelect.$setValidity('required', false);
            $ctrl.logicFormValid = isLogicFormValid();
          } else {
            if ($ctrl.field.operation === 'equals_any') {
              $ctrl.field.statement = _.map($ctrl.fieldStatements || [], function (s) {
                return s.text;
              }) || [];
            }
            if (!isLogicFormValid()) {
              $ctrl.logicFormValid = isLogicFormValid();
            } else {
              triggerSaveStep($ctrl.step);
              $ctrl.isSaved = true;
            }
          }
        }

        /**
         * @function
         * @name isLogicFormValid
         * @description Check if logic form is valid
         * @returns {boolean}
         */
        function isLogicFormValid() {
          var isValid = $ctrl.logicForm.inlineFormCustomSelect.$valid && !_.isEmpty($ctrl.field.operation) && !_.isEmpty($ctrl.flag);
          if ($ctrl.field.operation !== 'is_empty' && $ctrl.field.operation !== 'is_not_empty') {
            isValid = isValid && !Helper.isObjectEmpty($ctrl.field.statement);
          }
          return isValid;
        }

        /**
         * @function
         * @name setFieldData
         * @param {Object} subject
         * @description Set the field data
         * @returns void
         */
        function setFieldData(subject) {
          $ctrl.field.subject_id = subject.id.toString();
          $ctrl.field.subject_type = subject.type;
          $ctrl.field.operation = $ctrl.stepCases.length === 1 && $ctrl.isStep ? _.head($ctrl.stepCases).name : null;
          $ctrl.field.statement = $ctrl.stepValues.length === 1 && $ctrl.isStep ? _.head($ctrl.stepValues).name : null;
          setFieldDataValue(subject.id, subject.type);
          setConditionCases(subject);
        }

        /**
         * @function
         * @name deleteLogic
         * @description Delete logic
         * @returns void
         */
        function deleteLogic() {
          if (!Helper.checkAccessAuthority())
            return;
          $ctrl.isDeleting = true;
          $ctrl.logicForm.inlineFormCustomSelect.$setValidity('required', false);
          $ctrl.logicFormValid = true;
          $timeout(function () {
            $ctrl.onDelete({ index: $ctrl.index });
            $ctrl.isDeleting = true;
          }, 200);
        }

        /**
         * @function
         * @name isStepSelectedCheck
         * @description Check if step is selected
         * @returns void
         */
        function isStepSelectedCheck() {
          $ctrl.isStep = $ctrl.field.subject_type === 'Step';
          $ctrl.isFieldSelected = ($ctrl.field.subject_type === 'Capture') || ($ctrl.field.subject_type === 'Prerun');
        }

        /**
         * @function
         * @name isSelected
         * @param {Object} step
         * @description Check if step is selected
         * @returns {boolean}
         */
        function isSelected(step) {
          return (step.id == $ctrl.field.subject_id && $ctrl.field.subject_type == step.type);
        }

        /**
         * @function
         * @name prepareConditions
         * @description Prepare conditions
         * @returns void
         */
        function prepareConditions() {
          $ctrl.conditions = [];
          // Push prerun from process meta if and only if there is any prerun
          if (!_.isEmpty($ctrl.process.prerun)) {
            _.map($ctrl.process.prerun, function (item) {
              $ctrl.conditions.push({
                id: item.id,
                name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                title: $filter('translate')('steps.logic.label.kickOffTitle', { label: item.label }),
                subjectType: $filter('translate')('steps.logic.label.pre_run'),
                class: 'item-field',
                type: 'Prerun',
                required: item.required
              });
            });
          }

          // Push steps
          if ($ctrl.process.steps.data.length) {
            var stepsData = _.orderBy($ctrl.process.steps.data, 'position');
            _.map(stepsData, function (step, index) {
              if ($ctrl.step.id != step.id) {
                $ctrl.conditions.push({
                  id: step.id,
                  name: $filter('translate')('steps.logic.label.step_plain', { position: index + 1, title: step.title }),
                  nameText: $filter('translate')('steps.logic.label.step_plain_text', { position: index + 1, title: step.title }),
                  title: $filter('translate')('steps.logic.label.stepTitle', { position: index + 1, title: step.title }),
                  subjectType: '',
                  class: 'item-step',
                  type: 'Step',
                  stepType: step.step_type
                });
                _.map(_.filter(step.captures, function (item) {
                  return !_.isUndefined(item.step_id);
                }), function (item) {
                  $ctrl.conditions.push({
                    id: item.id,
                    name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                    nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                    title: $filter('translate')('steps.logic.label.captureTitle', { position: step.position, title: step.title, fieldName: item.label }),
                    subjectType: '',
                    class: 'item-field',
                    type: 'Capture',
                    parent_step_id: step.id,
                    required: item.required
                  });
                });
              }
            });
          }
          $ctrl.conditionsCache = angular.copy($ctrl.conditions);
          setSelectedValue();
        }

        /**
         * @function
         * @name setSelectedValue
         * @description Set selected value/condition
         * @returns {Object}
         */
        function setSelectedValue() {
          _.filter($ctrl.conditions, function (item) {
            if (item.id.toString() === $ctrl.field.subject_id) {
              $ctrl.isSaved = true;
              $ctrl.condition = angular.copy(item);
              setConditionCases($ctrl.condition);
              return;
            }
          });
        }

        /**
         * @function
         * @name setSearchPlaceholder
         * @param {*} $select
         * @description Set search placeholder
         * @returns {String}
         */
        function setSearchPlaceholder($select) {
          if (!_.isUndefined($select.searchInput)) {
            _.head($select.searchInput).placeholder = $filter('translate')('steps.logic.placeholder.searchField');
          }
        }

        /**
         * @function
         * @name triggerSaveStep
         * @param {Object} step
         * @description fire an event to save step
         * @returns void
         */
        function triggerSaveStep(step) {
          $rootScope.$emit('SAVE_STEP_CHANGES', step.id);
        }

        /**
         * @function
         * @name getSaveText
         * @description Get the text of Save Button
         * @returns {string}
         */
        function getSaveText() {
          if ($ctrl.isSaved) {
            return $filter('translate')('steps.buttons.saved');
          }
          return $filter('translate')('steps.buttons.save');
        }

        /**
         * @function
         * @name getConditionLabel
         * @description Get the name of selected Step or Field
         * @param {object} selectedItem
         * @returns {string}
         */
        function getConditionLabel(selectedItem) {
          var selectedCondition = _.get(selectedItem, 'selected');
          if (!_.isUndefined(selectedCondition)) {
            $ctrl.isFieldRequired = !!selectedCondition.required;
            if (selectedCondition.type.toLowerCase() === 'prerun') {
              return $filter('translate')('steps.logic.label.pre_run') + " &gt; " + selectedCondition.name;
            }
            if (selectedCondition.type.toLowerCase() === 'capture') {
              var parent_step = _.find($ctrl.conditions, function (condition) {
                return condition.id == selectedCondition.parent_step_id;
              });
              return parent_step.name + " &gt; " + selectedCondition.name;
            }
            return selectedCondition.name;
          }
        }

        /**
         * event handler when last selected step or tab changed
         * @type {*|(function())}
         */
        unregisterTabChangedEventHandler = $rootScope.$on('STEP:CHANGED_SELECTED_CONDITION_TAB', function () {
          $ctrl.logicFormValid = isLogicFormValid();
        });

        /**
         * @ngdoc listener
         * @name updateStepPositionEventListener
         * @description Listens to change in step position
         */
        unregisterStepPositionEventListener = $rootScope.$on('STEP:UPDATE_STEP_POSITION', function (event, updatedStep) {
          $ctrl.prepareConditions();
        });

        /**
         * @name setConditionCase
         * @param {*} field
         * @returns {void}
         * @description set condition case depend on field type
         * @returns void
         */
        function setConditionCases(field) {
          var capture = getCapture(field),
            conditionCases = StepService.getConditionCase();

          $ctrl.conditionCases = [];
          if (capture && (capture.field_type === "dropdown" || capture.field_type === "multiselect" || capture.field_type === "radio")) {
            _.forEach(conditionCases, function (condition) {
              if (condition !== 'less_than' && condition !== 'greater_than' && condition !== 'is_empty' && condition !== 'is_not_empty' && condition !== 'table_contains' && condition !== 'column_contains') {
                $ctrl.conditionCases.push({ name: condition, key: 'steps.logic.label.' + condition });
              }
            });
          } else if (capture && capture.field_type === "table") {
            _.forEach(conditionCases, function (condition) {
              if (condition === 'table_contains') {
                $ctrl.conditionCases.push({ name: condition, key: 'steps.logic.label.' + condition });
              }
              if (condition === 'column_contains') {
                $ctrl.conditionCases.push({ name: 'contains', key: 'steps.logic.label.' + condition });
              }
            });
          } else {
            _.forEach(conditionCases, function (condition) {
              if (condition !== 'less_than' && condition !== 'greater_than' && condition !== 'equals_any' && condition !== 'equals' && condition !== 'not_equals' && condition !== 'table_contains' && condition !== 'column_contains' && condition !== 'is_empty' && condition !== 'is_not_empty' || (!field.required && (condition === 'is_empty' || condition === 'is_not_empty'))) {
                $ctrl.conditionCases.push({ name: condition, key: 'steps.logic.label.' + condition });
              }
              if (capture && capture.field_type === "text") {
                var isNumericType = capture.field_validation.indexOf(SHORT_TEXT_VALUE_TYPE.NUMERIC) >= 0;
                if (isNumericType && (condition === 'greater_than' || condition === 'less_than')) {
                  $ctrl.conditionCases.push({ name: condition, key: 'steps.logic.label.' + condition });
                }
              }
            });
          }
        }

        /**
         * @name getCapture
         * @param {*} field 
         * @returns {object} capture object
         * @description get capture of field
         * @returns {Object} 
         */
        function getCapture(field) {
          var capture;
          if (_.get(field, 'type') === 'Capture') {
            _.forEach($ctrl.process.steps.data, function (step) {
              capture = _.find(step.captures, { id: field.id });
              if (capture) return false;
            });
          } else if (_.get(field, 'type') === 'Prerun') {
            capture = _.find($ctrl.process.prerun, { id: field.id });
          }

          return capture;
        }

        /**
         * @ngdoc method
         * @name loadMore
         * @public
         * @description triggers when scrolled down
         */
        function loadMore() {
          if ($ctrl.conditionsCache.length > $ctrl.conditionUiSelectLimit) {
            $ctrl.conditionUiSelectLimit += 10;
          }
        }

        /**
         * @ngdoc method
         * @name uiSelectOpenClose
         * @public
         * @description reset data on ui-select
         * open and close
         */
        function uiSelectOpenClose($select) {
          if (!$select.open) {
            return;
          }
          searchPreviousValue = '';
          $ctrl.conditionUiSelectLimit = 10;
          $ctrl.conditions = $ctrl.conditionsCache;
        }

        /**
          * @ngdoc method
          * @name searchRecords
          * @private
          * 
          * @description search record locally
          * wher user search for record. 
          * 
          * @param {string} $select type of search
          * @param {search} search search text
          */
        function searchRecords($select, search) {
          if (!$select.open && (!search && !searchPreviousValue)) {
            return;
          }
          $ctrl.conditionUiSelectLimit = 10;
          if (search) {
            searchPreviousValue = search;
            var searchedRecords = _.filter($ctrl.conditionsCache, function (value) {
              return _.toLower(value.nameText).indexOf(_.toLower(search)) !== -1;
            });
            $ctrl.conditions = searchedRecords;
          }
          if (!search && searchPreviousValue) {
            $ctrl.conditions = $ctrl.conditionsCache;
          }
        }

        $scope.$watch('$ctrl.field.operation', function (value, oldValue) {
          if (value === oldValue) {
            return;
          }
          if (value === 'equals_any' && !$ctrl.selectOptions) {
            $timeout(function () {
              $ctrl.selectOptions = getSelectBoxOptions();
            }, 0);
          }
        })

        function setSelectedElement(selectboxWidget) {
          $timeout(function () {
            var list = selectboxWidget.ul[0];
            var options = list.getElementsByTagName('li');
            for (var i = 0; i < options.length; i++) {
              var input = options[i].getElementsByTagName('input');
              if (options[i].classList.contains('k-state-selected')) {
                input[0].checked = true;
              } else {
                input[0].checked = false;
              }
            }
          }, 0);
        }

        function getSelectBoxOptions() {
          return {
            selectBoxId: 'select-' + $ctrl.field.id,
            widgetConfig: {
              autoClose: false,
              clearButton: false,
              dataSource: new kendo.data.DataSource(),
              dataTextField: "text",
              dataValueField: "id",
              downArrow: true,
              itemTemplate: kendo.template($('#optionTemplate').html()),
              placeholder: $filter('translate')('filter.filtersModal.searchPlaceholder'),
              tagTemplate: kendo.template($('#selectedOptionTemplate').html()),
              dataBound: function (e) {
                setSelectedElement(e.sender);
              },
              deselect: function (e) {
                if ($ctrl.saving) {
                  e.preventDefault();
                  return;
                }
                e.dataItem.selected = !e.dataItem.selected;
                setSelectedElement(e.sender);
              },
              select: function (e) {
                if ($ctrl.saving) {
                  e.preventDefault();
                  return;
                }
                e.dataItem.selected = !e.dataItem.selected;
                setSelectedElement(e.sender);
              },
              change: function (e) {
                setSelectedElement($ctrl.selectOptions.selectBoxElement.data('kendoMultiSelect'));
              },
              height: 160
            },
            templateScope: {
              variables: {},
              callbacks: {}
            }
          }
        }

        function optionChange(e) {
          $ctrl.logicForm.$setDirty();
          $ctrl.saveCondition();
        }

        /**
         * event handler when rule are invalid
         * @type {*|(function())}
         */
        updateInvalidRuleEventHandler = $rootScope.$on('STEP_RULES:INVALID', function () {
          $ctrl.logicFormValid = isLogicFormValid();
        });
      }
    });
})();

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.steps.service.FieldService
   * @module tallyfy.steps
   *
   * @description
   * A service for capture fields
   * 
  * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.steps')
    .service('FieldService', FieldService);

  /*@ngInject*/
  function FieldService(_, $rootScope, $log, $http, Helper, $filter, moment, SHORT_TEXT_VALUE_TYPE, CONST, DateUtils) {
    /**
     * store FieldService context in self
     * JavaScript has block level context So it can be changed in nested 
     * function
     */
    var self = this,
      captureFields;

    /**
     * Expose the callable members of the service
     */
    self.loadCaptureFields = loadCaptureFields;
    self.getFields = getFields;
    self.getFieldByType = getFieldByType;
    self.validateFields = validateFields;
    self.validateCaptures = validateCaptures;
    self.getFieldValue = getFieldValue;
    self.getDateInPayloadFormat = getDateInPayloadFormat;
    self.getDateInDatepickerDisplayFormat = getDateInDatepickerDisplayFormat;
    self.isTableEmpty = isTableEmpty;
    self.isNumericShortTextValue = isNumericShortTextValue;
    self.getShortTextValidations = getShortTextValidations;

    //load captureField at very first
    loadCaptureFields();

    /**
     * @name loadCaptureFields
     * @description Fetch capture fields from json and store in local variable captureFields for getter/setter
     * @returns void
     */
    function loadCaptureFields() {
      $http({
        method: 'GET',
        url: '/resources/captureFields.json'
      }).then(function (response) {
        captureFields = _.get(response, 'data.data', []);
      }).catch(function () {
        $log.error('Unable to load capture fields');
      });
    }

    /**
    * @name getFields
    * @description getter to return capture fields
    * @returns array
    */
    function getFields() {
      return captureFields;
    }

    /**
     * @name getFieldByType
     * @description get capture field by its field_type property
     * @param  type {string} field_type
     * @returns object
     */
    function getFieldByType(type) {
      return _.find(captureFields, { field_type: type });
    }

    /**
     * @name validateFields
     * @description validates capture fields and return error
     * @param {array} formFields 
     * 
     * @returns string
     */
    function validateFields(formFields) {
      var error = [];
      _.each(formFields, function (field) {
        var defaultField = getFieldByType(field.field_type);
        if (defaultField) {
          // Check if field title was set
          if (!field.label || (_.toLower(field.label) === _.toLower(defaultField.label))) {
            error[0] = $filter('translate')('validation.required', { field: 'Field title' });
          }
        }
        // Check if field that require options have at least one option
        if (field.field_type === 'radio' ||
          field.field_type === 'dropdown' ||
          field.field_type === 'multiselect') {
          if (field.options.length === 0 || _.filter(field.options, function (option) { return _.isEmpty(option.text); }).length > 0) {
            error[1] = $filter('translate')('validation.no_option');
          }
        }
      });
      return error;
    }

    /**
     * @function
     * @private
     * @name validateCaptures
     * @param {Object} field
     * @description Validate the form field 
     */
    function validateCaptures(field) {
      var flag = false;
      if (_.isEmpty(field.label)) {
        field.isExpanded = true;
        flag = true;
      }
      if ((field.field_type === 'table') && field.label && _.isEmpty(_.get(field, 'columns[0].label', ''))) {
        field.isExpanded = true;
        flag = true;
      }

      if (field.field_type === 'dropdown' || field.field_type === 'multiselect' || field.field_type === 'radio') {
        if (_.isEmpty(_.get(field, 'options[0].text', '')) || (((_.get(field, 'options', []).length > 1) || field.isNew) && _.isEmpty(_.get(field, 'options[1].text', '')))) {
          field.isExpanded = true;
          flag = true;
        }
      }
      return flag;
    }

    /**
    * @ngdoc method
    * @name getFieldValue
    * @description extract field value from field object based on field type
    * @param {Object} field
    * @returns string/object/array
    */
    function getFieldValue(field, isNewField) {
      var value = '';
      switch (field.field_type) {
        case 'radio':
          value = _.get(field, 'value.text', '');
          break;
        case 'date':
          if (isNewField) {
            value = field.value ? DateUtils.toUTC(field.value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '';
          } else {
            var timezone = _.get($rootScope, 'identity.timezone');
            if(!timezone) {
              timezone = null;
            }
            var timeoffset = 0;
            if(timezone) {
              timeoffset = moment().tz(timezone).utcOffset() * -1;
            }
            if(field.value) {
              value = DateUtils.toUTC(moment(field.value).add(timeoffset, 'minutes')).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            } else {
              value = '';
            }
          }
          break;
        case 'table':
          value = field.value || [];
          break;
        case 'textarea':
          if (field.use_wysiwyg_editor) {
            value = getWysiwygFieldValue(field);
            return value || null;
          } else {
            value = field.value || null;
          }
          break;
        default:
          value = field.value || null;
          break;
      }
      return value;
    }

    function getWysiwygFieldValue(field) {
      var tempEl = angular.element('<div>').html(field.value);
      var strippedElements = tempEl[0].querySelectorAll(['editor-form-field', 'editor-snippet', 'editor-blueprint', 'editor-variable', 'editor-pasted-url']);
      for (var i = 0; i < strippedElements.length; i++) {
        strippedElements[i].innerHTML = '';
      }
      return tempEl.html();
    }

    /**
     * @ngdoc method
     * @name getDateInPayloadFormat
     * @description return date in a payload format for the datepicker field
     * @param {Object} date
     * @returns date object
     */
    function getDateInPayloadFormat(date) {
      return moment(moment(date, 'YYYY-MM-DD hh:mm').toDate()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }

    /**
     * @ngdoc method
     * @name getDateInDatepickerDisplayFormat
     * @description return date in a display purpose format for the datepicker field
     * @param {Object} date
     * @returns date object
     */
    function getDateInDatepickerDisplayFormat(date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').toDate();
    }

    function isTableEmpty(prerun, forceValueCheck) {
      var result = true;
      for (var i = 0; i < _.get(prerun, 'value', []).length; i++) {
        var value = [];
        if (forceValueCheck) {
          var value = _.filter(_.get(prerun.value, '[' + i + '].value') || _.get(prerun, 'value[' + i + ']', []), function (v) {
            return !Helper.isObjectEmpty(v);
          });
        } else {
          var value = _.get(prerun.value, '[' + i + '].value') || _.get(prerun, 'value[' + i + ']', []);
        }
        if (value.length) {
          result = false;
          return false;
        }
      }
      return result;
    }

    function isNumericShortTextValue(field) {
      if (!field || !fieldTextType) {
        return false;
      }
      if (field.field_type !== 'text') {
        return false;
      }
      return _.includes(field.field_validation, SHORT_TEXT_VALUE_TYPE.NUMERIC) || _.includes(field.field_validation, SHORT_TEXT_VALUE_TYPE.INTEGER);
    }

    function getShortTextValidations() {
      return [{
        value: '',
        label: $filter('translate')('steps.captures.form.label.validation.noValidation')
      }, {
        value: SHORT_TEXT_VALUE_TYPE.NUMERIC,
        label: $filter('translate')('steps.captures.form.label.validation.enforceNumberOnly'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (isNaN(field.value)) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.fieldNumberOnly')
            };
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          } if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (Number(field.value) >= Number(min) && Number(field.value) <= Number(max)) {
              return {
                isValid: true
              }
            } else {
              return {
                isValid: false,
                message: $filter('translate')('steps.captures.form.label.validation.minMaxMessageNumber', { min: min, max: max })
              }
            }
          }
        }
      }, {
        value: SHORT_TEXT_VALUE_TYPE.EMAIL,
        label: $filter('translate')('steps.captures.form.label.validation.enforceEmail'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (!CONST.EMAIL_REGEX.test(field.value)) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.invalidEmail')
            }
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          } if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (field.value.length >= Number(min) && field.value.length <= Number(max)) {
              return {
                isValid: true
              }
            } else {
              return {
                isValid: false,
                message: $filter('translate')('steps.captures.form.label.validation.minMaxMessage', { min: min, max: max })
              }
            }
          }
        }
      }, {
        value: SHORT_TEXT_VALUE_TYPE.URL,
        label: $filter('translate')('steps.captures.form.label.validation.enforceUrl'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (!CONST.URL_REGEX.test(field.value)) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.invalidUrl')
            }
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          }
          if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (field.value.length >= Number(min) && field.value.length <= Number(max)) {
              return {
                isValid: true
              }
            } else {
              return {
                isValid: false,
                message: $filter('translate')('steps.captures.form.label.validation.minMaxMessage', { min: min, max: max })
              }
            }
          }
        }
      }, {
        value: SHORT_TEXT_VALUE_TYPE.INTEGER,
        label: $filter('translate')('steps.captures.form.label.validation.enforceInteger'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (isNaN(field.value) || !Number.isInteger(Number(field.value))) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.fieldIntegerOnly')
            };
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          }
          if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (Number(field.value) >= Number(min) && Number(field.value) <= Number(max)) {
              return {
                isValid: true
              }
            } else {
              return {
                isValid: false,
                message: $filter('translate')('steps.captures.form.label.validation.minMaxMessageNumber', { min: min, max: max })
              }
            }
          }
        }
      }, {
        value: SHORT_TEXT_VALUE_TYPE.STRING,
        label: $filter('translate')('steps.captures.form.label.validation.enforceAlphabetical'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (!field.value.match(/[\p{Letter}\p{Mark}]+/gu) || field.value.match(/\s/) || field.value.match(/\d/) || field.value.match(/[$&+,:;=?@#|'<>.^*()%!-]/)) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.fieldAphabeticalOnly')
            };
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          }
          if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (field.value.length >= Number(min) && field.value.length <= Number(max)) {
              return {
                isValid: true
              }
            } else {
              return {
                isValid: false,
                message: $filter('translate')('steps.captures.form.label.validation.minMaxMessage', { min: min, max: max })
              }
            }
          }
        }
      }, {
        value: SHORT_TEXT_VALUE_TYPE.ALPHA_NUM,
        label: $filter('translate')('steps.captures.form.label.validation.enforceAlphabeticalNumeric'),
        getValidationInfo: function (field) {
          if (Helper.isObjectEmpty(field.value)) {
            return {
              isValid: true,
              message: $filter('translate')('steps.captures.form.label.validation.emptyValue')
            };
          }
          if (!field.value.match(/[\p{Letter}\p{Mark}]+/gu) || field.value.match(/\s/) || field.value.match(/[$&+,:;=?@#|'<>.^*()%!-]/)) {
            return {
              isValid: false,
              message: $filter('translate')('steps.captures.form.label.validation.fieldAphabeticalNumericOnly')
            };
          }
          var min, max;
          if (field.field_validation.length && field.field_validation.length > 1) {
            min = field.field_validation[1].split(':')[1];
            max = field.field_validation[2].split(':')[1];
          }
          if (_.isNull(min) || _.isNull(max) || _.isUndefined(min) || _.isUndefined(max)) {
            return {
              isValid: true
            }
          } else {
            if (Number(field.value)) {
              if (Number(field.value) >= Number(min) && Number(field.value) <= Number(max)) {
                return {
                  isValid: true
                }
              } else {
                return {
                  isValid: false,
                  message: $filter('translate')('steps.captures.form.label.validation.minMaxMessage', { min: min, max: max })
                }
              }
            } else {
              if (field.value.length >= Number(min) && field.value.length <= Number(max)) {
                return {
                  isValid: true
                }
              } else {
                return {
                  isValid: false,
                  message: $filter('translate')('steps.captures.form.label.validation.minMaxMessage', { min: min, max: max })
                }
              }
            }
          }
        }
      }]
    }
  }
})();

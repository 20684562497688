(function () {
  'use strict';
  angular.module('tallyfy')
    .component('newRoleModal', {
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      templateUrl: 'app/modules/organizations/orgRoles/new-role-modal/new-role-modal.component.html',
      controller:
        /*@ngInject*/
        function (_, blockUI, StepService) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('new-role');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.saveRole = saveRole;
          $ctrl.cancel = cancel;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function saveRole() {
            blockUI.start();
            StepService.createRoles({ title: $ctrl.roleName })
              .then(function (response) {
                $ctrl.close({
                  $value: response.data
                });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
          }

          function cancel() {
            $ctrl.close();
          }
        }
    });
})();
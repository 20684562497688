/**
 * @ngdoc Component
 * @name tallyfy.steps.component.renderField
 * @module tallyfy.steps
 *
 * @description
 * A component to render any capture field
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('guestRenderField', {
      bindings: {
        field: '=',
        fieldType: '@',
        fieldValue: '<',
        orgId: '<',
        code: '<',
        validationErrors: '<',
        onSaving: '<',
        isCompletableTask: '<',
        hideNoInfoData: '<'
      },
      require: {
        renderFormCtrl: '^guestRenderForm'
      },
      template: '<div ng-include="$ctrl.templateUrl" data-ng-if="$ctrl.fieldType!==\'date\'"></div><render-guest-date data-field="$ctrl.field" data-field-value="$ctrl.fieldValue" data-ng-if="$ctrl.fieldType===\'date\'" validation-errors="$ctrl.validationErrors"></render-guest-date>',
      controller:
        /*@ngInject*/
        function (_, CONST, Growl, Upload, ENV_CONFIG, $log, FilesService, $rootScope, blockUI, $filter, Helper, $timeout, $q, $confirm) {
          var $ctrl = this, uploadedFileCount,
            multipleChoice = ['radio', 'dropdown', 'multiselect', 'checkbox', 'select'],
            blockUI = blockUI.instances.get('tasks'),
            growl = new Growl('uploadFile'),
            FULL_URL_REGEX = CONST.FULL_URL_REGEX,
            idleTime;
          /**
          * component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.updateChange = updateChange;
          $ctrl.deleteAsset = deleteAsset;
          $ctrl.uploadFiles = uploadFiles;
          $ctrl.getFieldValues = getFieldValues;
          $ctrl.updateDropDownField = updateDropDownField;
          $ctrl.isDisableField = isDisableField;
          $ctrl.getFileLocation = getFileLocation;
          $ctrl.getValue = getValue;
          $ctrl.downloadAsset = Helper.downloadAsset;

          /**
           * public properties
           */
          $ctrl.env_config = ENV_CONFIG;
          $ctrl.fileProgress = 0;
          $ctrl.isFileLoading = false;
          $ctrl.isImage = Helper.isImage;

          $ctrl.uploadingTranslateValue = {
            file_count: 0
          };
          $ctrl.options = [];
          $ctrl.successFiles = [];
          $ctrl.allowedFileTypes = CONST.STEP_ALLOWED_FILE_TYPES.join(',');
          $ctrl.allowedFileNames = CONST.STEP_ALLOWED_FILE_TYPES.join(', ');

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isDisabled = $ctrl.renderFormCtrl.isDisabled;
            $ctrl.task = $ctrl.renderFormCtrl.task;

            if ($ctrl.fieldType === 'multiselect') {
              $ctrl.field.value = $ctrl.fieldValue || [];
              $ctrl.field.value = _.filter($ctrl.field.value, function (field_option) {
                return (field_option.selected === true);
              });
              _.each($ctrl.field.value, function (option) {
                var idx = _.findIndex($ctrl.field.options, { id: option.id });
                angular.extend($ctrl.field.options[idx], { selected: true });
              });
            } else if ($ctrl.fieldType === 'radio') {
              var selectedOption = _.find($ctrl.field.options, function (option) {
                return _.toLower(_.trim(option.text)) === _.toLower(_.trim($ctrl.fieldValue));
              });
              $ctrl.field.value = selectedOption || '';
            } else {
              $ctrl.field.value = $ctrl.fieldValue || '';
            }
            $ctrl.previousValue = angular.copy($ctrl.field.value);
            if ($ctrl.fieldType === 'text' || $ctrl.fieldType === 'textarea') {
              showTextField();
            }
            $ctrl.hasAuthority = true;
            getBlobImageFiles($ctrl.field.value);
          }

          function getBlobImageFiles(fieldValues) {
            for (var i = 0; i < fieldValues.length; i++) {
              var isImageFile = $ctrl.isImage(fieldValues[i].source === 'url' ? fieldValues[i].url : fieldValues[i].filename) && !fieldValues[i].isTemp;
              if (isImageFile && fieldValues[i].source !== 'url') {
                $ctrl.downloadAsset(fieldValues[i], false, $ctrl.images, $ctrl.org.id, $ctrl.isGuest);
              }
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.fieldType && bindings.fieldType.isFirstChange()) {
              var fieldType = bindings.fieldType.currentValue.toLowerCase();
              $ctrl.isMultipleChoice = _.indexOf(multipleChoice, fieldType) >= 0;
              if (fieldType === 'textfield') {
                fieldType = 'text';
              }

              $ctrl.templateUrl = 'app/modules/steps/capture/fields/' + fieldType + '/render.html';
              if (fieldType === 'file') {
                $ctrl.templateUrl = 'app/modules/tasks/guestTask/form/fields/' + fieldType + '/render.html';
              }
            }
            if (bindings.fieldValue && !bindings.fieldValue.isFirstChange()) {
              initialization();
            }
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            if (idleTime) $timeout.cancel(idleTime);
            unregisterTaskStatusListener();
          }

          function updateChange(option) {
            if (option) {
              updateMultipleCheckboxOptions(option);
            }

            if ($ctrl.previousValue === $ctrl.field.value) {
              return;
            }

            if ($ctrl.field.required && ((_.isArray($ctrl.field.value) && $ctrl.field.value.length < 1) || !$ctrl.field.value)) {
              $ctrl.validationErrors[$ctrl.field.id] = $ctrl.field.field_type === 'multiselect' ? $filter('translate')('steps.messages.requiredMultipleField') : $filter('translate')('steps.messages.requiredField');
              return;
            }
            showTextField();
            $ctrl.previousValue = angular.copy($ctrl.field.value);
            $ctrl.renderFormCtrl.updateFieldValue($ctrl.field);
          }

          function uploadFiles(files, file, newFiles, duplicateFiles, invalidFiles) {
            uploadedFileCount = 0;
            $ctrl.successFiles = [];
            $ctrl.uploadingTranslateValue.file_count = files.length;
            $ctrl.fileProgress = 0;
            if (invalidFiles.length) {
              var invalidFileNames = _.map(invalidFiles, function (invalidFile) {
                return invalidFile.name;
              }).join(', ');
              var errorMsg = "<div class='p-r'>" + $filter('translate')('tasks.captures.invalid_files', { fileNames: invalidFileNames, allowedFileTypes: $ctrl.allowedFileNames }) + "</div>";
              growl.error(errorMsg, { referenceId: 'uploadFile' + $ctrl.field.id, disableIcons: true });
              removeTempFiles();
              return;
            }
            if (files && _.isArray(files) && files.length === 0) {
              return;
            }
            addTempFiles(files);
            $q.all(files.forEach(function (file) {
              saveFile(file);
            })).then(function () {
              $log.info("File uploaded");
            }, function () {
              $log.info("File could not be uploaded");
            });
          }

          function saveFile(file) {
            var data = {
              name: file
            };

            setUploadStatus(true);
            Upload.upload({
              url: ENV_CONFIG.API_HOST + '/organizations/' + $ctrl.orgId + '/guests/tasks/' + $ctrl.code + '/file',
              data: data
            }).then(function (resp) {
              if (resp.data) {
                $ctrl.successFiles.push(resp.data);
                $timeout(function () {
                  growl.success($filter('translate')('tasks.captures.success', { fileName: file.name }), { referenceId: 'uploadFile', disableIcons: true });
                  isLastFile();
                }, 950);
              }
              blockUI.stop();
            }, function (resp) {
              isLastFile();
              var errorMsg;
              if (resp.status === 413) {
                errorMsg = $filter('translate')('tasks.captures.exceed_size', { fileName: file.name });
              } else if (resp.status === 406) {
                errorMsg = '<ul><li>' + Object.keys(resp.data).map(function (x) {
                  return resp.data[x];
                }).join('</li><li>') + '</li></ul>';
              } else {
                errorMsg = $filter('translate')('tasks.captures.server_error', { fileName: file.name });
              }
              $timeout(function () {
                growl.error(errorMsg, { referenceId: 'uploadFile', disableIcons: true });
              }, 950);
            }, function (evt) {
              $ctrl.fileProgress = parseInt(100.0 * evt.loaded / evt.total);
            });
          }

          function addTempFiles(files) {
            _.forEach(files, function (file) {
              var f = {
                isTemp: true,
                filename: file.name,
                id: _.now(),
                url: "",
                version: ""
              };
              if (!_.isArray($ctrl.field.value)) {
                $ctrl.field.value = [];
              }
              $ctrl.field.value.unshift(f);
            })
          }

          function removeTempFiles() {
            $ctrl.field.value = _.filter($ctrl.field.value, function (f) {
              return !f.isTemp
            })
          }

          function saveUploadFieldData() {
            $ctrl.files = null;
            var fieldValue = angular.copy($ctrl.field.value);

            _.forEach($ctrl.successFiles, function (asset) {
              fieldValue = _.union(fieldValue, [asset.data], 'id');
              $log.warn('File Field Values', fieldValue);
            });

            for (var i = 0; i < fieldValue.length; i++) {
              if (fieldValue[i] && fieldValue[i].hasOwnProperty('id')) {
                var link = '/organizations/' + $ctrl.orgId + '/guests/tasks/' + $ctrl.code + '/file/' + fieldValue[i].id + '?download=1';
                fieldValue[i]['url'] = link;
              }
            }

            $ctrl.field.value = fieldValue || [];
            updateChange();
          }

          /**
           * @ngMethod
           * @name updateMultipleCheckboxOptions
           * @description add/delete selected/unselected option
           * @param {any} option 
           */
          function isLastFile() {
            if (++uploadedFileCount === $ctrl.uploadingTranslateValue.file_count) {
              removeTempFiles();
              saveUploadFieldData();
              setUploadStatus(false);
            }
          }

          function deleteAsset(file) {
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', { actionName: 'Delete', componentName: 'File' }),
              'body': $filter('translate')('global.confirmAction.body', { actionName: 'delete', componentName: file.filename }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Delete File' }),
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              blockUI.start();
              if (file.source === 'url') {
                emitEvent(file);
                $timeout(function () {
                  growl.success($filter('translate')('tasks.captures.delete.success'), {
                    referenceId: 'uploadFile',
                    disableIcons: true
                  });
                  blockUI.stop();
                }, 500);
              } else {
                FilesService.delete({ org: $ctrl.orgId, id: _.get(file, 'id') }).then(function (response) {
                  $log.info('File is deleted', response);
                  emitEvent(file);
                  blockUI.stop();
                  growl.success($filter('translate')('tasks.captures.delete.success'), { referenceId: 'uploadFile', disableIcons: true });
                }, function (error) {
                  if (error.status === 404) {
                    emitEvent(file);
                  }
                  $log.error('Unable to delete file', error);
                  blockUI.stop();
                  growl.error($filter('translate')('tasks.captures.delete.error'), { referenceId: 'uploadFile', disableIcons: true });
                });
              }
            }, function (error) {
              blockUI.stop();
            });
          }

          /**
           * @description 
           * @param {any} status 
           */
          function setUploadStatus(status) {
            $ctrl.isFileLoading = status;
            if ($ctrl.task) {
              $ctrl.task.isFileLoading = status;
            }
          }

          /**
           * @ngMethod
           * @name updateMultipleCheckboxOptions
           * @description add/delete selected/unselected option
           * @param {any} option 
           */
          function updateMultipleCheckboxOptions(option) {
            if (option.selected) {
              $ctrl.field.value.push(option);
            } else {
              var idx = _.findIndex($ctrl.field.value, { id: option.id });
              $ctrl.field.value.splice(idx, 1);
            }
          }

          function emitEvent(file) {
            $rootScope.$emit('GUEST_TASK:DELETE_FILE', {
              file: file,
              field: $ctrl.field
            });
          }

          /**
           * @ngMethod
           * @name getFieldValues
           * @description Return string as a clickable link if match with regex.
           * @returns {String} String
          */
          function getFieldValues() {
            var fieldValue = $ctrl.field.value;

            if (_.trim(fieldValue).length === 0) {
              fieldValue = $ctrl.field.label;
            }

            return fieldValue.replace(FULL_URL_REGEX, function (url) {
              return '<a class="t-mossy-green" target="_blank" href="' + url + '">' + url + '</a>';
            });
          }

          /**
           * @ngMethod
           * @name showTextField
           * @description 
           * Remove require field validation if field value not empty
           * @returns {String} String
          */
          function showTextField() {
            $ctrl.showTextField = true;
            $ctrl.showTextArea = true;

            if ($ctrl.field.value && $ctrl.validationErrors[$ctrl.field.id]) {
              delete $ctrl.validationErrors[$ctrl.field.id];
            }
          }

          /**
           * @ngMethod
           * @name updateDropDownField
           * @description Update dropdown field value
           * @param {Object} option
           */
          function updateDropDownField(e, option) {
            e.preventDefault();
            if (_.get(option, 'id') === _.get($ctrl.previousValue, 'id')) {
              return;
            }
            if ($ctrl.field.required && !$ctrl.field.value && !option) {
              $ctrl.validationErrors[$ctrl.field.id] = $filter('translate')('steps.messages.requiredField');
              return;
            }
            $ctrl.field.value = option ? option : "";
            showTextField();
            updateTaskFormField();
          }

          /**
           * @ngMethod
           * @name updateTaskFormField
           * @description Update task form field value
           */
          function updateTaskFormField() {
            $ctrl.renderFormCtrl.updateFieldValue($ctrl.field);
            $ctrl.previousValue = $ctrl.field.value;
          }

          var unregisterTaskStatusListener = $rootScope.$on('TASK:UPDATE_TASK_STATUS', function (event, data) {
            if (data.taskId === $ctrl.task.id) {
              $ctrl.isDisabled = data.isCompleted;
              if ($ctrl.fieldType === 'text' || $ctrl.fieldType === 'textarea') {
                showTextField();
              }
            }
          });

          /**
           * @ngMethod
           * @name isDisableField
           * @description disable form field 
           * @public
           * @returns {boolean}
           */
          function isDisableField() {
            return $ctrl.isDisabled || !$ctrl.isCompletableTask;
          }

          /**
           * @ngmethod
           * @name getFileLocation
           * @param {*} file 
           * 
           * @description
           * get file location
           */
          function getFileLocation(file) {
            return file.source === 'local' ? $ctrl.env_config.FILES_HOST + '/organizations/' + $ctrl.orgId + '/file/' + file.id + '/dl' : file.url;
          }

          /**
           * @ngmethod
           * @name getValue
           * @param {*} fieldType
           * 
           * @description
           * get empty field value 
           */
          function getValue(fieldType) {
            var value = '';
            if (fieldType === 'dropdown') {
              value = !$ctrl.isDisableField() ? $ctrl.field.value.text || $filter('translate')('steps.captures.select') : $ctrl.field.value.text || ($ctrl.hideNoInfoData ? '' : $filter('translate')('steps.captures.form.emptyValue.multiSelectField'));
            }
            return value;
          }
          //controller ends
        }
    });
})();

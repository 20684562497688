/**
 * @ngdoc component
 * @name tallyfy.compactProcessDescriptionDetail
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactProcessDescriptionDetail', {
      templateUrl: 'app/components/compact/components/processDescriptionStepDetail/compact-process-description-step-detail.component.html',
      bindings: {
        process: '<',
        form: '=',
        isPublic: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $q, ProcessService, Helper, BLUEPRINT_TYPE, $timeout, DESCRIPTIONSIZE, CompactStepService, COMMON) {
          // private properties
          var $ctrl = this,
            oldProcess, timeoutHandler;

          // angular life cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.maxLength = DESCRIPTIONSIZE.maxTitle;
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;
          
          // public methods
          $ctrl.onDiscardDescription = onDiscardDescription;
          $ctrl.onDescriptionSave = onDescriptionSave;
          $ctrl.onPaneClose = onPaneClose;

          /**
           * @ngdoc methods
           * @name onInit
           * 
           * @description
           * angular life cycle hook on component init
           */
          function onInit() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              autofocus: false,
              placeholder: $filter('translate')('steps.bpeDescStep.editorPlaceholder'),
              currentUser: $rootScope.identity,
              heightMin: 240,
              heightMax: 450,
              type: 'blueprint',
              entityType: 'template',
              enableFullscreen: true
            }, true, CompactStepService.getBlueprintFroalaToolbar());
            $ctrl.froalaOptions.skipBlueprintId = $ctrl.process.id;
            oldProcess = angular.copy($ctrl.process);
            timeoutHandler = $timeout(function () {
              if (_.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT) {
                $ctrl.froalaOptions.froalaEditor.$el.focus();
              }
            }, 350);
          }

          function onChanges() { }

          function onDestroy() { }

          /**
           * @ngdoc methods
           * @name onDiscardDescription
           * 
           * @description
           * on description discard
           */
          function onDiscardDescription() {
            angular.extend($ctrl.process, oldProcess);
            $ctrl.form.$setPristine();
          }

          /**
           * @ngdoc methods
           * @name onDescriptionSave
           * 
           * @description
           * on description save method handler
           */
          function onDescriptionSave() {
            $ctrl.onSaving = true;
            if (ProcessService.isDescriptionEmptyForHuman($ctrl.process.summary)) {
              $ctrl.process.summary = "";
            }
            onSaveChanges().then(function (response) {
              $ctrl.process.last_updated = _.get(response, 'data.last_updated');
              $ctrl.form ? $ctrl.form.$setPristine() : angular.noop();
              !$ctrl.process.summary ? $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true }) : angular.noop();
              oldProcess = angular.copy($ctrl.process);
              $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name onSaveChanges
           * 
           * @description
           * process save method
           */
          function onSaveChanges() {
            var deferred = $q.defer();
            ProcessService.update({
              id: $ctrl.process.id
            }, $ctrl.process).then(function (response) {
              deferred.resolve(response);
            }, function (error) {
              deferred.reject(error);
            });
            return deferred.promise;
          }
          
          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e 
           * 
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            $rootScope.$emit('RIGHT_PANE:CLOSE', {
              event: e
            });
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy.list')
    .component('editColumnModal', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/components/list/list-detail/edit-column-modal/edit-column-modal.component.html',
      controller: editColumnModal
    });

  /*@ngInject*/
  function editColumnModal(_, $scope) {
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onDestroy = onDestroy;

    $ctrl.cancel = cancel;
    $ctrl.save = save;

    function onInit() {
      if ($ctrl.resolve.action === 'edit') {
        $scope.$applyAsync(function () {
          $ctrl.formColumnHeader = {
            title: { value: $ctrl.resolve.title },
          }
        }, 0);
      }
    }

    function onChanges() { }

    function onDestroy() { }

    function save() {
      if ($ctrl.resolve.action === 'add') {
        $ctrl.close({
          $value: {
            newColumn: $ctrl.formColumnHeader.title.value
          }
        })
      } else {
        $ctrl.close({
          $value: {
            oldValue: $ctrl.resolve.title,
            newValue: $ctrl.formColumnHeader.title.value
          }
        })
      }
    }

    function cancel() {
      $ctrl.dismiss();
    }

  }

})();
(function () {
  'use strict';

  angular
    .module('tallyfy.securityAndLog')
    .service('SecurityAndLogService', securityAndLogService);

  /*@ngInject*/
  function securityAndLogService(SecurityAndLogRepository) {
    var self = this;

    /**
     * @ngdoc method
     * @name getEmailLogs
     * 
     * @description
     * get all email logs
     */
    self.getEmailLogs = function (params) {
      return SecurityAndLogRepository.get(params);
    }

    /**
     * @ngdoc method
     * @name getEmailContent
     * 
     * @description
     * get email content
     */
    self.getEmailContent = function (args) {
      return SecurityAndLogRepository.getEmailContent(args);
    }

    /**
     * @ngdoc method
     * @name getLastEmailStatus
     * 
     * @description
     * get last email status
     */
    self.getLastEmailStatus = function (args) {
      return SecurityAndLogRepository.getLastEmailStatus(args);
    }
  }
})();
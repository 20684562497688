/**
 * @ngdoc Component
 * @name tallyfy.process.component.importBluePrintModal
 * @module tallyfy.process
 *
 * @description
 * A component to open import blue print modal
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('importBluePrintModal', {
      templateUrl: 'app/modules/process/components/blueprint-import/import-blueprint-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (ProcessRepository, $rootScope) {
          var $ctrl = this;

          /**
           * public properties
           */
          $ctrl.onSaving = false;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.importBP = importBP;
          $ctrl.cancel = cancel;

          /**
           * @ngdoc method
           * @name onInit
           * @returns void
           * @description initalization component
           */
          function onInit() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @returns void
           * @description on destroy component
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name onChanges
           * @returns void
           * @description on component get changes
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name import blueprint
           * @returns void
           * @description import process
           */
          function importBP() {
            $ctrl.onSaving = true;
            ProcessRepository
              .importTemplate({
                title: "",
                id: $ctrl.bluePrintId,
                tenant: $ctrl.organizationId
              })
              .then(function (res) {
                $ctrl.onSaving = false;
                $ctrl.close({ $value: res.data });
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          /**
           * @ngdoc method
           * @name cancel
           * @returns void
           * @description on click of cancel button modal get dismiss
           */
          function cancel() {
            $ctrl.dismiss({ $value: 'dismissed' });
          }
        }
    });
})();

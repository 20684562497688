/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.storage
 * @module tallyfy.integration
 *
 * @description
 * A component to manage integration
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .component('storage', {
      bindings: {
        type: '<',
        index: "<"
      },
      templateUrl: 'app/modules/integration/storage/storage.html',
      controller:
        /*@ngInject*/
        function StorageController(_, IntegrationService, Growl, blockUI, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('storageIntegration');
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          /**
          * Expose bindable methods
          * these methods are accessible in view
          */

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
          }
        }
    });
})();
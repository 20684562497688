/**
 * @ngdoc Directive
 * @name tallyfy.tfyConfetti
 * @restrict 'A'
 * @element ANY
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
    'use strict';
    angular
      .module('tallyfy')
      .directive('tfyConfetti', tfyConfetti);
    /*@ngInject*/
    function tfyConfetti() {
        return {
          restrict: 'A',
          replace: true,
          scope: {
            offset: "@offset",
            isConfettiStart: '=',
            isActiveProcess: '=',
            confettiOptions: '='
          },
          link: function(scope) {
            angular.extend(scope.confettiOptions, {
              confettiStart: function () {
                for (var i = 0; i < 120; i++) {
                  createConfetti(i);
                }
              },
              confettiStop: function () {
                removeConfetti();
              }
            });

            /**
             * create confetti
             */
            function createConfetti(i) {
              var width = Math.random() * 10;
              var height = width * 0.4;
              var colourIdx = Math.ceil(Math.random() * 3);
              var colour = "";
              switch (colourIdx) {
                case 1:
                  colour = "yellow";
                  break;
                case 2:
                  colour = "green";
                  break;
                default:
                  colour = "orange";
              }
              $('<div id="confetti" class="confetti-' + i + ' ' + colour + '"></div>').css({
                "width": width + "px",
                "height": height + "px",
                "top": -Math.random() * 20 + "%",
                "left": Math.random() * 100 + "%",
                "opacity": Math.random() + 0.5,
                "transform": "rotate(" + Math.random() * 360 + "deg)"
              }).appendTo('body');
              dropConfetti(i);
            }

            /**
             * remove confetti
             */
            function removeConfetti() {
              var confetti = document.getElementById("confetti");
              if (confetti) {
                $("div[id=confetti]").remove();
              }
            }

            /**
             * drop confetti
             */
            function dropConfetti(x) {
              $('.confetti-' + x).animate({
                top: "100%",
                left: "+=" + Math.random() * 15 + "%"
              }, Math.random() * 3000 + 3000, function () {
                resetConfetti(x);
              });
            }

            /**
             * reset confetti
             */
            function resetConfetti(x) {
              $('.confetti-' + x).animate({
                "top": -Math.random() * 20 + "%",
                "left": "-=" + Math.random() * 15 + "%"
              }, 0, function () {
                dropConfetti(x);
              });
            }
            scope.$applyAsync();
          }
        };
      }
})();

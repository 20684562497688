/**
 * @ngdoc Component
 * @name tallyfy.steps.component.createFormVideoModal
 * @module tallyfy.steps
 *
 * @description
 * A component is create form video play modal
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('createFormVideoModal', {
      templateUrl: 'app/modules/steps/createFormVideoModal/create-form-video-modal.component.html',
      bindings: {
        dismiss: '&'
      },
      controller: VideoModalController
    });
  /*@ngInject*/
  function VideoModalController(_, VIMEO, $sce) {
    var $ctrl = this;
    /**
    * public properties
    */
    $ctrl.videoPath = $sce.trustAsResourceUrl(VIMEO.ELEMENTS.FORM);
    /**
     * component's lifeCycle hooks 
     */
    $ctrl.$onInit = initialization;
    $ctrl.$onDestroy = onDestroy;
    $ctrl.$onChanges = onChanges;
    $ctrl.closeVideoModal = closeVideoModal;
    /**
    * @function
    * @name initialization
    * @description
    * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
    */
    function initialization() {
    }
    /**
     * @function
     * @name onChanges
     * @description
      * A component's lifeCycle hook which is called when bindings are updated.
      */
    function onChanges() { }
    /**
    * @function
    * @name closeVideoModal
    * @description Close video modal
    */
    function closeVideoModal() {
      $ctrl.dismiss({ $value: 'cancel'});
    }
    /**
     * @function
     * @name onDestroy
     * @description
     * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
     * Usefull to release external resources, watches and event handlers.
     */
    function onDestroy() { }
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.analytics.Component.analytics
 * @module tallyfy.analytics
 *
 * @description
 * A component to manage analytics
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.analytics')
    .component('analytics', {
      templateUrl: 'app/modules/analytics/analytics.html',
      controller:
      /*@ngInject*/
      function (_, $rootScope, BeaconService) {
        var $ctrl = this;

        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        $ctrl.openBeaconModal = openBeaconModal;

        /**
         * @function $onInit
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          $ctrl.analyticsEnabled = _.get($rootScope.identity, 'organizations.analytics_enabled', false);
        }

        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        function openBeaconModal() {   
          BeaconService.open();
        }
      }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.process.component.embeddedBPModal
 * @module tallyfy.process
 *
 * @description
 * edit comments component
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com )
 */
(function () {
    'use strict';
    angular
      .module('tallyfy.process')
      .component('embeddedBPModal', {
        templateUrl: 'app/modules/process/components/readBluePrint/embeddedBPModal/embedded-bp-modal.html',
        bindings: {
          resolve: '<',
          close: '&'
        },
        require: {},
        controller:
          /*@ngInject*/
          function () {
            var $ctrl = this;
  
            /**
             * public properties
             */
  
            /**
             * public methods
             */
            $ctrl.closeModal = closeModal;

            //Close modal
            function closeModal () {
              $ctrl.close();
            }
          }
      });
  })();
  
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.renderField
 * @module tallyfy.steps
 *
 * @description
 * A component to render any capture field
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('renderField', {
      bindings: {
        field: '=',
        fieldType: '@',
        fieldValue: '<',
        org: '<',
        orgToken: '<',
        validationErrors: '<',
        onSaving: '<',
        isDisabled: '<',
        isCompletableTask: '<',
        isPrerun: '<',
        isGuest: '<',
        isOnOffTask: '<?',
        hideFieldLabel: '<?',
        hideNoInfoData: '<',
        isPublicProcess: '<',
        isApproval: '<?',
        translateEnable: '<?',
        asDefaultValue: '<',
        isNewField: '<',
        disableAutoSizeTableColumn: '<',
        autoSizeTableColumn: '<',
        process: '<?',
        isOneOfTaskForms: '<?'
      },
      require: {
        renderFormCtrl: '^renderForm'
      },
      templateUrl: 'app/modules/steps/capture/fields/render-field.component.html',
      controller:
        /*@ngInject*/
        function (_, CONST, Growl, TranslationService, Upload, ENV_CONFIG, $log, FilesService, FieldService, $rootScope, blockUI, $filter, Helper, $timeout,
          $q, $confirm, AuthServerProvider, CompactTaskService, RenderTableService, KendoUIService, COMMON, moment, FORM_FIELD, UsersService, store, OrganizationsService, DateUtils) {
          var $ctrl = this,
            uploadedFileCount,
            multipleChoice = ['radio', 'dropdown', 'multiselect', 'checkbox', 'select'],
            blockUI = blockUI.instances.get('tasks'),
            growl = new Growl('uploadFile'),
            FULL_URL_REGEX = CONST.FULL_URL_REGEX,
            idleTime,
            multiSelectTimeout;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.updateChange = updateChange;
          $ctrl.multiSelectChange = multiSelectChange;
          $ctrl.deleteAsset = deleteAsset;
          $ctrl.uploadFiles = uploadFiles;
          $ctrl.getFieldValues = getFieldValues;
          $ctrl.updateDropDownField = updateDropDownField;
          $ctrl.getFileClassName = getFileClassName;
          $ctrl.isDisableField = isDisableField;
          $ctrl.getFileLocation = getFileLocation;
          $ctrl.getValue = getValue;
          $ctrl.addFromUrl = addFromUrl;
          $ctrl.onInputKeyUp = onInputKeyUp;
          $ctrl.downloadAsset = Helper.downloadAsset;
          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getData = getData;
          $ctrl.fieldFocus = fieldFocus;
          $ctrl.getHtmlTextContent = Helper.getHtmlTextContent;
          $ctrl.clearSelection = clearSelection;
          $ctrl.checkFieldValid = checkFieldValid;
          $ctrl.closePopup = closePopup;
          $ctrl.onClickOpen = onClickOpen;
          $ctrl.updateAssigneeFormValue = updateAssigneeFormValue;
          $ctrl.addVariableValue = addVariableValue;

          /**
           * public properties
           */
          $ctrl.env_config = ENV_CONFIG;
          $ctrl.fileProgress = 0;
          $ctrl.isFileLoading = false;
          $ctrl.isImage = Helper.isImage;
          $ctrl.uploadingTranslateValue = {
            file_count: 0
          };
          $ctrl.options = [];
          $ctrl.successFiles = [];
          $ctrl.allowedFileTypes = CONST.STEP_ALLOWED_FILE_TYPES.join(',');
          $ctrl.allowedFileNames = CONST.STEP_ALLOWED_FILE_TYPES.join(', ');
          $ctrl.images = {};
          $ctrl.isResettingFroala = false;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @function
           * @name initialization
           * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.translateEnable) {
              $ctrl.translateTo = TranslationService.getMyContentLanguage();
            }
            $ctrl.isDisabled = $ctrl.renderFormCtrl.isDisabled;
            $ctrl.processView = $ctrl.renderFormCtrl.processView;
            $ctrl.task = $ctrl.renderFormCtrl.task;
            if ($ctrl.field.field_type === 'assignees_form') {
              var viewerId = $ctrl.isGuest ? _.get($rootScope.identity, 'guest.email', '') : $rootScope.identity.id;
              $ctrl.viewerId = (!viewerId && !$ctrl.isGuest) ? $rootScope.identity.id : viewerId;
              getAssigneesSources();
            }
            if (!$ctrl.fieldValue && $ctrl.field.default_value_enabled) {
              if ($ctrl.field.field_type === 'date') {
                $ctrl.fieldValue = moment().add($ctrl.field.default_value, 'days');
              } else {
                $ctrl.fieldValue = $ctrl.field.default_value;
              }
            }
            if ($ctrl.asDefaultValue) {
              $ctrl.fieldValue = $ctrl.field.default_value;
            }
            if ($ctrl.fieldType === 'multiselect') {
              if (!$ctrl.field.default_value_enabled) {
                _.each($ctrl.field.options, function (option) {
                  angular.extend(option, { selected: false });
                });
              }
              $ctrl.field.value = $ctrl.fieldValue || [];
              $ctrl.field.value = _.filter($ctrl.field.value, function (field_option) {
                return (field_option.selected === true);
              });
              _.each($ctrl.field.value, function (option) {
                var idx = _.findIndex($ctrl.field.options, { id: option.id });
                angular.extend($ctrl.field.options[idx], { selected: true });
              });
              if($ctrl.field.required && (_.isArray($ctrl.field.value) && $ctrl.field.value.length < 1) || !$ctrl.field.value) {
                $ctrl.validationErrors[$ctrl.field.id] = ($ctrl.field.settings && _.get($ctrl.field.settings, 'must_all_checked') && $ctrl.field.value.length !== $ctrl.field.options.length) ? $filter('translate')('steps.messages.requiredAllSelectedField') : (($ctrl.field.value) && ($ctrl.field.value.length < 1)) ? $filter('translate')('steps.messages.requiredMultipleField') : angular.noop();
              }
            } else if ($ctrl.fieldType === 'radio') {
              var selectedOption = _.find($ctrl.field.options, function (option) {
                return _.toLower(_.trim(option.text)) === _.toLower(_.trim($ctrl.fieldValue));
              });
              $ctrl.field.value = selectedOption || '';
            } else if ($ctrl.fieldType === 'file') {
              $ctrl.field.value = $ctrl.fieldValue || [];
            } else if ($ctrl.fieldType === 'table') {
              if ($ctrl.isPrerun) {
                if (Helper.isObjectEmpty($ctrl.fieldValue)) {
                  $ctrl.field.value = void 0;
                } else {
                  var rows = $ctrl.fieldValue;
                  $ctrl.field.value = [];
                  for (var i = 0; i < $ctrl.field.columns.length; i++) {
                    $ctrl.field.value.push(
                      _.isArray(rows[i]) ? {
                        id: $ctrl.field.columns[i].id,
                        label: $ctrl.field.columns[i].label,
                        value: rows[i]
                      } : rows[i]
                    );
                  }
                }
              } else {
                $ctrl.field.value = [];
                if (Helper.isObjectEmpty($ctrl.fieldValue)) {
                  $ctrl.field.value = _.map($ctrl.field.columns, function () { return []; });
                } else {
                  $ctrl.field.value = $ctrl.fieldValue;
                }
              }
            } else if ($ctrl.fieldType === 'assignees_form') {
              $ctrl.field.value = $ctrl.fieldValue || { users: [], groups: [], guests: [] };
            } else if (!$ctrl.process && !$ctrl.isPrerun && $ctrl.field.default_value_enabled && ($ctrl.fieldType === 'text' || $ctrl.fieldType === 'textarea')) {
              $ctrl.field.value = $ctrl.fieldValue === $ctrl.field.default_value ? $ctrl.field.replaced_default_value : $ctrl.fieldValue;
            } else {
              $ctrl.field.value = $ctrl.fieldValue || '';
            }
            $ctrl.previousValue = angular.copy($ctrl.fieldValue);
            $ctrl.checklistID = $ctrl.isPrerun ? $ctrl.field.checklist_id : _.get($ctrl.renderFormCtrl.task, 'run.data.checklist_id');
            if ($ctrl.fieldType === 'text' || $ctrl.fieldType === 'textarea') {
              hideTextField();
            }
            $ctrl.hasAuthority = Helper.checkAccessAuthority(false);
            $ctrl.currentUser = $rootScope.identity;
            getBlobImageFiles($ctrl.fieldValue || []);
            if ($ctrl.field.use_wysiwyg_editor) {
              $ctrl.froalaConfig = getFroalaConfig(true);
            }
            $ctrl.checkPreCreatedField = ($ctrl.field.label === FORM_FIELD.EMAIL_BODY_FIELD || $ctrl.field.label === FORM_FIELD.EMAIL_ATTACHMENTS_FIELD);
            $ctrl.preCreatedTextareaField = $ctrl.field.label === FORM_FIELD.EMAIL_BODY_FIELD && $ctrl.field.field_type === 'textarea';
            $ctrl.preCreatedFileField = $ctrl.field.label === FORM_FIELD.EMAIL_ATTACHMENTS_FIELD && $ctrl.field.field_type === 'file';
          }

          function getBlobImageFiles(fieldValues) {
            for (var i = 0; i < fieldValues.length; i++) {
              var source = fieldValues[i] ? (fieldValues[i].source === 'url' ? fieldValues[i].url : fieldValues[i].filename) : '';
              var isImageFile = source && $ctrl.isImage(source) && !fieldValues[i].isTemp;
              if (isImageFile && fieldValues[i].source !== 'url') {
                $ctrl.downloadAsset(fieldValues[i], false, $ctrl.images, $ctrl.org.id, $ctrl.isGuest);
              }
            }
          }

          /**
           * @function
           * @name onChanges
           * @param {Object} bindings 
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.fieldType) {
              var fieldType = bindings.fieldType.currentValue.toLowerCase();
              if (bindings.fieldType && bindings.fieldType.isFirstChange()) {
                $ctrl.isMultipleChoice = _.indexOf(multipleChoice, fieldType) >= 0;
                if (fieldType === 'textfield') {
                  fieldType = 'text';
                }
                renderFieldTemplate(fieldType);
              } else {
                renderFieldTemplate(fieldType);
              }
            }
            if (bindings.fieldValue) {
              if (!bindings.fieldValue.isFirstChange()) {
                if (!$ctrl.field.use_wysiwyg_editor) {
                  initialization();
                }
              }
            }
            if (bindings.isDisabled) {
              if (!bindings.isDisabled.isFirstChange()) {
                if (!bindings.isDisabled.currentValue) {
                  if ($ctrl.field.use_wysiwyg_editor) {
                    initialization();
                  }
                } else {
                  if ($ctrl.field.use_wysiwyg_editor) {
                    initialization();
                  }
                }
              }
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            if (multiSelectTimeout) {
              $timeout.cancel(multiSelectTimeout);
            }
          }

          function getFroalaConfig(allowEdit) {
            return Helper.getFroalaOptions({
              isGuest: $ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned),
              isPublic: $ctrl.isPublicProcess,
              initOnClick: !$ctrl.fieldValue,
              placeholder: $ctrl.field.label,
              autofocus: false,
              allowEdit: allowEdit,
              hideHtmlToggle: !allowEdit,
              currentUser: $ctrl.currentUser,
              enableFullscreen: true,
              qAutoSave: {
                callback: updateChange,
                debounce: 600
              },
              focusCallback: function (e) {
                var element = e.$el;
                element.closest('.form-group').addClass("has-success");
                $rootScope.$emit('FIELD:FOCUS-' + $ctrl.field.id, { field: $ctrl.field });
              },
              contentChangedCallback: function (e) {
                var element = e.$el;
                element.closest('.form-group').removeClass("has-success");
                $rootScope.$broadcast('FIELD:BLUR-' + $ctrl.field.id, { field: $ctrl.field });
              }
            }, true);
          }

          /**
           * @function
           * @name multiSelectChange
           * @description Update the changes in multi select field
           * @returns void
           */
          function multiSelectChange() {
            if (multiSelectTimeout) {
              $timeout.cancel(multiSelectTimeout);
            }
            CompactTaskService.isCaptureUpdateInQueue = true;
            multiSelectTimeout = $timeout(function () {
              CompactTaskService.isCaptureUpdateInQueue = false;
              prepareMultiSelectOptions();
            }, 1500);
          }

          /**
           * @function
           * @name prepareMultiSelectOptions
           * @description Prepare multi select options
           * @returns void
           */
          function prepareMultiSelectOptions() {
            var ogFieldOptions = angular.copy($ctrl.field.options), selectedFieldOptions = [];
            _.forEach(ogFieldOptions, function (optionItem) {
              if (optionItem.selected) {
                selectedFieldOptions.push(optionItem);
              }
            });
            $ctrl.field.value = selectedFieldOptions;
            updateChange();
          }

          /**
           * @function
           * @name updateChange
           * @param {*} blur
           * @param {*} option 
           * @description Update the changes in field
           * @returns void
           */
          function updateChange(blur, option) {
            if (blur) {
              $rootScope.$broadcast('FIELD:BLUR-' + $ctrl.field.id, { field: $ctrl.field });
            }
            if ($ctrl.previousValue === _.get($ctrl.field, 'value', '')) {
              return;
            }
            hideTextField(true);
            if (option && !$ctrl.field.use_wysiwyg_editor) {
              updateMultipleCheckboxOptions(option);
            }
            if ($ctrl.field.field_type === 'table') {
              var grid = $ctrl.tableOptions.tableElement.data('kendoGrid');
              if (grid) {
                setTableColumnGuids(grid.columns);
                $ctrl.field.value = mapTableValue(grid.dataSource.data());
              }
            }
            if (($ctrl.field.field_type === 'textarea' || $ctrl.field.field_type === 'text')) {
              var MAX_LIMIT = $ctrl.field.field_type === 'textarea' ? COMMON.MAX_TEXT_CHAR_LIMIT : COMMON.MAX_SHORT_TEXT_FIELD_LIMIT;
              if ($ctrl.field.value && $ctrl.field.value.length > MAX_LIMIT) {
                $ctrl.validationErrors[$ctrl.field.id] = $filter('translate')('steps.messages.textareaCharacterExceed', { maxChar: MAX_LIMIT, over: MAX_LIMIT - $ctrl.field.value.length });
              } else if ($ctrl.field.field_type === 'text') {
                var validationInfo = CompactTaskService.getShortTextValidationInfo($ctrl.field);
                if (validationInfo && !validationInfo.isValid) {
                  $ctrl.validationErrors[$ctrl.field.id] = validationInfo.message;
                }
              }
            }
            if ($ctrl.field.field_type !== 'multiselect' && ($ctrl.field.required || ($ctrl.asDefaultValue && $ctrl.field.default_value_enabled)) && (($ctrl.field.field_type === 'table' ? FieldService.isTableEmpty($ctrl.field) : (_.isArray($ctrl.field.value) && $ctrl.field.value.length < 1)) || !$ctrl.field.value)) {
              $ctrl.validationErrors[$ctrl.field.id] = $filter('translate')('steps.messages.requiredField');
            }
            if ($ctrl.field.field_type === 'multiselect' && $ctrl.field.required && ((!_.get($ctrl.field.settings, 'must_all_checked') && _.isArray($ctrl.field.value) && $ctrl.field.value.length < 1) || (_.get($ctrl.field.settings, 'must_all_checked') && $ctrl.field.value.length !== $ctrl.field.options.length))) {
              $ctrl.validationErrors[$ctrl.field.id] = ($ctrl.field.settings && _.get($ctrl.field.settings, 'must_all_checked') && $ctrl.field.value.length !== $ctrl.field.options.length) ? $filter('translate')('steps.messages.requiredAllSelectedField') : (($ctrl.field.value) && ($ctrl.field.value.length < 1)) ? $filter('translate')('steps.messages.requiredMultipleField') : angular.noop();
              return;
            }
            $ctrl.previousValue = angular.copy($ctrl.field.value);
            !$ctrl.validationErrors[$ctrl.field.id] ? $ctrl.renderFormCtrl.updateFieldValue($ctrl.field) : angular.noop();
          }

          function setTableColumnGuids(rawColumns) {
            var presentColumns = angular.copy($ctrl.field.columns) || [];
            if (rawColumns.length) {
              _.forEach(presentColumns, function (item, i) {
                presentColumns[i]._guid = rawColumns[i].field;
              });
            }
            $ctrl.field.columns = presentColumns;
          }

          function checkFieldValid(field) {
            if (field.field_type === 'multiselect' && _.get(field, 'settings.must_all_checked')) {
              var selected = _.filter(field.options, { selected: true });
              return !(field.options.length > selected.length);
            }
            return _.get($ctrl.field, 'value.length', 0) !== 0;
          }

          /**
           * @function
           * @name uploadFiles
           * @param {Object} files 
           * @param {Object} invalidFiles
           * @description Upload files
           * @returns void
           */
          function uploadFiles(files, invalidFiles) {
            uploadedFileCount = 0;
            $ctrl.successFiles = [];
            $ctrl.uploadingTranslateValue.file_count = files.length;
            $ctrl.fileProgress = 0;
            if (invalidFiles.length) {
              var invalidFileNames = _.map(invalidFiles, function (invalidFile) {
                return invalidFile.name;
              }).join(', ');
              var errorMsg = "<div class='p-r'>" + $filter('translate')('tasks.captures.invalid_files', { fileNames: invalidFileNames, allowedFileTypes: $ctrl.allowedFileNames }) + "</div>";
              growl.error(errorMsg, { referenceId: 'uploadFile' + $ctrl.field.id, disableIcons: true });
              removeTempFiles();
              return;
            }
            if (files && _.isArray(files) && files.length === 0) {
              return;
            }
            addTempFiles(files);

            $q.all(files.forEach(function (file) {
              saveFile(file);
            })).then(function () {
              $log.info("File uploaded");
            }, function () {
              $log.warn("File could not be uploaded");
            });
          }

          function addFromUrl() {
            var isValid = false;
            if (!$ctrl.urlLinkSource) {
              isValid = false;
            } else {
              var linkLength = $ctrl.urlLinkSource.split('.').length;
              if (linkLength === 1) {
                isValid = false;
              } else {
                if (!$ctrl.urlLinkSource.startsWith('https://') && !$ctrl.urlLinkSource.startsWith('http://')) {
                  $ctrl.urlLinkSource = 'https://' + $ctrl.urlLinkSource;
                }
                for (var i = 0; i < linkLength; i++) {
                  if (!$ctrl.urlLinkSource.split('.')[i]) {
                    return false;
                  }
                }
                isValid = true;
              }
            }
            if (!isValid) {
              $ctrl.validationErrors[$ctrl.field.id] = $filter('translate')('froala.linkErrorText');
              return;
            }
            var fileUrl = new URL($ctrl.urlLinkSource),
              task = _.get($ctrl, 'renderFormCtrl.task', {}),
              url = $ctrl.linkText || fileUrl.href;
            $ctrl.field.value.push({
              filename: url,
              source: 'url',
              subject: {
                id: $ctrl.isPrerun ? $ctrl.field.checklist_id : _.get(task, 'run.data.checklist_id'),
                type: $ctrl.isPrerun ? 'Checklist' : 'Run'
              },
              uploaded_from: $ctrl.isPrerun ? 'ko_field' : $ctrl.field.id,
              url: $ctrl.urlLinkSource,
              uploaded_at: DateUtils.toUTC(moment()).format()
            });
            updateChange();
            $timeout(function () {
              growl.success($filter('translate')('tasks.captures.linkSaved', { fileName: $ctrl.linkText || fileUrl.href }), {
                referenceId: 'uploadFile' + $ctrl.field.id,
                disableIcons: true
              });
              isLastFile();
              $ctrl.urlLinkSource = '';
              $ctrl.linkText = '';
            }, 500);
          }

          /**
           * @function
           * @name saveFile
           * @param {Object} file 
           * @description Save file
           * @returns void
           */
          function saveFile(file) {
            var task = _.get($ctrl, 'renderFormCtrl.task', {});
            var data = {
              name: file,
              uploaded_from: $ctrl.isPrerun ? 'ko_field' : $ctrl.field.id,
              subject_type: $ctrl.isPrerun ? 'Checklist' : (task.is_oneoff_task ? 'Task' : 'Run')
            };
            if (!$ctrl.isPrerun && !task.is_oneoff_task) {
              data.step_id = task.step_id;
              data.checklist_id = _.get(task, 'run.data.checklist_id');
            }
            data.subject_id = $ctrl.isPrerun ? $ctrl.field.checklist_id : (task.is_oneoff_task ? task.id : _.get(task, 'run.data.id') ? task.run.data.id: _.get(task, 'run_id'));

            setUploadStatus(true);
            var guestCode = AuthServerProvider.getToken(true);
            Upload.upload({
              url: $ctrl.isGuest
                ? ENV_CONFIG.API_HOST + '/organizations/' + $ctrl.org.id + '/guests/' + guestCode + '/tasks/' + $ctrl.task.id + '/file'
                : ENV_CONFIG.API_HOST + '/organizations/' + $ctrl.org.id + '/file',
              data: data
            }).then(function (resp) {
              if (resp.data) {
                $ctrl.successFiles.push(resp.data);
                $timeout(function () {
                  growl.success($filter('translate')('tasks.captures.success', { fileName: file.name }), {
                    referenceId: 'uploadFile' + $ctrl.field.id,
                    disableIcons: true
                  });
                  isLastFile();
                }, 950);
              }
              blockUI.stop();
            }, function (resp) {
              var errorMsg;
              if (resp.status === 422) {
                Helper.clearUniversalAlert('growlNotification');
                errorMsg = $filter('translate')('tasks.captures.exceed_size', { fileName: file.name });
              } else if (resp.status === 406) {
                errorMsg = '<ul><li>' + Object.keys(resp.data).map(function (x) {
                  return resp.data[x];
                }).join('</li><li>') + '</li></ul>';
              } else {
                errorMsg = $filter('translate')('tasks.captures.server_error', { fileName: file.name });
              }
              $timeout(function () {
                growl.error(errorMsg, { referenceId: 'uploadFile' + $ctrl.field.id, disableIcons: true });
              }, 950);
              isLastFile();
              blockUI.stop();
            }, function (evt) {
              $ctrl.fileProgress = parseInt(100.0 * evt.loaded / evt.total);
            });
          }

          /**
           * @function
           * @name addTempFiles
           * @param {Object} files 
           * @description Add temp files
           * @returns void
           */
          function addTempFiles(files) {
            _.forEach(files, function (file) {
              var f = {
                isTemp: true,
                filename: file.name,
                id: _.now(),
                step_id: "",
                subject_id: "",
                subject_type: "",
                uploaded_from: "",
                url: "",
                version: ""
              };
              if (!_.isArray($ctrl.field.value)) {
                $ctrl.field.value = [];
              }
              $ctrl.field.value.unshift(f);
            });
          }

          /**
           * @function
           * @name removeTempFiles
           * @description Remove temp files
           * @returns void
           */
          function removeTempFiles() {
            $ctrl.field.value = _.filter($ctrl.field.value, function (f) {
              return !f.isTemp;
            });
          }

          /**
           * @function
           * @name saveUploadFieldData
           * @description Save uploaded field data
           * @returns void
           */
          function saveUploadFieldData() {
            var fieldValue = angular.copy($ctrl.field.value);

            _.forEach($ctrl.successFiles, function (asset) {
              fieldValue = _.union(fieldValue, [asset.data], 'id');
              $log.warn('File Field Values', fieldValue);
            });

            _.forEach(fieldValue, function (fv) {
              if (fv && fv.hasOwnProperty('id')) {
                var link = 'organizations/' + $ctrl.org.id + '/file/' + fv.id + '/dl';
                fv.url = link;
              }
            });

            $ctrl.field.value = fieldValue || [];
            updateChange();
          }

          /**
          * @function
          * @name deleteAsset
          * @param {Object} file
          * @param {Integer} i
          * @description Delete asset
          * @returns void
          */
          function deleteAsset(file, i) {
            $ctrl.files = null;
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', {
                actionName: 'Delete',
                componentName: 'File'
              }),
              'body': $filter('translate')('global.confirmAction.body', {
                actionName: 'delete',
                componentName: file.filename
              }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Delete File' }),
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              blockUI.start();
              if (file.source === 'url') {
                if ($ctrl.isPrerun || $ctrl.isGuest) {
                  $ctrl.field.value.splice(i);
                  $ctrl.renderFormCtrl.onFieldUpdate({ field: $ctrl.field });
                } else {
                  emitEvent(file);
                }
                $timeout(function () {
                  growl.success($filter('translate')('tasks.captures.delete.success'), {
                    referenceId: 'uploadFile' + $ctrl.field.id,
                    disableIcons: true
                  });
                  blockUI.stop();
                }, 500);
              } else {
                var params = {
                  id: _.get(file, 'id'),
                  skipAuthToken: $ctrl.isGuest,
                  org: $ctrl.isGuest ? void 0 : $ctrl.org.id,
                  task_id: $ctrl.isGuest ? $ctrl.task.id : void 0
                };
                var resource = $ctrl.isGuest ? FilesService.deleteGuestFile(params) : FilesService.delete(params);
                resource.then(function (response) {
                  if ($ctrl.isPrerun || $ctrl.isGuest || $ctrl.isOnOffTask) {
                    _.remove($ctrl.field.value, function (option) {
                      return option.id === file.id;
                    });
                    $ctrl.renderFormCtrl.onFieldUpdate({ field: $ctrl.field });
                  } else {
                    $log.info('File is deleted', response);
                    emitEvent(file);
                  }
                  blockUI.stop();
                  growl.success($filter('translate')('tasks.captures.delete.success'), {
                    referenceId: 'uploadFile' + $ctrl.field.id,
                    disableIcons: true
                  });
                }, function (error) {
                  if (error.status === 404) {
                    emitEvent(file);
                  }
                  $log.error('Unable to delete file', error);
                  blockUI.stop();
                  growl.error($filter('translate')('tasks.captures.delete.error'), {
                    referenceId: 'uploadFile' + $ctrl.field.id,
                    disableIcons: true
                  });
                });
              }
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name setUploadStatus
           * @description Set status
           * @param {any} status
           * @returns void
           */
          function setUploadStatus(status) {
            $ctrl.isFileLoading = status;
            if ($ctrl.task) {
              $ctrl.task.isFileLoading = status;
            }
          }

          /**
           * @ngMethod
           * @name emitEvent
           * @description Emit event
           * @param {Object} file
           * @returns void
           */
          function emitEvent(file) {
            $rootScope.$emit('TASK:DELETE_FILE', {
              file: file,
              task: $ctrl.renderFormCtrl.task,
              field: $ctrl.field
            });
          }

          /**
           * @ngMethod
           * @name updateMultipleCheckboxOptions
           * @description add/delete selected/unselected option
           * @param {any} option
           * @returns void
           */
          function updateMultipleCheckboxOptions(option) {
            if (option.selected) {
              $ctrl.field.value.push(option);
            } else {
              var idx = _.findIndex($ctrl.field.value, { id: option.id });
              $ctrl.field.value.splice(idx, 1);
            }
          }

          /**
           * @ngMethod
           * @name isLastFile
           * @description add/delete selected/unselected option
           * @returns void
           */
          function isLastFile() {
            if (++uploadedFileCount === $ctrl.uploadingTranslateValue.file_count) {
              removeTempFiles();
              saveUploadFieldData();
              setUploadStatus(false);
            }
          }

          /**
           * @ngMethod
           * @name getFieldValues
           * @description Return string as a clickable link if match with regex.
           * @returns {String} String
           */
          function getFieldValues() {
            var fieldValue = $ctrl.field.value;

            if (_.trim(fieldValue).length === 0 && !$ctrl.isDisabled) {
              fieldValue = $ctrl.field.label;
            }

            return fieldValue.replace(FULL_URL_REGEX, function (url) {
              return '<a class="t-mossy-green" target="_blank" href="' + url + '">' + url + '</a>';
            });
          }

          /**
           * @ngMethod
           * @name hideTextField
           * @param {*} clearValidation 
           * @description Remove require field validation if field value not empty
           * @returns {String} String
           */
          function hideTextField(clearValidation) {
            $ctrl.showTextField = true;
            $ctrl.showTextArea = true;
            if (clearValidation) {
              delete $ctrl.validationErrors[$ctrl.field.id];
            }
          }

          /**
           * @ngMethod
           * @name updateDropDownField
           * @description Update dropdown field value
           * @param {Object} option
           * @returns void
           */
          function updateDropDownField(option) {
            if (_.get(option, 'id') === _.get($ctrl.previousValue, 'id')) {
              return;
            }
            if (($ctrl.field.required || ($ctrl.asDefaultValue && $ctrl.field.default_value_enabled)) && !$ctrl.field.value && !option) {
              $ctrl.validationErrors[$ctrl.field.id] = $filter('translate')('steps.messages.requiredField');
              return;
            }
            $ctrl.field.value = option ? option : "";
            hideTextField(true);
            updateTaskFormField();
          }

          /**
           * @ngMethod
           * @name updateTaskFormField
           * @description Update task form field value
           * @returns void
           */
          function updateTaskFormField() {
            $ctrl.renderFormCtrl.updateFieldValue($ctrl.field);
            $ctrl.previousValue = $ctrl.field.value;
          }

          /**
           * @ngMethod
           * @name getFileClassName
           * @description get class name for specific file type
           * @param file
           * @returns {string|*}
           */
          function getFileClassName(file) {
            return Helper.getFileClassName(file.filename);
          }

          /**
           * @ngMethod
           * @name isDisableField
           * @description disable form field 
           * @public
           * @returns {boolean}
           */
          function isDisableField() {
            return ($ctrl.isDisabled || !$ctrl.isCompletableTask || !$ctrl.hasAuthority) && !$ctrl.asDefaultValue;
          }

          /**
           * @ngmethod
           * @name getFileLocation
           * @param {*} file 
           * @description get file location
           * @returns {String} 
           */
          function getFileLocation(file) {
            return file.source === 'local' ? $ctrl.images[file.id] : file.url;
          }

          /**
           * @ngmethod
           * @name getValue
           * @param {*} fieldType 
           * @description get empty field value 
           * @returns {String} 
           */
          function getValue(fieldType) {
            var value = '';
            if (fieldType === 'dropdown') {
              value = !$ctrl.isDisableField() ? _.get($ctrl.field, 'value.text', '') || $filter('translate')('steps.captures.select') : _.get($ctrl.field, 'value.text', '') || ($ctrl.hideNoInfoData ? '' : $filter('translate')('steps.captures.form.emptyValue.multiSelectField'));
            }
            return value;
          }

          /**
           * @ngmethod
           * @name renderFieldTemplate
           * @param {*} fieldType 
           * @description render Field template
           */
          function renderFieldTemplate(fieldType) {
            if (fieldType !== 'date') {
              var fileName = $ctrl.renderFormCtrl.isReadonly ? 'render-readonly.html' : 'render.html';
              $ctrl.templateUrl = 'app/modules/steps/capture/fields/' + fieldType + '/' + fileName;
            }
            hideTextField();
          }

          function getTableData() {
            var result = [], columns = $ctrl.tableOptions.gridConfig.columns || [];
            var fieldValue = [];
            if ($ctrl.isPrerun) {
              if ($ctrl.fieldValue.length > 0 && _.get($ctrl.fieldValue[0], 'value')) {
                for (var i = 0; i < $ctrl.fieldValue.length; i++) {
                  fieldValue.push($ctrl.fieldValue[i].value);
                }
              } else {
                fieldValue = $ctrl.fieldValue;
              }
            } else {
              fieldValue = $ctrl.fieldValue;
            }
            for (var i = 0; i < columns.length; i++) {
              var columnValue = _.get(fieldValue, '[' + i + ']', []);
              for (var j = 0; j < (columnValue || []).length; j++) {
                if (!result[j]) {
                  result[j] = {};
                }
                result[j][columns[i].field] = columnValue[j];
              }
            }
            return result;
          }

          function mapTableValue(rows) {
            var value = [], tableColumns = $ctrl.field.columns;
            for (var i = 0; i < tableColumns.length; i++) {
              var mappedValue = { id: tableColumns[i].id, label: tableColumns[i].label };
              mappedValue.value = getColumnValue(tableColumns[i]._guid, rows);
              value.push(mappedValue);
            }
            return _.map(value, function (val) {
              return val.value;
            });
          }

          function getColumnValue(column, rows) {
            var result = [];
            for (var i = 0; i < rows.length; i++) {
              result.push(rows[i][column] || '');
            }
            return result;
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function tableFieldUpdated(data, isDestroy) {
            if (!data.length || isDestroy) {
              return;
            }
            $ctrl.field.value = mapTableValue(data);
            if ($ctrl.tableOptions.tableState) {
              $ctrl.tableOptions.tableState.editMode = false;
            }
            $timeout(function () {
              updateChange();
            }, 0);
          }

          function getTableConfig() {
            var hideAndShowColumns = [];
            var columnDefs = [];
            for (var i = 0; i < $ctrl.field.columns.length; i++) {
              var guid = Helper.getRandomString(8);
              $ctrl.field.columns[i]._guid = guid;
              columnDefs.push({
                field: guid,
                exportKeyMap: {
                  key: guid,
                  label: $ctrl.field.columns[i].label
                },
                headerTemplate: kendo.template(KendoUIService.getTooltippedHeaderLabel($ctrl.field.columns[i])),
                title: $ctrl.field.columns[i].label
              });
              hideAndShowColumns.push({
                field: guid,
                title: $ctrl.field.columns[i].label,
                initState: true
              });
            }
            return {
              beautyScrollStyle: true,
              addRow: true,
              readOnly: $ctrl.isDisabled,
              tableState: {
                sort: {},
                columns: {}
              },
              exportFileName: $ctrl.field.label,
              tableMenus: {
                export: true,
                enableImportExcelOrCsv: true,
                hideAndShowColumns: hideAndShowColumns
              },
              gridConfig: RenderTableService.getOptions($ctrl.field.id, columnDefs, $ctrl.renderFormCtrl.isReadonly || $ctrl.isDisabled, tableFieldUpdated, $ctrl.field.label, $ctrl.isDisabled),
              templateScope: {
                callbacks: {
                  deleteRow: deleteRow
                },
                variables: {}
              }
            };
          }

          function getData() {
            var defer = $q.defer();
            $timeout(function () {
              if ($ctrl.field.value) {
                var data = $ctrl.field.value.length ? getTableData() : [];
                defer.resolve(data);
              } else {
                defer.resolve([]);
              }
            }, 0);
            return defer.promise;
          }

          function deleteRow(e) {
            var grid = $ctrl.tableOptions.tableElement.data('kendoGrid');
            grid.removeRow($(e.target).closest('tr'));
            grid.dataSource.sync();
            $ctrl.field.value = mapTableValue(grid.dataSource.data());
            if ($ctrl.tableOptions.tableState) {
              $ctrl.tableOptions.tableState.editMode = false;
            }
            updateChange();
          }

          /**
           * @name onInputKeyUp
           * @returns void
           * @param {*} e 
           * 
           * @description
           * to check input idle
           */
          function onInputKeyUp(e) {
            if (idleTime) {
              $timeout.cancel(idleTime);
            }
            idleTime = $timeout(function () {
              updateChange();
            }, 1000);
          }

          function fieldFocus() {
            $rootScope.$emit('FIELD:FOCUS-' + $ctrl.field.id, { field: $ctrl.field });
          }

          //Clear radio selection - if not required
          function clearSelection() {
            $ctrl.field.value = '';
            $ctrl.updateChange();
          }

          function getAssigneesSources() {
            if ($ctrl.isGuest) {
              $ctrl.usersInOrg = $rootScope.identity.guest.organization.users;
            } else {
              var resources = [
                store.getUsers(),
                store.getGroups()
              ];
              $q.all(resources)
                .then(function (responses) {
                  $ctrl.usersInOrg = UsersService.getBilledUsers(responses[0]);
                  $ctrl.orgGroups = responses[1];
                });
            }
          }

          function closePopup() {
            $ctrl.assignedPopoverIsOpen = false;
          }

          /**
           * @ngdoc method
           * @name onClickOpen
           * @description
           * Open popup model to edit task data
           */
          function onClickOpen() {
            $ctrl.assignedPopoverIsOpen = true;
          }

          function updateAssigneeFormValue(e) {
            updateChange();
            closePopup();
          }

          /**
           * @ngdoc method
           * @name addVariableValue
           * @description
           * add variable value
           */
          function addVariableValue(field) {
            $ctrl.field.value = _.trim(_.get($ctrl.field, 'value', ''), 'null') + ' {{' + field.alias + '}}';
            updateChange(true);
          }

          //controller ends
        }
    });
})();
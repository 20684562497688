/**
 * @ngdoc Service
 * @name tallyfy.run.RunsDashboardService
 *
 * @module tallyfy.run
 *
 * @description
 * RunsDashboardService
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */

(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .service('RunsDashboardService', RunsDashboardService);
    
      /*@ngInject*/
      function RunsDashboardService($rootScope, $filter) {
      var self = this;

      /**
       * @name getFilterConfig
       * @description return filter default value
       * @returns Object
       */
      self.getFilterConfig = function () {
        return {
          filterTypes: [
            {
              id: 'owners',
              label: $filter('translate')('runs.filter.filterTypes.assigned'),
              slug: 'users',
              idField: 'id',
              labelField: 'full_name',
              labelOptionalField: 'email',
              sortFields: 'first_name' // optional
            },
            {
              id: 'tag',
              label: $filter('translate')('runs.filter.filterTypes.tags'),
              slug: 'tags',
              idField: 'id',
              labelField: 'title',
              labelOptionalField: ''
            },
            {
              id: 'checklist_id',
              label: $filter('translate')('runs.filter.filterTypes.template'),
              slug: 'checklists',
              idField: 'id',
              labelField: 'title',
              labelOptionalField: ''
            },
            {
              id: 'status',
              label: $filter('translate')('runs.filter.filterTypes.processState'),
              data: [
                { id: 'complete', label: $filter('translate')('runs.filter.filterTypes.completed') },
                { id: 'archived', label: $filter('translate')('runs.filter.filterTypes.archived') },
                { id: 'active', label: $filter('translate')('runs.filter.filterTypes.notComplete') },
                { id: 'problem', label: $filter('translate')('runs.filter.filterTypes.issueUnresolved') },
                { id: 'starred', label: $filter('translate')('runs.filter.filterTypes.starred') },
                { id: 'delayed', label: $filter('translate')('runs.filter.filterTypes.delayed') },
                { id: 'untagged', label: $filter('translate')('runs.filter.filterTypes.untagged') }
              ]
            },
            {
              id: 'type',
              label: $filter('translate')('runs.filter.filterTypes.type'),
              slug: 'type',
              idField: 'id',
              labelField: 'name',
              labelOptionalField: ''
            },
            {
              id: 'folder',
              label: $filter('translate')('runs.filter.filterTypes.folder')
            }
            /* TODO It can be unhide in future version */
            /*{
              id: 'groups',
              label: $filter('translate')('runs.filter.filterTypes.assigned'),
              slug: 'groups',
              idField: 'id',
              labelField: 'full_name',
              labelOptionalField: 'email',
              sortFields: 'first_name' // optional
            }, {
              id: 'groupOwners',
              label: 'Group Owners',
              slug: 'groups',
              idField: 'id',
              labelField: 'name',
              labelOptionalField: ''
            }*/
          ],
          typeFilter:[
            {
              name: $filter('translate')('runs.filter.defaultFilters.processes'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.processes'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.processes'),
              sidebarIconClass: 'far fa-list-ol',
              id: 'procedure',
              criteria: [],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.documents'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.documents'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.documents'),
              sidebarIconClass: 'far fa-align-left',
              id: 'document',
              criteria: [],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            }
          ],
          defaultFilters: [
            {
              name: $filter('translate')('runs.filter.defaultFilters.allProcess'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.all'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.all'),
              sidebarIconClass: 'far fa-home',
              id: 'all',
              criteria: [],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.myProcess'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.mine'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.mine'),
              sidebarIconClass: 'far fa-user-check',
              id: 'process_you_involved',
              criteria: [
                { field: 'owners', value: $rootScope.identity.id }
              ],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.sidebarNames.overdueProcesses'),
              sortName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.overdueProcesses'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.overdueProcesses'),
              sidebarIconClass: 'far fa-clock m-t-2px',
              id: 'overdue_processes',
              criteria: [
                { field: 'status', value: 'delayed' }
              ],
              customizable: false,
              type: { id: 'any', label: $filter('translate')('runs.filter.defaultFilters.any') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.favoriteProcess'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.favorite'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.favorite'),
              sidebarIconClass: 'far fa-star',
              id: 'starred',
              criteria: [
                { field: 'starred', value: 'true' }
              ],
              customizable: false,
              type: { id: 'any', label: $filter('translate')('runs.filter.defaultFilters.any') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.overdueProblems'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.overdueProblems'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.overdueProblems'),
              sidebarIconClass: 'far fa-exclamation-triangle',
              id: 'overdue_problems',
              criteria: [
                { field: 'status', value: 'problem' }
              ],
              customizable: false,
              type: { id: 'any', label: $filter('translate')('runs.filter.defaultFilters.any') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.unTaggedProcess'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.unTaggedProcess'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.unTaggedProcess'),
              sidebarIconClass: 'far fa-tag',
              id: 'untagged_process',
              criteria: [
                { field: 'untagged', value: 'true' }
              ],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            },
            {
              name: $filter('translate')('runs.filter.defaultFilters.archived'),
              sortName: $filter('translate')('runs.filter.defaultFilters.filterSortName.archived'),
              sidebarName: $filter('translate')('runs.filter.defaultFilters.sidebarNames.archived'),
              sidebarIconClass: 'far fa-archive',
              id: 'archived',
              criteria: [
                { field: 'status', value: 'archived' }
              ],
              customizable: false,
              type: { id: 'all', label: $filter('translate')('runs.filter.defaultFilters.all') }
            }
          ],
          filterCustomViewSlug: 'tracker_filter_custom_views',
          filterConfigSlug: 'tracker_filter_config',
          filterViewTypeSlug: 'run_display_on_grid_mode',
          filterSortSlug: 'sort_config_slug',
          filterFolderSlug: 'runs_tracker_filter_folder',
          filterTagsSlug: 'runs_tracker_filter_tags',
          filterTypeSlug: 'runs_tracker_filter_type',
          filterFor: 'run',
          successMessage: 'runs.filter.success.view',
          deleteMessage: 'runs.filter.success.delete'
        };
      };

      /**
       * @name getSortFilterConfig
       * @description return sort filter default value
       * @returns Object
       */
      self.getSortFilterConfig = function () {
        return {
          sortOptions: [
            { name: $filter('translate')('runs.filter.sortFilter.newestFirst'), value: '-created_at' },
            { name: $filter('translate')('runs.filter.sortFilter.oldestFirst'), value: 'created_at' },
            { name: $filter('translate')('runs.filter.sortFilter.mostDelayed'), value: '-dueDate' },
            { name: $filter('translate')('runs.filter.sortFilter.alphanumericAscending'), value: 'name' },
            { name: $filter('translate')('runs.filter.sortFilter.alphanumericDescending'), value: '-name' }
          ],
          sortConfigSlug: "sort_config_slug"
        };
      };

        /**
         * @name getBoardTypeOptions
         * @description return board type options
         * @returns Array
         */
        self.getViewTypeOptions = function () {
          return [
            { name: $filter('translate')('runs.display.grid'), value: 'grid', icon: 'far fa-th-large' },
            { name: $filter('translate')('runs.display.table'), value: 'table', icon: 'far fa-list-alt' }
          ];
        };
      
      /**
       * @name getBluePrintSortFilterConfig
       * @description return sort filter default value
       * @returns Object
       */
      self.getBluePrintSortFilterConfig = function () {
        return {
          sortOptions: [
            { name: $filter('translate')('runs.filter.sortFilter.updated'), value: 'updated' },
            { name: $filter('translate')('runs.filter.sortFilter.popular'), value: 'popular' },
            { name: $filter('translate')('runs.filter.sortFilter.alphanumericAscending'), value: 'title' },
            { name: $filter('translate')('runs.filter.sortFilter.alphanumericDescending'), value: '-title' }
          ],
          sortConfigSlug: "bp_filter_config"
        };
      };
    }
})();
/**
 * @ngdoc Transformer
 * @name tallyfy.factory.DeadlineTransformer
 * @module tallyfy
 *
 * @description
 * A transformer to update the deadline
 * @param data {Object} deadline
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('DeadlineTransformer',
      /*@ngInject*/

      function DeadlineTransformer(DateUtils) {
        var deadlines = [];
        /**
         * Constructor, with class name
         */
        function DeadlineTransformer(data) {
          deadlines = [];
          angular.forEach(data, convertDateString);
          return deadlines;
        }

        function convertDateString(deadline) {
          var newdeadline = angular.copy(deadline);
          newdeadline.deadline_time = DateUtils.toLocal(deadline.deadline_time).format();
          newdeadline.deadline_unformatted = DateUtils.toLocal(deadline.deadline_time);
          deadlines.push(newdeadline);
        }
        return DeadlineTransformer;
      });

})();

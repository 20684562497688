/**
 * @ngdoc Component
 * @name tallyfy.component.navFavorites
 * @module tallyfy
 *
 * @description
 * Favorites view for nav
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 */
 (function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('navFavorites', {
      bindings: {
        navbar: '<?'
      },
      templateUrl: 'app/components/nav-favorites/nav-favorites.component.html',
      controller:
      /*@ngInject*/
      function ($rootScope, FolderService, $q, blockUI, Growl, ProcessService, StepService, UtilsService, $timeout, $state, _, $stateParams, BLUEPRINT_TYPE, Helper, $filter) {
        var $ctrl = this, growl = new Growl(), folderSelectedWatcher;

        /*
        * @access public   
        * public properties
        */
        $ctrl.showScrolled = false;
        $ctrl.blueprintItemsToDisplay = [];
        $ctrl.pager = [];
        $ctrl.favoriteCount = 0;
        $ctrl.isFavoriteFetched = false;  
        $ctrl.folders = [];
        $ctrl.foldersAsMenu = [];
        $ctrl.showMoreFavorites = null;
        $ctrl.favoritesLimit = 5;
        $ctrl.displayShowMoreButton = false;
        $ctrl.isSubMenuOpen = false;

        /*
        * @access public
        * public methods
        */
        $ctrl.onFavoriteClick = onFavoriteClick;
        $ctrl.archiveProcess = archiveProcess;
        $ctrl.unArchiveProcess = unArchiveProcess;
        $ctrl.undoArchiveProcess = undoArchiveProcess;
        $ctrl.onRemoveFromFolder = onRemoveFromFolder;
        $ctrl.onNewFolderForBlueprint = onNewFolderForBlueprint;
        $ctrl.duplicateTemplate = ProcessService.showDuplicateTemplateModal;
        $ctrl.resetSelectedBP = resetSelectedBP;
        $ctrl.onMoveToFolder = onMoveToFolder;
        $ctrl.haveAuthority = Helper.checkAccessAuthority;
        $ctrl.folderClick = folderClick;
        $ctrl.toggleShowMoreFavorites = toggleShowMoreFavorites;
        $ctrl.onViewBluePrint = onViewBluePrint;
        $ctrl.closeLeftNav = closeLeftNav;
        $ctrl.getItemFoldersForBreadcrumb = getItemFoldersForBreadcrumb;
        /**
        * Component's lifeCycle hooks
        */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;
        /**
         * Expose bindable methods
         * these methods are accessible in view
         */
        /**
         * public properties
         */
        /**
         * @ngdoc method
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          $ctrl.statusFilter = 'favorite';
          $ctrl.processType = $ctrl.statusFilter === 'archived' ? $ctrl.statusFilter : angular.noop();
          $ctrl.pager = ProcessService.getPager({
            current_page: 1,
            per_page: 20
          });
          getDataInPage($ctrl.pager);
        }
        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() {}
        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { 
          folderSelectedWatcher();
        }

        function getDataInPage(params) {
          $ctrl.statusOptions = StepService.getProgressStatus();
          params.page = 1;
          $ctrl.isLoadingBPsData = true;
          $ctrl.blueprintItemsToDisplay = [];
          $ctrl.isAdminMember = _.isEqual(_.get($rootScope.identity, 'role', "standard"), "admin");
          $ctrl.isLightMember = _.isEqual(_.get($rootScope.identity, 'role'), "light");
          getProcess(params).then(function (response) {
            if (response.meta.pagination.total > $ctrl.pager.total) {
              $ctrl.pager = response.meta.pagination;
            }
            $ctrl.processes = angular.copy(response.data);
            var currentUserId = _.get($rootScope.identity, 'id');
            if ($ctrl.processes.length > 0) {
                _.forEach($ctrl.processes, function (process) {
                  _.forEach(_.get(process.tags, 'data', []), function(tag) {
                    _.assign(tag, { "title": tag.title.replace(/#/g, "") });
                  });
                  var itemData = {
                    id: process.id,
                    type: 'process',
                    data: process,
                    statusDropDown: _.find($ctrl.statusOptions, { value: process.status }),
                    createdByUser: _.find($ctrl.users, { 'id': process.created_by }),
                    updatedByUser: _.find($ctrl.users, { 'id': process.last_updated_by }),
                    meta: {
                      isFormType: _.get(process, 'type') ===  BLUEPRINT_TYPE.FORM,
                      isProcedureType: _.get(process, 'type') ===  BLUEPRINT_TYPE.PROCEDURE,
                      isDocumentType: _.get(process, 'type') ===  BLUEPRINT_TYPE.DOCUMENT
                    },
                    havePermissionToDuplicate: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_duplicate', []), _.get(process, 'permissions.data.checklist_duplicate_group', []), $ctrl.orgGroups), 
                    havePermissionToEdit: !$ctrl.isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_edit', []), _.get(process, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups),
                    havePermissionToRead: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_read', []), _.get(process, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups),
                    havePermissionToLaunch: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.process_launch', []), _.get(process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups)
                  };
                  $ctrl.blueprintItemsToDisplay.push(itemData);
                });
                $ctrl.displayShowMoreButton = !!($ctrl.blueprintItemsToDisplay.length > 5);
                $rootScope.$emit('NAV_FAVORITES:DATA_LOADED');
            }
            $ctrl.isLoadingBPsData = false;
          }, function () {
            $ctrl.isLoadingBPsData = false;
          });
        }
    
        /**
         * @ngdoc method
         * @name getProcess
         * @param {*} params
         * 
         * @description
         * get process 
         */
        function getProcess(params) {
          params = _.omit(params, ['total']);
          angular.extend(params, {
            append: 'steps_count',
            with: 'tags,folder,permissions',
            entire_folder_tree: "1"
          });
          params.starred = 1;
          return ProcessService.filter(params, true);
        }

        function onViewBluePrint(selected) {
          $state.go('process.templates', {
            status: 'example',
            blueprint_id: _.get(selected, 'id'),
            public_org: _.get(selected, 'organization_id')
          });
        }

        function onFavoriteClick(blueprint, index) {
          var canReadBlueprint = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, _.get($rootScope.identity, 'id'), _.get(blueprint, 'permissions.data.checklist_read', []), _.get(blueprint, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups);
          Growl.clearAllMessages('global');
          if (canReadBlueprint) {
              blueprint.starred = !blueprint.starred;
              var resource = blueprint.starred ? ProcessService.setFavorite(_.toString(_.get(blueprint, 'id')))
                : ProcessService.removeFavorite(_.toString(_.get(blueprint, 'id')));
              resource.then(function () {
                if (!blueprint.starred) {
                  $ctrl.blueprintItemsToDisplay.splice(index, 1);
                  $ctrl.pager.total--;
                  $rootScope.$emit('BLUEPRINT:UNFAVORITE_BLUEPRINT');
                }
                $timeout(function () {
                  var translateMsg = blueprint.starred ? 'runs.messages.favorited' : 'runs.messages.unFavorited';
                  growl.success($filter('translate')(translateMsg), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, 250);
                getFavoriteCount();
              }, function () {
                blueprint.starred = !blueprint.starred;
              });
          } else {
              $timeout(function () {
                growl.warning($filter('translate')('process.messages.favoriteRestricted'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 250);  
            }
          }
      
          /**
          * @ngdoc method
          * @name getFavoriteCount
          * 
          * @description
          * getting Favorite count to display
          */
          function getFavoriteCount() {
            var params = {
                per_page: 1,
                current_page: 1,
                page: 1,
                append: "steps_count",
                with: "tags,folder,permissions",
                starred: 1
            };
            ProcessService.filter(params, true).then(function (response) {
              if (response.meta.pagination.total > -1) {
                $ctrl.favoriteCount = response.meta.pagination.total;
              }
              $ctrl.isFavoriteFetched = true;
            }, function () {
              $ctrl.isFavoriteFetched = false;
            });
          }
      
          /**
          * @ngdoc method
          * @name archiveProcess
          * @description archive a process
          * @param {*} event
          * @param {Object} process
          * @param {*} i (index)
          */
          function archiveProcess(event, process, i) {
            if (!$ctrl.haveAuthority())
              return;
            Growl.clearAllMessages('global');
            event.stopPropagation();
            $ctrl.isOpenPopOver[i] = false;
            archived({
              process: process
            }).then(function () { }, function () { });
          }
      
          /**
          * @ngdoc method
          * @name undoArchiveProcess
          * @description undo archive process
          * @param {*} params 
          */
          function undoArchiveProcess(params) {
            if (!params) {
              return;
            }
            if (!$ctrl.haveAuthority())
              return;
            Growl.clearAllMessages('global');
            $ctrl.blueprintItemsToDisplay.splice(params.index, 0, params.process);
            $timeout(function () {
              growl.success($filter('translate')('process.messages.unArchive'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 500);
            unArchiveProcess(params.process.data, true);
          }
      
          /**
          * @ngdoc method
          * @name unArchiveProcess
          * @description un archive a process
          * @param {Object} process 
          * @param {Object} isUndo
          */
          function unArchiveProcess(process, isUndo) {
            if (!$ctrl.haveAuthority())
              return;
            if (!isUndo) {
              blockUI.start();
            }
            ProcessService.unArchiveProcess({
              id: process.id
            }).then(function (data) {
              if (!isUndo) {
                var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
                $ctrl.blueprintItemsToDisplay.splice(index, 1);
                growl.success($filter('translate')('process.messages.unArchive'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              }
            }, function (error) {
              growl.error(error.message);
              blockUI.stop();
            });
          }
      
          /**
          * @ngdoc method
          * @name archived
          * @description archive a process
          * @param {Object} process
          */
          function archived(process) {
            var defer = $q.defer();
            ProcessService.archiveProcess({
              id: process.id || process.process.id
            }).then(function () {
             var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id || process.process.id });
              $ctrl.blueprintItemsToDisplay.splice(index, 1);
              growl.success($filter('translate')('process.messages.archive'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: undoArchiveProcess,
                  param: {
                    "process": process.process || process,
                    "index": index
                  },
                  linkText: $filter('translate')('runs.dashboard.undo')
                }
              });
              $log.info("Blueprint archived successfully");
              resetSelectedBP();
              defer.resolve();
            }, function () {
              defer.reject();
            });
            return defer.promise;
          }
      
          /**
          * @ngdoc method
          * @name onNewFolderForBlueprint
          * @param {*} process 
          * 
          * @description
          * on new folder for blueprint added / directly added blueprint into a folder after created
          */
          function onNewFolderForBlueprint(process) {
            if (!$ctrl.haveAuthority()) {
              return;
            }
            var createModal = FolderService.openFolderModal(null, null, 'checklist');
            createModal.result.then(function (response) {
              var newFolder = response.data;
              process.folder_id = newFolder.id;
              ProcessService.update({
                id: process.id
              }, process).then(function (res) {
                newFolder.total = 0;
                if (!$ctrl.folderID) {
                  $ctrl.folders.splice(0, 0, newFolder);
                  $ctrl.foldersAsMenu.splice(0, 0, newFolder);
                }
                var folderExist = _.find($ctrl.folderItemsToDisplay, { type: 'folder' });
                if ((folderExist || $ctrl.pager.current_page === 1) && !$ctrl.folderID) {
                  $ctrl.folderItemsToDisplay.splice(0, 0, {
                    id: newFolder.id,
                    type: 'folder',
                    data: newFolder
                  });
                }
                _.remove($ctrl.blueprintItemsToDisplay, { id: process.id });
                var folderIndex = _.findIndex($ctrl.folders, { id: newFolder.id });
                if (folderIndex >= 0) {
                  $ctrl.folderID ? $ctrl.folders[folderIndex].total-- : $ctrl.folders[folderIndex].total++;
                }
                if ($ctrl.folderID) {
                  growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                } else {
                  growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }
              });
            });
          }
      
          function onRemoveFromFolder(process) {
            if (!$ctrl.haveAuthority())
              return;
            var processIndex = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
            if (processIndex > -1) {
              $ctrl.isOpenPopOver[processIndex] = false;
            }
            blockUI.start();
            process.folder_id = null;
            if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
              resetSelectedBP();
            }
            ProcessService.update({
              id: process.id
            }, process).then(function(res) {
              blockUI.stop();
              postRemoveFromFolder(res);
            });
          }
      
          function postRemoveFromFolder(res) {
            _.remove($ctrl.blueprintItemsToDisplay, { id: res.data.id });
            growl.success($filter('translate')('runs.messages.folder.removedFromFolder', {
              bpName: res.data.title
            }), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }
      
          function resetSelectedBP() {
            $state.transitionTo($state.current, angular.extend($stateParams, {
              blueprint_id: null
            }), {
              notify: false 
            });
            body.removeClass('library-view-modal');
            $ctrl.selectedBlueprint = null;
          }
      
          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process 
           * @param {*} folder 
           * 
           * @description
           * move blueprint to a folder
           */
           function onMoveToFolder(process, folder) {
            if (!$ctrl.haveAuthority())
              return;
            var processIndex = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
            if (processIndex > -1) {
              $ctrl.isOpenPopOver[processIndex] = false;
            }
            if (_.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
                resetSelectedBP();
              }
              blockUI.start();
              process.folder_id = folder.id;
              ProcessService.update({
                id: process.id,
                skipNotFound: true
              }, process).then(function(res) {
                blockUI.stop();
                postMoveToFolder(res);
              }, function(error) {
                blockUI.stop();
                if (error.status === 404) {
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  $state.go('process.templates', {
                    org_id: _.get($rootScope.identity, 'default_organization.id')
                  });
                }
              });
            } else {
              Growl.clearAllMessages('global');
              $timeout(function () {
                growl.warning($filter('translate')('runs.messages.folder.alreadyOnFolder', {
                  bpName: _.get(process, 'title'),
                  folderName: _.get(folder, 'name')
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }
      
          /**
           * @ngdoc method
           * @name postMoveToFolder
           * @param {*} res 
           * 
           * @description
           * callback after blueprint moved to folder
           */
           function postMoveToFolder(res) {
            var folderIndex = _.findIndex($ctrl.folders, { id: res.data.folder_id });
            folderIndex >= 0 ? $ctrl.folders[folderIndex].total++
              : angular.noop();
            _.remove($ctrl.blueprintItemsToDisplay, {
              id: res.data.id
            });
            if ($ctrl.folderID) {
              growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            } else {
              growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }
          }
      
          function folderClick(folder) {
            $ctrl.userState === 'GUEST' ? $state.go('guest.templates', { org_id: $ctrl.organizationId, status: 'example' }) : 
            $state.go('process.templates', {
              org_id: $ctrl.organizationId,
              status: 'folder',
              page: 1,
              folder_id: folder.id
            });
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: folder });
            closeLeftNav();
          }
      
          function toggleShowMoreFavorites (event) {
            if ($ctrl.pager.total > 20) {
              $state.go('process.templates', { org_id: $ctrl.organizationId, status: 'favorite' });
              closeLeftNav();
            } else {
              $ctrl.favoritesLimit = 20;
              $ctrl.displayShowMoreButton = false;
            }
          }

          function closeLeftNav () {
            $rootScope.$emit('LEFT_NAV_CLOSE');
          }

          function getItemFoldersForBreadcrumb (folders) {
            return folders.filter(function (_, i) {
              return i === 0 || i === folders.length - 1;
            });
          }

          folderSelectedWatcher = $rootScope.$on('FOLDER:CLICK', function (e, data) {
            onMoveToFolder(data.blueprint, data.folder);
          });

        //end of controller
      }
    });
})();

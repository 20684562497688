/**
 * @ngdoc Filter
 * @name amTimeDays
 * @module tallyfy
 * @description
 * Convert the date to day. This is to fix the amAgo issues (which uses local moment to figure out moments ago etc.)
 * @author Kiran Sompura ( gmail:: kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('amTimeDays', ['moment', '$filter', function (moment, $filter) {
      return function (value) {
        var duration_in_days = moment.duration(moment(value).diff(moment())).asDays(),
          day = Math.round(Math.abs(duration_in_days));
        if (day === 0) {
          return $filter('amTimeAgo')(moment(value));
        } else {
          return (duration_in_days > 0) ? $filter('translate')('global.deadline.in') + " " + day + " " + $filter('translate')('global.deadline.days') : day + " " +$filter('translate')('global.deadline.daysAgo');
        }
      };
    }]);
})();
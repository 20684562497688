(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .factory('stateHandler', stateHandler);

  /*@ngInject*/
  function stateHandler($rootScope, $state, ProgressBar, translationHandler, $window,
    Auth, Principal, CONFIG, blockUI, Growl, $uibModalStack, _, WEBPAGEURL, $document, ENV_CONFIG, BeaconService, USER_STATE, ChatlioService) {
    var pageMessages = {
      process: {
        templates: "The Templates section holds your templates - which are like recipes. Which process did you want to run?",
        edit: "Create your template here. Did you have questions?"
      },
      run: {
        dashboard: "The Tracker gives you a birds-eye view of processes running right now. Questions?",
        view: "You can see a list of tasks pending for you or others here. Would you like any help?"
      },
      settings: {
        billing: "Any questions on pricing? Don't be afraid to ask us!"
      },
      home: "You can see a list of tasks pending for you or others here. Would you like any help?"
    }
    return {
      initialize: initialize
    };

    function initialize() {
      $rootScope.VERSION = CONFIG.VERSION;
      $rootScope.$state = $state;
      $rootScope.blockUI = blockUI.instances.get('global');
      var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
        $uibModalStack.dismissAll();
        ProgressBar.reset();
        Growl.clearAllMessages();
        $rootScope.blockUI.start();
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams || {};
        $rootScope.fromState = fromState;
        var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
        if (isInTrial) {          
          ChatlioService.cancelTrigger();
        }

        // Redirect to a state with an external URL (http://google.com)
        if (toState.external) {
          event.preventDefault();
          $window.open(toState.url, '_self');
        }

        if (Principal.isIdentityResolved()) {
          Auth.authorize();
        }
        dismissUibModal(event);
      });

      var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
        var titleKey = 'global.title';

        // Set the page title key to the one configured in state or use default one
        if (toState.data.pageTitle) {
          titleKey = toState.data.pageTitle;
        }
        translationHandler.updateTitle(titleKey);
        $rootScope.blockUI.stop();
        
        checkContentContainer(toState.name);

        var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
        if (isInTrial) {
          ChatlioService.triggerMessage($rootScope.identity.id + '-' + _.get($rootScope.identity, 'default_organization.id') + '-' + toState.name, _.get(pageMessages, toState.name));
        }

        if ($rootScope.userState === USER_STATE.GUEST) {
          BeaconService.destroy();
        }
      });

      /**
       * @ngdoc method
       * @param {*} state
       * 
       * @description
       * to check the content container
       */
      function checkContentContainer(state) {
        var container = angular.element($document[0].body);
        container.css('overflow-y', _.indexOf(WEBPAGEURL.public, state) >= 0 || state === 'organizations.list' ? 'auto' : 'hidden');
      }

      var stateChangeError = $rootScope.$on('$stateChangeError', function () {
        $rootScope.blockUI.stop();
      });

      $rootScope.$on('$destroy', function () {
        if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
          stateChangeStart();
        }
        if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
          stateChangeSuccess();
        }

        if (angular.isDefined(stateChangeError) && stateChangeError !== null) {
          stateChangeError();
        }
      });
    }

    function dismissUibModal($event) {
      var openedModal = $uibModalStack.getTop();
      var modalsAllowedForStateChange = ['more-detail-drawer'];
      if ($state.is('authHandler'))
        return;
      if (openedModal) {
        var allowStateChange = $uibModalStack.getTop().value.modalDomEl.attr('id') && _.indexOf(modalsAllowedForStateChange, $uibModalStack.getTop().value.modalDomEl.attr('id')) > -1;
        if (!!$event.preventDefault && !allowStateChange) {
          $event.preventDefault();
        }
        if (!!$event.stopPropagation && !allowStateChange) {
          $event.stopPropagation();
        }
        $uibModalStack.dismiss(openedModal.key);
      }
    }
  }
})();
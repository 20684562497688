(function () {
  'use strict';
  angular.module('tallyfy')
    .service('ReadBlueprintTableService', ReadBlueprintTableService);

  function ReadBlueprintTableService() {
    var self = this;
    self.getOptions = function (id, columns) {
      var fields = { guid: { editable: false, nullable: true } };
      for (var i = 0; i < columns.length; i++) {
        fields[columns[i].guid] = { editable: true, nullable: true };
      }
      return {
        columns: columns,
        dataSource: new kendo.data.DataSource({
          data: [],
          schema: {
            model: {
              id: 'guid',
              fields: fields
            }
          }
        }),
        sortable: true,
        resizable: true,
        noRecords: {
          template: kendo.template($('#renderTableNoData').html())
        },
        cellClose: function () {
          this.dataSource.sync();
        }
      };
    }
    return self;
  }
})();
(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(confirmModalConfig);

  confirmModalConfig.$inject = ['$confirmProvider'];

  function confirmModalConfig($confirmProvider) {
    // set global settings for confirm modal
    $confirmProvider.setOptions({
      'body': 'global.confirmModal.body',
      'header': 'global.confirmModal.header',
      'button': {
        'accept': 'global.confirmModal.button.accept',
        'cancel': 'global.confirmModal.body.cancel'
      },
      animate: false,
      backdrop: false
    });
  }
})();
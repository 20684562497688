(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.compliance.factory.ComplianceRepository
   * @module tallyfy.compliance
   *
   * @description
   * Create Rest resource for compliance
   *
   * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
   * 
   * */
  angular
    .module('tallyfy.compliance')
    .factory('ComplianceRepository', ComplianceRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  ComplianceRepository.$inject = ['BaseRepository'];
  /**
   * Constructor
   * ComplianceRepository construct 
   */
  function ComplianceRepository(BaseRepository) {
    var repository;
    repository = BaseRepository('/organizations/:org/compliance/:id/:action/', {
      org: 'org_id',
      action: '@action',
      id: '@id'
    }, {});
    return repository;
  }
})(); 
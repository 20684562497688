(function () {
  'use strict';
  angular.module('tallyfy')
    .component('folderMenuList', {
      templateUrl: 'app/modules/process/components/library/folderMenuList/folder-menu-list.component.html',
      bindings: {
        parentId: '<',
        parentBlueprint: '<',
        dropSelect: '<?',
        activeFolder: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, FolderService, $rootScope) {
          var $ctrl = this, folderMenuCurrentPage;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.getFolders = getFolders;
          $ctrl.onMenuItemClick = onMenuItemClick;

          function onInit() {
            getFolders(true);
          }

          function onChanges() { }

          function onDestroy() { }

          function getFolders(isFirst) {
            folderMenuCurrentPage = isFirst ? 1 : folderMenuCurrentPage + 1;
            var params = {
              page: folderMenuCurrentPage,
              per_page: 20,
              sort: 'title',
              with: 'children'
            };
            if ($ctrl.parentId !== 'root') {
              params.parent_id = $ctrl.parentId;
            }
            $ctrl.menuOnLoading = true;
            FolderService
              .getFolders(params)
              .then(function (res) {
                $ctrl.foldersAsMenu = isFirst ? res.data : _.concat($ctrl.foldersAsMenu || [], res.data);
                $ctrl.menuOnLoading = false;
                $ctrl.menuPagination = res.meta.pagination;
              }, function () {
                $ctrl.menuOnLoading = false;
              });
          }

          function onMenuItemClick(folder) {
            $ctrl.dropSelect ? $rootScope.$emit('FOLDER:SELECTED', { folder: folder })
              : $rootScope.$emit('FOLDER:CLICK', { blueprint: $ctrl.parentBlueprint, folder: folder });
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.myOrg
 * @module tallyfy.organizations
 *
 * @description
 * A component which renders layout for organizations settings [general info, organization logo, Users etc]
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('myOrg', {
      templateUrl: 'app/modules/organizations/myOrg/my-org.html',
      controller: function ($rootScope, _, store, $q, TagsService, OrganizationsService) {
          var $ctrl = this,
            countUpdateEventHandler;
          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
          * @function
          * @name initialization
          * @description
          * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $q.all([
              store.getUsers(),
              store.getGuests(),
              TagsService.get({
                action: 'tags',
                page: 1,
                per_page: 1,
                with: 'statistics'
              }),
              store.getGroups()
            ]).then(function (data) {
              $ctrl.counts = {
                users: data[0].length,
                guests: data[1].length,
                tags: _.get(data, '[2].meta.pagination.total', 0),
                groups: data[3].length
              };
            });
            $ctrl.org = _.get($rootScope.identity, 'default_organization');
            var params = {
              org: $ctrl.org.id
            };
            OrganizationsService.domainsData(params).then(function (response) {
              $ctrl.domains = _.get(response, 'data');
            });
          }
          /**
          * @function
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() {}
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            countUpdateEventHandler();
          }

          countUpdateEventHandler = $rootScope.$on('COUNT_UPDATE', function(e, data) {
            _.set($ctrl.counts, data.type, data.count);
          });
        }
    });
})();
/**
 * @ngdoc Controller
 * @name tallyfy.settings.MyAccountController
 *
 * @module tallyfy.settings
 *
 * @description
 * MyAccountController
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .controller('MyAccountController', MyAccountController);
  /**
   * @inject
   * @description
   * inject dependecies
   */
  MyAccountController.$inject = ['_', '$rootScope', '$state', 'OrganizationsService', 'USER_STATE'];

  /**
   * Constructor
   * @description
   * MyAccountController function constructor
   */

  function MyAccountController(_, $rootScope, $state, OrganizationsService, USER_STATE) {
    var $ctrl = this;

    /**
     * @function $onInit
     * A Callback function which called when component has mounted
     * this is best place to initialze things
     */
    $ctrl.$onInit = initialization;

    function initialization() {
      $ctrl.isGuestUrl = ($rootScope.userState === USER_STATE.GUEST);
      $ctrl.templateUrl = $ctrl.isGuestUrl ? 'app/modules/manage/components/myAccount/guest-my-account.html' : 'app/modules/manage/components/myAccount/my-account.html';
      $ctrl.isAzureServiceEnabled = isAzureServiceEnabled();
      if ($ctrl.isGuestUrl && !$ctrl.isAzureCognitiveServiceEnabled) {
        $state.go('guest.settings.me.preferences');
      }
    }

    function isAzureServiceEnabled() {
      var organization = _.find(_.get($rootScope, 'identity.guest.guestOrganizations', []), { id: _.get($rootScope, 'identity.guest.organization.id', '') }) || {}, orgAzureConfig = OrganizationsService.getAzureCognitiveServiceConfig(organization);
      return orgAzureConfig && orgAzureConfig.key && orgAzureConfig.resource_name && orgAzureConfig.region;
    }
  }
})();

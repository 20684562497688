/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.organizationList
 * @module tallyfy.organizations
 *
 * @description
 * A component to customize public blueprint on organization
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular.module('tallyfy.public')
    .component('organizationListModal', {
      templateUrl: 'app/components/public/library/organization-list/organization-list-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, OrganizationsService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.closeModal = closeModal;
          $ctrl.chooseOrganization = chooseOrganization;

          $ctrl.listPager = {
            total: 0,
            per_page: 5,
            current_page: 1,
            onPageChanged: function (current_page) {
              this.current_page = current_page;
              getOrganizations();
            }
          };

          function onInit() {
            getOrganizations();
          }

          function getOrganizations() {
            $ctrl.spinner = true;
            var requestParams = {
              per_page: $ctrl.listPager.per_page,
              page: $ctrl.listPager.current_page,
              with: 'subscription'
            };
            OrganizationsService.myOrganizations(requestParams).then(function (organizations) {
              $ctrl.organizations = organizations.data;
              if (!_.isEmpty(organizations.meta)) {
                if (organizations.meta.pagination.total <= 0) {
                  return;
                }
                $ctrl.listPager.total = organizations.meta.pagination.total;
              }
              $ctrl.spinner = false;
            },
            function (error) {
              $ctrl.spinner = false;
              throw error;
            });
          }

          function onChanges() { }

          function onDestroy() { }

          function closeModal() {
            $ctrl.dismiss();
          }

          function chooseOrganization(org) {
            $ctrl.close({ $value: org });
          }
        }
    });
})();
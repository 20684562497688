/**
 * @ngdoc Component
 * @name tallyfy.steps.component.step
 * @module tallyfy.steps
 *
 * @description
 * A component to manage step
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('step', {
      bindings: {
        step: '<',
        onDelete: '&',
        onUpdate: '&'
      },
      require: {
        stepsCtrl: '^steps',
        processCtrl: '^processEdit'
      },
      templateUrl: 'app/modules/steps/step/step.html',
      controller:
        /*@ngInject*/
        function (_, $log, StepService, blockUI, Growl, $scope, $q, $rootScope, $confirm, $filter, DOM, UtilsService, TFY_EVENTS, DESCRIPTIONSIZE, $timeout, $state, DOMService, Helper, koStepService) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('editProcess'),
            unregisterSaveEventHandler,
            unregisterDiscardEventHandler,
            updateStepAndPrerunEventHandler,
            stepFormChangesHandler,
            oldStepTitle;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.stepForm = {};
          $ctrl.injectedTab = {};
          $ctrl.deadlineFormInvalid = false;
          $ctrl.editableClicked = false;
          $ctrl.activeTab = {};
          $ctrl.lastActiveTab = {};
          $ctrl.isStepDeleting = false;
          $ctrl.stepsOwners = [];
          $ctrl.isDragging = false;

          /**
           * public methods
           */
          $ctrl.isSelectedStepDirty = isSelectedStepDirty;
          $ctrl.updateOriginalStep = updateOriginalStep;
          $ctrl.toggleStepView = toggleStepView;
          $ctrl.deleteStep = deleteStep;
          $ctrl.updateStep = updateStep;
          $ctrl.setFormValid = setFormValid;
          $ctrl.setFormPristine = setFormPristine;
          $ctrl.onDiscard = onDiscard;
          $ctrl.showEditableTitle = showEditableTitle;
          $ctrl.hideEditableTitle = hideEditableTitle;
          $ctrl.updateStepItem = updateStepItem;
          $ctrl.hideSaveStepButton = hideSaveStepButton;
          $ctrl.openDeleteModal = openDeleteModal;
          $ctrl.setTabValue = setTabValue;

          /**
           * @function
           * @name initialization
           * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.maxTitle = DESCRIPTIONSIZE.maxTitle;
            $ctrl.ownerList = angular.copy($ctrl.processCtrl.availableUsers);
            $ctrl.groups = angular.copy($ctrl.stepsCtrl.groups);
            updateStepOwner($ctrl.step);
            prepareStepOwner($ctrl.step);
            $ctrl.step.deadline.value = (!$ctrl.step.deadline.value && $ctrl.step.deadline.value !== 0) ? 1 : _.toNumber($ctrl.step.deadline.value);
            $ctrl.masterViewElement = DOM.MASTER_VIEW.ELEMENT;
            $ctrl.containerOffset = DOM.MASTER_VIEW.STYLE.padding_top;
            $ctrl.conditionTerms = getStepCondition($ctrl.step);
            oldStepTitle = angular.copy($ctrl.step.title);
            $ctrl.step.webhookEnable = !!(_.get($ctrl.step, 'webhook.length', false));

            if ($ctrl.step.title.length > $ctrl.maxTitle) {
              $ctrl.step.title = $ctrl.step.title.substring(0, $ctrl.maxTitle);
            }
            /**
             * check if @param step is exists in query string
             * get step and find step by alias;
             * expand step
             * make step visible
             */
            var currentStep = _.get($rootScope, 'toStateParams.step', '');
            if (currentStep && $ctrl.step.alias === currentStep) {
              setEditableView($ctrl.step, true);
              $timeout(function () {
                DOMService.centerObjectToView('#step_' + $ctrl.step.id);
              }, 150);
            }
          }

          /**
           * @function
           * @name onChanges
           * @param {Object} bindings
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.step && !bindings.step.isFirstChange()) {
              if ($ctrl.isDiscard) {
                var idx = _.findIndex($ctrl.stepsCtrl.steps, {
                  id: _.get(bindings.step, 'previousValue.id')
                });
                $ctrl.stepsCtrl.steps[idx] = angular.copy(bindings.step.previousValue);
                $ctrl.isDiscard = false;
              }
              $ctrl.conditionTerms = getStepCondition(bindings.step.currentValue);
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Useful to release external resources, watches and event handlers.
           */
          function onDestroy() {
            if (stepFormChangesHandler) {
              stepFormChangesHandler();
            }
            unregisterDiscardEventHandler();
            unregisterSaveEventHandler();
            updateStepAndPrerunEventHandler();
            onKOStepOpenEventHandler();
            onBPDescOpenEventHandler();
            openSpecificHandler();
          }

          /**
           * @function
           * @name toggleStepView
           * @description Toggle step view. Set $ctrl.selectedTabValue empty for default value
           * @param {*} $event
           * @returns void
           */
          function toggleStepView($event) {
            if (angular.copy($ctrl.selectedTabValue)) {
              $ctrl.selectedTabValue = "";
            }
            $ctrl.isAllowToScrollIntoView = !$ctrl.step.isExpanded;

            if (preventOpenStep($event) || isExistStepTitle()) {
              $event.stopImmediatePropagation();
              return;
            }

            if ($ctrl.stepsCtrl.selectedStep.$dirty) {
              isSelectedStepDirty($ctrl.stepsCtrl.selectedStep, true, $event.name);
            } else if ($ctrl.stepsCtrl.isBpDescExpanded) {
              var isBPDescDirty = StepService.isDescHasUnsavedChanges($ctrl.stepsCtrl.oldProcess.summary, $ctrl.stepsCtrl.process);
              if (isBPDescDirty) {
                $rootScope.$emit('STEP:OPEN', { step: $ctrl.step });
              } else {
                openStep();
              }
            } else if ($ctrl.stepsCtrl.isKoStepExpanded) {
              var isKOStepDirty = koStepService.isKOFormDirty($ctrl.stepsCtrl.process, $ctrl.stepsCtrl.oldProcess);
              if (isKOStepDirty) {
                $rootScope.$emit('STEP:OPEN', { step: $ctrl.step });
              } else {
                openStep();
              }
            } else {
              openStep();
            }
          }

          function openStep() {
            $ctrl.editableClicked = ($ctrl.step.isExpanded) ? false : $ctrl.editableClicked;
            setEditableView($ctrl.step, true);
          }

          /**
           * @function 
           * @name preventOpenStep
           * @description Helper function to prevent opening/closing step's card
           * @param {*} $event
           * @return {Boolean}
           */
          function preventOpenStep($event) {
            var invalidFields = StepService.showInvalidStepFields($ctrl.stepsCtrl.selectedStep, $ctrl.stepsCtrl.selectedStepLastActiveTab);
            return invalidFields || ($event.type === 'keypress' && ($event.which === 32 || $event.which === 13));
          }

          /**
           * @function
           * @name  setEditableView
           * @description An helper function to expand/collapse step
           * @param {Object} currentStep A selected Step
           * @param {Boolean} isClosed
           * @returns void
           */
          function setEditableView(currentStep, isClosed) {
            if ($ctrl.stepsCtrl.isDragging) {
              $ctrl.stepsCtrl.isDragging = false;
              hideEditableTitle();
              return;
            }
            if (currentStep.isExpanded && isClosed) {
              resetStep(currentStep);

              //Remove step alias from the state param on close
              updateRouteParams({
                step: ''
              });
              return;
            }
            _.forEach($ctrl.stepsCtrl.steps, function (step) {
              if (step.isExpanded) {
                step.isExpanded = false;
              }
            });
            $ctrl.stepsCtrl.selectedStep.isExpanded = false;
            collapsCapture();
            currentStep.isExpanded = true;
            $ctrl.stepsCtrl.selectedStep = currentStep;
            if (!stepFormChangesHandler) {
              watchFormChanges();
            }

            //update stepID in route params
            updateRouteParams({
              step: currentStep.alias
            });
          }

          /**
           * @function
           * @name collapsCapture
           * @description Collaps all capture when step collapse/expand
           * @returns {void}
           */
          function collapsCapture() {
            _.forEach($ctrl.stepsCtrl.selectedStep.captures, function (field) {
              if (field.isExpanded) {
                field.isExpanded = false;
              }
            });
          }

          /**
           * @function 
           * @name onDiscard
           * @param {Object} step
           * @param {Object} tab
           * @param {*} triggerSource
           * @description Helper function to be used to discard changes in step.
           * @returns void
           */
          function onDiscard(step, tab, triggerSource) {
            var currentTab = !_.isEmpty(tab) ? tab.value : '';
            var originalStep = getOriginalStep(step.id);
            switch (currentTab) {
              case 'describe_capture':
                discardBasicInfo(step, originalStep);
                discardFormFields(step, originalStep);
                break;
              case 'assign':
                discardAssignee(step, originalStep);
                break;
              case 'settings':
                discardSettings(step, originalStep);
                break;
              case 'logic':
                discardLogic(step, originalStep);
                break;
              case 'set-deadline':
                discardDeadline(step, originalStep);
                break;
              default:
                discardChanges(step, originalStep);
                break;
            }
            delete step.$dirty;
            if (!triggerSource) {
              $ctrl.isDiscard = true;
            }
            setFormValid();
          }

          /**
           * @function
           * @name deleteStep
           * @description delete step
           * @param {Object} $event
           * @param {Object} step
           * @returns void
           */
          function deleteStep($event, step) {
            if (!Helper.checkAccessAuthority())
              return;
            $event.stopImmediatePropagation();
            var stepRule = [],
              deadlineRule = [];

            stepRule = StepService.validateRule($ctrl.stepsCtrl.steps, step);
            if (stepRule.length) {
              StepService.openRuleModal(stepRule, 'Step', $ctrl.processCtrl.process);
              return;
            }

            if (stepRule.length === 0) {
              deadlineRule = StepService.validateDeadlineRule($ctrl.stepsCtrl.steps, step);
              if (deadlineRule.length) {
                StepService.openRuleModal(deadlineRule, 'Deadline');
                return;
              }
            }
            openDeleteModal(step);
          }

          /**
           * @ngdoc method
           * @name openDeleteModal
           * @description open delete confirmation modal
           * @param {Object} step
           * @returns void
           */
          function openDeleteModal(step) {
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', {
                actionName: $filter('translate')('global.confirmAction.actionDelete'),
                componentName: 'Step'
              }),
              'body': $filter('translate')('global.confirmAction.stepDelete', {
                actionName: _.toLower($filter('translate')('global.confirmAction.actionDelete')),
                componentName: $ctrl.step.title
              }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', {
                  actionName: $filter('translate')('global.confirmAction.actionDelete')
                }),
                'cancel': 'global.confirmAction.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              blockUI.start();
              $ctrl.isStepDeleting = true;
              StepService.deleteStep({
                id: step.id,
                checklist_id: $ctrl.stepsCtrl.process.id
              }).then(function () {
                $ctrl.onDelete({
                  step: $ctrl.step
                });
                growl.success($filter('translate')('steps.messages.stepDeleted'), {
                  referenceId: 'growlNotification',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $ctrl.isStepDeleting = false;
                blockUI.stop();
              }, function () {
                blockUI.stop();
                $ctrl.isStepDeleting = false;
              });
            }, function () {
              $log.info('Delete step is cancelled');
            });
          }

          /**
           * @ngdoc method
           * @name updateStep
           * @description Update the step
           * @param {*} form
           * @returns {Promise}
           */
          function updateStep(form) {
            if (!Helper.checkAccessAuthority())
              return;
            var webHookFrom = _.get(form, '$ctrl.webhookForm', {}),
              changeActiveStep;

            if (form) {
              $timeout(function () {
                form.$setSubmitted();
              }, 0);
            }

            if (_.isUndefined(form) || (form && !webHookFrom.$dirty)) {
              return updateStepItem($ctrl.stepsCtrl.selectedStep);
            }

            if (webHookFrom.$dirty && webHookFrom.$valid && !$ctrl.isError) {
              $ctrl.onSaving = true;
              UtilsService.checkURL($ctrl.stepsCtrl.selectedStep.webhook).then(function () {
                $ctrl.isError = false;
                $ctrl.onSaving = false;
                updateStepItem($ctrl.stepsCtrl.selectedStep);
              }, function () {
                $ctrl.isError = true;
                $ctrl.onSaving = false;
                if ($ctrl.activeTab.value !== 'settings') {
                  $ctrl.injectedTab.switchTab('#tab-settings-step-', $ctrl.step.id);
                  changeActiveStep = $ctrl.activeTab;
                  $ctrl.activeTab = $ctrl.lastActiveTab;
                  $ctrl.lastActiveTab = changeActiveStep;
                }
                $rootScope.$broadcast('TAB:CHANGE_ACTIVE_TAB', $ctrl.activeTab);
              });
            }
          }

          /**
           * @ngdoc method
           * @name updateStepTitle
           * @description update step with the step title
           * @returns {Promise}
           */
          function updateStepTitle() {
            if ($ctrl.stepsCtrl.selectedStep) {
              $ctrl.stepsCtrl.selectedStep.title = $ctrl.step.title;
            }
          }

          /**
           * @ngdoc method
           * @name updateStepItem
           * @description update step with the step item
           * @param {Object} stepItem
           * @param {*} isTitle
           * @returns {Promise}
           */
          function updateStepItem(stepItem, isTitle) {
            stepItem.title = $ctrl.step.title;
            if (isTitle && _.isEmpty($ctrl.step.title)) {
              $ctrl.step.title = $filter('translate')('steps.setStepTitle', { stepNumber: $ctrl.step.position });
            }
            $ctrl.isExistsTitle = false;
            if (isTitle && _.lowerCase(oldStepTitle) === _.lowerCase($ctrl.step.title)) {
              hideEditableTitle();
              updateStepTitle();
              return;
            } else if (_.isEmpty($ctrl.step.title)) {
              $timeout(function () {
                growl.error($filter('translate')('steps.messages.stepTitleRequired'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 200);
              $ctrl.isExistsTitle = true;
              updateStepTitle();
              return;
            }
            prepareOwnerData(stepItem);
            prepareOldCaptures(stepItem); // Old captures has "textfield" type, which should be "text"

            var step = angular.copy(stepItem),
              deffered = $q.defer();

            if ($ctrl.deadlineFormInvalid) {
              $ctrl.injectedTab.switchTab('#tab-set-deadline-step-', $ctrl.step.id);
              growl.error($filter('translate')('steps.deadlines.messages.deadlineFormError'), {
                referenceId: 'growlNotification',
                disableIcons: true,
                disableCloseButton: true
              });
              return;
            }

            // Check conditions are valid
            if (!_.isNull(step.condition) && !_.get(step.condition, 'terms.length')) {
              step.condition = [];
            } else {
              var isValid = StepService.validateConditions(step.condition);
              if (isValid) {
                $ctrl.injectedTab.switchTab('#tab-logic-step-', $ctrl.step.id);
                return;
              }
            }

            if (step.isWebhookRequired) {
              $ctrl.injectedTab.switchTab('#tab-settings-step-', $ctrl.step.id);
              return;
            }

            beforeStepUpdate(step);

            step = _.omit(step, ['metadata', 'isWebhookRequired', 'webhookEnable', 'additional_links', 'tags', 'deadline_rules']);

            $ctrl.onSaving = true;
            StepService.updateStep({
              id: step.id,
              checklist_id: $ctrl.stepsCtrl.process.id,
              skipNotFound: true
            }, step).then(function (response) {
              var updatedStep = angular.copy(response.data);
              updateOriginalStep(step.id, updatedStep);
              $rootScope.$emit('STEP:UPDATE_DEADLINE');
              if (stepItem.isExpanded) {
                updatedStep.isExpanded = true;
              }
              updatedStep.webhookEnable = !!(_.get(updatedStep, 'webhook.length', false));
              $ctrl.onSaving = false;
              $ctrl.stepSaved = true;
              updateTooltipOnRule();
              oldStepTitle = updatedStep.title;
              hideEditableTitle();
              $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { last_updated: updatedStep.last_updated });
              if (_.isUndefined(isTitle)) {
                updatedStep.deadline.value = Number(updatedStep.deadline.value); // Value is string in response
                updatedStep = prepareStepOwner(updatedStep);
                $ctrl.onUpdate({
                  step: updatedStep
                });
                setFormValid();
                stepItem.$dirty = false;
                $rootScope.$emit("STEP:UPDATE_STEP", updatedStep);
              } else {
                $rootScope.$emit("STEP:UPDATE_STEP_NAME");
              }
              updateStepInProcess(updatedStep);
              deffered.resolve();
            }, function () {
              $ctrl.onSaving = false;
              deffered.reject();
            });

            return deffered.promise;
          }

          /**
           * @name updateStepInProcess
           * @param {Object} updatedStep
           * @description Updated step details on parent component
           * @returns void
           */
          function updateStepInProcess(updatedStep) {
            var index = _.findIndex($ctrl.processCtrl.process.steps.data, {
              'id': updatedStep.id
            });
            if (index >= 0) {
              angular.extend($ctrl.processCtrl.process.steps.data[index], updatedStep);
            }
          }

          /**
           * @ngdoc method
           * @name beforeStepUpdate
           * @description Some operation on step before updating the step
           * @param {Object} step
           * @returns {Object} step
           */
          function beforeStepUpdate(step) {
            if (step.captures.length > 0) {
              _.forEach(step.captures, function (capture) {
                delete capture.isExpanded;
                if (capture.isNew) {
                  capture.id = null;
                  delete capture.isNew;
                }
                if (_.get(capture, 'options.length', 0)) {
                  capture.options = _.map(capture.options, function (option) {
                    if (option.isNew) {
                      return {
                        text: option.text,
                        value: option.value,
                        id: option.id
                      };
                    }
                    return option;
                  });
                }
              });
            }
            return step;
          }

          /**
           * @ngdoc method
           * @name watchFormChanges
           * @description Watch on collection
           * @returns {any}
           */
          function watchFormChanges() {
            stepFormChangesHandler = $scope.$watch('$ctrl.stepForm.$dirty', function () {
              if ($ctrl.stepsCtrl.selectedStep) {
                $ctrl.stepsCtrl.selectedStep.$dirty = _.get($ctrl.stepForm, '$dirty', false);
              }
            }, true);
          }

          /**
           * @ngdoc method
           * @name prepareOwnerData
           * @description Prepare owner data of the step
           * @param {*} selectedStep
           * @returns void
           */
          function prepareOwnerData(selectedStep) {
            selectedStep.assignees = [];
            selectedStep.selectedStepOwners = selectedStep.selectedStepOwners || [];
            selectedStep.selectedGroups = selectedStep.selectedGroups || [];

            if (selectedStep.owner === 'specific') {
              _.forEach(selectedStep.selectedStepOwners, function (value, key) {
                if (_.has(value, 'id')) {
                  selectedStep.assignees.push(value.id);
                }
              });
              if (_.isArray(selectedStep.assignees) &&
                (selectedStep.assignees.length === 0 &&
                  selectedStep.selectedGroups.length === 0)) {
                selectedStep.owner = 'run_starter';
                var stepDefaultOwner = _.find($ctrl.ownerList, function (owner) {
                  return owner.id === $rootScope.identity.id;
                });
                selectedStep.selectedStepOwners.push(stepDefaultOwner);
              }

            } else if (selectedStep.owner === 'everyone') {
              selectedStep.selectedStepOwners = [];
              _.forEach($ctrl.ownerList, function (userVal) {
                selectedStep.selectedStepOwners.push(angular.copy(userVal));
              });
            }
            selectedStep.assignees = selectedStep.assignees || [];
          }

          /**
           * @ngdoc method
           * @name prepareOldCaptures
           * @param {Object} selectedStep 
           * @description Replace the old capture field type for textfield
           * @returns void
           */
          function prepareOldCaptures(selectedStep) {
            _.forEach(selectedStep.captures, function (capture) {
              if (capture.field_type === 'textfield') {
                capture.field_type = 'text';
              }
            });
          }

          /**
           * @ngdoc method
           * @name prepareStepOwner
           * @description Restructure the step owner obejct
           * @param {Object} step
           * @returns {Object} step
           */
          function prepareStepOwner(step) {
            return StepService.prepareStepAssignees(step, $ctrl.ownerList);
          }

          /**
           * @ngdoc method
           * @name setFormValid
           * @description Make the form valid
           * @returns void
           */
          function setFormValid() {
            if ($ctrl.stepForm) {
              typeof $ctrl.stepForm.$setPristine === 'function' ? $ctrl.stepForm.$setPristine() : angular.noop();
              typeof $ctrl.stepForm.$setUntouched === 'function' ? $ctrl.stepForm.$setUntouched() : angular.noop();
            }
          }

          /**
           * @ngdoc method
           * @name setFormPristine
           * @param {any} flag
           * @returns void
           */
          function setFormPristine(flag) {
            if ($ctrl.stepForm) {
              $ctrl.stepForm.$pristine = flag;
            }
            $ctrl.deadlineFormInvalid = flag;
          }

          /**
           * @ngdoc method
           * @name resetStep
           * @description Reset the step attribute
           * @param {Object} step
           * @returns void
           */
          function resetStep(step) {
            step.isExpanded = false;
            $ctrl.stepsCtrl.selectedStep = {};
            if (stepFormChangesHandler) {
              stepFormChangesHandler();
              stepFormChangesHandler = undefined;
            }
          }

          /**
           * @ngdoc method
           * @name showEditableTitle
           * @param {Object} step
           * @param $event
           * @description Callback function if show the title with edit form
           * @returns void
           */
          function showEditableTitle(step, $event) {
            if ($ctrl.stepsCtrl.selectedStep.$dirty) {
              return;
            }
            if (isExistStepTitle()) {
              return;
            }
            $event.stopPropagation();
            $ctrl.editableClicked = true;
            if (!step.isExpanded) {
              setEditableView(step, true);
            }
          }

          /**
           * @ngdoc method
           * @name hideEditableTitle
           * @description Callback function if hide the title with edit form
           * @returns void
           */
          function hideEditableTitle() {
            $ctrl.editableClicked = false;
          }

          /**
           * @ngdoc method
           * @name updateStepOwner
           * @param {Object} step
           * @description Change steps owner value
           * @returns void
           */
          function updateStepOwner(step) {
            if (step.owner === 'everyone') {
              step.owner = "run_starter";
            }
          }

          /**
           * capture event SAVE_STEP_CHANGES and unregister when component is destroyed
           * @see onDestroy
           */
          unregisterSaveEventHandler = $rootScope.$on('SAVE_STEP_CHANGES', function (event, stepId) {
            if (stepId === $ctrl.step.id) {
              updateStep();
            }
          });

          /**
           * capture event DISCARD_STEP_CHANGES and unregister when component is destroyed
           * @see onDestroy
           */
          unregisterDiscardEventHandler = $rootScope.$on('DISCARD_STEP_CHANGES', function (event, stepId) {
            if (stepId === $ctrl.stepsCtrl.selectedStep.id) {
              onDiscard($ctrl.stepsCtrl.selectedStep);
            }
          });

          /**
           * @ngdoc method
           * @name hideSaveStepButton
           * @description Hide Save Step button in capture and logic tab
           * @returns {boolean|Number}
           */
          function hideSaveStepButton() {
            var iswebhookOff;
            if ($ctrl.activeTab.value === 'settings') {
              iswebhookOff = !(_.get($ctrl.stepsCtrl, 'selectedStep.webhookEnable'));
            }
            return ($ctrl.activeTab.value === 'describe_capture') || ($ctrl.activeTab.value === 'logic') || iswebhookOff;
          }

          /**
           * @function
           * @name discardBasicInfo
           * @description Discard basic info
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardBasicInfo(step, originalStep) {
            step.summary = angular.copy(originalStep.summary);
            setFormValid();
          }

          /**
           * @function
           * @name discardAssignee
           * @description Discard assignee changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardAssignee(step, originalStep) {
            step.owner = angular.copy(originalStep.owner);
            step.allow_guest_owners = angular.copy(originalStep.allow_guest_owners);
            getStepDefaultOwner(step, originalStep);
          }

          /**
           * @function
           * @name getStepDefaultOwner
           * @private
           * @description Get default assignee of steps
           * @param {object} step
           * @param {object} originalStep
           * @returns void
           */
          function getStepDefaultOwner(step, originalStep) {
            var stepDefaultStepOwners = [];
            _.forEach(originalStep.assignees, function (id) {
              stepDefaultStepOwners.push(_.find($ctrl.ownerList, function (owner) {
                return owner.id === id;
              }));
            });
            step.selectedStepOwners = angular.copy(stepDefaultStepOwners);
          }

          /**
           * @function
           * @name discardSettings
           * @description discard settings changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardSettings(step, originalStep) {
            step.skip_start_process = angular.copy(originalStep.skip_start_process);
            step.webhook = angular.copy(originalStep.webhook) || '';
            step.webhookEnable = !!(_.get(step, 'webhook.length', false));
            step.isWebhookRequired = false;
            step.alias = angular.copy(originalStep.alias);
            $ctrl.isError = false;
          }

          /**
           * @function
           * @name discardFormFields
           * @description discard form fields changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardFormFields(step, originalStep) {
            step.captures = angular.copy(originalStep.captures);
            $rootScope.$emit('STEP:UPDATED', step);
            $rootScope.$emit(TFY_EVENTS.STEP.UPDATE_DRAWER_STEP, { step: step });
          }

          /**
           * @function
           * @name discardLogic
           * @description discard logic changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardLogic(step, originalStep) {
            if (_.isArray(step.condition) && step.condition.length === 0) {
              step.condition = {};
            }
            step.condition = angular.copy(originalStep.condition);
            $ctrl.conditionTerms = getStepCondition(step);
            $rootScope.$emit("STEP:UPDATE_STEP", step);
          }

          /**
           * @function
           * @name discardDeadline
           * @description discard deadline changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardDeadline(step, originalStep) {
            $rootScope.$broadcast('STEP:DEADLINE_CHANGED', originalStep.deadline);
            step.deadline = angular.copy(originalStep.deadline);
            step.deadline_time = angular.copy(originalStep.deadline_time);
          }

          /**
           * @function
           * @name discardChanges
           * @description Discard all changes
           * @param step
           * @param originalStep
           * @returns void
           */
          function discardChanges(step, originalStep) {
            $ctrl.stepSaved = false;
            discardBasicInfo(step, originalStep);
            discardFormFields(step, originalStep);
            discardAssignee(step, originalStep);
            discardSettings(step, originalStep);
            discardLogic(step, originalStep);
            discardDeadline(step, originalStep);
          }

          /**
           * @function
           * @name getOriginalStep
           * @param id
           * @description Get original step
           * @returns step
           */
          function getOriginalStep(id) {
            return _.filter($ctrl.stepsCtrl.originalSteps, {
              'id': id
            })[0];
          }

          /**
           * @function
           * @name getStepCondition
           * @param step
           * @description Get condition's terms from step
           * @returns condition's terms
           */
          function getStepCondition(step) {
            var terms = _.get(step, 'condition.terms') || [];
            if (terms.length) {
              $ctrl.step.ruleTerms = getTooltipOnRule(angular.copy(terms));
            }
            return terms;
          }

          /**
           * @function
           * @name getTooltipOnRule
           * @param terms
           * @description Show logic condition on the step when user hover on logic icon
           * @returns {Object} 
           */
          function getTooltipOnRule(terms) {
            var steps = _.orderBy($ctrl.stepsCtrl.steps, 'position');
            _.forEach(terms, function (term) {
              if (term.subject_type === 'Step') {
                term.conditions = getRuleStatement(term.subject_id);
                term.statement = $filter('translate')('steps.logic.tooltip.' + term.statement);
              }
              if (term.subject_type === 'Capture') {
                _.forEach(steps, function (step) {
                  updateTerms(step.captures, term);
                });
              }
              if (term.subject_type === 'Prerun') {
                var preruns = _.get($ctrl.processCtrl.process, 'prerun');
                updateTerms(preruns, term);
              }
            });
            return terms;
          }

          /**
           * @function
           * @name updateTerms
           * @param items
           * @param term
           * @description Update step logic terms
           * @returns void
           */
          function updateTerms(items, term) {
            _.forEach(angular.copy(items), function (item) {
              if (term.subject_id === item.id) {
                if (item.field_type === 'table' && term.operation === 'contains') {
                  term.operation = term.column_contains_name ? 'contains' : 'table_contains';
                }
                term.conditions = term.subject_type === 'Capture' ? getRuleStatement(item.step_id) : $filter('translate')('steps.logic.tooltip.kickOffForm');
                term.operation = term.subject_type === 'Capture' ? $filter('translate')('steps.logic.tooltip.stepFormFields.' + term.operation, {
                  "label": item.label,
                  "statement": term.statement,
                  "column": term.column_contains_name ? _.find(item.columns, { id: parseInt(term.column_contains_name) }).label : void 0
                }) : term.operation = $filter('translate')('steps.logic.tooltip.kickOffFormFields.' + term.operation, {
                  "label": item.label,
                  "statement": term.statement,
                  "column": term.column_contains_name ? _.find(item.columns, { id: parseInt(term.column_contains_name) }).label : void 0
                });
              }
            });
          }

          /**
           * @function
           * @name getRuleStatement
           * @param id
           * @description return step position and its name
           * @returns {Object} 
           */
          function getRuleStatement(id) {
            var index = _.findIndex($ctrl.stepsCtrl.steps, ['id', id]);
            if (index >= 0) {
              var step = $ctrl.stepsCtrl.steps[index];
              return $filter('translate')('steps.logic.tooltip.if', {
                "stepNumber": step.position,
                "stepTitle": step.title
              });
            }
          }

          /**
           * @function
           * @name updateTooltipOnRule
           * @description update tooltip value when step and prerun updated
           * @returns void
           */
          function updateTooltipOnRule() {
            _.forEach($ctrl.stepsCtrl.steps, function (step) {
              var terms = _.get(step, 'condition.terms') || [];
              if (terms.length) {
                step.ruleTerms = getTooltipOnRule(angular.copy(terms));
              }
            });
          }

          /**
           * @function
           * @name updateOriginalStep
           * @param id
           * @param updatedStep
           * @description update original step with updated data
           * @returns void
           */
          function updateOriginalStep(id, updatedStep) {
            var idxOriginal = _.findIndex($ctrl.stepsCtrl.originalSteps, {
              id: id
            });
            if (idxOriginal >= 0) {
              angular.extend($ctrl.stepsCtrl.originalSteps[idxOriginal], angular.copy(updatedStep));
            }

            var idxProcessSteps = _.findIndex($ctrl.processCtrl.process.steps.data, {
              id: id
            });
            if (idxProcessSteps >= 0) {
              angular.extend($ctrl.processCtrl.process.steps.data[idxProcessSteps], angular.copy(updatedStep));
            }
          }

          /**
           * @function
           * @name isExistStepTitle
           * @description It will check if step is exist or not
           * @returns {*} true/false
           */
          function isExistStepTitle() {
            return _.keys($ctrl.stepsCtrl.selectedStep).length && (_.isEmpty(_.get($ctrl.stepsCtrl, 'selectedStep.title', $ctrl.step.title)));
          }

          /**
           * @function
           * @name setTabValue
           * @param {Object} $event
           * @param {String} tab
           * @description Open selected step and tab with its content when clicking on icon in step's card
           * $ctrl.selectedTabValue used in step.edit.component.js
           * @returns void
           */
          function setTabValue($event, tab) {
            $event.stopImmediatePropagation();
            if (isExistStepTitle()) {
              return;
            }
            $ctrl.selectedTabValue = tab;
            if ($ctrl.step.isExpanded) {
              if (StepService.validateStepFields($ctrl.stepsCtrl.selectedStep) || _.isEqual($ctrl.activeTab.value, tab)) {
                return;
              }
              if ($ctrl.step.$dirty || $ctrl.stepsCtrl.selectedStep.$dirty) {
                isSelectedStepDirty($ctrl.step, false);
              }
              $ctrl.injectedTab.switchTab('#tab-' + tab + '-step-', $ctrl.step.id);
              $rootScope.$emit('STEP:SWITCHTAB', tab);
              return;
            }
            toggleStepView($event, $ctrl.step);
            $ctrl.selectedTabValue = tab;
          }

          /**
           * event handler when steps and prerun add or delete
           * @type {*|(function())}
           */
          updateStepAndPrerunEventHandler = $rootScope.$on('STEP:UPDATE_STEP_AND_PRERUN', function () {
            updateTooltipOnRule();
          });

          /**
           * @ngdoc method
           * @name isSelectedStepDirty 
           * @public
           * @description save or discard changes in selected step
           * @param {object} step
           * @param {*} shouldResetStep
           * @param {*} triggerSource
           * @returns void
           */
          function isSelectedStepDirty(step, shouldResetStep, triggerSource) {
            $confirm({
              'body': 'global.unsaved.body',
              'header': 'global.unsaved.header',
              'buttons': {
                'accept': 'global.unsaved.buttons.accept',
                'cancel': 'global.unsaved.buttons.cancel'
              },
              modalType: 'modal-blueprint'
            }).then(function () {
              updateStep().then(function () {
                if (step.id === $ctrl.stepsCtrl.selectedStep.id) {
                  step = $ctrl.stepsCtrl.selectedStep;
                }

                var editableStep = $ctrl.step.id !== $ctrl.stepsCtrl.selectedStep.id ? $ctrl.step : step;
                setEditableView(editableStep, shouldResetStep);
                $ctrl.stepsCtrl.checkTriggerFrom(triggerSource);
              });
            }, function () {
              setEditableView($ctrl.step, shouldResetStep);
              onDiscard(step, undefined, triggerSource);
              $ctrl.stepsCtrl.checkTriggerFrom(triggerSource);
            });
          }

          /**
           * @ngdoc
           * @name updateRouteParams
           * @description An helper function to update query params. this function does not reload state.
           * @param params {Object} A key-value pair
           * @returns void
           */
          function updateRouteParams(params) {
            params = params && !_.isObject(params) ? {} : params;
            $state.go('.', params, { notify: false });
          }

          var onKOStepOpenEventHandler = $rootScope.$on('KO_STEP:OPEN', function (e) {
            if ($ctrl.step.isExpanded && $ctrl.stepsCtrl.selectedStep.id === $ctrl.step.id) {
              isSelectedStepDirty($ctrl.stepsCtrl.selectedStep, true, e.name);
            }
          });

          var onBPDescOpenEventHandler = $rootScope.$on('BP_DESC:OPEN', function (e) {
            if ($ctrl.step.isExpanded && $ctrl.stepsCtrl.selectedStep.id === $ctrl.step.id) {
              isSelectedStepDirty($ctrl.stepsCtrl.selectedStep, true, e.name);
            }
          });

          var openSpecificHandler = $rootScope.$on('STEP:OPEN_TRIGGER', function (e, data) {
            if ($ctrl.step.id === data.step.id) {
              openStep();
            }
          });
          //controller ends
        }
    });
})();

/**
 * @ngdoc Component
 * @name tallyfy.organizations.component.organizationLogo
 * @module tallyfy.organizations
 *
 * @description
 * A component to display org logo
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('organizationLogo', {
      templateUrl: 'app/modules/organizations/organizationLogo/organization-logo.html',
      bindings: {
        placement: '<'
      },
      controller:
        /*@ngInject*/
        function (_, OrganizationsRepository, OrganizationsService, Helper, $rootScope, $state, AuthPlan, USER_STATE) {
          var $ctrl = this,
            organization = _.get($rootScope.identity, 'default_organization', {}),
            updateOrgLogoEventHandler;

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @function
           * @name initialization
           * @description  An component's lifecycle's hook which is called when component is mounted
           */
          function initialization() {
            $ctrl.appLogo = '';
            if ($rootScope.userState === USER_STATE.GUEST) {
              return;
            }
            if (showDefaultOrgLogo()) {
              var params = $ctrl.placement ? '' : AuthPlan.getCurrentPlanCode();
              $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(params);
              return;
            }
            getOrgLogo(organization);
          }

          /**
           * @function
           * @name showDefaultOrgLogo
           * @description return boolean
           */
          function showDefaultOrgLogo() {
            return $ctrl.placement || $state.current.name === 'organizations.list' || !_.get(organization, 'org_logo');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateOrgLogoEventHandler();
          }

          /**
           * @function
           * @name getOrgLogo
           * @param org
           * @description Get organization logo if file id exist
           */
          function getOrgLogo(org) {
            var fileId = OrganizationsService.getFileIdFromOrgLogoUrl(org.org_logo);
            if (_.isEmpty(fileId)) {
              $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(AuthPlan.getCurrentPlanCode());
              return;
            }
            getOrganizationLogo(org.id, fileId);
          }

          /**
           * @function
           * @name getOrganizationLogo
           * @param orgID
           * @param fileID
           * @description Get organization logo if file id exist
           */
          function getOrganizationLogo(orgID, fileID) {
            OrganizationsRepository.getOrganizationLogo({
              org: orgID,
              fileId: fileID
            }).then(function (response) {
              $ctrl.appLogo = Helper.getFileObjectUrl(response.data, response.contentType);
            }, function () {
              $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(AuthPlan.getCurrentPlanCode());
            });
          }

          /**
           * event handler when the organization logo has been changed
           * @type {*|(function())}
           */
          updateOrgLogoEventHandler = $rootScope.$on('ORGANIZATION:UPDATEORGLOGO', function (event, appLogo) {
            if (appLogo) {
              $ctrl.appLogo = appLogo.organization ? appLogo.organization.logo : appLogo;
              if(!$ctrl.appLogo) {                
                $ctrl.appLogo = OrganizationsService.getDefaultOrgLogoForPlan(null);
              }
              return;
            }
            getOrgLogo(organization);
          });
        }
    });
})();
/**
 * @ngdoc directive
 * @name tallyfy.compactStepContainer
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("compactStepContainer", compactStepContainer);

  /*@ngInject */
  function compactStepContainer(_, $timeout) {
    var domTimeoutHandler, container;
    return {
      restrict: 'A',
      scope: {
        compactStepContainer: '<'
      },
      link: function ($scope) {
        $timeout(function () {
          var body = angular.element('body');
          body.addClass('compact-step-active');
          if ($scope.compactStepContainer.type === 'document') {
            container = angular.element($scope.compactStepContainer.viewContainer);
            container.css('overflow-y', 'hidden').addClass('compact-step-document');
          }
        }, 0);

        function onDestroy() {
          var body = angular.element('body');
          body.removeClass('compact-step-active');
          if (domTimeoutHandler) {
            $timeout.cancel(domTimeoutHandler);
          }
          if ($scope.compactStepContainer.type === 'document') {
            container[0].removeAttribute('style');
            container.removeClass('compact-step-document');
          }
        }
        $scope.$on('$destroy', onDestroy);
      }
    }
  }
})();
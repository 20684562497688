(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('resetPassword', {
      parent: 'account',
      url: '/reset-password/:key',
      data: {
        authorities: []
      },
      views: {
        '': {
          component: 'resetFinish'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('reset');
          $translatePartialLoader.addPart('password');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
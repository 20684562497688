/**
 * @ngdoc Component
 * @name tallyfy.process.component.process
 * @module tallyfy.process
 *
 * @description
 * A component to manage process
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  var processComponent = {
    template: '<div ui-view="process_view"  class="animated fadeIn" focus-me focus-timeout="0"></div>',
    controller: 'ProcessController'
  };

  angular
    .module('tallyfy.process')
    .component('process', processComponent)
    .controller('ProcessController', [function () {
      var $ctrl = this;
    }]);
})();

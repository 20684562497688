(function () {
  'use strict';
  angular.module('tallyfy')
    .component('stylesheetEditor', {
      templateUrl: 'app/modules/organizations/customBranding/stylesheetEditor/stylesheet-editor.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $timeout, OrganizationsService, Growl, FroalaService, blockUI, Helper) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('updateStyle');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.saveStyle = saveStyle;
          $ctrl.closeModal = closeModal;

          function onInit() {
            $ctrl.organization = _.pick($rootScope.identity.default_organization, ['name', 'working_days', 'global_css']);
          }

          function onChanges() { }

          function onDestroy() { }

          function saveStyle() {
            if (!Helper.checkAccessAuthority())
              return;
            var jsonCss = FroalaService.validateOrgGlobalCssString($ctrl.organization.global_css);
            if (jsonCss) {
              blockUI.start();
              OrganizationsService.update(null, $ctrl.organization)
                .then(function (response) {
                  angular.extend($rootScope.identity.default_organization, response.data);
                  growl.success($filter('translate')('org_settings.messages.success'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                  blockUI.stop();
                }, function (e) {
                  blockUI.stop();
                });
            } else {
              $timeout(function() {
                growl.error($filter('translate')('org_settings.messages.globalStyleInvalid'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }

          function closeModal() {
            $ctrl.close();
          }
        }
    })
})();
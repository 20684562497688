(function () {
  'use strict';
  angular
    .module('tallyfy.settings')
    .component('guestLanguage', {
      templateUrl: 'app/modules/manage/guest-components/guest-language/guest-language.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $timeout, $filter, Growl, blockUI, GuestPreferenceRepository, TranslationService, Helper, USER_STATE) {
          var $ctrl = this,
            preferences = ['app_language', 'content_language'],
            growl = new Growl(),
            contentLanguageChangedHandler,
            blockUI = blockUI.instances.get('language-preference');

          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.saveAppLanguage = saveAppLanguage;
          $ctrl.saveContentLanguage = saveContentLanguage;

          $ctrl.supportedAppLanguages = TranslationService.getAppLanguages();
          $ctrl.supportedContentLanguages = TranslationService.getContentLanguages();

          function onInit() {
            blockUI.start();
            $ctrl.preferences = _.chain(preferences).map(function (p) {
              return {
                slug: p,
                metadata: undefined,
                value: undefined
              };
            }).keyBy(function (k) {
              return k.slug;
            }).value();
            GuestPreferenceRepository.getPreferences()
              .then(function (response) {
                $rootScope.identity.preferences = response.data;
                var pf = _.chain(response.data).filter(function (p) {
                  return _.includes(preferences, p.slug);
                }).keyBy(function (k) {
                  return k.slug;
                }).value();
                angular.extend($ctrl.preferences, pf);
                setPreferencesDefaultValue();
                $ctrl.isShowPreference = true;
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
          }

          function onChanges() { }

          function onDestroy() {
            contentLanguageChangedHandler();
          }

          function setPreferencesDefaultValue() {
            $ctrl.appLanguage = _.get($ctrl.preferences, "app_language.metadata.lang", TranslationService.getMyAppLanguage($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : $rootScope.identity.guest.preferences));
            $ctrl.contentLanguage = _.get($ctrl.preferences, "content_language.metadata.lang", TranslationService.getMyContentLanguage());
          }

          function saveAppLanguage(e) {
            e.stopImmediatePropagation();
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.appLanguageSaved = true;
            GuestPreferenceRepository.updatePreferences({
              slug: "app_language",
              value: !!$ctrl.appLanguage,
              metadata: $ctrl.appLanguage ? { lang: $ctrl.appLanguage } : void 0
            }).then(function () {
              $ctrl.appLanguageSaved = false;
              if ($ctrl.languageForm.$valid) {
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              }
            }, function () {
              $ctrl.appLanguageSaved = false;
            })
          }

          function saveContentLanguage(e) {
            e.stopImmediatePropagation();
            if (!Helper.checkAccessAuthority())
              return;
            $ctrl.contentLanguageSaved = true;
            GuestPreferenceRepository.updatePreferences({
              slug: "content_language",
              value: !!$ctrl.contentLanguage,
              metadata: $ctrl.contentLanguage ? { lang: $ctrl.contentLanguage } : void 0
            }).then(function () {
              $ctrl.contentLanguageSaved = false;
              if ($ctrl.languageForm.$valid) {
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              }
            }, function () {
              $ctrl.contentLanguageSaved = false;
            })
          }

          function showSavedAlert() {
            growl.success($filter('translate')('myAccount.messages.update.account'), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          contentLanguageChangedHandler = $rootScope.$on('CONTENT_LANGUAGE_CHANGED', function () {
            $ctrl.descriptionTranslateTo = TranslationService.getMyContentLanguage();
          });
        }
    });
})();

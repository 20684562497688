/**
 * @ngdoc Component
 * @name tallyfy.billing.Component.billing
 * @module tallyfy.billing
 *
 * @description
 * A component to manage billing
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.billing')
    .component('billing', {
      templateUrl: 'app/modules/billing/billing.html',
      controller:
      /*@ngInject*/
      function (_, $rootScope, PLANS, blockUI, OrganizationsService, AuthPlan, Growl, $filter, $state, Helper, MAILTO, ENV_CONFIG, store, UsersService) {
        var $ctrl = this,
        growl = new Growl();
        $ctrl.trialPlans = AuthPlan.getPlans();
        $ctrl.selectedTrialPlan = {};

        /**
         * Component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;
        $ctrl.copyPaymentUrl = copyPaymentUrl;

        // Public method
        $ctrl.changePlan = changePlan;
        $ctrl.onSelectedAmount = onSelectedAmount;
        $ctrl.amountList = OrganizationsService.getAmountList();
        $ctrl.discountsUrl = ENV_CONFIG.TALLYFY_PRICING_DISCOUNT_URL;
        $ctrl.comparePlansUrl = ENV_CONFIG.TALLYFY_PRICING_URL;
        $ctrl.bookingUrl = ENV_CONFIG.TALLYFY_BOOKING_URL;        
        
        /**
         * @function $onInit
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() {
          $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
          $ctrl.orgAccountUrl = _.get($ctrl.organization, 'subscription.data.account_manage_url');
          $ctrl.editCardUrl = _.get($ctrl.organization, 'subscription.data.billing_manage_url');
          $ctrl.tallyfyUrl = 'https://tallyfy.wufoo.com/forms/?formname=x1ppn8y00lni9lx&field7=' + $ctrl.organization.id;
          $ctrl.selectedTrialPlan = _.find($ctrl.trialPlans, { 'name': $ctrl.organization.preferred_trial_plan || _.first($ctrl.trialPlans).name });
          var timeDiff = Helper.getDiffInDays($ctrl.organization.created_on);
          $ctrl.pendingTrialDays = $ctrl.organization.free_trial_days > 0 ? !!(timeDiff <= $ctrl.organization.free_trial_days) : false;
          $ctrl.isAnnualPlan =_.includes(PLANS.ANNUAL_PLANS, $ctrl.organization.plan_code);
          Growl.clearAllMessages('orgBillingView');
          $ctrl.isTrialPlan = $ctrl.organization.plan_code === PLANS.FREE;
          if ($ctrl.isTrialPlan) {
            $ctrl.isExpand = true;
            if ($ctrl.pendingTrialDays) {
              $ctrl.trialDay = $ctrl.organization.free_trial_days - timeDiff;
              $ctrl.heading = $filter('translate')('billing.trialChangePlan');
            } else {
              $ctrl.heading = $filter('translate')('billing.message.planExpiredMsg');
            }
          } else {
            var subscription = _.get($ctrl.organization, 'subscription.data');
            store.getUsers().then(function (response) {
              $ctrl.lightUsers = _.filter(UsersService.getBilledUsers(response), { role: "light" });
              $ctrl.otherUsers = _.filter(UsersService.getBilledUsers(response), function(r) { return r.role !== "light"; });
              $ctrl.heading = $filter('translate')('billing.changePlan', { planName: subscription.plan_name, userCount: $ctrl.otherUsers.length, lightUserCount: $ctrl.lightUsers.length });
            });
          }
          $ctrl.selctedAmount = _.head($ctrl.amountList);
          updateMailLink();
        }

        /**
         * @ngdoc method
         * @name updateOrgPlan
         * @param {string} linkText
         * @description CA alert update Org Plan link text
         */
        function updateOrgPlan(linkText) {
          return { referenceId: 'orgBillingView', disableIcons: true, disableCloseButton: true, ttl: -1 ,
            variables: {
              method: function (param) {
                $state.go('updateOrgPlan', { org_id: param.org_id });
              },
              param: { "org_id": $ctrl.organization.id },
              linkText: $filter('translate')('billing.button.'+linkText)
            }
          };
        }
        
        /**
         * @ngdoc method
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }
        
        /**
          * @ngdoc method
          * @name changePlan
          * @description Change plan name
          * @param {Object} trialPlan
          * @returns {string} plan name
         */
         function changePlan(trialPlan) {
          if ($ctrl.selectedTrialPlan === trialPlan)
            return;
          $ctrl.selectedTrialPlan = trialPlan;
          blockUI.start();
          OrganizationsService.update(null, { name: $ctrl.organization.name, preferred_trial_plan: $ctrl.selectedTrialPlan.name, working_days: $rootScope.identity.default_organization.working_days })
          .then( function (response) {
            $rootScope.identity.default_organization.preferred_trial_plan = _.get(response.data, 'preferred_trial_plan');
            !_.get($rootScope.identity, 'default_organization.org_logo') ? $rootScope.$emit("ORGANIZATION:UPDATEORGLOGO") : angular.noop();
            $rootScope.$broadcast('MANAGE_SIDEBAR_BY_PLAN', trialPlan);
            growl.success($filter('translate')('billing.changedTrialPlanSuccessMsg', { planName: $ctrl.selectedTrialPlan.name}), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            blockUI.stop();
          }, function () {
            growl.error($filter('translate')('billing.changedTrialPlanSuccessMsg', { planName: $ctrl.selectedTrialPlan.name}), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            blockUI.stop();
          });
        }
        
        /**
         * @ngdoc method
         * @name onSelectedAmount
         * @param {Integer} amount
         * @description set selected amount
         */
        function onSelectedAmount(amount) {
          $ctrl.selctedAmount = amount;
          updateMailLink();
        }
 
        /**
         * @ngdoc method
         * @name updateMailLink
         * @description update mail subject with selected amount
         */
        function updateMailLink() {
          $ctrl.mail = MAILTO.HEY_TALLYFY +','+ MAILTO.AMIT_TALLYFY +','+ MAILTO.SUPPORT_TALLYFY;
          $ctrl.mailSubject = $filter('translate')('billing.title.mailSubject', {amount: _.get($ctrl.selctedAmount, 'value')});
        }

        function copyPaymentUrl(e) {
          Helper.copyToClipboard($ctrl.editCardUrl, 'paymentLinkCopied', e);
        }
      }
    });
})();
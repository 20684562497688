/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage rules tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepLogic', {
      bindings: {
        process: '<',
        step: '<',
        onLogicChange: "&",
        onSaving: '<',
        form: '=',
        isPublic: '<'
      },
      templateUrl: 'app/modules/steps/logic/logic.html',
      controller: StepLogicController
    });
  /*@ngInject*/
  function StepLogicController(_, StepService, $rootScope, AuthPlan, PLANS, $filter, $sce, Helper) {
    var $ctrl = this,
      updateStepAndPrerunCountEventHandler,
      firstSort = true,
      updateStepEventHandler,
      stepLogic = [],
      activeFilter = 'thisStepLogic',
      updateOtherStepEventHandler,
      unregisterSaveEventHandler;

    /**
     * component's lifeCycle hooks
     */
    $ctrl.$onInit = initialization;
    $ctrl.$onDestroy = onDestroy;
    $ctrl.$onChanges = onChanges;

    /**
     * Public properties
     */
    $ctrl.isLogicForm = true;
    $ctrl.logicFields = [];

    /**
     * Public method
     */
    $ctrl.showLogicForm = showLogicForm;
    $ctrl.addLogic = addLogic;
    $ctrl.deleteLogic = deleteLogic;
    $ctrl.isStarter = isStarter;
    $ctrl.toggleLogicTab = toggleLogicTab;
    $ctrl.getStepLogicCount = getStepLogicCount;
    $ctrl.openUpgradeModal = openUpgradeModal;

    /**
     * @function
     * @name initialization
     * @description
     * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
     */
    function initialization() {
      $ctrl.isProPlan = [PLANS.PRO, PLANS.PROMONTHLY, PLANS.PROANNUAL, PLANS.ENTERPRISE_ANNUAL, PLANS.ENTERPRISE_MONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
      if (Helper.isObjectEmpty($ctrl.step.condition)) {
        addLogic(true);
      }
      $ctrl.isLogicForm = StepService.isValidStepCondition($ctrl.step);
      prepareOtherLogic();
      $ctrl.htmlPopover = $sce.trustAsHtml($filter('translate')('steps.logic.tooltip.rule_help'));
      $ctrl.sortableOptions = getSortableOptions();
    }

    /**
     * @function
     * @name onChanges
     * @description
     * A component's lifeCycle hook which is called when bindings are updated.
     */
    function onChanges() { }

    /**
     * @function
     * @name onDestroy
     * @description
     * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
     * Usefull to release external resources, watches and event handlers.
     */
    function onDestroy() {
      updateStepAndPrerunCountEventHandler();
      updateStepEventHandler();
      updateOtherStepEventHandler();
      unregisterSaveEventHandler();
      if (!_.get($ctrl.step, 'condition.flag')) {
        $ctrl.step.condition = [];
        $rootScope.$emit('STEP:UPDATE_STEP', $ctrl.step);
      }
    }

    /**
     * @function
     * @name prepareOtherLogic
     * @description Prepare other step logic
     */
    function prepareOtherLogic() {
      var steps = _.get($ctrl.process, 'steps.data', []);
      $ctrl.otherLogic = [];
      _.forEach(steps, function (step) {
        var rules = _.get(step, 'condition.terms') || [];
        stepLogic = [];
        if (rules.length) {
          _.forEach(angular.copy(rules), function (rule) {
            if (rule.subject_type === 'Step') {
              updateStepLogic(rule, $ctrl.step);
            } else if (rule.subject_type == 'Capture') {
              var captures = _.get($ctrl.step, 'captures') || [];
              _.forEach(captures, function (item) {
                updateStepLogic(rule, item);
              });
            }
          });

          if (stepLogic.length) {
            $ctrl.otherLogic.push({
              otherStepPosition: step.position,
              OtherStepTitle: step.title,
              currentStepPosition: $ctrl.step.position,
              currentStepTitle: $ctrl.step.title,
              stepLogic: stepLogic,
              flag: step.condition.flag
            });
          }
        }
      });
      $ctrl.step.otherStepLogicCount = $ctrl.otherLogic.length;
      $ctrl.onLogicChange({
        step: $ctrl.step
      });
    }

    /**
     * @function
     * @name updateStepLogic
     * @description update step logic
     */
    function updateStepLogic(rule, step) {
      if (rule.subject_id == step.id) {
        if (step.field_type === 'table' && rule.operation === 'contains') {
          rule.operation = rule.column_contains_name ? 'contains' : 'table_contains';
        }
        stepLogic.push({
          subjectType: rule.subject_type,
          logic: rule.subject_type == 'Step' ? $filter('translate')('steps.logic.otherStepsLogic.step.' + rule.statement, {
            operation: rule.operation,
            column: rule.column_contains_name ? _.find(item.columns, { id: parseInt(rule.column_contains_name) }).label : void 0
          }) : $filter('translate')('steps.logic.otherStepsLogic.stepFormFields.' + rule.operation, {
            fieldName: step.label,
            statement: rule.statement,
            column: rule.column_contains_name ? _.find(item.columns, { id: parseInt(rule.column_contains_name) }).label : void 0
          })
        });
      }
    }

    /**
     * @function
     * @name showLogicForm
     * @description Display logic form with empty records
     */
    function showLogicForm() {
      if (!$ctrl.isLogicForm) {
        $ctrl.step.condition = StepService.getLogicStub();
      }
      $ctrl.isLogicForm = true;
      addLogic();
    }

    /**
     * @function
     * @name isStarter
     * @description
     * Returns if current user is on the starter plan
     * @returns boolean
     */
    function isStarter() {
      return AuthPlan.getCurrentPlanCode() === PLANS.FREE;
    }

    /**
     * @function
     * @name addLogic
     * @description
     * Add new logic
     */
    function addLogic(sample) {
      var isTerms = _.get($ctrl.step, 'condition.terms');
      if (_.isUndefined(isTerms)) {
        $ctrl.step.condition = StepService.getLogicStub();
      }

      var term = StepService.getLogicTermStub();
      if (sample) {
        $ctrl.step.condition.isFirst = true;
      }
      $ctrl.step.condition.terms.push(term);
      if (!sample) {
        var isFlagEmpty = _.isUndefined(_.get($ctrl.condition, 'flag'));
        if (isFlagEmpty) {
          $ctrl.step.condition.flag = 'show';
        }
        $ctrl.onLogicChange({ step: $ctrl.step });
        $ctrl.form.$setDirty();
      }
    }

    /**
     * @function
     * @name deleteLogic
     * @param index
     * @description
     * delete a logic
     */
    function deleteLogic(index) {
      if (!Helper.checkAccessAuthority())
        return;
      $ctrl.step.condition.terms.splice(index, 1);

      stepLogicChanges();
    }

    /**
     * @function
     * @name stepLogicChanges
     * @description update step logic
     */
    function stepLogicChanges() {
      $ctrl.isLogicForm = StepService.isValidStepCondition($ctrl.step);
      $ctrl.onLogicChange({ step: $ctrl.step });
      if (!_.get($ctrl.step, 'condition.isFirst')) {
        $rootScope.$emit('SAVE_STEP_CHANGES', $ctrl.step.id);
      }
      $rootScope.$emit('STEP:UPDATE_STEP', $ctrl.step);
      if (!_.get($ctrl.step, 'condition.terms', []).length) {
        $ctrl.form.$setPristine();
      }
    }

    /**
     * @ngdoc method
     * @name toggleLogicTab
     * @param {String} tabName
     * @description Return true/false value basen on value
     */
    function toggleLogicTab(tabName) {
      if (StepService.validateStepFields($ctrl.step)) {
        return;
      }
      if (activeFilter != tabName) {
        $ctrl.showOtherStepLogic = !$ctrl.showOtherStepLogic;
        activeFilter = tabName;
      }
    }

    /**
      * @ngdoc method
      * @name openUpgradeModal
      * @description Open upgrade modal
      */
    function openUpgradeModal() {
      if (!AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'stepAdvancedTab')) {
        return;
      }
    }

    /**
     * @ngdoc method
     * @name getStepLogicCount
     * @description return condition terms count
     * @returns {Integer} 
     */
    function getStepLogicCount() {
      var terms = _.get($ctrl.step, 'condition.terms') || [];
      return terms.length;
    }
    
    /**
     * @ngdoc method
     * @name getSortableOptions
     * 
     * @description
     * get sortable options
     */
    function getSortableOptions() {
      return {
        handle: '.logic-move-handle',
        scroll: true,
        axis: 'y',
        start: startHandler,
        stop: sortHandler,
        sort: dragHandler,
        animation: 500,
        disabled: false,
        scrollSpeed: 25,
        forceFallback: true,
        longTouch: true
      };
    }

    /**
     * @ngdoc method
     * @name dragHandler
     * @param {*} e 
     * 
     * @description
     * drag handler for sortable list options
     */
    function dragHandler(e) {
      $ctrl.pageY < e.pageY ? $(this).sortable("option", "scrollSensitivity", 10) : $(this).sortable("option", "scrollSensitivity", 208);
      $ctrl.pageY = e.pageY;
    }

    /**
     * @ngdoc method
     * @name startHandler
     * @param {*} e 
     * @param {*} ui 
     * 
     * @description
     * start handler for sortable list options
     */
    function startHandler(e, ui) {
      $ctrl.pageY = e.pageY;
      if (firstSort) {
        $(this).sortable("refreshPositions");
        firstSort = false;
      }
      ui.placeholder[0].style.visibility = "visible";
      ui.placeholder.height(ui.item.height());
    }

    /**
     * @ngdoc method
     * @name sortHandler
     * @param {*} e 
     * @param {*} ui 
     * 
     * @description
     * sort handler for sortable list options
     */
    function sortHandler(e, ui) {
      if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.step.condition.terms.length) {
        var item = $ctrl.step.condition.terms[ui.item.sortable.index], dropItem = $ctrl.step.condition.terms[ui.item.sortable.dropindex], itemPosition = item.position;
        item.position = dropItem.position;
        dropItem.position = itemPosition;
        $rootScope.$emit('SAVE_STEP_CHANGES', $ctrl.step.id);
      }
    }

    /**
     * event handler when step is updated
     * @type {*|(function())}
     */
    updateStepEventHandler = $rootScope.$on('STEP:UPDATE_STEP', function (event, updatedStep) {
      angular.extend($ctrl.step, updatedStep);
      $ctrl.isLogicForm = StepService.isValidStepCondition($ctrl.step);
    });

    /**
     * event handler when steps and prerun add or delete
     * @type {*|(function())}
     */
    updateStepAndPrerunCountEventHandler = $rootScope.$on('STEP:COUNT_TOTAL_STEPS_AND_PRERUN', function (event, totalStepsAndPrerun) {
      if (totalStepsAndPrerun <= 1) {
        $ctrl.isLogicForm = false;
        $ctrl.step.condition = StepService.getLogicStub();
        stepLogicChanges();
      }
    });

    /**
     * event handler when other step name updated
     * @type {*|(function())}
     */
    updateOtherStepEventHandler = $rootScope.$on('STEP:UPDATE_STEP_NAME', function (event) {
      prepareOtherLogic();
    });

    /**
     * capture event SAVE_STEP_CHANGES and unregister when component is destroyed
     * @see onDestroy
     */
    unregisterSaveEventHandler = $rootScope.$on('SAVE_STEP_CHANGES', function (event, stepId) {
      if (stepId === $ctrl.step.id) {
        $ctrl.onSaving = true;
        var stepToSave = angular.copy($ctrl.step);
        _.map(stepToSave.condition.terms, function (term) {
          if (term.operation === 'column_contains') {
            term.column_contains_name = parseInt(term.column_contains_name);
          } else if(term.operation=== 'table_contains') {
            term.column_contains_name = void 0;
          }
          if (term.operation === 'column_contains' || term.operation === 'table_contains') {
            term.operation = 'contains';
          }
          if (term.operation === 'is_not_empty' || term.operation === 'is_empty') {
            term.statement = term.statement || '';
          }
        });
        stepToSave.condition.terms = _.filter(stepToSave.condition.terms, function (value) {
          var isEmptyCheck = value.operation === 'is_not_empty' || value.operation === 'is_empty';
          return value.subject_id && value.operation && isEmptyCheck ? true : value.statement;
        });
        StepService.updateStep({
          id: stepToSave.id,
          checklist_id: $ctrl.process.id,
          skipNotFound: true
        }, stepToSave).then(function (res) {
          var isValidCondition = StepService.isValidStepCondition($ctrl.step);
          if (isValidCondition && $ctrl.form) {
            $ctrl.form.$setPristine();
          }
          $ctrl.step.last_updated = _.get(res, 'data.last_updated');
          $ctrl.onLogicChange({ step: $ctrl.step });
          $rootScope.$emit('STEP:UPDATED', $ctrl.step);
          $ctrl.onSaving = false;
          Helper.showChangesSavedGrowl();
        }, function () {
          $ctrl.onSaving = false;
        });
      }
    });
  }
})();
/**
 * @ngdoc component
 * @name tallyfy.compactKoFormDetail
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactKoFormDetail', {
      templateUrl: 'app/components/compact/components/koFormDetail/compact-ko-form-detail.component.html',
      bindings: {
        process: '<',
        form: '=',
        isFormTag: '<',
        isPublicProcess: '<',
        isPublic: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, $q, koStepService, DESCRIPTIONSIZE, Helper, $uibModal, preferencesStore, $stateParams, AccountService,
                  CompactStepService, AuthPlan, PLANS, COMMON, StepService) {
          var $ctrl = this,
            oldProcess,
            prerunWatchHandler;

          // angular ife cycle hook methods
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.deleteKOStep = deleteKOStep;
          $ctrl.onDescriptionSave = onDescriptionSave;
          $ctrl.onDiscardDescription = onDiscardDescription;
          $ctrl.onEditTitleClick = onEditTitleClick;
          $ctrl.onTitleUpdate = onTitleUpdate;
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;
          $ctrl.koFormHelpVideoModal = koFormHelpVideoModal;
          $ctrl.onPaneClose = onPaneClose;
          $ctrl.onSaveChanges = onSaveChanges;
          $ctrl.openShareModal = openShareModal;
          $ctrl.toggleAutoNaming = toggleAutoNaming;
          $ctrl.onKOFAccordionChanges = onKOFAccordionChanges;
          
          $ctrl.accordionOptions = [
            {
              id: 'formfields',
              isExpanded: true
            },
            {
              id: 'description',
              isExpanded: false
            },
            {
              id: 'auto-name-process',
              isExpanded: false
            },
            {
              id: 'advanced',
              isExpanded: false
            }
          ];
          
          /**
           * @ngdoc method
           * @name onInit
           * 
           * @description
           * angular life cycle hook
           */
          function onInit() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              autofocus: false,
              placeholder: $filter('translate')('steps.bpeDescStep.editorPlaceholder'),
              currentUser: $rootScope.identity,
              heightMin: 150,
              heightMax: 0,
              type: 'blueprint',
              enableFullscreen: true
            }, true, CompactStepService.getBlueprintFroalaToolbar());
            $ctrl.froalaOptions.skipBlueprintId = $ctrl.process.id;
            $ctrl.froalaOptions.resizeable = true;
            oldProcess = angular.copy($ctrl.process);
            $ctrl.isShowKoFormVideoModal = getKOPreferences();
            initFroalaForAutoNaming();
          }

          function onChanges() { }

          function onDestroy() {
            prerunWatchHandler();
          }

          /**
           * @ngdoc method
           * @name onSaveChanges
           * 
           * @description
           * save changes handler
           */
          function onSaveChanges() {
            if (!$ctrl.process.auto_naming) $ctrl.process = _.omit($ctrl.process, ['allow_launcher_change_name']);
            var deferred = $q.defer();
            koStepService.saveKOStep($ctrl.process)
              .then(function (response) {
                $ctrl.froalaOptions.initOnClick = !$ctrl.process.kickoff_description;
                deferred.resolve(response);
              }, function (error) {
                deferred.reject(error);
              });
            return deferred.promise;
          }

          function deleteKOStep() {
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_description = '';
            onSaveChanges().then(function () {
              setUserPreferences('no');
              $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
              $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process });
            });
          }

          function onDescriptionSave() {
            $ctrl.process.kickoff_title = $ctrl.process.kickoff_title || $ctrl.process.title;
            $ctrl.onSaving = true;
            onSaveChanges().then(function (response) {
              $ctrl.process.last_updated = _.get(response, 'data.last_updated');
              $ctrl.descriptionForm ? $ctrl.descriptionForm.$setPristine() : angular.noop();
              oldProcess.kickoff_title = $ctrl.process.kickoff_title;
              oldProcess.kickoff_description = $ctrl.process.kickoff_description;
              $rootScope.$emit('PROCESS:UPDATED', { process: $ctrl.process, koDescUpdate: true });
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function onTitleUpdate(heading) {
            heading ? onDescriptionSave() : $ctrl.process.kickoff_title = oldProcess.kickoff_title;
          }

          function onDiscardDescription() {
            $ctrl.process.kickoff_description = oldProcess.kickoff_description;
            $ctrl.descriptionForm ? $ctrl.descriptionForm.$setPristine() : angular.noop();
          }

          function onEditTitleClick() {
            $ctrl.titleEditMode = true;
          }

          /**
           * @function
           * @name koFormHelpVideoModal
           * @description ko form create help video modal
           */
          function koFormHelpVideoModal() {
            $uibModal.open({
              component: 'koFormVideoModal',
              windowClass: 'steps-modal',
              keyboard: false,
              size: 'lg'
            }).result.then(function () { }, function () {
              setUserPreferences('yes');
            });
          }

          /**
           * @function
           * @name getKOPreferences
           * @description Get value "ko_form_intro_viewed" slug from $rootScope
           * @returns {Boolean}
           */
          function getKOPreferences() {
            if (!$ctrl.isPublic) {
              var koFormIntroViewed = _.find($rootScope.identity.preferences, { 'slug': 'ko_form_intro_viewed' });
              if (_.isUndefined(koFormIntroViewed) || koFormIntroViewed.value === 'no') {
                return true;
              }
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name setUserPreferences
           * @description Save/update preference.
           * @param { string } setValue 
           * @returns {void}
           */
          function setUserPreferences(setValue) {
            AccountService.setUserPreference({
              id: $stateParams.id || $rootScope.identity.id,
              preference: {
                slug: 'ko_form_intro_viewed',
                value: setValue
              }
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function () {
            });
          }
          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e 
           * 
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            e.preventDefault();
            $rootScope.$emit('RIGHT_PANE:CLOSE', {
              event: e
            });
            $rootScope.$emit('PROCESS_TRIGGER_RIGHT_PANE:CLOSE', { event: e });
          }

          /**
           * @ngdoc methods
           * @name openShareModal
           * 
           * @description
           * click to open popup modal to get share ko step link
           */
          function openShareModal() {
            koStepService.koStepPublicModalPopup($ctrl.process);
          }

          //Initialize froala options for auto-name-process
          function initFroalaForAutoNaming() {
            $ctrl.froalaOptionsforAutoNaming = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              autofocus: false,
              placeholder: $filter('translate')('steps.settings.placeholders.processToLaunchNameFormat'),
              currentUser: $rootScope.identity,
              heightMin: 80,
              heightMax: 80,
              pluginsEnabled: ['insertVariable'],
              toolbar: ['insertVariableDropdown'],
              enableFullscreen: true,
              type: 'blueprint',
              blurCallback: onSaveChanges
            }, true);
            var prerun = angular.copy($ctrl.process.prerun);
            $ctrl.froalaOptionsforAutoNaming.variableValue = insertVariables(prerun);
            $ctrl.froalaOptionsforAutoNaming.upgradeModal = upgradeModal;
          }

          function insertVariables(preruns) {
            var variables = [{
              id: 'DATE',
              label: $filter('translate')('steps.captures.form.label.fields.date'),
              full_label: $filter('translate')('steps.captures.form.label.fields.date')
            }, {
              id: 'TEMPLATE_NAME',
              label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP'),
              full_label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP')
            }];
            if (!_.isEmpty(preruns)) {
              var truncateFields = _.map(_.filter(preruns, function (f) { return f.required && !_.includes(["textarea", "file", "table"], f.field_type); }) || [], function (field) {
                return angular.extend(field, {
                  full_label: field.label,
                  label: truncateName(field.label, 25),
                  type_field: $filter('translate')('steps.describes.insertVariable.' + field.field_type) + " " + field.alias.split('-').pop()
                });
              }) || [];
              variables.push({
                name: $filter('translate')('steps.logic.label.pre_run_requied'),
                type: 'Prerun',
                capture: truncateFields
              });
            }
            return variables;
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          //Handle auto-name-process toggle
          function toggleAutoNaming() {
            if ($ctrl.process.auto_naming) {
              initFroalaForAutoNaming();
            }
          }

          /**
           * @function
           * @name onKOFAccordionChanges
           * @description Execute when change tab to check if it has something need to be saved.
           * @param e
           * @param {*} idx
           */
          function onKOFAccordionChanges(e, idx) {
            if (e) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
            
            if ($ctrl.accordionOptions[0].isExpanded && StepService.validatePrerunFields($ctrl.process)) {
              return;
            }

            _.forEach($ctrl.accordionOptions, function (item, i) {
              $ctrl.accordionOptions[i].isExpanded = (i === idx) ? !$ctrl.accordionOptions[i].isExpanded : false;
            });
          }

          /**
          * keep watching on $ctrl.process.prerun
          * set Analytics process prerun properties
          */
          prerunWatchHandler = $scope.$watch('$ctrl.process.prerun', function (value) {
            if (value.length === 0 && $ctrl.process.is_public_kickoff) {
              $ctrl.process.is_public_kickoff = false;
              onSaveChanges();
            }
            if ($ctrl.froalaOptionsforAutoNaming) {
              var prerun = angular.copy($ctrl.process.prerun), variables = insertVariables(prerun),
                editor = _.get($ctrl.froalaOptionsforAutoNaming, 'froalaEditor', {});
              if (editor.insertVariable) {
                editor.insertVariable.updateVariableValues(editor, variables);
                $ctrl.froalaOptionsforAutoNaming.updateVariableValues(variables);
              }
            }
          }, true);
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.emailSetting.component.enableSmtpModal
 * @module tallyfy.organizations
 *
 * @description
 * A component to toggle SMTP and edit SMTP credentials
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 */
(function () {
    'use strict';
  
    angular
      .module('tallyfy.organizations')
      .component('enableSmtpModal', {
        templateUrl: 'app/modules/organizations/emailSetting/enable-smtp-modal/enable-smtp-modal.html',
        bindings: {
          resolve: '<',
          close: '&',
          dismiss: '&'
        },
        controller:
          /*@ngInject*/
          function (_) {
            var $ctrl = this;
  
            /**
             * component's lifeCycle hooks 
             */
            $ctrl.$onInit = initialization;
            $ctrl.$onDestroy = onDestroy;
            $ctrl.$onChanges = onChanges;
  
            /**
             * public methods
             */
            $ctrl.toggleUseExisting = toggleUseExisting;
            $ctrl.closeModal = closeModal;
            
  
            /**
             * public properties
             */
            $ctrl.useExistingTemplate = false;
  
            /**
             * @function
             * @name initialization
             * @description
             * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
             */
            function initialization() {}
  
            /**
             * @function
             * @name onChanges
             * @description
             * A component's lifeCycle hook which is called when bindings are updated.
             */
            function onChanges() { }
  
            /**
             * @function
             * @name onDestroy
             * @description
             * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
             * Usefull to release external resources, watches and event handlers.
             */
            function onDestroy() { }
  
            //Toggle use existing template
            function toggleUseExisting () {
                $ctrl.useExistingTemplate = !$ctrl.useExistingTemplate;
            }

            //Close modal
            function closeModal (useExisting, dismissed) {
                if (dismissed) {
                    $ctrl.dismiss();
                    return;
                }

                $ctrl.close({ $value: { useExisting: useExisting } });
            }
          }
      });
  })();
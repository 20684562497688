/**
 * @ngdoc Component
 * @name tallyfy.account.component.login
 * @module tallyfy.account
 *
 * @description
 * A component to login new account
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('logout', {
      templateUrl: 'app/modules/account/logout/logout.html',
      controller:
      /*@ngInject*/
      function (Auth, $state, growl, pollingService, _, $rootScope, TFY_EVENTS, blockUI, $cookies) {
        var $ctrl = this, blockUI = blockUI.instances.get('appView');

        /**
         * component's lifeCycle hooks
         */
        $ctrl.$onInit = initialization;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * public methods
         */
        $ctrl.logout = logout;

        /**
         * @function
         * @name initialization
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function initialization() { }

        /**
         * @function
         * @name onChanges
         * @description
         * A component's lifeCycle hook which is called when bindings are updated.
         */
        function onChanges() { }

        /**
         * @function
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() { }

        /**
         * @function
         * @name logout
         * @description to logout user
         */
        function logout() {
          blockUI.start();
          Auth.logout().then(function () {
            pollingService.cancelPolling();
            $cookies.remove('forcedOnboardingFlow');
            $(".app").removeClass("user-onboarding");
            $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, { reset: 'no', setTheme: '' });
            blockUI.stop();
            $state.go('authHandler');
          }, function (error) {
            blockUI.stop();
            growl.error(_.get(error, 'data.message', ''), {
              referenceId: 'logoutController',
              disableIcons: true,
              disableCloseButton: true
            });
          });
        }
      }
    });
})();

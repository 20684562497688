(function () {
  'use strict';
  angular.module('tallyfy.public')
    .service('PublicLibraryService', publicLibraryService);

  /*@ngInject*/
  function publicLibraryService(_,PublicLibraryRepository,$filter) {
    var self = this;

    self.getPublicLibrary = function (args) {
      return PublicLibraryRepository.get(args);
    };

    self.getPublicLibraryList = function (args) {
      return PublicLibraryRepository.getPublicLibraryList(args).$promise;
    };
 
    self.getPublicLibraryById = function (args) {
      return PublicLibraryRepository.getPublicLibraryById(args).$promise;
    };

    self.getPublicBlueprintDetail = function (args) {
      return PublicLibraryRepository.get(args);
    };

    self.getBlueprintStepsDeadline = function (args) {
      var params = {
        param1: 'steps-deadlines'
      };
      return PublicLibraryRepository.get(angular.extend(args, params));
    };

    self.blueprintTypes = function () {
      return [
        { id: 'all_blueprints', key: 'all_blueprints', title: $filter('translate')('process.filter.all_template'), favIcon: 'fas fa-list-ol' },
        { id: 'procedure', key: 'procedure', title: $filter('translate')('process.title.bpTypes.procedure'), favIcon: 'fas fa-list-ol' },
        { id: 'document', key: 'document', title: $filter('translate')('process.title.bpTypes.document'), favIcon: 'fas fa-align-left' }
      ];
    };
    
    self.getBlueprintTypes = function () {
      var types = self.blueprintTypes();
      return _.slice(types, 1);
    };
  }
})();
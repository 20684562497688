/**
 * @ngdoc Component
 * @name tallyfy.run.component.changeProcessOwner
 * @module tallyfy.run
 *
 * @description
 * A component to manage bunch of step
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('changeProcessOwner', {
      templateUrl: 'app/modules/runs/changeProcessOwner/changeProcessOwner.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
         /*@ngInject*/
        function (TasksService) {
          var $ctrl = this;
          
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          
          /**
           * public methods
           */
          $ctrl.cancel = cancel;
          $ctrl.reAssignToUser = reAssignToUser;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.originalOwner = $ctrl.resolve.originalOwner;
            $ctrl.newOwner = $ctrl.resolve.newOwner;
            $ctrl.originalOwnerName = $ctrl.resolve.originalOwner.first_name;
            $ctrl.newOwnerName = $ctrl.resolve.newOwner.first_name;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @function
           * @name cancel
           * @description
           * Dismiss modal
           */
          function cancel() {
            $ctrl.dismiss();
          }
          
          /**
           * @function
           * @name reAssignToUser
           * @param {string} isAssign
           * @description
           * Reassign user
           */
          function reAssignToUser(isAssign) {
            isAssign ? $ctrl.close({ $value: $ctrl.resolve.newOwner }) : $ctrl.close({ $value: $ctrl.resolve.originalOwner });
          }
          
          /**
          * @ngdoc method
          * @name defaultAvatar
          * @public
          * @description set default avatar
          * @param {string} avatar
          * @return {boolean}
          */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstName
           * @param {string} lastName
           * @return {string} first character of name
           */
          function defaultAvatarText(firstName, lastName) {
            return TasksService.setDefaultAvatarText(firstName, lastName);
          }
          
          //controller ends
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy.list')
    .component('listDetail', {
      binding: {},
      templateUrl: 'app/components/list/list-detail/list-detail.component.html',
      controller: listDetailController
    });
  /*@ngInject*/
  function listDetailController(_, $q, $timeout, $state, $uibModal, listService, ListDetailTableService, Helper) {
    var $ctrl = this;

    $ctrl.tableOptions = {};

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onDestroy = onDestroy;

    $ctrl.initTableConfig = initTableConfig;
    $ctrl.getData = getData;
    $ctrl.deleteRow = deleteRow;

    function onInit() {
      listService.getListById($state.params.list_id)
        .then(
          function (res) {
            $ctrl.list = res.data;
          });
    }

    function onChanges() { }

    function onDestroy() { }

    function initTableConfig() {
      $ctrl.tableOptions = getTableConfig();
    }

    function getData() {
      var defer = $q.defer();
      $timeout(function () {
        defer.resolve(ListDetailTableService.getListAsTableData($ctrl.list, $ctrl.tableOptions));
      }, 0);
      return defer.promise;
    }

    function tableFieldUpdated(data, isDestroy) {
      if (!data.length || isDestroy) {
        return;
      }
      if ($ctrl.tableOptions.tableState) {
        $ctrl.tableOptions.tableState.editMode = false;
      }
      $timeout(function () {
        updateListRow(data);
      }, 0);
    }

    function updateListRow(tableRows) {
      var grid = $ctrl.tableOptions.tableElement.data('kendoGrid'), columns = grid.columns || [], rows = [];
      for (var i = 0; i < tableRows.length; i++) {
        var rowData = [];
        for (var j = 0; j < columns.length; j++) {
          if (columns[j].field) {
            rowData.push(tableRows[i][columns[j].field]);
          }
        }
        rows.push(rowData);
      }
      listService.updateListRows($ctrl.list.id, { rows: rows }).then(function (res) { });
    }

    function deleteRow(e) {
      var grid = $ctrl.tableOptions.tableElement.data('kendoGrid');
      grid.removeRow($(e.target).closest('tr'));
      grid.dataSource.sync();
      updateListRow(grid.dataSource.data());
      if ($ctrl.tableOptions.tableState) {
        $ctrl.tableOptions.tableState.editMode = false;
      }
    }

    function addColumn(e) {
      $uibModal.open({
        component: 'editColumnModal',
        windowClass: 'edit-column-header-modal',
        backdrop: 'static',
        resolve: {
          action: function () {
            return 'add';
          }
        }
      }).result.then(function (res) {
        $ctrl.list.columns.push(res.newColumn);
        var grid = $ctrl.tableOptions.tableElement.data('kendoGrid'), columns = grid.columns || [], rows = [], tableRows = grid.dataSource.data();
        for (var i = 0; i < tableRows.length; i++) {
          var rowData = [];
          for (var j = 0; j < columns.length; j++) {
            if (columns[j].field) {
              rowData.push(tableRows[i][columns[j].field]);
            }
          }
          rows.push(rowData);
        }
        $ctrl.list.rows = rows;
        $ctrl.list.columns = _.map($ctrl.list.columns, function (col) {
          if (col === res.oldValue) {
            col = res.newValue;
          }
          return col;
        });
        updateListColumn('add', res);
      });
    }

    function editColumn(title) {
      $uibModal.open({
        component: 'editColumnModal',
        windowClass: 'edit-column-header-modal',
        backdrop: 'static',
        resolve: {
          title: function () {
            return title;
          },
          action: function () {
            return 'edit';
          }
        }
      }).result.then(function (res) {
        var grid = $ctrl.tableOptions.tableElement.data('kendoGrid'), columns = grid.columns || [], rows = [], tableRows = grid.dataSource.data();
        for (var i = 0; i < tableRows.length; i++) {
          var rowData = [];
          for (var j = 0; j < columns.length; j++) {
            if (columns[j].field) {
              rowData.push(tableRows[i][columns[j].field]);
            }
          }
          rows.push(rowData);
        }
        $ctrl.list.rows = rows;
        $ctrl.list.columns = _.map($ctrl.list.columns,
          function (col) {
            if (col === res.oldValue) {
              col = res.newValue;
            }
            return col;
          }
        );
        updateListColumn('edit', res);
      });
    }

    function deleteColumn(title) {
      $ctrl.list.columns = _.filter($ctrl.list.columns, function (col) {
        return col != title;
      });
      updateListColumn('remove', { removedTitle: title });
    }

    function updateListColumn(action, data) {
      kendo.ui.progress($ctrl.tableOptions.tableElement, true);
      listService.updateListColumns($ctrl.list.id, $ctrl.list)
        .then(function (res) {
          var grid = $ctrl.tableOptions.tableElement.data('kendoGrid'), columnDefs = [], hideAndShowColumns = [];
          if (action === 'edit') {
            for (var i = 0; i < grid.columns.length; i++) {
              if (grid.columns[i].title === data.oldValue) {
                columnDefs.push({
                  field: grid.columns[i].field,
                  title: data.newValue,
                  headerTemplate: ListDetailTableService.getColumnHeaderTemplate(data.newValue)
                });
                hideAndShowColumns.push({
                  field: grid.columns[i].field,
                  title: data.newValue,
                  initState: true
                });
                $ctrl.tableOptions.tableState.columns[grid.columns[i].field] = { isShown: true };
              } else {
                if (grid.columns[i].field) {
                  columnDefs.push({
                    field: grid.columns[i].field,
                    title: grid.columns[i].title,
                    headerTemplate: ListDetailTableService.getColumnHeaderTemplate(grid.columns[i].title)
                  });
                  hideAndShowColumns.push({
                    field: grid.columns[i].field,
                    title: grid.columns[i].title,
                    initState: true
                  });
                  $ctrl.tableOptions.tableState.columns[grid.columns[i].field] = { isShown: true };
                }
              }
            }
          } else if (action === 'add') {
            for (var i = 0; i < grid.columns.length; i++) {
              if (grid.columns[i].field) {
                columnDefs.push({
                  field: grid.columns[i].field,
                  title: grid.columns[i].title,
                  headerTemplate: ListDetailTableService.getColumnHeaderTemplate(grid.columns[i].title)
                });
                hideAndShowColumns.push({
                  field: grid.columns[i].field,
                  title: grid.columns[i].title,
                  initState: true
                });
                $ctrl.tableOptions.tableState.columns[grid.columns[i].field] = { isShown: true };
              }
            }
            var guid = Helper.getRandomString(8);
            columnDefs.push({
              field: guid,
              title: data.newColumn,
              headerTemplate: ListDetailTableService.getColumnHeaderTemplate(data.newColumn)
            });
            hideAndShowColumns.push({
              field: guid,
              title: data.newColumn,
              initState: true
            });
            $ctrl.tableOptions.tableState.columns[guid] = { isShown: true };
          } else if (action === 'remove') {
            var grid = $ctrl.tableOptions.tableElement.data('kendoGrid'), columns = _.filter(grid.columns, function (col) {
              return col.title != data.removedTitle;
            }) || [];
            for (var i = 0; i < columns.length; i++) {
              if (columns[i].field) {
                columnDefs.push({
                  field: columns[i].field,
                  title: columns[i].title,
                  headerTemplate: ListDetailTableService.getColumnHeaderTemplate(columns[i].title)
                });
                hideAndShowColumns.push({
                  field: columns[i].field,
                  title: columns[i].title,
                  initState: true
                });
                $ctrl.tableOptions.tableState.columns[columns[i].field] = { isShown: true };
              }
            }
          }
          columnDefs.push(
            {
              command: {
                name: 'removeRow',
                template: kendo.template($('#listDeleteRow').html())
              },
              width: 48
            }
          );
          kendo.ui.progress($ctrl.tableOptions.tableElement, false);
          grid.setOptions({ columns: columnDefs });
          $ctrl.tableOptions.tableMenus.hideAndShowColumns = hideAndShowColumns;
          grid.dataSource.data(ListDetailTableService.getListAsTableData($ctrl.list, { gridConfig: { columns: columnDefs } }));
        });
    }

    function getTableConfig() {
      var hideAndShowColumns = [], columnDefs = [], columns = $ctrl.list.columns || [];
      for (var i = 0; i < columns.length; i++) {
        var guid = Helper.getRandomString(8);
        columnDefs.push({
          field: guid,
          title: $ctrl.list.columns[i],
          headerTemplate: ListDetailTableService.getColumnHeaderTemplate($ctrl.list.columns[i])
        });
        hideAndShowColumns.push({
          field: guid,
          title: $ctrl.list.columns[i],
          initState: true
        });
      }
      return {
        beautyScrollStyle: true,
        addRow: true,
        editableHeader: true,
        exportFileName: $ctrl.list.title,
        tableState: {
          sort: {},
          columns: {}
        },
        tableMenus: {
          export: true,
          hideAndShowColumns: hideAndShowColumns
        },
        gridConfig: ListDetailTableService.getOptions($ctrl.list, columnDefs, tableFieldUpdated),
        templateScope: {
          callbacks: {
            addColumn: addColumn,
            editColumn: editColumn,
            deleteColumn: deleteColumn,
            deleteRow: deleteRow
          },
          variables: {}
        }
      }
    }
  }
})();
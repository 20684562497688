/**
 * @ngdoc Service
 * @name $modal
 *
 * @module $modal
 *
 * @description
 * Service for showing modals
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('modal', modal);

  modal.$inject = ['$uibModal'];

  function modal($uibModal) {

    this.options = {
      'body': 'global.alertModal.body',
      'header': 'global.alertModal.header',
      'button': {
        'close': 'global.alertModal.body.close'
      },
      'templateUrl': 'app/components/modal/modal.html',
      'windowTemplateUrl': 'uib/template/modal/window.html',
      'controller': 'ConfirmationModalController as $ctrl',
      'animate': false,
      'backdrop': true,
      'windowClass': "black-overlay"
    };


    this.show = function (options) {
      var $this = this;
      var templateOptions = $this.options;
      if (options) {
        angular.extend(templateOptions, options);
      }

      return $uibModal.open({
        animation: false,
        backdrop: templateOptions.backdrop,
        windowClass: templateOptions.windowClass,
        templateUrl: templateOptions.templateUrl,
        component: templateOptions.component,
        windowTemplateUrl: templateOptions.windowTemplateUrl,
        controller: templateOptions.controller,
        appendTo: templateOptions.appendTo,
        size: templateOptions.size,
        resolve: {
          options: function () {
            return templateOptions;
          }
        }
      }).result.then(function (callBack) {
        return callBack;
      });
    };
  }
})();

(function() {
  'use strict';
  angular
    .module('tallyfy')
    .directive('uiScrollTo', uiScrollTo);

    function uiScrollTo() {
      return {
        restrict: 'AC',
        replace: true,
        link: link
      };
      function link(scope, el, attr) {
        var scrollTo = attr.uiScrollTo;
        el.on('click', function() {
          angular.element(document).find(scrollTo).animate({
            scrollTop: 0
          }, 'fast');
        });
      }
    }
})();

/**
 * @ngdoc Component
 * @name tallyfy.steps.component.stepTipsModal
 * @module tallyfy.steps
 *
 * @description
 * A component is step tips modal
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepTipsModal', {
      templateUrl: 'app/modules/steps/stepTipsModal/stepTipsModal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
         /*@ngInject*/
        function (StepService) {
          var $ctrl = this;
          
          
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          
          /**
           * public methods
           */
          $ctrl.cancel = cancel;
          
          /**
           * public properties
           */
          $ctrl.stepsForExample = StepService.getStepsTips();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {}

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @function
           * @name cancel
           * @description
           * Dismiss modal
           */
          function cancel() {
            $ctrl.dismiss();
          }
          
          //controller ends
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.tags.component.tagsField
 * @module tallyfy.tags
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tags')
    .component('tagsField', {
      bindings: {
        isGrowlShow: '<',
        model: '=',
        isAttach: "<",
        subjectType: "<",
        subjectId: "<",
        tags: "<",
        cssClass: '@',
        customTemplate: '<?',
        minTags: '<?'
      },
      templateUrl: 'app/modules/tags/tagsField/tagsField.html',
      controller:
        /*@ngInject*/
        function (_, TagsService, Growl, blockUI, $filter, BLUEPRINT_TYPE, $timeout, AuthPlan) {
          var $ctrl = this,
            growl = new Growl();

        
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          /**
           * public methods
           */
          $ctrl.loadTags = loadTags;
          $ctrl.onTagAdding = onTagAdding;
          $ctrl.onRemovingTag = onRemovingTag;
          $ctrl.detachTag = detachTag;
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.getTagClass = getTagClass;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if (typeof $ctrl.isGrowlShow === undefined) {
              $ctrl.isGrowlShow = true;
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * loadTags
           * filters tags based on search text
           * @param  {string} $query A search string
           * @return {array}        An array of filtered tags
           */
          function loadTags($query) {
            Growl.clearAllMessages('blueprintContextAlert');
            return _.filter($ctrl.tags, function (tag) {
              return tag.title.toLowerCase().indexOf($query.toLowerCase()) != -1;
            });
          }
          /**
         * onTagAdding
         * creates new tag and attachs to entity[Step|Checklist|Run]
         * 
         * Usage : onTagAdding(tag, options) where options = {
         *     isAttach : true,
         *     subject : {
         *         type : 1 of [Step|Checklist|Run|.....],
         *         id : ID of [Step|Checklist|Run|......]
         *     }
         * }
         * options : is an optional parameter, Specially this is used if you need to attach tag with [Step|Run|Checklist| ......]
         * @param  {array} tag     An array of tag to be created/updated
         * @param  {object} options An additional param 
         * @return {void}
         */
        
          function onTagAdding(tag, options) {

            var referenceId = 'blueprintContextAlert';
            
            if ( _.indexOf([BLUEPRINT_TYPE.PROCEDURE, BLUEPRINT_TYPE.FORM, BLUEPRINT_TYPE.DOCUMENT], tag.title.toLowerCase()) !== -1) {
              var tagname1;
              if (_.indexOf(_.map($ctrl.model, 'title'), BLUEPRINT_TYPE.PROCEDURE.toLowerCase()) !== -1) {
                tagname1 =  BLUEPRINT_TYPE.PROCEDURE;
              }
              if (_.indexOf(_.map($ctrl.model, 'title'), BLUEPRINT_TYPE.FORM.toLowerCase()) !== -1) {
                tagname1 = BLUEPRINT_TYPE.FORM;   
              }
              if (_.indexOf(_.map($ctrl.model, 'title'), BLUEPRINT_TYPE.DOCUMENT.toLowerCase()) !== -1) {
                tagname1 = BLUEPRINT_TYPE.DOCUMENT;   
              }
              if(tagname1) {
                Growl.clearAllMessages(referenceId);
                $timeout(function() {
                    growl.error($filter('translate')('global.tag.notRepeatPrimaryTag', { tagname1:tagname1, tagname2: tag.title.toLowerCase() }), { referenceId: referenceId, disableIcons: true, disableCloseButton: true });   
                }, 10);
                return false; 
              }
            }

            var searchTag = _.filter($ctrl.tags, function (item) {
              return item.title.toLowerCase() == tag.title.toLowerCase();
            });
       
            if (searchTag.length) {
              tag = searchTag[0];
            }
            
            var options = options || {};
            if (tag.title.length > 31 && $ctrl.isGrowlShow) {
              growl.error($filter('translate')('global.tag.notGreaterThan'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              return false;
            } !tag.id ? createNewTag(tag, options) : (options.isAttach ? attachTag(tag, options.subject) : angular.noop);
          }
          /**
           * @ngdoc method
           * @name createNewTag
           * @param {Object} tag 
           * @param {Object} options 
           * @description an helper function to create new tag
           * @returns {Void} 
           */
          function createNewTag(tag, options) {
            TagsService.createTag({ title: tag.title }).then(function (response) {
              tag.id = response.data.id;
              $ctrl.tags.push(response.data);
              options.isAttach ? attachTag(tag, options.subject) : angular.noop;
              if ($ctrl.isGrowlShow) {
                growl.info($filter('translate')('global.tag.addedTag'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            }, function (error) {
              if (error.validation_errors) {
                var errors = error.validation_errors;
                for (var index in errors) {
                  if (errors.hasOwnProperty(index) && errors[index].length && $ctrl.isGrowlShow) {
                    growl.error(errors[index][0], { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  }
                }
              } else {
                if ($ctrl.isGrowlShow) {
                  growl.error($filter('translate')('global.tag.notcreateTag'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                }
              }
            });

          }
          /**
           * @ngdoc method
           * @name attachTag
           * @param {Object} tag
           * @param {Object} subject
           * @description This is going to attach tag to an entity[Step|Checklist|Run|..]
           * @returns {Void} 
           */
          function attachTag(tag, subject) {
            blockUI.start();
            TagsService.attachTag(tag, subject).then(function (response) {
              _.remove($ctrl.model, function(t) {
                return t.title.toLowerCase() === tag.title.toLowerCase();
              });
              $ctrl.model.push(response.data);
              var tagAttached = _.find($ctrl.model, { tag_id: response.data.tag_id });
              if (tagAttached) {
                tagAttached.tag_id = response.data.tag_id;
                tagAttached.id = response.data.id;
              }
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name getTagClass
           * @description Callback function tagClass in ngTagsInput
           * @param {String} $tag
           */
          function getTagClass($tag) {
            if ($ctrl.subjectType === 'Checklist' && TagsService.isSpecialTag($tag.title)) {
              return 'bp-locked-tag';
            }
          }

          /**
           * @function
           * @name onRemovingTag
           * @description check before removing a tag.
           */
          function onRemovingTag() {
            return !($ctrl.minTags && ($ctrl.model.length <= $ctrl.minTags));
          }

          /**
         * @ngdoc method
         * @name detachTag
         * @param {Object} tag 
         * @param {Object} option 
         * @description simply remove the association of tag from entity[Step|Run|Checklist|....]
         * @returns {Void} 
         */
          function detachTag(tag, option) {
            if (!tag.id || !option.isAttach) {
              return;
            }
            TagsService.detachTag(tag.id).then(function () {
              if ($ctrl.isGrowlShow) {
                growl.success($filter('translate')('global.tag.removedTag'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            }, function () {
              if ($ctrl.isGrowlShow) {
                growl.error($filter('translate')('global.tag.notRemovedTag'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
            });
          }
        }
    });
})();
/**
 * @ngdoc Service
 * @name tallyfy.process.service.ProcessModalService
 *
 * @module tallyfy.process
 *
 * @description
 * Service that manage conditional modal 
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .service('ProcessModalService',
      /**@ngInject*/
      function ($uibModal, _, RunsService, CONFIG, AuthPlan, PLANS, $q, DateUtils, moment) {
        var self = this,
          activeProcessCount = 0;

        self.getActiveProcess = getActiveProcess;
        self.transformTasks = transformTasks;

        /**
         * @name getActiveProcess
         * @description An helper function that loads active process of current users.
         * and stores count in private property activeProcessCount
         * 
         * @returns void
         */
        function getActiveProcess() {
          RunsService
            .activeProcessCount()
            .then(function (totalActiveRuns) {
              activeProcessCount = totalActiveRuns;
            }).catch(function (error) {
              $log.debug('ProcessModalService: Error while fetching active processes', JSON.stringify(error));
            });
        }

        /**
         * @ngdoc method
         * @name transformTasks
         * @private
         * @param {Object} tasksList
         * @param {Object} stepsDeadline
         * @description get a list of tasks and returns a map of tasks data
         */
        function transformTasks(tasksList, stepsDeadline) {
          var taskMap = {};
          _.forEach(tasksList, function (task) {
            taskMap[task.id] = {
              position: task.position,
              deadline: DateUtils.toUTC(moment(_.find(stepsDeadline, { step_id: task.id }).deadline_time).format()).format('YYYY-MM-DD HH:mm:ss')
            };
          });
          return taskMap;
        }
        //end of service
      });
})();

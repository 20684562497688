/**
 * @ngdoc Component
 * @name tallyfy.steps.component.stepEdit
 * @module tallyfy.steps
 *
 * @description
 * A component to edit the step
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('stepEdit', {
      bindings: {
        process: '<',
        usersInOrg: '<',
        step: '<',
        onSaving: '<',
        activeTab: '=?',
        stepDescribeForm: '=',
        captureForm: '=',
        assignForm: '=',
        deadlineForm: '=',
        logicForm: '=',
        settingsForm: '=',
        onTabChanged: '&',
        isPublic: '<',
        orgGroups: '<?',
        qStepDirtyCheck: '&?',
        roles: '<?',
        activeCategory: '='
      },
      templateUrl: 'app/modules/steps/edit/edit-new.html',
      controller:
        /*@ngInject*/
        function (_, $filter, StepService, $analytics, $rootScope, $scope, $uibModal, CompactStepService, $sce, $timeout, MilestoneService,
          ChatlioService, CONFIG, Helper, OrganizationsService, DOMService, blockUI) {
          var $ctrl = this,
            otherStepCount,
            updateActiveTabEventHandler,
            updateStepAndPrerunCountEventHandler,
            unRegisteredEventStepUpdated,
            activeTabWatcher,
            listOfStepOption,
            tabChangedTimeoutHandler,
            activeCategoryWatcher,
            stageDeletedWatcher,
            blockUI = blockUI.instances.get('editProcess');

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.updateStepCapture = updateStepCapture;
          $ctrl.onAccordionChanges = onAccordionChanges;
          $ctrl.prepareStepTab = prepareStepTab;
          $ctrl.stepTypeDDToggle = stepTypeDDToggle;
          $ctrl.stepMilestoneDDToggle = stepMilestoneDDToggle;
          $ctrl.onStepTypeSelected = onStepTypeSelected;
          $ctrl.onStepMilestonesSelected = onStepMilestonesSelected;
          $ctrl.onTitleUpdate = onTitleUpdate;
          $ctrl.openMoreSetting = openMoreSetting;
          $ctrl.addVariableValue = addVariableValue;

          $ctrl.getConditionableLabel = getConditionableLabel;
          $ctrl.getActionLabel = getActionLabel;
          $ctrl.getStepPosition = getStepPosition;
          $ctrl.editAutomation = editAutomation;
          $ctrl.createNewMilestone = createNewMilestone;
          $ctrl.getStageTitle = getStageTitle;
          $ctrl.openAutomationPage = openAutomationPage;
          $ctrl.toggleBasicsAccordion = toggleBasicsAccordion;
          $ctrl.removeMilestone = removeMilestone;
          $ctrl.toggleAdvancedAccordion = toggleAdvancedAccordion;

          $ctrl.stepDescribeForm = {};
          $ctrl.captureForm = {};
          $ctrl.assignForm = {};
          $ctrl.deadlineForm = {};
          $ctrl.logicForm = {};
          $ctrl.settingsForm = {};
          $ctrl.emailBodyIsExpanded = false;
          $ctrl.selectedAutomationTab = 'triggered';

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.htmlPopover = $sce.trustAsHtml($filter('translate')('steps.settings.advanced.inbound', { apiURL: CONFIG.API_HOST }));
            listOfStepOption = StepService.getStepOptions($ctrl.process);
            $ctrl.stepOptions = angular.copy(listOfStepOption);
            $ctrl.stepAdvancedOptions = StepService.getStepAdvancedOptions();
            $ctrl.stepTypes = StepService.getStepTypes();
            $ctrl.viewerId = $ctrl.isPublic ? 'PUBLIC' : $rootScope.identity.id;
            prepareStepTab($ctrl.step);
            $ctrl.ownerList = $ctrl.usersInOrg;
            $ctrl.ownerList = _.sortBy($ctrl.ownerList, 'text');
            setActiveTab();
            setSelectedStepType($ctrl.step.step_type);
            $ctrl.fromEmail = OrganizationsService.getEmailPreferences();
            prepareAutomationsInfo();
            prepareMilestoneDropdown();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * @param bindings
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.step && !bindings.step.isFirstChange()) {
              angular.extend($ctrl.step, bindings.step.currentValue);
              if (otherStepCount) {
                $ctrl.step.otherStepLogicCount = otherStepCount;
              }
              prepareStepTab($ctrl.step);
              prepareAutomationsInfo();
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateActiveTabEventHandler();
            updateStepAndPrerunCountEventHandler();
            onStepAddedHandler();
            onStepRemovedHandler();
            onKOFieldAddedHandler();
            onProcessDiscardHandler();
            onStepUpdatedEventHandler();
            conditionFlagWatcherHandler();
            unRegisteredEventStepUpdated();
            activeTabWatcher();
            activeCategoryWatcher();
            stageDeletedWatcher();
            var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
            if (isInTrial) {
              ChatlioService.cancelTrigger();
            }
          }

          function prepareAutomationsInfo() {
            var affectingAutomations = CompactStepService.getAffectingAutomations($ctrl.process, $ctrl.step);
            $ctrl.affectingAutomations = _.map(affectingAutomations, function (affectingAutomation) {
              return {
                id: affectingAutomation.id,
                automated_alias: affectingAutomation.automated_alias,
                conditions: affectingAutomation.conditions,
                then_actions: _.filter(affectingAutomation.then_actions, function (action) {
                  return action.target_step_id === $ctrl.step.id;
                })
              }
            });
            var triggerAutomations = CompactStepService.getTriggerAutomations($ctrl.process, $ctrl.step);
            $ctrl.asAutomationTriggers = _.map(triggerAutomations, function (triggerAutomation) {
              return {
                id: triggerAutomation.id,
                automated_alias: triggerAutomation.automated_alias,
                conditions: _.filter(triggerAutomation.conditions, function (condition) {
                  return condition.conditionable_id === $ctrl.step.id;
                }),
                then_actions: triggerAutomation.then_actions
              }
            });
          }

          /**
           * @function
           * @name setActiveTab
           * @description Active selected tab if the found value from the parent component
           * Active default value if value get undefined
           * Track event for selected TAB
           */
          function setActiveTab() {
            if (!$ctrl.activeTab) {
              $ctrl.activeTab = {};
            }
            setAccordionView($ctrl.activeTab);
          }

          /**
           * @function
           * @name setActiveCategory
           * @description Active selected category if the found value from the parent component
           * Active default value if value get undefined
           */
          function setActiveCategory() {
            if (!$ctrl.activeCategory) {
              $ctrl.activeCategory = 'basics';
            }
          }

          /**
           * @function
           * @name prepareStepTab
           * @param {Object} step
           * @description Update step tab name with value
           */
          function prepareStepTab(step) {

            otherStepCount = _.get(step, 'otherStepLogicCount') || 0;
            prepareTabNames(step);
            $ctrl.ruleTerms = CompactStepService.getAffectingAutomations($ctrl.process, $ctrl.step);
          }

          /**
           * @function
           * @name prepareTabNames
           * @param {Object} step
           * @description Update step tab names with value
           */
          function prepareTabNames(step) {
            if (step.step_type === 'email' || step.step_type === 'expiring_email') {
              var subjectObj = {
                name: $filter('translate')('steps.title.subject'),
                value: 'subject',
                class: '',
                eventText: 'Subject (Email)',
                isEnable: true,
                tooltip: $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.subject'))
              }
              if (!_.find($ctrl.stepOptions, { value: 'subject' })) {
                $ctrl.stepOptions.splice(1, 0, subjectObj);
              }
            } else {
              if (_.find($ctrl.stepOptions, { value: 'subject' })) {
                $ctrl.stepOptions.splice(1, 1);
              }
            }
            updateDeadlineTab(step);
            updateAssigneeTab(step);
          }

          function openMoreSetting() {
            $ctrl.emailBodyIsExpanded = !$ctrl.emailBodyIsExpanded;
            var searchActive = _.find($ctrl.stepOptions, { isExpanded: true });
            if (!!(searchActive)) {
              _.set(searchActive, 'isExpanded', false);
            }
          }

          /**
           * @function
           * @name updateDeadlineTab
           * @param {Object} step
           * @description Update deadline with value
           */
          function updateDeadlineTab(step) {
            var index = _.findIndex($ctrl.stepOptions, {
              'value': 'set-deadline'
            });
            if (index > -1) {
              var deadline_label = '';
              switch (step.step_type) {
                case 'expiring':
                  deadline_label = $filter('translate')('steps.title.expiryDateDeadline');
                  break;
                default:
                  deadline_label = $filter('translate')('steps.title.timings');
                  break;
              }

              $ctrl.stepOptions[index].name = deadline_label;
            }
          }

          /**
           * @function
           * @name updateAssigneeTab
           * @param {Object} step
           * @description Update assignee tab with value
           */
          function updateAssigneeTab(step) {
            var index = _.findIndex($ctrl.stepOptions, {
              'value': 'assign'
            });
            if (index > -1) {
              if (step.step_type === 'email' || step.step_type === 'expiring_email') {
                $ctrl.stepOptions[index].name = $filter('translate')('steps.title.to');
                $ctrl.stepOptions[index].tooltip = $filter('translate')('steps.settings.tooltip.assignEmail');
              } else {
                $ctrl.stepOptions[index].name = $filter('translate')('steps.title.assign');
                $ctrl.stepOptions[index].tooltip = $filter('translate')('steps.settings.tooltip.assign');
              }
            }
          }

          /**
           * @function
           * @name updateStepCapture
           * @param {Object} captureFields
           * @description Update step capture fields
           */
          function updateStepCapture(captureFields) {
            $ctrl.step.captures = captureFields;
            prepareStepTab($ctrl.step);
          }

          /**
           * @function
           * @name onAccordionChanges
           * @description Execute when change tab to check if it has something need to be saved.
           * @param e
           * @param {object} option
           */
          function onAccordionChanges(e, option) {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            var isCapturesTabInvalid = false;
            if ($ctrl.activeTab.value === 'form_fields') {
              isCapturesTabInvalid = StepService.isStepCapturesInvalid($ctrl.step);
            }
            trackTabEvent(option);
            $ctrl.stepEditTypeOpen = false;
            var timeoutDuration = 400,
              index = _.findIndex($ctrl.stepOptions, { value: 'form_fields' });
            angular.extend($ctrl.step, {
              captures: _.filter(_.get($ctrl.step, 'captures', []), function (f) { return _.get(f, 'label') !== null; })
            });
            _.set($ctrl.step, 'condition.terms', _.filter(_.get($ctrl.step, 'condition.terms'), function (t) {
              var isEmptyCheck = t.operation === 'is_not_empty' || t.operation === 'is_empty';
              return !isEmptyCheck && ((t.statement !== null) && (t.statement !== undefined));
            }));
            $ctrl.stepOptions[index].name = $filter('translate')('steps.title.fields', {
              FFCount: _.get($ctrl.step, 'captures').length
            });
            if ($ctrl.activeTab.value === 'describe_capture') {
              timeoutDuration = 800; // To account for the debounce at description content (froala) saving
            }
            if (option.value === 'inbound' && !!(option.isExpanded)) {
              _.set(option, 'isExpanded', false);
            } else {
              var searchInbound = _.find($ctrl.stepOptions, { value: 'inbound' });
              if (!!(searchInbound.isExpanded)) {
                _.set(searchInbound, 'isExpanded', false);
              }
            }
            tabChangedTimeoutHandler = $timeout(function () {
              if (!isCapturesTabInvalid) {
                _.forEach($ctrl.stepOptions, function (op) {
                  if (op.value !== option.value) {
                    op.isExpanded = false;
                  }
                });
              }
              $timeout(function () {
                if (!isCapturesTabInvalid) {
                  $scope.stepEditAccordion.collapseAll();
                }
                $ctrl.onTabChanged({ tab: option });
              }, 100);
              $timeout.cancel(tabChangedTimeoutHandler);
            }, timeoutDuration);
          }

          /**
           * @name trackTabEvent
           * @private
           * @description
           * track event for step tabs
           */
          function trackTabEvent(tab) {
            if (!tab.eventText) {
              return;
            }
            var eventName = "BPE - Step > " + tab.eventText + " Drawer";
            $analytics.eventTrack(eventName, { category: 'BPE - Step' });
          }

          /**
           * @ngdoc method
           * @name updateStep
           * @description Update the step
           */
          function updateStep() {
            StepService.updateStep({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, $ctrl.step).then(function (response) {
              $rootScope.$emit('STEP:UPDATED', response.data);
              Helper.showChangesSavedGrowl();
            }, function () { });
          }

          function stepTypeDDToggle(e) {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            if (!$ctrl.stepEditTypeOpen) {
              if (_.isFunction($ctrl.qStepDirtyCheck) && _.get($ctrl.activeTab, 'value')) {
                $ctrl.qStepDirtyCheck({ args: { activeTab: $ctrl.activeTab } })
                  .then(function (status) {
                    if (_.get(status, 'isValid')) {
                      $ctrl.stepEditTypeOpen = true;
                      $scope.stepEditAccordion.collapseAll();
                    }
                  });
              } else {
                $ctrl.onTabChanged({ tab: $ctrl.activeTab });
                $ctrl.stepEditTypeOpen = true;
              }
            } else {
              $ctrl.onTabChanged({ tab: $ctrl.activeTab });
              $ctrl.stepEditTypeOpen = false;
            }
          }

          function stepMilestoneDDToggle(e) {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            $ctrl.stepEditMilestoneOpen = !$ctrl.stepEditMilestoneOpen;
          }

          function createNewMilestone() {
            var modal = $uibModal.open({
              component: 'createMilestone',
              windowClass: 'create-milestone-modal',
              resolve: {
                process: function () {
                  return $ctrl.process;
                }
              }
            });

            modal.result.then(function (stage) {
              blockUI.start();
              MilestoneService.createTemplateMilestone($ctrl.process.id, stage.name, 'Checklist', $ctrl.process.stages.data.length + 1)
                .then(function (res) {
                  $ctrl.process.stages.data.push(res.data);
                  var previousStageId = $ctrl.step.stage_id;
                  MilestoneService.linkStepToMilestone($ctrl.process.id, $ctrl.step.id, res.data.id)
                    .then(function () {
                      $ctrl.step.stage_id = res.data.id;
                      prepareMilestoneDropdown();
                      $rootScope.$emit('STAGE:CREATED', { stage: res.data, step: $ctrl.step, previousStageId: previousStageId });
                      blockUI.stop();
                    });
                }).catch(function (err) {
                  blockUI.stop();
                })
            })
          }

          function onStepTypeSelected(e) {
            var stepType = e.selected;
            setSelectedStepType(stepType.value);
            if (stepType.value === 'approval' || stepType.value === 'expiring' || stepType.value === 'expiring_email') {
              $ctrl.step.everyone_must_complete = false;
            }
            if (stepType.value === 'email' || stepType.value === 'expiring_email') {
              $ctrl.emailBodyIsExpanded = false;
            }
            updateStep();
          }

          function onStepMilestonesSelected(e) {
            var milestone = e.selected;
            if (_.get(milestone, 'id') === 'new') {
              createNewMilestone();
              return;
            }
            if ($ctrl.step.stage_id == _.get(milestone, 'id', null)) {
              return;
            }
            var previousStage = $ctrl.process.stages.data.find(function (stage) {
              return $ctrl.step.stage_id ? stage.id.toString() === $ctrl.step.stage_id.toString() : false;
            });
            if (!milestone) {
              blockUI.start();
              $ctrl.selectedMilestone = null;
              $ctrl.step.stage_id = null;
              MilestoneService.unLinkStepToMilestone($ctrl.process.id, $ctrl.step.id)
                .then(function (res) {
                  $rootScope.$emit('STEP:UNLINK_STAGE', { step: $ctrl.step, previousStageId: _.get(previousStage, 'id') });
                  blockUI.stop();
                }).catch(function (err) {
                  blockUI.stop();
                });
            } else {
              $ctrl.selectedMilestone = milestone;
              $ctrl.step.stage_id = _.get(milestone, 'id');
              MilestoneService.linkStepToMilestone($ctrl.process.id, $ctrl.step.id, _.get(milestone, 'id'))
                .then(function (res) {
                  $rootScope.$emit('STEP:LINK_STAGE', { step: $ctrl.step, previousStageId: _.get(previousStage, 'id'), stageId: _.get(milestone, 'id') });
                });
            }
          }

          function getStageTitle() {
            var stage = $ctrl.process.stages.data.find(function (stage) {
              return stage.id.toString() === $ctrl.step.stage_id.toString();
            });
            return _.get(stage, 'title', '');
          }

          function setSelectedStepType(value) {
            if (!value) {
              value = 'task';
            }
            $ctrl.selectedStepType = _.find($ctrl.stepTypes, { value: value });
            getPopoverHtmlHelpText();
            $ctrl.step.step_type = value;
          }

          function getPopoverHtmlHelpText() {
            switch ($ctrl.selectedStepType.value) {
              case 'task':
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.taskDefault'));
                break;
              case 'approval':
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.approveReject'));
                break;
              case 'expiring':
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.expiringTask'));
                break;
              case 'email':
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.emailTask'));
                break;
              case 'expiringEmail':
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.expiring'));
                break;
              default:
                $ctrl.htmlPopoverBlueprintTypeHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.tooltip.taskDefault'));
                break;
            }
          }

          function setAccordionView(option) {
            if ($scope.stepEditAccordion) {
              $scope.stepEditAccordion.collapseAll();
            }
            if ((_.get(option, 'value'))) {
              var tab = _.find($ctrl.stepOptions, { value: option.value });
              tab ? tab.isExpanded = true : angular.noop();
              if ($ctrl.step.step_type === 'email' || $ctrl.step.step_type === 'expiring_email') {
                var isEmailToView = _.get(option, 'value') === 'assign';
                isEmailToView ? $ctrl.emailToIsExpanded = true : $ctrl.emailBodyIsExpanded = !!tab;
                $timeout(function () {
                  DOMService.centerObjectToView('#pane-header-' + isAssigneeView ? option.value : tab.value, {
                    behavior: 'smooth',
                    block: 'center'
                  });
                }, 800);
              }
              var isInTrial = _.get($rootScope.identity, 'default_organization.in_trial');
              if (isInTrial && option.name === 'Advanced') {
                ChatlioService.cancelTrigger();
                ChatlioService.triggerMessage($rootScope.identity.id + '-' + _.get($rootScope.identity, 'default_organization.id') + '-step-advance', "Run easy automations and integrations with Tallyfy. Would you like to schedule a demo?");
              }
              if (tab) {
                trackTabEvent(tab);
              }
            }
          }

          /**
           * @function
           * @name onTitleUpdate
           * @description On update step title
           * @param {$event} e
           */
          function onTitleUpdate(e) {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            StepService.updateStep({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, _.omit(angular.copy($ctrl.step), ['condition']));
          }

          function addVariableValue(isKoField, field) {
            $timeout(function () {
              $ctrl.step.title = $ctrl.step.title + ' {{' + field.alias + '}}';
            }, 100);
          }

          function getConditionableLabel(condition, asTrigger) {
            if (condition.conditionable_type === 'Step') {
              var step = _.find($ctrl.process.steps.data, { id: condition.conditionable_id });
              return (asTrigger ? 'this step' : _.get(step, 'title', '')) + ' ' + $filter('translate')('steps.logic.label.' + condition.operation);
            } else if (condition.conditionable_type === 'Prerun') {
              var prerun = _.find($ctrl.process.prerun, { id: condition.conditionable_id });
              return _.get(prerun, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            } else if (condition.conditionable_type === 'Capture') {
              var capture;
              for (var i = 0; i < $ctrl.process.steps.data.length; i++) {
                if ($ctrl.process.steps.data[i]) {
                  capture = _.find($ctrl.process.steps.data[i].captures, { id: condition.conditionable_id });
                  break;
                }
              }
              return _.get(capture, 'label', '') + ' ' + $filter('translate')('steps.logic.label.' + condition.operation) + (condition.statement ? ' ' + condition.statement : '');
            }
          }

          function getActionLabel(action) {
            var step = _.find($ctrl.process.steps.data, { id: action.target_step_id });
            return _.get(step, 'title', '');
          }

          function getStepPosition(action) {
            var step = _.find($ctrl.process.steps.data, { id: action.target_step_id });
            return _.get(step, 'position', '');
          }

          function editAutomation(automation) {
            var modal = $uibModal.open({
              component: 'automationComponent',
              windowClass: 'single-automation-modal',
              keyboard: false,
              backdrop: 'static',
              resolve: {
                editId: function () {
                  return automation.id;
                },
                process: function () {
                  return $ctrl.process;
                }
              }
            });

            modal.result.then(prepareAutomationsInfo);
            $rootScope.$emit('OPEN_AUTOMATION_PAGE', { automation: automation });
          }

          function openAutomationPage() {
            $rootScope.$emit('OPEN_AUTOMATION_PAGE');
          }

          function toggleBasicsAccordion (e, option) {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            trackTabEvent(option);
            $ctrl.stepEditTypeOpen = false;
            $ctrl.stepEditMilestoneOpen = false;
            _.forEach($ctrl.stepOptions, function (op) {
              if (op.value !== option.value) {
                op.isExpanded = false;
              } else {
                if (op.isExpanded) {
                  op.isExpanded = false;
                }
              }
            });
            $ctrl.onTabChanged({ tab: option });
          }

          //Prepare milestones dropdown
          function prepareMilestoneDropdown () {
            $ctrl.milestoneOptions = angular.copy($ctrl.process.stages.data);
            $ctrl.milestoneOptions.push({
              id: 'new',
              label: 'createMilestone',
              value: 'new'
            });
            if ($ctrl.step.stage_id) {
              $ctrl.selectedMilestone = _.find($ctrl.milestoneOptions, { id: $ctrl.step.stage_id.toString() });
            }
          }

          //Remove milestone from step
          function removeMilestone ($select, e) {
            e.stopPropagation();
            $select.clear();
            onStepMilestonesSelected($select);
          }

          //Toggle advanced accordion
          function toggleAdvancedAccordion (e, option) {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            trackTabEvent(option);
            _.forEach($ctrl.stepAdvancedOptions, function (op) {
              if (op.value !== option.value) {
                op.isExpanded = false;
              } else {
                op.isExpanded = !op.isExpanded;
              }
            });
            $ctrl.onTabChanged({ tab: option });
          }

          //Set basics accordion view
          function setBasicsAccordion (option) {
            if (option.key === 'basics') {
              _.forEach($ctrl.stepOptions, function (opt) {
                opt.isExpanded = false;
              });
            }
          }

          /**
           * event handler when active tab has been changed
           * @type {*|(function())}
           */
          updateActiveTabEventHandler = $rootScope.$on('TAB:CHANGE_ACTIVE_TAB', function (event, changeActiveTab) {
            $ctrl.activeTab = changeActiveTab;
          });

          /**
           * event handler when process prerun has been added
           * @type {*|(function())}
           */
          updateStepAndPrerunCountEventHandler = $rootScope.$on('STEP:COUNT_TOTAL_STEPS_AND_PRERUN', function () {
            $ctrl.stepOptions = StepService.getStepOptions($ctrl.process);
          });

          var onStepAddedHandler = $rootScope.$on('STEPS:ADD', function (e, data) {
            $ctrl.stepOptions = StepService.getStepOptions(data.process);
            $ctrl.stepAdvancedOptions = StepService.getStepAdvancedOptions(data.process);
            prepareStepTab($ctrl.step);
          });

          var onStepRemovedHandler = $rootScope.$on('STEPS:REMOVE', function (e, data) {
            $ctrl.stepOptions = StepService.getStepOptions(data.process);
            $ctrl.stepAdvancedOptions = StepService.getStepAdvancedOptions(data.process);
            prepareStepTab($ctrl.step);
          });

          var onKOFieldAddedHandler = $rootScope.$on('KO_FIELD:ADD', function (e, data) {
            $ctrl.stepOptions = StepService.getStepOptions(data.process);
            $ctrl.stepAdvancedOptions = StepService.getStepAdvancedOptions(data.process);
            prepareStepTab($ctrl.step);
          });

          var onProcessDiscardHandler = $rootScope.$on('PROCESS:DISCARD', function (e, data) {
            $ctrl.stepOptions = StepService.getStepOptions(data.process);
            $ctrl.stepAdvancedOptions = StepService.getStepAdvancedOptions(data.process);
            prepareStepTab($ctrl.step);
          });

          /**
           * event handler when other step name updated
           * @type {*|(function())}
           */
          var onStepUpdatedEventHandler = $rootScope.$on('STEP:UPDATE_STEP', function (event, step) {
            prepareStepTab(step);
          });

          var conditionFlagWatcherHandler = $scope.$watch('$ctrl.step.condition.flag', function (value) {
            if (value) {
              prepareStepTab($ctrl.step);
            }
          }, true);

          unRegisteredEventStepUpdated = $rootScope.$on('STEP:UPDATED', function (e, data) {
            var stepData = data.step || data;
            prepareTabNames(stepData);
          });

          activeTabWatcher = $scope.$watch('$ctrl.activeTab', function (newValue, oldValue) {
            if ((newValue !== oldValue)) {
              setAccordionView(newValue);
            }
          });

          activeCategoryWatcher = $scope.$watch('$ctrl.activeCategory', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              setBasicsAccordion(newValue);
            }
          });

          stageDeletedWatcher = $rootScope.$on('STAGE:DELETED', function (data) {
            prepareMilestoneDropdown();
          })

          //controller ends
        }
    });
})();

(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicProcessPage', {
      templateUrl: 'app/components/public/pages/process.page.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }
        }
    });
})();
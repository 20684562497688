/**
 * @ngdoc Component
 * @name tallyfy.organizations.createOrganization
 * @module tallyfy.organizations
 *
 * @description
 * A component to create new organization
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('createOrganizationV2', {
      templateUrl: 'app/modules/organizations/createOrganizationV2/createOrganizationV2.html',
      controller:
      /*@ngInject*/
        function ($rootScope, $state, $stateParams, _, moment, Growl, blockUI, $filter, UsersService, $analytics, CONST, CONFIG, AuthPlan, $q, AccountService, OrganizationsService, Principal, Helper, ENV_CONFIG, $timeout, DateUtils, preferencesStore, $interval, VALIDATION) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('createNewOrg'),
            growl = new Growl(),
            orgCarouselViews = OrganizationsService.getOrgCarouselViews(),
            interval;

          /**
           * @access public
           * public properties
           */
          $ctrl.emailPattern = CONST.EMAIL_REGEX;
          $ctrl.trialPlans = AuthPlan.getPlans();
          $ctrl.maxInviteesNumber = 3;
          $ctrl.tallyfyUrl = CONFIG.MARKETING_URL;
          $ctrl.currentUser = $rootScope.identity;
          $ctrl.orgCreationInitialized = false;
          $ctrl.problemSolvesSomethingElse = false;
          $ctrl.viewWelcomeVideo = false;
          $ctrl.progressValue = 0;
          $ctrl.orgResponse = [];
          $ctrl.loadingHome = false;
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          /**
           * @access public
           * public methods
           */
          $ctrl.getLogoURL = getLogoURL;
          $ctrl.onSetOrgName = onSetOrgName;
          $ctrl.setTrialPlan = setTrialPlan;
          $ctrl.addAnotherInviteeMember = addAnotherInviteeMember;
          $ctrl.removeInviteeMember = removeInviteeMember;
          $ctrl.onCompleteSubmit = onCompleteSubmit;
          $ctrl.goToPreviousView = goToPreviousView;
          $ctrl.onSetProblemSolve = onSetProblemSolve;
          $ctrl.onSetProcessImprove = onSetProcessImprove;
          $ctrl.getDefaultLogoURL = getDefaultLogoURL;
          $ctrl.onSomethingElseRoles = onSomethingElseRoles;
          $ctrl.onRemoveRoles = onRemoveRoles;
          $ctrl.onSomethingElseProblemSolves = onSomethingElseProblemSolves;
          $ctrl.onSomethingElseProcessImprove = onSomethingElseProcessImprove;
          $ctrl.isOptionsRequired = isOptionsRequired;
          $ctrl.onHomePage = onHomePage;
          $ctrl.onSetOrgIndustry = onSetOrgIndustry;
          $ctrl.changeIndustryName = changeIndustryName;
          $ctrl.onPhoneDialCodeChange = onPhoneDialCodeChange;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            OrganizationsService.getSignupQuestions().then(function (res) {
              var getSignupQuestions = _.get(res, 'data');
              $ctrl.problemSolves = _.find(getSignupQuestions, { 'question_id': 1 });
              $ctrl.processImproves = _.find(getSignupQuestions, { 'question_id': 2 });
              $ctrl.roles = _.find(getSignupQuestions, { 'question_id': 3 });
            });
            OrganizationsService.getIndustries().then(function (response) {
              if (!_.isUndefined(response)) {
                $ctrl.industries = response;
              }
            })
            $ctrl.isProdEnv = (ENV_CONFIG.APP_ENV === 'prod');
            $ctrl.orgDetails = OrganizationsService.getNewOrgData();
            $ctrl.newSignupcollect = OrganizationsService.getSignupDetails();
            if (!$ctrl.orgDetails.name) {
              $ctrl.orgCreationInitialized = true;
              trackEvent('SIGNUP - ORG CREATION PROCESS - STARTED');
            }
            initViewHandler($stateParams.view_name);
            $ctrl.viewName = _.get($stateParams, 'view_name');
            $ctrl.defaultUrl = 'assets/images/logo@2x.png';
            onPhoneDialCodeChange();
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            if (interval) {
              $interval.cancel(interval);
            }
          }

          /**
           * ngdoc method
           * @name setView
           * @description Set current view 
           */
          function setView(viewObj) {
            $ctrl.currentView = viewObj;
            setTemplate(viewObj.templateName);
          }

          /**
           * ngdoc method
           * @name setTemplate
           * @description Set template
           */
          function setTemplate(templateName) {
            $ctrl.templateUrl = 'app/modules/organizations/createOrganizationV2/includes/' + templateName + '.html';
          }

          /**
           * @ngdoc method
           * @name onSetOrgName
           * @description Event on org name set
           */
          function onSetOrgName() {
            if ($ctrl.orgNameForm.$valid) {
              OrganizationsService.setNewOrgData($ctrl.orgDetails);
              $ctrl.showIndustryDropdown = true;
            }
          }

          /**
           * @ngdoc method
           * @name onSetOrgIndustry
           * @description Event on org name set
           */
          function onSetOrgIndustry() {
            if ($ctrl.orgIndustryForm.$valid) {
              OrganizationsService.setNewOrgData($ctrl.orgDetails);
              $ctrl.viewName = "problem_solve";
              setTemplate('04_problem_solve');
            }
          }

          /**
           * @ngdoc method
           * @name changeIndustryName
           * @description Change industry name
           * @param {Object} industry
           * @returns {string} industry name
           */
          function changeIndustryName (industry) {
            $ctrl.orgDetails.industry = industry.name;
          }

          /**
           * @ngdoc method
           * @name setTrialPlan
           * @description Event on trial plan set
           */
          function setTrialPlan(planName) {
            $ctrl.orgDetails.preferred_trial_plan = planName;
            OrganizationsService.setNewOrgData($ctrl.orgDetails);
            $state.go('organizations.create', { view_name: 'invite_members_and_complete', allow_new_creation: $stateParams.allow_new_creation ? $stateParams.allow_new_creation : angular.noop() });
          }

          /**
           * @ngdoc method
           * @name addAnotherInviteeMember
           * @description Add another invite member obj
           */
          function addAnotherInviteeMember() {
            if ($ctrl.orgDetails.invited_members.length < $ctrl.maxInviteesNumber) {
              $ctrl.orgDetails.invited_members.push({ role: 'standard' });
              $ctrl.inviteMembersAndCompleteForm.$submitted = false;
            }
          }

          /**
           * @ngdoc method
           * @name removeInviteeMember
           * @description Remove invite member obj
           * @param {*} idx
           */
          function removeInviteeMember(idx) {
            $ctrl.orgDetails.invited_members.splice(idx, 1);
          }

          /**
           * @ngdoc method
           * @name onSetOrgName
           * @description Event on org name set
           */
          function onCompleteSubmit() {
            if ($ctrl.inviteMembersAndCompleteForm.$valid) {
              createOrganization();
            }
          }

          /**
           * ngdoc method
           * @name getLogoURL
           * @description Get Logo URL
           */
          function getLogoURL() {
            var defaultUrl = 'assets/images/logo@2x.png';
            if ($ctrl.orgDetails.preferred_trial_plan) {
              var selectedPlan = _.find($ctrl.trialPlans, { name: $ctrl.orgDetails.preferred_trial_plan });
              if (selectedPlan) {
                return selectedPlan.imageLarge;
              }
            }
            return defaultUrl;
          }
                    
          /**
           * ngdoc method
           * @name getLogoURL
           * @description Get Logo URL
           */
          function getDefaultLogoURL() {
            return 'assets/images/logo@2x.png';
          }

          /**
           * ngdoc method
           * @name goToPreviousView
           * @description Go to previous view
           */
          function goToPreviousView(view_name) {
            if (view_name){
              OrganizationsService.setNewOrgData($ctrl.orgDetails);
              $state.go('organizations.create', { view_name: view_name, allow_new_creation: $stateParams.allow_new_creation ? $stateParams.allow_new_creation : angular.noop() });
            }
          }

          /**
           * ngdoc method
           * @name initViewHandler
           * @description Go to previous view
           */
          function initViewHandler(view_name) {
            if ((!orgCarouselViews.hasOwnProperty(view_name) || !$ctrl.orgDetails.name) && (view_name !== 'org_name')){
              $state.go('organizations.create', { view_name: 'org_name', allow_new_creation: $stateParams.allow_new_creation ? $stateParams.allow_new_creation : angular.noop() }, { location: 'replace' });
            } else if ($ctrl.orgDetails.name && !$ctrl.orgDetails.preferred_trial_plan && (view_name !== 'org_name' && view_name !== 'plan_selection')) {
              $state.go('organizations.create', { view_name: 'plan_selection', allow_new_creation: $stateParams.allow_new_creation ? $stateParams.allow_new_creation : angular.noop() }, { location: 'replace' });
            } else {
              setView(orgCarouselViews[$stateParams.view_name]);
            }
          }

          /**
           * @ngdoc method
           * @private
           * @name getPhoneNumber
           *
           * @description
           * get user phone number with dial code
           */
          function getPhoneNumber() {
            var userNumber = $ctrl.orgDetails.phone.replace(/\D/g, '');
            var dialCode = $ctrl.orgDetails.phoneDialCode.replace(/\D/g, '');
            if (!_.startsWith(userNumber, dialCode)) {
              userNumber = dialCode + userNumber;
            }
            return userNumber;
          }

          /**
           * ngdoc method
           * @name createOrganization
           * @description Signup API call
           */
          function createOrganization() {
            var orgPayload = angular.copy($ctrl.orgDetails), params = {};
            if (moment.tz.guess()) {
              var userTZ = moment.tz.guess();
              if (userTZ === 'Asia/Calcutta') {
                userTZ = 'Asia/Kolkata';
              }
              orgPayload.timezone = userTZ;
            }
            if (orgPayload.phone) {
              orgPayload.phone = getPhoneNumber();
            }
            if ($stateParams.allow_new_creation && $stateParams.allow_new_creation === $ctrl.currentUser.id.toString()) {
              params.allow_multiple_orgs = 1;
            }
            orgPayload = _.omit(orgPayload, ['phoneDialCode', 'agreeToTerms', 'invited_members']);
            orgPayload.signup_survey.scheduleCall = orgPayload.scheduleCall ? 'Yes' : 'No';
            blockUI.start();
            OrganizationsService.createOrganization(params, orgPayload).then(function (response) {
              $ctrl.orgResponse = response;
              var paramsNewSignup =  {
                org: response.data.id
              };
              var questions = {questions:$ctrl.newSignupcollect};
              OrganizationsService.createSignupQuestions(paramsNewSignup, questions).then(function () {
              }, function () { });
              $ctrl.viewWelcomeVideo = true;
            }, function (error) {
              blockUI.stop();
              growl.error(error.data.message, {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              /*Uncomment in future*/
              /*var errorMessages = _.get(error, 'data.validation_errors') || _.get(error, 'data.errors');
              if (errorMessages) {
                angular.forEach(errorMessages, function (value, key) {
                  $ctrl.tailorForm[key].$invalid = true;
                  $ctrl.tailorForm[key].$error[key] = value;
                });
              }*/
            });
          }

          function signupSuccessHandler(response) {
            if (response.error) {
              blockUI.stop();
              growl.error('signup.messages.errors.' + response.code, {
                variables: {
                  'reason': 'validation_error'
                }, referenceId: 'createNewOrg', disableIcons: true, disableCloseButton: true
              });
              return;
            }

            trackEvent('SIGNUP COMPLETE - ORG CREATED');

            if ($ctrl.isProdEnv) {
              appendOrgCreationFBIframe();
            }
            
            if ($ctrl.orgDetails.invited_members.length && _.get(response, 'data.id')) {
              var inviteeResource = [], firstNames = [];
              _.forEach($ctrl.orgDetails.invited_members, function (inviteeMember) {
                inviteeMember.email = _.toLower(inviteeMember.email);
                if (inviteeMember.email && (inviteeMember.email !== _.toLower($ctrl.currentUser.email)) && inviteeMember.first_name && inviteeMember.last_name) {
                  inviteeMember.message = $filter('translate')('userProfile.defaultFormValues.message');
                  inviteeResource.push(UsersService.invite({
                    org: _.get(response, 'data.id')
                  }, inviteeMember));
                  firstNames.push(inviteeMember.first_name);
                }
              });
              
              if (inviteeResource.length) {
                $q.all(inviteeResource).then(function () {
                  getUserData(response.data);
                  growl.success($filter('translate')('user.messages.invitesSent', { inviteesStr: firstNames.join(', ') }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, function () {
                  Growl.clearAllMessages('global');
                  getUserData(response.data);
                });
              } else {
                getUserData(response.data);
              }
            } else {
              getUserData(response.data);
            }
          }

          /**
           * @function
           * @name getUserData
           * @description get user datas such as user information and organization
           * @param {*} createdOrg
           */
          function getUserData(createdOrg) {
            $q.all([
              AccountService.me(),
              OrganizationsService.myOrganizations({ with : 'subscription', per_page: 999 })
            ]).then(function (response) {
              if ($ctrl.isProdEnv) {
                $timeout(function () {
                  removeOrgCreationFBIframe();
                }, 2000);
              }
              var user = _.get(response, '[0].data'),
                orgs = _.get(response, '[1].data');
              !_.isArray(orgs) ? orgs = [] : '';
              setSession(user, orgs, createdOrg);
            }).catch(function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name setSession
           * @description set user data into user identity
           * @param {*} user
           * @param {*} orgs
           * @param {*} createdOrg
           */
          function setSession(user, orgs, createdOrg) {
            var identity;

            if (!_.isEmpty(user)) {
              identity = user;
              identity.authorities = ['USER_ROLE'];

              /*@TODO: Uncomment this when roles & permissions are back*/
              /*identity.permissions = [];
              identity.preferences = [];*/

              //set user identity
              Principal.authenticate(identity);

              var index = _.findIndex(orgs, { 'id': createdOrg.id });
              if (index > -1) {
                var redirectOrg = orgs[index];
                orgs.splice(index, 1);
                orgs.unshift(redirectOrg);
              }
              setOrganization(orgs);
            } else {
              blockUI.stop();
            }
          }

          /**
           * @function
           * @name setOrganization
           * @description ser user organizations
           * @param {*} orgs
           */
          function setOrganization(orgs) {
            Principal.setOrganization(orgs).then(function () {
              $rootScope.$broadcast('authenticationSuccess');
              $state.go('dashboard');
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @name appendOrgCreationFBIframe
           *
           * @description
           * method to add org creation fb iframe
           */
          function appendOrgCreationFBIframe() {
            var iframe = document.createElement('iframe');
            iframe.style.display = "none";
            iframe.id = 'fb_org_created_iframe';
            iframe.src = 'https://tallyfy.com/fb-px-completedregistration/';
            document.body.appendChild(iframe);
          }

          /**
           * @name removeOrgCreationFBIframe
           *
           * @description
           * method to remove org creation fb iframe
           */
          function removeOrgCreationFBIframe() {
            var iframe = document.getElementById('fb_org_created_iframe');
            if (iframe) {
              iframe.parentNode.removeChild(iframe);
            }
          }

          /**
           * @name trackEvent
           * @param {*} eventLabel
           * 
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventLabel) {
            $analytics.eventTrack('Click', { label: eventLabel, category: Helper.eventTrackingCategories['signup'] });
          }

          /**
           * @ngdoc method
           * @name onSetProblemSolve
           * @description Event on org problem solve set
           */
          function onSetProblemSolve() {
            if ($ctrl.problemSolveForm.$invalid) {
              return;
            }
            var roleId = [], problemSolvesId = [];
            _.forEach($ctrl.roleSelected, function (role) { roleId.push(role.id); });
            _.forEach($ctrl.problemSolvesSelected, function (problemSolves) { problemSolvesId.push(problemSolves.id); });
            $ctrl.problemSolvesSelected ? $ctrl.newSignupcollect.push({ id: 1, options:problemSolvesId, notes: $ctrl.problemSolveSomethingElseField }) : angular.noop();
            $ctrl.roleSelected ? $ctrl.newSignupcollect.push({ id: 3, options:roleId,  notes: $ctrl.roleSomethingElseField }) : angular.noop();
            $ctrl.viewName = "process_improve";
            setTemplate('05_process_improve');
          }

          /**
           * @ngdoc method
           * @name onSetProcessImprove
           * @description Event on org process improve set
           */
          function onSetProcessImprove() {
            $ctrl.viewName = "dashboard_complete";
            setTemplate('06_dashboard_complete');
            $ctrl.start = 0;
            interval = $interval(function () {
              $ctrl.progressValue = Math.min(100, parseInt(100.0 * $ctrl.start / 100));
              $ctrl.start += 5;
              $ctrl.width = $ctrl.progressValue + '%';
              if ($ctrl.progressValue === 100) {
                $interval.cancel(interval);
                var processImproveId = [];
                $ctrl.processImproveSelected = _.filter($ctrl.processImproves.options, { selected: true });
                _.forEach($ctrl.processImproveSelected, function (processImprove) { processImproveId.push(processImprove.id); });
                $ctrl.processImproveSomethingElse ? $ctrl.newSignupcollect.push({ id: 2, options:processImproveId, notes: $ctrl.processImproveSomethingElseField }) : angular.noop();
                OrganizationsService.setSignupDetails($ctrl.newSignupcollect);
                $state.go('organizations.create', { view_name: 'invite_members_and_complete', allow_new_creation: $stateParams.allow_new_creation ? $stateParams.allow_new_creation : angular.noop() });
              }
            }, 50);
          }

          /**
           * @ngdoc method
           * @name isOptionsRequired
           * @description check for options is required
          */
          function isOptionsRequired() {
            return !$ctrl.problemSolves.options.some(function(options){
              return options.selected;
            });
          }

          function onSomethingElseRoles(select) {
            $ctrl.selectedRoled = _.find(select.selected, { id: 18 });
            $ctrl.roleSelected = _.get(select, 'selected');
            if($ctrl.selectedRoled) {
              $ctrl.rolesSomethingElse = true;
            }
          }

          function onRemoveRoles(select) {
            $ctrl.selectedRole = _.find(select.selected, { value: "roles_something_else" });
            $ctrl.selectedSomethingElse = _.find(select.selected, { id: 18 });
            if(!$ctrl.selectedSomethingElse) {
              $ctrl.rolesSomethingElse = false;
            }
          }

          function onSomethingElseProblemSolves(event) {
            $timeout(function() {
              $ctrl.problemSolvesSelected = _.filter($ctrl.problemSolves.options, { selected: true });
              if(event.target.value === '6') {
                $ctrl.problemSolvesSomethingElse = !$ctrl.problemSolvesSomethingElse;
              }
            }, 0);
          }

          function onSomethingElseProcessImprove(event) {
            $timeout(function() {
              $ctrl.processImproveSomethingElse = _.filter($ctrl.processImproves.options, { selected: true });
              if(event.target.value === '13') {
                $ctrl.processImprovesSomethingElse = !$ctrl.processImprovesSomethingElse;
              }
            }, 0);
          }

          /**
           * @ngdoc method
           * @name onHomePage
           * @description Event on go to home page
          */
          function onHomePage(data) {
            $ctrl.loadingHome = true;
            signupSuccessHandler(data);
          }

          function onPhoneDialCodeChange () {
            $ctrl.phoneMaxLength = VALIDATION.PHONE_NUM_MAX_LENGTH - ($ctrl.orgDetails.phoneDialCode.replace(/\D/g, '').length);
          }
        }
    });
})();
/**
 * @ngdoc Filter
 * @name limitHtml
 * @module tallyfy
 * @description
 * Set manually string limit. This is to fix the string limit issues.
 * @author Kiran Sompura ( gmail:: kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .filter('limitHtml', function() {
      return function(text, limit, id) {
        var changedString = String(text).replace(/<[^>]+>/gm, ''),
         xText = angular.copy(changedString);
        document.getElementById('btn_more_'+id).classList.add("hide");
        if (xText && xText.length > 200) {
          document.getElementById('btn_more_'+id).classList.remove("hide");
        }
        return limit === 'full' ? changedString : (changedString.length > parseInt(limit) ? changedString.substr(0, parseInt(limit) - 1)+'...' : changedString);
      };
    });
})();
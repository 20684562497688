/**
 * @ngdoc Config
 * @name tallyfy.run.config.state
 * @module tallyfy.run
 *
 * @description
 * set route for create run
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.run')
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider.state('run.create', {
          url: '/process/:process_id/create?default_run_name&ko_fields&launchprocess',
          data: {
            authorities: ['USER_ROLE']
          },
          params: {
            ko_fields: { type: 'string', raw: true },
            launchprocess: { type: 'boolean', value: false, squash: true }
          },
          views: {
            'run_view': {
              component: 'createRun'
            }
          },
          // resolve: {
          //   users: ['UsersService', function (UsersService) {
          //     return UsersService.getUsers();
          //   }]
          // }
        });
      }]);
})();

/**
 * @ngdoc factory
 * @name tallyfy.tasks.factory.GuestTasksRepository
 * @module tallyfy.tasks
 *
 * @description
 * Create Rest resource for guest task
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .factory('GuestTasksRepository', GuestTasksRepository);
    /*@ngInject*/
    function GuestTasksRepository(_, BaseRepository, AuthServerProvider, $rootScope) {
      var BASE_URI_SEGMENT = '/organizations/:org', repository;

      repository = BaseRepository(BASE_URI_SEGMENT + '/guests/:action/:sub_action/:action_type', {
        org: 'org_id',
        action: '@action',
        sub_action: '@sub_action',
        action_type:'@action_type'
      }, {});
      
      /**
       * @function getGuestCompletedTasksByEmail
       * @description get guest completed tasks by email
       */
      repository.getGuestCompletedTasksByEmail = function (args) {
        args = args || {};
        var param = {
         action_type:'completed'
        };
        angular.extend(args, param);
        return repository.get(args);
      };
      
       /**
       * @function updateTaskByGuest
       * @description update task by guest user
       */
      repository.updateTaskByGuest = function (args) {
        args = args || {};
        var guestCode = AuthServerProvider.getToken(true),
            param = {
                org: _.get($rootScope.identity, 'guest.organization.id'),
                action: guestCode,
                sub_action: 'tasks',
                action_type: args.id
            };
        return repository.update(param, { guests: args.owners.guests });
      };

      return repository;
    }
})();

/**
 * @ngdoc overview
 * @name tallyfy.organizations.users
 *
 * @module tallyfy.organizations.users
 *
 * @description
 * Manager tallyfy.organizations users
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations.users', []);
})();

/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.zapier
 * @module tallyfy.integration
 *
 * @description
 * A component to manage zapier integration
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .component('zapier', {
      bindings: {
        type: '<',
        index: "<"
      },
      templateUrl: 'app/modules/integration/zap/zap.html',
      controller: [
        '_',
        '$state',
        '$stateParams',
        'ENV_CONFIG',
        function ZapierController(_, $state, $stateParams, ENV_CONFIG) {
          var $ctrl = this;

          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;


          $ctrl.list = [];

          function initialization() {
            $ctrl.fold = $stateParams.fold;
            (!$ctrl.list.length) && getintegrationes();
            $ctrl.zapierLink = ENV_CONFIG.TALLYFY_ZAPIER_URL;
          }

          function getintegrationes() {
            $ctrl.list = [{
              "id": 8,
              "subject": "Tallyfy user feedback checklist",
              "from": "jessica@gmail.com",
              "avatar": "../assets/images/a1.jpg",
              "to": [
                { "name": "Mogen", "email": "mogen.abc@hotmail.com" }
              ],
              "content": "Neque quam. Aliquam sollicitudin venenatis ipsum ac feugiat.",
              "attach": [
                { "name": "c0.jpg", "url": "../assets/images/c0.jpg" }
              ],
              "date": "16:20 7/22/2014",
              "label": "angular",
              "fold": "important"
            }];
          }
        }
      ]
    });
})();
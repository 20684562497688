/**
 * @ngdoc Component
 * @name tallyfy.organizations.access.Component.groups
 * @module tallyfy.organizations.access
 *
 * @description
 * A component to manage org groups
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.access')
    .component('groups', {
      bindings: {
        users: '<' //this binding is resolved in Route resolve @see org.access.routes.js
      },
      require: {
      },
      templateUrl: 'app/modules/access/groups/groups.html',
      controller: [
        '_',
        'blockUI',
        '$log',
        'UsersService',
        'GroupService',
        '$uibModal',
        'Growl',
        '$filter',
        function (_, $log, GroupService, $uibModal, Growl, $filter) {
          var $ctrl = this,
            growl = new Growl('groups'),
            usersMap;
          /**
          * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.newGroup = newGroup;
          /**
           * public properties
           */

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            usersMap = _.keyBy($ctrl.users, 'id');
            getGroups();
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
         * @ngdoc method
         * @name getGroups
         * @description load groups 
         */
          function getGroups() {
            GroupService
              .all({
                with: 'users'
              })
              .then(function (response) {
                var groups = response.data;
                $ctrl.groups = _.map(groups, function (g) {
                  var u = _.get(g, 'users.data') || [];
                  g.users = GroupService.transformUsers(u);
                  return g;
                });
              }).catch(function (error) {
                $log.info('Error while loading groups', error);
              });
          }


          /**
           * @ngdoc method
           * @name newGroup
           * @description opens modal to add new Group
           * @param {any} $event
           */
          function newGroup($event) {
            if ($event) {
              $event.preventDefault();
            }
            $uibModal.open({
              backdrop: 'static',
              component: 'newGroup',
              size: 'md',
              resolve: {
                users: function () {
                  return $ctrl.users;
                }
              }
            }).result.then(function (group) {
              $log.info('Add new group modal is closed', group);
              var g = group, users = _.get(g, 'users.data') || [];
              g.users = GroupService.transformUsers(users);
              $ctrl.groups.unshift(g);
              growl.success($filter('translate')('groups.messages.created'));
            }, function () {
              $log.info('modal is cancelled');
            });
          }
          //end of controller
        }]
    });
})();

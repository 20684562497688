/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.taskSummary
 * @module tallyfy.tasks
 *
 * @description
 * task summary component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('taskSummary', {
      templateUrl: 'app/modules/tasks/task/summary/task-summary.html',
      bindings: {
        isExpanded: '=',
        summary: '=',
        task: '=',
        run: '=',
        isGuestTask: '<'
      },
      /*@ngInject*/
      controller: function TaskController($sce, _, $rootScope, $scope, Helper, CONST, CompactTaskService) {
        var $ctrl = this,
          realValue,
          unregisterUpdateCaptureListener,
          unregisterExpandListener;

        /**
         * component's lifeCycle hooks
         */
        $ctrl.$onInit = onInit;
        $ctrl.$onDestroy = onDestroy;
        $ctrl.$onChanges = onChanges;

        /**
         * public methods
         */
        $ctrl.isImage = Helper.isImage;
        $ctrl.getFileLocation = Helper.getFileLocation;
        $ctrl.trustAsHtml = trustAsHtml;

        /**
         * public properties
         */
        $ctrl.summaryRendered = false;
        $ctrl.isHasVariables = false;
        $ctrl.summaryValue = angular.copy($ctrl.summary);
        $ctrl.formFieldWithValues = [];

        /**
         * @ngdoc method
         * @name onInit
         * @description
         * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
         */
        function onInit() {
          realValue = $ctrl.summary || '';
          $ctrl.summaryValue = angular.copy(realValue);
          $ctrl.isHasVariables = (realValue.match(CONST.INSERT_VARIABLE_REGEX) || []).length > 0;
          if ($ctrl.isExpanded) {
            getSummaryValue();
          }
        }

        /**
        * @ngdoc method
        * @name onChanges
        * @description
        * A component's lifeCycle hook which is called when bindings are updated.
        */
        function onChanges() { }

        /**
         * @ngdoc method
         * @name onDestroy
         * @description
         * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
         * Usefull to release external resources, watches and event handlers.
         */
        function onDestroy() {
          unregisterUpdateCaptureListener();
          unregisterExpandListener();
        }

        function trustAsHtml(html) {
          return $sce.trustAsHtml(html);
        }

        /**
         * @ngdoc method
         * @name getTitleRealName
         * @description
         * get title name
         */
        function getSummaryValue() {
          CompactTaskService.mergeAllFormField($ctrl.task, $ctrl.summaryValue, $ctrl.isGuestTask, 'summary').then(function (res) {
            $ctrl.summary = angular.element('<div>' + res + '</div>')[0].innerText ? res : '';
            $ctrl.summaryRendered = true;
          });
        }

        /**
         * event handler when changed step's captures value
         * @type {*|(function())}
         */
        unregisterUpdateCaptureListener = $rootScope.$on('CAPTURE:UPDATE', function (event, data) {
          if ($ctrl.isExpanded) {
            getSummaryValue();
          }
        });

        /**
         * event handler when isExpanded changed
         * @type {*|(function())}
         */
        unregisterExpandListener = $scope.$watch('$ctrl.isExpanded', function (newValue, oldValue) {
          if (newValue !== oldValue && newValue === true) {
            getSummaryValue();
          }
        });
      }
    });
})();

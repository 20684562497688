/**
 * @ngdoc Service
 * @name tallyfy.appRefresher
 *
 * @module tallyfy
 *
 * @description
 * appRefresher service to check for recent deployment changes, and hence reload the app
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('appRefresher', appRefresher);

  /*@ngInject*/
  function appRefresher(_, $http, $interval, $window, ENV_CONFIG, $log) {
    var self = this;
      
    /**
     * @property deploymentCheckInterval
     * @private
     *  stores constant value // 15 minutes in milliseconds
     **/
    var deploymentCheckInterval = ENV_CONFIG.deploymentCheckInterval || 900000;
    
    /**
     * @property versionCheckPromise
     * @private
     *  stores cancellable promise
     **/
    var versionCheckPromise;

    /**
     * @ngdoc method
     * @name getLatestBuildVersion
     * @description Get the latest version file (to check for any deployment changes)
     * @returns void
     */
    function getLatestBuildVersion() {
      $http({
        method: 'GET',
        url: '/version.json' + '?cacheBuster=' + Date.now(),
        params: { skipNotFound: true }
      }).then(function (response) {
        if (_.get(response, 'data.version') !== ENV_CONFIG.buildVersion) {
          $window.location.reload(true);
        }
      }).catch(function () {
        $log.error('Unable to load version.json file');
      });
    }

    /**
     * @ngdoc method
     * @name init
     * @description Initialize the timer / interval to fetch the version.json file
     * @returns void
     */
    self.init = function() {
      if (!_.get(ENV_CONFIG, 'isDevEnv')) {
        versionCheckPromise = $interval(getLatestBuildVersion, deploymentCheckInterval);
      }
    };
  }
})();

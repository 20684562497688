/**
 * 0.1.1
 * Deferred load js/css file, used for ui-jq.js and Lazy Loading.
 * 
 * @ flatfull.com All Rights Reserved.
 * Author url: http://themeforest.net/user/flatfull
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('uiLoad', uiLoad);

  uiLoad.$inject = ['$document', '$q', '$timeout', '_'];

  function uiLoad($document, $q, $timeout, _) {
    var self = this;
    var loaded = [];
    var promise = false;
    var deferred = $q.defer();

    /**
     * Chain loads the given sources
     * @param srcs array, script or css
     * @returns {*} Promise that will be resolved once the sources has been loaded.
     */
    self.load = function (srcs) {
      srcs = angular.isArray(srcs) ? srcs : srcs.split(/\s+/);
      if (!promise) {
        promise = deferred.promise;
      }
      angular.forEach(srcs, function (src) {
        promise = promise.then(function () {
          return src.indexOf('.css') >= 0 ? self.loadCSS(src) : self.loadScript(src);
        });
      });
      deferred.resolve();
      return promise;
    }

    /**
     * Dynamically loads the given script
     * @param src The url of the script to load dynamically
     * @returns {*} Promise that will be resolved once the script has been loaded.
     */
    self.loadScript = function (src, option) {
      option = option || {};
      if (loaded[src]) return loaded[src].promise;

      var deferred = $q.defer();
      var script = $document[0].createElement('script');
      script.src = src;
      if (option.id) {
        script.id = option.id;
      }
      script.async = true;
      script.onload = function (e) {
        $timeout(function () {
          deferred.resolve(e);
        });
      };
      script.onerror = function (e) {
        $timeout(function () {
          deferred.reject(e);
        });
      };
      $document[0].body.appendChild(script);
      loaded[src] = deferred;

      return deferred.promise;
    };

    /**
     * Dynamically loads the given CSS file
     * @param href The url of the CSS to load dynamically
     * @returns {*} Promise that will be resolved once the CSS file has been loaded.
     */
    self.loadCSS = function (id, styles) {
      var docHead = document.head;
      if (docHead) {
        var styleElement = document.createElement('style');
        styleElement.setAttribute("id", id);
        styleElement.type = 'text/css';
        if (styleElement.styleSheet) {
          styleElement.styleSheet.cssText = styles;
        } else {
          styleElement.appendChild(document.createTextNode(styles));
        }
        document.getElementsByTagName("head")[0].appendChild(styleElement);
      }
    };

    /**
    * Dynamically unloads the given CSS file
    * @param id The id of the CSS to unload dynamically
    * @returns boolean that will be true once the CSS file has been unloaded successfully or otherwise false.
    */
    self.unloadCSS = function (id) {
      var docHead = document.head;
      if (docHead) {
        var styleElement = document.getElementById(id);
        if (styleElement) {
          styleElement.remove();
        }
      }
    };

    self.isLoaded = function (src) {
      return loaded[src] ? true : false;
    };

    /**
    * Dynamically unloads the given javascript file
    * @param src The url of the javascript to unload dynamically
    * @returns boolean that will be true once the javascript file has been unloaded successfully or otherwise false.
    */
    self.unloadScript = function (src) {
      delete loaded[src];
      var doc = document;
      if (doc) {
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--;) {
          if (_.startsWith(scripts[i].src, src)) {
            scripts[i].remove();
          }
        }
      }
    };

  }
})();

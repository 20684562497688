(function () {
  'use strict';
  angular.module('tallyfy')
    .component('variableSelector', {
      templateUrl: 'app/components/compact/items/variable-selector/variable-selector.component.html',
      bindings: {
        process: '<',
        variableSelected: '&',
        fieldId: '<?',
        entityType: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $filter) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.variableClicked = variableClicked;
          $ctrl.stepsFormFields = [];
          $ctrl.koFormFields = [];

          function onInit() {
            if ($ctrl.process) {
              prepareInsertVariables();
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function variableClicked(isKoField, variable) {
            $ctrl.isDropDownOpen = !$ctrl.isDropDownOpen;
            if (typeof $ctrl.variableSelected === 'function') {
              $ctrl.variableSelected({ isKoField: isKoField, field: variable });
            }
          }

          function prepareInsertVariables() {
            var preruns = $ctrl.process.steps ? $ctrl.process.prerun : ($ctrl.process.ko_form_fields.data || []);
            if (!_.isEmpty(preruns)) {
              $ctrl.prerun = truncateFields(preruns);
              $ctrl.koFormFields.push({
                name: $filter('translate')('steps.logic.label.pre_run'),
                type: 'Prerun',
                capture: $ctrl.prerun
              });
            }
            var steps = $ctrl.process.steps ? $ctrl.process.steps.data : $ctrl.process.tasks.data;
            _.map(steps, function (step, key) {
              if (step.captures || step.form_fields.data) {
                $ctrl.captures = truncateFields(step.captures ? step.captures : step.form_fields.data);
                if ($ctrl.captures.length > 0) {
                  $ctrl.stepsFormFields.push({
                    id: step.id,
                    name: $filter('translate')('steps.describes.insertVariable.step', { stepNumber: key + 1, stepName: truncateName(step.title, 30) }),
                    type: 'Step',
                    capture: $ctrl.captures
                  });
                }
              }
            });
          }

          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            var copyFields = angular.copy(fields);
            copyFields = _.filter(copyFields, function (cpField) {
              return (!cpField.isNew && cpField.id !== $ctrl.fieldId) && cpField.field_type !== "file" && cpField.field_type !== "table" && cpField.field_type !== "assignees_form" && !cpField.use_wysiwyg_editor;
            });
            return _.map(copyFields, function (field) {
              if(field.id !== $ctrl.fieldId) {
                return angular.extend(field, {
                  full_label: field.label,
                  label: truncateName(field.label, 25),
                  type_field: prepareFieldsType(field.field_type) + " " + (field.alias || '').split('-').pop()
                });
              }
            });
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function prepareFieldsType(fieldType) {
            return $filter('translate')('steps.describes.insertVariable.' + fieldType);
          }
        }
    })
})();
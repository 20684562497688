/**
 * @ngdoc Directive
 * @name tallyfy.scrollDetector
 * @description detect scroll event
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollDetector', scrollDetector);
    /*@ngInject*/
    function scrollDetector() {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('scroll', function () {
            if (element[0].scrollHeight - element.scrollTop() < element.height() + 100) {
              scope.$apply(attrs.scrollDetector);
            }
          });
        }
      };
    }
})();


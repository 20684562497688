(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('autoTooltip', autoTooltip);

  function autoTooltip() {
    var directive = {
      restrict: 'A',
      scope: {
        url: '@?'
      },
      link: function ($scope, $element, $attributes) {
        var parent = $element[0].parentNode, style = 'position:absolute;left:0;top:-100%;width:100%;height:100%;margin:1px 0 0;border:none;opacity:0;pointer-events:none;';

        function onResize(element, handler) {
          var frame = document.createElement('iframe');
          var supportsPE = document.documentMode < 11 && 'pointerEvents' in frame.style;

          frame.style.cssText = style + (supportsPE ? '' : 'visibility:hidden;');
          frame.onload = function () {
            frame.contentWindow.onresize = function () {
              handler(element);
            };
          };
          element.appendChild(frame);
          return frame;
        }

        parent.style.position = "relative";
        onResize(parent, function (e) {
          parent.getBoundingClientRect().width < $element[0].getBoundingClientRect().width + 15 ? $element.removeClass('no-pointer') : $element.addClass('no-pointer')
        })
      }
    };

    return directive;
  }
})();

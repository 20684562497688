/**
  * @ngdoc factory
  * @name tallyfy.tasks.factory.TasksRepository
  * @module tallyfy.tasks
  *
  * @description
  * Create Rest resource for tasks
  *
  * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
  * 
  * */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .factory('TasksRepository', TasksRepository);
      /**
       * Constructor
       * TasksRepository construct 
       */
      function TasksRepository(BaseRepository, TaskTransformer, CONFIG, Helper) {
        var BASE_URI_SEGMENT = '/organizations/:org', repository;
        var transformResponse = function (response) {
          var transformedResponse = {};
          response = response && Helper.isJson(response) ? angular.fromJson(response) : { message: response };
          if (response.hasOwnProperty('data')) {
            transformedResponse.data = new TaskTransformer(response.data);
          } else {
            transformedResponse.data = new TaskTransformer(response);
          }
          if (response.hasOwnProperty('meta')) {
            transformedResponse.meta = response.meta;
          }
          return transformedResponse;
        };

        repository = BaseRepository(BASE_URI_SEGMENT + '/:action/:action_id/tasks/:id/:sub_action/:run_id', {
          org: 'org_id',
          action: '@action',
          action_id: '@action_id',
          sub_action: '@sub_action',
          id: '@id'
        }, {
          $all: { method: 'GET', cancellable: true, transformResponse: transformResponse },
          $get: { method: 'GET', transformResponse: transformResponse },
          $update: { method: 'PUT', transformResponse: transformResponse },
          $create: { method: 'POST', transformResponse: transformResponse },
          updateOOTForm: {
            method: 'PUT', 
            url: CONFIG.API_HOST + '/organizations/:org/tasks/:id/form-fields/:field_id',
            transformResponse: transformResponse 
          },
          deleteOOTForm: {
            method: 'DELETE', 
            url: CONFIG.API_HOST + '/organizations/:org/tasks/:id/form-fields/:field_id',
            transformResponse: transformResponse 
          },
          createBulkTasks: {
            method: 'POST',
            url: CONFIG.API_HOST + '/organizations/:org/:action/:action_id/create-tasks',
            transformResponse: transformResponse
          },
          linkTaskBlueprint: {
            method: 'POST',
            url: CONFIG.API_HOST + '/organizations/:org/tasks/:id/link-checklist/:checklist_id',
            transformResponse: transformResponse
          },
          setBulkTasks: {
            method: 'PUT',
            url: CONFIG.API_HOST + '/organizations/:org/runs/:run_id/update-tasks'
          }
        });

        repository.linkStandaloneTask = function (args) {
          args = args || {};
          var params = {
            id: args.id,
            checklist_id: args.checklist_id
          };
          angular.extend(params, args);
          return repository.linkTaskBlueprint(params, args);
        };

        repository.getUserTasks = function (args) {
          args = args || {};
          var params = {
            with: 'comments'
          };
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.getPublicProcessTasks = function (args) {
          var params = {
            skipAuthToken: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/public/runs/:run_id/tasks', { 
            org: 'org_id',
            run_id: args.runId
          }, {
            $get: { method: 'GET', transformResponse: transformResponse }
          }).get(params);
        };

        repository.getPublicProcessFormFields = function (args) {
          var params = {
            skipAuthToken: true,
            skipNotFound: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/public/runs/:id/form-fields', { 
            org: 'org_id',
            id: args.runId
          }, {
            $get: { method: 'GET', transformResponse: transformResponse }
          }).get(params);
        };

        repository.resendTask = function (requestParameters) {
          return repository.get(requestParameters);
        };

        repository.getUserCompletedTasks = function (args) {
          args = args || {};
          var params = {
            with: 'comments',
            id: 'completed'
          };
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.getCurrentUserTasks = function (args) {
          args = args || {};
          var params = {
            action: 'me',
            with: 'comments'
          };
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.getRunTasks = function (args) {
          args = args || {};
          var params = {
            action: 'runs'
          };
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.createTask = function (args, data) {
          args = args || {};
          var params = {
            action: 'runs'
          };
          angular.extend(params, args);
          return repository.create(params, data);
        };

        repository.createStandaloneTask = function (data, isFormsFieldIncluded) {
          var params = {};
          if(isFormsFieldIncluded) {
            angular.extend(params, { with: 'form_fields' });
          }
           
          return repository.create(params, data);
        };

        repository.createBulkStandaloneTasks = function (runId, data) {         
          var params = {
            action: 'runs',
            action_id: runId
          };
          return repository.createBulkTasks(params, { tasks: data }).$promise;
        };

        repository.updateStandaloneTask = function (args, data) {
          return repository.update(args, data);
        };

        repository.deleteStandaloneTask = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/tasks/:id', { org: 'org_id' }, {}).delete(args);
        };
        
        repository.createOOTForm = function (args, data) {
          var params = {
            sub_action: 'form-fields'
          };
          angular.extend(params, args);
          return repository.create(params, data);
        };

        repository.restoreStandaloneTask = function (args) {
          args = args || {};
          var params = {
            with: 'run,threads,threads.reactions,step,form_fields,ko-form-fields'
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/tasks/:id/restore', { org: 'org_id' }, {}).update(params);
        };

        repository.archiveTask = function (args) {
          args = args || {};
          var params = {
            action: 'runs'
          };
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.getTask = function (args) {
          var params = {};
          args = args || {};
          if (args.action_id) {
            params = {
              action: 'runs'
            };
          }
          angular.extend(params, args);
          return repository.get(params);
        };

        repository.updateTask = function (args, data) {
          args = args || {};
          var params = {
            action: 'runs'
          };
          angular.extend(params, args);
          return repository.update(params, data);
        };

        repository.getGuestTask = function (args) {
          if (!args.action) {
            args.action = 'tasks';
          } else {
            args.id = 'tasks';
          }
          args = args || {};
          var params = {
            skipAuthToken: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/:action/:action_id/:id', { 
            org: 'org_id' 
          }, {
            $get: { method: 'GET', transformResponse: transformResponse }
          }).get(params);
        };

        repository.updateGuestTask = function (args, data) {
          args = args || {};
          var params = {
            skipAuthToken: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/tasks/:action_id', { 
            org: 'org_id'
          }, {}).update(params, data);
        };

        repository.completeGuestTask = function (args, data, taskTypeAction) {
          args = args || {};
          var params = {
            skipAuthToken: true
          };
          if (taskTypeAction.taskType === 'approval') {
            data.is_approved = taskTypeAction.isApproved;
          }
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/tasks/:action_id/complete', { 
            org: 'org_id', 
            action_id: data.task_id
          }, {}).update(params, data);
        };

        repository.getGuestOrganization = function () {
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/organization', { 
            org: 'org_id'
          }, {}).get({
            skipAuthToken: true
          });
        };

        repository.getGuestTaskUrlFromOldCode = function (args) {
          args = args || {};
          var params = {
            skipAuthToken: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/tasks/:old_code', { 
            org: 'org_id'
          }, {}).get(params);
        };

        repository.completeTask = function (args, data, taskTypeAction) {
          if (taskTypeAction.taskType === 'approval') {
            data.is_approved = taskTypeAction.isApproved;
          }
          return BaseRepository(BASE_URI_SEGMENT + '/:action/:run_id/completed-tasks', { org: 'org_id' }, {}).save(args, data).$promise;
        };

        repository.incompleteTask = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/:action/:run_id/completed-tasks/:task_id', { org: 'org_id' }, {}).delete(args);
        };

        repository.completeKoTask = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/:action/:run_id/complete-prerun',  angular.extend({ org: 'org_id' }, args), {}).save().$promise;
        };

        repository.incompleteKoTask = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/:action/:run_id/complete-prerun', angular.extend({ org: 'org_id' }, args), {}).delete();
        };
        
        repository.setShowHideTask = function (args) {
          var params = {
            action: args.action
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/:org_id/runs/:run_id/tasks/:task_id/force-action', {
            run_id: args.runId,
            task_id: args.taskId
          }, {
            $update: { method: 'PUT', transformResponse: transformResponse }
          }).update(params);
        };

        repository.getTaskById = function (args) {
          return BaseRepository(BASE_URI_SEGMENT + '/tasks/:task_id', angular.extend({ org: 'org_id' }, args), {}).get(args);
        };

        repository.reOrderTask = function (args, data) {
          return repository.update(args, data);
        };

        repository.updateBulkTasks = function (params, data) {
          var paramsObj = {
            org: params.org,
            run_id: params.run_id
          };
          return repository.setBulkTasks(paramsObj, data);
        };

        repository.getGuestTaskById = function (args) {
          var params = {
            skipAuthToken: true
          };
          angular.extend(params, args);
          return BaseRepository(BASE_URI_SEGMENT + '/guests/guest_code/tasks/:id', { 
            org: 'org_id'
          }, {}).get(params);
        };

        repository.addedJobTasks = function (params, data) {
          return BaseRepository(BASE_URI_SEGMENT + ':org_id/organization-roles/add-object', params, {
            $update: { method: 'POST', transformResponse: transformResponse }
          }).update(data);
        };
        
        repository.removedJobTasks = function (params, data) {
          return BaseRepository(BASE_URI_SEGMENT + 'organizations/:org/roles-object/:id', params, {
            $update: { method: 'DELETE', transformResponse: transformResponse }
          }).update(data);
        };

        return repository;
      }
})();

/**
 * @ngdoc Config
 * @name tallyfy.process.config.ProcessStateConfig
 * @module tallyfy.process
 *
 * @description
 * set route process Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .config(ProcessStateConfig);

  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  ProcessStateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
  /**
   * Constructor
   * ProcessStateConfig construct 
   */
  function ProcessStateConfig($stateProvider) {
    /**
     * blueprint's parent state
     */
    $stateProvider.state('process', {
      parent: 'app',
      url: '/:org_id',
      data: {
        authorities: ['USER_ROLE']
      },
      views: {
        'content': {
          component: 'process'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('process');
          $translatePartialLoader.addPart('steps');
          $translatePartialLoader.addPart('runs');
          $translatePartialLoader.addPart('tasks');
          $translatePartialLoader.addPart('organizations');
          $translatePartialLoader.addPart('compact');
          $translatePartialLoader.addPart('libraryFaq');
          $translatePartialLoader.addPart('magicLink');
          return $translate.refresh();
        }]
      }
    });
    /**
     * Create blueprint
     * This is the only place to write the route for `CREATE Blueprint`
     * This route conflicts with edit/read `'/blueprints/:slug/:view?action&step',`
     * So, it is recommended to register `'/blueprints/create'` before `'/blueprints/:slug/:view?action&step',`
     */
    $stateProvider.state('process.create', {
      url: '/blueprints/create?folder_id&blueprintType',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        title: 'Create Blueprint',
        folder_id: { squash: true, value: null },
        blueprintType: 'snippet'
      },
      onEnter: ["ProcessService", "$stateParams", function (ProcessService, $stateParams) {
        var blueprintType = $stateParams.blueprintType || '';
        ProcessService.openBluePrintModal(blueprintType);
      }],
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });

    /**
     * Create blueprint V2
     */
    $stateProvider.state('process.new', {
      url: '/blueprints/new?folder_id',
      data: {
        authorities: ['USER_ROLE']
      },
      params: {
        title: 'New Blueprint',
        folder_id: { squash: true, value: null }
      },
      onEnter: ["ProcessService", "$stateParams", function (ProcessService, $stateParams) {
        ProcessService.openNewBluePrintModal($stateParams.folder_id);
      }],
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();

/**
 * @ngdoc Directive
 * @name tallyfy.contenteditable
 * @module tallyfy
 *
 * @description
 * contenteditable
 *
 * @author Adi Winata (gmail::adheegm@gmail.com)
 */
(function () {
  angular.module('tallyfy').
    directive('contenteditable', contentEditable);

  /*@ngInject*/
  function contentEditable($sce) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function (scope, element, attrs, ngModel) {
        var editableValueWatcher;
        if (!ngModel) return;

        ngModel.$render = function () {
          element.html(ngModel.$viewValue || '');
          read();
        };

        element.on('blur keyup change', function () {
          scope.$evalAsync(read);
        });

        function read() {
          var html = element.html();
          if (attrs.stripBr && html == '<br>') {
            html = '';
          }
          if (attrs.textOnly) {
            var tempEl = angular.element('<div>').html(html);
            html = tempEl.text();
          }
          ngModel.$setViewValue(html);
        }

        editableValueWatcher = scope.$watch(function () {
          return element.attr('contenteditable');
        }, function (newValue) {
          if (newValue === 'true') {
            if (element.data('autofocus')) {
              element.focus();
            }
          }
        });

        scope.$on('$destroy', function () {
          editableValueWatcher();
        });
      }
    };
  }
})();
/**
 * @ngdoc overview
 * @name tallyfy.account
 *
 * @module tallyfy.account
 *
 * @description
 * manage account
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function() {
    'use strict';
    angular.module('tallyfy.account', ['tallyfy.core']);
})();
(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicLibraryDetail', {
      templateUrl: 'app/components/public/pages/library-detail/library-detail.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $state, $stateParams, $timeout, $filter, moment, OrganizationsService, PublicLibraryService, StepService, ProcessService, BLUEPRINT_TYPE, DateUtils, TFY_EVENTS) {
          var $ctrl = this,
            stepChangesTimeoutHandler,
            condensedElement,
            body = angular.element('body');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.dirtyCheckHandler = dirtyCheckHandler;
          $ctrl.beforeRightPaneClose = beforeRightPaneClose;
          $ctrl.onItemSwitched = onItemSwitched;
          $ctrl.onRightPaneClosed = onRightPaneClosed;
          $ctrl.onTabChanged = onTabChanged;

          $ctrl.tagsData = [];
          $ctrl.metadata = {};

          function onInit() {
            $ctrl.publicOrganization = $rootScope.publicOrganization;
            $ctrl.blueprintId = $stateParams.blueprintId;
            initializePublicLibraryDetail();
            if ($stateParams.bpOrgId) {
              OrganizationsService.getPublicOrgCustomBranding({
                org: $stateParams.bpOrgId
              }).$promise.then(function (res) {
                $rootScope.$emit(TFY_EVENTS.BRANDING.UPDATE_COLOR, {
                  reset: 'yes',
                  setTheme: res.data
                });
              });
            }
          }

          function onChanges() { }

          function onDestroy() {
            stepChangesTimeoutHandler ? $timeout.cancel(stepChangesTimeoutHandler) : angular.noop();
            angular.element('.app-body').removeClass('compact-step-snippet-active');
            body.removeClass('body-overflow-visible');
          }

          function initializePublicLibraryDetail() {
            $ctrl.isBusy = true;
            PublicLibraryService
            .getPublicBlueprintDetail({
              action: 'organizations',
              action_id: $ctrl.publicOrganization ? $ctrl.publicOrganization.id : $stateParams.bpOrgId,
              sub_action: 'checklists',
              sub_action_id: $ctrl.blueprintId,
              with: 'steps,steps.captures,tags',
              skipAuthToken: true,
              featured: false
            }).then(function (res) {
                $ctrl.publicBlueprint = res.data;
                PublicLibraryService.getBlueprintStepsDeadline({
                  action: 'organizations',
                  action_id: $ctrl.publicOrganization ? $ctrl.publicOrganization.id : $stateParams.bpOrgId,
                  sub_action: 'checklists',
                  sub_action_id: $ctrl.blueprintId,
                  featured: false
                }).then(function (responces) {
                    updateDeadlines(responces.data);
                    $ctrl.isBusy = false;
                    $rootScope.$emit('PUBLIC_LIBRARY_DETAIL:BLUEPRINT_SELECTED', {
                      publicLibrary: $ctrl.publicBlueprint
                    });
                    if ($ctrl.publicBlueprint.type === BLUEPRINT_TYPE.DOCUMENT) {
                      angular.element('.app-body').addClass('compact-step-snippet-active');
                    } else {
                      angular.element('.app-body').removeClass('compact-step-snippet-active');
                    }
                    !$ctrl.selectedItem ? checkStepSelected() : angular.noop();
                    $ctrl.tocFocusElementId = $stateParams.section;
                    $timeout(function () {
                      $ctrl.showTOC = !!$ctrl.tocFocusElementId;
                    }, 350);
                    $timeout(function () {
                      condensedHeader();
                    }, 1000);
                });
            }, function () {
                $ctrl.isBusy = false;
            });
          }

          function updateDeadlines(data) {
            $ctrl.highestDeadline = ProcessService.getProcessDeadline(data, 'deadline_unformatted');
            setStepsAbsoluteDeadlines(data);
          }

          function dirtyCheckHandler(args) {
            var defer = $q.defer();
            if ($ctrl.selectedItem) {
              dirtyHandler(args).then(
                function (status) {
                  status.isValid ? setTab(status.tab) : angular.noop();
                  defer.resolve({ isValid: args.activeTab ? false : status.isValid });
                }, function (status) {
                  status.isValid ? setTab(status.tab) : angular.noop();
                  defer.resolve({ isValid: args.activeTab ? false : status.isValid });
                }
              );
            } else {
              setTab(args.activeTab || args.item.activeTab);
              defer.resolve({ isValid: !!!args.activeTab });
            }
            return defer.promise;
          }

          function dirtyHandler(args) {
            var defer = $q.defer(), tab;
            if (args) {
              tab = args.activeTab || args.item.activeTab;
            }
            defer.resolve({
              isValid: true,
              tab: tab
            });
            return defer.promise;
          }

          function setTab(tab) {
            stepChangesTimeoutHandler = $timeout(function () {
              $ctrl.stepActiveTab = tab;
            }, 0);
          }

          function beforeRightPaneClose(args) {
            var defer = $q.defer();
            dirtyHandler(args).then(
              function (status) {
                status.isValid ? setTab(status.tab) : angular.noop();
                defer.resolve({ isValid: status.isValid });
              }, function (status) {
                status.isValid ? setTab(status.tab) : angular.noop();
                defer.resolve({ isValid: status.isValid });
              }
            );
            return defer.promise;
          }

          function onItemSwitched(item) {
            var stepId = getSelectedStepAlias(item);
            $state.transitionTo($state.current, angular.extend($stateParams, { step: stepId }), { notify: false });
          }

          function getSelectedStepAlias(selectedItem) {
            var id;
            if (selectedItem.id === 'ko-step') {
              id = 'ko-step';
            } else if (selectedItem.id === 'process-description') {
              id = 'process-description';
            } else {
              id = selectedItem.step.id;
            }
            return id;
          }

          function onRightPaneClosed() {
            $state.transitionTo($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false });
          }

          function onTabChanged(tab) {
            var args = {
              activeTab: tab
            };
            if ($ctrl.stepActiveTab.value === tab.value) {
              return;
            }
            dirtyHandler(args).then(
              function (status) {
                setTab(status.tab);
              }, function (status) {
                setTab(status.tab);
              }
            );
          }

          function checkStepSelected() {
            if ($ctrl.selectedItem || !$stateParams.step) {
              return;
            }
            var stepId = $stateParams.step;
            if (stepId === 'process-description') {
              if ($ctrl.publicBlueprint.summary) {
                $ctrl.selectedItem = {
                  id: 'process-description',
                  process: $ctrl.publicBlueprint
                };
              } else {
                $state.transitionTo($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false });
              }
            } else if (stepId === 'ko-step') {
              if ($ctrl.publicBlueprint.kickoff_title || $ctrl.publicBlueprint.prerun.length) {
                $ctrl.selectedItem = {
                  id: 'ko-step',
                  process: $ctrl.publicBlueprint
                };
              } else {
                $state.transitionTo($state.current, angular.extend($stateParams, { step: void 0 }), { notify: false });
              }
            } else {
              var index = _.findIndex($ctrl.publicBlueprint.steps.data, { id: stepId });
              if (index > -1) {
                var stepTabs = StepService.getStepOptions($ctrl.publicBlueprint);
                $ctrl.selectedItem = {
                  index: index,
                  step: $ctrl.publicBlueprint.steps.data[index],
                  activeTab: stepTabs[0]
                };
              }
            }
          }

          function setStepsAbsoluteDeadlines(deadlines) {
            var steps = $ctrl.publicBlueprint.steps.data;
            _.forEach(steps, function (step) {
              var deadline = _.find(deadlines, {
                step_id: step.id
              });
              deadline.deadline_time = DateUtils.toLocal(deadline.deadline_time).format();
              deadline.deadline_unformatted = DateUtils.toLocal(deadline.deadline_time);
              if (!_.isUndefined(deadline)) {
                step.absolute_deadline = deadline.deadline_unformatted;
                step.deadlineAsHumanized = humanizeDeadline(step.absolute_deadline).replace('in', '');
                step.deadlineAsString = StepService.getStepDeadlineAsStr(steps, step);
              }
            });
          }

          function humanizeDeadline(deadline) {
            return $filter('tallyfyTimeAgo')(deadline);
          }

          function condensedHeader() {
            body.addClass('body-overflow-visible');
            condensedElement = angular.element('.library-header-detail');
            condensedElement.addClass('condensed-element');
          }
        }
    });
})();
/**
 * @ngdoc overview
 * @name tallyfy.organizations
 *
 * @module tallyfy.organizations
 *
 * @description
 * manage organizations
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.organizations', ['tallyfy.organizations.access', 'tallyfy.organizations.users','tallyfy.core']);
})();
/**
 * @ngdoc Directive
 * @name tallyfy.scrollPosition
 * @description detect scroll event
 * @restrict 'A'
 * @element ANY
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('scrollPosition', scrollPosition);
    /*@ngInject*/
    function scrollPosition() {
      return {
        restrict: 'A',
        scope: {
          offset: "@offset",
          isPageScrolled: '='
        },
        link: function (scope, element, attrs) {
          angular.element(document.querySelector('.more-detail-drawer-modal .modal-content')).bind("scroll", function (event) {
            if (this.scrollTop >= scope.offset) {
              scope.isPageScrolled = true;
            }
            if (this.scrollTop === 0) {
              scope.isPageScrolled = false;
            }
            scope.$apply();
          });
        }
      };
    }
})();



/**
 * @ngdoc Directive
 * @name tallyfy.fadeError
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular.module('tallyfy')
    .directive('fadeError',
      /*@ngInject*/
      function ($timeout) {
        return {
          restrict: 'A',
          scope: {
            fadeError: '<'
          },
          link: function (scope, element) {
            var fadeTimeoutHandler = null;

            /**
             * @private
             * @ngdoc method
             * 
             * @description
             * method to handler reset the field
             */
            function resetField() {
              scope.fadeError.form[scope.fadeError.field].$valid = true;
              scope.fadeError.form[scope.fadeError.field].$invalid = false;
            }
            
            // first init, reset the field
            if (scope.fadeError.isError) {
              resetField();
            }

            // watch scope properties (isError)
            var deRegisterFadeErrorWatcher = scope.$watch('fadeError.isError', function (newValue, oldValue) {
              if (newValue === oldValue) {
                return;
              }
              if (scope.fadeError.isError) {
                fadeTimeoutHandler ? $timeout.cancel(fadeTimeoutHandler) : angular.noop();
                fadeTimeoutHandler = $timeout(function () {
                  resetField();
                }, scope.fadeError.timeout);
              }
            });

            // on element destroy
            scope.$on('$destroy', function () {
              deRegisterFadeErrorWatcher();
              if (fadeTimeoutHandler) {
                $timeout.cancel(fadeTimeoutHandler);
              }
            });
          }
        };
      });
})();
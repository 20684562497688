/**
 * @ngdoc component
 * @name tallyfy.compactItemTitle
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactItemTitle', {
      templateUrl: 'app/components/compact/items/compactTitle/compact-item-title.component.html',
      bindings: {
        container: '@?',
        heading: '=?',
        headingOnEditMode: '=?',
        onHeadingClickCallback: '&?',
        maxLength: '<?',
        subHeading: '=?',
        subHeadingLabel: '<?',
        subHeadingOnEditMode: '=?',
        onSubHeadingClickCallback: '&?',
        maxHeadingCharacter: '@?',
        maxSubHeadingCharacter: '@?',
        tooltipDisabled: '<', // this is tooltipDisabled check for heading
        tooltippedPosition: '@?',
        onHeadingUpdated: '&?',
        onSubHeadingUpdated: '&?',
        customHeaderClass: '<',
        customSubHeaderClass: '<',
        focusOnEdit: '<',
        subHeadingEditModeValue: '=?',
        headingEditModeValue: '=?',
        isPublic: '<',
        progessBar: '<?',
        appendPopupInBody: '<?',
        containerOffset: '<?',
        headingTranslateEnable: '<?',
        subHeadingTranslateEnable: '<?',
        disableTruncated: '<',
        process: '<',
        headingEditMode: '@?',
        taskPosition: '<?',
        subHeadingTooltipDisabled: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $timeout, TranslationService, CONST) {
          var $ctrl = this,
            textHeightTimeoutHandler,
            contentLanguageChangedHandler,
            editorLostFocusHandler;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onHeadingUpdatedHandler = onHeadingUpdatedHandler;
          $ctrl.onSubHeadingUpdatedHandler = onSubHeadingUpdatedHandler;
          $ctrl.onHeadingClick = onHeadingClick;
          $ctrl.onSubHeadingClick = onSubHeadingClick;
          $ctrl.getTooltip = getTooltip;
          $ctrl.addVariableValue = addVariableValue;
          $ctrl.variableItemClicked = variableItemClicked;

          function onInit() {
            if ($ctrl.headingTranslateEnable || $ctrl.subHeadingTranslateEnable) {
              $ctrl.translateTo = TranslationService.getMyContentLanguage();
            }
          }

          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * angularjs life cycle hook
           */
          function onDestroy() {
            textHeightTimeoutHandler ? $timeout.cancel(textHeightTimeoutHandler) : angular.noop();
          }

          /**
           * @ngdoc method
           * @name onHeadingUpdatedHandler
           * @param {*} e 
           * 
           * @description
           * on heading updated
           */
          function onHeadingUpdatedHandler(e) {
            if (editorLostFocusHandler) {
              $timeout.cancel(editorLostFocusHandler);
            }
            editorLostFocusHandler = $timeout(function () {
              (e)
                ? ((e.type === 'click')
                  ? e.stopPropagation() : (e.type === 'blur')
                    ? $ctrl.onHeadingUpdated({ heading: $ctrl.heading }) : angular.noop()) : $ctrl.onHeadingUpdated({ heading: $ctrl.heading });
              $ctrl.headingOnEditMode = false;
            }, 250);
          }

          /**
           * @ngdoc method
           * @name onSubHeadingUpdatedHandler
           * @param {*} e 
           * 
           * @description
           * on heading updated
           */
          function onSubHeadingUpdatedHandler(e) {
            if (editorLostFocusHandler) {
              $timeout.cancel(editorLostFocusHandler);
            }
            editorLostFocusHandler = $timeout(function () {
              (e)
                ? ((e.type === 'click')
                  ? e.stopPropagation() : (e.type === 'blur')
                    ? $ctrl.onSubHeadingUpdated({ subHeading: $ctrl.subHeading }) : angular.noop()) : $ctrl.onSubHeadingUpdated({ subHeading: $ctrl.subHeading });
              $ctrl.subHeadingOnEditMode = false;
            }, 250);
          }

          function variableItemClicked(e) {
            if (editorLostFocusHandler) {
              $timeout.cancel(editorLostFocusHandler);
            }
          }

          function addVariableValue(isKoField, field, variable) {
            $timeout(function () {
              $ctrl[variable] = $ctrl[variable] + ' {{' + field.alias + '}}';
              angular.element(document.getElementsByTagName('textarea')[0]).focus();
            }, 100);
          }

          /**
           * @ngdoc method
           * @name onHeadingClick
           * @param {*} e 
           * 
           * @description
           * on heading click handler
           */
          function onHeadingClick(e) {
            if (typeof $ctrl.onHeadingClickCallback === 'function') {
              $ctrl.onHeadingClickCallback({ e: e });
            }
          }

          /**
           * @ngdoc method
           * @name onSubHeadingClick
           * @param {*} e 
           * 
           * @description
           * on sub heading click handler
           */
          function onSubHeadingClick(e) {
            if (typeof $ctrl.onSubHeadingClickCallback === 'function') {
              $ctrl.onSubHeadingClickCallback({ e: e });
            }
          }

          /**
           * @ngdoc method
           * @name getTooltip
           * @param {*} toolTip
           * @description
           * remove html tag from tool tip 
           */
          function getTooltip(toolTip) {
            return toolTip.replace(CONST.REMOVE_HTML_TAG_REGEX, " ");
          }

          contentLanguageChangedHandler = $rootScope.$on('CONTENT_LANGUAGE_CHANGED', function () {
            $ctrl.translateTo = TranslationService.getMyContentLanguage();
          });
        }
    });
})();
/**
 * @ngdoc Service
 * @name $realtime
 *
 * @module tallyfy
 *
 * @description
 * Service for showing modals
 *
 * @author Kodeplusdev
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .service('realtime', realtime);

  realtime.$inject = ['Pubnub', 'CONFIG', '$localStorage', '$rootScope', '_'];

  var subscribeChannels = [],
    isPubnubInitialized = false;

  function realtime(Pubnub, CONFIG, $localStorage, $rootScope, _) {
    this.init = function () {
      if (!isPubnubInitialized) {
        var userId = _.get($rootScope, 'identity.email') || _.get($rootScope, 'identity.guest.email');
        if (!userId) {
          return;
        }
        Pubnub.init({
          publishKey: CONFIG.PUBNUB_PUBLISH_KEY,
          subscribeKey: CONFIG.PUBNUB_SUBSCRIBE_KEY,
          authKey: $localStorage.authenticationToken,
          userId: userId
        });
        isPubnubInitialized = true;
      }
    };

    this.subscribe = function (channelName) {
      var channel = 'tallyfy_org_' + _.get($rootScope.identity, 'default_organization.id') + '.' + channelName;
      if (subscribeChannels.indexOf(channel) > -1) {
        return;
      }
      Pubnub.subscribe({
        channels: [channel],
        withPresence: true,
        triggerEvents: ['message', 'presence', 'status']
      });
      subscribeChannels.push(channel);
    };

    this.isAlreadyInitialized = function () {
      return isPubnubInitialized;
    };

    this.resetAndUnsubscribeAll = function () {
      subscribeChannels = [];
      Pubnub.unsubscribeAll();
    };

    this.setCurrentUserID = function () {
      var userId = _.get($rootScope, 'identity.email') || _.get($rootScope, 'identity.guest.email');
      Pubnub.setUserId(userId);
    };

    /**
     * Unsubscribe from all existing channels
     * @type {*|(function())}
     */
    $rootScope.$on('PUBNUB:UNSUBSCRIBE', function () {
      Pubnub.unsubscribe({
        channels: subscribeChannels
      });
    });

    /**
     * Subscribe to all existing channels
     * @type {*|(function())}
     */
    $rootScope.$on('PUBNUB:SUBSCRIBE', function () {
      Pubnub.subscribe({
        channels: subscribeChannels,
        withPresence: true,
        triggerEvents: ['message', 'presence', 'status']
      });
    });
  }
})();


/**
 * @ngdoc Component
 * @name tallyfy.steps.component.clientIntegrationModal
 * @module tallyfy.steps
 *
 * @description
 * A component to add / edit a client integration
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('clientIntegrationModal', {
      templateUrl: 'app/modules/support/components/client-integrations/components/client-integration-modal/client-integration-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (blockUI, SupportService, DESCRIPTIONSIZE, $rootScope, _) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('clientIntegrationModal'),
            clientObjPickValues = ['name', 'redirect', 'personal_access_client', 'password_client'];

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveClientIntegration = saveClientIntegration;

          /**
           * public properties
           */
          $ctrl.maxName = DESCRIPTIONSIZE.default;
          $ctrl.clientIntegration = {}; 

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.resolve.clientIntegrationObj) {
              $ctrl.clientIntegration = angular.copy($ctrl.resolve.clientIntegrationObj);
            } else {
              // Defaults
              $ctrl.clientIntegration = {
                personal_access_client: false,
                password_client: true
              };
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name saveClientIntegration
           * @description create / update client integration
           */
          function saveClientIntegration() {
            if ($ctrl.clientIntegrationForm.$valid && !blockUI.state().blocking) {
              saveClientIntegrationHandler();
            }
          }

          /**
           * @private
           */
          function saveClientIntegrationHandler() {
            var clientIntegration = angular.copy($ctrl.clientIntegration), resource;
            $ctrl.onSaving = true;
            resource = clientIntegration.id ? SupportService.updateClientIntegrations({ id: clientIntegration.id }, _.pick(clientIntegration, clientObjPickValues)) : SupportService.createClientIntegrations(clientIntegration);
            resource.then(function (response) {
              $ctrl.close({ $value: response.data });
            }, function () {
              $ctrl.onSaving = false;
              $ctrl.dismiss();
            });
          }
        }
    });
})();
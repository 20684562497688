(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.groups.factory.GroupRepository
   * @module tallyfy.process
   *
   * @description
   * Create Rest resource for process
   *
   * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
   * 
   * */
  angular
    .module('tallyfy.process')
    .factory('GroupRepository', GroupRepository);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  GroupRepository.$inject = ['BaseRepository', 'CONFIG', '$rootScope'];
  /**
   * Constructor
   * GroupRepository construct 
   */
  function GroupRepository(BaseRepository, CONFIG,$rootScope) {
    return BaseRepository('/organizations/:org/groups/:id', {
      org : 'org_id'
    }, {});
  }
})(); 
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('blueprintListPreview', {
      templateUrl: 'app/modules/dashboard/content-card-preview/blueprint-list-preview/blueprint-list-preview.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $state, ProcessService, $rootScope, store, UtilsService) {
          var $ctrl = this;

          $ctrl.activeTab = 'blueprint';
          $ctrl.myBlueprints = [];

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.viewAllBlueprints = viewAllBlueprints;

          function onInit() {
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id')
            getBlueprints();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            getUsers();
          }
          function onChanges() { }
          function onDestroy() { }

          function getBlueprints() {
            $ctrl.blueprintsLoading = true;
            ProcessService.filter({
              page: 1,
              per_page: 8,
              with: 'tags'
            }, true).then(function (res) {
              $ctrl.myBlueprints = res.data;
              $ctrl.blueprintsLoading = false;
            }, function () {
              $ctrl.blueprintsLoading = false;
            });
          }

          function viewAllBlueprints() {
            $state.go('process');
          }

          function getUsers () {
            store.getUsers().then(function (res) {
              $ctrl.canCreateOrEditTemplates = UtilsService.canCreateOrEditTemplates(res);
            });
          }
        }
    });
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('supportPublicBlueprints', {
      templateUrl: 'app/modules/support/components/public-blueprints/components/supportPublicBlueprints/support-public-blueprints.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, $state, $filter, blockUI, OrganizationsService, ProcessService, SupportService, SupportPublicBlueprintTableService, $uibModal) {
          var $ctrl = this,
            publicBlueprintData = [],
            blockUI = blockUI.instances.get('supportPublicBlueprints');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getProcessData = getProcessData;

          $ctrl.blueprintPagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          $ctrl.orgPagination = {
            pagination: {
              page: 1,
              per_page: 10
            },
            allItemsLoaded: false
          };

          function onInit() { }

          function onChanges() { }

          function onDestroy() { }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function rowModel(uid) {
            return _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
          }

          function isFeaturedChanged(uid) {
            var data = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            blockUI.start();
            ProcessService.update({
              org: data.organization_id,
              id: data.id
            }, data).then(function () {
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          function openBlueprint(uid) {
            var blueprint = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            if ($rootScope.identity.default_organization.id === blueprint.organization_id) {
              $state.go('process.edit', ({ slug: blueprint.id, view: 'edit' }));
            }
          }

          function openActivityModel(uid) {
            var blueprint = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {},
              blueprintData = _.find(publicBlueprintData, { id: blueprint.id });
            $uibModal.open({
              component: 'publicBlueprintActivityModal',
              windowClass: 'blueprint-modal create-blueprint-modal',
              backdrop: true,
              resolve: {
                activityData: function () {
                  return blueprintData.tracks_activities.data;
                }
              }
            });
          }

          function onItemSelected(item) {
            $ctrl.tableOptions.templateScope.variables.searchParams.org = item.id;
            $ctrl.tableOptions.templateScope.variables.displayedOrganizations = [];
            $ctrl.tableOptions.templateScope.callbacks.onColumnFilter();
          }

          function getProcessData(args) {
            var defer = $q.defer();
            if (!args) {
              $ctrl.orgPagination.allItemsLoaded = false;
              $ctrl.orgPagination.pagination.page = 1;
              publicBlueprintData = [];
              $ctrl.blueprintPagination.page = 0;
            }
            $ctrl.blueprintPagination = angular.extend($ctrl.blueprintPagination, {
              page: $ctrl.blueprintPagination.page || 1
            });
            var params = {
              page: $ctrl.blueprintPagination.page,
              per_page: $ctrl.blueprintPagination.per_page
            };
            $ctrl.isLoading = true;
            var searchParams = {};
            if ($ctrl.tableOptions.templateScope.variables.searchParams.org) {
              searchParams.org = $ctrl.tableOptions.templateScope.variables.searchParams.org;
            }
            if ($ctrl.tableOptions.templateScope.variables.searchParams.q) {
              searchParams.q = $ctrl.tableOptions.templateScope.variables.searchParams.q;
            }
            if ($ctrl.tableOptions.templateScope.variables.searchParams.is_public) {
              searchParams.is_public = $ctrl.tableOptions.templateScope.variables.searchParams.is_public;
            }
            if ($ctrl.tableOptions.templateScope.variables.searchParams.is_featured) {
              searchParams.is_featured = $ctrl.tableOptions.templateScope.variables.searchParams.is_featured;
            }
            getProcesses(angular.extend(params, searchParams, { with: 'tracks,tracks_activities' }))
              .then(function (res) {
                $ctrl.blueprintPagination = res.meta.pagination;
                var dataPublicBlueprints = prepareUIGridOptions(res.data);
                publicBlueprintData = dataPublicBlueprints;
                $ctrl.isLoading = false;
                defer.resolve(publicBlueprintData);
              }, function () {
                $ctrl.isLoading = false;
              });
            return defer.promise;
          }

          function onClearSelection() {
            $ctrl.tableOptions.templateScope.variables.searchParams.org = '';
            $ctrl.tableOptions.templateScope.variables.displayedOrganizations = [];
            $ctrl.tableOptions.templateScope.callbacks.onColumnFilter();
          }

          function prepareUIGridOptions(items) {
            var data = _.filter(items, function (tag) {
              return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
            });
            return data;
          }

          function getProcesses(args) {
            var defer = $q.defer();
            SupportService.getChecklists(args)
              .then(function (res) {
                defer.resolve(res);
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          function onOrganizationDropdownOpenHandler(isOpen) {
            if (!isOpen) {
              return;
            }
            OrganizationsService.myOrganizations(
              angular.extend($ctrl.orgPagination, {
                admin: true,
                skipNotFound: true
              })
            ).then(function (response) {
              $ctrl.tableOptions.templateScope.variables.displayedOrganizations = _.get(response, 'data', []);
              $ctrl.orgPagination.allItemsLoaded = response.meta.pagination.current_page >= response.meta.pagination.total_pages;
              if (!$ctrl.orgPagination.allItemsLoaded) {
                $ctrl.orgPagination.pagination.page++;
              }
            });
          }

          function getNextOrganizations(args) {
            var defer = $q.defer();
            if (args.init) {
              $ctrl.tableOptions.templateScope.variables.displayedOrganizations = [];
              $ctrl.orgPagination.allItemsLoaded = false;
              $ctrl.orgPagination.pagination.page = 1;
            }
            if ($ctrl.orgPagination.allItemsLoaded) {
              defer.resolve({ allItemsLoaded: true });
            } else {
              OrganizationsService.myOrganizations(
                angular.extend($ctrl.orgPagination.pagination, {
                  admin: true,
                  q: args.query,
                  skipNotFound: true
                })
              ).then(function (response) {
                $ctrl.tableOptions.templateScope.variables.displayedOrganizations = _.concat($ctrl.tableOptions.templateScope.variables.displayedOrganizations, _.get(response, 'data', []));
                $ctrl.orgPagination.allItemsLoaded = response.meta.pagination.current_page >= response.meta.pagination.total_pages;
                if (!$ctrl.orgPagination.allItemsLoaded) {
                  $ctrl.orgPagination.pagination.page++;
                }
                defer.resolve({ allItemsLoaded: $ctrl.orgPagination.allItemsLoaded });
              });
            }
            return defer.promise;
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: SupportPublicBlueprintTableService.getSupportPublicBlueprintTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'organization_name',
                  title: $filter('translate')('orgProfile.label.organizationName'),
                  initState: true
                }, {
                  field: 'title',
                  title: $filter('translate')('orgProfile.label.blueprintName'),
                  initState: true
                }, {
                  field: 'is_public',
                  title: $filter('translate')('orgProfile.label.isPublic'),
                  initState: true
                }, {
                  field: 'is_featured',
                  title: $filter('translate')('orgProfile.label.isFeatured'),
                  initState: true
                }, {
                  field: 'tracks',
                  title: $filter('translate')('orgProfile.label.tracks'),
                  initState: true
                }, {
                  field: 'tracks_activities',
                  title: $filter('translate')('orgProfile.label.tracksActivities'),
                  initState: true
                }]
              },
              tableFilterModel: {
                title: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  onColumnFilter: function () {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    getProcessData()
                      .then(function (res) {
                        $ctrl.tableOptions.gridConfig.dataSource.data(res);
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      }, function () {
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      });
                  },
                  openActivity: openActivityModel,
                  openBlueprint: openBlueprint,
                  onItemSelected: onItemSelected,
                  onClearSelection: onClearSelection,
                  getNextOrganizations: getNextOrganizations,
                  onOrganizationDropdownOpenHandler: onOrganizationDropdownOpenHandler,
                  isFeaturedChanged: isFeaturedChanged,
                  rowModel: rowModel,
                  isPublicChanged: isPublicChanged
                },
                variables: {
                  displayedOrganizations: [],
                  selectedOrganization: void 0,
                  isSelectBoxOpen: void 0,
                  onProcessesLoading: void 0,
                  searchParams: {
                    org: '',
                    q: '',
                    is_public: void 0,
                    is_featured: void 0
                  }
                }
              }
            };
          }

          function isPublicChanged(uid) {
            var data = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            blockUI.start();
            ProcessService.update({
              org: data.organization_id,
              id: data.id
            }, data).then(function () {
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }
        }
    });
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.users.component.userProfile
 * @module tallyfy.organizations.users
 *
 * @description
 * A component to update user profile
 *
 * @author Kiranv Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  
  angular
    .module('tallyfy.organizations.users')
    .component('userProfile', {
      templateUrl: 'app/modules/users/userProfile/userProfile.html',
      controller:
        /*@ngInject*/
        function (_, $stateParams, UsersService, AccountService, blockUI, Growl, $filter, $confirm, Permission, Upload, CONFIG, $state, $rootScope, AuthPlan, PLANS, TimeZone, TasksService, CONST,$log, store, TFY_EVENTS, $q, VALIDATION, OrganizationsService, FavoriteService, $timeout, Helper) {
          var $ctrl = this, 
            blockUI = blockUI.instances.get('userProfile'), 
            growl = new Growl(), 
            activeuser = [],
            firstName,
            lastName;

          /**
          * public properties
          */
          $ctrl.account = {};
          $ctrl.userForm = {};
          $ctrl.timeZones = [];
          $ctrl.loadUserProfile = true;
          $ctrl.emailPattern = CONST.EMAIL_REGEX;
          $ctrl.maxLength = VALIDATION.FN_LN_MAX_LENGTH;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();
          $ctrl.haveAuthority = Helper.checkAccessAuthority;

          /**
           * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
          * Expose bindable methods
          * these methods are accessible in view
          */
          $ctrl.updateUserProfile = updateUserProfile;
          $ctrl.enableUser = enableUser;
          $ctrl.disableUser = disableUser;
          $ctrl.resetForm = resetForm;
          $ctrl.withdrawInviteUser = withdrawInviteUser;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.prepareRoleOption = prepareRoleOption;
          $ctrl.getTimezones = getTimezones;
          $ctrl.toggleMemberWatcherStar = toggleMemberWatcherStar;

          /**
           * @function $onInit
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.infoName = $filter('translate')('userProfile.activateAccount.activateInfoName');
            $ctrl.action = $filter('translate')('userProfile.activateAccount.activateInfoAction');
            $ctrl.userOrganization = _.get($rootScope.identity, 'default_organization') || {};
            loadData();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"),"admin");
          }

          /**
          * @ngdoc method
          * @name onChanges
          * @description
          * A component's lifeCycle hook which is called when bindings are updated.
          */
          function onChanges() { }
          
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name userPermission
           * @private
           * @description Check user's permission
           * @returns {Boolean}
          */
          function userPermission() {
            $ctrl.allowUsersUpdate = Permission.hasPermission('users.update') && ($ctrl.account.type !== 'bot');
            $ctrl.allowUsersEnable = Permission.hasPermission('users.enable') && ($ctrl.account.type !== 'bot');
            $ctrl.allowUsersDisable = Permission.hasPermission('users.disable') && ($ctrl.account.type !== 'bot');
          }

          /**
           * @ngdoc method
           * @name loadData
           * @private
           * @description Get require datas on load
          */
          function loadData() {
            blockUI.start();
            $q.all([
              store.getUsers(),
              TimeZone.getAll(),
              store.getUserById($stateParams.id)
            ]).then(function (response) {
              $ctrl.users = _.head(response);
              activeuser = UsersService.getAvailableUsers(_.head(response));
              $ctrl.timeZones = response[1];
              updateAccountInfo(response[2].data);
              userPermission();
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name prepareOrgRoles
           * @description Get org roles and set current user role
           */
          function prepareOrgRoles() {
            $ctrl.userRoles = UsersService.getUserRoles();
            $ctrl.selectedOption = _.find($ctrl.userRoles, function (value) {
                return value.type === $ctrl.account.role;
            });
          }

          /**
           * @ngdoc method
           * @name prepareRoleOption
           * @description set/change role
           * @param {*} option
           */
          function prepareRoleOption(option) {
            if (option.id !== $ctrl.selectedOption.id) {
              $ctrl.selectedOption = angular.copy(option);
              $ctrl.userForm.$setDirty();
              $ctrl.account.role= $ctrl.selectedOption.type;
            }
          }

          /**
           * @ngdoc method
           * @name getGeneralInfo
           * @private
           * @description Returns some property in all object
           * @param {Object} account
           * @returns {Object} user
          */
          function getGeneralInfo(account) {
            return _.pick(account, ['id', 'email', 'first_name', 'last_name', 'username', 'job_description', 'phone', 'job_title', 'team', 'profile_pic', 'timezone', 'full_name', 'status', 'country', 'reassign_required', 'invited_by', 'approved_at', 'activated_at', 'disabled_by', 'disabled_at', 'reactivated_by', 'reactivated_at', 'role', 'type', 'member_watchers']);
          }

          /**
           * @ngdoc method
           * @name UpdateAccountInfo
           * @private
           * @description Returns some property in all object
           * @param {Object} account
           * @returns {Object} user
          */
          function updateAccountInfo(account) {
            $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, account);
            angular.extend($ctrl.account, getGeneralInfo(account));
            $ctrl.account.selectedTimezone = getTimezones($ctrl.account.timezone);
            firstName = $ctrl.account.first_name;
            lastName = $ctrl.account.last_name;
            $ctrl.defualValue = angular.copy($ctrl.account);
            setUserActivity();
            prepareOrgRoles();
          }
          
          function toggleMemberWatcherStar($event) {
            $event.stopPropagation();
            if (!$ctrl.haveAuthority()) {
              return;
            }
            if (_.get($ctrl.account, 'status') === "active") {
              var growlMessages = Growl.getAllMessages('global'), showAlertDelay = 0,
                active = _.get($ctrl.account, 'member_watchers.data[0].id') ? true : false;
              if (angular.copy(growlMessages.length) > 0) {
                showAlertDelay = 500;
                Growl.clearAllMessages('global');
              }
              if (!active) {
                blockUI.start();
                FavoriteService.addFavorite({
                  userId: _.get($rootScope, 'identity.id'),
                  objId: _.get($ctrl.account, 'id'),
                  objectType: 'user'
                }).then(function (res) {
                  $timeout(function () {
                    growl.success($filter('translate')('runs.dashboard.userAction.starred'), {
                      referenceId: 'global',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                  }, showAlertDelay);
                  $ctrl.account.member_watchers.data.push(res.data);
                  blockUI.stop();
                });
              } else if (_.get($ctrl.account, 'member_watchers.data[0].id')) {
                var watcherData = angular.copy(_.get($ctrl.account, 'member_watchers', { data: [] }));
                _.set($ctrl.account, 'member_watchers.data', []);
                Growl.clearAllMessages('global');
                $timeout(function () {
                  growl.success($filter('translate')('runs.dashboard.userAction.un-starred'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, showAlertDelay);
                FavoriteService.removeFavorite(_.get(watcherData, 'data[0].id'));
              }
            }
          }
          
          function getTimezones(select) {
            if ($ctrl.timeZones.length > 0) {
              return _.find($ctrl.timeZones, { value: select });
            }
            return select;
          }

          /**
           * @ngdoc method
           * @name updateUserProfile
           * @public
           * @description Update user's profile
           * @returns {Object}
          */
          function updateUserProfile() {
            var account = angular.copy($ctrl.account);
            if (_.isObject(account.selectedTimezone)) {
              account.timezone = _.get(account.selectedTimezone, 'value');  
            } else {
              account.timezone = account.selectedTimezone;            
            }
            account = _.omit(account, ['username', 'country', 'selectedTimezone']);
            if (!$ctrl.isAdminMember) {
              account = _.omit(account, ['role']);
            }
            account.country_id = _.get($ctrl.account, 'country.data.id');
            blockUI.start();
            if (!_.isUndefined($ctrl.userProfile) && !_.isNull($ctrl.userProfile) && $ctrl.userForm.file.$dirty) {
              updateUserPicture();
            }
            
            var sources = [
              AccountService.updateUserAccount($stateParams.id, account)
            ];
            
            if ($ctrl.isAdminMember){
              sources.push(
                UsersService.updateRole({
                  id: account.id,
                  role: account.role
                })
              );
            }
          
            $q.all(sources).then(function (response) {
              blockUI.stop();
              updateAccountInfo(response[0].data);
              showSuccessAlert($filter('translate')('userProfile.messages.updatedProfile'));
              $ctrl.userForm.$setPristine();
            }, function (error) {
              blockUI.stop();
              var erorrMessages = _.get(error, 'data.errors');
              if (erorrMessages) {
                angular.forEach(erorrMessages, function (value, key) {
                  $ctrl.userForm[key].$invalid = true;
                  $ctrl.userForm[key].$error[key] = value;
                });
              }
            });
          }

          /**
           * @ngdoc method
           * @name enableUser
           * @public
           * @description Enable user
           * @returns {Object}
          */
          function enableUser() {
            if (activeuser.length >= CONFIG.FREE_ACCOUNT_ACTIVE_USERS_LIMIT && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'coworkerLimit')))
              return;

            blockUI.start();
            UsersService.enable($ctrl.account.id).then(function (response) {
              blockUI.stop();
              updateAccountInfo(response.data);
              var message = $ctrl.account.full_name + $filter('translate')('regularUsers.buttons.enable.messages.success');
              showSuccessAlert(message);
              $state.go('settings.org.usersRegular', { org_slug: $ctrl.userOrganization.slug });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name disableUser
           * @public
           * @description Disable user
           * @returns {Object}
          */
          function disableUser() {
            if ($ctrl.account.reassign_required) {
              $state.go('userAssignments', {
                id: $stateParams.id,
                org_slug: $ctrl.userOrganization.slug ? $ctrl.userOrganization.slug : $ctrl.userOrganization.id
              }, { notify: true });
              return;
            }

            var isDisableUser = true;
            openConfirmModal(isDisableUser, disableAccount);
          }

          /**
           * @ngdoc method
           * @name withdrawUser
           * @public
           * @description Withdraw user
          */
          function withdrawInviteUser() {
            var isDisableUser = false ;
            openConfirmModal(isDisableUser, withdrawAccount);
          }

          /**
           * @ngdoc method
           * @name openConfirmModal
           * @param {*} status
           * @param {*} callback
           * @description Open confirmation modal before any action
           */
          function openConfirmModal(status, callback) {
            $confirm({
              header: $filter('translate')('userProfile.modal.header', {
                action: status ? $filter('translate')('userProfile.modal.remove') : $filter('translate')('userProfile.modal.withdraw'),
                fullname: $ctrl.account.full_name,
                organizationName: $ctrl.userOrganization.name
              }),
              body: '',
              buttons: {
                accept: status ? $filter('translate')('userProfile.button.removedUser') : $filter('translate')('userProfile.button.withdrawUser'),
                cancel: $filter('translate')('userProfile.modal.cancel')
              },
              modalType: 'modal-danger'
            }).then( function () {
              callback();
            }, function () {
              $log.info("Modal Dismiss at", new Date());
            });
          }

          /**
           * @ngdoc method
           * @name disableAccount
           * @description disable user account
           */
          function disableAccount() {
            blockUI.start();
            UsersService.disable($ctrl.account.id).then(function (response) {
              blockUI.stop();
              $rootScope.$emit(TFY_EVENTS.USER.UPDATE_DETAILS, response.data);
              showSuccessAlert($filter('translate')('organizations.owner.button.update.messages.success', { 'name': $ctrl.account.full_name }));
              $state.go('settings.org.usersRegular');
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name withdrawAccount
           * @description withdraw user account
           */
          function withdrawAccount() {
            blockUI.start();
            UsersService.withdrawInvite($ctrl.account.id).then(function () {
              store.clearUserStore();
              blockUI.stop();
              $state.go('settings.org.usersRegular', {
                'success': 'userProfile.messages.withdraw_success'
              });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @private
           * @description Reset form
           * @returns {Void}
          */
          function resetForm() {
            if (defaultAvatar($ctrl.defualValue.profile_pic)) {
              $ctrl.userProfile = angular.noop();
            } else {
              $ctrl.userProfile = $ctrl.account.profile_pic;
            }
            $ctrl.account = angular.copy($ctrl.defualValue);
            $ctrl.selectedOption = _.find($ctrl.userRoles, function (value) {
                return value.type === $ctrl.account.role;
            });
            $ctrl.userForm.$setPristine();
            $ctrl.userForm.$setUntouched();
          }

          /**
           * @ngdoc method
           * @name updateUserPicture
           * @private
           * @description Upload user profile
           * @returns {Void}
          */
          function updateUserPicture() {
            Upload.upload({
              url: CONFIG.API_HOST + '/organizations/'+ $ctrl.userOrganization.id + '/users/' + $stateParams.id + '/profile-pic',
              data: {
                profile_pic: $ctrl.userProfile
              }
            }).then(function (response) {
              blockUI.stop();
              $ctrl.account.profile_pic = _.get(response, 'data.data.profile_pic') || $ctrl.defualValue.profile_pic;
            }, function() {
              blockUI.stop();
            });
          }
          
          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }
          
          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @return {string} first character of name
           */
          function defaultAvatarText() {
            return TasksService.setDefaultAvatarText(firstName, lastName);
          }

          /**
           * @ngdoc method
           * @name showSuccessAlert
           * @param {*} message
           * @description To display success universal alert
           */
          function showSuccessAlert(message) {
            growl.success(message, {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }
          
          /**
           * @ngdoc method
           * @name setUserActivity
           * @description Set user activity
           * @returns {Void}
           */
          function setUserActivity() {
            $ctrl.account.activities = [];
            var disableByUser = _.find($ctrl.users, { id: $ctrl.account.disabled_by }), reactivatedByUser = _.find($ctrl.users, { 'id': $ctrl.account.reactivated_by });
            $ctrl.account.invited_by ? $ctrl.account.activities.push({ date: $ctrl.account.approved_at, act:  $filter('translate')('userProfile.activity.message', { actId: 1, showActUser: 1, isDateExist: $ctrl.account.approved_at ? 1 : 0, userFullName: _.find($ctrl.users, { id: $ctrl.account.invited_by }).full_name, userEmail:  _.find($ctrl.users, { id: $ctrl.account.invited_by }).email }, 'messageformat') }) : angular.noop();
            $ctrl.account.activated_at ? $ctrl.account.activities.push({ date: $ctrl.account.activated_at, act:  $filter('translate')('userProfile.activity.message', { actId: 2, showActUser: 0 }, 'messageformat')}) : angular.noop();
            $ctrl.account.disabled_by && disableByUser ? $ctrl.account.activities.push({ date: $ctrl.account.disabled_at, act:  $filter('translate')('userProfile.activity.message', { actId: 3, showActUser: 1, isDateExist: $ctrl.account.disabled_at ? 1 : 0, userFullName: disableByUser.full_name, userEmail:  disableByUser.email }, 'messageformat') }) : angular.noop();
            $ctrl.account.reactivated_by && reactivatedByUser ? $ctrl.account.activities.push({ date: $ctrl.account.reactivated_at, act:  $filter('translate')('userProfile.activity.message', { actId: 4, showActUser: 1, isDateExist: $ctrl.account.reactivated_at ? 1 : 0, userFullName: reactivatedByUser.full_name, userEmail: reactivatedByUser.email }, 'messageformat') }) : angular.noop();
          }
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.autogrow
 * @restrict 'A'
 * @element ANY 
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 **/

(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("autogrow", AutoGrow);

  function AutoGrow(DOM) {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, element, attributes) {
        var deRegisterNgModelWatcher;

        // prevent newlines
        element.on("keydown", function (evt) {
          if (evt.which === 13) {
            evt.preventDefault();
          }
        });

        // fit height to content
        element.on("input", function () {
          if (this.value.indexOf("\n")) {
            this.value = this.value.replace(/\n+/gi, "");
          }

          element.css({
            height: 0,
            minHeight: 0
          });

          var paddingTop = parseInt(window.getComputedStyle(element[0]).getPropertyValue('padding-top'));
          var paddingBottom = parseInt(window.getComputedStyle(element[0]).getPropertyValue('padding-bottom'));
          var contentHeight = (this.scrollHeight < DOM.AUTO_GROW.max_height) ? this.scrollHeight : DOM.AUTO_GROW.max_height;

          element.css({
            minHeight: "", // remove inline style reverting to default settings
            height: contentHeight + this.offsetHeight - paddingTop - paddingBottom + "px" // add border because we're using border-box
          });
        });

        // watch model changes from the outside to adjust height
        deRegisterNgModelWatcher = scope.$watch(attributes.ngModel, trigger);

        // set initial size
        trigger();

        function trigger() {
          setTimeout(element.triggerHandler.bind(element, "input"), 10);
        }

        scope.$on('$destroy', function () {
          deRegisterNgModelWatcher();
          element.unbind('keydown');
        });
      }
    };
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerTools
 * @module tallyfy.steps
 *
 * @description
 * A component to manage bunch of steps, assigner and deadline
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('powerTools', {
      templateUrl: 'app/modules/steps/powerTools/powerTools.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
         /*@ngInject*/
        function (_, ProcessService, $scope, MODAL_CLOSE_EVENTS, CompactTaskService, TASKORDER, $rootScope) {
          var $ctrl = this,
            body = angular.element('body');

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.cancel = cancelModal;
          $ctrl.onTabChanges = onTabChanges;
          
          /**
           * public variables
           */
          $ctrl.builderForm = {};
          $ctrl.userAssignment = false;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.powerToolsOptions = ProcessService.getPowerToolsOptions();
            if($ctrl.resolve.process.type === 'task' || $ctrl.resolve.process.type === 'launchBlueprint') {
              $ctrl.loadTask = true;
              body.addClass('tracker-powertools');
              _.set(_.find($ctrl.powerToolsOptions, { value: 'build_steps'}), "isEnable", false);
              checkJobsOptions();
            } else {
              body.addClass('bp-powertools');
            }
            if($ctrl.resolve.process.type === 'task') {
              getAllTasks($ctrl.resolve.process.id);
              $ctrl.loadTask = true;
            }
            if ($ctrl.resolve.process.type === 'blueprint' && $ctrl.isAdminMember) {
              _.set(_.find($ctrl.powerToolsOptions, { value: 'favorites'}), "isEnable", true);
            }
            $ctrl.activeTab = _.head(_.filter($ctrl.powerToolsOptions, { 'isEnable': true }));
          }
          
          function getAllTasks(runId) {
            var params = {
              action: "runs",
              action_id: runId,
              per_page: 999,
              page: 1,
              with: 'run,roles',
              sort: TASKORDER.POSITION
            };
            var resource = CompactTaskService.getUserTasks(params);
            resource.then(function (response) {
              $ctrl.resolve.process.steps = _.filter(response.data, function(t) { return !t.completed_at; });
              $ctrl.resolve.stepsLength = $ctrl.resolve.process.steps.length; 
              $ctrl.loadTask = false;
              checkJobsOptions();
            });
          }
          
          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { 
            onModalClosePreventHandler();
          }
          
          /**
           * @ngdoc method
           * @name onTabChanged
           * @description Execute when change tab to check if it has something need to be saved.
           * @param {object} option 
           * @param {object} isMobile
           * @param {event} e
           */
          function onTabChanges(e, option, isMobile) {
            if (!isMobile) {
              e.preventDefault();
              e.stopPropagation();
            }
            var tab = _.find($ctrl.powerToolsOptions, { value: option.value });
            $ctrl.activeTab = tab;
            $ctrl.activeTab.class = 'active';
          }

          /**
           * @function
           * @name cancelModal
           * @param {object} value
           * @param {String} type
           * @param {Date} startDate
           * @description Callback function for cancel the modal
           */
          function cancelModal(value, type, startDate) {
            if (!_.isEmpty(value) && !_.isEmpty(type)) {
              var data = { steps: value, type: type };
              if($ctrl.resolve.process.type === 'launchBlueprint') {
                angular.extend(data, {
                  startDate: startDate
                });
              }
              body.removeClass('tracker-powertools');
              body.removeClass('bp-powertools');
              $ctrl.close({ $value: data });
            } else {
              body.removeClass('tracker-powertools');
              body.removeClass('bp-powertools');
              $ctrl.dismiss({ $value: 'cancel'});
            }
          }

          function checkJobsOptions() {
            $ctrl.jobs = _.uniqBy(_.reduce($ctrl.resolve.process.steps, function (acc, value) {
              return _.concat(acc, _.get(value, 'roles.data', []));
            }, []), 'org_role_id');
            if(!$ctrl.jobs.length) {
              _.set(_.find($ctrl.powerToolsOptions, { value: 'roles'}), "isEnable", false);
            } else {
              _.set(_.find($ctrl.powerToolsOptions, { value: 'roles'}), "isEnable", true);
            }
            $ctrl.loadTask = false;
          }

          /**
           * Modal close prevent handler
           */
          var onModalClosePreventHandler = $scope.$on('modal.closing', function(event, reason) {
            if (($ctrl.userAssignment || $ctrl.builderForm.$dirty) && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
          //controller ends
        }
    });
})();
/**
 * @ngdoc Service
 * @name tallyfy.messages.MessagesService
 *
 * @module tallyfy.messages
 *
 * @description
 * MessagesService
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.messages')
    .service('MessagesService', MessagesService);

  /*@ngInject*/
  function MessagesService($filter, MessagesRepository, _, MESSAGE_TYPE) {
    var self = this;

    angular.extend(self, MessagesRepository);

    self.COMMENT_STATUS = {
      PROBLEM: 'problem',
      RESCIND: 'rescind',
      RESOLVED: 'resolved'
    };

    self.MESSAGE_TYPE = {
      REPORT: 'report',
      RESOLVE: 'resolve',
      IMPROVEMENT: 'improvement',
      ADVICE: 'advice'
    };

    self.getCommentFilters = function () {
      return [{
        value: 'auto_generated',
        label: $filter('translate')('compact.task.comment.autoGenerate'),
        selected: true
      }, {
        value: 'general_comment',
        label: $filter('translate')('compact.task.comment.generalComment'),
        selected: true
      }, {
        value: 'guest_facing',
        label: $filter('translate')('compact.task.comment.guestFacing'),
        selected: true
      }, {
        value: 'collapsed',
        label: $filter('translate')('compact.task.comment.collapsed'),
        selected: true
      }, {
        value: 'issue_and_resolution',
        label: $filter('translate')('compact.task.comment.issueAndResolution'),
        selected: true
      }, {
        value: 'improvement',
        label: $filter('translate')('compact.task.comment.improvements'),
        selected: true
      }, {
        value: 'i_am_mentioned',
        label: $filter('translate')('compact.task.comment.iAmMentioned'),
        selected: true
      }]
    }

    self.COMMENT_TYPES = function (isHasIssue, isGuest, isNone, isImproveAdvice, isReportResolve) {
      return [
        { label: 'comment', title: 'template.title.none', tooltip: '', value: MESSAGE_TYPE.SEND_COMMENT, isDisable: isNone },
        { label: 'report', title: 'template.title.blocker', tooltip: 'template.tooltip.blocker', value: MESSAGE_TYPE.REPORT, isDisable: isHasIssue, isSelectType: isReportResolve },
        { label: 'resolve', title: 'template.title.resolve', tooltip: 'template.tooltip.resolve', value: MESSAGE_TYPE.RESOLVE, isDisable: !isHasIssue, isSelectType: isReportResolve },
        { label: 'improvement', title: 'template.title.improvement', tooltip: 'template.tooltip.improvement', value: MESSAGE_TYPE.SEND_COMMENT, isSelectType: !isImproveAdvice },
        { label: 'advice', title: 'template.title.advice', tooltip: '', value: MESSAGE_TYPE.SEND_COMMENT, isDisable: isGuest, isSelectType: !isImproveAdvice }
      ];
    };

    self.COMMENT_VISIBILITY = function () {
      return [
        { label: 'none', title: 'messages.visibleToEveryone', value: MESSAGE_TYPE.OPEN },
        { label: 'hideFromGuest', title: 'messages.hideFromGuest', value: MESSAGE_TYPE.HIDE_FOR_GUESTS }
      ];
    };

    /**
     * [isSender]
     * @param {object} sent_by
     * @param {integer} current_id
     * @return {boolean}
     */
    self.isSender = function (sent_by, current_id) {
      return sent_by === current_id;
    };

    /**
     * @function getMentionData
     * @description prepare mention users
     * @param {object} threads
     * @param {object} currentUser
     * @param {object} users
     * @param {object} currentTask
     * @param {Array} collaborators
     * @return Object
     */
    self.getMentionData = function (threads, currentUser, users, currentTask, collaborators) {
      var taskAssignees = _.concat(_.get(currentTask, 'owners.groups', []), _.get(currentTask, 'owners.guests', []), _.get(currentTask, 'owners.users', [])),
        headAuthors = [],
        otherAuthors = [],
        lastCommentAuthor,
        processAuthors = [];

      var assignees = [];
      for (var i = 0; i < taskAssignees.length; i++) {
        var assigneeData = _.find(users, { id: taskAssignees[i] });
        if (assigneeData) {
          assignees.push(assigneeData);
        }
      }
      if (_.get(threads, 'length')) {
        lastCommentAuthor = _.findLast(threads, function (threadUser) {
          return threadUser.author_id !== _.get(currentUser, 'id');
        });
        if (lastCommentAuthor) {
          headAuthors.push(lastCommentAuthor.author);
        }
      }

      _.forEach(users, function (user) {
        if (user && (user.id !== currentUser.id)) {
          if (_.includes(_.get(currentTask, 'owners.users', []), user.id)) {
            headAuthors.push(user);
          } else if (_.includes(collaborators, user.id)) {
            processAuthors.push(user);
          } else {
            otherAuthors.push(user);
          }
        }
      });

      if (headAuthors.length > 0) {
        headAuthors = angular.copy(_.uniq(headAuthors));
      }

      return _.uniqBy(_.concat(assignees, headAuthors, processAuthors, otherAuthors), 'id');
    };
  }
})();

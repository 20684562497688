(function () {
  'use strict';
  angular.module('tallyfy')
    .component('runFilter', {
      templateUrl: 'app/modules/runs/dashboard/run-filter/run-filter.component.html',
      bindings: {
        filters: '<',
        config: '=',
        foldersList: '<',
        allTags: '<',
        isGuest: '<',
        filterChanged: '&'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $state, $stateParams, $aside, $filter, $confirm, $translate, PLANS, Growl, AuthPlan, Helper, FiltersService, AccountService, FolderService, RunsDashboardService, FilterModalService, $uibModal) {
          var $ctrl = this,
            growl = new Growl(),
            customFiltersParams = { page: 1, per_page: 999 },
            body = angular.element('body'),
            emptyFilterObj = {
              name: '',
              criteria: [],
              type: { id: 'all', label: 'All' },
              customizable: true
            };

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.showFilterOptions = showFilterOptions;
          $ctrl.viewTypeChanged = viewTypeChanged;
          $ctrl.sortChanged = sortChanged;
          $ctrl.filterItemClicked = filterItemClicked;
          $ctrl.filterRemoved = filterRemoved;
          $ctrl.clearFilters = clearFilters;
          $ctrl.savePreset = savePreset;
          $ctrl.presetChanged = presetChanged;
          $ctrl.openFilterModal = openFilterModal;

          $ctrl.isDefaultFilter = true;

          function onInit() {
            $ctrl.viewTypeOptions = RunsDashboardService.getViewTypeOptions();
            $ctrl.sortOptions = RunsDashboardService.getSortFilterConfig().sortOptions;
            initConfig();
          }

          function onChanges() { }

          function onDestroy() {
            body.removeClass('run-filter-option-modal-active');
          }

          function initConfig() {
            if (!$ctrl.isGuest) {
              AccountService.getRunView(customFiltersParams).then(
                function (response) {
                  $ctrl.customFilters = FiltersService.setFilterRecord(response.data);
                  initSort();
                  initFilter();
                  initFolder();
                  initTags();
                  initViewType();
                  initType();
                  $ctrl.isDefaultFilter = checkIsDefaultFilter();
                  $ctrl.filterChanged({ firstChange: true });
                }
              );
            } else {
              $ctrl.customFilters = [];
              initSort();
              initFilter();
              initFolder();
              initTags();
              initType();
              initViewType();
              $ctrl.isDefaultFilter = checkIsDefaultFilter();
              $ctrl.filterChanged({ firstChange: true });
            }
          }

          function viewTypeChanged() {
            $ctrl.isDefaultFilter = checkIsDefaultFilter();
            $ctrl.filterChanged({
              firstChange: false, filterObjectChanged: {
                preference: {
                  slug: $ctrl.filters.filterViewTypeSlug,
                  value: $ctrl.config.paramObjects.viewType.value === 'grid' ? 'yes' : 'no'
                }
              }
            });
          }

          function sortChanged() {
            $ctrl.isDefaultFilter = checkIsDefaultFilter();
            $ctrl.filterChanged({
              firstChange: false, filterObjectChanged: {
                preference: {
                  slug: $ctrl.filters.filterSortSlug, value: "yes", metadata: { active_view: $ctrl.config.paramObjects.sortObj.value }
                }
              }
            });
          }

          function filterItemClicked(e) {
            body.addClass('run-filter-option-modal-active');
            $ctrl.showFilterOptions(e);
          }

          function initFolder() {
            if ($state.params.folder) {
              $ctrl.config.paramObjects.folderObj = FolderService.validateAndReturnFolderObject($state.params.folder, $ctrl.foldersList);
            } else {
              var activeFolderId = FiltersService.getActiveFilterValue(_.get($rootScope.identity, 'preferences'), $ctrl.filters.filterFolderSlug, '') || {};
              $ctrl.config.paramObjects.folderObj = FolderService.validateAndReturnFolderObject(activeFolderId, $ctrl.foldersList);
            }
          }

          function clearFilters() {
            $ctrl.filterRemoved('filterObj', void 0, true);
            $ctrl.filterRemoved('folderObj', void 0, true);
            $ctrl.filterRemoved('tagsObj', void 0, true);
            $ctrl.filterRemoved('typeObj', void 0, true);
            $ctrl.filterRemoved('sortObj');
          }

          function initTags() {
            if ($state.params.tags) {
              $ctrl.config.paramObjects.tagsObj = validateAndReturnTagsValue($state.params.tags);
            } else {
              var tagsStrFromPreferences = FiltersService.getActiveFilterValue(_.get($rootScope.identity, 'preferences'), $ctrl.filters.filterTagsSlug, '');
              $ctrl.config.paramObjects.tagsObj = validateAndReturnTagsValue(tagsStrFromPreferences);
            }
          }

          function validateAndReturnTagsValue(tagsString) {
            var tagsStringArr = _.split(tagsString, ','), validTags = [];
            _.forEach(tagsStringArr, function (tag) {
              var foundTag = _.find($ctrl.allTags, { 'id': tag });
              if (foundTag) {
                validTags.push(foundTag);
              }
            });
            return validTags;
          }

          function initFilter() {
            var filter = _.find($rootScope.identity.preferences, { slug: 'tracker_filter_config' });
            if (filter && _.get(filter.metadata, 'active_view') !== 'all') {
              $ctrl.config.paramObjects.filterObj = _.isObject(filter.metadata.active_view) ? filter.metadata.active_view : $ctrl.filters.defaultFilters[0];
            } else if ($state.params.filter) {
              $ctrl.config.paramObjects.filterObj = _.find($ctrl.filters.defaultFilters, { id: $state.params.filter }) || $ctrl.filters.defaultFilters[0];
            } else {
              var filterId = FiltersService.getActiveFilterValue(_.get($rootScope.identity, 'preferences'), $ctrl.filters.filterConfigSlug, $ctrl.filters.defaultFilters[0].id);
              $ctrl.config.paramObjects.filterObj = _.find(_.concat($ctrl.customFilters, $ctrl.filters.defaultFilters), { id: filterId }) || $ctrl.filters.defaultFilters[0];
            }
          }

          function initSort() {
            if ($state.params.sortBy) {
              $ctrl.config.paramObjects.sortObj = validateAndReturnSortValue($state.params.sortBy);
            } else {
              if ($ctrl.isGuest) {
                $ctrl.config.paramObjects.sortObj = _.head($ctrl.sortOptions);
              } else {
                var defaultSort = _.head($ctrl.sortOptions), setActiveSort = FiltersService.getActiveFilterValue(_.get($rootScope.identity, 'preferences'), $ctrl.filters.filterSortSlug, defaultSort) || {};
                $ctrl.config.paramObjects.sortObj = validateAndReturnSortValue(setActiveSort);
              }
            }
          }

          function validateAndReturnSortValue(sortString) {
            var defaultSort = _.head($ctrl.sortOptions), index = _.findIndex($ctrl.sortOptions, { 'value': sortString });
            return (index >= 0) ? $ctrl.sortOptions[index] : defaultSort;
          }

          function initViewType() {
            if ($ctrl.isGuest) {
              $ctrl.config.paramObjects.viewType = $ctrl.viewTypeOptions[0];
            } else {
              if (_.get($stateParams, 'checklist')) {
                $ctrl.config.paramObjects.viewType = $ctrl.viewTypeOptions[1];
              } else {
                var viewTypePreferenceValue = AccountService.getPreference(_.get($rootScope.identity, 'preferences'), $ctrl.filters.filterViewTypeSlug, 'yes');
                $ctrl.config.paramObjects.viewType = (viewTypePreferenceValue === 'no') ? $ctrl.viewTypeOptions[1] : $ctrl.viewTypeOptions[0];
              }
            }
          }

          function showFilterOptions(e) {
            if ($ctrl.isGuest) {
              return;
            }
            $aside.open({
              component: 'runFilterOption',
              placement: 'right',
              windowClass: 'run-filter-option-component',
              backdrop: 'backdrop',
              animation: false,
              resolve: {
                views: function () {
                  return $ctrl.filters.defaultFilters;
                },
                type: function () {
                  return $ctrl.filters.typeFilter;
                },
                allTags: function () {
                  return $ctrl.allTags;
                },
                foldersList: function () {
                  return $ctrl.foldersList;
                },
                filters: function () {
                  return $ctrl.filters;
                },
                config: function () {
                  return $ctrl.config;
                },
                isGuest: function () {
                  return $ctrl.isGuest;
                },
                filterChangedCallback: function () {
                  return function (objectChanged) {
                    $ctrl.isDefaultFilter = checkIsDefaultFilter();
                    return $ctrl.filterChanged({ firstChange: false, filterObjectChanged: objectChanged });
                  };
                }
              }
            }).closed.then(function () {
              body.removeClass('run-filter-option-modal-active');
            });
          }

          function checkIsDefaultFilter() {
            var params = $ctrl.config.paramObjects;
            return params.filterObj.id === 'all' && params.sortObj.value === '-created_at' && Helper.isObjectEmpty(params.tagsObj) && Helper.isObjectEmpty(params.folderObj) && Helper.isObjectEmpty(params.typeObj);
          }

          function filterRemoved(key, value, savePreferenceOnly) {
            var preference;
            if (value) {
              $ctrl.config.paramObjects[key] = value;
            } else {
              delete $ctrl.config.paramObjects[key];
              delete $stateParams[key === 'folderObj' ? 'folder' : 'filter'];
            }
            if (key == 'filterObj') {
              $ctrl.config.paramObjects.filterObj = $ctrl.filters.defaultFilters[0];
              preference = {
                slug: $ctrl.filters.filterConfigSlug,
                value: "yes",
                metadata: { active_view: $ctrl.config.paramObjects.filterObj.id }
              };
            } else if (key == 'sortObj') {
              $ctrl.config.paramObjects.sortObj = _.head($ctrl.sortOptions);
              preference = {
                slug: $ctrl.filters.filterSortSlug,
                value: "yes",
                metadata: { active_view: $ctrl.config.paramObjects.sortObj.value }
              };
            } else if (key == 'viewType') {
              $ctrl.config.paramObjects.viewType = $ctrl.viewTypeOptions[0];
              preference = {
                slug: $ctrl.filters.filterViewTypeSlug,
                value: $ctrl.config.paramObjects.viewType.value === 'grid' ? 'yes' : 'no'
              };
            } else if (key == 'tagsObj') {
              preference = {
                slug: $ctrl.filters.filterTagsSlug,
                value: "yes",
                metadata: {
                  active_view: _.map($ctrl.config.paramObjects.tagsObj,
                    function (tag) {
                      return tag.id;
                    }).join(',')
                }
              };
            } else if (key == 'folderObj') {
              var record = _.find($ctrl.config.paramObjects.filterObj.criteria, { field: 'folder' });
              if (record) {
                _.set($ctrl.config, 'paramObjects.folderObj.id', record.value);
                $state.transitionTo($state.current, angular.extend($stateParams, { folder: record.value }), { notify: false });
              } else {
                $ctrl.config.paramObjects.folderObj = {};
              }
              preference = {
                slug: $ctrl.filters.filterFolderSlug,
                value: "yes",
                metadata: { active_view: void 0 }
              };
            } else if (key == 'typeObj') {
              preference = {
                slug: $ctrl.filters.filterTypeSlug,
                value: "yes",
                metadata: { active_view: void 0 }
              };
            }
            $ctrl.isDefaultFilter = checkIsDefaultFilter();
            $ctrl.filterChanged({ firstChange: false, filterObjectChanged: { preference: preference }, savePreferenceOnly: savePreferenceOnly });
          }

          function initType() {
            var filter = _.find($rootScope.identity.preferences, { slug: 'runs_tracker_filter_type' });
            if (filter) {
              $ctrl.config.paramObjects.typeObj = filter.metadata.active_view;
            }
          }

          function savePreset() {
            $uibModal.open({
              component: 'presetSaveModal',
              windowClass: 'save-preset-modal',
              backdrop: 'static',
              resolve: {
                config: function () {
                  return $ctrl.config;
                }
              }
            }).result.then(function (res) {
              var filter = {
                name: res.name,
                criteria: [],
                type: { id: 'all', label: 'All' },
                customizable: true,
                filter_type: "all"
              }, paramObject = _.get($ctrl.config, 'paramObjects', {});
              if (!Helper.isObjectEmpty(paramObject.tagsObj) && paramObject.tagsObj.length) {
                if (_.get(paramObject.tagsObj, 'criteria', []).length) {
                  filter.criteria = paramObject.tagsObj.criteria;
                } else {
                  for (var i = 0; i < paramObject.tagsObj.length; i++) {
                    filter.criteria.push({
                      field: 'tag',
                      label: paramObject.tagsObj[i].title,
                      value: paramObject.tagsObj[i].id
                    });
                  }
                }
              }
              if (!Helper.isObjectEmpty(paramObject.typeObj)) {
                if (_.get(paramObject.typeObj, 'criteria', []).length) {
                  filter.criteria = paramObject.typeObj.criteria;
                } else {
                  filter.criteria.push({
                    field: 'type',
                    label: paramObject.typeObj.name,
                    value: paramObject.typeObj.id
                  });
                }
              }
              if (!Helper.isObjectEmpty(paramObject.folderObj)) {
                if (_.get(paramObject.folderObj, 'criteria', []).length) {
                  filter.criteria = paramObject.folderObj.criteria;
                } else {
                  filter.criteria.push({
                    field: 'folder',
                    label: paramObject.folderObj.name,
                    value: paramObject.folderObj.id
                  });
                }
              }
              if (!Helper.isObjectEmpty(paramObject.filterObj)) {
                if (_.get(paramObject.filterObj, 'criteria', []).length) {
                  for (var i = 0; i < paramObject.filterObj.criteria.length; i++) {
                    if (paramObject.filterObj.criteria[i].field === 'untagged') {
                      filter.criteria.push({ field: 'status', label: '', value: 'untagged' });
                    } else {
                      filter.criteria.push(paramObject.filterObj.criteria[i]);
                    }
                  }
                } else {
                  if (paramObject.filterObj.id !== 'all') {
                    filter.criteria.push({
                      field: 'status',
                      label: paramObject.filterObj.name,
                      value: paramObject.filterObj.id
                    });
                  }
                }
              }
              if (!Helper.isObjectEmpty(paramObject.sortObj)) {
                if (_.get(paramObject.sortObj, 'criteria', []).length) {
                  filter.criteria = paramObject.sortObj.criteria;
                } else {
                  if (paramObject.sortObj.value !== '-created_at') {
                    filter.criteria.push({
                      field: 'sort',
                      label: paramObject.sortObj.name,
                      value: paramObject.sortObj.value
                    });
                  }
                }
              }
              AccountService.setRunView(filter)
                .then(function (data) {
                  var filterObj = formatAndReturnCustomFilterObj(data.data);
                  $ctrl.customFilters.push(filterObj);
                  Helper.showChangesSavedGrowl();
                  onFilterChange(filterObj);
                  AccountService.refreshCustomRunsFiltersStore(customFiltersParams);
                });
            });
          }

          function onFilterChange(filter) {
            $ctrl.config.paramObjects.filterObj = filter;
            $ctrl.isDefaultFilter = checkIsDefaultFilter();
            $ctrl.filterChanged({
              firstChange: false,
              filterObjectChanged: {
                preference: {
                  slug: $ctrl.filters.filterConfigSlug, value: "yes", metadata: { active_view: filter }
                }
              }
            });
          }

          function formatAndReturnCustomFilterObj(rawFilterObj) {
            rawFilterObj.customizable = true;
            rawFilterObj.type = {
              id: rawFilterObj.filter_type,
              label: $filter('capitalize')(rawFilterObj.filter_type)
            };
            return rawFilterObj;
          }

          function presetChanged() {
            var filter = $ctrl.config.paramObjects.filterObj, group = _.find(filter.criteria, { label: 'groups' });
            if (group) {
              _.forEach(filter.criteria, function (criteria) {
                if (criteria.label === 'groups') {
                  angular.extend(criteria, {
                    field: 'groups',
                    label: 'group',
                    value: group.value
                  });
                }
              });
            }
            $ctrl.config.paramObjects.filterObj = filter;
            $ctrl.isDefaultFilter = checkIsDefaultFilter();
            $ctrl.filterChanged({
              firstChange: false, filterObjectChanged: {
                preference: {
                  slug: $ctrl.filters.filterConfigSlug, value: "yes", metadata: { active_view: filter }
                }
              }
            });
          }

          function showMessage(message) {
            $translate(message).then(function (translation) {
              growl.success(translation, { referenceId: 'filterController', disableIcons: true, disableCloseButton: true });
            });
          }

          function deleteCustomFilter(filter) {
            $confirm({
              'header': $filter('translate')('global.confirmAction.header', { actionName: 'Delete', componentName: 'Custom View' }),
              'body': $filter('translate')('global.confirmAction.body', { actionName: 'delete', componentName: filter.name }),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Delete' }),
                'cancel': 'global.confirmAction.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              AccountService.deleteRunView({ id: filter.id })
                .then(function () {
                  showMessage($ctrl.filters.deleteMessage);
                  var index = _.findIndex($ctrl.customFilters, { 'id': filter.id });
                  if (index >= 0) {
                    $ctrl.customFilters.splice(index, 1);
                  }
                  if (_.get(filter, 'id') === _.get($ctrl.config, 'paramObjects.filterObj.id')) {
                    onFilterChange(_.head($ctrl.filters.defaultFilters));
                  }
                  AccountService.refreshCustomRunsFiltersStore(customFiltersParams);
                });
            }, function () {
              if (filter.isdelete) {
                filter.isdelete = null;
                openFilterModal(null, filter);
              }
            });
          }

          function saveOrUpdateCustomFilter(newFilterObj, oldFilterObj) {
            newFilterObj.filter.filter_type = newFilterObj.filter.type.id;
            if (oldFilterObj) {
              AccountService.updateRunView({ id: newFilterObj.filter.id }, newFilterObj.filter)
                .then(function (res) {
                  var data = angular.copy(res.data),
                    filter = data,
                    group = _.find(filter.criteria, { label: 'groups' });
                  if (group) {
                    _.forEach(filter.criteria, function (criteria) {
                      if (criteria.label === 'groups') {
                        angular.extend(criteria, {
                          field: 'groups',
                          label: 'group',
                          value: group.value
                        });
                      }
                    });
                  }
                  showMessage($ctrl.filters.successMessage);
                  var filterObj = formatAndReturnCustomFilterObj(data), index = _.findIndex($ctrl.customFilters, { 'id': filterObj.id });
                  if (index >= 0) {
                    $ctrl.customFilters[index] = filterObj;
                  }
                  onFilterChange(angular.copy(filterObj));
                  AccountService.refreshCustomRunsFiltersStore(customFiltersParams);
                });
            } else {
              AccountService.setRunView(newFilterObj.filter)
                .then(function (data) {
                  var filterObj = formatAndReturnCustomFilterObj(data.data);
                  $ctrl.customFilters.push(filterObj);
                  showMessage($ctrl.filters.successMessage);
                  onFilterChange(filterObj);
                  AccountService.refreshCustomRunsFiltersStore(customFiltersParams);
                });
            }
          }

          function openFilterModal(e, filterObj) {
            if (e) {
              e.stopImmediatePropagation();
            }
            if (filterObj) {
              var fieldGroups, group;
              fieldGroups = _.find(filterObj.criteria, { field: 'groups' });
              group = _.find(filterObj.criteria, { label: fieldGroups ? 'group' : 'groups' });
              _.forEach(filterObj.criteria, function (criteria) {
                if (criteria.label === 'groups' || criteria.label === 'group') {
                  _.remove(filterObj.criteria, { label: group.label });
                  filterObj.criteria.push({
                    task: group.task,
                    field: 'owners',
                    label: 'groups',
                    value: group.value
                  });
                }
              });
            }
            if (!AuthPlan.hasAnyAuthority(PLANS.FREE, 'addFilter'))
              return;

            FilterModalService.openFilterModal(
              filterObj ? filterObj : emptyFilterObj,
              $ctrl.filters.filterFor,
              $ctrl.filters.filterTypes
            ).result.then(function (response) {
              if (response.isdelete) {
                deleteCustomFilter(response);
              } else {
                saveOrUpdateCustomFilter(response, filterObj);
              }
            });
          }
        }
    });
})();

/**
 * @ngdoc Component
 * @name tallyfy.component.dashboard
 * @module tallyfy.dashboard
 *
 * @description A component to manage org dashboard
 *
 * @author Kiranv Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('dashboard', {
      templateUrl: 'app/modules/dashboard/dashboard.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, AccountService, preferencesStore, $log, PLANS, AuthPlan, ProcessService, Helper, $state, $uibModal, store, UsersService, $stateParams, QuickActionsService) {
          var $ctrl = this, updateOrgDashboardEventHandler;

          $ctrl.unreadNotifications = 0;
          $ctrl.taskCount = 0;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods these methods are accessible in view
           */
          $ctrl.bluePrintTypes = ProcessService.getBlueprintTypes();
          $ctrl.goToTargetedState = goToTargetedState;
          $ctrl.openFAQVideoModal = openFAQVideoModal;
          $ctrl.openProcessExampleModel = openProcessExampleModel;
          $ctrl.onBackgroundSelected = onBackgroundSelected;
          $ctrl.onTaskLength = onTaskLength;

          /**
           * @function
           * @name initialization
           * @description A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.orgId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.isDocPlan = [PLANS.DOCS, PLANS.DOCSMONTHLY, PLANS.DOCSANNUAL].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
            $ctrl.fullName = $rootScope.identity.full_name;
            
            // Handle quick action triggers here - for URL based triggers (i.e., a redirect from the state `quickActions`)
            if ($stateParams.triggerQuickActionSlug) {
              var acceptableQuickActionTypes = QuickActionsService.getAcceptableQuickActionTypes(), actionConfig = acceptableQuickActionTypes[$stateParams.triggerQuickActionSlug];
              if (actionConfig && actionConfig.isApplicableForCurrentAccount()) {
                actionConfig.actionHandler('Direct URL');
              } else {
                // Clear params
                $state.go('.', null, { inherit: false, notify: false, location: 'replace' });
              }
            }
            $ctrl.isUserInFreeTrial = AuthPlan.isUserInFreeTrial();
            store.getUsers().then(function (response) {
              $ctrl.orgUsersCount = UsersService.getBilledUsers(response).length;
            });
          }

          /**
           * @function
           * @name onChanges
           * @description A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateOrgDashboardEventHandler();
          }

          /**
           * @function
           * @name goToTargetedState
           * @param {String} to
           * @param {String} param
           * @returns {Void}
           */
          function goToTargetedState(to, param) {
            var param;
            if (!Helper.checkAccessAuthority())
              return;
            switch (to) {
              case 'process.create':
                param = { blueprintType: param };
                break;
              case 'process.templates':
                param = { org_id: $ctrl.orgId };
                break;
              case 'home':
                param = { org_id: $ctrl.orgId, isRequireToOpenOneOffTaskModal: true };
                break;
              case 'run.dashboard':
                param = { org_id: $ctrl.orgId };
                break;
              default:
                to = 'dashboard';
                break;
            }
            $state.go(to, param);
          }

          /**
           * @function
           * @name openProcessExampleModel
           * @param {String} keyType
           * @returns {Void}
           */
          function openProcessExampleModel(keyType) {
            $uibModal.open({
              windowClass: 'card-modals',
              component: 'bluePrintExampleModal',
              resolve: {
                keyType: function () {
                  return keyType;
                }
              }
            }).result.then(function () {
            })
          }

          /**
           * @function
           * @name openFAQVideoModal
           * @description Open video modal for FAQ
           * @returns {undefined}
           */
          function openFAQVideoModal() {
            $uibModal.open({
              templateUrl: 'app/modules/dashboard/learn-more.html',
              windowClass: 'faq-lightbox'
            });
          }

          function onBackgroundSelected(name) {
            $ctrl.backgroundClass = (name && (name !== 'NO_PHOTO')) ? 'dashboard-background-' + name : '';
          }

          // task count from task-list-preview
          function onTaskLength(taskCount) {
            $ctrl.taskCount = taskCount;
          }

          /**
           * event handler when the organization plan has been changed
           * @type {*|(function())}
           */
          updateOrgDashboardEventHandler = $rootScope.$on('ORGANIZATION:UPDATE_DASHBOARD', function () {
            $ctrl.isDocPlan = [PLANS.DOCS, PLANS.DOCSMONTHLY, PLANS.DOCSANNUAL].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
          });
        }
    });
})();

(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('User', User);

  User.$inject = ['$resource', 'CONFIG'];

  function User($resource, CONFIG) {
    var service = $resource(CONFIG.API_HOST + '/users/:login', {}, {
      'query': { method: 'GET', isArray: true },
      'get': {
        method: 'GET',
        transformResponse: function (data) {
          data = angular.fromJson(data);
          return data;
        }
      },
      'save': { method: 'POST' },
      'update': { method: 'PUT' },
      'delete': { method: 'DELETE' }
    });

    return service;
  }
})();

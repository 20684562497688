/**
 * @ngdoc Config
 * @name tallyfy.organizations.users.config.routeConfig
 * @module tallyfy.organizations.users
 *
 * @description
 * set route Organizations Module
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .config(routeConfig);
  /**
   * @inject 
   * @description
   * inject dependecies 
   */
  routeConfig.$inject = [];
  /**
   * Constructor
   * routeConfig construct 
   */
  function routeConfig() {
    //Routes goes here
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .service('ListDetailTableService', ListDetailTableService);

  function ListDetailTableService(_, Helper) {
    var self = this;

    self.getOptions = function (list, columns, updateCallback) {
      var fields = { guid: { editable: false, nullable: true } };
      for (var i = 0; i < columns.length; i++) {
        fields[columns[i].field] = { editable: true, nullable: true };
      }
      columns.push(
        {
          command: {
            name: 'removeRow',
            template: kendo.template($('#listDeleteRow').html())
          },
          width: 48
        }
      );
      return {
        columns: columns,
        dataSource: new kendo.data.DataSource({
          data: [],
          transport: {
            read: function (o) {
              o.success([]);
            },
            create: function (o) {
              o.data.guid = Helper.guid();
              o.success(o.data);
            },
            update: function (o) {
              o.success();
            },
            destroy: function (o) {
              o.success();
            }
          },
          schema: {
            model: {
              id: 'guid',
              fields: fields
            }
          },
          requestEnd: function (e) {
            updateCallback(e.sender.data(), e.type === "destroy");
          },
        }),
        excelExport: function (e) {
          e.workbook.fileName = (list.title || 'export') + ".xlsx";
        },
        pdf: {
          fileName: (list.title || 'export') + ".pdf"
        },
        sortable: false,
        resizable: true,
        editable: {
          confirmation: false,
          createAt: "bottom"
        },
        cellClose: function () {
          this.dataSource.sync();
        }
      };
    }

    self.getListAsTableData = function (list, options) {
      var rows = list.rows || [], columns = options.gridConfig.columns || [], listData = [];
      for (var i = 0; i < rows.length; i++) {
        var rowData = {};
        for (var j = 0; j < columns.length; j++) {
          if (columns[j].field) {
            rowData[columns[j].field] = rows[i][j];
          }
        }
        listData.push(rowData);
      }
      return listData;
    }

    self.getColumnHeaderTemplate = function (title) {
      return kendo.template('<div class="d-flex align-items-center list-column-header">'
        + '<span data-ng-click="$ctrl.options.templateScope.callbacks.editColumn(\''
        + title
        + '\')">'
        + title
        + '</span>'
        + '<div class="d-flex align-items-center ml-2">'
        + '<i class="far fa-times" data-ng-click="$ctrl.options.templateScope.callbacks.deleteColumn(\''
        + title
        + '\')"></i>'
        + '</div>'
        + '</div>')
    }
  }
})();
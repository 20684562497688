(function () {
  'use strict';
  angular.module('tallyfy.messages')
    .component('commentReaction', {
      bindings: {
        taskId: '<',
        commentId: '<',
        userId: '<',
        reactions: '<',
        isGuest: '<',
        users: '<',
        editDisabled: '<?'
      },
      templateUrl: 'app/modules/messages/comment-reactions/comment-reactions.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, CommentReactionService) {
          var $ctrl = this, body = angular.element('body');

          $ctrl.isOnSelectMode = false;
          $ctrl.reactionList = CommentReactionService.getReactionIconList();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onIconClick = onIconClick;
          $ctrl.onReactionListClick = onReactionListClick;
          $ctrl.addReactionToComment = addReactionToComment;

          $ctrl.reactionListModel = [];

          function onInit() {
            body.on('click', bodyClickHandler);
          }

          function onChanges(changes) {
            if (changes.reactions) {
              generateReactionsModel(changes.reactions.currentValue || []);
            }
          }

          function onDestroy() {
            body.off('click', bodyClickHandler);
          }

          function generateReactionsModel(reactions) {
            $ctrl.reactionListModel = [];
            for (var i = 0; i < reactions.length; i++) {
              var reactionOnList = _.find($ctrl.reactionListModel, function (react) {
                return react.icon.value === reactions[i].icon;
              });
              if (reactionOnList) {
                reactionOnList.total++;
                reactionOnList.tooltipText = reactionOnList.tooltipText + ', ' + (reactions[i].guest || getUserFullName(reactions[i].user_id));
              } else {
                $ctrl.reactionListModel.push({
                  icon: _.find($ctrl.reactionList, { value: reactions[i].alias }),
                  total: 1,
                  tooltipText: reactions[i].guest || getUserFullName(reactions[i].user_id)
                });
              }
            }
          }

          function getUserFullName(userId) {
            var user = _.find($ctrl.users, { id: userId });
            return _.get(user, 'full_name');
          }

          function onIconClick(e) {
            $timeout(function () {
              $ctrl.isOnSelectMode = !$ctrl.isOnSelectMode;
            }, 0);
          }

          function bodyClickHandler(e) {
            var checkSelectMode = angular.copy($ctrl.isOnSelectMode);
            if (checkSelectMode) {
              $ctrl.isOnSelectMode = false;
              $scope.$apply();
            }
          }

          function onReactionListClick(icon) {
            if ($ctrl.editDisabled) {
              return;
            }
            var myReaction = $ctrl.isGuest
              ? _.remove($ctrl.reactions, { guest: _.get($rootScope, 'identity.guest.email'), alias: icon.value })
              : _.remove($ctrl.reactions, { user_id: _.get($rootScope, 'identity.id'), alias: icon.value });
            myReaction.length ? removeReactionFromComment(myReaction) : addReactionToComment(icon);
          }

          function removeReactionFromComment(myReaction) {
            $ctrl.onSaving = true;
            CommentReactionService.removeReactionFromComment({
              commentId: $ctrl.commentId,
              taskId: $ctrl.taskId,
              reactionId: myReaction[0].id
            }).then(function (res) {
              generateReactionsModel($ctrl.reactions);
              $ctrl.onSaving = false;
            }, function (err) {
              $ctrl.onSaving = false;
            });
          }

          function addReactionToComment(icon) {
            $ctrl.onSaving = true;
            var args = {
              icon: icon.value,
              alias: icon.value
            };
            CommentReactionService.addReactionToComment({
              commentId: $ctrl.commentId,
              taskId: $ctrl.taskId
            }, args).then(function (res) {
              var isExist = _.find($ctrl.reactions, { id: _.get(res, 'data.id') });
              if (!isExist) {
                $ctrl.reactions.push(res.data);
              }
              generateReactionsModel($ctrl.reactions);
              $ctrl.onSaving = false;
            }, function (err) {
              $ctrl.onSaving = false;
            });
          }

        }
    });
})();
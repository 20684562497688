/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.addToFolderModal
 * @module tallyfy.tasks
 *
 * @description
 * A component to add / remove an object from a folder
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .component('addToFoldersModal', {
      templateUrl: 'app/modules/home/components/add-to-folders-modal/add-to-folders-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (Growl, blockUI, FolderService, _, $rootScope, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('addToFoldersModal'),
            growl = new Growl();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.onCreateFolder = onCreateFolder;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.onFolderSeletionToggle = onFolderSeletionToggle;
          $ctrl.onFolderRemoved = onFolderRemoved;

          /**
           * public properties
           */
          $ctrl.newObjectFolders = []; 

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.foldersList = angular.copy($ctrl.resolve.foldersList);
            if ($ctrl.resolve.objectFolders) {
              $ctrl.existingObjectFolders = angular.copy($ctrl.resolve.objectFolders);
            } else {
              // Defaults
              $ctrl.existingObjectFolders = [];
            }
            
            if ($ctrl.existingObjectFolders.length || $ctrl.foldersList.length) {
              setSelectedFolders($ctrl.foldersList);
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name setSelectedFolders
           * @param {*} folders
           * @description
           * Set Selected Folders
           */
          function setSelectedFolders(folders) {
            if (folders.length) {
              for (var i = 0; i < folders.length; i++) {
                var selectedFolder = _.find($ctrl.existingObjectFolders, { folder_id: folders[i].folder_id });
                folders[i].isSelected = !!selectedFolder;
                
                if (folders[i].children.data.length > 0) {
                  setSelectedFolders(folders[i].children.data);
                }
              }
            }
          }

          /**
           * @function
           * @name onCreateFolder
           * @param {*} folder
           * @description
           * Create new folder
           */
          function onCreateFolder(folder) {
            var createModal = FolderService.openFolderModal(null, _.get(folder, 'id', 'root'), 'run');
            createModal.result.then(function (response) {
              response.data.folder_name = response.data.name;
              response.data.folder_id = response.data.id;
              if (folder) {
                folder.children.data.push(response.data);
                folder.children.data = _.sortBy(folder.children.data, 'name');
              } else {
                $ctrl.foldersList.push(response.data);
                $ctrl.foldersList = _.sortBy($ctrl.foldersList, 'name');
              }
              $rootScope.$emit('TASK_ALL_FOLDERS_LIST:UPDATED', angular.copy($ctrl.foldersList));
            });
          }

          /**
           * @function
           * @name onFolderTreeToggle
           * @param {*} folder
           * @description
           * Toggle folder tree
           */
          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              collapseAllChildFolders(folder.children.data);
            }
          }

          /**
           * @function
           * @name collapseAllChildFolders
           * @param {*} folders
           * @description
           * Collapse folder tree
           */
          function collapseAllChildFolders(folders) {
            if (folders.length) {
              for (var i = 0; i < folders.length; i++) {
                folders[i].isExpanded = false;
                if (folders[i].children.data.length > 0) {
                  collapseAllChildFolders(folders[i].children.data);
                }
              }
            }
          }

          /**
           * @function
           * @name onFolderSeletionToggle
           * @param {*} folder
           * @description
           * Toggle folder selection
           */
          function onFolderSeletionToggle(folder) {
            if (folder.isSelected) {
              blockUI.start();
              FolderService.addObjectToFolder({ folder_id: folder.id, subject_id: $ctrl.resolve.subjectId, subject_type: $ctrl.resolve.subjectType })
                .then(function (response) {
                  var index = _.findIndex($ctrl.existingObjectFolders, { 'id': _.get(response, 'data.folder_id', '') });
                  if (index > -1) {
                    $ctrl.existingObjectFolders[index] = response.data;
                  } else {
                    $ctrl.existingObjectFolders.push(response.data);
                    $ctrl.existingObjectFolders = _.sortBy($ctrl.existingObjectFolders, 'folder_name');
                  }
                  growl.success($filter('translate')('runs.messages.foldersForRunsAndTasks.addedToFolder', { objectName: $ctrl.resolve.objectName, folderName: folder.folder_name }),
                    { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  blockUI.stop();
                }, function () {
                  folder.isSelected = false;
                  blockUI.stop();
                });
            } else {
              onFolderRemoved(folder);
            }
          }

          /**
           * @function
           * @name onFolderRemoved
           * @param {*} folder
           * @description
           * Remove folder
           */
          function onFolderRemoved(folder) {
            var index = _.findIndex($ctrl.existingObjectFolders, { 'folder_id': folder.folder_id });
            if (index > -1) {
              blockUI.start();
              FolderService.deleteObjectFromFolder({ id: $ctrl.existingObjectFolders[index].id })
                .then(function () {
                  $ctrl.existingObjectFolders.splice(index, 1);
                  setSelectedFolders($ctrl.foldersList);
                  growl.success($filter('translate')('runs.messages.foldersForRunsAndTasks.removedFromFolder', { objectName: $ctrl.resolve.objectName, folderName: folder.folder_name }),
                    { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  blockUI.stop();
                }, function () {
                  folder.isSelected = true;
                  blockUI.stop();
                });
            } else {
              setSelectedFolders($ctrl.foldersList);
            }
          }
        }
    });
})();
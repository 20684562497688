/**
 * @ngdoc Component
 * @name tallyfy.components.mcafeeLogo
 * @module tallyfy
 *
 * @description
 * Mcafee logo component
 *
 * @author Adi Winata (gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com)
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .component('mcafeeLogo', {
      templateUrl: 'app/components/mcafeeLogo/mcafee-logo.html',
      controller:
        /*@ngInject*/
        function (ENV_CONFIG, MCAFEEHOSTURL, _) {
          var $ctrl = this;
          
          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * @ngdoc method
           * 
           * @name onInit
           * @returns void
           * 
           * @description
           * initalization component
           */
          function onInit() {
            $ctrl.mcafeeHost = _.toLower(ENV_CONFIG.APP_ENV) === 'prod' ? MCAFEEHOSTURL.production : MCAFEEHOSTURL.staging;
          }

          /**
           * @ngdoc method
           * 
           * @name onDestroy
           * @returns void
           * 
           * @description
           * on destroy component
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * 
           * @name onChanges
           * @returns void
           * 
           * @description
           * on component get changes
           */
          function onChanges() { }
        }
    });
})();

/**
 * @ngdoc Component
 * @name tallyfy.account.component.preferences
 * @module tallyfy.account
 *
 * @description
 * A component to manage user's preferences
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('preferences', {
      templateUrl: 'app/modules/account/preferences/preferences.html',
      controller:
        /*@ngInject*/
        function (_, AccountService, Growl, blockUI, $filter, $rootScope, $stateParams, preferencesStore, Helper, OrganizationsService, store) {
          var $ctrl = this,
            preferences = [],
            growl = new Growl(),
            blockUI = blockUI.instances.get('preferences');

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.onDaySelectedChanged = onDaySelectedChanged;
          $ctrl.onDayClicked = onDayClicked;

          /**
           * public methods
           */
          $ctrl.savePreference = savePreference;

          $ctrl.account = {};
          $ctrl.cadenceDays = OrganizationsService.getCadenceDays();

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            preferences = AccountService.getAccountPreferences();
            $ctrl.preferences = _.chain(preferences).map(function (p) {
              return {
                slug: p,
                metadata: undefined,
                value: undefined
              };
            }).keyBy(function (k) {
              return k.slug;
            }).value();
            getPreferences();
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $stateParams.id ? getUserInfo() : getAccountInfo();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getPreferences
           * @description Get updated preference values
           * @returns {Object} preference
           */
          function getPreferences() {
            blockUI.start();
            preferencesStore.getPreferences($stateParams.id || $rootScope.identity.id).then(function (preference) {
              blockUI.stop();
              $ctrl.isShowPreference = true;
              extractPreferences(preference);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name extractPreferences
           * @description Make object for user preferences
           * @param {Object} pfs
           * @returns {Object}
           */
          function extractPreferences(pfs) {
            var pf = _.chain(pfs).filter(function (p) {
              return _.includes(preferences, p.slug);
            }).keyBy(function (k) {
              return k.slug;
            }).value();
            angular.extend($ctrl.preferences, pf);
            setPreferencesDefaultValue();
          }

          /**
           * @ngdoc method
           * @name setPreferencesDefaultValue
           * @description 
           * set default value for preferences for empty value 
           */
          function setPreferencesDefaultValue() {
            $ctrl.preferences['daily_digest'].value = _.get($ctrl.preferences, "daily_digest.value", 'yes');
            $ctrl.preferences['send_emails'].value = _.get($ctrl.preferences, "send_emails.value", 'yes');
            $ctrl.preferences['email_other_completed_tasks'].value = _.get($ctrl.preferences, "email_other_completed_tasks.value", 'no');
            $ctrl.preferences['email_comments'].value = _.get($ctrl.preferences, "email_comments.value", 'yes');
            $ctrl.preferences['email_mentioned'].value = _.get($ctrl.preferences, "email_mentioned.value", 'yes');
            $ctrl.preferences['email_deadline'].value = _.get($ctrl.preferences, "email_deadline.value", 'yes');
            $ctrl.preferences['task_update'].value = _.get($ctrl.preferences, "task_update.value", 'yes');
            $ctrl.preferences['assignee_changed'].value = _.get($ctrl.preferences, "assignee_changed.value", 'yes');
            $ctrl.preferences['email_process_completed'].value = _.get($ctrl.preferences, "email_process_completed.value", 'yes');
          }

          /**
           * @ngdoc method
           * @name savePreference
           * @description Save/update preference.
           * @param {Object} preference
           * @returns {void}
           */
          function savePreference(preference) {
            if (!Helper.checkAccessAuthority())
              return;
            AccountService.setUserPreference({
              id: $stateParams.id || $rootScope.identity.id,
              preference: preference
            }).then(function (response) {
              if (!$stateParams.id) {
                preferencesStore.updatePreferences(response.data);
              }
              growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function () {
            });
          }

          function getAccountInfo() {
            blockUI.start();
            AccountService.getAccount({ with: 'country,role' })
              .then(function (res) {
                $ctrl.accountInfo = AccountService.getGeneralInfo(res.data);
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
          }

          function onDaySelectedChanged() {
            if (!$ctrl.accountInfo.cadence_days.length) {
              $ctrl.accountInfo.cadence_days.push('Monday');
            }
            AccountService.updateCadenceDays(
              { user_id: $ctrl.accountInfo.id },
              { cadence_days: $ctrl.accountInfo.cadence_days }
            ).then(function (res) {
              growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          function getUserInfo() {
            store.getUserById($stateParams.id)
              .then(function (res) {
                $ctrl.accountInfo = res.data;
              });
          }

          //On day clicked
          function onDayClicked (day) {
            var isSelected = _.includes($ctrl.accountInfo.cadence_days, day), position = $ctrl.accountInfo.cadence_days.indexOf(day);

            isSelected ? $ctrl.accountInfo.cadence_days.splice(position, 1) : $ctrl.accountInfo.cadence_days.push(day);

            onDaySelectedChanged();
          }
        }
    });
})();

(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .config(
      /*@ngInject*/
      function ($stateProvider) {
        $stateProvider.state('support.publicBlueprints', {
          parent: 'app',
          absolute: true,
          url: '/support/public-blueprints/list',
          data: {
            authorities: ['USER_ROLE']
          },
          views: {
            content: {
              component: 'supportPublicBlueprints'
            }
          },
          resolve: {
            authorize:
              /*@ngInject*/
              function (Auth, $state) {
                if (Auth.authorize()) {
                  Auth.getIdentity().then(
                    function (identity) {
                      if (!identity.is_support) {
                        $state.go('home');
                      }
                    }
                  );
                }
                return true;
              },
            translatePartialLoader:
              /*@ngInject*/
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('organizations');
                $translatePartialLoader.addPart('support');
                $translatePartialLoader.addPart('steps');
                return $translate.refresh();
              }
          }
        });
      }
    );
})();

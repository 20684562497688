/**
 * @ngdoc Service
 * @name tallyfy.run.RunsService
 *
 * @module tallyfy.run
 *
 * @description
 * RunsService
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 * @modifiedby Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .service('RunDetailService', RunsService);
  /*@ngInject*/
  function RunsService($window) {
    var self = this;
    self.drawerOpened = false;

    /**
     * @function openDrawer
     * @description Set the current state of the drawer as opened
     * 
     */
    self.openDrawer = function () {
      self.drawerOpened = true;
    };

    /**
     * @function closeDrawer
     * @description Set the current state of the drawer as closed
     * 
     */
    self.closeDrawer = function () {
      self.drawerOpened = false;
    };


    /**
     * @function isDrawerOpen
     * @description return the current state of more detail drawer either open or close
     * 
     * @return {Object} Run model
     */
    self.isDrawerOpen = function () {
      return self.drawerOpened;
    };

    /**
     * @ngdoc method
     * @name getProcessSummaryLimit
     * @description get the process summary limit based on screen size
     */
    self.getProcessSummaryLimit = function () {
      var screenWidth = angular.element($window).width(), summaryLimit = 115;
      if (screenWidth < 400) {
        summaryLimit = 75;
      } else if (screenWidth >= 400 && screenWidth < 768) {
        summaryLimit = 95;
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        summaryLimit = 160;
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        summaryLimit = 130;
      } else if (screenWidth >= 1280 && screenWidth < 1440) {
        summaryLimit = 175;
      } else if (screenWidth >= 1440 && screenWidth < 1600) {
        summaryLimit = 210;
      } else if (screenWidth >= 1600 && screenWidth < 1680) {
        summaryLimit = 240;
      } else if (screenWidth >= 1680) {
        summaryLimit = 250;
      } else {
        summaryLimit = 115;
      }
      return summaryLimit;
    }
    
    /**
     * @ngdoc method
     * @name getDescriptionLimit
     * @description get the description limit based on screen size
     */
    self.getDescriptionLimit = function () {
      var screenWidth = angular.element($window).width(), summaryLimit = 250;
      if (screenWidth <= 400) {
        summaryLimit = 75;
      } else if (screenWidth > 400 && screenWidth < 768) {
        summaryLimit = 95;
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        summaryLimit = 160;
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        summaryLimit = 200;
      } else if (screenWidth >= 1280) {
        summaryLimit = 250;
      } else {
        summaryLimit = 250;
      }
      return summaryLimit;
    }
    /**
     * @ngdoc method
     * @name getScreenSize
     * @description get screen size
     */
    self.getScreenSize = function () {
      return angular.element($window).width();
    } 

  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.organizations.createOrganization
 * @module tallyfy.organizations
 *
 * @description
 * A component to create new organization
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('createOrganization', {
      templateUrl: 'app/modules/organizations/createOrganization/createOrganization.html',
      controller:
      /*@ngInject*/
        function ($rootScope, $state, $stateParams, Auth, _, moment, growl, blockUI, PLANS, $filter, UsersService, $scope, $analytics, CONST, CONFIG, $localStorage, AuthPlan, $window, $q, AccountService, OrganizationsService, Principal) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('signup'),
            planLevel = "";

          /**
           * @access public
           * public properties
           */
          $ctrl.orgDetails = {};
          $ctrl.userLikeToDo = [];
          $ctrl.isNextClick = false;
          $ctrl.createOrgForm = {};
          $ctrl.tailorForm = {};
          $ctrl.scheduleCallOptions = [{ value: 'Yes' }, { value: 'No' }];
          $ctrl.trialPlans = _.reverse(AuthPlan.getPlans());
          $ctrl.agreeNotifications = false;
          $ctrl.dialCode = '';
          $ctrl.country = {};
          $ctrl.tallyfyUrl = CONFIG.MARKETING_URL;
          $ctrl.isImproveAndWhy = true;
          $ctrl.phoneNumberPattern = CONST.PHONE_NUMBER_REGEX;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          /**
           * @access public
           * public methods
           */
          $ctrl.saveOrganization = saveOrganization;
          $ctrl.changeScheduleCallValue = changeScheduleCallValue;
          $ctrl.setOrgInfo = setOrgInfo;
          $ctrl.changeTeamSize = changeTeamSize;
          $ctrl.setFocusToImproveAndWhy = setFocusToImproveAndWhy;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.currentUser = $rootScope.identity;
            setPlanLevel();
            $ctrl.orgDetails.signup_survey = {
              'scheduleCall': _.first($ctrl.scheduleCallOptions).value
            };
            $ctrl.teamSize = OrganizationsService.getTeamSize();
            $ctrl.selectedTeamSize = _.head($ctrl.teamSize);
            $ctrl.orgDetails.team_size_above = $ctrl.selectedTeamSize.value;
            trackEvent('Viewed Signup ' + planLevel);
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {}

          /**
           * ngdoc method
           * @name setPlanLevel
           * @description Set plan level
           */
          function setPlanLevel() {
            planLevel = PLANS.FREE;
            //@TODO uncomment when planLevel param is implemented at the route
            //$state.go($state.current, { 'planLevel': planLevel }, { notify: false });
          }

          /**
           * ngdoc method
           * @name saveOrganization
           * @param {*} event
           * @description Save user using user object
           */
          function saveOrganization(event) {
            event.preventDefault();
            if (planLevel === PLANS.FREE && $ctrl.createOrgForm.$valid) {
              $ctrl.orgDetails.recurly_token = "";
              $ctrl.orgDetails.plan_code = PLANS.FREE;
              signUpOrganization();
            }
          }

          /**
           * @ngdoc method
           * @private
           * @name getPhoneNumber
           *
           * @description
           * get user phone number with dial code
           */
          function getPhoneNumber() {
            var userNumber = $ctrl.orgDetails.phone.replace(/\D/g, '');
            var dialCode = $ctrl.dialCode.replace(/\D/g, '');
            if (!_.startsWith(userNumber, dialCode)) {
              userNumber = dialCode + userNumber;
            }
            return userNumber;
          }

          /**
           * @ngdoc method
           * @name setOrgInfo
           * @param {*} event
           * @description To save information in user object and redirected to second screen
           */
          function setOrgInfo(event) {
            event.preventDefault();
            if ($ctrl.tailorForm.$valid) {
              $ctrl.validInitInfo = true;
            }
          }

          /**
           * ngdoc method
           * @name signUpOrganization
           * @description Signup API call
           */
          function signUpOrganization() {
            if (moment.tz.guess()) {
              $ctrl.orgDetails.timezone = moment.tz.guess();
            }
            var orgPayload = angular.copy($ctrl.orgDetails);
            if ($ctrl.orgDetails.phone) {
              orgPayload.phone = getPhoneNumber();
            }
            blockUI.start();
            OrganizationsService.createOrganization(orgPayload).then(function (response) {
              signupSuccessHandler(response);
            }, function (error) {
              blockUI.stop();
              var errorMessages = _.get(error, 'data.validation_errors') || _.get(error, 'data.errors');
              if (errorMessages) {
                $ctrl.validInitInfo = false;
                angular.forEach(errorMessages, function (value, key) {
                  $ctrl.tailorForm[key].$invalid = true;
                  $ctrl.tailorForm[key].$error[key] = value;
                });
              }
            });
          }

          function signupSuccessHandler(response) {
            if (response.error) {
              blockUI.stop();
              growl.error('signup.messages.errors.' + response.code, {
                variables: {
                  'reason': 'validation_error'
                }, referenceId: 'signupController', disableIcons: true, disableCloseButton: true
              });
              return;
            }
            getUserData(response.data);
          }

          /**
           * ngdoc method
           * @name changeScheduleCallValue
           * @description Change call value
           * @param {int} selectedIndex
           */
          function changeScheduleCallValue(selectedIndex) {
            if ($ctrl.orgDetails.signup_survey.scheduleCall === $ctrl.scheduleCallOptions[selectedIndex].value)
              return;

            $ctrl.orgDetails.signup_survey.scheduleCall = $ctrl.scheduleCallOptions[selectedIndex].value;
          }

          /**
           * @ngdoc method
           * @name changeTeamSize
           * @description Change call value
           * @param {*} team
           */
          function changeTeamSize(team) {
            if ($ctrl.orgDetails.team_size_above === team.value) {
              return;
            }
            $ctrl.selectedTeamSize = team;
            $ctrl.orgDetails.team_size_above = team.value;
          }

          /**
           * @name trackEvent
           * @param {*} message
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(message) {
            $analytics.eventTrack(message);
          }

          /**
           * @ngdoc method
           * @name setFocusToImproveAndWhy
           * @description Set focus in textarea after selected plan
           * @returns {Void}
           */
          function setFocusToImproveAndWhy() {
            if (!$ctrl.orgDetails.signup_survey.improveAndWhy && $window.document.getElementById('signupplansprompt')) {
              $window.document.getElementById('signupplansprompt').focus();
            }
          }

          /**
           * @function
           * @name getUserData
           * @description get user datas such as user information and organization
           * @param {*} createdOrg
           */
          function getUserData(createdOrg) {
            $q.all([
              AccountService.me(),
              OrganizationsService.myOrganizations({ with : 'subscription', per_page: 999 })
            ]).then(function (response) {
              var user = _.get(response, '[0].data'),
                orgs = _.get(response, '[1].data');
              !_.isArray(orgs) ? orgs = [] : '';
              setSession(user, orgs, createdOrg);
            }).catch(function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name setSession
           * @description set user data into user identity
           * @param {*} user
           * @param {*} orgs
           * @param {*} createdOrg
           */
          function setSession(user, orgs, createdOrg) {
            var identity;

            if (!_.isEmpty(user)) {
              identity = user;
              identity.authorities = ['USER_ROLE'];

              /*@TODO: Uncomment this when roles & permissions are back*/
              /*identity.permissions = [];
              identity.preferences = [];*/

              //set user identity
              Principal.authenticate(identity);

              var index = _.findIndex(orgs, { 'id': createdOrg.id });
              if (index > -1) {
                var redirectOrg = orgs[index];
                orgs.splice(index, 1);
                orgs.unshift(redirectOrg);
              }
              setOrganization(orgs, identity);
            } else {
              blockUI.stop();
            }
          }

          /**
           * @function
           * @name setOrganization
           * @description ser user organizations
           * @param {*} orgs
           * @param {*} identity
           */
          function setOrganization(orgs, identity) {
            Principal.setOrganization(orgs).then(function () {
              $rootScope.$broadcast('authenticationSuccess');
              if ($ctrl.orgDetails.signup_survey['scheduleCall'].toLowerCase() === 'yes') {
                $state.go('organizations.schedule_call');
              } else {
                $state.go('dashboard', {
                  org_id: _.get(identity, 'default_organization.id')
                });
              }
            }, function () {
              blockUI.stop();
            });
          }
        }
    });
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.account.factory.PreferenceRepositiory
   * @module tallyfy.account
   *
   * @description
   * Create Rest resource for account
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.account')
    .factory('PreferenceRepositiory', PreferenceRepositiory);

  /**
   * @inject
   * @description
   * inject dependecies
   */
  PreferenceRepositiory.$inject = ['BaseRepository', 'CONFIG'];
  /**
   * Constructor
   * AccountRepositiory construct 
   */
  function PreferenceRepositiory(BaseRepository, CONFIG) {
    return BaseRepository('/organizations/:org/accounts', {
      org: 'org_id'
    }, {
        refreshKey: {
          method: 'GET',
          url: CONFIG.API_URL + '/users/refresh-key'
        }
      });
  }
})();
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('learnMoreDocumentModal', {
      templateUrl: 'app/components/documentPaneEditor/learnMoreModal/learn-more.component.html',
      bindings: {
        close: '&'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.continueToEditor = continueToEditor;

          function onInit() { }

          function onChanges() { }

          function onDestroy() { }

          function continueToEditor (e) {
            e.preventDefault();
            $ctrl.close();
          }

        }
    })
})();
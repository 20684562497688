/**
 * @ngdoc directive
 * @name tallyfy.imageLazyLoad
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("imageLazyLoad", imageLazyLoad);

  /*@ngInject */
  function imageLazyLoad($timeout) {
    return {
      restrict: 'A',
      scope: false,
      link: function ($scope, $el, $attrs) {
        var mainSection = angular.element('.main-section');

        $el[0].classList.add('fade');
        $el[0].classList.add('lazy-image');
        
        $timeout(function () {
          var rect = $el[0].getBoundingClientRect().top;
          if (mainSection[0]) {
            if (rect <= mainSection.height() + mainSection[0].getBoundingClientRect().top) {
              $el.attr('src', $attrs.imageLazyLoad);
              $el[0].classList.remove('lazy-image');
              $el[0].classList.add('lazy-image-loaded');
            }
          }
        }, 1000);
      }
    };
  }
})();
(function () {
  "use strict";
  angular.module("tallyfy")
    .directive('noPristineCheck', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, iElem, iAttrs, ngModelCtrl) {
          ngModelCtrl.$setDirty = angular.noop;
          ngModelCtrl.$setPristine = angular.noop;
        }
      };
    });
})();
/**
 * @ngdoc overview
 * @name editor
 * @module tallyfy.editor
 * @description
 * edito module
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict'
  angular
    .module('tallyfy.editor', ['ngFileUpload', 'bootstrapLightbox']);
})();


/**
 * @ngdoc Component
 * @name lightRoleErrorMessageModal
 * @module tallyfy
 * @description
 * Component for light role error message modal
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('lightRoleErrorMessageModal', {
      templateUrl: 'app/modules/process/components/library/lightRoleErrorMessageModal/light-role-error-message-modal.html',
      bindings: {
        resolve: '<',
        dismiss: '&',
        close: '&'
      },
      controller:
      /*@ngInject*/
        function lightRoleErrorMessageModalComponentController(_, ENV_CONFIG, $rootScope) {
          var $ctrl = this;

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          $ctrl.accepts = accepts;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.lightRoleErrorMessage = _.get($ctrl.resolve, 'data');
            var orgId = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.memberLink = ENV_CONFIG.TYFY_MAIN_URL + '/' + orgId + '/settings/org/members';
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
          */
          function cancel() {
            $ctrl.dismiss();
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Close modal
          */
          function accepts() {
            $ctrl.close();
          }
        }
    });
})();

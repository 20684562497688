(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(paginationConfig);
  paginationConfig.$inject = ['paginationTemplateProvider'];
  function paginationConfig(paginationTemplateProvider) {
    paginationTemplateProvider.setPath('app/components/pagination/pagination.tpl.html');
  }
})();
(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name tallyfy.tags.factory.TagsRepository
   * @module tallyfy.tags
   *
   * @description
   * Create Rest resource for tags
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   * 
   * */
  angular
    .module('tallyfy.tags')
    .factory('TagsRepository', TagsRepository);

  /*@ngInject*/
  function TagsRepository(BaseRepository) {
    var repository;

    repository = BaseRepository('/organizations/:org/:action/:sub_action/:id', {
      org: 'org_id'
    }, {});

    repository.createTag = function (data) {
      data.title = data.title.replaceAll(/#/g,'');
      var params = {
        action: 'tags'
      };
      return repository.create(params, data);
    };

    repository.updateTag = function (tag) {
      var params = {
        action: 'tags',
        id: tag.id
      };
      var data = {
        'title': tag.title,
        'color': tag.color
      };
      return repository.update(params, data);
    };

    repository.attachTag = function (tag, subject) {
      var params = {
        action: 'checklists',
        sub_action: 'tags'
      };

      var data = {
        'tag_id': tag.id,
        'subject_id': subject.id,
        'subject_type': subject.type,
        'tag_type': tag.type ? 'public' : 'private'
      };
      return repository.create(params, data);
    };

    repository.attachPublicTag = function (data) {
      var params = {
        action: 'public-tags'
      };
      return repository.create(params, data);
    };

    repository.detachTag = function (ID) {
      var params = {
        action: 'checklists',
        sub_action: 'tags',
        id: ID
      };
      return repository.delete(params);
    };

    repository.detachPublicTag = function (ID) {
      var params = {
        action: 'checklists',
        sub_action: 'public-tags',
        id: ID
      };
      return repository.delete(params);
    };

    repository.deleteTag = function (ID) {
      var params = {
        action: 'tags',
        id: ID
      };
      return repository.delete(params);
    };

    repository.getPublicTags = function (params) {
      var publicTags = BaseRepository('/utils/public-tags/:tagType', {
        tagType: params.action
      }, {});
      return publicTags.get(params);
    };

    return repository;
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportDomainModal
 * @module tallyfy.support
 *
 * @description
 * A component to add / edit a org domain
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('supportDomainModal', {
      templateUrl: 'app/modules/support/components/domains/support-domain-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (DESCRIPTIONSIZE, _, SupportService) {
          var $ctrl = this,
            domainObjPickValues = ['name'];

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveDomainForm = saveDomainForm;

          /**
           * public properties
           */
          $ctrl.maxName = DESCRIPTIONSIZE.default;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.supportDomains = _.get($ctrl.resolve, 'domains')
            $ctrl.domainId = _.get($ctrl.resolve, 'domainId')
            $ctrl.orgId = _.get($ctrl.resolve, 'orgId');
            $ctrl.domainSupport = _.find($ctrl.supportDomains, { id: $ctrl.domainId});
          }

          function onChanges() { }
          function onDestroy() { }

          /**
           * @function
           * @name saveDomainForm
           * @description create / update org domain
           */
          function saveDomainForm() {
            if ($ctrl.supportDomainForm.$valid) {
              saveDomainHandler();
            }
          }

          /**
           * @function
           * @name saveDomainHandler
           * @description create / update org domain
           */
          function saveDomainHandler() {
            var domainSupport = angular.copy($ctrl.domainSupport), resource;
            $ctrl.onSaving = true;
            resource = domainSupport.id ? updateDomainSupport({ id: domainSupport.id }, _.pick(domainSupport, domainObjPickValues)) : createDomainSupport(domainSupport);
            resource.then(function (response) {
              $ctrl.close({ $value: response.data });
            }, function () {
              $ctrl.onSaving = false;
              $ctrl.dismiss();
            });
          }

          /**
           * @function
           * @name updateDomainSupport
           * @description update domain
           */
          function updateDomainSupport (domain){
            SupportService.updateDomainSupport($ctrl.orgId, {
              id: domain.id,
              name: $ctrl.domainSupport.name
            }).then(function (response) {
              $ctrl.close({ $value: response.data });
            }, function () { });
          }

          /**
           * @function
           * @name createDomainSupport
           * @description create new domain
           */
          function createDomainSupport () {
            SupportService.createDomainSupport($ctrl.orgId, {
              name: $ctrl.domainSupport.name
            }).then(function (response) {
              $ctrl.supportDomains.push(response.data);
              $ctrl.close({ $value: response.data });
            }, function () { });   
          }
        }
    });
})();
/**
 * @ngdoc Directive
 * @name tallyfy.defaultValue
 * @restrict 'A'
 * @element ANY 
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('defaultValue', ['_','$log', function (_, $log) {
      return {
        restrict: 'A',
        require: '^ngModel',
        scope: {
          defaultField: '=?'
        },
        link: function (scope, element, attrs, modelCtrl) {
          var currValue,
          defaultField = scope.defaultField || {},
          defaultProperty = attrs.defaultValue;
          if(!defaultProperty){
            throw new Error('defaultProperty is not defined for default-value directive');
          }
          element.bind('focus', function (event) {
            if (modelCtrl.$modelValue === defaultField[defaultProperty]) {
              modelCtrl.$setViewValue('');
              modelCtrl.$render();
            }
          });

          element.bind('blur', function (event) {
            if (_.isEmpty(modelCtrl.$modelValue)) {
              modelCtrl.$setViewValue(defaultField[defaultProperty]);
              modelCtrl.$render();
            }
          });

          scope.$on('$destroy', function () {
            element.unbind('focus blur');
          })
        }
      }
    }]);

})();

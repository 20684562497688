(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('publicSearchBox', {
      templateUrl: 'app/components/public/library/search-box/search-box.component.html',
      bindings: {
        ngModel: '=',
        placeholder: '@',
        ngModelChangeCallback: '&?'
      },
      controller:
        /*@ngInject*/
        function () {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onModelChange = onModelChange;

          function onInit() { }
          function onChanges() { }
          function onDestroy() { }

          function onModelChange() {
            if (typeof $ctrl.ngModelChangeCallback === 'function') {
              $ctrl.ngModelChangeCallback({
                value: $ctrl.ngModel
              });
            }
          }

        }
    });
})();
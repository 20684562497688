(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('TranslationRepository', translationRepository);

  /*@ngInject*/
  function translationRepository(BaseRepository) {
    var repository = BaseRepository('/functions/translation/azure/:action');

    repository.translateContent = function (params, body) {
      return repository.$create(params, body);
    }

    repository.test = function (params, body) {
      return repository.$create(params, body);
    }

    return repository;
  }
})();
/**
 * @ngdoc Component
 * @name tallyfy.integration.Component.api
 * @module tallyfy.integration
 *
 * @description
 * A component to manage api integration
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.integration')
    .component('api', {
      templateUrl: 'app/modules/integration/developerApi/developerApi.html',
      controller:
        /*@ngInject*/
        function (Principal, IntegrationService, Growl, $filter, blockUI, Helper, DeveloperApiService, ProcessService, AuthServerProvider, _, DateUtils, $rootScope, AuthPlan, PLANS, OrganizationsService, BeaconService, $uibModal, $state) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('developerApi'),
            growl = new Growl();

          /**
         * component's lifeCycle hooks
         */
          $ctrl.$onInit = initialization;
          $ctrl.copySuccess = copySuccess;
          $ctrl.resetKeys = resetKeys;
          $ctrl.setScrollToMenu = setScrollToMenu;
          $ctrl.toggleTokenView = toggleTokenView;
          $ctrl.openAskQuestion = openAskQuestion;
          $ctrl.showAccessToken = false;
          $ctrl.saveGAId = saveGAId;
          $ctrl.saveMixpanelToken = saveMixpanelToken;
          $ctrl.deleteAllTokens = deleteAllTokens;
          $ctrl.onMagicLinkClick = onMagicLinkClick;
          $ctrl.openAzureConfigModal = openAzureConfigModal;
          $ctrl.importBlueprint = ProcessService.openImportBluePrintModal;

          /**
           * public properties
           */
          $ctrl.apiModel = {};

          function initialization() {
            $ctrl.organization = _.pick($rootScope.identity.default_organization, ['name', 'google_analytics_id', 'mixpanel_token', 'azure_cognitive_service', 'working_days']);
            $ctrl.isSaved = !!$ctrl.organization.google_analytics_id;
            $ctrl.isMixpanelSaved = !!$ctrl.organization.mixpanel_token;
            $ctrl.orgAzureCognitiveService = JSON.parse($ctrl.organization.azure_cognitive_service);
            $ctrl.isAzureResourceCognitiveServiceSaved = !!$ctrl.organization.azure_cognitive_service;
            getCurrentAccount(false);
            var navTab = _.get($state, 'current.name');
            if (navTab === 'settings.integration') {
              $ctrl.integrations = DeveloperApiService.getIntegrations();
              $ctrl.integrationContent = DeveloperApiService.getIntegrationContent();
            }
            if (navTab === 'settings.compliance') {
              $ctrl.compliances = DeveloperApiService.getCompliances();
              $ctrl.compliancesContent = DeveloperApiService.getCompliancesContent();
            }
            $ctrl.tokenDetails = AuthServerProvider.getAllTokenDetails();
            processTokenDetails();
          }

          /**
           * @function
           * @name getCurrentAccount
           * @description Get current account information
           * @param {Object} force
           * @returns {Void}
           */
          function getCurrentAccount(force) {
            return Principal.identity(force).then(function (account) {
              var apiModel = IntegrationService.getAPIModel(account);
              angular.extend($ctrl.apiModel, apiModel);
            });
          }

          /**
           * @function
           * @name resetKeys
           * @description Reset keys
           * @returns {Void}
           */
          function resetKeys() {
            blockUI.start();
            IntegrationService.resetKeys().then(function () {
              getCurrentAccount(true).then(function () {
                growl.success($filter('translate')('integration.api.messages.success'), { referenceId: 'apiController', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name deleteAllTokens
           * @description Delete all tokens in organization
           * @returns {Void}
           */
          function deleteAllTokens() {
            blockUI.start();
            OrganizationsService.onDeleteOrgActiveTokens().then(function (res) {
              growl.success($filter('translate')('integration.api.messages.tokenSuccess'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            }, function () {
              growl.warning($filter('translate')('integration.api.messages.tokenWarning'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            });
          }

          /**
           * @function 
           * @name copySuccess
           * @param {String} text 
           * @param {Object} target 
           * @param {*} $event 
           * @description Copy success handler
           * @returns {Void} 
           */
          function copySuccess(text, target, $event) {
            var label = $filter('translate')('integration.api.form.fields.' + target + '.label');
            $ctrl.highlight = target;
            Helper.copyToClipboard(text, target, $event, label);
          }

          /**
           * @function
           * @name setScrollToMenu
           * @param {Function} scrollToMenuOfIntegration
           * @returns {Void}
           */
          function setScrollToMenu(scrollToMenuOfIntegration) {
            $ctrl.scrollToMenuOfIntegration = scrollToMenuOfIntegration;
          }

          /**
           * @function
           * @name toggleTokenView
           * @returns {Void}
           */
          function toggleTokenView() {
            if (!Helper.checkAccessAuthority())
              return;
            if (AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'viewAccessToken')) {
              $ctrl.showAccessToken = !$ctrl.showAccessToken;
            }
          }

          /**
           * @function
           * @name processTokenDetails
           * @returns {Void}
           */
          function processTokenDetails() {
            if (_.get($ctrl.tokenDetails, 'issued_at')) {
              var issuedAtMoment = DateUtils.toLocal($ctrl.tokenDetails.issued_at);
              $ctrl.tokenDetails.issuedAtDate = issuedAtMoment.format('MMM D, YYYY  h:mm a') + ' (' + $rootScope.identity.timezone + ')';
              $ctrl.tokenDetails.expiresAtDate = issuedAtMoment.add($ctrl.tokenDetails.expires_in, 'seconds').format('MMM D, YYYY  h:mm a') + ' (' + $rootScope.identity.timezone + ')';
            }
          }

          /**
           * @function
           * @name openAskQuestion
           * @returns {void}
           */
          function openAskQuestion() {
            BeaconService.open();
          }

          /**
           * @ngdoc method
           * @name saveGAId
           * 
           * @description
           * save ga id preference
           */
          function saveGAId() {
            $ctrl.onSaving = true;
            OrganizationsService
              .update(null, $ctrl.organization)
              .then(function (res) {
                $rootScope.identity.default_organization.google_analytics_id = res.data.google_analytics_id;
                $ctrl.isSaved = true;
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.isSaved = $ctrl.onSaving = false;
              });
          }

          function saveMixpanelToken() {
            $ctrl.onSaving = true;
            OrganizationsService
              .update(null, $ctrl.organization)
              .then(function (res) {
                $rootScope.identity.default_organization.mixpanel_token = res.data.mixpanel_token;
                $ctrl.isMixpanelSaved = true;
                $ctrl.onSaving = false;
              }, function () {
                $ctrl.isMixpanelSaved = $ctrl.onSaving = false;
              });
          }

          function onMagicLinkClick() {
            if (!Helper.checkAccessAuthority())
              return;
            $uibModal.open({
              component: 'magicLink',
              windowClass: 'magic-link-modal',
              backdrop: 'static'
            });
          }

          function openAzureConfigModal() {
            if (!Helper.checkAccessAuthority())
              return;
            $uibModal.open({
              component: 'azureConfigModal',
              windowClass: 'azure-config-modal',
              backdrop: 'static',
              resolve: {
                organization: function () {
                  return $ctrl.organization
                }
              }
            }).result.then(function (res) {
              if (res) {
                angular.extend($ctrl.organization.azure_cognitive_service, res);
              }
            });
          }

          //controller ends
        }
    });
})();

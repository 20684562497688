/**
 * @ngdoc overview
 * @name tallyfy.settings
 *
 * @module tallyfy.settings
 *
 * @description
 * tallyfy.settings module manages logged in user's account
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular.module('tallyfy.settings', ['tallyfy.core','tallyfy.account']);
})();

/**
 * @ngdoc factory
 * @name tallyfy.factory.TimeZone
 * @module tallyfy
 *
 * @description
 * encapsulate CRUD and business logic of timeZones
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy')
    .factory('TimeZone', TimeZone);
  
  /**
   * Constructor
   * TimeZone construct
   */
  /*@ngInject*/
  function TimeZone($http, _, $q) {
    var service = {
      getAll: getAll
    };
    return service;
    function getAll() {
      var defer = $q.defer();
      $http.get('/resources/timeZone.json')
      .then(
        function (res) {
        var zones = parseResponse(res.data);
        defer.resolve(zones);
      }, function() {
        return defer.reject();
      });
      return defer.promise;
    }
    function parseResponse(data) {
      var timeZones = [];
      if (!angular.isArray(data)) {
        return [];
      }
      for (var index = 0, length = data.length; index < length; index++) {
        var group = data[index]['group'], zones = data[index]['zones'], tempItem;
        tempItem = _.map(zones, function (zone) {
          var zone = _.clone(zone);
          zone.group = group;
          return zone;
        });
        Array.prototype.push.apply(timeZones, tempItem);
      }
      return timeZones;
    }
  }
})();

/**
 * @ngdoc Component
 * @name tallyfy.account.component.resetRequestForm
 * @module tallyfy.account
 * @description
 * password requert form
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.account')
    .component('resetRequestForm', {
      templateUrl: 'app/modules/account/reset/requestForm/reset-request-form.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (Auth, blockUI, _) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('requestPasswordUI');
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          /**
           * public properties
           */
          $ctrl.requestReset = requestReset;
          $ctrl.resetPasswordSuccess = false;
          $ctrl.resetRequest = {};
          $ctrl.resetRequestForm = {};
          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() { }
          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          /**
           * @ngdoc method
           * @name requestReset
           * @description To reset password
           * @returns {void}
           */
          function requestReset() {
            blockUI.start();
            $ctrl.resetRequest.email = _.toLower($ctrl.resetRequest.email);
            Auth.recoverPassword($ctrl.resetRequest).then(function () {
              blockUI.stop();
              $ctrl.resetPasswordSuccess = true;
              resetForm();
            }, function () {
              blockUI.stop();
            });
          }
          /**
           * @ngdoc method
           * @name resetForm
           * @description Reset reset passoword form
           * @returns {void}
           */
          function resetForm() {
            $ctrl.resetRequest = {};
            $ctrl.resetRequestForm.$setPristine();
            $ctrl.resetRequestForm.$setUntouched();
          }

        }
    });
})();
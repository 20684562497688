(function () {
  'use strict';
  angular.module('tallyfy')
    .config(
      /*@ngInject*/
      function ($tallyfyWebSocketProvider, ENV_CONFIG) {
        $tallyfyWebSocketProvider.$setConfig({
          url: ENV_CONFIG.COLLECTOR_WS_URL,
          autoReconnect: true,
          autoConnectInterval: 10000,
          heartbeat: 60
        });
      }
    );
})();